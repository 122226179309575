import {Form} from "react-bootstrap";
import {ORDER_SORT_OPTIONS} from "entities/orders/constant/order.constant";
import SettingMentorSkeleton from "entities/mentor/skeleton/setting.mentor.list.skeleton";
import React, { useCallback, useEffect, useState } from 'react'
import helpers from "helpers/index";
import axios, { AxiosError, AxiosResponse } from 'axios'
import {Order} from "./order.type";
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll";
import {useAppDispatch} from 'config/store'
import { clearError, getEntities, getListOrderOfChannel, getOrders } from 'entities/orders/order.store.reducer'
import OrderItemOfChannel from "./components/OrderItemOfChannel";
import Title from "components/Title";
import __ from "languages/index";
import _Helmet from "components/helmet";
import BigLayout from "../../layouts/bigLayout";
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";
import { Box } from '@mui/material'
import ComponentEmpty from 'components/componentEmpty'
import OrderListTable from 'entities/orders/components/OrderListTable'
import { IQueryParams } from 'config/reducer.utils'
import moment from 'moment/moment'
import { omit } from 'lodash'


const OrderListChannel = () =>{

    const initialQuery = {
        order_by: "DESC",
        page: 1,
        limit: 20,
    }

  const [totalCountOrder, setTotalCountOrder] = useState(0)
  const [listOrderOfChannel, setListOrderOfChannel] = useState<Order[]>([])
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [paramRequest, setParamRequest] = useState<IQueryParams>({ ...initialQuery });


    useEffect(() => {
      getOrdersOfChannel(paramRequest)
    }, [paramRequest])


    const dispatch = useAppDispatch()
    const getOrdersOfChannel = (prams: IQueryParams) => {
      setIsRefresh(true)
      dispatch(getListOrderOfChannel(prams))
        .unwrap()
        .then((res: AxiosResponse) => {
          setListOrderOfChannel(res.data)
          setTotalCountOrder(res.headers['x-total-count'])
          setIsRefresh(false)
        })
        .catch((error: AxiosError) => {
          console.log(`getEntities error >>>`, error)
        })}


  const handleFilterByField = (type, value) => {
    const newVal =
      type === 'createdAt' ? moment(value).format('YYYY-MM-DD') : value;
    const newValues: IQueryParams = value === 0 || value
      ? {
        ...paramRequest,
        page: type === 'query' ? 1 : paramRequest?.page,
        [type]: newVal
      }
      : omit(paramRequest, [type]);
    setParamRequest(newValues);
  };

    useEffect(() => {
        const buildURLSearch = helpers.buildEndUrl(paramRequest)
        if (window.location.search !== buildURLSearch) {
            window.history.replaceState(null, 'Orders', `/orders/orders-channel${buildURLSearch}`)
        }
    }, [paramRequest])

    const handleClick = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
      setParamRequest({
           ...paramRequest,
            status: event?.target?.value
        })
    },[])

    useEffect(() => {
      window.scrollTo(0, 0)
      dispatch(clearError())
    }, [])

    return (
      <BigLayout>
        <_Helmet title='order_of_channel' />
        <div className="order_container_select_box">
          <div className="order_select_box">
            <Title text={__("order_of_channel")} />
            <div className="order_container_sort">
              <Form.Select
                aria-label=""
                onChange={handleClick}
                defaultValue={paramRequest?.status ? paramRequest?.status : undefined}
              >
                {ORDER_SORT_OPTIONS.map((item, index: number) => (
                  <option key={index.toString()} value={item?.value}>
                    {item?.title}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        </div>
        <Box width={'100%'}>
          {Boolean((!listOrderOfChannel || listOrderOfChannel?.length < 1)) ?
            <ComponentEmpty /> : (
              <OrderListTable
                orders={listOrderOfChannel}
                pathname={location?.pathname}
                isRefresh={isRefresh}
                paramsRequest={paramRequest}
                totalCount={totalCountOrder}
                handleFilterByField={handleFilterByField}
                isAdmin={true}
              />
            )}
        </Box>
      </BigLayout>
    )
}
export default OrderListChannel;
