import { SvgIcon, SvgIconProps } from '@mui/material'

const StrokeIcon = (props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="currentColor">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M9 2C5.13401 2 2 5.13401 2 9C2 9.90329 2.31391 11.057 2.88213 12.3318C3.44332 13.5909 4.21934 14.8946 5.06348 16.0753C5.90881 17.2576 6.80238 18.2886 7.58499 19.0119C7.97753 19.3747 8.32191 19.6413 8.60122 19.8109C8.8457 19.9595 8.97142 19.9913 8.99831 19.9982C8.99893 19.9983 8.99949 19.9985 9 19.9986C9.00051 19.9985 9.00107 19.9983 9.00169 19.9982C9.02859 19.9913 9.1543 19.9595 9.39878 19.8109C9.67809 19.6413 10.0225 19.3747 10.415 19.0119C11.1976 18.2886 12.0912 17.2576 12.9365 16.0753C13.7807 14.8946 14.5567 13.5909 15.1179 12.3318C15.6861 11.057 16 9.90329 16 9C16 5.13401 12.866 2 9 2ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 10.3059 17.5639 11.7567 16.9446 13.1461C16.3183 14.5512 15.4693 15.9714 14.5635 17.2385C13.6588 18.5038 12.6774 19.6444 11.7725 20.4807C11.3213 20.8977 10.8688 21.258 10.4372 21.5203C10.0328 21.7659 9.52765 22 9 22C8.47235 22 7.96716 21.7659 7.56284 21.5203C7.13122 21.258 6.67872 20.8977 6.22751 20.4807C5.32262 19.6444 4.34119 18.5038 3.43652 17.2385C2.53066 15.9714 1.68168 14.5512 1.05537 13.1461C0.436092 11.7567 0 10.3059 0 9ZM9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6ZM4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9Z"
              fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
export default StrokeIcon
