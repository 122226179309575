import { Components } from '@mui/material'
import { CheckboxIcon, CheckedboxIcon, IndeterminateIcon } from 'icons'
import { CurrentTheme } from 'styles/themes'

export const defaultCheckbox: Components<CurrentTheme>['MuiCheckbox'] = {
  defaultProps: {
    size: 'small',
    icon: <CheckboxIcon color="action" />,
    checkedIcon: <CheckedboxIcon />,
    indeterminateIcon: <IndeterminateIcon />
  },

  styleOverrides: {
    root: ({ theme }) => ({
      '&:hover': {
        // color: theme.palette.primary.main
      }
    })
  }
}
