import React from 'react'
import { TicketComment } from 'entities/support/ticket.type'
import NavigateUser from 'entities/challenge/component/NavigateUser'
import Avatar from 'components/Avatar'
import { Card, Stack } from 'react-bootstrap'
import { formatDate } from 'entities/support/components/TicketItem'
import MediaView from 'components/MediaView'
import Image from 'react-bootstrap/Image'

type Props = {
  comment: TicketComment
}
const CommentItem = ({ comment }: Props) => {
  const { user_id: user, content } = comment
  return (
    <Stack direction="horizontal" gap={2} className={'d-flex align-items-start'}>
      <NavigateUser _id={user?._id}>
        <Avatar width={35} height={35} src={user?.user_avatar} roundedCircle />
      </NavigateUser>
      <Card className={'flex-grow-1'}>
        <Card.Header>
          <span>
            <b>{user.display_name}</b> commented at {formatDate(comment.createdAt)}
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text className={'m-0'}>{comment.content}</Card.Text>
          {comment?.attach_files.map(attach => (
            <MediaView type={'image'} mediaUrl={attach?.media_url} key={attach._id}>
              <Image src={attach.media_url} width={200} height={'auto'} />
            </MediaView>
          ))}
        </Card.Body>
      </Card>
    </Stack>
  )
}

export default CommentItem
