import React from 'react'
import {ListPlan} from 'entities/payment/payment.interface'
import DateTime from 'date-and-time'
import __helpers from 'helpers/index'
import TypedChannel from "../../../interfaces/channel.interface";
import __ from "languages/index";

interface TypePaymentTimeProps {
  onCheckout: (planId:string) => void
  checked: string
  listPlan: ListPlan[]
  selectPayment: number
  show: boolean
  channel:TypedChannel
}
const PaymentTime: React.FC<TypePaymentTimeProps> = ({onCheckout, checked, selectPayment, listPlan, show,channel}) => {
  const plan = checked === '' ? listPlan[0] : Array.isArray(listPlan) && listPlan?.find(plan => checked === plan?._id)
  const dateStart = new Date()
  const dateEnd = DateTime.addDays(dateStart, checked === plan?._id ? plan?.amount_of_day : 30)
  function ViewStep() {
    return (
      <div >
        <div
          className="bg-white rounded-4 border d-flex align-items-center justify-content-center"
          style={{ width: '27px', height: '27px' }}
        >
          <div
            className="rounded-4"
            style={{ width: '17px', height: '17px', background: '#a51c30' }}
          />
        </div>
        <div className="border-start mt-2" style={{ height: '30px', marginLeft: '13px' }} />
      </div>
    )
  }

  return (
    <div className="col-12 col-xl-4 mt-4 mt-xl-0">
      <div className="bg-white rounded-2 ">
        <div className="p-3">
          <h5>{plan?.type === "one_time" ?__("payment_time_title_one_time") : __("payment_time_title")}</h5>
          <p>
            {plan?.type === "recurring" ? __("payment_time_sub_title") :  __("payment_time_sub_title_one_time")}
          </p>
          <div>
            <div
              className="d-flex flex-row justify-content-between w-100 mt-2 py-0 row payment_text"
              style={{ color: '#a51c30' }}
            >
              <div className=" col-6">
                <div className="d-flex flex-row">
                  {plan?.type === "recurring" && ViewStep()}
                  <div className="ms-2">
                    <p className="my-0 fw-bold">{plan?.type === "recurring" ? DateTime.format(dateStart, 'YYYY-MM-DD') : plan?.name}</p>
                  </div>
                </div>
              </div>

              <div className=" col-6 pe-0">
                <p className="my-0 fw-bold text-end">
                  {`${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}`}
                </p>
              </div>
            </div>
            {
              plan?.type === "recurring" &&
                <div
                  className="d-flex flex-row justify-content-between w-100 mt-2 py-0 row payment_text"
                  style={{ color: '#a51c30' }}
                >
                  <div className=" col-6">
                    <div className="d-flex flex-row">
                      {ViewStep()}
                      <div className="ms-2">
                        <p className="my-0 fw-bold">{DateTime.format(dateEnd, 'YYYY-MM-DD')}</p>
                      </div>
                    </div>
                  </div>
                  <div className=" col-6 pe-0">
                    <p className="my-0 fw-bold text-end">
                      {`${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}`}
                    </p>
                  </div>
                </div>
            }
          </div>
        </div>

        <div className="border-top pb-3">
          <div className="px-3 mt-3 ">
            <div className="d-flex flex-row justify-content-between">
              <p className="fw-bold m-0">{__("payment_total")}</p>
              <p className="fw-bold m-0 fs-6">
                {/*{`${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")} +  ${__("payment_tax")}`}*/}
                {`${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}`}
              </p>
            </div>
            <div className="position-relative">
              <button
                onClick={()=> onCheckout(plan?._id)}
                className="btn btn-primary w-100 mt-3"
                disabled={(plan?.price > 0 && selectPayment == null) || listPlan?.length <= 0 || show}
              >
                {show && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
                {__("payment_title")}
              </button>
              {/*<p>*/}
              {/*  {__("payment_time_text")}*/}
              {/*</p>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PaymentTime
