//Library
import React, {memo, useCallback, useEffect} from 'react'
import {notEmpty, useField, useForm} from '@shopify/react-form'
import DragDropUploadMedia from 'entities/courses/components/dragDropUploadMedia'
import {Button, Modal} from 'react-bootstrap'
import {AxiosError, AxiosResponse} from 'axios'

//Components
import TextField from 'components/TextField'

//Store
import {useAppDispatch} from 'config/store'
import {createGift, setActionsCheck} from '../store/mission.store.reduce'

//Helper
import helpers from 'helpers'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

const AddGiftMission = ({
  show,
  onClose,
  onChange
}: {
  show?: boolean
  onClose?: () => void
  onChange?: Function
}) => {
  const dispatch = useAppDispatch()
  const toast = useToast()

  const { fields, submit, submitting, dirty, reset } = useForm({
    fields: {
      award_name: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("mission_reward_name_not_empty")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("mission_reward_name_not_empty")}`
            }
          }
        ]
      }),
      award_price: useField<string>({
        value: '0',
        validates: [
          notEmpty(`${__("mission_reward_price_not_empty")}`),
          inputVal => {
            if (helpers.parseNumeric(inputVal) <= 0) {
              return `${__("mission_reward_price_not_empty")}`
            }
          }
        ]
      }),
      award_image: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("mission_reward_image")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("mission_reward_image")}`
            }
          }
        ]
      })
    },

    async onSubmit(values) {
      try {
        const formData = {
          gift_type: 'redeem',
          name: values?.award_name?.trim(),
          media_id: values?.award_image?.trim(),
          price: `${helpers.parseNumeric(values?.award_price)}`
        }

        dispatch(createGift(formData))
          .unwrap()
          .then((res: AxiosResponse) => {
            onChange({ _id: res?.data?._id, value: res?.data?._id, label: res?.data?.name })
            dispatch(
              setActionsCheck({
                type: `${__("mission_add_reward")}`,
                isSuccess: true,
                statusCode: res?.status
              })
            )
            onClose()
          })
          .catch((error: AxiosError) => {
            console.log(`createGift_${error}`)
            toast.show({
              title: `${__("notification")}`,
              content: `${__("mission_add_reward_failed")}`,
              type: EnumTypeToast.Error
            })
          })

        await helpers.sleep(1000)
      } catch (e: any) {
      } finally {
        return { status: 'success' }
      }
    }
  })

  const handleAwardAllChange = useCallback(
    (media_id: string) => {
      fields?.award_image?.onChange(media_id)
      fields?.award_image?.runValidation(media_id)
    },
    [fields]
  )

  useEffect(() => {
    reset()
  }, [])

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{__("mission_add_gift")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addMission_right_info_award_content">
            <div className="addMission_right_info_award_content_image">
              <div
                className={`addMission_right_info_award_image_wrapper ${
                  fields?.award_image.error && 'validate_failed'
                }`}
              >
                <DragDropUploadMedia
                  callbackSetUrl={handleAwardAllChange}
                  urlDefaultThumbnail={fields?.award_image?.value || ''}
                  accept="image/png, image/jpeg, image/jpg"
                  placeHolder={__("mission_upload_image_placeholder")}
                  placeHolderBelow={__("mission_upload_image_placeholder_below")}
                />
              </div>
              {fields?.award_image.error && (
                <small className="form-text text-danger d-block invalid-feedback">
                  {fields?.award_image.error || ''}
                </small>
              )}
            </div>
            <div className="w-100">
              <div className="addMission_right_info_award_content_wrapper">
                <div className="addMission_right_content_title">
                  <div>
                    <label className="addMission_body_header_title">{__("mission_reward_name")}</label>
                    <span className="text-danger">&#42;</span>
                  </div>
                  <TextField
                    {...fields?.award_name}
                    label={__("mission_reward_name")}
                    labelHidden
                    placeholder={__("mission_reward_name")}
                    type="text"
                  />
                </div>
                <div className="addMission_right_content_title">
                  <div>
                    <label className="addMission_body_header_title">{__("mission_price")}</label>
                    <span className="text-danger">&#42;</span>
                  </div>
                  <TextField
                    {...fields?.award_price}
                    label={__("mission_gift_value")}
                    labelHidden
                    placeholder={__("mission_gift_value")}
                    maxLength={12}
                    type="text"
                    value={helpers.formatNumberWithCommas(fields?.award_price?.value || '0')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            {__("btn_close")}
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!dirty || submitting}
            className="button-event"
            onClick={submit}
          >
            {submitting ? `${__("btn_processing")}` : `${__("btn_confirm")}`}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default memo(AddGiftMission)
