import { useEffect, useRef } from 'react'

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const convertTimeToTimeZone = originalTime => {
  const originalDate = new Date(originalTime)
  const timeZoneOffset = 7 * 60 * 60 * 1000
  const newTime = new Date(originalDate.getTime() - timeZoneOffset)
  return newTime.toISOString()
}

export const addTimeZone = originalTime => {
  const originalDate = new Date(originalTime)
  const timeZoneOffset = 7 * 60 * 60 * 1000
  const newTime = new Date(originalDate.getTime() + timeZoneOffset)
  return newTime.toISOString()
}

export const outOfTime = (time: string) => {
  return new Date().getTime() > new Date(time).getTime()
}
