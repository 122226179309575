import { useCallback, useEffect, useRef, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import { TypedLivestream } from 'entities/calendar/calendar.interface'
import { postSDPOffer } from 'entities/livestream/services/cloudflare.service'
import { EnumCommunicateClient } from 'entities/livestream/hooks/livestream.admin.hook'
import {
  EnumReadyStatusLivestream,
  EnumStatusLivestream
} from 'entities/livestream/livestream.interface'
import { EnumLiveStreamSource } from 'entities/event/event.interface'
import { setEntity } from 'entities/livestream/livestream.store.reducer'
import { useAppDispatch } from 'config/store'
import helpers from 'helpers'

export const useLivestreamRoomSocket = (idLivestreamRoom: string) => {
  const refSocket = useRef<Socket>(
    io(process.env.REACT_APP_SOCKET_IO_URL, {
      extraHeaders: {
        'X-Authorization': localStorage.getItem('session')
      },
      autoConnect: false
    })
  )

  const refIdLivestreamRoom = useRef<string>()
  const refCountReconnect = useRef<number>(0)
  const refIntervalToCheckSocketConnected = useRef<NodeJS.Timeout>()
  const refIsSocketOk = useRef(false)

  useEffect(() => {
    if (idLivestreamRoom && refCountReconnect.current === 0) {
      console.log('socket ok setup')
      refIdLivestreamRoom.current = idLivestreamRoom
      setupSocket()
    }
  }, [idLivestreamRoom])

  const connectToSocket = useCallback(() => {
    refSocket.current?.connect()

    if (refIntervalToCheckSocketConnected.current)
      clearInterval(refIntervalToCheckSocketConnected.current)

    refIntervalToCheckSocketConnected.current = setInterval(() => {
      console.log('socket ok', refIsSocketOk.current)
      if (refIsSocketOk.current) {
        console.log('clearInterval socket')
        clearInterval(refIntervalToCheckSocketConnected.current)
      } else {
        console.log('call disconnect to reconnect')
        refSocket.current.disconnect()
      }
    }, 5000)
  }, [])

  useEffect(() => {
    return () => {
      clearSocket()
    }
  }, [])

  window.onunload = useCallback(() => {
    clearSocket()
  }, [])

  const clearSocket = useCallback(() => {
    if (refSocket.current) {
      refSocket.current.offAny()
      refSocket.current.disconnect()
    }

    if (refIntervalToCheckSocketConnected.current) {
      clearInterval(refIntervalToCheckSocketConnected.current)
    }
  }, [])

  const setupSocket = useCallback(() => {
    try {
      refSocket.current?.on('connect', () => {
        setTimeout(() => {
          refSocket.current?.emit('joinLivestream', 'livestream_' + refIdLivestreamRoom.current)
        }, 500)
      })

      refSocket.current?.on('joinLivestreamClient', () => {
        console.log('joinLivestreamClient')
        refIsSocketOk.current = true
      })

      refSocket.current?.on('connect_error', () => {
        console.log('connect_error')
        connectToSocket()
      })

      refSocket.current?.on('disconnect', () => {
        console.log('disconnect socket')
        connectToSocket()
      })

      connectToSocket()
    } catch (error) {
      console.log(error)
    }
  }, [])

  return { socket: refSocket.current }
}

const CONFIG_PEER = {
  iceServers: [
    {
      urls: 'stun:stun.cloudflare.com:3478'
    }
  ],
  bundlePolicy: 'max-bundle'
}

export const useLivestreamRoom = (
  liveDetail: TypedLivestream,
  videoElement: React.MutableRefObject<HTMLVideoElement>,
  socket: Socket
) => {
  const refStream = useRef<MediaStream>(new MediaStream())
  const refPeerConnections = useRef<RTCPeerConnection>()
  const [connectionStatus, setConnectionStatus] = useState<RTCPeerConnectionState>()
  const refLiveDetail = useRef<TypedLivestream>(liveDetail)
  const refCallStartOneTime = useRef(false)
  const dispatch = useAppDispatch()
  const refIsAdminOffSource = useRef(false)
  const refIntervalPostOffer = useRef<any>()

  useEffect(() => {
    refLiveDetail.current = liveDetail

    if (liveDetail?.livestream_status !== EnumStatusLivestream.Live) return
    if (liveDetail?.ready_status !== EnumReadyStatusLivestream.Connected) return

    if (liveDetail?.input_type !== EnumLiveStreamSource.OutSide) {
      if (liveDetail?.whep_data && !refCallStartOneTime.current) {
        // lấy livestream
        startWatchLivestream()
      }
    }
  }, [liveDetail])

  useEffect(() => {
    if (liveDetail?.livestream_status === EnumStatusLivestream.Ended) {
      try {
        fetch(refLiveDetail.current?.whep_data, {
          method: 'DELETE',
          mode: 'cors'
        })
      } catch (error) {
        console.log(error, 'ljdfj')
      }

      if (refPeerConnections.current) {
        refPeerConnections.current.ontrack = null
        refPeerConnections.current.onconnectionstatechange = null
        refPeerConnections.current.onnegotiationneeded = null
        refPeerConnections.current?.close()
        refPeerConnections.current = null
        refCallStartOneTime.current = false
      }
    }
  }, [liveDetail?.livestream_status])

  useEffect(() => {
    socket.on('offerClient', data => {
      try {
        if (data) {
          switch (data?.['status']) {
            case EnumCommunicateClient.StopStreamWithOutEndLive:
              {
                setConnectionStatus('disconnected')
              }
              break

            case EnumCommunicateClient.StartStreamSuccess:
              {
                if (refLiveDetail?.current?.input_type === EnumLiveStreamSource.OutSide) {
                  setConnectionStatus('connecting')
                } else {
                  setTimeout(() => {
                    reConnectLivestream()
                  }, 2000)
                }
              }
              break

            case EnumCommunicateClient.UpdateTitleAndCaption:
              {
                dispatch(setEntity({ data: { entity: data?.['livestreamData'] } }))
              }
              break
          }
        }
      } catch (error) {
        console.log(error, 'sljnfjsssbsdf')
      }
    })

    socket.on('leaveRoomToClient', data => {
      console.log('leaveRoomToClient')
      try {
        if(!helpers.isJson(data)) return
        if (typeof JSON.parse(data) === 'object') {
          let dataView = JSON.parse(data)
          if (dataView?.user_id === refLiveDetail?.current?.user_id?._id) {
            setTimeout(() => {
              if (refLiveDetail.current?.livestream_status === EnumStatusLivestream.Live) {
                setConnectionStatus('failed')
              }

              if (liveDetail?.input_type !== EnumLiveStreamSource.OutSide) {
                refCallStartOneTime.current = false
              }
            }, 1000)
          }
        }
      } catch (error) {
        console.log(error, 'sljnfsssjsssbsdf')
      }
    })
  }, [])

  const startWatchLivestream = () => {
    if (refCallStartOneTime.current) return
    refCallStartOneTime.current = true
    /**
     * Create a new WebRTC connection, using public STUN servers with ICE,
     * allowing the client to disover its own IP address.
     * https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Protocols#ice
     */
    refPeerConnections.current = new RTCPeerConnection(CONFIG_PEER as RTCConfiguration)
    /** https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addTransceiver */
    refPeerConnections.current?.addTransceiver('video', {
      direction: 'recvonly'
    })
    refPeerConnections.current?.addTransceiver('audio', {
      direction: 'recvonly'
    })
    /**
     * When new tracks are received in the connection, store local references,
     * so that they can be added to a MediaStream, and to the <video> element.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/track_event
     */
    refPeerConnections.current.ontrack = (event: RTCTrackEvent) => {
      const newTrack = event.track
      console.log(newTrack, 'track from server')

      let tracksToRemove: MediaStreamTrack[]
      if (newTrack.kind === 'video') {
        tracksToRemove = refStream.current.getVideoTracks()
      } else {
        tracksToRemove = refStream.current.getAudioTracks()
      }

      tracksToRemove.forEach((track: MediaStreamTrack) => {
        refStream.current.removeTrack(track)
      })

      refStream.current.addTrack(newTrack)
    }

    refPeerConnections.current?.addEventListener('connectionstatechange', ev => {
      console.log(refPeerConnections.current?.connectionState, 'peerConnection.connectionState')
      setConnectionStatus(refPeerConnections.current?.connectionState)
      if (refPeerConnections.current?.connectionState !== 'connected') {
        if (
          refPeerConnections.current?.connectionState === 'disconnected' &&
          !refIsAdminOffSource.current
        ) {
          reConnectLivestream()
        } else {
          return
        }
      }
      if (videoElement.current) {
        videoElement.current.srcObject = refStream.current
        videoElement.current?.load()
      }
    })

    refPeerConnections.current?.addEventListener('negotiationneeded', ev => {
      console.log('negotiationneeded')
      clearInterval(refIntervalPostOffer.current)
      refIntervalPostOffer.current = null
      negotiateConnectionWithClientOffer()
    })
  }

  const reConnectLivestream = async () => {
    try {
      await fetch(refLiveDetail.current?.whep_data, {
        method: 'DELETE',
        mode: 'cors'
      })
    } catch (error) {
      console.log(error, 'ljdfj')
    }

    if (refPeerConnections.current) {
      refPeerConnections.current.ontrack = null
      refPeerConnections.current.onconnectionstatechange = null
      refPeerConnections.current.onnegotiationneeded = null
      refPeerConnections.current?.close()
      refPeerConnections.current = null
      refCallStartOneTime.current = false
    }
    startWatchLivestream()
  }

  const negotiateConnectionWithClientOffer = async () => {
    /** https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/createOffer */
    const offer = await refPeerConnections.current?.createOffer()
    /** https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/setLocalDescription */
    await refPeerConnections.current?.setLocalDescription(offer)
    /** Wait for ICE gathering to complete */
    let ofr = await waitToCompleteICEGathering()
    if (!ofr) {
      throw Error('failed to gather ICE candidates for offer')
    }
    /**
     * As long as the connection is open, attempt to...
     */
    refIntervalPostOffer.current = setInterval(async () => {
      if (refPeerConnections.current?.connectionState !== 'closed') {
        /**
         * This response contains the server's SDP offer.
         * This specifies how the client should communicate,
         * and what kind of media client and server have negotiated to exchange.
         */
        let response = await postSDPOffer(refLiveDetail.current?.whep_data, ofr.sdp)
        if (response.status === 201) {
          let answerSDP = await response.text()
          await refPeerConnections.current?.setRemoteDescription(
            new RTCSessionDescription({ type: 'answer', sdp: answerSDP })
          )

          clearInterval(refIntervalPostOffer.current)
          refIntervalPostOffer.current = null
          return response.headers.get('Location')
        } else if (response.status === 405) {
          console.error('Update the URL passed into the WHIP or WHEP client')
        } else {
          const errorMessage = await response.text()
          console.error(errorMessage)
        }
      } else {
        clearInterval(refIntervalPostOffer.current)
        refIntervalPostOffer.current = null
      }
    }, 5000)
  }

  const waitToCompleteICEGathering = async (): Promise<any> => {
    return new Promise(resolve => {
      /** Wait at most 1.5 second for ICE gathering. */
      setTimeout(function () {
        resolve(refPeerConnections.current?.localDescription)
      }, 1500)
      refPeerConnections.current.onicegatheringstatechange = ev =>
        refPeerConnections.current?.iceGatheringState === 'complete' &&
        resolve(refPeerConnections.current?.localDescription)
    })
  }

  useEffect(() => {
    return () => {
      disconnectStream()
    }
  }, [])

  window.onunload = useCallback(() => {
    disconnectStream()
  }, [])

  const disconnectStream = () => {
    if (refPeerConnections.current) {
      refPeerConnections.current.ontrack = null
      refPeerConnections.current.onconnectionstatechange = null
      refPeerConnections.current.onnegotiationneeded = null
      refPeerConnections.current?.close()
      refPeerConnections.current = null
    }

    if (refStream.current) {
      refStream.current?.getTracks().forEach(track => track?.stop())
    }
  }

  return { startWatchLivestream, connectionStatus, setConnectionStatus }
}
