//Library
import React, {memo} from 'react'
import { Form} from 'react-bootstrap'
import {FieldDictionary} from '@shopify/react-form'

//Components
import TextField from 'components/TextField'
import OptionGiftMission from './mission.option.gift'

//Interface
import {TypeDataConvert, TypeMissionAction} from '../interface'

//Data
import {optionMission} from '../data'

//Helper
import helpers from 'helpers'
import __ from "languages/index";
import { Box, IconButton } from '@mui/material'
import { TrashAltIcon } from 'icons'

interface TypeDataMission {
  action_name?: string
  action_point?: string
  mission_action?: {
    action_name?: string
    action_point?: string
  }[]
  number_of_day?: string
  option_award?: string
  gift_data?: string
  gift_coin?: string
}
interface TypeProps {
  fulfillmentFields?: FieldDictionary<TypeDataMission>[]
  field?: FieldDictionary<TypeDataMission>
  idx?: number
  validationField?: Function
  handleFocusField?: Function
  isRerender?: boolean
  setIsRerender?: Function
  optionMission?: TypeDataConvert[]
}

const AddListMission = (props: TypeProps) => {
  const {
    fulfillmentFields,
    field,
    idx,
    validationField,
    handleFocusField,
    isRerender,
    setIsRerender
  } = props

  return (
    <div key={idx} className="addMission_body_wrapper">
      <div className="addMission_container_left">
        {fulfillmentFields?.length > 1 && (
          <div className="addMission_container_left_content">
            <span>{+idx + 1}</span>
            <div className="addMission_container_left_after"></div>
          </div>
        )}
      </div>
      <div className="addMission_container_right">
        <div className="addMission_container_right_header">
          <span className="addMission_right_header_title">{`${__("mission_time")} ${+idx + 1}`}</span>
        </div>
        <div className="addMission_container_right_content">
          <div className="addMission_right_content_body">
            <div className="addMission_body_header_wrapper">
              <div className="addMission_body_header_wrapper_left">
                <div>
                  <label className="addMission_body_header_title">{__("mission_title")}</label>
                  <span className="text-danger">&#42;</span>
                </div>
                <Form.Select
                  value={field.action_name?.value}
                  disabled={field.mission_action?.value?.length === optionMission?.length - 1}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>{
                    field.action_name?.onChange(e.target.value)
                    if(e.target.value === "update_profile") {
                      field.action_point?.onChange('1')
                      field.action_point?.setError('')
                    }
                  }}
                  onBlur={() =>
                    !field.mission_action?.value?.length &&
                    validationField(field, 'action_name', true)
                  }
                  onFocus={() => handleFocusField(field, 'action_name')}
                  className={`form-select w-100 ${field.action_name?.error && 'validate_failed'}`}
                >
                  {optionMission?.map((item, idx: number) => (
                    <option
                      hidden={field?.mission_action?.value.some(
                        (data: TypeMissionAction) => data?.action_name === item?.key
                      )}
                      key={idx}
                      disabled={!idx}
                      value={item?.key || ''}
                    >
                      {item?.label || ''}
                    </option>
                  ))}
                </Form.Select>
                {field.action_name?.error && (
                  <small className="form-text text-danger d-block invalid-feedback">
                    {__("mission_chose_task")}
                  </small>
                )}
              </div>
              <div className="addMission_body_header_wrapper_right">
                <div className="addMission_right_content_title">
                  <div>
                    <label className="addMission_body_header_title">{__("mission_quantity")}</label>
                    <span className="text-danger">&#42;</span>
                  </div>
                  <TextField
                    {...field.action_point}
                    label={__("mission_quantity")}
                    labelHidden
                    name="action_point"
                    disabled={(field.mission_action?.value?.length === optionMission?.length - 1) || (field.action_name.value === 'update_profile')}
                    placeholder={__("mission_quantity")}
                    maxLength={10}
                    type="text"
                    value={helpers.parseNumeric(field.action_point?.value)}
                    onBlur={() =>
                      !field.mission_action?.value?.length &&
                      validationField(field, 'action_point', true)
                    }
                    onFocus={() => handleFocusField(field, 'action_point')}
                    error={field.action_point?.error && `${__("mission_quantity_no_empty")}`}
                  />
                </div>
              </div>
              <Box sx={{paddingTop: theme=>theme.spacing(3.75)}} className="addMission_body_header_button">
                <IconButton
                  disabled={field?.mission_action?.value?.length === optionMission?.length - 1}
                  onClick={() => {
                    validationField(field, 'action_name', true)
                    validationField(field, 'action_point', true)
                    if (!field.action_name?.value || !Number(field.action_point?.value)) return
                    field.action_name?.onChange('')
                    field.action_point?.onChange('')
                    field.mission_action?.value.push({
                      action_name: field.action_name?.value,
                      action_point: field.action_point?.value
                    })
                  }}
                  sx={{backgroundColor: theme=>theme.palette.primary.main, borderRadius: theme=>theme.spacing(1), height: theme => theme.spacing(5)}}
                >
                  <i className="bi bi-plus"></i>
                </IconButton>
              </Box>
            </div>
            <div className="addMission_right_list_child">
              <div className="">
                <label className="addMission_body_header_title">{__("mission_list")}</label>
              </div>
              {field.mission_action?.value?.length ? (
                field.mission_action?.value?.map((item: TypeMissionAction, idx: number) => (
                  <div key={idx} className="addMission_right_list_child_item">
                    <Box sx={{'::after': {backgroundColor: theme=>theme.palette.primary.main}}} className="addMission_list_child_item_left">
                      {field.mission_action?.value?.length > 1 && (
                        <Box sx={{backgroundColor: theme=>theme.palette.primary.main}} className="addMission_list_child_item_left_title"></Box>
                      )}
                    </Box>
                    <div className="addMission_list_child_item_right">
                      <div className="d-flex justify-content-between">
                        <span className="addMission_right_header_title">{`${__("mission_title")} ${
                          +idx + 1
                        }:`}</span>
                        <IconButton
                          sx={{backgroundColor: theme=>theme.palette.error.main, borderRadius: theme=>theme.spacing(1), height: theme => `calc(100% - ${theme.spacing(1.5)})`}}
                          onClick={async () => {
                            !isRerender && setIsRerender(true)
                            field.mission_action?.value?.splice(idx, 1)
                            await helpers.sleep(100)
                            setIsRerender(false)
                          }}
                        >
                        <TrashAltIcon
                                sx={{
                                  width: theme => theme.spacing(3),
                                  height: theme => theme.spacing(3)
                                }}
                                color="inherit"
                              />
                        </IconButton>
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        <div>
                          <span className="addMission_right_children_title">{__("mission_detail_name")}</span>
                          <span className="ms-2">
                            {optionMission?.find(data => data?.key === item?.action_name)?.label}
                          </span>
                        </div>
                        <div>
                          <span className="addMission_right_children_title">{__("mission_detail_quantity")}</span>
                          <span className="ms-2">{helpers.parseNumeric(item?.action_point)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <small className="form-text d-block">{__("mission_no_task")}</small>
              )}
            </div>
          </div>

          <div className="addMission_right_info_award_wrapper">
            <div className="addMission_header_right_info_award">
              <div className="addMission_body_header_title_wrapper">
                <label className="addMission_body_header_title">{__("mission_reward")}</label>
                <span className="text-danger">&#42;</span>
              </div>
              <div className="addMission_body_header_option_award">
                <div className="addMission_body_header_option_body">
                  <input
                    type="radio"
                    value="award"
                    id={`award_${idx}`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.option_award?.onChange(e.target.value)
                      field.gift_coin?.reset()
                    }}
                    checked={field.option_award?.value === 'award'}
                  />
                  <label htmlFor={`award_${idx}`}>{__("mission_physical_gifts")}</label>
                </div>
                <div className="addMission_body_header_option_body">
                  <input
                    type="radio"
                    value="coin"
                    id={`coin_${idx}`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      field.option_award?.onChange(e.target.value)
                      field.gift_data?.reset()
                    }}
                    checked={field.option_award?.value === 'coin'}
                  />
                  <label htmlFor={`coin_${idx}`}>{__("mission_gift_diamond")}</label>
                </div>
              </div>
            </div>
            {field.option_award.value === 'coin' && (
              <div className="addMission_right_info_award_coin">
                <div className="addMission_header_right_content_title">
                  <div className="addMission_body_header_title_wrapper">
                    <label className="addMission_body_header_title">{__("mission_quantity_diamond")}</label>
                    <span className="text-danger">&#42;</span>
                  </div>
                  <TextField
                    {...field.gift_coin}
                    label={__("mission_quantity_diamond")}
                    labelHidden
                    maxLength={12}
                    placeholder={__("mission_quantity_diamond")}
                    type="text"
                    value={helpers.formatNumberWithCommas(field.gift_coin?.value)}
                    onBlur={() => validationField(field, 'gift_coin', true)}
                    onFocus={() => handleFocusField(field, 'gift_coin')}
                    error={field.gift_coin?.error && `${__("mission_quantity_diamond_not_empty")}`}
                  />
                </div>
              </div>
            )}
          </div>
          {field.option_award.value === 'award' && (
            <div className="addMission_body_header_option_gift">
              <OptionGiftMission
                onChange={e => {
                  field.gift_data?.onChange(e?._id)
                }}
                onBlurCallback={(e: string) => {
                  validationField(field, 'gift_data', true)
                }}
                onFocusCallback={() => handleFocusField(field, 'gift_data')}
                className={`${field.gift_data?.error && 'validate_failed'}`}
                value={field.gift_data?.value}
                required
              />
              {field.gift_data?.error && (
                <small className="form-text text-danger d-block invalid-feedback">
                  {__("mission_chose_reward_note")}
                </small>
              )}
            </div>
          )}
        </div>
        <div className="addMission_line"></div>
      </div>
    </div>
  )
}

export default memo(AddListMission)
