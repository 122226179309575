//Library
import {useEffect, useState} from 'react'
import {useField, useForm} from '@shopify/react-form'
import {Form} from 'react-bootstrap'
import {AxiosError, AxiosResponse} from 'axios'

//Components
import TextField from 'components/TextField'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import { getDataChannelSetting, updateChannel } from '../store/channelSetting.store.reduce.ts'

//Helpers
import helpers from 'helpers'

//Scss
import '../media/channel.setting.point.scss'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { getDefaultChannel } from 'store/user.store.reducer'
import { Button } from '@mui/material'

interface TypeDataPoint {
  key: string
  value: string
  _id: string
}

const ChannelSettingPoint = () => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [dataPoint, setDataPoint] = useState<TypeDataPoint[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()

  const { fields, submit } = useForm({
    fields: {
      comment: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (Number(inputVal) > 30) {
              return `${__("channel_setting_point_max30")}`
            }
          }
        ]
      }),
      like_comment: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (Number(inputVal) > 30) {
              return `${__("channel_setting_point_max30")}`
            }
          }
        ]
      }),
      like_post: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (Number(inputVal) > 30) {
              return `${__("channel_setting_point_max30")}`
            }
          }
        ]
      }),
      invite_user: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (Number(inputVal) > 30) {
              return `${__("channel_setting_point_max30")}`
            }
          }
        ]
      }),
      post_new: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (Number(inputVal) > 30) {
              return `${__("channel_setting_point_max30")}`
            }
          }
        ]
      }),
      view_course: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (Number(inputVal) > 30) {
              return `${__("channel_setting_point_max30")}`
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const data = [
          { key: 'comment', value: `${values?.comment}` },
          { key: 'like_post', value: `${values?.like_post}` },
          { key: 'view_course', value: `${values?.view_course}` },
          { key: 'like_comment', value: `${values?.like_comment}` },
          { key: 'post_new', value: `${values?.post_new}` },
          { key: 'invite_user', value: `${values?.invite_user}` }
        ]
        const formData = {
          _id: channel_data?._id,
          point_data: JSON.stringify(data)
        }
        setIsLoading(true)
        dispatch(updateChannel(formData))
          .unwrap()
          .then((res: AxiosResponse) => {
            setIsLoading(false)
            setDataPoint(res?.data?.point_data);
            dispatch(getDefaultChannel());
            toast.show({
              content: `${__("channel_setting_update_info_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateChannel_${error}`)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_info_failed")}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  useEffect(() => {
      dispatch(getDataChannelSetting())
        .unwrap()
        .then((res: AxiosResponse) => {
          setDataPoint(res?.data?.[0]?.point_data || [])
        })
        .catch((error: AxiosError) => {
          console.log(`getDataChannel_${error}`)
          setDataPoint([])
          toast.show({
            content: `${__("channel_setting_error")}`,
            type: EnumTypeToast.Error
          })
        })
  }, [])

  useEffect(() => {
    if (helpers.isEmpty(dataPoint)) return
    Object.keys(fields).forEach((item: string) => {
      const valuePoint = dataPoint?.find((data: TypeDataPoint) => data?.key === item)?.value
      fields[item].onChange(valuePoint)
    })
  }, [dataPoint])

  return (
    <div id="channelSettingPoint" className="channelSettingPoint_container">
      <div className="channelSettingPoint_header">
        <h4 className="channelSettingPoint_header_title">{__("channel_setting_point")}</h4>
        <span className="channelSettingPoint_header_desc">
          {__("channel_setting_point_text")}
        </span>
      </div>
      <Form onSubmit={submit} className="channelSettingPoint_form_wrapper">
        <div className="channelSettingPoint_form">
          <div className="channelSettingPoint_form_item">
            <label>{__("channel_setting_point_comment")}</label>
            <TextField
              {...fields.comment}
              autoFocus
              onChange={(value: string) => fields.comment.onChange(helpers.parseNumeric(value))}
              placeholder={__("channel_setting_point_comment")}
            />
          </div>
          <div className="channelSettingPoint_form_item">
            <label>{__("channel_setting_point_like_comment")}</label>
            <TextField
              {...fields.like_comment}
              onChange={(value: string) =>
                fields.like_comment.onChange(helpers.parseNumeric(value))
              }
              placeholder={__("channel_setting_point_like_comment")}
            />
          </div>
          <div className="channelSettingPoint_form_item">
            <label>{__("channel_setting_point_like_post")}</label>
            <TextField
              {...fields.like_post}
              onChange={(value: string) => fields.like_post.onChange(helpers.parseNumeric(value))}
              placeholder="Điểm thích bài viết"
            />
          </div>
          <div className="channelSettingPoint_form_item">
            <label>{__("channel_setting_point_post")}</label>
            <TextField
              {...fields.post_new}
              onChange={(value: string) => fields.post_new.onChange(helpers.parseNumeric(value))}
              placeholder={__("channel_setting_point_post")}
            />
          </div>
          <div className="channelSettingPoint_form_item">
            <label>{__("channel_setting_point_invite_user")}</label>
            <TextField
              {...fields.invite_user}
              onChange={(value: string) => fields.invite_user.onChange(helpers.parseNumeric(value))}
              placeholder={__("channel_setting_point_invite_user")}
            />
          </div>
          <div className="channelSettingPoint_form_item">
            <label>{__("channel_setting_point_watch_course")}</label>
            <TextField
              {...fields.view_course}
              onChange={(value: string) => fields.view_course.onChange(helpers.parseNumeric(value))}
              placeholder={__("channel_setting_point_watch_course")}
            />
          </div>
        </div>
        <div className="channelSettingPoint_button">
          <Button sx={{textTransform: 'none'}} onClick={submit} disabled={isLoading}>
            {isLoading ? `${__("btn_processing")}` : `${__("btn_save")}`}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default ChannelSettingPoint
