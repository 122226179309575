import React from 'react';

import __helpers from 'helpers/index';
import { Breadcrumb } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Theme404 from '../../layout/404';
import AddCard from './add.card';
import AddPayment from './add.payment';
import DetailPayment from './detail.payment';

export default function InfoPayment() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'detail';
  let ActualPage: any;

  switch (Param) {
    case 'payment':
    case 'detail':
      ActualPage = DetailPayment;
      break;
    case 'add':
      ActualPage = AddPayment;
      break;
    case 'card':
      ActualPage = AddCard;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return (
    <>
      <div className="mainFrame">
        <div className="container">
          {<ActualPage />}
        </div>
      </div>
    </>
  )
}
