import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import './media/courses.update.modules.scss'
import {TypedLevel, TypedModule} from "entities/courses/courses.interface";
import {useAppDispatch, useAppSelector} from "config/store";
import {getChildrenModule, getEntity, updateCourse} from "entities/courses/courses.store.reducer";
import {Link, useNavigate, useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CoursesUpdateModulesItem from "entities/courses/components/courses.update.modules.item";
import ModalConfirmCourse from "./components/course.modal.confirm";
import __ from "languages/index";
import Capacity from "components/Capacity";
import helpers from "helpers/index";
import MediumLayout from "../../layouts/mediumLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";


export default function CoursesUpdateModules() {

  let useParam = useParams();
  const dispatch = useAppDispatch();
  const permission_data = useAppSelector(state => state.user.permission_data);
  const user_data = useAppSelector(state => state.user.user_data);
  const listLevel: TypedLevel[] = useAppSelector(state => state.courses.listLevel);
  const childrenModule: TypedModule[] = useAppSelector(state => state.courses.childrenModule);
  const entity = useAppSelector(state => state.courses.entity);
  const loading = useAppSelector(state => state.courses.loading);
  const [modules, setModules] = useState<TypedModule[]>([])
  const toast = useToast()
  const navigate = useNavigate();
  const [modalConfirmData, setModalConfirmData] = useState({
    show: false,
    callback: () => {
    },
    description: "",
    titleKeep: ""
  });
  const [isShowConfirmPublicCourse, setIsShowConfirmPublicCourse] = useState<boolean>(false)
  const sortChildrenModule = [...childrenModule]?.sort((a,b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))

  const handleUpdatePublicCourse = useCallback((result: boolean) => {
    setIsShowConfirmPublicCourse(false)
    if(result){
      dispatch(updateCourse({ _id: useParam?.courses_id,trash_status: 'public'}))
      .unwrap()
      .then((res) => {
        toast.show({
          content:`${__("course_public_success")}`,
          type: EnumTypeToast.Success
        })
        navigate(`/courses/view/${res?.data?._id}`)
      })
      .catch((error) => {
        console.log(`updateCourse_${error}`);
        toast.show({
          content:`${__("course_public_failed")}`,
          type: EnumTypeToast.Error
        })

      })
    }
  },[useParam,isShowConfirmPublicCourse])

  const handleShowModalConfirmPublic = useCallback(() => {
    setIsShowConfirmPublicCourse(true)
  },[])

  useLayoutEffect(() => {
    if(permission_data){
      if(helpers.current_user_can("boss", permission_data)){
        window.scrollTo(0, 0)
        if (useParam.courses_id) {
          dispatch(getEntity({
            _id:useParam.courses_id,
            auth_id: user_data?._id
          }))
          dispatch(getChildrenModule({
            page: 1,
            limit: 1000,
            course_id: useParam.courses_id
          }))
        }
      }
    }
  }, [permission_data]);


  useEffect(() => {
    if (sortChildrenModule && sortChildrenModule?.length) {
      setModules(sortChildrenModule.filter(item => !item.parent_id).map(item => ({
        ...item,
        subModule: sortChildrenModule.filter(itemModule => itemModule.parent_id === item._id)
      })))
    }
  }, [childrenModule]);

  useEffect(()=>{
    if(useParam?.courses_id){
        dispatch(
          getEntity({
            _id: useParam?.courses_id,
            auth_id: user_data?._id || ''
          })
        )
        dispatch(
          getChildrenModule({
            page: 1,
            limit: 1000,
            course_id: useParam?.courses_id,
            auth_id: user_data?._id || ''
          })
        )
    }
  },[])


  const renderItemModule = useCallback((module: TypedModule, index: number) => {

    return (
      <div key={module?._id}>
        <CoursesUpdateModulesItem index={index} setModalConfirmData={setModalConfirmData} key={module?._id} module={module}
                                  isLast={false} alreadyOpen course_id={entity?._id}/>
        {index === modules.length - 1 ?
          <CoursesUpdateModulesItem index={index + 1} isLast setModalConfirmData={setModalConfirmData} course_id={entity?._id}/>
          : null
        }
      </div>
    )
  }, [modules, entity])

  const onDelete = useCallback(() => {
    modalConfirmData?.callback?.()
    setModalConfirmData(old => ({
      ...old,
      show: false
    }))
  }, [modalConfirmData])

  const noAuthUI = ()=>{
    return(
      <div id={"course-update-module"}>
        <div className="container mt-3" >
          <div className="row">
            <div className={`col-md-12`}>

              <div className="courseupdatemodules_viewmodule">

                <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
                  <div className="courseupdatemodules_txtcontent">{__("course_add_content_noauth")}</div>

                  <div className="d-flex">
                    <Link to={"/courses/view/"+useParam.courses_id} className="btn courseupdatemodules_btnview d-flex align-items-center">
                      {__("course_watch_less")}
                    </Link>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <MediumLayout>
      <Capacity current_user_can={'boss'} onFail={noAuthUI}>
        <div id={"course-update-module"}>
          <div className="container mt-3" >
            <div className="row">
              <div className={`col-md-12`}>

                <div className="courseupdatemodules_viewmodule">

                  <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
                    <div className="courseupdatemodules_txtcontent">{__("course_add_content")}</div>

                    <div className="d-flex">
                      <Link to={"/courses/view/"+useParam.courses_id} className="btn courseupdatemodules_btnview d-flex align-items-center">
                        {__("course_watch_less")}
                      </Link>
                      {entity?.trash_status === 'trash' && <Button variant="primary" onClick={handleShowModalConfirmPublic} className="ms-2 fw-semibold button-event">{__("course_update_public")}</Button>}
                    </div>
                  </div>

                  {
                    Array.isArray(sortChildrenModule) && sortChildrenModule?.length > 0 ?
                      <>
                        {/*<div*/}
                        {/*  className="courseview_txtnumofmodule">{(entity?.module_count - entity?.module_child_count) || "0"} phần*/}
                        {/*  - {entity?.module_child_count || "0"} bài giảng*/}
                        {/*</div>*/}

                        <div>
                          {
                            modules.map(renderItemModule)
                          }
                        </div>
                      </>
                      :
                      <CoursesUpdateModulesItem index={0} isLast setModalConfirmData={setModalConfirmData} course_id={entity?._id}/>
                  }
                </div>

              </div>
            </div>
          </div>

          {isShowConfirmPublicCourse &&
            <ModalConfirmCourse show={isShowConfirmPublicCourse} title={__("course_update_modal_confirm_title")} onClose={handleUpdatePublicCourse}>
              <p>{__("course_update_modal_confirm_content")}</p>
            </ModalConfirmCourse>}

          <Modal show={modalConfirmData?.show} onHide={() => setModalConfirmData(old => ({...old, show: false}))}>
            <Modal.Header closeButton>
              <Modal.Title>{__("course_modal_delete_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalConfirmData?.description}</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={onDelete}>
                {__("course_modal_btn_delete")}
              </Button>
              <Button variant="primary" onClick={() => setModalConfirmData(old => ({...old, show: false}))}>
                {modalConfirmData?.titleKeep}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Capacity>
    </MediumLayout>

  )
}
