import { EnumPointDataKey } from 'constant/index'
import __ from 'languages/index'

export const pointConfigs = {
  [EnumPointDataKey.Comment]: __('channel_setting_point_comment'),
  [EnumPointDataKey.LikeComment]: __('channel_setting_point_like_comment'),
  [EnumPointDataKey.LikePost]: __('channel_setting_point_like_post'),
  [EnumPointDataKey.PostNew]: __('channel_setting_point_post'),
  [EnumPointDataKey.ViewCourse]: __('channel_setting_point_watch_course'),
  [EnumPointDataKey.InviteUser]: __('channel_setting_point_invite_user')
}
