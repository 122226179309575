import { SvgIcon, SvgIconProps } from '@mui/material'

const Filter3LineIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Essentials/settings/3">
                    <path id="Icon (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5ZM15.1707 5C15.5825 3.83481 16.6938 3 18 3C19.6569 3 21 4.34315 21 6C21 7.65685 19.6569 9 18 9C16.6938 9 15.5825 8.16519 15.1707 7H4C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5H15.1707ZM6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14C6.55228 14 7 13.5523 7 13C7 12.4477 6.55228 12 6 12ZM3 13C3 11.3431 4.34315 10 6 10C7.30622 10 8.41746 10.8348 8.82929 12H20C20.5523 12 21 12.4477 21 13C21 13.5523 20.5523 14 20 14H8.82929C8.41746 15.1652 7.30622 16 6 16C4.34315 16 3 14.6569 3 13ZM18 19C17.4477 19 17 19.4477 17 20C17 20.5523 17.4477 21 18 21C18.5523 21 19 20.5523 19 20C19 19.4477 18.5523 19 18 19ZM15.1707 19C15.5825 17.8348 16.6938 17 18 17C19.6569 17 21 18.3431 21 20C21 21.6569 19.6569 23 18 23C16.6938 23 15.5825 22.1652 15.1707 21H4C3.44772 21 3 20.5523 3 20C3 19.4477 3.44772 19 4 19H15.1707Z" fill="#0E1426" />
                    <circle id="Ellipse 9" cx="19.001" cy="5.99902" r="4.99902" fill="#DC3545" stroke="white" stroke-width="2" />
                </g>
            </svg>

        </SvgIcon>
    )
}

export default Filter3LineIcon
