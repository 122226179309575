import React from 'react'
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  ListItemButton,
  SxProps
} from '@mui/material'
import { channelSettingsRoutes } from '../channelSettingsRoutes'
import { CurrentTheme } from 'styles/themes'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

interface ListItemLinkProps {
  icon?: React.ReactElement
  primary: string
  to: string
}

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />
})

const ListItemLink = ({ icon, primary, to }: ListItemLinkProps) => {
  return (
    <ListItemButton sx={listItemButtonStyled}>
      <ListItem component={Link} to={to}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </ListItemButton>
  )
}

const ChannelSettingSidebar = () => {
  return (
    <Paper>
      <List
        dense
        sx={{
          paddingX: 1
        }}
      >
        {channelSettingsRoutes.map(route => (
          <ListItemLink to={route.link} primary={route.label} icon={<route.icon />} />
        ))}
      </List>
    </Paper>
  )
}

export default ChannelSettingSidebar

const listItemButtonStyled: SxProps<CurrentTheme> = {
  borderRadius: theme => theme.spacing(1),
  '& > a': {
    textDecoration: 'none',
    color: 'inherit'
  }
}
