import {Box, Skeleton} from "@mui/material";
import React from "react";

export default function DefaultSkeleton() {
  return (
    <Box sx={{width: '100%'}}>
      <Skeleton/>
      <Skeleton animation="wave"/>
      <Skeleton animation={false}/>
    </Box>
  );
}
