import React from 'react'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import {styled} from '@mui/material'

const SmallLayout = ({children}: { children: React.ReactNode }) => {

  return (
    <SmallLayoutContainer container columns={10} spacing={3}>
      <Grid className='column' xs={1}></Grid>
      <Grid className='content' xs={5}>{children}</Grid>
      <Grid className='column' xs={4}></Grid>
    </SmallLayoutContainer>
  )
}

export default SmallLayout
const SmallLayoutContainer = styled(Grid)(({theme}) => ({
  '& .right_bar': {
    height: `calc(100vh - ${theme.spacing(12.5)})`,
    overflow: 'auto',
    transition: `padding-right 0.3s ease`,
    '::-webkit-scrollbar': {
      display: 'none'
    },
  },

  '& .filter_icon': {
    margin: theme.spacing(0, 1)
  },
  '@media (max-width: 1024px)':{
    '& .column': {
      display: 'none'
    },
    '& .content': {
      width: '100%'
    }
  }
}))
