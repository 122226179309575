import React, { ChangeEvent, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { ListGroup, Stack } from 'react-bootstrap'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import RenderView from 'components/RenderView'
import IconButton from './IconButton'

type Props<T> = {
  options: T[]

  renderOption?: (option: T) => React.ReactNode
  getOptionLabel: (option: T) => string
  getOptionValue?: string
  isLoading?: boolean
  value: string
  onChange?: (option: T) => void
  inputValue?: string
  onInputChange?: (value: string) => void
}

const Combobox = <T,>({
  options,
  isLoading,
  value,
  onChange,
  getOptionLabel,
  renderOption,
  inputValue,
  onInputChange
}: Props<T>) => {
  const [showList, setShowList] = useState<boolean>(false)

  const toggleList = () => {
    setShowList(prevState => !prevState)
  }

  const handleChange = (option: T) => {
    if (getOptionLabel) {
      onInputChange(getOptionLabel(option))
    }
    //Todo: handle when getOptionLabel is undefined or renderOption is defined
    onChange(option)
    setShowList(false)
  }

  const closeList = () => {
    setShowList(false)
  }

  const openList = () => {
    setShowList(true)
  }

  const _renderOptionDefault = (option: T) => {
    return (
      <ListGroup.Item
        key={getOptionLabel(option)}
        className={'border-0 bg-transparent'}
        onClick={() => handleChange(option)}
      >
        {getOptionLabel(option)}
      </ListGroup.Item>
    )
  }

  return (
    <div className="position-relative">
      <div>
        <Stack direction={'horizontal'} className={'border rounded-3 bg-white'}>
          <input
            style={{
              outline: 'none'
            }}
            type="text"
            className={'border-0 outline-0 w-100 h-100 bg-transparent px-4 py-2'}
            onChange={e => onInputChange(e.target.value)}
            onFocus={openList}
            value={inputValue}
          />
          <IconButton variant="none" icon={faChevronDown} onClick={toggleList} />
        </Stack>
        {showList && (
          <RenderView
            isLoading={isLoading}
            isEmpty={options?.length === 0}
            view={
              <ListGroup
                role="button"
                className="border rounded-2 shadow overflow-auto position-absolute w-100 bg-white"
                style={{
                  height: '200px'
                }}
              >
                {!isLoading &&
                  options?.map((option, index) =>
                    renderOption ? (
                      <ListGroup.Item onClick={() => handleChange(option)} key={index}>
                        {renderOption(option)}
                      </ListGroup.Item>
                    ) : (
                      _renderOptionDefault(option)
                    )
                  )}
              </ListGroup>
            }
          />
        )}
      </div>
    </div>
  )
}

export default Combobox
