import axios from 'axios'
import { TypedGift, TypedGiftTransaction, TypedUser } from 'entities/gift/gift.interface'
import helpers from 'helpers/index'
import { TypedDataMember } from '../../../interfaces/member.interface'
import { EnumGiftSendStatusType } from 'entities/gift/constants/gift.constant'

/**
 * @param giftId
 */

export async function getReceivers(giftId: string): Promise<TypedGiftTransaction[]> {
  try {
    let response = await axios.get<{ user_id: TypedUser; gift_id: TypedGift }>(
      `gift/list-user?order_type=time&order_by=DESC&gift_id=${giftId}`
    )
    if (Array.isArray(response.data)) {
      return response.data
    } else {
      return []
    }
  } catch (error) {
    return []
  }
}

export async function getBannerGift(): Promise<string | undefined> {
  try {
    let response = await axios.get(
      'channel/list-banner?page=1&limit=1&order_by=DESC&banner_type=gift'
    )
    if (response.data?.[0]?.media_id?.media_url) {
      return response.data?.[0]?.media_id?.media_url
    } else {
      return undefined
    }
  } catch (error) {
    return undefined
  }
}

export async function updateBannerGift(channelId: string, mediaId: string): Promise<boolean> {
  try {
    await axios.post('channel/create-banner', {
      channel_id: channelId,
      banner_url: '',
      banner_type: 'gift',
      media_id: mediaId
    })
    return true
  } catch (error) {
    return false
  }
}

export function getReceiversForTab(param: object) {
  return axios.get<TypedGiftTransaction[]>(`gift/list-user${helpers.buildEndUrl(param)}`)
}

export function getListGifts(param: object) {
  return axios.get<TypedGift[]>(`gift/list-gift${helpers.buildEndUrl(param)}`)
}

export function getListMembers(param: object) {
  return axios.get<TypedDataMember[]>(`channel/list-members${helpers.buildEndUrl(param)}`)
}

export function postAddUserToCourse(param: object) {
  let url = `course/add-user`
  return axios.post<never[]>(url, param)
}

export function postDeleteUserToCourse(param: object) {
  let url = `course/un-join`
  return axios.post<never[]>(url, param)
}

export async function sendGiftsToUsers(data: object): Promise<boolean> {
  try {
    let response = await axios.post(`gift/give-gift`, data)
    return !!Array.isArray(response.data)
  } catch (error) {
    return false
  }
}

export async function updateStatusSendGift(
  status: EnumGiftSendStatusType,
  idSendGift: string
): Promise<boolean> {
  try {
    let response = await axios.patch(`gift/update-user-gift`, {
      _id: idSendGift,
      gift_status: status
    })
    return response.status === 200
  } catch (error) {
    return false
  }
}

export const getDetailGift = (id: string) => {
  return axios.get<TypedGift>(`/gift/detail-gift/${id}`)
}

export const getListUserReceivedGift = (giftId: string) => {
  return axios.get<TypedGiftTransaction[]>(`gift/list-user`, {
    params: {
      order_by: 'DESC',
      order_type: 'time',
      gift_id: giftId
    }
  })
}
