import {useCallback, useEffect, useRef} from "react";
import LivestreamPreviewDeviceDragAndDrop from "./livestream.preview.device.dragAndDrop";
import {EnumLiveStreamSource, ErrorMediaType} from "entities/event/event.interface";
import {ID_VIDEO_ADMIN_STUDIO} from "entities/livestream/livestream.admin.constant";
import {TypedMediaResponse} from "../../../interfaces/media.interface";
import {setupStreamFromFile} from "entities/livestream/services/stream.service";
import {
  setIdAudioDeviceSelectedForLivestream,
  setIsAlreadyPlayLiveFromFile,
  updateEntity
} from "entities/livestream/livestream.store.reducer";
import {useAppDispatch, useAppSelector} from "config/store";
import {EnumTypeInputSound} from "entities/livestream/hooks/livestream.admin.hook";
import {Form} from "react-bootstrap";
import __ from "languages/index";
import {useParams} from "react-router-dom";


interface TypedEventSetupLivestreamFileProps {
  setAudioTrack?: (audioTrack: MediaStreamTrack, type: EnumTypeInputSound) => void
}

export default function LivestreamPreviewDeviceFile({setAudioTrack}: TypedEventSetupLivestreamFileProps) {
  const liveDetail = useAppSelector(state => state.livestream.entity);
  const isAlreadyPlayLiveFromFile = useAppSelector(state => state.livestream.isAlreadyPlayLiveFromFile);
  const audioSourceError = useAppSelector(state => state.livestream.audioSourceError);
  const audioSourceList = useAppSelector(state => state.livestream.audioSourceList);
  const dispatch = useAppDispatch();
  const refIsAlreadyPlayLiveFromFile = useRef(isAlreadyPlayLiveFromFile)
  const params = useParams()

  const idAudioDeviceSelectedForLivestream = useAppSelector(state => state.livestream.idAudioDeviceSelectedForLivestream);

  useEffect(() => {
    if (liveDetail?.input_type === EnumLiveStreamSource.File && liveDetail?.media_id && !refIsAlreadyPlayLiveFromFile.current) {
      setupSourceVideo();
    }
  }, [liveDetail?.input_type, liveDetail?.media_id]);

  const setupSourceVideo = useCallback(() => {
    if (!liveDetail?.media_id) return;
    setupStreamFromFile(ID_VIDEO_ADMIN_STUDIO, liveDetail?.media_id?.media_url, setAudioTrack);
    dispatch(setIsAlreadyPlayLiveFromFile(true))
  }, [liveDetail?.media_id])

  const callbackSetUrl = useCallback((media: TypedMediaResponse) => {
    if (media) {
      refIsAlreadyPlayLiveFromFile.current = false;
      const videoElement = document.getElementById(ID_VIDEO_ADMIN_STUDIO) as any;
      videoElement.muted = false;
      dispatch(updateEntity({_id: liveDetail?._id || params?.liveStreamId, input_type: EnumLiveStreamSource.File, media_id: media.callback?._id}))
    }
  }, [])

  const onChangeAudioSource = useCallback((e: any) => {
    dispatch(setIdAudioDeviceSelectedForLivestream(e.target.value))
  }, [])

  return (
    <div className="livestreamSelection">
      {
        liveDetail?.media_id && (
          <div className="mb-3"> {__("livestream_source_play")} <b><a href={liveDetail?.media_id?.media_url} target="_blank"
                                                               className="text-white"> {__("livestream_click_to_watch")}</a></b></div>
        )
      }
      <div className="livestreamSelection_note mb-2">
        {__("livestream_preview_note")}
      </div>
      <LivestreamPreviewDeviceDragAndDrop callbackSetMedia={callbackSetUrl}/>

      <div className="select">
        <label htmlFor="audioSource" className="mt-3 mb-1">{__("livestream_source_mic")} </label>
        {
          audioSourceError ?
            (
              audioSourceError === ErrorMediaType.Permission ?
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_permission_reject")}
                </h6>
                :
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_not_found_mic")}
                </h6>
            )
            :
            <Form.Select id="audioSource"
                         value={idAudioDeviceSelectedForLivestream || undefined}
                         onChange={onChangeAudioSource}>
              {
                audioSourceList?.map((e, index) => {
                  return <option key={index + '_dgsdg'} value={`${e.deviceId}`}>{e.label}</option>
                })
              }
            </Form.Select>
        }
      </div>
    </div>
  )
}
