import React, { useCallback, useEffect, useState } from 'react'
import {
  Box, IconButton,
  Stack,
  styled,
  Tooltip,
  Typography
} from '@mui/material'
import __ from 'languages/index'
import {
  DataGrid,
  GridColDef
} from '@mui/x-data-grid'
import { TypedStock } from 'entities/shop/interface'
import { formatString } from 'entities/support/utils'
import StrokeIcon from '../../../icons/StrokeIcon'
import { TrashAltIcon } from '../../../icons'
import DeliveryBoxIcon from '../../../icons/DeliveryBoxIcon'
import ShippingUnitIcon from '../../../icons/BlockIcon copy'
import { useNavigate } from 'react-router-dom'
import ConfirmModal from 'components/ConfirmModal'
import NotificationModal from 'entities/shop/components/NotificationModal'
import { deleteStock } from 'entities/shop/store/shop.store.reducer'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { AxiosError } from 'axios'
import { useAppDispatch } from 'config/store'
import __helpers from 'helpers/index'
import CustomPagination from 'entities/shop/components/CustomPagination'
import { IQueryParams } from 'config/reducer.utils'

interface TypeListStockTable {
  listStock: TypedStock[]
  totalStock: number
  setIsShowAddStockroom: React.Dispatch<React.SetStateAction<boolean>>
  setStockDetail: React.Dispatch<React.SetStateAction<TypedStock>>
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
  setIsDelete: React.Dispatch<React.SetStateAction<boolean>>
  setParamRequest: React.Dispatch<React.SetStateAction<IQueryParams>>
  paramRequest: IQueryParams,
}

const ListStockTable = ({ listStock, setIsShowAddStockroom, paramRequest, setStockDetail, setIsEdit, setIsDelete, totalStock, setParamRequest }: TypeListStockTable) => {
  const [stockId, setStockId] = useState<string>('')
  const [isShowModalNotification, setIsShowModalNotification] = useState<boolean>(false)
  const [selected, setSelected] = useState(1);
  const dispatch = useAppDispatch()
  const toast = useToast()


  const navigate = useNavigate()

  // const PAGE_SIZE = 5;
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: paramRequest.limit,
    page: 0,
  });

  const handleCloseDeleteStockModal = useCallback(() => {
    setStockId('')
  }, [])
  const handleCloseModalNotification = useCallback(() => {
    setIsShowModalNotification(false)
    setIsDelete(false)
  }, [])

  const handleClickEdit = useCallback((item) => {
    if (item?.field !== 'action') {
      setIsShowAddStockroom(true)
      setStockDetail(item?.row)
      setIsEdit(true)
    }
  }, [listStock])

  const handleDeleteStockModal = useCallback(async () => {
    await dispatch(deleteStock(stockId))
      .unwrap()
      .then((res) => {
        // refresh(true)
        setIsDelete(true)
        toast.show({
          content: `${__("delete_stockroom_success")}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((e: AxiosError) => {
        console.log('deleteStock', e);
        toast.show({
          content: `${__("delete_stockroom_failed")}`,
          type: EnumTypeToast.Error
        })
      })
    handleCloseDeleteStockModal()
  }, [stockId])

  useEffect(() => {
    setParamRequest(oldParam => ({ ...oldParam, page: selected }))
  }, [selected])

  const columns: GridColDef[] = [
    {
      field: "stock_name",
      headerName: `${__("stock_name")}`,
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        <Stack direction={'column'} alignItems='start' >
          <Typography color={'text.primary'} variant={'subtitle2'}>
            {__helpers.getTrimContent(`${params?.row.stock_name}`, 30)}
          </Typography>
          <Typography color={'text.secondary'} variant={'body1'}>
            {formatString(__('table_stock_code'), params?.row.stock_code)}
          </Typography>
        </Stack>
    },

    {
      field: "stock_address",
      headerName: `${__("stock_address")}`,
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <Box>
            <Typography
              fontWeight={500}
              variant={'subtitle2'}
              whiteSpace={'nowrap'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              textAlign={'start'}
              sx={{
                maxWidth: theme => theme.spacing(20),
              }}
            >
              <StrokeIcon
                color="inherit"
                sx={{
                  width: 'auto',
                  height: 'auto',
                  marginRight: theme => theme.spacing(1.25)
                }} />
              {!!params?.row.stock_address_district_name ? `${params?.row.stock_address_district_name},` : ''}
              {!!params?.row.stock_address_city_name ? `${params?.row.stock_address_city_name}` : ''}
            </Typography>
            {params?.row.is_default_stock === 1 && (
              <Typography
                variant="body2"
                color={'primary.main'}
                paddingTop={theme => theme.spacing(1)}
                fontWeight={500}
              >
                {__("stock_default")}
              </Typography>
            )}
          </Box>
        )
      }
    },
    {
      field: 'action',
      headerName: `${__("stock_action")}`,
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const handleClickDelete = useCallback((item: TypedStock) => {
          if (item?.stock_count <= 0) {
            setStockId(item?.stock_id)
          } else {
            setIsShowModalNotification(true)
          }
        }, [])
        const handleClickDeliveryBox = (stock_id: string) => {
          navigate(`/shop/admin/stock/${stock_id}#stockroom`)
        }
        const handleClickShippingUnit = (stock_id: string) => {
          navigate(`/shop/admin/stock/${stock_id}/shippingunits#stockroom`)
        }

        return (
          <Box display={'flex'} columnGap={theme => theme.spacing(2)}>
            <Tooltip title={__('delete_stock')}>
              <IconButton
                className="icon_button"
                onClick={() => handleClickDelete(params?.row)}
              >
                <TrashAltIcon
                  sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                  color="inherit"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={__('add_product_to_stock')}>
              <IconButton
                onClick={() => handleClickDeliveryBox(params?.row?.stock_id)}
                className="icon_button edit"
              >
                <DeliveryBoxIcon
                  sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                  color="inherit"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={__('config_logistic_stock')}>

              <IconButton
                onClick={() => handleClickShippingUnit(params?.row?.stock_id)}
                className="icon_button edit"
              >
                <ShippingUnitIcon
                  sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                  color="inherit"
                />
              </IconButton>
            </Tooltip>

          </Box>
        )
      }

    },
  ]

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setSelected(value)
  }


  return (
    <CustomTableContainer>
      <Box>
        <DataGrid
          {...listStock}
          rowHeight={100}
          showCellVerticalBorder={false}
          rows={listStock}
          disableColumnMenu
          rowSpacingType={'margin'}
          columns={columns}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: paramRequest.limit,
              },
            },
          }}
          slots={{
            pagination: () => <CustomPagination handleChangePage={handleChangePage} page={paramRequest.page ?? selected} count={Math.ceil(totalStock / paginationModel.pageSize)} />,
          }}
          onCellClick={(value) => handleClickEdit(value)}
          onPaginationModelChange={setPaginationModel}
          // pageSizeOptions={[5]}
          paginationModel={paginationModel}
          getRowId={(row) => row.stock_id}
          sx={{
            backgroundColor: theme => theme.palette.background.paper,
            borderRadius: theme => theme.spacing(1.5),
            boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
            '& .MuiDataGrid-cell': {
              height: '100px',
            },
          }}
        />
      </Box>

      {Boolean(!!stockId) && <ConfirmModal
        onClose={handleCloseDeleteStockModal}
        open={Boolean(!!stockId)}
        onConfirm={handleDeleteStockModal}
        title={__('delete_stock_title')}
        confirmTextBtn={__('btn_delete')}
        confirmText={__('delete_stockroom_confirm')}
      />}

      {isShowModalNotification &&
        <NotificationModal
          handleCloseModalNotification={handleCloseModalNotification}
          isShowModalNotification={isShowModalNotification}
          textContent={`${__("modal_notification_content")}`}
        />
      }
    </CustomTableContainer>
  )
}
export default ListStockTable

export const CustomTableContainer = styled(Box)(({ theme }) => ({
  '& .MuiDataGrid-main': {
    padding: theme.spacing(2),
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.background.secondary,
    // margin: theme.spacing(0,2),
    borderRadius: theme.spacing(1.5)
  },
  '& .super-app-theme--header:hover': {
    '& .MuiDataGrid-menuIcon': {
      visibility: 'hidden',
    },
  },
  '& .MuiDataGrid-root': {
    boxShadow: 'none',
    borderRadius: 0,
    borderColor: 'transparent',
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within,& .MuiDataGrid-cell:focus-visible, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-columnHeader:focus-visible, & .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
      outline: 'none',
      outlineWidth: 0,
      outlineOffset: 0
    }
  },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
    display: 'flex',
    visibility: 'visible',
    width: 'auto'

  },
  '& .MuiDataGrid-iconSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-root .MuiDataGrid-withBorderColor': {
    borderColor: theme.palette.divider,
    // padding: theme.spacing(2,2,2,0)
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }

}))
