import React from 'react'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import QuickViewRanking from 'components/QuickViewRanking'
import { useGetRankingQuery } from 'entities/ranking/store/rankingApi'
import RenderView from 'components/RenderView'
import ChannelOverview from 'entities/homeNew/components/ChannelOverview'
import Container from '@mui/material/Container'
import { Box, Paper, Typography, styled } from '@mui/material'
import MissionViewComponent from 'entities/homeNew/components/MissionViewComponent'
import EventRightBarComponent from 'entities/homeNew/components/EventRightBarComponent'
import Capacity from 'components/Capacity'
import { Link } from 'react-router-dom'
import { AppsAdd } from 'icons'
import __ from 'languages/index'
import { GAMIFA_CHANNEL_HOST } from 'constant/index'
import { useAppSelector } from 'config/store'
import helpers from 'helpers'
const HomeLayout = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading } = useGetRankingQuery({
    page: 1,
    limit: 10,
    order_type: 'point',
    order_by: 'DESC'
  })
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in);
  const isCreatedChannel = useAppSelector(state => state.user.isCreatedChannel)
  const rankingList = data?.data
  const members = data?.totalCount
  const checkMobile = helpers.isMobile();
  return (
    <HomeLayoutContainer container columns={10} spacing={3}>
      {!checkMobile && (
        <Grid xs={1}></Grid>
      )}
      <Grid sx={{
        padding: theme => checkMobile ? 0 : theme.spacing(2, 2)
      }} xs={checkMobile ? 10 : 5}>{children}</Grid>
      {!checkMobile && (
        <>
          <Grid xs={1}></Grid>

          <Grid xs={3}>
            <Box
              sx={{
                position: 'sticky',
                top: theme => theme.spacing(12)
              }}
            >
              <Box className="right_bar" sx={{

              }}>
                <Capacity current_user_can={'boss'} onFail={() => (
                  <MissionViewComponent />
                )}>
                  <></>
                </Capacity>
                <EventRightBarComponent />
                <ChannelOverview members={members} />
                <RenderView
                  view={<QuickViewRanking rankingList={rankingList} isAuth={is_user_logged_in} />}
                  isLoading={isLoading}
                  isEmpty={rankingList?.length === 0}
                />
              </Box>
              {(GAMIFA_CHANNEL_HOST.includes(window.location.host) && !isCreatedChannel) && (
                <Link to="/channel/create" className='link'>
                  <Paper
                    sx={{
                      p: theme => theme.spacing(1.75, 4),
                      background: theme => theme.palette.primary.dark,
                      borderRadius: theme => theme.spacing(1.5, 1.5, 0, 0),
                      display: 'flex',
                      columnGap: theme => theme.spacing(1),
                      alignItems: 'center'
                    }}>
                    <AppsAdd sx={{
                      color: theme => theme.palette.background.paper,
                      width: theme => theme.spacing(3),
                      height: theme => theme.spacing(3),
                    }} />
                    <Typography textTransform={'none'} fontWeight={600} color={'background.paper'}>{__('header_create_channel')}</Typography>
                  </Paper>
                </Link>
              )}
            </Box>
          </Grid>
        </>
      )}
    </HomeLayoutContainer>
  )
}

export default HomeLayout
const HomeLayoutContainer = styled(Grid)(({ theme }) => ({
  '& .right_bar': {
    height: `calc(100vh - ${theme.spacing(12.5)})`,
    overflow: 'auto',
    transition: `padding-right 0.3s ease`,
    '::-webkit-scrollbar': {
      display: 'none'
    },
  },
  '& .link':{
    position: 'absolute',
    zIndex: 10,
    bottom: theme.spacing(-0.5),
    right: theme.spacing(3)
  }
}))
