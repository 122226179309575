import { TypedChallengeDetail } from 'entities/challenge/challenge.interface'
import React, { useCallback } from 'react'
import { Table } from 'react-bootstrap'
import Parser from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldHalved, faUser } from '@fortawesome/free-solid-svg-icons'
import ChallengeTimeline, { TypedTimeline } from '../ChallengeTimeline'
import __ from "languages/index";
import { Box, Typography, styled } from '@mui/material'

interface Props {
  detail: TypedChallengeDetail
}

const ChallengeInformation = (props: Props) => {
  const { detail } = props

  // Function
  const renderContextCondition = useCallback(() => {
    let context = ''
    if (detail?.coin_value == 0 && detail?.level_value == 1) {
      context = `${__("challenge_detail_tournaments_public")}`
    }
    if (detail?.coin_value > 0 && detail?.level_value == 1) {
      context = `${__("challenge_detail_tournaments_for_point")}` + detail?.coin_value
    }
    if (detail?.coin_value == 0 && detail?.level_value > 1) {
      context = `${__("challenge_detail_tournaments_for_level")}` + detail?.level_value
    }
    return context
  }, [detail?.level_value, detail?.coin_value])
  // ========================================================

  // render view

  const renderChallengeInfo = useCallback(() => {
    return (
      <>
        {detail?.max_user > 0 && (
          <div className="mb-3 w-100">
            <Typography variant='subtitle2' color={'black'} className="fs-5 mb-1">{__("challenge_information_title")}</Typography>
            <div className="mb-2">
              <FontAwesomeIcon icon={faUser} /> {__("challenge_information_max_participants")}{' '}
              {detail?.max_user.toLocaleString('es-US')} {__("challenge_information_unit")}
            </div>
          </div>
        )}
        <div className="mb-3 w-100">
          <Typography variant='subtitle2' color={'black'} className="fs-5 mb-1">{__("challenge_information_condition_join")}</Typography>
          <div className="mb-1">
            <FontAwesomeIcon icon={faShieldHalved} /> {renderContextCondition()}
          </div>
        </div>
      </>
    )
  }, [detail?.start_time, detail?.end_time, detail?.level_value, detail?.coin_value])

  const renderDetailChallenge = useCallback(() => {
    return (
      <Box className="mb-3 w-100">
        <Typography variant='subtitle2' color={'black'} className="fs-5 mb-1">{__('challenge_information_introduce')}</Typography>
        <div className="mb-1"></div>
        {detail?.long_description && (
          <div className="mb-2 text-break challenge__detail_challenge">
            {Parser(detail?.long_description ? detail?.long_description : '')}
          </div>
        )}
      </Box>
    )
  }, [detail?.long_description])

  const renderGift = useCallback(() => {
    return (
      <div className="w-100">
        <Typography variant='subtitle2' color={'black'} className="fs-5 mb-2">{__("challenge_information_reward")}</Typography>
        {detail?.gift_data?.length > 0 ? (
          <Box className="gifts">
            {
              detail?.gift_data.map((item, index) => {
                return (
                  <Box key={item._id} className="item_gift">
                    <img
                      className="challenge_gift_avatar"
                      src={item?.media_id?.media_thumbnail || item?.media_id?.media_url}
                    />
                    <Box className="info_gift">
                      <Typography variant='subtitle2'>{item?.name}</Typography>
                      <Typography sx={{
                        color: '#DC3545'
                      }}>{item?.price.toLocaleString('en-US')} {__("order_unit")}</Typography>
                      <Typography>Còn lại: {item?.stock_qty}</Typography>
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
        ) : (
          <div className="mb-3">{__("challenge_information_no_reward")}</div>
        )}
      </div>
    )
  }, [detail?.gift_data])

  const renderChallengeStage = useCallback(() => {
    if (detail?.challenge_stage?.length > 0) {
      const data: TypedTimeline[] = detail?.challenge_stage.map(item => {
        let newItem: TypedTimeline = {
          _id: item?._id,
          // startDate: new Date(item?.start_time).getTime() / 1000,
          // endDate: new Date(item?.end_time).getTime() / 1000,
          startDate: item?.start_time,
          endDate: item?.end_time,
          title: item?.name,
          detail: [{ description: item?.description, gifts: item?.gift_data, id: item._id }]
        }
        return newItem
      })
      return <ChallengeTimeline timeline={data} />
    }
    return <div className="mb-3">{__("challenge_detail_no_race")}</div>
  }, [detail?.challenge_stage])

  // ========================================================

  return (
    <ChallengeInformationContainer margin={theme => theme.spacing(2, 0)} className="w-100">
      {renderDetailChallenge()}
      {renderChallengeInfo()}
      <div>
        <Typography variant='subtitle2' color={'black'} className="fs-5 mb-2">{__("challenge_information_race")}</Typography>
        {renderChallengeStage()}
      </div>
      {renderGift()}
    </ChallengeInformationContainer>
  )
}

export default ChallengeInformation

const ChallengeInformationContainer = styled(Box)(({ theme }) => ({
  '& .gifts': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: theme.spacing(1),

    '& .item_gift': {
      boxShadow: '0px 2px 4px 0px #0E142614',
      borderRadius: theme.spacing(1.5),

      '& .challenge_gift_avatar': {
        height: '167px',
        borderTopLeftRadius: theme.spacing(1.5),
        borderTopRightRadius: theme.spacing(1.5),
        width: '100%',
        objectFit: 'cover'
      },

      '& .info_gift': {
        padding: theme.spacing(2),
   
        '& h6':{
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          height: '45px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontWeight: 'bold',
          display: '-webkit-box'
        },

        '& p': {
          marginTop: theme.spacing(1),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }
      }
    }
  }

})) 
