/********************************************** *
 * list of all functions include:
 * isSomething => check if is something
 * parseSomething => Parse something ...
 * getSomething => Get Something ...
 ********************************************** */

import helper_is from './is'
import helper_get from './get'
import helper_parse from './parse'

import dateandtime from 'date-and-time'
import __ from 'languages/index'
import {TypedCurrentUserCan} from 'interfaces/Capacity.interface'
import {FetchBaseQueryArgs} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import {fetchBaseQuery} from '@reduxjs/toolkit/query/react'

/**
 * Helpers
 */

class helpers {
  /**
   * Check if current user can do something ...
   * @param roles string | string[]
   * @param permission_data string[]
   * @returns
   */
  current_user_can(
    roles: TypedCurrentUserCan | TypedCurrentUserCan[],
    permission_data: string[]
  ): boolean {
    // full quyền cho super_admin
    // global
    if (!permission_data) return false
    if (permission_data.indexOf('super_admin') > -1 || permission_data.indexOf('admin') > -1)
      return true

    if (Array.isArray(roles)) {
      let isCan = false
      for (let role of roles) {
        if (permission_data.indexOf(role) > -1) {
          isCan = true
        }
      }
      return isCan
    } else {
      if (permission_data.indexOf(roles) > -1) {
        return true
      }
    }

    return false
  }

  /**
   * Caculate percent,
   * @input target: number, got: number
   * @return number * 100
   */
  caculatePercent(target: number | string, got: number | string): number {
    target = Number(target)
    got = Number(got)
    let r = ((got / target) * 100).toFixed(1)
    let rr = parseFloat(r)
    if (rr > 100) rr = 100
    return rr
  }

  // constructor() {}
  /**
   * Filter object, null or undefined is ignore
   * @param _self
   * @returns
   */
  filterNullObject(_self: any) {
    var result: any = {}
    for (var key in _self) {
      /**
       * Địt mẹ Javascript, cái địt tổ nó, đổi sang
       * _self[key] !== null nó lại không được, phải
       * _self[key] === null cơ! null === null
       */
      if (_self[key] === null || _self[key] === undefined) continue
      result[key] = _self[key]
    }
    return result
  }

  /**
   * Pick variable from Object, like lodash/pick
   * @Param _object: object
   * @Param _PickArray: array to pick from Object
   * @McJamBi  from Jamviet.com
   * @DateTime 2021-10-06T02:22:20+0700
   */
  pick(_object: any, _PickArray: string[]) {
    let ALLOW_VARIABLE: any = {}
    for (let query_string in _object) {
      if (_PickArray.indexOf(query_string) > -1) {
        ALLOW_VARIABLE = { ...ALLOW_VARIABLE, ...{ [query_string]: _object[query_string] } }
      }
    }
    return ALLOW_VARIABLE
  }

  /**
   * Removes fields with an 'id' field that equals ''.
   * This function was created to prevent entities to be sent to
   * the server with an empty id and thus resulting in a 500.
   *
   * @param entity Object to clean.
   */
  cleanEntity(entity: any) {
    const keysToKeep = Object.keys(entity).filter(
      k => !(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1)
    )
    return this.pick(entity, keysToKeep)
  }

  /**
   * Return filter to save to history ...
   * @param stringQuery Object to URL query function
   * @returns
   */
  buildEndUrl(stringQuery: any) {
    if (stringQuery === void 0) return '?search='
    const params = []
    for (let key in stringQuery) {
      let nameofquery = String(key || '').trim()
      let valueofquery = String(stringQuery[key] || '').trim()
      if (key !== '') params.push({ key: nameofquery, value: valueofquery })
    }
    if (params.length > 0) {
      return '?' + params.map(({ key, value }) => `${key}=${value}`).join('&')
    }

    return '?search='
  }

  /**
   * Revert buildEndUrl, parse URL to Object
   * Duplicate value will be overwrite, last value will taken
   * @param stringQuery URL SEARCH STRING
   */
  ExtractUrl(stringQuery: any): any {
    let searchParams = new URLSearchParams(stringQuery)
    let final_object = {}
    for (const [key, value] of searchParams.entries()) {
      final_object = {
        ...final_object,
        ...{
          [key]: value
        }
      }
    }
    return final_object

    // let URLWithoutQuestionMark = String(stringQuery).substr(1); // remove ? at beginer of string
    // let URLToObject = String(URLWithoutQuestionMark).split('&'); // array
    // let FN = URLToObject.map( (r) => {
    //     let a = String(r).split('=');
    //     let y = { [a[0]] : a[1] };
    //     return y;
    // });

    // if ( FN ) {
    //   let final_object = {};
    //   for ( var a of FN) {
    //     final_object = {...final_object, ...a};
    //   }
    //   return final_object;
    // }
    // return {};
  }

  /**
   * Caculate percentage
   * @param partialValue Number
   * @param totalValue Number
   * @returns
   */
  percentage(partialValue: number, totalValue: number) {
    partialValue = Number(partialValue)
    totalValue = Number(totalValue)
    if (totalValue === 0) return 0
    return ((100 * partialValue) / totalValue).toFixed(2)
  }

  /**
   * Filter empty element in an array, remove empty string, null and undefined
   * @param _array array
   */
  filterEmptyArray(_array: any[]) {
    let __array = _array.filter(el => {
      return el !== '' || el !== undefined || el !== null
    })
    return __array
  }

  /**
   * Filter empty element in an object, remove empty string, null and undefined
   * @param object object
   */
  filterEmptyObject(_object: any) {
    let final_after_filter = {}
    for (let property in _object) {
      let val = _object[property]
      if (val === '' || val === undefined || val === null) continue

      final_after_filter = {
        ...final_after_filter,
        ...{
          [property]: val
        }
      }
    }
    return final_after_filter
  }

  /**
   * Remove value empty in object
   * @return object
   */
  clearValueEmptyInObject(object: object) {
    return Object.keys(object).forEach(key => {
      if (object[key] === null || object[key] === '' || object[key] === undefined) {
        delete object[key]
      }
    })
  }

  /**
   * Convert date
   * Default format DD/MM/YYYY
   */
  convertDateTime(time: string, format: string = 'DD/MM/YYYY'): string {
    return dateandtime.format(new Date(time), format)
  }

  /**
   * Get session id for current user
   * @returns session_id
   */
  get_session_id() {
    return localStorage.getItem('session')
  }

  /**
   * Trim middle string, eg: Hello xin chào...nhé bạn!
   * @param s String
   */
  trimMiddleString(s: string, front?: number, back?: number) {
    if (s === void 0) return ''
    if (front === void 0) front = 10
    if (back === void 0) back = 10
    if (s.length < 21) return s

    let start = String(s || ' ').substring(0, front)
    let end = String(s || ' ').substring(s.length - back)

    return `${start} ...${end}`
  }

  /**
   * Trim content string, eg: Hello xin chào...
   * @param s String
   */
  trimContentString(s: string, _length?: number) {
    if (s === void 0) return ''
    if (!s) return ''
    if (_length === void 0) _length = 20
    if (s.length < 21) return s

    let start = String(s || ' ').substring(0, _length)

    return `${start}...`
  }

  /**
   * Truncated content string, eg: Hello xin chào...
   * @param s String
   */
  truncatedContentString(s: string, _length?: number) {
    if (s.split(' ').length < Math.floor(_length / 2)) return s.slice(0, _length * 5) + '...'

    if (s === void 0) return ''
    if (!s) return ''
    if (_length === void 0) _length = 20
    if (s.length < 21) return s

    let start = String(s || ' ').split(' ')

    return `${start.slice(0, _length).join(' ')}...`
  }

  /**
   * Check if passwords length enought and streng enought
   * you should take this password if it has strength point more than 2 and less than 5
   * @param _password
   */
  getPasswordStrength = (_password: string): number => {
    if (String(_password || ' ').trim().length < 6) return 0
    let strength = 1

    let password = String(_password || ' ')
    if (password.match(/[a-z]+/)) {
      strength += 1
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1
    }
    if (password.match(/[0-9]+/)) {
      strength += 1
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1
    }
    return strength
  }

  /**
   * Convert Bytes to KB, MB, GB
   * @param bytes
   * @param decimals
   * @returns
   */
  bytesToSize(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let i = Math.floor(Math.log(bytes) / Math.log(k))
    if (i < 0) i = 0
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i] || 'Bytes'
  }

  /**
   * Convert DateTime bigInt to number of days remain
   * Tính số ngày đã trôi qua, dương là số ngày trôi qua, số âm là chưa tới ngày
   * @param thatday bigint
   * @returns
   */
  subtractDate = (thatday: any, prefix?: string) => {
    if (!thatday || thatday < 1) return ''
    const today = dateandtime.format(new Date(), 'YYYY/MM/DD HH:mm:ss')
    const tday = dateandtime.subtract(new Date(today), new Date(Number(thatday)))
    return Math.ceil(tday.toDays()) + (prefix ? ' ' + prefix : '')
  }

  /**
   * Show the date / or hour or minutes passed
   * @param thatday Date object
   * @param prefix
   * @returns
   */
  subtractTimeHistory = (timestamp: Number | String, default_value?: string): string => {
    if (!timestamp) return default_value

    const today = dateandtime.format(new Date(), 'YYYY/MM/DD HH:mm:ss')
    const tday = dateandtime.subtract(new Date(today), new Date(Number(timestamp)))

    if (tday.toSeconds() < 1) {
      return __('format_time_just_now')
    }
    if (tday.toSeconds() < 59) {
      return Math.ceil(tday.toSeconds()) + ' ' + __('format_time_second_ago')
    }
    if (tday.toMinutes() < 59) {
      return Math.ceil(tday.toMinutes()) + ' ' + __('format_time_minute_ago')
    }

    if (tday.toHours() < 24) {
      return Math.ceil(tday.toHours()) + ' ' + __('format_time_hour_ago')
    }
    if (tday.toDays() < 30) {
      return Math.ceil(tday.toDays()) + ' ' + __('format_time_date_ago')
    }
    const monthsAgo = Math.floor(tday.toDays() / 30)
    if (monthsAgo > 0) {
      return monthsAgo + `${__('format_time_month_ago')}`
    }

    const yearsAgo = Math.floor(tday.toDays() / 365)
    if (yearsAgo > 0) {
      return yearsAgo + `${__('format_time_year_ago')}`
    }

    return dateandtime.format(new Date(Number(timestamp)), 'YYYY/MM/DD HH:mm')
  }

  /**
   * Convert comma to array
   * @param __str String with comma, safe function
   * @return any[] or empty []
   */
  commaToArray(__str: string): any[] {
    try {
      return String(__str || ' ')
        .split(',')
        .map(el => el.trim())
    } catch (_e) {
      return []
    }
  }

  /**
   * logic cho việc sang màn đăng ký của gamifa
   * Nếu là dev cho tại màn luôn vì không đồng bộ với DB livesite
   */
  isDevEnv() {
    return (
      this.isDev() ||
      window.location.host === 'dev.gamifa.appuni.io' ||
      window.location.host === 'dev.social.gamifa.com'
    )
  }


  /**
   * Format for money or you need something like easy to read long number
   * @param x
   * @returns
   */
  formatNumberWithCommas(n: string | number): string {
    n = this.formatNumberCommasToNumeric(n)
    n = n.toString()
    var pattern = /(-?\d+)(\d{3})/
    while (pattern.test(n)) n = n?.replace(pattern, '$1,$2')
    return n
  }

  formatPhoneNumber(n: string | number): string {
    n = this.formatNumberCommasToNumeric(n)
    n = n.toString()
    var pattern = /(-?\d+)(\d{3})/
    while (pattern.test(n)) n = n?.replace(pattern, '$1$2')
    return n
  }

  formatNumberCommasToNumeric(n: string | number): number {
    return Number(String(n || ' ')?.replace(/\D+/g, ''))
  }

  convertToCommasFormat(number: string | number): string {
    const strNumber = parseFloat(String(number)).toString()
    const [integerPart, decimalPart] = strNumber.split('.')
    let commaIntegerPart = ''
    for (let i = integerPart.length - 1, j = 0; i >= 0; i--, j++) {
      if (j > 0 && j % 3 === 0) {
        commaIntegerPart = ',' + commaIntegerPart
      }
      commaIntegerPart = integerPart[i] + commaIntegerPart
    }
    let result = commaIntegerPart
    // if (decimalPart) {
    //   result += '.' + decimalPart
    // }

    return result
  }

  /**
   *
   * @param number
   * @returns 1000 => 1k
   */
  convertNumber(n: number | string, convertB = true, convertM = true, convertK = true) {
    const number = Number(n)
    if (number >= 1000000000 && convertB) {
      if (number % 1000000000 === 0) {
        return `${number / 1000000000}B`
      }
      return `${(number / 1000000000).toFixed(1)}B`
    }
    if (number >= 1000000 && convertM) {
      if (number % 1000000 === 0) {
        return `${number / 1000000}M`
      }
      return `${(number / 1000000).toFixed(1)}M`
    }
    if (number >= 1000 && convertK) {
      if (number % 1000 === 0) {
        return `${number / 1000}k`
      }
      return `${(number / 1000).toFixed(1)}k`
    }
    return `${Math.max(number, 0)}`
  }

  // Add a cache buster to the baseQuery
  getBaseQuery(data: FetchBaseQueryArgs) {
    const baseQuery = fetchBaseQuery(data)

    return (args, api, extraOptions) => {
      if (typeof args === 'string') {
        args = args + '&cacheBuster=' + new Date().getTime()
      } else {
        if (typeof args === 'object' && typeof args?.url === 'string') {
          args = {
            ...args,
            url: args.url + '?cacheBuster=' + new Date().getTime()
          }
        }
      }
      return baseQuery(args, api, extraOptions)
    }
  }

  setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

  validColor(color: string) {
    if (!color) return false
    const colorFormat = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/

    return colorFormat.test(color)
  }
}

interface helpers extends helper_is, helper_get, helper_parse {}

const __helpers = new helpers()
export default __helpers

/*******
 * Everything after here is for webpack!
 */

// copy the methods
Object.assign(helpers.prototype, new helper_is())
Object.assign(helpers.prototype, new helper_get())
Object.assign(helpers.prototype, new helper_parse())

/**
 * It is must be here because of webpack can not run without applyMixins
 * @param derivedCtor
 * @param constructors
 */

// the helper function
function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) || Object.create(null)
      )
    })
  })
}

applyMixins(helpers, [helper_is, helper_get, helper_parse])
