import { useParams } from 'react-router-dom'
import Theme404 from '../../layout/404'

import gift_list from './gift.list'
import gift_receiver from './gift.receiver'
import gift_update from './gift.update'
import GiftReceiverAdd from 'entities/gift/gift.receiver.add'
import GiftDetail from './GiftDetail.page'

/**
 *   Create index file for Comment
 */
export default function Gift() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'list'

  let ActualPage: any
  switch (Param) {
    case 'detail':
      ActualPage = GiftDetail
      break

    case 'list':
      ActualPage = gift_list
      break

    case 'receivers':
      ActualPage = gift_receiver
      break

    case 'add-receiver':
      ActualPage = GiftReceiverAdd
      break

    case 'update':
      ActualPage = gift_update
      break

    case 'add':
      ActualPage = gift_update
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div>
      {<ActualPage />}
    </div>
  )
}
