import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Helpers
import helpers from 'helpers'

interface TypeBodyCreateChannel {
  name: string
  short_description: string
  avatar: string
  description: string
  public_status: string
  mentor_name: string
  mentor_address: string
  mentor_income: string
  mentor_number_member: string
  mentor_category: string
  mentor_target: string
}

interface DataCreateOrder {
  plan_id: string
  payment_method: string
  amount_of_package: string
  trans_id: string
}

const initialState = {
  dataCreateChannel: null
}

export const uploadImage = createAsyncThunk(
  'channel/upload_image',
  async (body: unknown, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(
        `${process.env.REACT_APP_MEDIA_UPLOAD_URL}?callback=${process.env.REACT_APP_AJAX_URL}/chat-media/create`,
        body,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const registerChannel = createAsyncThunk(
  'channel/register_channel',
  async (body: TypeBodyCreateChannel, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/channel/create`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getListMembers = createAsyncThunk(
  'channel/get_list_members',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<unknown>(`/channel/list-members`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getListChannel = createAsyncThunk(
  'channel/get_list_channel',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<unknown>(`/channel`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const joinPermission = createAsyncThunk(
  'channel/join_permission',
  async (
    body: { user_id: string; channel_id: string;},
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<unknown>(
        `/channel/join-permission`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getDataChannel = createAsyncThunk(
  'channel/get_data_channel',
  async (_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<unknown>(`/channel/detail/${_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getListPlan = createAsyncThunk(
  'channel/get_list_plan',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<unknown>(`/plan/list-plan`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getOrderList = createAsyncThunk(
  'channel/get_order_list',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<unknown>(`/order/user-list`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createOrder = createAsyncThunk(
  'channel/create_order',
  async (body: DataCreateOrder, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/order/create`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updateOrder = createAsyncThunk(
  'channel/update_order',
  async (body: { _id: string; status: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/order/user-update`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const CHANNEL_REDUCER = createEntitySlice({
  name: 'channel',
  initialState: initialState as any,
  reducers: {
    setDataCreateChannel(state, action) {
      state.dataCreateChannel = action.payload
    },

    clearInitialState: () => initialState
  },
  extraReducers(builder) {}
})

export const { setDataCreateChannel, clearInitialState } = CHANNEL_REDUCER.actions
export default CHANNEL_REDUCER.reducer
