import React, {useMemo, useState} from 'react'
import {Button, Container, ListGroup, ListGroupItem, Tab, Tabs} from 'react-bootstrap'
import RenderView from 'components/RenderView'
import {Link} from 'react-router-dom'
import {useGetListTicketQuery} from 'entities/support/store/supportApi'
import {PostStatus} from 'entities/support/ticket.type'
import InfiniteList from 'components/InfiniteList'
import TicketItem from './components/TicketItem'
import __ from "languages/index";
import _Helmet from 'components/helmet'
import BigLayout from "../../layouts/bigLayout";

const DEFAULT_LIMIT = 10
const DEFAULT_ORDER_BY = 'DESC' as 'DESC' | 'ASC'

const Support = () => {
  const [selectedTab, setSelectedTab] = useState<PostStatus>('open')

  const [page, setPage] = useState<number>(1)
  const fetchOptions = useMemo(
    () => ({
      page,
      limit: DEFAULT_LIMIT,
      post_status: selectedTab,
      order_by: DEFAULT_ORDER_BY
    }),
    [page, selectedTab]
  )

  const {
    data: getTicketsResponse,
    isLoading: gettingTickets,
    isFetching
  } = useGetListTicketQuery(fetchOptions)
  const tickets = getTicketsResponse?.data
  const totalCount = getTicketsResponse?.totalCount

  const CreateNewTicketButton = () => {
    return (
      <Button className="p-0">
        <Link
          className={'text-white text-decoration-none w-100 h-100 d-inline px-2 py-2 d-block'}
          to={'/support/create-ticket'}
        >
          {__("support_create_new_ticket")}
        </Link>
      </Button>
    )
  }

  return (
    <BigLayout>
      <_Helmet title='support_helmet_title' />
      <Container className="py-4">
        <h5 className="fs-4 fw-semibold mb-4">{__("support_list_title")}</h5>
        <div className={'border py-2 px-4 rounded-3'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <Tabs
              variant="underline"
              id="list-ticket"
              className="mb-3"
              onSelect={key => setSelectedTab(key as PostStatus)}
            >
              <Tab title={__("support_ticket_open")} eventKey={'open'} />
              <Tab title={__("support_ticket_close")} eventKey={'close'} />
            </Tabs>
            {CreateNewTicketButton()}
          </div>
          <RenderView
            emptyElementFallback={
              <div className={'d-flex flex-column align-items-center justify-content-center'}>
                <p>
                  {__("support_ticket_sub_text")}{' '}
                  {selectedTab === 'close'
                    ? `${__("support_ticket_status_close")}`
                    : selectedTab === 'open'
                    ? `${__("support_ticket_status_open")}`
                    : `${__("support_ticket_status_processing")}`}
                  .
                </p>
                {CreateNewTicketButton()}
              </div>
            }
            isLoading={gettingTickets}
            isEmpty={tickets?.length === 0}
            view={
              <InfiniteList
                as={ListGroup}
                list={tickets}
                renderItem={(ticket, ref) => (
                  <ListGroupItem ref={ref}>
                    <Link to={ticket._id} className="text-decoration-none text-mute text-reset">
                      <TicketItem
                        key={ticket._id}
                        title={ticket.post_title}
                        createdAt={ticket.createdAt}
                        status={ticket.post_status || 'open'}
                        fromUser={ticket.user_id[0]}
                      />
                    </Link>
                  </ListGroupItem>
                )}
                hasNextPage={tickets?.length < totalCount}
                onLoadMore={() => setPage(prev => prev + 1)}
                isLoading={isFetching}
              />
            }
          />
        </div>
      </Container>
    </BigLayout>
  )
}

export default Support
