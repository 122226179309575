//Library
import {memo} from 'react'
import date from 'date-and-time'
import {Link} from 'react-router-dom'
import __ from 'languages'

//Helpers
import helpers from 'helpers'
//Image
import avatarDefault from 'media/images/avatar_default.png'

//Scss
import './media/courses.income.table.scss'

const CoursesIncomeTable = ({ data, index }) => {
  console.log('data',data)
  return (
    <>
      <div id="coursesIncomeTable" className="coursesIncomeTable__container">
        <div className={`coursesIncomeTable__header ${Number(index) && 'fromClient'}`}>
          <span className="coursesIncomeTable__item">{__("course_income_table_course")}</span>
          <span className="coursesIncomeTable__item">{__("course_income_table_buyer")}</span>
          <span className="coursesIncomeTable__item">{__("course_income_table_affiliate")}</span>
          <span className="coursesIncomeTable__item">{__("course_income_table_price")}</span>
          <span className="coursesIncomeTable__item">{__("course_income_table_commission")}</span>
          <span className="coursesIncomeTable__item">{__("course_income_table_revenue")}</span>
        </div>

        <div className="coursesIncomeTable__list">
          {data.map((item: any, idx: number) => (
            <div
              key={idx}
              className={`coursesIncomeTable__list--wrapper ${
                idx % 2 ? 'evenNumber' : 'oddNumber'
              } ${Number(index) && 'fromClientWrapper'}`}
            >
              <div className="coursesIncomeTable__list--item">
                <span className="coursesIncomeTable__list--headerMobile">{__("course_title")}</span>
                <div className="d-flex flex-column justify-content-center">
                  <span className="coursesIncomeTable__list--name">{item?.ref_name}</span>
                  {item?.successfully_on && (
                    <span className="coursesIncomeTable__list--subTitle">
                      {__("course_income_table_order_date")}
                      {item?.successfully_on
                        ? date.format(new Date(item?.successfully_on), 'DD/MM/YYYY')
                        : '-'}
                    </span>
                  )}
                </div>
              </div>

              <div className="coursesIncomeTable__list--item">
                <span className="coursesIncomeTable__list--headerMobile">{__("course_income_text_buyer")}</span>
                <div className="coursesIncomeTable__list--buyerWrapper">
                  {item?.user_id?._id ? (
                    <>
                      <Link to={`/user/detail/${item?.user_id?._id}`}>
                        <div className="coursesIncomeTable__list--image">
                          <img
                            src={item?.user_id?.user_avatar_thumbnail || item?.user_id?.user_avatar || avatarDefault}
                            onError={e => {
                              e.currentTarget.src = avatarDefault
                            }}
                            alt="image"
                            loading="lazy"
                          />
                        </div>
                      </Link>
                      <div className="coursesIncomeTable__link overflow-hidden">
                        <Link
                          to={`/user/detail/${item?.user_id?._id}`}
                        >
                        <span className="coursesIncomeTable__list--buyer">
                          {item?.user_id?.display_name}
                        </span>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <span className="coursesIncomeTable__list--buyer">-</span>
                  )}
                </div>
              </div>

              {item?.successfully_on && (
                <div className="coursesIncomeTable__list--buyTimeMobile">
                  <span className="coursesIncomeTable__list--headerMobile">{__("course_income_text_buy_time")}</span>
                  <span className="coursesIncomeTable__list--buyTime">
                    {item?.successfully_on
                      ? date.format(new Date(item?.successfully_on), 'DD/MM/YYYY')
                      : '-'}
                  </span>
                </div>
              )}

              {item?.from_user?._id && (
                <div className="coursesIncomeTable__list--item affiliateBlock">
                  <span className="coursesIncomeTable__list--headerMobile">{__("course_income_text_affiliate")}</span>
                  <div className="coursesIncomeTable__list--affiliateWrapper">
                    <Link to={`/user/detail/${item?.from_user?._id}`}>
                      <div className="coursesIncomeTable__list--image">
                        <img
                          src={item?.user_id?.user_avatar_thumbnail || item?.from_user?.user_avatar || avatarDefault}
                          onError={e => {
                            e.currentTarget.src = avatarDefault
                          }}
                          alt="image"
                          loading="lazy"
                        />
                      </div>
                    </Link>
                    <div className="coursesIncomeTable__link overflow-hidden">
                      <Link
                        className="text_decoration_none"
                        to={`/user/detail/${item?.from_user?._id}`}
                      >
                        <span className="coursesIncomeTable__list--contentName">
                          {item?.from_user?.display_name ?? '-'}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`coursesIncomeTable__list--item ${
                  !item?.from_user?._id && 'displayPosition'
                }`}
              >
                <span className="coursesIncomeTable__list--headerMobile">{__("course_income_text_price")}</span>
                <span
                  className={`coursesIncomeTable__list--price ${Number(index) && 'fromClientItem'}`}
                >
                  {helpers.convertToCommasFormat(item?.transaction_value || '0')}
                </span>
              </div>

              <div className="coursesIncomeTable__list--item coursesIncomeTable__list--itemMobile">
                <span className="coursesIncomeTable__list--headerMobile">{__("course_income_text_commission")}</span>
                <span className="coursesIncomeTable__list--percent">
                  {item?.commission_value || '0'}%
                </span>
              </div>

              <div className="coursesIncomeTable__list--item">
                <span className="coursesIncomeTable__list--headerMobile">{__("course_income_text_revenue")}</span>
                <div className="coursesIncomeTable__list--revenueMobile">
                  <span className="coursesIncomeTable__list--percentMobile">{`(${
                    item?.commission_value || '0'
                  }%)`}</span>
                  <span
                    className={`coursesIncomeTable__list--revenue ${
                      Number(index) && 'fromClientItem'
                    }`}
                  >
                    {helpers.convertToCommasFormat(item?.transaction_value || '0')}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default memo(CoursesIncomeTable)
