import { Box, Button, IconButton, InputBase, Menu, MenuItem, Pagination, PaginationItem, Stack, styled, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from "@mui/material"
import { PlusIcon, SearchIcon } from "icons"
import DownloadIcon from "icons/DownloadIcon"
import __, { ___ } from "languages/index"
import BigLayout from "layouts/bigLayout"
import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import ActionMenu from "./components/ActionMenu"
import { OrderStatus } from "./data/order.enum"
import { IRootState, useAppDispatch } from "config/store"
import { getOrders, selectorOrders, updateStatusOrder } from "./order.store.reducer"
import { IQueryParams } from "config/reducer.utils"
import helpers from 'helpers'
import { useSelector } from "react-redux"
import moment from "moment"
import { omit } from "lodash"
import debounce from 'lodash.debounce'
import __helpers from "helpers"
import { AxiosError, AxiosResponse } from "axios"
import { EnumTypeToast, useToast } from "hooks/useToast"
import ComponentEmpty from "components/componentEmpty"
import { tabChildrenManager } from "./data"
import ShopBreadcrumb from "entities/shop/components/shop.breadcrumb"

const OrderManagerList = () => {

    const initialQuery = {
        page: 1,
        limit: 10,
        sort: 'createdAt:desc'
    }
    const [paramRequest, setParamRequest] = useState<IQueryParams>({ ...initialQuery })
    const [chooseTab, setChooseTab] = useState(paramRequest.order_status || null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const orders = useSelector(selectorOrders);
    const orderTotal = useSelector((state: IRootState) => state.order?.totalItems)
    const toast = useToast()

    useLayoutEffect(()=>{
        window.history.replaceState(null, "Shop", `/shop/manage-order`);
    },[])

    useEffect(() => {
        dispatch(getOrders(paramRequest))
    }, [])

    const handleValueSearch = useMemo(() => {
        return debounce((e: ChangeEvent<HTMLInputElement>) => {
            handleFilterByField('query', e.target.value)
        }, 500)
    }, [paramRequest])

    const handleChooseTab = (event: React.SyntheticEvent, newValue: OrderStatus) => {
        setChooseTab(newValue)
        handleFilterByField('order_status', newValue)

    }

    const formatStatus = (value) => {
        let color = 'warning.dark';
        let text = 'Vận chuyển';

        switch (value.toString()) {
            case OrderStatus.AwaitPickup:
                color = '#08979C'
                text = __('comfirmed')
                break;
            case OrderStatus.Delivered:
                color = 'success.dark'
                text = __('order_complete')
                break;
            case OrderStatus.Cancelled:
                color = 'error.dark'
                text = __('order_cancel')
                break;
            case OrderStatus.Pending:
                color = 'info.dark'
                text = __('ordered')
                break;
            case OrderStatus.AwaitDelivery:
                color = 'warning.dark'
                text = __('transporting')
                break;
            case OrderStatus.ReturnInitated:
                color = 'primary.main'
                text = __('return_initated')
                break;
            default:
                break;
        }

        return {
            color,
            text
        };
    }

    const handleFilterByField = (type, value) => {
        const newVal =
            type === 'createdAt' ? moment(value).format('YYYY-MM-DD') : value;
        const newValues: IQueryParams = value === 0 || value
            ? {
                ...paramRequest,
                [type]: newVal,
            }
            : omit(paramRequest, [type]);
        if (type !== 'page') {
            newValues.page = 1
        }

        setParamRequest(newValues);
        dispatch(getOrders(newValues));

        let buildURLSearch = helpers.buildEndUrl(newValues);
        if (window.location.search !== buildURLSearch) {
            window.history.replaceState(null, "Shop", `/shop/manage-order${buildURLSearch}`);
        }
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        handleFilterByField("page", value)
    }

    const handleUpdateStatusOrder = (idOrder: string, value: OrderStatus) => {
        dispatch(updateStatusOrder({
            idOrder,
            body: {
                order_status: value
            }
        })).unwrap()
            .then((res: AxiosResponse) => {
                dispatch(getOrders(paramRequest));
                toast.show({
                    content: `${__('about_page_update_info_success')}`,
                    type: EnumTypeToast.Success
                })
            })
            .catch((error: AxiosError) => {
                return toast.show({
                    content: `${__('about_page_update_info_failed')}`,
                    type: EnumTypeToast.Error
                })
            })
    }

    const handlePrintBillOrder = (value) => {
        window.open('/print/order/' + value)
    }

    const handleRowClick = (orderId: string) => {
        navigate(`/shop/order-detail-manager/${orderId}`)
    };

    return (
        <>
            <BigLayout>
                {/* start header action */}
                <ShopBreadcrumb
                    breadcrumbName={{
                        '/shop/manage-order': __('shop_manage_order')
                    }}
                />
                <HeaderContainer>
                    <Stack className="left">
                        <Box className="box_search">
                            <IconButton aria-label="search" sx={{ padding: 0 }}>
                                <SearchIcon color="inherit" />
                            </IconButton>
                            <InputBase
                                onChange={handleValueSearch}
                                placeholder={__('header_search')}
                                inputProps={{ 'aria-label': __('header_search') }}
                                className="search_input"
                            />
                        </Box>
                        <Box className="total_order" >
                            <Typography variant="body1" color={'primary.main'}>
                                {___('{total} order', { total: <>{orderTotal || 0}</> })}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack className="right">
                        <Button variant="outlined" className="btn down" size={'small'}>
                            <IconButton sx={{ p: theme => theme.spacing(0, 1, 0, 0) }}>
                                <DownloadIcon fill="#DC3545" color={'primary'} sx={{ width: '100%', height: '100%' }} />
                            </IconButton>
                            <Typography variant="body1" color={'inherit'} textTransform="none">
                                {__('manage_product_btn_down')}
                            </Typography>
                        </Button>

                        <Button
                            onClick={() => navigate('/shop/create-order')}
                            size={'small'}
                            sx={{ padding: theme => theme.spacing(1, 1) }}
                            className="btn add"
                        >
                            <IconButton sx={{ p: theme => theme.spacing(0, 1, 0, 0), color: 'inherit' }}>
                                <PlusIcon sx={{ width: '100%', height: '100%', color: 'inherit' }} />
                            </IconButton>
                            <Typography variant="body1" textTransform="none">
                                {__('order_create')}
                            </Typography>
                        </Button>

                    </Stack>
                </HeaderContainer>
                {/* list header action */}

                {/* start list */}
                <ListContainer>
                    <Box className="box_tabs">
                        <Tabs
                            onChange={handleChooseTab}
                            value={chooseTab}
                            variant='fullWidth'
                            textColor="secondary"

                        >
                            {tabChildrenManager.map((tab, index) => <Tab key={index} value={tab.value} label={<Typography color={theme => chooseTab === tab.value ? theme.palette.primary.main : '#626F82'} variant="subtitle2" >{tab.label}</Typography>} />)}
                        </Tabs>
                    </Box>

                    <Table className="box_list">
                        <TableHead >
                            <TableRow className="table_header">
                                <TableCell
                                    sx={{
                                        borderRadius: theme => theme.spacing(1.5, 0, 0, 1.5)
                                    }}
                                ><Typography variant="subtitle2" >{__('order_table_code')}</Typography></TableCell>
                                <TableCell ><Typography variant="subtitle2" >{__('order_table_orderer')}</Typography></TableCell>
                                <TableCell >
                                    <Typography variant="subtitle2" >{__('order_table_total_payment')}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="subtitle2" >{__('order_table_date')}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="subtitle2" >{__('order_table_status')}</Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderRadius: theme => theme.spacing(0, 1.5, 1.5, 0)
                                    }}
                                >
                                    <Typography variant="subtitle2" >{__('order_action')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody className="table_body">
                            {orders?.length ? orders.map((data, index) => {
                                const formatedStatus = formatStatus(data.order_status)
                                return (
                                    <TableRow key={index}>
                                        <TableCell onClick={() => handleRowClick(data.order_id)} >{data.order_pnr || "-"}</TableCell>
                                        <TableCell onClick={() => handleRowClick(data.order_id)}>
                                          {__helpers.getTrimContent(`${data.orders_to_address.order_customer_name}`, 30) || "-"}
                                        </TableCell>
                                        <TableCell onClick={() => handleRowClick(data.order_id)}>{__helpers.formatNumberWithCommas(data.order_total_price)} {__("order_unit")}</TableCell>
                                        <TableCell onClick={() => handleRowClick(data.order_id)}>{moment(+data.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                                        <TableCell onClick={() => handleRowClick(data.order_id)}>
                                            <Box className="order_status">
                                                <Typography color={'white'} variant="body1" bgcolor={formatedStatus.color}>
                                                    {formatedStatus.text}
                                                </Typography>
                                            </Box>

                                        </TableCell>
                                        <TableCell >
                                            <ActionMenu
                                                handleUpdateStatusOrder={handleUpdateStatusOrder}
                                                handlePrintBillOrder={handlePrintBillOrder}
                                                status={data.order_status}
                                                idOrder={data.order_id as string}
                                            />
                                        </TableCell>

                                    </TableRow>
                                )
                            }) : null

                            }

                        </TableBody>
                    </Table>
                    {
                        !orders?.length && (
                            <Stack alignItems={'center'}>
                                <ComponentEmpty />

                            </Stack>
                        )
                    }
                    <Box className="pagination" >
                        {
                            orders.length ? (
                                <Pagination
                                    color="primary"
                                    variant="outlined"
                                    shape="rounded"
                                    page={+paramRequest.page}
                                    count={Math.ceil(orderTotal / paramRequest.limit) || 1}
                                    renderItem={(otherProps) => (
                                        <PaginationItem
                                            sx={{ borderRadius: theme => theme.spacing(1.5) }}
                                            {...(otherProps as any)}
                                            disableRipple
                                        />
                                    )}
                                    onChange={handleChangePage}
                                />
                            ) : null
                        }
                    </Box>
                </ListContainer>
                {/* end list */}
            </BigLayout>


        </>
    )
}

export default OrderManagerList

const HeaderContainer = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '12px',
    marginTop: theme.spacing(2),

    '& .left': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .box_search': {
            background: '#F3F4F5',
            borderRadius: '12px',
            padding: theme.spacing(1, 2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            '& .search_input': {
                paddingLeft: theme.spacing(2)
            }
        },
        '& .total_order': {
            padding: theme.spacing(0, 2),
            background: '#F3F4F5',
            marginLeft: theme.spacing(2),
            borderRadius: '12px',
            textAlign: 'center'
        }
    },
    '& .right': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .btn': {
            padding: theme.spacing(2, 3),
        },
        '& .down': {
            borderColor: theme.palette.divider,
        },
        '& .add': {
            marginLeft: theme.spacing(2)
        }
    }
}))

const ListContainer = styled(Box)(({ theme }) => ({
    background: 'white',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1.5),

    '& .box_tabs': {
        borderBottom: 1,
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none'
    },

    '& .box_list': {
        marginTop: theme.spacing(2),

        '& .table_header': {
            '& th': {
                padding: theme.spacing(1.25, 0.8),
                background: '#E9EBEE',
            },
            '& th:first-child': {
                padding: theme.spacing(1.25, 0.8, 1.25, 2),
            },
            '& th:last-child': {
                padding: theme.spacing(1.25, 0.8, 1.25, 2),
            }
        },

        '& .table_body': {

            '& :hover': {
                cursor: 'pointer',
                opacity: 0.8
            },
            '& tr': {
                '& td': {
                    padding: theme.spacing(3, 0.8),
                    height: theme.spacing(2.75),
                    borderBottom: 1,
                    borderBottomColor: theme.palette.divider,
                    borderStyle: 'solid',

                    '& .order_status': {
                        display: 'flex',
                        alignItems: 'center',
                        padding: theme.spacing(0, 1),

                        '& p': {
                            padding: theme.spacing(0.5, 1),
                            borderRadius: theme.spacing(1.5),
                        }
                    }
                },
                '& td:first-child': {
                    padding: theme.spacing(3, 0.8, 3, 2),
                },
                '& td:last-child': {
                    padding: theme.spacing(3, 2, 3, 3),
                },

            }
        }
    },

    '& .pagination': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        width: '100%',

        '& .Mui-selected': {
            background: theme.palette.primary.main,
            color: 'white'
        }
    }
}))
