import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginationResponse } from 'entities/challenge/challenge.interface'
import { convertParamsToQueryString } from 'entities/challenge/store/challengeApi'
import { InjectPaginateParams } from '../../../interfaces/query.interface'
import { RankingType } from '../../../interfaces/common.interface'

const DEFAULT_ORDER_BY = 'ASC'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL + '/channel',
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    return headers
  },
  credentials: 'same-origin'
})

const rankingApi = createApi({
  baseQuery,
  reducerPath: 'rankingApi',
  tagTypes: ['List'],
  refetchOnMountOrArgChange: 30,
  endpoints: builder => ({
    getRanking: builder.query<
      PaginationResponse<RankingType[]>,
      InjectPaginateParams<{ order_type: string }>
    >({
      query(params) {
        return `/member-ranking${convertParamsToQueryString({ ...params })}`
      },
      providesTags: ['List'],
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return endpointName
      },
      transformResponse: (response: RankingType[], meta) => {
        return {
          data: response,
          totalCount: Number(meta.response.headers.get('X-Total-Count'))
        }
      },
      merge: (currentCacheData, newData, { arg, baseQueryMeta }) => {
        if (currentCacheData.data && arg.page !== 1) {
          return {
            ...currentCacheData,
            ...newData,
            data: [...currentCacheData.data, ...newData.data]
          }
        }
        return newData
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    })
  })
})

export const { useGetRankingQuery } = rankingApi

export default rankingApi
