import React from 'react'
import { FormControlLabel, Radio, Stack, SxProps, Theme, Typography } from '@mui/material'
import __ from "languages/index";

type Props = {
  label: string
  votes?: number
  className?: string
  value: string
  disable?: boolean
}
const PollElement = ({ value, label, votes = 0, className, disable = false }: Props) => {
  return (
    <FormControlLabel
      disabled={disable}
      value={value}
      sx={containerStyled}
      control={<Radio />}
      label={
        <Stack direction={'row'} sx={labelStyled}>
          <Typography>{label}</Typography>
          <Typography color={'text.secondary'}>{votes} {__("home_page_vote_number")}</Typography>
        </Stack>
      }
      className={className}
    />
  )
}

export default PollElement

const containerStyled: SxProps<Theme> = {
  width: '100%',
  backgroundColor: theme => theme.palette.background.default,
  borderRadius: theme => theme.spacing(1.5),
  marginRight: 0,
  '& .MuiFormControlLabel-label': {
    width: '100%'
  }
}

const labelStyled: SxProps<Theme> = {
  width: '100%',
  justifyContent: 'space-between',
  pr: theme => theme.spacing(2)
}
