import ChallengeListComponent from "./component/challenge.list/challenge.list.component";
import _Helmet from "components/helmet";

const ChallengeList = (props) => {

  return (
    <>
      <_Helmet title='Challenge' />
      <div className="mainFrame">
        <ChallengeListComponent />
      </div>
    </>
  )
}

export default ChallengeList
