import React, {useCallback, useState} from 'react'
import {Form, Image} from 'react-bootstrap'
import ImgBlur from 'entities/courses/media/img_blur.png'
import {uploadMedia} from 'entities/calendar/service'
import __ from "languages/index";

interface Props {
  getDataImageChange: (media_id: string, id?: string, title?: string) => void
  id: string
  title: string
}

const ImageForm = (props: Props) => {
  const { getDataImageChange, id, title } = props

  // State
  const [avatarUrl, setAvatarUrl] = useState<string>('')
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(false)
  // =========================================

  const _onChooseImageChange = useCallback(async event => {
    if (event.target.files[0]) {
      setLoadingAvatar(true)
      setAvatarUrl(ImgBlur)
      const link = URL.createObjectURL(event.target.files[0])
      try {
        const res: any = await uploadMedia(event.target.files[0])
        console.log('res data upload', res)
        if (Array.isArray(res) && res.length > 0) {
          getDataImageChange(JSON.stringify(res[0]?.callback), id, title)
        }
      } catch (error) {
        console.log('error_234234', error)
      }
      setAvatarUrl(link)
      setLoadingAvatar(false)
    }
  }, [])

  return (
    <div className="file-drop-area form-group">
      <span className="choose-file-button">{__("media_form_title")}</span>
      <Form.Control
        className="file-input"
        type="file"
        id={id}
        accept=".png,.jpg,.jpeg,.gif,.mp4,.flv,.mov,.webp"
        required={true}
        onChange={_onChooseImageChange}
        title={title}
      />
      <div className="error-image invalid-feedback">{__("media_form_text")}</div>
      {avatarUrl ? (
        <Image
          id="target"
          className={`choose_file_image choose-file-button ${!loadingAvatar ? 'fit-image' : ''}`}
          src={avatarUrl}
          alt="placeholder"
        />
      ) : null}
      {avatarUrl ? (
        !loadingAvatar ? (
          <div className="view-bottom-image">{__("media_form_sub_text")}</div>
        ) : null
      ) : null}
    </div>
  )
}

export default ImageForm
