import { Menu, MenuItem, Box, styled, Typography, List, Popover, IconButton } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'
import { useClickOutside } from 'hooks/useClickOutside'
import { CheckSecondIcon, ChevronIcon, EmptyIcon } from 'icons'
import __ from 'languages/index'
import React, { ReactNode, useCallback, useRef, useState } from 'react'
import helpers from 'helpers'
import FilterIcon from 'icons/FilterIcon'

interface TypedOption {
  value: string
  label: string
}

type TypedProps = {
  options: TypedOption[]
  onSelect: Function
  position: 'left' | 'right'
  selectData?: TypedOption
  icon?: ReactNode
} & Omit<ButtonProps, 'onSelect'>

const DropdownComponent = ({ options, position, onSelect, selectData, icon, ...props }: TypedProps) => {
  const [isShow, setIsShow] = useState<boolean>(false)

  const wrapperRef = useRef(null)

  useClickOutside(wrapperRef, setIsShow)

  const handleClick = useCallback(() => {
    setIsShow(!isShow)
  }, [isShow])

  const handleClose = useCallback(() => {
    setIsShow(false)
  }, [])

  const handleOptionClick = useCallback(
    (option: TypedOption) => {
      if (typeof onSelect === 'function') {
        onSelect(option)
      }
      handleClose()
    },
    [onSelect]
  )

  return (
    <DropdownContainer ref={wrapperRef}>

      {
        icon ? (
          <IconButton onClick={handleClick}>
            {icon}
          </IconButton>
        ) : (
          <Button {...props} className="button" onClick={handleClick}>
            <Typography variant="subtitle2" fontWeight={600} textTransform={'none'}>
              {__(selectData?.label || '')}
            </Typography>
            <Box display={'flex'} alignItems={'center'} color="text.primary">
              <ChevronIcon
                sx={{
                  width: theme => theme.spacing(2),
                  height: theme => theme.spacing(2)
                }}
                color="inherit"
              />
            </Box>
          </Button>
        )
      }

      {isShow && (
        <Box className={`dropdown_modal ${position}`}>
          {helpers.isEmpty(options) ? (
            <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
              <EmptyIcon
                sx={{
                  width: theme => theme.spacing(18.75),
                  height: theme => theme.spacing(18.75)
                }}
              />
            </Box>
          ) : (
            <>
              {options?.map((option, index) => (
                <MenuItem
                  className="dropdown_item"
                  key={index}
                  onClick={() => handleOptionClick(option)}
                >
                  <Typography
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    whiteSpace={'nowrap'}
                    fontWeight={600}
                    color={option?.value === selectData?.value ? 'primary.main' : 'text.primary'}
                  >
                    {__(option.label || '')}
                  </Typography>
                  {option?.value === selectData?.value && (
                    <Box display="flex" alignItems={'center'} color="primary.main">
                      <CheckSecondIcon
                        sx={{
                          width: theme => theme.spacing(3),
                          height: theme => theme.spacing(3)
                        }}
                        color="inherit"
                      />
                    </Box>
                  )}
                </MenuItem>
              ))}
            </>
          )}
        </Box>
      )}
    </DropdownContainer>
  )
}

export default DropdownComponent

DropdownComponent.defaultProps = {
  position: 'left'
}

const DropdownContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',

  '& .button': {
    padding: theme.spacing(0),
    'h6': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'start'
    }
  },
  '& .dropdown_modal': {
    position: 'absolute',
    zIndex: 1,
    minWidth: theme.spacing(40),
    width: '100%',
    maxHeight: theme.spacing(56.25),
    overflow: 'auto',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    background: theme.palette.background.paper,
    boxShadow: '0px 4px 12px 0px rgba(14, 20, 38, 0.12)'
  },
  '& .left': {
    left: 0
  },
  '& .right': {
    right: 0
  },
  '& .dropdown_item': {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))
