import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckOutlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM16.7399 9.32733C17.1114 9.73598 17.0813 10.3684 16.6727 10.7399L11.509 15.4342C10.9369 15.9543 10.0631 15.9543 9.49099 15.4342L7.32733 13.4672C6.91867 13.0957 6.88855 12.4633 7.26006 12.0546C7.63157 11.6459 8.26402 11.6158 8.67267 11.9873L10.5 13.6485L15.3273 9.26006C15.736 8.88855 16.3684 8.91867 16.7399 9.32733ZM10.8363 13.9543L10.8357 13.9538L10.8363 13.9543Z" fill="currentColor" />
      </svg>

    </SvgIcon>
  )
}

export default CheckOutlineIcon
