import {Badge, Button, Container, Stack} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import SupportTicket from '../components/SupportTicket'
import {useGetDetailTicketQuery, useGetListCommentQuery, useUpdateTicketMutation} from '../store/supportApi'
import SupportCommentEditor from '../components/SupportCommentEditor'
import RenderView from 'components/RenderView'
import CommentItem from 'entities/support/components/CommentItem'

import {PostStatus} from '../ticket.type'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import LinkToListTicket from '../components/LinkToListTicket'
import __ from "languages/index";
import BigLayout from "../../../layouts/bigLayout";

const DetailTicket = () => {
  const { id } = useParams()

  const { data: ticket, isLoading } = useGetDetailTicketQuery(id)
  const { data: comments } = useGetListCommentQuery({ ticketId: id })
  const [updateTicket, { isLoading: updatingTicket }] = useUpdateTicketMutation()

  const toast = useToast()

  const isClosingTicket = ticket?.post_status === 'close'

  const updateTicketStatusHandler = (status: PostStatus) => () => {
    updateTicket({
      _id: id,
      post_status: status
    })
      .unwrap()
      .catch(err => {
        toast.show({
          type: EnumTypeToast.Error,
          content: err?.data?.message
        })
      })
  }

  const renderListComment = () => {
    return (
      <Stack gap={2}>
        {comments?.map(comment => <CommentItem key={comment._id} comment={comment} />)}
      </Stack>
    )
  }

  return (
    <BigLayout>
      <Container className={'py-3'}>
        <LinkToListTicket />

        {ticket && (
          <div
            style={{
              maxWidth: '700px'
            }}
          >
            <Stack direction={'horizontal'} className={'d-flex align-items-center mb-3 '}>
              <h6 className="fs-5">
                {ticket?.post_title}
                <Badge
                  className={`ms-2 text-capatalize ${
                    ticket?.post_status === 'open' ? 'bg-success' : 'bg-danger'
                  }`}
                >
                  {ticket?.post_status || 'open'}
                </Badge>
              </h6>

              <Button
                onClick={updateTicketStatusHandler(!isClosingTicket ? 'close' : 'open')}
                className={'ms-auto'}
                disabled={updatingTicket}
              >
                {isClosingTicket ? `${__("support_detail_ticket_reopen")}` : `${__("support_detail_ticket_close")}`}
              </Button>
            </Stack>
            <div className="mb-2">
              <SupportTicket
                title={ticket.post_title}
                description={ticket.post_content}
                createdAt={ticket.createdAt}
                fromUser={ticket?.user_id[0]}
                status={ticket.post_status || 'close'}
                attachData={ticket?.data_id}
              />
            </div>
            <RenderView view={renderListComment()} />
            <div className={'mt-2'}>
              <SupportCommentEditor ticketId={id} />
            </div>
          </div>
        )}
      </Container>
    </BigLayout>

  )
}

export default DetailTicket
