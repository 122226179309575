import {invalid} from 'entities/support/utils'
import {z} from 'zod'
import __ from "languages/index";

export const CreatePodcastSchema = z.object({
  post_avatar: z.string().min(1, invalid('required', 'Thumbnail')),
  attach_files: z.string().min(1, invalid('required', 'Podcast')),
  title: z.string().min(1, invalid('required', `${__("validation_title")}`)).max(150, invalid('maxLength', 150)),
  content: z.string().min(1, invalid('required', `${__("validation_description")}`)).max(350, invalid('maxLength', 350))
})

export type CreatePodcastPayload = z.infer<typeof CreatePodcastSchema>
