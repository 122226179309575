import React, { useEffect, useState } from 'react'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { UploaderIcon } from '../../../icons'
import __ from 'languages/index'
import TextFieldMUI from 'components/TextFieldMUI'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import {
  createProductCategory,
  updateProductCategory
} from 'entities/shop/service/product-category.service'
import { formatString, validateLabel } from 'entities/support/utils'
import { AntSwitch } from 'entities/shop/components/shop.admin.category'
import { TypedProductCategory } from '../../../interfaces/shop.interface'
import useUploadFile from '../../../hooks/useUploadFile'
import uploadLoading from 'entities/shop/media/json/ uploadLottie.json'
import Lottie from 'lottie-react'
import { notEmpty } from '@shopify/react-form'

type Props = {
  category?: TypedProductCategory
  onSuccess?: (category: TypedProductCategory) => void
  isEditing?: boolean
}
const FormCreateEditProductCategory = ({ category, onSuccess, isEditing }: Props) => {
  const [categoryThumbnail, setCategoryThumbnail] = useState<FileList | null>(null)
  const [categoryName, setCategoryName] = useState<string>('')
  const [creatingCategory, setCreatingCategory] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const [categoryStatus, setCategoryStatus] = useState<number>(category?.category_status ?? 1)
  const [processingError, setProcessingError] = useState<string | null>(null)
  const [validateError, setValidateError] = useState<string>('')

  const { srcFiles: uploadedThumbnail, loading: uploadingCategoryThumb } = useUploadFile(
    categoryThumbnail,
    {
      isMultiple: false,
      initialFiles: [{ media_url: category?.category_thumbnail || '' }]
    }
  )

  const toast = useToast()

  const handleChangeCategoryName = (value: string) => {
    setCategoryName(value)
  }


  const handleSubmit = async () => {
    try {
      setCreatingCategory(true)
      if (!categoryThumbnail && !uploadedThumbnail) {
        throw new Error(__('error_required_thumbnail_category'))
      }
      if (!categoryName) {
        throw new Error(__('error_required_name_category'))
      }
      if (isEditing) {
        const res = await updateProductCategory({
          category_id: category?.category_id,
          category_name: categoryName,
          category_thumbnail: uploadedThumbnail[0]?.media_url,
          category_status: categoryStatus
        })
        const updatedCategory = res.data

        onSuccess(updatedCategory)
        toast.show({
          type: EnumTypeToast.Success,
          content: formatString(__('update_category_successfully'), updatedCategory.category_name)
        })
      } else {
        const res = await createProductCategory({
          category_name: categoryName,
          category_thumbnail: uploadedThumbnail[0]?.media_url,
          category_status: categoryStatus
        })
        const createdCategory = res.data
        onSuccess(createdCategory)
        toast.show({
          type: EnumTypeToast.Success,
          content: formatString(__('create_category_successfully'), createdCategory.category_name)
        })
      }

      setProcessingError(null)
    } catch (err) {
      if(err === 'invalid_value'){
        return setProcessingError(__("category_invalid_value"))
      }
      if(err === 'internal_error'){
        return setProcessingError(__("category_internal_error"))
      }

      return setProcessingError(__(`${err}`))

      // if (err?.response?.data?.message) {
      //   console.log('response',err.response.data.message)
      //
      //   return setProcessingError(__(`${err.response.data.message}`))
      // } else {
      //   console.log('response',err.message)
      //   return setProcessingError(__(`${err.message}`))
      // }
    } finally {
      setCreatingCategory(false)
    }
  }

  useEffect(() => {
    const isDisable =
      creatingCategory ||
      uploadingCategoryThumb ||
      !categoryName ||
      (isEditing &&
        categoryName === category?.category_name &&
        uploadedThumbnail[0]?.media_url === category?.category_thumbnail &&
        categoryStatus === category?.category_status)
    setDisable(isDisable)
  }, [
    creatingCategory,
    uploadingCategoryThumb,
    categoryName,
    uploadedThumbnail,
    categoryStatus,
    category
  ])

  useEffect(() => {
    if (!category?.category_id) return
    setCategoryName(category.category_name)
  }, [category?.category_id])

  return (
    <form>
      <Stack mb={2}>
        <Box
          display={'flex'}
          p={theme => theme.spacing(2, 0)}
          columnGap={theme => theme.spacing(3)}
          sx={{ '&  .MuiButton-root': { padding: theme => theme.spacing(0.5) } }}
        >
          <Button component="label" className="button_wrapper" variant="text">
            {!uploadedThumbnail || !uploadedThumbnail[0].media_url ? (
              <Box className="upload_wrapper">
                <UploaderIcon
                  sx={{
                    width: theme => theme.spacing(3),
                    height: theme => theme.spacing(3)
                  }}
                />
                <Typography textTransform={'none'} fontWeight={600}>
                  {__('upload_image')}
                </Typography>
              </Box>
            ) : (
              <Box
                width={85}
                height={85}
                sx={{
                  '& img': {
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }
                }}
              >
                <img src={uploadedThumbnail[0]?.media_url} alt="image" loading="lazy" />
              </Box>
            )}
            {uploadingCategoryThumb && <StyledLottie animationData={uploadLoading} autoPlay loop />}
            <VisuallyHiddenInput
              type="file"
              accept={'image/png, image/jpeg, image/jpg, image/webp'}
              onChange={e => {
                setCategoryThumbnail(e.currentTarget.files)
              }}
            />
          </Button>
          <Box
            sx={{
              width: theme => theme.spacing(45)
            }}
          >
            <TextFieldMUI
              placeholder={__('category_name')}
              error={validateError}
              maxLength={80}
              onChange={value => handleChangeCategoryName(value)}
              value={categoryName}
              onBlur={() => {
                if (!categoryName) {
                  setValidateError(notEmpty(validateLabel(`${__('category_name')}`)?.required))
                }
              }}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                onChange={event => {
                  if (event.target.checked) {
                    setCategoryStatus(1)
                  } else {
                    setCategoryStatus(0)
                  }
                }}
                value={categoryStatus}
                inputProps={{ 'aria-label': 'ant design' }}
                defaultChecked={Boolean(categoryStatus === 1)}
              />
              <Typography fontWeight={500}>{__('display_at_the_booth')}</Typography>
            </Stack>
          </Box>
        </Box>
        <Typography variant={'caption'} color={'error.main'}>
          {processingError}
        </Typography>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          <Button
            sx={{
              p: theme => theme.spacing(1.9375, 3)
            }}
            onClick={handleSubmit}
            disabled={disable}
          >
            <Typography textTransform={'none'} fontWeight={500}>
              {__('save_category')}
            </Typography>
          </Button>
        </Box>
      </Stack>
    </form>
  )
}

export default FormCreateEditProductCategory

const ButtonUpload = styled(Button)(({ theme }) => ({
  '&  .MuiButton-root': {
    padding: theme.spacing(0)
  }
}))

const StyledLottie = styled(Lottie)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: theme.spacing(15),
  svg: {
    '& path': {
      fill: theme.palette.primary.main,
      stroke: ''
    }
  }
}))

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})
