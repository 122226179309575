import __ from 'languages/index'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default function ModalAccept(props) {
  const descriptionLines = props.desciption
    ?.split('\n')
    .map((line, index) => <p key={index}>{line}</p>)
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{descriptionLines}</Modal.Body>
      <Modal.Footer>
        {props?.showMultiSelect &&
          <Button onClick={props?.onAcceptSecond}>{props?.btnSecond ?? __('btn_confirm')}</Button>
        }
        <Button onClick={props?.onAccept}>{props?.btnFirst ?? __('btn_confirm')}</Button>
        <Button variant="secondary" onClick={() => props.onHide()}>
          {__('btn_cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
