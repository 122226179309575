import { Button, IconButton, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
import __helpers from "helpers/index"
import __ from "languages/index"
import { OrderProduct } from "../order.type"
import { TrashIcon } from "icons"
import { useMemo, useState } from "react"
import debounce from "lodash.debounce"
import ConfirmModal from "components/ConfirmModal"
import { TypeActionOrderList } from "../data/order.enum"
import ComponentEmpty from "components/componentEmpty"
interface TypedProps {
    orderProducts: OrderProduct[];
    handleRemoveProductFromOrderList: (product_id: string) => void;
    handleChangeQuantityProduct: (product_id, value) => void;
}

const ItemTableDetailOrder = ({
    data,
    handleChangeQuantityProduct,
    handleRemoveProductFromOrderList,
    index
}: {
    data: OrderProduct;
    handleRemoveProductFromOrderList: (product_id: string) => void;
    handleChangeQuantityProduct: (product_id, value) => void;
    index: number
}) => {
    const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)


    const onChangeQuantity = (product_id, value) => {

        if (value < 1) {
            setIsShowConfirmDelete(true)
        } else {
            handleChangeQuantityProduct(product_id, value)
        }


    }

    return (
        <TableRow key={index}>
            <TableCell ><Typography variant="body1" >{index + 1}</Typography></TableCell>
            <TableCell ><Typography variant="body1" >{data.product.product_name || "-"}</Typography></TableCell>
            <TableCell >
                <TextField
                    fullWidth={false}
                    onChange={e => onChangeQuantity(data.product.product_id, e.target.value)}
                    // onInput={e => {console.log('e',e)}}
                    value={data.item_quantity || 1}
                    hiddenLabel
                    type="number"

                    sx={{
                        width: '33%'
                    }}
                />
            </TableCell>
            <TableCell ><Typography variant="body1" >{__helpers.formatNumberWithCommas(data.product_price)} {__("order_unit")}</Typography></TableCell>
            <TableCell ><Typography variant="body1" >{__helpers.formatNumberWithCommas(data.product_original_price)} {__("order_unit")}</Typography></TableCell>
            <TableCell ><Typography variant="body1" >{__helpers.formatNumberWithCommas(data.product_price * data.item_quantity)} {__("order_unit")}</Typography></TableCell>

            <TableCell >
                <Button sx={{
                    minWidth: 'unset',
                    width: theme => theme.spacing(5.5),
                    height: theme => theme.spacing(5.5)
                }} variant="outlined"
                    onClick={() => {
                        setIsShowConfirmDelete(true)
                    }}                                >
                    <TrashIcon />
                </Button>
            </TableCell>

            <ConfirmModal

                onClose={() => setIsShowConfirmDelete(false)}
                open={isShowConfirmDelete}
                onConfirm={() => {
                    handleRemoveProductFromOrderList(data.product.product_id)
                    setIsShowConfirmDelete(false)
                }}
                title={__('btn_confirm')}
                cancelTextBtn={__('home_btn_cancel')}
                confirmText={__('delete_product_title')}
            />
        </TableRow>
    )
}

const OrderDetailTable = ({ orderProducts = [], handleRemoveProductFromOrderList, handleChangeQuantityProduct }: TypedProps) => {

    return (
        <>


            <TableContainer className="box_list">
                <TableHead >
                    <TableRow className="table_header">
                        <TableCell
                            sx={{
                                borderRadius: theme => theme.spacing(1.5, 0, 0, 1.5)
                            }}
                        ><Typography variant="subtitle2" >{__('STT')}</Typography></TableCell>
                        <TableCell ><Typography variant="subtitle2" >{__('product')}</Typography></TableCell>
                        <TableCell >
                            <Typography variant="subtitle2" >{__('quantity')}</Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant="subtitle2" >{__('cost_product')}</Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant="subtitle2" >{__('cost_listed')}</Typography>
                        </TableCell>

                        <TableCell >
                            <Typography variant="subtitle2" >{__('total_price')}</Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                borderRadius: theme => theme.spacing(0, 1.5, 1.5, 0)
                            }}
                        >
                            <Typography variant="subtitle2" >{__('action_list')}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody className="table_body">
                    {orderProducts.length ? orderProducts.filter((res) => res.type !== TypeActionOrderList.DELETE).map((data, index) => (
                        <ItemTableDetailOrder
                            key={data.product_id}
                            data={data}
                            index={index}
                            handleChangeQuantityProduct={handleChangeQuantityProduct}
                            handleRemoveProductFromOrderList={handleRemoveProductFromOrderList}
                        />)) : null
                    }

                </TableBody>
            </TableContainer>
            {orderProducts.every((res) => res.type === TypeActionOrderList.DELETE) && (
                <Stack alignItems={'center'}>
                    <ComponentEmpty />
                </Stack>
            )}
        </>
    )
}

export default OrderDetailTable

const TableContainer = styled(Table)(({ theme }) => ({
    marginTop: theme.spacing(3),

    '& .table_header': {
        '& th': {
            padding: theme.spacing(1.25, 0.8),
            background: '#E9EBEE',


        },
        '& th:first-child': {
            padding: theme.spacing(1.25, 0.8, 1.25, 2),
        },
        '& th:last-child': {
            padding: theme.spacing(1.25, 0.8, 1.25, 2),
        }
    },

    '& .table_body': {
        '& input': {
            textAlign: 'center'
        }
    }

}))
