import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckSecondIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} stroke="currentColor">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L10.0607 18.3536C9.47487 18.9393 8.52513 18.9393 7.93934 18.3536L3.29289 13.7071C2.90237 13.3166 2.90237 12.6834 3.29289 12.2929C3.68342 11.9024 4.31658 11.9024 4.70711 12.2929L9 16.5858L19.2929 6.29289C19.6834 5.90237 20.3166 5.90237 20.7071 6.29289Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default CheckSecondIcon
