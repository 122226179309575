//Library
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  Modal,
  Paper,
  Stack,
  Switch,
  Typography,
  debounce,
  styled
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

//Components
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import TextFieldMUI from 'components/TextFieldMUI'

//Icons
import { CloseIcon, EditIcon, PlusIcon, TrashAltIcon } from 'icons'


import __ from 'languages/index'

import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll'
import { TypedProductCollection } from 'entities/shop/interface'
import { deleteCollectionById, getProductCollection } from '../service/product-collection.service'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { formatString, validateLabel } from 'entities/support/utils'
import { createCollection, updateCollection } from 'entities/shop/store/shop.store.reducer'
import { useAppDispatch } from 'config/store'
import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'
import { getChannelId } from '../../../store/user.store.reducer'
import { notEmpty } from '@shopify/react-form'
import DeliveryBoxIcon from '../../../icons/DeliveryBoxIcon'
import { useNavigate } from 'react-router-dom'
import ConfirmModal from 'components/ConfirmModal'
import NotificationModal from 'entities/shop/components/NotificationModal'

const ProductGroups = () => {
  const channelId = useSelector(getChannelId)
  const [isShowAddGroup, setIsShowAddGroup] = useState<boolean>(false)
  const [collection, setCollection] = useState<TypedProductCollection | null>(null)
  const [collectionId, setCollectionId] = useState<string >('')
  const [isShowModalNotification, setIsShowModalNotification] = useState<boolean>(false)

  const navigate = useNavigate()
  const toast = useToast()



  const {
    hasMore,
    isRefreshing,
    loadMore,
    data,
    refresh,
    deleteItemById
  } = useInfiniteScroll<TypedProductCollection>({
    channel_id: channelId,
    limit: 12,
    order_by: 'DESC',
    search: '',
    keyAttribute:"collection_id"
  }, getProductCollection)


  const handleOpenAddOrEditCollectionModal = (collection: TypedProductCollection | null) => {
    setCollection(collection)
    setIsShowAddGroup(true)
  }




  const handleCloseShowModal = useCallback(() => {
    setIsShowAddGroup(false)
  }, [])


  const handleClickDeleteCollection = useCallback(
    (collection: TypedProductCollection) => {
      if (collection?.collection_count <= 0) {
        setCollectionId(collection?.collection_id)
      } else {
        setIsShowModalNotification(true)
      }
    },
    [data]
  )

  const handleDeleteCollection = useCallback(async () => {
    try {
       await deleteCollectionById(collectionId)
        .then((res)=>{
          deleteItemById(collectionId)
          refresh(true)
          toast.show(
            {
              content: `${__("delete_collection_successfully")}`,
              type: EnumTypeToast.Success
            }
          )
        })
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        toast.show({
          type: EnumTypeToast.Error,
          content: __(message)
        })
      }
    }
    setCollectionId('')
  }, [collectionId])

  const handleClickDeliveryBox = (collection_id: string)=>{
    navigate(`/shop/admin/collection/${collection_id}/product-collection#groups`)
  }

  return (
    <ProductGroupsContainer id="productGroups">
      <Paper className="wrapper">
        <Stack display={'grid'} gridTemplateColumns={'auto auto'} justifyContent={'space-between'}>
          <Typography textTransform={'none'} variant="h5" fontWeight={700}>
            {__('product_groups')}
          </Typography>
          <Button
            onClick={() => handleOpenAddOrEditCollectionModal(null)}
            sx={{ p: theme => theme.spacing(0, 2) }}
            startIcon={<PlusIcon sx={{ color: theme => theme.palette.background.paper }} />}
          >
            <Typography textTransform={'none'} color={'background.paper'}>
              {__('add_group')}
            </Typography>
          </Button>
        </Stack>
        <Typography color={'text.secondary'}>{__('shop_product_groups_title')}</Typography>

          <List className="product_list">
            <InfiniteScrollContainer
              isRefreshing={isRefreshing}
              dataLength={data.length}
              next={loadMore}
              hasMore={hasMore}
              showEndMessage={false}
              refreshFunction={refresh}
              pullDownToRefresh={false}
            >
            {data?.map((item, idx) => (
              <React.Fragment key={idx}>
                <ListItem>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'grid',
                      columnGap: theme => theme.spacing(3),
                      height: theme => theme.spacing(5.5),
                      gridTemplateColumns: '1fr auto auto'
                    }}
                  >
                    <Box
                      display={'flex'}
                      overflow={'hidden'}
                      alignItems={'center'}
                    >
                      <Typography fontWeight={500} variant="inherit" noWrap>
                        {item?.collection_name || ''}
                      </Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                      <Paper
                        sx={{
                          p: theme => theme.spacing(0, 1),
                          background: theme => theme.palette.background.default
                        }}
                      >
                        <Typography fontWeight={500} color={'primary.main'} noWrap>
                          {item?.collection_count || ''}
                        </Typography>
                      </Paper>
                    </Box>
                    <Box display={'flex'} columnGap={theme => theme.spacing(2)}>
                      <IconButton
                        className="icon_button"
                        onClick={() => handleClickDeleteCollection(item)}
                      >
                        <TrashAltIcon
                          sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                          color="inherit"
                        />
                      </IconButton>
                      <IconButton className="icon_button edit" onClick={() => handleOpenAddOrEditCollectionModal(item)}>
                        <EditIcon
                          sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                          color="inherit"
                        />
                      </IconButton>
                      <IconButton
                        className="icon_button edit"
                        onClick={()=>handleClickDeliveryBox(item?.collection_id)}

                      >
                        <DeliveryBoxIcon
                          sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                          color="inherit"
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </ListItem>
              </React.Fragment>
            ))}
            </InfiniteScrollContainer>

          </List>

      </Paper>
      {isShowAddGroup && (
       <CreateEditCollectionModal
         isShowAddGroup={isShowAddGroup}
         handleCloseShowModal={handleCloseShowModal}
         collection={collection}
         refresh={refresh}
         setIsShowAddGroup={setIsShowAddGroup}
       />
      )}
      {Boolean(!!collectionId) && (
        <ConfirmModal
          onClose={() => setCollectionId('')}
          open={Boolean(!!collectionId)}
          onConfirm={handleDeleteCollection}
          title={__('delete_collection_title')}
          confirmTextBtn={__('btn_delete')}
          confirmText={__('delete_collection_confirm')}
        />
      )}

      {isShowModalNotification && (
        <NotificationModal
          handleCloseModalNotification={() => setIsShowModalNotification(false)}
          isShowModalNotification={isShowModalNotification}
          textContent={`${__('modal_notification_collection')}`}
        />
      )}
    </ProductGroupsContainer>
  )
}

export default ProductGroups

const  CreateEditCollectionModal = ({isShowAddGroup, handleCloseShowModal, collection,refresh, setIsShowAddGroup})=>{

  const [collectionStatus, setCollectionStatus] = useState<number>(collection?.collection_status ?? 1)
  const [processingError, setProcessingError] = useState<string | null>(null)
  const [validateError, setValidateError] = useState<string>('')
  const [addCollection, setAddCollection] = useState<TypedProductCollection>()
  const [creatingCollection, setCreatingCollection] = useState<boolean>(false)
  const [collectionName, setCollectionName] = useState<string>('')
  const [disable, setDisable] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(()=>{
    const isDisable = !collectionName || creatingCollection || (collection &&  collectionName === collection?.collection_name &&
      collectionStatus === collection?.collection_status)
    setDisable(isDisable)
  },[collectionName, creatingCollection,collection, collectionStatus])


  useEffect(() => {
    if (!collection?.collection_id) return
    setCollectionName(collection?.collection_name)
  }, [collection?.collection_id])
  const handleChangeCollectionName = (value: string)=>{
    setCollectionName(value)
  }

  const handleSubmitCollection = useCallback(async ()=>{
    try {
      setCreatingCollection(true)
      if (!collectionName) {
        throw new Error(__('error_required_name_collection'))
      }
      if (collection) {
        dispatch(updateCollection({
          collection_id: collection?.collection_id,
          params: {
            collection_name: collectionName,
            collection_status: collectionStatus
          }
        }))
          .unwrap()
          .then(res=>{
            setAddCollection(res?.data)
            setCollectionName('')
            setIsShowAddGroup(false)
            refresh(true)
            toast.show({
              type: EnumTypeToast.Success,
              content: formatString(__('update_collection_successfully'), res?.data?.collection_name)
            })

          })
          .catch((e: AxiosError)=>{
            console.log("updateCollection",e)
            toast.show({
              content: `${__("update_collection_failed")}`,
              type: EnumTypeToast.Error
            })
          })

      }else{
        dispatch(createCollection({
          collection_name: collectionName,
          collection_status: collectionStatus
        }))
          .unwrap()
          .then(res=>{
            setAddCollection(res?.data)
            setCollectionName('')
            setIsShowAddGroup(false)
            refresh(true)
            toast.show({
              type: EnumTypeToast.Success,
              content: formatString(__('create_collection_successfully'), res?.data?.collection_name)
            })

          })
          .catch((e: AxiosError)=>{
            if(e.message === 'invalid_value'){
              return setProcessingError(__("collection_invalid_value"))
            }
            if(e.message === 'internal_error'){
              return setProcessingError(__("collection_internal_error"))
            }
            toast.show({
              content: `${__("create_collection_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }

      setProcessingError(null)
    } catch (err) {
      if (err?.response?.data?.message) {
        return setProcessingError(__(err.response.data.message))
      } else {
        return setProcessingError(__(err.message))
      }
    } finally {
      setCreatingCollection(false)
    }
  },[collectionName, collectionStatus, collection, creatingCollection])

  const handleChangeSwitch = (event) => {

    if (event.target.checked) {
      setCollectionStatus(1)
    }else{
      setCollectionStatus(0)
    }
  }


  return(
    <AddGroupContainer open={isShowAddGroup} onClose={handleCloseShowModal}>
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(115.125),
          margin: '0 auto'
        }}
      >
        <Box className="header">
          <ModalHeader direction={'row'}>
            <Typography className="title">{Boolean(!!collection) ? `${__('edit_product_group')}`: `${__('add_product_group')}`}</Typography>
            <IconButton onClick={handleCloseShowModal}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider sx={{ opacity: 1 }} />
        </Box>
        <ModalBody className="body">
          <Box
            display={'grid'}
            p={theme => theme.spacing(2, 0)}
            justifyContent={'space-between'}
            gridTemplateColumns={theme => (`1fr repeat(1,minmax(${theme.spacing(40)}, auto))`)}
          >
            <Box>
              <TextFieldMUI
                autoFocus
                requiredIndicator
                error={validateError}
                maxLength={80}
                placeholder={__('product_group_name')}
                onChange={value => handleChangeCollectionName(value)}
                value={collectionName}
                onBlur={()=>{
                  if(!collectionName){
                    setValidateError(notEmpty(validateLabel(`${__("product_group_name")}`)?.required),)
                  }
                }}
              />
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                  value={collectionStatus}
                  inputProps={{ 'aria-label': 'ant design' }}
                  defaultChecked={Boolean(collectionStatus === 1)}
                  onChange={handleChangeSwitch}
                />
                <Typography fontWeight={500}>{__('display_at_the_booth')}</Typography>
              </Stack>
            </Box>

          </Box>

          <Typography variant={'caption'} color={'error.main'}>
            {processingError}
          </Typography>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            paddingBottom={theme => theme.spacing(2)}
          >
            <Button
              sx={{
                p: theme => theme.spacing(1.9375, 3)
              }}
              disabled={disable}
              onClick={handleSubmitCollection}
            >
              <Typography textTransform={'none'} fontWeight={500}>
                {__('save_group')}
              </Typography>
            </Button>
          </Box>
          {/*<Divider sx={{ opacity: 1 }} />*/}
          {/*<Box*/}
          {/*  p={theme => theme.spacing(2, 0)}*/}
          {/*  display={'grid'}*/}
          {/*  rowGap={theme => theme.spacing(1.875)}*/}
          {/*>*/}
          {/*  <Box maxWidth={theme => theme.spacing(33.5)}>*/}
          {/*    <Paper className="input_search">*/}
          {/*      <IconButton*/}
          {/*        aria-label="search"*/}
          {/*        sx={{*/}
          {/*          p: 0,*/}
          {/*          width: theme => theme.spacing(3),*/}
          {/*          height: theme => theme.spacing(3)*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <SearchIcon color="inherit" sx={{ width: '100%', height: '100%' }} />*/}
          {/*      </IconButton>*/}
          {/*      <InputBase*/}
          {/*        value={textSearch || ''}*/}
          {/*        onChange={handleChangeValue}*/}
          {/*        placeholder={__('search_product')}*/}
          {/*        inputProps={{ 'aria-label': __('search_product') }}*/}
          {/*      />*/}
          {/*    </Paper>*/}
          {/*  </Box>*/}
          {/*  <Typography textTransform={'none'} variant="subtitle2">*/}
          {/*    {__('products_in_group')}*/}
          {/*  </Typography>*/}
          {/*<Box display={'grid'} rowGap={theme => theme.spacing(0.75)}>*/}
          {/*  <TransferList*/}
          {/*    titleModal={__('transfer_all')}*/}
          {/*    confirmTextTransferLeft={__('add_product_confirm_title')}*/}
          {/*    confirmTextTransferRight={__('remove_product_confirm_title')}*/}
          {/*  />*/}
          {/*</Box>*/}
          {/*</Box>*/}
        </ModalBody>
      </ModalContainer>
    </AddGroupContainer>

  )
}

const ProductGroupsContainer = styled(Box)(({ theme }) => ({
  '& .wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .product_list': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(45)})`,
    overflow: 'auto',
    '& li': {
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${theme.palette.divider}`,
      ':last-child': {
        borderBottom: 'none'
      }
    }
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .edit': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

const AddGroupContainer = styled(Modal)(({ theme }) => ({
  '& .header': {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    background: theme.palette.background.paper,
    padding: 0
  },
  '& .title': {
    textTransform: 'none',
    variant: 'h6',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center'
  },
  '& .body': {
    padding: theme.spacing(0, 3),
    maxHeight: `calc(100% - ${theme.spacing(10)})`,
    overflowY: 'auto'
  },
  '& .button_wrapper': {
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    padding: theme.spacing(0, 2),
    border: `1px dashed ${theme.palette.primary.main}`,
    '& .upload_wrapper': {
      display: 'grid',
      justifyItems: 'center',
      rowGap: theme.spacing(1)
    }
  },
  '& .input_search': {
    height: theme.spacing(5.75),
    padding: theme.spacing(1, 2),
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
  }
}))

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: theme.spacing(5.5),
  height: theme.spacing(2.75),
  borderRadius: theme.spacing(2),
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: theme.spacing(1.875)
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: `translateX(${theme.spacing(1.125)})`
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: `translateX(${theme.spacing(2.75)})`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}))
