import React, { useEffect, useMemo, useState } from 'react'
import Tree from 'react-d3-tree'
import './media/custom-tree.scss'
import Node from './Node'
import Combobox from 'components/Combobox'
import { useSelector } from 'react-redux'
import { getChannelId, getPermissionData, getUserId } from '../../store/user.store.reducer'
import {
  useGetReferredDataByIdQuery,
  useGetReferrerListQuery
} from 'entities/ReferrerTree/store/referrerApi'
import Container from 'react-bootstrap/Container'
import { ReferredUser } from 'entities/ReferrerTree/referrer.types'
import RenderView from 'components/RenderView'
import { useCenteredTree } from './helper/userCenteredTree'
import Avatar from 'components/Avatar'
import { skipToken } from '@reduxjs/toolkit/query'
import __helpers from 'helpers/index'
import MaxLayout from "../../layouts/maxLayout";

type Node = {
  id: string | number
  name: string
  attributes?: Record<string, string | number | boolean>
  children?: Node[]
}
export type TreeData = Node

const transformToReferred = (user: ReferredUser) => {
  return {
    id: user.id,
    name: user.display_name,
    attributes: {
      avatar: user.user_avatar_thumbnail,
      department: user.channel_role
    },
    children: user.referredList?.map(user => transformToReferred(user))
  }
}

const FamilyTree = () => {
  const channelId = useSelector(getChannelId)
  const permissionData = useSelector(getPermissionData)
  const userId = useSelector(getUserId)

  //Todo: update role 'mentor' to 'admin' after BE update
  const isAdmin = __helpers.current_user_can('boss', permissionData)

  const [treeData, setTreeData] = useState<TreeData>({} as TreeData)
  const [comboBoxValue, setComboBoxValue] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [translate, containerRef] = useCenteredTree()

  const { data: referrals } = useGetReferrerListQuery(
    { channelId },
    { skip: !channelId || !isAdmin }
  )
  const {
    data: referredData,
    isLoading: loadingReferredData,
    isFetching: fetchingReferredData
  } = useGetReferredDataByIdQuery(
    { userId: isAdmin ? comboBoxValue : userId },
    { skip: isAdmin ? !comboBoxValue : !userId }
  )

  const referredList = referredData?.referredList
  const selectedUser = referredData?.user

  const transformReferralDataToTreeData = useMemo(() => {
    return {
      name: selectedUser?.display_name,
      id: selectedUser?.id,
      attributes: {
        department: selectedUser?.channel_role,
        avatar: selectedUser?.user_avatar_thumbnail
      },
      children: referredList?.map(user => transformToReferred(user))
    }
  }, [referredList])

  const handleSearchUser = (value: string) => {
    setSearchTerm(value)
  }

  //Todo: handle search user

  useEffect(() => {
    setTreeData(transformReferralDataToTreeData)
  }, [transformReferralDataToTreeData])

  const renderTree = () => {
    return (
      <div id="treeWrapper" ref={containerRef as any}>
        <Tree
          translate={translate as any}
          data={treeData}
          renderCustomNodeElement={props => <Node {...props} />}
          branchNodeClassName="node__branch"
          initialDepth={1}
        />
      </div>
    )
  }

  return (
    <MaxLayout>
      <Container className="py-3">
        <h1 className="mb-3">Referral</h1>
        {isAdmin && (
          <Combobox
            value={comboBoxValue}
            onChange={option => setComboBoxValue(option.id)}
            inputValue={searchTerm}
            onInputChange={handleSearchUser}
            options={referrals}
            getOptionLabel={option => option.display_name}
            renderOption={option => (
              <div key={option.id}>
                <Avatar width={30} height={30} src={option.user_avatar_thumbnail} roundedCircle />
                <span className="ms-2">{option.display_name}</span>
              </div>
            )}
          />
        )}
        <RenderView
          view={renderTree()}
          isLoading={loadingReferredData || fetchingReferredData}
          isEmpty={Object.keys(treeData)?.length === 0}
        />
      </Container>
    </MaxLayout>

  )
}

export default FamilyTree
