import '../media/gift.list.item.scss'
import React, {useMemo} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAppSelector} from 'config/store'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  EnumGiftConditionType,
  GiftConditionTypeIcon,
  GiftConditionTypeTitle
} from 'entities/gift/constants/gift.constant'
import helpers from 'helpers/index'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {TypedGift} from 'entities/gift/gift.interface'
import __ from 'languages/index'
import { Box, Button, Typography } from '@mui/material'

export default function GiftListItem({ item }: { item: TypedGift }) {
  const permission_data = useAppSelector(state => state.user.permission_data)
  const navigate = useNavigate()


  const handleClickViewGift = () => {
    navigate(`/gift/detail/${item._id}`)
  }

  return (
    <div className="giftlistitem my-2 col-sm-6 col-lg-4">
      <div className="giftlistitem_container">
        <div className="giftlistitem_container_img clickable" onClick={handleClickViewGift}>
          <img src={item?.media_id?.media_url} className="giftlistitem_img clickable" alt="" />
          <div className="giftlistitem_container_condition">
            <ul>
              {Object.keys(item.gift_conditions).map((key, index) =>
                'start_time|end_time|_id'.includes(key) ? null : (
                  <Box component={'li'} sx={{color: theme=>`${theme.palette.primary.main} !important`}} key={index.toString()}>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip>
                          <strong>
                            {__(GiftConditionTypeTitle[key]) +
                              (key !== EnumGiftConditionType.Birth
                                ? ': ' + item.gift_conditions[key]
                                : '')}
                          </strong>
                        </Tooltip>
                      }
                    >
                      <div
                        className={`w-100 d-flex align-items-center ${
                          key === EnumGiftConditionType.Birth
                            ? 'justify-content-center'
                            : 'justify-content-between'
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={GiftConditionTypeIcon[key]}
                          className={`${
                            key !== EnumGiftConditionType.Birth ? 'me-2' : 'my-2 mx-2'
                          } giftlistitem_icon`}
                        />{' '}
                        {key !== EnumGiftConditionType.Birth && item.gift_conditions[key]}
                      </div>
                    </OverlayTrigger>
                  </Box>
                )
              )}
            </ul>
          </div>
        </div>

        <div className="giftlistitem_bottom d-flex flex-column justify-content-between">
          <div
            className="d-flex flex-column align-items-center clickable"
            onClick={handleClickViewGift}
          >
            <div className="giftlistitem_title">{item.name}</div>
            <Box>
              <Typography className='giftlistitem_price' color={theme=>`${theme.palette.primary.main} !important`}>
                {helpers.numberWithCommas(item.price)} {__("currency_unit")}
              </Typography>
            </Box>
            <div className="giftlistitem_received">{`${__("gift_quantity_text")} ${item.stock_qty} ${__("gift_quantity")}`}</div>
          </div>

          {helpers.current_user_can("boss", permission_data)? (
            <Button component={Link} variant='text' sx={{textTransform: 'none'}} to={'/gift/update/' + item?._id} className="d-flex align-items-center justify-content-center giftlistitem_container_status btn-primary">
              {__("gift_update")}
            </Button>
          ) : (
            <Button variant='text' sx={{textTransform: 'none'}} onClick={handleClickViewGift} className="d-flex align-items-center justify-content-center giftlistitem_container_status btn-primary">
              {__("gift_detail")}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
