import __ from 'languages/index'


export const formatTime = (milliseconds: any) => {
  try {
    if(milliseconds === undefined || milliseconds === null) return "--:--"

    let length = parseInt(milliseconds?.toString());
    if (Number.isNaN(length)) {
      return "--:--"
    }

    // to seconds
    length = Math.floor(length / 1000);

    if (length < 60) {
      return length + ` ${__("format_time_second")}`
    }

    if (length < 3600) {
      return Math.floor(length / 60) + ` ${__("format_time_minute")} ` + (length % 60) + ` ${__("format_time_second")}`
    }

    if (length < 86400)
      return Math.floor(length / 3600) + ` ${__("format_time_hour")} ` + Math.floor((length % 3600) / 60) + ` ${__("format_time_minute")} ` + ((length % 3600) % 60) + ` ${__("format_time_second")}`

    return Math.floor(length / 86400) + ` ${__("format_time_date")} ` + Math.floor((length % 86400) / 3600) + ` ${__("format_time_hour")} ` + Math.floor((((length % 86400) % 3600)) / 60) + ` ${__("format_time_minute")} ` + ((((length % 86400) % 3600)) % 60) + ` ${__("format_time_second")}`

  } catch (err: any) {
    console.log(err, "flbndskudisf")
    return "--:--"
  }
}

declare global {
  interface String {
    fromCharCodeS(...args: number[]): string;
  }
}
