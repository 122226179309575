import React, {memo, useCallback, useEffect, useMemo, useRef} from 'react'
import '../media/livestream.room.scss'
import '../media/livestream.chat.scss'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBackward, faCalendarDay, faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import {EnumStatusLivestream, TypedLiveChat} from 'entities/livestream/livestream.interface'
import {getListChatLivestream, sendLiveChat} from 'entities/livestream/services/livestream.service'
import {Socket} from 'socket.io-client'
import LivestreamLivechatItem from 'entities/livestream/components/livestream.livechat.item'
import {Link} from 'react-router-dom'
import {useInfiniteScroll} from '../../../hooks/useInfiniteScroll'
import {TypedGiftTransaction} from 'entities/gift/gift.interface'
import {getDetailLiveStream} from '../livestream.store.reducer'
import {useSelector} from 'react-redux'
import __ from "languages/index";
import helpers from 'helpers'
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";

interface TypedLivestreamRoomChatProps {
  socket: Socket
}

const LivestreamChat = ({ socket }: TypedLivestreamRoomChatProps) => {
  const liveDetail = useSelector(getDetailLiveStream)
  const event_id = liveDetail?.event_data?._id

  const isLiveBelongToEvent = liveDetail?.event_data

  const refValueChat = useRef('')
  const refInputChat = useRef<HTMLInputElement>()

  const getDataChat = useCallback(
    (param: object) => {
      return getListChatLivestream(param, liveDetail?._id)
    },
    [liveDetail?._id]
  )

  const { data, isRefreshing, hasMore, refresh, loadMoreFromLastItem, addHead } = useInfiniteScroll<TypedGiftTransaction>(
    {
      limit: 20,
      order_by: 'DESC'
    },
    getDataChat,
    false
  )

  useEffect(() => {
    if (liveDetail?._id) {
      refresh()
    }
  }, [liveDetail?._id])

  useEffect(() => {
    socket.on('livestreamToClient', data => {
      console.log('livestreamToClient')
      console.log(data)
      try {
        if(!helpers.isJson(data)) return
        if (typeof JSON.parse(data) === 'object') {
          addHead(JSON.parse(data))
        }
      } catch (error) {
        console.log(error, 'sljnfjbsdf')
      }
    })
  }, [])

  const onSendChat = useCallback(() => {
    if (refValueChat.current.trim()) {
      sendLiveChat(refValueChat.current, liveDetail?._id)
      refValueChat.current = ''
      refInputChat.current.value = ''
    }
  }, [liveDetail?._id])

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onSendChat()
      }
    },
    [liveDetail?._id]
  )

  const onChangeText = useCallback((event: any) => {
    refValueChat.current = event?.target?.value
  }, [])

  const renderItemChat = useCallback(
    (chatItem: TypedLiveChat) => {
      return (
        <LivestreamLivechatItem
          isAdmin={chatItem?.createBy?._id === liveDetail?.user_id?._id}
          key={chatItem?._id}
          item={chatItem}
        />
      )
    },
    [liveDetail]
  )


  const InputOrBackToEventButton = useMemo(() => {
    if (liveDetail?.livestream_status === EnumStatusLivestream.Live) {
      return (
        <div className="d-flex w-100 justify-content-between py-2 ps-3 pe-1 align-items-center">
          <input
            type="text"
            maxLength={100}
            onKeyDown={onKeyDown}
            ref={refInputChat}
            onChange={onChangeText}
            className="livestreamroom_input"
            placeholder="Hãy nói gì đó (100 ký tự)"
          />

          <Button className="bg-transparent border-0" onClick={onSendChat}>
            <FontAwesomeIcon icon={faPaperPlane} className="fs-5" />
          </Button>
        </div>
      )
    }

    return (
      <Link
        to={isLiveBelongToEvent ? '/event/detail/' + event_id : '/'}
        className="btn btn-primary m-3 py-2"
      >
        <FontAwesomeIcon icon={faBackward} style={{ marginRight: 10 }} />
        {__("livestream_back_to_event")}
      </Link>
    )
  }, [liveDetail])

  const HeaderChat = useMemo(() => {
    return (
      <div className="livestream_header px-3">
        <b>{__("livestream_live_chat")}</b>

        <Button disabled className="livestreamroom_btn invisible pe-none">
          <FontAwesomeIcon icon={faCalendarDay} className="me-2 icon_btn" />
          {__("livestream_interact")}
        </Button>
      </div>
    )
  }, [])

  const LoadingMore = useMemo(() => {
    return <div className="px-2 py-2 text-center small text-white">{__("loading_more")}</div>
  }, [])

  return (
    <div id={'livestreamChat'}>
      {HeaderChat}

      <div className="livestreamroom_chat_container" id={"scrollableDiv"}>
        {liveDetail?.livestream_status &&
        liveDetail?.livestream_status !== EnumStatusLivestream.Wait ? (
          <InfiniteScrollContainer
            isRefreshing={isRefreshing}
            dataLength={data.length}
            next={loadMoreFromLastItem}
            hasMore={hasMore}
            refreshFunction={refresh}
            scrollableTarget={"scrollableDiv"}
          >
            {data.map(renderItemChat)}
          </InfiniteScrollContainer>
        ) : (
          <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
            <h4 className="text-white" style={{ textAlign: 'center' }}>
              {__("livestream_prepare_start")}
            </h4>
          </div>
        )}

        {InputOrBackToEventButton}
      </div>
    </div>
  )
}

export default memo(LivestreamChat)
