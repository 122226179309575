//Library
import { Badge, Box, Button, IconButton, InputBase, Paper, styled, Typography } from '@mui/material'
import React, { KeyboardEvent, KeyboardEventHandler, useCallback, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import { useNavigate } from 'react-router-dom'

//Icons
import { MessageWritingIcon, SearchIcon, ShoppingBasketIcon } from 'icons'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { getNumberOfCartItems } from 'entities/shop/store/cart/cart.slice'

import __ from 'languages/index'

const ShopHeader = ({ titleHeader, sort = "", handleFilterByField }: { titleHeader?: string, sort?: string, handleFilterByField?: (type, value) => void }) => {
  const navigate = useNavigate()
  const numberOfCartItems = useAppSelector(getNumberOfCartItems)
  const [textSearch, setTextSearch] = useState<string>(sort)

  const requestSearch = ({ key, keyCode }: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (textSearch.length && key === 'Enter' && keyCode === 13) {
      if (handleFilterByField) {
        handleFilterByField('query', textSearch)
      } else {
        navigate(`/shop/search?query=${textSearch}`)
      }
    }
  }

  const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value)
  }, [])

  return (
    <ShopHeaderContainer
      display={'flex'}
      justifyContent={titleHeader ? 'space-between' : 'flex-end'}
      width={'100%'}
    >
      {titleHeader && (
        <Typography textTransform={'none'} variant="h5">
          {titleHeader || ''}
        </Typography>
      )}
      <Box columnGap={2} display={'flex'}>
        <Box maxWidth={theme => theme.spacing(33.5)}>
          <Paper elevation={1} className="input_search">
            <IconButton
              aria-label="search"
              sx={{ p: 0, width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
            >
              <SearchIcon color="inherit" sx={{ width: '100%', height: '100%' }} />
            </IconButton>
            <InputBase
              value={textSearch || ''}
              onChange={handleChangeValue}
              onKeyUp={requestSearch}
              placeholder={__('search_product')}
              inputProps={{ 'aria-label': __('search_product') }}
            />
          </Paper>
        </Box>
        <Button
          onClick={() => navigate('/shop/my-order')}
          variant="text"
          className="button"
          startIcon={<MessageWritingIcon sx={{ color: theme => theme.palette.text.primary }} />}
        >
          <Typography variant="body1" textTransform={'none'} color="text.primary" fontWeight={600}>
            {__('order')}
          </Typography>
        </Button>
        <Button
          onClick={() => navigate('/shop/cart')}
          variant="text"
          className="button"
          startIcon={
            <Badge badgeContent={numberOfCartItems} color="primary">
              <ShoppingBasketIcon sx={{ color: theme => theme.palette.text.primary }} />
            </Badge>
          }
        >
          <Typography variant="body1" textTransform={'none'} color="text.primary" fontWeight={600}>
            {__('cart')}
          </Typography>
        </Button>
      </Box>
    </ShopHeaderContainer>
  )
}

export default ShopHeader

const ShopHeaderContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .input_search': {
    height: theme.spacing(5.75),
    padding: theme.spacing(1, 2),
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`
  },
  '& .button': {
    display: 'flex',
    height: '100%',
    columnGap: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper
  }
}))
