import { PaletteMode } from '@mui/material'
import { darkThemeOptions, lightThemeOptions } from './theme'

export const getDesignTokens = (
  mode: PaletteMode,
  {
    lightPalette,
    darkPalette
  }: {
    lightPalette?: typeof lightThemeOptions.palette
    darkPalette?: typeof darkThemeOptions.palette
  } = { lightPalette: lightThemeOptions.palette, darkPalette: darkThemeOptions.palette }
) => {
  return {
    palette: {
      mode,
      ...(mode === 'light' ? lightPalette : darkPalette)
    }
  }
}
