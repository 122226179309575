import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckInactiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} stroke="currentColor">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="currentColor"
      >
        <rect y="0.5" width="16" height="16" rx="8" />
        <path
          d="M10.6061 5.37116L6.68247 10.3416L4.86214 8.03514L4.74431 8.12794L4.86204 8.03502L4.86209 8.03508C4.81876 7.98009 4.76353 7.93561 4.70056 7.90501C4.63757 7.87439 4.56845 7.85844 4.49841 7.85835H4.49823H3.81296C3.62216 7.85835 3.51554 8.0782 3.63341 8.22769L3.63346 8.22775L6.31865 11.6296L6.31869 11.6296C6.50447 11.8648 6.86056 11.864 7.04685 11.6301L7.04726 11.6296L11.8345 5.56322C11.8346 5.56299 11.8348 5.56277 11.835 5.56254C11.9553 5.41188 11.8439 5.19375 11.6558 5.19375H10.9705C10.8289 5.19375 10.694 5.25861 10.6064 5.37077C10.6063 5.3709 10.6062 5.37103 10.6061 5.37116Z"
          fill="white"
          stroke="white"
          strokeWidth="0.3"
        />
      </svg>
    </SvgIcon>
  )
}

export default CheckInactiveIcon
