import { useAppDispatch, useAppSelector } from "config/store";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getEntity, deleteEntity, createEntity } from './example.store.reducer';
import DeleteConfirm from "components/deleteConfirm";


export default function ExampleView() {

const entity = useAppSelector(state => state.example.entity);
const loading = useAppSelector(state => state.example.loading);
const updating = useAppSelector(state => state.example.updating);
const errorMessage = useAppSelector(state => state.example.errorMessage);
const dispatch = useAppDispatch();
/**
 * Lấy URL ra... để query. chú ý phải load nội dung mới
 * trong useEffect
 */
let useParam = {} as any;
useParam = useParams();
let Param = useParam.example_id || false;

useEffect(() => {
  dispatch(getEntity(Param));
}, [Param]);

const fetchNewSampleData = useCallback( (_id: string) => {
    dispatch( deleteEntity(_id) )
}, []);


const createNewSampleData = useCallback( () => {
    dispatch( createEntity({}) );
}, []);

const [showModal, setShowModal] = useState(false);
const [showToast, setShowToast] = useState(false);

const onDeleteAgree = () => {}

return (
    <>

        <DeleteConfirm show={showModal} onClose={(result: boolean) => {
            setShowModal(false);
            if ( result === true)
                onDeleteAgree();
            } } title={"Bạn chắc chắn muốn xóa?"} />
        <p>Bạn đang xem trang chi tiết Example: ID là {Param}</p>
        <Link to={''} onClick={() => setShowModal(true) }>Click là mở hộp thoại</Link>
        <br /><br />
        <Link to={''} onClick={() => fetchNewSampleData('122222')}>Click là báo lỗi</Link>
        <br /><br />
        <Link to={''} onClick={() => setShowToast(true)}>Click là show toast</Link>
        <br /><br />
        <Link to={''} onClick={() => createNewSampleData()}>Click là check Auth</Link>. Nếu chưa đăng nhập sẽ bị chuyển sang trang đăng nhập...Còn không thì báo lỗi bên dưới ...
        <br /><br />
        <div style={{color: 'red'}}>
            {errorMessage}
        </div>
        <br />
        <Link to={'/'}>Trở về trang chủ</Link>
        <br /><br />
    </>
)

}
