import { SvgIcon, SvgIconProps } from '@mui/material'

const MinimizeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.74408 17.2559C2.41864 16.9305 2.41864 16.4028 2.74408 16.0774L5.48815 13.3333L3.33333 13.3333C2.8731 13.3333 2.5 12.9602 2.5 12.5C2.5 12.0398 2.8731 11.6667 3.33333 11.6667L6.76343 11.6667C7.63047 11.6667 8.33333 12.3695 8.33333 13.2366L8.33333 16.6667C8.33333 17.1269 7.96024 17.5 7.5 17.5C7.03976 17.5 6.66667 17.1269 6.66667 16.6667L6.66667 14.5118L3.92259 17.2559C3.59715 17.5814 3.06951 17.5814 2.74408 17.2559ZM2.5 7.5C2.5 7.03976 2.8731 6.66667 3.33333 6.66667L5.48816 6.66667L2.74408 3.92259C2.41864 3.59715 2.41864 3.06952 2.74408 2.74408C3.06951 2.41864 3.59715 2.41864 3.92259 2.74408L6.66667 5.48816L6.66667 3.33333C6.66667 2.8731 7.03976 2.5 7.5 2.5C7.96024 2.5 8.33333 2.8731 8.33333 3.33333L8.33333 6.76343C8.33333 7.63047 7.63046 8.33333 6.76343 8.33333L3.33333 8.33333C2.8731 8.33333 2.5 7.96024 2.5 7.5ZM12.5 17.5C12.0398 17.5 11.6667 17.1269 11.6667 16.6667L11.6667 13.2366C11.6667 12.3695 12.3695 11.6667 13.2366 11.6667L16.6667 11.6667C17.1269 11.6667 17.5 12.0398 17.5 12.5C17.5 12.9602 17.1269 13.3333 16.6667 13.3333L14.5118 13.3333L17.2559 16.0774C17.5814 16.4028 17.5814 16.9305 17.2559 17.2559C16.9305 17.5814 16.4028 17.5814 16.0774 17.2559L13.3333 14.5118L13.3333 16.6667C13.3333 17.1269 12.9602 17.5 12.5 17.5ZM13.2366 8.33333C12.3695 8.33333 11.6667 7.63046 11.6667 6.76343L11.6667 3.33333C11.6667 2.8731 12.0398 2.5 12.5 2.5C12.9602 2.5 13.3333 2.8731 13.3333 3.33333L13.3333 5.48816L16.0774 2.74408C16.4028 2.41864 16.9305 2.41864 17.2559 2.74408C17.5814 3.06951 17.5814 3.59715 17.2559 3.92259L14.5118 6.66667L16.6667 6.66667C17.1269 6.66667 17.5 7.03976 17.5 7.5C17.5 7.96024 17.1269 8.33333 16.6667 8.33333L13.2366 8.33333Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default MinimizeIcon
