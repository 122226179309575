import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography
} from '@mui/material'
import {ModalBody, ModalContainer, ModalHeader} from 'components/Modal'
import TextField from 'components/TextFieldMUI'
import {CloseIcon, PlusIcon, SearchIcon} from 'icons'
import __ from 'languages/index'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TypeAddess, TypedCustomer, TypeDistrict, TypeWard } from '../interface'
import {convertData} from '../helper'
import addressData from '../../challenge/media/diachinh.json'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import helpers from 'helpers'
import ExternalCustomersTable from 'entities/shop/components/ExternalCustomersTable'
import { getCustomers } from 'entities/shop/service/external-customer.service'
import {useSelector} from 'react-redux'
import {getChannelId} from '../../../store/user.store.reducer'
import DefaultSkeleton from 'components/default.skeleton'
import ComponentEmpty from 'components/componentEmpty'
import { formatString, validateLabel } from 'entities/support/utils'
import SelectComponent from 'entities/shop/components/SelectComponent'
import { addCustomers, updateCustomers } from 'entities/shop/store/shop.store.reducer'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { AxiosError } from 'axios'
import { useAppDispatch } from 'config/store'
import debounce from 'lodash.debounce'
import { IQueryParams } from 'config/reducer.utils'

const ExternalCustomers = () => {
  const channelId = useSelector(getChannelId)
  const [listCustomer, setListCustomer] = useState<TypedCustomer[]>()
  const [isShowAddCustomers, setIsShowAddCustomer] = useState<boolean>(false)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [totalCustomer, setTotalCustomer] = useState<number>()
  const [dataUpdate, setDataUpdate] = useState<TypedCustomer>()
  const [customer, setCustomer] = useState<TypedCustomer>()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)


  const [paramRequest, setParamRequest] = useState<IQueryParams>({
    channel_id: channelId,
    order_by: 'DESC',
    limit: 10,
    page: 1
  })


  useEffect(() => {
    const getListCustomer = async () => {
      try {
        // setIsRefresh(true)
        await getCustomers(paramRequest)
          .then((res)=>{
            setListCustomer(res?.data)
            setTotalCustomer(Number(res.headers['x-total-count']))
          })

      } catch (err) {
        console.log('category', err)
      } finally {
        // setIsRefresh(false)
      }
    }
    getListCustomer()

  }, [dataUpdate, isDelete, paramRequest])

  const reduceRequestSearch = useMemo(() => {
    return debounce(_value => {
      setParamRequest(oldParam => ({ ...oldParam,query: _value }))
    }, 500)
  }, [])

  const handleSearchCustomer = (event) => {
    const inputValue = event.target.value;
    reduceRequestSearch(inputValue)
  };

  const handleCloseModal = useCallback(() => {
    setIsShowAddCustomer(false)
    setCustomer(null)
    setIsEdit(false)
  }, [])


  return (
    <ExternalCustomersContainer>
      <Paper className="wrapper">
        <Stack
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          width={'100%'}
          padding={theme => theme.spacing(0, 2)}
        >
          <Typography
            paddingBottom={theme => theme.spacing(3)}
            textTransform={'none'}
            variant="h5"
            fontWeight={700}
          >
            {__('external_customers')}
          </Typography>
          <Stack direction={'row'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{maxWidth: theme => theme.spacing(60)}}
            >
              <OutlinedInput
                size="small"
                type="search"
                id="input_search"
                className="input_search"
                placeholder={__('manage_product')}
                autoFocus
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" sx={{ padding: 0, width: theme => theme.spacing(2.375)}} >
                      <SearchIcon sx={{ width: '100%', height: '100%', marginRight: theme => theme.spacing(1)}} color="inherit" />
                    </IconButton>
                  </InputAdornment>}
                aria-describedby="outlined-helper-text"
                sx={{borderRadius: theme => theme.spacing(1.5)}}
                onChange={handleSearchCustomer}
              />
              <Typography
                variant="body1"
                color={'primary.main'}
                sx={{
                  backgroundColor: theme => theme.palette.background.default,
                  borderRadius: theme => theme.spacing(1.5),
                  padding: theme => theme.spacing(1,2)
                }}
              >
                {formatString(__('customer_total_count'), totalCustomer || '0')}
              </Typography>
            </Box>

            <Button
              sx={{ padding: theme => theme.spacing(1, 1) }}
              startIcon={<PlusIcon sx={{ color: theme => theme.palette.background.paper }} />}
              onClick={()=> setIsShowAddCustomer(true)}
            >
              <Typography textTransform={'none'} color={'background.paper'}>
                {__('add_customers')}
              </Typography>
            </Button>
          </Stack>
        </Stack>
        {isRefresh ? <DefaultSkeleton/> : helpers.isEmpty(listCustomer) ?
          <ComponentEmpty/> : (
            <ExternalCustomersTable
              setIsEdit={setIsEdit}
              setIsDelete={setIsDelete}
              setCustomer={setCustomer}
              paramRequest={paramRequest}
              totalCustomer={totalCustomer}
              setParamRequest={setParamRequest}
              listCustomer={listCustomer}
              setIsShowAddCustomer={setIsShowAddCustomer}
            />
          )}
      </Paper>
      {isShowAddCustomers && (
        <ModalAddCustomers
          customer={customer}
          isEdit={isEdit}
          setDataUpdate={setDataUpdate}
          title={
          ! isEdit ? `${__('add_new_customers')}` :
            `${__('edit_customers')}`}
          isShow={isShowAddCustomers}
          onClose={handleCloseModal}
        />
      )}
    </ExternalCustomersContainer>
  )
}

export default ExternalCustomers


const ModalAddCustomers = ({ title, isShow, onClose, isEdit, customer, setDataUpdate }) => {
  const [cityName, setCityName] = React.useState<string>('')
  const [districtName, setDistrictName] = React.useState<string>('')
  const [wardName, setWardName] = React.useState<string>('')
  const [listAddress, setListAddress] = React.useState<TypeAddess[]>([])
  const [districtByCity, setDistrictByCity] = React.useState<TypeDistrict[]>([])
  const [wardByDistrict, setWardByDistrict] = React.useState<TypeWard[]>([])

  const dispatch = useAppDispatch();
  const toast = useToast()

  const stockDefaultCity = (listAddress?.length>0 && isEdit) && listAddress?.find((item)=>item?.code === customer?.customer_address_city )
  const stockDefaultDistrict = (districtByCity?.length>0 && isEdit) && districtByCity?.find(item => item?.code === customer?.customer_address_district)
  const stockDefaultWard = (wardByDistrict?.length>0 && isEdit) && wardByDistrict?.find(item =>     item?.code === customer?.customer_address_ward)

  const { fields, submit, submitting, dirty, reset, validate } = useForm({
    fields: {
      customer_display_name: useField<string>({
        value: (isEdit && customer?.customer_display_name) ? customer?.customer_display_name : '',
        validates: [
          notEmpty(validateLabel(`${__("customer_name")}`)?.required),
          inputVal => {
            if (!inputVal.trim()) {
              return validateLabel(__('customer_name'))?.required

            }
          }
        ]
      }),
      customer_email: useField<string>({
        value: (isEdit && customer?.customer_email) ? customer?.customer_email : '',
        validates: [
          notEmpty(validateLabel(`${__("Email")}`)?.required),
          inputVal => {
            if (!inputVal.trim()) {
              return validateLabel(__('Email'))?.required

            }
            if (!helpers.isEmail(inputVal)) {
              return `${__('Email_validate')}`
            }
          }
        ]
      }),
      customer_phonenumber: useField<string>({
        value:  (isEdit && customer?.customer_phonenumber) ? customer?.customer_phonenumber : '',
        validates: [
          notEmpty(validateLabel(`${__("phone_number")}`)?.required),
          inputVal => {
            if (!helpers.isPhoneNumber(inputVal)) {
              return `${__('phone_number_validate')}`
            }
          }
        ]
      }),
      // customer_birthday: useField<string>({
      //   value: (isEdit && customer?.customer_birthday) ? customer?.customer_birthday : '',
      //   validates: []
      // }),
      // customer_gender: useField<string>({
      //   value: (isEdit && customer?.customer_gender) ? customer?.customer_gender : '',
      //   validates: []
      // }),
      customer_address: useField<string>({
        value: (isEdit && customer?.customer_address) ? customer?.customer_address : '',
        validates: [
          notEmpty(validateLabel(`${__("add_customer_address")}`)?.required),
          inputVal => {
            if (!inputVal.trim()) {
              return validateLabel(__('add_customer_address'))?.required
            }
            if (inputVal.trim().length < 3) {
              return `${__('address_validate')}`
            }
          }
        ]
      }),
      customer_address_district: useField<string>({
        value: (isEdit && customer?.customer_address_district) ? customer?.customer_address_district : '',
        validates: [
          notEmpty(validateLabel(`${__("select_district")}`)?.required),
          inputVal => {
            if (!inputVal) {
              return validateLabel(__('select_district'))?.required

            }
          }
        ]
      }),
      customer_address_ward: useField<string>({
        value: (isEdit && customer?.customer_address_ward) ? customer?.customer_address_ward :  '',
        validates: [
          notEmpty(validateLabel(`${__("select_ward")}`)?.required),
          inputVal => {
            if (!inputVal) {
              return validateLabel(__('select_ward'))?.required

            }
          }
        ]
      }),
      customer_address_city: useField<string>({
        value: (isEdit && customer?.customer_address_city) ? customer?.customer_address_city : '',
        validates: [
          notEmpty(validateLabel(`${__("select_city")}`)?.required),
          inputVal => {
            if (!inputVal) {
              return validateLabel(__('select_city'))?.required

            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          customer_display_name: values?.customer_display_name?.trim(),
          customer_email: values?.customer_email?.trim(),
          customer_phonenumber: values?.customer_phonenumber,
          // customer_birthday: values.customer_birthday,
          // customer_gender: values?.customer_gender,
          customer_address: values?.customer_address?.trim(),
          customer_address_district: values?.customer_address_district,
          customer_address_city: values?.customer_address_city,
          customer_address_ward: values?.customer_address_ward,
        }
        if(isEdit){
          await  dispatch(updateCustomers({params : formData, address_id : customer.address_id }))
            .unwrap()
            .then((res)=>{
              setDataUpdate(res?.data)
              reset()
              toast.show({
                content: `${__("edit_customer_success")}`,
                type: EnumTypeToast.Success
              })
            })
            .catch((e: AxiosError)=>{
              console.log('updateCustomers', e);
              toast.show({
                content: `${__("edit_customer_failed")}`,
                type: EnumTypeToast.Error
              })
            })

        } else {
          await dispatch(addCustomers(formData))
            .unwrap()
            .then((res)=>{
              setDataUpdate(res?.data)
              reset()
              toast.show({
                content: `${__("add_customer_success")}`,
                type: EnumTypeToast.Success
              })
            })
            .catch((e: AxiosError)=>{
              console.log('addCustomers', e);
              toast.show({
                content: `${__("add_customer_failed")}`,
                type: EnumTypeToast.Error
              })
            })
        }
        // setIsLoading(true)
        // // await helpers.sleep(500)
        // setIsLoading(false)
        reset()
      } catch (e: any) {
        console.error(` createCustomerSubmit`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
      return { status: 'success' }
    }
  })

  const handleChangeCity = (event: SelectChangeEvent<typeof cityName>) => {
    const {
      target: { value }
    } = event
    setCityName(value)
    setWardName('')
    fields.customer_address_district.onChange('')
    fields.customer_address_ward.onChange('')
    setDistrictName('')
  }

  const handleChangeWard = (event: SelectChangeEvent<typeof wardName>) => {
    const {
      target: { value }
    } = event
    setWardName(value)
  }

  const handleChangeDistrict = (event: SelectChangeEvent<typeof districtName>) => {
    const {
      target: { value }
    } = event
    setDistrictName(value)
    setWardName('')
    fields.customer_address_ward.onChange('')
  }

  const handleSubmit = useCallback(async () => {
    await submit()
    if(!submitting && validate().length <= 0){
      onClose()
      reset()
    }
  },[])


  useEffect(() => {
    if (!isEdit) {
      reset()
    }

  }, [isEdit])


  useEffect(() => {
    setListAddress(convertData(addressData))
  }, [])

  useEffect(() => {
    const district = listAddress
      ?.filter(item => item?.code === fields.customer_address_city.value)
      .map(item => item['quan-huyen'])
    if (!!district) {
      setDistrictByCity(convertData(district[0]))
    }
  }, [listAddress, fields.customer_address_city.value])

  useEffect(() => {
    if(isEdit){
      setCityName(stockDefaultCity?.name || '')
      setWardName(stockDefaultWard?.name || '')
      setDistrictName(stockDefaultDistrict?.name || '')
    }

  }, [wardByDistrict, districtByCity, listAddress])

  useEffect(() => {
    const ward = districtByCity
      ?.filter(item => item?.code === fields.customer_address_district.value)
      .map(item => item['xa-phuong'])
    if (!!ward) {
      setWardByDistrict(convertData(ward[0]))
    }
  }, [districtByCity, fields.customer_address_district.value])

  return (
    <ModalAddCustomersContainer open={isShow} onClose={onClose}>
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(115.125),
          margin: '0 auto'
        }}
      >
        <Box className="header">
          <ModalHeader direction={'row'}>
            <Typography className="title">{title || ''}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider sx={{ opacity: 1 }} />
        </Box>
        <ModalBody className="body">
          <Box display={'grid'} rowGap={theme => theme.spacing(0.5)}>
            <TextField
              {...fields.customer_display_name}
              maxLength={200}
              max={200}
              requiredIndicator
              placeholder={__('customer_name')}
            />
            <Box
              display={'grid'}
              gridTemplateColumns={'1fr 1fr'}
              columnGap={theme => theme.spacing(1.5)}
            >
              <TextField
                {...fields.customer_email}
                maxLength={200}
                max={200}
                requiredIndicator
                placeholder={__('Email')}
              />
              <TextField
                {...fields.customer_phonenumber}
                maxLength={10}
                onChange={value => {
                  fields.customer_phonenumber.onChange(`${value?.replace(/\D+/g, '')}`)
                }}
                requiredIndicator
                placeholder={__('phone_number')}
              />
            </Box>
            {/* <Box
              display={'grid'}
              gridTemplateColumns={'1fr 1fr'}
              columnGap={theme => theme.spacing(1.5)}
            >
              <TextField
                {...fields.customer_birthday}
                maxLength={20}
                max={20}
                placeholder={__('date_of_birth')}
              />
              <TextField
                {...fields.customer_gender}
                maxLength={20}
                max={20}
                placeholder={__('sex')}
              />
            </Box> */}
            <Box
              display={'grid'}
              gridTemplateColumns={'1fr 1fr 1fr'}
              columnGap={theme => theme.spacing(1.5)}
            >
              <SelectComponent
                options={listAddress}
                title={__('select_city')}
                value={cityName}
                onChange={handleChangeCity}
                _onClick={value => fields.customer_address_city.onChange(value)}
                _onBlur={() =>
                  fields.customer_address_city.runValidation(fields.customer_address_city.value)
                }
                error={fields.customer_address_city.error}
              />
              <SelectComponent
                options={districtByCity}
                title={__('select_district')}
                value={districtName}
                onChange={handleChangeDistrict}
                _onClick={value => fields.customer_address_district.onChange(value)}
                _onBlur={() =>
                  fields.customer_address_district.runValidation(fields.customer_address_district.value)
                }
                error={fields.customer_address_district.error}
              />
              <SelectComponent
                options={wardByDistrict}
                title={__('select_ward')}
                value={wardName}
                onChange={handleChangeWard}
                _onClick={value => fields.customer_address_ward.onChange(value)}
                _onBlur={() =>
                  fields.customer_address_ward.runValidation(fields.customer_address_ward.value)
                }
                error={fields.customer_address_ward.error}
              />
            </Box>
            <TextField
              {...fields.customer_address}
              maxLength={200}
              max={200}
              requiredIndicator
              placeholder={__('add_customer_address')}
            />
          </Box>
          <Box display={'flex'} justifyContent={'flex-end'}>
            <Button
              onClick={handleSubmit}
              disabled={!dirty || submitting}
              sx={{ padding: theme => theme.spacing(2, 3) }}
            >
              <Typography textTransform={'none'} color={'background.paper'}>
                {__('add_customer_button')}
              </Typography>
            </Button>
          </Box>
        </ModalBody>
      </ModalContainer>
    </ModalAddCustomersContainer>
  )
}

const ExternalCustomersContainer = styled(Box)(({ theme }) => ({
  '& .wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .input_search': {
    height: theme.spacing(5.5),
    borderColor: theme.palette.text.secondary,
    display: "flex",
    marginRight: theme.spacing(2)
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .edit': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

const ModalAddCustomersContainer = styled(Modal)(({ theme }) => ({
  '& .header': {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    background: theme.palette.background.paper,
    padding: 0
  },
  '& .title': {
    textTransform: 'none',
    variant: 'h6',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
    fontSize: theme.spacing(3)
  },
  '& .body': {
    padding: theme.spacing(2, 3),
    maxHeight: `calc(100% - ${theme.spacing(10)})`,
    overflowY: 'auto'
  },
  '& .button_wrapper': {
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    padding: theme.spacing(0, 2),
    border: `1px dashed ${theme.palette.primary.main}`,
    '& .upload_wrapper': {
      display: 'grid',
      justifyItems: 'center',
      rowGap: theme.spacing(1)
    }
  },
  '& .input_search': {
    height: theme.spacing(5.75),
    padding: theme.spacing(1, 2),
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
  }
}))
