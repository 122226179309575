import React from 'react'
import { Box, Stack, Button, SxProps, Typography } from '@mui/material'
import { VisuallyHiddenInput } from 'entities/shop/components/FormCreateEditProductCategory'
import { CurrentTheme } from 'styles/themes'
import { UploaderIcon } from 'icons'
import __ from 'languages/index'

type Props = {
  acceptFiles?: string
  multiple?: boolean
  initialFile?: FileList
  onUploaded?: (files: FileList) => void
  placeholder?: React.ReactNode
}

const FileUploader = ({
  acceptFiles = '*',
  multiple,
  initialFile,
  onUploaded,
  placeholder
}: Props) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventTarget = event?.target
    if (!eventTarget) return
    onUploaded(eventTarget.files)
  }

  const placeholderFallback = (
    <>
      <Stack alignItems="center" gap={1}>
        <UploaderIcon />
        <Typography textTransform="none">Upload file</Typography>
      </Stack>
      <Box sx={containerStyled} />
    </>
  )

  return (
    <Button component="label" variant="outlined" sx={uploadButtonStyled}>
      <VisuallyHiddenInput
        type="file"
        multiple={multiple}
        onChange={handleFileChange}
        accept={acceptFiles}
      />
      {placeholder ? placeholder : placeholderFallback}
    </Button>
  )
}

export default FileUploader

const containerStyled: SxProps<CurrentTheme> = {
  position: 'absolute',
  borderRadius: theme => theme.spacing(1.5),
  width: '100%',
  height: '100%',
  border: theme => `1px dashed ${theme.palette.primary.main}`
}

const uploadButtonStyled: SxProps<CurrentTheme> = {
  padding: 0,
  border: 'none',
  height: '100%',
  width: 'max-content',
  '&:hover': {
    border: 'none'
  }
}
