export const DEFAULT_VOLUME = 0.5
export const ID_VIDEO_ADMIN_STUDIO = "live_video_preview"

export const STREAM_CONFIG = {
  maxResolution: {
    width: 1920,
    height: 1080
  },
  maxFramerate: 24,
  /**
   * maxBitrate is measured in kbps
   */
  maxBitrate: 1750,
}

export enum EnumReactLivestream {
  Like = "like",
  Love = "love",
  Care = "care",
  Haha = "haha",
  Wow = "wow",
  Sad = "sad"
}

export const REACT_LIST = [
  {
    icon: require("./media/like.png"),
    name: EnumReactLivestream.Like
  },
  {
    icon: require("./media/love.png"),
    name: EnumReactLivestream.Love
  },
  {
    icon: require("./media/care.png"),
    name: EnumReactLivestream.Care
  },
  {
    icon: require("./media/haha.png"),
    name: EnumReactLivestream.Haha
  },
  {
    icon: require("./media/wow.png"),
    name: EnumReactLivestream.Wow
  },
  {
    icon: require("./media/sad.png"),
    name: EnumReactLivestream.Sad
  },
]

export const REACT_GIFT = {
  [EnumReactLivestream.Like]: require("./media/like.gif"),
  [EnumReactLivestream.Love]: require("./media/love.gif"),
  [EnumReactLivestream.Haha]: require("./media/haha.gif"),
  [EnumReactLivestream.Wow]: require("./media/wow.gif"),
  [EnumReactLivestream.Care]: require("./media/care.gif"),
  [EnumReactLivestream.Sad]: require("./media/sad.gif"),
}

export const REACT_PNG = {
  [EnumReactLivestream.Like]: require("./media/like.png"),
  [EnumReactLivestream.Love]: require("./media/love.png"),
  [EnumReactLivestream.Haha]: require("./media/haha.png"),
  [EnumReactLivestream.Wow]: require("./media/wow.png"),
  [EnumReactLivestream.Care]: require("./media/care.png"),
  [EnumReactLivestream.Sad]: require("./media/sad.png"),
}

export const REACT_LIST_GIFT = [
  {
    icon: REACT_GIFT[EnumReactLivestream.Like],
    name: EnumReactLivestream.Like
  },
  {
    icon: REACT_GIFT[EnumReactLivestream.Love],
    name: EnumReactLivestream.Love
  },
  {
    icon: REACT_GIFT[EnumReactLivestream.Care],
    name: EnumReactLivestream.Care
  },
  {
    icon: REACT_GIFT[EnumReactLivestream.Haha],
    name: EnumReactLivestream.Haha
  },
  {
    icon: REACT_GIFT[EnumReactLivestream.Wow],
    name: EnumReactLivestream.Wow
  },
  {
    icon: REACT_GIFT[EnumReactLivestream.Sad],
    name: EnumReactLivestream.Sad
  },
]
