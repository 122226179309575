import React, {useCallback} from "react"
import ChallengeAddDetail from "./component/challenge.add.detail"
import {useNavigate, useParams} from "react-router-dom"
import Capacity from "components/Capacity"
import _Helmet from "components/helmet"
import BigLayout from "../../layouts/bigLayout";

const ChallengeAdd = (props) => {

  const params = useParams()

  const isUpdate = params && params?.challenge_id


  return (
    <BigLayout>
      <_Helmet title='challenge_add_challenge' />
      <div className="mainFrame">
        <div className="container">
          <ChallengeAddDetail isUpdate={isUpdate} />
        </div>
      </div>
    </BigLayout>
  )
}

export default ChallengeAdd
