import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'

import setupAxiosInterceptors from './config/axios'
import App from './App'
import reportWebVitals from './reportWebVitals'
import getStore from './config/store'
import 'yet-another-react-lightbox/styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './media/APP.scss'
import { bindActionCreators } from '@reduxjs/toolkit'
import { setLoading, stopLoading } from 'store/global_process_bar.store.reducer'
import { setError } from 'store/global.warning.store.reducer'
declare const gtag: (...args: any) => void

const store = getStore()

/**
 * If API header return 401 or 403, call login now!
 */
const actions = bindActionCreators({ setLoading, stopLoading, setError }, store.dispatch)
/**
 * If API header return 401 or 403, call login now!
 */

setupAxiosInterceptors(
  (status: number) => {
    // not log-in or locked
    if (status === 401 || status === 418) {
      // const PUBLIC_URL = process.env.PUBLIC_URL || '/';
      // window.location.href = PUBLIC_URL + '/login';
    }
    // logout ? do something now, or leave it there...
  },
  (errorMessage: string) => {
    actions.setError(errorMessage)
  },
  (loadingStatus: boolean) => {
    if (loadingStatus === true) actions.setLoading()
    else actions.stopLoading()
  }
)

const container = document.getElementById('root')
const root = ReactDOMClient.createRoot(container)
// Create a root.

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }) {
  if (typeof gtag !== 'function') return

  gtag('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true // avoids affecting bounce rate
  })
}

reportWebVitals(sendToAnalytics)

/**
 * Webpack HMR Activation
 */

/**/

type ModuleId = string | number

interface WebpackHotModule {
  hot?: {
    data: any
    accept(dependencies: string[], callback?: (updatedDependencies: ModuleId[]) => void): void
    accept(dependency: string, callback?: () => void): void
    accept(errHandler?: (err: Error) => void): void
    dispose(callback: (data: any) => void): void
  }
}

declare const module: WebpackHotModule

if (module.hot) {
  module.hot.accept()
  module.hot.dispose(() => {
    process.exit()
  })
}
