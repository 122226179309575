import { SvgIcon, SvgIconProps } from '@mui/material'

const ShoppingBasketPlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="currentColor"
      >
        <path
          d="M16.065 7.8125H2.93498M16.065 7.8125C16.1176 7.8125 16.157 7.86121 16.1467 7.91342L15.8333 9.5M16.065 7.8125H16.1667M2.93498 7.8125C2.8824 7.8125 2.84296 7.86121 2.85327 7.91342L4.23204 14.8934C4.38785 15.6822 5.07187 16.25 5.86634 16.25H9.5M2.93498 7.8125H2.83333M2 7.8125H2.83333M2.83333 7.8125L5.33333 2.75M16.1667 7.8125H17M16.1667 7.8125L13.6667 2.75M13.6667 11.1875V13.7188M13.6667 13.7188V16.25M13.6667 13.7188H16.1667M13.6667 13.7188H11.1667"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default ShoppingBasketPlusIcon
