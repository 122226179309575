import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import './styles/successful-screen.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { getOrderDetail } from 'entities/orders/service'
import { TypedServiceId } from 'interfaces/channel.interface'
import congratsData from '../lotties/congrats.json'
import Lottie from 'lottie-react'
import __ from 'languages/index'
import BigLayout from "../layouts/bigLayout";

const serviceType = {
  extension: 'tính năng',
  domain: 'cài đặt',
  course: 'khoá học',
  mobile: 'ticket'
}

const SuccessfulScreen = () => {
  const navigate = useNavigate()
  const { orderId } = useParams()
  const [currentService, setCurrentService] = useState<
    (TypedServiceId & { isFree: boolean; productUrl: string }) | null
  >(null)

  const getServiceType = () => {
    return serviceType[currentService?.service_type]
  }

  const orderDetail = async () => {
    try {
      const res = await getOrderDetail(orderId)
      setCurrentService({
        ...res?.data?.service_id,
        isFree: res?.data?.payment_method === 'free',
        productUrl: res?.data?.product_url || ''
      })
    } catch (err) {
      throw err
    }
  }

  const handleRedirect = () => {
    navigate( Boolean(currentService?.productUrl) ? '/' : currentService?.productUrl)
  }

  useEffect(() => {
    orderDetail()
  }, [])

  const animatedCheck = () => {
    return (
      <div className="check-container">
        <div className="check-background">
          <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7 25L27.3077 44L58.5 7"
              stroke="white"
              strokeWidth="13"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="check-shadow"></div>
      </div>
    )
  }

  return (
    <BigLayout>
      <div className="successful-screen d-flex align-items-center justify-content-start flex-column position-relative">
        <div
          className="position-absolute"
          style={{
            zIndex: '2'
          }}
        >
          <Lottie
            animationData={congratsData}
            style={{width: '100%',height: '100vh'}}
          />
        </div>
        {animatedCheck()}
        <p className="mb-0">{__("successful")}</p>
        <p>{!currentService?.isFree ? `${__("payment_success_ful_screen")}` : `${__("payment_register_success_ful_screen")}`}</p>
        <Button
          className="position-relative "
          style={{
            zIndex: 5
          }}
          onClick={handleRedirect}
        >
          {__("successful_text")} {getServiceType()}
        </Button>
      </div>
    </BigLayout>

  )
}

export default SuccessfulScreen
