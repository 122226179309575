import React, {useCallback, useEffect, useState,} from 'react';

import {useAppDispatch, useAppSelector} from 'config/store';
import {likeEvent, unlikeEvent,} from 'entities/calendar/calendar.store.reducer';
import helpers from 'helpers/index';
import {Button, Dropdown,} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {checkIsNotShowButtonJoin} from '../helpers';
import {TypedEvent} from 'entities/calendar/calendar.interface';
import __ from "languages/index";
import { Box, styled } from '@mui/material';

interface Props {
  is_like: boolean,
  _id: string,
  data: TypedEvent,
  onLike: (type: boolean) => void
  onCopy: (success: boolean) => void
}

const EventAction = (props: Props) => {
  const { is_like,data, _id, onLike, onCopy } = props
  const dispatch = useAppDispatch()
  const user_data = useAppSelector(state => state.user.user_data)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const user_in_this_channel = useAppSelector(state => state.user.user_in_this_channel);

  // state
  const [isLike, setIsLike] = useState < boolean > (is_like)
  const [disable, setDisable] = useState < boolean > (false)
  const [disableShare, setDisableShare] = useState < boolean > (false)


  // ======================================

  // useEffect
  useEffect(() => {
    setIsLike(is_like)
  }, [is_like])

  useEffect(() => {
    if (user_data) {
      setDisable(false)
      setDisableShare(false)
    } else {
      setDisable(true)
      setDisableShare(true)
    }
  }, [])
  // ======================================

  // Function
  const _onLike = useCallback(async () => {
    if (!disable) {
      let res: any = null
      if (isLike) {
        res = await dispatch(unlikeEvent({ event_id: _id }))
      } else {
        res = await dispatch(likeEvent({ event_id: _id }))
      }
      setDisable(true)
      if(!res?.error){
        setIsLike(!isLike)
        onLike(!isLike)
      }
      setTimeout(() => {
        setDisable(false)
      }, 2000)
    }
  }, [disable, isLike, _id])

  // ======================================

  return (
    <EventActionContainer className="top-area pb-6 mb-6 d-center flex-wrap gap-3 justify-content-between">
      <ul className="nav flex-wrap gap-2 tab-area" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link d-center fs-4" id="about-tab" data-bs-toggle="tab"
            data-bs-target="#about-tab-pane" type="button" role="tab" aria-controls="about-tab-pane" aria-selected="false" tabIndex={-1}>{__("information")}</button>
        </li>
      </ul>
      <div className="d-flex d-center flex-wrap gap-2">
        {!checkIsNotShowButtonJoin(data, user_in_this_channel) && <Button disabled={disable} variant={`outline-${isLike ? "success" : "danger"}`} onClick={_onLike} className={`d-center gap-2 me-2`}>
          {isLike ? <i className="bi bi-heart-fill me-2"></i> : <i className="bi bi-heart me-2" />}
          {isLike ? `${__("event_status_joined")}` : `${__("event_status_join")}`}
        </Button>}
        <Dropdown>
          <Dropdown.Toggle disabled={disableShare} variant="outline-primary" id="dropdown-basic">
            <i className="bi bi-share me-2"></i>
            {__("event_share")}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <CopyToClipboard text={helpers.getRootDomain(`/event/detail/${_id}`)} onCopy={() => onCopy(true)}>
              <Dropdown.Item>
                <i className="bi bi-link-45deg fs-5 me-2"></i>
                Copy Link
              </Dropdown.Item>
            </CopyToClipboard>
            <Dropdown.Item target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${helpers.getRootDomain(`/event/detail/${_id}`)}`}>
              <i className="bi bi-facebook fs-5 me-2"></i>
              {__("event_social_facebook")}
            </Dropdown.Item>
            <Dropdown.Item target="_blank" href={`https://twitter.com/intent/tweet?text=${helpers.getRootDomain(`/event/detail/${_id}`)}`}>
              <i className="bi bi-twitter fs-5 me-2"></i>
              {__("event_social_twitter")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </EventActionContainer>
  )
}

export default EventAction

const EventActionContainer = styled(Box)(({theme})=>({
  '& .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active': {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  '& .btn-outline-primary':{
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  '& .btn':{
    ':hover':{
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    }
  },
  '&  .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active':{
    color: '#fff',
  }
}))
