import React from 'react'
import { useInfinite } from 'hooks/useInfinite'
import { Box, CircularProgress, Stack } from '@mui/material'

type Props<T> = {
  list: T[]
  renderItem: (item: T) => React.ReactNode
  hasNextPage: boolean
  onLoadMore: () => void
  isLoading?: boolean
}

const InfiniteList = <ListType,>({
  list,
  renderItem,
  hasNextPage,
  onLoadMore,
  isLoading,
  ...rest
}: Props<ListType>) => {
  const { ref } = useInfinite(hasNextPage, onLoadMore)

  return (
    <Stack gap={2.5}>
      {Array.isArray(list) &&
        list.map((item, index) => {
          if (list.length === index + 1) {
            return isLoading ? (
              <Box display="flex" justifyContent={'center'} key={index}>
                <CircularProgress />
              </Box>
            ) : (
              <div key={`list_${index}`} ref={ref}>{renderItem(item)}</div>
            )
          }
          return renderItem(item)
        })}
    </Stack>
  )
}

export default InfiniteList
