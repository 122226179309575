import { useRef } from 'react'

type AudioConfig = {
  fastForwardTime: number
  rewindTime: number
  currentTime: number
}

export type AudioInfo = {
  duration: number
  currentTime: number
}

const useAudio = (audioSrc: string, config: Partial<AudioConfig>) => {
  const audioRef = useRef<HTMLAudioElement | null>(new Audio(audioSrc))

  const play = () => {
    audioRef.current.play()
  }

  const pause = () => {
    audioRef.current.pause()
  }

  const handleTimeControl = (time: number) => () => {
    audioRef.current.currentTime += time
  }

  const fastForward = handleTimeControl(config.fastForwardTime)

  const rewind = handleTimeControl(-config.rewindTime)

  return {
    ref: audioRef,
    rewind,
    fastForward,
    play,
    pause
  }
}

export default useAudio
