import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import DateTime from 'date-and-time'
import { TypedChallengeGift, TypedLeg } from 'entities/challenge/challenge.interface'
import GiftModalComponent from '../challenge.add.detail/gift.modal.component'
import { useAppSelector } from 'config/store'
import __ from "languages/index";
import GiftReceiverGiftAddModal from 'entities/gift/components/gift.receivergift.add.modal'
import { styled, Modal, Typography, Stack, Tooltip, Button, IconButton } from '@mui/material'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { CloseIcon, PlusIcon } from 'icons'
import ListGiftComponent from '../challenge.add.detail/list.gift.component'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import DeleteConfirmModal from 'components/deleteConfirmModal'

interface Props {
  onAddLeg: (item: TypedLeg) => void
}

interface TypedTime {
  startDate: string
  finishedDate: string
  disableOpenDate: boolean
  disableEndDate: boolean
  showFinishDate: string
}

interface TypedParams {
  title: string
  openDate: string
  endDate: string
  description: string
}

const LegComponent = (props: Props, ref) => {
  const { onAddLeg } = props

  const { gifts } = useAppSelector(state => state.challenge)
  const toast = useToast()
  // Refs Input
  const titleInputRef = useRef(null)
  const openDateInputRef = useRef(null)
  const endDateInputRef = useRef(null)
  const descriptionInputRef = useRef(null)
  const searchSelectRef = useRef(null)
  const giftModalRef = useRef(null)
  const refGiftReceiverGiftAddModal = useRef<any>()
  const refListGiftIdsAdded = useRef<string[]>([])
  // ========================================

  // Refs

  const paramsRef = useRef<TypedParams>({
    title: '',
    openDate: '',
    endDate: '',
    description: ''
  })
  // ========================================

  // State
  const [show, setShow] = useState<boolean>(false)
  const [validate, setValidate] = useState<boolean>(false)
  const [validateTime, setValidateTime] = useState<boolean>(false)
  const [chooseGift, setChooseGift] = useState<any>([])
  const [time, setTime] = useState<TypedTime>({
    startDate: '',
    finishedDate: '',
    disableOpenDate: false,
    disableEndDate: false,
    showFinishDate: ''
  })
  // ========================================

  // useEffect
  useImperativeHandle(ref, () => ({
    show: handleShow
  }))
  // ========================================

  // Function

  const setMaxDate = useMemo(() => {
    const maxDate = new Date(time?.finishedDate)
    maxDate.setMinutes(maxDate.getMinutes() + 1)
    return maxDate
  }, [time?.finishedDate])

  const handleShow = useCallback(
    (
      startDate: string,
      finishedDate: string,
      disableOpenDate: boolean,
      disableEndDate: boolean
    ) => {
      setShow(true)
      paramsRef.current.openDate = startDate
      paramsRef.current.endDate = startDate
      const newTime: TypedTime = {
        startDate: startDate?.replace(':00.000Z', ''),
        finishedDate: finishedDate?.replace(':00.000Z', ''),
        disableEndDate,
        disableOpenDate,
        showFinishDate: finishedDate
      }
      setTime(newTime)
      setValidate(false)
      setChooseGift([])
    },
    []
  )

  const handleClose = useCallback(() => {
    setShow(false)
    setValidateTime(false)
  }, [])

  const _onTitleChange = useCallback(event => {
    paramsRef.current.title = event.target.value
  }, [])

  const _onOpenDateChange = useCallback(event => {
    setTime(prev => {
      return { ...prev, startDate: event.target.value }
    })

    paramsRef.current.openDate = event.target.value
    if (endDateInputRef.current) {
      endDateInputRef.current.value = event.target.value
    }
  }, [])

  const _onEndDateChange = useCallback(event => {
    paramsRef.current.endDate = event.target.value
  }, [])

  const _onDescriptionChange = useCallback(event => {
    paramsRef.current.description = event.target.value
  }, [])

  const _onShowGift = useCallback(() => {
    giftModalRef.current.show()
  }, [])

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setValidate(true)
      return
    }

    if (paramsRef.current.openDate == paramsRef.current.endDate) {
      setValidateTime(true)
      if (endDateInputRef.current) {
        endDateInputRef.current.focus()
      }
      event.preventDefault()
      event.stopPropagation()
      return
    }

    const newItem: TypedLeg = {
      _id: new Date().toISOString(),
      start_time: paramsRef.current.openDate,
      end_time: paramsRef.current.endDate,
      description: paramsRef.current.title,
      name: paramsRef.current.title,
      gift_data: chooseGift
    }
    onAddLeg(newItem)
    setValidateTime(false)
    handleClose()
    event.preventDefault()
    event.stopPropagation()
  }

  const _onGiftChange = useCallback(data => {
    setChooseGift(old => [...old, data])
    refListGiftIdsAdded.current = [...refListGiftIdsAdded.current, data?._id]
  }, [])

  const _onCheckBoxChange = useCallback(
    event => {
      setTime(prev => {
        return { ...prev, disableEndDate: event.target.checked }
      })
      if (event.target.checked) {
        paramsRef.current.endDate = time?.finishedDate
        endDateInputRef.current.value = time?.finishedDate
      }
    },
    [time?.finishedDate]
  )
  // ========================================
  const _onDeleteGift = useCallback((item: TypedChallengeGift) => {
    let newArr: TypedChallengeGift[] = []
    setChooseGift(prev => {
      newArr = prev.filter(i => i?._id != item?._id)
      return newArr
    })
    if (searchSelectRef.current) {
      searchSelectRef.current?.setValue(newArr)
    }
    toast.show({
      title: `${__("notification")}`,
      content: `${__("challenge_add_detail_content_delete_gift_success")}`,
      type: EnumTypeToast.Success
    })
  }, [])
  return (
    <LegContainer open={show} onClose={handleClose}>
      <ModalContainer>
        <Form className="needs-validation" noValidate validated={validate} onSubmit={handleSubmit}>

          <ModalHeader position={'relative'}>
            <Typography textTransform={'none'} variant='h6'>{__("challenge_leg_add_race")}</Typography>
            <IconButton sx={{
              position: 'absolute',
              right: theme => theme.spacing(2)
            }} onClick={() => setShow(false)}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>

          <ModalBody>

            <Form.Group className="mb-3">
              <Tooltip title={__("challenge_leg_race_name")}>
                <Form.Control
                  maxLength={100}
                  onChange={_onTitleChange}
                  type="search"
                  ref={titleInputRef}
                  id="title"
                  required
                  className='input'
                  placeholder={__("challenge_leg_race_name")}
                />
              </Tooltip>
              <div className="invalid-feedback">{__("challenge_leg_race_name_sub_text")}</div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type={'switch'}
                onChange={_onCheckBoxChange}
                label={<Typography>{__("challenge_leg_last_race")}</Typography>}
                id="last_leg"
              />
            </Form.Group>
            <Stack flexDirection={'row'} width={'100%'} justifyContent={'space-between'} className={validateTime ? 'mb-1' : 'mb-3'}>
              <Form.Group>
                <Tooltip title={__("challenge_leg_race_start_time")}>
                  <Form.Control
                    disabled={time?.disableOpenDate}
                    required
                    type={'datetime-local'}
                    id={'openDate'}
                    defaultValue={time?.startDate}
                    ref={openDateInputRef}
                    maxLength={500}
                    onChange={_onOpenDateChange}
                    min={DateTime.format(new Date(time?.startDate), 'YYYY-MM-DD HH:mm')}
                    max={DateTime.format(new Date(time?.finishedDate), 'YYYY-MM-DD HH:mm')}
                    className='input'
                    style={{
                      width: '96%'
                    }}
                  />
                </Tooltip>
                <div className="invalid-feedback">{__("challenge_leg_race_start_time_text")}</div>
              </Form.Group>
              <Form.Group>
                <Tooltip title={__("challenge_leg_race_end_time")}>
                  <Form.Control
                    disabled={time?.disableEndDate}
                    required
                    type={'datetime-local'}
                    id={'openDate'}
                    ref={endDateInputRef}
                    onChange={_onEndDateChange}
                    min={DateTime.format(new Date(time?.startDate), 'YYYY-MM-DD HH:mm')}
                    max={DateTime.format(setMaxDate, 'YYYY-MM-DD HH:mm')}
                    className='input'
                    style={{
                      width: '96%'
                    }}
                  />
                </Tooltip>
                <div className="invalid-feedback">{__("challenge_leg_race_end_time_text")}</div>
                {validateTime && (
                  <Typography className="text-danger">
                    {__("challenge_leg_race_end_time_sub_text")}
                  </Typography>
                )}
                <i style={{ fontSize: 12 }}>
                  {__("challenge_leg_race_end_time_of_challenge")}
                  <b> {DateTime.format(new Date(time?.showFinishDate), 'DD/MM/YYYY HH:mm')}</b>
                </i>
              </Form.Group>
            </Stack>
            <Form.Group className="mb-3">
              <Tooltip title={__("challenge_leg_information_race")}>
                <Form.Control
                  as="textarea"
                  rows={7}
                  maxLength={1000}
                  ref={descriptionInputRef}
                  onChange={_onDescriptionChange}
                  required
                  id="description"
                  className='input'
                  placeholder={__("challenge_leg_information_race")}
                  style={{
                    minHeight: '109px'
                  }}
                />
              </Tooltip>
              <div className="invalid-feedback">{__("challenge_leg_information_race_description")}</div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant='subtitle1' textTransform={'none'}>
                  {__("channel_setting_email_gift")}
                </Typography>
                <Button
                  onClick={() => refGiftReceiverGiftAddModal.current?.show()}
                  variant='outlined'
                  sx={{textTransform: 'none !important'}}
                >
                  <PlusIcon
                    sx={{
                      width: theme => theme.spacing(2.5),
                      height: theme => theme.spacing(2.5),
                      marginRight: theme => theme.spacing(0.5)
                    }}
                  />
                  {__("challenge_chose_gift")}
                </Button>
              </Stack>
              <ListGiftComponent listGift={chooseGift} onDeleteGift={_onDeleteGift} />
              <GiftReceiverGiftAddModal ref={refGiftReceiverGiftAddModal} addGift={_onGiftChange}
                listGiftIdsAdded={refListGiftIdsAdded.current} />
            </Form.Group>


          </ModalBody>
          <ModalFooter flexDirection={'row'}>
            <Button variant="text" onClick={handleClose} sx={{textTransform: 'none !important'}}>
              {__("btn_close")}
            </Button>
            <Button type="submit" sx={{textTransform: 'none !important'}}>
              {__('btn_save')}
            </Button>
          </ModalFooter>
        </Form >

        <GiftModalComponent ref={giftModalRef} />
      </ModalContainer>
    </LegContainer >
  )
}

export default forwardRef(LegComponent)

const LegContainer = styled(Modal)(({ theme }) => ({

  '& .needs-validation': {
    '& .input, .input_group': {
      height: '52px',
      width: '100%',
      padding: theme.spacing(1, 2),
      background: '#F3F4F5',
      borderRadius: `${theme.spacing(1.5)} !important`,

      '& input, #dropdown-basic': {
        border: 'none',
        background: 'none',
        color: '#000',
        padding: 0,


      },

      '& input': {
        paddingRight: theme.spacing(1),
      },
    },

    '& .form-control': {

      '&:focus': {
        boxShadow: 'none !important',
        border: 'none',
        '&-visible': {
          outline: 0
        }
      },
    },
  }
}))
