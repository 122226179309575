import { TypedIcon } from "entities/calendar/calendar.interface"
import * as React from "react"
import { SVGProps, memo } from "react"
const SvgComponent = (props: TypedIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g fill={props.color || "#374957"} clipPath="url(#a)">
      <path d="M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0Zm0 21a9 9 0 1 1 9-9 9.01 9.01 0 0 1-9 9Z" />
      <path d="m10.5 11.055-2.4 1.5a1.5 1.5 0 1 0 1.593 2.543l2.869-1.8a2 2 0 0 0 .938-1.7V7.772a1.5 1.5 0 1 0-3 0v3.283Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo
