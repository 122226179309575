import { SvgIcon, SvgIconProps } from '@mui/material'

const XOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          d="M13.7685 10.5155L20.8417 2.04688H19.1656L13.0239 9.40007L8.11863 2.04688H2.46094L9.87873 13.1662L2.46094 22.0469H4.13715L10.6229 14.2816L15.8032 22.0469H21.4609L13.7681 10.5155H13.7685ZM11.4727 13.2642L10.7211 12.1569L4.74111 3.34655H7.31567L12.1416 10.4568L12.8932 11.5641L19.1664 20.8063H16.5918L11.4727 13.2646V13.2642Z"
          fill={'currentColor' || 'black'}
        />
      </svg>
    </SvgIcon>
  )
}

export default XOutlinedIcon
