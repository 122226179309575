import DateTime from 'date-and-time'

export const formatDate = (time: string, format: string = 'HH:mm:ss DD/MM/YYYY') => {
  return DateTime.format(new Date(time), format)
}
export const calculateTimeAgo = (time: string) => {
  const currentTime = Date.now()
  const timeAgo = currentTime - new Date(time).getTime()

  const seconds = Math.floor(timeAgo / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  if (days > 0) {
    if (days > 6) return formatDate(time, 'DD/MM/YYYY')
    return `${days} ngày trước`
  }
  if (hours > 0) {
    return `${hours} giờ trước`
  }
  if (minutes > 0) {
    return `${minutes} phút trước`
  }
  if (seconds > 0) {
    return `${seconds} giây trước`
  }
  return 'Vừa xong'
}
