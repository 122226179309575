import { SvgIcon, SvgIconProps } from '@mui/material'

const StarVoteIcon = (props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="currentColor">
        <path
          d="M9.94541 0.89268L11.807 4.09588C11.963 4.36628 12.223 4.55348 12.535 4.61588L16.175 5.38548C17.007 5.56228 17.3294 6.56068 16.7678 7.18468L14.2718 9.93028C14.0638 10.1591 13.9598 10.4711 13.991 10.7727L14.3862 14.4439C14.4798 15.2759 13.6166 15.8999 12.847 15.5567L9.44621 14.0487C9.16541 13.9239 8.83261 13.9239 8.55181 14.0487L5.15101 15.5567C4.38141 15.8999 3.51821 15.2863 3.61181 14.4439L4.00701 10.7727C4.03821 10.4607 3.93421 10.1591 3.72621 9.93028L1.23021 7.18468C0.668609 6.56068 0.991009 5.56228 1.82301 5.38548L5.46301 4.61588C5.76461 4.55348 6.03501 4.36628 6.19101 4.09588L8.05261 0.89268C8.47901 0.16468 9.52941 0.16468 9.95581 0.89268H9.94541Z"
          fill="#FFC53D" />
      </svg>
    </SvgIcon>
  )
}
export default StarVoteIcon
