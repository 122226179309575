import {SvgIcon, SvgIconProps} from '@mui/material'

const ShopSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="#fbb540" d="M24 176h144v32H24zM344 176h144v32H344z"/>
        <path fill="#c0f5f9" d="M168 208h176v160H168z"/>
        <circle cx="224" cy="336" r="8" fill="#dc4d41"/>
        <circle cx="264" cy="336" r="8" fill="#dc4d41"/>
        <path fill="#dc4d41"
              d="M312 232h-24a8 8 0 0 0-7.589 5.47L274.234 256H200a8 8 0 0 0-7.589 10.53l16 48A8 8 0 0 0 216 320h56a8 8 0 0 0 7.891-6.685l7.9-47.381L293.766 248H312a8 8 0 0 0 0-16Zm-46.777 72h-43.457L211.1 272h59.456Z"/>
        <path fill="#c38325" d="M472 240H344v-32h136l-8 32z"/>
        <path fill="#c0f5f9" d="M344 240h128v128H344z"/>
        <path fill="#ebebeb" d="M344 312h128v56H344z"/>
        <path fill="#c38325" d="M40 240h128v-32H32l8 32z"/>
        <path fill="#c0f5f9" d="M168 368H40V240h128z"/>
        <path fill="#ebebeb" d="M168 368H40v-56h128z"/>
        <path fill="#fbb540" d="M24 368h464v32H24z"/>
        <path fill="#ea9d2d" d="M24 392h464v8H24z"/>
        <path fill="#b4e4e8"
              d="M472 240H344v72h16v-56h112v-16zM40 240v16h112v56h16v-72H40zM344 208v10.88a36.137 36.137 0 0 1-8-2.88 35.752 35.752 0 0 1-32 0 35.752 35.752 0 0 1-32 0 35.752 35.752 0 0 1-32 0 35.752 35.752 0 0 1-32 0 35.752 35.752 0 0 1-32 0 36.137 36.137 0 0 1-8 2.88V208Z"/>
        <path fill="#d9d9d9" d="M152 112h208v32H152z"/>
        <path fill="#ebebeb"
              d="M368 208a35.778 35.778 0 0 1-32 0h-32a35.778 35.778 0 0 1-32 0h-32a35.778 35.778 0 0 1-32 0h-32a35.778 35.778 0 0 1-32 0l8-64h208Z"/>
        <path fill="#dc4d41"
              d="M176 136h32v72a35.778 35.778 0 0 1-32 0zm64 0h32v72a35.778 35.778 0 0 1-32 0zm64 0h32v72a35.778 35.778 0 0 1-32 0z"/>
        <path fill="#c7463b" d="M304 112h32v32h-32zM240 112h32v32h-32zM176 112h32v32h-32z"/>
        <path fill="#d9d9d9" d="M168 368h-16v-56h16zM360 368h-16v-56h16z"/>
      </svg>
    </SvgIcon>
  )
}

export default ShopSidebarIcon
