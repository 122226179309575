import React from 'react'
import { Button, Stack, TextField } from '@mui/material'
import { TypeDataLevel } from 'entities/channelSetting/interface'
import { useForm } from 'react-hook-form'
import {
  EditLevelPayload,
  EditLevelSchema
} from 'entities/channel-settings/validate/level.validate'
import { zodResolver } from '@hookform/resolvers/zod'
import __ from 'languages/index'
import { createConfigLevel, updateConfigLevel } from '../service'
import { useAppSelector } from 'config/store'
import { getChannelId } from 'store/user.store.reducer'

import { CreateConfigLevelPayload } from '../service/service.type'
type Props = {
  level?: TypeDataLevel
  initialLevelPoint?: number
  onSuccess?: () => void
}
const EditLevelForm = ({ level, initialLevelPoint, onSuccess }: Props) => {
  const isEditForm = Boolean(level)
  const channelId = useAppSelector(getChannelId)
  const {
    register,
    handleSubmit,
    formState: { isDirty }
  } = useForm<EditLevelPayload>({
    resolver: zodResolver(EditLevelSchema),
    defaultValues: {
      title: level?.title || '',
      level_number: +level?.level_number || initialLevelPoint,
      level_point: +level?.level_point || 0
    }
  })

  const handleSubmitForm = async (value: EditLevelPayload) => {
    const payload = {
      title: value.title,
      level_number: value.level_number.toString(),
      level_point: value.level_point.toString()
    }
    try {
      if (isEditForm) {
        await updateConfigLevel({
          ...level,
          ...payload
        })
      } else {
        await createConfigLevel({
          ...payload,
          channel_id: channelId
        })
      }
      onSuccess && onSuccess()
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <Stack component="form" gap={2} onSubmit={handleSubmit(handleSubmitForm)}>
      <TextField
        {...register('level_number')}
        placeholder={__('channel_setting_level')}
        label={__('channel_setting_level')}
        disabled={true}
      />
      <TextField
        {...register('title')}
        label={__('channel_setting_level_name')}
        placeholder={__('channel_setting_level_name')}
      />
      <TextField
        {...register('level_point')}
        placeholder={__('channel_setting_level_point')}
        label={__('channel_setting_level_point')}
        disabled={isEditForm}
      />
      <Button
        sx={{
          ml: 'auto'
        }}
        type="submit"
        disabled={!isDirty}
      >
        {__('btn_save')}
      </Button>
    </Stack>
  )
}

export default EditLevelForm
