import { ElementType, useState } from 'react'

const useMultiForm = (forms: ElementType[]) => {
  const [currentStep, setCurrentStep] = useState(0)

  const next = () => {
    setCurrentStep(prev => {
      if (prev === forms.length) return prev
      return prev + 1
    })
  }

  const back = () => {
    if (currentStep === 0) return
    return setCurrentStep(current => current - 1)
  }

  const goto = (step: number) => {
    if (step < 0 || step > forms.length) return
    return setCurrentStep(step)
  }

  return {
    currentStep,
    Step: forms[currentStep],
    isLastStep: currentStep === forms.length - 1,
    isFirstStep: currentStep === 0,
    next,
    back,
    goto
  }
}

export default useMultiForm
