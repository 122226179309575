import React, {useEffect, useState} from 'react'
import {FormControl, RadioGroup, SxProps, Theme, Typography} from '@mui/material'
import PollElement from 'entities/homeNew/components/DisplayPostContent/PollElement'
import {useSelector} from 'react-redux'
import {getUserId} from '../../../../store/user.store.reducer'
import {TypedPoll} from '../../../../interfaces/request.interface'
import {renewRequest, voteRequest} from "entities/homepage/home.store.reducer";
import {useAppDispatch} from "config/store";
import __ from "languages/index";

type Props = {
  poll: TypedPoll[]
  requestId: string
  isInReview?: boolean
}
const PollResult = ({poll, requestId, isInReview}: Props) => {
  const userId = useSelector(getUserId)
  const hasMore = poll.length > 3
  const [slicedPoll, setSlicedPoll] = useState(poll)

  const [pollData, setPollData] = useState(poll);
  const [votedOption, setVotedOption] = useState(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (poll?.length > 0) {
      poll.map((e, i) => {
        const indexUserSelect = e.users_choose.findIndex(user => user?._id === userId)

        if (indexUserSelect !== -1) {
          setVotedOption(e._id)
        }
      })
      setPollData(poll)
    }
  }, [poll])

  const handleQuestionChange = (questionId: string) => {
    setVotedOption(questionId)
    setPollData(oldValue => {
      let newValue = (oldValue || []).map((itemPoll: TypedPoll) => {
        if (itemPoll?._id === questionId) {
          return {...itemPoll, number_choose: itemPoll?.number_choose + 1}
        }

        const indexUserSelect = itemPoll?.users_choose.findIndex(user => user?._id === userId)
        if (indexUserSelect !== -1) {
          let arrayUserChoose = [...itemPoll?.users_choose].splice(indexUserSelect, 1)
          return {
            ...itemPoll,
            users_choose: arrayUserChoose,
            number_choose: Math.max(itemPoll?.number_choose - 1, 0)
          }
        }

        return itemPoll
      })

      return newValue
    })

    let params = {
      request_id: requestId,
      poll_id: questionId
    }

    voteRequest(params)
      .then(res => {
        dispatch(renewRequest({_id: requestId, auth_id: userId}))
      })
      .catch(error => {
      })
  }

  const handleViewAll = () => {
    setSlicedPoll(poll)
  }


  useEffect(() => {
    if (hasMore) {
      setSlicedPoll(pollData.slice(0, 3))
    }
  }, [])

  return (
    <FormControl sx={containerStyled}>
      <RadioGroup value={votedOption || ""} onChange={e => handleQuestionChange(e.target.value)}>
        {pollData.map(pollEl => (
          <PollElement
            disable={isInReview}
            value={pollEl._id}
            key={pollEl._id}
            label={pollEl.question}
            className={'poll-element'}
            votes={pollEl.number_choose}
          />
        ))}
      </RadioGroup>
      {hasMore && slicedPoll.length === pollData.length && (
        <Typography
          onClick={handleViewAll}
          color={'primary.main'}
          textAlign={'center'}
          role={'button'}
        >
          {__("home_page_btn_seen_all")}
        </Typography>
      )}
    </FormControl>
  )
}

export default PollResult

const containerStyled: SxProps<Theme> = {
  width: '100%',
  '& .poll-element:not(last-child)': {
    mb: theme => theme.spacing(1.125)
  }
}
