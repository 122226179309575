import * as React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import TypedMedia from '../../../../interfaces/media.interface'
import { Box, SxProps } from '@mui/material'
import Lightbox from 'yet-another-react-lightbox'
import { Video } from 'yet-another-react-lightbox/plugins'
import { TypedMediaMeta } from 'entities/calendar/calendar.interface'
import { CurrentTheme } from '../../../../styles/themes'
import { generateGridLayoutImage, generateMediaSlides } from 'helpers/generateImages'
import helpers from 'helpers'

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`
  }
}

const detectImageSize = (imageMetadata: TypedMediaMeta) => {}

type Props = {
  medias?: TypedMedia[]
  sxImageList?: SxProps<CurrentTheme>
}

export default function OverviewPostImages({ medias, sxImageList }: Props) {
  const itemLength = medias?.length
  const images = medias?.filter(media => media.media_mime_type.includes('image'))
  const videos = medias?.filter(media => media.media_mime_type.includes('video'))
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null)
  const quickViewMedias = medias.slice(0, 5)
  const handleOpen = (index: number) => {
    setSelectedIndex(index)
  }

  const handleClose = () => {
    setSelectedIndex(null)
  }

  let data = generateMediaSlides(medias)

  return (
    <Box>
      <Lightbox
        open={selectedIndex !== null}
        close={handleClose}
        slides={data}
        plugins={[Video]}
        carousel={{
          finite: true
        }}
        index={selectedIndex}
      />
      <ImageList
        sx={{
          width: '100%',
          height: theme => theme.spacing(64),
          '&.MuiImageList-root': {
            overflowY: 'hidden'
          },
          ...sxImageList
        }}
        variant="quilted"
        cols={6}
        rowHeight={60}
      >
        {generateGridLayoutImage(medias?.slice(0, 5))?.map((item, index) => (
          <ImageListItem
            sx={
              (index === quickViewMedias?.length - 1 && medias.length > 5) ? {
                position: 'relative',
                '&:before': {
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  content: `"+${itemLength - 5}"`,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  color: theme => theme.palette.common.white,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              }: {}
            }
            key={item.media_thumbnail}
            cols={item.cols || 1}
            rows={item.rows || 1}
            onClick={(e) => {
              e.preventDefault();
              handleOpen(index)
            }}
          >
            {item?.media_mime_type?.includes('video') ? (
              <video width="100%" height="100%" controls src={item?.media_url} />
            ) : (
              <img
                {...srcset(item?.media_thumbnail || item?.media_url, 60, item.rows, item.cols)}
                style={{
                  verticalAlign: 'center'
                }}
                // src={item.media_url}
                alt={item.media_file_name}
                loading="lazy"
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  )
}
