import BigLayout from 'layouts/bigLayout'
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
  styled
} from '@mui/material'
import __, { ___ } from 'languages/index'
import ShopBreadcrumb from 'entities/shop/components/shop.breadcrumb'
import { CheckIcon, PlusIcon, TrashAltIcon } from 'icons'
import UploadFileComponent from './shop.upload'
import DropdownComponent from 'components/Dropdown/index'
import { useCallback, useEffect, useRef, useState } from 'react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {CKEditor} from '@ckeditor/ckeditor5-react'

// import vnPayImage from '../media/images/vnPay.png'
// import stripeImage from '../media/images/stripe.png'
// import paypalImage from '../media/images/paypal.png'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import TextField from 'components/TextFieldMUI'
import { validateLabel } from 'entities/support/utils'
import helpers from 'helpers'
import { useAppDispatch } from 'config/store'
import { createProductOfShop, getCategoryOfShop, getCollectionOfShop } from '../store'
import { AxiosError, AxiosResponse } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { getProductById, updateProductById } from '../service/product.service'

interface TypedCategory {
  category_count: number
  category_description: string
  category_id: string
  category_name: string
  category_slug: string
  category_thumbnail: string
  channel_id: string
  createdAt: string
  createdBy: string
}

interface TypedSelectData {
  value: string
  label: string
}

interface TypedCollection {
  collection_id: string
  collection_name: string
  collection_description: string
  collection_count: number
  channel_id: string
  createdAt: string
}

const AddProductComponent = () => {
  const dispatch = useAppDispatch()
  const promotionRef = useRef(null)
  const editorRef = useRef(null)
  const productPriceRef = useRef(null)

  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()


  const [isEditProduct, setIsEditProduct] = useState<boolean>(false)
  const [listSpecifications, setListSpecifications] = useState([])
  const [categoryList, setCategoryList] = useState<{value: string, label: string}[]>([])
  const [collectionList, setCollectionList] = useState<{value: string, label: string}[]>([])
  const productStatus = [
    { value: '1', label: __('display') },
    { value: '0', label: __('not_display') }
  ]
  const handleCreateProduct = (formData)=>{
    dispatch(createProductOfShop({ ...formData }))
    .unwrap()
    .then(() => {
      reset()
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__('add_product_success')}`
      })
      navigate('/shop/manage-product')
    })
    .catch(error => {
      console.error('Create product failed', error)
      toast.show({
        type: EnumTypeToast.Error,
        content: `${__('add_product_failed')}`
      })
    })
  }

  const handleEditProduct = async(formData)=>{
    if(!params?.productId)return
    updateProductById(params?.productId,{ ...formData })
    .then(() => {
      reset()
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__('updateProductSuccess')}`
      })
      navigate('/shop/manage-product')
    })
    .catch(error => {
      console.error('Update product failed', error)
      toast.show({
        type: EnumTypeToast.Error,
        content: `${__('updateProductFailed')}`
      })
    })
  }

  const { fields, submit, reset, dirty, submitting } = useForm({
    fields: {
      product_avatar: useField<string[]>({
        value: [],
        validates: []
      }),
      product_sub_avatar: useField<string[]>({
        value: [''],
        validates: []
      }),
      product_name: useField<string>({
        value: '',
        validates: [
          notEmpty(validateLabel(`${__('product_name')}`)?.required),
          inputVal => {
            if (inputVal?.length > 255) {
              return validateLabel(255)?.maxLength
            }
          }
        ]
      }),
      product_original_price: useField<string>({
        value: '',
        validates: [
          notEmpty(validateLabel(__('product_price'))?.required),
          inputVal => {
            if (!inputVal) {
              return validateLabel(__('product_price'))?.required
            }
          }
        ]
      }),
      product_content: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('product_description')}`)?.required)]
      }),
      product_price: useField<string>({
        value: '',
        validates: []
      }),
      promotion: useField<string>({
        value: '',
        validates: []
      }),
      category_id: useField<any>({
        value: { value: '', label: __('product_portfolio') },
        validates: [
          inputVal => {
            if (!inputVal?.value) {
              return validateLabel(__('product_portfolio'))?.required
            }
          }
        ]
      }),
      collection_id: useField<any>({
        value: { value: '', label: __('product_groups') },
        validates: [
          inputVal => {
            if (!inputVal?.value) {
              return validateLabel(__('product_groups'))?.required
            }
          }
        ]
      }),
      product_status: useField<any>({
        value: { value: '1', label: __('display') },
        validates: []
      }),
      product_unit: useField<string>({
        value: '',
        validates: []
      }),
      product_code: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (!inputVal) {
              return validateLabel(__('product_code'))?.required
            }

            if (inputVal?.length > 50) {
              return validateLabel(50)?.maxLength
            }
          }
        ]
      }),
      product_size_length: useField<string>({
        value: '',
        validates: []
      }),
      product_size_width: useField<string>({
        value: '',
        validates: []
      }),
      product_size_height: useField<string>({
        value: '',
        validates: []
      }),
      product_size_weight: useField<string>({
        value: '',
        validates: []
      }),
      stock_id: useField<string>({
        value: '',
        validates: []
      }),
      specifications: useField<string>({
        value: '',
        validates: []
      }),
      specificationsValue: useField<string>({
        value: '',
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        const convertProduct_specifications = listSpecifications?.map(item => JSON.stringify(item))
        values.product_sub_avatar?.pop()
        const formData = {
          category_id: Number(values.category_id?.value),
          collection_id: [Number(values.collection_id?.value)],
          product_avatar: [...values.product_avatar, ...values.product_sub_avatar],
          product_content: values.product_content,
          product_name: values.product_name?.trim(),
          product_original_price: values.product_original_price,
          product_price: values.product_price,
          product_size_height: values.product_size_height,
          product_size_length: values.product_size_length,
          product_size_weight: values.product_size_weight,
          product_size_width: values.product_size_width,
          product_code: values.product_code?.trim(),
          product_specifications: convertProduct_specifications,
          product_status: values.product_status?.value,
          product_unit: values.product_unit?.trim()
        }
        helpers.clearValueEmptyInObject(formData)
        isEditProduct ? handleEditProduct(formData) : handleCreateProduct(formData)
        return { status: 'success' }
      } catch (e) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleAddSpecifications = useCallback(() => {
    const newData = {
      [fields.specifications.value?.trim()]: fields.specificationsValue.value?.trim()
    }
    setListSpecifications(listSpecifications => [...listSpecifications, newData])
    fields.specifications.onChange('')
    fields.specificationsValue.onChange('')
  }, [[fields.specifications.value, fields.specificationsValue.value, listSpecifications]])

  const handleRemoveSpecifications = useCallback(
    index => {
      listSpecifications.splice(index, 1)
      setListSpecifications(listSpecifications => [...listSpecifications])
    },
    [listSpecifications]
  )

  const handleChangeInputPrice = useCallback(
    (value: string, name: string) => {
      if (helpers.isEmpty(fields[name])) return
      const convertNumber = String(value)?.replace(/[^\d.-]/g, '');
      fields[name].onChange(`${convertNumber || ''}`)
    },
    [fields]
  )

  const handleConvertPromotion = useCallback(() => {
    const priceValue = Number(fields.product_original_price.value)
    const promotionValue = Number(fields.promotion.value)
    const data = ((100 - promotionValue) / 100) * priceValue
    fields.product_price.onChange(`${data}`)
  }, [fields.product_original_price.value, fields.promotion.value, fields.product_price.value])

  useEffect(() => {
    if (helpers.isEmpty(fields.product_original_price.value)) {
      fields.product_price.onChange('')
      fields.product_price.setError('')
      fields.promotion.onChange('')
      return
    }
    promotionRef.current = fields.product_original_price.value
  }, [fields.product_original_price.value])

  //Edit product
  useEffect(()=>{
    if(!params?.productId || helpers.isEmpty(categoryList) || helpers.isEmpty(collectionList))return
    setIsEditProduct(true)
    getProductById(params?.productId)
    .then((res: AxiosResponse)=>{
      const data = res?.data

      const promotion = (data?.product_original_price - data?.product_price) / data?.product_original_price
      const category  = categoryList?.find(value=> value?.value === data?.product_to_category?.category_id) || { value: '', label: __('product_portfolio') }
      const collection  = collectionList?.find(value=> value?.value === data?.product_to_collection?.[0]?.collection_id) || { value: '', label: __('product_groups') }
      const product  = productStatus?.find(value=> value?.value === `${data?.product_status}`) || { value: '1', label: __('display') }
      fields.product_avatar.onChange([data?.product_avatar?.[0]] || [''])
      fields.product_sub_avatar.onChange([...data?.product_avatar?.slice(1),''])
      fields.product_name.onChange(data?.product_name || '')
      fields.product_original_price.onChange(data?.product_original_price || '')
      fields.product_price.onChange(data?.product_price || '')
      fields.product_code.onChange(data?.product_code || '')
      fields.product_unit.onChange(data?.product_unit || '')
      fields.product_size_length.onChange(data?.product_size_length || '')
      fields.product_size_width.onChange(data?.product_size_width || '')
      fields.product_size_height.onChange(data?.product_size_height || '')
      fields.product_size_weight.onChange(data?.product_size_weight || '')
      fields.product_content.onChange(data?.product_content || '')
      fields.category_id.onChange(category)
      fields.product_status.onChange(product)
      fields.collection_id.onChange(collection)
      fields.promotion.onChange(`${(Math.round(promotion * 100))}`)
      setListSpecifications(data?.product_specifications?.map((data: string)=>{
        if(helpers.isJson(data)){
          return JSON.parse(data)
        }
      }))
    })
    .catch((error)=>{
      console.log('getProductById Error >>>',error);
    })
  },[params, categoryList, collectionList])


  const customDropdown = (fields: any, options: TypedSelectData[]) => {
    return (
      <Box display={'grid'} gridTemplateRows={'1fr auto'}>
        <DropdownComponent
          sx={{
            width: '100%',
            // justifyContent: 'space-between',
            display: 'grid',
            gridTemplateColumns: '94% 6%',
            gridGap: theme => theme.spacing(1),
            p: theme => `${theme.spacing(1, 2)} !important`,
            border: theme =>
              `1px solid ${fields?.error ? theme.palette.error.main : theme.palette.divider}`,
            background: theme => theme.palette.background.default,
            height: theme => theme.spacing(7),
            '& h6': {
              fontWeight: 500,
              color: theme => theme.palette.text.secondary
            }
          }}
          options={options}
          variant="text"
          position="left"
          onSelect={(value: TypedSelectData) => fields?.onChange(value)}
          selectData={fields?.value}
          onBlur={() => {
            fields?.runValidation(fields.value)
          }}
        />
        {fields?.error && (
          <Typography component={'span'} variant="body2" color={'error.main'} fontWeight={400}>
            {fields?.error?.toString()}
          </Typography>
        )}
      </Box>
    )
  }

  useEffect(() => {
    dispatch(getCategoryOfShop({}))
      .unwrap()
      .then((res: AxiosResponse) => {
        setCategoryList(res?.data?.map((value: TypedCategory) => ({
          value: value?.category_id,
          label: value?.category_name
        })))
      })
      .catch((error: AxiosError) => {
        console.error(`getCategoryOfShop_>>${error}`)
      })
  }, [])

  useEffect(() => {
    dispatch(getCollectionOfShop())
      .unwrap()
      .then((res: AxiosResponse) => {
        setCollectionList(res?.data?.map((value: TypedCollection) => ({
          value: value?.collection_id,
          label: value?.collection_name
        })))
      })
      .catch((error: AxiosError) => {
        console.error(`getCollectionOfShop_>>${error}`)
      })
  }, [])

  return (
    <BigLayout>
      <AddProductComponentContainer>
        <ShopBreadcrumb
          breadcrumbName={{
            '/shop/manage-product': __('breadcrumb_manage_product'),
            '/shop/manage-product/add': __('manage_product_btn_add')
          }}
        />
        <form onSubmit={submit}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant="h5" textTransform={'none'}>
              {isEditProduct ? __('manage_product_btn_edit') : __('manage_product_btn_add')}
            </Typography>
            <Button
              disabled={!dirty || submitting}
              type="submit"
              startIcon={<CheckIcon color="inherit" />}
            >
              <Typography textTransform={'none'}>{isEditProduct ? __('update_product') : __('save_product')}</Typography>
            </Button>
          </Box>
          <Box width={'100%'}>
            <Grid container spacing={3} columns={8}>
              <Grid item xs={3} display={'grid'} rowGap={theme => theme.spacing(3)} height={'100%'}>
                <Paper className="wrapper">
                  <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                    {__('image_product')}
                  </Typography>
                  <Box height={theme => theme.spacing(42.8755)}>
                    <UploadFileComponent
                      mediaUrl={fields.product_avatar.value?.[0] || ''}
                      removeMediaUrl={() => {
                        fields.product_avatar.onChange([])
                      }}
                      setMediaUrl={data => {
                        fields.product_avatar.onChange([data])
                      }}
                    />
                  </Box>
                  {(fields.product_avatar.value?.length > 0 ||
                    fields.product_sub_avatar.value?.length > 1) && (
                    <Box
                      display={'grid'}
                      gap={theme => theme.spacing(2)}
                      gridTemplateColumns={theme =>
                        `repeat(auto-fill,minmax(${theme.spacing(12.5)},1fr))`
                      }
                      justifyContent={'flex-start'}
                    >
                      {fields.product_sub_avatar.value?.map((value, idx) => (
                        <Box
                          key={idx}
                          maxWidth={theme => theme.spacing(12.5)}
                          height={theme => theme.spacing(12.5)}
                        >
                          <UploadFileComponent
                            mediaUrl={value}
                            removeMediaUrl={() => {
                              const arr = [...fields.product_sub_avatar.value]
                              arr?.splice(idx, 1)
                              fields.product_sub_avatar.onChange([...arr])
                            }}
                            setMediaUrl={data => {
                              const arr = [...fields.product_sub_avatar.value]
                              arr?.splice(idx, 1, data)
                              fields.product_sub_avatar.onChange([...arr, ''])
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Paper>

                <Paper className="wrapper">
                  <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                    <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                      {__('display_management')}
                    </Typography>
                    <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                      {customDropdown(
                        fields.category_id,
                        categoryList
                      )}
                      {customDropdown(
                        fields.collection_id,
                        collectionList
                      )}
                      {customDropdown(fields.product_status,productStatus )}
                    </Box>
                  </Box>
                </Paper>

                {/* <Paper className="wrapper">
                <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                  <Typography
                    textTransform={'none'}
                    variant="subtitle1"
                    color={'primary.main'}
                    fontWeight={600}
                  >
                    {__('Affiliate')}
                  </Typography>
                  <Box
                    display={'grid'}
                    gridTemplateColumns={'1fr 1fr'}
                    columnGap={theme => theme.spacing(2)}
                  >
                    <OutlinedInput
                      className="outline_input background"
                      placeholder={__('commission_rate')}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <Typography fontWeight={500}>%</Typography>
                        </InputAdornment>
                      }
                    />
                    <OutlinedInput
                      className="outline_input background"
                      placeholder={__('discount')}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <Typography fontWeight={500}>VND</Typography>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Box>

                <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                  <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                    {__('promotion')}
                  </Typography>
                  <Box
                    display={'grid'}
                    gridTemplateColumns={'1fr 1fr'}
                    columnGap={theme => theme.spacing(2)}
                  >
                    <OutlinedInput
                      className="outline_input"
                      placeholder={__('promotion')}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <Typography fontWeight={500}>%</Typography>
                        </InputAdornment>
                      }
                    />
                    <OutlinedInput
                      className="outline_input "
                      placeholder={__('promotional_price')}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <Typography fontWeight={500}>VND</Typography>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </Box>

                <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                      {__('gift_upon_purchase')}
                    </Typography>
                    <Button startIcon={<PlusIcon color="inherit" />}>
                      <Typography textTransform={'none'} variant="body2">
                        {__('add')}
                      </Typography>
                    </Button>
                  </Box>
                  <List sx={{ p: 0 }}>
                    <ListItem sx={{ p: 0 }}>
                      <Paper
                        sx={{
                          width: '100%',
                          p: theme => theme.spacing(1.5),
                          background: theme => theme.palette.background.default
                        }}
                      >
                        <Box className="gift_item">
                          <Avatar
                            sx={{
                              borderRadius: theme => theme.spacing(0.75),
                              width: theme => theme.spacing(7.375),
                              height: theme => theme.spacing(7.375)
                            }}
                            alt="image_product"
                          >
                            A
                          </Avatar>
                          <Box overflow={'auto'}>
                            <Typography variant="body2" fontWeight={600} noWrap>
                              {__('Son Ngọc Không Chì')}
                            </Typography>
                            <Typography variant="caption" color={'primary.main'} fontWeight={700}>
                              {__('143.000 VND')}
                            </Typography>
                          </Box>

                          <IconButton className="icon_button">
                            <TrashAltIcon
                              sx={{
                                width: theme => theme.spacing(3),
                                height: theme => theme.spacing(3)
                              }}
                              color="inherit"
                            />
                          </IconButton>
                        </Box>
                      </Paper>
                    </ListItem>
                  </List>
                </Box>
              </Paper> */}
              </Grid>
              <Grid item xs={5} display={'grid'} rowGap={theme => theme.spacing(3)} height={'100%'}>
                <Paper className="wrapper">
                  <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                    <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                      {__('basic_information')}
                    </Typography>
                    <Box display={'grid'} rowGap={theme => theme.spacing(1)}>
                      <TextField
                        {...fields.product_name}
                        maxLength={255}
                        autoFocus
                        placeholder={__('product_name')}
                      />
                      <TextField
                        {...fields.product_original_price}
                        onChange={value => handleChangeInputPrice(value, 'product_original_price')}
                        placeholder={__('product_price')}
                        onBlur={() => {
                          if (fields.product_original_price.value) {
                            if(fields.product_original_price.value !== productPriceRef.current || !fields.product_price.value) {
                              fields.product_price.onChange(promotionRef.current)
                              productPriceRef.current = fields.product_original_price.value
                              fields.promotion.onChange( '0')
                            }
                          }
                          fields.product_original_price.runValidation(
                            fields.product_original_price.value
                          )
                        }}
                        value={
                          fields.product_original_price.value
                            ? helpers.formatNumberWithCommas(fields.product_original_price.value)
                            : ''
                        }
                        min={0}
                        maxLength={11}
                        iconEnd={<Typography fontWeight={500}>VND</Typography>}
                      />
                    </Box>
                    {fields.product_original_price.value && (
                      <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                        <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                          {__('promotion')}
                        </Typography>
                        <Box
                          display={'grid'}
                          gridTemplateColumns={'1fr 1fr'}
                          columnGap={theme => theme.spacing(2)}
                        >
                          <TextField
                            {...fields.promotion}
                            onChange={value => {
                              const formatValue = Math.min(100, Math.max(0, parseFloat(value)))
                              handleChangeInputPrice(`${formatValue}`, 'promotion')
                            }}
                            onBlur={() => {
                              handleConvertPromotion()
                            }}
                            maxLength={3}
                            placeholder={__('promotion')}
                            iconEnd={<Typography fontWeight={500}>%</Typography>}
                          />
                          <TextField
                            {...fields.product_price}
                            value={helpers.convertToCommasFormat(
                              Number(fields.product_price.value)
                            )}
                            readOnly
                            maxLength={11}
                            placeholder={__('promotional_price')}
                            iconEnd={<Typography fontWeight={500}>VND</Typography>}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {/* <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                    <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                      {__('payment_method')}
                    </Typography>
                    <Box display={'flex'} columnGap={theme => theme.spacing(3)}>
                      <Button className="payment_button" variant="text">
                        <img src={vnPayImage} style={{ objectFit: 'cover' }} alt="image" />
                      </Button>
                      <Button className="payment_button" variant="text">
                        <img src={stripeImage} style={{ objectFit: 'cover' }} alt="image" />
                      </Button>
                      <Button className="payment_button" variant="text">
                        <img src={paypalImage} style={{ objectFit: 'cover' }} alt="image" />
                      </Button>
                      <Button
                        className="payment_button"
                        variant="text"
                        sx={{
                          p: theme => theme.spacing(0, 1)
                        }}
                      >
                        <Typography
                          textTransform={'none'}
                          color={'text.primary'}
                          variant="caption"
                          fontWeight={600}i
                        >
                          {__('payment_upon_delivery')}
                        </Typography>
                      </Button>
                    </Box>
                  </Box> */}
                </Paper>

                <Paper className="wrapper">
                  <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                    {__('storage_info')}
                  </Typography>

                  <Box display={'grid'} gap={theme => theme.spacing(1)}>
                    <Box display={'grid'} rowGap={theme => theme.spacing(1)}>
                      <Box
                        display={'grid'}
                        gridTemplateColumns={'1fr 1fr'}
                        columnGap={theme => theme.spacing(2)}
                      >
                        <TextField
                          {...fields.product_code}
                          maxLength={50}
                          placeholder={`${__('product_code')}`}
                        />
                        <TextField
                          {...fields.product_unit}
                          placeholder={__('unit')}
                          maxLength={15}
                          // iconEnd={<Typography fontWeight={500}>{__("storage_info_unit")}</Typography>}
                        />
                      </Box>
                    </Box>
                    <Box
                      display={'grid'}
                      gridTemplateColumns={'1fr 1fr'}
                      columnGap={theme => theme.spacing(2)}
                    >
                      <TextField
                        {...fields.product_size_length}
                        onChange={value => handleChangeInputPrice(value, 'product_size_length')}
                        maxLength={7}
                        placeholder={__('length')}
                        iconEnd={<Typography fontWeight={500}>cm</Typography>}
                      />
                      <TextField
                        {...fields.product_size_width}
                        onChange={value => handleChangeInputPrice(value, 'product_size_width')}
                        maxLength={7}
                        placeholder={__('width')}
                        iconEnd={<Typography fontWeight={500}>cm</Typography>}
                      />
                    </Box>
                    <Box
                      display={'grid'}
                      gridTemplateColumns={'1fr 1fr'}
                      columnGap={theme => theme.spacing(2)}
                    >
                      <TextField
                        {...fields.product_size_height}
                        onChange={value => handleChangeInputPrice(value, 'product_size_height')}
                        maxLength={7}
                        placeholder={__('height')}
                        iconEnd={<Typography fontWeight={500}>cm</Typography>}
                      />
                      <TextField
                        {...fields.product_size_weight}
                        onChange={value => handleChangeInputPrice(value, 'product_size_weight')}
                        maxLength={7}
                        placeholder={__('weight')}
                        iconEnd={<Typography fontWeight={500}>kg</Typography>}
                      />
                    </Box>
                  </Box>
                </Paper>

                <Paper className="wrapper">
                  <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                    <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                      {__('product_specifications')}
                    </Typography>
                    {!helpers.isEmpty(listSpecifications) && (
                      <List sx={{ p: 0, display: 'grid', rowGap: theme => theme.spacing(2) }}>
                        {listSpecifications.map((item, idx) => (
                          <ListItem
                            key={idx}
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: 'auto 1fr auto',
                              p: 0,
                              columnGap: theme => theme.spacing(2),
                              height: theme => theme.spacing(7)
                            }}
                          >
                            <Typography
                              width={theme => theme.spacing(30)}
                              fontWeight={500}
                              color="text.secondary"
                              noWrap
                            >
                              {__(Object.keys(item)?.[0])}
                            </Typography>
                            <Typography fontWeight={500} color="text.secondary" noWrap>
                              {__(item[Object.keys(item)?.[0]])}
                            </Typography>
                            <IconButton
                              className="icon_button"
                              onClick={() => handleRemoveSpecifications(idx)}
                            >
                              <TrashAltIcon
                                sx={{
                                  width: theme => theme.spacing(3),
                                  height: theme => theme.spacing(3)
                                }}
                                color="inherit"
                              />
                            </IconButton>
                          </ListItem>
                        ))}
                      </List>
                    )}
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr auto',
                        p: 0,
                        alignItems: 'flex-start',
                        columnGap: theme => theme.spacing(2)
                      }}
                    >
                      <Box
                        sx={{
                          width: theme => theme.spacing(30)
                        }}
                      >
                        <TextField {...fields.specifications} placeholder={__('specifications')} />
                      </Box>
                      <TextField {...fields.specificationsValue} placeholder={__('value')} />
                      <IconButton
                        type="button"
                        onClick={handleAddSpecifications}
                        disabled={Boolean(
                          !fields.specifications.value?.trim() ||
                            !fields.specificationsValue.value?.trim()
                        )}
                        className={'icon_button_add'}
                      >
                        <PlusIcon
                          sx={{
                            width: theme => theme.spacing(3),
                            height: theme => theme.spacing(3)
                          }}
                          color="inherit"
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Paper>

                <Paper className="wrapper">
                  <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
                    <Typography textTransform={'none'} variant="subtitle1" fontWeight={600}>
                      {__('product_description')}
                    </Typography>
                    {/*Start:  Ckeditor */}
                    <Box>
                      <Box
                        sx={{
                          border: theme =>
                            `1px solid ${
                              fields.product_content.error
                                ? theme.palette.error.main
                                : theme.palette.divider
                            }`,
                            '& .ck-editor__editable_inline': {
                              minHeight: theme =>theme.spacing(50)
                            }
                        }}
                      >
                         <CKEditor
                          editor={ClassicEditor}
                          ref={editorRef}
                          data={fields.product_content?.value}
                          onChange={(event, editor)=>{
                            fields.product_content.onChange(editor.getData())
                          }}
                          config={{
                            placeholder: `${__("product_description")}`,
                            plugins: ['Bold', 'Italic', 'Heading', 'Link', 'List', 'Table', 'TableToolbar']
                          }}
                        />
                      </Box>
                      {fields.product_content?.error && (
                        <Typography
                          component={'span'}
                          variant="body2"
                          color={'error.main'}
                          fontWeight={400}
                        >
                          {fields.product_content?.error?.toString()}
                        </Typography>
                      )}
                    </Box>
                    {/*End:  Ckeditor */}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </form>
      </AddProductComponentContainer>
    </BigLayout>
  )
}
export default AddProductComponent

const AddProductComponentContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(2),
  '& form': {
    display: 'grid',
    rowGap: theme.spacing(2)
  },
  '& .wrapper': {
    display: 'grid',
    rowGap: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(3)
  },
  '& .outline_input': {
    width: '100%',
    borderRadius: theme.spacing(1.5),
    background: theme.palette.background.default,
    caretColor: theme.palette.primary.main
  },
  '& .background': {
    background: theme.palette.primary.background
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .icon_button_add': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    color: theme.palette.background.paper,
    background: theme.palette.primary.main,
    marginTop: theme.spacing(0.75),
    ':hover': {
      background: theme.palette.primary.main
    },
    ':disabled': {
      background: theme.palette.background.secondary,
      color: theme.palette.text.disabled
    }
  },
  '& .gift_item': {
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    columnGap: theme.spacing(3),
    gridTemplateColumns: 'auto 1fr auto'
  },
  '& .payment_button': {
    background: theme.palette.background.default,
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    border: `1px solid ${theme.palette.divider}`
  }
}))
