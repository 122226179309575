import {TypedCourse} from 'entities/courses/courses.interface'
import React, {useCallback, useMemo, useState} from 'react'
import CoursesProgress from 'entities/courses/components/courses.progress'
import {Link, useNavigate} from 'react-router-dom'
import {EnumPublicStatus, EnumPublishStatus} from 'entities/courses/media/constants'
import {useAppDispatch, useAppSelector} from 'config/store'
import {faBan, faCircleCheck, faLayerGroup, faMedal} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//Store
import {updateStatusJoinCourse} from '../courses.store.reducer'

//Helper
import helpers from 'helpers'

//Scss
import '../media/courses.list.item.scss'
import imageDefault from "media/images/image_default.png";
import __ from "languages/index";

interface TypedCoursesListItemProps {
  course: TypedCourse
}

export default function CoursesListItem({ course }: TypedCoursesListItemProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const channel_data: any = useAppSelector(state => state.user.channel_data)

  const {
    _id,
    media_id,
    avatar,
    description,
    trash_status,
    title,
    level_value,
    public_status,
    total_view,
    module_child_count,
    is_join,
    coin_value,
    service_id
  } = course

  const [percentComplete, setPercentComplete] = useState < number > (
    module_child_count == 0
      ? 0
      : Math.min(
        100,
        Math.max(0, Math.round((Number(total_view || 0) * 100) / Number(module_child_count || 1)))
      )
  )
  const permission_data = useAppSelector(state => state.user.permission_data);
  const user_in_this_channel = useAppSelector(state => state.user.user_in_this_channel);


  const handleUpdateStatusIsJoin = useCallback(() => {
    dispatch(updateStatusJoinCourse({ course_id: _id }))
    navigate(`/study/${_id}`)
  }, [course])

  return (
    <div className="courselistitem col-sm-6 col-lg-4 col-xl-3">
      <div className="courselistitem_container">
        <div className="courselistitem_container_img">

          {avatar?.media_url ? (
            avatar?.media_type !== 'video' ? (
              <img
                className="courselistitem_img clickable"
                src={avatar?.media_url}
                alt=""
                onClick={() => navigate('/courses/view/' + _id)}
              />
            ) :
              <video
                className="courselistitem_img clickable"
                width={'auto'}
                disablePictureInPicture
                height={'auto'}
                controls
              >
                <source src={avatar?.media_url} type="video/mp4" />
              </video>
          ) : (
            <img className="courselistitem_img clickable" src={channel_data?.avatar?.media_thumbnail?.length > 0 ? channel_data?.avatar?.media_thumbnail : channel_data?.avatar?.media_url || imageDefault} alt="" onClick={() => navigate('/courses/view/' + _id)} />
          )}
        </div>

        <div className="courselistitem_bottom d-flex flex-column justify-content-between">
          <div className="courselistitem_container_info">
            <Link to={'/courses/view/' + _id}>
              <div className="courselistitem_title">{title}</div>
              <div className="courselistitem_description">{description}</div>
            </Link>
          </div>

          <div className="">
            {coin_value > 0 && (
              <div className="courselistitem_card_info_price">
                <span className="fs-6 fw-bold text-dark">{__("course_price")}</span>
                <span className="fs-6 fw-bold text-dark">
                  {`${helpers.convertToCommasFormat(coin_value || '0')} ${__("currency_unit")}`}
                </span>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faLayerGroup} className="courselistitem_img_layer_time" />
                <p className="courselistitem_txt_layer_time">{`${module_child_count} ${__("course_item_lesson")}`}</p>
              </div>
              {public_status === EnumPublicStatus.public ? (
                <p className="courselistitem_txt_layer_public">{__("course_public")}</p>
              ) : (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faMedal} className="courselistitem_img_layer_time" />
                  <p className="courselistitem_txt_layer_time">{`${__("course_item_LvL")} ${level_value} ${__("course_upto")}`}</p>
                </div>
              )}
            </div>

            <div className="mt-2">
              {helpers.current_user_can("boss", permission_data) ? (
                //Mentor role
                <button
                  onClick={() => navigate('/courses/update/' + _id)}
                  className="d-flex flex-column align-self-center align-items-center justify-content-center courselistitem_container_status courselistitem_btn shadow-lg btn-primary w-100 border-0 text-capitalize"
                >
                  {__("course_update")}
                </button>
              ) : //User role
                trash_status === EnumPublishStatus.public ? (
                  module_child_count > 0 ? (
                    user_in_this_channel?.level_number >= level_value ? (
                      percentComplete === 0 ? (
                        //Not yet
                        <>
                          {is_join ? (
                            <button
                              onClick={() => navigate('/study/' + _id)}
                              className="d-flex flex-column align-self-center align-items-center justify-content-center courselistitem_container_status courselistitem_btn shadow-lg btn-primary w-100 border-0 text-capitalize"
                            >
                              {__("course_btn_join")}
                            </button>
                          ) : (
                            <>
                              {coin_value ? (
                                <button
                                  className="d-flex flex-column align-self-center align-items-center justify-content-center courselistitem_container_status courselistitem_btn shadow-lg btn-primary w-100 border-0 text-capitalize"
                                  onClick={() => navigate(`/payment?service_id=${service_id}`)}
                                >
                                  {__("course_btn_buy")}
                                </button>
                              ) : (
                                <button
                                  className="d-flex flex-column align-self-center align-items-center justify-content-center courselistitem_container_status courselistitem_btn shadow-lg btn-primary w-100 border-0 text-capitalize"
                                  onClick={handleUpdateStatusIsJoin}
                                >
                                  {__("course_btn_join_now")}
                                </button>
                              )}
                            </>
                          )}
                        </>
                      ) : percentComplete === 100 ? (
                        // Complete
                        <div
                          onClick={() => navigate('/courses/view/' + _id)}
                          className="d-flex align-items-center justify-content-center courselistitem_container_status clickable"
                        >
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="courselistitem_img_status"
                          />
                          <span className="courselistitem_txt_status done">
                            {__("course_completed")}
                          </span>
                        </div>
                      ) : (
                        //In-progress
                        <div
                          className="d-flex flex-column justify-content-center courselistitem_container_status clickable"
                          onClick={() => navigate('/courses/view/' + _id)}
                        >
                          <div className="d-flex justify-content-between">
                            <span className="courselistitem_txt_progress">{__("course_progress")}</span>
                            <span className="courselistitem_txt_progress percent">
                              {percentComplete.toFixed(0) + '%'}
                            </span>
                          </div>
                          <CoursesProgress
                            percentValue={percentComplete}
                            styleProgress={{ backgroundColor: '#a51c30' }}
                          />
                        </div>
                      )
                    ) : (
                      <div>
                        {is_join ?
                          <button
                            onClick={() => navigate('/study/' + _id)}
                            className="d-flex flex-column align-self-center align-items-center justify-content-center courselistitem_container_status courselistitem_btn shadow-lg btn-primary w-100 border-0 text-capitalize"
                          >
                            {__("course_btn_join")}
                          </button> :
                          <div
                            className="d-flex align-items-center justify-content-center courselistitem_container_status clickable"
                            onClick={() => navigate('/courses/view/' + _id)}
                          >
                            <FontAwesomeIcon icon={faBan} className="courselistitem_img_status ban" />
                            <span className="courselistitem_txt_status ban">{__("course_not_allow_level")}</span>
                          </div>
                        }
                      </div>

                    )
                  ) : (
                    <div
                      className="d-flex align-items-center justify-content-center courselistitem_container_status clickable"
                      onClick={() => navigate('/courses/view/' + _id)}
                    >
                      <FontAwesomeIcon icon={faBan} className="courselistitem_img_status ban" />
                      <span className="courselistitem_txt_status ban">{__("course_not_ready")}</span>
                    </div>
                  )
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center courselistitem_container_status clickable"
                    onClick={() => navigate('/courses/view/' + _id)}
                  >
                    <FontAwesomeIcon icon={faBan} className="courselistitem_img_status ban" />
                    <span className="courselistitem_txt_status ban">{__("course_not_ready")}</span>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
