import {ListPlan} from "entities/payment/payment.interface";
import __helpers from "helpers/index";
import __ from "languages/index";

export const getMinPricePlan = (plan: ListPlan) => {
  if(plan?.amount_of_day <= 30){
    return `${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}/${plan?.amount_of_day}${__("format_time_date")}`
  }
  if (plan?.amount_of_day <= 365){
    return `${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}/${plan?.amount_of_day/30}${__("format_time_month")}`
  }
  if(plan?.amount_of_day > 365 && plan.amount_of_day < 3650){
    return `${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}/${plan?.amount_of_day/365}${__("format_time_year")}`
  }

  return `${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}`
}
