//Components
import ChannelInformation from './components/leaderBoard.channel.info'
import RatingLeaderBoard from './components/leaderBoard.rating'
import CheckChannelPayment from 'components/CheckChannelPayment'
import _Helmet from 'components/helmet'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Box, Drawer, IconButton, Stack, styled, Typography } from '@mui/material'

//Store
import { useAppSelector } from 'config/store'

//Interface
import TypedChannel from 'interfaces/channel.interface'
import React, { useState } from "react";
import { CloseIcon } from 'icons'

const LeaderBoardPage = () => {
  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)
  const [toggle, setToggle] = useState(false);

  return (
    <LeaderBoardContainer id="leaderBoardPage" container columns={10} spacing={3}>
      <_Helmet title="leader_board_title" />
      <Grid className="space" xs={1}></Grid>
      <Grid className="main" xs={5}>
        <Box>
          {channel_data?.official_status !== 0 ? <RatingLeaderBoard setToggle={setToggle} /> : <CheckChannelPayment />}
        </Box>
      </Grid>
      <Grid className="space" xs={1}></Grid>
      <Grid xs={3} className="infomation" >
        <ChannelInformation />
      </Grid>

      <DrawerChannelInfoContainer
        PaperProps={{
          sx: {
            width: '100%',
            borderRadius: '0'
          },
          elevation: 8
        }}
        anchor="right"
        open={toggle}
        onClose={() => setToggle(false)}
        className="list_user_mobile"
      >
        <Stack alignItems={'center'} flexDirection={'row'} className="header_list_user">
          <IconButton className="icon_close" onClick={() => setToggle(false)}>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' >Điểm</Typography>
        </Stack>
        <ChannelInformation />

      </DrawerChannelInfoContainer>
    </LeaderBoardContainer>
  )
}

export default LeaderBoardPage

const LeaderBoardContainer = styled(Grid)(({ theme }) => ({
  '& .right_bar': {
    height: `calc(100vh - ${theme.spacing(12.5)})`,
    overflow: 'auto',
    transition: `padding-right 0.3s ease`,
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },



  '@media(max-width: 1024px)': {
    borderRadius: 0,
    '& .infomation': {
      display: 'none'
    },
    '& .space': {
      display: 'none'

    },
    '& .main': {
      width: '100%'
    },

  }

}))

const DrawerChannelInfoContainer = styled(Drawer)(({ theme }) => ({
  '& .header_list_user': {
    display: 'flex',
    height: '100%',
    alignItems: 'center',

    '& h6': {
      flex: '1 0 0',
      marginRight: theme.spacing(3.8),
      textAlign: 'center'
    }
  }
}))
