import {useCallback, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import __ from "languages/index";

const ModalSettingUser = ({
  show,
  onClose,
  children,
  ...args
}: {
  show: boolean
  onClose: Function
  children: JSX.Element
  args?: any
}) => {
  const [isShow, setIsShow] = useState(false)

  const closeOnly = useCallback(() => {
    setIsShow(false)
    onClose?.call(this, false, args)
  }, [])

  useEffect(() => {
    setIsShow(show)
  }, [show])

  return (
    <>
      <Modal show={isShow} onHide={closeOnly}>
        <Modal.Header closeButton>
          <Modal.Title>{__("member_setting_user")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  )
}

export default ModalSettingUser
