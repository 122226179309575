import './media/courses.update.scss'

import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'

import {useAppDispatch, useAppSelector} from 'config/store'
import DragDropUploadMedia from 'entities/courses/components/dragDropUploadMedia'
import {TypedLevel} from 'entities/courses/courses.interface'
import {
  clearEntity,
  createCourse,
  getDefaultImage,
  getEntity,
  getLevels,
  updateCourse
} from 'entities/courses/courses.store.reducer'
import {EnumPublicStatus, EnumPublishStatus} from 'entities/courses/media/constants'
import {isEqual} from 'lodash'
import {Form, Row} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import {useNavigate, useParams} from 'react-router-dom'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import helpers from 'helpers'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import {ConfirmModal} from 'components/modalConfirm'
import _Helmet from 'components/helmet'
import Title from 'components/Title'
import {notEmpty, useField, useForm} from '@shopify/react-form'
import imageDefault from 'media/images/image_default.png'
import __ from "languages/index";
import Capacity from "components/Capacity";
import BigLayout from "../../layouts/bigLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";

export default function CoursesUpdate() {
  let useParam = useParams()
  const dispatch = useAppDispatch()
  const user_data = useAppSelector(state => state.user.user_data)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const listLevel: TypedLevel[] = useAppSelector(state => state.courses.listLevel)
  const entity = useAppSelector(state => state.courses.entity)
  const [urlCourseMedia, setUrlCourseMedia] = useState(entity?.avatar?.media_thumbnail)

  const navigate = useNavigate()

  const refInputTitle = useRef<any>()
  const refInputDescription = useRef<any>()
  const refConfirmModal = useRef<any>()

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [validate, setValidate] = useState(false)
  const toast = useToast()
  const { fields, reset } = useForm({
    fields: {
      title: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("course_update_name_error_text")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("course_update_name_error_text")}`
            }
          }
        ]
      }),
      description: useField<string>({
        value: '',
        validates: []
      }),
      long_description: useField<string>({
        value: '',
        validates: []
      }),
      avatar: useField<string>({
        value: '',
        validates: []
      }),
      coin_value: useField<string>({
        value: '',
        validates: []
      }),
      level_value: useField<string>({
        value: '1',
        validates: []
      }),
      public_status: useField<string>({
        value: EnumPublicStatus.public,
        validates: []
      }),
      trash_status: useField<string>({
        value: 'trash',
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const refValueDefaultCourse = useRef({
    title: useParam.courses_id ? entity?.title || '' : '',
    description: useParam.courses_id ? entity?.description || '' : '',
    long_description: useParam.courses_id ? entity?.long_description || '' : '',
    avatar: useParam.courses_id ? entity?.avatar?._id || '' : '',
    level_value: useParam.courses_id ? entity?.level_value || 1 : 1,
    coin_value: useParam.courses_id ? entity?.coin_value || '0' : '0',
    public_status: useParam.courses_id
      ? entity?.public_status || EnumPublicStatus.public
      : EnumPublicStatus.public,
    trash_status: useParam.courses_id
      ? entity?.trash_status || EnumPublishStatus.public
      : EnumPublishStatus.public
  })

  useLayoutEffect(() => {
    window.scrollTo(0, 0)

    if(permission_data){
      if (helpers.current_user_can("boss", permission_data)) {
        dispatch(
          getLevels({
            channel_id: channel_data?._id
          })
        )
        if (useParam.courses_id) {
          dispatch(
            getEntity({
              _id: useParam.courses_id,
              auth_id: user_data?._id
            })
          )
        }
      }
    }

    return () => {
      dispatch(clearEntity())
    }
  }, [permission_data])


  useEffect(() => {

    if (entity) {
      fields.avatar.onChange(entity?.avatar?._id)
      fields.title.onChange(entity?.title)
      fields.description.onChange(entity?.description)
      fields.long_description.onChange(entity?.long_description)
      fields.level_value.onChange(entity?.level_value)
      fields.coin_value.onChange(entity?.coin_value)
      fields.public_status.onChange(entity?.public_status)
      fields.trash_status.onChange(entity?.trash_status)

      refValueDefaultCourse.current = {
        title: entity?.title,
        description: entity?.description,
        long_description: entity?.long_description,
        avatar: entity?.avatar?._id,
        level_value: entity?.level_value,
        coin_value: `${entity?.coin_value}`,
        public_status: entity?.public_status,
        trash_status: entity?.trash_status
      }

      if (
        refInputTitle?.current?.scrollHeight !== undefined &&
        refInputTitle.current?.style?.height !== undefined
      ) {
        refInputTitle.current.style.height = 'auto'
        refInputTitle.current.style.height = refInputTitle.current.scrollHeight + 'px'
      }

      if (
        refInputDescription?.current?.scrollHeight !== undefined &&
        refInputDescription.current?.style?.height !== undefined
      ) {
        refInputDescription.current.style.height = 'auto'
        refInputDescription.current.style.height = refInputDescription.current.scrollHeight + 'px'
      }

      setUrlCourseMedia(entity?.avatar?.media_thumbnail)
    }
  }, [entity])

  const onSubmitCourse = useCallback(
    async event => {
      try {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
        } else {
          event.preventDefault()
          event.stopPropagation()

          setLoading(true)
          if (!fields.avatar?.value) {
            delete fields.avatar
          }

          const formData = {
            title: fields.title?.value,
            description: fields.description?.value,
            long_description: fields.long_description?.value,
            avatar: fields.avatar?.value,
            level_value: fields.level_value?.value,
            coin_value: `${helpers.parseNumeric(fields.coin_value?.value || '0')}`,
            public_status: fields.public_status?.value,
            trash_status: fields.trash_status?.value
          }

          let res: any = useParam.courses_id
            ? await dispatch(
                updateCourse({
                  ...formData,
                  _id: useParam.courses_id,
                  level_value: fields.level_value?.value + ''
                })
              )
            : await dispatch(
                createCourse({
                  ...formData,
                  level_value: fields.level_value?.value + ''
                })
              )

          if (res?.type?.includes('fulfilled')) {
            toast.show({
              content: (useParam.courses_id ? `${__("course_update_success")}` : `${__("course_create_success")}`),
              type: EnumTypeToast.Success
            })

            if (res?.payload?.data?._id) {
              navigate('/courses/view/' + res.payload.data?._id)
            } else {
              navigate('/courses/list')
            }
          } else {
            if (res?.type?.includes('rejected')) {
              toast.show({
                content: `${__("course_create_error_text")}`,
                type: EnumTypeToast.Error
              })
            }

            setLoading(false)
          }
        }

        setValidate(true)
      } catch (error) {}
    },
    [useParam.courses_id, fields]
  )

  const handleSelectLevelChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    fields.level_value.onChange(event?.target?.value)
  }, [])

  const handleSelectPublicChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      fields.public_status.onChange(value)
      value === EnumPublicStatus.public && fields.level_value.onChange('1')
    },
    [fields, listLevel]
  )

  const handleAvatarChange = useCallback(
    (media_id: string) => {
      fields.avatar.onChange(media_id)
    },
    [fields]
  )

  const onPressCancel = useCallback(() => {
    const currentData = {
      title: fields.title?.value,
      description: fields.description?.value,
      long_description: fields.long_description?.value,
      avatar: fields.avatar?.value,
      level_value: fields.level_value?.value,
      coin_value: `${fields.coin_value?.value}`,
      public_status: fields.public_status?.value,
      trash_status: fields.trash_status?.value
    }
    if (isEqual(refValueDefaultCourse.current, currentData)) {
      if (useParam.courses_id) {
        navigate('/courses/view/' + entity?._id)
      } else {
        navigate('/courses/list')
      }
    } else {
      refConfirmModal.current?.show({
        title: 'Hủy bỏ',
        description:
          (useParam.courses_id ? `${__("course_update_cancel")}` : `${__("course_drop")}`) +
          `${__("course_confirm_text")}`,
        confirmText: useParam.courses_id ? 'Tiếp tục chỉnh sửa' : 'Giữ khóa học',
        onCancel: () => navigate('/courses/list')
      })
    }
  }, [entity?._id, fields, useParam])

  const adjustTextareaHeight = (textarea: any) => {
    textarea.style.height = 'auto'
    textarea.style.height = textarea.scrollHeight + 'px'
  }

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if ('e+-'.includes(event.key)) {
      event.preventDefault() // Chặn sự kiện nhập ký tự e, -, +
    }
  }, [])

  const preventPasteNegative = useCallback(e => {
    const clipboardData = e.clipboardData
    const pastedData = parseFloat(clipboardData.getData('text'))

    if (isNaN(pastedData) || pastedData < 0) {
      e.preventDefault()
    }
  }, [])

  useEffect(() => {
    if (useParam.courses_id){
      dispatch(
        getEntity({
          _id: useParam.courses_id,
          auth_id: user_data?._id || ''
        })
      )
      return;
    }

    reset()
    dispatch(getDefaultImage())
      .unwrap()
      .then(res => {
        setUrlCourseMedia(res?.data?.media_url)
        fields.avatar?.onChange(res?.data?._id)
      })
      .catch(error => {
        console.log(`getDefaultImage_${error}`)
        setUrlCourseMedia('')
        fields.avatar?.onChange('')
      })
  }, [])

  return (
    <BigLayout>
      <div id="course-update">
        <_Helmet title='course_update' />
        <div id={'course-update'}>
          <div className="bg-white container p-4 my-3 rounded-2">
            <Form
              className="needs-validation"
              noValidate
              validated={validate}
              onSubmit={onSubmitCourse}
            >
              <Title text={`${useParam.courses_id ? `${__("course_edit")}` : `${__("course_add_title")}`}`} />
              <Row className="h-100">
                <Col xs="12" lg={5} className="p-3 h-100 flex-column d-flex">
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="image">{__("course_image_cover")}</Form.Label>
                    <div className="courseupdate_uploadmedia_container">
                      <DragDropUploadMedia
                        callbackSetUrl={handleAvatarChange}
                        urlDefaultThumbnail={urlCourseMedia || imageDefault}
                        placeholderText={<div>{__("course_size_image_cover")}</div>}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="description">{__("course_detail")}</Form.Label>
                    <CKEditor
                      {...fields.long_description}
                      id={'description'}
                      editor={ClassicEditor}
                      data={fields.long_description?.value}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        fields.long_description.onChange(data)
                      }}
                      config={{
                        placeholder: `${__("course_placeholder")}`,
                        plugins: [
                          'Bold',
                          'Italic',
                          'Heading',
                          'Link',
                          'List',
                          'Table',
                          'TableToolbar'
                        ]
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs="12" lg={7} className="p-3 h-100 courseupdate_container_form">
                  <div>
                    <Form.Group className="mb-4">
                      <Form.Label htmlFor="name">{__("course_name")}</Form.Label>
                      <Form.Control
                        maxLength={100}
                        type="text"
                        id="name"
                        pattern=".*\S+.*"
                        required
                        placeholder={__("course_name_placeholder")}
                        value={fields.title?.value}
                        ref={refInputTitle}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          adjustTextareaHeight(e.target)
                          fields.title.onChange(e.target.value)
                        }}
                      />
                      <div className="invalid-feedback">{__("course_name_error_text")}</div>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label htmlFor="short-description">{__("course_short_des")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        id="short-description"
                        placeholder={__("course_short_des_placeholder")}
                        value={fields.description?.value || ""}
                        maxLength={150}
                        ref={refInputDescription}
                        rows={3}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                          adjustTextareaHeight(e.target)
                          fields.description.onChange(e.target.value)
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label htmlFor="price">{__("course_price_less")}</Form.Label>
                      <Form.Control
                        maxLength={12}
                        type={'text'}
                        onKeyDown={onKeyDown}
                        onPaste={preventPasteNegative}
                        value={helpers.formatNumberWithCommas(fields.coin_value?.value)}
                        id="price"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          fields.coin_value.onChange(e.target.value)
                        }}
                        placeholder={__("course_price_less")}
                        name="coin_value"
                      />
                    </Form.Group>

                    <Form.Group className={'mb-2'}>
                      <Form.Label htmlFor="price">{__("course_public_label")}</Form.Label>
                      <div>
                        <Form.Check
                          name="public-type"
                          label={__("course_everybody")}
                          inline
                          type="radio"
                          value={EnumPublicStatus.public}
                          checked={fields.public_status?.value === EnumPublicStatus.public}
                          onChange={handleSelectPublicChange}
                        />
                        <Form.Check
                          label={__("course_limit")}
                          name={'public-type'}
                          inline
                          type="radio"
                          value={EnumPublicStatus.private}
                          checked={fields.public_status?.value === EnumPublicStatus.private}
                          onChange={handleSelectPublicChange}
                        />
                      </div>
                    </Form.Group>

                    {fields.public_status?.value === EnumPublicStatus.private && (
                      <Form.Group className={'mb-2'}>
                        <Form.Label htmlFor="price">{__("course_level_study")}</Form.Label>
                        <Form.Select
                          aria-label=""
                          className={`courseupdate_optionbox`}
                          value={fields.level_value?.value}
                          onChange={handleSelectLevelChange}
                        >
                          {listLevel?.map((item, index) => (
                            <option key={item?._id} value={item?.level_number}>
                              Lvl {item?.level_number} - {item?.title}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}

                    <Capacity current_user_can={'boss'}>
                      <div className="d-flex justify-content-end mt-5">
                        <Button variant="secondary" onClick={onPressCancel}>
                          {__("course_btn_cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          className="mx-3 button-event"
                          type="submit"
                          disabled={loading}
                        >
                          {loading && (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="visually-hidden">Loading...</span>
                            </>
                          )}
                          {__("course_btn_save")}
                        </Button>
                      </div>
                    </Capacity>

                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <ConfirmModal ref={refConfirmModal} />
        </div>
      </div>
    </BigLayout>
  )
}
