import { Button, Modal } from 'react-bootstrap'
import React from 'react'
import __, { ___ } from 'languages/index'

interface TypeModalUpgradeConfirm{
    show: boolean
    handleClose: ()=>void
    onConfirm: ()=>void

}
const number_coin = 370000
const payment_amount = 380000
const fetch_coin =  10000
const ModalUpgradeConfirm: React.FC<TypeModalUpgradeConfirm> = ({show, handleClose, onConfirm})=>{
    return (
        <Modal  show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                className="modal"
                centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{__("upgrade_compare_modal_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{___("upgrade_compare_remaining {number_coin} coin", { number_coin : <>{number_coin}</> })}</div>
                <div>{___("upgrade_compare_payment {payment_amount} coin", { payment_amount : <>{payment_amount}</> })}</div>
                <div>{___("upgrade_compare_add_more {fetch_coin} coin", { fetch_coin : <>{fetch_coin}</> })}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>{__("upgrade_compare_modal_btn_cancel")}</Button>
                <Button onClick={onConfirm}>{__("upgrade_compare_modal_btn")}</Button>
            </Modal.Footer>
        </Modal>
    )
 }
 export  default ModalUpgradeConfirm
