import '../media/gift.receiver.scss'
import React, {useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGift} from "@fortawesome/free-solid-svg-icons";

export default function GiftReceiverSkeleton() {

  const renderItem = (_,index) => {
    return (
      <div key={index.toString()} className="giftreceiveritem col-12">
        <div className="giftreceiveritem_container">
          <div className="giftreceiveritem_container_user">
            <div className="giftreceiveritem_avatar skeleton"/>

            <div className="giftreceiveritem_container_user_info">
              <div className="h5 giftreceiveritem_container_user_txtname skeleton skeleton-text w-50" style={{height: 22}}/>
              <div className="h5 giftreceiveritem_container_user_txtname skeleton skeleton-text w-50" style={{height: 16}}/>
            </div>
          </div>

          <FontAwesomeIcon icon={faGift} className="giftreceiveritem_container_icongift"/>

          <div className="giftreceiveritem_container_gift clickable">
            <div className="giftreceiveritem_container_user_info align-items-end">
              <div className="giftreceiveritem_container_user_txtnamegift skeleton skeleton-text w-50" style={{height: 20}}/>
              <div className="giftreceiveritem_container_user_txtPrice skeleton skeleton-text w-25 mt-1" style={{height: 14}}/>

              <div className="d-flex align-items-center justify-content-end skeleton skeleton-text w-25 mt-1"
                   style={{height: 14}}/>

              <div className="giftreceiveritem_container_user_txtTime text-lg-end skeleton skeleton-text w-50 mt-1"
                   style={{height: 14}}/>

            </div>

            <div className="giftreceiveritem_imgproduct skeleton"/>

          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="giftreceiver_container_user row gap-3">
      {[1, 2, 3, 4].map(renderItem)}
    </div>
  )
}
