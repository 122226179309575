import {OrderStatus} from "entities/orders/order.type";
import {ORDER_SORT_OPTIONS} from "entities/orders/constant/order.constant";

 export const getStatus = (status: OrderStatus) => {
  return status === OrderStatus.SUCCESS  ? "success" : (status === OrderStatus.PENDING || status === OrderStatus.PROCESSING ) ? "warning" : "danger" ;
};

export const hanleShowTextPayment = (paymentMethod: string) => {
    let dataPaymentMethod = ''
    switch (paymentMethod) {
        case 'google_payment':
            dataPaymentMethod = 'GOOGLEPAY'
            break
        case 'vn_pay':
            dataPaymentMethod = 'VNPAY'
            break
        case 'transfer':
            dataPaymentMethod = 'chuyển khoản'
            break
        case 'apple_payment':
            dataPaymentMethod = 'APPLEPAY'
            break
        case 'stripe':
            dataPaymentMethod = 'STRIPE'
            break
        case 'zalo_pay':
            dataPaymentMethod = 'ZALOPAY'
            break
        case  'paypal':
            dataPaymentMethod = 'PAYPAL'
            break
        default:
            dataPaymentMethod = 'Miễn phí'
            break
    }
    return dataPaymentMethod
};

export const showStatus = (status: OrderStatus) =>{
  let nameTitle = ''
  ORDER_SORT_OPTIONS.map ((item)=>{
    if(item?.value === status){
      nameTitle =  item?.title
    }
  })
  return nameTitle
}
