import React, { useMemo, useState } from 'react'
import { ListGroup, Tab, Tabs } from 'react-bootstrap'
import { ActivityType, ChallengePrivacy, OfficialStatus } from 'entities/challenge/challenge.interface'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { useParams } from 'react-router-dom'
import ActivityItem from '../ActivityItem'
import { useGetActivitiesQuery } from 'entities/challenge/store/challengeApi'
import RenderView from 'components/RenderView'
import InfiniteList from 'components/InfiniteList'
import __ from "languages/index";
import helpers from 'helpers/index'
import { useAppSelector } from 'config/store'
import { Box, Button, Stack } from '@mui/material'
import BoxSuggestCreateProduct from 'components/BoxSuggestCreateProduct'

type Props = {
  status?: ChallengePrivacy
  onCheckin: () => void
  shouldDisplayCheckinButton: boolean
}
const LIMIT = 10
const listButton = [
  {
    value: OfficialStatus.APPROVED,
    title: 'member_approval'
  },
  {
    value: OfficialStatus.REJECTED,
    title: 'member_reject'
  },
  {
    value: OfficialStatus.PENDING,
    title: 'member_approval'
  },

]
function ChallengeWork({ status = 'private', onCheckin, shouldDisplayCheckinButton }: Props) {
  const [currentTab, setCurrentTab] = useState<OfficialStatus>(OfficialStatus.APPROVED)
  const [page, setPage] = useState(1);
  const { challenge_id } = useParams();
  const permission_data = useAppSelector(state => state.user.permission_data);

  const options = useMemo(() => {
    return {
      challenge_id,
      page,
      limit: LIMIT,
      official_status: currentTab.toString()
    }
  }, [page, challenge_id, currentTab])

  const { data: activitiesResponse, isLoading, isFetching } = useGetActivitiesQuery(options)
  const listActivity = activitiesResponse?.data
  const totalCount = activitiesResponse?.totalCount

  const toast = useToast()

  const selectTab = (tab: OfficialStatus) => {
    setCurrentTab(tab)
  }

  return (
    <>
      {status === 'private' && (
        <Box mt={2} >

          <Stack width={'100%'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} >
            <Stack flexDirection={'row'} alignItems={'center'}   >
              {
                listButton.map(item => (
                  <Button key={item.value} onClick={() => setCurrentTab(item.value)} sx={{
                    marginRight: theme => theme.spacing(1),
                    borderColor: currentTab === item.value ? '#DC3545' : '#E0E2E6',
                    background: '#FDEFF1',
                    color: currentTab === item.value ? '#DC3545' : '#000',
                  }} variant="outlined">{__(item.title)}</Button>
                ))
              }
            </Stack>
            {shouldDisplayCheckinButton && <Button onClick={onCheckin}>{__("challenge_detail_attendance_join")}</Button>}
          </Stack>
        </Box>
      )}

      <Box mt={2}>
        <RenderView
          view={
            <InfiniteList
              as={ListGroup}
              list={listActivity}
              className={'gap-2'}
              renderItem={(act: ActivityType, ref) => (
                <div
                  ref={ref}
                ><ActivityItem
                    key={act._id}
                    {...act}
                    id={act._id}
                    attachments={act.data_activity}
                    time={act.start_time || act?.createdAt}
                    status={act.official_status}
                    user={act.user_id}
                    hasPermission={helpers.current_user_can('boss', permission_data)}
                    onConfirmSuccess={async (status: OfficialStatus) => {
                      if (status === OfficialStatus.APPROVED) {
                        toast.show({ content: `${__("challenge_work_accept_active")}`, type: EnumTypeToast.Success })
                      } else {
                        toast.show({ content: `${__("challenge_work_reject_active")}`, type: EnumTypeToast.Warning })
                      }
                    }}
                  /></div>
              )}
              hasNextPage={listActivity?.length < totalCount}
              onLoadMore={() => setPage(prev => prev + 1)}
              isLoading={isFetching}
            />
          }
          isLoading={isLoading}
          isEmpty={listActivity?.length === 0}
        />
      </Box>
    </>
  )
}

export default ChallengeWork
