import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TypedOrder, TypedOrderFullfillment, TypedProductCart } from '../interface'
import { useNavigate } from 'react-router-dom'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import helpers from 'helpers'
import { OrderDeliveryStatus } from '../shop.myOrder'
import { Box, Button, Divider, IconButton, Paper, Typography, styled } from '@mui/material'
import { CopyIcon, DocumentCheckIcon, ShoppingCartIcon, TimeLoadingIcon } from 'icons'
import __, { ___ } from 'languages/index'
import imageDefault from 'media/images/image_default.png'
import { formatString } from 'entities/support/utils'
import ModalReviewOrder from './ModalReviewOrder'
import DeliveryBoxIcon from 'icons/DeliveryBoxIcon'

const OrderItem = ({
  valueTab,
  dataOrder,
  isReviewed = true
}: {
  valueTab: string
  dataOrder: TypedOrder
  isReviewed?: boolean
}) => {
  const navigate = useNavigate()

  const [isCopySuccess, setIsCopySuccess] = useState<boolean>(false)
  const [reviewOrderId, setReviewOrderId] = useState<string>('')

  const toast = useToast()

  const handleCopyOrderCode = useCallback(() => {
    navigator.clipboard
      .writeText(dataOrder?.order_pnr)
      .then(() => {
        setIsCopySuccess(true)
        toast.show({
          content: __('copy_code_success'),
          type: EnumTypeToast.Success
        })
      })
      .catch(error => {
        setIsCopySuccess(false)
        toast.show({
          content: `${error}`,
          type: EnumTypeToast.Error
        })
      })
  }, [dataOrder?.order_pnr])

  const handleCloseModalReview = useCallback(() => {
    setReviewOrderId('')
  }, [])

  const _RenderBottomCart = ({ order_id }: { order_id: string }) => {
    switch (valueTab) {
      case OrderDeliveryStatus.pending:
        return (
          <Paper
            className="alert_info"
            sx={{ background: theme => theme.palette.background.default }}
          >
            <TimeLoadingIcon sx={{ color: theme => theme.palette.text.primary }} />
            <Typography
              textTransform={'none'}
              variant="subtitle2"
              fontWeight={400}
              color="text.primary"
            >
              {__('wait_confirmation')}
            </Typography>
          </Paper>
        )
      case OrderDeliveryStatus.awaitingPickup:
        return (
          <Paper
            className="alert_info"
            sx={{ background: '#FEF3DC' }}
          >
            <DeliveryBoxIcon sx={{ color: theme => theme.palette.text.primary }} />
            <Typography
              textTransform={'none'}
              variant="subtitle2"
              fontWeight={400}
              color="text.primary"
            >
              {__('wait_pickup')}
            </Typography>
          </Paper>
        )
      case OrderDeliveryStatus.awaitingDelivery:
        return (
          <>
            <Paper
              className="alert_info"
              sx={{ background: theme => theme.palette.info.background }}
            >
              <TimeLoadingIcon sx={{ color: theme => theme.palette.info.dark }} />
              <Typography
                textTransform={'none'}
                variant="subtitle2"
                fontWeight={400}
                color="info.dark"
              >
                {__('wait_being_delivered')}
              </Typography>
            </Paper>
          </>
        )
      case OrderDeliveryStatus.delivered:
        return (
          <>
            <Paper
              className="alert_info"
              sx={{ background: theme => theme.palette.success.background }}
            >
              <DocumentCheckIcon sx={{ color: theme => theme.palette.success.dark }} />
              <Typography
                textTransform={'none'}
                variant="subtitle2"
                fontWeight={400}
                color="success.dark"
              >
                {__('wait_delivered_success')}
              </Typography>
            </Paper>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography>{__('delivered_note')}</Typography>
              <Box
                display={'grid'}
                gridTemplateColumns={'1fr'}
                columnGap={theme => theme.spacing(3)}
              >
                {!isReviewed && (
                  <Button onClick={() => setReviewOrderId(order_id)}>
                    <Typography textTransform={'none'} color="background.paper" fontWeight={600}>
                      {__('rate_product')}
                    </Typography>
                  </Button>
                )}
                {/* <Button variant="outlined" startIcon={<ShoppingCartIcon />}>
                  <Typography textTransform={'none'} fontWeight={600}>
                    {__('buy_again')}
                  </Typography>
                </Button> */}
              </Box>
            </Box>
          </>
        )
      case OrderDeliveryStatus.cancelled:
        return (
          <>
            <Paper
              className="alert_info"
              sx={{ background: theme => theme.palette.primary.background }}
            >
              <TimeLoadingIcon sx={{ color: theme => theme.palette.primary.main }} />
              <Typography
                textTransform={'none'}
                variant="subtitle2"
                fontWeight={400}
                color="primary.main"
              >
                {__('order_cancelled')}
              </Typography>
            </Paper>
          </>
        )
      case OrderDeliveryStatus.returnInitated:
        return (
          <>
            <Paper
              className="alert_info"
              sx={{ background: theme => theme.palette.primary.background }}
            >
              <TimeLoadingIcon sx={{ color: theme => theme.palette.primary.main }} />
              <Typography
                textTransform={'none'}
                variant="subtitle2"
                fontWeight={400}
                color="primary.main"
              >
                {__('order_returnInitiated')}
              </Typography>
            </Paper>
          </>
        )

      default:
        return <></>
    }
  }

  return (
    <OrderItemContainer
      sx={{
        p: theme => theme.spacing(4, 3),
        display: 'grid',
        rowGap: theme => theme.spacing(3),
        border: theme => `1px solid ${theme.palette.divider}`
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'} columnGap={theme => theme.spacing(1.5)}>
          <Typography textTransform={'none'} variant="h6" fontWeight={700} noWrap>
            {`${__('order')} ${dataOrder?.order_pnr || ''}`}
          </Typography>
          {dataOrder?.order_pnr && (
            <IconButton
              onClick={handleCopyOrderCode}
              sx={{
                color: theme => theme.palette.text.primary
              }}
            >
              <CopyIcon
                sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                color="inherit"
              />
            </IconButton>
          )}
        </Box>
        <Button onClick={() => navigate(`/shop/order/${dataOrder?.order_id}`)} variant="text">
          <Typography textTransform={'none'}>{__('see_details')}</Typography>
        </Button>
      </Box>
      <Box display={'grid'} rowGap={theme => theme.spacing(3)}>
        {dataOrder?.orders_to_product?.map((data: TypedProductCart, idx: number) => (
          <Box
            key={`product_${idx}`}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
          >
            <Box display={'flex'} columnGap={theme => theme.spacing(1.5)}>
              <Box
                component={'img'}
                sx={{
                  width: theme => theme.spacing(13.125),
                  height: theme => theme.spacing(13.125),
                  objectFit: 'cover',
                  borderRadius: theme => theme.spacing(1)
                }}
                src={data?.product?.product_avatar?.[0] || imageDefault}
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  e.currentTarget.src = imageDefault
                }}
                alt="image-product"
              />
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-around'}
                maxWidth={theme => theme.spacing(65.625)}
              >
                <Typography textTransform={'none'} variant="subtitle1" fontWeight={400}>
                  {data?.product?.product_name || ''}
                </Typography>
                <Typography
                  textTransform={'none'}
                  variant="subtitle1"
                  color={'primary.main'}
                  fontWeight={600}
                >
                  {`${__('order_table_quantity')}: ${data?.item_quantity || '0'}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1" fontWeight={400} color="primary.main">
                {`${helpers.convertToCommasFormat(
                  data?.item_quantity * data?.product?.product_price
                )} VND`}
              </Typography>
            </Box>
          </Box>
        ))}
        {dataOrder?.order_total_product_count > 3 && (
          <Box display={'flex'} justifyContent={'center'}>
            <Button onClick={() => navigate(`/shop/order/${dataOrder?.order_id}`)} variant="text">
              <Typography textTransform={'none'} variant="subtitle2" color={'primary.main'}>
                {__('header_see_all')}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Divider sx={{ opacity: 1 }} />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography color={'text.secondary'} fontWeight={400}>
          {formatString(
            __('total_order'),
            helpers.convertNumber(dataOrder?.order_total_product_count || '0')
          )}
        </Typography>
        <Box display={'flex'} columnGap={theme => theme.spacing(0.5)}>
          <Typography textTransform={'none'} variant="subtitle1" fontWeight={400}>
            {`${__('order_detail_total_payment')}:`}
          </Typography>
          <Typography
            textTransform={'none'}
            variant="subtitle1"
            color={'primary.main'}
            fontWeight={600}
          >
            {`${helpers.convertToCommasFormat(
              dataOrder?.order_total_price + dataOrder?.order_total_fee || '0'
            )} VND`}
          </Typography>
        </Box>
      </Box>
      <_RenderBottomCart order_id={dataOrder?.order_id} />
      {Boolean(reviewOrderId) && (
        <ModalReviewOrder
          isShow={Boolean(reviewOrderId)}
          onClose={handleCloseModalReview}
          order_id={reviewOrderId}
          ordersToProduct={dataOrder?.orders_to_product}
        />
      )}
    </OrderItemContainer>
  )
}

export default OrderItem

const OrderItemContainer = styled(Paper)(({ theme }) => ({
  '& .alert_info': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 2.5),
    columnGap: theme.spacing(1.5)
  }
}))
