import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Typography
} from '@mui/material'
import __ from 'languages/index'
import StarVoteIcon from '../../../icons/StarVoteIcon'
import {
  DataGrid,
  GridColDef,
} from '@mui/x-data-grid'
import { Image } from 'react-bootstrap'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import { TypedProduct } from '../interface'
import { ProductStatus } from '../data/shop.logictic.enum'
import { IQueryParams } from 'config/reducer.utils'
import imageDefault from 'media/images/image_default.png'
import { EditIcon } from 'icons'
import { useNavigate } from 'react-router-dom'
import CustomPagination from 'entities/shop/components/CustomPagination'
import __helpers from 'helpers/index'

interface TypedProps {
  products: TypedProduct[];
  handleFilterByField: (type, value) => void,
  paramsRequest: IQueryParams,
  totalCount: number,
  isRefresh: boolean
}

const ManageProductTable = ({ products, handleFilterByField, paramsRequest, totalCount, isRefresh }: TypedProps) => {
  const navigate = useNavigate()

  const columns: GridColDef<TypedProduct>[] = [
    {
      field: "",
      headerName: `${__("manage_product_table_product")}`,
      flex: 2,
      sortable: false,
      renderCell: ({ row }) => {
        return (<Stack direction={'row'} alignItems='center' >
          <Box sx={{ paddingRight: theme => theme.spacing(2) }}>
            <Box
              component={'img'}
              sx={{ height: theme => theme.spacing(6.25), width: theme => theme.spacing(6.25), objectFit: 'cover' }}
              src={row?.product_avatar?.[0] || imageDefault}
              alt='image_product'
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => { e.currentTarget.src = imageDefault }}
            />
          </Box>
          <Stack direction={'column'}>
            <Typography color={'text.primary'} variant={'subtitle2'}>
              {row.product_name}
            </Typography>
            <Typography color={'text.secondary'} variant={'body1'} >
              {/* {params?.value.industry} */}
            </Typography>
            <Typography color={'primary.main'} variant={'body1'}>
              {row.product_code}
            </Typography>
          </Stack>
        </Stack>)
      },
    },
    {
      field: "product_price",
      headerName: `${__("manage_product_table_price")}`,
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      renderCell: ({value}) => {
        return `${__helpers.formatNumberWithCommas(value) || 0} VND`
      }
    },
    {
      field: "quantity",
      headerName: `${__("manage_product_table_quantity")}`,
      flex: 1,
      renderCell: ({ row }) => {
        const count = countItemInStock(row?.product_to_stock || [])
        return count
      },
      sortingOrder: ['asc', 'desc'],
      sortable: false
    },
    {
      field: "product_meta",
      headerName: `${__("manage_product_table_vote")}`,
      flex: 1,
      sortingOrder: ['asc', 'desc'],
      renderCell: (params) =>
        <Typography variant='body1' alignItems='center' display='flex' textAlign='center'>
          <StarVoteIcon sx={{ width: 16, height: 15, marginRight: theme => theme.spacing(0.5) }} color="inherit" />
          {params?.value?.product_review_point || 0}
        </Typography>
    },
    {
      field: 'product_status',
      headerName: `${__("manage_product_table_status")}`,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        const count = countItemInStock(row?.product_to_stock || [])

        return (<Typography
          variant='body1'
          alignItems='center'
          display='flex'
          color={row.product_status === ProductStatus.NOSALE ? 'text.secondary' : count === 0 ? 'error.main' : 'success.dark'}
        >
          {row.product_status === ProductStatus.NOSALE ? __('product_no_sale') : count === 0 ? __('product_sold') : __('product_available')}
        </Typography>)
      }

    },
    {
      field: ".",
      headerName: `${__("label_action")}`,
      flex: 1,
      sortable: false,
      renderCell: params => {
        return (
          <IconButton
            onClick={() => {
              navigate(`/shop/manage-product/edit/${params?.row?.product_id || ''}`)
            }}
            className="icon_button edit"
          >
            <EditIcon
              sx={{
                width: theme => theme.spacing(3),
                height: theme => theme.spacing(3)
              }}
              color="inherit"
            />
          </IconButton>
        )
      }

    },
  ];

  const countItemInStock = (product_to_stock) => {
    const count = product_to_stock.reduce((res, item) => {
      res += item.product_quantity
      return res
    }, 0)

    return count
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    handleFilterByField('page', value)
  }

  const onSortModelChange = newModel =>{
    let sortSplit = [];
    // sortSplit = sortSplit.filter(item => !item.includes(newModel[0].field))

    switch (newModel[0]?.field) {
      case "product_meta":
        sortSplit.push(`${newModel[0].field}.product_review_point:${newModel[0].sort}`)
        break;
      default:
        sortSplit.push(`${newModel[0].field}:${newModel[0].sort}`)
        break;
    };

    handleFilterByField('sort', sortSplit.join(','))
  }

  return (
    <ManageProductTableContainer>
      <DataGrid
        rowHeight={100}
        showCellVerticalBorder={false}
        rows={products}
        disableColumnMenu
        rowSpacingType={'margin'}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: paramsRequest.limit,
              page: +paramsRequest.page
            },
          }
        }}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        slots={{
          pagination: ()=> <CustomPagination handleChangePage = {handleChangePage} page ={+paramsRequest.page} count={Math.ceil(totalCount / paramsRequest.limit)}/>,
        }}
        disableRowSelectionOnClick
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          borderRadius: theme => theme.spacing(1.5),
          boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
          '& .MuiDataGrid-cell': {
            height: '100px',
          },
        }}
        getRowId={row => row.product_id}
        loading={isRefresh}
      />
    </ManageProductTableContainer>
  )
}
export default ManageProductTable

const ManageProductTableContainer = styled(Box)(({ theme }) => ({
  '& .MuiDataGrid-main': {
    padding: theme.spacing(2),
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.background.secondary,
    // margin: theme.spacing(0,2),
    borderRadius: theme.spacing(1.5)
  },
  '& .super-app-theme--header:hover': {
    '& .MuiDataGrid-menuIcon': {
      visibility: 'hidden',
    },
  },
  '& .MuiDataGrid-root': {
    boxShadow: 'none',
    borderRadius: 0,
    borderColor: 'transparent',
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within,& .MuiDataGrid-cell:focus-visible, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-columnHeader:focus-visible, & .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
      outline: 'none',
      outlineWidth: 0,
      outlineOffset: 0
    }
  },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
    display: 'flex',
    visibility: 'visible',
    width: 'auto'

  },
  '& .MuiDataGrid-iconSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-root .MuiDataGrid-withBorderColor': {
    borderColor: theme.palette.divider,
    // padding: theme.spacing(2,2,2,0)
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .edit': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  },

}))
