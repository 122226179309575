import { SvgIcon, SvgIconProps } from '@mui/material'

const ShippingUnitIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="E-Commerce/fast shopping">
          <path id="Icon (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M19.8535 5.80948C16.4346 1.47212 10.1468 0.727582 5.80947 4.14652C5.37573 4.48841 4.74696 4.41396 4.40507 3.98022C4.06318 3.54648 4.13763 2.91771 4.57137 2.57582C9.7762 -1.5269 17.3215 -0.633462 21.4242 4.57137C25.5269 9.77621 24.6335 17.3215 19.4286 21.4242C14.2238 25.5269 6.67853 24.6335 2.57581 19.4286C2.23392 18.9949 2.30837 18.3661 2.74211 18.0242C3.17584 17.6823 3.80461 17.7568 4.14651 18.1905C7.56544 22.5279 13.8532 23.2724 18.1905 19.8535C22.5279 16.4346 23.2724 10.1468 19.8535 5.80948ZM5 7C5 6.44771 5.44772 6 6 6H6.38271C7.15482 6 7.84972 6.38846 8.25979 6.99313C8.26397 6.99308 8.26815 6.99305 8.27234 6.99305H15.7369C17.2011 6.99305 18.2984 8.36557 17.9286 9.80135L17.0576 13.1833C16.8632 13.9379 16.3005 14.5159 15.5954 14.7549C16.429 14.8194 17.0993 15.5118 17.0993 16.375C17.0993 17.2818 16.3596 18 15.4681 18C14.5765 18 13.8369 17.2818 13.8369 16.375C13.8369 15.6936 14.2545 15.1187 14.8404 14.875H9.40452C9.30208 14.875 9.20099 14.8682 9.10175 14.8549C9.71413 15.0859 10.156 15.6741 10.156 16.375C10.156 17.2818 9.41637 18 8.52482 18C7.63327 18 6.89362 17.2818 6.89362 16.375C6.89362 15.4682 7.63327 14.75 8.52482 14.75C8.57796 14.75 8.63055 14.7525 8.68248 14.7575C7.87117 14.4856 7.25439 13.767 7.15557 12.8701L6.64369 8.22395C6.63052 8.10442 6.52488 8 6.38271 8H6C5.44772 8 5 7.55228 5 7ZM8.74053 8.99305L9.14354 12.651C9.15671 12.7706 9.26236 12.875 9.40452 12.875H14.8659C14.9928 12.875 15.0935 12.7905 15.1208 12.6845L15.9918 9.30253C16.0291 9.1576 15.9202 8.99305 15.7369 8.99305H8.74053ZM1 10C1 9.44771 1.44772 9 2 9H4C4.55228 9 5 9.44771 5 10C5 10.5523 4.55228 11 4 11H2C1.44772 11 1 10.5523 1 10ZM2 13C2 12.4477 2.44772 12 3 12H5C5.55228 12 6 12.4477 6 13C6 13.5523 5.55228 14 5 14H3C2.44772 14 2 13.5523 2 13Z" fill="white" />
        </g>
      </svg>

    </SvgIcon>
  )
}

export default ShippingUnitIcon
