import React, {useCallback, useEffect, useState} from 'react'
import {Col, Container, Form, FormControl, FormGroup, Image, Row, Stack} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {CreatePodcastPayload, CreatePodcastSchema} from '../utils/podcast.validation'
import {zodResolver} from '@hookform/resolvers/zod'
import {useCreatePodcastMutation} from '../store/podcastApi'
import FileUploader from 'components/FileUploader'
import useUploadFile from 'hooks/useUploadFile'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import {useNavigate} from 'react-router-dom'
import LoadingButton from 'components/LoadingButton'
import __ from 'languages/index'
import BigLayout from "../../layouts/bigLayout";

const acceptAudio = 'audio/*'
const acceptThumbnail = 'image/png, image/jpeg, image/jpg'

const CreateNewPodcast = () => {
  const [thumbnail, setThumbnail] = useState<File[]>(null)
  const [podcast, setPodcast] = useState<File[]>(null)

  const toast = useToast()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors }
  } = useForm<CreatePodcastPayload>({
    resolver: zodResolver(CreatePodcastSchema)
  })

  const [create, { isLoading: creatingPodcast }] = useCreatePodcastMutation()

  const { srcFiles: uploadedThumbnail } = useUploadFile(thumbnail, {
    isMultiple: false,
    acceptFile: acceptThumbnail
  })
  const { srcFiles: uploadedPodcast } = useUploadFile(podcast, {
    isMultiple: false,
    acceptFile: acceptAudio
  })

  useEffect(() => {
    if (!uploadedThumbnail) return
    setValue('post_avatar', uploadedThumbnail[0]?._id)
    setError('post_avatar', {
      message: undefined
    })
  }, [uploadedThumbnail])

  useEffect(() => {
    if (!uploadedPodcast) return
    setError('attach_files', {
      message: undefined
    })

    setValue('attach_files', uploadedPodcast[0]?._id)
  }, [uploadedPodcast])

  const handleCreatePodcast = async (payload: CreatePodcastPayload) => {
    try {
      await create({
        ...payload,
        podcast_language: 'vi',
        attach_files: JSON.stringify([payload.attach_files])
      }).unwrap() // <<<<<--- NOTE: Await có cần unwrap không ta?
      // unwrap để catch error tại nơi call. Không lỗi sẽ được throw trong hàm async thunk của redux

      toast.show({
        type: EnumTypeToast.Success,
        content: `${__("podcast_create_status_success")}`
      })
      navigate('/podcast')
    } catch (err) {
      console.log('err', err)
    }
  }

  const handleAddFileError = (name: keyof CreatePodcastPayload, errorMsg: string) => {
    setError(name, {
      message: errorMsg
    })
  }

  const renderErrorMsg = useCallback(
    (errorMsg: string) => {
      return errorMsg && <div className="text-danger">{errorMsg}</div>
    },
    [errors.attach_files, errors.post_avatar]
  )

  return (
    <BigLayout>
      <Container
        className="py-5 mx-auto"
        style={{
          minHeight: '100vh',
          maxWidth: '800px'
        }}
      >
        <div
          className={'w-100 m-auto'}
          style={{
            maxWidth: '800px'
          }}
        >
          <h1 className={'mb-3'}>{__("podcast_create")}</h1>
          <Stack as={Form} gap={3} onSubmit={handleSubmit(handleCreatePodcast)}>
            <div>
              <Form.Label>{__("podcast_title")}</Form.Label>
              <FormGroup>
                <FormControl
                  type="text"
                  {...register('title')}
                  placeholder={__("podcast_title_placeholder")}
                />
              </FormGroup>
            </div>
            <div>
              <Form.Label>{__("podcast_description")}</Form.Label>
              <FormGroup>
                <FormControl
                  rows={3}
                  as="textarea"
                  type="text"
                  {...register('content')}
                  placeholder={__("podcast_description_placeholder")}
                />
              </FormGroup>
            </div>
            <div>
              <Form.Label>{__("podcast_thumbnail")}</Form.Label>
              <input type={'text'} {...register('post_avatar')} hidden />
              <FileUploader
                onUpload={setThumbnail}
                onAddFileError={(err: string) => handleAddFileError('post_avatar', err)}
                acceptFile={acceptThumbnail}
              >
                {() =>
                  uploadedThumbnail?.map(file => (
                    <Image width={100} src={file.media_thumbnail || file.media_url} key={file?._id} />
                  ))
                }
              </FileUploader>
              {renderErrorMsg(errors?.post_avatar?.message)}
            </div>

            <div>
              <Form.Label>{__("podcast")}</Form.Label>
              <input type="text" {...register('attach_files')} hidden />
              <FileUploader
                onUpload={setPodcast}
                acceptFile={acceptAudio}
                onAddFileError={error => handleAddFileError('attach_files', error)}
              >
                {() =>
                  uploadedPodcast?.map(file => <div key={file?._id}>{file.media_file_name}</div>)
                }
              </FileUploader>
              {renderErrorMsg(errors?.attach_files?.message)}
            </div>
            <Row className={'justify-content-end'}>
              <Col sx={12} md={3}>
                <LoadingButton type="submit" className={'w-100'} isLoading={creatingPodcast}>
                  {__("podcast_btn_add")}
                </LoadingButton>
              </Col>
            </Row>
          </Stack>
        </div>
      </Container>
    </BigLayout>
  )
}

export default CreateNewPodcast
