import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  Typography,
  styled,
  Autocomplete,
  Select,
  TextField,
  OutlinedInput, MenuItem, FormControl, SelectChangeEvent, Stack
} from '@mui/material'

import __ from 'languages/index'
import React, { useCallback, useEffect, useState } from 'react'
import { CloseIcon, EditIcon, EmptyIcon, PlusIcon, TrashAltIcon } from 'icons'
import imageDefault from 'media/images/image_default.png'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import UploadFileComponent from './shop.upload'
import { useAppDispatch } from 'config/store'
import {
  createBannerOfShop,
  deleteBannerOfShop,
  getBannerOfShop,
  updateBannerOfShop
} from '../store'
import { AxiosError, AxiosResponse } from 'axios'
import helpers from 'helpers'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { validateLabel } from 'entities/support/utils'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { TypedBanner} from '../interface'
import ConfirmModal from 'components/ConfirmModal'
import CreateEditBannerModal from 'entities/shop/components/CreateEditBannerModal'

const BannerComponent = () => {

  const [createOrUpdateBanner, setCreateOrUpdateBanner] = useState<string>('')
  const [editBannerData, setEditBannerData] = useState<TypedBanner>(null)
  const [confirmDeleteBannerData, setConfirmDeleteBannerData] = useState<string>('')
  const [bannerData, setBannerData] = useState<TypedBanner[]>([])
  const [largeBannerData, setLargeBannerData] = useState<TypedBanner[]>([])
  const [smallBannerData, setSmallBannerData] = useState<TypedBanner[]>([])

  const dispatch = useAppDispatch()

  const toast = useToast()

  const handleCreateBanner = useCallback(
    formData => {
      dispatch(createBannerOfShop(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          reset()
          toast.show({
            type: EnumTypeToast.Success,
            content: `${__('add_banner_success')}`
          })
          setBannerData(bannerData => [res?.data, ...bannerData])
          setCreateOrUpdateBanner('')
        })
        .catch((error: AxiosError) => {
          console.error('createBannerOfShop >>>', error)
          toast.show({
            type: EnumTypeToast.Error,
            content: `${error}`
          })
        })
    },
    [bannerData]
  )

  const handleUpdateBanner = useCallback(
    formData => {
      dispatch(updateBannerOfShop({ banner_id: editBannerData?.banner_id, formData: formData }))
        .unwrap()
        .then((res: AxiosResponse) => {
          reset()
          toast.show({
            type: EnumTypeToast.Success,
            content: `${__('update_banner_success')}`
          })
          const findIndex = bannerData?.findIndex(data => data?.banner_id === res?.data?.banner_id)
          bannerData?.splice(findIndex, 1, res?.data)
          setBannerData(bannerData => [...bannerData])
          setCreateOrUpdateBanner('')
        })
        .catch((error: AxiosError) => {
          console.error('updateBannerOfShop >>>', error)
          toast.show({
            type: EnumTypeToast.Error,
            content: `${error}`
          })
        })
    },
    [bannerData, editBannerData]
  )

  const { fields, submit, reset, dirty, submitting } = useForm({
    fields: {
      banner_image_url: useField<string>({
        value: '',
        validates: [notEmpty(`${__('media_form_text')}`)]
      }),
      banner_type: useField<string>({
        value: '',
        validates: []
      }),

      banner_title: useField<string>({
        value: '',
        validates: [
          notEmpty(validateLabel(`${__('banner_title')}`)?.required),

        ]
      }),
      banner_meta: useField<any>({
        value: {
          link: '',
        },
        validates: [
          notEmpty(validateLabel(`${__('destination_link')}`)?.required),

        ]
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          banner_image_url: values.banner_image_url,
          banner_title: values.banner_title.trim(),
          banner_meta: values.banner_meta,
          banner_type: values.banner_type
        }
        helpers.clearValueEmptyInObject(formData)

        const isAdd = createOrUpdateBanner === 'ADD'
        isAdd ? handleCreateBanner(formData) : handleUpdateBanner(formData)
        return { status: 'success' }
      } catch (e) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  useEffect(() => {
    if (helpers.isEmpty(editBannerData)) return reset()
    fields.banner_image_url.onChange(editBannerData?.banner_image_url || '')
    fields.banner_title.onChange(editBannerData?.banner_title || '')
    fields.banner_meta.onChange(editBannerData?.banner_meta || '')
  }, [editBannerData])


  const handleCloseShowModal = useCallback(() => {
    setCreateOrUpdateBanner('')
    setEditBannerData(null)
    reset()
  }, [fields])

  const handleDeleteBanner = useCallback(() => {
    dispatch(deleteBannerOfShop(confirmDeleteBannerData))
      .unwrap()
      .then((res: AxiosResponse) => {
        toast.show({
          type: EnumTypeToast.Success,
          content: `${__('delete_banner_success')}`
        })
        setConfirmDeleteBannerData('')
        setBannerData(bannerData => [
          ...bannerData?.filter(data => data?.banner_id !== res?.data?.banner_id)
        ])
        reset()
      })
      .catch((error: AxiosError) => {
        console.error(`deleteBannerOfShop >>>`, error)
        setConfirmDeleteBannerData('')
        toast.show({
          type: EnumTypeToast.Error,
          content: `${error}`
        })
      })
  }, [confirmDeleteBannerData, bannerData])

  const EmptyComponent = useCallback(() => {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
        <EmptyIcon
          sx={{
            width: theme => theme.spacing(18.75),
            height: theme => theme.spacing(18.75)
          }}
        />
      </Box>
    )
  }, [])

  useEffect(() => {
    dispatch(getBannerOfShop())
      .unwrap()
      .then((res: AxiosResponse) => {
        setBannerData([...res?.data])
      })
      .catch((error: AxiosError) => {
        console.error(`getBannerOfShop Error >>>`, error)
        toast.show({
          type: EnumTypeToast.Error,
          content: `${error}`
        })
      })
  }, [])

  useEffect(() => {
    if (helpers.isEmpty(editBannerData)) return reset()
    fields.banner_image_url.onChange(editBannerData?.banner_image_url || '')
    fields.banner_title.onChange(editBannerData?.banner_title || '')
    fields.banner_meta.onChange(editBannerData?.banner_meta || '')
  }, [editBannerData])

  useEffect(() => {
    if (helpers.isEmpty(bannerData)) {
      setLargeBannerData([])
      setSmallBannerData([])
      return
    }
    const convertBigData = bannerData?.filter((data: TypedBanner) => data?.banner_type === 'big')
    const convertMediumData = bannerData?.filter(
      (data: TypedBanner) => data?.banner_type === 'medium'
    )
    setLargeBannerData([...convertBigData])
    setSmallBannerData([...convertMediumData])
  }, [bannerData])




  return (
    <BannerComponentContainer id="bannerComponent">
      <Paper className="wrapper">
        <Typography textTransform={'none'} variant="h5" fontWeight={700}>
          {__('advertising_banner')}
        </Typography>
        <Box width={'100%'} display={'flex'} columnGap={theme => theme.spacing(1.5)}>
          <Box
            className="banner_wrapper"
            sx={{
              flex: smallBannerData?.length > 0 && (smallBannerData?.length > 1 ? 0.7 : 0.5),
              paddingBottom: smallBannerData?.length > 0 && '0 !important'
            }}
          >
            <Typography className="banner_content_item" variant="h6" color={'primary.main'}>
              {__('large_advertising_banners')}
            </Typography>
          </Box>
          {!helpers.isEmpty(smallBannerData) && (
            <Box
              display={'flex'}
              width={'100%'}
              flex={smallBannerData?.length > 1 ? 0.3 : 0.5}
              flexDirection={'column'}
              rowGap={theme => theme.spacing(1.5)}
            >
              {smallBannerData?.map((_item, idx) => (
                <Box key={idx} className="banner_wrapper">
                  <Typography
                    className="banner_content_item"
                    fontSize={theme => smallBannerData?.length > 1 && theme.spacing(2)}
                    variant="h6"
                    color={'primary.main'}
                  >
                    {__('small_advertising_banner')}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box display={'grid'} rowGap={theme => theme.spacing(2)}>
          <Box>
            <Box
              display={'flex'}
              height={theme => theme.spacing(5.5)}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography textTransform={'none'} variant="subtitle1" fontWeight={500}>
                {__('large_banner')}
              </Typography>
              <Button
                onClick={() => {
                  fields.banner_type.onChange('big')
                  setCreateOrUpdateBanner('ADD')
                }}
                sx={{ p: theme => theme.spacing(0, 2), height: '100%' }}
                startIcon={<PlusIcon sx={{ color: theme => theme.palette.background.paper }} />}
              >
                <Typography textTransform={'none'} color={'background.paper'}>
                  {__('add_large_banner')}
                </Typography>
              </Button>
            </Box>

            <Box>
              {helpers.isEmpty(largeBannerData) ? (
                <EmptyComponent />
              ) : (
                <>
                  {largeBannerData?.map((data: TypedBanner, idx: number) => (
                    <Box key={idx} className="banner_item">
                      <img
                        src={data?.banner_image_url || imageDefault}
                        className="banner_img"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                          e.currentTarget.src = imageDefault
                        }}
                        alt="banner"
                        loading="lazy"
                      />
                      <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Box>
                          <Typography textTransform={'none'} fontWeight={500}>
                            {__('destination_link')}
                          </Typography>
                          <Typography
                            textTransform={'none'}
                            color={'primary.main'}
                            fontWeight={500}
                          >
                            {data?.banner_title || ''}
                          </Typography>
                        </Box>
                        <Box
                          display={'flex'}
                          justifyContent={'flex-end'}
                          columnGap={theme => theme.spacing(2)}
                        >
                          <IconButton
                            disabled={idx === 0 && smallBannerData?.length > 0}
                            onClick={() => setConfirmDeleteBannerData(data?.banner_id)}
                            className="icon_button"
                          >
                            <TrashAltIcon
                              sx={{
                                width: theme => theme.spacing(3),
                                height: theme => theme.spacing(3)
                              }}
                              color="inherit"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setEditBannerData(data)
                              setCreateOrUpdateBanner('EDIT')
                            }}
                            className="icon_button edit"
                          >
                            <EditIcon
                              sx={{
                                width: theme => theme.spacing(3),
                                height: theme => theme.spacing(3)
                              }}
                              color="inherit"
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
          <Box>
            <Box
              display={'flex'}
              height={theme => theme.spacing(5.5)}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography textTransform={'none'} variant="subtitle1" fontWeight={500}>
                {__('max_banner_small')}
              </Typography>
              {largeBannerData?.length > 0 && smallBannerData?.length < 2 && (
                <Button
                  onClick={() => {
                    fields.banner_type.onChange('medium')
                    setCreateOrUpdateBanner('ADD')
                  }}
                  sx={{ p: theme => theme.spacing(0, 2), height: '100%' }}
                  startIcon={<PlusIcon sx={{ color: theme => theme.palette.background.paper }} />}
                >
                  <Typography textTransform={'none'} color={'background.paper'}>
                    {__('add_small_banner')}
                  </Typography>
                </Button>
              )}
            </Box>
            <Box>
              {helpers.isEmpty(smallBannerData) ? (
                <EmptyComponent />
              ) : (
                <>
                  {smallBannerData?.map((data: TypedBanner, idx: number) => (
                    <Box key={idx} className="banner_item">
                      <img
                        src={data?.banner_image_url || imageDefault}
                        className="banner_img"
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                          e.currentTarget.src = imageDefault
                        }}
                        alt="banner"
                        loading="lazy"
                      />
                      <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Box>
                          <Typography textTransform={'none'} fontWeight={500}>
                            {__('destination_link')}
                          </Typography>
                          <Typography
                            textTransform={'none'}
                            color={'primary.main'}
                            fontWeight={500}
                          >
                            {data?.banner_title || ''}
                          </Typography>
                        </Box>
                        <Box
                          display={'flex'}
                          justifyContent={'flex-end'}
                          columnGap={theme => theme.spacing(2)}
                        >
                          <IconButton
                            onClick={() => setConfirmDeleteBannerData(data?.banner_id)}
                            className="icon_button"
                          >
                            <TrashAltIcon
                              sx={{
                                width: theme => theme.spacing(3),
                                height: theme => theme.spacing(3)
                              }}
                              color="inherit"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setEditBannerData(data)
                              setCreateOrUpdateBanner('EDIT')
                            }}
                            className="icon_button edit"
                          >
                            <EditIcon
                              sx={{
                                width: theme => theme.spacing(3),
                                height: theme => theme.spacing(3)
                              }}
                              color="inherit"
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <CreateEditBannerModal
        handleCloseShowModal={handleCloseShowModal}
        createOrUpdateBanner={createOrUpdateBanner}
        handleUpdateBanner={handleUpdateBanner}
        handleCreateBanner={handleCreateBanner}
        editBannerData={editBannerData}
        fields={fields}
        onSubmit={submit}
        disabled={!dirty || submitting}
      />
      {Boolean(confirmDeleteBannerData) && (
        <ConfirmModal
          onClose={() => setConfirmDeleteBannerData('')}
          open={Boolean(confirmDeleteBannerData)}
          onConfirm={() => {
            handleDeleteBanner()
          }}
          title={__('btn_confirm')}
          cancelTextBtn={__('home_btn_cancel')}
          confirmText={__('delete_banner')}
        />
      )}
    </BannerComponentContainer>
  )
}

export default BannerComponent

const BannerComponentContainer = styled(Box)(({ theme }) => ({
  '& .wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    // height: `calc(100vh - ${theme.spacing(26.25)}`,
    // overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`
  },
  '& .banner_item': {
    padding: theme.spacing(2, 0),
    columnGap: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: `${theme.spacing(30)} 1fr`,
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  '& .banner_img': {
    width: '100%',
    height: theme.spacing(14),
    objectFit: 'cover',
    borderRadius: theme.spacing(2.25)
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .edit': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '& .banner_wrapper': {
    display: 'grid',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: '44.44%',
    placeItems: 'center',
    borderRadius: theme.spacing(1.5),
    border: `1px dashed ${theme.palette.primary.main}`
  },
  '& .banner_content_item': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    textTransform: 'none',
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center'
  }
}))

