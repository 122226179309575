import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'config/store'
import DateTime from 'date-and-time'
import ExtensionElement from 'entities/extentions/components/extension.element'
import ExtensionPrice from 'entities/extentions/components/extension.price'
import { PublicAlbum } from 'entities/extentions/extensions.interface'
import { getEntities, getEntity } from 'entities/extentions/extensions.store.reducer'
import { ListPlan } from 'entities/payment/payment.interface'
import Parser from 'html-react-parser'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import Lightbox from 'yet-another-react-lightbox'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Video from 'yet-another-react-lightbox/plugins/video'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import { Link, useNavigate, useParams } from 'react-router-dom'
import helpers from 'helpers/index'
import StarVoting from './components/extension.star'
import ImageDefault from 'media/images/image_default.png'
import __helpers from 'helpers/index'
import TypedChannel from '../../interfaces/channel.interface'
import { getMinPricePlan } from 'entities/extentions/hepls'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import { getDataChannel } from 'entities/channel/store/channel.store.reducer'
import { getDefaultChannel, getInfoChannel } from 'store/user.store.reducer'
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll";
import TypedPlan, {TypedService} from "../../interfaces/plan.interface";
import {getListExtension} from "entities/extentions/services/courses.service";
import BigLayout from "../../layouts/bigLayout";

export default function ExtensionsDetail() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const channel: TypedChannel = useAppSelector(state => state.user.channel_data)
  const entity = useAppSelector(state => state.extensions.entity)
  const entities = useAppSelector(state => state.extensions.entities)
  const listPlan: ListPlan[] = useAppSelector(state => state.payment.plans)
  const useParam = useParams()
  const Param = useParam.extension_id
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [isOrded, setIsOrded] = useState(false)
  localStorage.setItem('extension_id', Param);

  const {data : dataPlan, isRefreshing} = useInfiniteScroll<TypedPlan>({
    amount_of_day: 30,
    limit: 100,
    order_by: 'DESC',
    service_type: "extension"
  }, getListExtension)

  useEffect( () => {
    if ( !channel ) dispatch( getDefaultChannel() );
  }, [channel]);

  useEffect(() => {
    dispatch(
      getEntity({
        _id: Param
      })
    )

    setIsOrded(!!channel?.service_id?.find( (service: any) => Param === service?._id));

  }, [Param, channel])

  const handleClick = (index: number) => {
    setPhotoIndex(index)
    setIsOpen(true)
  }
  const getTrialDay = listPlan
    ?.filter(plan => plan?.service_id === Param.toString() && plan?.trial_day)
    .map(plan => {
      return plan?.trial_day
    })
  const planForMonth = listPlan?.filter(
    (plan: ListPlan) => plan?.service_id === Param.toString() && plan?.amount_of_day === 30
  )
  const planItem = listPlan?.filter((plan: ListPlan) => plan?.service_id === Param.toString())
  const minPlan =
    planItem?.length > 0 &&
    planItem.reduce((plan1, plan2) => {
      return plan2.amount_of_day < plan1.amount_of_day ? plan2 : plan1
    }, planItem[0])
  const maxDay = Math.max(...getTrialDay)
  const listExtension = entities
    ?.filter((extension: TypedService) => extension._id !== Param)
    .slice(0, 6)
  const videoMedia = Array.isArray(entity?.public_album)
    ? entity?.public_album?.filter((item: PublicAlbum) => item?.media_type === 'video')
    : []
  const imageMedia = Array.isArray(entity?.public_album)
    ? entity?.public_album?.filter((item: PublicAlbum) => item?.media_type === 'image')
    : []
  const newImageMedia = imageMedia?.length > 3 ? imageMedia?.slice(0, 3) : imageMedia
  const dataImage = [...newImageMedia]
  dataImage.shift()
  const data = videoMedia?.length === 0 ? dataImage : newImageMedia

  const showPlus = Boolean(imageMedia?.length > 3)

  const handleClickTab = useCallback((link: string)=> {
    if(!link) return navigate('/404')
    navigate(link)
  },[])

  return (
    <BigLayout>
      <_Helmet title='extension_title' />
      <div id="extension_wrap" key={`ExtensionsDetail-${entity?._id}`}>
        <div style={{ backgroundColor: '#eee' }}>
          <div className="container">
            <div className="row extension_content">
              <div className="col-12 col-md-4 extension_item">
                <div className="py-4 extension-text">
                  <Breadcrumb>
                    <Breadcrumb.Item onClick={()=> handleClickTab('/')}>
                      {__('home_page')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={()=> handleClickTab('/extensions')}>
                      {__('extension_breadcrumb')}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <Row>
                    <Col xs="4" className="p-3">
                      <img
                        alt="Lebesgue: Marketing &amp; LTV"
                        className="d-inline w-100 rounded-4"
                        src={entity?.avatar?.media_thumbnail ?? ImageDefault}
                      />
                    </Col>
                    <Col xs="8" className="d-flex justify-content-lg-start  flex-column p-3">
                      <h1 className="h3 extension_title">
                        {__helpers.getTrimContent(entity?.title, 70)}
                      </h1>

                      <div className="mt-1, mb-2">
                        {__('extension_price')}{' '}
                        {planForMonth?.length > 0
                          ? `${__helpers.formatNumberWithCommas(planForMonth?.[0]?.price)} ${__(
                              'extension_unit'
                            )}`
                          : getMinPricePlan(minPlan)}
                      </div>
                      {isOrded && (
                        <label className="extension_lable">{__('extension_view_sub_text')}</label>
                      )}
                    </Col>
                  </Row>

                  {maxDay > 0 && (
                    <h3>{`${__('extension_trial')} ${maxDay} ${__('extension_trial_date')}`}</h3>
                  )}

                  <div className="d-flex extension_meta_block">
                    <div className="a flex-fill">
                      <div className="title text-muted">
                        {' '}
                        {__('extension_vote')} ({entity?.review_value}){' '}
                      </div>
                      <div className="description">
                        <StarVoting
                          key={`StarVoting ${entity?._id}`}
                          rating={entity?.review_value}
                        />
                      </div>
                    </div>
                    <div className="a a border-start border-end flex-fill  ">
                      <div className="title text-muted"> {__('extension_evaluate')} </div>
                      <div className="description">
                        {helpers.formatNumberWithCommas(entity?.review_number)}
                      </div>
                    </div>
                    <div className="a flex-fill">
                      <div className="title text-muted"> {__('extension_developer')} </div>
                      <div className="description">{__('extension_gamifa')}</div>
                    </div>
                  </div>
                  <button
                    disabled={!entity?.active_status || isOrded}
                    className="btn btn-primary btn-lg w-100 extension_btn"
                    onClick={ () => navigate(`/payment?service_id=${entity?._id?.trim()}`) }
                  >
                    {__('extension_setting')}
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-8 extension_item">
                <div className="py-4">
                  {Array.isArray(entity?.public_album) && entity?.public_album?.length > 0 ? (
                    <div className="extension_card_media">
                      {videoMedia?.length > 0 ? (
                        <div className="reponsive_video " style={{ width: '100%' }}>
                          {videoMedia.map((item, index) => (
                            <div key={'extension_element_' + index} style={{ marginRight: '15px' }}>
                              <video
                                className="logo_video w-100"
                                width={'auto'}
                                disablePictureInPicture
                                height={'auto'}
                                controls
                              >
                                <source src={item?.media_url} type="video/mp4" />
                              </video>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div style={{ width: '100%', objectFit: 'cover' }}>
                          <button onClick={() => handleClick(0)} className="btn w-100">
                            <img
                              className="logo_video w-100 object-fit-contain"
                              src={newImageMedia[0]?.media_url ?? ImageDefault}
                            />
                          </button>
                        </div>
                      )}
                      {data?.length > 0 && (
                        <div className="reponsive_image  position-relative">
                          {data?.map((item, index) => (
                            <div
                              key={`image_${index}`}
                              className="reponsive_image position-relative"
                            >
                              <button
                                onClick={() => handleClick(index)}
                                key={index}
                                className="btn w-100"
                              >
                                <img
                                  className={
                                    imageMedia?.length > 1
                                      ? 'logo_image w-100 rounded object-fit-contain'
                                      : 'image-default'
                                  }
                                  style={{ opacity: index === 2 && showPlus ? '0.3' : '1' }}
                                  src={item?.media_url ?? ImageDefault}
                                />
                                {index === 2 && showPlus ? (
                                  <div
                                    style={{
                                      color: 'black',
                                      position: 'absolute',
                                      top: '60px',
                                      left: '0',
                                      right: '0',
                                      fontSize: '20px'
                                    }}
                                  >
                                    <i className="bi bi-plus" style={{ color: 'black' }}></i>
                                    {imageMedia?.length - 3}
                                  </div>
                                ) : null}
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="extension_card_media">
                      <img className="image-default" src={ImageDefault} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: '#fff' }} className="py-4">
          <div className="container-sm">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="app_section extension-text">
                  <h4>{__('extension_highlight')}</h4>
                  <div>{__('extension_highlight_sub_text')}</div>
                </div>
                <br />
                <div className="app_section">
                  <h4> {__('extension_introduce')} </h4>
                  <div> {__('extension_debut')} </div>
                  <div>{DateTime.format(new Date(entity?.createdAt), 'YYYY-MM-DD')}</div>
                </div>
                <hr className="hr d-md-none" />
              </div>
              <div className="col-12 col-md-8">
                <h2> {entity?.description} </h2>
                {Parser(entity?.long_description ? entity?.long_description : '')}
              </div>
            </div>
          </div>
        </div>

        {planItem?.length > 0 && (
          <div style={{ backgroundColor: '#fff' }} className="py-4">
            <hr className="hr" />
            <div className="container">
              <div className="title-wrap">
                <h4 className="m-0">{__('extension_expenset')} </h4>
                {maxDay > 0 && (
                  <span className="free-trial">
                    {' '}
                    {`${__('extension_trial')} {maxDay} ${__('extension_trial_date')}`}{' '}
                  </span>
                )}
              </div>
              <Row>{planItem?.map((el, index) => <ExtensionPrice key={index} plan={el} />)}</Row>
            </div>
          </div>
        )}

        <div style={{ backgroundColor: '#fff' }} className="py-4">
          <hr className="hr" />
          <div className="container">
            <h4 className="my-5">{__('extension_aplication_other')}</h4>
            <Row>
              {dataPlan?.filter(item=>item?.service_id?._id!==Param).map((plan: TypedPlan, index: number) => (
                <ExtensionElement key={index} plan={plan} />
              ))}
            </Row>
          </div>
          <br />
          <br />
        </div>

        {isOpen && (
          <Lightbox
            index={photoIndex}
            slides={
              !helpers.isEmpty(imageMedia) &&
              imageMedia?.map(item => {
                return {
                  type: 'image',
                  src: item?.media_url
                }
              })
            }
            carousel={{
              finite:true
            }}
            open={isOpen}
            close={() => setIsOpen(false)}
            plugins={[Fullscreen, Video, Zoom, Counter]}
          />
        )}
      </div>
    </BigLayout>
  )
}
