//Library
import { memo, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

//Components
import MissionTimeline from './mission.timeline'
import DeleteConfirm from 'components/deleteConfirm'
import ViewGiftMission from './mission.view.gift'

//Store
import { useAppDispatch } from 'config/store'
import { setDataEditRedeem } from '../store/mission.store.reduce'

//Images
import iconDiamond from 'media/icons/icon_diamond.svg'

//Interface
import { TypeListRedeem } from '../interface'

//Hepler
import helpers from 'helpers'
import __ from 'languages/index'
import { Box, Button } from '@mui/material'

const SettingItemMission = ({
  item,
  onToggle,
  idx,
  showIndexItem,
  handleDeleteRedeem
}: {
  item?: TypeListRedeem
  onToggle: (index: number) => void
  idx?: number
  showIndexItem?: number
  handleDeleteRedeem?: Function
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isShowModalConfirmDelete, setIsShowModalConfirmDelete] = useState<boolean>(false)
  const [isShowModalConfirmUpdate, setIsShowModalConfirmUpdate] = useState<boolean>(false)
  const [isShowGift, setIsShowGift] = useState<boolean>(false)

  const handleShowDetailRedeem = useCallback(() => {
    onToggle(idx)
  }, [idx, showIndexItem])

  const handleShowModalConfirmDelete = useCallback(() => {
    setIsShowModalConfirmDelete(!isShowModalConfirmDelete)
  }, [isShowModalConfirmDelete])

  const handleShowModalConfirmUpdate = useCallback(() => {
    setIsShowModalConfirmUpdate(!isShowModalConfirmUpdate)
  }, [isShowModalConfirmUpdate])

  const handleEditRedeem = useCallback(() => {
    dispatch(setDataEditRedeem(item))
    navigate('/setting-mission/add')
  }, [])

  const _onClose = useCallback(async (result: boolean) => {
    setIsShowModalConfirmDelete(false)
    if (result) {
      handleDeleteRedeem(item?._id)
    }
  }, [])

  const handleShowModalOptionGift = useCallback(() => {
    setIsShowGift(true)
  }, [])

  const handleCloseModalOptionGift = useCallback(() => {
    setIsShowGift(false)
  }, [])

  return (
    <div>
      <Box sx={{boxShadow: theme => `0 0 1px ${theme.palette.primary.main}`}} className="settingMission_body_wrapper">
        <div>
          <span className="settingMission_body_title">{item?.redeem_name}</span>
        </div>
        <div>
          {item?.gift_data?.length > 0 && <span>{item?.gift_data[0]?.name}</span>}
          {Boolean(item?.gift_coin) && (
            <div className="missionDetail_right_info_award_name">
              <img src={iconDiamond} width={20} height={20} alt="icon/diamond" loading="lazy" />
              <span>{helpers.formatNumberWithCommas(item?.gift_coin || '0')}</span>
            </div>
          )}
        </div>
        <div
          className={`settingMission_body_button ${showIndexItem === idx && 'active'}`}
          onClick={handleShowDetailRedeem}
        >
          <i className="bi bi-chevron-down"></i>
        </div>
      </Box>
      {showIndexItem === idx && (
        <Box sx={{backgroundColor: '#fff'}} className="settingMission_body_timeline">
          {item?.mission_data?.map((data, index) => (
            <MissionTimeline
              key={index}
              length={item?.mission_data?.length}
              data={data}
              index={index + 1}
              handleShowModalConfirmDelete={handleShowModalConfirmDelete}
              handleShowModalConfirmUpdate={handleShowModalConfirmUpdate}
              handleShowModalOptionGift={handleShowModalOptionGift}
            />
          ))}
        </Box>
      )}
      {isShowGift && <ViewGiftMission show={isShowGift} onClose={handleCloseModalOptionGift} />}

      {isShowModalConfirmDelete && (
        <DeleteConfirm
          show={isShowModalConfirmDelete}
          title={__("mission_setting_confirm")}
          onClose={_onClose}
        />
      )}
      {isShowModalConfirmUpdate && (
        <Modal show={isShowModalConfirmUpdate} onHide={handleShowModalConfirmUpdate}>
          <Modal.Header closeButton>
            <Modal.Title>{__("mission_confirm_edit")}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              {__("mission_modal_text")}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button sx={{textTransform: 'none'}} variant="text" onClick={handleShowModalConfirmUpdate}>
              {__("btn_close")}
            </Button>
            <Button sx={{textTransform: 'none'}} onClick={handleEditRedeem}>
              {__("mission_modal_btn")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default memo(SettingItemMission)
