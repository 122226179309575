//Library
import __, {___} from 'languages/index'
import React, {memo, useCallback, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {faCalendar, faClock, faGear} from '@fortawesome/free-solid-svg-icons'
import {Form} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {AxiosError, AxiosResponse} from 'axios'

//Component
import ModalConfirmMember from './member.modal.confirm'
import AvatarMember from './member.avatar'
import ModalSettingUser from './member.setting.modal'
import Capacity from 'components/Capacity'
import TextField from 'components/TextField'

//Helpers
import helpers from 'helpers'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {
  banUser,
  createChatroomToUser,
  postBossIntoUser,
  postUserIntoBoss,
  updatePointUser,
  updateStatusPermissionUser
} from '../store/member.store.reducer'

//Hook
import {EnumTypeToast, useToast} from '../../../hooks/useToast'

//Images
import chatLeftDot from 'media/icons/message.svg'
import avatarDefaults from 'media/images/avatar_default.png'

//Interface
import {TypedChannelLevel} from '../interface'
import {TypedDataMember} from 'interfaces/member.interface'

//Scss
import '../media/member.list.item.scss'
import { formatString } from 'entities/support/utils'
import { Box, Button, Typography } from '@mui/material'

const MemberLists = ({
  data,
  isRefresh,
  listLevels,
  setIsRefresh,
  setMemberLists,
  memberLists
}: {
  data: TypedDataMember
  isRefresh: boolean
  listLevels: TypedChannelLevel[]
  setIsRefresh: Function
  setMemberLists: Function
  memberLists: TypedDataMember[]
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user_data = useAppSelector(state => state.user.user_data)

  const [isShowModalSettingUser, setIsShowModalSettingUser] = useState<boolean>(false)
  const [isShowModalDeleteUser, setIsShowModalDeleteUser] = useState<boolean>(false)
  const [isShowModalClockedUser, setIsShowModalClockedUser] = useState<boolean>(false)
  const [isShowModalRefuseUser, setIsShowModalRefuseUser] = useState<boolean>(false)
  const [isShowModalAcceptUser, setIsShowModalAcceptUser] = useState<boolean>(false)
  const [isShowModalUnClockedUser, setIsShowModalUnClockedUser] = useState<boolean>(false)
  const [isLoadingUpdatePoint, setIsLoadingUpdatePoint] = useState<boolean>(false)
  const [pointUser, setPointUser] = useState<string>(`${data?.point || 0}`)
  const toast = useToast();

  const handleCreateChatroomToUser = useCallback((partner_id: string) => {
    dispatch(createChatroomToUser({ partner_id: partner_id, chat_type: 'personal' }))
    .unwrap()
    .then((res: AxiosResponse) => {
        const { _id } = res?.data?.chat_room_id
        navigate(`/chat/detail/${_id}`)
      })
      .catch((error: AxiosError) => {
        console.log(`createChatroomToUser_${error}`)
          toast.show({
              content: `${__("member_request_failed")}`,
              type: EnumTypeToast.Error
          })
      })
  }, [])

  const handleShowSettingUser = useCallback(() => {
    setIsShowModalSettingUser(false)
    setIsShowModalDeleteUser(false)
    setIsShowModalClockedUser(false)
  }, [])

  const handleChangeRoleUser = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.target
      if(value === "boss"){
        dispatch(postUserIntoBoss({ _id: data?._id}))
          .unwrap()
          .then(() => {
            setIsShowModalSettingUser(false)
            setIsRefresh(!isRefresh)
            toast.show({
              content: `${__("member_update_user_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateRoleUser_${error}`)
            toast.show({
              content: `${__("member_update_user_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }else {
        dispatch(postBossIntoUser({ _id: data?._id}))
          .unwrap()
          .then(() => {
            setIsShowModalSettingUser(false)
            setIsRefresh(!isRefresh)
            toast.show({
              content: `${__("member_update_user_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`updateRoleUser_${error}`)
            toast.show({
              content: `${__("member_update_user_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }

    },
    [isRefresh]
  )


  const handleShowConfirmClockedUser = useCallback(() => {
    if (isShowModalClockedUser) {
      dispatch(banUser({ _id: data?._id }))
      // dispatch(updateOfficialStatus({ _id: data?._id, official_status: 2 }))
        .unwrap()
        .then(() => {
          setIsShowModalSettingUser(false)
          setIsRefresh(!isRefresh)
            toast.show({
                content: `${__("member_ban_user_success")}`,
                type: EnumTypeToast.Success
            })
        })
        .catch((error: AxiosError) => {
          console.log(`updateRoleUser_${error}`)
            toast.show({
                content: `${__("member_update_user_failed")}`,
                type: EnumTypeToast.Error
            })
        })
      return
    }
    setIsShowModalClockedUser(true)
  }, [isShowModalClockedUser, isRefresh, data])

  const handleUpdatePointUser = useCallback(() => {
    if(Number(pointUser) === Number(data?.point)) return
    console.log("pointUser",pointUser)
    if(
      confirm(`${__("member_confirm_user")}${pointUser}${" "}${__("point_unit")}`)
    ){
      setIsLoadingUpdatePoint(true)
      dispatch(updatePointUser({user_id: data?._id, point: Number(pointUser)}))
      .unwrap()
      .then((res: AxiosResponse)=> {
        const {old_point, point, point_month, point_week} = res?.data
        const memberListsNew = [...memberLists]
        const newData = {...data, old_point: old_point, point: point, point_month: point_month, point_week}
        const index = memberListsNew.findIndex((item: TypedDataMember) => res?.data?.user_id === item?._id)
        memberListsNew.splice(index, 1 , newData);
        setMemberLists([...memberListsNew])
        setIsLoadingUpdatePoint(false)
        toast.show({
          content: `${__("member_confirm_update_user_success")}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((error: AxiosError)=> {
        console.log(`updatePointUser_${error}`);
        handleShowSettingUser()
        toast.show({
          content: `${__("member_confirm_update_user_failed")}`,
          type: EnumTypeToast.Error
        })
        setIsLoadingUpdatePoint(false)
      })
    }
  }, [pointUser,data,memberLists])

  const handleRefuseUser = useCallback(
    (result: boolean) => {
      setIsShowModalRefuseUser(false)
      if (result) {
        dispatch(updateStatusPermissionUser({ _id: data?._id, official_status: 4 }))
          .unwrap()
          .then(() => {
            setIsShowModalSettingUser(false)
            setIsRefresh(!isRefresh)
              toast.show({
                  content: `${__("member_update_user_success")}`,
                  type: EnumTypeToast.Success
              })
          })
          .catch((error: AxiosError) => {
            console.log(`updateRoleUser_${error}`)
              toast.show({
                  content: `${__("member_update_user_failed")}`,
                  type: EnumTypeToast.Error
              })
          })
      }
    },
    [isRefresh]
  )

  const handleAcceptUser = useCallback(
    (result: boolean) => {
      setIsShowModalAcceptUser(false)
      setIsShowModalUnClockedUser(false)
      if (result) {
        dispatch(updateStatusPermissionUser({ _id: data?._id, official_status: 1 }))
          .unwrap()
          .then(() => {
            setIsShowModalSettingUser(false)
            setIsRefresh(!isRefresh)
              toast.show({
                  content: `${__("member_update_user_success")}`,
                  type: EnumTypeToast.Success
              })
          })
          .catch((error: AxiosError) => {
            console.log(`updateRoleUser_${error}`)
              toast.show({
                  content: `${__("member_update_user_failed")}`,
                  type: EnumTypeToast.Error
              })
          })
      }
    },
    [isRefresh]
  )

  useEffect(()=> {
    if(!isShowModalSettingUser) return
    setPointUser(`${data?.point || 0}`)
  },[isShowModalSettingUser])


  return (
    <div key={`member${data?._id}`}  id="memberLists" className="memberLists_container">
      <div className="memberLists_left">
        <Link to={`/user/detail/${data?._id}`}>
          <AvatarMember data={data} listLevels={listLevels} />
        </Link>
        <div className="memberLists_left_info">
          <Link
            to={`/user/detail/${data?._id}`}
            className="memberLists_left_info_name text-decoration-none"
          >
            {data?.display_name || `${__("anonymous_user")}`}
          </Link>
          {
            helpers.getRoleName(data?.permission) !== 'user' && (
              <span className="memberLists_left_info_position">
                {helpers.getRoleName(data?.permission)}
              </span>
            )
          }
          <span className="memberLists_left_info_des">{data?.bio || ''}</span>
          <div className="memberLists_left_info_active">
            <FontAwesomeIcon icon={faClock} />
            <span className="memberLists_left_info_active_title">{`${__("member_list_active")} ${helpers.subtractTimeHistory(
              new Date(data?.last_active).getTime()
            )}`}</span>
          </div>
          <div className="memberLists_left_info_active">
            <FontAwesomeIcon icon={faCalendar} />
            <span className="memberLists_left_info_active_title">{`${__("member_list_join")} ${helpers.convertDateTime(
              data?.createdAt
            )}`}</span>
          </div>
        </div>
      </div>
      <div className="memberLists_right">
        <div className="memberLists_right_button_wrapper">
          {Number(data?.official_status) === 1 && user_data?._id !== data?._id && (
            <>
              <Box
                sx={{border: theme=>`1px solid ${theme.palette.primary.main}`}}
                className="memberLists_right_button_chat"
                onClick={() => handleCreateChatroomToUser(data?._id)}
                role="button"
              >
                <img
                  src={chatLeftDot}
                  className="memberLists_right_icon_chat"
                  alt="icon_chat"
                  loading="lazy"
                />
              </Box>
              <Capacity current_user_can={'boss'}>
                <Button
                  className="memberLists_right_button_setting"
                  onClick={() => setIsShowModalSettingUser(true)}
                  role="button"
                >
                  <FontAwesomeIcon icon={faGear} />
                  <Typography textTransform={'none'} fontWeight={600} className="memberLists_right_button_setting_title">{__("member_setting_user")}</Typography>
                </Button>
              </Capacity>
            </>
          )}
          {Number(data?.official_status) === 0 && (
            <>
              <Button onClick={() => setIsShowModalRefuseUser(true)} variant='text'>
                <Typography textTransform={'none'} color='error.main'>
                  {__("member_btn_reject")}
                </Typography>
              </Button>
              <Button
                onClick={() => setIsShowModalAcceptUser(true)}
                sx={{textTransform: 'none'}}
              >
                {__("btn_confirm")}
              </Button>
            </>
          )}
          {Number(data?.official_status) === 3 && (
            <Button
              sx={{textTransform: 'none'}}
              onClick={() => setIsShowModalUnClockedUser(true)}
            >
              {__("member_btn_unlock")}
            </Button>
          )}
          {Number(data?.official_status) === 4 && (
            <Button
              sx={{textTransform: 'none'}}
              onClick={() => setIsShowModalAcceptUser(true)}
            >
              {__("member_btn_approval")}
            </Button>
          )}
        </div>
      </div>
      {isShowModalSettingUser && (
        <ModalSettingUser show={isShowModalSettingUser} onClose={handleShowSettingUser}>
          <div className="memberLists_modal_setting_user_wrapper">
            <div className="memberLists_modal_setting_user_header">
              <img
                src={data?.user_avatar_thumbnail || data?.user_avatar || avatarDefaults}
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  e.currentTarget.src = avatarDefaults
                }}
                className="memberLists_modal_setting_user"
                alt="avatar"
                loading="lazy"
              />
              <div className="memberLists_modal_setting_user_info">
                <span className="memberLists_modal_setting_user_info_name">
                  {data?.display_name || __("user_anonymous")}
                </span>
                <span>{`${__("member_text")} ${__("mentoruser".includes(helpers.getRoleName(data?.permission))?"user":"boss")}`}</span>
              </div>
            </div>
            <div className="memberLists_modal_setting_user_body">
              <span>{__("member_role")}</span>
              <Form.Select
                role="button"
                defaultValue={helpers.getRoleName(data?.permission)}
                onChange={handleChangeRoleUser}
              >
                <option value="user">{__("user")}</option>
                <option value="boss">{__("member_role_admin")}</option>
              </Form.Select>
            </div>
            <Form onSubmit={handleUpdatePointUser} className="memberLists_modal_setting_user_body d-flex">
              <span className="text-nowrap">{__("member_point_user")}</span>
              <TextField
                value={pointUser || '0'}
                maxLength={13}
                onChange={(value: string) => setPointUser(helpers.parseNumeric(value))}
                />
              <Button onClick={handleUpdatePointUser} disabled={Number(pointUser) === Number(data?.point)} className="button-event">
                {isLoadingUpdatePoint ? '...' : `${__("member_btn_save")}`}
              </Button>
            </Form>

            <button
              onClick={handleShowConfirmClockedUser}
              className="memberLists_modal_setting_user_body_button btn btn-link text-primary"
            >
              {isShowModalClockedUser
                ? `${__("member_confirm_lock_user")}`
                : `${__("member_lock_user")}`}
            </button>
          </div>
        </ModalSettingUser>
      )}
      {isShowModalRefuseUser && (
        <ModalConfirmMember
          show={isShowModalRefuseUser}
          onClose={handleRefuseUser}
          title={__("member_modal_confirm_title")}
        >
          <p>{__("member_confirm_reject_user")}</p>
        </ModalConfirmMember>
      )}
      {isShowModalAcceptUser && (
        <ModalConfirmMember
          show={isShowModalAcceptUser}
          onClose={handleAcceptUser}
          title={__("member_confirm_add_user")}
        >
          <p>{__("member_confirm_add_user_text")}</p>
        </ModalConfirmMember>
      )}
      {isShowModalUnClockedUser && (
        <ModalConfirmMember
          show={isShowModalUnClockedUser}
          onClose={handleAcceptUser}
          title={__("member_confirm_unlock_user")}
        >
          <p>{__("member_confirm_unlock_user_text")}</p>
        </ModalConfirmMember>
      )}
    </div>
  )
}

export default memo(MemberLists)
