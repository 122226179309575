import { SvgIcon, SvgIconProps } from '@mui/material'

export default function RankTopIcon2(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path d="M12.3851 0.699762C13.4577 0.569514 14.5423 0.569514 15.6149 0.699762C16.6876 0.83001 17.7406 1.08955 18.751 1.47272C19.7613 1.8559 20.7216 2.35989 21.6109 2.97372C22.5002 3.58756 23.3119 4.30672 24.0285 5.11553C24.745 5.92435 25.3611 6.81688 25.8633 7.77367C26.3654 8.73047 26.75 9.7445 27.0086 10.7937C27.2672 11.8428 27.3979 12.9194 27.3979 14C27.3979 15.0806 27.2672 16.1572 27.0086 17.2063C26.75 18.2555 26.3654 19.2695 25.8633 20.2263C25.3611 21.1831 24.745 22.0757 24.0285 22.8845C23.3119 23.6933 22.5002 24.4124 21.6109 25.0263C20.7216 25.6401 19.7613 26.1441 18.751 26.5273C17.7406 26.9105 16.6876 27.17 15.6149 27.3002C14.5423 27.4305 13.4577 27.4305 12.3851 27.3002C11.3124 27.17 10.2594 26.9105 9.24903 26.5273C8.23868 26.1441 7.2784 25.6401 6.38911 25.0263C5.49982 24.4124 4.68806 23.6933 3.97151 22.8845C3.25496 22.0757 2.63889 21.1831 2.13673 20.2263C1.63456 19.2695 1.24999 18.2555 0.991396 17.2063C0.732799 16.1572 0.602076 15.0806 0.602076 14C0.602076 12.9194 0.732798 11.8428 0.991395 10.7937C1.24999 9.7445 1.63456 8.73047 2.13673 7.77367C2.63889 6.81688 3.25496 5.92435 3.97151 5.11553C4.68806 4.30672 5.49982 3.58756 6.38911 2.97372C7.2784 2.35989 8.23868 1.8559 9.24903 1.47272C10.2594 1.08955 11.3124 0.83001 12.3851 0.699762Z" fill="#E35D6A" stroke="white" />
        <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" fill="url(#paint0_linear_7194_16496)" />
        <defs>
          <linearGradient id="paint0_linear_7194_16496" x1="14" y1="24.5" x2="14" y2="3.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#F8D7DA" />
            <stop offset="1" stop-color="white" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
