import { Box, Button, FormControl, Grid, MenuItem, OutlinedInput, Paper, Select, Stack, Typography, styled } from "@mui/material"
import { CheckIcon, SearchIcon } from "icons"
import __, { ___ } from "languages/index"
import BigLayout from "layouts/bigLayout"
import { OrderStatus, PaymentStatus, TypeActionOrderList } from "./data/order.enum"
import { notEmpty, useField, useForm } from "@shopify/react-form"
import { TypedDetailForm } from "./order.manager.detail"
import { validateLabel } from "entities/support/utils"
import __helpers from "helpers/index"
import OrderInfomationForm from "./components/Order.infomation.form"
import OrderDetailMethodPayment from "./components/Order.detail.methodPatment"
import { useEffect, useState } from "react"
import { TypedProduct, TypedStock } from "entities/shop/interface"
import { useAppDispatch } from "config/store"
import { AxiosError, AxiosResponse } from "axios"
import { tabChildrenManager } from "./data"
import { OrderProduct } from "./order.type"
import { getSTockes } from "entities/shop/store/shop.store.reducer"
import { IsDefaultStock } from "entities/shop/data/shop.logictic.enum"
import OrderDetailTable from "./components/Order.detail.table"
import BoxSuggestCreateProduct from "components/BoxSuggestCreateProduct"
import { createOrderAdmin, updateStatusOrder } from "./order.store.reducer"
import { EnumTypeToast, useToast } from "hooks/useToast"
import { useNavigate } from "react-router-dom"
import ConfirmModal from "components/ConfirmModal"

const OrderCreate = () => {
    const [stockes, setStockes] = useState<TypedStock[]>([])
    const [detailOrder, setDetailOrder] = useState<OrderProduct[]>([]);
    const dispatch = useAppDispatch();
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalOriginPrice, setOriginTotalPrice] = useState(0)
    const [productIsNotEnough, setProductIsNotEnough] = useState<OrderProduct[]>([]);
    const [stockIdSelected, setStockIdSelected] = useState('')
    const [showModalWarningChangeStock, setShowModalWarningChangeStock] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();
    useEffect(() => {
        dispatch(getSTockes({
            limit: 100
        }))
            .unwrap()
            .then((res) => {
                setStockes(res.data)
                const defaultStock = res.data.find((item) => item.is_default_stock === IsDefaultStock.DEFAULT);
                fields.stock_id.onChange(defaultStock?.stock_id || res.data[0].stock_id)

            })
            .catch((err: AxiosError) => {
                console.log('get stockes', err)
            })
    }, [])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            const confirmationMessage = 'Thông tin chưa được lưu,bạn có muốn tải lại trang không?';
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };


        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (productIsNotEnough.length) {
            setShowModalWarningChangeStock(true)
        }
    }, [productIsNotEnough])


    const useFields: TypedDetailForm = {
        order_customer_name: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("name_partner")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('name_partner'))?.required

                    }
                }
            ]
        }),
        order_customer_phonenumber: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("phone_number")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('phone_number'))?.required

                    }

                    if (!__helpers.isPhoneNumber(inputVal)) {
                        return `${__('validate_phone')}`
                    }
                },
            ]
        }),
        order_customer_address_ward: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_ward")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_ward'))?.required

                    }
                }
            ]
        }),
        order_customer_address_district: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_district")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_district'))?.required

                    }
                }
            ]
        }),
        order_customer_address_city: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_city")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_city'))?.required

                    }
                }
            ]
        }),
        order_customer_address: useField<string>({
            value: '',
            validates: []
        }),
        customer_note: useField<string>({
            value: '',
            validates: []
        }),
        order_status: useField<OrderStatus>({
            value: OrderStatus.Pending,
            validates: []
        }),

        // kho

        stock_id: useField<string>({
            value: '',
            validates: []
        }),

        logistic_id: useField<string>({
            value: '',
            validates: []
        }),

        order_delivery_type: useField<string>({
            value: "cash_on_delivery",
            validates: []
        }),
        payment_status: useField<PaymentStatus>({
            value: PaymentStatus.UNPAID,
            validates: []
        }),
        order_voucher: useField<string>({
            value: '',
            validates: []
        })
    }

    const { fields, submit, submitting } = useForm({
        fields: useFields,
        async onSubmit(values) {
            try {

                const customerAddress = {
                    order_customer_address_city: values.order_customer_address_city,
                    order_customer_address_district: values.order_customer_address_district,
                    order_customer_address_ward: values.order_customer_address_ward,
                    order_customer_name: values.order_customer_name,
                    order_customer_phonenumber: values.order_customer_phonenumber,
                    order_customer_address: values.order_customer_address,
                    customer_note: values.customer_note
                }
                const listProduct = detailOrder.map((res) => ({
                    product_id: res.product.product_id,
                    item_quantity: res.item_quantity
                }))

                dispatch(createOrderAdmin({
                    logistic: [{
                        logistic_id: values.logistic_id
                    }],
                    customer_address: customerAddress,
                    products: listProduct,
                    order_delivery_type: values.order_delivery_type,
                    stock_id: values.stock_id,
                    order_status: values.order_status
                })).unwrap()
                    .then((res) => {

                        if (values.order_status !== OrderStatus.Pending) {
                            dispatch(updateStatusOrder({
                                idOrder: res.data?.order_id,
                                body: {
                                    order_status: values.order_status
                                }
                            }))
                        }
                        toast.show({ type: EnumTypeToast.Success, content: `${__("order_create_success")}` })
                        navigate('/shop/manage-order')
                    })
                return { status: 'success' }
            } catch (e: any) {
                console.error(`Submit error`, e)
                const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
                const field = e?.response?.data?.errorKey ?? 'base'
                return { status: 'fail', errors: [{ field, message }] }
            }
        }
    })

    const handleChangeStock = (stock_id) => {

        const productInOrder = detailOrder;
        const productInStockIsNotEnough: OrderProduct[] = [];
        productInOrder.forEach((item) => {
            const findStock = item.product.product_to_stock.find((stock) => +stock.stock_id === +stock_id);
            if (!findStock || findStock?.product_quantity < item.item_quantity) {
              productInStockIsNotEnough.push(item)
            } else {
                fields.stock_id.onChange(stock_id)
            }
        });

        if (productInStockIsNotEnough.length) {
            setStockIdSelected(stock_id)
            setProductIsNotEnough(productInStockIsNotEnough)
        } else {
            fields.stock_id.onChange(stock_id)
        }
    }

    const handleSelectItemProduct = (product: TypedProduct) => {
        let dataNew = detailOrder
        const productAlreadyExist = detailOrder.find((item) => item.product.product_id === product.product_id);

        if (productAlreadyExist) {
            dataNew = detailOrder.map((res) => {
                if (res.product.product_id === product.product_id) {
                    return {
                        ...res,
                        item_quantity: +res.item_quantity + 1
                    }
                }

                return res;
            })

        } else {
            const data: OrderProduct = {
                item_quantity: 1,
                product_original_price: product.product_original_price,
                product_price: product.product_price,
                product: product
            }

            dataNew.unshift(data)
        }

        setDetailOrder(dataNew)
        updatePriceTotal(dataNew)
    }

    const updatePriceTotal = (dataNew: OrderProduct[]) => {
        let totalPrice = 0;
        let totalOriginPrice = 0;

        dataNew.forEach((res) => {
            if (res.type !== TypeActionOrderList.DELETE) {
                totalOriginPrice += res.product_original_price * res.item_quantity;
                totalPrice += res.product_price * res.item_quantity
            }
        })

        setTotalPrice(totalPrice)
        setOriginTotalPrice(totalOriginPrice)
    }



    const handleChangeQuantityProduct = (product_id, value) => {

        const findProduct = detailOrder.find((res) => res.product.product_id === product_id);
        const findStock = findProduct.product.product_to_stock.find((res) => +res.stock_id === +fields.stock_id.value);

        if (!findStock && value > findProduct.item_quantity || findProduct.item_quantity + 1 > findStock?.product_quantity && value > findProduct.item_quantity) {
            toast.show({
                type: EnumTypeToast.Error, title: 'Error', content: __('product_in_stock_not_enough')
            })
        } else {
            const dataNew = detailOrder.map((item) => {
                if (item.product.product_id === product_id) {
                    return {
                        ...item,
                        item_quantity: +value
                    }
                }
                return item;
            });

            setDetailOrder(dataNew)
            updatePriceTotal(dataNew)
        }
    }

    const handleRemoveProductFromOrderList = (product_id) => {
        const dataNew = detailOrder.filter((res) => res.product.product_id !== product_id)
        setDetailOrder(dataNew)
        updatePriceTotal(dataNew)
    }

    return (
        <BigLayout>
            <CreateContainer>
                <Stack className="box_header">
                    <Typography variant="h5">{__('order_create')}</Typography>

                    <Button
                        onClick={submit}
                        disabled={submitting || !detailOrder?.length}
                    >
                        <CheckIcon />
                        {__('save_order')}
                    </Button>
                </Stack>

                <Box className="info_order"   >

                    <OrderInfomationForm fields={fields} />

                    <OrderDetailMethodPayment
                        fields={fields}
                        stockes={stockes}
                        orderTotalPrice={totalPrice}
                        orderTotalOriginPrice={totalOriginPrice}
                        orderFee={0}
                    />
                </Box>

                <Box className="control_list_item_order"  >

                    <Stack flexDirection={"row"} alignItems="center">
                        <FormControl sx={{
                            width: '30%'
                        }} >
                            <Select

                                MenuProps={{
                                    elevation: 1,
                                    sx: {
                                        maxHeight: theme => theme.spacing(37.5),
                                        maxWidth: theme => theme.spacing(27)
                                    }

                                }}
                                displayEmpty
                                required
                                defaultValue={fields.stock_id.value}
                                value={fields.stock_id.value}
                                onChange={(e) => handleChangeStock(e.target.value)}
                                input={<OutlinedInput />}
                                className='payment-modal-select'
                                sx={{
                                    padding: theme => theme.spacing(1, 2),
                                    backgroundColor: theme => theme.palette.background.default,
                                    borderRadius: theme => theme.spacing(1.5),
                                    width: '100%'
                                }}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {stockes?.length && stockes.map((stock) => (
                                    <MenuItem
                                        key={stock?.stock_id}
                                        value={stock?.stock_id}
                                        sx={{
                                            whiteSpace: "nowrap",
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: 'block'
                                        }}
                                    >
                                        {stock?.stock_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <BoxSuggestCreateProduct stock_id={fields.stock_id.value} handleSelectItemProduct={handleSelectItemProduct} />
                    </Stack>

                    <FormControl sx={{
                        width: '100%'
                    }} >
                        <Select
                            // onBlur={() => {
                            //     fields.stock_address_city?.runValidation(fields.stock_address_city.value)
                            // }}
                            MenuProps={{
                                elevation: 1,
                                sx: {
                                    maxHeight: theme => theme.spacing(37.5)
                                }

                            }}
                            displayEmpty
                            required
                            defaultValue={fields.order_status.value}
                            value={fields.order_status.value}
                            onChange={(e) => fields.order_status.onChange(e.target.value as OrderStatus)}
                            input={<OutlinedInput />}
                            className='payment-modal-select'
                            sx={{
                                padding: theme => theme.spacing(1, 2),
                                backgroundColor: theme => theme.palette.background.default,
                                borderRadius: theme => theme.spacing(1.5),
                                width: '100%'
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">
                                {__("status_order")}
                            </MenuItem>
                            {tabChildrenManager.filter(item => item.value !== OrderStatus.Cancelled && item.value).map((statusOrder) => (
                                <MenuItem
                                    key={statusOrder?.value}
                                    value={statusOrder?.value}
                                >
                                    {statusOrder?.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {fields.order_status?.error && (
                            <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.order_status?.error}</Typography>
                        )}
                    </FormControl>
                </Box>

                <Box className="table_item_product">
                    <OrderDetailTable
                        orderProducts={detailOrder}
                        handleRemoveProductFromOrderList={handleRemoveProductFromOrderList}
                        handleChangeQuantityProduct={handleChangeQuantityProduct}
                    />
                </Box>

                <ConfirmModal

                    onClose={() => {
                        setProductIsNotEnough([])
                        setStockIdSelected('')
                        setShowModalWarningChangeStock(false)
                    }}
                    open={showModalWarningChangeStock}
                    onConfirm={() => {
                        let dataNew = detailOrder;
                        productIsNotEnough.forEach((res) => {
                            dataNew = dataNew.filter((item) => item.product.product_id !== res.product.product_id)
                        })

                        setDetailOrder(dataNew)

                        updatePriceTotal(dataNew)
                        setProductIsNotEnough([])
                        fields.stock_id.onChange(stockIdSelected)
                        setShowModalWarningChangeStock(false)
                    }}
                    title={__('btn_confirm')}
                    cancelTextBtn={__('home_btn_cancel')}
                >
                    <Typography>
                        {___('notice warning {name} remove', {
                            name: <>{productIsNotEnough.map((item) => item.product.product_name).join(', ')}</>
                        })}
                    </Typography>

                </ConfirmModal>
            </CreateContainer>
        </BigLayout>
    )
}

export default OrderCreate

const CreateContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),

    '& .box_header': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& .btn': {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',

            '& button': {
                fontSize: '1rem',

                '& svg': {
                    marginRight: theme.spacing(0.5)
                }
            },

            '& button:first-child': {
                margin: theme.spacing(0, 1),
                borderColor: theme.palette.divider
            }
        }
    },
    '& .info_order': {
        margin: theme.spacing(2, 0),
        display: 'grid',
        gridTemplateColumns: '65% 30%',
        gridGap: theme.spacing(2)
    },
    '& .control_list_item_order': {
        display: 'grid',
        gridTemplateColumns: '60% 35%',
        gridGap: theme.spacing(2),

        '& fieldset': {
            border: 'none'
        },
    }

}))
