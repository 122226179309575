import React, { useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { contactFormConfigs } from 'entities/channel/components/createChannelFormConfigs'
import { Controller, useFormContext } from 'react-hook-form'
import { CreateChannelContactPayload } from './validation/contactForm.vaidation'
import { ArrowLeftIcon } from '../../../icons'
import TypedChannel from '../../../interfaces/channel.interface'
import __ from 'languages/index'
import Select from 'components/Select'
import { dataCategory as workfields } from 'entities/channel/data'

type Props = {
  onSubmit?: (
    value: CreateChannelContactPayload & {
      _id: string
    }
  ) => void
  onBack?: () => void
  draft?: Partial<TypedChannel>
}

const ContactFormStep = ({ onSubmit, onBack, draft }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useFormContext<CreateChannelContactPayload>()

  const handleSubmitForm = (value: CreateChannelContactPayload) => {
    onSubmit({
      _id: draft._id,
      ...value
    })
  }

  useEffect(() => {
    if (!draft) return
    reset({
      mentor_name: draft?.mentor_name,
      mentor_income: +draft?.mentor_income || 0,
      mentor_address: draft?.mentor_address,
      mentor_category: draft?.mentor_category,
      mentor_number_member: draft?.mentor_number_member || 0,
      mentor_target: draft?.mentor_target
    })
  }, [draft])

  return (
    <Box width="100%">
      <Typography variant="h4" component="h4" fontWeight={700}>
        {__('label_contact_info')}
      </Typography>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Stack gap={2}>
          {contactFormConfigs.map(formEl => {
            return formEl.type === 'text' ? (
              <FormControl
                fullWidth
                key={formEl.name}
                {...register(formEl.name as keyof CreateChannelContactPayload)}
              >
                <TextField
                  label={formEl.label}
                  placeholder={formEl.placeholder}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      '&.MuiInputBase-multiline': {
                        alignItems: 'flex-start'
                      }
                    },
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        {<formEl.icon fontSize={'small'} />}
                      </InputAdornment>
                    )
                  }}
                  {...formEl}
                />
                <FormHelperText error>{errors[formEl.name]?.message}</FormHelperText>
              </FormControl>
            ) : (
              <Controller
                name={'mentor_category'}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControl variant={'filled'}>
                      <InputLabel>{formEl.label}</InputLabel>
                      <Select
                        value={value || ''}
                        options={workfields}
                        renderItem={option => option.value}
                        getItemValue={option => option.value}
                        onChangeValue={onChange}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position={'start'}
                              sx={{ marginTop: 0, alignItems: 'flex-end' }}
                            >
                              <formEl.icon fontSize={'small'} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  )
                }}
              />
            )
          })}

          <Stack
            direction="row"
            justifyContent={'space-between'}
            alignItems={'center'}
            mt={2}
            width={'100%'}
          >
            <IconButton onClick={onBack}>
              <ArrowLeftIcon />
            </IconButton>
            <Button type={'submit'}>{__('btn_next')}</Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}

export default ContactFormStep
