import { Box, Button, Checkbox, FormControlLabel, IconButton, InputBase, Paper, Stack, styled, Typography } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useAppDispatch } from "config/store";
import __helpers from "helpers/index";
import __ from "languages/index";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TypedCategory, TypedProductCollection } from "../interface";
import { getCategoryOfShop, getProductCollectionOfShop } from "../store";
import { IQueryParams } from "config/reducer.utils";
import { ChevronDownIcon, EmptyIcon, SearchIcon } from "icons";
import ChevronUpIcon from "icons/ChevronUpIcon";
import helpers from 'helpers'

// limit record category and collection
const limitRecordCategory = 5
const limitRecordCollection = 5

interface TypedProps {
    handleFilterByField: (type, value) => void;
    queryParams: IQueryParams
}
const BoxFilterProducts = ({ handleFilterByField, queryParams }: TypedProps) => {
    const dispatch = useAppDispatch();
    const [categoryData, setCategoryData] = useState<TypedCategory[]>([])
    const [productCollectionData, setProductCollectionData] = useState<TypedProductCollection[]>([])
    const [checkBoxCategory, setCheckboxCategory] = useState({});
    const [checkBoxCollection, setCheckboxCollection] = useState({});
    const [isTruncatedCategory, setIsTruncatedCategory] = useState(true);
    const [isTruncatedCollection, setIsTruncatedCollection] = useState(true);
    const [gte, setGte] = useState(0);
    const [lte, setLte] = useState(0);
    const [totalCountCategory, setTotalCountCategory] = useState(0);
    const [totalCountCollection, setTotalCountCollection] = useState(0);

    useEffect(() => {
        getCategory(limitRecordCategory);
        getCollection(limitRecordCollection);

        if (queryParams?.product_price) {
            const regex = /(\d+)/g;
            const [gteValue, lteValue] = queryParams?.product_price.match(regex);

            setLte(lteValue)
            setGte(gteValue)

        }
    }, [])

    const getCategory = (limit = 0) => {
        dispatch(getCategoryOfShop({ category_status: '!0' }))
            .unwrap()
            .then((res: AxiosResponse<TypedCategory[]>) => {
                let dataCategory = res.data;
                setTotalCountCategory(+res.headers['x-total-count']);

                if (queryParams['product_to_category.category_id']) {
                    const data = queryParams['product_to_category.category_id'].split(',');
                    let params = {}
                    data.forEach(id => {
                        params[id] = true
                    });
                    dataCategory = dataCategory.sort((a, b) => {
                        let aIdIndex = data.indexOf(a.category_id);
                        let bIdIndex = data.indexOf(b.category_id);

                        if (aIdIndex !== -1) return -1;
                        if (bIdIndex !== -1) return 1;
                        return 0;
                    })
                    setCheckboxCategory(params)
                };
                setCategoryData(dataCategory)
            })
            .catch((error: AxiosError) => {
                console.log(`getCategoryOfShop error >>>`, error)
            })
    }

    const handleSearchCategory = (id, value) => {
        const newVal = {
            ...checkBoxCategory,
            [id]: value
        }
        const idTrue = Object.keys(newVal).reduce((res, key) => {
            if (newVal[key]) {
                res.push(key)
            }
            return res
        }, []);
        
        handleFilterByField('product_to_category.category_id', idTrue.join(','));
        setCheckboxCategory(newVal)
    }

    const getCollection = (limit = 0) => {
        dispatch(getProductCollectionOfShop({ collection_status: '!0' }))
            .unwrap()
            .then((res: AxiosResponse) => {
                let dataCollection = res.data;
                setTotalCountCollection(+res.headers['x-total-count'])
                if (queryParams['product_to_collection.collection_id']) {
                    const data = queryParams['product_to_collection.collection_id'].split(',');
                    let params = {}
                    data.forEach(id => {
                        params[id] = true
                    });
        
                    dataCollection = dataCollection.sort((a, b) => {
                        let aIdIndex = data.indexOf(a.collection_id);
                        let bIdIndex = data.indexOf(b.collection_id);
        
                        if (aIdIndex !== -1) return -1;
                        if (bIdIndex !== -1) return 1;
                        return 0;
                    })
                    setCheckboxCollection(params)
                }
                setProductCollectionData([...dataCollection])
            })
            .catch((error: AxiosError) => {
                console.log(`getProductCollectionOfShop error >>>`, error)
            })
    }

    const handleSearchCost = () => {
        let query = [];

        if (gte > 0) {
            query.unshift(`gte:${gte}`);
        }

        if (lte > 0) {
            query.push(`lte:${lte}`);
        }

        handleFilterByField('product_price', query.join(','))
    };


    const handleSearchCollection = (id, value) => {
        const newVal = {
            ...checkBoxCollection,
            [id]: value
        }
        const idTrue = Object.keys(newVal).reduce((res, key) => {
            if (newVal[key]) {
                res.push(key)
            }
            return res
        }, []);

        handleFilterByField('product_to_collection.collection_id', idTrue.join(','));
        setCheckboxCollection(newVal)
    }

    const onKeyDown = useCallback(event => {
        if ('e+-'.includes(event.key)) {
            event.preventDefault() // Chặn sự kiện nhập ký tự e, -, +
        }
    }, [])

    const handleBlurFormatCost = (e) => {
        const value = e.target.value;
        e.target.value = __helpers.convertToCommasFormat(value) === "NaN" ? "" : __helpers.convertToCommasFormat(value)
    }

    const handleForcusCost = (e) => {
        e.target.value = e.target.value.replace(/,/g, '');
    }

    return (
        <BoxFilterProductsContainer>
            <Typography variant="subtitle1">
                {__('filter')}
            </Typography>
            <Box className="category" mt={3}>
                <Typography mb={0.8} variant="subtitle1">
                    {__('filter_category')}
                </Typography>
                {helpers.isEmpty(categoryData) ? (
                    <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                        <EmptyIcon
                        sx={{
                            width: theme => theme.spacing(18.75),
                            height: theme => theme.spacing(18.75)
                        }}
                        />
                    </Box>
                ) : (
                    <Box>
                        {(isTruncatedCategory ? categoryData.slice(0, limitRecordCollection) : categoryData).map((item) => (
                            <FormControlLabel
                                sx={{
                                    margin: theme => theme.spacing(0.8, 0)
                                }}
                                className="check_list"
                                key={item.category_id}
                                label={item.category_name}
                                control={
                                <Checkbox
                                    checked={checkBoxCategory[item.category_id]}
                                        onChange={(e) => handleSearchCategory(item.category_id, e.target.checked)}
                                />}
                            />))}
                        {
                            totalCountCategory > limitRecordCategory ? (
                                <Button sx={{textTransform: 'none'}}
                                    startIcon={!isTruncatedCategory ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                    onClick={() => setIsTruncatedCategory(!isTruncatedCategory)}
                                    variant="text" className="lern_more_btn">{__(!isTruncatedCategory ? 'collapse' : 'learn_more')}</Button>
                            ) : null
                        }
                    </Box>

                )}
            </Box>
            <Box className="collection" mt={5}>
                <Typography mb={0.8} variant="subtitle1">
                    {__('filter_collection')}
                </Typography>
                {helpers.isEmpty(productCollectionData) ? (
                    <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                        <EmptyIcon
                        sx={{
                            width: theme => theme.spacing(18.75),
                            height: theme => theme.spacing(18.75)
                        }}
                        />
                    </Box>
                ) : (
                    <Box >
                        {
                            (isTruncatedCollection ? productCollectionData.slice(0, limitRecordCollection) : productCollectionData).map((item) => (
                                <FormControlLabel
                                    sx={{
                                        margin: theme => theme.spacing(0.8, 0)
                                    }}
                                    className="check_list"
                                    key={item.collection_id}
                                    label={item.collection_name}
                                    control={
                                        <Checkbox
                                            checked={checkBoxCollection[item.collection_id]}
                                            onChange={(e) => handleSearchCollection(item.collection_id, e.target.checked)}
                                        />}
                                />
                            ))
                        }
                        {
                            totalCountCollection > limitRecordCollection ? (
                                <Button sx={{textTransform: 'none'}}
                                    startIcon={!isTruncatedCollection ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                    onClick={()=> setIsTruncatedCollection(!isTruncatedCollection)}
                                    variant="text" className="lern_more_btn">{__(!isTruncatedCollection ? 'collapse' : 'learn_more')}</Button>
                            ) : null
                        }
                    </Box>
                )}
            </Box>
            <Box className="cost" mt={5}>
                <Typography mb={0.8} variant="subtitle1">
                    {__('filter_cost')}
                </Typography>
                <Stack flexDirection={"row"} justifyContent={"space-between"} mt={0.8}>
                    <InputBase onKeyDown={onKeyDown} onBlur={handleBlurFormatCost} onFocus={handleForcusCost}  onChange={e => setGte(+e.target.value)} className="input_cost" placeholder="Từ" />
                    <InputBase onKeyDown={onKeyDown} onBlur={handleBlurFormatCost} onFocus={handleForcusCost}  onChange={e => setLte(+e.target.value)} className="input_cost" placeholder="Đến" />
                    <IconButton onClick={handleSearchCost} >
                        <SearchIcon />
                    </IconButton>
                </Stack>
            </Box>
            {/* <Box className="sale">

            </Box> */}
        </BoxFilterProductsContainer>
    )
}

export default BoxFilterProducts;

const BoxFilterProductsContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2, 3),

    '& .check_list': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'block'
    },

    '& .lern_more_btn': {
        color: theme.palette.primary,

        '&:hover': {
            backgroundColor: 'nonê'
        },

        '& .MuiTouchRipple-root': {
            display: 'none'
        }
    },

    '& .cost': {
        '& .input_cost': {
            border: '1px solid var(--Text-Secondary, #626F82)',
            borderRadius: theme.spacing(1.5),
            padding: theme.spacing(0, 2),
            width: '35%',
            height: theme.spacing(5.5)
        }
    }
}))