import React, {useEffect} from 'react'
import Comment from './Comment'
import { useGetPostCommentsQuery, useGetPostDetailQuery } from '../store/homeApi'
import RenderView from 'components/RenderView'
import { Typography } from '@mui/material'
import __ from 'languages/index'
import {updateRequestNormal} from "entities/homepage/home.store.reducer";
import {useAppDispatch} from "config/store";
import { TypedRequest } from '../../../interfaces/request.interface'

type Props = {
  post?: TypedRequest
}

const PostComment = ({ post }: Props) => {
  const { data: comments, isLoading } = useGetPostCommentsQuery({ postId: post?._id })
  const { data: postDetail, isLoading: loadingDetail } = useGetPostDetailQuery({ postId: post?._id })
  const dispatch = useAppDispatch()
  const renderHideComments = () => {
    return <Typography textAlign={'center'}>{__('home_page_comment_hidden')}</Typography>
  }

  useEffect( () => {
     dispatch(updateRequestNormal({_id: postDetail?._id, comment_number: postDetail?.comment_number}))

  }, [postDetail?.comment_number,postDetail?.is_comment]);

  const renderListComments = () => {
    return (
      <>
        {comments?.map((comment, index) => <Comment post={post} key={index} comment={comment} depth={1} />)}
      </>
    )
  }

  const renderEmptyList = () => {
    return (
      <div>
        <Typography>There is no comment</Typography>
      </div>
    )
  }

  return (
    <RenderView
      view={renderListComments()}
      isLoading={isLoading || loadingDetail}
      emptyElementFallback={renderEmptyList()}
    />
  )
}

export default PostComment
