import {faArrowUp91, faCircleXmark, faMagnifyingGlass, faMedal} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useAppSelector} from 'config/store'
import {postAddUserToCourse} from 'entities/gift/services/gift.receiver.service'
import React, {useState} from 'react'
import {Form, InputGroup, Modal} from 'react-bootstrap'
import {useInfiniteScroll} from 'hooks/useInfiniteScroll'
import avatarDefault from 'media/images/avatar_default.png'
import ModalAccept from 'entities/homepage/components/modal.accept'
import LoadingSelectUserSkeleton from '../skeletons/course.select.user.skeleton'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import __ from "languages/index";
import {getListMembers} from '../services/courses.service'
import {TypedMember} from '../courses.interface'
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";
import { formatString } from 'entities/support/utils'

export default function ModalAddUser(props) {
  const [modalShow, setModalShow] = useState(false);
  const entity = useAppSelector(state => state.courses.entity)
  const toast = useToast()

  const [selectUser, setSelectUser] = useState<TypedMember>()

  const { data, hasMore, loadMore, updateParamRequestDebounce, isRefreshing, refresh } = useInfiniteScroll < TypedMember > ({
    search: "",
    course_id: props?.courseId,
    limit: 10
  }, getListMembers)

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    updateParamRequestDebounce({ search: inputValue });
  };

  const addUserToCourse = (item) => {

    setModalShow(true)
    setSelectUser(item)
  }

  const acceptAdd = () => {
    setModalShow(false)
    const params = {
      user_id: selectUser?._id || '',
      course_id: props?.courseId,
      add_type: entity ? entity?.coin_value > 0 ? "payment" : 'free' : 'free'
    }
    console.log("params",params)
    postAddUserToCourse(params).then(res => {
      setTimeout(() => {
        refresh()
      }, 500);
      props.refresh()
      toast.show({ type: EnumTypeToast.Success, content: `${__("course_modal_add_user_content_success")}` })
    }).catch(error => {
      toast.show({ type: EnumTypeToast.Error, content: `${__("course_modal_add_user_content_failed")}` })

    })
  }
  const acceptAddSecond = () => {
    setModalShow(false)
    const params = {
      user_id: selectUser?._id || '',
      course_id: props?.courseId,
      add_type: 'free'
    }
    postAddUserToCourse(params).then(res => {
      setTimeout(() => {
        refresh()
      }, 500);
      props.refresh()
      toast.show({ type: EnumTypeToast.Success, content: `${__("course_modal_add_user_content_success")}` })
    }).catch(error => {
      toast.show({ type: EnumTypeToast.Error, content: `${__("course_modal_add_user_content_failed")}` })
    })
  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered

    >
      <Modal.Header className='text-center w-100'>
        <Modal.Title className='w-100' id="contained-modal-title-vcenter">
          <div className='d-flex justify-content-between'>
            <div>

            </div>
            <div style={{ fontSize: 22 }}>
              {__("course_modal_add_member")}
            </div>
            <button className='btn' onClick={() => props.onHide()}>
              <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: 22 }} />
            </button>
          </div>

        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }} id="scrollableDiv">
        <div className='row' >
          <div className='col-md-6 col-12'>
            <InputGroup  >
              <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
              <Form.Control
                placeholder={__("course_modal_search_member")}
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleInputChange}
              />
            </InputGroup>
          </div>

          <div className='col-md-6 col-12 d-flex justify-content-end mt-md-0 mt-3 '>
            {/* <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Trạng thái
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Hoạt động</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Không hoạt động</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            {/* <Button onClick={() => props.onHide()}>
              Đóng
            </Button> */}
          </div>
        </div>

        <InfiniteScrollContainer
          isRefreshing={isRefreshing}
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          refreshFunction={refresh}
          scrollableTarget={"scrollableDiv"}
          skeleton={<LoadingSelectUserSkeleton />}
          textEmptyMessage={formatString(__('data_empty'), `${__('course_student')}`)}
        >
          <div>
            {data?.map((item, index) => {
              return (
                <div className='mt-3 p-2 rounded' style={{ backgroundColor: '#f5f7fa' }} key={index}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <img
                        src={item?.user_avatar_thumbnail.length > 0 ? item?.user_avatar_thumbnail : item?.user_avatar.length > 0 ? item?.user_avatar : avatarDefault}
                        onError={(e) =>
                          (e.currentTarget.src = avatarDefault)
                        }
                        width={50}
                        height={50}
                        style={{ borderRadius: 40 }}
                        alt="avatar"
                        loading="lazy"
                      />
                      <div className='ms-3'>
                        <p className='my-0 fw-bold' style={{ fontSize: 16 }}>{item?.display_name}</p>
                        <div className="d-flex align-items-center giftreceiveradd_container_user_txtlvl">
                          <FontAwesomeIcon icon={faArrowUp91} style={{ marginRight: 5 }} />
                          <div style={{ marginRight: 25 }}>{item?.point} {__("point_unit")}</div>
                          <FontAwesomeIcon icon={faMedal} style={{ marginRight: 5 }} />
                          {`${__("course_level")} ${item?.level_number}`}
                        </div>
                      </div>

                    </div>
                    <button onClick={() => addUserToCourse(item)} className='btn btn-primary'>
                      <p className='my-0'>{__("course_modal_text_add")}</p>
                    </button>
                  </div>

                </div>
              )
            })}
          </div>
        </InfiniteScrollContainer>

      </Modal.Body>
      {entity?.coin_value > 0 ?
        <div>
          {
            modalShow &&
            <ModalAccept
              show={modalShow}
              onHide={() => setModalShow(false)}
              onAccept={acceptAdd}
              onAcceptSecond={acceptAddSecond}
              title={__("course_add_user")}
              desciption={__("course_add_user_desciption")}
              showMultiSelect={true}
              btnFirst={__("course_add_user_paid")}
              btnSecond={__("course_add_user_free")}

            />
          }
        </div> :
        <div>
          {
            modalShow &&
            <ModalAccept
              show={modalShow}
              onHide={() => setModalShow(false)}
              onAccept={acceptAdd}
              title={__("course_add_user")}
              desciption={__("course_add_user_confirm")}
            />
          }
        </div>

      }


    </Modal>
  )
}
