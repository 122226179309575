import BigLayout from 'layouts/bigLayout'
import { Box, styled } from '@mui/material'
import __ from 'languages/index'
import ShopBreadcrumb from 'entities/shop/components/shop.breadcrumb'
import ManageProductHeader from 'entities/shop/components/ManageProductHeader'
import ManageProductTable from 'entities/shop/components/ManageProductTable'
import { useAppDispatch } from 'config/store'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { getProductOfShop } from './store'
import { AxiosError, AxiosResponse } from 'axios'
import { TypedProduct } from './interface'
import __helpers from 'helpers/index'
import { IQueryParams } from 'config/reducer.utils'
import moment from 'moment'
import { omit } from 'lodash'
import ComponentEmpty from 'components/componentEmpty'
const ShopManageProduct = () => {

  const initialQuery = {
    sort: "createdAt:desc",
    page: 1,
    limit: 10,
  };

  const dispatch = useAppDispatch();
  const [paramRequest, setParamRequest] = useState<IQueryParams>({ ...initialQuery });
  const [listProduct, setListProduct] = useState<TypedProduct[]>([]);
  const [totalCount, setTotalCount] = useState(0)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  

  useLayoutEffect(()=>{
    window.history.replaceState(null, "Shop", `/shop/manage-product`);
    setParamRequest({ ...initialQuery })
  },[])

  useEffect(() => {
    getProducts(paramRequest)
  }, [paramRequest])


  const handleFilterByField = (type, value) => {
    const newVal =
      type === 'createdAt' ? moment(value).format('YYYY-MM-DD') : value;
    const newValues: IQueryParams = value === 0 || value
      ? {
        ...paramRequest,
        page: type === 'query' ? 1 : paramRequest?.page,
        [type]: newVal
      }
      : omit(paramRequest, [type]);
    setParamRequest(newValues);
    // getProducts(newValues)
    let buildURLSearch = __helpers.buildEndUrl(newValues);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, "Shop", `/shop/manage-product${buildURLSearch}`);
    }
  };

  const getProducts = (prams: IQueryParams) => {
    setIsRefresh(true)
    dispatch(getProductOfShop(prams))
      .unwrap()
      .then((res: AxiosResponse) => {
        setListProduct([...res.data])
        setTotalCount(res.headers['x-total-count'])
        setIsRefresh(false)
      })
      .catch((error: AxiosError) => {
        console.log(`getProductOfShop error >>>`, error)
      })
  }

  return (
    <BigLayout>
      <ShopManageProductContainer>
        <ShopBreadcrumb
          breadcrumbName={{
            '/shop/manage-product': __('breadcrumb_manage_product'),
          }}
        />
        <Box width={'100%'}>
          <ManageProductHeader
            handleFilterByField={handleFilterByField}
            totalItem={totalCount}
            paramsRequest={paramRequest}
          />
        </Box>
        <Box width={'100%'}>

          {Boolean((!listProduct || listProduct?.length < 1)) ?
            <ComponentEmpty /> : (
              <ManageProductTable
                paramsRequest={paramRequest}
                handleFilterByField={handleFilterByField}
                products={listProduct}
                totalCount={totalCount}
                isRefresh={isRefresh}
              />
            )}
        </Box>
      </ShopManageProductContainer>
    </BigLayout>
  )
}
export default ShopManageProduct
const ShopManageProductContainer = styled(Box)(({ theme }) => ({}))
