import {  Stack, Typography, styled, Button, Paper, Box } from '@mui/material'
import ArrowChevronLeftIcon from '../../../icons/ArrowChevronLeftIcon'
import __, { ___ } from 'languages/index'
import { formatString } from 'entities/support/utils'
import { useNavigate } from 'react-router-dom'
import { useMemo, useState } from 'react'
import ConfirmModal from 'components/ConfirmModal'

interface TypedProps {
  orderCode: string
  orderStatus: number
  handleCancelMyOrder: Function
}

const ShopOrderHeader = ({orderCode,orderStatus = 0,handleCancelMyOrder}: TypedProps) => {
  const navigate = useNavigate()
  const [isShowConfirmCancel, setIsShowConfirmCancel] = useState<boolean>(false)

  const textButton = useMemo(()=>{
    switch (orderStatus) {
      case 1:
        return 'Chờ lấy hàng';
      case 2:
        return 'Đơn hàng đang vận chuyển';
      case 3:
        return 'Đơn hàng đã hoàn thành';
      default:
        return '';
    }
  },[orderStatus])

  return(
    <ShopOrderHeaderContainer>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Button 
          variant='text' 
          onClick={()=> navigate('/shop/my-order')}
          startIcon={<ArrowChevronLeftIcon sx={{height:10, width:10,color: theme=>theme.palette.text.primary, paddingRight: theme=>theme.spacing(0.75)}}/>}
          >
          <Typography variant='body1' textTransform='none' color={'text.primary'}>
            {__("shop_order_back")}
          </Typography>
        </Button>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          columnGap={3}
        >
          <Typography>
            {formatString(__('shop_order_code'), orderCode || '')}
          </Typography>
          <Box sx={{width: '2px', background: theme=>theme.palette.divider, height: theme=>theme.spacing(3)}}></Box>
          {!orderStatus ? <Button onClick={()=> setIsShowConfirmCancel(true)} sx={{textTransform: 'none'}}>
            {__("shop_order_btn_cancel")}
          </Button> : 
          <Typography color={'primary.main'}>
           {textButton}
          </Typography>
          }
        </Stack>
      </Stack>
      {isShowConfirmCancel &&    
        <ConfirmModal
          onClose={() => setIsShowConfirmCancel(false)}
          open={(isShowConfirmCancel)}
          onConfirm={() => {
            handleCancelMyOrder()
          }}
          title={__('btn_confirm')}
          cancelTextBtn={__('home_btn_cancel')}
          confirmText={__('cancel_order_confirm')}
        />}

    </ShopOrderHeaderContainer>
  )
}
export default ShopOrderHeader

const ShopOrderHeaderContainer = styled(Paper)(({theme})=>({
  boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.08)`,
  padding: theme.spacing(2.5),

}))
