import { SvgIcon, SvgIconProps } from '@mui/material'

const HomeIcon = (props: SvgIconProps)=>{
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M12.7838 3.28669C12.3261 2.90444 11.6739 2.90444 11.2162 3.28669L5.29145 8.23497C4.47755 8.91472 4 9.93706 4 11.0226V19.7032C4 20.4389 4.57893 21 5.25 21H7.625V13.9614C7.625 12.7943 8.55711 11.813 9.75 11.813H14.25C15.4429 11.813 16.375 12.7943 16.375 13.9614V21H18.75C19.4211 21 20 20.4389 20 19.7032V11.0226C20 9.93706 19.5224 8.91472 18.7085 8.23497L12.7838 3.28669ZM18.75 23C20.5642 23 22 21.5045 22 19.7032V11.0226C22 9.3527 21.2659 7.76505 19.9906 6.69992L14.0658 1.75164C12.8658 0.749452 11.1342 0.749453 9.93419 1.75164L4.00941 6.69992C2.73409 7.76505 2 9.3527 2 11.0226V19.7032C2 21.5045 3.43578 23 5.25 23H18.75ZM14.375 21V13.9614C14.375 13.8599 14.2997 13.813 14.25 13.813H9.75C9.70025 13.813 9.625 13.8599 9.625 13.9614V21H14.375Z"
              fill="#0E1426" />
      </svg>
    </SvgIcon>

  )
}
export default HomeIcon
