import React from 'react'
import '../media/audio-wave.scss'

const AudioWave = () => {
  return (
    <div className="boxContainer">
      <div className="box box1"></div>
      <div className="box box2"></div>
      <div className="box box3"></div>
      <div className="box box4"></div>
      <div className="box box5"></div>
    </div>
  )
}

export default AudioWave
