//Library
import React, { useCallback, useEffect, useState } from 'react'
import { Box, IconButton, styled } from '@mui/material'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation, Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { ChevronIcon } from 'icons'
import imageDefault from 'media/images/image_default.png'

//Interface
import { TypedBanner } from '../interface'

//Helpers
import helpers from 'helpers'

//Scss
import '../media/styled.scss'
import { useNavigate } from 'react-router-dom'
import __helpers from 'helpers'

const Banner = ({ bannerData }: { bannerData: TypedBanner[] }) => {
  const [largeBannerData, setLargeBannerData] = useState<any>([])
  const [smallBannerData, setSmallBannerData] = useState<TypedBanner[]>([])
  const [isShowNextButton, setIsShowNextButton] = useState<boolean>(true)
  const [isShowPrevButton, setIsShowPrevButton] = useState<boolean>(false)

  const  navigate = useNavigate()
  const ButtonNext = () => {
    const swiper = useSwiper()
    return (
      <IconButton
        onClick={() => {
          setIsShowPrevButton(true)
          typeof swiper.slideNext() === 'function' && swiper.slideNext()
        }}
        sx={{
          color: theme => theme.palette.primary.dark,
          ':hover': {
            background: theme => theme.palette.primary.backgroundHover
          }
        }}
        className="button button_next"
      >
        <ChevronIcon sx={{ transform: 'rotate(-90deg)' }} color="inherit" />
      </IconButton>
    )
  }

  const ButtonPrev = () => {
    const swiper = useSwiper()
    return (
      <IconButton
        onClick={() => {
          setIsShowNextButton(true)
          typeof swiper.slidePrev() === 'function' && swiper.slidePrev()
        }}
        sx={{
          color: theme => theme.palette.primary.dark,
          ':hover': {
            background: theme => theme.palette.primary.backgroundHover
          }
        }}
        className="button button_prev"
      >
        <ChevronIcon sx={{ transform: 'rotate(90deg)' }} color="inherit" />
      </IconButton>
    )
  }

  useEffect(() => {
    const convertBigData = bannerData?.filter((data: TypedBanner) => data?.banner_type === 'big')
    const convertMediumData = bannerData?.filter(
      (data: TypedBanner) => data?.banner_type === 'medium'
    )
    setLargeBannerData([...convertBigData])
    setSmallBannerData([...convertMediumData])
  }, [bannerData])

  const handleNavigate = useCallback((bannerMeta)=>{
    let link = '';
    let param = '';

    switch (Boolean(bannerMeta)) {
      case Boolean(bannerMeta?.product):
        param = `product/${bannerMeta?.product}`
        break
      case Boolean(bannerMeta?.category):
        param = `search?product_to_category.category_id=${bannerMeta?.category}&&sort=product_meta.product_review_count:desc`
        break
      case Boolean(bannerMeta?.link):
        link  = `${bannerMeta?.link}`
        break
      default:
        break
    }
    param?.length ? navigate(`${param}`) : window.location.href = `${link}`

  },[])


  return (
    <BannerContainer>
      <Box width={'100%'} display={'flex'} columnGap={theme => theme.spacing(1.5)}>
        <Box
          className="banner_wrapper"
          sx={{
            flex: smallBannerData?.length > 0 && (smallBannerData?.length > 1 ? 0.7 : 0.5),
            paddingBottom: smallBannerData?.length > 0 && '0 !important',
            borderRadius: theme => `${theme.spacing(2)} !important`
          }}
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            onSlideChange={e => {
              setIsShowNextButton(!e?.isEnd)
              setIsShowPrevButton(!e?.isBeginning)
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            pagination={{
              clickable: true
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="swiper_wrapper"
          >
            {largeBannerData?.map((data: TypedBanner, idx: number) => (
              <SwiperSlide
                key={`banner_${idx}`}
                onClick={()=>handleNavigate(data?.banner_meta)}
              >
                <img
                  src={data?.banner_image_url || imageDefault}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = imageDefault
                  }}
                  className="banner_content_item"
                  alt="banner"
                />
              </SwiperSlide>
            ))}
            {isShowNextButton && largeBannerData?.length > 1 && <ButtonNext />}
            {isShowPrevButton && <ButtonPrev />}
          </Swiper>
        </Box>
        {!helpers.isEmpty(smallBannerData) && (
          <Box
            display={'flex'}
            width={'100%'}
            flex={smallBannerData?.length > 1 ? 0.3 : 0.5}
            flexDirection={'column'}
            rowGap={theme => theme.spacing(1.5)}
          >
            {smallBannerData?.map((data, idx) => (
              <Box
                onClick={()=>handleNavigate(data?.banner_meta)}
                key={idx} className="banner_wrapper"
              >
                <img src={data?.banner_image_url} className="banner_content_item" alt="banner" />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </BannerContainer>
  )
}

export default Banner

const BannerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(1.25, 0),
  '& .button': {
    height: 'auto',
    position: 'absolute',
    top: '50%',
    transform: 'translate(50%, -50%)',
    zIndex: 1,
    borderRadius: theme.spacing(1.5),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`
  },
  '& .button_next': {
    right: theme.spacing(3.75)
  },
  '& .button_prev': {
    left: theme.spacing(-2)
  },
  '& .banner_wrapper': {
    display: 'grid',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: '44.44%',
    placeItems: 'center',
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .banner_content_item': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center',
    objectFit: 'cover'
  },
  '& .swiper_wrapper': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }
}))
