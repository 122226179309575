import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  CreateCommentPayload,
  GetListCategoryParams,
  GetListCategoryResponse,
  GetListTicketParams,
  GetListTicketResponse,
  TicketComment,
  TicketType
} from 'entities/support/ticket.type'
import { PaginationResponse } from 'entities/challenge/challenge.interface'
import { convertParamsToQueryString } from 'entities/challenge/store/challengeApi'
import { CreateTicketPayload } from '../support.validation'

const DEFAULT_ORDER_BY = 'ASC'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL + '/ticket',
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    return headers
  }
  // credentials: 'include'
})

const supportApi = createApi({
  baseQuery,
  reducerPath: 'supportApi',
  tagTypes: ['ListTicket', 'Comment', 'Ticket'],
  refetchOnMountOrArgChange: 30,
  endpoints: build => ({
    getListTicket: build.query<PaginationResponse<GetListTicketResponse>, GetListTicketParams>({
      query(params) {
        return `/list${convertParamsToQueryString({ ...params })}`
      },
      providesTags: ['ListTicket'],
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return endpointName
      },
      transformResponse: (response: GetListTicketResponse, meta) => {
        return {
          data: response,
          totalCount: Number(meta.response.headers.get('X-Total-Count'))
        }
      },
      merge: (currentCacheData, newData, { arg, baseQueryMeta }) => {
        if (currentCacheData.data && arg.page !== 1) {
          return {
            ...currentCacheData,
            ...newData,
            data: [...currentCacheData.data, ...newData.data]
          }
        }
        return newData
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),
    getDetailTicket: build.query<TicketType, string>({
      query(id: string) {
        return {
          url: `/detail/${id}`
        }
      },
      providesTags: ['Ticket']
    }),
    getCategories: build.query<GetListCategoryResponse, GetListCategoryParams>({
      query({ channel_id }) {
        return {
          url: 'list-category',
          params: {
            channel_id,
            public_status: 1,
            limit: 100,
            page:1
          }
        }
      }
    }),

    createNewTicket: build.mutation<TicketType, CreateTicketPayload>({
      query(payload) {
        return {
          url: '/create',
          method: 'POST',
          body: {
            post_language: 'vi',
            ...payload
          }
        }
      }
    }),

    getListComment: build.query<TicketComment[], { ticketId: string }>({
      query({ ticketId }) {
        return {
          url: '/list-comment',
          params: {
            ticket_id: ticketId,
            order_by: DEFAULT_ORDER_BY
          }
        }
      },
      providesTags: ['Comment']
    }),

    commentTicket: build.mutation<TicketComment, CreateCommentPayload>({
      query(payload) {
        return {
          url: '/create-comment',
          method: 'POST',
          body: {
            ...payload,
            ticket_id: payload.ticketId,
            attach_files: payload.attachFiles
          }
        }
      },
      invalidatesTags: ['Comment']
    }),
    updateTicket: build.mutation<TicketType, Partial<TicketType>>({
      query(payload) {
        return {
          url: '/update',
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: ['Ticket']
    })
  })
})

export const {
  useGetListTicketQuery,
  useGetCategoriesQuery,
  useGetDetailTicketQuery,
  useCreateNewTicketMutation,
  useCommentTicketMutation,
  useGetListCommentQuery,
  useUpdateTicketMutation
} = supportApi

export default supportApi
