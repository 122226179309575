//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Interface
import { TypedGift } from 'entities/gift/gift.interface'

//helpers
import helpers from 'helpers'

interface TypeBodyRedeem {
  _id?: string
  mission_data?: string
  gift_data?: string
  gift_coin?: string
  redeem_name?: string
  total_day?: string
  redeem_status?: string
}

export const initialState = {
  dataEditRedeem: null,
  actionsCheck: {
    type: '',
    isSuccess: false,
    statusCode: 0,
    dataRedeem: [],
    dataJoinPermission: [],
    dataMission: []
  }
}

export const createRedeem = createAsyncThunk(
  'mission/create_redeem',
  async (body: TypeBodyRedeem, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/redeem/create`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updateRedeem = createAsyncThunk(
  'mission/update_redeem',
  async (body: TypeBodyRedeem, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/redeem/update`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getListRedeem = createAsyncThunk(
  'mission/get_list_redeem',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/redeem/list`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createListRedeemJoinPermission = createAsyncThunk(
  'mission/create_list_redeem_permission_join',
  async (body: { redeem_id: string; user_id: string; channel_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/redeem/join-permission`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createGift = createAsyncThunk(
  'gift/create_gift',
  async (data: { gift_type?: string; name?: string; media_id?: string; price?: string }) => {
    return await axios.post<TypedGift>(`/gift/create-gift`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const dataMissionNotComplete = createAsyncThunk(
  'mission/data_mission_not_complete',
  async (data: Array<any>) => {
    return data;
  }
)
export const getListLevel = createAsyncThunk(
  'mission/get_list_level',
  async (params: { channel_id?: string }, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get(`/channel/list-level`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getListGift = createAsyncThunk(
  'mission/get_list_gift',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get(`/gift/list-gift`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)
export const deleteRedeem = createAsyncThunk(
  'mission/delete_redeem',
  async (redeem_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/redeem/delete/${redeem_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const MISSION_REDUCER = createEntitySlice({
  name: 'mission',
  initialState: initialState as any,
  reducers: {
    clearInitialState: () => initialState,
    setDataEditRedeem: (state, action) => {
      state.dataEditRedeem = action.payload
    },
    setActionsCheck: (state, action) => {
      state.actionsCheck = {
        type: action.payload.type,
        isSuccess: action.payload.isSuccess,
        statusCode: action.payload.statusCode
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getListRedeem.fulfilled, (state, action) => {
        state.dataRedeem = action.payload.data;
      })
      .addCase(getListRedeem.rejected, (state, action) => {
        state.errorMessage = action.payload;
      })
      .addCase(createListRedeemJoinPermission.fulfilled, (state, action) => {
        state.dataJoinPermission = action.payload.data;
      })
      .addCase(createListRedeemJoinPermission.rejected, (state, action) => {
        state.errorMessage = action.payload;
      })
      .addCase(dataMissionNotComplete.fulfilled, (state, action) => {
        state.dataMission = action.payload;
      })
  }
})

export const { clearInitialState, setActionsCheck, setDataEditRedeem } = MISSION_REDUCER.actions

export default MISSION_REDUCER.reducer
