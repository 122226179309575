import { Box, Button, styled } from "@mui/material"
import __ from "languages/index"
import { notEmpty, numericString, useField, useForm } from "@shopify/react-form";
import { validateLabel } from "entities/support/utils";
import ShippingUnitsTable from "./shippingunits.table.stock";
import ShippingUnitsInfomation from "./shippingunits.infomation.stock";
import { useEffect, useState } from "react";
import { TypedLogisticService, TypedStock } from "../interface";
import { getStockById, updateStock } from "../store/shop.store.reducer";
import { useAppDispatch } from "config/store";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import __helpers from "helpers/index";
import { omit } from "lodash";
import { EnumTypeToast, useToast } from "hooks/useToast";
import helpers from 'helpers'

const ShippingUnit = () => {
    const dispatch = useAppDispatch();
    const [dataStock, setDataStock] = useState<TypedStock>({});
    const { stock_id } = useParams();
    const toast = useToast()
    const useFields = {
        manager: useField<string>({
            value: '',
            validates: [
            ]
        }),

        stock_address_city: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_city")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_city'))?.required

                    }
                }
            ]
        }),
        stock_address_district: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_district")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_district'))?.required

                    }
                }
            ]
        }),
        stock_address_ward: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_ward")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_ward'))?.required

                    }
                }
            ]
        }),
        stock_address: useField<string>({
            value: '',
            validates: [
            ]
        }),
        stock_desciption: useField<string>({
            value: '',
            validates: [
            ]
        }),
        hotline: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("payment_edit_modal_input_number_phone")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('payment_edit_modal_input_number_phone'))?.required
                    }
                    if (inputVal && !helpers.isPhoneNumber(inputVal)) {
                        return `${__('validate_phone')}`
                    }
                }
            ]
        }),
    }

    const { fields, submit } = useForm({
        fields: useFields,
        async onSubmit(values) {
            try {

                const { stock_id, channel_id, createdAt, stock_slug, stock_count, ...rest } = dataStock;
                dispatch(updateStock({
                    _id: stock_id,
                    params: {
                        ...rest,
                        // stock_settings: values
                        stock_address: values.stock_address,
                        manager: values.manager,
                        hotline: values.hotline,
                        stock_address_city: values.stock_address_city,
                        stock_address_district: values.stock_address_district,
                        stock_address_ward: values.stock_address_ward,
                        logistic_service_to_stock: dataStock.logistic_service_to_stock.map(res => ({ ID: res.ID, logistic_service_status: res.logistic_service_status, is_default_service: res.is_default_service })),
                    }
                }))
                    .unwrap()
                    .then((res) => {
                        setDataStock(res?.data)
                        toast.show({
                            content: `${__("edit_stockroom_success")}`,
                            type: EnumTypeToast.Success
                        })
                    })
                    .catch((e: AxiosError) => {
                        console.log('createStock', e);
                        toast.show({
                            content: `${__("edit_stockroom_failed")}`,
                            type: EnumTypeToast.Error
                        })
                    })
            } catch (e: any) {
                console.error(` createStockSubmit`, e)
                const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
                const field = e?.response?.data?.errorKey ?? 'base'
                return { status: 'fail', errors: [{ field, message }] }
            }
            return { status: 'success' }
        }
    })

    useEffect(() => {
        dispatch(getStockById(stock_id))
            .unwrap()
            .then((res) => {
                setDataStock(res.data);
                Object.keys(useFields).forEach((key) => {
                    fields[key].onChange(res.data[key])
                })

            }).catch((e: AxiosError) => {
                console.log('error', e)
            })
    }, [])



    const actionCheckTableLogistic = (dataLogistices: TypedLogisticService[]) => {
        setDataStock({
            ...dataStock,
            logistic_service_to_stock: dataLogistices
        })
    }


    return (
        <>
            <ShippingUnitContainer id='shippping'>
                {Object.values(dataStock).length > 0 &&
                    <ShippingUnitsTable
                        dataTable={dataStock.logistic_service_to_stock}
                        actionCheckTableLogistic={actionCheckTableLogistic}
                    />
                }

                <ShippingUnitsInfomation fields={fields} />

                {/* <ConfigFee fields={fields} /> */}

                <Box className="btn_save">
                    <Button onClick={submit}>
                        {__('product_shipping_units_btn_save')}
                    </Button>
                </Box>
            </ShippingUnitContainer>
        </>
    )
}

export default ShippingUnit

const ShippingUnitContainer = styled(Box)(({ theme }) => ({

    '& .config_fee': {
        padding: theme.spacing(4.5, 3),
        marginTop: theme.spacing(3),

        '& .box_select': {
            marginTop: theme.spacing(5),
            '& fieldset': {
                border: 'none'
            },
        }
    },
    '& .btn_save': {
        marginTop: theme.spacing(3),
        textAlign: 'end'
    }
}))

