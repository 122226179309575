import React from 'react'
import { Box, Paper, Stack, styled, SxProps, Typography } from '@mui/material'
import ProductSpecifications from './ProductSpecifications'
import ProductDetailContainer from './ProductDetailContainer'
import RatingSpace from './RatingSpace'
import { CurrentTheme } from '../../../../styles/themes'
import { TypedProduct } from 'entities/shop/interface'
import helpers from 'helpers'
import __ from 'languages/index'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { getProductOfToday } from 'entities/shop/service/product.service'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import CardItem from 'entities/shop/components/shop.cardItem'
import { EmptyIcon } from 'icons'

type Props = {
  product: TypedProduct;
  clickViewImages: (index: number, images: string[]) => void

}
const ProductDetail = ({ product, clickViewImages }: Props) => {

  const [activeTab, setActiveTab] = React.useState<string>('product-information')
  const handleScrollToView = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      setActiveTab(id)
      element.scrollIntoView({ behavior: 'smooth', block : 'center',inline: "nearest" })
    }
  }

  const { data, hasMore, isRefreshing, loadMore, refresh } =
  useInfiniteScroll<TypedProduct>(
    {
      query: product?.product_to_category?.category_id,
      keyAttribute: 'product_id',
    },
    getProductOfToday,
    true,
    )

  return (
    <Stack position="relative" gap={3} mt={3}>
      <Paper elevation={6} sx={headerStyled}>
        <Stack direction="row" gap={2}>
          <TitleTab
            isActive={activeTab === 'product-information'}
            onClick={() => handleScrollToView('product-information')}
          >
            Thông tin sản phẩm
          </TitleTab>
          <TitleTab
            isActive={activeTab === 'product-specifications'}
            onClick={() => handleScrollToView('product-specifications')}
          >
            Thông số sản phẩm
          </TitleTab>
          <TitleTab
            isActive={activeTab === 'rating-space'}
            onClick={() => handleScrollToView('rating-space')}
          >
            Đánh giá
          </TitleTab>

          <TitleTab
            isActive={activeTab === 'product-suggestions'}
            onClick={() => handleScrollToView('product-suggestions')}
          >
            Sản phẩm gợi ý
          </TitleTab>
        </Stack>
      </Paper>

      <Box id={'product-information'}>
        <ProductDetailContainer label={'Thông tin sản phẩm'}>
          {helpers.isEmpty(product?.product_content) ? (
            <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
            <EmptyIcon 
              sx={{
                  width: theme => theme.spacing(18.75),
                  height: theme => theme.spacing(18.75)
                }} />
          </Box>
          ) : (
            <Box dangerouslySetInnerHTML={{ __html: product.product_content }}/>
          )}
        </ProductDetailContainer>
      </Box>
      <Box id={'product-specifications'}>
        <ProductSpecifications product_specifications={product.product_specifications} />
      </Box>
      <Box id={'rating-space'}>
        <RatingSpace clickViewImages={clickViewImages}  />
      </Box>

       {/*Start Product Suggestion */}
        <Stack id='product-suggestions' rowGap={theme=>theme.spacing(3)}>
          <Typography textTransform={'none'} variant="h6">
            {__('product_suggestions')}
          </Typography>
          {helpers.isEmpty(data) ? (
            <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
              <EmptyIcon 
                sx={{
                    width: theme => theme.spacing(18.75),
                    height: theme => theme.spacing(18.75)
                  }} />
            </Box>
          ) :(
              <InfiniteScrollContainer
                isRefreshing={isRefreshing}
                dataLength={data.length}
                next={loadMore}
                hasMore={hasMore}
                refreshFunction={refresh}
                showEndMessage={false}
                pullDownToRefresh={false}
              >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
                  gap: theme => theme.spacing(3)
                }}
              >
                {data?.map((data: TypedProduct, idx: number) => (
                  <React.Fragment key={`item_${idx}`}>
                    <CardItem productData={data} />
                  </React.Fragment>
                ))}
              </Box>
              </InfiniteScrollContainer>
              )}
        </Stack>
        {/*End Product Suggestions*/}
    </Stack>
  )
}

export default ProductDetail

const headerStyled: SxProps<CurrentTheme> = {
  position: 'sticky',
  top: theme => theme.spacing(10),
  padding: 3,
  zIndex: 1000
}

const TitleTab = styled(Typography, { shouldForwardProp: propName => propName !== 'isActive' })<{
  isActive: boolean
}>(({ theme, isActive }) => ({
  color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
  cursor: 'pointer',
  fontWeight: 600
}))
