import React from 'react'
import './media/css/index.scss'
import {Row} from 'react-bootstrap'
import ExtensionElement from './components/extension.element'
import ExtensionsSkeleton from 'entities/extentions/components/extension.skeleton'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll";
import {getListExtension} from "entities/extentions/services/courses.service";
import TypedPlan from "../../interfaces/plan.interface";
import BigLayout from "../../layouts/bigLayout";

/**
 * Extensions page ...
 * @returns
 */
export default function ExtensionsList() {


  const {data, isRefreshing} = useInfiniteScroll<TypedPlan>({
    amount_of_day: 30,
    limit: 100,
    order_by: 'DESC',
    service_type: "extension"
  }, getListExtension)

  return (
    <BigLayout>
      <div id="extension_wrap">
        <_Helmet title='extension_list_title'/>
        <div id="hero_banner" className="text-center bg-image">
          <div className="mask justify-content-center align-items-center">
            <section className="position-relative  text-center">
              <div className="container position-relative">
                <div className=" align-items-center">
                  <div className=" position-relative  ">
                    <h6 className="h6 mt-2 text-white extension-sub-text">
                      {__('extension_list_sub_title')}
                    </h6>
                    <h1 className=" h3 mb-4 mt-3 display-6 text-white extension-text-header">
                      {__('extension_list_text')}
                      <br/>
                      <span className="position-relative z-index-9">
                      {__('extension_list_sub_text')}
                    </span>
                    </h1>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div id="extension_body" className="container">
          <div className="mb-4 col-lg-12 col-md-12 col-sm-12">
            <div className="d-lg-flex justify-content-between align-items-center row">
              <div className="col-xl-9 col-lg-8 col-md-6">
                <h6 className="mb-3 mb-lg-0">
                  {__('extension_list_show')} {data?.length} {__('extension_list_result')}
                </h6>
              </div>
              {/*<div className="d-inline-flex col-xl-3 col-lg-4 col-md-6 ">*/}
              {/*  <Form.Select*/}
              {/*    aria-label=""*/}
              {/*    onChange={handleOnSubmitFilterForm}*/}
              {/*    style={{ cursor: 'pointer' }}*/}
              {/*  >*/}
              {/*    <option key="key1" value="value1">*/}
              {/*      {__('extension_sort_by_newest_first')}*/}
              {/*    </option>*/}
              {/*    <option key="key2" value="value2">*/}
              {/*      {__('extension_sort_by_oldest_first')}*/}
              {/*    </option>*/}
              {/*  </Form.Select>*/}
              {/*</div>*/}
            </div>
          </div>
          <Row>
            {isRefreshing ? (
              <ExtensionsSkeleton/>
            ) : (
              <>
                {data?.map((plan: TypedPlan, index: React.Key) => (
                  <ExtensionElement key={index} plan={plan}/>
                ))}
              </>
            )}
          </Row>
        </div>

        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>

        <footer id="extension_footer" className="container text-muted small">
          {__('extension_text_footer')}
        </footer>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </BigLayout>
  )
}
