import React from "react";
import { Dropdown as BDropdown, DropdownProps } from "react-bootstrap";
import BDropdownToggle from "react-bootstrap/esm/DropdownToggle";

type CustomToogleProps = React.ComponentPropsWithRef<any>;
type CustomMenuProps = React.ComponentPropsWithRef<any> & { children: React.ReactNode };

type Props<T> = {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  DropdownToggle?: CustomToogleProps;
  DropdownMenu?: CustomMenuProps;
} & Omit<DropdownProps, "children">;

const Dropdown = <T,>({ DropdownMenu, DropdownToggle, items, renderItem, ...rest }: Props<T>) => {
  const _renderToggle = () => {
    if (DropdownToggle) return <BDropdownToggle as={DropdownToggle}>{rest.title}</BDropdownToggle>;
    return <BDropdownToggle>{rest.title}</BDropdownToggle>;
  };

  const _renderMenu = () => {
    if (DropdownMenu) {
      return DropdownMenu;
    }
    return (
      <BDropdown.Menu>
        {items.map((item) =>
          // <BDropdown.Item>{renderItem(item)}</BDropdown.Item>
          renderItem(item)
        )}
      </BDropdown.Menu>
    );
  };

  return (
    <BDropdown {...rest}>
      {_renderToggle()}
      {_renderMenu()}
    </BDropdown>
  );
};

export default Dropdown;

