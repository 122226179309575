import axios, { AxiosResponse } from 'axios'
import { EnumTypeInputSound } from 'entities/livestream/hooks/livestream.admin.hook'
import { TypedLivestream } from '../livestream.interface'
// const liveStreamApi = axios.create({
//   baseURL: process.env.REACT_APP_AJAX_URL + '/livestream'
// })

export function setupStreamFromFile(
  idVideo: string,
  srcVideo: string,
  setAudioTrack: (audioTrack: MediaStreamTrack, type: EnumTypeInputSound) => void
) {
  const videoElement = document.getElementById(idVideo) as any
  videoElement.srcObject = null
  videoElement.src = srcVideo

  videoElement.load()

  const sUsrAg = navigator.userAgent
  let mediaStream: MediaStream

  videoElement.onplay = () => {
    if (sUsrAg.indexOf('Firefox') > -1) {
      console.log('Firefox')
      mediaStream = videoElement.mozCaptureStream(24)
    } else {
      console.log('Other')
      mediaStream = videoElement.captureStream(24)
    }

    if (mediaStream.getAudioTracks().length > 0) {
      console.log(mediaStream.getAudioTracks(), 'stream.getAudioTracks file')
      setAudioTrack(mediaStream.getAudioTracks()?.[0], EnumTypeInputSound.Sound)
    }
  }
}

export function setupStreamFromCamera(
  idVideo: string,
  srcVideo: string,
  setAudioTrack: (audioTrack: MediaStreamTrack, type: EnumTypeInputSound) => void
) {
  const videoElement = document.getElementById(idVideo) as any
  videoElement.srcObject = null
  videoElement.src = srcVideo
  videoElement.muted = false
  videoElement.load()

  const sUsrAg = navigator.userAgent
  let mediaStream: MediaStream

  videoElement.onplay = () => {
    if (sUsrAg.indexOf('Firefox') > -1) {
      console.log('Firefox')
      mediaStream = videoElement.mozCaptureStream(24)
    } else {
      console.log('Other')
      mediaStream = videoElement.captureStream(24)
    }

    if (mediaStream.getAudioTracks().length > 0) {
      console.log(mediaStream.getAudioTracks, 'stream.getAudioTracks file')
      setAudioTrack(mediaStream.getAudioTracks()?.[0], EnumTypeInputSound.Sound)
    }
  }
}

const getById = (id: string) => {
  return axios.get<void, AxiosResponse<TypedLivestream>>(`livestream/detail/${id}`)
}

export default {
  getById
}
