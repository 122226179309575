import { useCallback, useRef } from 'react'

export const useInfinite = (hasNextPage: boolean, onLoadMore: () => void) => {
  const intObserver = useRef<any>()
  const lastItemRef = useCallback(
    (item: HTMLElement) => {
      if (intObserver.current) intObserver.current.disconnect()
      intObserver.current = new IntersectionObserver(list => {
        if (list[0].isIntersecting && hasNextPage) {
          onLoadMore()
        }
      })
      if (item) intObserver.current.observe(item)
    },
    [hasNextPage]
  )

  return { ref: lastItemRef }
}
