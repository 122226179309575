import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  createTheme,
  Modal,
  Stack,
  Step,
  StepLabel,
  Stepper,
  styled,
  ThemeProvider,
  Typography
} from '@mui/material'
import PreviewTemplate from './components/PreviewTemplate'
import BasicInfoFormStep from './components/BasicInfoFormStep'
import ContactFormStep from './components/ContactFormStep'
import PaymentStep from 'entities/channel/components/PaymentStep'
import Container from 'react-bootstrap/Container'
import FormStepContainer from 'entities/channel/components/FormStepContainer'
import { lightPalette } from '../../styles/themes/palettes/defaultPalete'
import { colorGenerator } from '../../styles/utils/colorPaletteGenerator'
import { getThemeByMode } from '../../styles/themes'
import previewBackground from 'media/images/img-preview-background.png'
import { checkCreateChannel, createChannel, getDraft, getPlans, updateChannel } from './service'
import TypedChannel from '../../interfaces/channel.interface'
import __ from 'languages/index'
import { FormProvider, useForm } from 'react-hook-form'
import {
  BasicInfoPayload,
  BasicInfoSchema
} from 'entities/channel/components/validation/basicInfoForm.validation'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreateChannelContactSchema } from 'entities/channel/components/validation/contactForm.vaidation'
import TypedPlan from '../../interfaces/plan.interface'
import { Link } from 'react-router-dom'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { useNavigate } from 'react-router'
import RenderView from 'components/RenderView'
import { EnumTypeToast, useToast } from '../../hooks/useToast'
import { createOrder } from 'entities/orders/service'

const defaultColors = ['#DC3545', '#1677FF', '#FAAD14', '#722ED1', '#13C2C2']

const createChannelStepsConfigs = [
  {
    label: __('label_channel_info'),
    isGlobal: false
  },
  {
    label: __('label_contact_info'),
    isGlobal: true
  },
  {
    label: __('label_payment'),
    isGlobal: false
  }
]

const ChannelCreate = () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [draft, setDraft] = useState<Partial<TypedChannel>>(null)
  const [plans, setPlans] = useState<TypedPlan[]>([])
  const [fetchingPlans, setFetchingPlans] = useState(false)
  const [selectedColor, setSelectedColor] = React.useState<string>(defaultColors[1])
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const [checkingCreatedChannel, setCheckingCreatedChannel] = useState<boolean>(false)
  const [isGlobal, setIsGlobal] = useState<boolean>(false)

  const navigate = useNavigate()
  const toast = useToast()

  const stepConfigs = useMemo(() => {
    return createChannelStepsConfigs.map(config => ({
      ...config,
      show: config.isGlobal ? !isGlobal : true
    }))
  }, [isGlobal])

  useEffect(() => {
    const checkCreatedChannel = async () => {
      try {
        setCheckingCreatedChannel(true)
        const res = await checkCreateChannel()
        const { isCreated, isGlobal } = res.data
        if (isCreated) {
          navigate('/orders')
        }
        setIsGlobal(isGlobal)
      } catch (err) {
        console.log(err)
      } finally {
        setCheckingCreatedChannel(false)
      }
    }

    checkCreatedChannel()
  }, [])
  const handleStep = async (step: number) => {
    setActiveStep(step)
    const prePaymentStep = stepConfigs.filter(config => config.show).length - 1
    if (step == prePaymentStep) {
      try {
        setFetchingPlans(true)
        const res = await getPlans()
        setPlans(res)
      } catch (err) {
        console.error('fetch plan err', err)
      } finally {
        setFetchingPlans(false)
      }
    }
  }

  const handleSelectColor = (value: string) => {
    setSelectedColor(value)
  }

  const tempTheme = () => ({
    ...lightPalette,
    ...colorGenerator(selectedColor, 'primary')
  })

  const templateTheme = createTheme({
    ...getThemeByMode('light', {
      lightPalette: tempTheme()
    }),
    spacing: 4,
    typography: {
      fontSize: 8
    }
  })

  const handleSubmitBasicInfoForm = async value => {
    const payload = {
      trash_status: 'trash',
      short_description: draft?.short_description || '',
      mentor_name: draft?.mentor_name || '',
      mentor_address: draft?.mentor_address || '',
      mentor_income: draft?.mentor_income || '',
      mentor_number_member: draft?.mentor_number_member || '',
      mentor_category: draft?.mentor_category || '',
      mentor_target: draft?.mentor_target || '',
      ...value
    }
    try {
      //TODO: check create domain
      // await checkCreateDomain(value?.sub_domain)
      if (draft) {
        const { data } = await updateChannel(payload)
        setDraft(data)
      } else {
        const { data } = await createChannel(payload)
        setDraft(data)
      }

      handleStep(activeStep + 1)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmitContactForm = async value => {
    await updateChannel(value)
    handleStep(activeStep + 1)
  }

  const handleSubmitPaymentForm = async (plan: TypedPlan) => {
    const isFreePlan = plan?.price === 0
    try {
      const res = await createOrder({
        plan_id: plan._id,
        amount_of_package: '1',
        channel_id: draft._id,
        payment_method: isFreePlan ? 'transfer' : 'vn_pay',
        status: 'pending'
      })
      if (isFreePlan) {
        setOpenSuccessModal(true)
      } else {
        const redirectUrl = res?.data?.redirect_url
        window.location.href = redirectUrl
      }
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        toast.show({
          type: EnumTypeToast.Error,
          content: __(message)
        })
      }
    }
  }

  const basicMethod = useForm<BasicInfoPayload>({
    resolver: zodResolver(BasicInfoSchema),
    defaultValues: {
      name: draft?.name,
      description: draft?.description,
      sub_domain: draft?.sub_domain,
      slogan: draft?.slogan
    }
  })

  const contactMethod = useForm({
    resolver: zodResolver(CreateChannelContactSchema)
  })

  useEffect(() => {
    ;(async () => {
      const res = await getDraft()
      setDraft(res.data)
    })()
  }, [])


  const renderPage = () => {
    return (
      <LayoutContainer>
        <ThemeProvider theme={templateTheme}>
          <Box
            flex={1}
            sx={{
              background: `url(${previewBackground})  lightgray -520.94px 0px / 202.553% 100% no-repeat`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              minHeight: '100vh',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <PreviewTemplate
              channelName={basicMethod.watch('name')}
              slogan={basicMethod.watch('slogan')}
              domain={basicMethod.watch('sub_domain')}
            />
          </Box>
        </ThemeProvider>
        <Box
          flex={1}
          sx={{
            backgroundColor: theme => theme.palette.background.paper,
            height: '100%',
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <FormContainer width={'100%'}>
            <Box width={'100%'}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {stepConfigs
                  .filter(config => config.show)
                  .map(({ label }) => (
                    <Step key={label}>
                      <StepLabel
                        sx={{
                          '& .MuiStepLabel-label::first-letter': {
                            textTransform: 'uppercase'
                          }
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
              </Stepper>
            </Box>
            <FormStepContainer mt={5}>
              <Box
                sx={{
                  display: activeStep == 0 ? 'unset' : 'none'
                }}
              >
                <FormProvider {...basicMethod}>
                  <BasicInfoFormStep
                    draft={draft}
                    selectedColor={selectedColor}
                    onSelectPrimaryColor={handleSelectColor}
                    onSubmit={value => handleSubmitBasicInfoForm(value)}
                  />
                </FormProvider>
              </Box>
              {!isGlobal && (
                <Box
                  sx={{
                    display: activeStep == 1 ? 'unset' : 'none'
                  }}
                >
                  <FormProvider {...contactMethod}>
                    <ContactFormStep
                      draft={draft}
                      onSubmit={handleSubmitContactForm}
                      onBack={() => handleStep(activeStep - 1)}
                    />
                  </FormProvider>
                </Box>
              )}
              <Box
                sx={{
                  display:
                    activeStep == stepConfigs.filter(config => config.show).length - 1
                      ? 'unset'
                      : 'none'
                }}
              >
                <PaymentStep
                  plans={plans}
                  onSubmit={handleSubmitPaymentForm}
                  onBack={() => handleStep(activeStep - 1)}
                  fetchingPlans={fetchingPlans}
                />
              </Box>
            </FormStepContainer>
          </FormContainer>
        </Box>
        <Modal open={openSuccessModal}>
          <ModalContainer
            sx={{
              p: theme => theme.spacing(2, 4)
            }}
          >
            <ModalHeader>
              <Typography variant={'h6'} className={'title'}>
                {__('txt_create_channel_success')}
              </Typography>
            </ModalHeader>
            <ModalBody>
              <Typography>{__('txt_create_channel_success_description')}</Typography>
            </ModalBody>
            <ModalFooter>
              <Button>
                <Typography
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    '& * ': {
                      color: 'inherit',
                      textDecoration: 'none',
                      textTransform: 'capitalize'
                    }
                  }}
                >
                  <Link to={'/orders'}>{__('btn_goto_subscriptions')}</Link>
                </Typography>
              </Button>
            </ModalFooter>
          </ModalContainer>
        </Modal>
      </LayoutContainer>
    )
  }

  return <RenderView view={renderPage()} isLoading={checkingCreatedChannel} />
}

export default ChannelCreate

const LayoutContainer = styled(Container)(({ theme }) => ({
  minHeight: '100vh',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '1920px',
  margin: '0 auto'
}))

const FormContainer = styled(Stack)(({ theme }) => ({
  maxWidth: theme.spacing(76),
  margin: '0 auto',
  alignItems: 'flex-start'
}))
