import React, { useEffect } from 'react'
import {Box, IconButton, Modal, ModalProps, styled, SxProps, Theme, Typography} from '@mui/material'
import {CloseIcon} from 'icons'
import AddCommentForm from './AddCommentForm'
import {TypedRequest} from '../../../interfaces/request.interface'
import NewsFeedPost from "entities/homeNew/components/NewsFeedPost";
import PostOptions from "entities/homeNew/components/PostOptions";
import PostComment from "entities/homeNew/components/PostComment";

interface TypedDetailPostModalProps {
  modalProps: Omit<
    ModalProps & {
    onClose?: () => void
  },
    'children'
  >
  post: TypedRequest
  is_user_logged_in: boolean,
  isLike: boolean,
  setIsLike: React.Dispatch<any>

}

const DetailPostModal = ({modalProps,is_user_logged_in, isLike, setIsLike, post}: TypedDetailPostModalProps) => {
  const {onClose, ...rest} = modalProps
  const inputRef = React.useRef<HTMLInputElement>(null)
  const handleClickCommentButton = () => {
    inputRef.current?.focus()
  }

  return (
    <Modal {...rest} sx={{zIndex: 998}} onClose={onClose}>
      <ModalContainer>
        <ModalHeader>
          <Typography
            variant="subtitle1"
            component="h6"
            fontWeight="600"
            className="modal-title"
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
          >
            {post?.post_title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </ModalHeader>

        {post && (
          <NewsFeedPost
            post={post}
            isLike={isLike}
            setIsLike={setIsLike}
            variant={"flatten"}
            is_user_logged_in={is_user_logged_in}
            onClickCommentButton={handleClickCommentButton}
            PostOptionsComponent={props => (
              <PostOptions
                post={post}
                // options={[
                //   ...mergeArrayObjectsByKey(postOptionConfigs, [...postOptions(postDetail)], 'key')
                // ]}
                {...props}
              />
            )}
          />
        )}
        <Box sx={postCommentContainerStyled}>
          <PostComment post={post}/>
        </Box>

        {post?.is_comment == 1 && (
          <AddCommentForm className="form-add-comment" ref={inputRef} postId={post?._id}/>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default DetailPostModal

const ModalHeader = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  borderStartStartRadius: theme.spacing(1.5),
  borderEndEndRadius: 0,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'sticky',
  top: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '& .modal-title': {
    textAlign: 'center',
    width: '100%'
  },

  '& .modal-close-btn': {
    marginLeft: 'auto'
  }
}))

const ModalContainer = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1.5),
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  overflowY: 'auto',
  width: '80%',
  maxWidth: theme.spacing(95.75),
  margin: '0 auto',

  '& .form-add-comment': {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  }
}))

const postCommentContainerStyled: SxProps<Theme> = {
  padding: theme => `${theme.spacing(2)} ${theme.spacing(3)}`
}

