import { Box, Button, Paper, styled, Typography } from "@mui/material";
import __, { ___ } from "languages/index";
import TextFieldMUI from 'components/TextFieldMUI'
import { notEmpty, useField } from "@shopify/react-form";
import { validateLabel } from "entities/support/utils";
import ConfigRoadItem from "./shop.admin.configRoad.item";
import { useEffect, useState } from "react";
import { getLogistices } from "../store/shop.store.reducer";
import { IRootState, useAppDispatch } from "config/store";
import { LogicticModule } from "../data/shop.logictic.enum";
import { useSelector } from "react-redux";
import { TypedLogistic } from "../interface";
import { AxiosError } from "axios";
import __helpers from "helpers/index";

const ConfigRoad = () => {
    const dispatch = useAppDispatch();
    const brandRoads = [
        // {
        //     name: 'ghtk',
        //     fields: {
        //         logistic_token: useField<string>({
        //             value: '',
        //             validates: [
        //                 notEmpty(validateLabel(`${__("token")}`)?.required),
        //                 inputVal => {
        //                     if (!inputVal) {
        //                         return validateLabel(__('token'))?.required

        //                     }
        //                 }
        //             ]
        //         }),
        //     },
        //     payload: {
        //         logistic_name: "Giao hàng tiết kiệm",
        //         logistic_delivery_method: "cash_on_delivery",
        //         module: LogicticModule.GHTK
        //     },
        //     placeholder: ['token'],
        // },
        // {
        //     name: 'ghn',
        //     fields: {
        //         logistic_shop_id: useField<string>({
        //             value: '',
        //             validates: [
        //                 notEmpty(validateLabel(`${__("shop_id")}`)?.required),
        //                 inputVal => {
        //                     if (!inputVal) {
        //                         return validateLabel(__('shop_id'))?.required

        //                     }
        //                 }
        //             ]
        //         }),
        //         logistic_phonenumber: useField<string>({
        //             value: '',
        //             validates: [
        //                 notEmpty(validateLabel(`${__("phone_shop")}`)?.required),
        //                 inputVal => {
        //                     if (!inputVal) {
        //                         return validateLabel(__('phone_shop'))?.required

        //                     }

        //                     if (!__helpers.isPhoneNumber(inputVal)) {
        //                         return `${__('validate_phone')}`
        //                     }
        //                 },
        //             ]
        //         })
        //     },
        //     payload: {
        //         logistic_name: "Giao hàng nhanh",
        //         logistic_delivery_method: "cash_on_delivery",
        //         module: LogicticModule.GHN
        //     },
        //     placeholder: ['shop_id', 'phone_shop']
        // },
        {
            name: 'viettel',
            fields: {
                logistic_username: useField<string>({
                    value: '',
                    validates: [
                        notEmpty(validateLabel(`${__("user_name")}`)?.required),
                        inputVal => {
                            if (!inputVal) {
                                return validateLabel(__('user_name'))?.required

                            }
                        }
                    ]
                }),
                logistic_password: useField<string>({
                    value: '',
                    validates: [
                        notEmpty(validateLabel(`${__("login_password_label")}`)?.required),
                        inputVal => {
                            if (!inputVal) {
                                return validateLabel(__('login_password_label'))?.required

                            }
                        }
                    ]
                })
            },
            payload: {
                logistic_name: "Viettel Post",
                logistic_delivery_method: "cash_on_delivery",
                module: LogicticModule.VP

            },
            placeholder: ['user_name', 'login_password_label'],
        },
        // {
        //     name: 'aha',
        //     fields: {
        //         logistic_username: useField<string>({
        //             value: '',
        //             validates: [
        //                 notEmpty(validateLabel(`${__("user_name")}`)?.required),
        //                 inputVal => {
        //                     if (!inputVal) {
        //                         return validateLabel(__('user_name'))?.required

        //                     }
        //                 }
        //             ]
        //         }),
        //         logistic_phonenumber: useField<string>({
        //             value: '',
        //             validates: [
        //                 notEmpty(validateLabel(`${__("validate_code_telephone_number")}`)?.required),
        //                 inputVal => {
        //                     if (!inputVal) {
        //                         return validateLabel(__('validate_code_telephone_number'))?.required

        //                     }

        //                     if (!__helpers.isPhoneNumber(inputVal)) {
        //                         return `${__('validate_phone')}`
        //                     }
        //                 }
        //             ]
        //         })
        //     },
        //     payload: {
        //         logistic_name: "AHAmove",
        //         logistic_delivery_method: "cash_on_delivery",
        //         module: LogicticModule.GHN

        //     },
        //     placeholder: ['user_name', 'validate_code_telephone_number'],
        // }
    ]
    const [dataLogistices, setDataLogictices] = useState<TypedLogistic[]>([])

    useEffect(() => {
        dispatch(getLogistices())
            .unwrap()
            .then((res) => {
                setDataLogictices(res.data as TypedLogistic[])
            })
            .catch((e: AxiosError) => {
                console.error('getStockById', e)
            })
    }, [])
    return (
        <>
            {!__helpers.isEmpty(dataLogistices) && brandRoads.map((item, index) => {
                const data = dataLogistices.find(i => i.module == item.payload.module) || null

                return (
                    <ConfigRoadItem data={data} key={index} fieldNames={Object.keys(item.fields)} {...item} />
                )
            })}
        </>
    )
}

export default ConfigRoad;