import { createTheme, PaletteMode } from '@mui/material'
import { darkThemeOptions, getThemeOptionsByMode, lightThemeOptions } from 'styles/utils/theme'

export const getThemeByMode = (
  mode: PaletteMode,
  palettes: {
    lightPalette?: typeof lightThemeOptions.palette
    darkPalette?: typeof darkThemeOptions.palette
  } = { lightPalette: lightThemeOptions.palette, darkPalette: darkThemeOptions.palette },
  ...rest: object[]
) => createTheme({ ...getThemeOptionsByMode(mode, palettes), ...rest })

export type CurrentTheme = ReturnType<typeof getThemeByMode>
