import React, {ReactElement, useEffect, useState} from 'react'
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Image,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  OverlayTrigger,
  Row,
  Spinner,
  Stack,
  Tooltip
} from 'react-bootstrap'
import useMultiForm from '../../hooks/useMultiForm'
import './media/multiform-progress.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUpRightFromSquare, faCheck, faCircleQuestion, faGlobe} from '@fortawesome/free-solid-svg-icons'
import InputGroupText from 'react-bootstrap/InputGroupText'
import {useForm} from 'react-hook-form'
import {AddRootDomainPayload, AddRootDomainSchema} from 'entities/domain/add-domain.validation'
import {zodResolver} from '@hookform/resolvers/zod'
import {FieldErrors} from 'react-hook-form/dist/types/errors'
import {faCircle, faCircleCheck, faCircleXmark, faCopy} from '@fortawesome/free-regular-svg-icons'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {
  useCreateDomainMutation,
  useCreateFreeDomainMutation,
  useGetChannelInfoQuery,
  useGetSubscriptionsByDomainQuery
} from './store/domainApi'
import {useSelector} from 'react-redux'
import {getChannelId, getUserId} from 'store/user.store.reducer'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import CloudFlareLogo from './media/icon/CF_logomark.svg'
import IconButton from 'components/IconButton'
import {formatString} from 'entities/support/utils'
import {Link} from 'react-router-dom'
import __ from 'languages/index'
import BigLayout from "../../layouts/bigLayout";

const sleep = (delay: number) => {
  return new Promise(resolve => setTimeout(resolve, delay))
}

const getRootDomain = (domain: string) => {
  if (!domain) return ''
  const splitDomain = domain?.split('.')
  return splitDomain[0]?.replace(/^https?\:\/\//i, '')
}

const freeDomainFormat = (domain: string) => formatString('https://%s.gamifa.vn', domain)

const AddDomain = () => {
  const userId = useSelector(getUserId)
  const channelId = useSelector(getChannelId)
  const [createDomain, {}] = useCreateDomainMutation()
  const [createFreeDomain, {}] = useCreateFreeDomainMutation()
  const { data } = useGetSubscriptionsByDomainQuery({ id: userId })
  const { data: channelData } = useGetChannelInfoQuery()

  const isFree = !Boolean(data)
  const forms = !isFree
    ? [SetupDomainNameForm, ConfigDNSForm, GoLiveSummary]
    : [SetupDomainNameForm, GoLiveSummary]
  const { isLastStep, isFirstStep, currentStep, Step, back, next, goto } = useMultiForm(forms)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const {
    handleSubmit,
    watch,
    formState: { errors, isSubmitting: isSubmitForm },
    reset,
    register
  } = useForm<AddRootDomainPayload>({
    resolver: zodResolver(AddRootDomainSchema)
  })

  useEffect(() => {
    if (isFirstStep) {
      setError(null)
      setIsSuccess(false)
    }
  }, [back, goto])

  useEffect(() => {
    reset(values => ({
      ...values,
      rootDomain: isFree ? getRootDomain(channelData?.domain) : ''
    }))
  }, [channelData])

  const submitHandler = async (values: AddRootDomainPayload) => {
    if (isLastStep) {
      if (isFree) {
        return createFreeDomain({
          channelId,
          domain: freeDomainFormat(values.rootDomain)
        })
          .unwrap()
          .then(() => {
            setIsSuccess(true)
          })
          .catch(err => {
            setIsSuccess(false)
            setError(err)
          })
      }
      return createDomain({
        id: channelId,
        domain: `https://${values.rootDomain}`
      })
        .unwrap()
        .then(() => setIsSuccess(true))
        .catch(err => {
          setIsSuccess(false)
          setError(err)
        })
    }
    next()
  }

  return (
    <BigLayout>
      <div className="d-flex align-items-center justify-content-center pt-5">
        <div className="w-100">
          <MultiFormProgress total={forms.length} current={currentStep} />
          <Form
            className={'w-100 d-flex flex-column align-items-center'}
            onSubmit={handleSubmit(submitHandler)}
            style={{
              maxWidth: '600px',
              margin: '0 auto'
            }}
          >
            <Step
              isFree={isFree}
              register={register}
              errors={errors}
              isSubmitting={isSubmitForm}
              error={error}
              isSuccess={isSuccess}
              domain={`https://${watch('rootDomain')}${isFree ? '.gamifa.vn' : ''}`}
              onConfirm={() => {
                reset()
                goto(0)
              }}
            />
            <div className={'d-flex justify-content-center my-3'}>
              {!isFirstStep && (
                <Button variant={'outline-secondary'} onClick={back}>
                  {__('domain_btn_back')}
                </Button>
              )}
              <Button type={'submit'} className={'ms-2'}>
                {isLastStep ? `${__('domain_btn_complete')}` : `${__('domain_btn_continue')}`}
              </Button>
            </div>
          </Form>
          <div className="text-center">
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-read-setup-guide">{__('domain_text_tooltip')}</Tooltip>}
            >
              <FontAwesomeIcon icon={faCircleQuestion} className="me-2" />
            </OverlayTrigger>
            <a
              href="https://docs.gamifa.com/huong-dan-cai-dat-ten-mien-tren-gamifa"
              className="text-decoration-none text-muted"
              target="_blank"
            >
              {__('domain_instructions_setting')}
            </a>
          </div>

          {isFree && (
            <div className="text-center d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faGlobe} />
              <p className="m-0 ms-2 text-muted">
                {__('domain_text_suggestion')}{' '}
                <Link to="/domain/intro">
                  <b>{__('domain_text_recomment')}.</b>
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </BigLayout>

  )
}

type FormSetupDomainNameProps = {
  register: (name: string) => {}
  errors: FieldErrors<AddRootDomainPayload>
  isFree: boolean
}
const SetupDomainNameForm = ({ register, errors, isFree }: FormSetupDomainNameProps) => {
  return (
    <>
      <h6 className={'fs-4 mb-3'}>{__('domain_chose_name')}</h6>
      <div className={'d-flex'}>
        <FormGroup>
          <InputGroup>
            <InputGroupText>{__('domain_http')}</InputGroupText>
            <FormControl type={'input'} {...register('rootDomain')} />
            {isFree && <InputGroupText>{__('domain_param')}</InputGroupText>}
          </InputGroup>
          {errors.rootDomain && <div className="text-danger">{errors.rootDomain.message}</div>}
        </FormGroup>
      </div>
    </>
  )
}

const ConfigDNSForm = (): ReactElement => {
  const toast = useToast()

  return (
    <div className="w-100">
      <h5 className="text-center"></h5>
      <h6 className={'fs-4 mb-3'}>{__('domain_config_DNS')}</h6>
      <Row gap={1} className="w-100">
        <FormGroup as={Col}>
          <FormLabel className="text-black d-flex align-items-center justify-content-start">
            <Image src={CloudFlareLogo} alt="cloud-flare-logo" width={40} className="me-2" />
            <span>{__('domain_nameserver1')}</span>
          </FormLabel>
          <div
            className={
              'rounded-2 border bg-white py-1 px-2 d-flex justify-content-between align-items-center'
            }
          >
            <p className={'m-0'}>{__('domain_carlane')}</p>
            <CopyToClipboard
              text={'carlane.ns.cloudflare.com'}
              onCopy={() => {
                toast.show({
                  type: EnumTypeToast.Success,
                  content: 'Copied'
                })
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </CopyToClipboard>
          </div>
        </FormGroup>
        <FormGroup as={Col}>
          <FormLabel className="text-black d-flex align-items-center justify-content-start">
            <Image src={CloudFlareLogo} alt="cloud-flare-logo" width={40} className="me-2" />
            <span> {__('domain_nameserver2')}</span>
          </FormLabel>
          <div
            className={
              'rounded-2 border bg-white py-1 px-2 d-flex justify-content-between align-items-center'
            }
          >
            <p className={'m-0'}>{__('domain_sam')}</p>
            <CopyToClipboard
              text={'sam.ns.cloudflare.com'}
              onCopy={() => {
                toast.show({
                  type: EnumTypeToast.Success,
                  content: 'Copied'
                })
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </CopyToClipboard>
          </div>
        </FormGroup>
      </Row>
    </div>
  )
}

type GoLiveSummaryProps = {
  isSubmitting: boolean
  isSuccess: boolean
  error?: string
  domain: string
  onConfirm: () => void
  errors: FieldErrors<AddRootDomainPayload>
}

const GoLiveSummary = ({
  isSubmitting,
  isSuccess,
  error,
  errors,
  domain,
  onConfirm
}: GoLiveSummaryProps) => {
  const [fakeLoadingSSL, setFakeLoadingSSL] = useState(false)
  const [fakeSSLSuccess, setFakeSSLSuccess] = useState(false)
  const [fakeLoadingDomain, setFakeLoadingDomain] = useState(false)
  const [fakeDomainSuccess, setFakeDomainSuccess] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const toast = useToast()

  const fakeLoadingHandler = async () => {
    setFakeLoadingSSL(true)
    await sleep(1000)
    setFakeLoadingSSL(false)
    setFakeSSLSuccess(true)
    setFakeLoadingDomain(true)
    await sleep(2000)
    setFakeLoadingDomain(false)
    setFakeDomainSuccess(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    // console.log('err', error)
    // if (error !== null) return
    if (!error && isSuccess) {
      fakeLoadingHandler().then(() => setShowModal(true))
    }
  }, [isSuccess, error])

  const renderIcon = (loading: boolean, success: boolean, error?: string) => {
    return loading ? (
      <Spinner animation="border" role="status" size="sm">
        <span className="visually-hidden">{__('loading')}</span>
      </Spinner>
    ) : success ? (
      <FontAwesomeIcon icon={faCircleCheck} className="text-success" />
    ) : error ? (
      <FontAwesomeIcon icon={faCircleXmark} className="text-danger" />
    ) : (
      <FontAwesomeIcon icon={faCircle} />
    )
  }

  const renderStatus = () => {
    if (!isSubmitting && !isSuccess && !error) return null
    return (
      <Stack direction="vertical" gap={2} className="ps-2">
        <div className="d-flex align-items-center justify-content-start">
          {renderIcon(isSubmitting, isSuccess, error)}
          <p className="ms-2 my-0">{__('domain_DNS')}</p>
        </div>
        <div className="d-flex align-items-center justify-content-start">
          {renderIcon(fakeLoadingSSL, fakeSSLSuccess)}
          <p className="ms-2 my-0">{__('domain_config_SSL')}</p>
        </div>
        <div className="d-flex align-items-center justify-content-start">
          {renderIcon(fakeLoadingDomain, fakeDomainSuccess)}
          <p className="ms-2 my-0">{__('domain_is_pending')}</p>
        </div>
      </Stack>
    )
  }

  return (
    <div>
      <h5 className="text-center">{__('domain_setting')}</h5>
      {renderStatus()}
      <Modal show={showModal} onHide={closeModal}>
        <ModalHeader closeButton>
          <ModalTitle>{__('domain_modal_title')}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="w-100 bg-body d-flex justify-content-end align-items-center">
            {domain}
            <CopyToClipboard
              text={domain}
              onCopy={() =>
                toast.show({
                  type: EnumTypeToast.Success,
                  content: 'Copied'
                })
              }
            >
              <FontAwesomeIcon icon={faCopy} role="button" className="ms-2" />
            </CopyToClipboard>
            <IconButton
              icon={faArrowUpRightFromSquare}
              className="ms-auto"
              as="a"
              href={domain}
              target="_blank"
            >
              {__('domain_btn_go_to')}
            </IconButton>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={async () => {
              closeModal()
              onConfirm()
            }}
          >
            {__('domain_edit')}
          </Button>
          <Button onClick={closeModal}>{__('domain_btn_complete')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AddDomain

const MultiFormProgress = ({ total, current }: { total: number; current: number }) => {
  const isCompleted = (index: number) => index < current

  return (
    <div className="stepper-wrapper">
      {Array.from(Array(total)).map((step, index) => (
        <div
          key={index}
          className={`${
            isCompleted(index) ? 'completed' : index === current ? 'active' : ''
          } stepper-item`}
        >
          <div className={'step-counter'}>
            {isCompleted(index) ? (
              <FontAwesomeIcon icon={faCheck} className={'text-primary'} />
            ) : (
              index + 1
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
