import React, {FocusEventHandler, memo, useCallback, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import SearchSelect, {components} from 'react-select'
import {AxiosError, AxiosResponse} from 'axios'

//Components
import AddGiftMission from './mission.add.gift'

//Interface
import {TypeDataConvert, TypeDataGift} from '../interface'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getListGift} from '../store/mission.store.reduce'

//Scss
import '../media/mission.option.gift.scss'
import __ from "languages/index";

interface TypeProps {
  required?: boolean
  onChange?: (data) => void
  value?: string
  className?: string
  /** Callback fired when input is focused */
  onFocusCallback?: FocusEventHandler<HTMLInputElement>
  onBlurCallback?: (e?: string) => void
}

interface TypeGift {
  _id?: string
  value?: string
  label?: string
}

const OptionGiftMission = (props: TypeProps) => {
  const { required, onChange, value, onFocusCallback, onBlurCallback, className } = props

  const dispatch = useAppDispatch()

  const { actionsCheck } = useAppSelector(state => state.mission)

  const [listGift, setListGift] = useState<TypeDataGift[]>([])
  const [isShowGift, setIsShowGift] = useState<boolean>(false)
  const [defaultValue, setDefaultValue] = useState<TypeGift>({
    _id: '0',
    value: '',
    label: `${__("mission_chose_reward")}`
  })

  const [gift, setGift] = useState<TypeGift[]>([])

  const _onDataChange = useCallback((data: TypeGift) => {
    setDefaultValue(data)
    onChange(data)
  }, [])

  const handleCloseModalOptionGift = useCallback(() => {
    setIsShowGift(false)
  }, [])

  // render view
  const renderMenuList = props => {
    return (
      <components.MenuList {...props} className="z-99">
        {props.children}
        <div className="d-flex align-items-center p-2 justify-content-between">
          <Button
            variant="danger"
            className="me-2 button-event d-flex align-items-center"
            onClick={() => setIsShowGift(true)}
          >
            <i className={`bi bi-plus-circle-fill fs-6 me-2`} />
            <div>{__("mission_add_reward")}</div>
          </Button>
        </div>
      </components.MenuList>
    )
  }

  const renderNoOptions = props => {
    return (
      <div className="w-100 p-2 d-flex justify-content-center align-items-center">
        {__("mission_add_reward_note")}
      </div>
    )
  }

  const renderPlaceHolder = props => {
    return (
      <components.Placeholder {...props}>
        <div>{__("mission_chose_reward")}</div>
      </components.Placeholder>
    )
  }

  useEffect(() => {
    dispatch(getListGift({ gift_type: 'redeem' }))
      .unwrap()
      .then((res: AxiosResponse) => {
        setListGift(res?.data)
      })
      .catch((error: AxiosError) => {
        console.log(`getListGift_${error}`)
        setListGift([])
      })
  }, [actionsCheck])

  useEffect(() => {
    if (!listGift?.length) return
    listGift?.map((item: TypeDataGift) => {
      if (gift?.every((data: TypeDataConvert) => data?._id !== item?._id)) {
        setGift(gift => [...gift, { _id: item?._id, value: item?._id, label: item?.name }])
      }
    })
  }, [listGift])

  useEffect(() => {
    if (!value) return
    setDefaultValue(gift?.find((item: TypeGift) => item?._id === value))
  }, [gift])

  return (
    <>
      <SearchSelect
        options={gift}
        components={{
          MenuList: renderMenuList,
          NoOptionsMessage: renderNoOptions,
          Placeholder: renderPlaceHolder
        }}
        value={defaultValue}
        required={required}
        onChange={_onDataChange}
        onBlur={() => onBlurCallback(defaultValue?.value || '')}
        onFocus={onFocusCallback}
        className={className}
      />
      {isShowGift && (
        <AddGiftMission
          show={isShowGift}
          onChange={_onDataChange}
          onClose={handleCloseModalOptionGift}
        />
      )}
    </>
  )
}

export default memo(OptionGiftMission)
