//Scss
import '../media/about.skeleton.scss'

const AboutSkeleton = () => {
  return (
    <div className="aboutSkeleton_container">
      <p className="placeholder-glow mt-4 mb-4">
        <span className="placeholder col-12 img_holder"></span>
        <p>
          <span className="placeholder col-4 img_view"></span>
          <span className="placeholder col-4 img_view"></span>
          <span className="placeholder col-4 img_view"></span>
          <span className="placeholder col-4 img_view"></span>
        </p>
        <p>
          <span className="placeholder col-5 des_view"></span>
          <span className="placeholder col-5 des_view"></span>
        </p>
        <span className="placeholder col-8 "></span>
        <span className="placeholder col-6 "></span>
      </p>
    </div>
  )
}

export default AboutSkeleton
