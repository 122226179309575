import { Box, Button, Paper, Stack, Typography, styled } from '@mui/material'
import BigLayout from '../../layouts/bigLayout'
import ShopBreadcrumb from 'entities/shop/components/shop.breadcrumb'
import __ from 'languages/index'
import ShopOrderHeader from 'entities/shop/components/ShopOrderHeader'
import ShopOrderDeliveryStep from 'entities/shop/components/ShopOrderDeliveryStep'
import ShopOrderInfo from 'entities/shop/components/ShopOrderInfo'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ShopOrderListProduct from 'entities/shop/components/ShopOrderListProduct'
import { useNavigate, useParams } from 'react-router-dom'
import { cancelMyOrder, getLogisticLog, getMyUnReview, getOrderDetail } from './service/order.service'
import { AxiosResponse } from 'axios'
import { TypedLogisticLog, TypedOrder, TypedReviewOrder } from './interface'
import ModalReviewOrder from './components/ModalReviewOrder'
import { OrderDeliveryStatus } from './shop.myOrder'
import { EnumTypeToast, useToast } from 'hooks/useToast'

const ShopOrder = () => {
  const { productId } = useParams();
  const toast = useToast()
  const navigate = useNavigate()

  const [orderData, setOrderData] = useState<TypedOrder>(null)
  const [logisticLog, setLogisticLog] = useState<TypedLogisticLog[]>(null)
  const [reviewOrderId, setReviewOrderId] = useState<string>('')
  const [totalUnReview, setTotalUnReview] = useState<number>(0)

  const handleCloseModalReview = useCallback(() => {
    setReviewOrderId('')
  }, [])

  const orderStatusMemo = useMemo(()=>{
    return Object.values(OrderDeliveryStatus)?.findIndex(value => value === orderData?.order_status) || 0
  },[orderData?.order_status])

  const handleCancelMyOrder = useCallback(()=>{
    cancelMyOrder(orderData?.order_id)
        .then(() => {
          toast.show({
            type: EnumTypeToast.Success,
            content: `${__('cancel_order_success')}`
          })
          navigate(`/shop/my-order`)
        })
        .catch(error => {
          console.log(`'getMyUnReview Error >>>`, error)
          toast.show({
            type: EnumTypeToast.Error,
            content: `${error}`
          })
          navigate(`/shop/my-order`)
        })
  },[orderData])

  useEffect(() => {
    if (productId) {
      getMyUnReview({order_id: productId})
        .then((res: AxiosResponse<TypedReviewOrder[]>) => {
         setTotalUnReview(parseInt(res?.headers['x-total-count'] || 0, 10))
        })
        .catch(error => {
          console.log(`'getMyUnReview Error >>>`, error)
        })
    }
  }, [productId])

  useEffect(()=>{
    if(!productId) return
    getLogisticLog({
      order_id: productId,
      sort: "createdAt:desc",
    })
    .then((res: AxiosResponse)=>{
      setLogisticLog(res?.data)
    })
    .catch((error)=>{
      setLogisticLog(null)
      console.log('getLogisticLog error >>>',error);
    })
  },[productId])

  useEffect(()=>{
    if(!productId) return
    getOrderDetail(productId)
    .then((res: AxiosResponse)=>{
      if(res?.data?.order_status === OrderDeliveryStatus.cancelled) return navigate(`/shop/my-order`)
      setOrderData(res?.data)
    })
    .catch((error)=>{
      console.log('getOrderDetail error >>>',error);
      setOrderData(null)
    })
  },[productId])

  return (
    <BigLayout>
      <ShopOrderContainer>
        <ShopBreadcrumb
          breadcrumbName={{
            '/shop': __('shop'),
            '/shop/order': __('breadcrumb_order')
          }}
        />
        <Stack sx={{marginTop: theme=> theme.spacing(6.125), rowGap: theme=>theme.spacing(3)}} width={'100%'}>
          <ShopOrderHeader orderCode={orderData?.order_pnr} handleCancelMyOrder={handleCancelMyOrder} orderStatus={orderStatusMemo} />
          <Paper sx={{border: theme => `1px solid ${theme.palette.divider}`}}>
            <ShopOrderDeliveryStep orderStatus={totalUnReview === 0  && orderData?.order_status === OrderDeliveryStatus.delivered ?  orderStatusMemo + 1 : orderStatusMemo } />
            <ShopOrderInfo orderToAddress={orderData?.orders_to_address} logisticLog={logisticLog} />
            <ShopOrderListProduct orderData={orderData} />
          </Paper>
          {orderData?.order_status === OrderDeliveryStatus.delivered && totalUnReview > 0  && <Paper sx={{p: theme=>theme.spacing(3), display: 'flex', justifyContent: 'flex-end', columnGap: theme=>theme.spacing(3)}}>
            {/* <Button variant='outlined' startIcon={<ShoppingCartIcon />}>
              <Typography textTransform={'none'}>
                {__('buy_again')}
              </Typography>
            </Button> */}
            {totalUnReview > 0 && <Button onClick={() => setReviewOrderId(orderData?.order_id)}>
              <Typography textTransform={'none'}>
                {__('rate_product')}
              </Typography>
            </Button>}
          </Paper>}
        </Stack>
        {Boolean(reviewOrderId) && (
          <ModalReviewOrder
            isShow={Boolean(reviewOrderId)}
            onClose={handleCloseModalReview}
            order_id={reviewOrderId}
            ordersToProduct={orderData?.orders_to_product}
          />
        )}
      </ShopOrderContainer>
    </BigLayout>
  )
}
export default ShopOrder

const ShopOrderContainer = styled(Box)(({ theme }) => ({
  '& .shop_order_content': {
  }
}))
