import { SvgIcon, SvgIconProps } from '@mui/material'

const WaitingDeliveryIcon =(props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12.5 2.82416C10.9403 2.82416 9.67584 4.08857 9.67584 5.64831V7.06039H15.3242V5.64831C15.3242 4.08857 14.0597 2.82416 12.5 2.82416ZM18.1483 7.06039V5.64831C18.1483 2.52884 15.6195 0 12.5 0C9.38052 0 6.85169 2.52884 6.85169 5.64831V7.06039H5.24508C3.02259 7.06039 1.17796 8.77796 1.01961 10.9948L0.0109837 25.1156C-0.164175 27.5678 1.77799 29.6536 4.23645 29.6536H20.7635C23.222 29.6536 25.1642 27.5678 24.989 25.1156L23.9804 10.9948C23.822 8.77796 21.9774 7.06039 19.7549 7.06039H18.1483ZM5.24508 9.88455C4.50425 9.88455 3.88937 10.4571 3.83659 11.196L2.82796 25.3168C2.76958 26.1342 3.41696 26.8295 4.23645 26.8295H20.7635C21.583 26.8295 22.2304 26.1342 22.172 25.3168L21.1634 11.196C21.1106 10.4571 20.4958 9.88455 19.7549 9.88455H5.24508ZM17.7308 14.5306C18.2843 15.0799 18.2877 15.974 17.7384 16.5275L12.288 22.0196C11.5289 22.7845 10.2937 22.7845 9.53456 22.0196L7.26134 19.729C6.712 19.1754 6.7154 18.2814 7.26894 17.732C7.82249 17.1827 8.71656 17.1861 9.26591 17.7396L10.9113 19.3976L15.7338 14.5382C16.2832 13.9846 17.1772 13.9812 17.7308 14.5306Z"
              fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
export default WaitingDeliveryIcon
