import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { LiveStream } from '../liveStream.types'
import { CreateLiveStreamPayload } from '../liveStream.validate'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL + '/livestream',
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    return headers
  }
  // credentials: 'include'
})

const liveStreamApi = createApi({
  baseQuery,
  reducerPath: 'liveStreamApi',
  tagTypes: ['ListLiveStream'],
  refetchOnMountOrArgChange: 30,
  endpoints: build => ({
    createLiveStream: build.mutation<LiveStream, CreateLiveStreamPayload>({
      query(payload) {
        return {
          url: '/create',
          method: 'POST',
          body: payload
        }
      }
    })
  })
})

export const { useCreateLiveStreamMutation } = liveStreamApi

export default liveStreamApi
