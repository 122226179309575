import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const StarOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6531 1.46506C11.2033 0.350189 12.793 0.350189 13.3433 1.46506L15.895 6.63553L21.601 7.46465C22.8313 7.64343 23.3226 9.15539 22.4323 10.0232L18.3034 14.0478L19.2781 19.7307C19.4883 20.9561 18.2022 21.8905 17.1017 21.312L11.9982 18.6289L6.89459 21.312C5.79415 21.8905 4.508 20.9561 4.71817 19.7307L5.69286 14.0478L1.564 10.0232C0.673721 9.15539 1.16499 7.64343 2.39532 7.46465L8.10127 6.63553L10.6531 1.46506ZM11.9982 3.25868L9.77841 7.75638C9.55991 8.19909 9.13757 8.50595 8.649 8.57694L3.68549 9.29818L7.27712 12.7992C7.63065 13.1438 7.79197 13.6403 7.70852 14.1269L6.86065 19.0703L11.3001 16.7363C11.7371 16.5066 12.2592 16.5066 12.6962 16.7363L17.1357 19.0703L16.2878 14.1269C16.2043 13.6403 16.3657 13.1438 16.7192 12.7992L20.3108 9.29818L15.3473 8.57694C14.8587 8.50595 14.4364 8.19909 14.2179 7.75638L11.9982 3.25868Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default StarOutlinedIcon
