import React, {useEffect, useState} from 'react'
import TypedMedia from '../../../../interfaces/media.interface'
import { Box, Stack, SxProps, Typography } from '@mui/material'
import { CloseIcon, DocumentIcon } from '../../../../icons'
import { CurrentTheme } from '../../../../styles/themes'
import helpers from "helpers/index";
import { Button, Modal } from "react-bootstrap";
import __ from "languages/index";

type Props = {
  files: TypedMedia[],
  deleteFileById?: (id: string) => void
}
const DocumentFileView = ({ files, deleteFileById }: Props) => {
  let dataCut = [...files]
  let cutFiles = dataCut.slice(0, 6)
  const [newFile, setNewFile] = useState(cutFiles)
  const [loadMore, setLoadMore] = useState(false)

  useEffect(() => {
    setNewFile(cutFiles)
  }, [files]);

  const readMore = () => {
    if (!loadMore) {
      setNewFile(files)
      setLoadMore(!loadMore)
    } else {
      setNewFile(cutFiles)
      setLoadMore(!loadMore)
    }
  }

  return (
    <div>
      <Stack gap={1.5} sx={documentFileViewStyled} flexWrap={'wrap'} justifyContent={'space-between'} direction={helpers.isMobile() ? 'column' : 'row'}>
        {newFile.map(file => (
          <Stack
            key={file._id}
            gap={1}
            direction={'row'}
            sx={{
              ...documentFileItemStyled,
              width: helpers.isMobile() ? '100%' : '47%'
            }}
          >
            <Box className={'icon'}
              component={'a'}
              href={file.media_url}
              target={'_blank'}
              download>
              <DocumentIcon color={'inherit'} />
            </Box>

            <Typography noWrap fontWeight={600}
              component={'a'}
              target={'_blank'}
              sx={{
                textDecoration: "none",
                flexGrow: 1
              }}
              href={file.media_url}
              download>
              {helpers.truncatedFileName(helpers.convertUTF8ToUnicodeText(file.media_file_name))}
            </Typography>

            {
              deleteFileById &&
              <CloseIcon
                cursor={'pointer'}
                color={'inherit'}
                onClick={() => deleteFileById(file._id)}
              />
            }

          </Stack>
        ))}
      </Stack>

      <Stack marginTop={1} display={"flex"} alignItems={"end"}>

        {files.length > 6 ?
          <Button variant="outline-primary" onClick={readMore}>{loadMore ? __('home_page_btn_hide_less') : __("home_page_btn_see_more")}</Button>
          : null}
      </Stack>

    </div>

  )
}

export default DocumentFileView

const documentFileViewStyled: SxProps<CurrentTheme> = {}

const documentFileItemStyled: SxProps<CurrentTheme> = {
  alignItems: 'center',
  border: theme => `1px solid ${theme.palette.divider}`,
  borderRadius: theme => theme.spacing(1.5),
  padding: theme => theme.spacing(0.75),
  textDecoration: 'none',
  color: theme => theme.palette.text.primary,
  '& .icon': {
    width: theme => theme.spacing(5.5),
    height: theme => theme.spacing(5.5),
    color: theme => theme.palette.text.disabled,
    borderRadius: theme => theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme => theme.palette.background.default
  }
}
