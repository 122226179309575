import React, { forwardRef, useState } from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCircleCheck, faCircleXmark, faEllipsisV, faTrash, faX } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from 'config/store'
import moment from 'moment'
import Avatar from 'components/Avatar'
import { ChallengeUserInfo, StatusMember } from '../challenge.interface'
import NavigateUser from './NavigateUser'
import __ from "languages/index";
import helpers from 'helpers/index'
import { Box, IconButton, Menu, MenuItem, Stack, Typography, styled } from '@mui/material'
import { MenusIcon, TrashIcon } from 'icons'
import CheckOutlineIcon from 'icons/CheckOutlineIcon'
import CloseCircleIcon2 from 'icons/CloseCircleIcon2'
import IconButtonComponent from 'components/IconButton'
type Props = Partial<ChallengeUserInfo> & {
  className?: string
  status: StatusMember
  onDelete?: (id: string) => void
  onUpdatePermission: (id: string, status: StatusMember) => void
  index?: number
}

type Ref = HTMLDivElement

// eslint-disable-next-line react/display-name
const UserCard = forwardRef<Ref, Props>((props, ref) => {
  const {
    onDelete,
    _id,
    display_name,
    createdAt,
    user_avatar,
    user_avatar_thumbnail,
    className,
    status,
    total_point,
    onUpdatePermission,
    index
  } = props
  const [showModalRemoveUser, setShowModalRemoveUser] = useState<boolean>(false)
  const permission_data = useAppSelector(state => state.user.permission_data);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const statusCard = {
    [StatusMember.approved]: {
      disabled: true,
      icon: faCheck,
      text: `${__("challenge_activity_accept")}`,
      updateText: `${__("challenge_activity_reject")}`,
      updateIcon: faCircleXmark,
      updateStatus: StatusMember.rejected
    },
    [StatusMember.rejected]: {
      disabled: false,
      icon: faTrash,
      text: `${__("challenge_activity_reject")}`,
      updateText: `${__("challenge_activity_accept")}`,
      updateIcon: faCircleCheck,
      updateStatus: StatusMember.approved
    }
  }

  const renderActionPopover = (
    <Popover id="user-action" className="border d-flex align-items-center">
      <Popover.Body>
        {status !== StatusMember.pending && !statusCard[status].disabled && (
          <div
            role="button"
            onClick={() => onUpdatePermission(_id, statusCard[status].updateStatus)}
            className="mb-2"
          >
            <FontAwesomeIcon
              icon={statusCard[status]?.updateIcon}
              className={`${status === StatusMember.approved ? 'text-warning' : 'text-success'
                } me-2`}
            />
            <span>{statusCard[status]?.updateText}</span>
          </div>
        )}
        <div role="button" onClick={() => setShowModalRemoveUser(true)}>
          <FontAwesomeIcon icon={faTrash} className="text-danger me-2" />
          <span>{__('challenge_user_card_delete')}</span>
        </div>
      </Popover.Body>
    </Popover>
  )

  const modalRemoveUser = (
    <Modal show={showModalRemoveUser}>
      <Modal.Header>
        <Modal.Title>{__("challenge_user_card_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{__('challenge_user_card_text')}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModalRemoveUser(false)}>
          {__("btn_close")}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onDelete(_id)
            setShowModalRemoveUser(false)
          }}
        >
          {__("btn_agree")}
        </Button>
      </Modal.Footer>
    </Modal>
  )

  return (
    <UserCardContainer
      ref={ref}
    >
      <Typography className="stt">{index + 1 || 1}</Typography>
      <NavigateUser _id={_id}>
        <Avatar
          src={user_avatar_thumbnail || user_avatar}
          roundedCircle
          alt={display_name}
          width={60}
          height={60}
          className="me-3"
          style={{ objectFit: 'cover' }}
        />
      </NavigateUser>
      <Box className="info_user">
        <Typography variant='subtitle2'>{display_name}</Typography>
        <Typography color='text.secondary'>Tham gia từ: {moment(createdAt).format('DD/MM/YYYY')}</Typography>
      </Box>
      <Typography className='point_value' color={'primary.main'} sx={{
        padding: theme => theme.spacing(0.25, 1),
        borderRadius: theme => theme.spacing(1.5),
        background: '#F3F4F5',
        margin: 'auto'
      }} >{Number(total_point) || 0}</Typography>

      <Box m={'auto'}>
        <Stack flexDirection={'row'} alignItems={'center'} sx={{
          marginRight: theme => theme.spacing(2),
        }}>

          <IconButton
            id="basic-button"
            aria-controls={!!anchorEl ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={!!anchorEl ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenusIcon sx={{
              transform: 'rotate(90deg)'
            }} />
          </IconButton>
          {helpers.current_user_can('boss', permission_data) && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              elevation={4}
            >
              {status === StatusMember.pending ? (
                <>
                  <MenuItem onClick={() => {
                    handleClose()
                    onUpdatePermission(_id, StatusMember.approved)
                  }}>
                    <CheckOutlineIcon sx={{
                      marginRight: theme => theme.spacing(2)
                    }} />
                    Chấp nhận
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleClose()
                    onUpdatePermission(_id, StatusMember.rejected)
                  }}>
                    <CloseCircleIcon2 sx={{
                      marginRight: theme => theme.spacing(2)
                    }} />
                    Từ chối</MenuItem>
                </>
              ) : null}
              < MenuItem onClick={() => {
                handleClose()
                setShowModalRemoveUser(true)
              }}>
                <TrashIcon sx={{
                  marginRight: theme => theme.spacing(2)
                }} />
                Xoá</MenuItem>

            </Menu>
          )
          }
        </Stack>
      </Box>
      {modalRemoveUser}
    </UserCardContainer >
  )
})

export default UserCard

const UserCardContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50px 76px 1fr 62px 40px',
  marginTop: theme.spacing(2),

  '& .info_user, .stt': {
    margin: 'auto 0'
  }
}))
