import { Button, Paper, Stack, TextField, styled } from '@mui/material'
import React from 'react'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  AdvanceConfigurationPayload,
  AdvanceConfigurationSchema
} from '../validate/advance.validate'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannelThunk } from '../store/channel.slice'
import { smtpConfigs, ssoConfigs } from './addvance-form-configs'
import TypedChannel from 'interfaces/channel.interface'

const ChannelSettingsAdvance = () => {
  const channel = useAppSelector(channelInfo)
  const dispatch = useAppDispatch()

  const { register, handleSubmit } = useForm<AdvanceConfigurationPayload>({
    resolver: zodResolver(AdvanceConfigurationSchema),
    defaultValues: {
      firebase_notification_key: channel?.firebase_notification_key || '',
      permalink_tos: channel?.permalink_tos || '',
      facebook_login_client_id: channel?.facebook_login_client_id || '',
      google_login_client_id: channel?.google_login_client_id || '',
      smtp_server_address: channel?.smtp_server_address || '',
      smtp_server_username: channel?.smtp_server_username || '',
      smtp_server_password: channel?.smtp_server_password || '',
      smtp_server_port: channel?.smtp_server_port || '',
      smtp_server_secure_protocol: channel?.smtp_server_secure_protocol || ''
    }
  })

  const handleUpdate = async (updatedChannel: Partial<TypedChannel>) => {
    try {
      dispatch(
        updateChannelThunk({
          ...channel,
          ...updatedChannel
        })
      ).unwrap()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Stack gap={2} component={'form'} onSubmit={handleSubmit(handleUpdate)}>
      <Configuration title={__('channel_setting_advance_firebase')}>
        <TextField
          label={__('channel_setting_overview_firebase_key')}
          placeholder={__('channel_setting_overview_firebase_key_placeholder')}
          {...register('firebase_notification_key')}
        />
      </Configuration>
      <Configuration title={__('channel_setting_advance_tos')}>
        <TextField
          label={__('channel_setting_tos_url')}
          placeholder={__('channel_setting_tos_url')}
          {...register('permalink_tos')}
        />
      </Configuration>
      <Configuration title={__('channel_setting_advance_SSO')}>
        <Stack gap={2}>
          {ssoConfigs.map(config => (
            <TextField {...register(config.key as keyof AdvanceConfigurationPayload)} {...config} />
          ))}
        </Stack>
      </Configuration>
      <Configuration title={__('channel_setting_advance_smtp')}>
        <Stack gap={2}>
          {smtpConfigs.map(config => (
            <TextField {...register(config.key as keyof AdvanceConfigurationPayload)} {...config} />
          ))}
        </Stack>
      </Configuration>
      <Button sx={{ ml: 'auto' }} type="submit">
        {__('btn_update')}
      </Button>
    </Stack>
  )
}

export default ChannelSettingsAdvance

const ConfigurationContainer = styled(Paper)(({ theme }) => ({
  border: ` 1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1)
}))

const Configuration = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <ConfigurationContainer>
      <ChannelSettingsTitle mb={1}>{title}</ChannelSettingsTitle>
      {children}
    </ConfigurationContainer>
  )
}
