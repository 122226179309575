import React, {useState} from 'react'
import TypedUser from 'interfaces/user.interface'
import {Button, Form, FormControl, ProgressBar, Spinner, Stack} from 'react-bootstrap'
import Avatar from 'components/Avatar'
import FileUploader from 'components/FileUploader'
import useUploadFile from 'hooks/useUploadFile'
import {useCommentTicketMutation} from '../store/supportApi'
import {useForm} from 'react-hook-form'
import {CommentTicketPayload, CreateTicketSchema} from '../support.validation'
import {zodResolver} from '@hookform/resolvers/zod'
import {EnumTypeToast, useToast} from '../../../hooks/useToast'
import {TXT_ERROR} from 'entities/support/constants/label.constant'
import {useSelector} from 'react-redux'
import {IRootState} from 'config/store'
import __ from "languages/index";

type Props = {
  author?: TypedUser
  ticketId?: string
}

const SupportCommentEditor = ({ author, ticketId }: Props) => {
  const userAvatar = useSelector((state: IRootState) => state.user?.user_data?.user_avatar)
  const [files, setFiles] = useState<File[]>()
  const { srcFiles, progress, loading } = useUploadFile(files, {})
  const [comment, {}] = useCommentTicketMutation()
  const {
    register,
    watch,
    formState: { errors, isSubmitting },
    reset
  } = useForm<CommentTicketPayload>({
    resolver: zodResolver(CreateTicketSchema)
  })

  const toast = useToast()

  const commentHandler = async (content: string) => {
    try {
      const attachFiles = srcFiles.map(file => file._id)
      await comment({
        content,
        ticketId,
        attachFiles: JSON.stringify(attachFiles)
      })
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__("support_content_comment")}`
      })
      await reset()
      setFiles([])
    } catch (err) {
      toast.show({
        type: EnumTypeToast.Error,
        content: TXT_ERROR
      })
    }
  }

  return (
    <div className="d-flex align-items-start">
      <Avatar width={35} height={35} roundedCircle src={userAvatar} />
      <Form
        className="ms-2 flex-grow-1"
        onSubmit={e => {
          e.preventDefault()
          commentHandler(watch('content'))
        }}
      >
        <FormControl
          {...register('content')}
          as="textarea"
          type="text"
          rows={4}
          className="border-none mb-2"
          placeholder={__("support_add_comment")}
        />
        <FileUploader onUpload={setFiles}>
          {() => {
            return (
              <div>
                {srcFiles?.map(file => <div key={file._id}>{file.media_file_name}</div>)}
                <Stack
                  direction="horizontal"
                  className="d-flex align-items-center justify-content-start"
                >
                  {loading && (
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner size="sm" />
                    </div>
                  )}
                  {progress < 100 && progress > 0 && (
                    <ProgressBar now={progress} variant="success" className="w-50 ms-2" />
                  )}
                </Stack>
              </div>
            )
          }}
        </FileUploader>
        <Button className="mt-2" type="submit" disabled={!watch('content')}>
          {__("support_btn_comment")}
        </Button>
      </Form>
    </div>
  )
}

export default SupportCommentEditor
