import React from 'react'
import { Box, Typography } from '@mui/material'

type Props = {
  value: string
}

const CategoryTag = ({ value }: Props) => {
  return (
    <Box
      component={'span'}
      sx={{
        backgroundColor: 'primary.background' || 'primary.light',
        py: 0.25,
        px: 1,
        borderRadius: 3
      }}
    >
      <Typography component="span" variant="body2" fontWeight={600} color="primary">
        #{value}
      </Typography>
    </Box>
  )
}

export default CategoryTag
