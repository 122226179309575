//Library
import React, {useCallback, useEffect, useState} from 'react'
import __ from 'languages/index'

//Components
import ComponentEmpty from 'components/componentEmpty'
import SettingMentorSkeleton from 'entities/mentor/skeleton/setting.mentor.list.skeleton'
import ModalConfirm from 'entities/mentor/component/modalConfirm'
import _Helmet from 'components/helmet'
import Title from "components/Title";
import OrderPlan from './components/OrderPlan'


//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getListUserExpiredSubscription, getListUserSubscription, updateSubscription} from './order.store.reducer'

//Hooks
import {useInfinite} from 'hooks/useInfinite'

//Data
import {dataTabs} from './data'

//Interface
import {TypeDataMySubscription, TypeDataSubscription} from './order.type'

//Scss
import BigLayout from "../../layouts/bigLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";
import { formatString } from 'entities/support/utils'
import { Box, styled, Typography } from '@mui/material'

interface TypeParams {
  page: number
  limit: number
  order_by: string
}

const OrderMySubscription = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const current_user_id = useAppSelector(state => state.user.current_user_id)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowModalCancelExtend, setIsShowCancelExtend] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState<string>('0')
  const [dataSelectCancelExtend, setDataSelectCancelExtend] = useState<TypeDataMySubscription>()
  const [dataListSubscription, setDataListSubscription] = useState<TypeDataSubscription>({
    listData: [],
    totalListData: 0
  })
  const [params, setParams] = useState<TypeParams>({
    page: 1,
    limit: 10,
    order_by: 'DESC'
  })

  const { ref } = useInfinite(
    dataListSubscription?.listData?.length < dataListSubscription?.totalListData,
    () => {
      setParams({ ...params, page: params?.page + 1 })
    }
  )

  const handleChangeTabs = useCallback((key: string) => {
    setTabIndex(key)
    setParams({ page: 1, limit: 10, order_by: 'DESC' })
    setDataListSubscription({ listData: [], totalListData: 0 })
  }, [])

  const handleAcceptCancelExtend = useCallback(
    (result: boolean) => {
      setIsShowCancelExtend(false)
      if (result) {
        dispatch(updateSubscription({ _id: dataSelectCancelExtend?._id, is_auto_renew: 'false' }))
          .unwrap()
          .then(() => {
            setDataListSubscription({ listData: [], totalListData: 0 })
            setParams({ page: 1, limit: 10, order_by: 'DESC' })
            toast.show({
              title: `${__("toast_title")}`,
              content: `${__("toast_cancel_payment_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch(error => {
            console.log(error)
            toast.show({
              title: `${__("toast_title")}`,
              content: `${__("toast_cancel_payment_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }
    },
    [isShowModalCancelExtend, dataSelectCancelExtend]
  )

  const handleClickCancel = useCallback((item: TypeDataMySubscription)=>{
    setIsShowCancelExtend(true)
    setDataSelectCancelExtend(item)
  },[])

  useEffect(() => {
    params?.page < 2 && setIsLoading(true)
    if (!Number(tabIndex)) {
      dispatch(
        getListUserSubscription({ params: params, id: current_user_id })
      )
        .unwrap()
        .then(res => {
          setIsLoading(false)
          setDataListSubscription(dataListSubscription => ({
            listData: [...dataListSubscription?.listData, ...res?.data],
            totalListData: parseInt(res.headers['x-total-count'] || 1000, 10)
          }))
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
          setDataListSubscription({ listData: [], totalListData: 0 })
        })
      return
    }
    if (Number(tabIndex)) {
      dispatch(
        getListUserExpiredSubscription({
          params: params,
          id: current_user_id
        })
      )
        .unwrap()
        .then(res => {
          setIsLoading(false)
          setDataListSubscription(dataListSubscription => ({
            listData: [...dataListSubscription?.listData, ...res?.data],
            totalListData: parseInt(res.headers['x-total-count'] || 1000, 10)
          }))
        })
        .catch(error => {
          console.log('error', error)
          setIsLoading(false)
          setDataListSubscription({ listData: [], totalListData: 0 })
        })
      return
    }
  }, [tabIndex, params])

  return (
    <BigLayout>
      <OrderMySubscriptionContainer>
        <_Helmet title='order_subscription' />
        <Box id="orderMySubscription" className="orderMySubscription_container">
          <Box className="orderMySubscription_header_wrapper">
            <Title text={__("order_subscription")} />
            <Box className="orderMySubscription_tabs" marginBottom={theme => theme.spacing(3)}>
              {dataTabs?.map((item: any, idx: number) => (
                <Box key={`tab_${idx}`}>
                  <Typography
                    variant={'body1'}
                    className={`orderMySubscription_item_tab ${
                      Number(tabIndex) === idx && 'activeTab'
                    }`}
                    sx={{

                    }}
                    onClick={() => handleChangeTabs(`${idx}`)}
                  >
                    {item?.tabName}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box className="orderMySubscription_body_wrapper">
            {isLoading ? (
              <Box className="bg-white p-4 rounded" marginBottom={theme => theme.spacing(2)}>
                <SettingMentorSkeleton />
              </Box>
            ) : (
              <>
                {dataListSubscription?.listData?.length ? (
                  <>
                    {dataListSubscription?.listData?.map((item, idx: number) => (
                      <Box marginBottom={theme => theme.spacing(3)}>
                        <OrderPlan
                          handleClickCancel={handleClickCancel}
                          refItem={ref}
                          dataSubscription={item}
                          idx={idx}
                        />
                      </Box>
                    ))}
                  </>
                ) : (
                  <ComponentEmpty text={formatString(__('data_empty'), `${__('order_subscription_text')}`)} />
                )}
              </>
            )}
          </Box>
          {isShowModalCancelExtend && (
            <ModalConfirm
              show={isShowModalCancelExtend}
              title={__("order_subscription_modal_confirm_title")}
              onClose={handleAcceptCancelExtend}
            >
              <Typography>{__("order_subscription_modal_confirm_text")}</Typography>
            </ModalConfirm>
          )}
        </Box>
      </OrderMySubscriptionContainer>

    </BigLayout>
  )
}

export default OrderMySubscription

const OrderMySubscriptionContainer = styled(Box)(({theme})=>({
  // '& .orderMySubscription_container':{
  //   display: 'grid',
  //   rowGrap: theme.spacing(2.5),
  // },
  // '& .orderMySubscription_header_wrapper':{
  //   display: 'grid',
  //   gridTemplateRows: `repeat(1,minmax(2, minmax(0, 1fr))`,
  //   rowGrap: theme.spacing(1.25),
  //     '& .orderMySubscription_title': {
    // color: var(--global-font-color);
    // text-transform: uppercase;
    // font-weight: 500;
  //     }
  // },


  '& .orderMySubscription_tabs ':{
  display: 'flex',
  width: '100%',
  height: theme.spacing(6),
  alignItems: 'center',
  // borderBottom: `${theme.spacing(0.124)} solid ${theme.palette.text.disabled}`
},

 '& .orderMySubscription_item_tab':{
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    cursor: 'pointer',
    position: 'relative',
    color: theme.palette.text.primary,
   ':hover':{
     color: theme.palette.primary.main,
   }
  },
  ' & .activeTab ':{
    color: theme.palette.primary.main,
    '::after':{
      content:'""',
      width: '100%',
      height: theme.spacing(0.125),
      position: 'absolute',
      bottom: 0,
      right: 0,
      borderBottom: `${theme.spacing(0.124)} solid ${theme.palette.primary.main}`
    }
  },

  '& .orderMySubscription_body_wrapper':{
      display: 'grid',
      rowGrap: theme.spacing(2.5),
      gridTemplateRows: `repeat(1,minmax(2, minmax(0, 1fr))`
  },

}))
