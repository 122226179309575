import React, { ReactNode, useState } from 'react'
import { Image, Modal, Ratio } from 'react-bootstrap'

type Props = {
  children: ReactNode
  type: 'video' | 'image'
  mediaUrl: string
}

const MediaView = ({ children, type, mediaUrl }: Props) => {
  const [show, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Modal show={show} onHide={() => setShowModal(false)} size="lg" centered>
        <Modal.Header closeButton />
        <Modal.Body>
          {type === 'image' ? (
            <Image src={mediaUrl} className="w-100" />
          ) : (
            <Ratio aspectRatio="16x9">
              <embed type="video/webm" src={mediaUrl} />
            </Ratio>
          )}
        </Modal.Body>
      </Modal>
      <div
        onClick={() => setShowModal(true)}
        role="button"
        style={{
          width: 'max-content'
        }}
      >
        {children}
      </div>
    </>
  )
}

export default MediaView
