import React from 'react'
import {TypedChallengeGift} from '../challenge.interface'
import {Button, ListGroupItem} from 'react-bootstrap'
import __helpers from 'helpers/index'
import __ from "languages/index";
import TimelineChallenge from 'components/TimelineChallenge'
import { Box, Stack, Typography, styled } from '@mui/material';

export interface TypedTimeline {
  _id?: string
  startDate: number | string
  endDate: number | string
  title: string
  detail: TypedDetail[]
}

interface TypedDetail {
  id: string
  description: string
  gifts: TypedChallengeGift[]
}

const ChallengeTimeline = ({
  isOwner = false,
  timeline,
  onDelete
}: {
  isOwner?: boolean
  timeline?: TypedTimeline[]
  onDelete?: (_id: string) => void
}) => {
  const _onDeleteLeg = (_id: string) => {
    onDelete(_id)
  }

  const renderDetail = (detail: TypedDetail[]) => {
    return (
      <div>
        {detail.map(d => {
          return (
            <>
              <DetailTimeLineContainer key={d.id} mt={1} pb={2}>
                <Typography className="m-0 rounded-3">{d.description}</Typography>
                {
                  d.gifts?.length ? (
                    <>
                      <Typography mt={1} variant='subtitle2'>{__('mission_reward')}: </Typography>
                      {d.gifts.map(gift => (
                        <Stack mt={1.5} flexDirection={'row'} alignItems={'center'} key={gift?._id}>
                          <picture>
                            <img
                              src={gift.media_id?.media_url || gift?.media_id?.media_thumbnail}
                              className="rounded"
                              style={{
                                // maxHeight: '60px',
                                height: '52px',
                                width: '52px',
                                objectFit: 'cover'
                              }}
                            />
                          </picture>
                          <Box ml={2} className="info_reward">
                            <Typography className="m-0 gift_name">{gift.name}</Typography>
                            <Typography className="gift_price" mt={1} >{__helpers.formatNumber(gift.price)} VND</Typography>
                          </Box>
                        </Stack>
                      ))}
                    </>
                  ) : null
                }

              </DetailTimeLineContainer>
            </>
          )
        })}
      </div>
    )
  }

  return <TimelineChallenge onDeleteLeg={_onDeleteLeg} isOwner={isOwner} events={timeline} renderDetail={renderDetail} />
}

export default ChallengeTimeline

const DetailTimeLineContainer = styled(Box)(({ theme }) => ({
  '& .info_reward': {
    '& .gift_name': {
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box'
    },

    '& .gift_price': {
      fontWeight: '600',
      color: '#DC3545'
    }
  }
}))
