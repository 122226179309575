export interface  TypeStarVotingProps {
  rating : number;
}
 const  StarVoting: React.FC<TypeStarVotingProps> = ({rating}) => {
   const maxStars = 5;
   const fullStars = Math.floor(rating);
   const halfStar = rating % 1 >= 0.5;
   const starIcons = [];

   for (let i = 1; i <= fullStars; i++) {
     starIcons.push(
       <svg
         viewBox="0 0 24 24"
         style={{ width: "0.975rem", height: "0.975rem", fill:  "currentcolor"}}
         role="presentation"
         key={i}
       >
         <path
           d= "M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
         ></path>
       </svg>
     );
   }

     if (halfStar) {
       starIcons.push(
         <svg key={Math.random()} viewBox="0 0 24 24" style={{width: "0.975rem", height: "0.975rem"}} role="presentation">
            <path d="M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" style={{fill: "currentcolor"}}></path>
         </svg>
       );
     }

     while (starIcons.length < maxStars) {
       starIcons.push(
         <svg key={Math.random()} viewBox="0 0 24 24" style={{width: "0.975rem", height: "0.975rem"}} role="presentation">
            <path d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" style={{fill: "currentcolor"}}></path>
         </svg>);
     }
    return (
        <div className='d-flex justify-content-center pt-2'>
          {starIcons}
        </div>
    )
}
export default StarVoting
