//Library
import {memo, useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {DateRange} from 'react-date-range'
import dateandtime from 'date-and-time'
//Interface
import {TypeDataTime} from 'entities/mentor/mentor.interface'

//Scss
import 'react-date-range/dist/theme/default.css'
import 'react-date-range/dist/styles.css'
import './../media/course.filter.time.scss'
import __ from "languages/index";

const addDays = dateandtime.addDays
//Stores

const CourseIncomeFilterTime = ({
  data,
  show,
  onClose,
  onSubmit,
  title,
  ...args
}: {
  data
  show: boolean
  onClose: () => void
  onSubmit
  title: string
  args?: any
}) => {
  const [stateTime, setStateTime] = useState<TypeDataTime[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ])

  const handleSubmit = useCallback(() => {
    onSubmit(stateTime.at(0))
  }, [stateTime])

  const handleClose = useCallback(() => {
    onClose()
    setStateTime([data])
  }, [stateTime])

  useEffect(() => {
    if (!data?.startDate) return
    setStateTime([data])
  }, [data])

  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="courseIncomeFilterTime__container"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRange
            onChange={(item: any) => setStateTime([item.selection])}
            months={1}
            minDate={addDays(new Date(), -300)}
            maxDate={addDays(new Date(), 900)}
            direction="vertical"
            scroll={{ enabled: true }}
            moveRangeOnFirstSelection
            retainEndDateOnFirstSelection
            ranges={stateTime}
            dateDisplayFormat="dd/MM/yyyy"
            // locale={vi}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <span
            role="button"
            className="text-decoration-underline text-primary"
            onClick={() =>
              onSubmit({
                startDate: undefined,
                endDate: undefined,
                key: 'selection'
              })
            }
          >
            {__("course_deselect_time")}
          </span>
          <Button variant="primary" onClick={handleSubmit}>
            {__("course_btn_apply")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default memo(CourseIncomeFilterTime)
