//Library
import {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {notEmpty, useField, useForm} from '@shopify/react-form'
import {AxiosError} from 'axios'
import { Form} from 'react-bootstrap'

//Components
import TextField from 'components/TextField'
import ChannelModalConfirm from './channel.modal.confirm'

//Interface
import {TypeDataLevel} from '../interface'

//Helpers
import helpers from 'helpers'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {deleteLevel, updateLevel} from '../store/channelSetting.store.reduce.ts'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { Button } from '@mui/material'

const LevelItem = ({
  item,
  idx,
  isRefresh,
  setIsRefresh,
  listLevel,
  onToggle,
  isEdit,
  isLastItem
}: {
  item: TypeDataLevel
  idx: number
  isRefresh: boolean
  setIsRefresh: Function
  onToggle: Function
  isEdit: boolean
  listLevel: TypeDataLevel[]
  isLastItem: boolean
}) => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)
  const toast = useToast()

  const beforeLevel = useMemo(() => {
    if (helpers.isEmpty(listLevel)) return null
    const beforeIdx = idx < listLevel.length - 1 ? idx + 1 : null
    return beforeIdx !== null ? listLevel[beforeIdx] : null
  }, [listLevel])

  const afterLevel = useMemo(() => {
    if (helpers.isEmpty(listLevel)) return null
    const afterIdx = idx !== 0 ? idx - 1 : null
    return afterIdx !== null ? listLevel[afterIdx] : null
  }, [listLevel])

  const { fields, submit, reset, dirty, submitting } = useForm({
    fields: {
      title: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("channel_setting_fill_name_level")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("channel_setting_fill_name_level")}`
            }
          }
        ]
      }),
      level_point: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__("channel_setting_fill_point_level")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("channel_setting_fill_point_level")}`
            }
            if (
              !helpers.isEmpty(afterLevel) &&
              Number(inputVal) <= Number(afterLevel?.level_point)
            ) {
              return `${__("channel_setting_fill_point_level_lower")}`
            }
            if (
              !helpers.isEmpty(beforeLevel) &&
              Number(inputVal) >= Number(beforeLevel?.level_point)
            ) {
              return `${__("channel_setting_fill_point_level_above")}`
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          _id: item?._id,
          channel_id: channel_data?._id,
          title: values.title.trim(),
          level_point: `${values.level_point}`
        }
        setIsLoading(true)
        dispatch(updateLevel(formData))
          .unwrap()
          .then(() => {
            setIsLoading(false)
            setIsRefresh(!isRefresh)
            onToggle(null)
            toast.show({
              content: `${__("channel_setting_update_level_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`createLevel_${error}`)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_level_failed")}`,
              type: EnumTypeToast.Error
            })
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleShowEdit = useCallback(() => {
    onToggle(idx)
  }, [idx])

  const handleDeleteLevel = useCallback(
    (result: boolean, args: { _id?: string }) => {
      setIsShowConfirmDelete(false)
      if (result) {
        dispatch(deleteLevel(args?._id))
          .unwrap()
          .then(() => {
            setIsRefresh(!isRefresh)
            toast.show({
              content: `${__("channel_setting_remove_level_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`deleteLevel_${error}`)
            toast.show({
              content: `${__("channel_setting_remove_level_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }
    },
    [isRefresh]
  )

  useEffect(() => {
    if (!isEdit) return reset()
    fields.title.onChange(item?.title)
    fields.level_point.onChange(`${item?.level_point}`)
  }, [isEdit])

  return (
    <div id="levelItem" className={`channelSettingLevel_body_item ${isEdit && 'edit'}`}>
      {isEdit ? (
        <Form onSubmit={submit} className="levelItem_form_wrapper">
          <div className="levelItem_form">
            <div className="levelItem_form_item">
              <label>{__("channel_setting_level_name")}</label>
              <TextField
                {...fields.title}
                autoFocus
                onChange={(value: string) => fields.title.onChange(value)}
                placeholder={__("channel_setting_level_name")}
              />
            </div>
            {idx !== 0 && (
              <div className="levelItem_form_item">
                <label>{__("channel_setting_level_point")}</label>
                <TextField
                  {...fields.level_point}
                  onChange={(value: string) =>
                    fields.level_point.onChange(helpers.parseNumeric(value))
                  }
                  placeholder={__("channel_setting_level_point")}
                />
              </div>
            )}
          </div>
          <div className="levelItem_form_button">
            <Button variant='text' sx={{textTransform: 'none'}}  onClick={() => onToggle(null)}>
              {__("btn_cancel")}
            </Button>
            <Button sx={{textTransform: 'none'}}
              disabled={isLoading || !dirty || submitting}
              onClick={submit}
            >
              {isLoading ? `${__("btn_processing")}` : `${__("btn_save")}`}
            </Button>
          </div>
        </Form>
      ) : (
        <>
          <div className="d-flex flex-column">
            <span className="fw-bold">{`Level ${item?.level_number} - ${item?.title || ''}`}</span>
            <span>{`Level point ${item?.level_point || '0'}`}</span>
            <span>{`${item?.total_member || '0'} ${__("channel_setting_member")}`}</span>
          </div>
          <div className="channelSettingLevel_body_list_button">
            <span
              className={`text-primary text-decoration-underline ${(idx === 0||!isLastItem) && 'd-none'}`}
              onClick={() => {
                setIsShowConfirmDelete(true)
              }}
            >
              {__("channel_setting_remove")}
            </span>
            <Button sx={{textTransform: 'none'}} onClick={handleShowEdit}>
              {__("channel_setting_edit")}
            </Button>
          </div>
        </>
      )}
      {isShowConfirmDelete && (
        <ChannelModalConfirm
          show={isShowConfirmDelete}
          onClose={handleDeleteLevel}
          title={__("channel_setting_modal_confirm_remove_level_title")}
          {...{ _id: item?._id }}
        >
          <p>{__("channel_setting_modal_confirm_remove_level_text")}</p>
        </ChannelModalConfirm>
      )}
    </div>
  )
}
export default memo(LevelItem)
