export const dataPaymentMethod = (selectPayment: number) => {
    let dataPaymentMethod = ''
    switch (selectPayment) {
        case 0:
            dataPaymentMethod = 'google_payment'
            break
        case 1:
            dataPaymentMethod = 'vn_pay'
            break
        case 2:
            dataPaymentMethod = 'transfer'
            break
        case 3:
            dataPaymentMethod = 'apple_payment'
            break
        case 4:
            dataPaymentMethod = 'stripe'
            break
        case 5:
            dataPaymentMethod = 'zalo_pay'
            break
        case 6:
            dataPaymentMethod = 'paypal'
            break
        default:
            dataPaymentMethod = ''
            break
    }
    return dataPaymentMethod
}
