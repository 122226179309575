import { useAppDispatch } from "config/store";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { getCurrentUserData, getDefaultChannel, getUserPermission } from "store/user.store.reducer";
import __ from 'languages/index'


/**
 * @author jamviet.com
 * Hàm xử lý giúp SSO từ gamifa.vn sang, để đảm bảo các subdomain, kênh khác ... đều được login bằng một nơi, một chỗ, đỡ phải config
 * Khi nó self-hosting, có thể xóa bỏ file này ...
 */
export default function auth() {

    let {hash} = useLocation();
    const navigator = useNavigate();
    const dispatch = useAppDispatch();

    const loadUserInformation = useCallback( async() => {
        if ( ! hash ) return;
        try {
            let originHash = hash.slice(1);
            let params = new URLSearchParams(originHash);
            let authentication_hash = params.get('authentication_hash');
            if ( !authentication_hash ) return;
            localStorage.setItem('session', authentication_hash);
            document.cookie = `session=${authentication_hash};path=/;SameSite=Lax`;
            localStorage.removeItem('sso_host');
            await dispatch(getDefaultChannel());
            await dispatch( getCurrentUserData() );
            await dispatch(getUserPermission());
            return navigator('/');
        } catch(e) {}

    }, [hash]);

    useEffect( () => {
        loadUserInformation();
    }, [hash]);


    return (
        <>{__("auth_text")}</>
    )
}
