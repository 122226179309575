import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material"
import { Field } from "@shopify/react-form"
import { convertData } from "entities/shop/helper";
import { TypeAddess, TypeDistrict, TypeWard } from "entities/shop/interface";
import __ from "languages/index";
import { useEffect, useState } from "react";
import addressData from '../entities/challenge/media/diachinh.json';


interface TypedProps {
    fields: {
        order_customer_address_city: Field<string>;
        order_customer_address_district: Field<string>;    
        order_customer_address_ward: Field<string>;    
    }
}
const AddressForm = ({fields}: TypedProps) => {

    const [addresses, setAddresses] = useState<TypeAddess[]>([]);
    const [districtByCity, setDistrictByCity] = useState<TypeDistrict[]>([]);
    const [wardByDistrict, setWardByDistrict] = useState<TypeWard[]>([]);

    useEffect(() =>{
        setAddresses(convertData(addressData))

    }, [])

    useEffect(() => {
        const district = addresses?.filter(item => item?.code === fields.order_customer_address_city.value).map(item => item['quan-huyen'])
        if (!!district) {
            setDistrictByCity(convertData(district[0]))
        }

    }, [fields.order_customer_address_city.value])


    useEffect(() => {
        const ward = districtByCity?.filter(item => item?.code === fields.order_customer_address_district.value).map(item => item['xa-phuong'])
        if (!!ward) {
            setWardByDistrict(convertData(ward[0]))
        }
    }, [fields.order_customer_address_district.value, districtByCity])

    const handleChangeCity = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;

        fields.order_customer_address_city.onChange(value)
        fields.order_customer_address_district.onChange('')

    };

    const handleChangeWard = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        fields.order_customer_address_ward.onChange(value)
    };

    const handleChangeDistrict = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;

        fields.order_customer_address_district.onChange(value)
        fields.order_customer_address_ward.onChange('')
    };
    return (
        <Grid sx={{
            marginBottom: theme => theme.spacing(2.7)
        }} spacing={2} container >

            <Grid item xs={4}>
                <FormControl sx={{
                    width: '100%'
                }} >
                    <Select
                        onBlur={() => {
                            fields.order_customer_address_city?.runValidation(fields.order_customer_address_city.value)
                        }}
                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }

                        }}
                        displayEmpty
                        required
                        defaultValue={''}
                        value={fields.order_customer_address_city.value}
                        onChange={handleChangeCity}
                        input={<OutlinedInput />}
                        className='payment-modal-select'
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5),
                            width: '100%'
                        }}
                        placeholder={'select_city'}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem selected value="">
                            {__("select_city")}
                        </MenuItem>
                        {addresses?.map((city) => (
                            <MenuItem
                                key={city?.code}
                                value={city?.code}
                            >
                                {city?.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {fields.order_customer_address_city?.error && (
                        <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.order_customer_address_city?.error}</Typography>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{
                    width: '100%'
                }} >
                    <Select
                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }
                        }}
                        onBlur={() => {
                            fields.order_customer_address_district?.runValidation(fields.order_customer_address_district.value)
                        }}
                        required
                        displayEmpty
                        value={fields.order_customer_address_district.value}
                        defaultValue={''}
                        onChange={handleChangeDistrict}
                        input={<OutlinedInput />}
                        placeholder={'select_district'}
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5),

                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <InputLabel id="select_district"></InputLabel>
                        <MenuItem selected value="">
                            {__("select_district")}
                        </MenuItem>
                        {districtByCity?.map((district) => (
                            <MenuItem
                                key={district?.code}
                                value={district?.code}
                            >
                                {district?.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {fields.order_customer_address_district?.error && (
                        <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.order_customer_address_district?.error}</Typography>
                    )}
                </FormControl>

            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{
                    width: '100%'
                }} >
                    <Select
                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }
                        }}
                        onBlur={() => {
                            fields.order_customer_address_ward?.runValidation(fields.order_customer_address_ward.value)
                        }}
                        required
                        displayEmpty
                        value={fields.order_customer_address_ward.value}
                        defaultValue={''}
                        onChange={handleChangeWard}
                        placeholder={'select_ward'}
                        input={<OutlinedInput />}
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5)
                        }}

                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem selected value="">
                            {__("select_ward")}
                        </MenuItem>
                        {wardByDistrict?.map((ward) => (
                            <MenuItem
                                key={ward?.code}
                                value={ward?.code}
                            >
                                {ward?.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {fields.order_customer_address_ward?.error && (
                        <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.order_customer_address_ward?.error}</Typography>
                    )}
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default AddressForm