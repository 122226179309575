import React, { memo } from 'react'

//Helpers
import helpers from 'helpers'

//Interface
import { TypeYourIncome } from '../payment.interface'
import __ from 'languages/index'

const DetailPaymentIncome = ({ yourIncome }: { yourIncome: TypeYourIncome[] }) => {
  return (
    <div className="p-4 bg-white mt-4 rounded">
      <div className="d-flex flex-column">
        <div className="w-full d-flex justify-content-between align-items-center flex-wrap flex-grow-1">
          <h4>{__("account_balance")}</h4>
          <div className="text-end">
            <p className="fs-5 my-0 fw-bold">
              {`${helpers.convertToCommasFormat(yourIncome[0]?.current_token || '0')} ${__("currency_unit")}`}
            </p>
          </div>
        </div>
        <p className="my-2">{__("payment_income_text")}</p>
      </div>
    </div>
  )
}

export default memo(DetailPaymentIncome)
