import { SvgIcon, SvgIconProps } from '@mui/material'

const GroupUser = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.7769 6.97975C10.7769 8.81309 12.2686 10.2964 14.0936 10.2964C15.9269 10.2964 17.4103 8.81309 17.4103 6.97975C17.4103 5.14642 15.9269 3.66309 14.0936 3.66309C12.2603 3.66309 10.7769 5.14642 10.7769 6.97975ZM12.4436 6.98809C12.4436 6.07975 13.1853 5.33809 14.0936 5.33809C15.0019 5.33809 15.7436 6.07975 15.7436 6.98809C15.7436 7.89642 15.0019 8.63809 14.0936 8.63809C13.1853 8.63809 12.4436 7.89642 12.4436 6.98809ZM2.97694 8.07975C2.97694 10.1131 4.62694 11.7631 6.66028 11.7631V11.7548C8.69361 11.7548 10.3436 10.1048 10.3436 8.07142C10.3436 6.03809 8.69361 4.38809 6.66028 4.38809C4.62694 4.38809 2.97694 6.04642 2.97694 8.07975ZM4.64361 8.07142C4.64361 6.96309 5.54361 6.05475 6.66028 6.05475C7.76861 6.05475 8.67694 6.96309 8.67694 8.07142C8.67694 9.17975 7.76861 10.0881 6.66028 10.0881C5.55194 10.0881 4.64361 9.17975 4.64361 8.07142ZM1.71861 17.6381C1.79361 17.6631 1.87694 17.6714 1.96028 17.6714V17.6798C2.31861 17.6798 2.65194 17.4464 2.76028 17.0881C3.29361 15.3631 4.86028 14.2048 6.66861 14.2048C8.47694 14.2048 10.0519 15.3631 10.5769 17.0881C10.7103 17.5214 11.1769 17.7798 11.6186 17.6381C12.0603 17.5048 12.3019 17.0381 12.1686 16.5964C11.4269 14.1631 9.21861 12.5298 6.66861 12.5298C4.11861 12.5298 1.91028 14.1631 1.16861 16.5964C1.02694 17.0381 1.27694 17.5048 1.71861 17.6381ZM18.1353 15.6881C17.7769 15.6881 17.4519 15.4548 17.3353 15.0964C16.8936 13.6631 15.5936 12.6964 14.0853 12.6964C13.3186 12.6964 12.5936 12.9464 12.0019 13.4048C11.6353 13.6881 11.1186 13.6214 10.8353 13.2631C10.5519 12.8964 10.6186 12.3798 10.9769 12.0964C11.8686 11.3964 12.9436 11.0298 14.0853 11.0298C16.3269 11.0298 18.2769 12.4714 18.9269 14.6048C19.0603 15.0464 18.8103 15.5131 18.3769 15.6464C18.2936 15.6714 18.2103 15.6798 18.1353 15.6798V15.6881Z" fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
export default GroupUser
