import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import calendar_list from './calendar.list';
import calendar_view from './calendar.view';
import addEvent_view from '../event/event.update';
/**
*   Create index file for Comment
*/

export default function Calendar() {
  let useParam = {} as any;
    useParam = useParams();
  let Param = useParam.slug || 'list';

  let ActualPage: any;
  switch (Param) {

    case 'list':
      ActualPage = calendar_list;
      break;
    case 'view':
      ActualPage = calendar_view;
      break;
    case 'add':
      ActualPage = addEvent_view;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return (
    <div className="mainFrame">
        {<ActualPage />}
    </div>
  );
}
