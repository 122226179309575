import '../media/css/index.scss'
import googlePlay from '../media/image/google-play.png'
import appStore from '../media/image/app-store.png'
import __ from "languages/index";

const MobileInfo = () =>{
    return(
        <div className='mobile-container '>
          <div className='container-sm'>
            <h1 className='mobile-title' >{__("mobile_app_info_title")}</h1>
            <p className='mobile-des' >
              {__("mobile_app_info_sub_title")}
            </p>
            <div className='row mt-5 mobile-info-content'>
              <div className='col-12  col-sm-6 p-4 mobile-info-store'>
                <h5 className='mobile-sub-title' >
                  <img src={appStore} alt='AppStore' style={{width: '30px', height: '30px', marginRight: '8px', marginBottom: '10px'}}/>
                  {__("mobile_app_info_ios")}
                </h5>
                <div>
                  {__("mobile_app_info_ios_text")}
                  <br />
                  {__("mobile_app_info_ios_sub_text")}
                </div>
              </div>
              <div className='col-12  col-sm-6 p-4 mobile-info-store'>
                <h5 className='mobile-sub-title'>
                  <img src={googlePlay} alt='GooglePlay' style={{width: '30px', height: '30px',  marginRight: '8px', marginBottom: '10px'}}/>
                  {__("mobile_app_info_android")}
                </h5>
                <div>
                  {__("mobile_app_info_android_text")}
                  <br />
                  {__("mobile_app_info_android_sub_text")}
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}
export default MobileInfo;
