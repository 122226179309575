import { SvgIcon, SvgIconProps } from '@mui/material'

const BlockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.22297 4.22068C6.21349 2.23075 8.96302 1 12 1C18.0751 1 23 5.92487 23 12C23 15.037 21.7692 17.7866 19.7792 19.7771C19.7789 19.7775 19.7785 19.7778 19.7782 19.7782C19.7774 19.7789 19.7767 19.7797 19.7759 19.7805C17.7855 21.7697 15.0364 23 12 23C5.92487 23 1 18.0751 1 12C1 8.96263 2.23106 6.21278 4.22145 4.2222C4.22158 4.22208 4.2217 4.22195 4.22183 4.22183C4.22221 4.22144 4.22259 4.22106 4.22297 4.22068ZM4.9681 6.38231C3.73647 7.92199 3 9.87499 3 12C3 16.9706 7.02944 21 12 21C14.125 21 16.078 20.2635 17.6177 19.0319L4.9681 6.38231ZM19.0319 17.6177L6.38231 4.9681C7.92199 3.73647 9.87499 3 12 3C16.9706 3 21 7.02944 21 12C21 14.125 20.2635 16.078 19.0319 17.6177Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default BlockIcon
