import { IconButton, Modal, styled, Typography } from '@mui/material'
import { CloseIcon } from '../../../icons'
import React from 'react'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import __, { ___ } from 'languages/index'

interface TypeNotificationModal{
  handleCloseModalNotification: ()=> void
  isShowModalNotification: boolean
  textContent: string
}

const NotificationModal = ({handleCloseModalNotification,isShowModalNotification, textContent}:TypeNotificationModal)=>{
  return(
    <NotificationModalContainer open={isShowModalNotification} onClose={handleCloseModalNotification}>
      <ModalContainer sx={{maxWidth: theme => theme.spacing(62.5)}}>
        <ModalHeader direction={'row'} alignItems={'center'}>
          <Typography variant={'h6'} className={'title'}>
            {__('modal_notification_title')}
          </Typography>
          <IconButton onClick={handleCloseModalNotification}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody>
          <Typography variant={'subtitle1'} textAlign={'center'} fontWeight={600} textTransform='none'>
            {textContent}
          </Typography>
        </ModalBody>
      </ModalContainer>
    </NotificationModalContainer>

  )
}
export default NotificationModal

const NotificationModalContainer = styled((Modal))(({theme})=>({
}))
