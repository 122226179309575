import axios from "axios";
import helpers from "helpers/index";
import {TypedCourse, TypedMember} from "entities/courses/courses.interface";
import { IQueryParams } from "config/reducer.utils";


export function getListCourses(param: object) {
  return axios.get<TypedCourse[]>(`course/list${helpers.buildEndUrl(param)}`)
}

export function getListCourseJoined(param: object) {
  return axios.get<TypedCourse[]>(`course/list-join${helpers.buildEndUrl(param)}`)
}

export function getListMembers(params: IQueryParams) {
  const courseId = params?.course_id
  delete params.course_id
  helpers.clearValueEmptyInObject(params)
  return axios.get<TypedMember[]>(`course/list-available-member/${courseId}`, {params: params})
}