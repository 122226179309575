import axios from 'axios'
import {
  Response,
  CreateProductCategoryPayload,
  UpdateProductCategoryPayload
} from 'entities/shop/service/service.type'

import { TypedProductCategory } from '../../../interfaces/shop.interface'
import { TypedStock } from 'entities/shop/interface'


// export const categoryApi = axios.create({
//   baseURL: process.env.REACT_APP_AJAX_URL_V2 + '/product_category',
//   headers: {
//     ['X-Authorization']:
//       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MzU4MTExNTEsImRhdGEiOnsiX2lkIjoiNjRkNWRlMmZhYTJmZWQxNzU4NDUxMGQyIiwia2V5IjoiODRhYWM3M2JjZWZiZTE1NGUxMWY1MjE5MWUyYTY1ZWMzYTI0MjU3YWEwOGNlMzQyYWYxYTJiZjYzZDA0NTAwYiIsInNpZ25hdHVyZSI6IjBlNzI0YjBhYmJlY2QxMDA3YWY3M2M5Y2M2ZDEzY2EyNGY3MWNhNjQxMmVhZWZlNmNkOGJiZWU4MWY3ZGUyYzciLCJzZXNzaW9uIjoiNjU5NTJjY2Y1MmNjY2JlNGU0Nzk0ZDQxIn0sImlhdCI6MTcwNDI3NTE1MX0._r3bwu32scLrZLJeVubFB4hDkTeUnxe_CT-G4z42doM'
//   }
// })
export const getProductCategoryById = (
  categoryId: string
): Response<TypedProductCategory, string> => {
  return axios.get(`v2/product_category/${categoryId}`)
}

export const createProductCategory = async (
  payload: CreateProductCategoryPayload
): Response<TypedProductCategory, CreateProductCategoryPayload> => {
  return axios.post('v2/product_category', payload)
}

export const updateProductCategory = async (
  payload: UpdateProductCategoryPayload
): Response<TypedProductCategory, UpdateProductCategoryPayload> => {
  const url = `v2/product_category/${payload.category_id}`
  return axios.patch(url, payload)
}

export const getProductCategories = async (): Response<TypedProductCategory[], void> => {
  return axios.get('v2/product_category')
}

export const deleteCategoryById = async (categoryId: string): Response<void, string> => {
  const url = `v2/product_category/${categoryId}`
  return axios.delete(url)
}

export const getListStock = async (params: object) => {
  return await axios.get<TypedStock[]>('v2/stock', { params })
}
