import { createSlice } from '@reduxjs/toolkit'
import { PlayingPodcast, PlayingPodcastState } from '../podcast.types'
import { PodcastAction, PodcastUpdateAction } from './podcastAction'
import { IRootState } from 'config/store'
import { getItemFromLocalStorage, setItemToLocalStorage } from '../utils'
import { Key_Of_Playing_Podcast_In_LocalStorage } from '../utils/constant'

type State = {
  playingPodcast: PlayingPodcast | null
  playingPodcastState: PlayingPodcastState
}

const initialState: State = {
  playingPodcast: getItemFromLocalStorage(Key_Of_Playing_Podcast_In_LocalStorage) || null,
  playingPodcastState: {
    currentTime: 0,
    duration: 0,
    isPlaying: false,
    volume: 0.4,
    prevVolume: 0
  }
}

const podcastSlice = createSlice({
  initialState,
  name: 'podcast',
  reducers: {
    setPlayingPodcast: (state: State, action: PodcastAction) => {
      state.playingPodcast = action.payload
      state.playingPodcastState.isPlaying = true
      setItemToLocalStorage(Key_Of_Playing_Podcast_In_LocalStorage, action.payload)
    },
    updatePlayingPodcastInfo: (state: State, action: PodcastUpdateAction) => {
      return {
        ...state,
        playingPodcastState: {
          ...state.playingPodcastState,
          ...action.payload
        }
      }
    }
  }
})

export const { setPlayingPodcast, updatePlayingPodcastInfo } = podcastSlice.actions

export const getPlayingPodcast = (state: IRootState): PlayingPodcast =>
  state.podcast?.playingPodcast
export const getStatePlayingPodcast = (state: IRootState): PlayingPodcastState =>
  state.podcast?.playingPodcastState

export default podcastSlice.reducer
