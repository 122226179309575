import OrderDetail from "entities/orders/components/OrderDetail";
import {useAppDispatch, useAppSelector} from "config/store";
import {useEffect, useLayoutEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {clearEntity, getEntity} from "entities/orders/order.store.reducer";
import __ from "languages/index";
import _Helmet from "components/helmet";
import BigLayout from "../../layouts/bigLayout";


const OrderDetailView = ()=>{

  const entity = useAppSelector(state => state.order.entity);
  const [status, setStatus] = useState("");
  const dispatch = useAppDispatch();
  let useParam = useParams();

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    if(useParam.order_id){
      dispatch(getEntity({
        _id: useParam.order_id
      }))
    }
    return (() => {
      dispatch(clearEntity())
    })
  }, []);

  useEffect(() => {
    setStatus(entity?.status)
  }, [entity]);

    return (
      <BigLayout>
        <_Helmet title='order_detail_title' />
        <div className="mainFrame">
          <div className="container">
            <OrderDetail orders={entity ? [entity] : []} status={status} />
          </div>
        </div>
      </BigLayout>
    )
  };

export default  OrderDetailView;
