import { createRef } from 'react'

import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import ToastComponent from 'entities/courses/components/toast.component'
import MemberPage from './member.page'

export default function Member() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'member'
  let ActualPage: any

  switch (Param) {
    case 'member':
      ActualPage = MemberPage
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="mainFrame">
        {<ActualPage />}
    </div>
  )
}
