import React, { useEffect, useMemo } from 'react'
import { PostCommentType } from 'interfaces/home.interface'
import { Avatar, Box, IconButton, Stack, styled, Typography } from '@mui/material'
import FilledHeartIcon from 'icons/FilledHeartIcon'
import { BlockIcon, CopyIcon, FlagIcon, MenusIcon } from '../../../icons'
import { useLikeCommentMutation } from 'entities/homeNew/store/homeApi'
import { calculateTimeAgo } from 'helpers/dateAndTime'
import __ from 'languages/index'
import { useGetPostDetailQuery } from 'entities/homeNew/store/homeApi'
import AvatarComponent from 'layouts/components/AvatarComponent'

type Props = Partial<
  PostCommentType & {
    onClickCommentButton: (comment: Partial<PostCommentType> & Pick<PostCommentType, '_id'>) => void
    is_comment: string | number
  }
> &
  Pick<PostCommentType, '_id'>

const commentOptions = [
  {
    icon: <FlagIcon />,
    label: __("home_page_report_comment")
  },
  {
    icon: <BlockIcon />,
    label: __("block")
  },
  {
    icon: <CopyIcon />,
    label: __("copy_link")
  }
]
const CommentElement = (props: Props) => {
  const [mouseOverElement, setMouseOverElement] = React.useState(false)

  const {
    user_id: author,
    content: comment_content,
    is_like,
    like_number,
    updatedAt,
    createdAt,
    _id: commentId,
    onClickCommentButton,
    request_id: postId,
    is_comment
  } = props
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [isLike,setIsLike] = React.useState<boolean>(false)
  const id = open ? 'comment-options' : undefined
  const openPostOptions = Boolean(anchorEl)
  const timeAgo = useMemo(() => calculateTimeAgo(createdAt), [createdAt])

  const [like] = useLikeCommentMutation()

  const handleLike = () => {
    like({ commentId, postId })
      .unwrap()
      .then((res)=>{
        setIsLike(res?.is_like)
      })
      .catch((e)=>{
        console.log("err",e)

      })

  }

  const handleMouseHoverComment = () => {
    setMouseOverElement(true)
  }
  const handleMouseLeaveComment = () => {
    setMouseOverElement(false)
  }

  const handleCloseCommentOptions = () => {
    setAnchorEl(null)
  }

  const handOpenCommentOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(()=>{
    setIsLike(is_like)
  },[is_like])

  return (
    <CommentContainer
      direction="row"
      gap={1}
      mt={2}
      onMouseEnter={handleMouseHoverComment}
      onMouseLeave={handleMouseLeaveComment}
    >
      <AvatarComponent
          userData={author}
          permissionData={author?.permission}
          channelpermission={author}
          width={44}
          height={44}
        />
      <Stack gap={0.5} width="100%">
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <CommentContent>
            <Typography variant="body1" fontWeight="600">
              {author?.display_name}
            </Typography>
            <Typography whiteSpace={"pre-line"} variant="body1">{comment_content}</Typography>
          </CommentContent>
          {/*<IconButton*/}
          {/*  className={`menu-icon ${mouseOverElement && 'active'}`}*/}
          {/*  onClick={handOpenCommentOptions}*/}
          {/*>*/}
          {/*  <MenusIcon />*/}
          {/*</IconButton>*/}
          {/*<OptionsPopover*/}
          {/*  id={id}*/}
          {/*  elevation={6}*/}
          {/*  open={openPostOptions}*/}
          {/*  anchorEl={anchorEl}*/}
          {/*  onClose={handleCloseCommentOptions}*/}
          {/*  anchorOrigin={{*/}
          {/*    vertical: 'bottom',*/}
          {/*    horizontal: 'right'*/}
          {/*  }}*/}
          {/*  transformOrigin={{*/}
          {/*    vertical: 'top',*/}
          {/*    horizontal: 'right'*/}
          {/*  }}*/}
          {/*  options={commentOptions}*/}
          {/*/>*/}
        </Stack>

        <Stack direction="row" alignItems={'center'}>
          <Stack direction="row" gap={2} alignItems={'center'}>
            <Typography
              color={!isLike ? 'text.secondary' : 'primary.main'}
              fontWeight={600}
              role="button"
              onClick={handleLike}
            >
              {__(isLike ? "home_liked":"home_post_footer_like")}
            </Typography>
            {
              Boolean(is_comment) && (
                <Typography
                  color="text.secondary"
                  fontWeight={600}
                  role="button"
                  onClick={() => onClickCommentButton(props)}
                >
                  {__("home_feedback")}
                </Typography>
              )
            }
            <Typography variant="body2" color="text.secondary">
              {timeAgo}
            </Typography>
          </Stack>
          {like_number > 0 && (
            <Typography marginLeft="auto" variant="body2" color="text.secondary">
              {like_number}
              <FilledHeartIcon color="primary" fontSize={'small'} />
            </Typography>
          )}
        </Stack>
      </Stack>
    </CommentContainer>
  )
}

export default CommentElement

const CommentContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  '& .menu-icon': {
    visibility: 'hidden',
    transform: 'rotate(90deg)',
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&.active': {
      visibility: 'visible'
    }
  }
}))

const CommentContent = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.background.default,
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  '&:hover': {}
}))
