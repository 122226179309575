//Library
import {memo, useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'

//Helpers
import helpers from 'helpers/index'

//Scss
import '../media/about.modal.upload.image.scss'
import __ from "languages/index";

const ModalUploadImage = ({ show, onClose }: { show: boolean; onClose: Function }) => {
  const [isShow, setIsShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState<string>('')
  const [isVideo, setIsVideo] = useState<boolean>(false)
  const [dataFile, setDataFile] = useState<File>()

  const closeOnly = useCallback(() => {
    setIsShow(false)
    onClose?.call(this, false)
  }, [])

  const closeAndAgree = useCallback(async () => {
    setLoading(true)
    await helpers.sleep(1000)
    setIsShow(false)
    onClose?.call(this, true, dataFile)
    await helpers.sleep(500)
    setLoading(false)
  }, [dataFile])

  const handleChangeImage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setDataFile(file)
      setIsVideo(file.type.startsWith('video/'))
      setImage(URL.createObjectURL(file))
    }
  }, [])

  useEffect(() => {
    return () => {
      if (!image) return
      URL.revokeObjectURL(image)
    }
  }, [image])

  useEffect(() => {
    setIsShow(show)
    setImage('')
    setIsVideo(false)
  }, [show])

  return (
    <>
      <Modal show={isShow} onHide={closeOnly} id="modalUploadImage">
        <Modal.Header closeButton>
          <Modal.Title>{__("about_page_modal_upload_title")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {image && (
            <div className="modalUploadImage_wrapper">
              <input
                type="file"
                id="fileInput"
                accept="image/*, video/*"
                onChange={handleChangeImage}
                style={{ display: 'none' }}
              />
              {!isVideo && <img className="modalUploadImage_image" src={image} alt="image" />}
              {isVideo && <video className="modalUploadImage_video" src={image} controls></video>}
              <label htmlFor="fileInput" className="modalUploadImage_button" role="button">
                {__("about_page_modal_upload_label")}
              </label>
            </div>
          )}
          {!image && (
            <div className="modalUploadImage_button_plus_wrapper">
              <input
                type="file"
                id="fileInput"
                accept="image/*, video/*"
                onChange={handleChangeImage}
                style={{ display: 'none' }}
              />
              <label htmlFor="fileInput" role="button" className="modalUploadImage_button_plus">
                <FontAwesomeIcon className="modalUploadImage_icon_plus" icon={faPlus} />
              </label>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeOnly}>
            {__("btn_close")}
          </Button>
          <Button
            variant="primary"
            className="button-event"
            disabled={loading || !dataFile}
            onClick={closeAndAgree}
          >
            {loading ? `${__("btn_processing")}` : `${__("about_page_modal_upload_btn")}`}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default memo(ModalUploadImage)
