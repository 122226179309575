import '../media/css/index.scss'
import {Table} from "react-bootstrap";
import React from "react";
import checkIcon from '../media/image/check-mark.png'
import __ from "languages/index";

const MoblileTableCompare = () =>{
  return(
    <div  className='mobile-container '>
      <div className = 'container-sm'>
        <h1 className='mobile-title'>{__("mobile_app_table_title")}</h1>
        <p className='mobile-des' >
          {__("mobile_app_table_sub_title")}
        </p>
        <div className="table-responsive-lg">
          <Table striped borderless hover  responsive="lg" className='mobile-table'>
            <thead >
            <tr>
              <th style={{minWidth:140}}>{''}</th>
              <th style={{minWidth:180}}>{__("mobile_app_table_private_app")}</th>
              <th style={{minWidth:180}}>{__("mobile_app_table_using_gamifa")}</th>
            </tr>
            </thead>
            <tbody>
            <tr key={''} className=''>
              <td>{__("mobile_app_table_receive_notification")}</td>
              <td>
                <ul style={{padding: '0'}}>
                  <li style={{listStyle: "none"}}>{__("mobile_app_table_no_spam")}</li>
                  <li style={{listStyle: "none"}}>{__('mobile_app_table_receive_private_notifications')}</li>
                  <li style={{listStyle: "none"}}>{__("mobile_app_table_complete_control")}</li>
                </ul>
              </td>
              <td>{__("mobile_app_table_receive_notification_from_channel")}</td>
            </tr>
            <tr>
              <td>{__("mobile_app_table_name_app")}</td>
              <td>{__("mobile_app_table_name_private_app")}</td>
              <td>{__("mobile_app_table_name_gamifa")}</td>
            </tr>
            <tr>
              <td>{__("mobile_app_table_interact")}</td>
              <td>
                <img src={checkIcon} alt='checkIcon' style={{width: '30px', height: '30px'}}/>
              </td>
              <td>
                <img src={checkIcon} alt='checkIcon' style={{width: '30px', height: '30px'}}/>
              </td>
            </tr >
            <tr>
              <td>{__("mobile_app_table_interface")}</td>
              <td>{__("mobile_app_table_interface_custom")}</td>
              <td>{__("mobile_app_table_interface_use_gamifa")}</td>
            </tr>
            <tr>
              <td>{__("mobile_app_table_function")}</td>
              <td>{__("mobile_app_table_feature_unlimited")}</td>
              <td>{__("mobile_app_table_feature_basic")}</td>
            </tr>
            </tbody>
          </Table>
          <p>{__("mobile_app_table_name")}</p>
        </div>
      </div>

    </div>
  )
}
export default MoblileTableCompare
