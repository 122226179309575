import BigLayout from '../../layouts/bigLayout'
import { Box, styled } from '@mui/material'
import ShopBreadcrumb from 'entities/shop/components/shop.breadcrumb'
import __ from 'languages/index'
import ShopPaymentAddress from 'entities/shop/components/shop.payment.address'
import ShopPaymentProduct from 'entities/shop/components/shop.payment.product'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ItemInCart, TypedAddressBook, TypedLogistic, TypedStock } from './interface'
import helpers from 'helpers'
import ShopPaymentMethod from './components/shop.payment.method'
import {
  createAddressOfShop,
  deleteAddressOfShop,
  getAddressOfShop,
  getLogisticOfShop,
  orderOfShop,
  removeCartItemAfterOrderOfShop,
  updateDefaultAddressOfShop
} from './service/cart.service'
import { AxiosError, AxiosResponse } from 'axios'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { sortByDistance, uniqueStockId } from './helper'
import { caculatorFeeOfOrder } from './service/order.service'

type TypedLogisticData = Partial<
  TypedLogistic & {
    expected_delivery_date: string
    price_for_this_transaction: number
    is_select?: boolean
  }
>

interface TypedOrder {
  logisticData: Partial<TypedLogisticData>[]
  products: ItemInCart[]
  stock: Partial<TypedStock> & { stock_id: string }[]
}

const ShopPayment = () => {
  const navigate = useNavigate()
  const [productData, setProductData] = useState<ItemInCart[]>([])
  const [logisticData, setLogisticData] = useState<TypedLogistic[]>([])
  const [listAddress, setListAddress] = useState<TypedAddressBook[]>([])
  const [orderData, setOrderData] = useState<TypedOrder[]>([
    { stock: null, logisticData: [], products: [] }
  ])

  const toast = useToast()

  const handleOrder = useCallback(
    async (values: { order_delivery_type: string; order_note: string }, _function: Function) => {
      try {
        if (helpers.isEmpty(listAddress)) {
          _function()
          return toast.show({
            content: `${__('please_add_address')}`,
            type: EnumTypeToast.Error
          })
        }
        const address = {
          order_customer_address_city: listAddress?.[0]?.user_address_city,
          order_customer_address_district: listAddress?.[0]?.user_address_district,
          order_customer_address_ward: listAddress?.[0]?.user_address_ward,
          order_customer_name: listAddress?.[0]?.user_display_name,
          order_customer_phonenumber: listAddress?.[0]?.user_phonenumber,
          customer_note: values?.order_note.trim()
        }

        orderData?.forEach(async (data: TypedOrder) => {
          const productOrder = data?.products?.map((value: ItemInCart) => ({
            product_id: value?.product_id,
            item_quantity: value?.item_quantity
          }))

          const logistic = data?.logisticData?.find((value: TypedLogisticData) => value?.is_select)
          helpers.clearValueEmptyInObject(address)

          const formData = {
            order_delivery_type: values?.order_delivery_type,
            stock_id: data?.stock?.stock_id || '',
            products: [...productOrder],
            logistics: [{ logistic_id: logistic?.logistic_id }],
            fees: [
              {
                //Hard code order_fee_name
                order_fee_name: 'SHIPPING_CHARGES',
                order_fee_value: logistic?.price_for_this_transaction || 0
              }
            ],
            customer_address: address
          }
          await orderOfShop(formData)
            .then((res: AxiosResponse) => {
              navigate(`/shop/order/${res?.data?.order_id}`)
            })
            .catch(error => {
              console.log('orderOfShop Error >>>', error)
              return toast.show({
                content: `${error || ''}`,
                type: EnumTypeToast.Error
              })
            })
        })
        if (productData?.[0]?.item_id) {
          const itemId = productData?.map((data: ItemInCart) => data?.item_id)
          await removeCartItemAfterOrderOfShop(itemId.join(','))
            .then(() => {})
            .catch((error: AxiosError) => {
              console.log('removeCartItemAfterOrderOfShop Error >>>', error)
            })
        }
        _function()
        localStorage.removeItem('product_cart')
        toast.show({
          content: `${__('order_success')}`,
          type: EnumTypeToast.Success
        })
      } catch (error) {
        _function()
        console.log('Handle Order Error >>>', error)
      }
    },
    [orderData, listAddress, logisticData, productData]
  )

  const handleUpdateNewAddress = useCallback(
    (addressDefault: TypedAddressBook) => {
      const oldAddressDefault = { ...listAddress?.[0], is_default_address: 0 }
      listAddress.splice(0, 1, oldAddressDefault)
      const newAddress = listAddress.filter(
        (data: TypedAddressBook) => addressDefault?.address_id !== data?.address_id
      )
      setListAddress([addressDefault, ...newAddress])
    },
    [listAddress]
  )

  const handleUpdateDefaultAddress = useCallback(
    (id: string) => {
      updateDefaultAddressOfShop(id, { is_default_address: 1 })
        .then((res: AxiosResponse) => {
          handleUpdateNewAddress(res?.data)
        })
        .catch((error: AxiosError) => {
          console.error('updateDefaultAddressOfShop Error >>>', error)
        })
    },
    [listAddress]
  )

  const handleDeleteAddress = useCallback(
    (id: string) => {
      deleteAddressOfShop(id)
        .then(() => {
          const findIndex = listAddress?.findIndex(
            (data: TypedAddressBook) => data?.address_id === id
          )
          listAddress?.splice(findIndex, 1)
          setListAddress(listAddress => [...listAddress])
          toast.show({
            content: `${__('address_delete_success')}`,
            type: EnumTypeToast.Success
          })
        })
        .catch(error => {
          console.error('deleteAddressOfShop Error >>>', error)
          toast.show({
            content: `${error || ''}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [listAddress]
  )

  const handleCreateAddress = useCallback(
    values => {
      const formData = {
        ...values,
        is_default_address: helpers.isEmpty(listAddress) ? 1 : values?.is_default_address
      }

      helpers.clearValueEmptyInObject(formData)
      createAddressOfShop(formData)
        .then((res: AxiosResponse) => {
          res?.data?.is_default_address && !helpers.isEmpty(listAddress)
            ? handleUpdateNewAddress(res?.data)
            : setListAddress(listAddress => [...listAddress, res?.data])
          toast.show({
            content: `${__('address_add_success')}`,
            type: EnumTypeToast.Success
          })
        })
        .catch(error => {
          console.error('createAddressOfShop Error >>>', error)
          toast.show({
            content: `${error || ''}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [listAddress]
  )

  const totalPayment = useMemo(() => {
    if (helpers.isEmpty(productData)) return { totalPriceProduct: 0, totalFee: 0 }
    let total = 0
    let fee = 0
    productData?.forEach(
      (data: ItemInCart) => (total += data?.item_quantity * data?.product?.product_price)
    )
    orderData?.forEach((data: TypedOrder) => {
      fee +=
        data?.logisticData?.find((item: TypedLogisticData) => item?.is_select)
          ?.price_for_this_transaction || 0
    })

    return { totalPriceProduct: total + fee, totalFee: fee }
  }, [productData, orderData])

  useEffect(() => {
    if (helpers.isEmpty(productData) || helpers.isEmpty(listAddress)) return

    const address = listAddress?.[0]
    const uniqueStockData = uniqueStockId(productData)
    const sortData = sortByDistance(
      address?.stock_address_city_latitude,
      address?.stock_address_city_longitude,
      uniqueStockData
    )

    sortData?.forEach(async (data, idx) => {
      const formData = {
        stock_id: data?.stock?.stock_id,
        products: data?.products?.map(value => ({
          product_id: value?.product_id,
          item_quantity: value?.item_quantity
        })),
        customer_address: {
          order_customer_address_city: address?.user_address_city,
          order_customer_address_district: address?.user_address_district,
          order_customer_address_ward: address?.user_address_ward
        }
      }

      await caculatorFeeOfOrder(formData)
        .then((res: AxiosResponse) => {
          const newValue: TypedOrder = {
            ...sortData[idx],
            logisticData: res?.data?.map((value: Partial<TypedLogistic>, idx: number) => ({
              ...value,
              is_select: idx === 0
            }))
          }
          const isInArr = orderData.some(
            (item: TypedOrder) => item?.stock?.stock_id === newValue?.stock?.stock_id
          )
          if (isInArr) return
          setOrderData(orderData => [
            ...orderData?.filter((data: TypedOrder) => data?.stock),
            newValue
          ])
        })
        .catch(error => {
          console.log('caculatorFeeOfOrder Error >>', error)
          navigate(`/shop/cart`)
          localStorage.removeItem('product_cart')
        })
    })
  }, [productData, listAddress])

  useEffect(() => {
    getAddressOfShop({ sort: 'is_default_address:desc' })
      .then((res: AxiosResponse) => {
        setListAddress(res?.data)
      })
      .catch((error: AxiosError) => {
        console.log('getAddressOfShop Error >>>', error)
      })
  }, [])

  useEffect(() => {
    const dataJson = localStorage.getItem('product_cart')
    if (helpers.isJson(dataJson)) {
      const productData = JSON.parse(dataJson)
      if (helpers.isEmpty(productData)) return navigate('/shop')
      setProductData(productData)
      setOrderData([{ stock: null, logisticData: [], products: productData }])
    } else {
      navigate('/shop')
    }
  }, [])

  useEffect(() => {
    getLogisticOfShop()
      .then((res: AxiosResponse) => {
        setLogisticData([...res?.data])
      })
      .catch((error: AxiosError) => {
        console.log('getLogisticOfShop >>>', error)
      })
  }, [])

  return (
    <BigLayout>
      <ShopPaymentContainer>
        <ShopBreadcrumb breadcrumbName={{ '/shop/payment': __('breadcrumb_payment') }} />
        <Box width={'100%'}>
          <ShopPaymentAddress
            addressData={listAddress}
            handleDeleteAddress={handleDeleteAddress}
            handleUpdateDefaultAddress={handleUpdateDefaultAddress}
            handleCreateAddress={handleCreateAddress}
          />
        </Box>
        <Box className="second_body">
          <Box width={'100%'}>
            <ShopPaymentProduct orderData={orderData} handleUpdateOrderData={setOrderData} />
          </Box>
          <Box>
            <ShopPaymentMethod totalPayment={totalPayment} handleSubmit={handleOrder} />
          </Box>
        </Box>
      </ShopPaymentContainer>
    </BigLayout>
  )
}
export default ShopPayment

const ShopPaymentContainer = styled(Box)(({ theme }) => ({
  // background: theme.palette.background.paper,
  '& .second_body': {
    borderRadius: theme.spacing(1.25),
    background: theme.palette.background.paper,
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.08)`
  }
}))
