import React, { forwardRef, useCallback } from 'react'
import { TypedChallengeItem } from '../challenge.interface'
import { Col, Image } from 'react-bootstrap'
import EventDefault from 'entities/event/media/event_default.jpg'
import { useNavigate } from 'react-router-dom'
import DateTime from 'date-and-time'
import { useAppSelector } from 'config/store'
import __ from "languages/index";
import { Box, Button, Stack, Typography, styled } from '@mui/material'
import { CalendarMinusIcon, CheckIcon, PenIcon, TrashIcon } from 'icons'
import GroupUser from 'icons/GroupUserIcon'
import __helpers from 'helpers/index'
import { TabPaneChallengeData } from './challenge.list/challenge.list.component'

interface Props {
  item: TypedChallengeItem
  onDelete: (item: TypedChallengeItem) => void
  isCurrent?: boolean
  isPast?: boolean,
  joinChallengeHandler: (item: TypedChallengeItem) => void,
  currentSelected: TabPaneChallengeData
}

const ChallengeItem = (props: Props, ref) => {
  const { item, onDelete, isCurrent, currentSelected, joinChallengeHandler } = props
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in);
  const permission_data = useAppSelector(state => state.user.permission_data)

  const navigate = useNavigate()

  let DATA_ACTION = [
    {
      id: 'edit',
      type: 'edit',
      title: `${__("challenge_item_edit")}`,
      icon: <PenIcon />
    },
    {
      id: 'delete',
      type: 'delete',
      title: `${__("challenge_item_delete")}`,
      icon: <TrashIcon />
    }
  ]

  if (currentSelected !== TabPaneChallengeData.UPCOMMING) {
    delete DATA_ACTION[0]
  }
  // Function
  const _gotoDetail = useCallback(() => {
    if (is_user_logged_in) {
      navigate(`/challenge/detail/${item?._id}`)
    }
  }, [])

  const _onEdit = useCallback(() => {
    if (is_user_logged_in) {
      navigate(`/challenge/add/${item?._id}`)
    }
  }, [])

  const _onActionClick = useCallback(i => {
    if (is_user_logged_in) {

      if (i?.type == 'edit') {
        _onEdit()
        return
      }
      if (i?.type == 'delete') {
        onDelete(item)
        return
      }
    }
  }, [])

  return (
    <ChallengeItemContainer md={3} xs={6} className="mb-3" ref={ref}>
      <div className="position-relative">
        <Image
          onClick={_gotoDetail}
          className="clickable image-challenge-item w-100"
          src={item?.avatar?.media_thumbnail || item?.avatar?.media_url || EventDefault}
        />
      </div>
      <Box className="content_item" >
        <Typography onClick={_gotoDetail} className={'challange_name'} variant='body1' >{item?.title}</Typography>

        <Stack className='challange_date' mt={1.2} flexDirection={'row'} alignItems={'center'} color='text.secondary'>
          <CalendarMinusIcon sx={{
            marginRight: theme => theme.spacing(1)
          }} /> {DateTime.format(new Date(item?.start_time), 'DD/MM')} -{' '}{DateTime.format(new Date(item?.end_time), 'DD/MM/YYYY')}
        </Stack>

        <Stack mt={1.2} className='challange_user_join' flexDirection={'row'} alignItems={'center'} color='text.secondary'>
          <GroupUser sx={{
            marginRight: theme => theme.spacing(1)
          }} /> {item?.join_number > 0 ? item?.join_number : 0}
        </Stack>

        <Stack mt={1.2} flexDirection={'row'} alignItems={'center'} justifyContent="center" className='challange_action'>

          {currentSelected !== TabPaneChallengeData.FINISHED ? __helpers.current_user_can('boss', permission_data) ? (
            DATA_ACTION.map(item => (
              <Button key={item.id} onClick={() => _onActionClick(item)} className='btn_remove' variant='outlined'>{item.icon}</Button>
            ))

          ) : (
            <Button
              onClick={() => joinChallengeHandler(item)}
              variant={item?.is_join ? "outlined" : 'contained'}
              sx={{
                width: '100%',
                color: item?.is_join ? 'primary.main' : '#fff'
              }}> {item?.is_join ? <CheckIcon sx={{
                marginRight: theme => theme.spacing(1)
              }} /> : null}  {__(item?.is_join ? 'challenge_detail_status_joined' : 'challenge_detail_status_join')}</Button>
          ) : null

          }
        </Stack>
      </Box>
    </ChallengeItemContainer>
  )
}

export default forwardRef(ChallengeItem)

const ChallengeItemContainer = styled(Col)(({ theme }) => ({

  '& .content_item': {
    padding: theme.spacing(2, 3),
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    background: '#fff',
    height: '204px',

    '& .challange': {
      '&_name': {
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        height: '48px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold'
      },

      '&_date': {
        fontSize: theme.spacing(1.5)
      },

      '&_action': {

        '& .btn_remove': {
          width: '44px',
          height: '44px',
          padding: 0,
          borderColor: '#E0E2E6',
          color: '#000',
          marginLeft: theme.spacing(1)
        }
      }

    }
  }
}))

