import React from 'react'
import { Box, Paper, Stack } from '@mui/material'
import ProductImageSlide from 'entities/shop/components/ProductImageSlide'
import ProductProperties from 'entities/shop/components/ProductProperties'
import { TypedProduct } from 'entities/shop/interface'
import { productSoldQuantity } from '../helper'

type Props = {
  product: TypedProduct;
  clickViewImages: (index: number, images: string[]) => void
}

const ProductOverview = ({ product, clickViewImages}: Props) => {
  return (
    <Paper
      sx={{
        padding: theme => theme.spacing(3)
      }}
    >
      <Stack gap={3} direction={'row'}>
        <Box sx={{ flex: 1, maxWidth: '450px' }}>
          <ProductImageSlide clickViewImages={clickViewImages} images={product?.product_avatar} isSoldOut={productSoldQuantity(product)?.totalProduct === 0} />
        </Box>
        <Box sx={{ flex: 1, width: '100%' }}>
          <ProductProperties product={product} productSold={productSoldQuantity(product)?.productSold} isSoldOut={productSoldQuantity(product)?.totalProduct === 0} />
        </Box>
      </Stack>
    </Paper>
  )
}

export default ProductOverview
