import { SvgIcon, SvgIconProps } from '@mui/material'

export default function NotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M11.9979 22.5051C10.6479 22.5051 9.29787 22.1151 8.12787 21.3351C7.66787 21.0251 7.54787 20.4051 7.84787 19.9451C8.14787 19.4851 8.77787 19.3651 9.23787 19.6651C10.9179 20.7851 13.0879 20.7851 14.7679 19.6651C15.2279 19.3551 15.8479 19.4851 16.1579 19.9451C16.4679 20.4051 16.3379 21.0251 15.8779 21.3351C14.6979 22.1151 13.3579 22.5051 12.0079 22.5051H11.9979ZM20.0679 18.4951H3.92787C3.37787 18.4951 2.86787 18.1951 2.60787 17.7051C2.34787 17.2151 2.37787 16.6251 2.68787 16.1651L2.82787 15.9551C3.59787 14.8051 3.99787 13.4551 3.99787 12.0751V9.49512C3.99787 5.08512 7.58787 1.49512 11.9979 1.49512C16.4079 1.49512 19.9979 5.08512 19.9979 9.49512V12.0751C19.9979 13.4651 20.4079 14.8051 21.1779 15.9551L21.3179 16.1651C21.6279 16.6251 21.6479 17.2151 21.3879 17.7051C21.1279 18.1951 20.6179 18.4951 20.0679 18.4951ZM4.83787 16.4951H19.1579C18.3979 15.1451 17.9979 13.6351 17.9979 12.0751V9.49512C17.9979 6.18512 15.3079 3.49512 11.9979 3.49512C8.68787 3.49512 5.99787 6.18512 5.99787 9.49512V12.0751C5.99787 13.6351 5.59787 15.1451 4.83787 16.4951Z" />
      </svg>
    </SvgIcon>
  )
}
