import { SvgIcon, SvgIconProps } from '@mui/material'

const SendIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M2.28203 5.23569C1.24303 3.36603 3.24399 1.27959 5.15527 2.23495L20.8004 10.0552C22.4033 10.8564 22.4033 13.1435 20.8004 13.9447L5.15527 21.7649C3.24399 22.7203 1.24303 20.6339 2.28203 18.7642L5.9941 12.0844C6.02331 12.0319 6.02331 11.968 5.9941 11.9154L2.28203 5.23569Z" />
      </svg>
    </SvgIcon>
  )
}

export default SendIcon
