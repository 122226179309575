import '../media/courses.update.modules.item.scss'
import {TypedModule} from 'entities/courses/courses.interface'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Collapse} from 'react-bootstrap'
import {createModule, deleteModule, updateModule} from 'entities/courses/courses.store.reducer'
import {useAppDispatch, useAppSelector} from 'config/store'
import CoursesUpdateMediaModuleItem from 'entities/courses/components/courses.update.media.module.item'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleUp, faPlus, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import __ from 'languages/index'
import useUploadFile from '../../../hooks/useUploadFile'
import __helpers from 'helpers/index'
import helpers from 'helpers/index'
import {TypeMedia} from 'entities/channel/interface'
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

interface TypedCoursesViewModulesItemProps {
  module?: TypedModule
  isLast: boolean
  alreadyOpen?: boolean
  course_id: string
  setModalConfirmData: Function
  index: number
}

export default function CoursesUpdateModulesItem({
                                                   module,
                                                   isLast,
                                                   alreadyOpen,
                                                   course_id,
                                                   setModalConfirmData,
                                                   index
                                                 }: TypedCoursesViewModulesItemProps) {
  const [open, setOpen] = useState(!!alreadyOpen)
  const dispatch = useAppDispatch()

  const permission_data = useAppSelector(state => state.user.permission_data)
  const refDefaultValueTitle = useRef(module?.title)
  const refValueTitle = useRef(module?.title)
  const [enableBtn, setEnableBtn] = useState(false)
  const toast = useToast()
  const [isShowForm, setIsShowForm] = useState(!!module)

  const onCreateParentModule = useCallback(async () => {
    let res: any = module
      ? await dispatch(
        updateModule({
          title: refValueTitle.current.trim(),
          _id: module?._id
        })
      )
      : await dispatch(
        createModule({
          course_id: course_id,
          title: refValueTitle.current.trim()
        })
      )

    if (res?.type?.includes('fulfilled')) {
      refDefaultValueTitle.current = refValueTitle.current
      setEnableBtn(false)
      toast.show({
        content: (module ? `${__("course_update_module_success")}` : `${__("course_create_module_success")}`),
        type: EnumTypeToast.Success
      })
    } else {
      if (res?.type?.includes('rejected')) {
        toast.show({
          content: `${__("course_create_error_text")}`,
          type: EnumTypeToast.Error
        })
      }
    }
  }, [course_id])

  const handleInputTitleChange = (event: any) => {
    refValueTitle.current = event?.target?.value
    if (
      refValueTitle.current.trim().length > 0 &&
      refDefaultValueTitle.current !== refValueTitle.current
    ) {
      if (!enableBtn) {
        setEnableBtn(true)
      }
    } else {
      if (enableBtn) {
        setEnableBtn(false)
      }
    }
  }

  const onClickDeleteModule = useCallback(() => {
    setModalConfirmData({
      show: true,
      callback: onDeleteParentModule,
      description:
         `${__("course_confirm_delete_module")}` +
        (module?.title || '') +
        `${__("course_confirm_continue_deletion")}`,
      titleKeep: `${__("course_keep_module")}`
    })
  }, [])

  const onDeleteParentModule = useCallback(async () => {
    let res: any = await dispatch(deleteModule(module?._id))
    if (res?.type?.includes('fulfilled')) {
      toast.show({
        content: `${__("course_delete_module_success")}`,
        type: EnumTypeToast.Success
      })
    } else {
      if (res?.type?.includes('rejected')) {
        toast.show({
          content: `${__("course_create_error_text")}`,
          type: EnumTypeToast.Error
        })
      }
    }
  }, [])

  const renderSubModule = useCallback(
    (item: TypedModule, index: number) => {
      return (
        <div key={item?._id}>
          <UpdateSubModule
            index={index}
            setModalConfirmData={setModalConfirmData}
            item={item}
            course_id={course_id}
            parent_id={module?._id}
          />

          {module && index === module?.subModule?.length - 1 ? (
            <UpdateSubModule index={index + 1} course_id={course_id} parent_id={module?._id}/>
          ) : null}
        </div>
      )
    },
    [module?.subModule, course_id]
  )

  if (!isShowForm)
    return (
      <div className="d-flex justify-content-center mt-5 mb-3">
        <button
          onClick={() => setIsShowForm(true)}
          className={`courseupdatesubmoduleitem_addbutton_initmodule shadow-sm d-flex align-items-center`}
        >
          <FontAwesomeIcon icon={faPlus} className="courseupdatesubmoduleitem_iconplus"/>
          {__("course_create_module")}
        </button>
      </div>
    )

  return (
    <div className="text-decoration-none mb-5 bg-white">
      <div className={'courseupdatemoduleitem last_module'}>
        <div
          className={`courseupdatemoduleitem_groupheader ${
            module?.subModule?.length > 0 ? 'not_empty' : ''
          }`}
        >
          <div
            className={`d-flex align-items-center justify-content-between ${
              isLast || enableBtn ? 'force_visible' : 'hover_to_show'
            } `}
            style={{pointerEvents: 'auto'}}
          >
            <div className="d-flex align-items-center justify-content-between w-100">
              <div
                onClick={() => setOpen(!open)}
                className={`pe-2 ps-2 pt-2 pb-2 ${module ? '' : 'invisible pe-none'}`}
                aria-controls={'collapseTarget' + module?._id}
                aria-expanded={open}
              >
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className={`courseupdatemoduleitem_angleicon ${
                    open ? 'rotate_arrow_module' : ''
                  } ${module?.subModule?.length > 0 ? '' : 'invisible'}`}
                />
              </div>

              <input
                type={'text'}
                placeholder={module ? `✎ Sửa tên học phần thứ ${index + 1}` : `✎ Nhập tên học phần thứ ${index + 1}`}
                maxLength={100}
                onChange={handleInputTitleChange}
                defaultValue={module?.title || ''}
                className="courseupdatemoduleitem_inputnamemodule courseupdatemoduleitem_input"
              />
              {module ? (
                <button
                  onClick={onClickDeleteModule}
                  className={`courseupdatemoduleitem_addbutton delete_btn shadow-sm d-flex align-items-center me-2`}
                >
                  <FontAwesomeIcon icon={faTrashCan}/>
                </button>
              ) : null}
              <button
                onClick={onCreateParentModule}
                disabled={
                  !enableBtn || !helpers.current_user_can("boss", permission_data)
                }
                className={`courseupdatemoduleitem_addbutton shadow-sm d-flex align-items-center ${
                  !enableBtn || !helpers.current_user_can("boss", permission_data)
                    ? 'disable_btn pe-none'
                    : ''
                }`}
              >
                {__("course_save_module")}
              </button>
            </div>
          </div>
        </div>

        {module ? (
          module?.subModule?.length > 0 ? (
            <Collapse in={open}>
              <div id={'collapseTarget' + module?._id}>
                {module?.subModule?.map(renderSubModule)}
              </div>
            </Collapse>
          ) : (
            <UpdateSubModule index={0} course_id={course_id} parent_id={module?._id}/>
          )
        ) : null}
      </div>
    </div>
  )
}

const UpdateSubModule = ({
                           item,
                           parent_id,
                           course_id,
                           setModalConfirmData,
                           index
                         }: {
  item?: TypedModule,
  course_id: string,
  parent_id: string,
  index: number,
  setModalConfirmData?: Function
}) => {
  const permission_data = useAppSelector(state => state.user.permission_data)
  const [files, setFiles] = useState<File[]>(null)
  const toast = useToast()
  const dispatch = useAppDispatch()

  const [isShowForm, setIsShowForm] = useState(!!item)

  const refDefaultValueToCreateModule = useRef({
    course_id: course_id,
    title: item?.title || '',
    parent_id: parent_id,
    media_id: item?.media_id?._id || '',
    media_files : item?.media_files?.map((file)=>{return file?._id}) || ''
  })

  const refValueToCreateModule = useRef({
    course_id: course_id,
    title: item?.title || '',
    parent_id: parent_id,
    media_id: item?.media_id?._id || '',
    media_files : item?.media_files?.map((file)=>{return file?._id}) || ''
  })

  const handleMediaChange = (media_id: string) => {
    refValueToCreateModule.current = {
      ...refValueToCreateModule.current,
      media_id: media_id
    }

    if (
      refValueToCreateModule.current?.title.trim().length > 0 &&
      refValueToCreateModule.current?.media_id
    ) {
      if (!enableBtn) {
        setEnableBtn(true)
      }
    } else {
      if (enableBtn) {
        setEnableBtn(false)
      }
    }
  }

  const handleInputTitleChange = (event: any) => {
    refValueToCreateModule.current = {
      ...refValueToCreateModule.current,
      title: event?.target?.value
    }
    if (
      refValueToCreateModule.current?.title.trim().length > 0 &&
      refValueToCreateModule.current?.media_id &&
      refValueToCreateModule.current?.title.trim() !=
      refDefaultValueToCreateModule.current?.title.trim()
    ) {
      if (!enableBtn) {
        setEnableBtn(true)
      }
    } else {
      if (enableBtn) {
        setEnableBtn(false)
      }
    }
  }

  const [enableBtn, setEnableBtn] = useState(false)

  const acceptFile = ".pdf, .doc, .docx, .pptx, .ppt, .xlsx"
  const { srcFiles: uploadedFile } = useUploadFile(files, {
    isMultiple: true,
    acceptFile: acceptFile
  })

  useEffect(()=>{
    if(item?.media_files?.length >= 20 && __helpers.isArray(uploadedFile) && uploadedFile?.length > 0){
      setEnableBtn(false)
    }
    if(item?.media_files?.length < 20 &&__helpers.isArray(uploadedFile) && uploadedFile?.length > 0) {
      setEnableBtn(true)

    }
  },[uploadedFile, item])

  const onAddFileError = useCallback((files: TypeMedia[], dataFiles: File[])=>{
    if (files?.length > 20 && files?.length  + dataFiles?.length > 20 ){
      return `${__("course_upload_files_max_length")}`
    }
  },[item, uploadedFile])

  const handleClickDeleteFile = useCallback(async (file_id: string)=>{
    if(item?.media_files.length > 0) {
      const dataId = item?.media_files.filter((file)=> file?._id !== file_id ).map((file)=> {
        return file._id
      })
       let res: any = await dispatch(
          updateModule({
            ...refValueToCreateModule.current,
            _id: item?._id,
            media_files: dataId
          }))
      if (res?.type?.includes('fulfilled')) {
        refDefaultValueToCreateModule.current = refValueToCreateModule.current
        setEnableBtn(false)
        toast.show({
          content: `${__("course_update_lesson_success")}`,
          type: EnumTypeToast.Success
        })
      } else {
        if (res?.type?.includes('rejected')) {
          toast.show({
            content: `${__("course_create_error_text")}`,
            type: EnumTypeToast.Error
          })
        }
      }
    }

  },[item])

  const handlePreviewFile = useCallback((file_id: string)=>{
    if(item?.media_files.length > 0) {
      const file = item?.media_files.find((file)=> file?._id === file_id )
      window.open( decodeURI(file.media_url), '_blank')
    }

  },[item])


  const handlePreviewUploaderFile = useCallback((name: string)=>{
    if(uploadedFile?.length > 0) {
      const file = uploadedFile.find((file)=> file?.media_file_name === name )
      window.open(file.media_url, '_blank')
    }

  },[uploadedFile])

  const onSubmitChildModule = useCallback(async () => {
    const dataFileDefault = item?.media_files.map((file)=> {
      return file._id
    })
    const dataFiles= uploadedFile?.map((item)=> {return item?._id})

    let res: any = item
      ? await dispatch(
        updateModule({
          ...refValueToCreateModule.current,
          _id: item?._id,
          media_files: dataFiles.concat(dataFileDefault)
        })
      )
      : await dispatch(createModule({...refValueToCreateModule.current, course_id: course_id, media_files: dataFiles || []}))

    if (res?.type?.includes('fulfilled')) {
      refDefaultValueToCreateModule.current = refValueToCreateModule.current
      setEnableBtn(false)
      toast.show({
        content: (item ? `${__("course_update_lesson_success")}` : `${__("course_add_lesson_success")}`),
        type: EnumTypeToast.Success
      })
    } else {
      if (res?.type?.includes('rejected')) {
        toast.show({
          content: `${__("course_create_error_text")}`,
          type: EnumTypeToast.Error
        })
      }
    }
    setFiles([])
  }, [course_id, uploadedFile])

  const onClickDeleteModule = useCallback(() => {
    setModalConfirmData({
      show: true,
      callback: onDeleteChildModule,
      description:
         `${__("course_confirm_delete_lesson")}` +
        (item?.title || '') +
        `${__("course_confirm_continue_deletion")}`,
      titleKeep: `${__("course_keep_lesson")}`
    })
  }, [])

  const onDeleteChildModule = useCallback(async () => {
    let res: any = await dispatch(deleteModule(item._id))
    if (res?.type?.includes('fulfilled')) {
      toast.show({
        content: `${__("course_delete_lesson_success")}`,
        type: EnumTypeToast.Success
      })
    } else {
      if (res?.type?.includes('rejected')) {
        toast.show({
          content: `${__("course_create_error_text")}`,
          type: EnumTypeToast.Error
        })
      }
    }
  }, [])

  if (!isShowForm)
    return (
      <div className="d-flex justify-content-center my-3">
        <button
          onClick={() => setIsShowForm(true)}
          className={`courseupdatesubmoduleitem_addbutton_init shadow-sm d-flex align-items-center`}
        >
          <FontAwesomeIcon icon={faPlus} className="courseupdatesubmoduleitem_iconplus"/>
          {__("course_create_new_lesson")}
        </button>
      </div>
    )


  return (
      <div
        className={`d-flex pt-3 pb-3 pe-4 ps-4 border-bottom hover_to_show ${
          enableBtn ? 'force_visible' : ''
        }`}
        style={{ textDecoration: 'none' }}
      >
        <div style={{ width: '10%' }} />
        <div>
          <CoursesUpdateMediaModuleItem
            callbackSetUrl={handleMediaChange}
            urlDefaultThumbnail={item?.media_id?.media_thumbnail}
            nameFile={helpers.convertUTF8ToUnicodeText(item?.media_id?.media_file_name)}
            isVideoFile={item? (item?.media_id?.media_mime_type.includes("video")) : true}
          />

          {/*<div className='mt-1'>*/}
          {/*  {*/}
          {/*    __helpers.isArray(item?.media_files) && (item?.media_files).length >0*/}
          {/*    && item?.media_files.map((file)=>{*/}
          {/*      return (*/}
          {/*        <div className='d-flex'>*/}
          {/*          <button key={file?._id}*/}
          {/*                  className="btn btn-outline-primary border-0 d-flex flex-column courseupdate_file"*/}
          {/*                  onClick={() => handlePreviewFile(file?._id)}*/}
          {/*          >*/}
          {/*            <div className="courseupdate_file_name">*/}
          {/*              <FontAwesomeIcon icon={faFile} />*/}
          {/*              <span className="courseupdate_media_file_name">{__helpers.getTrimContent(helpers.convertUTF8ToUnicodeText(`${file?.media_file_name}`), 50) || ''}</span>*/}
          {/*            </div>*/}
          {/*          </button>*/}
          {/*          <button className="btn btn-outline-primary border-0 courseupdate_file"*/}
          {/*                  onClick={() => handleClickDeleteFile(file?._id)}><FontAwesomeIcon icon={faXmark} />*/}
          {/*          </button>*/}
          {/*        </div>*/}

          {/*      )*/}
          {/*    })*/}
          {/*  }*/}
          {/*  <FileUploader*/}
          {/*    onUpload={setFiles}*/}
          {/*    acceptFile={acceptFile}*/}
          {/*    onAddFileError={() => onAddFileError(item?.media_files, files)}*/}
          {/*    isShowPlaceholder={false}*/}
          {/*    className="border-0 w-100"*/}
          {/*    textUpload={__('course_upload_btn_upload_file')}*/}
          {/*  >*/}
          {/*    {() =>*/}
          {/*       uploadedFile?.map((file,index) => (*/}
          {/*        <button key={index}*/}
          {/*                className="btn btn-outline-primary border-0 d-flex flex-column courseupdate_file"*/}
          {/*                onClick={() => handlePreviewUploaderFile(file?._id)}*/}
          {/*        >*/}
          {/*          {*/}
          {/*            !!file && (*/}
          {/*              <div className="courseupdate_file_name">*/}
          {/*                <FontAwesomeIcon icon={faFile} />*/}
          {/*                <span className="courseupdate_media_file_name">{__helpers.getTrimContent(helpers.convertUTF8ToUnicodeText(`${file?.media_file_name}`), 50) || ''}</span>*/}
          {/*              </div>*/}
          {/*            )*/}
          {/*          }*/}
          {/*        </button>*/}
          {/*      ))*/}
          {/*    }*/}
          {/*  </FileUploader>*/}
          {/*  /!*{item?.media_files.length >= 20 && uploadedFile.length > 0 && <div className="text-danger">{__('course_upload_files_max_length')}</div>}*!/*/}
          {/*</div>*/}
        </div>

        <div className="d-flex flex-grow-1 align-items-start mt-4">
          <input
            type={'text'}
            placeholder={`✎ ${item ? `${__('course_edit_lesson_name')}` : `${__('course_add_lesson_name')}`} ${index + 1}`}
            maxLength={100}
            onChange={handleInputTitleChange}
            defaultValue={item?.title || ''}
            className={`courseupdatesubmoduleitem_inputnamemodule courseupdatemoduleitem_input`}
          />
          {item ? (
            <button
              onClick={onClickDeleteModule}
              className={`courseupdatesubmoduleitem_addbutton delete_btn shadow-sm d-flex align-items-center me-2`}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          ) : null}
          <button
            onClick={onSubmitChildModule}
            disabled={
              !enableBtn || !helpers.current_user_can("boss", permission_data)
            }
            className={`courseupdatesubmoduleitem_addbutton shadow-sm d-flex align-items-center ${
              !enableBtn || !helpers.current_user_can("boss", permission_data)
                ? 'disable_btn pe-none'
                : ''
            }`}
          >
            {__("course_btn_save_lesson")}
          </button>
        </div>
      </div>
  )
}
