import {
  Box,
  Paper,
  Stack,
  styled,
  Typography
} from '@mui/material'
import __ from 'languages/index'
import helpers from 'helpers/index'
import OrderStepIcon from 'entities/shop/components/OrderStepIcon'
import { TypedLogisticLog, TypedOrderAddress } from '../interface'
import { getLocationInformationViaCode } from '../helper'
import dateandtime from 'date-and-time'
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useCallback } from 'react'

interface TypedProps {
  orderToAddress: Omit<TypedOrderAddress,'order_customer_address_city_name' | 'order_customer_address_district_name'|'order_customer_address_ward_name' >
  logisticLog: TypedLogisticLog[]
}

const ShopOrderInfo =({orderToAddress,logisticLog}: TypedProps)=>{

  const handleSetColorText = useCallback((data)=>{
    switch (data?.log_type) {
      case 'success':
        return '#0E1426'
      case 'warning':
        return '#faad14'
      case 'error':
        return '#FF2528'
      default:
        return '#0E1426'
    }
  },[logisticLog])

  return(
    <ShopOrderInfoContainer>
      <Paper sx={{background: theme=>theme.palette.background.default, p: theme=>theme.spacing(3)}}>
        <Box>
          <Typography variant='subtitle1' textTransform='none'>
            {__("shop_order_info")}
          </Typography>
        </Box>
        <Stack
          spacing={2}
          direction='row'
          alignItems='start'
          justifyContent='space-between'
        >
          <Box sx={{paddingTop: theme=>theme.spacing(2)}}>
            <Box sx={{marginBottom: theme=>theme.spacing(2.5)}}>
              <Typography>{orderToAddress?.order_customer_name || ''}</Typography>
              <Typography>{helpers.validPhoneNumber(orderToAddress?.order_customer_phonenumber || '')}</Typography>
            </Box>
            <Typography>{`${orderToAddress?.order_customer_address ? `${orderToAddress?.order_customer_address}, ` : ''}${getLocationInformationViaCode(orderToAddress?.order_customer_address_ward)?.name}, ${getLocationInformationViaCode(orderToAddress?.order_customer_address_district)?.name}, ${getLocationInformationViaCode(orderToAddress?.order_customer_address_city)?.name}`}</Typography>
          </Box>
          <Box sx={{ maxWidth: '60%', minWidth: '60%', }} className='shop_order_info_step'>
            {helpers.isEmpty(logisticLog) ? <Typography>{__('no_information_order')}</Typography> : (
            <Timeline 
              sx={{
                [`& .${timelineItemClasses.root}`]: {
                  height:'100%',
                },
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}>
              {logisticLog?.map((step, index) => (
                <Stack key={index} sx={{display:'grid', alignItems: 'flex-start', gridTemplateColumns: '1fr 2fr'}}>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot />
                      {index + 1 < logisticLog?.length && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent fontWeight={!index ? 700 : 400}>{dateandtime.format(new Date(Number(step?.createdAt)), 'HH:mm DD/MM/YYYY')}</TimelineContent>
                  </TimelineItem>
                  <Box sx={{p: theme => theme.spacing(0.75, 0, 0.75, 1.25, )}}>
                    <Typography fontWeight={!index ? 700 : 600} variant='subtitle2' color={handleSetColorText(step)} textTransform='none'>{step?.log_title || ''}</Typography>
                    <Typography fontWeight={!index ? 600 : 400} color={handleSetColorText(step)}>{step?.log_content || ''}</Typography>
                  </Box>
                </Stack>
              ))}
            </Timeline>
            )}
          </Box>
        </Stack>
      </Paper>
    </ShopOrderInfoContainer>
  )
}
export default ShopOrderInfo
const ShopOrderInfoContainer = styled(Box)(({theme})=>({
  padding: theme.spacing(3),
  display: 'grid',
  '& .shop_order_info_step ': {
    borderLeft: `solid 3px ${theme.palette.divider}`,
    paddingLeft: theme.spacing(2.5),
    height: '100%'
  }
}))
// const StepperConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 22,
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     height: 3,
//     marginLeft: theme.spacing(1),
//     borderLeft: `solid 3px ${theme.palette.divider}`,
//   },
// }));
