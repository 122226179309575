import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  Stack,
  styled,
  SxProps,
  Typography
} from '@mui/material'
import {
  ChallengeSidebarIcon,
  CircleMessageIcon,
  CloseIcon,
  CourseSidebarIcon,
  EventIcon,
  GiftSidebarIcon,
  HeartIcon,
  LivestreamSidebarIcon,
  MenusIcon,
  PinOutlinedIcon,
  ShareIcon
} from 'icons'
import CategoryTag from 'components/CategoryTag'
import FilledHeartIcon from 'icons/FilledHeartIcon'
import PollResult from 'entities/homeNew/components/DisplayPostContent/PollResult'
import PostOptions, { TypedPostOptionsProps } from 'entities/homeNew/components/PostOptions'
import DisplayAttachFiles from 'entities/homeNew/components/DisplayPostContent/DisplayAttachFiles'
import SharePostOption from 'entities/homeNew/components/SharePostOptionsPopover'
import { EnumPostStatus, TypedRequest } from '../../../interfaces/request.interface'
import { calculateTimeAgo } from 'helpers/dateAndTime'
import { CurrentTheme } from '../../../styles/themes'
import __ from 'languages/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { deletePost, likePost, updateRequest } from 'entities/homepage/home.store.reducer'

import { useAppDispatch } from 'config/store'
import helpers from 'helpers'
import imgPost from 'media/images/banner_default.png'
import AvatarComponent from 'layouts/components/AvatarComponent'
import ConfirmModal from 'components/ConfirmModal'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import ModalCreateEditPost from 'components/ModalCreateEditPost'
import { EnumPostType } from '../../../interfaces/media.interface'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import Capacity from 'components/Capacity'
import useRedirectAuth from '../../../hooks/useRedirectAuth'

import ArrowChevronLeftIcon from 'icons/ArrowChevronLeftIcon'
//Images
import avatarDefault from 'media/images/avatar_default.png'

interface TypedNewsFeedPostProps {
  is_user_logged_in: boolean
  onClickCommentText?: (item: TypedRequest) => void
  onClickCommentButton?: (item: TypedRequest) => void
  post: TypedRequest
  isLike?: boolean
  setIsLike?: React.Dispatch<any>
  variant?: 'default' | 'flatten'
  PostOptionsComponent?: (props: TypedPostOptionsProps) => React.ReactNode
  isInReview?: boolean
  imageListStyle?: SxProps<CurrentTheme>
  onClose?: () => void
  isDetailMobile?: boolean
}

const NewsFeedPost = ({
  is_user_logged_in,
  isInReview,
  post,
  isLike,
  setIsLike,
  PostOptionsComponent,
  onClickCommentText,
  onClickCommentButton,
  variant = 'default',
  imageListStyle,
  onClose,
  isDetailMobile = false,
}: TypedNewsFeedPostProps) => {
  const {
    _id,
    user_id: user,
    post_title,
    post_content,
    like_number,
    comment_number,
    share_number,
    is_like,
    createdAt,
    attach_files,
    poll_ids,
    is_pin,
    is_comment,
    post_category,
    channelpermission,
    post_status
  } = post
  const mediaType = attach_files[0]?.media_type === 'file' ? EnumPostType.File : EnumPostType.Media
  const postType = Boolean(post?.poll_ids?.length) ? EnumPostType.Poll : mediaType
  const toast = useToast()
  const refIsLike = useRef(is_like)

  const [isTruncatedText, setIsTruncatedText] = useState<boolean>(true)
  const [numOfLike, setNumOfLike] = useState(like_number)
  const [postOptionsAnchorEl, setPostOptionsAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [showEditModal, setShowEditModal] = React.useState(false)
  const [shareAnchorEl, setShareAnchorEl] = useState<HTMLButtonElement | null>(null)
  const sharePopoverId = Boolean(shareAnchorEl) ? `share-popover-${_id}` : undefined
  const navigate = useNavigate()
  const { goToLogin } = useRedirectAuth()
  const dispatch = useAppDispatch()

  const truncatedText = useMemo(() => {
    return isTruncatedText && post_content.length > 300 ? helpers.truncatedContentString(post_content, 48) : post_content
  }, [isTruncatedText, post_content])

  useEffect(() => {
    refIsLike.current = is_like
    setNumOfLike(like_number)
  }, [is_like, like_number])

  const onLike = useMemo(() => {
    return debounce((isLike: boolean) => {
      if (refIsLike.current !== isLike) {
        refIsLike.current = isLike
        dispatch(
          likePost({
            request_id: _id
          })
        )
          .unwrap()
          // .then((result)=>{
          //   setDetailPost((oldDataPost)=>{
          //     return {...oldDataPost, is_like: result?.data?.is_like, like_number: result?.data?.like_number}
          //   })
          // })
          .catch(() => {
            refIsLike.current = !isLike
            setIsLike(!isLike)
          })
      }
    }, 500)
  }, [])

  const onPressLike = useCallback(() => {
    if (is_user_logged_in) {
      setIsLike(oldValue => {
        onLike(!oldValue)
        setNumOfLike(oldValueCount => oldValueCount + (oldValue ? -1 : 1))
        return !oldValue
      })
    } else {
      goToLogin()
    }
  }, [is_user_logged_in])

  const handleClosePostOptions = () => {
    setPostOptionsAnchorEl(null)
  }

  const handleOpenPostOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPostOptionsAnchorEl(event.currentTarget)
  }

  const handleOpenSharePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShareAnchorEl(event.currentTarget)
  }
  const handleCloseSharePopover = () => {
    setShareAnchorEl(null)
  }

  const renderPostOptions = () => {
    if (!PostOptionsComponent)
      return (
        <PostOptions
          isInReview={isInReview}
          handleShowConfirmDeleteModal={handleShowConfirmDeleteModal}
          handleOpenEditModal={handleOpenEditModal}
          onUpdatePost={onUpdatePost}
          post={post}
          anchorEl={postOptionsAnchorEl}
          onClose={handleClosePostOptions}
        />
      )
    return PostOptionsComponent({
      post: post,
      anchorEl: postOptionsAnchorEl,
      onClose: handleClosePostOptions,
      onUpdatePost: onUpdatePost,
      isInReview: isInReview,
      handleOpenEditModal: handleOpenEditModal,
      handleShowConfirmDeleteModal: handleShowConfirmDeleteModal
    })
  }

  const timeAgo = useMemo(() => calculateTimeAgo(createdAt), [createdAt])

  const onClickLike = () => {
    if (is_user_logged_in) {
      onPressLike()
    } else {
      goToLogin()
    }
  }

  const onClickComment = () => {
    if (is_user_logged_in) {
      onClickCommentButton(post)
    } else {
      goToLogin()
    }
  }

  const onUpdatePost = async (data: any) => {
    let dataUpdate = { ...data, ...{ _id: post?._id } }
    await dispatch(updateRequest(dataUpdate))
      .unwrap()
      .then(res => {
        let contentToast = ''
        if (data?.is_pin == '1') {
          contentToast = 'home_page_pin_post_success'
        }

        if (data?.is_pin == '0') {
          contentToast = 'home_page_unpin_post_success'
        }
        if (data?.is_comment == '1') {
          contentToast = 'home_page_turn_on_comment_success'
        }
        if (data?.is_comment == '0') {
          contentToast = 'home_page_turn_off_comment_success'
        }
        if (data?.post_status == EnumPostStatus.Cancel) {
          contentToast = 'list_approval_post_handle_cancel_success'
        }
        if (data?.post_status == EnumPostStatus.Publish) {
          contentToast = 'list_approval_post_handle_approve_success'
        }

        if (contentToast)
          toast.show({
            type: EnumTypeToast.Success,
            content: __(contentToast)
          })
      })
      .catch(e => {
        console.log('updateRequest', e)
      })
  }

  const handleEditPost = async (updatePayload: Partial<TypedRequest>) => {
    try {
      await onUpdatePost({
        ...updatePayload,
        post_category: updatePayload.post_category?._id || '',
        post_language: 'vi',
        attach_files:
          updatePayload?.attach_files &&
          JSON.stringify(updatePayload.attach_files.map(item => item._id))
      })
      handleCloseEditModal()
    } catch (err) {
      console.log(err)
    }
    return null
  }

  const handleDeletePost = async () => {
    try {
      await dispatch(deletePost(post?._id))
        .unwrap()
        .then(res => {
          toast.show({
            type: EnumTypeToast.Success,
            content: `${__('home_post_delete_success')}`
          })
        })
        .catch(e => {})

      handleCloseConfirmDeleteModal()
    } catch (err) {
      console.log(err)
    }
  }

  const handleViewEvent = useCallback(() => {
    if (!helpers.isJson( post?.data_json)) return
    const dataJson = JSON.parse( post?.data_json)
    let data;
    switch ( post?.data_json_type) {
      case 'gift':
        data = {
          image: dataJson?.media_id?.media_thumbnail || dataJson?.media_id?.media_url,
          title: __('gift_title'),
          name: dataJson?.name,
          background: 'linear-gradient(90deg, #8eff5fcf 0.5%, #FFF 99.99%)',
          color: 'warning.dark',
          icon: <GiftSidebarIcon sx={{ width: '100%', height: '100%' }} />,
          action: () => navigate('/gift/receivers')
        }
        break
      case 'livestream':
        data = {
          image: dataJson?.avatar?.media_thumbnail || dataJson?.avatar?.media_url,
          title: __('Livestream'),
          name: dataJson?.title,
          background: 'linear-gradient(90deg, #FDEFF1 0.01%, #FFF 99.99%)',
          color: 'primary.main',
          icon: <LivestreamSidebarIcon sx={{ width: '100%', height: '100%' }} />,
          action: () => navigate(`/live-room/${dataJson?._id}`)
        }
        break
      case 'challenge':
        data = {
          image: dataJson?.avatar?.media_thumbnail || dataJson?.avatar?.media_url,
          name: dataJson?.title,
          title: __('challenge'),
          background: 'linear-gradient(90deg, #E6F4FF 0.01%, #FFF 99.99%)',
          color: 'info.main',
          icon: <ChallengeSidebarIcon sx={{ width: '100%', height: '100%' }} />,
          action: () => navigate(`/challenge/detail/${dataJson?._id}`)
        }
        break
      case 'course':
        data = {
          image: dataJson?.avatar?.media_thumbnail || dataJson?.avatar?.media_url,
          title: __('courses'),
          name: dataJson?.title,
          background: 'linear-gradient(90deg, #aaabaa 0.5%, #FFF 99.99%)',
          color: 'text.primary',
          icon: <CourseSidebarIcon sx={{ width: '100%', height: '100%' }} />,
          action: () => navigate(`/courses/view/${dataJson?._id}`)
        }
        break
      case 'event':
        data = {
          image:
            dataJson?.public_album?.[0]?.media_thumbnail || dataJson?.public_album?.[0]?.media_url,
          title: __('event'),
          name: dataJson?.title,
          background: 'linear-gradient(90deg, #FEF3DC 0.03%, #FFF 99.98%)',
          color: 'warning.dark',
          icon: <EventIcon sx={{ width: '100%', height: '100%' }} />,
          action: () => navigate(`/event/detail/${dataJson?._id}`)
        }
        break
      default:
        data = {
          image: dataJson?.avatar?.media_thumbnail || dataJson?.avatar?.media_url,
          title: __('challenge'),
          name: dataJson?.title,
          background: 'linear-gradient(90deg, #E6F4FF 0.01%, #FFF 99.99%)',
          color: 'info.main',
          icon: <ChallengeSidebarIcon sx={{ width: '100%', height: '100%' }} />,
          action: () => navigate(`/`)
        }
        break
    }

    return (
      <Box>
        {/*{*/}
        {/*  data?.name && <Typography fontWeight={700} variant='h6' color={'info.main'}>{data?.name || ''}</Typography>*/}
        {/*}*/}
        {data?.image ? (
          <img src={data?.image} width={'100%'} height={'100%'} alt="image" loading="lazy" />
        ) : (
          <img src={imgPost} alt="image" width={'100%'} height={'100%'} loading="lazy" />
        )}
        <Box>
          <Box
            sx={{
              padding: theme => theme.spacing(2, 3),
              background: data.background
            }}
            display={'grid'}
            gridTemplateColumns={'1fr auto'}
          >
            <Box
              display={'grid'}
              columnGap={theme => theme.spacing(2)}
              alignItems={'center'}
              gridTemplateColumns={'auto 1fr'}
            >
              <Box width={theme => theme.spacing(5.625)} height={theme => theme.spacing(5.625)}>
                {data?.icon}
              </Box>
              <Box overflow={'hidden'} whiteSpace={'nowrap'}>
                <Typography
                  variant="body1"
                  color={data?.color || 'info.main'}
                >
                  {data?.title || ''}
                </Typography>
                <Typography variant="subtitle2" overflow={'hidden'} textOverflow={'ellipsis'}>
                  {data?.name || ''}
                </Typography>
              </Box>
            </Box>
            <Button onClick={data?.action || null}>{__('home_page_join')}</Button>
          </Box>
        </Box>
      </Box>
    )
  }, [post?.data_json_type, post?.data_json])

  const handleShowConfirmDeleteModal = () => {
    setShowDeleteModal(true)
  }

  const handleCloseConfirmDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleOpenEditModal = () => {
    setShowEditModal(true)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }


  return (
    <Paper
      elevation={variant === 'default' ? 6 : 0}
      sx={{
        pt: 2,
        pb: 1
      }}
    >
      {isDetailMobile && helpers.isMobile() && (
        <PostTitle>
          <IconButton onClick={onClose}>
            <ArrowChevronLeftIcon width="24px" height="24px" />
          </IconButton>
          <Typography fontSize="20px" width={'100%'} noWrap fontWeight={600} color="text.primary">
            {__('post_by') + user?.display_name}
          </Typography>
        </PostTitle>
      )}
      <PostHeader
        sx={{
          padding: theme =>
            `0 ${theme.spacing(3)} ${theme.spacing(1)} ${
              onClose && attach_files.length && poll_ids.length ? 0 : theme.spacing(3)
            }`
        }}
        direction="row"
        gap={1}
      >
        <Link to={`/user/detail/${user?._id}`}>
          {isDetailMobile ? (
            <Avatar className="user_avatar" alt="avatar" src={user?.user_avatar || avatarDefault} />
          ) : (
            <AvatarComponent
              userData={user}
              permissionData={channelpermission?.permission}
              channelpermission={channelpermission}
              width={52}
              height={52}
            />
          )}
        </Link>
        <Stack direction="row" flex={1}>
          <Stack gap={0.25}>
            <Link to={`/user/detail/${user?._id}`}>
              <Typography width={'100%'} noWrap fontWeight={600} color="text.primary">
                {user?.display_name}
              </Typography>
            </Link>
            <Stack direction="row" gap={1} alignItems={'center'}>
              <Link to={`/post/detail/${_id}`}>
                <Typography variant="body2" color="text.secondary">
                  {timeAgo}
                </Typography>
              </Link>
              {Boolean(post_category && post_category?.category_title) && (
                <CategoryTag value={post_category?.category_title} />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" gap={0.5} marginLeft="auto" alignItems={'center'}>
            { ( is_pin) == 1 && (
              <IconButton className={'pinned-icon'}>
                <PinOutlinedIcon color={'inherit'} />
              </IconButton>
            )}
            {is_user_logged_in && (
              <>
                <IconButton onClick={handleOpenPostOptions} className={'menu-icon'}>
                  <MenusIcon color={'inherit'} />
                </IconButton>
                {renderPostOptions()}
              </>
            )}
          </Stack>
        </Stack>
      </PostHeader>
      <Box>
        <Typography
          component="h5"
          variant="subtitle2"
          fontWeight={600}
          padding={theme => theme.spacing(2, 3)}
        >
          { post_title}
        </Typography>
        <Typography variant="body1" px={3} whiteSpace={'pre-line'}>
          {truncatedText}
          {( post_content?.length) > 300 && (
            <Button
              variant="text"
              sx={{ textTransform: 'none', padding: theme => theme.spacing(0, 1) }}
              onClick={() => setIsTruncatedText(!isTruncatedText)}
            >
              {isTruncatedText
                ? `${__('user_setting_info_see_more')}`
                : `${__('user_setting_info_collapse')}`}
            </Button>
          )}
        </Typography>

        {Boolean( attach_files.length) && (
          <DisplayAttachFiles attachFiles={ attach_files} listImageStyle={imageListStyle} />
        )}
        {Boolean( poll_ids.length) && (
          <Box
            sx={{
              padding: theme => `0 ${theme.spacing(3)}`
            }}
          >
            <PollResult isInReview={isInReview} poll={ poll_ids} requestId={ post?._id} />
          </Box>
        )}
      </Box>

      {( post?.data_json_type) && handleViewEvent()}

      {!isInReview && (
        <>
          <Stack direction="row" alignItems="center" justifyContent={'space-between'} pl={2} pr={3}>
            <Stack direction="row" alignItems={'center'}>
              <IconButton
                sx={{
                  width: 'fit-content'
                }}
              >
                {isLike ? <FilledHeartIcon color="primary" /> : <HeartIcon />}
              </IconButton>
              <Typography variant="body1">{numOfLike}</Typography>
            </Stack>
            <Stack direction="row" gap={1} alignItems={'center'}>
              <Typography
                role="button"
                variant="body2"
                color="text.secondary"
                textTransform={'none'}
                onClick={() => (onClickCommentText ? onClickCommentText(post) : undefined)}
              >
                {comment_number} {__('home_comment').toLowerCase()}
              </Typography>
              {share_number > 0 && (
                <Typography role="button" variant="body2" color="text.secondary" textTransform={'none'}>
                  {share_number} {__('home_share').toLowerCase()}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent={'space-around'} alignItems={'center'} pt={0.5}>
            <Button variant="text" sx={actionBtnStyled} onClick={onClickLike}>
              {isLike ? <FilledHeartIcon color="primary" /> : <HeartIcon />}
              <Typography
                fontSize={helpers.isMobile ? '12px' : '1rem'}
                ml={1.25}
                color={isLike && 'primary'}
              >
                {__('home_post_footer_like')}
              </Typography>
            </Button>
            {Boolean(is_comment) && ( !post?.data_json_type) && (
              <Button variant="text" sx={actionBtnStyled} onClick={onClickComment}>
                <CircleMessageIcon />
                <Typography fontSize={helpers.isMobile ? '12px' : '1rem'} ml={1.25}>
                  {__('home_comment')}
                </Typography>
              </Button>
            )}
            <Button
              variant="text"
              sx={actionBtnStyled}
              onClick={handleOpenSharePopover}
              aria-describedby={sharePopoverId}
            >
              <ShareIcon />
              <Typography fontSize={helpers.isMobile ? '12px' : '1rem'} ml={1.25}>
                {__('home_share')}
              </Typography>
            </Button>
            <SharePostOption
              postId={_id}
              anchorEl={shareAnchorEl}
              onClose={handleCloseSharePopover}
            />
          </Stack>
        </>
      )}

      {isInReview && post_status === EnumPostStatus.Pending && (
        <Capacity
          current_user_can={'boss'}
          onFail={() => (
            <Stack
              direction="row"
              gap={1}
              alignItems={'center'}
              justifyContent={'end'}
              px={2}
              py={1}
            >
              <Button variant={'outlined'} onClick={handleShowConfirmDeleteModal}>
                {__('item_approval_post_delete')}
              </Button>
              <Button onClick={handleOpenEditModal}>{__('item_approval_post_edit')}</Button>
            </Stack>
          )}
        >
          <Stack direction="row" gap={1} alignItems={'center'} justifyContent={'end'} px={2} py={1}>
            <Button
              variant={'outlined'}
              onClick={() => onUpdatePost({ post_status: EnumPostStatus.Cancel })}
            >
              {__('list_approval_post_handle_cancel')}
            </Button>
            <Button onClick={() => onUpdatePost({ post_status: EnumPostStatus.Publish })}>
              {__('list_approval_post_handle')}
            </Button>
          </Stack>
        </Capacity>
      )}

      <Modal open={showEditModal} onClose={handleCloseEditModal}>
        <ModalContainer
          sx={{
            width: '80%',
            maxWidth: theme => theme.spacing(95.75),
            margin: '0 auto'
          }}
        >
          <ModalHeader direction={'row'}>
            <Typography variant={'h6'} className={'title'}>
              {__('home_page_edit_post')}
            </Typography>
            <IconButton onClick={handleCloseEditModal}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider />
          <ModalBody
            sx={{
              maxHeight: 'calc(100% - 80px)',
              overflowY: 'auto'
            }}
          >
            <ModalCreateEditPost
              postTypeInit={postType}
              post={post}
              onSubmit={handleEditPost}
              isSubmitting={false}
              actionText={__('home_btn_update')}
            />
          </ModalBody>
        </ModalContainer>
      </Modal>

      <ConfirmModal
        onClose={handleCloseConfirmDeleteModal}
        open={showDeleteModal}
        onConfirm={handleDeletePost}
        title={__('item_approval_post_delete')}
        confirmTextBtn={__('btn_delete')}
        confirmText={__('item_approval_modal_confirm_sub_title')}
      />
    </Paper>
  )
}
export default NewsFeedPost

const PostHeader = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: theme.spacing(1),
  '& .menu-icon': {
    transform: 'rotate(90deg)',
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  '& .pinned-icon': {
    background: theme.palette.primary.main,
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    borderRadius: theme.spacing(1.5),
    color: theme.palette.primary.contrastText
  },
  a: {
    textDecoration: 'none'
  }
}))

const actionBtnStyled: SxProps<CurrentTheme> = {
  color: 'text.secondary',
  textTransform: 'unset',
  fontWeight: 600
}

const PostTitle = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  flexDirection: 'row',
  padding: `0 ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(1.7)}`,
  background: '#fff',
  zIndex: 1,
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))
