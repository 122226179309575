import {useCallback, useEffect} from "react";
import __ from "languages/index";


export default function ChatWidget() {


    const savingChatContent = useCallback( () => {
        const editedField = document.getElementById('editedField');
        editedField.innerHTML = '';
        editedField.focus();
    }, []);

    useEffect( () => {
        const editedField = document.getElementById('editedField');
        const span_placeholder = document.getElementById('span_placeholder');
        editedField.addEventListener('focusin', () => {
            span_placeholder.style.display = 'none';
        })
        editedField.addEventListener('focusout', () => {
            if ( editedField.textContent.trim() === '' )
                span_placeholder.style.display = 'block';
        })
    }, []);


    return (
        <div id='broadcase_chatting'>
        <div className='broadcase_chatting_heading'>
        <i className="bi bi-broadcast"></i> {__("Chat_widget_title")}
        </div>
        <div className='broadcase_chatting_body'>
            <div className='broadcase_chatting_wrap'>

                <div className='chat_element'>
                    <div className='chat_avatar'>
                        <img className='w-100 rounded-circle' src="https://placehold.co/300x300" alt="" />
                    </div>
                    <div className='chat_meta'>
                        <div className='chat_meta_name'>{__("Chat_widget_example_user1")}</div>
                        <div className='chat_meta_content'>{__("Chat_widget_example_description1")}</div>
                    </div>
                </div>

                <div className='chat_element'>
                    <div className='chat_avatar'>
                        <img className='w-100 rounded-circle' src="https://placehold.co/300x300" alt="" />
                    </div>
                    <div className='chat_meta'>
                        <div className='chat_meta_name'>{__("Chat_widget_example_user2")}</div>
                        <div className='chat_meta_content'>{__("Chat_widget_example_description2")}</div>
                    </div>
                </div>




            </div>
        </div>
        <div className='broadcase_chatting_footer'>
            <span id="span_placeholder" className='span_placeholder'>{__("Chat_widget_sub_description")}</span>
            <div contentEditable className='editedField' id="editedField">
            </div>
            <div className='submitButton clickable' onClick={ () => savingChatContent() } >
                <i className="bi bi-send"></i>
            </div>
        </div>
    </div>
    )
}
