import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { Avatar, Box, Button, Stack, TextField, Typography } from '@mui/material'
import { MessageIcon, NotificationIcon, SearchIcon, StarBadgeIcon } from '../../../../icons'
import __ from 'languages/index'
import { BorderLinearProgress } from '../../../../layouts/components/UserInformation'
import logoImg from 'media/images/logo.png'

type Props = {
  channelName: string
  domain?: string
}
const Header = ({ channelName, domain }: Props) => {
  return (
    <Box>
      <Box
        sx={{
          padding: theme => theme.spacing(1)
        }}
      >
        <TextField
          disabled
          value={`https://${domain}.gamifa.vn`}
          hiddenLabel
          sx={{
            pt: 0,
            '& .MuiInputBase-input': {
              py: 0
            }
          }}
        />
      </Box>
      <Grid
        container
        spacing={3}
        columns={15}
        sx={{
          padding: theme => theme.spacing(0, 2),
          alignItems: 'center'
        }}
      >
        <Grid xs={4}>
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <Avatar
              src={logoImg}
              sx={{
                width: theme => theme.spacing(4.5),
                height: theme => theme.spacing(4.5)
              }}
            />
            <Typography fontWeight={600} noWrap>
              {channelName}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={11}>
          <Grid container columns={12} spacing={3}>
            <Grid xs={7}>
              <Stack
                direction={'row'}
                gap={1}
                sx={{
                  padding: theme => theme.spacing(1, 2),
                  border: theme => `1px solid ${theme.palette.divider}`,
                  borderRadius: theme => theme.spacing(1.5),
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <SearchIcon />
                <Typography>{__('header_search')}</Typography>
              </Stack>
            </Grid>
            <Grid xs={5}>
              <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'flex-end'}>
                <Stack direction={'row'} gap={1}>
                  <Button
                    variant={'outlined'}
                    sx={{
                      borderColor: theme => theme.palette.divider,
                      color: theme => theme.palette.text.primary,
                      minWidth: 'auto',
                      width: theme => theme.spacing(3.5),
                      height: theme => theme.spacing(5.5)
                    }}
                  >
                    <NotificationIcon color={'inherit'} fontSize="small" />
                  </Button>
                  <Button
                    variant={'outlined'}
                    sx={{
                      minWidth: 'auto',
                      color: theme => theme.palette.text.primary,
                      borderColor: theme => theme.palette.divider,
                      width: theme => theme.spacing(3.5),
                      height: theme => theme.spacing(5.5)
                    }}
                  >
                    <MessageIcon color={'inherit'} fontSize="small" />
                  </Button>
                </Stack>
                <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'flex-end'}>
                  <Avatar
                    sx={{
                      width: theme => theme.spacing(5.5),
                      height: theme => theme.spacing(5.5)
                    }}
                  >
                    TT
                  </Avatar>
                  <Stack gap={0.5}>
                    <Typography fontWeight={600} noWrap>
                      {'Tran Nam '}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      sx={{
                        position: 'relative',
                        width: '100%',
                        '& .userInformation_icon_star_badge': {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '50%',
                          height: theme => theme.spacing(2.5),
                          width: theme => theme.spacing(2.5),
                          left: '0',
                          background: theme => theme.palette.background.paper,
                          position: 'absolute',
                          zIndex: '1'
                        },
                        '& .userInformation_user_line': {
                          textAlign: 'center',
                          height: '10px',
                          width: '100%',
                          background: theme => theme.palette.background.default,
                          borderRadius: theme => theme.spacing(0, 2, 2, 0),
                          '& *': {
                            width: '100%'
                          }
                        },

                        '& .userInformation_icon_diamond': {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: theme => theme.spacing(2.5),
                          width: theme => theme.spacing(2.5),
                          left: theme => `-${theme.spacing(1.25)}`,
                          position: 'absolute',
                          zIndex: '1'
                        },
                        '& .userInformation_icon_diamond_number': {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 'auto',
                          textAlign: 'center',
                          height: '80%',
                          minWidth: theme => theme.spacing(5.125),
                          background: theme => theme.palette.background.default,
                          borderRadius: theme => theme.spacing(0, 2, 2, 0)
                        }
                      }}
                    >
                      <Box width={'100%'}>
                        <Box className="userInformation_icon_star_badge">
                          <StarBadgeIcon />
                        </Box>

                        <Box className="userInformation_user_line">
                          <BorderLinearProgress variant="determinate" value={50} />
                          <Typography
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              whiteSpace: 'nowrap'
                            }}
                            color={'warning.dark'}
                            fontWeight={700}
                            variant="body2"
                          >
                            50
                          </Typography>
                        </Box>
                      </Box>
                      {/*<Box*/}
                      {/*  sx={{*/}
                      {/*    position: 'relative'*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <Box className="userInformation_icon_diamond">*/}
                      {/*    <DiamondIcon />*/}
                      {/*  </Box>*/}
                      {/*<Box className="userInformation_icon_diamond_number">*/}
                      {/*  <Typography color={'primary.dark'} variant="body2" fontWeight={700}>*/}
                      {/*    30*/}
                      {/*  </Typography>*/}
                      {/*</Box>*/}
                      {/*</Box>*/}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
