import { SvgIcon, SvgIconProps } from '@mui/material'

export default function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99967 3.00016C7.58343 3.00016 5.62467 4.95892 5.62467 7.37516C5.62467 9.79141 7.58343 11.7502 9.99967 11.7502C12.4159 11.7502 14.3747 9.79141 14.3747 7.37516C14.3747 4.95892 12.4159 3.00016 9.99967 3.00016ZM13.5177 12.2874C15.0458 11.1911 16.0413 9.39939 16.0413 7.37516C16.0413 4.03844 13.3364 1.3335 9.99967 1.3335C6.66295 1.3335 3.95801 4.03844 3.95801 7.37516C3.95801 9.39939 4.9535 11.1911 6.48162 12.2874C5.40852 12.6304 4.43419 13.1271 3.60712 13.7474C1.94123 14.9968 0.833008 16.7865 0.833008 18.8335C0.833008 19.2937 1.2061 19.6668 1.66634 19.6668C2.12658 19.6668 2.49967 19.2937 2.49967 18.8335C2.49967 17.4287 3.25694 16.0934 4.60712 15.0807C5.95756 14.0679 7.86136 13.4168 9.99967 13.4168C12.138 13.4168 14.0418 14.0679 15.3922 15.0807C16.7424 16.0934 17.4997 17.4287 17.4997 18.8335C17.4997 19.2937 17.8728 19.6668 18.333 19.6668C18.7932 19.6668 19.1663 19.2937 19.1663 18.8335C19.1663 16.7865 18.0581 14.9968 16.3922 13.7474C15.5652 13.1271 14.5908 12.6304 13.5177 12.2874Z"
        />
      </svg>
    </SvgIcon>
  )
}
