import Modal from "react-bootstrap/Modal";
import React from "react";
import PaymentMethod from "entities/payment/components/PaymentMethod";
import {ListPlan} from 'entities/payment/payment.interface'
import {Button} from "react-bootstrap";
import __ from "languages/index";

interface TypeMobileModalPayment{
  isShowModal: boolean
  handleCloseModalPayment: ()=>void
  listPaymentMethod: string[]
  plan: ListPlan
  selectPayment: number
  handleSelectPayment: (param: number)=>void
  onClickPayment: (planId: string)=> void
}
const MobileModalPayment:React.FC<TypeMobileModalPayment> = ({isShowModal,handleCloseModalPayment,listPaymentMethod,plan,selectPayment, onClickPayment, handleSelectPayment}) =>{
  return (
    <Modal show={isShowModal}
           onHide={handleCloseModalPayment}
           aria-labelledby="contained-modal-title-vcenter"
           className="modal"
           centered>
      <Modal.Header closeButton>
        <Modal.Title>{__("mobile_app_modal_title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <PaymentMethod
          listPaymentMethod={listPaymentMethod}
          onHandelSelectPayment={handleSelectPayment}
          listPlan={[plan]}
          isShowModal={isShowModal}
          selectPayment={selectPayment}
        />
      </Modal.Body>
      <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalPayment}>
              {__("btn_close")}
            </Button>
            <Button variant="primary" className="button-event" onClick={()=>onClickPayment(plan?._id)}>
              {__("mobile_app_modal_btn_payment")}
            </Button>
        </Modal.Footer>
    </Modal>
  )
}
export  default MobileModalPayment;
