import Footer from './footer'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import HeaderComponent from "../layouts/Header";
import React from "react";

export default function aboutUs() {

  return (
    <div id="single_page">
      <_Helmet title='about_us_title' />
      <HeaderComponent />

      <header className="py-5 border-bottom" id="page_hero">
        <div className="container bd-gutter pt-md-1 pb-md-4 d-flex align-items-center">
          <div className="ovf">
            <h1 className="bd-title mt-0 fs-1">{__('about_us_title')}</h1>
            <p className="small text-white">{__('about_us_sub_title')}</p>
          </div>
        </div>
      </header>

      <main className="py-5">
        <div className="container bd-gutter pt-md-1 pb-md-4">
          <p>{__('about_us_text')}</p>
        </div>
      </main>

      <Footer />
    </div>
  )
}
