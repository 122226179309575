import React, {useCallback, useEffect, useState} from 'react'
import './media/livestream.room.scss'
import {Row} from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import LivestreamRoomInfo from 'entities/livestream/components/livestream.room.info'
import {useNavigate, useParams} from 'react-router-dom'
import {getDetailEvent} from 'entities/calendar/calendar.store.reducer'
import {useAppDispatch, useAppSelector} from 'config/store'
import {EnumStatusLivestream} from 'entities/livestream/livestream.interface'
import {clearEntity, setEntity} from 'entities/livestream/livestream.store.reducer'
import LivestreamReplayChat from 'entities/livestream/components/livestream.replay.chat'
import LivestreamReplayVideo from 'entities/livestream/components/livestream.replay.video'
import {socket} from 'config/socket.io'
import TypedChannel from 'interfaces/channel.interface'
import __ from "languages/index";
import _Helmet from 'components/helmet'

export default function LivestreamReplay() {
  const params = useParams()
  const event_id = params?.event_id
  const [liveStatus, setLiveStatus] = useState(EnumStatusLivestream.Wait)
  const liveDetail = useAppSelector(state => state.livestream.entity)
  const account = useAppSelector(state => state.user.user_data)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (event_id) {
      getDataEvent()
    } else {
      navigate('/event')
    }

    return () => {
      dispatch(clearEntity())
    }
  }, [])

  useEffect(() => {
    if (liveDetail?._id && !liveDetail?.livestream_data) {
      navigate('/event')
    }
  }, [liveDetail])

  const getDataEvent = useCallback(async () => {
    try {
      const res: any = await dispatch(getDetailEvent({ _id: event_id, auth_id: account?._id }))
      if (res?.payload?.data?._id && res?.payload?.data?.livestream_id?._id) {
        dispatch(
          setEntity({
            data: {
              entity: { ...res?.payload?.data?.livestream_id, user_id: res?.payload?.data?.user_id }
            }
          })
        )
      } else {
        navigate('/event')
      }
    } catch (error) {}
  }, [])

  return (
    <div id={'containerLivestreamRoom'}>
      <_Helmet title='livestream_title' />
      <Container fluid>
        <Row>
          <Col xs={12} md={9} className="text-white d-flex flex-column">
            <LivestreamReplayVideo />
          </Col>
          <Col xs={12} md={3} className={' text-white'}>
            <div className="livestream_action">
              <div className={'livestream_action_row_1 mb-3'}>
                <LivestreamRoomInfo socket={socket} />
              </div>
              <div className={'livestream_action_row_2'}>
                <LivestreamReplayChat />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
