import {TypedAddress} from "entities/calendar/calendar.interface"
import React, {useEffect, useState} from "react"
import GoogleMapReact from "google-map-react";
import {extractGoogleMapInfo} from "../helpers";
import __ from "languages/index";


interface Props {
  address: TypedAddress
}

const AnyReactComponent = ({ text }) => <div className="fs-7 text-danger fw-bold">
  <i className="bi bi-geo-alt-fill text-danger fs-5"></i>
  {text}
  </div>;

const EventMap = (props: Props) => {
  const { address } = props

  // State
  const [lat, setLat] = useState(0)
  const [lng, setLng] = useState(0)
  const [title, setTitle] = useState("")
  // ==============================

  useEffect(() => {
    const { placeName, lat, lng } = extractGoogleMapInfo(address?.value)
    setLat(lat)
    setLng(lng)
    setTitle(placeName)
  }, [address])



  if (lat && lng) {
    return (
      <div className="w-100 h-100 map-event mt-3 rounded">
        <GoogleMapReact
          defaultZoom={12}
          defaultCenter={{ lat: lat, lng: lng }}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        >
          <AnyReactComponent
            // @ts-ignore
            lat={lat}
            lng={lng}
            text={title || `${__("event_detail_location")}`}
          />
        </GoogleMapReact>
      </div>
    );
  }
  return null
}


export default EventMap;
