import { Order, OrderToAddress } from "../order.type"
import TextFieldMUI from 'components/TextFieldMUI'
import __ from "languages/index"
import { Box, FormControl, MenuItem, OutlinedInput, Select, styled } from "@mui/material"
import TextareaAutoSize from "components/TextAreaAutoSize"
import React, { useEffect, useState } from "react"
import { TypedCustomerAddressBook } from 'entities/shop/interface'
import { TypedDetailForm } from "../order.manager.detail"
import { useAppDispatch } from "config/store"
import { getCustomerAddressBook } from "entities/shop/store"
import { AxiosError, AxiosResponse } from "axios"
import AddressForm from "components/AddressForm"

interface TypedProps {
    orderToAddress?: OrderToAddress,
    fields: TypedDetailForm
}
const OrderInfomationForm = ({ orderToAddress, fields }: TypedProps) => {
    const dispatch = useAppDispatch();
    const [dataCustomer, setDataCustomer] = useState<TypedCustomerAddressBook[]>([]);
    const [customerSelected, setCustomerSelected] = useState<string>('')

    useEffect(() => {
        dispatch(getCustomerAddressBook())
            .unwrap()
            .then((res: AxiosResponse) => {
                setDataCustomer(res.data)
            })
            .catch((error: AxiosError) => {
                console.log(`getCustomer`, error)
            })

    }, [])

    useEffect(() => {
        if (orderToAddress) {
            handleSetDataDefault(orderToAddress)
        }
    }, [orderToAddress])

    const handleSelectCustomer = (address_id: string) => {
        const findCustomer: TypedCustomerAddressBook = dataCustomer.find((res) => res.address_id === address_id);
        if (findCustomer) {
            const data: OrderToAddress = {
                order_customer_address: findCustomer.customer_address,
                order_customer_address_city: findCustomer.customer_address_city,
                order_customer_address_district: findCustomer.customer_address_district,
                order_customer_address_ward: findCustomer.customer_address_ward,
                order_customer_name: findCustomer.customer_display_name,
                order_customer_phonenumber: findCustomer.customer_phonenumber,
                customer_note: findCustomer.customer_address_note,
            }
            handleSetDataDefault(data)
            setCustomerSelected(address_id)

        }

    }

    const handleSetDataDefault = (data: OrderToAddress) => {
        fields.order_customer_name.onChange(data.order_customer_name)
        fields.order_customer_phonenumber.onChange(data.order_customer_phonenumber)
        fields.order_customer_address.onChange(data.order_customer_address)
        fields.customer_note.onChange(data.customer_note)
        fields.order_customer_address_city.onChange(data.order_customer_address_city)
        fields.order_customer_address_district.onChange(data.order_customer_address_district)
        fields.order_customer_address_ward.onChange(data.order_customer_address_ward)
    }

    return (
        <OrderInfomationFormContainer>
            <form>

                <FormControl sx={{
                    width: '100%',
                    marginBottom: theme => theme.spacing(2.7)
                }} >
                    <Select
                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }

                        }}
                        value={customerSelected}
                        displayEmpty
                        onChange={(e) => {
                            handleSelectCustomer(e.target.value)
                        }}
                        input={<OutlinedInput />}
                        className='payment-modal-select'
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5),
                            width: '100%'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem selected value="">
                            {__("partner")}
                        </MenuItem>
                        {dataCustomer?.length && dataCustomer.map((customer) => (
                            <MenuItem
                                key={customer?.address_id}
                                value={customer?.address_id}
                            >
                                {customer?.customer_display_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextFieldMUI

                    {...fields.order_customer_name}
                    maxLength={30}
                    max={30}
                    requiredIndicator
                    placeholder={__('name_partner')}
                />

                <TextFieldMUI

                    {...fields.order_customer_phonenumber}
                    maxLength={10}
                    requiredIndicator
                    placeholder={__('validate_code_telephone_number')}
                />

                <AddressForm fields={{
                    order_customer_address_city: fields.order_customer_address_city,
                    order_customer_address_district: fields.order_customer_address_district,
                    order_customer_address_ward: fields.order_customer_address_ward
                }} />
                <TextFieldMUI

                    {...fields.order_customer_address}
                    maxLength={10}
                    requiredIndicator
                    placeholder={__('product_stock_address')}
                />

                <TextareaAutoSize
                    {...fields.customer_note}
                    // sx={{
                    //     minHeight: theme => theme.spacing(18.25)
                    // }}
                    minRows={6}
                />

            </form>
        </OrderInfomationFormContainer>
    )


}

export default OrderInfomationForm


const OrderInfomationFormContainer = styled(Box)(({ theme }) => ({

    '& fieldset': {
        border: 'none'
    }
}))
