import { useAppDispatch } from 'config/store'
import { ToastProps } from 'react-bootstrap'
import { addToast } from 'store/ui.slice'

export enum EnumTypeToast{
  Warning="warning",
  Error="error",
  Success="success"
}

export type ToastType = {
  type: EnumTypeToast
  content: string
  title?: string
  options?: Omit<ToastProps, 'defaultValue'>
}

function generateUEID() {
  let first = (Math.random() * 46656) | 0
  let second = (Math.random() * 46656) | 0
  let firstStr = ('000' + first.toString(36)).slice(-3)
  let secondStr = ('000' + second.toString(36)).slice(-3)

  return firstStr + secondStr
}
const defaultToastConfig: Partial<ToastProps> = {
  delay: 3000,
  autohide: true
}

export const useToast = () => {
  const dispatch = useAppDispatch()

  const show = (payload: ToastType) => {
    dispatch(
      addToast({
        id: generateUEID(),
        ...payload,
        options: {
          ...defaultToastConfig,
          ...payload.options
        }
      })
    )
  }

  return { show }
}

// const toast = useToast()
// export default toast
