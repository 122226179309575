//Library
import { useCallback, useEffect, useLayoutEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createPortal } from 'react-dom'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

//Components
import ScrollToTop from 'components/ScrollToTop'
import EventTrigger from 'components/eventTrigger'
import Theme404 from './layout/404'
import _ComponentCheckShowIframe from 'layout/component/component.check.show.zoho'
import AppToast from 'components/AppToast'

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { public_route, separate_route as SeparateRoute } from './config/router-define'
import {
  checkCreatedChannel,
  checkJoinChannel,
  getCurrentUserData,
  getDefaultChannel,
  getLevelsChannel,
  getUserPermission,
  setLoadingDoneFirstTime
} from 'store/user.store.reducer'
import { clearError } from 'store/global.warning.store.reducer'

//Helper
import helpers from 'helpers'

//Css
// Styles must use direct files imports
import { getThemeByMode } from 'styles/themes'
import 'yet-another-react-lightbox/styles.css'
import MainLayout from 'layouts/MainLayout'
import ChannelCreate from 'entities/channel/channel.create'
import { socket } from 'config/socket.io'
import LogoutModal from 'components/LogoutModal'
import { AUTH_SCREEN, GAMIFA_PRIMARY_COLOR } from 'constant/index'
import { lightPalette } from './styles/themes/palettes/defaultPalete'
import { colorGenerator } from './styles/utils/colorPaletteGenerator'
import ChannelSettingLayout from 'layouts/ChannelSettingLayout'
import InviteUser from 'entities/channel-settings/invite-user'
import ChannelSettingsInformation from 'entities/channel-settings/information'
import { channelInfo } from 'entities/channel-settings/store/channel.slice'
import PostCategory from 'entities/channel-settings/post-category'
import Level from 'entities/channel-settings/level'
import CreditConfigurations from 'entities/channel-settings/credit-configurations'
import PointConfigurations from 'entities/channel-settings/point-configurations'
import EmailNotification from 'entities/channel-settings/email-notification'
import ChannelSettingsAdvance from 'entities/channel-settings/advance'
// import { useNavigate } from 'react-router'

const baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '')

export default function App() {
  const channel_data = useAppSelector(channelInfo)
  const user_data = useAppSelector(state => state.user.user_data)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const errorMessage = useAppSelector(state => state.global_notification.errorMessage)
  const dispatch = useAppDispatch()

  const getDataForDisplay = useCallback(async () => {
    let hash = window.location.hash
    if (hash && hash.includes('authentication_hash')) {
      try {
        let originHash = hash.slice(1)
        let params = new URLSearchParams(originHash)
        let authentication_hash = params.get('authentication_hash')
        if (authentication_hash) {
          localStorage.setItem('session', authentication_hash)
          document.cookie = `session=${authentication_hash};path=/;SameSite=Lax`
        }
        window.history.replaceState(null, 'Home', window.location.pathname)
      } catch (e) {}
    }

    try {
      const isJoinChannel = localStorage.getItem('is_join_channel')
      if (!!localStorage.getItem('session')) {
        if (isJoinChannel === 'true') {
          await dispatch(getCurrentUserData())
        }
        await dispatch(checkCreatedChannel())
      } else {
        dispatch(setLoadingDoneFirstTime())
      }
      await dispatch(getDefaultChannel())
      dispatch(getUserPermission()) // new way to get permission
    } catch (e) {}
  }, [])

  useEffect(() => {
    if (!!localStorage.getItem('session')) {
      if (!channel_data?._id) return
      const isJoinChannel = localStorage.getItem('is_join_channel')
      if (isJoinChannel) {
        if (isJoinChannel !== 'true') {
          if (!window.location.pathname.includes('/channel/join')) {
            window.location.href =
              window.location.protocol +
              '//' +
              window.location.host +
              `/channel/join/${channel_data?._id}`
          }
        }
      } else {
        dispatch(checkJoinChannel())
          .unwrap()
          .then(res => {
            if (res?.data?.is_join)
              return (window.location.href = window.location.protocol + '//' + window.location.host)
            return (window.location.href =
              window.location.protocol +
              '//' +
              window.location.host +
              `/channel/join/${channel_data?._id}`)
          })
          .catch(error => {
            console.log('checkJoinChannel Error >>>', error)
          })
      }
    }
  }, [channel_data?._id])

  useLayoutEffect(() => {
    getDataForDisplay()
  }, [])

  useEffect(() => {
    if (!channel_data?._id) return
    dispatch(getLevelsChannel({ channel_id: channel_data?._id }))
  }, [channel_data?._id])


/**
 * Anh Hiển bảo muốn cắn cổ Thăng Trịnh
 * @refactor jamviet.com
 */
function autoFixDomain (_domain: string) {
  if (String(_domain).substring(0, 4) !== 'http') {
    _domain = 'https://' + _domain
  }
  return _domain;
}
function getHostFromDomain(mainDomain: string): string {
  try {
      mainDomain = autoFixDomain(mainDomain);
    let {host}  = new URL(mainDomain);
    return host;
  } catch(e) {
    return '';
  }
}
  useEffect( () => {

    if ( helpers.isDev() ) return; /** Môi trường dev, bỏ qua */
    if ( ! channel_data) return; /** Chưa nạp được channel data, bỏ qua */

    let currentHostToCompair = window.location.host; // lấy cái domain đang hiển thị trên trình duyệt đã...
    if ( channel_data.domain !== '' && (getHostFromDomain(currentHostToCompair) !== getHostFromDomain(channel_data.domain) ) ) {
      // nếu có domain riêng, và domain đó  đang không hiển thị, thì mí chuyển hướng 
      window.location.href = autoFixDomain(channel_data.domain) + '#ok_redirect_to_domain';
    }

    // nếu không thì không làm gì ... vì nếu làm gì nó sẽ lặp vĩnh viễn

  }, [channel_data, window.location.host]);

  /*
  @Thăng trịnh xem lại phần này, ai cho mày code thế này?

  useEffect(() => {
    function forceRedirect(url: string) {
      window.location.href = url
    }

    function redirectToDomain() {
      return forceRedirect(channel_data?.domain)
    }

    function redirectToSubdomain() {
      return forceRedirect(channel_data?.sub_domain)
    }

    if (!helpers.isDev()) {
      if (channel_data?.domain) {
        redirectToDomain()
      }
      redirectToSubdomain()
    }
  }, [channel_data?.domain])
  */

  useEffect(() => {
    setTimeout(() => {
      socket.io.opts.extraHeaders = {
        'X-Authorization': localStorage.getItem('session')
      }
      socket.connect()
    }, 2000)

    return () => {
      socket.disconnect()
    }
  }, [is_user_logged_in])

  useEffect(() => {
    if (channel_data?.country) {
      localStorage.setItem(
        'languageChannel',
        channel_data?.country?.toUpperCase() == 'VN' ? 'vi' : 'en'
      )
    }
  }, [channel_data?.country])

  // useEffect(() => {
  //   if (!channel_data) return
  //
  //   if (!user_data) {
  //     let { pathname } = new URL(window.location.href)
  //     let ex = ['/', '/login', '/tos', '/about-us', '/courses', '/blog', '/event', '/password']
  //     let m = false
  //     for (let router of ex) {
  //       if (pathname.indexOf(router) > -1) {
  //         m = true
  //       }
  //     }
  //
  //     if (!m) {
  //       Navigate({ to: '/login#redirectFromApp' })
  //     }
  //   }
  // }, [channel_data, user_data])

  // useEffect(() => {
  //   const { pathname } = window.location
  //   if (!helpers.isEmpty(user_data) && !pathname.includes(`/validate-code#phone`)) {
  //     const isValidatedPhone = user_data?.is_validated_phone == 1
  //     if (!isValidatedPhone) {
  //       navigate(`/validate-code#phone`)
  //       return
  //     }
  //   }
  // }, [window.location.pathname, user_data, channel_data, permission_data, is_user_logged_in])

  useEffect(() => {
    if (
      channel_data?.public_status &&
      channel_data?.public_status === 'private' &&
      !localStorage.getItem('session') &&
      !AUTH_SCREEN.some(item => window.location.pathname.includes(item))
    ) {
      if (channel_data?.facebook_login_client_id || channel_data?.google_login_client_id) {
        window.location.href = window.location.origin + '/login'
      } else {
        if (helpers.isDevEnv()) {
          window.location.href = `https://dev.auth.appuni.io/login?domain=${window.location.origin}&redirect_to=${window.location.href}`
        } else {
          window.location.href = `https://auth.appuni.io/login?domain=${window.location.origin}&redirect_to=${window.location.href}`
        }
      }
    }
  }, [
    channel_data?.public_status,
    channel_data?.facebook_login_client_id,
    channel_data?.google_login_client_id,
    is_user_logged_in
  ])

  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      const hashURL = String(hash || ' ').replace('#', '')
      let _url = new URLSearchParams(hashURL)
      const user_referrer = _url.get('user_referrer') || ''
      if (user_referrer) {
        localStorage.setItem('user_referrer', user_referrer)
      }
    }
  }, [window.location])

  useEffect(() => {
    if (!errorMessage) return
    console.log('errorMessage', errorMessage)
    dispatch(clearError())
  }, [errorMessage])

  const chanelColor = helpers.validColor(channel_data?.primary_color)
    ? channel_data?.primary_color
    : GAMIFA_PRIMARY_COLOR
  const paletteGenerated = {
    ...lightPalette,
    ...colorGenerator(chanelColor, 'primary')
  }

  const currentTheme = createTheme({
    ...getThemeByMode('light', {
      lightPalette: paletteGenerated
    })
  })

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <BrowserRouter basename={process.env.PUBLIC_URL || baseHref}>
        <ScrollToTop />
        <EventTrigger />
        <>{createPortal(<AppToast />, document.body)}</>

        {/* Logout Modal */}
        <LogoutModal />

        {/* <BlockWebOnMobileModal channelData={channel_data} showModal={helpers.isMobile()} /> */}

        <Routes>
          <Route element={<MainLayout />}>
            {public_route.map((Routex, index) => (
              <Route
                key={`public_${index}`}
                path={Routex.path}
                element={
                  <_ComponentCheckShowIframe>
                    <Routex.main />
                  </_ComponentCheckShowIframe>
                }
              />
            ))}
            <Route path="/channel-settings" element={<ChannelSettingLayout />}>
              <Route path="/channel-settings/invite-user" element={<InviteUser />} />
              <Route
                path="/channel-settings/information"
                element={<ChannelSettingsInformation />}
              />
              <Route path="/channel-settings/post-categories" element={<PostCategory />} />
              <Route path="/channel-settings/gamifications" element={<Level />} />
              <Route
                path="/channel-settings/credit-configurations"
                element={<CreditConfigurations />}
              />
              <Route
                path="/channel-settings/point-configurations"
                element={<PointConfigurations />}
              />
              <Route path="/channel-settings/email-notification" element={<EmailNotification />} />
              <Route path="/channel-settings/advance" element={<ChannelSettingsAdvance />} />
            </Route>

            {/* {public_route.map((Routex, index) => (
              <Route
                key={`public_${index}`}
                path={Routex.path}
                element={
                  <_ComponentCheckShowIframe>
                    <Routex.main />
                  </_ComponentCheckShowIframe>
                }
              />
            ))} */}
          </Route>

          <Route path="/temp/template" element={<ChannelCreate />} />

          {SeparateRoute.map((Routex, index) => (
            <Route
              key={index}
              path={Routex.path}
              element={
                <_ComponentCheckShowIframe>
                  <Routex.main />
                </_ComponentCheckShowIframe>
              }
            />
          ))}

          <Route key={99999} path="*" element={<Theme404 includeHeader />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}
