import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, SxProps, Theme, Typography } from '@mui/material'
import CommentElement from './CommentElement'
import { PostCommentType } from 'interfaces/home.interface'
import AddCommentForm from './AddCommentForm'
import { TypedRequest } from '../../../interfaces/request.interface'
import { getListComment } from 'entities/homepage/home.store.reducer'
import { useAppDispatch } from 'config/store'
import __ from 'languages/index'

type Props = {
  comment: PostCommentType
  depth?: number
  post?: TypedRequest
}

const Comment = ({ comment, depth, post }: Props) => {
  const { child: sub_comments, ...rest } = comment
  const [showReplyForm, setShowReplyForm] = React.useState(false)
  const [listSubComment, setListSubComment] = React.useState<PostCommentType[]>([])
  const [page, setPage] = React.useState<number>(1)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()


  function listCommentFilterById(listComment : PostCommentType[], data : PostCommentType[]) {
    return (listComment.concat(data)).filter((comment, index, subComments) => {
      return index === subComments.findIndex((item) => item._id === comment._id);
    }).sort((i,j) => Date.parse(j.createdAt) - Date.parse(i.createdAt));
  }
  const displayListSubComment =  listSubComment?.filter((item) => item?._id !== sub_comments[0]?._id)

  const handleClickSeeMore = useCallback(async ()=>{
     await dispatch(getListComment({
       postId: comment?.request_id,
       parent_id: comment?._id,
       order_by: 'DESC',
       limit: 10,
       page: page
     }))
      .unwrap()
      .then(res => {
        setListSubComment(prev => listCommentFilterById(res?.data, prev) || res?.data )
        if (listSubComment?.length < comment?.child_number && Math.ceil(comment?.child_number/10) > page){
          setPage(prevPage => prevPage + 1)
        }
      }).catch(e => {
        console.log("get_comment_err", e)
      })

  },[listSubComment,comment, page])

  const handleClickCommentButton = () => {
    setShowReplyForm(true)
    inputRef.current?.focus()
  }

  return (
    <Box sx={commentContainerStyled}>
      <CommentElement is_comment={post?.is_comment} {...rest} onClickCommentButton={handleClickCommentButton} />
      <Box pl={4}>
        {sub_comments?.map((comment: PostCommentType) => (
          <CommentElement
            is_comment={post?.is_comment}
            key={comment?._id}
            onClickCommentButton={handleClickCommentButton}
            {...comment}
          />
        ))}
        {!!displayListSubComment?.length && comment?._id === displayListSubComment[0]?.parent_id && displayListSubComment?.map((comment: PostCommentType) => (
          <CommentElement
            is_comment={post?.is_comment}
            key={comment?._id}
            onClickCommentButton={handleClickCommentButton}
            {...comment}
          />
        ))}
        <Typography
          variant="body1"
          px={3}
          color="text.secondary"
          textAlign="center"
        >
          { comment?.child_number > 1 && (sub_comments?.length + displayListSubComment?.length) < comment?.child_number && (
            <Button
              variant="text"
              sx={{textTransform: 'none', padding: theme => theme.spacing(0, 1), color:'text.secondary' }}
              onClick={handleClickSeeMore}
            >
              {__("see_more")}
            </Button>
          )}
        </Typography>
      </Box>
      {showReplyForm && depth < 2 && (
        <AddCommentForm
          ref={inputRef}
          postId={rest.request_id}
          commentId={rest._id}
          className={'reply-comment-form'}
        />
      )}
    </Box>
  )
}

export default Comment

const commentContainerStyled: SxProps<Theme> = {
  '& .reply-comment-form': {
    padding: theme => `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(4)}`
  }
}
