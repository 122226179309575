import React, { forwardRef, PropsWithRef } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonProps } from 'react-bootstrap'

type Props = PropsWithRef<ButtonProps> & { icon: IconProp }

type Ref = HTMLElement
const IconButton = forwardRef<Ref, Props>((props, ref) => {
  const { icon, children, ...rest } = props
  return (
    <Button {...props} className={`${props.className} d-flex gap-2`} ref={ref}>
      <span>{<FontAwesomeIcon icon={icon} />}</span>
      {children}
    </Button>
  )
})

export default IconButton
