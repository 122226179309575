import { SvgIcon, SvgIconProps } from '@mui/material'

const PollIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.04199 6C2.04199 4.34315 3.38514 3 5.04199 3H19.9578C21.6147 3 22.9578 4.34315 22.9578 6V8C22.9578 9.65685 21.6147 11 19.9578 11H5.04199C3.38514 11 2.04199 9.65685 2.04199 8V6ZM5.04199 5C4.48971 5 4.04199 5.44772 4.04199 6V8C4.04199 8.55228 4.48971 9 5.04199 9H19.9578C20.5101 9 20.9578 8.55228 20.9578 8V6C20.9578 5.44772 20.5101 5 19.9578 5H5.04199ZM2.04199 16C2.04199 14.3431 3.38514 13 5.04199 13H11.042C12.6988 13 14.042 14.3431 14.042 16V18C14.042 19.6569 12.6988 21 11.042 21H5.04199C3.38514 21 2.04199 19.6569 2.04199 18V16ZM5.04199 15C4.48971 15 4.04199 15.4477 4.04199 16V18C4.04199 18.5523 4.48971 19 5.04199 19H11.042C11.5943 19 12.042 18.5523 12.042 18V16C12.042 15.4477 11.5943 15 11.042 15H5.04199Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default PollIcon
