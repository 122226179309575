//Library
import {
  Avatar,
  Box,
  Button,
  debounce,
  Divider,
  IconButton,
  List,
  ListItem,
  Modal,
  Paper,
  Stack,
  styled,
  Switch,
  Typography
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

//Components
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'

//Icons
import { CloseIcon, PlusIcon, TrashAltIcon } from 'icons'

//Images
import imageDefault from 'media/images/image_default.png'

import __ from 'languages/index'
import FormCreateEditProductCategory from 'entities/shop/components/FormCreateEditProductCategory'
import { TypedProductCategory } from '../../../interfaces/shop.interface'
import {
  deleteCategoryById,
  getProductCategories
} from 'entities/shop/service/product-category.service'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import ConfirmModal from 'components/ConfirmModal'
import NotificationModal from 'entities/shop/components/NotificationModal'
import DeliveryBoxIcon from 'icons/DeliveryBoxIcon'
import { useNavigate } from 'react-router-dom'

const ProductCategory = () => {
  const [isShowAddCategory, setIsShowAddCategory] = useState<boolean>(false)
  const [isShowModalNotification, setIsShowModalNotification] = useState<boolean>(false)
  const [categoryId, setCategoryId] = useState<string>('')
  const [textSearch, setTextSearch] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<TypedProductCategory | null>(null)
  const [categories, setCategories] = useState<TypedProductCategory[]>([])
  const toast = useToast()
  const navigate = useNavigate()

  const isEditing = Boolean(!!selectedCategory)

  const requestSearch = useMemo(() => {
    return debounce((_value: string) => {
      console.log('Search', _value)
      _value
    }, 500)
  }, [])

  useEffect(() => {
    const getListCategory = async () => {
      try {
        const res = await getProductCategories()
        const categories = res?.data
        setCategories(categories)
      } catch (err) {
        console.log('category', err)
      }
    }
    getListCategory()
  }, [])

  const handleChangeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value)
    requestSearch(e.target.value)
  }, [])

  const handleCloseShowModal = useCallback(() => {
    setIsShowAddCategory(false)
  }, [])


  const handleOpenAddOrEditCategoryModal = (category: TypedProductCategory | null) => {
    setSelectedCategory(category)
    setIsShowAddCategory(true)
  }

  const handleUpdatedCategorySuccess = (updatedCategory: TypedProductCategory) => {
    setSelectedCategory(updatedCategory)
    setCategories(prev => {
      const index = prev.findIndex(
        category => category?.category_id === updatedCategory?.category_id
      )
      if (index !== -1) {
        prev[index] = {
          ...prev[index],
          ...updatedCategory
        }
        return [...prev]
      }
      return [updatedCategory, ...prev]
    })
    setIsShowAddCategory(false)
  }

  const handleDeleteCategory = useCallback(async () => {
    try {
      const res = await deleteCategoryById(categoryId)
      const category = res.data
      setCategories(prev => prev.filter(category => category?.category_id !== categoryId))
    } catch (err) {
      const message = err?.response?.data?.message
      if (message) {
        toast.show({
          type: EnumTypeToast.Error,
          content: __(message)
        })
      }
    }
    setCategoryId('')
  }, [categoryId])

  const handleClickDeleteCategory = useCallback(
    (category: TypedProductCategory) => {
      if (category?.category_count <= 0) {
        setCategoryId(category?.category_id)
      } else {
        setIsShowModalNotification(true)
      }
    },
    [categories]
  )

  const handleClickDeliveryBox = (category_id: string)=>{
    navigate(`/shop/admin/category/${category_id}/products#portfolio`)
  }

  return (
    <ProductCategoryContainer id="productCategory">
      <Paper className="wrapper">
        <Stack display={'grid'} gridTemplateColumns={'auto auto'} justifyContent={'space-between'}>
          <Typography textTransform={'none'} variant="h5" fontWeight={700}>
            {__('product_portfolio')}
          </Typography>
          <Button
            onClick={() => handleOpenAddOrEditCategoryModal(null)}
            sx={{ p: theme => theme.spacing(0, 2) }}
            startIcon={<PlusIcon sx={{ color: 'inherit' }} />}
          >
            <Typography textTransform={'none'} color={'background.paper'}>
              {__('add_categories')}
            </Typography>
          </Button>
        </Stack>
        <Typography color={'text.secondary'}>{__('shop_category_title')}</Typography>
        <List className="category_list">
          {categories?.map(category => (
            <React.Fragment key={category.category_id}>
              <ListItem >
                <Box
                  sx={{
                    width: '100%',
                    display: 'grid',
                    columnGap: theme => theme.spacing(3),
                    height: theme => theme.spacing(5.5),
                    gridTemplateColumns: '1fr auto auto'
                  }}
                >
                  <Box
                    onClick={() => handleOpenAddOrEditCategoryModal(category)}
                    display={'flex'}
                    overflow={'hidden'}
                    alignItems={'center'}
                    columnGap={theme => theme.spacing(3)}
                  >
                    <Avatar
                      src={category?.category_thumbnail || imageDefault}
                      variant="rounded"
                      alt="avatar"
                      sx={{ width: theme => theme.spacing(6), height: theme => theme.spacing(6) }}
                    />
                    <Typography fontWeight={500} variant="inherit" noWrap>
                      {category?.category_name || ''}
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => handleOpenAddOrEditCategoryModal(category)}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Paper
                      sx={{
                        p: theme => theme.spacing(0, 1),
                        background: theme => theme.palette.background.default
                      }}
                    >
                      <Typography fontWeight={500} color={'primary.main'} noWrap>
                        {`${category?.category_count} ${__('txt_products')}`}
                      </Typography>
                    </Paper>
                  </Box>
                  <Box display={'flex'} columnGap={theme => theme.spacing(2)}>
                    <IconButton
                      className="icon_button"
                      onClick={() => handleClickDeleteCategory(category)}
                    >
                      <TrashAltIcon
                        sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                        color="inherit"
                      />
                    </IconButton>
                    <IconButton
                      className="icon_button edit"
                      onClick={()=>handleClickDeliveryBox(category?.category_id)}

                    >
                      <DeliveryBoxIcon
                        sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                        color="inherit"
                      />
                    </IconButton>
                  </Box>
                </Box>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
      {isShowAddCategory && (
        <CategoryModalContainer open={isShowAddCategory} onClose={handleCloseShowModal}>
          <ModalContainer
            sx={{
              width: '100%',
              maxWidth: theme => theme.spacing(115.125),
              margin: '0 auto'
            }}
          >
            <Box className="header">
              <ModalHeader direction={'row'}>
                <Typography className="title">{ isEditing ? `${__('edit_categories')}` : `${__('add_categories')}`}</Typography>
                <IconButton onClick={handleCloseShowModal}>
                  <CloseIcon />
                </IconButton>
              </ModalHeader>
              <Divider sx={{ opacity: 1 }} />
            </Box>
            <ModalBody className="body">
              <FormCreateEditProductCategory
                category={selectedCategory}
                isEditing={isEditing}
                onSuccess={handleUpdatedCategorySuccess}
              />
              {/*<Divider sx={{ opacity: 1 }} />*/}
              {/*<Box*/}
              {/*  p={theme => theme.spacing(2, 0)}*/}
              {/*  display={'grid'}*/}
              {/*  rowGap={theme => theme.spacing(1.875)}*/}
              {/*>*/}
                {/*<Box maxWidth={theme => theme.spacing(33.5)}>*/}
                {/*  <Paper className="input_search">*/}
                {/*    <IconButton*/}
                {/*      aria-label="search"*/}
                {/*      sx={{*/}
                {/*        p: 0,*/}
                {/*        width: theme => theme.spacing(3),*/}
                {/*        height: theme => theme.spacing(3)*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <SearchIcon color="inherit" sx={{ width: '100%', height: '100%' }} />*/}
                {/*    </IconButton>*/}
                {/*    <InputBase*/}
                {/*      value={textSearch || ''}*/}
                {/*      onChange={handleChangeValue}*/}
                {/*      placeholder={__('search_product')}*/}
                {/*      inputProps={{ 'aria-label': __('search_product') }}*/}
                {/*    />*/}
                {/*  </Paper>*/}
                {/*</Box>*/}
                {/*<Box display={'grid'} rowGap={theme => theme.spacing(0.75)}>*/}
                  {/*<TransferList*/}
                  {/*  titleModal={__('transfer_all')}*/}
                  {/*  confirmTextTransferLeft={__('add_category_confirm_title')}*/}
                  {/*  confirmTextTransferRight={__('remove_category_confirm_title')}*/}
                  {/*/>*/}
                  {/*<TransferProductToCategory categoryId={selectedCategory?.category_id} />*/}
                {/*</Box>*/}
              {/*</Box>*/}
            </ModalBody>
          </ModalContainer>
        </CategoryModalContainer>
      )}
      {Boolean(!!categoryId) && (
        <ConfirmModal
          onClose={() => setCategoryId('')}
          open={Boolean(!!categoryId)}
          onConfirm={handleDeleteCategory}
          title={__('delete_category_title')}
          confirmTextBtn={__('btn_delete')}
          confirmText={__('delete_category_confirm')}
        />
      )}

      {isShowModalNotification && (
        <NotificationModal
          handleCloseModalNotification={() => setIsShowModalNotification(false)}
          isShowModalNotification={isShowModalNotification}
          textContent={`${__('modal_notification_category')}`}
        />
      )}
    </ProductCategoryContainer>
  )
}

export default ProductCategory

const ProductCategoryContainer = styled(Box)(({ theme }) => ({
  '& .wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .category_list': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(45)})`,
    overflow: 'auto',
    '& li': {
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${theme.palette.divider}`,
      ':last-child': {
        borderBottom: 'none'
      }
    }
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .edit': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

const CategoryModalContainer = styled(Modal)(({ theme }) => ({
  '& .header': {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    background: theme.palette.background.paper,
    padding: 0
  },
  '& .title': {
    textTransform: 'none',
    variant: 'h6',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center'
  },
  '& .body': {
    padding: theme.spacing(0, 3),
    maxHeight: `calc(100% - ${theme.spacing(10)})`,
    overflowY: 'auto'
  },
  '& .button_wrapper': {
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    padding: theme.spacing(0, 2),
    border: `1px dashed ${theme.palette.primary.main}`,
    '& .upload_wrapper': {
      display: 'grid',
      justifyItems: 'center',
      rowGap: theme.spacing(1)
    }
  },
  '& .input_search': {
    height: theme.spacing(5.75),
    padding: theme.spacing(1, 2),
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
  }
}))

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: theme.spacing(5.5),
  height: theme.spacing(2.75),
  borderRadius: theme.spacing(2),
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: theme.spacing(1.875)
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: `translateX(${theme.spacing(1.125)})`
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: `translateX(${theme.spacing(2.75)})`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}))
