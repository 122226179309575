import { Box, List, ListItem, Stack, styled, Typography } from '@mui/material'
import __ from 'languages/index'
import imageDefault from 'media/images/image_default.png'
import helpers from 'helpers'
import { TypedOrder} from '../interface'

interface TypedProps {
  orderData: TypedOrder
}

const ShopOrderListProduct=({orderData}: TypedProps)=>{
  return(
    <ShopOrderListProductContainer>
      <Box>
        <List sx={{padding: theme => theme.spacing(2, 1.5)}}>
          {
            orderData?.orders_to_product?.map((data, index: number)=>(
              <ListItem key={`list_product_${index}`} sx={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Stack direction={'row'} alignItems='center' >
                  <Box sx={{paddingRight: theme => theme.spacing(2)}}>
                    <Box 
                      component={'img'}
                      sx={{ height: theme=>theme.spacing(13.125), width: theme=>theme.spacing(13.125), objectFit: 'cover', borderRadius: theme=>theme.spacing(1.5) }} 
                      src={data?.product?.product_avatar?.[0] || imageDefault} 
                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>)=>{e.currentTarget.src = imageDefault}}
                      alt='image product'/>
                  </Box>
                  <Stack direction={'column'}>
                    <Typography color={'text.primary'} variant={'subtitle2'}>
                      {data?.product?.product_name || ''}
                    </Typography>
                    <Typography color={'text.secondary'} variant={'body1'} >
                      {`x ${data?.item_quantity || 0}`}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography>{`${helpers.formatNumberWithCommas(data?.product_price || 0)} VND`}</Typography>
              </ListItem>
            ))
          }
        </List>
        <Box className='shop_order_payment_product'>
          <Box>
            <Typography className='shop_order_product_item' >{__("shop_order_total")}</Typography>
            <Typography className='shop_order_product_item'>{__("shop_order_shipping")}</Typography>
            <Typography className='shop_order_product_item'>{__("shop_order_money")}</Typography>
            <Typography className='shop_order_product_item'>{__("shop_order_payment_method")}</Typography>
          </Box>
          <Box>
            <Typography className='shop_order_product_item'>{`${helpers.formatNumberWithCommas(orderData?.order_total_price || 0)} VND`}</Typography>
            <Typography className='shop_order_product_item'>{`${helpers.formatNumberWithCommas(orderData?.order_total_fee || 0)} VND`}</Typography>
            <Typography className='shop_order_product_item'>{`${helpers.formatNumberWithCommas((orderData?.order_total_price + orderData?.order_total_fee) || 0)} VND`}</Typography>
            <Typography className='shop_order_product_item'>{__("COD")}</Typography>
          </Box>
        </Box>
      </Box>

    </ShopOrderListProductContainer>
  )
}
export default ShopOrderListProduct

const ShopOrderListProductContainer = styled(Box)(({theme})=>({
  '& .shop_order_payment_product':{
    display: 'grid',
    gridTemplateColumns:'2fr 1fr',
    alignItems: 'center',
    textAlign: 'end'

  },
  '& .shop_order_product_item':{
    borderTop: `solid 1px ${theme.palette.divider}`,
    borderRight: `solid 1px ${theme.palette.divider}`,
    padding: theme.spacing(1.25,3.5)

  }
}))
