import React from 'react'
import {Toast, ToastContainer} from 'react-bootstrap'
import {removeToast} from 'store/ui.slice'
import {useAppDispatch, useAppSelector} from 'config/store'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck, faCircleExclamation, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import __ from 'languages/index'
import {EnumTypeToast} from "../hooks/useToast";

const AppToast = () => {
  const toasts = useAppSelector(state => state.ui.toasts)
  const dispatch = useAppDispatch()

  const closeToastHandler = id => {
    dispatch(removeToast(id))
  }

  const renderToast = (type: EnumTypeToast, content: string) => {
    return <Toast.Body className="text-white">{content}</Toast.Body>
  }

  const getIcon = (type: EnumTypeToast) => {
    if (type === EnumTypeToast.Success) return <FontAwesomeIcon icon={faCircleCheck} className="text-success" />
    if (type === EnumTypeToast.Warning)
      return <FontAwesomeIcon icon={faTriangleExclamation} className="text-warning" />
    if (type === EnumTypeToast.Error)
      return <FontAwesomeIcon icon={faCircleExclamation} className="text-danger" />
  }

  const getBgToast = (type: EnumTypeToast) => {
    return type === EnumTypeToast.Success ? 'success' : type === 'error' ? 'danger' : 'warning'
  }

  return (
    <ToastContainer position="bottom-end" className="z-1400 position-fixed">
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          onClose={() => closeToastHandler(toast.id)}
          show={toasts.includes(toast)}
          {...toast.options}
          bg={getBgToast(toast.type)}
        >
          <Toast.Header className="flex justify-content-between align-items-center">
            <div>
              {getIcon(toast.type)}
              <span className="m-0 ms-2 fs-6 fw-semibold">
                {toast.title || `${__('notification')}`}
              </span>
            </div>
          </Toast.Header>
          {renderToast(EnumTypeToast.Success, toast.content)}
        </Toast>
      ))}
    </ToastContainer>
  )
}

export default AppToast
