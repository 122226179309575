import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material'
import {useNavigate, useParams} from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import __ from 'languages/index'
import {useAppDispatch} from 'config/store'
import { TypedProduct } from 'entities/shop/interface'
import {AxiosError} from 'axios'
import ArrowChevronLeftIcon from '../../../icons/ArrowChevronLeftIcon'
import { EditIcon, SearchIcon, TrashAltIcon } from '../../../icons'
import PlusIcon from '../../../icons/PlusIcon'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {Image} from 'react-bootstrap'
import AddProductModal from 'entities/shop/components/AddProductModal'
import {InfiniteScrollContainer} from 'components/InfiniteScrollContainer'
import {useInfiniteScroll} from '../../../hooks/useInfiniteScroll'
import imageDefault from 'media/images/image_default.png'
import {
  deleteProductCollection,
  getCollectionById,
  updateProductToCollection
} from 'entities/shop/store/shop.store.reducer'
import __helpers from "helpers/index";
import {formatString} from "entities/support/utils";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { getProducts } from 'entities/shop/service/product.service'

const ListProductCollection = ()=>{
  const [collectionName,setCollectionName] = useState<string>('')
  const [addProduct,setAddProduct] = useState<TypedProduct>()

  const [isShowAddProductModal,setIsShowAddProductModal] = useState<boolean>(false)
  const { collection_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const toast = useToast()


  useEffect(() => {
    if(!collection_id){
      navigate("/shop/admin")
    }
    dispatch(getCollectionById(collection_id))
      .unwrap()
      .then(res=>{
        setCollectionName(res?.data?.collection_name)
      })
      .catch((e: AxiosError)=>{
        console.log('getCollectionById', e)
      })

  }, [collection_id])

  const {
    hasMore,
    isRefreshing,
    loadMore,
    data,
    updateParamRequestDebounce,
    updateItem,
    updateData,
    deleteItemById,
    isLoading,
    refresh
  } = useInfiniteScroll<TypedProduct>({
    collection_id: collection_id,
    limit: 12,
    order_by: 'DESC',
    keyAttribute:"product_id"
  }, getProducts)


  useEffect(()=>{
    if(!!addProduct){
      updateData([...data,addProduct])
      refresh(true)
    }
  },[addProduct])


  const handleDeleteProduct = useCallback(async (param)=>{

    try {
      const payload = {
        collection_id: collection_id,
        product_id: param?.product_id
      }
      dispatch(deleteProductCollection(payload))
        .unwrap()
        .then((res)=>{
          deleteItemById(param?.product_id)
          refresh(true)
          toast.show({
            content: `${__('delete_product_to_collection_success')}`,
            type: EnumTypeToast.Success
          })
        })

    } catch (err) {
      toast.show({
        content: `${__('delete_product_to_collection_failed')}`,
        type: EnumTypeToast.Error
      })
      console.log('err', err)
    }

  },[data])

  const handleCloseAddProductModal = useCallback(()=>{
    setIsShowAddProductModal(false)
  },[])


  const handleSearchProduct = (event) => {
    const inputValue = event.target.value;
    updateParamRequestDebounce({query: inputValue})};

  const columns :GridColDef[] = [
    {
      field: "product",
      headerName: `${__("stock_product")}`,
      flex: 2,
      sortable: false,
      renderCell: (params) =>{
        return(
          <Stack direction={'row'} alignItems='center' >
            <Box sx={{paddingRight: theme => theme.spacing(2)}}>
              <Image style={{ height: '50px', width: '50px', objectFit: 'cover' }}
                     src={ params?.row?.product_avatar?.[0] ?? imageDefault}/>
            </Box>
            <Stack direction={'column'}>
              <Typography color={'text.primary'} variant={'subtitle2'}>
                {__helpers.getTrimContent(`${params?.row.product_name}`, 30)}
              </Typography>
              <Typography color={'primary.main'}  variant={'body1'} >
                {formatString(__('stock_product_price'), `${__helpers.formatNumberWithCommas(params?.row.product_price) || 0} `)}
              </Typography>
              <Typography color={'text.secondary'}  variant={'body1'}>
                {formatString(__('stock_product_sku'), `${params?.row.product_sku || 0} `)}
              </Typography>
            </Stack>
          </Stack>
        )
      }

    },
    {
      field: 'action',
      headerName: `${__("stock_action")}`,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align:'center',
      renderCell: (params) =>{
        return(
          <Box>
            <IconButton
              onClick={(event)=>handleDeleteProduct(params?.row)}
              className='icon_button_action'
            >
              <TrashAltIcon
                sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
                color="inherit"
              />

            </IconButton>
          </Box>

        )
      }

    },
  ]



  return(
    < ListProductCollectionContainer id={'CollectionDetailContainer'}>
      <Paper className="list_product_collection_wrapper">
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <IconButton
            onClick={() => navigate(`/shop/admin#groups`)}
            sx={{color: theme => theme.palette.text.primary, fontSize: theme => theme.spacing(2.5) }}
          >
            <ArrowChevronLeftIcon
              sx={{
                width: theme => theme.spacing(1.5),
                height: theme => theme.spacing(1.5),
              }}/>
          </IconButton>
          <Typography
            variant='subtitle1'
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            {collectionName}
          </Typography>
        </Box>
        <Box>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent='space-between'
          >
            <Box sx={{maxWidth: theme => theme.spacing(60)}}>
              <OutlinedInput
                size="small"
                type="search"
                id="stock_detail_search"
                className="stock_detail_search"
                placeholder={__('manage_product')}
                autoFocus
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" sx={{ padding: 0, width: theme => theme.spacing(2.375)}} >
                      <SearchIcon sx={{ width: '100%', height: '100%', marginRight: theme => theme.spacing(1)}} color="inherit" />
                    </IconButton>
                  </InputAdornment>}
                aria-describedby="outlined-helper-text"
                sx={{borderRadius: theme => theme.spacing(1.5)}}
                onChange={handleSearchProduct}
              />
            </Box>
            <Button
              onClick={()=> setIsShowAddProductModal(true)}
              size={'small'}
              sx={{padding: theme => theme.spacing(1,1)}}
            >
              <PlusIcon
                sx={{
                  width: theme => theme.spacing(2.25),
                  height: theme => theme.spacing(2.25) ,
                  margin: theme => theme.spacing(0, 1, 0, 0),
                  color: 'inherit',
                }}
              />
              <Typography variant="body1" textTransform= 'none'>
                {__("stock_detail_btn_add")}
              </Typography>
            </Button>
          </Stack>
        </Box>
        <Box>

          <InfiniteScrollContainer
            isRefreshing={isRefreshing}
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            refreshFunction={refresh}
            showEndMessage={false}
            pullDownToRefresh={false}
            scrollableTarget={"scrollableDiv"}
          >
            <DataGrid
              rowHeight={120}
              hideFooterPagination
              hideFooter
              disableColumnMenu
              disableRowSelectionOnClick
              showCellVerticalBorder={false}
              columns={columns}
              rows={data}
              rowSpacingType={'margin'}
              sx={{
                backgroundColor: theme => theme.palette.background.paper,
                borderRadius: theme => theme.spacing(1.5),
                boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
                '& .MuiDataGrid-cell': {
                  height: '100px',
                },
              }}
              getRowId={(row) => row.product_id}
            />
          </InfiniteScrollContainer>

        </Box>
      </Paper>

      {isShowAddProductModal &&
        <AddProductModal
          setAddProduct={setAddProduct}
          collection_id={collection_id}
          isShowAddProductModal={isShowAddProductModal}
          handleCloseAddProductModal={handleCloseAddProductModal}
        />}

    </ListProductCollectionContainer>

  )
}

export default ListProductCollection

const ListProductCollectionContainer = styled(Box)(({theme})=>({
  '& .list_product_collection_wrapper':{
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .MuiDataGrid-root':{
    boxShadow: 'none',
    borderRadius: 0,
    borderColor: 'transparent',
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within,& .MuiDataGrid-cell:focus-visible, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-columnHeader:focus-visible, & .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within':{
      outline: 'none',
      outlineWidth: 0,
      outlineOffset: 0
    }
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.background.secondary,
    borderRadius:  theme.spacing(1.5),

  },
  '& .super-app-theme--header:hover': {
    '& .MuiDataGrid-menuIcon': {
      visibility: 'hidden',
    },
  },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
    display: 'flex',
    visibility: 'visible',
    width: 'auto'

  },
  '& .MuiDataGrid-iconSeparator':{
    visibility: 'hidden',
  },
  '& .MuiDataGrid-root .MuiDataGrid-withBorderColor':{
    borderColor: theme.palette.divider,
  } ,
  '& .MuiDataGrid-footerContainer':{
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .stock_product_quantity':{
    width: '90%',
    margin: theme.spacing(2, 1)

  },

  '& .icon_button_action': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    alignItems: 'center'
  },
  '& .icon_button_check': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '& .stock_detail_search':{
    borderColor: theme.palette.text.secondary,
    display: "flex"
  },

}))
