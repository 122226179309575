import { SvgIcon, SvgIconProps } from '@mui/material'

const TrashIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.03583 5.5H4C3.44772 5.5 3 5.94772 3 6.5C3 7.05228 3.44772 7.5 4 7.5V18.5C4 21.2614 6.23857 23.5 9 23.5H14.8927C17.6367 23.5 19.868 21.2885 19.8925 18.5446L19.9911 7.5H20C20.5523 7.5 21 7.05228 21 6.5C21 5.94772 20.5523 5.5 20 5.5H16.9642C16.6596 3.16386 14.4568 1.5 12 1.5C9.54321 1.5 7.34037 3.16386 7.03583 5.5ZM9.07417 5.5H14.9258C14.6354 4.41763 13.5163 3.5 12 3.5C10.4837 3.5 9.36461 4.41763 9.07417 5.5ZM6 7.5V18.5C6 20.1569 7.34314 21.5 9 21.5H14.8927C16.5391 21.5 17.8779 20.1731 17.8926 18.5268L17.991 7.5H6Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default TrashIcon
