import './media/css/index.scss'
import MobilePlan from "./components/MobilePlan";
import MobileHeader from "./components/MobileHeader";
import MobileInfo from "./components/MobileInfo";
import MobileProcess from "./components/MobileProcess";
import MoblileTableCompare from "./components/MoblileTableCompare";
import React, {useCallback, useEffect} from "react";
import {getEntities} from "entities/extentions/extensions.store.reducer";
import {useAppDispatch, useAppSelector} from "config/store";
import {getListPlan} from "entities/payment/payment.store.reducer";
import {ListPlan} from "entities/payment/payment.interface";
import {useNavigate} from "react-router-dom";
import TypedChannel from 'interfaces/channel.interface'
import __ from "languages/index";
import _Helmet from 'components/helmet';
import BigLayout from "../../layouts/bigLayout";


const MobileAppDetail = () =>{
    const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)

    const entities = useAppSelector(state => state.extensions.entities)
    const listPlan: ListPlan[] = useAppSelector(state => state.payment.plans)
    const service_id = entities[0]?._id
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    useEffect(() => {
         dispatch(getEntities({
            service_type: 'mobile',
        }))
    }, []);

    useEffect(() => {
        if(service_id){
            dispatch(
                getListPlan({
                    service_id: String(service_id)
                })
            )
        }
    }, [service_id]);


  const handleClickBtn= useCallback(()=>{
    const scrollTarget = document.getElementById('myScrollTarget');
    if (scrollTarget) {
      scrollTarget.scrollIntoView({ behavior: 'smooth' });
    }
    },[])
  const handleClickPlan = (plan_id: string) => {
    localStorage.setItem('plan_id', plan_id)
    localStorage.setItem('mobile_id', service_id)
    navigate('/mobile/register-form');
  };
    return (
      <BigLayout>
        <_Helmet title='mobile_app_title' />
        <div>
          <MobileHeader handleClickBtn={handleClickBtn} />
          <MobileInfo />
          <MobileProcess />
          <MoblileTableCompare />
          <MobilePlan
            handleClickPlan={handleClickPlan}
            scrollTarget="myScrollTarget"
            listPlan={listPlan}
          />
        </div>
      </BigLayout>
    )
}
export default MobileAppDetail
