import __ from 'languages/index'
import { OrderStatus, PaymentStatus } from './order.enum';

export const dataTabs = [
  {
    tabName: `${__("active")}`,
  },
  {
    tabName: `${__("expired")}`,
  },
];

export const tabChildrenManager = [
  {
      label: __('all'),
      value: null
  },
  {
      label: __('ordered'),
      value: OrderStatus.Pending
  },
  {
      label: __('comfirmed'),
      value: OrderStatus.AwaitPickup
  },
  {
      label: __('transporting'),
      value: OrderStatus.AwaitDelivery
  },
  {
      label: __('order_complete'),
      value: OrderStatus.Delivered
  },
  {
      label: __('order_cancel'),
      value: OrderStatus.Cancelled
  },
  {
      label: __('return_initated'),
      value: OrderStatus.ReturnInitated
  }
]

export const paymentStatus = [
  {
    value: PaymentStatus.UNPAID,
    label: __('unpaid')
  },
  {
    value: PaymentStatus.PARTIAL,
    label: __('partial')
  },
  {
    value: PaymentStatus.PAID,
    label: __('paid')
  },
]

export const paymentStatusObj = {
  [PaymentStatus.UNPAID]: __('unpaid'),
  [PaymentStatus.PARTIAL]: __('partial'),
  [PaymentStatus.PAID]: __('paid'),

}