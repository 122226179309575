import React from 'react'
import { useGetPostCategoriesQuery } from 'entities/homeNew/store/homeApi'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { TypedCategory } from '../../../interfaces/request.interface'
import __helpers from 'helpers/index'
import __ from 'languages/index'

type Props = {
  value: string
  onChange: (event: TypedCategory) => void
}
const PostCategoriesSelector = ({ value, onChange }: Props) => {
  const { data: categories, isLoading } = useGetPostCategoriesQuery()

  const defaultValue = categories?.[0]?._id
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(categories.find(category => category._id === event.target.value))
  }

  return (
    <FormControl
      size={'small'}
      sx={{
        minWidth: theme => theme.spacing(20.5)
      }}
    >
      <InputLabel
        sx={{
          root: {
            color: theme => theme.palette.primary.main
          }
        }}
      >
        {isLoading ? `${__("loading")}` : `${__("categories")}`}
      </InputLabel>

      <Select
        sx={{
          root: {
            backgroundColor: theme => theme.palette.primary.background
          },
        }}
        MenuProps={{
          elevation:1,
          sx:{
            width: theme => theme.spacing(100),
            maxHeight: theme => theme.spacing(30.5),
            boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
          }
        }}
        defaultValue={defaultValue}
        value={value || defaultValue}
        onChange={handleChange}
        label={isLoading ? 'Loading...' : 'Category'}
      >
        {categories?.map(category => (
          <MenuItem key={category._id} value={category._id}>
            {__helpers.getTrimContent(category.category_title, 70)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PostCategoriesSelector
