import { createRef } from 'react'

import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'
import ToastComponent from 'entities/courses/components/toast.component'
import LeaderBoardPage from './leaderBoard.page'

export default function LeaderBoard() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'leader-board'
  let ActualPage: any

  switch (Param) {
    case 'leader-board':
      ActualPage = LeaderBoardPage
      break
    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="">
      {<ActualPage />}
    </div>
  )
}
