import React from 'react';

import {useAppSelector} from 'config/store';
import __ from "languages/index";
import helpers from 'helpers/index';
import { useNavigate } from 'react-router-dom';

export default function CheckChannelPayment() {
  const permission_data = useAppSelector(state => state.user.permission_data);
  const channel_data = useAppSelector(state => state.user.channel_data);
  const navigate = useNavigate();

  const goToPayment = () => {
    navigate(channel_data?.redirect_url);
  }
  return (
    <div>
      {
        helpers.current_user_can('boss', permission_data) ?
          <div className='bg-white p-4 rounded'>
            <div className="text-center">
              <p className=" fw-bold">{__("check_channel_payment_title")}</p>
              <p className="">
                {__("check_channel_payment_sub_title")}
              </p>
              <button onClick={goToPayment} className="btn btn-primary fw-bold">
                {__("check_channel_payment_btn")}
              </button>
            </div>
          </div> :

          <div className='bg-white p-4 rounded'>
            <p className="text-center fw-bold">{__("check_channel_payment_title")}</p>
            <p className="text-center">
              {__("check_channel_payment_by_admin")}
            </p>
          </div>

      }

    </div>
  );
}
