//Library
import React, {memo} from 'react'

//Images
import defaultImage from 'media/images/image_default.png'
import iconDiamond from 'media/icons/icon_diamond.svg'

//Data
import {optionMission} from '../data'

//Interface
import {TypeMissionAction, TypeMissionData} from '../interface'

//Helper
import helpers from 'helpers'

//Scss
import 'entities/mission/media/mission.timeline.scss'
import __ from "languages/index";
import { Button, Stack } from '@mui/material'

const MissionTimeline = ({
  data,
  index,
  length,
  handleShowModalConfirmDelete,
  handleShowModalConfirmUpdate,
  handleShowModalOptionGift
}: {
  data?: TypeMissionData
  index?: number
  length?: number
  handleShowModalConfirmDelete?: Function
  handleShowModalConfirmUpdate?: Function
  handleShowModalOptionGift?: Function
}) => {
  return (
    <div id="missionTimeline" className="missionTimeline_body_container">
      <div className="missionTimeline_container_left">
        {length > 1 && (
          <div className="missionTimeline_container_left_content">
            <span>{index}</span>
            <div className="missionTimeline_container_left_after"></div>
          </div>
        )}
      </div>
      <div className="missionTimeline_container_right">
        <div className="missionTimeline_container_right_header">
          <span className="missionTimeline_right_header_title">{`${__("mission_date")} ${index}`}</span>
          {index === 1 && (
            <div className="missionTimeline_right_header_button">
              <Button variant='text' onClick={() => handleShowModalConfirmUpdate()} sx={{textTransform: 'none !important',p: 0}}>
                {__("mission_timeline_edit")}
              </Button>
              <Button variant='text' onClick={() => handleShowModalConfirmDelete()} sx={{textTransform: 'none !important', p: 0, color: theme => theme.palette.error.main}}>
                {__("mission_timeline_remove")}
              </Button>
            </div>
          )}
        </div>
        <div className="missionTimeline_container_right_content">
          {data?.mission_action?.map((item: TypeMissionAction, idx: number) => (
            <div key={idx} className="missionTimeline_right_content_title">
              <span>{`${__("mission_title")} ${idx + 1}: ${optionMission?.find(
                data => data?.key === item?.action_name
              )?.label} ${`(0/${item?.action_point})`}`}</span>
            </div>
          ))}

          <div className="missionTimeline_right_info_award">
            <span className="missionTimeline_right_info_award_title">{__("mission_reward")}</span>
            {Boolean(data?.gift_coin) && (
              <Stack direction={'row'} columnGap={theme=>theme.spacing(1)}>
                <div className="d-flex align-items-center">
                  <img src={iconDiamond} width={20} height={20} alt="icon/diamond" loading="lazy" />
                  <span>{helpers.formatNumberWithCommas(data?.gift_coin || '0')}</span>
                </div>
                <Button variant='text' sx={{textTransform: 'none !important', p: 0}} onClick={() => handleShowModalOptionGift()} className="missionTimeline_right_info_award_question">
                  {__("mission_diamond")}
                </Button>
              </Stack>
            )}

            {data?.gift_data?.length > 0 && (
              <div className="missionTimeline_right_info_award_name">
                <img
                  src={data?.gift_data[0]?.media_id?.media_url || defaultImage}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = defaultImage
                  }}
                  alt="image-award"
                />
                <div className="missionTimeline_right_award_name">
                  <span>{data?.gift_data[0]?.name || ''}</span>
                  <span>{`${helpers.formatNumberWithCommas(
                    data?.gift_data[0]?.price || '0'
                  )} ${__("currency_unit")}`}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="missionTimeline_line"></div>
      </div>
    </div>
  )
}

export default memo(MissionTimeline)
