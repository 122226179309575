import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EyeOffIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2933 3.29289C19.6838 2.90237 20.317 2.90237 20.7075 3.29289C21.098 3.68342 21.098 4.31658 20.7075 4.70711L18.9454 6.46923C20.2418 7.52578 21.2928 8.8722 22 10.4106C22.4634 11.4188 22.4634 12.5812 22 13.5894C20.2618 17.371 16.4392 20 12.0004 20C10.0996 20 8.30918 19.5171 6.74766 18.667L4.70752 20.7071C4.31699 21.0976 3.68383 21.0976 3.2933 20.7071C2.90278 20.3166 2.90278 19.6834 3.2933 19.2929L19.2933 3.29289ZM8.23672 17.1779C9.3812 17.7056 10.6556 18 12.0004 18C15.6293 18 18.7587 15.8522 20.1827 12.7541C20.4025 12.2761 20.4025 11.7239 20.1827 11.2459C19.5757 9.92524 18.6576 8.77589 17.5222 7.8924L15.4468 9.96778C16.3528 11.5017 16.1468 13.5105 14.8288 14.8284C13.5109 16.1464 11.5021 16.3524 9.9682 15.4464L8.23672 17.1779ZM11.4825 13.9321C12.1497 14.1103 12.8912 13.9377 13.4146 13.4142C13.9381 12.8908 14.1107 12.1493 13.9325 11.4821L11.4825 13.9321ZM2.00089 10.4106C3.73907 6.62905 7.56168 4 12.0004 4C12.7525 4 13.488 4.07563 14.1994 4.22002C14.7406 4.32989 15.0903 4.85772 14.9804 5.39897C14.8706 5.94022 14.3427 6.28992 13.8015 6.18005C13.2204 6.06209 12.6182 6 12.0004 6C8.3715 6 5.24213 8.14781 3.81811 11.2459C3.59839 11.7239 3.59839 12.2761 3.81811 12.7541C4.08699 13.3391 4.41693 13.8905 4.79992 14.4003C5.13166 14.8418 5.04265 15.4687 4.6011 15.8005C4.15955 16.1322 3.53267 16.0432 3.20093 15.6017C2.73322 14.9791 2.32986 14.3051 2.00089 13.5894C1.53746 12.5812 1.53746 11.4188 2.00089 10.4106Z"
        />
      </svg>
    </SvgIcon>
  )
}
