import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  OutlinedInput,
  Rating,
  Stack,
  Typography,
  styled
} from '@mui/material'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { CheckSecondIcon, CloseIcon } from 'icons'
import __ from 'languages/index'
import React, { useCallback, useMemo, useState } from 'react'
import imageDefault from 'media/images/image_default.png'
import { useField, useForm } from '@shopify/react-form'
import helpers from 'helpers'
import UploadFileComponent from './shop.upload'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { TypedProductCart, TypedReviewOrder } from '../interface'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { createReviewOfOrder, getMyUnReview } from '../service/order.service'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { formatString } from 'entities/support/utils'

interface TypedProps {
  isShow: boolean
  onClose: () => void
  order_id: string
  ordersToProduct: TypedProductCart[]
}

const ModalReviewOrder = ({ isShow, onClose, order_id, ordersToProduct }: TypedProps) => {
  const { data, hasMore, isRefreshing, loadMore, refresh } = useInfiniteScroll<TypedReviewOrder>(
    { limit: 10, order_id: order_id, keyAttribute: 'review_id' },
    getMyUnReview,
    true
  )

  const toast = useToast()

  const handleCreateReview = useCallback(
    (reviewId: string, values, _function: Function) => {
      createReviewOfOrder(reviewId, values)
        .then(() => {
          _function()
          toast.show({
            content: `${__('rate_success')}`,
            type: EnumTypeToast.Success
          })
        })
        .catch(error => {
          console.log('createReviewOfOrder Error >>>', error)
          toast.show({
            type: EnumTypeToast.Error,
            content: `${error || ''}`
          })
        })
    },
    [order_id]
  )

  return (
    <ReviewModalContainer open={isShow} onClose={onClose}>
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(95.75),
          margin: '0 auto'
        }}
      >
        <Box className="header">
          <ModalHeader direction={'row'}>
            <Typography variant="subtitle1" className="title">
              {__('rate')}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider sx={{ opacity: 1 }} />
        </Box>
        <ModalBody className="body">
          <InfiniteScrollContainer
            isRefreshing={isRefreshing}
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            refreshFunction={refresh}
            pullDownToRefresh={false}
            showEndMessage={false}
          >
            {data.map((data: TypedReviewOrder, idx: number) => (
              <ReviewItem
                key={idx}
                dataReview={data}
                ordersToProduct={ordersToProduct}
                handleCreateReview={handleCreateReview}
              />
            ))}
          </InfiniteScrollContainer>
        </ModalBody>
        <ModalFooter alignItems={'flex-end !important'}>
          <Button variant={'outlined'} onClick={onClose}>
            <Typography textTransform={'none'}>{__('shop_order_back')}</Typography>
          </Button>
        </ModalFooter>
      </ModalContainer>
    </ReviewModalContainer>
  )
}

export default ModalReviewOrder

const ReviewItem = ({
  dataReview,
  ordersToProduct,
  handleCreateReview
}: {
  dataReview: TypedReviewOrder
  ordersToProduct: TypedProductCart[]
  handleCreateReview: Function
}) => {
  const [isRated, setIsRated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { fields, submit } = useForm({
    fields: {
      review_point: useField<string>({
        value: '5',
        validates: []
      }),
      review_content: useField<string>({
        value: '',
        validates: []
      }),
      review_media: useField<string[]>({
        value: [''],
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        fields.review_media.value.pop()
        setIsLoading(true)
        handleCreateReview(dataReview?.review_id, values, () => {
          setIsRated(true)
          fields.review_media.onChange([...fields.review_media.value])
        })
        await helpers.sleep(500)
        setIsLoading(false)
        return { status: 'success' }
      } catch (e) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const reviewTitle = useMemo(() => {
    switch (helpers.parseNumeric(fields.review_point.value)) {
      case 1:
        return <Typography>{__('terrible')}</Typography>
      case 2:
        return <Typography>{__('poor')}</Typography>
      case 3:
        return <Typography>{__('fair')}</Typography>
      case 4:
        return <Typography color={'warning.main'}>{__('good')}</Typography>
      case 5:
        return <Typography color={'warning.main'}>{__('amazing')}</Typography>
      default:
        return ''
    }
  }, [fields.review_point.value])

  const renderProductPrice = useMemo(() => {
    return ordersToProduct?.find(
      (data: TypedProductCart) => data?.product_id === dataReview?.product?.product_id
    )?.product?.product_price
  }, [ordersToProduct, dataReview])

  return (
    <Box display={'grid'} p={theme => theme.spacing(3, 0, 0)} rowGap={theme => theme.spacing(2)}>
      <Stack direction={'row'} spacing={2}>
        <img
          className="image_product"
          src={dataReview?.product?.product_avatar?.[0] || imageDefault}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = imageDefault
          }}
          alt="image"
        />
        <Stack
          direction={'column'}
          justifyContent={'space-around'}
          maxWidth={theme => theme.spacing(56.75)}
        >
          <Typography fontWeight={500}>{dataReview?.product?.product_name || ''}</Typography>
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <Typography variant="body2">{`${__('productPrice')}:`}</Typography>
            <Typography color={'primary.main'} fontWeight={700}>
              {`${helpers.convertToCommasFormat(renderProductPrice || '0')} VND`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Typography variant="caption" fontWeight={500}>
          {__('product_quality')}
        </Typography>
        <Rating
          size="large"
          readOnly={isRated}
          value={helpers.parseNumeric(fields.review_point.value)}
          onChange={(event, newValue) => {
            fields.review_point.onChange(helpers.parseNumeric(newValue || 1))
          }}
        />
        <Typography variant="caption" fontWeight={500}>
          {reviewTitle}
        </Typography>
      </Stack>
      <OutlinedInput
        multiline
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          fields.review_content.onChange(e.target.value)
        }
        value={fields.review_content.value || ''}
        placeholder={__('thoughts_about_the_product')}
        readOnly={isRated}
        minRows={4}
        inputProps={{
          maxLength: 255
        }}
        sx={{
          width: '100%',
          p: theme => theme.spacing(2, 3),
          borderRadius: theme => theme.spacing(1.5),
          background: theme => theme.palette.background.default,
          outline: 'none',
          caretColor: theme => theme.palette.primary.main
        }}
      />
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
        <Stack direction={'row'} spacing={1.5} flexWrap={'wrap'}>
          {isRated ? (
            <>
              {fields.review_media.value?.map((value: string, idx: number)=> (
                 <Box  
                    width={theme => theme.spacing(13.75)}
                    height={theme => theme.spacing(13.75)}
                    key={`media_${idx}`}
                  >
                    <Box 
                      component={'img'} 
                      src={value || imageDefault} 
                      sx={{
                        width: '100%', 
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: theme => theme.spacing(3)
                      }} 
                      alt="image" 
                    />
                 </Box>
              ))}
            </>
          ) : (
            <>
              {fields.review_media.value.map((value: string, idx: number) => (
                <Box
                  width={theme => theme.spacing(13.75)}
                  height={theme => theme.spacing(13.75)}
                  key={`media_${idx}`}
                >
                  <UploadFileComponent
                    mediaUrl={value}
                    title={formatString(
                      __('upload_image_length'),
                      `${fields.review_media.value?.length}/5`
                    )}
                    accept="image/*"
                    removeMediaUrl={() => {
                      const arr = [...fields.review_media.value]
                      arr?.splice(idx, 1)
                      if(arr.indexOf('') === -1){
                        return fields.review_media.onChange([...arr,''])
                      }
                      fields.review_media.onChange([...arr])
                    }}
                    setMediaUrl={data => {
                      const arr = [...fields.review_media.value]
                      arr?.splice(idx, 1, data)
                      fields.review_media.onChange(arr?.length > 4 ? [...arr] : [...arr, ''])
                    }}
                  />
                </Box>
              ))}
            </>
          )}
        </Stack>
        <Box display={'flex'} flex={1} justifyContent={'flex-end'}>
          {isRated ? (
            <Button variant="text" startIcon={<CheckSecondIcon color="success" />}>
              <Typography textTransform={'none'} color={'success.dark'}>
                {__('rated')}
              </Typography>
            </Button>
          ) : (
            <Button onClick={submit}>
              <Typography textTransform={'none'}>
                {__(isLoading ? 'btn_processing' : 'rate')}
              </Typography>
            </Button>
          )}
        </Box>
      </Stack>
      <Divider sx={{ opacity: 1 }} />
    </Box>
  )
}

const ReviewModalContainer = styled(Modal)(({ theme }) => ({
  zIndex: 1,
  '& .header': {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    background: theme.palette.background.paper,
    padding: 0
  },
  '& .title': {
    textTransform: 'none',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center'
  },
  '& .body': {
    padding: theme.spacing(0, 3),
    maxHeight: `calc(100vh - ${theme.spacing(30)})`,
    overflowY: 'auto'
  },
  '& .image_product': {
    width: theme.spacing(10),
    height: theme.spacing(10),
    objectFit: 'cover',
    borderRadius: theme.spacing(1)
  },
  '& .MuiRating-icon': {
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
    '& svg': {
      width: '100%',
      height: '100%'
    }
  }
}))
