import React, {memo, useEffect, useState} from 'react'
import '../media/livestream.room.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import {useAppSelector} from 'config/store'
import {Link, useParams} from 'react-router-dom'
import {Socket} from 'socket.io-client'
import {TypedCourse} from 'entities/courses/courses.interface'
import __ from "languages/index";

type Props = {
  socket: Socket
}

type Product = TypedCourse | null

const LivestreamRoomInfo = ({ socket }: Props) => {
  const liveDetail = useAppSelector(state => state.livestream.entity)
  const params = useParams()
  const event_id = params?.event_id
  const [pinningProduct, setPinningProduct] = useState<Product>(liveDetail?.product_id)

  const handleGetProductPinned = (product: Product) => {
    setPinningProduct(product)
  }

  useEffect(() => {
    setPinningProduct(liveDetail?.product_id)
  }, [liveDetail?.product_id])

  useEffect(() => {
    socket.on('productToClient', handleGetProductPinned)
  }, [])

  return (
    <div className="livestreamroomreact w-100">
      <div className="livestream_header mx-3">
        <b>{__("livestream_room_info")}</b>

        <Link
          to={'/event/detail/' + event_id}
          target="_blank"
          className="livestreamroom_btn text-decoration-none"
        >
          <FontAwesomeIcon icon={faCalendarDay} className="me-2 icon_btn" />
          {__("livestream_event")}
        </Link>
      </div>

      <div className="livestreamroom_info_des_container d-flex justify-content-between m-2 flex-grow-1 flex-column">
        <div>
          <h6>{liveDetail?.title}</h6>
        </div>
        <div className="w-100">
          <p>{liveDetail?.caption}</p>
        </div>
      </div>
    </div>
  )
}

export default memo(LivestreamRoomInfo)
