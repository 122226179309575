import React, { DragEvent, useEffect, useRef, useState } from 'react'
import useDragAndDrop, { SelectFileErrorType } from 'hooks/useDragDrop'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import __ from 'languages/index'

export const MAX_UPLOAD_SIZE = 1024 * 1024 * 1024 * 4 // 4GB

const fileErrorMgs = {
  [SelectFileErrorType.FILE_TYPE]: `${__('file_uploader_type')}`,
  [SelectFileErrorType.FILE_SIZE]: `${__('file_uploader_size')}`
}

const getErrorMessage = (type: SelectFileErrorType) => {
  return fileErrorMgs[type]
}

type Props = {
  onUpload?: (files: File[]) => void
  onAddFileError?: (error: string) => void
  children?: (files?: File[]) => React.ReactNode | React.ReactNode[]
  acceptFile?: string
  notice?: string
  textUpload?: string
  disabled?: boolean
  className?: string
  isShowPlaceholder?: boolean
}

const FileUploader = ({
  onUpload: onUpload,
  children,
  acceptFile,
  notice = `${__('file_uploader_notice')}`,
  textUpload = `${__('file_uploader')}`,
  disabled,
  onAddFileError,
  className,
  isShowPlaceholder = true
}: Props) => {
  const [files, setFiles] = useState<File[]>([] as File[])

  const { dragOver, setDragOver, onDragOver, onDragLeave, fileDropError, setFileDropError } =
    useDragAndDrop()
  const inputRef = useRef(null)

  const addFile = (newFiles: FileList) => {
    setFiles([...newFiles])
  }

  const isSupportedFile = (file: File) => {
    const fileName = file.name
    const extension = fileName.split('.').pop()
    if (!acceptFile) return true
    return acceptFile.includes(extension)
  }

  const isOverSize = (file: File) => {
    const fileSize = file.size
    return fileSize > MAX_UPLOAD_SIZE
  }

  const handleAddFileError = (errorType: SelectFileErrorType) => {
    setFileDropError(getErrorMessage(errorType))
    if (onAddFileError) {
      onAddFileError(getErrorMessage(errorType))
    }
  }

  const onDrop = (e: DragEvent<HTMLElement>) => {
    e.preventDefault()
    setDragOver(false)
    const selectedFiles = e?.dataTransfer?.files

    if (isOverSize(selectedFiles[0])) {
      return handleAddFileError(SelectFileErrorType.FILE_SIZE)
    }
    if (!isSupportedFile(selectedFiles[0])) {
      return handleAddFileError(SelectFileErrorType.FILE_TYPE)
    }
    addFile(selectedFiles)
    setFileDropError(null)
    onAddFileError && onAddFileError(null)
  }

  const handleFileChange = e => {
    const selectedFiles = e.target.files
    if (!selectedFiles) return
    addFile(e.target.files)
    setFileDropError(null)
  }

  useEffect(() => {
    if (files.length === 0 || !onUpload) return
    onUpload(files)
  }, [files])

  const dragClassName = 'border-warning'

  const errorClassName = 'border-danger'

  return (
    <label
      htmlFor="file"
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={
        className
          ? `${className}`
          : `w-100 p-3 rounded-2 ${dragOver && dragClassName} ${fileDropError && errorClassName}`
      }
      style={{
        border: '1px dashed #ccc'
      }}
    >
      <input
        multiple
        ref={inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleFileChange}
        hidden
        accept={acceptFile}
        disabled={disabled}
      />
      {children(files)}
      {
        <p className="d-flex align-items-center justify-content-center">
          {isShowPlaceholder && `${__('file_uploader_text')} ${' '}`}
          <span
            className="text-decoration-underline ms-1"
            role="button"
            onClick={() => {
              inputRef.current.click()
            }}
          >
            {textUpload}
            <OverlayTrigger overlay={<Tooltip id="upload-notification">{notice}</Tooltip>}>
              <FontAwesomeIcon icon={faCircleInfo} className="ms-1" />
            </OverlayTrigger>
          </span>
        </p>
      }
    </label>
  )
}

export default FileUploader
