import {createEntitySlice, serializeAxiosError} from 'config/reducer.utils'
import {createAsyncThunk, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit'
import axios from 'axios'
import {TypeMobileApp} from 'entities/mobileApp/mobile.interface'

export const initialState = {
  entity: {},
  entities: [],
  plans: [],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false
}
const apiUrl = 'contact-form'
export const createContact = createAsyncThunk(
  'contact-form/create',
  async (data: Partial<TypeMobileApp> & Pick<TypeMobileApp, 'full_name' | 'form_type'>) => {
    return axios.post<any>(`${apiUrl}/create`, data)
  },
  { serializeError: serializeAxiosError }
)

const MOBILE_REDUCER = createEntitySlice({
  name: 'mobile',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => ({ ...state, ...initialState }),
    clearEntity: state => {
      state.entity = null
    }
  },
  extraReducers(builder) {
    builder
      .addMatcher(isPending(createContact), state => {
        state.loading = true
        state.entity = null
      })
      .addMatcher(isRejected(createContact), (state, action) => {
        state.loading = false
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(createContact), (state, action) => {
        state.loading = false
        // @ts-ignore
        state.entity = action.payload.data
      })
  }
})
export const { clearError, reset, clearEntity } = MOBILE_REDUCER.actions
export default MOBILE_REDUCER.reducer
