import { SvgIcon, SvgIconProps } from '@mui/material'

const FilledHeartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6395 3.73C10.2995 1.67 6.83954 1.25 4.14954 3.57C1.24954 6.07 0.829541 10.28 3.11954 13.26C3.98954 14.39 5.68954 16.09 7.32954 17.65C8.98954 19.23 10.6395 20.72 11.4495 21.45C11.4495 21.45 11.4595 21.45 11.4695 21.46C11.5495 21.53 11.6395 21.61 11.7295 21.68C11.8395 21.76 11.9995 21.87 12.2095 21.93C12.4895 22.01 12.7895 22.01 13.0695 21.93C13.2795 21.87 13.4395 21.76 13.5495 21.68C13.6395 21.61 13.7395 21.53 13.8095 21.46C13.8095 21.46 13.8195 21.46 13.8295 21.45C14.6395 20.72 16.2995 19.23 17.9495 17.65C19.5895 16.09 21.2895 14.39 22.1595 13.26C24.4395 10.29 24.0895 6.05 21.1195 3.56C18.3995 1.28 14.9795 1.67 12.6395 3.73Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default FilledHeartIcon
