import { Box, Paper, Tab, Tabs, styled } from '@mui/material'
import BigLayout from 'layouts/bigLayout'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import ShopBreadcrumb from './components/shop.breadcrumb'
import __, { ___ } from 'languages/index'
import { useLocation, useNavigate } from 'react-router-dom'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { getListOrders, getMyUnReview } from './service/order.service'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { TypedOrder, TypedReviewOrder } from './interface'
import { AxiosResponse } from 'axios'
import OrderItem from './components/shop.myOrder.item'

export enum OrderDeliveryStatus {
  pending = "draft",
  awaitingPickup = "approved",
  awaitingDelivery = "transporting",
  delivered = "completed",
  cancelled = "cancel",
  returnInitated = "refunded"
}

const tabs = [
  {
    label: __('pendingConfirmation'),
    hash: '#pending'
  },
  {
    label: __('awaitingPickup'),
    hash: '#awaitingPickup'
  },
  {
    label: __('awaitingDelivery'),
    hash: '#awaitingDelivery'
  },
  {
    label: __('delivered'),
    hash: '#delivered'
  },
  {
    label: __('cancelled'),
    hash: '#cancelled'
  },
  {
    label: __('returnInitiated'),
    hash: '#returnInitiated'
  }
]

const MyOrder = () => {
  const { hash } = useLocation()
  const navigate = useNavigate()

  const [valueTab, setValueTab] = useState<string>('draft')
  const [unReviewId, setUnReviewId] = useState<string[]>([])
  
  const userIdV2 = localStorage.getItem('user_id_v2')

  const { data, hasMore, isRefreshing, paramRequest, loadMore, setParamRequest, refresh } =
    useInfiniteScroll<TypedOrder>(
      { limit: 10, order_status: valueTab,createdBy: userIdV2, keyAttribute: 'order_id' },
      getListOrders,
      false
    )

  useEffect(() => {
    setParamRequest({
      ...paramRequest,
      page: 1,
      order_status: valueTab
    })
  }, [valueTab])

  useEffect(() => {
    if (valueTab === OrderDeliveryStatus.delivered) {
      getMyUnReview()
        .then((res: AxiosResponse<TypedReviewOrder[]>) => {
          const convertData = res?.data?.map((data: TypedReviewOrder) => data?.order_id)
          setUnReviewId([...convertData])
        })
        .catch(error => {
          console.log(`'getMyUnReview Error >>>`, error)
        })
    }
  }, [valueTab])

  useLayoutEffect(() => {
    switch (hash) {
      case '#pending':
        setValueTab(OrderDeliveryStatus.pending)
        break
      case '#awaitingPickup':
        setValueTab(OrderDeliveryStatus.awaitingPickup)
        break
      case '#awaitingDelivery':
        setValueTab(OrderDeliveryStatus.awaitingDelivery)
        break
      case '#delivered':
        setValueTab(OrderDeliveryStatus.delivered)
        break
      case '#cancelled':
        setValueTab(OrderDeliveryStatus.cancelled)
        break
      case '#returnInitiated':
        setValueTab(OrderDeliveryStatus.returnInitated)
        break
      default:
        setValueTab(OrderDeliveryStatus.pending)
    }
  }, [hash])

  return (
    <BigLayout>
      <MyOrderContainer>
        <ShopBreadcrumb
          breadcrumbName={{
            '/shop': __('shop'),
            '/shop/my-order': __('my_order')
          }}
        />
        <Paper
          sx={{
            borderRadius: 'unset',
            borderTopLeftRadius: theme => theme.spacing(1.5),
            borderTopRightRadius: theme => theme.spacing(1.5),
            border: theme => `1px solid ${theme.palette.divider}`
          }}
        >
          <Tabs value={Object.values(OrderDeliveryStatus)?.findIndex(value => value === valueTab) || 0}>
            {tabs?.map((data: { label: string; hash: string }, idx: number) => (
              <Tab
                key={`tab_${idx}`}
                onClick={() => navigate(`.${data?.hash}`)}
                sx={{
                  textTransform: 'none'
                }}
                label={data?.label || ''}
              />
            ))}
          </Tabs>
        </Paper>
        <InfiniteScrollContainer
          isRefreshing={isRefreshing}
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          refreshFunction={refresh}
          pullDownToRefresh={false}
          showEndMessage={false}
        >
          <Box display={'grid'} rowGap={theme => theme.spacing(3)}>
            {data.map((data, idx) => (
              <OrderItem
                key={`order_${idx}`}
                isReviewed={unReviewId?.indexOf(data?.order_id) === -1}
                valueTab={valueTab}
                dataOrder={data}
              />
            ))}
          </Box>
        </InfiniteScrollContainer>
        <Box className="shop_order_content" width={'100%'}></Box>
      </MyOrderContainer>
    </BigLayout>
  )
}

export default MyOrder

const MyOrderContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(3),
  '& .shop_order_content': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    boxShadow: `0px 2px 8px 0px rgba(0, 0, 0, 0.08)`,
    margin: theme.spacing(5, 0)
  },
  '& .alert_info': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 2.5),
    columnGap: theme.spacing(1.5)
  }
}))
