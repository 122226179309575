 import './media/Title.scss'
interface  TypeTitle{
  text: string
  className?: string
  children?: React.ReactNode
}
const Title: React.FC<TypeTitle> =({text, className, children})=>{
  return(
    <div className={className}>
      <div className='h2'>
        {text}
        {children}
      </div>
    </div>
  )
}
export default Title
