//Library
import {useCallback, useEffect, useState} from 'react'
import {AxiosError, AxiosResponse} from 'axios'

//Components
import AddLevel from './channel.setting.add.level'
import ComponentEmpty from 'components/componentEmpty'
import LevelItem from './channel.setting.edit.level'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getListLevel} from '../store/channelSetting.store.reduce.ts'

//Interface
import {TypeDataLevel} from '../interface'

//Helpers
import helpers from 'helpers'

//Scss
import '../media/channel.setting.level.scss'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { Button } from '@mui/material'

const ChannelSettingLevel = () => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isShowAdd, setIsShowAdd] = useState<boolean>(false)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [showIndexItem, setShowIndexItem] = useState<number | null>(null)
  const [listLevel, setListLevel] = useState<TypeDataLevel[]>([])
  const toast = useToast()

  const toggleItem = useCallback(
    (index: number) => {
      if (showIndexItem === index) {
        setShowIndexItem(null)
      } else {
        setShowIndexItem(index)
      }
    },
    [showIndexItem]
  )

  useEffect(() => {
    if(channel_data?._id){
      const params = {
        channel_id: channel_data?._id,
        order_by: 'ASC'
      }
      dispatch(getListLevel(params))
        .unwrap()
        .then((res: AxiosResponse) => {
          setListLevel(res?.data)
        })
        .catch((error: AxiosError) => {
          console.log(`getListLevel_${error}`)
          setListLevel([])
          toast.show({
            content: `${__("channel_setting_error")}`,
            type: EnumTypeToast.Error
          })
        })
    }

  }, [isRefresh, channel_data?._id])

  return (
    <div id="channelSettingLevel" className="channelSettingLevel_container">
      {isShowAdd ? (
        <AddLevel
          setIsShowAdd={setIsShowAdd}
          isRefresh={isRefresh}
          setIsRefresh={setIsRefresh}
          listLevel={listLevel}
        />
      ) : (
        <>
          <div className="channelSettingLevel_header">
            <h4 className="channelSettingLevel_header_title">{__("channel_setting_data_gamifications")}</h4>
            <span>
              {__("channel_setting_level_text")}
            </span>
          </div>
          <div className="channelSettingLevel_body">
            <div className="channelSettingLevel_body_add_level">
              <Button sx={{textTransform: 'none'}} onClick={() => setIsShowAdd(true)}>
                {__("channel_setting_add_level")}
              </Button>
            </div>
            <div className="channelSettingLevel_body_list">
              {helpers.isEmpty(listLevel) ? (
                <ComponentEmpty />
              ) : (
                <>
                  {listLevel?.map((item: TypeDataLevel, idx: number) => (
                    <LevelItem
                      key={`level_${idx}`}
                      idx={idx}
                      item={item}
                      isRefresh={isRefresh}
                      setIsRefresh={setIsRefresh}
                      listLevel={listLevel}
                      onToggle={toggleItem}
                      isEdit={showIndexItem === idx}
                      isLastItem={idx === listLevel.length-1}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ChannelSettingLevel
