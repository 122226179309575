import {useCallback, useEffect} from "react";
import {EnumLiveStreamSource, ErrorMediaType} from "entities/event/event.interface";
import Button from "react-bootstrap/Button";
import {ID_VIDEO_ADMIN_STUDIO} from "entities/livestream/livestream.admin.constant";
import {EnumTypeInputSound} from "entities/livestream/hooks/livestream.admin.hook";
import {
  setIdAudioDeviceSelectedForLivestream,
  setIsAlreadyPlayLiveFromFile
} from "entities/livestream/livestream.store.reducer";
import {useAppDispatch, useAppSelector} from "config/store";
import {Form} from "react-bootstrap";
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";


interface TypedLivestreamPreviewDeviceScreenProps {
  currentSourceStream: EnumLiveStreamSource,
  setAudioTrack?: (audioTrack: MediaStreamTrack, type: EnumTypeInputSound) => void
}

export default function LivestreamPreviewDeviceScreen({
                                                        setAudioTrack,
                                                        currentSourceStream,
                                                      }: TypedLivestreamPreviewDeviceScreenProps) {

  const idAudioDeviceSelectedForLivestream = useAppSelector(state => state.livestream.idAudioDeviceSelectedForLivestream);
  const audioSourceError = useAppSelector(state => state.livestream.audioSourceError);
  const audioSourceList = useAppSelector(state => state.livestream.audioSourceList);
  const dispatch = useAppDispatch();
  const toast = useToast()

  useEffect(() => {
    if (currentSourceStream === EnumLiveStreamSource.Screen) {
      onChooseScreen()
    }else {
      const videoElementDemo = document.getElementById("video_demo_screen") as any;
      videoElementDemo.srcObject = null;
      videoElementDemo.load();
    }
  }, [currentSourceStream]);

  const onChooseScreen = useCallback(async () => {
    let displayMediaStreamConstraints = {
      video: true,
      audio: true
    };

    let stream = await navigator.mediaDevices.getDisplayMedia(displayMediaStreamConstraints).catch(()=>{
      toast.show({
        type:EnumTypeToast.Error,
        content:`${__("livestream_chose_screen")}`
      })
    });

    if (stream && currentSourceStream === EnumLiveStreamSource.Screen) {
      const videoElement = document.getElementById(ID_VIDEO_ADMIN_STUDIO) as any;
      videoElement.srcObject = stream;
      videoElement.src = null;
      videoElement.muted = false;
      videoElement.load();

      dispatch(setIsAlreadyPlayLiveFromFile(false))

      if (stream.getAudioTracks().length > 0) {
        console.log(stream.getAudioTracks,"stream.getAudioTracks screen")
        setAudioTrack(stream.getAudioTracks()?.[0], EnumTypeInputSound.Sound);
      }else {
        setAudioTrack(undefined, EnumTypeInputSound.Sound);
      }

      const videoElementDemo = document.getElementById("video_demo_screen") as any;
      videoElementDemo.srcObject = stream;
      videoElementDemo.load();
    }
  }, [currentSourceStream])

  const onChangeAudioSource = useCallback((e: any) => {
    dispatch(setIdAudioDeviceSelectedForLivestream(e.target.value))
  }, [])

  return (
    <div className="livestreamSelection">
      <div className="d-flex align-items-center justify-content-center flex-row">
        <video id={"video_demo_screen"} src={null} playsInline autoPlay muted
               className="livestreamadmin_screen_video_demo"/>

        <Button onClick={onChooseScreen}>
          {__("livestream_chose_screen_live")}
        </Button>
      </div>

      <div className="select">
        <label htmlFor="audioSource" className="mt-3 mb-1">{__("livestream_source_mic")}</label>
        {
          audioSourceError ?
            (
              audioSourceError === ErrorMediaType.Permission ?
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_permission_reject")}
                </h6>
                :
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_not_found_mic")}
                </h6>
            )
            :
            <Form.Select id="audioSource"
                         value={idAudioDeviceSelectedForLivestream || undefined}
                         onChange={onChangeAudioSource}>
              {
                audioSourceList?.map((e, index) => {
                  return <option key={index + '_dgsdg'} value={`${e.deviceId}`}>{e.label}</option>
                })
              }
            </Form.Select>
        }
      </div>
    </div>
  )
}
