import { SvgIcon, SvgIconProps } from '@mui/material'

const ShieldCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd" d="M6 3.58333C6 2.70888 6.70888 2 7.58333 2H16.4167C17.2911 2 18 2.70888 18 3.58333C18 4.64188 18.8581 5.5 19.9167 5.5H20.3333C21.2538 5.5 22 6.24619 22 7.16667V8.02527C22 13.9277 18.8 19.3662 13.6403 22.2327L13.1547 21.3585L13.6403 22.2327L13.2621 22.4428C12.4772 22.8789 11.5228 22.8789 10.7379 22.4428L10.3597 22.2327L10.8453 21.3585L10.3597 22.2327C5.20003 19.3662 2 13.9277 2 8.02527V7.16667C2 6.24619 2.74619 5.5 3.66667 5.5H4.08333C5.14188 5.5 6 4.64188 6 3.58333ZM7.9781 4C7.77007 5.96722 6.1057 7.5 4.08333 7.5H4V8.02527C4 13.2014 6.80624 17.9706 11.331 20.4844L11.7092 20.6945L11.2236 21.5686L11.7092 20.6945C11.89 20.795 12.11 20.795 12.2908 20.6945L12.669 20.4844C17.1938 17.9706 20 13.2014 20 8.02527V7.5H19.9167C17.8943 7.5 16.2299 5.96722 16.0219 4H7.9781ZM15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L12.0607 14.3536C11.4749 14.9393 10.5251 14.9393 9.93934 14.3536L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
              fill="currentColor"/>
      </svg>
    </SvgIcon>
  )
}

export default ShieldCheck
