import PaginationTable from "components/pagination_table";
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "helpers/index";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { clearError, getEntities } from "./example.store.reducer";
import debounce from "lodash.debounce";


export default function ExampleList() {
    
    const entity = useAppSelector(state => state.example.entity);
    const loading = useAppSelector(state => state.example.loading);
    const updating = useAppSelector(state => state.example.updating);
    const errorMessage = useAppSelector(state => state.example.errorMessage);
    const updateSuccess = useAppSelector(state => state.example.updateSuccess);

    const dispatch = useAppDispatch();
    
    
    const rootSearchPath = window.location.search;
    /**
     * If user apply filter, it will add to URL, then parse URL back to initial state
     */
    let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
    const initialQuery = {
        query: "",
        page: 1,
        limit: 50
        // sort: "createdAt:desc",
    };
    const [mainQuery, setMainQuery] = useState<any>({
        ...initialQuery,
        ...StringQuery,
    });

    useEffect( () => {
        dispatch( clearError() );
    }, []);
    
    /**
     * Change page number
     * Must be mainQuery or it will reset mainQuery. BUG!
     */
    const onChangePagination = useCallback( (page: number, limit: number) => {
            setMainQuery({...mainQuery, ...{ page, limit }} );
    }, []);

    const reduceRequest = useCallback( (mainQuery) => {
        dispatch(getEntities(mainQuery));  
        return mainQuery;
    }, []);

    const reduceRequestMemo = useMemo(() => {
        return debounce((_value) => {
            reduceRequest?.call(this, _value)
        }, 500);
      }, []);
    
    
    useEffect(() => {
        let buildURLSearch = helpers.buildEndUrl(mainQuery);
        if (window.location.search !== buildURLSearch) {
            window.history.replaceState(null, "Example", "/example" + buildURLSearch);
        }
        reduceRequestMemo(mainQuery);
    }, [mainQuery]);

    useEffect( () => {
        if ( updateSuccess === true ) {
            dispatch( clearError() );
            reduceRequestMemo(mainQuery);
        }
    }, [updateSuccess]);


    
    return (
        <>
            <div className="mainFrame">
                <div className="container">

                    <p>ExampleList(): Khi bạn thấy dòng này, nghĩa là mọi thứ working...</p>
                    <Link to={"/example/view/3"}>Xem bừa một ID</Link>

                    <br />
                    <PaginationTable
                        TotalRecord={200}
                        onChange={onChangePagination}
                        pageSize={Number(mainQuery?.limit)}
                        activeCurrentPage={Number(mainQuery?.page)}
                    />

                </div>
            </div>
        </>
    )
}