//Library
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useLayoutEffect } from 'react'
import { Box, IconButton, styled } from '@mui/material'

//Helpers
import helpers from 'helpers'
import {EnumTypeToast, useToast} from 'hooks/useToast'

//Icons
import { FacebookIcon, GoogleIcon } from 'icons'

import __ from 'languages/index'
import {useAppSelector} from "config/store";

declare namespace google {
  namespace accounts {
    namespace id {
      interface Credential {}
      interface InitializeOptions {}
      interface RenderButtonOptions {}
      function initialize(options: InitializeOptions): void
      function renderButton(element: HTMLElement, options: RenderButtonOptions): void
    }
  }
}

export default function SocialNetworkComponent() {
  const navigate = useNavigate()
  const toast = useToast()
  const facebook_login_client_id = useAppSelector(state => state.user?.channel_data?.facebook_login_client_id)
  const google_login_client_id = useAppSelector(state => state.user?.channel_data?.google_login_client_id)

  /**
   * PUBLIC_URL sẽ tự động ăn theo root path, CHỨ KHÔNG PHẢI NÓ ĐƯỢC SET trong env nhá!
   */
  // const PUBLIC_URL = process.env.PUBLIC_URL || '/'
  // const callbackFacebookLink = `https://${window.location.host + `${PUBLIC_URL}` }/login/facebook`;

  /** Khi SSO, nếu self-hosting thì khóa lại ... */
  const callbackFacebookLink = window.location.origin+'/login/facebook'
  const callbackGoogleLink = window.location.origin+'/login/google'
  const loginWithFacebookURL = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${facebook_login_client_id}&display=popup&response_type=token&redirect_uri=${callbackFacebookLink}`

  useEffect(() => {
    if(google_login_client_id){
      try {
        google.accounts.id.initialize({
          client_id: google_login_client_id,
          auto_select: true,
          callback: handleGoogleLogin,
          auto_prompt: false,
          context: 'signin',
          login_uri: callbackGoogleLink,
          ux_mode: 'popup'
        });

        googleButtonRendering();

      } catch (error) {
        console.log(error, 'GOOOGLE LOGIN ERROR <<<<')
      }
    }
  }, [google_login_client_id])

  const googleButtonRendering =  useCallback( () => {
    const googleButton = document.getElementById('google-login-button');
    google.accounts.id.renderButton(googleButton, {
      size: 'large',
      theme: 'outline',
      type:'icon',
      logo_alignment: 'center',
      width: '300',
      height: '300'
    })
  }, [google_login_client_id]);

  const handleGoogleLogin = useCallback(
    async (response: { credential: string; select_by: string, error: any }) => {
      if (response.error) {
        // Handle error response from Google
        console.log(response.error , '<<< Gooogle Response Error');
      }
      try {
        const { credential } = response
        await helpers.sleep(500)
        navigate('/login/google', {
          state: {
            credential: credential
          }
        })
      } catch (error) {
        console.log(error, 'User cancel login with Google')
        toast.show({
          content: `${__('login_failed')}`,
          type: EnumTypeToast.Error
        })
      }
    },
    []
  )


  const onClickGoogleLogin = useCallback( async () => {
    for (let attempt = 1; attempt <= 3; attempt++) {
      let realGoogleButton = document.getElementById("google-login-button");
      let buttonDiv: HTMLElement | null = realGoogleButton ? realGoogleButton.querySelector('div[role="button"]') : null;
      if (buttonDiv) {
        buttonDiv.click();
        break; // Exit the loop if the button is found
      } else {
        await helpers.sleep(1000);
        googleButtonRendering();
        // Log or handle the fact that the button was not found in this attempt
        console.log(`Attempt ${attempt}: Button not found`, 'Google Login');
      }
    }
  }, [google_login_client_id]);

  return (
    <SocialNetworkContainer sx={{
      display: 'flex',
      justifyContent: 'center'
    }} id="socialNetworkComponent">

      {
        facebook_login_client_id &&
        <Box position={'relative'} sx={{
          minWidth:'45%'
        }}>
          <a href={loginWithFacebookURL}>
            <IconButton className="icon_button">
              <FacebookIcon />
            </IconButton>
          </a>
        </Box>
      }

      {
        google_login_client_id &&
        <Box position={'relative'} sx={{
          minWidth:'45%'
        }}>
          <IconButton onClick={onClickGoogleLogin} className="icon_button">
            <GoogleIcon />
          </IconButton>
          <Box id="google-login-button" className="login_google" visibility={'hidden'}></Box>
        </Box>
      }


      {/* <IconButton className="icon_button">
        <AppleIcon />
      </IconButton> */}
    </SocialNetworkContainer>
  )
}

const SocialNetworkContainer = styled(Box)(({ theme }) => ({
  // display: 'grid',
  // gridTemplateColumns: '1fr 1fr',
  columnGap: theme.spacing(2),
  '& .icon_button': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(1.5)
  },
  '& .login_google': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    '& div': {
      height: '100%',
      width:0
    }
  }
}))
