//Library
import { createRef } from 'react'
import { useParams } from 'react-router-dom'

//Components
import Theme404 from '../../layout/404'
import courses_list from './courses.list'
import courses_view from './courses.view'
import courses_update from './courses.update'
import courses_income from './courses.income'
import courses_update_modules from './courses.update.modules'
import ToastComponent from 'entities/courses/components/toast.component'
import CourseJoined from './course.joined'

/**
 *   Create index file for Comment
 */
export default function Courses() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'list'

  let ActualPage: any
  switch (Param) {
    case 'list':
      ActualPage = courses_list
      break

    case 'view':
      ActualPage = courses_view
      break

    case 'update':
      ActualPage = courses_update
      break

    case 'add':
      ActualPage = courses_update
      break

    case 'income':
      ActualPage = courses_income
      break

    case 'update-module':
      ActualPage = courses_update_modules
      break

    case 'course-joined':
      ActualPage = CourseJoined
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div>
      {<ActualPage />}
    </div>
  )
}
