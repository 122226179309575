import { Button, Checkbox, Paper, Stack, SxProps, Typography } from '@mui/material'
import { CurrentTheme } from '../../../styles/themes'
import { useNavigate } from 'react-router-dom'
import __, { ___ } from 'languages/index'
import helpers from 'helpers'
import { useCallback } from 'react'

type Props = {
  onSelectAllProducts: () => void
  selectedProductsLength: number
  selectedProductData: any
  productsLength: number
  totalPayment: number
}

const CartFooter = ({
  onSelectAllProducts,
  selectedProductsLength,
  selectedProductData,
  productsLength,
  totalPayment
}: Props) => {
  const navigate = useNavigate()
  const isSelectAll = selectedProductsLength === productsLength
  const indeterminate = selectedProductsLength > 0 && selectedProductsLength < productsLength

  const handleOrderNow = useCallback(() => {
    localStorage.setItem('product_cart', JSON.stringify(selectedProductData))
    navigate('/shop/payment')
  }, [selectedProductData])

  return (
    <Stack
      component={Paper}
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={containerStyled}
    >
      <Stack direction={'row'} alignItems={'center'}>
        <Checkbox
          onChange={onSelectAllProducts}
          checked={isSelectAll}
          indeterminate={indeterminate}
        />
        <Typography textTransform={'capitalize'} variant={'subtitle2'} fontWeight={600}>
          {__('check_all')} ({selectedProductsLength})
        </Typography>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <Stack>
          <Typography textTransform={'none'} variant={'subtitle2'} fontWeight={600}>
            {___('total_paymemt {product} items', {
              product: <>{selectedProductsLength || '0'}</>
            })}
          </Typography>
          {Number(selectedProductsLength) > 0 && (
            <Typography
              variant={'subtitle2'}
              fontWeight={600}
              color={'primary'}
              textAlign={'right'}
            >
              {`${helpers.formatNumberWithCommas(totalPayment || '0')} VNĐ`}
            </Typography>
          )}
        </Stack>
        <Button onClick={handleOrderNow} disabled={helpers.isEmpty(selectedProductData)}>
          <Typography textTransform={'none'} fontWeight={600}>
            {__('order_now')}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  )
}

export default CartFooter

const containerStyled: SxProps<CurrentTheme> = {
  padding: theme => theme.spacing(3),
  height: theme => theme.spacing(15.375)
}
