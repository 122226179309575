import { Box, Drawer, DrawerProps, IconButton, Stack, styled, SxProps, Theme, Typography } from "@mui/material";
import { MenusIcon } from "icons";
import ArrowChevronLeftIcon from "icons/ArrowChevronLeftIcon";
import { TypedRequest } from "interfaces/request.interface";
import AvatarComponent from "layouts/components/AvatarComponent";
import React, { Dispatch, SetStateAction, useRef } from "react";
import { Link } from "react-router-dom";
import AddCommentForm from "./AddCommentForm";
import NewsFeedPost from "./NewsFeedPost";
import PostComment from "./PostComment";
import PostOptions from "./PostOptions";

interface TypedProps {
    modalProps: Omit<
        DrawerProps & {
            onClose?: () => void
        },
        'children'
    >
    post: TypedRequest
    is_user_logged_in: boolean,
    isLike: boolean,
    setIsLike: React.Dispatch<any>

}
const DetailPostMobile = ({ modalProps, is_user_logged_in, isLike, setIsLike, post }: TypedProps) => {
    const { onClose, ...rest } = modalProps

    const inputRef = useRef<HTMLInputElement>(null)

    const handleClickCommentButton = () => {
        inputRef.current?.focus()
    }
    return (
        <DrawerContainer
            PaperProps={{
                sx: {
                    width: '100%',
                    borderRadius: '0'
                }
            }}
            elevation={8}
            {...rest}
            onClose={onClose}
            anchor="right"
        >
            {post && (
                <>
                    <NewsFeedPost
                        onClose={onClose}
                        post={post}
                        isLike={isLike}
                        setIsLike={setIsLike}
                        variant={"flatten"}
                        is_user_logged_in={is_user_logged_in}
                        onClickCommentButton={handleClickCommentButton}
                        PostOptionsComponent={props => (
                            <PostOptions
                                post={post}
                                // options={[
                                //   ...mergeArrayObjectsByKey(postOptionConfigs, [...postOptions(postDetail)], 'key')
                                // ]}
                                {...props}
                            />
                        )}
                        isDetailMobile={true}
                    />

                </>

            )}
            <Box sx={postCommentContainerStyled}>
                <PostComment post={post} />
            </Box>

            {post?.is_comment == 1 && (
                <AddCommentForm className="form-add-comment" ref={inputRef} postId={post?._id} />
            )}
        </DrawerContainer>
    )
}

export default DetailPostMobile;

const DrawerContainer = styled(Drawer)(({ theme }) => ({
    '& .form-add-comment': {
        position: 'sticky',
        bottom: 0,
        width: '100%',
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    }
}))

const postCommentContainerStyled: SxProps<Theme> = {
    padding: theme => `${theme.spacing(2)} ${theme.spacing(3)}`
  }
