import React from 'react'
import ProductDetailContainer from './ProductDetailContainer'
import { Box, Stack } from '@mui/material'
import helpers from 'helpers'
import { EmptyIcon } from 'icons';
import __ from 'languages/index';

interface TypedProps {
  product_specifications: string[];
}
const ProductSpecifications = ({ product_specifications = [] }: TypedProps) => {
  return (
    <ProductDetailContainer label={__('product_specifications')}>
      
        {helpers.isEmpty(product_specifications) ? (
           <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
             <EmptyIcon 
               sx={{
                  width: theme => theme.spacing(18.75),
                  height: theme => theme.spacing(18.75)
                }} />
           </Box>
          ): (
            <Stack
              sx={{
                border: theme => `1px solid ${theme.palette.divider}`
              }}
            >
            {product_specifications.map((res, index) => {
                const data = JSON.parse(res);
                const key = Object.keys(data)

                return (
                  <Stack
                    direction={'row'}
                    key={index}
                    sx={{
                      borderBottom: theme => `1px solid ${theme.palette.divider}`
                    }}
                  >
                    <Box
                      className={'item'}
                      sx={{
                        borderRight: theme => `1px solid ${theme.palette.divider}`,
                        padding: theme => theme.spacing(1.5)
                      }}
                    >
                    {key[0]}
                    </Box>
                    <Box
                      sx={{
                        padding: theme => theme.spacing(1.5)
                      }}
                    >
                      {data[key[0]]}
                    </Box>
                  </Stack>
                )
              })
            }
          </Stack>
          )}

    </ProductDetailContainer>
  )
}

export default ProductSpecifications
