//Library
import React, { useCallback, useEffect, useRef, useState } from 'react'
import __, { ___ } from 'languages/index'
import { AxiosResponse } from 'axios'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { Alert, Box, Button, Divider, IconButton, Paper, Typography, keyframes, styled } from '@mui/material'

//Component
import SocialNetworkComponent from './component/social.login'
import ProgressBarPassword from 'components/ProgressBarPassword'
import _Helmet from 'components/helmet'
import TextFieldMUI from 'components/TextFieldMUI'

//Helper
import helpers from 'helpers'

//Images
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg'

//Store
import { checkJoinChannel, getCurrentUserData, getUserPermission, setUserData } from '../store/user.store.reducer'
import { useAppDispatch, useAppSelector } from 'config/store'
import { loginWithPassword, registerAccount } from 'store/auth.store.reducer'

//Icons
import { ArrowLeftIcon, EyeIcon, EyeOffIcon, MobileIcon, SafetyClockIcon, UserIcon } from 'icons'
import useRedirectAuth from "../hooks/useRedirectAuth";
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'

export default function RegisterPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const passwordRef = useRef(null)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const { goToRegister, getPathToLogin } = useRedirectAuth()


  /**!SECTION
   * @author jamviet.com
   * Load async a script from root domain for SSO function
   */

  useEffect(() => {
    if (window.location.host !== "auth.appuni.io" && window.location.host !== "dev.auth.appuni.io") {
      if (channel_data !== null && !channel_data?.facebook_login_client_id && !channel_data?.google_login_client_id) {
        goToRegister()
      }
    }
  }, [channel_data]);

  const handleShowTextError = (text: string) => {
    let errorMessage = ''
    switch (text) {
      case 'account_existed':
        errorMessage = `${__("error_account_existed")}`
        break
      default:
        errorMessage = text
        break
    }
    return errorMessage
  };

  const handleLogin = useCallback((account: string, password: string) => {
    const hashName = account.indexOf('@') === -1 ? '#phone' : '#email'
    navigate(`.${hashName}`)
    setIsLoading(true)
    dispatch(
      loginWithPassword({
        user_password: password.trim(),
        user_input: account.trim()
      })
    )
      .unwrap()
      .then(async (res: AxiosResponse) => {
        const authCode = res.headers['x-authorization'] || ''

        document.cookie = `session=${authCode};path=/;SameSite=Lax`

        localStorage.setItem('session', authCode)
        await helpers.sleep(500); // make sure anything should written to cookie and localStorage
        await dispatch(getCurrentUserData())
        await dispatch(getUserPermission())
        await dispatch(checkJoinChannel()).unwrap()
          .then((res) => {
            if (res?.data?.is_join) return navigate('/', { replace: true });
            return navigate(`/channel/join/${channel_data?._id}`)
          })
          .catch((error) => {
            console.log('checkJoinChannel Error >>>', error);
          })
        setError('')
        setIsLoading(false)
      })
      .catch(error => {
        console.log(`loginWithPassword_${error}`)
        setError(error)
        setIsLoading(false)
      })
  }, [channel_data?._id])

  const { fields, submit } = useForm({
    fields: {
      user_input: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_email_phone_not_empty')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_email_phone_not_empty')}`
            }
            if (!helpers.isEmail(inputVal)) {
              if (!helpers.isPhoneNumber(inputVal)) {
                return `${__('register_email_phone_incorrect')}`
              }
            }
          }
        ]
      }),
      fullName: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_username_not_empty')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_username_not_empty')}`
            }
          }
        ]
      }),
      password: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_password_not_empty')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_password_not_empty')}`
            }
            if (helpers.getPasswordStrength(inputVal) < 1) {
              return `${__('register_password_invalid')}`
            }
          }
        ]
      }),
      confirm_password: useField<string>({
        value: '',
        validates: [
          notEmpty(`${__('register_re-enter_password')}`),
          inputVal => {
            if (!inputVal) {
              return `${__('register_re-enter_password')}`
            }
            if (inputVal !== passwordRef?.current) {
              return `${__('register_password_not_match')}`
            }
          }
        ]
      })
    },
    async onSubmit(values) {
      try {
        const formData = {
          user_input: values.user_input.trim(),
          user_password: values.password.trim(),
          device_uuid: '',
          device_type: 'website',
          user_referrer: localStorage.getItem('user_referrer') || '',
          domain: window.location.origin,
          full_name: values.fullName.trim()
        }
        setIsLoading(true)
        dispatch(registerAccount(formData))
          .unwrap()
          .then((response: AxiosResponse) => {
            dispatch(setUserData(response))
            handleLogin(values.user_input, values.password)
            setIsLoading(false)
            setError('')
          })
          .catch(error => {
            console.log(`registerAccount_${error}`)
            setIsLoading(false)
            setError(error)
          })
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  return (
    <>
      <_Helmet title="register" />
      <RegisterContainer id="registerPage">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.primary_color }))}')`,
          }}
        />

        <Box
          id={'background_svg_default'}
        />

        <Box id={'box_form'} className="wrapper">
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
            position: 'relative',
              maxWidth: theme => theme.spacing(76),
              height: 'auto',
              width: '100%'
            }}
          >
            <Box>
              {error && <Alert severity="error">{handleShowTextError(error)}</Alert>}
              <Box
                display={'grid'}
                rowGap={theme => theme.spacing(3)}
                padding={theme => theme.spacing(3)}
              >
                <Box justifyContent={'flex-start'}>
                  <IconButton sx={{ p: 0 }}>
                    <Link to={getPathToLogin()}>
                      <ArrowLeftIcon sx={{ color: theme => theme.palette.text.primary }} />
                    </Link>
                  </IconButton>
                </Box>
                <Typography
                  variant="h5"
                  textTransform={'none'}
                  fontWeight={700}
                  textAlign={'start'}
                >
                  {__('register')}
                </Typography>
                <form onSubmit={submit} className="form">
                  <TextFieldMUI
                    placeholder={__('user_name')}
                    {...fields.fullName}
                    autoFocus
                    iconStart={<UserIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    id="full_name"
                  />
                  <TextFieldMUI
                    placeholder={__('register_form_label_email_or_phone')}
                    {...fields.user_input}
                    iconStart={<MobileIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    id="user_input"
                  />
                  <Box>
                    <TextFieldMUI
                      type={isShowPassword ? 'text' : 'password'}
                      placeholder={__('register_form_label_password')}
                      {...fields.password}
                      id="password"
                      onChange={(value: string) => {
                        fields.password.onChange(value)
                        passwordRef.current = value
                      }}
                      iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                      iconEnd={
                        <IconButton
                          type="button"
                          onClick={() => setIsShowPassword(!isShowPassword)}
                          sx={{ p: 0 }}
                        >
                          {isShowPassword ?
                            <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} /> :
                            <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />}
                        </IconButton>
                      }
                      error={fields.password.value ? '' : fields.password.error}
                    />
                    {fields.password.value && (
                      <ProgressBarPassword
                        strength_password={helpers.getPasswordStrength(fields.password.value)}
                      />
                    )}
                  </Box>
                  <TextFieldMUI
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    placeholder={__('register_form_label_re-enter_password')}
                    {...fields.confirm_password}
                    id="confirm_password"
                    iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                    iconEnd={
                      <IconButton
                        type="button"
                        onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                        sx={{ p: 0 }}
                      >
                        {isShowConfirmPassword ?
                          <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} /> :
                          <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />}
                      </IconButton>
                    }
                  />
                  <Button disabled={isLoading} type="submit" sx={{ p: theme => theme.spacing(2.1875, 2) }}>
                    <Typography textTransform={'none'}>
                      {isLoading ? __('btn_processing') : __('register_btn_register')}
                    </Typography>
                  </Button>
                </form>
                <Box
                  display={'grid'}
                  alignItems={'center'}
                  columnGap={theme => theme.spacing(2)}
                  gridTemplateColumns={'1fr auto 1fr'}
                  p={theme => theme.spacing(1.1875, 0)}
                >
                  <Divider
                    sx={{
                      borderColor: theme => theme.palette.text.disabled
                    }}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    {__('or')}
                  </Typography>
                  <Divider
                    sx={{
                      borderColor: theme => theme.palette.text.disabled
                    }}
                  />
                </Box>
                <SocialNetworkComponent />
                <Typography sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {___('By log in, you agree to our {terms} and {policies}', {
                    terms: (
                      <>
                        &nbsp;
                        <Link to="/tos" className="link">
                          <Typography color={'text.primary'} fontWeight={600}>
                            {` ${__('terms_of_use')} `}
                          </Typography>
                        </Link>
                        &nbsp;
                      </>
                    ),
                    policies: (
                      <>
                        &nbsp;
                        <Link to="/tos" className="link">
                          <Typography color={'text.primary'} fontWeight={600}>
                            {` ${__('policies')} `}
                          </Typography>
                        </Link>
                      </>
                    )
                  })}
                </Typography>
              </Box>
            </Box>
            <Paper className="footer">
              <Box display={'flex'} alignItems={'center'}>
                <Typography>{__('register_question')}</Typography>
                <Button variant="text" sx={{ p: 0 }}>
                  <Link to={getPathToLogin()} style={{ textDecoration: 'none' }}>
                    <Typography textTransform={'none'} fontWeight={600}>
                      &nbsp;{__('login')}
                    </Typography>
                  </Link>
                </Button>
              </Box>
            </Paper>
          </Paper>
        </Box>
      </RegisterContainer>
    </>
  )
}


const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`;


const RegisterContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },
  '& #box_form': {
    position: 'absolute',
    top: 0
  },
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 824px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
  '& .footer': {
    display: 'flex',
    columnGap: theme.spacing(1.25),
    width: '100%',
    justifyContent: 'center',
    background: theme.palette.background.default,
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(0, 0, 1.5, 1.5)
  },
  '& .form': {
    display: 'grid',
    rowGap: theme.spacing(1.25)
  },
  '& .link': {
    color: theme.palette.text.primary
  }
}))
