import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, SelectChangeEvent, styled, Typography } from "@mui/material"
import AddressForm from "components/AddressForm";
import TextareaAutoSize from "components/TextAreaAutoSize";
import TextFieldMUI from 'components/TextFieldMUI'
import { convertData, getStyles } from 'entities/shop/helper'
import __ from "languages/index";
import { useEffect, useState } from "react";
import addressData from '../../challenge/media/diachinh.json';
import { TypeAddess, TypeDistrict, TypeWard } from "../interface";
interface TypedProps {
    fields: any
 }
const ShippingUnitsInfomation = ({fields }: TypedProps) => {

    return (
        <InfomationContainer>
            <Typography variant="h6" >
                {__('shipping_infomation')}
            </Typography>

            <Box className="box_form">
                <form>
                    <Grid container spacing={2} >
                        <Grid item xs={8} >
                            <TextFieldMUI

                                  {...fields.manager}
                                maxLength={30}
                                requiredIndicator
                                placeholder={__('sender')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFieldMUI
                                  {...fields.hotline}
                                maxLength={10}
                                requiredIndicator
                                placeholder={__('validate_code_telephone_number')}
                            />
                        </Grid>

                    </Grid>

                    <AddressForm fields={{
                        order_customer_address_city: fields.stock_address_city,
                        order_customer_address_district: fields.stock_address_district,
                        order_customer_address_ward: fields.stock_address_ward
                    }} />

                    <Box className="address" >
                        <TextFieldMUI
                            {...fields.stock_address}
                            maxLength={150}
                            requiredIndicator
                            placeholder={__('product_stock_address')}
                        />
                    </Box>
                    <Box className="desciption">
                        <TextareaAutoSize {...fields.stock_desciption} />
                    </Box>
                </form>
            </Box>
        </InfomationContainer>
    )
}

export default ShippingUnitsInfomation

const InfomationContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4.5, 3),
    marginTop: theme.spacing(3),
    '& fieldset': {
        border: 'none'
    },
    '& .box_form': {
        marginTop: theme.spacing(5),

        '& .address': {
            marginTop: theme.spacing(3)
        },


    }
}))