import _Helmet from "components/helmet";
import EventListComponent from "entities/event/components/event.list.component";
import __ from "languages/index";
import BigLayout from "../../layouts/bigLayout";


export default function EventList() {
  return (
    <BigLayout>
      <_Helmet title='event_list' />
      <div className="mainFrame">
        <div className="container">
          <EventListComponent />
        </div>
      </div>
    </BigLayout>
  )
}
