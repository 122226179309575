import { z } from 'zod'
import { invalid } from 'entities/support/utils'
import __ from 'languages/index'

export const CreateCategoryPostSchema = z.object({
  category_title: z.string().min(1, invalid('required', __('channel_setting_label_category_name'))),
  category_content: z
    .string()
    .min(1, invalid('required', __('channel_setting_label_category_name')))
})

export type CreateCategoryPostPayload = z.infer<typeof CreateCategoryPostSchema>
