import React, { useState } from 'react'
import { Stack, TableCell, Typography, Box, TextField } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import Table from '../components/Table'
import { pointConfigs } from './pointConfigs'
import RowActions from '../components/RowActions'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannel, updateChannelThunk } from '../store/channel.slice'
import { TypedPointData } from 'interfaces/channel.interface'
import RenderView from 'components/RenderView'
import { EnumPointDataKey } from 'constant/index'
import { useDispatch } from 'react-redux'
import channelService from '../service'

const getPointLabel = (key: EnumPointDataKey) => {
  return pointConfigs[key]
}

const PointConfigurations = () => {
  const dispatch = useAppDispatch()
  const channel = useAppSelector(channelInfo)
  const pointData = channel?.point_data

  const [editingPoint, setEditingPoint] = useState<TypedPointData>(null)

  const handleClickEditBtn = (point: TypedPointData) => {
    setEditingPoint(point)
  }

  const handleUpdatePoint = async () => {
    if (!editingPoint) return
    try {
      dispatch(
        updateChannelThunk({
          ...channel,
          point_data: pointData.map(point =>
            point.key === editingPoint.key ? editingPoint : point
          )
        })
      ).unwrap()
    } catch (err) {
      console.log(err)
    }

    setEditingPoint(null)
  }

  const renderCell = (item: TypedPointData) => {
    return (
      <>
        <TableCell>
          <Typography fontWeight={600}>{getPointLabel(item.key)}</Typography>
        </TableCell>
        <TableCell>
          {item.key === editingPoint?.key ? (
            <TextField
              sx={{
                maxWidth: '100px'
              }}
              size="small"
              value={editingPoint?.value}
              onChange={e => {
                setEditingPoint({
                  ...editingPoint,
                  value: e.target.value
                })
              }}
              // onBlur={event => {
              //   event.stopPropagation()
              //   setEditingPoint(null)
              // }}
              autoFocus
              label={__('channel_setting_level_point')}
            />
          ) : (
            <Box
              sx={{
                padding: theme => theme.spacing(0.25, 1),
                borderRadius: theme => theme.spacing(1.5),
                bgcolor: theme => theme.palette.background.default,
                width: 'fit-content'
              }}
            >
              <Typography
                sx={{
                  color: theme => theme.palette.primary.main,
                  fontWeight: 600
                }}
              >
                {`${item.value} ${__('point_unit')}`}
              </Typography>
            </Box>
          )}
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => handleClickEditBtn(item)}
            editing={item.key === editingPoint?.key}
            onConfirm={handleUpdatePoint}
          />
        </TableCell>
      </>
    )
  }

  return (
    <Stack gap={2}>
      <ChannelSettingsTitle>{__('channel_setting_data_point')}</ChannelSettingsTitle>
      <Typography>
        Chức năng "Cài đặt điểm" trong ứng dụng của chúng tôi là một cách thú vị để tạo động viên và
        tạo ra một cộng đồng tích cực. Với chức năng này, bạn có thể cài đặt điểm cho những hoạt
        động mà bạn thấy cần thiết, tạo sự cổ vũ và khích lệ các thành viên khác.
      </Typography>
      <RenderView
        view={<Table data={pointData} renderTableRow={item => renderCell(item)} />}
        isLoading={!Boolean(pointData)}
      />
    </Stack>
  )
}

export default PointConfigurations
