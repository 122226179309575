import {useCallback, useEffect, useRef, useState} from 'react'
import {EnumLiveStreamSource} from 'entities/event/event.interface'
import {
  EnumReadyStatusLivestream,
  EnumStatusLivestream,
  TypedLivestream
} from 'entities/livestream/livestream.interface'
import {DEFAULT_VOLUME} from 'entities/livestream/livestream.admin.constant'
import {useAppDispatch} from 'config/store'
import {clearEntity, setEntity, updateEntity} from 'entities/livestream/livestream.store.reducer'
import {Socket} from 'socket.io-client'
import {postSDPOffer} from 'entities/livestream/services/cloudflare.service'
import {updateLiveStream} from 'entities/livestream/services/livestream.service'
import helpers from 'helpers'
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

export enum EnumTypeInputSound {
  Mic = 'mic',
  Sound = 'sound'
}

export enum EnumCommunicateClient {
  StopStreamWithOutEndLive = 'StopStreamWithOutEndLive',
  StartStreamSuccess = 'StartStreamSuccess',
  UpdateTitleAndCaption = 'UpdateTitleAndCaption'
}

export const useLivestreamAdmin = (liveDetail: TypedLivestream, socket: Socket) => {
  const [changeStream, setChangeStream] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState<RTCPeerConnectionState>()
  const refConnectionStatus = useRef<RTCPeerConnectionState>()
  const refStream = useRef<null | MediaStream>(null)
  const refLiveStatus = useRef(liveDetail?.livestream_status)
  const refIsCallStartLive = useRef(false)
  const refGainNodeSound = useRef<GainNode>()
  const refGainNodeMicro = useRef<GainNode>()
  const refDefaultSoundVolume = useRef(DEFAULT_VOLUME)
  const refDefaultMicroVolume = useRef(DEFAULT_VOLUME)
  const refLiveDetail = useRef<TypedLivestream>(liveDetail)
  const dispatch = useAppDispatch()
  const refPeerConnections = useRef<RTCPeerConnection>()
  const refSoundTrack = useRef<any>()
  const refMicroTrack = useRef<any>()
  const toast = useToast()

  const refMaxResolution = useRef({
    width: 1080,
    height: 1920
  })

  useEffect(() => {
    socket.on('livestreamStartToClient', data => {
      console.log('livestreamStartToClient')
      if (refLiveDetail?.current.input_type === EnumLiveStreamSource.OutSide) {
        try {
        if(!helpers.isJson(data)) return
          if (typeof JSON.parse(data) === 'object') {
            let dataLivestream = JSON.parse(data)
            dispatch(setEntity({ data: { entity: dataLivestream } }))
            toast.show({
              type: EnumTypeToast.Success,
              content: 'Livestream bắt đầu'
            })
          }
        } catch (error) {
          console.log(error, 'sljnfjsssbsdf')
        }
      }
    })
  }, [])

  useEffect(() => {
    refLiveDetail.current = liveDetail
  }, [liveDetail])

  useEffect(() => {
    if (
      refStream.current &&
      refStream.current.getAudioTracks().length > 0 &&
      liveDetail?.livestream_status === EnumStatusLivestream.Live &&
      !refIsCallStartLive.current &&
      liveDetail?.whip_data &&
      liveDetail?.input_type !== EnumLiveStreamSource.OutSide &&
      liveDetail?.ready_status === EnumReadyStatusLivestream.Disconnected
    ) {
      startLivestream()
    }

    refLiveStatus.current = liveDetail?.livestream_status
  }, [liveDetail?.livestream_status, changeStream])

  const endLiveStream = useCallback(() => {
    disconnectStream()
    dispatch(
      updateEntity({
        _id: refLiveDetail.current?._id,
        livestream_status: EnumStatusLivestream.Ended
      })
    )
  }, [])

  async function waitToCompleteICEGathering(peerConnection: RTCPeerConnection) {
    return new Promise(resolve => {
      /** Wait at most 1 second for ICE gathering. */
      setTimeout(function () {
        resolve(peerConnection.localDescription)
      }, 1000)
      peerConnection.onicegatheringstatechange = ev =>
        peerConnection.iceGatheringState === 'complete' && resolve(peerConnection.localDescription)
    })
  }

  useEffect(() => {
    return () => {
      disconnectStream(true)
    }
  }, [])

  window.onunload = useCallback(() => {
    disconnectStream(true)
  }, [])

  const disconnectStream = (isUnload = false) => {
    if (refStream.current) {
      refStream.current?.getTracks().forEach(track => track?.stop())
    }

    if (
      refLiveDetail.current &&
      refLiveDetail.current?.input_type !== EnumLiveStreamSource.OutSide
    ) {
      if (isUnload) {
        updateLiveStream({
          _id: refLiveDetail.current?._id,
          ready_status: EnumReadyStatusLivestream.Disconnected
        })
        dispatch(clearEntity())
      } else {
        dispatch(
          updateEntity({
            _id: refLiveDetail.current?._id,
            ready_status: EnumReadyStatusLivestream.Disconnected
          })
        )
      }
    }

    if (refIsCallStartLive.current) {
      fetch(refLiveDetail.current?.whip_data, {
        method: 'DELETE',
        mode: 'cors'
      })
    }

    if (refPeerConnections.current) {
      refPeerConnections.current.onconnectionstatechange = null
      refPeerConnections.current.onicegatheringstatechange = null
      refPeerConnections.current?.close()
      refPeerConnections.current = null
    }

    console.log('Done unload')
  }

  const setStream = useCallback((stream: MediaStream) => {
    refStream.current = stream
    setChangeStream(old => !old)
  }, [])

  const startLivestream = useCallback(async () => {
    if (
      refStream.current &&
      !refIsCallStartLive.current &&
      refStream.current?.getAudioTracks().length > 0
    ) {
      setConnectionStatus('connecting')
      refConnectionStatus.current = 'connecting'
      refIsCallStartLive.current = true

      try {
        console.log('start livestream ......')
        const config = {
          iceServers: [
            {
              urls: 'stun:stun.cloudflare.com:3478'
            }
          ]
        }

        refPeerConnections.current = new RTCPeerConnection(config)

        refStream.current.getTracks().forEach(track => {
          console.log(track, 'aljdfaf')
          const transceiver = refPeerConnections.current.addTransceiver(track, {
            /** WHIP is only for sending streaming media */
            direction: 'sendonly'
          })
          // if (track.kind == "video" && transceiver.sender.track) {
          //   transceiver.sender.track.applyConstraints({
          //     width: 1280,
          //     height: 720,
          //   });
          // }
        })

        refPeerConnections.current.addEventListener('connectionstatechange', async ev => {
          setConnectionStatus(refPeerConnections.current?.connectionState)
          refConnectionStatus.current = refPeerConnections.current?.connectionState
          console.log(refPeerConnections.current?.connectionState)
          if (
            refPeerConnections.current?.connectionState === 'connected' &&
            refLiveDetail.current?.livestream_status !== EnumStatusLivestream.Live
          ) {
            dispatch(
              updateEntity({
                _id: refLiveDetail.current?._id,
                livestream_status: EnumStatusLivestream.Live,
                ready_status: EnumReadyStatusLivestream.Connected
              })
            )
          } else {
            if (refPeerConnections.current?.connectionState === 'connected') {
              socket.emit(
                'emitOffer',
                JSON.stringify({
                  payload: { status: EnumCommunicateClient.StartStreamSuccess },
                  room_id: 'livestream_' + refLiveDetail.current?._id
                })
              )

              dispatch(
                updateEntity({
                  _id: refLiveDetail.current?._id,
                  ready_status: EnumReadyStatusLivestream.Connected
                })
              )
            }
          }

          if (refPeerConnections.current?.connectionState === 'disconnected') {
            refIsCallStartLive.current = false
            dispatch(
              updateEntity({
                _id: refLiveDetail.current?._id,
                ready_status: EnumReadyStatusLivestream.Disconnected
              })
            )
            await fetch(refLiveDetail.current?.whip_data, {
              method: 'DELETE',
              mode: 'cors'
            })

            if (refPeerConnections.current) {
              refPeerConnections.current.onconnectionstatechange = null
              refPeerConnections.current.onicegatheringstatechange = null
              refPeerConnections.current?.close()
              refPeerConnections.current = null
            }

            startLivestream()
          }
        })

        const offer = await refPeerConnections.current.createOffer()
        /** https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/setLocalDescription */
        await refPeerConnections.current.setLocalDescription(offer)
        /** Wait for ICE gathering to complete */
        let ofr: any = await waitToCompleteICEGathering(refPeerConnections.current)
        if (!ofr) {
          throw Error('failed to gather ICE candidates for offer')
        }

        while (refPeerConnections.current.connectionState !== 'closed') {
          /**
           * This response contains the server's SDP offer.
           * This specifies how the client should communicate,
           * and what kind of media client and server have negotiated to exchange.
           */
          const response = await postSDPOffer(refLiveDetail.current?.whip_data, ofr.sdp)

          if (response.status === 201) {
            let answerSDP = await response.text()
            await refPeerConnections.current.setRemoteDescription(
              new RTCSessionDescription({ type: 'answer', sdp: answerSDP })
            )
            return response.headers.get('Location')
          } else if (response.status === 405) {
            console.error('Update the URL passed into the WHIP or WHEP client')
          } else {
            const errorMessage = await response.text()
            console.error(errorMessage)
          }
          /** Limit reconnection attempts to at-most once every 5 seconds */
          await new Promise(r => setTimeout(r, 5000))
        }
      } catch (error) {
        console.log(error)

        toast.show({
          type: EnumTypeToast.Error,
          content: 'Bắt đầu phiên live thất bại, thử lại sau.'
        })

        refIsCallStartLive.current = false
      }
    }
  }, [liveDetail])

  const replaceAudioTrackInStream = useCallback(
    async (
      newAudioTrack: MediaStreamTrack,
      type: EnumTypeInputSound,
      updateChangeStream: boolean = false
    ) => {
      let audioTrackWithGainNode = newAudioTrack
        ? linkSoundToGainNode(newAudioTrack, type)
        : undefined

      if (refStream.current?.getAudioTracks()?.[0] && audioTrackWithGainNode) {
        console.log(refStream.current?.getAudioTracks()?.[0], 'old.getAudioTracks()?.[0]')
        refStream.current?.removeTrack(refStream.current?.getAudioTracks()?.[0])
        refStream.current?.addTrack(audioTrackWithGainNode)
      }

      if (refStream.current?.getAudioTracks().length === 0 && audioTrackWithGainNode) {
        refStream.current?.addTrack(audioTrackWithGainNode)
      }

      if (
        refConnectionStatus.current === 'connected' ||
        refConnectionStatus.current === 'connecting'
      ) {
        for (let sender of refPeerConnections.current?.getSenders()) {
          console.log(sender, 'sender')
          if (sender.track?.kind == 'audio' && audioTrackWithGainNode) {
            await sender.replaceTrack(audioTrackWithGainNode)
          }
        }
      }

      if (updateChangeStream) {
        setChangeStream(old => !old)
      }
    },
    []
  )

  const linkSoundToGainNode = useCallback(
    (audioTrack: MediaStreamTrack, type: EnumTypeInputSound): MediaStreamTrack => {
      if (AudioContext) {
        console.log(audioTrack, 'audioTrack')
        console.log(type, 'type')
        const newMediaStream = new MediaStream([audioTrack])

        let audioCtx = new AudioContext()

        const audioSourceNode = audioCtx.createMediaStreamSource(newMediaStream)
        let destination = audioCtx.createMediaStreamDestination()
        if (type === EnumTypeInputSound.Sound) {
          refSoundTrack.current = audioTrack
          refGainNodeSound.current = audioCtx.createGain()
          refGainNodeSound.current.gain.value = refDefaultSoundVolume.current
          audioSourceNode.connect(refGainNodeSound.current)
          refGainNodeSound.current?.connect(destination)
          if (refGainNodeMicro.current) {
            const newExtraMediaStream = new MediaStream([refMicroTrack.current])
            const audioExtraNode = audioCtx.createMediaStreamSource(newExtraMediaStream)
            refGainNodeMicro.current = audioCtx.createGain()
            refGainNodeMicro.current.gain.value = refDefaultMicroVolume.current
            audioExtraNode.connect(refGainNodeMicro.current)
            refGainNodeMicro.current?.connect(destination)
          }
        } else {
          refMicroTrack.current = audioTrack
          refGainNodeMicro.current = audioCtx.createGain()
          refGainNodeMicro.current.gain.value = refDefaultMicroVolume.current
          audioSourceNode.connect(refGainNodeMicro.current)
          refGainNodeMicro.current?.connect(destination)
          if (refGainNodeSound.current) {
            const newExtraMediaStream = new MediaStream([refSoundTrack.current])
            const audioExtraNode = audioCtx.createMediaStreamSource(newExtraMediaStream)
            refGainNodeSound.current = audioCtx.createGain()
            refGainNodeSound.current.gain.value = refDefaultSoundVolume.current
            audioExtraNode.connect(refGainNodeSound.current)
            refGainNodeSound.current?.connect(destination)
          }
        }

        return destination.stream.getAudioTracks()[0]
      }

      return audioTrack
    },
    []
  )

  const onChangeVolume = useCallback((value: number, type: EnumTypeInputSound) => {
    if (type === EnumTypeInputSound.Mic && refGainNodeMicro.current?.gain?.value !== undefined) {
      console.log(value / 100, 'micro')
      refGainNodeMicro.current.gain.value = value / 100
      refDefaultMicroVolume.current = value / 100
    }

    if (type === EnumTypeInputSound.Sound && refGainNodeSound.current?.gain?.value !== undefined) {
      console.log(value / 100, 'sound')
      refGainNodeSound.current.gain.value = value / 100
      refDefaultSoundVolume.current = value / 100
    }
  }, [])

  const updateMaxResolution = useCallback((value: { width: number; height: number }) => {
    refMaxResolution.current = value
  }, [])

  return {
    updateMaxResolution,
    setStream,
    onChangeVolume,
    startLivestream,
    replaceAudioTrackInStream,
    endLiveStream,
    connectionStatus
  }
}
