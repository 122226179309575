import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Rating,
  Stack,
  Typography
} from '@mui/material'
import ProductDetailContainer from './ProductDetailContainer'
import ProductFeedback from 'entities/shop/ViewProduct/ProductDetail/ProductFeedback'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { TypedReviewOrder } from 'entities/shop/interface'
import { getListReviewOfProduct } from 'entities/shop/service/product.service'
import { useParams } from 'react-router-dom'
import { User } from 'entities/challenge/challenge.interface'
import { formatString } from 'entities/support/utils'
import __ from 'languages/index'
import helpers from 'helpers'
import { IRootState, useAppDispatch } from 'config/store'
import { useSelector } from 'react-redux'
import { getSummarize } from 'entities/shop/store'

interface TypedFeedback extends TypedReviewOrder {
  user: Omit<User, '_id'> & {
    user_id: string
  }
}

const enumFilterFeedback = {
  "ALL": '',
  "MEDIA": 'media',
  "COMMENT": 'comment',
}

interface TypedProps {
  clickViewImages: (index: number, images: string[]) => void
}

const RatingSpace = ({clickViewImages}: TypedProps) => {
  const { productId } = useParams()
  const dispatch = useAppDispatch();

  const [filterReview, setFilterReview] = useState({
    review_media: '',
    review_point: ''
  })
  const summarize = useSelector((state: IRootState) => state.shop.summarize);

  useEffect(()=>{
    dispatch(getSummarize(productId))
  },[])


  const { data, hasMore, isRefreshing, loadMore,setParamRequest,paramRequest, refresh } =
  useInfiniteScroll<TypedFeedback>(
    {
      product_id: productId,
      review_meta_status: 1,
      keyAttribute: 'review_id',
    },
    getListReviewOfProduct,
    true,
    )

    useEffect(()=>{
      const params = { 
        ...paramRequest, 
        page: 1,
        ["review_meta.review_media"]: filterReview?.review_media === enumFilterFeedback.MEDIA ? '!null' : '',
        ["review_meta.review_title"]: filterReview?.review_media === enumFilterFeedback.COMMENT ? '!null' : '',
        ["review_meta.review_point"]: filterReview?.review_point,
       }
       helpers.clearValueEmptyInObject(params)
      setParamRequest({...params})
    },[filterReview])


  return (
    <ProductDetailContainer label="Đánh giá từ khách hàng đã mua">
      <Stack direction="row" justifyContent={'flex-start'} gap={3} mb={5}>
        <Stack
          justifyContent={'space-between'}
          sx={{
            maxWidth: theme => theme.spacing(30),
            width: '100%'
          }}
        >
          <Box>
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography variant="h5" component="p">
                {summarize?.product_review_point ? summarize?.product_review_point.toFixed(1) : 0}
              </Typography>
              <Rating name="read-only" value={Number((summarize?.product_review_point || 0).toFixed(1))} precision={0.1} readOnly />
            </Stack>
            <Typography display={'block'}>{summarize?.product_review_count ? formatString(__('review_count'), (summarize?.product_review_count)) : __('no_review')}</Typography>
          </Box>
        </Stack>
        <Divider orientation="vertical" variant="fullWidth" flexItem />

        <Stack direction="row" gap={3} alignItems={'flex-start'}>
          <Stack direction={'column-reverse'} justifyContent={'space-between'} gap={2}>
            {Object.keys(summarize?.review_sumary)?.map((_label, index) => (
              <Stack direction="row" key={index}>
                <Rating value={index + 1} readOnly />
                 <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={(summarize?.review_sumary[_label] /summarize?.product_review_count ) * 100} 
                      color="warning"
                      sx={{
                        width: theme => theme.spacing(20)
                      }}
                      />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`(${summarize?.review_sumary[_label] || 0})`}</Typography>
                  </Box>
                </Box>
              </Stack>
            ))}
          </Stack>
          <Box>
            <Typography>Lọc đánh giá</Typography>
            <FormGroup>
              <Stack direction="row" flexWrap={'wrap'}>
                <FormControlLabel control={<Checkbox checked={filterReview?.review_media === enumFilterFeedback.ALL} onChange={()=>setFilterReview({...filterReview, review_media: enumFilterFeedback.ALL})} />} label="Tất cả" />
                <FormControlLabel control={<Checkbox checked={filterReview?.review_media === enumFilterFeedback.MEDIA} onChange={()=>setFilterReview({...filterReview, review_media: enumFilterFeedback.MEDIA})} />} label="Có hình ảnh" />
                <FormControlLabel control={<Checkbox checked={filterReview?.review_media === enumFilterFeedback.COMMENT} onChange={()=>setFilterReview({...filterReview, review_media: enumFilterFeedback.COMMENT})} />} label="Có bình luận" />
              </Stack>
              <Stack direction="row" flexWrap={'wrap'}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <FormControlLabel
                    control={<Checkbox 
                                checked={Number(filterReview?.review_point) === index + 1} 
                                onChange={()=>setFilterReview({...filterReview, review_point: `${index + 1}`})}  
                                />}
                    label={<Typography>{`${index + 1} sao`}</Typography>}
                  />
                ))}
              </Stack>
            </FormGroup>
          </Box>
        </Stack>
      </Stack>

      <InfiniteScrollContainer
        isRefreshing={isRefreshing}
        dataLength={data.length}
        next={loadMore}
        hasMore={hasMore}
        refreshFunction={refresh}
        showEndMessage={false}
        pullDownToRefresh={false}
      >
        {
          data.map((value, idx)=>(
            <Stack key={idx} rowGap={theme=>theme.spacing(4.5)}>
              {idx > 0 && <Divider sx={{opacity: 1, pt: theme=>theme.spacing(5)}} />}
              <ProductFeedback clickViewImages={clickViewImages}  {...value} />
            </Stack>
          ))
        }
      </InfiniteScrollContainer>
    </ProductDetailContainer>
  )
}

export default RatingSpace
