import __ from 'languages/index'

export const VALIDATE = {
  required: __('support_validate_required'),
  maxLength: __('support_validate_max_length'),
  numeric: __('validate_numeric'),
  pattern: __('validate_pattern')
}

export const TXT_ERROR = `${__('support_txt_error')}`
