//Library
import __ from 'languages/index'
import debounce from 'lodash.debounce'
import { useNavigate, useParams } from 'react-router-dom'
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { AxiosError, AxiosResponse } from 'axios'
import { socket } from 'config/socket.io'

//Components
import _Helmet from 'components/helmet'
import TextField from 'components/TextField'
// import ChatIframe from './chat.iframe'
import { useInfinite } from 'hooks/useInfinite'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import ItemInbox from './components/chat.inbox.item'
import ChatInformation from './components/chat.information'

//Store
import { getListInboxChatRoom, viewMessageOfClient } from './store/chat.store.reducer'
import { useAppDispatch, useAppSelector } from 'config/store'

//Image
import imageDefault from 'media/images/image_default.png'

//Icons
import iconSearch from './media/icons/iconSearch.svg'
import emptyInbox from './media/icons/iconEmptyInbox.svg'
import iconInformation from './media/icons/iconInformation.svg'
import iconDotMenu from './media/icons/iconDotMenu.svg'

//Interface
import { TypedChatRoom } from './chat.interface'

//Helpers
import helpers from 'helpers'

//Scss
import './media/chat.detail.scss'
import useWindowSize from 'hooks/useWindownSize'

const ChatIframe = lazy(() => import('./chat.iframe'))

interface TypedParams {
  page: number
  search: string
  limit: number
  read_count: string
}

const ChatDetail = () => {
  const dispatch = useAppDispatch()
  const { id_chat } = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [textSearch, setTextSearch] = useState<string>('')
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [isShowChatInformation, setIsShowChatInformation] = useState<boolean>(false)
  const [clientInformation, setClientInformation] = useState<TypedChatRoom>(null)
  const [listDataInbox, setListDataInbox] = useState<TypedChatRoom[]>([])
  const [totalListDataInbox, setTotalListDataInbox] = useState<number>(0)
  const [params, setParams] = useState<TypedParams>({
    page: 1,
    search: '',
    limit: 20,
    read_count: ''
  })

  const { ref } = useInfinite(listDataInbox?.length < totalListDataInbox, () => {
    setParams({
      ...params,
      page: params.page + 1
    })
  })

  const reduceRequestSearch = useMemo(() => {
    return debounce(_value => {
      setListDataInbox([])
      setParams(params => ({
        ...params,
        page: 1,
        search: _value
      }))
    }, 500)
  }, [textSearch, params])

  const handleChangeValue = useCallback((value: string) => {
    setTextSearch(value)
    reduceRequestSearch(value)
  }, [])

  const handleChangeTab = useCallback(
    (index: number) => {
      setTabIndex(index)
      let statusMessage = ''
      switch (index) {
        case 0:
          statusMessage = ''
          break
        case 1:
          statusMessage = 'read'
          break
        case 2:
          statusMessage = 'unread'
          break

        default:
          break
      }
      setParams({
        ...params,
        page: 1,
        read_count: statusMessage
      })
      setListDataInbox([])
      setTotalListDataInbox(0)
    },
    [params]
  )

  const handleShowChatInformation = useCallback(() => {
    setIsShowChatInformation(!isShowChatInformation)
  }, [isShowChatInformation])

  const handleViewMessage = useCallback(
    (idChatRoom: string) => {
      navigate(`/chat/detail/${idChatRoom}`)
      dispatch(viewMessageOfClient(idChatRoom))
        .unwrap()
        .then((res: AxiosResponse) => {
          const newMessageData = [...listDataInbox]
          const index = listDataInbox.findIndex(
            (item: TypedChatRoom) => res?.data?._id === item?._id
          )
          newMessageData.splice(index, 1, {
            ...listDataInbox[index],
            read_count: res?.data?.read_count || 0
          })
          setListDataInbox([...newMessageData])
        })
        .catch((error: AxiosError) => {
          console.log(`viewMessageOfClient_${error}`)
        })
    },
    [isShowChatInformation, listDataInbox]
  )

  const handleGetListInbox = useCallback(
    (params: TypedParams) => {
      dispatch(getListInboxChatRoom(params))
        .unwrap()
        .then((res: AxiosResponse) => {
          if (helpers.isEmpty(listDataInbox)) return setListDataInbox([...res?.data])
          listDataInbox?.forEach((data: TypedChatRoom) => {
            res?.data?.forEach((item: TypedChatRoom) => {
              if (item?._id === data?._id) {
                const newMessageData = [...listDataInbox]
                const index = listDataInbox.findIndex(
                  (item: TypedChatRoom) => data?._id === item?._id
                )
                newMessageData.splice(index, 1, {
                  ...item
                })
                setListDataInbox([...newMessageData])
              } else {
                setListDataInbox(listDataInbox => [...listDataInbox, item])
              }
            })
          })
          setTotalListDataInbox(parseInt(res?.headers['x-total-count'] || 0, 10))
        })
        .catch((error: AxiosError) => {
          console.log(`getListInboxChatRoom_${error}`)
          setListDataInbox([])
          setTotalListDataInbox(0)
          toast.show({
            content: `${__('list_inbox_chat_room_failed')}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [params]
  )

  useEffect(() => {
    handleGetListInbox(params)
  }, [params])

  useEffect(() => {
    try {
      socket.on('msgToClient', () => {
        handleGetListInbox({
          ...params,
          page: 1
        })
      })
    } catch (error) {
      console.log(`msgToClient_${error}`)
    }
  }, [socket])

  useEffect(() => {
    try {
      socket.on('msgToUser', () => {
        handleGetListInbox({ ...params, page: 1 })
      })
    } catch (error) {
      console.log(`msgToClient_${error}`)
    }
  }, [socket])

  return (
    <>
      <_Helmet title="Nhắn tin" />
      <ChatDetailContainer id="chatDetail" className="chatDetail_container">
        <div className="chatDetail_header">
          <div
            role="button"
            className="chatDetail_header_logo_wrapper"
            onClick={() => navigate('/', { replace: true })}
          >
            <img
              src={channel_data?.avatar?.media_thumbnail || imageDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = imageDefault
              }}
              className="chatDetail_header_logo"
              alt="logo"
            />
            <Typography component={'h4'} variant="subtitle1" className="fw-bold">
              {channel_data?.name || ''}
            </Typography>
          </div>
        </div>
        <div className="chatDetail_body">
          <div className="chatDetail_list_message_wrapper">
            <div className="chatDetail_list_message_header">
              <Typography component={'h5'} variant="h5">
                Tin nhắn
              </Typography>
              <div className="position-relative">
                <TextField
                  value={textSearch}
                  onChange={value => handleChangeValue(value)}
                  placeholder={__('chat_search_in_messages')}
                  className="chatDetail_list_message_input"
                />
                <img
                  src={iconSearch}
                  className="chatDetail_list_message_search"
                  alt="icon_search"
                  loading="lazy"
                />
              </div>
              <div className="chatDetail_list_message_button">
                <Typography
                  role="button"
                  onClick={() => handleChangeTab(0)}
                  component={'button'}
                  className={`${tabIndex === 0 && 'chatDetail_active'}`}
                >
                  Tất cả
                </Typography>
                <Typography
                  role={'button'}
                  onClick={() => handleChangeTab(1)}
                  component={'button'}
                  className={`${tabIndex === 1 && 'chatDetail_active'}`}
                >
                  Đã đọc
                </Typography>
                <Typography
                  role={'button'}
                  onClick={() => handleChangeTab(2)}
                  component={'button'}
                  className={`${tabIndex === 2 && 'chatDetail_active'}`}
                >
                  Chưa đọc
                </Typography>
              </div>
            </div>
            {helpers.isEmpty(listDataInbox) ? (
              <div className="chatDetail_list_message_empty">
                <img src={emptyInbox} alt="icons_empty" />
                <Typography component={'span'}>{__('Bạn chưa có tin nhắn nào')}</Typography>
              </div>
            ) : (
              <div className="chatDetail_list_message_content">
                {listDataInbox.map((item: TypedChatRoom, idx: number) => (
                  <div
                    ref={ref}
                    key={`itemInbox_${idx}`}
                    onClick={() => handleViewMessage(item?.chat_room_id?._id)}
                  >
                    <ItemInbox dataChatRoom={item} />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="chatDetail_content_message_wrapper position-relative">
            <Suspense fallback={<></>}>
              <ChatIframe idChat={id_chat} setClientInformation={setClientInformation} />
            </Suspense>
            <div className="chatDetail_content_message_button">
              <div onClick={handleShowChatInformation} role="button" className="chatDetail_content_message_button_item">
                <img src={iconDotMenu} alt="icon_dot_menu" />
              </div>
            </div>
          </div>
          {isShowChatInformation && (
            <div className="chatDetail_information_wrapper">
              <ChatInformation
                handleShowChatInformation={handleShowChatInformation}
                dataClient={clientInformation}
              />
            </div>
          )}
        </div>
      </ChatDetailContainer>
    </>
  )
}

export default ChatDetail

const ChatDetailContainer = styled(Box)(({ theme }) => ({
  '& .chatDetail_active': {
    background: `${theme.palette.primary.backgroundHover} !important`, 
    color: `${theme.palette.primary.main} !important`
  },
  
}))