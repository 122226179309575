import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import __ from 'languages/index'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { SearchIcon } from '../../../icons'
import FilterIcon from '../../../icons/FilterIcon'
import { fiterByCreatedAt } from 'entities/shop/data'
import { getStyles } from 'entities/shop/helper'
import DownloadIcon from '../../../icons/DownloadIcon'
import PlusIcon from '../../../icons/PlusIcon'
import { useNavigate } from 'react-router-dom'
import { IQueryParams } from 'config/reducer.utils'
import debounce from 'lodash.debounce'

interface TypedProps {
  totalItem: number;
  handleFilterByField: (type, value) => void;
  paramsRequest: IQueryParams,

}
const ManageProductHeader = ({ totalItem, handleFilterByField, paramsRequest }: TypedProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectSortCreatedAt, setSelectSortCreatedAt] = useState(fiterByCreatedAt[0].id)
  useEffect(() => {
    const sortSplit = [];

    // Lặp qua từng phần tử
    sortSplit.forEach((part) => {
      const subParts = part.split(':');
      const key = subParts[0];

      if (key === 'createdAt') {
        setSelectSortCreatedAt(`createdAt:${subParts[1]}`)
      }
    });
  }, [])

  const handleChangeStatus = (e) => {
    let sortSplit = [];
     // sortSplit =sortSplit.filter(item => !item.includes('createdAt'))
    sortSplit.push(e.target.value)
    setSelectSortCreatedAt(e.target.value)
    handleFilterByField('sort', sortSplit.join(','))
  }

  const handleValueSearch = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      handleFilterByField('query', e.target.value)

    }, 500)
  }, [paramsRequest])

  return (
    <ManageProductHeaderContainer>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          className="manage_product_header_container"
        >
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Box sx={{ maxWidth: theme => theme.spacing(60) }}>
              <OutlinedInput
                size="small"
                type="search"
                id="manage_product_search"
                className="manage_product_search"
                placeholder={__('manage_product')}
                autoFocus
                onChange={handleValueSearch}
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      sx={{ padding: 0, width: theme => theme.spacing(2.375) }}
                    >
                      <SearchIcon
                        sx={{
                          width: '100%',
                          height: '100%',
                          marginRight: theme => theme.spacing(1)
                        }}
                        color="inherit"
                      />
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby="outlined-helper-text"
                sx={{ borderRadius: theme => theme.spacing(1.5) }}
              />
            </Box>

            <Typography variant="body1" color={'primary.main'}>
              {totalItem} sản phẩm
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <FormControl sx={{ m: 1, mt: 3 }}>
              <Select
                size="small"
                displayEmpty
                variant="filled"
                className='select_sort'
                value={selectSortCreatedAt}
                onChange={handleChangeStatus}
                IconComponent={() => (
                  <IconButton>
                    <FilterIcon sx={{ width: '100%', height: '100%' }} />{' '}
                  </IconButton>
                )}
                input={<OutlinedInput />}
                MenuProps={{
                  elevation:1,
                }}
                sx={{
                  padding: theme => theme.spacing(0, 2),
                  backgroundColor: theme => theme.palette.background.default,
                  borderRadius: theme => theme.spacing(1.5)
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {fiterByCreatedAt?.map(item => (
                  <MenuItem
                    key={item?.id}
                    value={item?.id}
                    style={getStyles(item?.value, item.id, theme)}
                  >
                    {item?.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/*<Button variant="outlined" className="manage_product_btn_down" size={'small'}>*/}
            {/*  <IconButton sx={{ p: theme => theme.spacing(0, 1, 0, 0) }}>*/}
            {/*    <DownloadIcon color={'inherit'} sx={{ width: '100%', height: '100%' }} />*/}
            {/*  </IconButton>*/}
            {/*  <Typography variant="body1" color={'inherit'} textTransform="none">*/}
            {/*    {__('manage_product_btn_down')}*/}
            {/*  </Typography>*/}
            {/*</Button>*/}

            <Button
              onClick={() => navigate('add')}
              size={'small'}
              sx={{ padding: theme => theme.spacing(1, 1) }}
            >
              <IconButton sx={{ p: theme => theme.spacing(0, 1, 0, 0), color:'inherit' }}>
                <PlusIcon sx={{ width: '100%', height: '100%', color: 'inherit'}} />
              </IconButton>
              <Typography variant="body1" textTransform="none">
                {__('manage_product_btn_add')}
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ManageProductHeaderContainer>
  )
}
export default ManageProductHeader
const ManageProductHeaderContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, 0),
  borderRadius: theme.spacing(1.25),
  background: theme.palette.background.paper,
  boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
  padding: theme.spacing(2.5),
  '& .MuiFormControl-root': {
    width: 'min-content'
  },
  '& .MuiInputBase-root .MuiOutlinedInput-root': {
    flexDirection: 'row-reverse'
  },
  '& .manage_product_header_container': {
    display: 'grid',
    gridTemplateColumns: 'auto auto'
  },
  '& .manage_product_search': {
    borderColor: theme.palette.text.secondary,
    display: 'flex'
  },
  '& .manage_product_btn_down': {
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
    borderColor: theme.palette.text.secondary,
    padding: theme.spacing(1, 1)
  },
  '& .manage_product_btn_down:hover,& .manage_product_btn_down:focus': {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },

  '& .select_sort': {
    '& .MuiInputBase-input': {
      textOverflow: 'clip !important'
    }
  }
}))
