import React from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { GroupIcon, SettingIcon } from '../../../../icons'

type Props = {
  channelName?: string
  slogan?: string
}
const OverviewTemplate = ({ channelName, slogan }: Props) => {
  return (
    <Paper
      elevation={6}
      sx={{
        padding: theme => theme.spacing(2)
      }}
    >
      <Stack gap={2}>
        <Stack>
          <Box component={'img'} src={''}></Box>
          <Box>
            <Typography variant={'subtitle1'} fontWeight={600}>
              {channelName}
            </Typography>
            <Typography>{slogan}</Typography>
          </Box>
        </Stack>
        <Stack direction={'row'} gap={1}>
          <Stack
            direction={'row'}
            sx={{
              width: '100%',
              border: theme => `1px solid ${theme.palette.divider}`,
              borderRadius: theme => theme.spacing(1.5),
              justifyContent: 'center',
              alignItems: 'center'
            }}
            gap={2}
          >
            <GroupIcon />
            <Typography>10000</Typography>
          </Stack>
          <Box
            sx={{
              padding: theme => theme.spacing(1.75),
              border: theme => `1px solid ${theme.palette.divider}`,
              borderRadius: theme => theme.spacing(1.5)
            }}
          >
            <SettingIcon />
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default OverviewTemplate
