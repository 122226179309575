import React from 'react'
import { Typography } from '@mui/material'

const BaseTypography = () => {
  return (
    <div>
      <Typography component="h1" variant="h1" fontWeight={'bold'}>
        Heading 1
      </Typography>
      <Typography component="h2" variant="h2" fontWeight={'medium'}>
        Heading 2
      </Typography>
      <Typography component="h3" variant="h3">
        Heading 3
      </Typography>
      <Typography component="h4" variant="h4">
        Heading 4
      </Typography>
      <Typography component="h5" variant="h5">
        Heading 5
      </Typography>
      <Typography component="h6" variant="h6">
        Heading 6
      </Typography>

      <Typography component="p" variant="body1" fontWeight={600}>
        Body 1
      </Typography>

      <Typography component="p" variant="body2">
        Body 2
      </Typography>
      <Typography component="p" variant="caption">
        Caption
      </Typography>
      <Typography component="p" variant="subtitle1">
        Subtitle 1
      </Typography>
      <Typography component="p" variant="subtitle2">
        Subtitle 2
      </Typography>
      <Typography component="p" variant="overline">
        Overline
      </Typography>
      <Typography component="p" variant="inherit">
        Inherit
      </Typography>
    </div>
  )
}

export default BaseTypography
