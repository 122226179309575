import React from 'react'
import TypedUser from '../../../interfaces/user.interface'
import {PostStatus} from 'entities/support/ticket.type'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleDot} from '@fortawesome/free-solid-svg-icons'
import DateTime from 'date-and-time'
import NavigateUser from 'entities/challenge/component/NavigateUser'
import __ from "languages/index";

type Props = {
  title: string
  createdAt: string
  fromUser: Pick<TypedUser, '_id' | 'display_name'>
  status: PostStatus
}

export const formatDate = (time: string, format: string = 'HH:mm:ss DD/MM/YYYY') => {
  return DateTime.format(new Date(time), format)
}

const TicketItem = ({ title, createdAt, fromUser }: Props) => {
  return (
    <div className="d-flex align-items-start">
      <FontAwesomeIcon icon={faCircleDot} className="text-success me-2 mt-1" />
      <div>
        <p className="fw-semibold text-black m-0 ">{title}</p>
        <p
          className=" m-0"
          style={{
            fontSize: '12px'
          }}
        >
          {`${__("support_ticket_create_time")} ${formatDate(createdAt, 'HH:mm DD/MM/YYYY')} ${__("support_ticket_create_by")}`}{' '}
          <NavigateUser _id={fromUser?._id}>{fromUser?.display_name}</NavigateUser>
        </p>
      </div>
    </div>
  )
}

export default TicketItem
