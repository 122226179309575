import React, { useCallback, useEffect, useState } from 'react'
import {Box, IconButton, Typography} from '@mui/material'
import __ from 'languages/index'
import {DataGrid, GridColDef,} from '@mui/x-data-grid'
import { TypedCustomer, TypedStock } from 'entities/shop/interface'
import {EditIcon, TrashAltIcon} from '../../../icons'
import {useNavigate} from 'react-router-dom'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import {useAppDispatch} from 'config/store'
import __helpers from 'helpers/index'
import {CustomTableContainer} from './ListStockTable'
import CustomPagination from 'entities/shop/components/CustomPagination'
import { deleteCustomer, deleteStock } from 'entities/shop/store/shop.store.reducer'
import { AxiosError } from 'axios'
import ConfirmModal from 'components/ConfirmModal'
import { IQueryParams } from 'config/reducer.utils'

interface TypeListStockTable{
  listCustomer: TypedCustomer[]
  setIsShowAddCustomer: React.Dispatch<React.SetStateAction<boolean>>
  setIsDelete?: React.Dispatch<React.SetStateAction<boolean>>
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
  setCustomer?:React.Dispatch<React.SetStateAction<TypedCustomer>>
  setParamRequest: React.Dispatch<React.SetStateAction<IQueryParams>>
  paramRequest: IQueryParams,
  totalCustomer: number
}

const ExternalCustomersTable = ({listCustomer,setIsShowAddCustomer, setCustomer,setIsDelete,setParamRequest,paramRequest,totalCustomer, setIsEdit}:TypeListStockTable) =>{
  const [addressId, setAddressId] = useState<string>('')
  const [selected, setSelected] = useState(1)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const navigate = useNavigate()

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: paramRequest.limit,
    page: 0,
  });
  const handleCloseConfirmModal = useCallback(() => {
    setAddressId('')
  }, [])

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setSelected(value)
  }

  useEffect(() => {
    setParamRequest(oldParam => ({ ...oldParam, page: selected }))
  }, [selected])

  const handleDeleteConfirmModal = useCallback(async () => {
    await dispatch(deleteCustomer(addressId))
      .unwrap()
      .then((res)=>{
        // refresh(true)
        setIsDelete(true)
        toast.show({
          content: `${__("delete_customers_success")}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((e: AxiosError)=>{
        console.log('deleteCustomer', e);
        toast.show({
          content: `${__("delete_customer_failed")}`,
          type: EnumTypeToast.Error
        })
      })
    handleCloseConfirmModal()
  },[addressId])

  const columns :GridColDef[] = [
    {
      field: "address_id",
      headerName: `${__("customer_id")}`,
      flex: 0.5,
      sortable: false,
      renderCell: (params) =>
        <Typography color={'text.primary'} variant={'subtitle2'}>
          {__helpers.getTrimContent(`${params?.row.address_id}`, 30)}
        </Typography>
    },

    {
      field: "customer_display_name",
      headerName: `${__("customer_full_name")}`,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align:'center',
      renderCell: (params) =>{
        return(
          <Typography color={'text.primary'} variant={'subtitle2'}>
            {__helpers.getTrimContent(`${params?.row.customer_display_name}`, 30)}
          </Typography>
        )}
    },
    {
      field: 'customer_phone',
      headerName: `${__("phone_number")}`,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align:'center',
      renderCell: (params) =>{
        return(
          <Typography color={'text.primary'} variant={'subtitle2'}>
            {__helpers.getTrimContent(`${params?.row.customer_phonenumber}`, 30)}
          </Typography>
        )
      }

    },
    {
      field: 'customer_referer',
      headerName: `${__("presenter")}`,
      minWidth: 100,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align:'center',
      renderCell: (params) =>{
        return(
          <Typography color={'text.primary'} variant={'subtitle2'}>
            {__helpers.getTrimContent(`${params?.row?.user.display_name}`, 30)}
          </Typography>
        )
      }

    },
    {
      field: 'action',
      headerName: `${__("label_action")}`,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align:'center',
      renderCell: (params) =>{
        const handleClickEdit = useCallback((item: TypedCustomer)=>{
          setIsShowAddCustomer(true)
          setCustomer(item)
          setIsEdit(true)
        },[listCustomer])

          const handleClickDelete = useCallback((item: TypedCustomer) => {
            setAddressId(item?.address_id)
          }, [])

        return(
        <Box display={'flex'} columnGap={theme => theme.spacing(2)}>
          {/*<IconButton*/}
          {/*  onClick={()=>handleClickDelete(params?.row)}*/}
          {/*  className="icon_button"*/}
          {/*>*/}
          {/*  <TrashAltIcon*/}
          {/*    sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}*/}
          {/*    color="inherit"*/}
          {/*  />*/}
          {/*</IconButton>*/}
          <IconButton
            onClick={()=>handleClickEdit(params?.row)}
            className="icon_button edit"
          >
            <EditIcon
              sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3) }}
              color="inherit"
            />
          </IconButton>
        </Box>
        )
      }

    },
  ]

  return(
    <CustomTableContainer>
      <Box>
        <DataGrid
          rowHeight={100}
          showCellVerticalBorder={false}
          rows={listCustomer}
          disableColumnMenu
          rowSpacingType={'margin'}
          columns={columns}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: paramRequest.limit,
              },
            },
          }}
          slots={{
            pagination: () => <CustomPagination handleChangePage={handleChangePage} page={paramRequest.page ?? selected} count={Math.ceil(totalCustomer / paginationModel.pageSize)} />,
          }}
          // onCellClick={(value)=>handleClickEdit(value)}
          onPaginationModelChange={setPaginationModel}
          paginationModel={paginationModel}
          getRowId={(row) => row.address_id}
          sx={{
            backgroundColor: theme => theme.palette.background.paper,
            borderRadius: theme => theme.spacing(1.5),
            boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
            '& .MuiDataGrid-cell': {
              height: '100px',
            },
          }}
        />
      </Box>

      {Boolean(!!addressId) && <ConfirmModal
        onClose={handleCloseConfirmModal}
        open={Boolean(!!addressId)}
        onConfirm={handleDeleteConfirmModal}
        title={__('delete_stock_title')}
        confirmTextBtn={__('btn_delete')}
        confirmText={__('delete_stockroom_confirm')}
      />}

      {/*{isShowModalNotification &&*/}
      {/*  <NotificationModal*/}
      {/*    handleCloseModalNotification={handleCloseModalNotification}*/}
      {/*    isShowModalNotification={isShowModalNotification}*/}
      {/*    textContent={`${__("modal_notification_content" )}`}*/}
      {/*  />*/}
      {/*}*/}
    </CustomTableContainer>
  )
}
export default ExternalCustomersTable

