//Library
import __ from 'languages/index'
import {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {Link, useNavigate} from 'react-router-dom'
import {AxiosError, AxiosResponse} from 'axios'

//Component
import _Helmet from 'components/helmet'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getListChannel} from './store/channel.store.reducer'
import {getDefaultChannel, logout, setChannelData} from 'store/user.store.reducer'

//Image
import logoDefault from 'media/images/logo.png'
import imageDefault from 'media/images/image_default.png'

//Interface
import {TypeChannelData} from './interface'

//Hook
import {useInfinite} from 'hooks/useInfinite'

//Helper
import helpers from 'helpers'

//Constant
import { GAMIFA_CHANNEL_HOST } from 'constant/index'

//Scss
import './media/channel.list.scss'

interface TypeParams {
  page: number
  limit: number
}

const ListChannel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const channel_data = useAppSelector(state => state.user.channel_data)
  const user_data = useAppSelector(state => state.user.user_data)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listChannel, setListChannel] = useState<TypeChannelData[]>([])
  const [totalListChannel, setTotalListChannel] = useState<number>(0)
  const [params, setParams] = useState<TypeParams>({
    page: 1,
    limit: 10
  })

  const isHostNameGamifa = useCallback(() => {
    let host = window.location?.hostname || ''
    let currentHost = 'https://' + host
    return Boolean(
      currentHost === 'https://gamifa.vn' ||
        currentHost === 'https://gamifa.com' ||
        currentHost === 'https://staging.gamifa.com'
    )
  }, [window.location])

  const redirectUrl = localStorage.getItem('redirectUrl') || ''

  const { ref } = useInfinite(listChannel?.length < totalListChannel, () => {
    setParams(params => ({ ...params, page: params.page + 1 }))
  })

  const handleSetVisitorIdFoZoho = useCallback((_id: string)=> {
    const script = document.createElement('script');
    try {
      script.setAttribute('type', 'text/javascript');
      let code = `var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || { 
              widgetcode: "${process.env.REACT_APP_ZOHO_WIDGETCODE}", 
              values:{},
              ready:function(){}
          };
          var d=document;
          s=d.createElement("script");
          s.type="text/javascript";
          s.id = "zsiqscript";
          s.defer = true;
          s.src = "${process.env.REACT_APP_ZOHO_URL}";
          t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
          if (!window.zohoReadyEvent){
              window.zohoReadyEvent = new Event('zohoReady');
          }
          $zoho.salesiq.ready = function() {
            $zoho.salesiq.floatwindow.visible("0");
            var newVisitorId = '${_id}'; 
            $zoho.salesiq.visitor.id(newVisitorId);
          };
      `
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
      (window as any).$zoho?.salesiq?.reset?.();
    } catch (error) {
      console.log(`Zoho_${error}`);
    }
    return () => {
      document.body.removeChild(script)
    }
  },[])

  const handleSelectChannel = useCallback(
    (data: TypeChannelData) => {
      const user_id = data?.user_id
      if (GAMIFA_CHANNEL_HOST.includes(window.location.host)) {
        handleSetVisitorIdFoZoho(user_data?._id)
      }
      if (data?.official_status) {
        dispatch( getDefaultChannel()  );
        dispatch(setChannelData(data))
        localStorage.removeItem('redirectUrl')
        if (redirectUrl) {
          return navigate(redirectUrl)
        }
        navigate('/')
      } else {
        if (user_data?._id === user_id) {
          navigate(`/channel/order/${data?._id}`)
          return
        } else {
          alert(`${__("channel_list_select_text")}`)
        }
      }
    },
    [redirectUrl, user_data]
  )

  useEffect(() => {
    params.page < 2 && setIsLoading(true)
    dispatch(getListChannel(params))
      .unwrap()
      .then((res: AxiosResponse) => {
        setIsLoading(false)
        setListChannel(listChannel => [...listChannel, ...res?.data])
        setTotalListChannel(parseInt(res?.headers['x-total-count'] || 0, 10))
      })
      .catch((error: AxiosError) => {
        console.log(`getListMember_${error}`)
        setIsLoading(false)
        setListChannel([])
        setTotalListChannel(0)
      })
  }, [params])

  useEffect(() => {
    if (isHostNameGamifa()) return
    navigate('/')
  }, [window.location])

  return (
    <>
      <_Helmet title='channel_join' />
      <div id="listChannel" className="listChannel_container">
        {isLoading ? (
          <div className="loader_wrapper">
            <span className="loader"></span>
          </div>
        ) : (
          <div className="listChannel_wrapper container-small">
            <div className="listChannel_logo_wrapper">
              <img
                src={channel_data?.avatar?.media_url || logoDefault}
                onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                  e.currentTarget.src = logoDefault
                }}
                width={40}
                height={40}
                className="listChannel_logo_image"
                alt="logo"
                loading="lazy"
              />
              <p className="listChannel_logo_title">{channel_data?.name || 'Gamifa'}</p>
            </div>
            <div className="listChannel_list_wrapper">
              <h2 className="listChannel_list_title">{__("channel_list_chose_channel")}</h2>
              <div className="listChannel_list">
                <div className="listChannel_list_item">
                  <Link to="create" role="button" className="listChannel_list_item_icon_wrapper">
                    <FontAwesomeIcon
                      size="2xl"
                      className="listChannel_list_item_icon"
                      icon={faPlus}
                    />
                  </Link>
                  <div className="d-flex justify-content-center">
                    <p className="listChannel_list_item_name">{__("channel_list_add_new_channel")}</p>
                  </div>
                </div>
                {!helpers.isEmpty(listChannel) && (
                  <>
                    {listChannel?.map((item: TypeChannelData, idx: number) => (
                      <div
                        ref={ref}
                        key={`channel_${idx}`}
                        role="button"
                        onClick={() => handleSelectChannel(item)}
                        className="listChannel_list_item_channel"
                      >
                        <div className="listChannel_list_item_image_wrapper">
                          <img
                            src={item?.avatar?.media_url || imageDefault}
                            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                              e.currentTarget.src = imageDefault
                            }}
                            className="listChannel_list_item_image"
                            alt="logo"
                            loading="lazy"
                          />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p className="listChannel_list_item_name">
                            {item?.name || ''}
                          </p>
                          <button className="listChannel_list_item_button text-black">
                            {__('user')}
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <a
              href="#"
                role="button"
                onClick={() => dispatch(logout())}
                className="text-primary text-decoration-underline"
              >
                {__("channel_list_text")}
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ListChannel
