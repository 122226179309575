import React from 'react'
import useTransferList from 'hooks/useTransferList'

type Props<T extends { _id: string }> = {
  resource: T[]
  destination?: T[]
  children: (props: ReturnType<typeof useTransferList<T>>) => React.ReactNode
}
const AddItemToList = <T extends { _id: string }>({
  resource,
  destination,
  children
}: Props<T>) => {
  const props = useTransferList(resource, destination)

  return <>{children(props)}</>
}

export default AddItemToList
