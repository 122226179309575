import { TypedIcon } from "entities/calendar/calendar.interface"
import * as React from "react"
import { SVGProps, memo } from "react"
const SvgComponent = (props: TypedIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g fill={props.color || "#374957"} clipPath="url(#a)">
      <path d="M11.452 14.019A8.19 8.19 0 0 0 4 22.284v.217a1.5 1.5 0 0 0 3 0v-.277a5.153 5.153 0 0 1 4.5-5.2A5.006 5.006 0 0 1 17 22v.5a1.5 1.5 0 0 0 3 0V22a8.009 8.009 0 0 0-8.548-7.982ZM12 12a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-9a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo
