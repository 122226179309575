import React from "react";
import '../media/courses.view.scss'
import '../media/courses.skeletons.scss'
import '../media/courses.view.modules.item.scss'
import BigLayout from "../../../layouts/bigLayout";


export default function CoursesViewSkeleton() {

  return (
    <BigLayout>
      <div className="ovf" id={"course-view"}>
        <div className="courseview_topcontainer">

          <div className="courseview_topcontainer_infoontainer">

            <div className="mainFrame">
              <div className="container">

                <div className="col-12 col-md-8 courseview_container">

                  <h1 className="courseview_topcontainer title_course skeleton skeleton-text mb-4 w-50"/>
                  <h1 className="courseview_topcontainer title_course skeleton skeleton-text"/>
                  <h1 className="courseview_topcontainer title_course skeleton skeleton-text w-75"/>
                  <div className="courseview_topcontainer des_course skeleton skeleton-text mt-5"/>
                  <div className="courseview_topcontainer des_course skeleton skeleton-text"/>
                  <div className="courseview_topcontainer des_course skeleton skeleton-text w-25"/>

                  <div className="d-flex justify-content-between">
                    <div className="align-items-center mt-4 w-50">
                      <div className="courseview_topcontainer createat_course skeleton skeleton-text w-50"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>


        <div className="mainFrame">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="courseview_viewmodule">
                  <div className="courseview_txtcontent skeleton skeleton-text-bottom w-25" style={{height: 24}}/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-25 mb-4"/>

                  <div className="courseview_txtcontent skeleton skeleton-text-bottom w-75" style={{height: 18}}/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-100 mb-4"/>

                  <div className="courseview_txtcontent skeleton skeleton-text-bottom w-75" style={{height: 18}}/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-100 mb-4"/>

                  <div className="courseview_txtcontent skeleton skeleton-text-bottom w-75" style={{height: 18}}/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-100 mb-4"/>
                </div>

                <div className="courseview_viewlongdescription w-100">
                  <div className="courseview_txtdetailcourse skeleton skeleton-text-bottom w-25 mb-3" style={{height: 24}}/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-100 mb-2"/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-100 mb-2"/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-100 mb-2"/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-100 mb-2"/>
                  <div className="courseview_txtnumofmodule skeleton skeleton-text w-50 mb-2"/>
                </div>


                <br/><br/><br/>
              </div>
              <div className="d-none d-lg-block col-lg-4">
                <div className="courseview_cardcontainer">
                  <div className="courseview_card shadow-lg">
                    <div className="courseview_card_media_container">
                      <div className="courseview_card_media skeleton skeleton-text"/>
                    </div>


                    <div className="courseview_card_info">

                      <button className="courseview_card_button shadow-sm skeleton skeleton-text"/>


                      <div className="courseview_card_authcontainer w-100">
                        <div className="courseview_card_txtauth skeleton skeleton-text w-25"/>

                        <div className="d-flex text-decoration-none w-100">
                          <div className="courseview_card_avatar skeleton skeleton-text"/>
                          <div className="ps-2 d-flex flex-column justify-content-evenly flex-grow-1">
                            <div className="courseview_card_txtteacher skeleton skeleton-text w-75"/>
                            <div
                              className="courseview_card_txtnameauth skeleton skeleton-text w-50"/>
                          </div>
                        </div>

                        <div className="courseview_card_txtdescription skeleton skeleton-text"/>
                      </div>
                    </div>


                  </div>

                </div>
                <br/><br/>

              </div>
            </div>
          </div>
        </div>

      </div>
    </BigLayout>
  )
}
