import __ from 'languages/index'

export const ssoConfigs = [
  {
    key: 'facebook_login_client_id',
    label: __('channel_setting_advance_facebook_js_url'),
    readonly: true
  },
  {
    key: 'facebook_login_client_id',
    label: __('channel_setting_advance_facebook_callback_url')
  },
  {
    key: 'facebook_login_client_id',
    label: __('channel_setting_advance_facebook_client_id')
  },
  {
    key: 'facebook_login_client_id',
    label: __('channel_setting_advance_google_origin_url')
  },
  {
    key: 'google_login_client_id',
    label: __('channel_setting_advance_google_client_id')
  }
]

export const smtpConfigs = [
  {
    key: 'smtp_server_address',
    label: __('channel_setting_smtp_server_address')
  },
  {
    key: 'smtp_server_port',
    label: __('channel_setting_smtp_server_port')
  },
  { key: 'smtp_server_username', label: __('channel_setting_smtp_server_username') },
  {
    key: 'smtp_server_password',
    label: __('channel_setting_smtp_server_password')
  },
  {
    key: 'smtp_server_secure_protocol',
    label: __('channel_setting_smtp_secure_protocol')
  }
]
