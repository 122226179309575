import { TypedEvent, TypedUser } from "entities/calendar/calendar.interface"

export interface TypedListLikeEvent {
  createdAt: string
  updatedAt: string
  event_id: TypedEvent
  user_id: TypedUser
}

export enum EnumLiveStreamSource{
  OutSide="outside",
  Camera="camera",
  File="file",
  Screen="screen",
}

export type TypedDeviceInfo = {
  deviceId?: string,
  label?: string,
  kind?: string,
  [propName: string]: any
}

export enum ErrorMediaType{
  Permission="Permission",
  NotFound="NotFound"
}
