import React from 'react'
import { Box, List, Stack, SxProps } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import SidebarItem from 'components/Sidebar/SidebarItem'
import { freeChannelConfigs } from 'constant/sidebar'
import { CurrentTheme } from '../../../../styles/themes'
import OverviewTemplate from 'entities/channel/components/template/Overview.template'
import QuickViewRanking from 'components/QuickViewRanking'
import { ranking } from 'entities/channel/components/template/mock-data'
import { RankingType } from '../../../../interfaces/common.interface'
import Header from 'entities/channel/components/template/Header'
import PostForm from 'components/PostForm'
import NewsFeedPost from 'entities/homeNew/components/NewsFeedPost'

const MOCK_POST = {
  _id: '659badd5643f2ec3df867813',
  user_id: {
    _id: '64d5de2faa2fed17584510d2',
    user_avatar:
      'https://media.whiteg.app/lgbtapp.s3.ap-southeast-1.amazonaws.com/2024/01/08_1704702973850/64d5de2faa2fed17584510d2-1704702973850-tong-hop-50-hinh-anh-thien-nhien-dep-hung-vi-tho-mong_3.jpg',
    user_avatar_thumbnail:
      'https://media.whiteg.app/lgbtapp.s3.ap-southeast-1.amazonaws.com/2023/10/27_1698381119009/64d5de2faa2fed17584510d2-1698381119009-IMG_0133.jpeg',
    display_name: 'Chàng trai ngu ngơ'
  },
  channel_id: '64d5decfaa2fed1758451616',
  ref_id: null,
  post_language: 'vi',
  poll_ids: [],
  post_title: 'I have a great picture',
  post_content: 'One for father one for my sister!',
  post_excerpt: '',
  post_slug: 'i-have-a-great-picture-1704701397684',
  country: 'VN',
  post_status: 'publish',
  data_json: '{}',
  data_json_type: '',
  post_avatar: null,
  attach_files: [
    {
      _id: '659bad9f643f2ec3df86759f',
      media_url: 'https://i.pinimg.com/564x/c7/57/b9/c757b95adf617a20ea8cb82a5c35967e.jpg',
      media_url_presign: '',
      media_type: 'image',
      media_thumbnail: 'https://i.pinimg.com/564x/c7/57/b9/c757b95adf617a20ea8cb82a5c35967e.jpg',
      media_content: '',
      media_square: '',
      media_mime_type: 'image/jpeg',
      media_file_name: 'JAM04842.jpg',
      chat_history_id: null,
      chat_room_id: null,
      media_status: 0,
      gender: 'male',
      function_type: '',
      sexual_content: 0,
      data_ai:
        '{"status":true,"is_male":true,"is_female":true,"data_return":"[[\\"Female\\", \\"99.38\\"], [\\"Male\\", \\"76.1\\"]]"}',
      media_meta: [
        {
          key: 'width',
          value: '1000',
          _id: '659bad9f643f2ec3df8675a0'
        },
        {
          key: 'height',
          value: '667',
          _id: '659bad9f643f2ec3df8675a1'
        },
        {
          key: 'size',
          value: '76181',
          _id: '659bad9f643f2ec3df8675a2'
        }
      ],
      createBy: '64d5de2faa2fed17584510d2',
      createdAt: '2024-01-08T08:09:03.167Z',
      updatedAt: '2024-01-08T08:09:03.618Z'
    },
    {
      _id: '659badcd643f2ec3df8677d6',
      media_url: 'https://i.pinimg.com/564x/c7/57/b9/c757b95adf617a20ea8cb82a5c35967e.jpg',
      media_url_presign: '',
      media_type: 'image',
      media_thumbnail: 'https://i.pinimg.com/564x/c7/57/b9/c757b95adf617a20ea8cb82a5c35967e.jpg',
      media_content: '',
      media_square: '',
      media_mime_type: 'image/jpeg',
      media_file_name: 'z4776787450958_0cd9b1b18cf6033dd4989118efad027d.jpg',
      chat_history_id: null,
      chat_room_id: null,
      media_status: 0,
      gender: 'unknown',
      function_type: '',
      sexual_content: 0,
      data_ai: '{"status":false,"is_male":false,"is_female":false,"data_return":"[]"}',
      media_meta: [
        {
          key: 'width',
          value: '2048',
          _id: '659badcd643f2ec3df8677d7'
        },
        {
          key: 'height',
          value: '1536',
          _id: '659badcd643f2ec3df8677d8'
        },
        {
          key: 'size',
          value: '335054',
          _id: '659badcd643f2ec3df8677d9'
        }
      ],
      createBy: '64d5de2faa2fed17584510d2',
      createdAt: '2024-01-08T08:09:49.600Z',
      updatedAt: '2024-01-08T08:09:49.996Z'
    }
  ],
  post_type: '',
  view_number: 0,
  like_number: 2,
  share_number: 0,
  is_pin: 0,
  is_comment: 1,
  dislike_number: 0,
  comment_number: 1,
  vote_number: 0,
  trending_number: 13,
  popular_number: 13,
  points: 0,
  createdAt: '2024-01-08T08:09:57.689Z',
  updatedAt: '2024-01-08T08:45:03.459Z',
  channelpermission: {
    point: 1012,
    permission: ['admin'],
    level_number: 5
  },
  is_like: false
} as any

type ContainerLayoutProps = {
  children: React.ReactNode
  sx: SxProps<CurrentTheme>
  channelName?: string
  domain?: string
}
const ContainerLayout = ({ children, sx, channelName, domain }: ContainerLayoutProps) => {
  return (
    <Box sx={sx}>
      <Header channelName={channelName} domain={domain} />
      <Grid container spacing={3} columns={15}>
        <Grid xs={4}>
          <List>
            {freeChannelConfigs
              .map(item => ({
                ...item,
                icon: <item.icon />
              }))
              .map((config, index) => (
                <SidebarItem key={index} {...config} />
              ))}
          </List>
        </Grid>
        <Grid xs={11}>{children}</Grid>
      </Grid>
    </Box>
  )
}
type ContentLayoutProps = {
  children: React.ReactNode
  sidebarSlots: React.ReactNode[]
}

const ContentLayout = ({ children, sidebarSlots }: ContentLayoutProps) => {
  return (
    <Grid container spacing={3}>
      <Grid xs={7}>{children}</Grid>
      <Grid xs={5}>
        <Stack gap={1.25}>
          {sidebarSlots.map((slot, index) => (
            <React.Fragment key={index}>{slot}</React.Fragment>
          ))}
        </Stack>
      </Grid>
    </Grid>
  )
}

type HomeTemplateProps = {
  sxLayout: SxProps<CurrentTheme>
  channelName: string
  slogan: string
  domain?: string
}
const HomeTemplate = ({ channelName, sxLayout, slogan, domain }: HomeTemplateProps) => {
  const sidebarSlot = [
    <OverviewTemplate channelName={channelName} slogan={slogan} />,
    <QuickViewRanking rankingList={ranking as RankingType[]} />
  ]

  return (
    <>
      <ContainerLayout sx={sxLayout} channelName={channelName} domain={domain}>
        <ContentLayout sidebarSlots={sidebarSlot}>
          <Stack gap={2.5}>
            <PostForm variant={'small'} />
            <NewsFeedPost
              post={MOCK_POST}
              is_user_logged_in={true}
              imageListStyle={{
                height: theme => theme.spacing(20),
                '& li': {
                  height: theme => `${theme.spacing(20)} !important`
                }
              }}
            />
          </Stack>
        </ContentLayout>
      </ContainerLayout>
    </>
  )
}

export default HomeTemplate
