import { SvgIcon, SvgIconProps } from '@mui/material'

const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="36"
        viewBox="0 0 33 36"
        fill="none"
      >
        <path
          d="M27.9434 31.597H5.72215C4.7917 31.597 4.0437 30.8429 4.0437 29.9186V7.18041C4.0437 6.24996 4.79779 5.50195 5.72215 5.50195H27.9434C28.8739 5.50195 29.6219 6.25604 29.6219 7.18041V29.9186C29.6219 30.849 28.8678 31.597 27.9434 31.597Z"
          fill="url(#paint0_linear_1881_3704)"
        />
        <path
          d="M27.9434 5.50195H5.72215C4.7917 5.50195 4.0437 6.25604 4.0437 7.18041V7.16824C4.0437 8.09869 4.79779 8.8467 5.72215 8.8467H27.9434C28.8739 8.8467 29.6219 8.09261 29.6219 7.16824V7.18041C29.6219 6.24996 28.8678 5.50195 27.9434 5.50195Z"
          fill="url(#paint1_linear_1881_3704)"
        />
        <path
          d="M27.4139 5.66593V8.85256H6.19604V5.66593C6.19604 5.27065 6.35416 4.91185 6.61566 4.65643C6.87108 4.40101 7.22988 4.23682 7.62517 4.23682H25.9787C26.7693 4.23682 27.4139 4.87536 27.4139 5.67202V5.66593Z"
          fill="url(#paint2_linear_1881_3704)"
        />
        <path
          d="M9.01768 14.5207C9.74314 14.5207 10.3312 13.9326 10.3312 13.2071C10.3312 12.4817 9.74314 11.8936 9.01768 11.8936C8.29221 11.8936 7.7041 12.4817 7.7041 13.2071C7.7041 13.9326 8.29221 14.5207 9.01768 14.5207Z"
          fill="url(#paint3_linear_1881_3704)"
        />
        <path
          d="M24.6473 14.5207C25.3728 14.5207 25.9609 13.9326 25.9609 13.2071C25.9609 12.4817 25.3728 11.8936 24.6473 11.8936C23.9218 11.8936 23.3337 12.4817 23.3337 13.2071C23.3337 13.9326 23.9218 14.5207 24.6473 14.5207Z"
          fill="url(#paint4_linear_1881_3704)"
        />
        <path
          d="M29.6219 17.032V29.9184C29.6219 30.8489 28.8678 31.5969 27.9435 31.5969H20.0073L10.8184 22.4079C9.13386 20.7903 8.08179 18.5037 8.08179 15.986V13.3346C8.08179 12.8298 8.48924 12.4224 8.99399 12.4224C9.3163 12.4224 9.59604 12.5866 9.76024 12.8359C9.76024 12.8359 19.4174 22.4809 19.4417 22.4992C21.9655 21.4653 23.7473 18.9841 23.7473 16.0894V13.3346C23.7473 12.8298 24.1548 12.4224 24.6595 12.4224C24.9819 12.4224 25.2616 12.5866 25.4258 12.8359L29.6159 17.0259L29.6219 17.032Z"
          fill="url(#paint5_linear_1881_3704)"
        />
        <path
          d="M16.9917 24.8408C12.0841 24.9259 8.08862 20.9 8.08862 15.9924V13.3409C8.08862 12.8362 8.49607 12.4287 9.00083 12.4287C9.50558 12.4287 9.91303 12.8362 9.91303 13.3409V16.0045C9.91303 19.8905 13.0753 23.0893 16.9552 23.0224C20.7135 22.9555 23.7542 19.8784 23.7542 16.1018V13.347C23.7542 12.8422 24.1616 12.4348 24.6664 12.4348C25.1711 12.4348 25.5786 12.8422 25.5786 13.347V16.1018C25.5786 20.8696 21.7413 24.7617 16.9917 24.8468V24.8408Z"
          fill="url(#paint6_linear_1881_3704)"
        />
        <path
          d="M29.6217 29.9187V17.0323L25.4317 12.8423C25.5229 12.9882 25.5776 13.1524 25.5776 13.3349V16.0897C25.5776 20.8575 21.7403 24.7496 16.9907 24.8347C14.6069 24.8773 12.4358 23.9468 10.8364 22.4204L20.0071 31.5911H27.9433C28.8737 31.5911 29.6217 30.837 29.6217 29.9126V29.9187Z"
          fill="url(#paint7_linear_1881_3704)"
        />
        <path
          d="M30.7252 35.0998H2.94865C1.78559 35.0998 0.850586 34.1572 0.850586 33.0017V4.57902C0.850586 3.41596 1.7932 2.48096 2.94865 2.48096H30.7252C31.8883 2.48096 32.8233 3.42357 32.8233 4.57902V33.0017C32.8233 34.1648 31.8807 35.0998 30.7252 35.0998Z"
          fill="url(#paint8_linear_1881_3704)"
        />
        <path
          d="M30.7252 2.48096H2.94865C1.78559 2.48096 0.850586 3.42357 0.850586 4.57902V4.56382C0.850586 5.72688 1.7932 6.66189 2.94865 6.66189H30.7252C31.8883 6.66189 32.8233 5.71928 32.8233 4.56382V4.57902C32.8233 3.41596 31.8807 2.48096 30.7252 2.48096Z"
          fill="url(#paint9_linear_1881_3704)"
        />
        <path
          d="M30.0633 2.6863V6.66958H3.54102V2.6863C3.54102 2.19219 3.73866 1.74369 4.06553 1.42442C4.3848 1.10515 4.8333 0.899902 5.32741 0.899902H28.2693C29.2575 0.899902 30.0633 1.69808 30.0633 2.6939V2.6863Z"
          fill="url(#paint10_linear_1881_3704)"
        />
        <path
          d="M7.06872 13.7541C7.97556 13.7541 8.71069 13.019 8.71069 12.1122C8.71069 11.2053 7.97556 10.4702 7.06872 10.4702C6.16189 10.4702 5.42676 11.2053 5.42676 12.1122C5.42676 13.019 6.16189 13.7541 7.06872 13.7541Z"
          fill="url(#paint11_linear_1881_3704)"
        />
        <path
          d="M26.6051 13.7541C27.5119 13.7541 28.2471 13.019 28.2471 12.1122C28.2471 11.2053 27.5119 10.4702 26.6051 10.4702C25.6983 10.4702 24.9631 11.2053 24.9631 12.1122C24.9631 13.019 25.6983 13.7541 26.6051 13.7541Z"
          fill="url(#paint12_linear_1881_3704)"
        />
        <path
          d="M32.8312 16.8939V33.0019C32.8312 34.165 31.8886 35.1 30.7331 35.1H20.8129L9.32677 23.6138C7.2211 21.5918 5.90601 18.7335 5.90601 15.5864V12.2721C5.90601 11.6411 6.41532 11.1318 7.04626 11.1318C7.44915 11.1318 7.79883 11.3371 8.00407 11.6488C8.00407 11.6488 20.0756 23.705 20.106 23.7278C23.2607 22.4355 25.488 19.3341 25.488 15.7157V12.2721C25.488 11.6411 25.9973 11.1318 26.6282 11.1318C27.0311 11.1318 27.3808 11.3371 27.586 11.6488L32.8236 16.8863L32.8312 16.8939Z"
          fill="url(#paint13_linear_1881_3704)"
        />
        <path
          d="M17.0349 26.6542C10.9003 26.7606 5.90601 21.7283 5.90601 15.5938V12.2794C5.90601 11.6485 6.41532 11.1392 7.04626 11.1392C7.6772 11.1392 8.18651 11.6485 8.18651 12.2794V15.609C8.18651 20.4664 12.1394 24.4649 16.9893 24.3813C21.6871 24.2977 25.488 20.4512 25.488 15.7306V12.287C25.488 11.6561 25.9973 11.1468 26.6282 11.1468C27.2592 11.1468 27.7685 11.6561 27.7685 12.287V15.7306C27.7685 21.6903 22.9718 26.5554 17.0349 26.6618V26.6542Z"
          fill="url(#paint14_linear_1881_3704)"
        />
        <path
          d="M32.8234 33.0018V16.8938L27.5859 11.6562C27.6999 11.8387 27.7683 12.0439 27.7683 12.272V15.7156C27.7683 21.6753 22.9716 26.5404 17.0347 26.6468C14.0548 26.7 11.341 25.5369 9.3418 23.6289L20.8051 35.0923H30.7254C31.8884 35.0923 32.8234 34.1497 32.8234 32.9942V33.0018Z"
          fill="url(#paint15_linear_1881_3704)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1881_3704"
            x1="16.8328"
            y1="7.30811"
            x2="16.8328"
            y2="30.5267"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1881_3704"
            x1="16.8328"
            y1="5.27694"
            x2="16.8328"
            y2="8.86494"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1881_3704"
            x1="10.453"
            y1="6.54165"
            x2="23.1326"
            y2="6.54165"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1881_3704"
            x1="14.3752"
            y1="18.7355"
            x2="6.4952"
            y2="10.862"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1881_3704"
            x1="30.0513"
            y1="18.7355"
            x2="22.1777"
            y2="10.862"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1881_3704"
            x1="21.3449"
            y1="20.9867"
            x2="11.2785"
            y2="10.9203"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1881_3704"
            x1="17.1942"
            y1="12.4692"
            x2="17.1942"
            y2="29.9146"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#E8EFEE" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1881_3704"
            x1="29.6672"
            y1="29.3225"
            x2="18.4556"
            y2="18.1109"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1881_3704"
            x1="16.8369"
            y1="4.73866"
            x2="16.8369"
            y2="33.7619"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1881_3704"
            x1="16.8369"
            y1="2.19969"
            x2="16.8369"
            y2="6.68469"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1881_3704"
            x1="8.8698"
            y1="3.78094"
            x2="24.7117"
            y2="3.78094"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1881_3704"
            x1="13.4263"
            y1="19.0226"
            x2="3.57628"
            y2="9.18074"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1881_3704"
            x1="33.0288"
            y1="19.0226"
            x2="23.1788"
            y2="9.18074"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1881_3704"
            x1="22.1461"
            y1="21.8372"
            x2="9.5631"
            y2="9.25422"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1881_3704"
            x1="16.9567"
            y1="11.1898"
            x2="16.9567"
            y2="32.9965"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#E8EFEE" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1881_3704"
            x1="32.5491"
            y1="32.2565"
            x2="18.5345"
            y2="18.242"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default CopyIcon
