import React, {useCallback, useEffect, useRef, useState} from 'react'
import {uploadMedia} from "entities/courses/services/courses.media.service";
import {TypedMediaResponse} from "../../../interfaces/media.interface";
import __ from "languages/index";

export default function LivestreamPreviewDeviceDragAndDrop({callbackSetMedia, urlDefaultThumbnail}: {
  urlDefaultThumbnail?: string,
  callbackSetMedia?: (mediaCallBack: TypedMediaResponse) => void
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [progress, setProgress] = useState(urlDefaultThumbnail?0:-1);
  const [error, setError] = useState("");
  const refTimeoutHandleError = useRef<any>()

  useEffect(() => {
    return(()=>{
      if(refTimeoutHandleError.current){
        clearTimeout(refTimeoutHandleError.current)
      }
    })
  }, []);


  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = async function (e) {
    setError("")
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    await onUpload(e.dataTransfer.files)
  };

  const onUpload = useCallback(async (files: any[] | FileList ) => {
    if (files && files[0]) {
      if (files?.length === 1) {
        if(files?.[0]?.size<5000000000) {
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
          }
          let res = await uploadMedia(formData, setProgress, undefined, () => {
            setError("Có lỗi xảy ra, thử lại sau")
            setProgress(-1)
          })

          if (res && res?.length > 0) {
            callbackSetMedia?.(res?.[0])
            setProgress(101)
            setTimeout(()=>{
              setProgress(0)
            },3000)
          }else {
            setError(`${__("drag_drop_text_error")}`)
            setProgress(-1)
          }

        } else {
          setError(`${__("drag_drop_max_size")}`)
        }

      } else {
        setError(`${__("drag_drop_select_image_or_video")}`)
      }
    }
  }, [])

// triggers the input when the button is clicked
  const onButtonClick = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/mp4,video/x-m4v,video/quicktime,video/*');
    input.click();

    // Listen upload local image and save to server
    input.onchange = async () => {
        await onUpload(input.files);
    };
  };

  return (
    <div className="dragdrop_container_livestream flex-grow-1 clickable">
      {
        (progress <= 100 && progress > 0) && (
          <div className='loaded pe-none'>{__("livestream_drag_drop_upload_video")} {progress}%
          </div>
        )
      }

      {
        (progress === 101) && (
          <div className='loaded pe-none'>{__("livestream_drag_drop_loading_video")}</div>
        )
      }

      <div id="form-file-upload">
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
          <div>
            <div>{__("livestream_drag_drop_select_video_to_live")}</div>
            <button className="upload-button" onClick={onButtonClick}>{__("livestream_drag_drop_select_upload_video")}</button>
            <div style={{color: 'red'}}>{error}</div>
          </div>
        </label>
        {dragActive &&
          <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
      </div>


    </div>

  );
};




