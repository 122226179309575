import { SvgIcon, SvgIconProps } from '@mui/material'

const ShoppingBagIcon =(props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.4982 4.00016C15.0255 4.00016 13.8316 5.19407 13.8316 6.66683V8.00016H19.1649V6.66683C19.1649 5.19407 17.971 4.00016 16.4982 4.00016ZM21.8316 8.00016V6.66683C21.8316 3.72131 19.4438 1.3335 16.4982 1.3335C13.5527 1.3335 11.1649 3.72131 11.1649 6.66683V8.00016H9.6479C7.54934 8.00016 5.80758 9.62195 5.65806 11.7152L4.70568 25.0485C4.54029 27.364 6.37415 29.3335 8.69552 29.3335H24.301C26.6223 29.3335 28.4562 27.364 28.2908 25.0485L27.3384 11.7152C27.1889 9.62195 25.4471 8.00016 23.3486 8.00016H21.8316ZM9.6479 10.6668C8.94838 10.6668 8.36779 11.2074 8.31795 11.9052L7.36557 25.2385C7.31044 26.0103 7.92173 26.6668 8.69552 26.6668H24.301C25.0748 26.6668 25.6861 26.0103 25.6309 25.2385L24.6785 11.9052C24.6287 11.2074 24.0481 10.6668 23.3486 10.6668H9.6479ZM11.1648 18.6668C11.1648 17.9305 11.7617 17.3335 12.4981 17.3335H20.4982C21.2346 17.3335 21.8316 17.9305 21.8316 18.6668C21.8316 19.4032 21.2346 20.0002 20.4982 20.0002H12.4981C11.7617 20.0002 11.1648 19.4032 11.1648 18.6668Z"/>
      </svg>
    </SvgIcon>
  )
}
export default ShoppingBagIcon
