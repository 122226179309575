import {
  BurgerMenuIcon,
  CreditCardIcon,
  CupIcon,
  InfoIcon,
  NotificationIcon,
  SettingIcon,
  LetterIcon,
  StarOutlinedIcon
} from 'icons'
import __ from 'languages/index'

export const channelSettingsRoutes = [
  {
    link: `invite-user`,
    label: __('invite_user'),
    icon: LetterIcon
  },
  {
    link: 'information',
    label: __('channel_information'),
    icon: InfoIcon
  },

  {
    link: 'post-categories',
    label: __('channel_setting_data_category'),
    icon: BurgerMenuIcon
  },
  {
    link: 'gamifications',
    label: __('channel_setting_data_gamifications'),
    icon: CupIcon
  },
  {
    link: 'credit-configurations',
    label: __('channel_setting_data_payment'),
    icon: CreditCardIcon
  },
  {
    link: 'point-configurations',
    label: __('channel_setting_data_point'),
    icon: StarOutlinedIcon
  },
  {
    link: 'email-notification',
    label: __('channel_setting_data_email'),
    icon: NotificationIcon
  },
  {
    link: 'advance',
    label: __('channel_setting_data_advance'),
    icon: SettingIcon
  }
]
