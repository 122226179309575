//Library
import axios from 'axios'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Button, Modal} from 'react-bootstrap'

//Components
import _Helmet from 'components/helmet'

//Stores
import {useAppDispatch} from 'config/store'
import {createAccountBank, MENTOR_REDUCER, updateAccountBank} from 'entities/mentor/store/mentor.store.reducer'

//Data
import {listBankMock} from './data'

//Scss
import './media/add.card.scss'
import __ from 'languages/index'
import BigLayout from "../../layouts/bigLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";

export default function AddCard() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()

  const [show, setShow] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listDataBank, setListDataBank] = useState([])
  const [formData, setFormData] = useState({
    payment_method: '',
    bank_name: '',
    bank_number: '',
    bank_account_name: ''
  })
  const [isValidation, setIsValidation] = useState({
    payment_method: false,
    bank_name: false,
    bank_number: false,
    bank_account_name: false
  })

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'bank_account_name')
      return setFormData({ ...formData, [name]: value.toLocaleUpperCase() })
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleBlur = useCallback(
    (e: React.ChangeEvent<any>) => {
      if (e.target.value !== '') {
        setIsValidation({ ...isValidation, [e.target.name]: false })
      } else {
        setIsValidation({ ...isValidation, [e.target.name]: true })
      }
    },
    [isValidation]
  )

  const handleClick = (e: React.ChangeEvent<any>) => {
    setIsValidation({ ...isValidation, [e.target.name]: false })
  }

  const handleSubmit = useCallback(() => {
    if (Object.values(formData).some(item => !item))
      return Object.entries(formData)
        .map(([key, value]) => ({ key, value }))
        .forEach(item =>
          setIsValidation(isValidation => ({
            ...isValidation,
            [item?.key]: item?.value ? false : true
          }))
        )
    setIsLoading(true)
    !location?.state?._id &&
      dispatch(createAccountBank(formData))
        .unwrap()
        .then(res => {
          dispatch(
            MENTOR_REDUCER.actions.setActionsCheck({
              type: `${__("payment_add_payment_method")}`,
              isSuccess: true,
              statusCode: res.status
            })
          )
          setIsLoading(false)
          navigate(`/info-payment/add`, { replace: true })
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
          toast.show({
            title: `${__("notification")}`,
            content: `${__("payment_add_payment_method_failed")}`,
            type: EnumTypeToast.Error
          })
        })

    location?.state?._id &&
      dispatch(updateAccountBank({ ...formData, _id: location?.state?._id }))
        .unwrap()
        .then(res => {
          dispatch(
            MENTOR_REDUCER.actions.setActionsCheck({
              type: `${__("payment_edit_payment_method")}`,
              isSuccess: true,
              statusCode: res.status
            })
          )
          setIsLoading(false)
          navigate(`/info-payment/add`, { replace: true })
        })
        .catch(error => {
          console.log(error)
          setIsLoading(false)
          toast.show({
            title: `${__("notification")}`,
            content: `${__("payment_edit_payment_method_failed")}`,
            type: EnumTypeToast.Error
          })
        })
  }, [formData, location])

  const handleClose = () => setShow(false)

  useEffect(() => {
    axios
      .get(`https://api.vietqr.io/v2/banks`)
      .then(res => {
        setListDataBank(res?.data?.data)
      })
      .catch(error => {
        console.log(error)
        setListDataBank(listBankMock)
      })
  }, [])

  useEffect(() => {
    if (!location?.state?._id) return
    setFormData({
      ...formData,
      payment_method: location?.state?.payment_method,
      bank_name: location?.state?.bank_name,
      bank_number: location?.state?.bank_number,
      bank_account_name: location?.state?.bank_account_name
    })
  }, [location])

  return (
    <BigLayout>
      <_Helmet title='payment_title' />
      <div id="addCard" className="addCard_container">
        <div className="bg-white p-4 rounded">
          <h4>{location?.state?._id ? `${__("payment_edit_payment_method")}` : `${__("payment_method_title")}`}</h4>
          <div>
            <div className="d-flex align-items-center">
              <p>{__("payment_method_title")}</p>
              <span className="text-danger">&#42;</span>
            </div>
            <select
              name="payment_method"
              value={formData?.payment_method}
              onChange={handleChangeSelect}
              className={`form-select addCard__select ${
                isValidation?.payment_method && 'validation_error'
              }`}
              onBlur={handleBlur}
              onClick={handleClick}
              aria-label="Default select example"
            >
              <option value="" selected>
                {__('payment_add_card_select_payment_method')}
              </option>
              {/* <option value="zaloPay">ZaloPay</option>
            <option value="vnPay">VNPay</option> */}
              <option value="transfer">{__("payment_add_card_transfer")}</option>
            </select>
            {isValidation?.payment_method && (
              <p className="text_error">{__("payment_add_card_text_note")}</p>
            )}
          </div>
          <div>
            <div className="d-flex align-items-center">
              <p>{__("payment_add_card_select_bank")}</p>
              <span className="text-danger">&#42;</span>
            </div>
            <select
              name="bank_name"
              value={formData?.bank_name}
              onBlur={handleBlur}
              onClick={handleClick}
              onChange={handleChangeSelect}
              className={`form-select addCard__select ${
                isValidation?.bank_name && 'validation_error'
              }`}
              aria-label="Default select example"
            >
              <option value="" selected>
                {__("payment_add_card_select_bank")}
              </option>
              {listDataBank?.map((item, idx) => (
                <option
                  key={`option_${idx}`}
                  value={item?.shortName}
                >{`${item?.shortName} - ${item?.name}`}</option>
              ))}
            </select>
            {isValidation?.bank_name && <p className="text_error">{__("payment_add_card_bank_text_note")}</p>}
          </div>
          <div>
            <div className="d-flex align-items-center">
              <p>{__("payment_add_card_bank_account_number")}</p>
              <span className="text-danger">&#42;</span>
            </div>
            <input
              className={`p-2 rounded border w-100 addCard__input ${
                isValidation?.bank_number && 'validation_error'
              }`}
              type="text"
              name="bank_number"
              value={formData?.bank_number?.replace(/\D+/g, '')}
              onBlur={handleBlur}
              maxLength={20}
              onClick={handleClick}
              onChange={handleChangeInput}
              placeholder={__("payment_add_card_bank_account_number_placeholder")}
            />
            {isValidation?.bank_number && (
              <p className="text_error">{__('payment_add_card_bank_account_number_not_empty')}</p>
            )}
          </div>
          <div>
            <div className="d-flex align-items-center">
              <p>{__('payment_add_card_bank_account_name')}</p>
              <span className="text-danger">&#42;</span>
            </div>
            <input
              className={`p-2 rounded border w-100 addCard__input ${
                isValidation?.bank_account_name && 'validation_error'
              }`}
              type="text"
              name="bank_account_name"
              value={formData?.bank_account_name}
              onBlur={handleBlur}
              onClick={handleClick}
              onChange={handleChangeInput}
              placeholder={__("payment_add_card_bank_account_name_placeholder")}
            />
            {isValidation?.bank_account_name && (
              <p className="text_error">{__("payment_add_card_bank_account_name_not_empty")}</p>
            )}
          </div>
          <button onClick={handleSubmit} className="btn btn-primary mt-4">
            {isLoading ? (
              `${__("btn_processing")}`
            ) : (
              <>{!location?.state?._id ? `${__("payment_add_card_btn_save_method")}` : `${__('payment_add_card_btn_save_changes')}`}</>
            )}
          </button>
        </div>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{__("payment_add_card_payment_success")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{__('payment_add_card_payment_success_text')}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              {__('btn_close')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </BigLayout>
  )
}
