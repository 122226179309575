import React, { useState } from 'react'
import { FormControl, Stack, TextField, Typography, Button, Box, InputLabel } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannelThunk } from '../store/channel.slice'
import { PaymentPayload, PaymentSchema } from '../validate/payment-configurations.validate'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import FileUploader from '../components/FileUploader'
import Select from 'components/Select'
import useBanks from 'hooks/useBanks'

const CreditConfigurations = () => {
  const channel = useAppSelector(channelInfo)
  const dispatch = useAppDispatch()

  const [qr, setQr] = useState<FileList | File[]>(null)
  const banks = useBanks()

  const { register, control, handleSubmit } = useForm<PaymentPayload>({
    resolver: zodResolver(PaymentSchema),
    defaultValues: {
      bank_account_name: channel?.bank_account_name || '',
      bank_account_number: channel?.bank_account_number || '',
      bank_brand_name: channel?.bank_brand_name || '',
      bank_name: channel?.bank_name || ''
    }
  })

  const urlQR = () => {
    if (qr) return URL.createObjectURL(qr[0])
    return channel?.bank_qr_code?.media_url
  }

  const submitForm = (value: PaymentPayload) => {
    try {
      dispatch(updateChannelThunk({ ...channel, ...value }))
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Stack gap={2} onSubmit={handleSubmit(submitForm)} component="form">
      <ChannelSettingsTitle>{__('payment_add_card_transfer')}</ChannelSettingsTitle>
      <Typography>
        Chức năng cài đặt thanh toán với tài khoản ngân hàng cho phép người dùng liên kết và quản lý
        thông tin tài khoản ngân hàng của họ để thực hiện các giao dịch dễ dàng hơn.
      </Typography>
      <Grid container spacing={2}>
        <Grid container xs={12}>
          <Grid xs={3}>
            <FileUploader
              acceptFiles="image/*"
              onUploaded={setQr}
              placeholder={
                (qr !== null || channel?.bank_qr_code?.media_url) && (
                  <Box
                    component={'img'}
                    src={urlQR()}
                    sx={{
                      borderRadius: theme => theme.spacing(1.5),
                      width: '128px',
                      height: '128px'
                    }}
                  />
                )
              }
            />
          </Grid>
          <Grid xs={9}>
            <Stack gap={2}>
              <Controller
                control={control}
                name="bank_name"
                render={({ field: { onChange, value } }) => (
                  <FormControl variant="filled">
                    <InputLabel>{__('channel_setting_payment_bank')}</InputLabel>
                    <Select
                      onChangeValue={onChange}
                      value={value}
                      options={banks}
                      getItemValue={item => item.shortName}
                      renderItem={item => <Typography>{item.name}</Typography>}
                    />
                  </FormControl>
                )}
              />

              <TextField
                label={__('channel_setting_payment_branch_bank')}
                placeholder={__('channel_setting_payment_branch_bank_placeholder')}
                {...register('bank_brand_name')}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid spacing={2} container xs={12}>
          <Grid xs={6}>
            <TextField
              label={__('payment_add_card_bank_account_number')}
              placeholder={__('payment_add_card_bank_account_number_placeholder')}
              {...register('bank_account_number')}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              label={__('payment_add_card_bank_account_name')}
              placeholder={__('payment_add_card_bank_account_name_placeholder')}
              {...register('bank_brand_name')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Stack gap={2}>
        <ChannelSettingsTitle>VNPay</ChannelSettingsTitle>
        <TextField label={__('channel_setting_tmncode_label')} />
        <TextField label={__('channel_setting_secret_key_label')} />
      </Stack>
      <Button type="submit" sx={{ ml: 'auto' }}>
        {__('txt_update')}
      </Button>
    </Stack>
  )
}

export default CreditConfigurations
