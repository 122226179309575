import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import './media/livestream.admin.scss'
import LivestreamAdminPreview from 'entities/livestream/components/livestream.admin.preview'
import {Form, InputGroup, Modal, Row} from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import LivestreamAdminReact from 'entities/livestream/components/livestream.admin.react'
import LivestreamChat from 'entities/livestream/components/livestream.chat'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from 'config/store'
import {useLivestreamRoomSocket} from 'entities/livestream/hooks/livestream.room.hook'
import {EnumReactLivestream} from 'entities/livestream/livestream.admin.constant'
import {EnumStatusLivestream, TypedLivestream} from 'entities/livestream/livestream.interface'
import {useLivestreamAdmin} from 'entities/livestream/hooks/livestream.admin.hook'
import {clearEntity, detailLiveStream, getDetailLiveStream} from 'entities/livestream/livestream.store.reducer'
import Button from 'react-bootstrap/Button'
import {updateLiveStream} from 'entities/livestream/services/livestream.service'
import helpers from 'helpers/index'
import {useSelector} from 'react-redux'
import __ from "languages/index";
import _Helmet from 'components/helmet'


export default function LiveStreamAdmin() {
  const params = useParams()
  const liveStreamId = params?.liveStreamId
  const permission_data = useAppSelector(state => state.user.permission_data)
  const liveDetail = useSelector(getDetailLiveStream)
  const isLiveStreamBelongToEvent = Boolean(liveDetail?.event_data)
  const eventDataOfLiveStream = isLiveStreamBelongToEvent ? liveDetail?.event_data : null
  const refLiveDetail = useRef<TypedLivestream>()
  const [isReadyForSetup, setIsReadyForSetup] = useState(false)
  const account = useAppSelector(state => state.user.user_data)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { socket } = useLivestreamRoomSocket(liveDetail?._id)
  const refLivestreamAdminReact = useRef<any>()
  const [showModalEnd, setShowModalEnd] = useState(false)
  const [showModalAlreadyLive, setShowModalAlreadyLive] = useState(false)
  const [showModalBlockMobile, setShowModalBlockMobile] = useState(false)
  const [isPressEnd, setIsPressEnd] = useState(false)
  const [isHaveMoreLivestream, setIsHaveMoreLivestream] = useState(false)

  const [validate, setValidate] = useState(false)

  const refValueTimeToNextLive = useRef({
    date: new Date().toLocaleDateString('en-CA'),
    time: `${new Date().getHours()}:${new Date().getMinutes()}`
  })

  const {
    setStream,
    endLiveStream,
    startLivestream,
    replaceAudioTrackInStream,
    onChangeVolume,
    updateMaxResolution,
    connectionStatus
  } = useLivestreamAdmin(liveDetail, socket)

  const setupLiveStream = () => {
    if (liveDetail) {
      setShowModalAlreadyLive(true)
    } else {
      setIsReadyForSetup(true)
    }
  }

  useEffect(() => {
    if(permission_data){
      if (helpers.isMobile()) {
        setShowModalBlockMobile(true)
      } else {
        if (liveStreamId && helpers.current_user_can("boss", permission_data)) {
          setupLiveStream()
        } else {
          navigate('/event')
        }
      }
    }

    return () => {
      dispatch(clearEntity())
    }
  }, [permission_data])

  useEffect(() => {
    refLiveDetail.current = liveDetail
  }, [liveDetail])

  useEffect(() => {
    if (liveDetail?.livestream_status === EnumStatusLivestream.Ended) {
      setShowModalEnd(true)
    }
  }, [liveDetail?.livestream_status])

  useEffect(() => {
    dispatch(detailLiveStream({ id: liveStreamId }))
  }, [])

  const onPlusCountReact = useCallback((typeReact: EnumReactLivestream) => {
    refLivestreamAdminReact.current?.plusCount(typeReact)
  }, [])

  const onDateLivestreamChange = useCallback(event => {
    refValueTimeToNextLive.current = {
      ...refValueTimeToNextLive.current,
      date: event.target.value
    }
  }, [])

  const onTimeLivestreamChange = useCallback(event => {
    refValueTimeToNextLive.current = {
      ...refValueTimeToNextLive.current,
      time: event.target.value
    }
  }, [])

  const updateLivestream = useCallback(
    async event => {
      try {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
        } else {
          event.preventDefault()
          event.stopPropagation()

          if (isHaveMoreLivestream) {
            await updateLiveStream({
              _id: refLiveDetail.current?._id,
              start_time: new Date(
                `${refValueTimeToNextLive.current.date} ${refValueTimeToNextLive.current.time}`
              ).toISOString(),
              livestream_status: EnumStatusLivestream.Wait
            })
          }

          if (isLiveStreamBelongToEvent) {
            backToEvent()
          } else {
            backToHomepage()
          }
        }
      } catch (error) {}

      setValidate(true)
    },
    [isHaveMoreLivestream]
  )

  const handleChangeHasMoreRadio = useCallback(() => {
    setIsHaveMoreLivestream(old => !old)
  }, [])
  const backToEvent = () => {
    navigate('/event/detail/' + liveDetail?.event_data?._id)
  }

  const backToHomepage = () => {
    navigate('/')
  }

  const btnBackToEvent = () => {
    return <Button onClick={backToEvent}>{__("livestream_back_to_event")}</Button>
  }
  const btnBackToHomepage = () => {
    return <Button onClick={backToHomepage}>{__("livestream_back_to_home")}</Button>
  }

  const ModalBlockMobile = useMemo(() => {
    if (!showModalBlockMobile) return

    return (
      <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-lg-center" style={{ textAlign: 'center' }}>
          <h5>{__("livestream_admin_modal_title")}</h5>

          {isLiveStreamBelongToEvent ? btnBackToEvent() : btnBackToHomepage()}
        </Modal.Body>
      </Modal>
    )
  }, [showModalBlockMobile])

  const ModalAlreadyLive = useMemo(() => {
    if (!showModalAlreadyLive) return

    return (
      <Modal show size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-lg-center" style={{ textAlign: 'center' }}>
          <h5>{__("livestream_admin_modal_already_live_title")}</h5>
          {isLiveStreamBelongToEvent ? btnBackToEvent() : btnBackToHomepage()}
        </Modal.Body>
      </Modal>
    )
  }, [showModalAlreadyLive])

  return (
    <div id={'containerLivestreamAdmin'}>
      <_Helmet title='livestream_title' />
      <Container fluid>
        <Row>
          <Col xs={12} md={9} className={'text-white'}>
            <LivestreamAdminPreview
              connectionStatus={connectionStatus}
              endLiveStream={endLiveStream}
              setStream={setStream}
              startLivestream={startLivestream}
              replaceAudioTrackInStream={replaceAudioTrackInStream}
              onPlusCountReact={onPlusCountReact}
              isReadyForSetup={isReadyForSetup}
              onChangeVolume={onChangeVolume}
              updateMaxResolution={updateMaxResolution}
              showModalAlreadyLive={showModalAlreadyLive}
              setIsPressEnd={setIsPressEnd}
              socket={socket}
            />
          </Col>
          <Col xs={12} md={3} className={' text-white'}>
            <div className="livestream_action">
              <div className={'livestream_action_row_1 mb-3'}>
                <LivestreamAdminReact ref={refLivestreamAdminReact} />
              </div>
              <div className={'livestream_action_row_2'}>
                <LivestreamChat socket={socket} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {ModalAlreadyLive}

      <Modal
        show={showModalEnd}
        size={isPressEnd ? 'lg' : 'sm'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {isPressEnd ? (
          <Modal.Body className="text-lg-center" style={{ textAlign: 'center' }}>
            <h5>{__("livestream_closed")}</h5>
            <p className="text-lg-start fw-bold">
              {__("livestream_admin_sub_text")}
            </p>
            <Form.Group className={'mb-2'}>
              <div className="d-flex">
                <Form.Check
                  name="has-more-live"
                  label={__("livestream_admin_never")}
                  inline
                  type="radio"
                  checked={!isHaveMoreLivestream}
                  onChange={handleChangeHasMoreRadio}
                />
                <Form.Check
                  label={__("livestream_admin_next_time")}
                  name={'has-more-live'}
                  inline
                  type="radio"
                  checked={isHaveMoreLivestream}
                  onChange={handleChangeHasMoreRadio}
                />
              </div>
            </Form.Group>
            {isHaveMoreLivestream ? (
              <Form
                className="needs-validation my-3 text-lg-center"
                noValidate
                validated={validate}
                onSubmit={updateLivestream}
              >
                <Form.Group className="mb-3 text-lg-start">
                  <Form.Label htmlFor="date" className="text-lg-start fw-bold">
                    {__("livestream_next_time")}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={'date'}
                      className="form-control"
                      lang="vi"
                      onChange={onDateLivestreamChange}
                      required
                      id="date"
                      min={new Date().toLocaleDateString('en-CA')}
                      defaultValue={refValueTimeToNextLive.current.date}
                    />
                    <Form.Control
                      className="form-control"
                      type={'time'}
                      onChange={onTimeLivestreamChange}
                      required
                      id="time"
                      defaultValue={refValueTimeToNextLive.current.time}
                    />
                    <div className="invalid-feedback">
                      {__("livestream_chose_start_time")}
                    </div>
                  </InputGroup>
                </Form.Group>

                <Button
                  className="text-lg-center align-self-center"
                  type={'submit'}
                  style={{ textAlign: 'center' }}
                >
                  {__("livestream_save")}
                </Button>
              </Form>
            ) : isLiveStreamBelongToEvent ? (
              btnBackToEvent()
            ) : (
              btnBackToHomepage()
            )}
          </Modal.Body>
        ) : (
          <Modal.Body className="text-lg-center" style={{ textAlign: 'center' }}>
            <h5>{__("livestream_closed")}</h5>
            {isLiveStreamBelongToEvent ? btnBackToEvent() : btnBackToHomepage()}
          </Modal.Body>
        )}
      </Modal>

      {ModalBlockMobile}

    </div>
  )
}
