//Library
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Interface
import { TypeDataNotification } from '../interface'

//Helpers
import helpers from 'helpers'

const initialState = {}

export const getListNotification = createAsyncThunk(
  'notification',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<TypeDataNotification[]>(`/notification`, {
        params: params
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const NOTIFICATION_REDUCER = createEntitySlice({
  name: 'notification',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = NOTIFICATION_REDUCER.actions

export default NOTIFICATION_REDUCER.reducer
