import {Button, Card, ListGroup} from "react-bootstrap"
import "../media/css/mobile.plan.scss"
import {ListPlan} from "entities/payment/payment.interface";
import __helpers from "helpers/index";
import __ from "languages/index";

interface TypeMobilePlan{
    listPlan: ListPlan[];
    scrollTarget: string;
    handleClickPlan: (plan_id: string) => void;
}
const MobilePlan:React.FC<TypeMobilePlan> = ({listPlan,scrollTarget,handleClickPlan}) =>{
    return(
        <div id={scrollTarget} className="pricing-mobile-container mobile-container">
            <div className="container-sm">
                <div>
                    <h1 className='mobile-title'>{__("mobile_app_plan_title")}</h1>
                    <p>{__("mobile_app_plan_sub_title")}</p>
                </div>
                <div className='row mobile-card'>
                    {
                        listPlan.map((plan)=>(
                            <div className= 'col-xl-4 col-sm-6 col-xs-6 p-2 mobile-card-item'>
                                <Card className= 'pricing-mobile-card m-0' >
                                    <Card.Body className= 'mobile-card-content '>
                                        <Card.Title className= 'mobile-card-title'>{plan?.name}</Card.Title>
                                        <Card.Text className= 'mobile-card-text'>
                                            <div className='mobile-card-price'>
                                                <p className='price-text'>{`${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}`}</p>
                                            </div>
                                        </Card.Text>
                                    </Card.Body>
                                    {
                                        plan?.trial_day >0 ? (
                                            <ListGroup className="list-group-flush mobile-card-des">
                                              <ListGroup.Item className='des-text' >{`${__("mobile_app_plan_trial")} ${plan?.trial_day} ${__("mobile_app_plan_date")}`}</ListGroup.Item>
                                            </ListGroup>
                                        ):(
                                            <hr className='mobile-hr'/>
                                        )
                                    }
                                    <Card.Body className= 'mobile-card-body' >
                                        {/*<Card.Title style={{fontSize: "14px"}}>What's included on Basic</Card.Title>*/}
                                        <Card.Text className='card-des-text'>
                                            {/*<FontAwesomeIcon icon={faCheck} style={{paddingRight: "10px"}}/>*/}
                                            {plan?.description}
                                        </Card.Text>
                                        <div className= 'mobile-card-btn '>
                                            <Button onClick={()=>handleClickPlan(plan?._id)} className= 'btn mobile-btn' variant="primary">{__("mobile_app_btn_buy")}</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))
                    }
                </div>

            </div>
       </div>

    )
}
export default MobilePlan
