import React from 'react'
import OptionsPopover, {OptionType} from 'entities/homeNew/components/OptionsPopover'
import {FacebookOutlinedIcon, LinkIcon, XOutlinedIcon} from '../../../icons'
import {CustomPopoverOptionType} from 'entities/homeNew/components/PostOptions'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

type Props = { postId: string | undefined } & CustomPopoverOptionType
const SharePostOptionsPopover = ({postId, anchorEl, onClose}: Props) => {
  const toast = useToast()

  //Todo: move this to props
  const shareOptionConfigs: OptionType[] = [
    {
      label: __('home_copy_link'),
      icon: <LinkIcon/>,
      show: true,
      action: () => {
        onClose?.()
        navigator.clipboard.writeText(window.location.origin + `/post/detail/${postId}`)
          .then(() => {
            toast.show({
              type: EnumTypeToast.Success,
              content: `${__("livestream_outside_message_copy_success")}`
            })
          })
          .catch((error) => {
            toast.show({
              type: EnumTypeToast.Error,
              content: `${__("livestream_outside_message_copy_error")}`
            })
          })
      }
    },
    {
      label: __('home_share_to_facebook'),
      icon: <FacebookOutlinedIcon fontSize={'small'}/>,
      show: true,
      action: () => {
        onClose?.()
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.origin + `/post/detail/${postId}`}`)
      }
    },
    {
      label: __('home_share_twitter'),
      icon: <XOutlinedIcon fontSize={'small'}/>,
      show: true,
      action: () => {
        onClose?.()
        window.open(`https://twitter.com/intent/tweet?text=${window.location.origin + `/post/detail/${postId}`}`)
      }
    }
  ]

  return (
    <OptionsPopover
      elevation={6}
      open={Boolean(anchorEl)}
      onClose={onClose}
      options={shareOptionConfigs}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    />
  )
}

export default SharePostOptionsPopover
