import { z } from 'zod'

export const AdvanceConfigurationSchema = z.object({
  firebase_notification_key: z.string(),
  permalink_tos: z.string().url(),
  facebook_login_client_id: z.string(),
  google_login_client_id: z.string(),
  smtp_server_address: z.string(),
  smtp_server_port: z.string(),
  smtp_server_username: z.string(),
  smtp_server_password: z.string(),
  smtp_server_secure_protocol: z.string()
})

export type AdvanceConfigurationPayload = z.infer<typeof AdvanceConfigurationSchema>
