import './media/index.scss';

import { useParams } from 'react-router-dom';

import Theme404 from '../../layout/404';
import addEvent_view from './event.update';
import detail_event from './event.detail';
import event_list from './event.list';

/**
*   Create index file for Comment
*/

export default function Calendar() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'list';

  let ActualPage: any;
  switch (Param) {

    case 'list':
      ActualPage = event_list;
      break;
    case 'add':
      ActualPage = addEvent_view;
      break;
    case 'detail':
      ActualPage = detail_event
      break
    default:
      ActualPage = Theme404;
      break;
  }
  return (
    <>
      {<ActualPage />}
    </>
  );
}
