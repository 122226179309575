import React, {useCallback, useEffect, useRef, useState} from 'react'
import '../media/dragDropUploadMedia.scss'
import {uploadMedia} from 'entities/courses/services/courses.media.service'
import ImgBlur from 'entities/courses/media/img_blur.png'
import __ from "languages/index";

export default function DragDropUploadMedia({
  callbackSetUrl,
  placeHolderBelow,
  errorFromParent = '',
  placeHolder,
  urlDefaultThumbnail,
  accept,
  placeholderText
}: {
  urlDefaultThumbnail?: string
  callbackSetUrl?: (media_id: string) => void
  placeHolder?: string
  errorFromParent?: string
  accept?: string
  placeHolderBelow?: string
  placeholderText?: string | React.ReactNode
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false)
  const [progress, setProgress] = useState(urlDefaultThumbnail ? 0 : -1)
  const [urlThumbnail, setUrlThumbnail] = useState('')
  const [error, setError] = useState('')
  const inputRef = useRef(null)
  const [retryCount, setRetryCount] = useState(0)
  const [mediaType, setMediaType] = useState<string>('')
  const refTimeoutHandleError = useRef<any>()

  useEffect(() => {
    setProgress(urlDefaultThumbnail ? 0 : -1)
  }, [urlDefaultThumbnail])
  const handleImageLoadError = () => {
    // Increment the retry count when image loading fails
    refTimeoutHandleError.current = setTimeout(() => {
      setRetryCount(retryCount + 1)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (refTimeoutHandleError.current) {
        clearTimeout(refTimeoutHandleError.current)
      }
    }
  }, [])

  useEffect(() => {
    setError(errorFromParent)
  }, [errorFromParent])

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = async function (e) {
    setError('')
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    await onUpload(e.dataTransfer.files)
  }

  // triggers when file is selected with click
  const handleChange = async function (e) {
    e.preventDefault()
    setError('')

    await onUpload(e?.target?.files)
  }

  const onUpload = useCallback(async (files: any[]) => {
    if (files && files[0]) {
      if (files?.length === 1) {
        if (files?.[0]?.size < 5000000000) {
          callbackSetUrl?.('')
          const formData = new FormData()
          for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
          }
          setUrlThumbnail(ImgBlur)
          let res = await uploadMedia(formData, setProgress, undefined, () => {
            setError(`${__("drag_drop_text_error")}`)
            setProgress(-1)
          })

          if (res && res?.length > 0) {
            setUrlThumbnail(res?.[0]?.src)
            callbackSetUrl?.(res?.[0]?.callback?._id)
            setProgress(0)
            setMediaType(res?.[0]?.callback?.media_type)
          } else {
            setError(`${__("drag_drop_text_error")}`)
            setProgress(-1)
            setUrlThumbnail('')
            setMediaType('')
          }
        } else {
          setError(`${__("drag_drop_max_size")}`)
        }
      } else {
        setError(`${__("drag_drop_select_image_or_video")}`)
      }
    }
  }, [])

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click()
  }

  return (
    <div className="dragdrop_container clickable">
      {progress < 100 && progress > 0 && (
        <div className="loaded pe-none" style={{ width: '100%', backgroundColor: 'transparent' }}>
          <div className="loaded pe-none opacity-25" style={{ width: progress + '%' }} />
          {progress}%
        </div>
      )}

      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={e => e.preventDefault()}>
        <input
          accept={accept || 'video/mp4, video/mov, image/png, image/jpeg, image/jpg'}
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={false}
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? 'drag-active' : ''}
        >
          <div>
            <div>{placeHolder || `${__("drag_drop_text")}`}</div>
            <button className="upload-button" onClick={onButtonClick} type={'button'}>
              {placeHolderBelow || `${__("drag_drop_select_image_or_video_to_upload")}`}
            </button>
            <div style={{ color: 'red' }}>{error}</div>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>

      {!error && (urlThumbnail || urlDefaultThumbnail) ? (
        <div className="thumbnail_module_drop">
          {mediaType !== 'video' ? (
            <img
              src={urlThumbnail ? urlThumbnail + '?retry=' + retryCount : urlDefaultThumbnail}
              className="thumbnail_module_drop"
              alt=""
              onError={handleImageLoadError}
            />
          ) : (
            <video
              controlsList={'nodownload'}
              key={urlThumbnail}
              autoPlay={true}
              preload={'auto'}
              id="video_source"
            >
              <source src={urlThumbnail} type="video/mp4" />
            </video>
          )}
          {(progress === 0 && urlThumbnail !== ImgBlur) || progress === 100 ? (
            <div className="thumbnail_module_bottom_drop">
              {urlThumbnail === ImgBlur && progress === 100
                ? `${__("course_loading")}`
                : placeholderText || `${__("course_change_image_video")}`}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
