//Library
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Stack, Typography, keyframes, styled } from '@mui/material'

//Images
import avatarDefaults from 'media/images/avatar_default.png'

//Icons
import { AdminAvatarIcon, ManagerAvatarIcon } from 'icons'

//Helpers
import helpers from 'helpers'
import { useAppSelector } from 'config/store'

const AvatarComponent = ({ userData, permissionData,channelpermission, isInfomationMobile = false, ...props }) => {
  const level_data = useAppSelector(state => state.user.level_data)

  const [percentLevelUp, setPercentLevelUp] = useState<number>(0)

  const levelNext = useMemo(()=> {
      if (helpers.isEmpty(level_data)) return
      const findIndex = level_data.findIndex(
        value => value?.level_number === channelpermission?.level_number
      )
      let dataNextLevelNew = level_data[findIndex + 1]
      if (helpers.isEmpty(dataNextLevelNew)) return level_data[findIndex]
      return dataNextLevelNew
  },[channelpermission,level_data])

  const ProcessBarLevelUp = useCallback(
    () => (
      <svg
        viewBox="0 0 36 36"
        className={`circular-chart ${isInfomationMobile ? 'mobile' : ''} ${
          helpers.current_user_can('boss', permissionData) ? 'boss' : 'user'
        }`}
      >
        <path
          className="circle-bg"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={`circle ${helpers.current_user_can('boss', permissionData) ? 'circle_admin' : ''}`}
          strokeDasharray={`${percentLevelUp || 0}, 100`}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    ),
    [percentLevelUp]
  )

  const handleShowIcon = useCallback(() => {
    if (helpers.current_user_can('boss', permissionData)) {
      return <AdminAvatarIcon />
    } else if (helpers.current_user_can('mentor', permissionData)) {
      return <ManagerAvatarIcon />
    } else {
      return (
        <Box className={`avatarComponent_level_number ${isInfomationMobile ? 'mobile' : ''}`}>
          <Typography color="background.paper" fontSize={isInfomationMobile ? 12 :10} fontWeight={700} component="p">
            {channelpermission?.level_number || '0'}
          </Typography>
        </Box>
      )
    }
  }, [permissionData, channelpermission])

  useEffect(() => {
    switch (helpers.current_user_can('boss', permissionData)) {
      case true:
        setPercentLevelUp(100)
        break
      case false:
        setPercentLevelUp((channelpermission?.point / levelNext?.level_point) * 100 || 0)
        break

      default:
        setPercentLevelUp(100)
        break
    }
  }, [permissionData, levelNext, channelpermission])

  return (
      <AvatarContainer id="avatarComponent">
        <Box
          sx={{
            position: 'relative',
            width: theme=> theme.spacing((props?.width / 8) || 5.5),
            height:theme=> theme.spacing((props?.height / 8) || 5.5)
          }}
        >
          <Avatar
            src={userData?.user_avatar_thumbnail || userData?.user_avatar || avatarDefaults}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            alt="avatar"
          />
          {ProcessBarLevelUp()}
          <Box className="avatarComponent_icon">{handleShowIcon()}</Box>
        </Box>
      </AvatarContainer>
  )
}

export default AvatarComponent

const AvatarContainer = styled(Stack)(({ theme }) => ({
  '& .avatarComponent_icon': {
    position: 'absolute',
    whiteSpace: 'nowrap',
    bottom: '0',
    left: '50%',
    transform: 'translate(-50%,50%)',
    width: 'auto'
  },
  '& .avatarComponent_level_number': {
    padding: theme.spacing(0, 1),
    borderRadius: theme.spacing(12.5),
    background: theme.palette.primary.main,
    '& .mobile': {
      border: '1px solid #fff'
    }
  },
  '& .circular-chart': {
    position: 'absolute',
    borderRadius: '50%',
    overflow: 'hidden',
    left: '-4px',
    top: '-4px',
    objectFit: 'cover',
    width: '118%',
    height: '120%',
    transform: 'rotate(-180deg)'
  },
  '& .circular-chart.mobile': {
    left: '-9px',
    top: '-10px'
  },
  '& .circular-chart.boss .circle': {
    stroke: theme.palette.warning.main
  },
  '& .circular-chart.user .circle': {
    stroke: theme.palette.primary.main
  },
  '& .circle-bg': {
    fill: 'none',
    stroke: '#eee',
    strokeWidth: 2
  },
  '& .circle': {
    fill: 'none',
    strokeWidth: '2',
    strokeLinecap: 'round',
    animation: `${progress} 1s ease-out forwards`
  },
  '& .circle_admin': {
    animation: 'unset'
  }
}))

const progress = keyframes`
  0% {
      stroke-dasharray: 0 100;
  }
`
