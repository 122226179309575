import React, { memo } from 'react'

//Image
import avatarDefault from 'media/images/avatar_default.png'

//Scss
import '../media/user.skeleton.scss'

const UserSkeleton = () => {
  return (
    <div>
      <div className="user_screen_cover_border">
        <span className="placeholder col-12 img"></span>
      </div>
      <div className="col-md-3 col-12 px-0 view_img_avatar_image">
        <img className="img_avatar" src={avatarDefault} alt="image_default" />
      </div>
      <div className="bottom_user">
        <span className="placeholder col-2" style={{ borderRadius: '8px' }}></span>
      </div>
      <div className="bottom_user">
        <span className="placeholder col-2" style={{ borderRadius: '8px' }}></span>
      </div>
      <div className="post_user row">
        <div className="col-0 col-md-4 d-none d-md-block">
          <div className="view_info">
            <span className="placeholder col-5 item_info" style={{ borderRadius: '8px' }}></span>
            <span className="placeholder col-8 item_info" style={{ borderRadius: '8px' }}></span>
            <span className="placeholder col-7 item_info" style={{ borderRadius: '8px' }}></span>
            <span className="placeholder col-6 item_info" style={{ borderRadius: '8px' }}></span>
            <span className="placeholder col-8 item_info" style={{ borderRadius: '8px' }}></span>
            <span className="placeholder col-7 item_info" style={{ borderRadius: '8px' }}></span>
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="view_post">
            <span className="placeholder col-4" style={{ borderRadius: '8px' }}></span>
            <span
              className="placeholder col-8"
              style={{ borderRadius: '8px', marginTop: '8px', height: '20px' }}
            ></span>
            <span
              className="placeholder col-12"
              style={{ borderRadius: '8px', marginTop: '8px', height: '120px' }}
            ></span>
          </div>
          <div className="view_post" style={{ marginTop: '20px' }}>
            <span className="placeholder col-4" style={{ borderRadius: '8px' }}></span>
            <span
              className="placeholder col-8"
              style={{ borderRadius: '8px', marginTop: '8px', height: '20px' }}
            ></span>
            <span
              className="placeholder col-12"
              style={{ borderRadius: '8px', marginTop: '8px', height: '120px' }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(UserSkeleton)
