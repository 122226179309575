import { createRef } from 'react'

import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import ToastComponent from 'entities/courses/components/toast.component'
import NotificationList from './notification.list'

export default function Notification() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'notifications'
  let ActualPage: any

  switch (Param) {
    case 'notifications':
      ActualPage = NotificationList
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="mainFrame">
        <ActualPage />
    </div>
  )
}
