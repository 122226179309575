import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Theme404 from "../../layout/404";
import __ from "languages/index";
import PrintOrder from "./PrintOrder";

export default function Print() {
  let useParam = {} as any;
  useParam = useParams();
  const navigate = useNavigate()
  let Param = useParam.slug || "order";

  let ActualPage: any;

  switch (Param) {
    case "order":
      ActualPage = PrintOrder;
      break;
  
    default:
      ActualPage = Theme404;
      break;
  }
  return (
    <>
      <div className="mainFrame">
        <ActualPage />
      </div>
    </>
  );
}
