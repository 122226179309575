import React from 'react'
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  PopoverProps,
  SxProps,
  Theme
} from '@mui/material'
import { CurrentTheme } from '../../../styles/themes'
import __ from "languages/index";

export type OptionType = {
  icon?: React.ReactNode
  label: string
  show?: boolean
  action?: () => void
}

type Props = PopoverProps & {
  options: OptionType[]
}

const OptionsPopover = (props: Props) => {
  const { options, onClose, ...rest } = props

  const handleClickOption = (option: OptionType) => {
    // @ts-ignore
    onClose?.();
    if (!option.action) return
    return option.action()
  }

  return (
    <Popover {...rest} onClose={onClose}>
      <List sx={listStyled}>
        {options
          ?.filter(option => option.show && !!option.action)
          .map((option, index) => (
            <ListItemButton
              sx={listItemButtonStyled}
              onClick={() => handleClickOption(option)}
              key={index}
            >
              {option.icon && <ListItemIcon className={'option-icon'}><>{option.icon}</></ListItemIcon>}
              <ListItemText className={'option-label'} color={'inherit'} primary={__(option.label)} />
            </ListItemButton>
          ))}
      </List>
    </Popover>
  )
}

export default OptionsPopover

const listStyled: SxProps<Theme> = {
  px: theme => theme.spacing(1)
}

const listItemButtonStyled: SxProps<CurrentTheme> = {
  px: theme => theme.spacing(3),
  borderRadius: theme => theme.spacing(1.5),
  '& *': {
    fontWeight: 600
  },
  '&:hover': {
    backgroundColor: theme => theme.palette.primary.backgroundHover || theme.palette.primary[100],

    '& .option-label, & .option-icon': {
      color: theme => theme.palette.primary.main
    }
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'fit-content',
    marginRight: theme => theme.spacing(2)
  }
}
