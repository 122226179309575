import { useEffect, useState } from 'react'
import { remove as lodashRemove } from 'lodash'

const useAddItemToList = <T extends { _id: string }>(resource: T[], des: T[] = []) => {
  const [destination, setDestination] = useState<T[]>(des)
  const [resourceCopy, setResourceCopy] = useState<T[]>(resource)

  const moveItems = (resource: T[], destination: T[], items: T[]) => {
    const tempResource = resource
    const tempDestination = destination
    const removedItems = lodashRemove(tempResource, el => items.includes(el))
    tempDestination.push(...removedItems)
    return [tempResource, tempDestination]
  }

  const add = (items: T[]) => {
    const [r, d] = moveItems(resourceCopy, destination, items)
    setDestination(d)
    setResourceCopy(r)
  }
  const remove = (items: T[]) => {
    const [r, d] = moveItems(destination, resourceCopy, items)
    setDestination(r)
    setResourceCopy(d)
  }

  useEffect(() => {
    setResourceCopy(resource)
  }, [resource])

  useEffect(() => {
    setDestination(des)
  }, [])

  return { destination, remove, add, resource: resourceCopy }
}

export default useAddItemToList
