import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import extensions_list from './extensions.list';
import extensions_view from './extensions.view';
import React from "react";

/**
 *   Create index file for Comment
 */

export default function ExtensionsIndex() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'list';
  let ActualPage: ({ includeHeader }: { includeHeader?: never }) => React.JSX.Element

  switch (Param) {

    case 'list':
      ActualPage = extensions_list;
      break;

    case 'view':
      ActualPage = extensions_view;
      break;

    default:
      ActualPage = Theme404;
      break;
  }
  return (
    <>
      <div className="mainFrame">
        <ActualPage />
      </div>
    </>
  );
}
