import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAddToCart, IRemoveFromCart, IUpdateQuantity } from './cart.action'
import { IRootState } from 'config/store'
import { ItemInCart } from 'entities/shop/interface'
import { add, list } from 'entities/shop/service/cart.service'
import { IQueryParams } from 'config/reducer.utils'

type CartState = {
  loading?: boolean
  error?: string | null
  items: ItemInCart[]
}

const initialState: CartState = {
  items: [],
  loading: false,
  error: null
}

export const addItemToCart = createAsyncThunk(
  'cart/addItemToCart',
  async (item: IAddToCart['payload']) => {
    const response = await add(item)
    return response.data
  }
)

export const getListCartItems = createAsyncThunk(
  'cart/getCartItems',
  async (params: IQueryParams) => {
    const response = await list(params)
    return response.data
  }
)

export const deleleItemInCart = createAsyncThunk(
  'cart/deleteItemInCart',
  async (itemId: string) => {}
)

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: IAddToCart) => {
      state.items.push(action.payload)
    },
    isFulfilledListCartItems: (state, action) => {
      state.items = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getListCartItems.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListCartItems.fulfilled, (state, action) => {
        state.items = action.payload
        state.loading = false
      })
      .addCase(getListCartItems.rejected, (state, action) => {
        state.error = action.error.message
        state.loading = false
      })
      .addCase(addItemToCart.pending, (state, action) => {
        const item = state.items.find(
          item => item.product.product_id === action.meta.arg.product.product_id
        )
        if (item) {
          item.item_quantity = item.item_quantity + action.meta.arg.item_quantity
        } else {
          state.items.push(action.meta.arg)
        }
      })

      .addCase(addItemToCart.rejected, (state, action) => {
        state.items = state.items.filter(
          item => item.product.product_id !== action.meta.arg.product.product_id
        )
      })
  }
})

export const getNumberOfCartItems = (state: IRootState) => state.cart.items?.length
export const getCartItems = (state: IRootState) => state.cart.items

export const { addToCart, isFulfilledListCartItems } = cartSlice.actions

export default cartSlice.reducer
