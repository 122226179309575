import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material'
import { CheckIcon, UploaderIcon } from '../../../icons'
import { colorGenerator } from '../../../styles/utils/colorPaletteGenerator'
import { basicInfoFormConfigs } from 'entities/channel/components/createChannelFormConfigs'
import { BasicInfoPayload } from 'entities/channel/components/validation/basicInfoForm.validation'
import useUploadFile from '../../../hooks/useUploadFile'
import imgPlaceholder from 'media/images/img-placeholder-image.png'
import { useFormContext } from 'react-hook-form'
import TypedChannel from '../../../interfaces/channel.interface'
import __ from 'languages/index'
import helpers from 'helpers/index'

const defaultColors = ['#DC3545', '#1677FF', '#FAAD14', '#722ED1', '#13C2C2']
const getLightColor = (color: string) => {
  const colorObj = colorGenerator(color, 'color')
  return colorObj.color.light
}

const ColorPickerButton = styled(Button, {
  shouldForwardProp(prop) {
    return prop !== 'backgroundColor'
  }
})<{ backgroundColor: string }>(({ theme, backgroundColor }) => {
  return {
    backgroundColor: backgroundColor,
    padding: 0,
    minWidth: 0,
    width: theme.spacing(5),
    height: theme.spacing(5),
    '&:hover': {
      backgroundColor: getLightColor(backgroundColor)
    },
    '&:focus': {
      backgroundColor: backgroundColor
    }
  }
})

const getSubdomain = (isGlobal: boolean) => {
  return isGlobal ? '.gamifa.com' : '.gamifa.vn'
}

type Props = {
  isGlobal?: boolean
  onSelectPrimaryColor: (color: string) => void
  selectedColor?: string
  draft?: Partial<TypedChannel>
  onSubmit?: (
    data: Partial<
      BasicInfoPayload & {
        primaryColor: string
        avatar: string
      }
    >
  ) => void
}
const BasicInfoFormStep = ({
  isGlobal = false,
  draft,
  onSelectPrimaryColor,
  selectedColor,
  onSubmit
}: Props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useFormContext<BasicInfoPayload>()

  const [logo, setLogo] = useState<File[]>(null)
  const [requiredLogo, setRequiredLogo] = useState<string>('')
  const [colorValue, setColorValue] = useState<string>(selectedColor)
  const [isDraft, setIsDraft] = useState<boolean>(true)

  const logoInputRef = useRef(null)
  const colorPickerInputRef = useRef(null)

  const { srcFiles: uploadedLogo, error: uploadLogoError } = useUploadFile(logo, {
    isMultiple: false,
    initialFiles: draft?.avatar ? [draft?.avatar] : [],
    acceptFile: 'image/png, image/jpeg, image/jpg'
  })

  useEffect(() => {
    reset(draft)
  }, [draft])

  const handleSelectColor = (color: string) => {
    color = color.toLowerCase()
    setColorValue(color)
    if (helpers.validColor(color)) {
      onSelectPrimaryColor(color)
    }
  }

  const handleChangeLogo = e => {
    setLogo(e.target.files)
  }

  const handleSubmitForm = (value: BasicInfoPayload) => {
    if (uploadedLogo.length === 0 && !draft?.avatar) {
      return setRequiredLogo('Vui lòng tải lên logo')
    }
    setRequiredLogo('')
    const payload = {
      ...value,
      _id: draft?._id,
      avatar: uploadedLogo[0]?._id,
      primary_color: selectedColor
    }
    onSubmit(payload)
  }

  return (
    <Stack gap={3} component={'form'} onSubmit={handleSubmit(handleSubmitForm)}>
      <Typography variant="h4" component="h4" fontWeight={700}>
        {__('title_channel_info_step')}
      </Typography>

      <Stack direction={'row'} gap={2}>
        <Box
          width={80}
          height={80}
          sx={{
            borderRadius: theme => theme.spacing(1.5)
          }}
        >
          <Box
            component={'img'}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: theme => theme.spacing(1.5)
            }}
            src={
              isDraft && !Boolean(uploadedLogo.length)
                ? draft?.avatar?.media_url
                : isDraft && Boolean(uploadedLogo.length)
                  ? uploadedLogo[0].media_url
                  : imgPlaceholder
            }
          />
        </Box>
        <Stack>
          <Stack alignItems={'flex-start'} justifyContent={'space-between'}>
            <Button
              variant={'outlined'}
              startIcon={<UploaderIcon />}
              fullWidth={false}
              onClick={() => logoInputRef.current.click()}
            >
              {__('btn_upload_logo')}
            </Button>
            <Typography color={'text.secondary'}>{__('txt_logo_format')}</Typography>
            <input
              type="file"
              hidden
              ref={logoInputRef}
              onChange={handleChangeLogo}
              accept={'image/*'}
            />
          </Stack>
          <FormHelperText error>{uploadLogoError || requiredLogo}</FormHelperText>
        </Stack>
      </Stack>

      {basicInfoFormConfigs.map(formEl => {
        return (
          <FormControl {...register(formEl.name as keyof BasicInfoPayload)}>
            <TextField
              key={formEl.name}
              label={formEl.label}
              placeholder={formEl.placeholder}
              sx={{
                '& .MuiFormLabel-root::first-letter': {
                  textTransform: 'uppercase'
                }
              }}
              InputProps={{
                disableUnderline: true,
                sx: {
                  '&.MuiInputBase-multiline': {
                    alignItems: 'flex-start'
                  }
                },
                startAdornment: (
                  <InputAdornment position={'start'}>{<formEl.icon />}</InputAdornment>
                ),
                endAdornment:
                  formEl.name === 'sub_domain' ? (
                    <InputAdornment position={'start'}>{getSubdomain(isGlobal)}</InputAdornment>
                  ) : null
              }}
              {...formEl}
            />
            <FormHelperText error>
              {!!errors[formEl.name] && errors[formEl.name]?.message}
            </FormHelperText>
          </FormControl>
        )
      })}

      <Box>
        <Typography variant="h6" fontWeight={700}>
          {__('txt_select_primary_color')}
        </Typography>
        <Stack direction="row" gap={1}>
          <ColorPickerInput>
            <Box
              component={'input'}
              type="color"
              ref={colorPickerInputRef}
              onChange={e => handleSelectColor(e.target.value)}
              hidden
              sx={{
                position: 'absolute',
                top: 0,
                right: 0
              }}
            />
            <Box
              className="select"
              sx={{
                backgroundColor: colorValue
              }}
              onClick={() => colorPickerInputRef.current.click()}
            ></Box>
            <Box
              component="input"
              className="text"
              value={colorValue}
              onChange={e => handleSelectColor(e.target.value)}
            ></Box>
          </ColorPickerInput>
          {defaultColors.map(color => (
            <ColorPickerButton
              key={color}
              backgroundColor={color}
              onClick={() => handleSelectColor(color)}
            >
              {selectedColor === color.toLowerCase() && <CheckIcon />}
            </ColorPickerButton>
          ))}
        </Stack>
      </Box>
      <Button type={'submit'}>{__('btn_next')}</Button>
    </Stack>
  )
}

export default BasicInfoFormStep

const ColorPickerInput = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1.5),
  border: `1px solid ${theme.palette.divider}`,
  paddingLeft: theme.spacing(0.125),

  '& .select': {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1.5),
    mr: theme.spacing(2)
  },

  '& .text': {
    borderRadius: theme.spacing(1.5),
    border: 'none',
    outline: 'none',
    height: '100%',
    width: '100%'
  }
}))
