//Library
import React, {memo, useCallback, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBuildingColumns, faCircleCheck} from '@fortawesome/free-solid-svg-icons'

import {notEmpty, useField, useForm} from '@shopify/react-form'

//Components
import TextField from 'components/TextField'

//Store
//Helpers
import helpers from 'helpers'

//Interface
import {TypeAccountBank, TypeYourIncome} from '../payment.interface'

//Scss
import './../media/detail.payment.modal.withdraw.scss'
import __ from "languages/index";

const DetailPaymentModalWithdraw = ({
  show,
  onClose,
  onSubmitWithdraw,
  yourIncome,
  dataBank
}: {
  show: boolean
  onClose
  onSubmitWithdraw
  yourIncome: TypeYourIncome[]
  dataBank: TypeAccountBank[]
}) => {
  const [dataBankSelect, setDataBankSelect] = useState<TypeAccountBank>(dataBank[0])

  /**
   *
   * @param e
   * @returns
   */

  const useFields = {
    transaction_value: useField<string>({
      value: '0',
      validates: [
        notEmpty(`${__("payment_withdrawal_amount_not_empty")}`),
        inputVal => {
          if (helpers.parseNumeric(inputVal) > 100000000) {
            return`${__("payment_withdrawal_amount_not_exceed")}`
          }
          if (helpers.parseNumeric(inputVal) < 10000) {
            return `${__("payment_withdrawal_amount_not_less_than_100000")}`
          }
          if (
            helpers.parseNumeric(inputVal) >
            helpers.parseNumeric(helpers.convertToCommasFormat(yourIncome[0]?.current_token), 0)
          ) {
            return `${__("payment_withdrawal_amount_smaller_balance")}`
          }
        }
      ]
    })
  }

  const { fields, submit, submitting, dirty, reset } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!dataBankSelect?._id) {
          return {status: 'fail', errors: [{field:"Chưa chọn ngân hàng", message:"lỗi"}]}
        }

        const formData = {
          transaction_value: `${helpers.formatNumberCommasToNumeric(values?.transaction_value)}`,
          data_payment: '',
          transaction_bank: dataBankSelect?._id
        }
        onSubmitWithdraw(formData)
        await helpers.sleep(1000)
        return { status: 'success' }
      } catch (e: any) {
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  useEffect(() => {
    reset()
  }, [])

  /**
   *
   * @param e
   * @returns
   */

  const handleSelectBank = useCallback((data: TypeAccountBank) => {
    setDataBankSelect(data)
  }, [])

  return (
    <Modal className="modal-lg" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{__("payment_request_withdrawal")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="detailPaymentModalWithdraw__body">
          <div className="detailPaymentModalWithdraw__bankWrapper">
            <div>
              <label className="detailPaymentModalWithdraw__information--title">
                {__("payment_method_title")}
              </label>
              <span className="detailPaymentModalWithdraw__information--note">&#42;</span>
            </div>
            {dataBank?.length ? (
              <>
                {dataBank?.map((item, idx) => (
                  <div
                    key={`account_${idx}`}
                    className={`detailPaymentModalWithdraw__account ${
                      dataBankSelect?._id === item?._id &&
                      'detailPaymentModalWithdraw__accountChecked'
                    }`}
                    onClick={() => handleSelectBank(item)}
                  >
                    <div className="detailPaymentModalWithdraw__account--iconBank">
                      <FontAwesomeIcon
                        icon={faBuildingColumns}
                        style={{ color: '#a51c30', fontSize: '40px' }}
                      />
                    </div>
                    <div className="detailPaymentModalWithdraw__infoBank">
                      <span className="detailPaymentModalWithdraw__infoBank--number">{`${item?.bank_name} - ${item?.bank_number}`}</span>
                      <span className="detailPaymentModalWithdraw__infoBank--infoUser">{`(${item?.bank_account_name})`}</span>
                    </div>
                    <div
                      className={`detailPaymentModalWithdraw__default ${
                        dataBankSelect?._id === item?._id && 'detailPaymentModalWithdraw__checked'
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        style={{ color: '#bc251a', fontSize: '20px' }}
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <span className="text-danger">
                {__("payment_chose_payment_method")}
              </span>
            )}
          </div>
          <div className="detailPaymentModalWithdraw__information">
            <span className="detailPaymentModalWithdraw__information--balance">
             {` ${__('payment_total_balance')} ${helpers.convertToCommasFormat(yourIncome[0]?.current_token || '0')} ${__("currency_unit")}`}
            </span>
            <div className="detailPaymentModalWithdraw__inputWrapper">
              <div className="detailPaymentModalWithdraw__input">
                <div className="detailPaymentModalWithdraw__input--label">
                  <label className="detailPaymentModalWithdraw__information--title">
                    {__("payment_enter_amount_to_withdrawal")}
                  </label>
                  <span className="detailPaymentModalWithdraw__information--note">&#42;</span>
                </div>
                <Form className="d-grid" onSubmit={submit}>
                  <TextField
                    {...fields.transaction_value}
                    label={__("payment_enter_amount_label")}
                    labelHidden
                    maxLength={12}
                    type="search"
                    requiredIndicator
                    value={helpers.formatNumberWithCommas(useFields?.transaction_value?.value)}
                    onChange={value => {
                      useFields?.transaction_value?.onChange(value)
                    }}
                  />
                </Form>
              </div>
              <span className="detailPaymentModalWithdraw__information--balance">
                {`${__("payment_total_money_received")} ${' '}
                ${helpers.formatNumberWithCommas(useFields?.transaction_value?.value || '0')} ${__("currency_unit")}`}
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={submit} disabled={!dirty || submitting || !dataBankSelect?._id}>
          {submitting ? `${__("btn_processing")}` : `${__("btn_confirm")}`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default memo(DetailPaymentModalWithdraw)
