import { SvgIcon, SvgIconProps } from '@mui/material'

const PodcastSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="32"
        height="35"
        viewBox="0 0 32 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 48095863">
          <path
            id="Vector"
            d="M16.3879 0.522515C7.83786 0.370515 0.853461 7.18012 0.800261 15.7377C0.777461 20.1229 2.59386 24.0901 5.53506 26.8945C6.00626 27.3429 6.76626 26.8717 6.58386 26.2561C6.37106 25.5265 6.24946 24.7589 6.24946 23.9609C6.24946 23.4593 6.09746 22.9805 5.82386 22.5625C4.51666 20.5789 3.81746 18.2533 3.81746 15.8289C3.81746 12.5457 5.09425 9.46012 7.41985 7.14212C9.73785 4.82412 12.8235 3.53972 16.1067 3.53972C19.3899 3.53972 22.4755 4.81652 24.7935 7.14212C27.1115 9.46012 28.3959 12.5457 28.3959 15.8289C28.3959 18.2609 27.6967 20.5789 26.3895 22.5625C26.1159 22.9729 25.9639 23.4593 25.9639 23.9533C25.9639 24.7513 25.8499 25.5265 25.6295 26.2561C25.4471 26.8793 26.2071 27.3429 26.6783 26.8945C29.5967 24.1053 31.4131 20.1837 31.4131 15.8289C31.4131 7.46891 24.7099 0.674515 16.3879 0.522515Z"
            fill="url(#paint0_linear_1101_5345)"
          />
          <path
            id="Vector_2"
            d="M16.1072 25.1543C21.26 25.1543 25.4324 20.9743 25.4324 15.8291C25.4324 10.6839 21.2524 6.50391 16.1072 6.50391C10.962 6.50391 6.78198 10.6839 6.78198 15.8291C6.78198 20.9743 10.962 25.1543 16.1072 25.1543Z"
            fill="url(#paint1_linear_1101_5345)"
          />
          <path
            id="Vector_3"
            d="M20.9326 23.8091C23.6306 22.1751 25.4318 19.2111 25.4318 15.8291C25.4318 15.4947 25.4166 15.1603 25.3786 14.8259L20.9326 10.3799V23.8091Z"
            fill="url(#paint2_linear_1101_5345)"
          />
          <path
            id="Vector_4"
            d="M17.9916 32.8377V27.9053H14.2144V32.8377C14.2144 33.8789 15.058 34.7225 16.0992 34.7225C17.1404 34.7225 17.984 33.8789 17.984 32.8377H17.9916Z"
            fill="url(#paint3_linear_1101_5345)"
          />
          <path
            id="Vector_5"
            d="M17.9916 32.8377V27.9053H14.2144V32.8377C14.2144 33.8789 15.058 34.7225 16.0992 34.7225C17.1404 34.7225 17.984 33.8789 17.984 32.8377H17.9916Z"
            fill="url(#paint4_linear_1101_5345)"
          />
          <path
            id="Vector_6"
            d="M17.9916 32.8377V27.9053H14.2144V32.8377C14.2144 33.8789 15.058 34.7225 16.0992 34.7225C17.1404 34.7225 17.984 33.8789 17.984 32.8377H17.9916Z"
            fill="url(#paint5_linear_1101_5345)"
          />
          <path
            id="Vector_7"
            d="M17.672 31.0062H14.5408C10.5888 31.0062 7.37402 27.7914 7.37402 23.8394V22.5626C7.37402 22.2814 7.60203 22.061 7.87563 22.061C8.14923 22.061 8.37723 22.289 8.37723 22.5626V23.8394C8.37723 27.2366 11.1436 30.003 14.5408 30.003H17.672C21.0692 30.003 23.8356 27.2366 23.8356 23.8394V22.5626C23.8356 22.2814 24.0636 22.061 24.3372 22.061C24.6108 22.061 24.8388 22.289 24.8388 22.5626V23.8394C24.8388 27.7914 21.624 31.0062 17.672 31.0062Z"
            fill="url(#paint6_linear_1101_5345)"
          />
          <path
            id="Vector_8"
            d="M10.4441 12.9331H21.7605V24.5991H10.4441V12.9331Z"
            fill="url(#paint7_linear_1101_5345)"
          />
          <path
            id="Vector_9"
            d="M10.4441 12.9331H21.7605V24.5991H10.4441V12.9331Z"
            fill="url(#paint8_linear_1101_5345)"
          />
          <path
            id="Vector_10"
            d="M10.4441 13.9443H21.7605V15.6847H10.4441V13.9443Z"
            fill="url(#paint9_linear_1101_5345)"
          />
          <path
            id="Vector_11"
            d="M10.4441 17.3716H21.7605V19.112H10.4441V17.3716Z"
            fill="url(#paint10_linear_1101_5345)"
          />
          <path
            id="Vector_12"
            d="M10.4441 20.9893H21.7605V22.7297H10.4441V20.9893Z"
            fill="url(#paint11_linear_1101_5345)"
          />
          <path
            id="Vector_13"
            d="M19.5196 23.2539C18.9724 23.2539 18.5316 22.8055 18.5544 22.2507C18.5696 21.7263 19.018 21.3235 19.5424 21.3235H21.6704C21.9896 21.3235 22.248 21.0651 22.248 20.7459V20.3051C22.248 19.9859 21.9896 19.7275 21.6704 19.7275H19.5196C18.9876 19.7275 18.5544 19.2943 18.5544 18.7623C18.5544 18.2303 18.9876 17.7971 19.5196 17.7971H21.6704C21.9896 17.7971 22.248 17.5387 22.248 17.2195V16.7787C22.248 16.4595 21.9896 16.2011 21.6704 16.2011H19.5196C18.9876 16.2011 18.5544 15.7679 18.5544 15.2359C18.5544 14.7039 18.9876 14.2707 19.5196 14.2707H21.6704C21.9896 14.2707 22.248 14.0123 22.248 13.6931C22.248 11.0103 20.0668 8.8291 17.384 8.8291H14.8152C12.1324 8.8291 9.95117 11.0103 9.95117 13.6931C9.95117 14.0123 10.2096 14.2707 10.5288 14.2707H12.6796C13.2116 14.2707 13.6448 14.7039 13.6448 15.2359C13.6448 15.7679 13.2116 16.2011 12.6796 16.2011H10.5288C10.2096 16.2011 9.95117 16.4595 9.95117 16.7787V17.2195C9.95117 17.5387 10.2096 17.7971 10.5288 17.7971H12.6796C13.2116 17.7971 13.6448 18.2303 13.6448 18.7623C13.6448 19.2943 13.2116 19.7275 12.6796 19.7275H10.5288C10.2096 19.7275 9.95117 19.9859 9.95117 20.3051V20.7459C9.95117 21.0651 10.2096 21.3235 10.5288 21.3235H12.6568C13.1812 21.3235 13.6296 21.7339 13.6448 22.2507C13.6676 22.7979 13.2268 23.2539 12.6796 23.2539H10.5288C10.2096 23.2539 9.95117 23.5123 9.95117 23.8315C9.95117 26.5143 12.1324 28.6955 14.8152 28.6955H17.384C20.0668 28.6955 22.248 26.5143 22.248 23.8315C22.248 23.5123 21.9896 23.2539 21.6704 23.2539H19.5196Z"
            fill="url(#paint12_linear_1101_5345)"
          />
          <path
            id="Vector_14"
            d="M14.9282 23.8771V20.4951C14.9282 19.8491 15.4526 19.3247 16.0986 19.3247C16.7446 19.3247 17.269 19.8491 17.269 20.4951V23.8771C17.269 24.5231 16.7446 25.0475 16.0986 25.0475C15.4526 25.0475 14.9282 24.5231 14.9282 23.8771Z"
            fill="url(#paint13_linear_1101_5345)"
          />
          <path
            id="Vector_15"
            d="M14.9282 17.0221V13.6401C14.9282 12.9941 15.4526 12.4697 16.0986 12.4697C16.7446 12.4697 17.269 12.9941 17.269 13.6401V17.0221C17.269 17.6681 16.7446 18.1925 16.0986 18.1925C15.4526 18.1925 14.9282 17.6681 14.9282 17.0221Z"
            fill="url(#paint14_linear_1101_5345)"
          />
          <path
            id="Vector_16"
            d="M12.6945 23.2543H10.5437C10.2245 23.2543 9.96606 23.5127 9.96606 23.8319C9.96606 26.5147 12.1473 28.6959 14.8301 28.6959H17.3989C20.0817 28.6959 22.2629 26.5147 22.2629 23.8319C22.2629 23.5127 22.0045 23.2543 21.6853 23.2543H19.5345C19.2685 23.2543 19.0329 23.1479 18.8581 22.9731H13.3861C13.2113 23.1479 12.9757 23.2543 12.7097 23.2543H12.6945Z"
            fill="url(#paint15_linear_1101_5345)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1101_5345"
            x1="5.27666"
            y1="4.99132"
            x2="26.9291"
            y2="26.6513"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9D66D6" />
            <stop offset="1" stopColor="#453D81" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1101_5345"
            x1="9.51038"
            y1="9.23231"
            x2="22.704"
            y2="22.4259"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9D66D6" />
            <stop offset="1" stopColor="#453D81" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1101_5345"
            x1="24.4894"
            y1="17.0983"
            x2="22.407"
            y2="17.0983"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#453D81" stopOpacity="0" />
            <stop offset="1" stopColor="#453D81" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1101_5345"
            x1="12.3828"
            y1="31.3101"
            x2="21.3736"
            y2="31.3101"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5A5A5A" />
            <stop offset="1" stopColor="#464646" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1101_5345"
            x1="16.1068"
            y1="32.9973"
            x2="16.1068"
            y2="29.0073"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#464646" stopOpacity="0" />
            <stop offset="1" stopColor="#202020" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1101_5345"
            x1="14.9364"
            y1="31.3101"
            x2="18.1208"
            y2="31.3101"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#464646" stopOpacity="0" />
            <stop offset="1" stopColor="#202020" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1101_5345"
            x1="16.1064"
            y1="22.061"
            x2="16.1064"
            y2="31.0062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1101_5345"
            x1="6.50729"
            y1="18.7623"
            x2="23.9341"
            y2="18.7623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1101_5345"
            x1="11.5461"
            y1="14.2023"
            x2="20.8713"
            y2="23.5275"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AAADC" stopOpacity="0" />
            <stop offset="1" stopColor="#8AAADC" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1101_5345"
            x1="16.1061"
            y1="15.6999"
            x2="16.1061"
            y2="14.1419"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AAADC" stopOpacity="0" />
            <stop offset="1" stopColor="#8AAADC" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1101_5345"
            x1="16.1061"
            y1="19.074"
            x2="16.1061"
            y2="17.402"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AAADC" stopOpacity="0" />
            <stop offset="1" stopColor="#8AAADC" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1101_5345"
            x1="16.1061"
            y1="22.6385"
            x2="16.1061"
            y2="21.4833"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AAADC" stopOpacity="0" />
            <stop offset="1" stopColor="#8AAADC" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1101_5345"
            x1="10.3844"
            y1="13.0395"
            x2="20.3556"
            y2="23.0107"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1101_5345"
            x1="17.6186"
            y1="22.1899"
            x2="15.027"
            y2="22.1899"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1101_5345"
            x1="17.6186"
            y1="15.3349"
            x2="15.027"
            y2="15.3349"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1101_5345"
            x1="16.1069"
            y1="24.2347"
            x2="16.1069"
            y2="27.5711"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AAADC" stopOpacity="0" />
            <stop offset="1" stopColor="#8AAADC" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default PodcastSidebarIcon
