import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
  StepConnector
} from '@mui/material'
import { STEPS } from 'entities/shop/data'
import OrderStepIcon from 'entities/shop/components/OrderStepIcon'

const ShopOrderDeliveryStep = ({orderStatus}) =>{
  return(
    <ShopOrderDeliveryStepContainer>
      <Stack sx={{ width: '100%' }} spacing={4}>
        <Stepper alternativeLabel activeStep={orderStatus} connector={<ColorConnector />}>
          {STEPS.map((step) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={OrderStepIcon}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </ShopOrderDeliveryStepContainer>
  )
}
export default ShopOrderDeliveryStep

const ShopOrderDeliveryStepContainer = styled(Box) (({theme})=>({
  padding: theme.spacing(2.5),

}))

const ColorConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
  },
}));
