import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {EnumTypeInputSound} from "entities/livestream/hooks/livestream.admin.hook";
import {DEFAULT_VOLUME} from "entities/livestream/livestream.admin.constant";

interface TypedLivestreamAdminSliderProps {
  type: EnumTypeInputSound,
  iconOff?: IconProp,
  iconOn?: IconProp,
  onChangeVolume: (value: number, type: EnumTypeInputSound) => void
  defaultValue?: number
  display?: boolean
}

export default function LivestreamAdminSlider({
                                                iconOff,
                                                iconOn,
                                                onChangeVolume,
                                                type,
                                                display = true,
                                                defaultValue = DEFAULT_VOLUME
                                              }: TypedLivestreamAdminSliderProps) {
  const [volume, setVolume] = useState(DEFAULT_VOLUME * 100);
  const refCurrentVolume = useRef(DEFAULT_VOLUME * 100)

  useEffect(() => {
    handleVolumeChange(volume);
  }, [volume]);

  const handleVolumeChange = (newVolume: number) => {
    onChangeVolume?.(newVolume, type)
  };

  const onSliderChange = (event: any) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    refCurrentVolume.current = newVolume;
  };

  const onOnOffVolume = useCallback(() => {
    setVolume(old => old > 0 ? 0 : refCurrentVolume.current)
  }, [])

  return (
    <div className={`volume-slider ${!display && 'd-none'}`}>
      {
        (iconOn && iconOff) &&
        <div className="clickable" onClick={onOnOffVolume}>
          <FontAwesomeIcon icon={volume ? iconOn : iconOff} className="me-2"/>
        </div>
      }

      <input
        type="range"
        min={0}
        max={100}
        value={volume}
        onChange={onSliderChange}
        className="slider"
      />
      <div className="volume-label">{volume}%</div>
    </div>
  );
};

