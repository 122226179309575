import { SvgIcon, SvgIconProps } from '@mui/material'

const CloseCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4.5C7.58172 4.5 4 8.08172 4 12.5C4 13.8188 4.31832 15.0603 4.88144 16.1546C5.27781 16.9249 5.43555 17.8942 5.08534 18.8186L4.44826 20.5H12C16.4183 20.5 20 16.9183 20 12.5C20 8.08172 16.4183 4.5 12 4.5ZM2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5C22 18.0228 17.5228 22.5 12 22.5H3.72413C2.67465 22.5 1.9496 21.4499 2.32144 20.4685L3.25656 20.8228L2.32144 20.4685L3.21508 18.1099C3.32331 17.8243 3.29872 17.45 3.10306 17.0697C2.39771 15.6989 2 14.1445 2 12.5ZM8.2929 8.7929C8.68342 8.40238 9.31659 8.40238 9.70711 8.7929L12 11.0858L14.2929 8.7929C14.6834 8.40238 15.3166 8.40238 15.7071 8.7929C16.0976 9.18342 16.0976 9.81659 15.7071 10.2071L13.4142 12.5L15.707 14.7928C16.0975 15.1833 16.0975 15.8165 15.707 16.207C15.3165 16.5975 14.6833 16.5975 14.2928 16.207L12 13.9142L9.70723 16.207C9.3167 16.5975 8.68354 16.5975 8.29302 16.207C7.90249 15.8165 7.90249 15.1833 8.29302 14.7928L10.5858 12.5L8.2929 10.2071C7.90238 9.81659 7.90238 9.18342 8.2929 8.7929Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default CloseCircleIcon
