import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, SxProps } from '@mui/material'
import ProductOverview from 'entities/shop/ViewProduct/ProductOverview'
import ProductDetail from 'entities/shop/ViewProduct/ProductDetail'
import { CurrentTheme } from '../../../styles/themes'
import { useParams } from 'react-router-dom'
import { getProductById } from 'entities/shop/service/product.service'
import { TypedProduct } from 'entities/shop/interface'
import RenderView from 'components/RenderView'
import BigLayout from '../../../layouts/bigLayout'
import ShopHeader from '../components/shop.header'
import ShopBreadcrumb from '../components/shop.breadcrumb'
import __ from 'languages/index'
import { Lightbox, Slide } from 'yet-another-react-lightbox'
import __helpers from 'helpers/index'
import Counter from 'yet-another-react-lightbox/plugins/counter'

import Zoom from 'yet-another-react-lightbox/plugins/zoom'
const ViewProduct = () => {
  const { productId } = useParams()

  const [product, setProduct] = useState<TypedProduct>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpenLightBox, setIsOpenLightBox] = useState<boolean>(false)
  const [currentIndexLightBox, setCurrentIndexLightBox] = useState<number>(0)
  const [convertMediaData, setConvertMediaData] = useState<Slide[]>([])
  const getProductDetail = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getProductById(productId)
      const productDetail = response.data
      setProduct(productDetail)
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }, [productId])

  useEffect(() => {
    if (!productId) return
    getProductDetail()
  }, [getProductDetail])


  const openLightBox = useCallback((index: number, images: string[]) => {
    if (__helpers.isEmpty(images)) return
    const listImage: Slide[] = images?.map((item: string) => {
      return {
        type: 'image',
        src: item
      }
    })

    setConvertMediaData(listImage)
    setIsOpenLightBox(true)
    setCurrentIndexLightBox(index)
  }, [])


  const renderView = () => {
    return <>
      <ProductOverview product={product} clickViewImages={openLightBox}/>
      <ProductDetail product={product} clickViewImages={openLightBox}/>

    </>
  }

  return (
    <BigLayout>
      <Box sx={containerStyled}>
        <ShopBreadcrumb
          breadcrumbName={{
            '/shop': __('shop'),
            [`/shop/product/${productId}`]: product?.product_name || ''
          }}
        />
        <ShopHeader titleHeader={__('product_details')} />
        <RenderView view={renderView()} isLoading={loading} isEmpty={!product}/>
        {isOpenLightBox && (
          <Lightbox
            index={currentIndexLightBox}
            slides={convertMediaData}
            open={true}
            close={() => setIsOpenLightBox(false)}
            plugins={[Zoom, Counter]}
            carousel={{
              finite: true
            }}
          />
        )}
      </Box>
    </BigLayout>
  )
}

export default ViewProduct

const containerStyled: SxProps<CurrentTheme> = {
  maxWidth: theme => theme.spacing(155),
  margin: '0 auto',
  display: 'grid',
  rowGap: theme => theme.spacing(3)
}
