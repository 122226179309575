import React, {useEffect} from 'react'
import {useAppDispatch, useAppSelector} from 'config/store'
import {useParams} from 'react-router-dom'
import ItemHomePost from 'entities/homeNew/components/ItemHomePost'
import SmallLayout from '../../layouts/smallLayout'
import _Helmet from 'components/helmet'
import {getRequestByIdOrSlug} from "entities/homepage/home.store.reducer";

const HomeDetailPost = () => {
  const useParam = useParams()
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in);
  const currentRequest = useAppSelector(state => state.home.currentRequest)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getRequestByIdOrSlug(useParam?.post_id))
  }, [useParam?.post_id]);

  return (
    <SmallLayout>
      <_Helmet title='home_page'/>
      {
        !!currentRequest && (
          <ItemHomePost
            key={currentRequest?._id}
            post={currentRequest}
            is_user_logged_in={is_user_logged_in}
          />
        )
      }

    </SmallLayout>
  )
}
export default HomeDetailPost
