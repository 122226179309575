import '../media/css/index.scss'
import __, { ___ } from 'languages/index'

interface TypeUpgradeHeader {
    hanleClickBtnUpgrade: () =>void
}
const UpgradeHeader:React.FC<TypeUpgradeHeader> = ({hanleClickBtnUpgrade}) =>{
    return(
        <div className='upgrade_header_container px-2'>
            <div className='container-sm py-5 px-4'>
                <h1 className='upgrade-header-content'>
                  {
                    ___('upgrade_compare_header {br} title', {
                     br: <br/>
                    })
                  }
                </h1>
                <p className='upgrade-header-subContent'>
                  {
                    ___('upgrade_compare_header {br} text', {
                      br: <br/>
                    })
                  }
                </p>
                <button onClick={hanleClickBtnUpgrade} className="btn btn-primary mobile-btn" >{__("upgrade_compare_update_now")}</button>
            </div>
        </div>
    )
}
export default UpgradeHeader
