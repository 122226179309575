import React from 'react'
import { Button, Stack, TextField } from '@mui/material'
import __ from 'languages/index'
import { TypedCategory } from '../../../interfaces/request.interface'
import { useForm } from 'react-hook-form'
import {
  CreateCategoryPostPayload,
  CreateCategoryPostSchema
} from 'entities/channel-settings/validate/post-category.validate'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useCreateCategoryMutation,
  useUpdateCategoryMutation
} from 'entities/homeNew/store/homeApi'
import { useAppSelector } from 'config/store'
import { getChannelId } from 'store/user.store.reducer'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { formatString } from 'entities/support/utils'

type Props = {
  category?: TypedCategory
  onSuccess?: () => void
}

const AddCategoryForm = ({ category, onSuccess }: Props) => {
  const channelId = useAppSelector(getChannelId)
  const { register, handleSubmit } = useForm<CreateCategoryPostPayload>({
    resolver: zodResolver(CreateCategoryPostSchema),
    defaultValues: {
      category_title: category?.category_title || '',
      category_content: category?.category_content || ''
    }
  })

  const [createCategory, {}] = useCreateCategoryMutation()
  const [updateCategory, {}] = useUpdateCategoryMutation()

  const toast = useToast()

  const handleSubmitForm = async (value: CreateCategoryPostPayload) => {
    try {
      if (category) {
        const response = await updateCategory({
          ...value,
          _id: category?._id,
          channel_id: channelId
        }).unwrap()
        toast.show({
          type: EnumTypeToast.Success,
          content: formatString(__('updated_category_successfully'), response.category_title)
        })
      } else {
        const response = await createCategory({
          ...value,
          public_status: '1',
          category_language: 'en',
          channel_id: channelId
        }).unwrap()
        console.log(response)
        toast.show({
          type: EnumTypeToast.Success,
          content: formatString(__('create_category_successfully'), response.category_title)
        })
      }
      onSuccess()
    } catch (err) {
      console.log('category', err)
    }
  }

  return (
    <Stack gap={2} component="form" onSubmit={handleSubmit(handleSubmitForm)}>
      <TextField
        {...register('category_title')}
        placeholder={__('channel_setting_label_category_name')}
        hiddenLabel
      />
      <TextField
        {...register('category_content')}
        placeholder={__('channel_setting_category_description')}
        hiddenLabel
        multiline
        minRows={3}
      />
      <Button sx={{ ml: 'auto' }} type={'submit'}>
        {__('save_category')}
      </Button>
    </Stack>
  )
}

export default AddCategoryForm
