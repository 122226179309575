import {z} from 'zod'
import {invalid} from './utils'
import __ from "languages/index";

export const CreateTicketSchema = z.object({
  post_title: z.string().min(1, invalid('required', `${__("validation_title")}`)).max(150, invalid('maxLength', 150)),
  post_content: z.string().min(1, invalid('required', `${__("validation_description")}`)).max(350, invalid('maxLength', 350)),
  post_category: z.string().min(1, invalid('required', `${__("validation_category")}`))
})
export type CreateTicketPayload = z.infer<typeof CreateTicketSchema>

export const CommentTicketSchema = z.object({
  content: z.string().min(1, invalid('required', `${__("support_validation_content_comment")}`))
})

export type CommentTicketPayload = z.infer<typeof CommentTicketSchema>
