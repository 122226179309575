import OrderApprove from "entities/orders/components/OrderApprove";
import {useAppDispatch, useAppSelector} from "config/store";
import {useLayoutEffect} from "react";
import { useParams } from "react-router-dom";
import {clearEntity, getEntity} from "entities/orders/order.store.reducer";
import __ from "languages/index";
import _Helmet from "components/helmet";
import BigLayout from "../../layouts/bigLayout";


const OrderApproveOfChannel = () =>{

    const OrderDetail = useAppSelector(state => state.order.entity);
    const dispatch = useAppDispatch();
    let useParam = useParams();

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        if(useParam.order_id){
            dispatch(getEntity({
                _id: useParam.order_id
            }))
        }
        return (() => {
            dispatch(clearEntity())
        })
    }, []);

    return (
      <BigLayout>
        <_Helmet title='order_approve_of_channel' />
        <div className="mainFrame">
          <div className="container">
            <OrderApprove OrderDetail={OrderDetail} />
          </div>
        </div>
      </BigLayout>
    )
}
export default OrderApproveOfChannel;
