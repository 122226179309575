import { SvgIcon, SvgIconProps } from '@mui/material'

const VnPayIcon = (props: SvgIconProps)=>{
  return(
    <SvgIcon {...props} >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="currentColor">
        <path
          d="M10.0813 4.94201L8.75265 6.34444L8.68615 6.41464L8.2642 6.86003L7.95204 7.19316L7.53009 7.63856L7.46358 7.70512L7.28099 7.90149L7.21449 7.96806L6.59331 8.62374L6.52681 8.69031C6.34536 8.88153 6.13955 9.04553 5.91567 9.17775C5.80875 9.24099 5.69782 9.29606 5.58345 9.34266C5.32604 9.44795 5.05486 9.51059 4.77968 9.52844C4.61285 9.54417 4.44487 9.54417 4.27804 9.52844C3.91371 9.48668 3.56113 9.36747 3.24152 9.17775C3.09676 9.09878 2.96289 8.99954 2.84279 8.88335L2.76625 8.80256L0.344921 6.2219L0.295043 6.16925C0.252046 6.11993 0.213922 6.06607 0.182103 6.00798C0.0989749 5.85941 0.0554041 5.68967 0.0559774 5.5172C0.0542575 5.49027 0.0542575 5.46334 0.0559774 5.43642V5.26818C0.0883689 5.1408 0.144839 5.02158 0.221948 4.9175C0.2423 4.89027 0.264659 4.86425 0.28845 4.84034L0.331735 4.79465L2.93939 2.07723L4.50077 0.432734C4.84561 0.0681313 5.39884 0.0481614 5.76633 0.387045L10.0816 4.94201H10.0813Z"
          fill="#005BAA" />
        <path
          d="M5.38582 11.0574C5.1757 11.0565 4.96817 11.0075 4.77783 10.9137L4.28279 10.3948L4.15982 10.2686L4.13975 10.2441L3.91043 10.002L2.75781 8.77117L2.83435 8.85196C2.95503 8.96996 3.09033 9.07012 3.23623 9.15C3.55585 9.33941 3.90843 9.45893 4.27276 9.50068C4.43959 9.51642 4.60756 9.51642 4.77439 9.50068C5.04987 9.48283 5.32104 9.4202 5.57816 9.3149C5.69253 9.2683 5.80347 9.21324 5.91039 9.15C6.13426 9.01807 6.34008 8.85408 6.52153 8.66255L6.58803 8.59598L7.2092 7.9403L7.2757 7.87374L7.4583 7.67737L7.5248 7.6108L7.94675 7.16541L8.25891 6.83227L8.69089 6.41139L8.75739 6.34119L11.4316 3.53602L12.5277 2.24917L12.5876 2.18956C12.9247 1.83525 13.4696 1.83525 13.8067 2.18956L14.4113 2.82769H14.4313C14.157 2.81559 13.8904 2.92331 13.6938 3.12573L11.6508 5.22953L11.6308 5.25404L8.08291 8.98479L6.77062 10.3418L6.69094 10.4296C6.51235 10.6126 6.30711 10.7642 6.08295 10.8783C5.95826 10.9421 5.82669 10.9894 5.6911 11.0187C5.61342 11.0359 5.53459 11.0465 5.45519 11.0502L5.38553 11.0571L5.38582 11.0574Z"
          fill="#ED1C24" />
        <path
          d="M17.6922 7.56815L17.6257 7.63835L14.7323 10.7379L11.6861 13.9954C11.5915 14.0962 11.4891 14.1888 11.3805 14.2726L11.3372 14.3041C10.2448 15.128 8.7522 15.0175 7.77931 14.0411L7.69962 13.9921L7.17821 13.4417L5.70654 11.8883L5.21494 11.3658L5.14844 11.2922C5.22755 11.3062 5.30753 11.3131 5.3875 11.3134H5.45716C5.55118 11.3092 5.64434 11.2962 5.73607 11.275C5.89172 11.2423 6.0425 11.1882 6.18439 11.1137C6.42919 10.9854 6.65335 10.8175 6.84884 10.6157L6.93857 10.514L8.06797 9.34275L11.9512 5.31034L11.9713 5.2822L13.8781 3.33271C14.1332 3.07461 14.5242 3.038 14.8183 3.24496L15.2635 3.71819L17.6885 6.2813C17.7103 6.30309 17.7301 6.32639 17.7484 6.3515C18.0282 6.71217 18.0038 7.23804 17.6919 7.56815H17.6922Z"
          fill="#ED1C24" />
        <path
          d="M8.31281 4.17361C8.24143 4.2432 8.13164 4.2432 8.06027 4.17361C6.91281 2.95453 5.04614 2.94818 3.89123 4.15969C2.73632 5.3709 2.7303 7.34126 3.87805 8.56034L4.76293 9.50104C4.76379 9.508 4.41351 9.51315 4.25098 9.49711L3.62235 8.83356C2.33816 7.46834 2.34562 5.26257 3.63898 3.90704C4.93234 2.5515 7.02202 2.55937 8.30621 3.92459C8.3687 3.99357 8.37157 4.10099 8.31281 4.17361Z"
          fill="#009EDB" />
        <path
          d="M9.68896 5.36595L9.43986 5.62556L9.00472 5.16262C8.93593 5.09 8.93593 4.9723 9.00472 4.89968C9.07352 4.82706 9.18502 4.82706 9.25382 4.89968L9.68896 5.36595Z"
          fill="#009EDB" />
        <path
          d="M5.91281 9.14934C5.91281 9.14934 5.69495 9.26916 5.58058 9.31424L4.37464 8.04131C3.60986 7.22829 3.50122 5.95626 4.11551 5.01162C4.17141 4.92781 4.28034 4.90603 4.36146 4.96261C4.44115 5.02131 4.46064 5.13719 4.40503 5.22131C4.40503 5.22131 4.40474 5.22191 4.40445 5.22191C3.88648 6.0189 3.97878 7.09213 4.62374 7.77807L5.91252 9.14903L5.91281 9.14934Z"
          fill="#009EDB" />
        <path
          d="M8.94129 6.14166L8.7587 6.3344L8.69879 6.39764L7.32688 4.93559C6.81464 4.39247 6.05502 4.20366 5.36706 4.44814C5.27877 4.49111 5.17414 4.45057 5.13344 4.35768C5.09273 4.26448 5.13114 4.15404 5.21914 4.11108C5.23147 4.10503 5.24408 4.10049 5.25727 4.09776C6.06849 3.81697 6.95997 4.04512 7.55936 4.68688L8.94129 6.14196V6.14166Z"
          fill="#009EDB" />
        <path
          d="M8.19458 6.90222L7.94663 7.16667L6.58304 5.71673C6.21412 5.39751 5.67006 5.45439 5.36764 5.84381C5.10565 6.18118 5.10679 6.66742 5.37051 7.00358L6.2574 7.9467C6.3262 8.01932 6.3262 8.13702 6.2574 8.20964C6.18861 8.28226 6.0771 8.28226 6.00831 8.20964L5.10479 7.26652C4.65274 6.73973 4.69058 5.92611 5.18963 5.44895C5.65745 5.00144 6.37178 5.00507 6.83558 5.45742L8.19458 6.90252V6.90222Z"
          fill="#009EDB" />
        <path
          d="M7.46288 7.6769L7.21895 7.9347L5.84847 6.49172C5.77968 6.4191 5.77968 6.3014 5.84847 6.22878C5.91727 6.15616 6.02877 6.15616 6.09757 6.22878L7.46288 7.6769Z"
          fill="#009EDB" />
      </svg>
    </SvgIcon>
  )
}
 export default VnPayIcon
