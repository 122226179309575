import { createRef } from 'react'

import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import JoinChannel from './channel.join'
import ListChannel from './channel.list'
import OrderChannel from './channel.order'
import ChannelCreate from 'entities/channel/channel.create'
import ChannelCreateLoading from 'entities/channel/channel.create.loading'

export default function Channel() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'channel'
  let ActualPage: any

  switch (Param) {
    case 'channel':
      ActualPage = ListChannel
      break

    case 'create':
      ActualPage = ChannelCreate
      break

    case 'loading':
      ActualPage = ChannelCreateLoading
      break

    case 'join':
      ActualPage = JoinChannel
      break

    case 'order':
      ActualPage = OrderChannel
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className={Param === 'join' ? '' : 'mainFrame'}>
      <div className="">{<ActualPage />}</div>
    </div>
  )
}
