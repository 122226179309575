import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import ShopDetail from './shop.detail'
import ShopPayment from 'entities/shop/shop.payment'
import ShopAdministration from './shop.admin'
import ShopManageProduct from 'entities/shop/shop.manage.product'
import AddProductComponent from './components/shop.product.create'
import ShopOrder from 'entities/shop/shop.order'
import { getListCartItems } from 'entities/shop/store/cart/cart.slice'
import { useAppDispatch, useAppSelector } from 'config/store'
import {  useEffect } from 'react'
import Cart from 'entities/cart'
import ViewProduct from './ViewProduct'
import MyOrder from './shop.myOrder'
import ShopRevenue from './ShopRevenue/shop.revenue'
import OrderManagerList from 'entities/orders/order.manager.list'
import OrderDetailManager from 'entities/orders/order.manager.detail'
import OrderCreate from 'entities/orders/order.manager.create'
import helpers from 'helpers/index'
import * as React from 'react'
import ShopSearch from './shop.search'
import { AxiosResponse } from 'axios'
import { getIdUserV2 } from './store'

export default function Shop() {
  let useParam = {} as any
  useParam = useParams()
  const permission_data = useAppSelector(state => state.user.permission_data)
  const  permission = helpers.current_user_can('boss', permission_data)

  let Param = useParam.slug || 'detail'
  const renderActualPage = (page: any)=>{
    return  (permission || !permission_data) ? page : Theme404
  }
  let ActualPage: any
  switch (Param) {
    case 'detail':
      ActualPage = ShopDetail
      break
    case 'payment':
      ActualPage = ShopPayment
      break
    case 'collection':
    case 'category':
    case 'stock':
    case 'admin':
        ActualPage = renderActualPage(ShopAdministration)
      break
    case 'manage-product':
      ActualPage = renderActualPage(ShopManageProduct)
      break
    case 'manage-order':
      ActualPage = renderActualPage(OrderManagerList)
      break
    case 'add':
      ActualPage = renderActualPage(AddProductComponent)
      break
    case 'edit':
      ActualPage = renderActualPage(AddProductComponent)
      break
    case 'order':
      ActualPage = ShopOrder
      break
    case 'my-order':
      ActualPage = MyOrder
      break
    case 'cart':
      ActualPage = Cart
      break
    case 'product':
      ActualPage = ViewProduct
      break
    case 'revenue':
      ActualPage = renderActualPage(ShopRevenue)
      break
    case 'order-detail-manager':
      ActualPage = renderActualPage(OrderDetailManager)
      break
    case 'create-order':
      ActualPage = renderActualPage(OrderCreate)
      break
    case 'search':
      ActualPage = ShopSearch
      break;
    default:
      ActualPage = Theme404
      break
  }

  const dispatch = useAppDispatch()

  const initCart = async () => {
    dispatch(getListCartItems({ page: 1, limit: 10 }))
  }

  useEffect(() => {
    initCart()
  }, [])

  useEffect(() => {
    dispatch(getIdUserV2())
    .unwrap()
    .then((res: AxiosResponse)=>{
      if(!res?.data?.user_id) return 
      localStorage.setItem('user_id_v2', res?.data?.user_id)
    })
    .catch((error)=>{
      console.log('getIdUserV2 error >>>', error);
    })
    return () => {
      localStorage.removeItem('user_id_v2')
    }
  }, [])

  return (
    <div className="mainFrame">
      <ActualPage />
    </div>
  )
}
