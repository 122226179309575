import React, {useCallback} from "react"
import {Breadcrumb, Image} from "react-bootstrap"
import EventDefault from "../media/event_default.jpg"
import DateTime from "date-and-time"
import {useNavigate} from "react-router-dom";
import __ from "languages/index";
import { Box } from "@mui/material";

interface Props {
  open_date: string,
  img: string,
  title: string
}

const HeaderEvent = (props: Props) => {
  const { open_date, img, title } = props
  const navigate = useNavigate()

  const handleClickTab = useCallback((link: string)=>{
    if(!link) return navigate('/404')
    navigate(link)
  },[])

  return (
    <div className="position-relative">
      <Image className="w-100 height-image-event object-fit-container overflow-hidden" src={img || EventDefault} />
      <div className="container">
        <div className="position-absolute top-0 p-4 d-center justify-content-between z-2">
            <Box component={Breadcrumb} sx={{'& a': {color: theme => `${theme.palette.primary.main} !important`}}} className="ovf">
                <Breadcrumb.Item onClick={()=> handleClickTab('/')}>{__("home_page")}</Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=> handleClickTab('/event')}>{__("event_list")}</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {title ? title?.slice(0, 20) + (title?.length > 20 ? "..." : "") : ''}
                </Breadcrumb.Item>
            </Box>
            {open_date &&
          <Box component={'span'} sx={{backgroundColor: theme=>`${theme.palette.primary.main} !important`}} className="date-area mdtxt">{DateTime.format(new Date(open_date), "D MMMM YYYY lúc HH:mm")}</Box>
        }
        </div>
        <h2 className="position-absolute bottom-0 p-4 z-3 fs-2 fw-bold text-white">{title}</h2>
      </div>
      <div className="rounded w-100 h-100 top-0 position-absolute z-1 bg-event-header"></div>
    </div>
  )
}

export default HeaderEvent
