import {connect} from "socket.io-client";
import axios from "axios";
import {TypedCourse} from "entities/courses/courses.interface";
import {TypedCreateLivestream, TypedEvent, TypedLivestream} from "entities/calendar/calendar.interface";
import helpers from "helpers/index";
import {TypedGiftTransaction} from "entities/gift/gift.interface";

/**
 * @param chatContent
 * @param idLivestream
 */

export function sendLiveChat(chatContent: string, idLivestream: string) {
  axios.post<TypedCourse>(`livestream/create-comment`, {
    "livestream_id": idLivestream,
    "chat_content": chatContent
  }).catch(console.log)
}

export function sendReact(typeReact: string, idLivestream: string) {
  axios.post<TypedCourse>(`livestream/like`, {
    "livestream_id": idLivestream,
    "react_type": typeReact
  }).catch(console.log)
}

export function sendMoreView(idLivestream: string) {
  axios.post<TypedCourse>(`livestream/view`, {
    "livestream_id": idLivestream,
    "total_time": "0"
  }).catch(console.log)
}

export async function updateLiveStream(data: TypedCreateLivestream) :Promise<TypedLivestream|undefined> {
  try{
    let response = await axios.patch<TypedLivestream>(`livestream/update`, helpers.cleanEntity(data));
    if(response?.data?._id){
      return response.data
    }else {
      return undefined
    }
  }catch (error){
    console.log(error,"hwcgwww")
    return undefined
  }
}

export function getListChatLivestream(param: object, idLivestream: string) {
  return axios.get<TypedGiftTransaction[]>(`livestream/list-comment/${idLivestream}/${helpers.buildEndUrl(param)}`)
}
