import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import axios, { AxiosRequestConfig } from 'axios'
import helpers from 'helpers/index'
import {TypedService} from "../../interfaces/plan.interface";

export const initialState = {
  entity: {} as TypedService,
  entities: [] as TypedService[],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false
}

const apiUrl = 'plan'
export const getEntity = createAsyncThunk(
  'plan/detail_service',
  async ({ _id }: { _id: string }) => {
    const requestUrl = `${apiUrl}/detail-service/${_id}`
    return axios.get<TypedService>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)
export const getEntities = createAsyncThunk(
  'plan/list_service',
  async (queryParam: any ) => {
    const EndURL = helpers.buildEndUrl(queryParam)
    const requestUrl = `${apiUrl}/list-service${EndURL}`
    return axios.get<TypedService[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

const EXTENSIONS_REDUCER = createEntitySlice({
  name: 'extensions',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => ({ ...state, ...initialState }),
    clearEntity: state => {
      state.entity = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false
        state.entities = action.payload.data
        state.totalItems = parseInt(action.payload.headers['x-total-count'] || 1000, 10)
      })
      .addCase(getEntities.pending, state => {
        state.loading = true
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false
        state.entities = null
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addMatcher(isPending(getEntity), state => {
        state.loading = true
        state.entity = null
      })
      .addMatcher(isRejected(getEntity), (state, action) => {
        state.loading = false
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(getEntity), (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
  }
})
export const { clearError, reset, clearEntity } = EXTENSIONS_REDUCER.actions
export default EXTENSIONS_REDUCER.reducer
