import axios from 'axios'
import { createAsyncThunk, isFulfilled, PayloadAction } from '@reduxjs/toolkit'
import { createEntitySlice, IQueryParams, serializeAxiosError } from '../../config/reducer.utils'
import helpers from 'helpers'
import {
  TypedChallengeDetail,
  TypedChallengeGift,
  TypedChallengeItem,
  TypedCreateAction,
  TypedCreateChallenge,
  TypedGame
} from './challenge.interface'
import { IRootState } from 'config/store'
import { TabPaneChallengeData } from './component/challenge.list/challenge.list.component'

export const initialState = {
  currentChallenge: {} as TypedChallengeDetail,
  gifts: [] as TypedChallengeGift[],
  challengeSearch: [] as TypedChallengeItem[],
  gift_add: null as TypedChallengeGift,
  errorMessage: null as unknown as string, // Errors returned from server side
  cookbook: null as TypedGame | string,
  listCookbook: [] as TypedGame[]
}

const apiUrl = 'challenge'

export const getListGiftChallenge = createAsyncThunk(
  'challenge/getListGiftChallenge',
  async () => {
    return await axios.get<TypedChallengeGift[]>(`gift/list-gift?gift_type=challenge`)
  },
  { serializeError: serializeAxiosError }
)

export const createGiftChallenge = createAsyncThunk(
  'challenge/createGiftChallenge',
  async (data: { media_id: string; name: string; description: string; price: string }) => {
    const dataPost = { ...data, gift_type: 'challenge' }
    return await axios.post<TypedChallengeGift>(`gift/create-gift`, helpers.cleanEntity(dataPost))
  },
  { serializeError: serializeAxiosError }
)

export const createGameChallenge = createAsyncThunk(
  'challenge/createGameChallenge',
  async (data: {
    media_id?: string
    title?: string
    parent_id?: string
    game_type?: string
    custom_field?: string
  }) => {
    return await axios.post<TypedChallengeGift>(`challenge/create-game`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const updateGameChallenge = createAsyncThunk(
  'challenge/updateGameChallenge',
  async (data: {
    _id: string
    media_id?: string
    title?: string
    parent_id?: string
    game_type?: string
    custom_field?: string
  }) => {
    return await axios.patch<TypedChallengeItem>(`challenge/update-game`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const getListGame = createAsyncThunk(
  'challenge/getListGame',
  async ({ type }: { type: 'system' | 'custom' }) => {
    return await axios.get<TypedGame[]>(`challenge/list-game?game_type=${type}`)
  },
  { serializeError: serializeAxiosError }
)

export const createChallenge = createAsyncThunk(
  'challenge/createChallenge',
  async (data: TypedCreateChallenge) => {
    return await axios.post<TypedChallengeItem>(`challenge/create`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const updateChallenge = createAsyncThunk(
  'challenge/updateChallenge',
  async (data: TypedCreateChallenge) => {
    return await axios.patch<TypedChallengeItem>(`challenge/update`, helpers.cleanEntity(data))
  },
  { serializeError: serializeAxiosError }
)

export const getListChallenge = createAsyncThunk(
  'challenge/getListChallenge',
  async (data: {
    page: number
    limit: number
    challenge_status?: 'past' | 'present' | 'future'
    search?: string
  }) => {
    const EndURL = helpers.buildEndUrl(data)
    const requestUrl = `challenge/list${EndURL}&order_by=DESC`
    return await axios.get<TypedChallengeItem[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const getDetailChallenge = createAsyncThunk(
  'challenge/getDetailChallenge',
  async (data: { _id: string; auth_id?: string }) => {
    return await axios.get<TypedChallengeDetail>(
      `challenge/detail/${data?._id}${data?.auth_id ? `?auth_id=${data?.auth_id}` : ''}`
    )
  },
  { serializeError: serializeAxiosError }
)

export const createActionCheckIn = createAsyncThunk(
  'challenge/createAction',
  async (data: TypedCreateAction) => {
    return await axios.post<TypedChallengeItem>(
      `challenge/create-activity`,
      helpers.cleanEntity(data)
    )
  },
  { serializeError: serializeAxiosError }
)

export const getListAction = createAsyncThunk(
  'challenge/getListAction',
  async (object: { page?: number; limit: number; user_id?: string; official_status?: string }) => {
    const EndURL = helpers.buildEndUrl(object)
    const requestUrl = `challenge/list-activity${EndURL}`
    return await axios.get<any>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const searchListChallenge = createAsyncThunk(
  'challenge/searchListChallenge',
  async (data: { page: number; limit: number; search?: string }) => {
    const EndURL = helpers.buildEndUrl(data)
    const requestUrl = `challenge/list${EndURL}`
    return await axios.get<TypedChallengeItem[]>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const deleteChallenge = createAsyncThunk(
  'challenge/deleteChallenge',
  async (data: { _id?: string }) => {
    return await axios.delete<TypedChallengeItem>(`challenge/delete/${data._id}`)
  },
  { serializeError: serializeAxiosError }
)

export const joinPermission = createAsyncThunk(
  'challenge/joinPermission',
  async (data: { challenge_id: string }) => {
    return await axios.post<TypedChallengeItem>(
      `challenge/join-permission`,
      helpers.cleanEntity(data)
    )
  },
  { serializeError: serializeAxiosError }
)

export const deletePermission = createAsyncThunk(
  'challenge/deletePermission',
  async (data: { challenge_id?: string; user_id?: string }) => {
    return await axios.delete<TypedChallengeItem>(`challenge/delete-permission`, {
      data: helpers.cleanEntity(data)
    })
  },
  { serializeError: serializeAxiosError }
)

export const getDetailGame = createAsyncThunk(
  'challenge/getDetailGame',
  async (data: { _id: string }) => {
    const requestUrl = `challenge/detail-game/${data?._id}`
    return await axios.get<TypedGame>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const CHALLENGE_REDUCER = createEntitySlice({
  name: 'challenge',
  initialState: initialState as typeof initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null
    },
    reset: state => {
      return { ...state, ...initialState }
    },
    setCookbook: (state, { payload }) => {
      state.cookbook = payload
    },
    removeDataGiftAdd: state => {
      state.gift_add = null
    },
    updateTotalMember: (state, action: PayloadAction<number>) => {
      state.currentChallenge.join_number += action.payload
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getDetailChallenge.fulfilled, (state, action) => {
        state.currentChallenge = action.payload.data
      })
      .addMatcher(isFulfilled(getListGiftChallenge), (state, action) => {
        state.gifts = action?.payload?.data
      })
      .addMatcher(isFulfilled(getListGame), (state, action) => {
        state.listCookbook = action?.payload?.data
      })
      .addMatcher(isFulfilled(searchListChallenge), (state, action) => {
        state.challengeSearch = action.payload?.data
      })
      .addMatcher(isFulfilled(createGiftChallenge), (state, action) => {
        state.gift_add = action.payload?.data
      })
  }
})
export const { clearError, reset, setCookbook, updateTotalMember, removeDataGiftAdd } =
  CHALLENGE_REDUCER.actions

export const getCurrentChallenge = (state: IRootState) => state.challenge.currentChallenge
// Reducer
export default CHALLENGE_REDUCER.reducer
