import axios from "axios";
import helpers from "helpers/index";

interface MediaAsset {
  duration?: any;
  path?: string;
  name?: string;
}

export interface MediaResponse {
  src: string;
  callback: TypedMedia;
}
interface TypedMedia {
  media_url: string;
  media_type: string;
  media_mime_type: string;
  media_file_name: string;
  media_thumbnail: string;
  _id?: string;
}

export async function uploadMedia(file): Promise<MediaResponse[]> {
  try {
    const newForm = new FormData();
    newForm.append("file[]", file);
    const mediaResponse = await axios.post(
      process.env.REACT_APP_MEDIA_UPLOAD_URL + `?callback=${process.env.REACT_APP_AJAX_URL}/chat-media/create`,
      newForm,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (Array.isArray(mediaResponse.data)) {
      return mediaResponse.data;
    }
    return [];
  } catch (error) {
    console.log(error, "syncMessages");
    throw error;
  }
}
