import { ListPlan } from 'entities/payment/payment.interface'
import __helpers from 'helpers/index'
import __ from 'languages/index'

export interface TypedExtensionPrice {
  plan: ListPlan
}
const ExtensionPrice: React.FC<TypedExtensionPrice> = ({ plan }) => {
  return (
    <div className="col-xl-4 col-sm-6 col-xs-6 pb-3">
      <div className="pricing-card p-3">
        <div className="details-pricing-card">
          <div className="pricing-card-title">
            <h3 className="m-0 text-ellipsis">{plan?.name}</h3>
            <div>{`${__helpers.formatNumberWithCommas(plan?.price)} ${__('currency_unit')}`}</div>
            <div className="m-0" style={{ fontSize: 12, color: '#157f3c', height: '20px' }}>
              {plan?.trial_day > 0
                ? `${__('extension_trial')} ${plan?.trial_day} ${__('extension_trial_date')}`
                : ''}
            </div>
          </div>
          <hr className="hr" />
          <p>{plan?.description}</p>
        </div>
      </div>
    </div>
  )
}
export default ExtensionPrice
