import {useNavigate} from 'react-router-dom'
import __helpers from 'helpers/index'
import ImageDefault from 'media/images/image_default.png'
import __ from 'languages/index'
import TypedPlan from "../../../interfaces/plan.interface";

type TypedExtensionData = {
  plan: TypedPlan
}

const ExtensionElement: React.FC<TypedExtensionData> = ({plan}) => {
  const navigate = useNavigate()
  const extension = plan?.service_id
  const clickExtensionItem = (extensionId: string) => {
    navigate(`/extensions/view/${extensionId}`)
  }


  return (
    <div key={Math.random()} className="col-12 col-xl-4 extension_container">
      <div className="extension_element d-flex mb-4">
        <div className="extension_thumbnail">
          <img
            onClick={() => clickExtensionItem(extension?._id)}
            className="extension_thumbnail_img w-100 h-100 rounded-4 object-fit-cover"
            src={extension?.avatar?.media_thumbnail ?? ImageDefault}
            alt=""
          />
        </div>
        <div className="extension_meta_in_list">
          <div className="e_title" style={{}}>
            <a onClick={() => clickExtensionItem(extension?._id)}>
              {__helpers.getTrimContent(extension?.title, 70)}
            </a>
          </div>
          <div className="mb-1" style={{color: '#1976d2', fontWeight: 500}}>
            {`${__helpers.formatNumberWithCommas(plan?.price)} ${__('extension_unit')}`}
          </div>
          {/*<div className="e_vote mb-1">*/}
          {/*    { extension?.review_value} <img  src={ratingStarExtension} alt="" /> ({__helpers.formatNumberWithCommas(extension?.review_number)} vote)*/}
          {/*</div>*/}
          <div className="e_content">{__helpers.getTrimContent(extension?.description, 255)}</div>
        </div>
      </div>
    </div>
  )
}
export default ExtensionElement
