import { SvgIcon, SvgIconProps } from '@mui/material'

export default function EyeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0004 6C8.3715 6 5.24213 8.14781 3.81811 11.2459C3.59839 11.7239 3.59839 12.2761 3.81811 12.7541C5.24213 15.8522 8.3715 18 12.0004 18C15.6293 18 18.7587 15.8522 20.1827 12.7541C20.4025 12.2761 20.4025 11.7239 20.1827 11.2459C18.7587 8.14781 15.6293 6 12.0004 6ZM2.00089 10.4106C3.73907 6.62904 7.56168 4 12.0004 4C16.4392 4 20.2618 6.62905 22 10.4106C22.4634 11.4188 22.4634 12.5812 22 13.5894C20.2618 17.371 16.4392 20 12.0004 20C7.56168 20 3.73907 17.371 2.00089 13.5894C1.53747 12.5812 1.53746 11.4188 2.00089 10.4106ZM12.0004 10C10.8959 10 10.0004 10.8954 10.0004 12C10.0004 13.1046 10.8959 14 12.0004 14C13.105 14 14.0004 13.1046 14.0004 12C14.0004 10.8954 13.105 10 12.0004 10ZM8.00042 12C8.00042 9.79086 9.79128 8 12.0004 8C14.2096 8 16.0004 9.79086 16.0004 12C16.0004 14.2091 14.2096 16 12.0004 16C9.79128 16 8.00042 14.2091 8.00042 12Z"
        />
      </svg>
    </SvgIcon>
  )
}
