//Library
import { Box, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'

//Components
import Banner from './components/shop.detail.banner'
import Category from './components/shop.detail.category'
import SuggestionToday from './components/shop.detail.suggestion'
import ProductCollection from './components/shop.detail.collection'

//Layout
import BigLayout from 'layouts/bigLayout'

//Languages
import __ from '../../languages/index'

//Store
import { useAppDispatch } from 'config/store'
import {
  getBannerOfShop,
  getCategoryOfShop,
  getProductCollectionOfShop,
  getProductOfShop
} from './store'

//Interface
import { TypedBanner, TypedCategory, TypedProduct } from './interface'

import helpers from 'helpers'
import _Helmet from '../../components/helmet'
import ShopHeader from './components/shop.header'

type TypedProductToStock = TypedProduct['product_to_collection']

interface TypedProductCollection {
  channel_id: string
  collection_count: number
  collection_description: string
  collection_id: string
  collection_name: string
  createdAt: string
  product_to_collection: TypedProductToStock
}

const ShopDetail = () => {
  const dispatch = useAppDispatch()
  const [bannerData, setBannerData] = useState<TypedBanner[]>([])
  const [categoryData, setCategoryData] = useState<TypedCategory[]>([])
  const [suggestionProductData, setSuggestionProductData] = useState<TypedProduct[]>([])
  const [productCollectionData, setProductCollectionData] = useState<TypedProductCollection[]>([])

  useEffect(() => {
    dispatch(getBannerOfShop())
      .unwrap()
      .then((res: AxiosResponse) => {
        setBannerData([...res?.data])
      })
      .catch((error: AxiosError) => {
        console.log(`getBannerOfShop error >>>`, error)
      })
  }, [])

  useEffect(() => {
    dispatch(getCategoryOfShop({ category_status: '!0' }))
      .unwrap()
      .then((res: AxiosResponse) => {
        setCategoryData([...res?.data])
      })
      .catch((error: AxiosError) => {
        console.log(`getCategoryOfShop error >>>`, error)
      })
  }, [])

  useEffect(() => {
    dispatch(getProductOfShop({ product_status: 1, limit: 12 }))
      .unwrap()
      .then((res: AxiosResponse) => {
        setSuggestionProductData([...res?.data])
      })
      .catch((error: AxiosError) => {
        console.log(`getProductOfShop error >>>`, error)
      })
  }, [])

  useEffect(() => {
    dispatch(getProductCollectionOfShop({ collection_status: '!0' }))
      .unwrap()
      .then((res: AxiosResponse) => {
        setProductCollectionData([...res?.data])
      })
      .catch((error: AxiosError) => {
        console.log(`getProductCollectionOfShop error >>>`, error)
      })
  }, [])

  return (
    <BigLayout>
      <_Helmet title={__('shop')} />
      <ShopDetailContainer id="shopDetail">
        <ShopHeader titleHeader={__('shop')} />
        {/*Start Banner */}
        {!helpers.isEmpty(bannerData) && <Banner bannerData={bannerData} />}
        {/*End Banner */}

        {!helpers.isEmpty(categoryData) && <Category categoryData={categoryData} />}

        {!helpers.isEmpty(productCollectionData) &&
          productCollectionData?.map((data: TypedProductCollection, idx: number) => (
            <React.Fragment key={`product_collection_${idx}`}>
              {!helpers.isEmpty(data?.product_to_collection) && <ProductCollection productCollectionData={data} />}
            </React.Fragment>
          ))}

        {/*Start SuggestionToday */}
        {!helpers.isEmpty(suggestionProductData) && (
          <SuggestionToday suggestionProductData={suggestionProductData} />
        )}
        {/*End SuggestionToday*/}
      </ShopDetailContainer>
    </BigLayout>
  )
}

export default ShopDetail

const ShopDetailContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(3)
}))
