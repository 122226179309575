import React, { SyntheticEvent } from 'react'
import { CustomNodeElementProps } from 'react-d3-tree'

type Props = CustomNodeElementProps

const FamilyNode = (props: Props) => {
  const { nodeDatum, toggleNode, onNodeClick, onNodeMouseOver, onNodeMouseOut } = props
  const { attributes, name } = nodeDatum

  const [isHover, setIsHover] = React.useState<boolean>(false)

  const handleClickNode = (e: SyntheticEvent) => {
    if (nodeDatum.__rd3t.collapsed) {
      onNodeClick(e)
    }
    toggleNode()
  }

  const handleMouseOver = (e: SyntheticEvent) => {
    setIsHover(true)
    onNodeMouseOver(e)
  }

  const handleMouseOut = (e: SyntheticEvent) => {
    setIsHover(false)
    onNodeMouseOver(e)
  }

  return (
    <svg
      viewBox="0 0 50 50"
      width="50"
      height="50"
      x={-25}
      y={-25}
      onClick={handleClickNode}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className="node-container"
    >
      <defs>
        <circle id="circle" cx="25" cy="25" r="25" vectorEffect="non-scaling-stroke" />
        <clipPath id="circle-clip">
          <use href="#circle" />
        </clipPath>
      </defs>
      {attributes?.avatar && (
        <g clipPath="url(#circle-clip)">
          <use href="#circle" fill="none" stroke="#0F1C3F" strokeWidth="2" opacity="0.25" />
          <image
            href={attributes.avatar as string}
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
          />
          <title>{`${name} \n ${attributes?.department}`}</title>
        </g>
      )}
      <g>
        <g className={`tooltip-node ${isHover && 'show'}`} fill="#ccc">
          <rect width="4em" height="2em" x="-4em" y="-2em" className="tooltip-container">
            {/* <foreignObject
              x="-4em"
              y={'-2em'}
              width="4em"
              height="2em"
              xmlns="http://www.w3.org/1999/xhtml"
            >
              <div className="tooltip-node__text">{name}</div>
            </foreignObject> */}
          </rect>
          <text x="-2em" y="-1.5em" textAnchor="middle">
            <tspan dy="1em" className="tooltip-node__text">
              {name}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}

export default FamilyNode
