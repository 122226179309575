import { SvgIcon, SvgIconProps } from '@mui/material'

export default function DiamondPointIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 11">
                    <path id="Vector" d="M2.20586 9.86092L2.76147 9.35715L2.20586 9.86092L10.5185 19.029C11.3123 19.9045 12.6881 19.9045 13.4818 19.029L21.7924 9.86326C22.485 9.09943 22.4834 7.93428 21.7887 7.17236L17.3979 2.35661C17.019 1.94096 16.4825 1.7041 15.92 1.7041H8.05181C7.48741 1.7041 6.94928 1.94258 6.57017 2.3607L2.20586 7.17412C1.51471 7.93638 1.51472 9.09866 2.20586 9.86092Z" fill="#DC3545" stroke="white" stroke-width="1.5" />
                    <path id="Vector 66" d="M7.94084 2.4541H16.0302C16.3114 2.4541 16.5797 2.57253 16.7691 2.78035L21.3874 7.84564C21.7348 8.2266 21.7356 8.80918 21.3893 9.19109L12.7408 18.7295C12.3439 19.1672 11.6561 19.1672 11.2592 18.7295L2.60903 9.18922C2.26346 8.80809 2.26346 8.22695 2.60903 7.84582L7.20002 2.7824C7.38957 2.57334 7.65864 2.4541 7.94084 2.4541Z" fill="url(#paint0_linear_3488_25289)" />
                    <path id="Intersect" fillRule="evenodd" clipRule="evenodd" d="M21.6486 8.51758C21.6519 8.76264 21.5656 9.0087 21.3895 9.20288L12.7411 18.7413C12.3442 19.179 11.6563 19.179 11.2594 18.7413L2.60928 9.20101C2.43368 9.00735 2.34731 8.76204 2.35017 8.51758H21.6486Z" fill="url(#paint1_linear_3488_25289)" />
                    <path id="Vector_2" d="M15.516 8.51751L11.9564 2.4541H11.8975L8.48438 8.51751H15.516Z" fill="url(#paint2_linear_3488_25289)" fillOpacity="0.6" />
                    <path id="Vector_3" d="M15.516 8.51751L11.9564 2.4541H11.8975L8.48438 8.51751H15.516Z" fill="url(#paint3_linear_3488_25289)" />
                    <path id="Vector_4" d="M15.516 8.51792L11.9564 18.9639H11.8975L8.48438 8.51792H15.516Z" fill="url(#paint4_linear_3488_25289)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_3488_25289" x1="21.4393" y1="8.42232" x2="2" y2="8.42233" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A51C30" />
                        <stop offset="1" stopColor="#DC3545" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_3488_25289" x1="2.3501" y1="19.0696" x2="21.4298" y2="19.0696" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A51C30" />
                        <stop offset="1" stopColor="#DC3545" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_3488_25289" x1="8.48438" y1="2.4541" x2="8.48438" y2="8.51751" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A51C30" stopOpacity="0" />
                        <stop offset="1" stopColor="#E35D6A" />
                        <stop offset="1" stopColor="#E35D6A" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_3488_25289" x1="8.48438" y1="2.4541" x2="8.48438" y2="8.51751" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#A51C30" stopOpacity="0" />
                        <stop offset="1" stopColor="#E35D6A" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_3488_25289" x1="12.0002" y1="8.51791" x2="12.0002" y2="18.9639" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D85965" stopOpacity="0" />
                        <stop offset="1" stopColor="#DC3545" />
                        <stop offset="1" stopColor="#E35D6A" />
                    </linearGradient>
                </defs>
            </svg>

        </SvgIcon>
    )
}
