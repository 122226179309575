import { Box, Typography } from "@mui/material";
import MessageEmptyIcon from "icons/MessageEmptyIcon";
import __ from "languages/index";

const ChatEmptyComponent = () =>{
    return (
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <MessageEmptyIcon
            sx={{
              width: '150px',
              height: '150px'
            }}
          />
          <Typography color={'text.disabled'} fontWeight={400}>
            {__(`chat_empty`)}
          </Typography>
        </Box>
      );
}
export default ChatEmptyComponent