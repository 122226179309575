import { SvgIcon, SvgIconProps } from '@mui/material'

export default function DiamondIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="21"
        viewBox="0 0 24 21"
        fill="currentColor"
      >
        <path
          d="M2.20561 9.57674L2.76123 9.07297L2.20561 9.57674L10.5183 18.7448C11.3121 19.6203 12.6878 19.6203 13.4816 18.7448L21.7922 9.57908C22.4847 8.81525 22.4831 7.6501 21.7884 6.88818L17.3977 2.07243C17.0187 1.65678 16.4822 1.41992 15.9198 1.41992H8.05157C7.48716 1.41992 6.94904 1.6584 6.56992 2.07652L2.20561 6.88994C1.51447 7.6522 1.51447 8.81448 2.20561 9.57674Z"
          fill="#DC3545"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M7.94084 2.16992H16.0302C16.3114 2.16992 16.5797 2.28835 16.7691 2.49617L21.3874 7.56146C21.7348 7.94242 21.7356 8.525 21.3893 8.90691L12.7408 18.4453C12.3439 18.8831 11.6561 18.8831 11.2592 18.4453L2.60903 8.90504C2.26346 8.52391 2.26346 7.94277 2.60903 7.56164L7.20002 2.49822C7.38957 2.28916 7.65864 2.16992 7.94084 2.16992Z"
          fill="url(#paint0_linear_2093_21701)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6484 8.2334C21.6517 8.47846 21.5653 8.72452 21.3893 8.9187L12.7408 18.4571C12.3439 18.8949 11.6561 18.8949 11.2592 18.4571L2.60903 8.91683C2.43344 8.72317 2.34707 8.47786 2.34992 8.2334H21.6484Z"
          fill="url(#paint1_linear_2093_21701)"
        />
        <path
          d="M15.5159 8.23333L11.9563 2.16992H11.8974L8.48425 8.23333H15.5159Z"
          fill="url(#paint2_linear_2093_21701)"
          fillOpacity="0.6"
        />
        <path
          d="M15.5159 8.23333L11.9563 2.16992H11.8974L8.48425 8.23333H15.5159Z"
          fill="url(#paint3_linear_2093_21701)"
        />
        <path
          d="M15.5159 8.23325L11.9563 18.6792H11.8974L8.48425 8.23325H15.5159Z"
          fill="url(#paint4_linear_2093_21701)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2093_21701"
            x1="21.4393"
            y1="8.13814"
            x2="2"
            y2="8.13815"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2093_21701"
            x1="2.34985"
            y1="18.7854"
            x2="21.4296"
            y2="18.7854"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2093_21701"
            x1="8.48425"
            y1="2.16992"
            x2="8.48426"
            y2="8.23333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" stopOpacity="0" />
            <stop offset="1" stopColor="#E35D6A" />
            <stop offset="1" stopColor="#E35D6A" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2093_21701"
            x1="8.48425"
            y1="2.16992"
            x2="8.48426"
            y2="8.23333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" stopOpacity="0" />
            <stop offset="1" stopColor="#E35D6A" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2093_21701"
            x1="12"
            y1="8.23324"
            x2="12"
            y2="18.6792"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D85965" stopOpacity="0" />
            <stop offset="1" stopColor="#DC3545" />
            <stop offset="1" stopColor="#E35D6A" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
