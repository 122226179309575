import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Box, Paper, SxProps, Typography } from '@mui/material'
import { CurrentTheme } from '../../../styles/themes'
import __ from 'languages/index'
import __helpers from 'helpers/index'
import imageDefault from 'media/images/image_default.png'

type Props = {
  images: string[]
  isSoldOut: boolean;
  clickViewImages: (index: number, images: string[]) => void
}

const ProductImageSlide = ({ images, isSoldOut, clickViewImages }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <Box>
      <Box sx={slideMainStyled}>
        <Swiper
          loop={true}
          spaceBetween={10}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
        >
           {__helpers.isEmpty(images) ? (
            <SwiperSlide>
              <img src={imageDefault} alt='image-product'/>
            </SwiperSlide>
          ) : (
            <>
             {images?.map((img, index) => (
               <SwiperSlide
                 key={index}
                 onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                   e.preventDefault()
                   clickViewImages(index, images)
                 }} 
                 className='swiper_wrapper'>
                 <img src={img || imageDefault} alt='image-product' />
                 {isSoldOut && (
                   <Paper className="sold_out">
                     <Typography color="background.paper">{__('sold_out')}</Typography>
                   </Paper>
                 )}
               </SwiperSlide>
             ))}
            </>
          )}
        </Swiper>
      </Box>
      <Box sx={slideThumbStyled}>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={16}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          navigation={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {__helpers.isEmpty(images) ? (
            <SwiperSlide>
              <img src={imageDefault} alt='image-product'/>
            </SwiperSlide>
          ) : (
            <>
              {images?.map((img, index) => (
                <SwiperSlide key={index}>
                  <img src={img || imageDefault} alt='image-product'/>
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      </Box>
    </Box>
  )
}

export default ProductImageSlide

const slideMainStyled: SxProps<CurrentTheme> = {
  '& .swiper': {
    width: '450px',
    height: '450px'
  },
  '& .swiper_wrapper': {
    position: 'relative',
    '& .sold_out': {
      width: theme => theme.spacing(18.75),
      height: theme => theme.spacing(18.75),
      borderRadius: '50%',
      display: 'grid',
      placeItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'rgb(98 111 130 / 37%)'
    }
  },
  '& .swiper-slide': {
    width: '100%',
    height: '100%',
    borderRadius: theme => theme.spacing(1.5),
    '& img': {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
      borderRadius: theme => theme.spacing(1.5)
    }
  }
}

const slideThumbStyled: SxProps<CurrentTheme> = {
  marginTop: theme => theme.spacing(2),
  '& .swiper-slide': {
    height: 'auto',
    maxWidth: '100px',
    borderRadius: theme => theme.spacing(1.5),
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: theme => theme.spacing(1.5)
    }
  }
}
