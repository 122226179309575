import {
  Box,
  Button,
  IconButton, InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  styled,
  Typography
} from '@mui/material'
import __, { ___ } from 'languages/index'
import { PlusIcon, SearchIcon } from '../../../icons'
import React, { useCallback, useEffect, useState } from 'react'
import AddStockroomModal from 'entities/shop/components/AddStockroomModal'
import { TypedStock } from 'entities/shop/interface'
import { useSelector } from 'react-redux'
import { getChannelId } from '../../../store/user.store.reducer'
import { getListStock } from 'entities/shop/service/product-category.service'
import ListStockTable from 'entities/shop/components/ListStockTable'
import { formatString } from 'entities/support/utils'
import ComponentEmpty from 'components/componentEmpty'
import DefaultSkeleton from 'components/default.skeleton'
import { IQueryParams } from 'config/reducer.utils'

const ProductStockRoom = () => {
  const channelId = useSelector(getChannelId)
  const [isShowAddStockroom, setIsShowAddStockroom] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [stockDetail, setStockDetail] = useState<TypedStock>()
  const [listStock, setListStock] = useState<TypedStock[]>([])
  const [totalStock, setTotalStock] = useState<number>()
  const [dataUpdate, setDataUpdate] = useState<TypedStock>()
  const [paramRequest, setParamRequest] = useState<IQueryParams>({
    channel_id: channelId,
    order_by: 'DESC',
    limit: 10,
    page: 1
  })

  useEffect(() => {
    const getStock = async () => {
      try {
        // setIsRefresh(true)
        await getListStock(paramRequest)
          .then((res)=>{
            // setListStock(listStock => [...listStock, ...res?.data])
            setListStock(res?.data)
            setTotalStock(Number(res.headers['x-total-count']))
          })

      } catch (err) {
        setListStock([])
        console.log('category', err)
      } finally {
        // setIsRefresh(false)
      }
    }
    getStock()

  }, [dataUpdate, isDelete, paramRequest])

  const handleSearchProduct = (event) => {
    const inputValue = event.target.value;
    setParamRequest(oldParam => ({ ...oldParam,query: inputValue, page: 1 }))
 };


  const handleCloseAddStockModal = useCallback(() => {
    setIsShowAddStockroom(false)
    setStockDetail(null)
    setIsEdit(false)
  }, [])


  return(
    <ProductStockRoomContainer id="productStockroom">
      <Paper className="wrapper">
        <Stack
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          width={'100%'}
          padding={theme => theme.spacing(0, 2)}
        >
          <Typography
            textTransform={'none'}
            variant="h5"
            fontWeight={700}
            paddingBottom={theme => theme.spacing(3)}
          >
            {__('product_stockroom')}
          </Typography>
          <Stack direction={'row'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{maxWidth: theme => theme.spacing(60)}}
            >
              <OutlinedInput
                size="small"
                type="search"
                id="stock_search"
                className="stock_search"
                placeholder={__('manage_product')}
                autoFocus
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" sx={{ padding: 0, width: theme => theme.spacing(2.375)}} >
                      <SearchIcon sx={{ width: '100%', height: '100%', marginRight: theme => theme.spacing(1)}} color="inherit" />
                    </IconButton>
                  </InputAdornment>}
                aria-describedby="outlined-helper-text"
                sx={{borderRadius: theme => theme.spacing(1.5)}}
                onChange={handleSearchProduct}
              />
              <Typography
                variant="body1"
                color={'primary.main'}
                sx={{
                  backgroundColor: theme => theme.palette.background.default,
                  borderRadius: theme => theme.spacing(1.5),
                  padding: theme => theme.spacing(1,2)
                }}
              >
                {formatString(__('stock_total_count'), totalStock || '0')}
              </Typography>
            </Box>

            <Button
              sx={{ padding: theme => theme.spacing(1, 1) }}
              startIcon={<PlusIcon sx={{ color: theme => theme.palette.background.paper }} />}
              onClick={()=> setIsShowAddStockroom(true)}
            >
              <Typography textTransform={'none'} color={'background.paper'}>
                {__('product_stockroom_add')}
              </Typography>
            </Button>
          </Stack>

        </Stack>
        {isRefresh ? <DefaultSkeleton/> : !listStock ?
          <ComponentEmpty/> : (
            <ListStockTable
              totalStock={totalStock}
              listStock={listStock}
              setParamRequest={setParamRequest}
              paramRequest={paramRequest}
              setIsEdit={setIsEdit}
              setStockDetail={setStockDetail}
              setIsShowAddStockroom={setIsShowAddStockroom}
              setIsDelete={setIsDelete}

            />
          )}

      </Paper>
      {isShowAddStockroom &&
        <AddStockroomModal
          isEdit={isEdit}
          channelId={channelId}
          setDataUpdate={setDataUpdate}
          stockDetail={stockDetail}
          isShowAddStockroom={isShowAddStockroom}
          handleCloseAddStockModal={handleCloseAddStockModal}
        />}

    </ProductStockRoomContainer>
  )
}
export default ProductStockRoom
const ProductStockRoomContainer = styled(Box)(({theme})=>({
  '& .wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .stockroom_list': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(45)})`,
    overflow: 'auto',
    '& li': {
      padding: theme.spacing(2, 0),
      borderBottom: `1px solid ${theme.palette.divider}`,
      ':last-child': {
        borderBottom: 'none'
      }
    }
  },
  '& .icon_button': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '& .edit': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '& .stock_search':{
    borderColor: theme.palette.text.secondary,
    display: "flex",
    marginRight: theme.spacing(2)
  },
}))
