import React from 'react'
import { Podcast } from '../podcast.types'
import { formatDate } from 'entities/support/components/TicketItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { getPlayingPodcast, getStatePlayingPodcast } from '../store/podcastSlice'
import AudioWave from './AudioWave'
import '../media/podcast-item.scss'
import { Image, Stack } from 'react-bootstrap'

type Props = Podcast & {
  onPlayPause: (isPlaying: boolean) => void
  className?: string
}

const PodcastItem = ({
  _id,
  title,
  content,
  updatedAt,
  onPlayPause,
  className,
  post_avatar
}: Props) => {
  const { isPlaying } = useSelector(getStatePlayingPodcast)
  const playingPodcast = useSelector(getPlayingPodcast)

  const currentPodcastPlaying = isPlaying && playingPodcast?._id === _id

  return (
    <div className={`podcast-item ${className}`}>
      <span className="podcast-item__posted-at">{formatDate(updatedAt, 'DD/MM/YYYY')}</span>
      <Stack direction={'horizontal'} className={'align-items-start'}>
        <Image src={post_avatar?.media_thumbnail || post_avatar?.media_url} width={70} />
        <div className={'ms-3'}>
          <h6 className="podcast-item__title">{title}</h6>
          <p className="podcast-item__excerpt d-inline-block text-truncate my-0">{content}</p>
        </div>
      </Stack>
      <Stack
        direction="horizontal"
        gap={2}
        onClick={e => {
          e.stopPropagation()
          onPlayPause(!isPlaying)
        }}
        className="podcast-item__controller border rounded-4"
      >
        {!currentPodcastPlaying ? <FontAwesomeIcon icon={faPlay} /> : <AudioWave />}
        <span className="podcast-item__countdown">
          {currentPodcastPlaying ? 'Playing' : 'Play'}
        </span>
      </Stack>
    </div>
  )
}

export default PodcastItem
