import React from 'react'
import {ListPlan} from 'entities/payment/payment.interface'
import __helpers from 'helpers/index'
import {faClock, faMoneyBill1Wave} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import __ from "languages/index";

interface TypePaymentPackageProps {
  checked: string
  onHandle: (planId: string) => void
  listPlan: ListPlan[]
}

const PaymentPackage: React.FC<TypePaymentPackageProps> = ({ checked, onHandle, listPlan }) => {
  return (
    <div className="bg-white rounded-2 p-3 mb-4">
      {
        listPlan?.length === 1 && listPlan[0]?.type === "one_time"?
          <h6>{__("payment_package_title_one_time")}</h6>
          :
          <h6>{__("payment_package_title")}</h6>
      }

      {listPlan.map(plan => (
        <div key={plan._id} className="border overflow-hidden rounded-4 mt-3">
          <button
            className="form-check d-flex align-items-center ps-5 p-2 w-100 border-0"
            onClick={() => onHandle(plan._id )}
            style={{
              backgroundColor: (checked === '' ? plan._id === listPlan[0]._id : checked === plan._id) ? 'rgba(0,0,0,0.05)' : 'white',
            }}
          >
            <input
              type="radio"
              className="form-check-input"
              id={plan._id}
              checked={checked === '' ? plan._id === listPlan[0]._id : checked === plan._id}
              defaultValue={checked}
              onChange={() => onHandle(plan._id)}
            />
            <div className="d-block ms-3 w-100 ">
              <div className=" d-block ms-3">
                <p
                  className="m-0 text-start text-payment"
                >
                  {plan.name}
                </p>
                <p
                  className="m-0 text-start text-payment"
                >
                  {plan.description}
                </p>
              </div>

              <div className="d-flex justify-content-start align-items-center">
                <div
                  className="text-start d-flex align-items-center rounded-pill"
                  style={{
                    color: '#FFBD00',
                    background: '#FFFAED',
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                >
                  <FontAwesomeIcon icon={faMoneyBill1Wave} />
                  <p className="m-0 p-1">{`${__helpers.formatNumberWithCommas(plan?.price)} ${__("currency_unit")}`}</p>
                </div>
              </div>
            </div>
          </button>
        </div>
      ))}
    </div>
  )
}
export default PaymentPackage
