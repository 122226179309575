import { SvgIcon, SvgIconProps } from '@mui/material'

const ShippingIcon =(props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25" fill="currentColor">
        <path
          d="M19 19.75V1.75C19 1.33579 18.6642 1 18.25 1H1.75C1.33579 1 1 1.33579 1 1.75V19.75C1 20.1642 1.33579 20.5 1.75 20.5H4M19 19.75C19 20.1642 18.6642 20.5 18.25 20.5H10M19 19.75V6.25C19 5.83579 19.3358 5.5 19.75 5.5H24.6893C24.8883 5.5 25.079 5.57902 25.2197 5.71967L30.7803 11.2803C30.921 11.421 31 11.6117 31 11.8107V19.75C31 20.1642 30.6642 20.5 30.25 20.5H28M19 19.75C19 20.1642 19.3358 20.5 19.75 20.5H22M4 20.5C4 22.1569 5.34315 23.5 7 23.5C8.65685 23.5 10 22.1569 10 20.5M4 20.5C4 18.8431 5.34315 17.5 7 17.5C8.65685 17.5 10 18.8431 10 20.5M22 20.5C22 22.1569 23.3431 23.5 25 23.5C26.6569 23.5 28 22.1569 28 20.5M22 20.5C22 18.8431 23.3431 17.5 25 17.5C26.6569 17.5 28 18.8431 28 20.5"
          stroke="white" strokeWidth="2" />
      </svg>
    </SvgIcon>
  )
}
export default ShippingIcon
