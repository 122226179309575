import React, {memo, useEffect, useState} from 'react'
import '../media/livestream.room.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye} from '@fortawesome/free-regular-svg-icons'
import {Socket} from 'socket.io-client'
import {useAppSelector} from 'config/store'
import ImgDefaultAvatar from 'entities/courses/media/img_default_avatar.png'
import {TypedCourse} from 'entities/courses/courses.interface'
import __ from "languages/index";
import helpers from 'helpers'

interface TypedLivestreamRoomHeaderProps {
  socket: Socket
}

type Product = TypedCourse | null
const LivestreamRoomHeader = ({ socket }: TypedLivestreamRoomHeaderProps) => {
  const liveDetail = useAppSelector(state => state.livestream.entity)
  const [viewCount, setViewCount] = useState(0)

  useEffect(() => {
    setViewCount(liveDetail?.view_number || 0)
  }, [liveDetail])

  useEffect(() => {
    socket.on('viewToClient', data => {
      console.log('viewToClient')
      try {
        if(!helpers.isJson(data)) return
        if (typeof JSON.parse(data) === 'object') {
          let dataView = JSON.parse(data)
          if (typeof dataView?.livestream_id?.view_number === 'number') {
            setViewCount(dataView?.livestream_id?.view_number)
          }
        }
      } catch (error) {
        console.log(error, 'sljnfjsssbsdf')
      }
    })
  }, [socket])

  return (
    <div className="d-flex align-items-center">
      <img
        className="livestreamroomvideo_avatar_mentor"
        src={
          liveDetail?.user_id?.user_avatar ||
          liveDetail?.user_id?.user_avatar_thumbnail ||
          ImgDefaultAvatar
        }
      />

      <div className="d-flex align-items-start">
        <div className="ms-3 d-flex flex-column align-items-start">
          <div className="livestreamroomvideo_txtname_mentor">
            {liveDetail?.user_id?.display_name || `${__("livestream_host")}`}
          </div>

          <div className="d-flex align-items-center livestreamroomvideo_txtview_mentor">
            <FontAwesomeIcon icon={faEye} className="me-2" /> {' ' + viewCount}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(LivestreamRoomHeader)
