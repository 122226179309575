import React from 'react'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import DateTime from 'date-and-time'
import __ from "languages/index";
import { Box, Button, Stack, Typography, styled } from '@mui/material'
import { TrashIcon } from 'icons'

type TimelineDetailType<T> = {
  description: string
} & T

type TimelineItemType<T> = {
  _id?: string
  startDate: number | string
  endDate: number | string
  title: string
  detail: TimelineDetailType<T>[],
  isOwner?: boolean
  disableDelete?: boolean
  onDeleteLeg?: (_id: string) => void
}

type TimelineProps<T> = {
  direction?: 'vertical' | 'horizontal'
  events: TimelineItemType<T>[]
  renderDetail?: (detail: TimelineDetailType<T>[]) => React.ReactNode
  isOwner?: boolean
  onDeleteLeg: (_id: string) => void
}

type TimelineItemProps<T> = TimelineItemType<T> & {
  icon: JSX.Element
  DetailTimelineComponent?: React.ReactNode
}

const TimelineChallenge = <T,>({ direction = 'horizontal', events, renderDetail, isOwner, onDeleteLeg }: TimelineProps<T>) => {
  const getIcon = (index: number, inTime: boolean) => {
    return <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#00c729' }} />
  }

  const inTime = (startDate: number | string, endDate: number | string) => {
    const now = moment.utc()
    const start = moment(+startDate * 1000).utc()
    const end = moment(+endDate * 1000).utc()
    return start <= now && end >= now
  }

  if (Array.isArray(events)) {
    return (
      <div>
        {events.map((event, index) => (
          <TimelineItem
            key={event.title}
            icon={getIcon(index, inTime(event.startDate, event.endDate))}
            DetailTimelineComponent={renderDetail(event.detail)}
            disableDelete={index == events?.length - 1}
            isOwner={isOwner}
            onDeleteLeg={onDeleteLeg}
            {...event}
          />
        ))}
      </div>
    )
  }
  return null
}

export const convertDate = (timestamp: number | string, format: string = 'DD/MM/YYYY') => {
  return moment(+timestamp * 1000)
    .utc()
    .format(format)
}

const TimelineItem = <T,>({
  title,
  icon,
  startDate,
  endDate,
  detail,
  DetailTimelineComponent,
  disableDelete,
  isOwner,
  _id,
  onDeleteLeg
}: TimelineItemProps<T>) => {
  return (
    <TimelineChallengeContainer>
      <Stack flexDirection={'row'} alignItems={'center'} className='title' >
        <Box className='circle_icon' />
        <Typography ml={2} variant='subtitle1' >{title}</Typography>
        {isOwner && disableDelete && (
          <Button onClick={() => onDeleteLeg(_id)} variant='outlined' className='btn_remove'>
            <TrashIcon />
          </Button>
        )}
      </Stack>


      <Box pl={3.5} ml={1.5} className="info_race">
        <Box sx={{
          borderBottom: '1px solid #E0E2E6',
          marginBottom: theme => theme.spacing(2)
        }}>
          <Typography>{__("time_line_start")} {DateTime.format(new Date(startDate), 'DD/MM/YYYY lúc HH:mm')}</Typography>
          <Typography mt={1}>{__("time_line_end")} {DateTime.format(new Date(endDate), 'DD/MM/YYYY lúc HH:mm')}</Typography>
          <Typography mt={1}>{__('challenge_leg_information_race')}:</Typography>

          {DetailTimelineComponent
            ? DetailTimelineComponent
            :
            detail.map(d => (
              <Typography mt={1} key={d?.description} className="mb-2 rounded-3">
                {d.description}
              </Typography>
            ))
          }
        </Box>
      </Box>
    </TimelineChallengeContainer>
  )
}

export default TimelineChallenge

const TimelineChallengeContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),

  '& .title': {
    position: 'relative',

    '& .circle_icon': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      border: '1px solid #DC3545'
    },

    '& .btn_remove': {
      width: '44px',
      height: '44px',
      borderColor: '#E0E2E6',
      color: '#000',
      padding: 0,
      position: 'absolute',
      top: 0,
      right: 0

    }
  },

  '& .info_race': {
    borderLeft: '1px solid #E0E2E6',
    margin: theme.spacing(2, 0, 2, 1.2)
  }
}))
