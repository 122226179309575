//Library
import {memo, useCallback, useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getListUserSubscription} from 'entities/orders/order.store.reducer'

//Interface
import {TypedServiceId} from 'interfaces/channel.interface'
import __ from "languages/index";

//Helpers
import helpers from 'helpers'

interface TypeStateDataSubscription {
  is_trial: boolean
  expDate: string
  service_name: string
}

const SubscriptionHeader = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const channel = useAppSelector(state => state.user.channel_data);
  const current_user_id = useAppSelector(state => state.user.current_user_id);
  const service_id = channel?.service_id

  const [dataSubscription, setDataSubscription] = useState<TypeStateDataSubscription>({
    is_trial: false,
    expDate: '',
    service_name: ''
  })

  const expirationDate = useMemo(() => {
    const currentDate: Date = new Date()
    const expiredDate: Date = new Date(dataSubscription?.expDate)
    return Math.floor((expiredDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24))
  }, [dataSubscription])

  const handleClickToPayment = useCallback(() => {
    navigate(
      `/payment?service_id=${service_id?.find(
        (item: TypedServiceId) => item?.router_link?.includes(location.pathname)
      )?._id}`
    )
  }, [location, service_id])

  useEffect(() => {
    const checkServiceId = service_id?.find((item: TypedServiceId) => item?.router_link === `${location.pathname}`)?._id
    if (!checkServiceId || helpers.isEmpty(service_id)) return setDataSubscription({ is_trial: false, expDate: '', service_name: '' })

    dispatch(
      getListUserSubscription({
        params: {
          page: 1,
          limit: 1,
          order_by: 'DESC',
          service_id: service_id?.find(
            (item: TypedServiceId) => item?.router_link?.includes(location.pathname)
          )?._id
        },
        id: current_user_id
      })
    )
      .unwrap()
      .then(res => {
        setDataSubscription({
          is_trial: res?.data[0]?.is_trial,
          expDate: res?.data[0]?.expDate,
          service_name: res?.data[0]?.service_id?.title
        })
      })
      .catch(error => {
        console.log(error)
        setDataSubscription({ is_trial: false, expDate: '', service_name: '' })
      })
  }, [service_id, location])

  return (
    <>
      {dataSubscription?.is_trial && (
        <div
          className="d-flex z-3 position-sticky p-1 ps-3 fs-6"
          style={{ backgroundColor: '#ffeef0', top: '83px' }}
        >
          <span
            className="text-primary text-decoration-underline"
            onClick={handleClickToPayment}
            role="button"
          >
            {expirationDate && (
              <>
                {__("subscription_feature")} <b className="text-capitalize">{dataSubscription?.service_name}</b>
                {`${__("subscription_feature_expire")} ${expirationDate} ${__("subscription_feature_upgrade_now")}`}
              </>
            )}
            {!expirationDate && (
              <>
                {__("subscription_feature")} <b className="text-capitalize">{dataSubscription?.service_name}</b>
                {__("subscription_feature_expire_today")}
              </>
            )}
            {expirationDate < 0 && (
              <>
                {__("subscription_feature")} <b className="text-capitalize">{dataSubscription?.service_name}</b>
                {__("subscription_feature_expired")}
              </>
            )}
          </span>
        </div>
      )}
    </>
  )
}

export default memo(SubscriptionHeader)
