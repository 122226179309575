//Library
import { Box, Button, IconButton, Paper, Typography, styled } from '@mui/material'
import __ from 'languages/index'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'

//Image
import imageDefault from 'media/images/image_default.png'

//Icons
import { ArrowRightIcon } from 'icons'
import { TypedCategory } from '../interface'
import { useNavigate } from 'react-router-dom'

const Category = ({ categoryData }: { categoryData: TypedCategory[] }) => {
  const myBlockItemRef = useRef(null)
  const myBlockRef = useRef(null)

  const [perView, setPerView] = useState<number>(6)
  const [isShowNextCategory, setIsShowNextCategory] = useState<boolean>(true)
  const [isShowPrevCategory, setIsShowPrevCategory] = useState<boolean>(false)
  const  navigate = useNavigate()
  const ButtonNext = () => {
    const swiper = useSwiper()
    useEffect(() => {
      setIsShowNextCategory(!swiper?.isEnd || false)
    }, [swiper?.isEnd])
    return (
      <IconButton
        onClick={() => {
          setIsShowPrevCategory(true)
          typeof swiper.slideNext() === 'function' && swiper.slideNext()
        }}
        sx={{
          color: theme => theme.palette.primary.dark,
          ':hover': {
            background: theme => theme.palette.primary.backgroundHover
          }
        }}
        className="button button_next"
      >
        <ArrowRightIcon color="inherit" />
      </IconButton>
    )
  }

  const ButtonPrev = () => {
    const swiper = useSwiper()
    return (
      <IconButton
        onClick={() => {
          setIsShowNextCategory(true)
          typeof swiper.slidePrev() === 'function' && swiper.slidePrev()
        }}
        sx={{
          color: theme => theme.palette.primary.dark,
          ':hover': {
            background: theme => theme.palette.primary.backgroundHover
          }
        }}
        className="button button_prev"
      >
        <ArrowRightIcon sx={{ transform: 'rotate(180deg)' }} color="inherit" />
      </IconButton>
    )
  }

  const handlePerViewCategory = useCallback(() => {
    const widthBlock = myBlockRef?.current?.clientWidth
    const widthBlockItem = myBlockItemRef?.current?.clientWidth + 18
    setPerView(widthBlock / widthBlockItem)
  }, [myBlockItemRef, myBlockRef])

  useEffect(() => {
    window.addEventListener('resize', () => handlePerViewCategory())
    return () => {
      window.removeEventListener('resize', () => handlePerViewCategory())
    }
  }, [])

  useEffect(() => {
    handlePerViewCategory()
  }, [])

  const handleClickCategory = (category_id) =>{
    navigate(`/shop/search?product_to_category.category_id=${category_id}&&sort=product_meta.product_review_count:desc`)
  }

  return (
    <CategoryContainer>
      <Typography textTransform={'none'} variant="h6">
        {__('category')}
      </Typography>
      <Box ref={myBlockRef} className="list_wrapper">
        <Swiper
          onReachEnd={() => setIsShowNextCategory(false)}
          onReachBeginning={() => setIsShowPrevCategory(false)}
          onSlideChange={e => {
            setIsShowNextCategory(!e?.isEnd)
            setIsShowPrevCategory(!e?.isBeginning)
          }}
          slidesPerView={perView}
          watchSlidesProgress={true}
          navigation={false}
          spaceBetween={18}
          modules={[FreeMode, Navigation]}
        >
          {categoryData?.map((item: any, idx: number) => (
            <SwiperSlide key={`item_${idx}`}>
              <Button onClick={()=>handleClickCategory(item?.category_id)}
                      ref={myBlockItemRef} variant="text"
                      sx={{ p: 0 }}
              >
                <Paper className="item">
                  <Box className="image">
                    <img
                      src={item?.category_thumbnail || imageDefault}
                      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                        e.currentTarget.src = imageDefault
                      }}
                      alt="image"
                    />
                  </Box>
                  <Typography textTransform={'none'} variant="body2">
                    {__(`${item?.category_name}`)}
                  </Typography>
                </Paper>
              </Button>
            </SwiperSlide>
          ))}
          {isShowNextCategory && <ButtonNext />}
          {isShowPrevCategory && <ButtonPrev />}
        </Swiper>
      </Box>
    </CategoryContainer>
  )
}

export default Category

const CategoryContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.25, 0),
  display: 'grid',
  rowGap: theme.spacing(1.25),
  '& .button': {
    height: 'auto',
    position: 'absolute',
    top: '50%',
    transform: 'translate(50%, -50%)',
    zIndex: 1,
    borderRadius: theme.spacing(1.5),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`
  },
  '& .button_next': {
    right: theme.spacing(2.875)
  },
  '& .button_prev': {
    left: theme.spacing(-2.5)
  },
  '& .list_wrapper': {
    padding: theme.spacing(1.25, 0),
    width: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  '& .list': {
    display: 'flex',
    columnGap: theme.spacing(3)
  },
  '& .item': {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    justifyContent: 'center',
    rowGap: theme.spacing(2),
    maxWidth: theme.spacing(16.75),
    maxHeight: theme.spacing(21.25),
    padding: theme.spacing(4.125, 0),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .image ': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 4),
    '& img': {
      width: theme.spacing(8.75),
      height: theme.spacing(8.75),
      objectFit: 'contain'
    }
  }
}))
