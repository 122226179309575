import { createRef } from 'react'

import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import SettingMission from './mission.setting'
import AddMission from './components/mission.add'
import ToastComponent from 'entities/courses/components/toast.component'

export default function Mission() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'setting-mission'
  let ActualPage: any

  switch (Param) {
    case 'setting-mission':
      ActualPage = SettingMission
      break
    case 'add':
      ActualPage = AddMission
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="mainFrame">
      <div className="">
        {<ActualPage />}
      </div>
    </div>
  )
}
