import { Button, Menu, MenuItem, styled, Typography } from "@mui/material"
import { EditIcon, MenusIcon, MessageWritingIcon } from 'icons'
import CancelOrderIcon from "icons/CancelOrderIcon";
import EditIcon2 from "icons/EditIcon2";
import PrintIcon from "icons/PrintIcon";
import __ from "languages/index";
import { useEffect, useState } from "react";
import { OrderStatus } from "../data/order.enum";
import { useNavigate } from 'react-router-dom'

interface TypedProps {
    handleUpdateStatusOrder: (idOrder: string, value: OrderStatus) => void;
    handlePrintBillOrder: (value) => void;
    status: OrderStatus;
    idOrder: string;
}


const ActionMenu = ({ handlePrintBillOrder, handleUpdateStatusOrder, status, idOrder }: TypedProps) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (key: string) => {

        switch (key) {
            case "update":
                handleUpdateStatusOrder(idOrder, getNextStatusOrder(status))
                break;
            case "print":
                handlePrintBillOrder(idOrder)
                break;
            case "cancel":
                handleUpdateStatusOrder(idOrder, OrderStatus.Cancelled)
                break;
            case 'seen':
                navigate(`/shop/order-detail-manager/${idOrder}`)
                break;
            default:
                break;
        }

        handleClose()
    }

    const getNextStatusOrder = (status: OrderStatus) =>{
        let nextStatus: OrderStatus
        switch(status){
            case OrderStatus.Pending || OrderStatus.Cancelled:
                nextStatus = OrderStatus.AwaitPickup
                break;
            case  OrderStatus.Cancelled:
                nextStatus = OrderStatus.AwaitPickup
                break;
            case OrderStatus.AwaitPickup:
                nextStatus = OrderStatus.AwaitDelivery
                break;
            case OrderStatus.AwaitDelivery:
                nextStatus = OrderStatus.Delivered;
                break;
            default:
                break;
        }

        return nextStatus
    }



    const optionMenu = [
        {
            icon: <EditIcon2 />,
            label: status === OrderStatus.Cancelled ? __(`order_update_status_draft`) : __(`order_update_status_${status}`),
            key: "update"
        },
        {
            icon: <PrintIcon />,
            label: __('order_print_bill'),
            key: "print"
        },
        {
            icon: <CancelOrderIcon />,
            label: __('shop_order_btn_cancel'),
            key: "cancel"
        },
        {
          icon: <MessageWritingIcon sx={{ color: theme => theme.palette.text.primary }} />,
          label: __('shop_order_btn_detail'),
          key: "seen"
        }
    ]

    const renderMenuItem = () => {
        let optionFilter = optionMenu;
        if (status === OrderStatus.Cancelled) {
            optionFilter = optionMenu.filter(item => item.key === "seen")
        }

        if (status === OrderStatus.ReturnInitated || status === OrderStatus.Delivered)  {
            optionFilter = optionMenu.filter(item => item.key !== "cancel" && item.key !== "update")
        }
        return optionFilter.map((res, index) => (
            <MenuItem key={index} onClick={() => handleClickMenu(res.key)} >
                {res.icon}
                <Typography sx={{
                    paddingLeft: theme => theme.spacing(2)
                }} className="action_name">
                    {res.label}
                </Typography>
            </MenuItem>
        ))
    }


    return (
        <>
            <ButtonContainer  onClick={handleClick} className="table_action" variant="outlined">
                <MenusIcon />
            </ButtonContainer>

            <Menu
                elevation={4}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClose}
            >
                {
                    renderMenuItem()
                }

            </Menu>
        </>

    )
}

const ButtonContainer = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.divider,
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    minWidth: 'unset',
    transform: 'rotate(90deg)',
    padding: 0,
    boxShadow: "0px 8px 40px 0px rgba(14, 20, 38, 0.08)",
    color: 'black'
}))

export default ActionMenu
