import '../media/gift.receiver.item.scss'
import React, {useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  EnumGiftSendStatusType,
  GiftSendStatusNextStep,
  GiftSendStatusTitle
} from "entities/gift/constants/gift.constant";
import {Dropdown} from "react-bootstrap";
import {faEllipsis, faMedal} from "@fortawesome/free-solid-svg-icons";
import {TypedGift, TypedGiftTransaction} from "entities/gift/gift.interface";
import {updateStatusSendGift} from "entities/gift/services/gift.receiver.service";
import __helpers from "helpers/index";
import DateTime from 'date-and-time'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

interface TypeGiftReceiverTable {
  item: TypedGiftTransaction;
  isMine: boolean;
  hasPermissionBoss: boolean;
  onRemoveItem: (id: string) => void;
  showDetailGift: (gift: TypedGift) => void;
  index: number;
}
const GiftReceiverItiemTable: React.FC<TypeGiftReceiverTable> = ({ item, showDetailGift, onRemoveItem, hasPermissionBoss, isMine, index }) => {
  const [statusSend, setStatusSend] = useState(item.gift_status || EnumGiftSendStatusType.Prepare)
  const toast = useToast()

  const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <a
      href=""
      ref={ref}
      className="px-1 d-flex justify-content-center"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <FontAwesomeIcon icon={faEllipsis} className="giftreceiveritem_iconmenu" />
      {children}
    </a>
  ));

  const onChangeStatus = useCallback(async (newStatus: EnumGiftSendStatusType) => {
    let isUpdateSuccess = await updateStatusSendGift(newStatus, item?._id)
    if (isUpdateSuccess) {
      setStatusSend(newStatus)
      setTimeout(() => {
        onRemoveItem(item._id)
      }, 1500)

      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Success,
        content: `${__("gift_update_status_success")}`,
      })
    } else {
      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Error,
        content: `${__("gift_update_status_failed")}`,
      })
    }
  }, [])

  return (
    <tr className="giftreceiveritem col-12">
      <td className='text-center'>{index + 1}</td>
      <td className="">
        <Link to={`/user/detail/${item?.user_id?._id}`}
          className="giftreceiveritem_container_user text-decoration-none">
          <img src={item?.user_id?.user_avatar_thumbnail || item?.user_id?.user_avatar}
            className="giftreceiveritem_avatar" />

          <div className="giftreceiveritem_container_user_info">
            <div className="giftreceiveritem_container_user_txtname text-break">{item?.user_id?.display_name}</div>
            <div className="d-flex align-items-center giftreceiveritem_container_user_txtlvl">
              {/*<FontAwesomeIcon icon={faArrowUp91} style={{ marginRight: 5 }} />*/}
              {/*{item?.user_id?.point} {__("point_unit")}*/}
              {/*<div style={{ width: 20 }} />*/}
              <FontAwesomeIcon icon={faMedal} style={{ marginRight: 5 }} />
              {__("gift_member_level")} {item?.user_id?.level_number}
            </div>
          </div>
        </Link>
      </td>
      <td className="align-middle">
        <div className="giftreceiveritem_container_gift_table clickable" onClick={() => showDetailGift(item?.gift_id)}>
          <div className="giftreceiveritem_container_user_txtnamegift text-break">{item?.gift_id?.name}</div>
          {hasPermissionBoss || isMine ?
            <div className="d-flex align-items-center justify-content-end" style={{ width: 'max-content' }}>
              <div className={`giftreceiveritem_container_user_txtStatus ${statusSend}`}>
                {__(GiftSendStatusTitle[statusSend])}
              </div>
            </div> : null}
        </div>
      </td>
      <td className="align-middle">
        <div className="giftreceiveritem_container_user_txtPrice ">
          {`${__helpers.formatNumberWithCommas(item?.gift_id?.price)} ${__("currency_unit")}`}
        </div>
      </td>
      <td className="align-middle">
        <div className="giftreceiveritem_container_user_txtTime text-lg-end">{(hasPermissionBoss ?  `${__("gift_receiver_update")}` : "") + DateTime.format(new Date(item.createdAt), 'HH:mm DD/MM/YYYY')}</div>
      </td>
      <td><img src={item?.gift_id?.media_id?.media_url} className="giftreceiveritem_imgproduct" /></td>

      {
        hasPermissionBoss && statusSend !== EnumGiftSendStatusType.Cancel && statusSend !== EnumGiftSendStatusType.Received ?
          <Dropdown className="giftreceiveritem_optionmenu">
            <Dropdown.Toggle variant="success" id="dropdown-basic" as={CustomToggle} />

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => onChangeStatus(GiftSendStatusNextStep[statusSend])}>{__(GiftSendStatusTitle[GiftSendStatusNextStep[statusSend]])}</Dropdown.Item>
              <Dropdown.Item onClick={() => onChangeStatus(EnumGiftSendStatusType.Cancel)} className="cancel_gift">{__("gift_cancel")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          : null
      }
    </tr>
  )
}
export default GiftReceiverItiemTable
