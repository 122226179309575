import React, { useState } from 'react'
import '../media/audio-progress.scss'

type Props = {
  audioRef: React.MutableRefObject<HTMLAudioElement>
  onChange: (value: number) => void
  className?: string
}

const AudioProgress = ({ audioRef, className, onChange }: Props) => {
  const max = audioRef.current.duration | 0

  const [currentTime, setCurrentTime] = useState(audioRef.current.currentTime)
  audioRef.current.ontimeupdate = () => {
    setCurrentTime(audioRef.current?.currentTime)
  }

  const handleChange = (value: number) => {
    audioRef.current.currentTime = value
    onChange(value)
  }

  return (
    <input
      className={className}
      type="range"
      value={Math.round(currentTime)}
      max={Math.round(max)}
      onChange={event => handleChange(+event.target.value)}
    />
  )
}

export default AudioProgress
