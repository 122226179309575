import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';

import example_list from './example.list';
import example_view from './example.view';

/**
*   Create index file for Comment
*/

export default function Example() {
  let useParam = {} as any;
    useParam = useParams();
  let Param = useParam.slug || 'list';

  let ActualPage: any;
  switch (Param) {

    case 'list':
      ActualPage = example_list;
      break;

    case 'view':
      ActualPage = example_view;
      break;

    default:
      ActualPage = Theme404;
      break;
  }
  return (
    <>
      {<ActualPage />}
    </>
  );
}
