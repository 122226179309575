import { Link } from 'react-router-dom'
import { Box, Button, Paper, Rating, styled, Typography } from '@mui/material'

import __, { ___ } from 'languages/index'
import helpers from 'helpers'
import { ShoppingBasketPlusIcon } from 'icons'
import imageDefault from 'media/images/image_default.png'
import { TypedProduct } from '../interface'
import { formatString } from 'entities/support/utils'
import { useAppDispatch } from 'config/store'
import { addItemToCart } from 'entities/shop/store/cart/cart.slice'
import { productSoldQuantity } from '../helper'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { AxiosError } from 'axios'

const CardItem = ({ productData }: { productData: TypedProduct }) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  
  const handleAddToCart = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    dispatch(addItemToCart({ product: productData, item_quantity: 1 }))
    .unwrap()
    .then(()=>{
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__('add_to_cart_success')}`
      })
    })
    .catch((error: AxiosError)=>{
      console.log('addItemToCart Error',error);
      toast.show({
        type: EnumTypeToast.Error,
        content: `${error}`
      })
    })
  }
  
  return (
    <CardItemContainer>
      <Box component={Link} to={productData?.product_status ? `/shop/product/${productData?.product_id}` : '.'} sx={{
        textDecoration: 'none',
        cursor: productData?.product_status ? 'pointer' : 'default'
      }}>
        <Box display={'grid'} justifyItems={'center'} rowGap={theme => theme.spacing(1.5)}>
          <Box position={'relative'} className="card_image">
            <img
              src={productData?.product_avatar?.[0] || imageDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = imageDefault
              }}
              alt="image"
            />
            {productSoldQuantity(productData)?.totalProduct === 0 && productData?.product_status && (
              <Paper className="sold_out">
                <Typography color="background.paper">{__('sold_out')}</Typography>
              </Paper>
            )}
            {!productData?.product_status && (
              <Paper className="sold_out">
                <Typography sx={{textAlign: 'center'}} color="background.paper">{__('not_display')}</Typography>
              </Paper>
            )}
          </Box>
          <Box
            display={'grid'}
            alignItems={'center'}
            padding={theme => theme.spacing(0, 3)}
            height={theme => theme.spacing(5.625)}
          >
            <Typography textTransform={'none'} className="product_name" variant="subtitle2">
              {productData?.product_name || ''}
            </Typography>
          </Box>
          <Box
            minHeight={theme => theme.spacing(6.25)}
            display={'grid'}
            justifyItems={'center'}
            alignItems={'center'}
          >
            <Typography variant="h6" color={'primary.main'} fontWeight={700}>
              {`${helpers.formatNumberWithCommas(productData?.product_price || '0')} VND`}
            </Typography>
            {Number(productData?.product_original_price) > Number(productData?.product_price) && (
              <Typography
                color={'text.secondary'}
                sx={{ textDecorationLine: 'line-through' }}
                fontWeight={400}
              >
                {`${helpers.formatNumberWithCommas(productData?.product_original_price || '0')} VND`}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          width={'100%'}
          boxSizing={'border-box'}
          p={theme => theme.spacing(0, 3)}
        >
          <Rating
            name="read-only"
            value={productData?.product_meta?.product_review_point || 0}
            precision={0.5}
            readOnly
          />
          <Typography variant="body2" color="text.secondary" fontWeight={700}>
            {formatString(
              __('number_sold'),
              helpers.convertNumber(productSoldQuantity(productData)?.productSold || '0')
            )}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: theme => theme.spacing(4.5),
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button
          disabled={productSoldQuantity(productData)?.totalProduct === 0 || !productData?.product_status}
          onClick={handleAddToCart}
          startIcon={<ShoppingBasketPlusIcon sx={{ width: '100%', height: '100%' }} />}
        >
          <Typography variant="body2" textTransform={'none'}>
            {__('add_to_cart')}
          </Typography>
        </Button>
      </Box>
    </CardItemContainer>
  )
}

export default CardItem

const CardItemContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3, 0),
  border: `1px solid ${theme.palette.divider}`,
  '& a': {
    display: 'grid',
    rowGap: theme.spacing(1.5)
  },
  '& .product_name': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  '& .card_image': {
    '& img': {
      width: theme.spacing(18.75),
      height: theme.spacing(18.75),
      borderRadius: theme.spacing(1.5),
      objectFit: 'cover'
    },
    '& .sold_out': {
      width: theme.spacing(12.5),
      height: theme.spacing(12.5),
      borderRadius: '50%',
      display: 'grid',
      placeItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'rgb(98 111 130 / 37%)'
    }
  }
}))
