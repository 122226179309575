import React, { createRef, useRef, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Paper,
  Stack,
  styled,
  SxProps,
  Typography
} from '@mui/material'
import { CloseIcon, FileIcon, PhotoIcon, PollIcon } from 'icons'
import { CurrentTheme } from '../styles/themes'
import AvatarCurrentUser from 'components/AvatarCurrentUser'
import ModalCreateEditPost from 'components/ModalCreateEditPost'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import { useAddPostMutation } from 'entities/homeNew/store/homeApi'
import { useAppSelector } from 'config/store'
import { getFetchingParams } from 'entities/homeNew/store/homeSlice'
import { TypedRequest } from '../interfaces/request.interface'
import __ from 'languages/index'
import { EnumPostType } from '../interfaces/media.interface'
import helpers from 'helpers'
import EventRightBarComponent from 'entities/homeNew/components/EventRightBarComponent'
import ArrowChevronLeftIcon from 'icons/ArrowChevronLeftIcon'
import { useNavigate } from 'react-router-dom'
interface TypedPostFormProps {
  refresh?: (isBackground?: boolean) => void
  variant?: 'small' | 'medium',
  getListApprovalInHome?: () => void
}

const PostForm = ({ variant = 'medium', refresh, getListApprovalInHome}: TypedPostFormProps) => {
  const currentParams = useAppSelector(getFetchingParams)
  const user_data = useAppSelector(state => state.user.user_data)
  const [postType, setPostType] = useState<EnumPostType | null>(undefined)
  const [createPost, { isLoading: creatingPost }] = useAddPostMutation()
  const navigate = useNavigate();
  const postRef = useRef<{
      handleSubmitForm: () => Promise<void>;
      postTypeActive: boolean
  }>()
  const checkMobile = helpers.isMobile();
  const handleOpenModal = (type: EnumPostType) => {
    if (helpers.isEmpty(user_data)) {
      navigate('/login')
    }else{
      setPostType(type)
    }
  }
  const [isCheckButtonPost, setIsCheckButtonPost] = useState<Partial<TypedRequest>>({})

  const handleCloseModal = () => {
    setPostType(undefined)
  }

  const handleCreatePost = async (
    createPostPayload: Partial<TypedRequest>
  ): Promise<TypedRequest> => {
    try {
      let newPost = await createPost({
        payload: {
          ...createPostPayload,
          post_category: createPostPayload?.post_category?._id || '',
          post_language: 'vi',
          attach_files:
            createPostPayload?.attach_files &&
            JSON.stringify(createPostPayload.attach_files.map(item => item._id))
        },
        currentFetchParams: currentParams
      }).unwrap()

      handleCloseModal()
      return newPost
    } catch (err) {
      console.log(err)
    }
    return null
  }
  return (
    <>
      <Paper component="form" elevation={5}>
        <Stack
          direction="row"
          gap={1}
          sx={{
            padding: theme => checkMobile ? theme.spacing(3, 3, 1, 3) :  (variant === 'small' ? theme.spacing(1) : theme.spacing(3, 3, 2, 3))
          }}
        >
          <AvatarCurrentUser
            sx={{
              width: theme => theme.spacing(5.5),
              height: theme => theme.spacing(5.5)
            }}
          />
          <Stack width={'100%'}>
            <Box
              onClick={() => handleOpenModal(null)}
              sx={{
                backgroundColor: theme => theme.palette.background.secondary,
                borderRadius: theme => theme.spacing(1.5),
                padding: theme => theme.spacing(2),
                color: theme => theme.palette.text.disabled
              }}
            >
              <Typography>{__('home_create_post_text')}</Typography>
            </Box>
            {
              !checkMobile && (
                <Stack
                  direction="row"
                  alignItems={'center'}
                  mt={1}
                  justifyContent={'space-between'}
                  gap={0.5}
                >
                  <Button
                    variant="text"
                    sx={sxButton}
                    onClick={() => handleOpenModal(EnumPostType.Media)}
                  >
                    <PhotoIcon color="error" />
                    {variant === 'medium' && (
                      <Typography ml={1} fontWeight={600} noWrap>
                        {__('home_image')}
                      </Typography>
                    )}
                  </Button>
                  <Button
                    variant="text"
                    sx={sxButton}
                    onClick={() => handleOpenModal(EnumPostType.File)}
                  >
                    <FileIcon color="info" />
                    {variant === 'medium' && (
                      <Typography ml={1} fontWeight={600} noWrap>
                        {__('home_file')}
                      </Typography>
                    )}
                  </Button>
                  <Button
                    variant="text"
                    sx={sxButton}
                    onClick={() => handleOpenModal(EnumPostType.Poll)}
                  >
                    <PollIcon color="success" />
                    {variant === 'medium' && (
                      <Typography ml={1} fontWeight={600} noWrap>
                        {__('home_voted')}
                      </Typography>
                    )}
                  </Button>
                </Stack>
              )
            }
          </Stack>
            {
              checkMobile && (
                <Button
                    variant="text"
                    sx={sxButton}
                    onClick={() => handleOpenModal(EnumPostType.Media)}
                  >
                    <PhotoIcon color="error" />
                    {checkMobile ? '' : variant === 'medium' && (
                      <Typography ml={1} fontWeight={600} noWrap>
                        {__('home_image')}
                      </Typography>
                    )}
                </Button>
              )
            }
        </Stack>
        {
          checkMobile && (
            <Box>
              <EventRightBarComponent />
            </Box>
          )
        }
      </Paper>

      {
        checkMobile ? (
          <DrawerContainer
            PaperProps={{
              sx: {
                width: '100%',
                borderRadius: '0'
              }
            }}
            elevation={8}
            onClose={handleCloseModal}
            anchor="right"
            open={!(postType === undefined)}
          >

            <Stack direction={'row'} padding={'16px 0'} alignItems='center' borderBottom={'0.5px solid var(--Other-Line, #E0E2E6)'}>
              <IconButton onClick={handleCloseModal}>
                <ArrowChevronLeftIcon />
              </IconButton>
              <Typography textTransform={'none'} fontSize='20px' flex={1} textAlign='center' variant={'h6'} className={'title'}>
                {__('home_create_post')}
              </Typography>
              <Button
                color={'primary'}
                disabled={creatingPost || !isCheckButtonPost?.post_title || !isCheckButtonPost?.post_content}
                variant="text" onClick={postRef.current?.handleSubmitForm}  >
                {/*<Typography  fontWeight={600} fontSize='16px'>*/}
                {/*  Đăng*/}
                {/*</Typography>*/}
              </Button>
            </Stack>

            <ModalCreateEditPost
              ref={postRef}
              refresh={refresh}
              setIsCheckButtonPost={setIsCheckButtonPost}
              getListApprovalInHome={getListApprovalInHome}
              postTypeInit={postType}
              onSubmit={handleCreatePost}
              isSubmitting={creatingPost}
            />
          </DrawerContainer>
        ) : (
          <>
            {/* start modal post */}
            <Modal open={!(postType === undefined)} onClose={handleCloseModal}>
              <ModalContainer
                sx={{
                  width: '80%',
                  maxWidth: theme => theme.spacing(95.75),
                  margin: '0 auto'
                }}
              >
                <ModalHeader direction={'row'}>
                  <Typography textTransform={'none'} variant={'h6'} className={'title'}>
                    {__('home_create_post')}
                  </Typography>
                  <IconButton onClick={handleCloseModal}>
                    <CloseIcon />
                  </IconButton>
                </ModalHeader>
                <Divider />
                <ModalBody
                  sx={{
                    maxHeight: 'calc(100% - 80px)',
                    overflowY: 'auto'
                  }}
                >
                  <ModalCreateEditPost
                    refresh={refresh}
                    getListApprovalInHome={getListApprovalInHome}
                    postTypeInit={postType}
                    onSubmit={handleCreatePost}
                    isSubmitting={creatingPost}
                  />
                </ModalBody>
              </ModalContainer>
            </Modal>
            {/* end modal post */}
          </>
        )
      }



    </>
  )
}

export default PostForm

const modalBodyStyled: SxProps<CurrentTheme> = {
  overflow: 'scroll'
}
const sxButton: SxProps = {
  padding: 0,
  color: 'text.primary',
  textTransform: 'unset',
  minWidth: 'auto'
}

const DrawerContainer = styled(Drawer)(({ theme }) => ({
  '& .form-add-comment': {
      position: 'sticky',
      bottom: 0,
      width: '100%',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  }
}))
