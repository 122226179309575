import ChallengeDetailComponent from "./component/challenge.detail/challenge.detail.component";
import __ from "languages/index";
import _Helmet from "components/helmet";
import MaxLayout from "../../layouts/maxLayout";

const ChallengeDetail = (props) => {

  return (
    <>
      <_Helmet title='challenge_detail_title' />
      <div className="mainFrame">
          <ChallengeDetailComponent />
      </div>
    </>
  )
}

export default ChallengeDetail
