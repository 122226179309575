import { SvgIcon, SvgIconProps } from '@mui/material'

const InfomationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5007 2.49967C6.35851 2.49967 3.00065 5.85754 3.00065 9.99967C3.00065 14.1418 6.35851 17.4997 10.5007 17.4997C14.6428 17.4997 18.0006 14.1418 18.0006 9.99967C18.0006 5.85754 14.6428 2.49967 10.5007 2.49967ZM1.33398 9.99967C1.33398 4.93706 5.43804 0.833008 10.5007 0.833008C15.5633 0.833008 19.6673 4.93706 19.6673 9.99967C19.6673 15.0623 15.5633 19.1663 10.5007 19.1663C5.43804 19.1663 1.33398 15.0623 1.33398 9.99967ZM10.5007 5.83281C10.9609 5.83281 11.334 6.20591 11.334 6.66615V6.67448C11.334 7.13472 10.9609 7.50781 10.5007 7.50781C10.0404 7.50781 9.66732 7.13472 9.66732 6.67448V6.66615C9.66732 6.20591 10.0404 5.83281 10.5007 5.83281ZM10.5007 8.34115C10.9609 8.34115 11.334 8.71424 11.334 9.17448V13.3411C11.334 13.8014 10.9609 14.1745 10.5007 14.1745C10.0404 14.1745 9.66732 13.8014 9.66732 13.3411V9.17448C9.66732 8.71424 10.0404 8.34115 10.5007 8.34115Z" fill="white" />
      </svg>

    </SvgIcon>
  )
}

export default InfomationIcon
