import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowChevronLeftIcon =(props: SvgIconProps)=>{
  return(
    <SvgIcon {...props} >
      <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.85943 9.13823C4.59908 9.39858 4.17697 9.39858 3.91662 9.13823L0.388021 5.60964C-0.132678 5.08894 -0.132678 4.24472 0.388021 3.72402L3.91662 0.195425C4.17697 -0.0649252 4.59908 -0.0649252 4.85943 0.195425C5.11977 0.455774 5.11977 0.877884 4.85943 1.13823L1.33083 4.66683L4.85943 8.19542C5.11977 8.45577 5.11977 8.87788 4.85943 9.13823Z"
              fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
export default ArrowChevronLeftIcon
