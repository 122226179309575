import { Box, Button, IconButton, Modal, Stack, styled, Typography } from '@mui/material'
import __ from 'languages/index'
import StrokeIcon from '../../../icons/StrokeIcon'
import React, { useEffect } from 'react'
import PaymentEditInfoModal from 'entities/shop/components/PaymentEditInfoModal'
import helpers from 'helpers/index'
import ListAddress from 'entities/shop/components/ListAddress'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { CirclePlusIcon, CloseIcon } from '../../../icons'
import { TypedAddressBook } from '../interface'

const ShopPaymentAddress = ({
  addressData,
  handleUpdateDefaultAddress,
  handleDeleteAddress,
  handleCreateAddress
}: {
  addressData: TypedAddressBook[]
  handleUpdateDefaultAddress: Function
  handleDeleteAddress: Function
  handleCreateAddress: Function
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [showEditModal, setShowEditModal] = React.useState(false)
  const [showListAddress, setShowListAddress] = React.useState(false)
  const [defaultAddress, setDefaultAddress] = React.useState<string>('')

  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }
  const handleOpenEditModal = () => {
    setShowEditModal(true)
  }

  const openListAddressModal = () => {
    setShowListAddress(true)
  }
  const closeListAddressModal = () => {
    setShowListAddress(false)
    setDefaultAddress(addressData?.[0]?.address_id)
  }

  const handleChangeAddress = async () => {
    handleUpdateDefaultAddress(defaultAddress)
    setIsLoading(true)
    await helpers.sleep(500)
    setIsLoading(false)
    setShowListAddress(false)
  }

  useEffect(() => {
    if (helpers.isEmpty(addressData)) return
    setDefaultAddress(addressData?.[0]?.address_id)
  }, [addressData])

  return (
    <ShopPaymentAddressContainer>
      <Box>
        <Typography variant="subtitle2" className="address_sub_title">
          <StrokeIcon
            color="inherit"
            sx={{ width: 'auto', height: 'auto', marginRight: theme => theme.spacing(1.25) }}
          />
          {__('shop_payment_address_sub_title')}
        </Typography>
        {helpers.isEmpty(addressData) ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Button variant={'text'} startIcon={<CirclePlusIcon />} onClick={handleOpenEditModal}>
              {__('btn_add_address')}
            </Button>
          </Box>
        ) : (
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            className="address_content"
          >
            <Stack direction="column" justifyContent="center" alignItems="flex-start">
              <Typography>{addressData?.[0]?.user_display_name || ''}</Typography>
              <Typography>{addressData?.[0]?.user_phonenumber || ''}</Typography>
            </Stack>

            <Typography>{`${addressData?.[0]?.user_address || ''}, ${
              addressData?.[0]?.user_address_ward_name || ''
            }, ${addressData?.[0]?.user_address_district_name || ''}, ${
              addressData?.[0]?.user_address_city_name || ''
            }`}</Typography>
            <Button variant="text" color={'info'} onClick={openListAddressModal}>
              {__('shop_payment_address_btn_change')}
            </Button>
          </Stack>
        )}
        {showEditModal && (
          <PaymentEditInfoModal
            showEditModal={showEditModal}
            handleCloseEditModal={handleCloseEditModal}
            handleCreateAddress={handleCreateAddress}
          />
        )}
      </Box>

      <Modal open={showListAddress} sx={{ zIndex: 1 }}>
        <ModalContainer>
          <ModalHeader
            direction={'row'}
            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
          >
            <Typography textTransform={'none'} variant={'h6'} className={'title'}>
              {__('txt_my_address')}
            </Typography>
            <IconButton onClick={closeListAddressModal}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <ModalBody
            sx={{
              p: theme => theme.spacing(0, 3),
              maxHeight: theme => `calc(100vh - ${theme.spacing(37.5)})`,
              overflow: 'auto'
            }}
          >
            <ListAddress
              list={addressData}
              defaultAddress={defaultAddress}
              setDefaultAddress={setDefaultAddress}
              handleDeleteAddress={handleDeleteAddress}
            />
            <Button variant={'text'} startIcon={<CirclePlusIcon />} onClick={handleOpenEditModal}>
              {__('btn_add_address')}
            </Button>
          </ModalBody>
          <ModalFooter direction={'row'} gap={2}>
            <Button variant={'outlined'} onClick={closeListAddressModal}>
              {__('btn_cancel')}
            </Button>
            <Button onClick={handleChangeAddress}>
              {isLoading ? __('btn_processing') : __('btn_confirm')}
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </ShopPaymentAddressContainer>
  )
}

export default ShopPaymentAddress
const ShopPaymentAddressContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5, 0),
  borderRadius: theme.spacing(1.25),
  background: theme.palette.background.paper,
  boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
  padding: theme.spacing(2.5),
  minHeight: '100px',
  '& .address_sub_title': {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center'
  },
  '& .address_content': {
    padding: theme.spacing(2.25, 1.25)
  }
}))
