//Library
import __ from 'languages/index'
import {useAppDispatch, useAppSelector} from 'config/store'
import React, {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {AxiosError, AxiosResponse} from 'axios'

//Store
import {loginWithFacebook, loginWithGoogle} from 'store/auth.store.reducer'
import {checkJoinChannel, getCurrentUserData} from 'store/user.store.reducer'

//Hook
import {EnumTypeToast, useToast} from 'hooks/useToast'
import helpers from 'helpers/index'
import _Helmet from "components/helmet";
import {Box, Paper, styled, Typography} from "@mui/material";
import backgroundLogin from "entities/auth/media/images/backgroundLogin.svg";
import Lottie from "lottie-react";
import loginLottie from "../../lotties/login.json";

const LoginWithSocial = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const toast = useToast()
  let sso_host = localStorage.getItem('sso_host');

  const handleCheckJoinChannel = async()=>{
    await dispatch(checkJoinChannel()).unwrap()
    .then((res)=>{
      if(res?.data?.is_join) return navigate('/', { replace: true });
      return navigate(`/channel/join/${channel_data?._id}`)
    })
    .catch((error)=>{
      console.log('checkJoinChannel Error >>>', error);
    })
  }

  useEffect(() => {
    if (location.state?.credential && location?.pathname === "/login/google") {
      const formData = {
        user_token: location.state?.credential,
        device_uuid: "",
        device_type: 'website',
        user_referrer: localStorage.getItem('user_referrer') || "",
        domain: window.location.origin,
        _language: localStorage.getItem("languageChannel")
      }
      dispatch(loginWithGoogle(formData))
        .unwrap()
        .then(async (response: AxiosResponse) => {
          let authCode = response.headers["x-authorization"] || '';

          // SSO
          if (sso_host) {
            localStorage.removeItem('sso_host');
            return window.location.href = decodeURIComponent(sso_host) + '#authentication_hash=' + authCode;
          } else {
            localStorage.setItem("session", authCode);
            await helpers.sleep(500)
            await dispatch(getCurrentUserData());
            handleCheckJoinChannel()
          }
        })
        .catch((error: AxiosError) => {
          console.log(`loginWithFacebook_${error}`);
          navigate("/login")
          toast.show({
            content: `${__('login_failed')}`,
            type: EnumTypeToast.Error
          })
        })
    } else {

      if(window.location.hash && location?.pathname === "/login/facebook"){
        const hashURL = String(window.location.hash || ' ').replace('#','')
        let _url = new URLSearchParams(hashURL);
        const accessToken = _url.get('access_token') || '';
        if(accessToken) {
          const formData = {
            user_token: accessToken,
            device_uuid: "",
            device_type: 'website',
            user_referrer: localStorage.getItem('user_referrer') || "",
            domain: window.location.origin,
            _language: localStorage.getItem("languageChannel")
          }
          dispatch(loginWithFacebook(formData))
            .unwrap()
            .then( async (response)=> {
              let authCode = response.headers["x-authorization"] || '';


              // SSO
              if ( sso_host ) {
                return window.location.href = decodeURIComponent(sso_host) + '#authentication_hash=' + authCode;
              } else {
                localStorage.setItem("session", authCode);
                await helpers.sleep(500)
                await dispatch(getCurrentUserData());
                handleCheckJoinChannel()
              }
            })
            .catch((error)=> {
              console.log(`loginWithFacebook_${error}`);

              navigate("/login")
              toast.show({
                content: `${__('login_failed')}`,
                type: EnumTypeToast.Error
              })
            })
        }
      }else {
        navigate("/login")
      }
    }
  }, [sso_host])

  return (
    <>
      <_Helmet title={'login'}/>
      <LoginContainer id="loginPage">
        <Box className="wrapper">
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              height: 'auto'
            }}
          >
            <Box>
              <Box
                display={'grid'}
                rowGap={theme => theme.spacing(3)}
                padding={theme => theme.spacing(3)}
              >
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                     sx={{
                       width: theme => theme.spacing(60),
                     }}>
                  <Box
                    sx={{
                      width: theme => theme.spacing(20),
                      height: theme => theme.spacing(20)
                    }}
                  >
                    <Lottie
                      animationData={loginLottie}
                      style={{width: '100%', height: '100%'}}
                      autoPlay
                      loop
                    />
                  </Box>
                </Box>
                <Typography textTransform={'none'} variant="h6" align={'center'}>
                  {__('signing_in')}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </LoginContainer>
    </>
  )
}

const LoginContainer = styled(Box)(({theme}) => ({
  height: '100vh',
  backgroundImage: `url(${backgroundLogin})`,
  backgroundAttachment: 'fixed',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: theme.palette.primary.main,
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 824px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
  '& .form': {
    display: 'grid',
    rowGap: theme.spacing(1.25)
  },
  '& .footer': {
    display: 'flex',
    columnGap: theme.spacing(1.25),
    width: '100%',
    justifyContent: 'space-between',
    background: theme.palette.background.default,
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(0, 0, 1.5, 1.5)
  },
  '& .link': {
    color: theme.palette.text.primary
  }
}))

export default LoginWithSocial
