import { SvgIcon, SvgIconProps } from '@mui/material'

const OrderIcon = (props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="31" viewBox="0 0 25 31" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0 4.16667C0 1.86548 1.86548 0 4.16667 0H20.8333C23.1345 0 25 1.86548 25 4.16667V24.6721C25 27.7696 21.7404 29.7841 18.9699 28.3989L18.6767 28.2523C18.2857 28.0568 17.8254 28.0568 17.4344 28.2523L14.3634 29.7878C13.1904 30.3743 11.8096 30.3743 10.6366 29.7878L7.56558 28.2523C7.17457 28.0568 6.71433 28.0568 6.32332 28.2523L6.03006 28.3989C3.25963 29.7841 0 27.7696 0 24.6721V4.16667ZM4.16667 2.77778C3.3996 2.77778 2.77778 3.3996 2.77778 4.16667V24.6721C2.77778 25.7046 3.86432 26.3761 4.7878 25.9144L5.08105 25.7678C6.25409 25.1812 7.6348 25.1812 8.80784 25.7678L11.8789 27.3033C12.2699 27.4988 12.7301 27.4988 13.1211 27.3033L16.1922 25.7678C17.3652 25.1812 18.7459 25.1812 19.9189 25.7678L20.2122 25.9144C21.1357 26.3761 22.2222 25.7046 22.2222 24.6721V4.16667C22.2222 3.3996 21.6004 2.77778 20.8333 2.77778H4.16667ZM5.55556 6.94444C5.55556 6.17738 6.17738 5.55556 6.94444 5.55556H18.0556C18.8226 5.55556 19.4444 6.17738 19.4444 6.94444C19.4444 7.71151 18.8226 8.33333 18.0556 8.33333H6.94444C6.17738 8.33333 5.55556 7.71151 5.55556 6.94444ZM5.55556 12.5C5.55556 11.7329 6.17738 11.1111 6.94444 11.1111H18.0556C18.8226 11.1111 19.4444 11.7329 19.4444 12.5C19.4444 13.2671 18.8226 13.8889 18.0556 13.8889H6.94444C6.17738 13.8889 5.55556 13.2671 5.55556 12.5ZM5.55556 18.0556C5.55556 17.2885 6.17738 16.6667 6.94444 16.6667H18.0556C18.8226 16.6667 19.4444 17.2885 19.4444 18.0556C19.4444 18.8226 18.8226 19.4444 18.0556 19.4444H6.94444C6.17738 19.4444 5.55556 18.8226 5.55556 18.0556Z"
              fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
export default OrderIcon
