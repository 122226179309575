export const backgroundLeaderBoard = ({primaryColor}) => {
  return `<svg width="766" height="676" viewBox="0 0 766 676" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3010_39909)">
  <rect width="766" height="1871" rx="12" fill="${primaryColor}"/>
  <g style="mix-blend-mode:screen" opacity="0.4">
  <g clip-path="url(#clip1_3010_39909)">
  <g filter="url(#filter0_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 278.743L320 -195.303C361.82 -200.747 404.18 -200.747 446 -195.303L383 278.743Z" fill="url(#paint0_linear_3010_39909)"/>
  </g>
  <g filter="url(#filter1_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 278.743L90 -100.323C123.367 -125.749 159.952 -146.714 198.818 -162.683L383 278.743Z" fill="url(#paint1_linear_3010_39909)"/>
  </g>
  <g filter="url(#filter2_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 278.743L-62 96.0234C-45.8896 57.4812 -24.7551 21.1969 0.864181 -11.9033L383 278.743Z" fill="url(#paint2_linear_3010_39909)"/>
  </g>
  <g filter="url(#filter3_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 278.752L-94.8834 341.237C-100.372 299.753 -100.372 257.733 -94.8834 216.249L383 278.752Z" fill="url(#paint3_linear_3010_39909)"/>
  </g>
  <g filter="url(#filter4_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 281L0.864181 574C-24.75 540.622 -45.884 504.039 -62 465.182L383 281Z" fill="url(#paint4_linear_3010_39909)"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 281L198.818 726C179.407 717.959 160.544 708.649 142.354 698.13C124.16 687.617 106.672 675.928 90 663.136L383 281Z" fill="url(#paint5_linear_3010_39909)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 281L446 758.866C404.181 764.378 361.819 764.378 320 758.866L383 281Z" fill="url(#paint6_linear_3010_39909)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 281L676 663.136C659.334 675.928 641.852 687.617 623.664 698.13C605.466 708.645 586.598 717.956 567.182 726L383 281Z" fill="url(#paint7_linear_3010_39909)"/>
  <g filter="url(#filter5_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 281L828 465.182C811.884 504.039 790.75 540.622 765.136 574L383 281Z" fill="url(#paint8_linear_3010_39909)"/>
  </g>
  <g filter="url(#filter6_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 278.752L860.883 216.249C866.372 257.733 866.372 299.753 860.883 341.237L383 278.752Z" fill="url(#paint9_linear_3010_39909)"/>
  </g>
  <g filter="url(#filter7_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 278.743L765.136 -11.9033C790.755 21.1969 811.89 57.4812 828 96.0234L383 278.743Z" fill="url(#paint10_linear_3010_39909)"/>
  </g>
  <g filter="url(#filter8_d_3010_39909)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M383 278.743L567.182 -162.683C606.048 -146.714 642.633 -125.749 676 -100.323L383 278.743Z" fill="url(#paint11_linear_3010_39909)"/>
  </g>
  </g>
  </g>
  </g>
  <defs>
  <filter id="filter0_d_3010_39909" x="316" y="-199.386" width="134" height="486.129" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter1_d_3010_39909" x="86" y="-162.683" width="301" height="449.426" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter2_d_3010_39909" x="-66" y="-11.9033" width="453" height="298.646" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter3_d_3010_39909" x="-103" y="216.249" width="490" height="132.988" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter4_d_3010_39909" x="-66" y="281" width="453" height="301" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter5_d_3010_39909" x="379" y="281" width="453" height="301" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter6_d_3010_39909" x="379" y="216.249" width="490" height="132.988" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter7_d_3010_39909" x="379" y="-11.9033" width="453" height="298.646" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <filter id="filter8_d_3010_39909" x="379" y="-162.683" width="301" height="449.426" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="4"/>
  <feGaussianBlur stdDeviation="2"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3010_39909"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3010_39909" result="shape"/>
  </filter>
  <linearGradient id="paint0_linear_3010_39909" x1="383" y1="278.743" x2="383" y2="-199.315" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint1_linear_3010_39909" x1="382.997" y1="278.732" x2="144.976" y2="-136.563" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint2_linear_3010_39909" x1="382.998" y1="278.753" x2="-32.3086" y2="36.8542" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint3_linear_3010_39909" x1="383.018" y1="278.752" x2="-98.9822" y2="278.752" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint4_linear_3010_39909" x1="382.967" y1="281.013" x2="-34.0302" y2="521.945" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint5_linear_3010_39909" x1="383.016" y1="281.032" x2="142.084" y2="698.029" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint6_linear_3010_39909" x1="383" y1="281" x2="383" y2="763" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint7_linear_3010_39909" x1="382.939" y1="281.039" x2="623.862" y2="698.021" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint8_linear_3010_39909" x1="383.014" y1="281.04" x2="800.011" y2="521.972" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint9_linear_3010_39909" x1="383" y1="278.734" x2="865" y2="278.734" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint10_linear_3010_39909" x1="382.987" y1="278.759" x2="798.294" y2="36.86" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <linearGradient id="paint11_linear_3010_39909" x1="382.996" y1="278.76" x2="621.026" y2="-136.551" gradientUnits="userSpaceOnUse">
  <stop stop-color="${primaryColor}"/>
  <stop offset="0.83"/>
  </linearGradient>
  <clipPath id="clip0_3010_39909">
  <rect width="766" height="676" fill="white"/>
  </clipPath>
  <clipPath id="clip1_3010_39909">
  <rect width="766" height="988" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  `
}
