import Modal from 'react-bootstrap/Modal'
import '../media/css/modal.scss'
import React from 'react'
import __ from 'languages/index'

export interface  TypeModalNotificationProp{
    show:boolean;
    handleChange: ()=> void;
}

const ModalNotification: React.FC<TypeModalNotificationProp> =({show,handleChange }) =>{
  return(
      <Modal show={show}
             onHide={handleChange}
             aria-labelledby="contained-modal-title-vcenter"
             className="modal-sm"
             centered>
        <Modal.Header closeButton>
          <Modal.Title>{__("notification")}</Modal.Title>
        </Modal.Header>
          <Modal.Body >
            {__("payment_modal_notification_text")}
          </Modal.Body>
      </Modal>
  )
}
export  default ModalNotification;
