//Library
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'

//Components
import DetailPaymentAccountBank from './components/detail.payment.account.bank'
import SettingMentorSkeleton from 'entities/mentor/skeleton/setting.mentor.list.skeleton'
import _Helmet from 'components/helmet'

//Helper

//Stores
import { getListBank, MENTOR_REDUCER } from 'entities/mentor/store/mentor.store.reducer'
import { useAppDispatch, useAppSelector } from 'config/store'

//Interface
import { TypeAccountBank } from './payment.interface'
import __ from 'languages/index'
import BigLayout from "../../layouts/bigLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";

export default function AddPayment() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const { actionsCheck } = useAppSelector((state) => state.mentor);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTriggerFetch, setIsTriggerFetch] = useState<boolean>(false);
  const [dataBank, setDataBank] = useState({
    listBank: [],
    totalData: 0,
  });
  const toast = useToast()

  const handleClickTab = useCallback((link: string)=> {
    if(!link) return navigate('/404')
    navigate(link)
  },[])

  const handleGetListBank = useCallback(() => {
    setIsLoading(true);
    dispatch(getListBank({ payment_method: "transfer" }))
      .unwrap()
      .then((res) => {
        setDataBank({
          listBank: res?.data,
          totalData: parseInt(res.headers["x-total-count"] || 1000, 10),
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setDataBank({
          listBank: [],
          totalData: 0,
        });
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    handleGetListBank();
  }, []);

  useEffect(() => {
    if (!isTriggerFetch) return;
    setIsTriggerFetch(false);
    handleGetListBank();
  }, [isTriggerFetch]);

  useEffect(() => {
    if (actionsCheck?.isSuccess && actionsCheck?.statusCode === 200) {
      dispatch(
        MENTOR_REDUCER.actions.setActionsCheck({
          type: "",
          isSuccess: false,
          statusCode: 0,
        })
      );
      toast.show({
        title: `${__("notification")}`,
        content: `${actionsCheck?.type} ${__("payment_success")}`,
        type: EnumTypeToast.Success,
      });
    }
  }, [actionsCheck]);

  return (
    <BigLayout>
      <_Helmet title='payment_title' />
      <div className="row">
        <Breadcrumb className="d-none d-md-block">
          <Breadcrumb.Item onClick={()=> handleClickTab('/')}>{__("home_page")}</Breadcrumb.Item>
          <Breadcrumb.Item onClick={()=> handleClickTab('/info-payment')}>
            {__("payment_title")}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{__("payment_method_title")}</Breadcrumb.Item>
        </Breadcrumb>
        {isLoading ? (
          <div className="bg-white p-4 rounded mt-4">
            <SettingMentorSkeleton />
          </div>
        ) : (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
              columnGap: '20px',
              rowGap: '20px'
            }}
          >
            {dataBank?.listBank?.map((item: TypeAccountBank, idx: number) => (
              <DetailPaymentAccountBank
                key={`accountBank_${idx}`}
                dataBank={item}
                setIsTriggerFetch={setIsTriggerFetch}
              />
            ))}
            <div
              className="col-12 col-md-6 w-100 rounded overflow-hidden"
              style={{ minHeight: '242px' }}
            >
              <Link
                to={'/info-payment/card'}
                className="bg-white d-flex justify-content-center align-items-center h-100 w-100"
                style={{ height: 200 }}
              >
                <div className="p-4">{__("payment_add_payment_method")}</div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </BigLayout>
  )
}
