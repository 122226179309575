import React, { useEffect, useMemo } from 'react'
import { Box, Paper, SxProps, TableContainer } from '@mui/material'
import TableHeadCart from './components/TableHeadCart'
import CartProductItem from 'entities/cart/components/CartProductItem'
import CartFooter from 'entities/cart/components/CartFooter'
import { CurrentTheme } from '../../styles/themes'
import { useAppSelector } from 'config/store'
import { getCartItems, isFulfilledListCartItems } from 'entities/shop/store/cart/cart.slice'
import BigLayout from '../../layouts/bigLayout'
import ShopBreadcrumb from 'entities/shop/components/shop.breadcrumb'
import __ from 'languages/index'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { ItemInCart } from 'entities/shop/interface'
import { list, removeItemById, updateQuantityService } from 'entities/shop/service/cart.service'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import helpers from 'helpers'
import debounce from 'lodash.debounce'

const Cart = () => {
  const cartItems = useAppSelector(getCartItems)
  const [selectedProduct, setSelectedProduct] = React.useState<string[]>([])
  const [selectedProductData, setSelectedProductData] = React.useState<ItemInCart[]>([])
  const [isSelectedAll, setIsSelectedAll] = React.useState<boolean>(false)

  const toast = useToast()

  const { hasMore, isRefreshing, loadMore, deleteItemById, refresh, updateItemAttribute } =
    useInfiniteScroll<ItemInCart>(
      {
        keyAttribute: 'item_id',
        order_by: 'DESC'
      },
      list,
      true,
      cartItems,
      isFulfilledListCartItems
    )

  const totalPayment = useMemo(() => {
    if (helpers.isEmpty(selectedProduct)) return 0
    let total = 0
    selectedProductData?.forEach(
      (data: ItemInCart) => (total += data?.product?.product_price * data?.item_quantity)
    )
    return total || 0
  }, [selectedProduct, selectedProductData, cartItems])

  useEffect(() => {
    if (helpers.isEmpty(cartItems)) return
    const filterData = selectedProduct?.map((item: string) => {
      return cartItems?.find((data: ItemInCart) => item === data?.product_id)
    })
    setSelectedProductData([...filterData])
  }, [cartItems, selectedProduct])

  const handleSelectProduct = (productId: string) => {
    const selectedIndex = selectedProduct.indexOf(productId)
    setIsSelectedAll(false)
    let newSelected: string[] = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProduct, productId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProduct.slice(1))
    } else if (selectedIndex === selectedProduct.length - 1) {
      newSelected = newSelected.concat(selectedProduct.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProduct.slice(0, selectedIndex),
        selectedProduct.slice(selectedIndex + 1)
      )
    }
    if (newSelected.length === cartItems.length) {
      setIsSelectedAll(true)
    }
    setSelectedProduct(newSelected)
  }
  const handleSelectAllProducts = () => {
    if (selectedProduct.length === cartItems.length) {
      setSelectedProduct([])
      setIsSelectedAll(false)
    } else {
      setIsSelectedAll(true)
      setSelectedProduct(cartItems.map(item => item.product.product_id))
    }
  }

  const handleRemoveProduct = (id: string) => {
    removeItemById(id)
      .then(() => {
        deleteItemById(id)
        toast.show({
          type: EnumTypeToast.Success,
          content: `${__('delete_product_success')}`
        })
      })
      .catch(error => {
        console.error('removeItemById >>> ', error)
        toast.show({
          type: EnumTypeToast.Error,
          content: `${error}`
        })
      })
  }

  const handleUpdateQuantity = (id: string, quantity: number) => {
    requestUpdateQuantity({ id, quantity })
  }

  const requestUpdateQuantity = useMemo(() => {
    return debounce(({ id, quantity }) => {
      updateQuantityService(id, { item_quantity: quantity })
        .then(res => {
          updateItemAttribute(res?.data)
          if (selectedProduct.indexOf(res?.data?.product_id) !== -1) return
          setSelectedProduct(selectedProduct => [...selectedProduct, res?.data?.product_id])
        })
        .catch(error => {
          console.error('update quantity error >>> ', error)
        })
    }, 500)
  }, [selectedProduct, cartItems])

  useEffect(() => {
    if (isSelectedAll) {
      handleSelectAllProducts()
    }
  }, [cartItems])

  return (
    <BigLayout>
      <TableContainer sx={containerStyled}>
        <ShopBreadcrumb
          breadcrumbName={{
            '/shop': __('shop'),
            '/shop/cart': __('cart')
          }}
        />
        <TableHeadCart />
        <Paper className="list_product">
          <InfiniteScrollContainer
            isRefreshing={isRefreshing}
            dataLength={cartItems.length}
            next={loadMore}
            hasMore={hasMore}
            refreshFunction={refresh}
            showEndMessage={false}
            pullDownToRefresh={false}
          >
            {cartItems?.map((item, idx) => (
              <CartProductItem
                key={idx}
                label={item.product.product_name}
                cartItem={{ ...item }}
                isSelected={selectedProduct.includes(item.product.product_id)}
                onSelectProduct={prod => handleSelectProduct(prod.product_id)}
                onRemoveProduct={handleRemoveProduct}
                onUpdateQuantity={quantity => handleUpdateQuantity(item?.item_id, quantity)}
              />
            ))}
          </InfiniteScrollContainer>
        </Paper>
      </TableContainer>

      {!helpers.isEmpty(cartItems) && (
        <Box position={'sticky'} bottom={0} zIndex={2}>
          <Box
            sx={{
              background: 'linear-gradient(transparent,rgba(0,0,0,.06))',
              height: theme => theme.spacing(3),
              borderRadius: theme => theme.spacing(0.5)
            }}
          ></Box>
          <CartFooter
            onSelectAllProducts={handleSelectAllProducts}
            productsLength={cartItems.length}
            selectedProductData={selectedProductData}
            totalPayment={totalPayment}
            selectedProductsLength={selectedProduct.length}
          />
        </Box>
      )}
    </BigLayout>
  )
}

export default Cart

const containerStyled: SxProps<CurrentTheme> = {
  display: 'grid',
  rowGap: theme => theme.spacing(3),
  '& .list_product': {
    padding: theme => theme.spacing(3)
  }
}
