import DateTime from 'date-and-time'
import {TypedEvent, TypedItemEvent} from 'entities/calendar/calendar.interface'

export const configDataDate = (data: TypedEvent[]) => {
  let temp: TypedItemEvent[] = []

  data.map(item => {
    let tempItem: TypedItemEvent = {
      _id: item?._id,
      title: new Date(item?.end_date).getTime() >= new Date().getTime()? item?.title:null,
      start: new Date(item?.open_date).toISOString()
    }

    if (Array.isArray(item?.event_time) && item?.event_time.length > 1) {
      const filterArr = uniqueArr(item?.event_time?.slice(0, 31), (a, b) => {
        return (
          DateTime.format(new Date(a?.event_date), 'YYYY-MM-DD HH:mm') ==
          DateTime.format(new Date(b?.event_date), 'YYYY-MM-DD HH:mm')
        )
      })
      if (Array.isArray(filterArr) && filterArr.length > 1) {
        filterArr.map(i => {
          const newItem = {
            ...tempItem,
            start: new Date(i?.event_date).toISOString()
          }
          temp.push(newItem)
        })
      } else {
        tempItem = {
          ...tempItem,
          start: new Date(filterArr[0]?.event_date).toISOString()
        }
        temp.push(tempItem)
      }
    } else {
      temp.push(tempItem)
    }
  })

  return temp.filter((item) => !!item?.title)
}

export function uniqueArr(a, fn) {
  if (a.length === 0 || a.length === 1) {
    return a
  }
  if (!fn) {
    return a
  }

  for (let i = 0; i < a.length; i++) {
    for (let j = i + 1; j < a.length; j++) {
      if (fn(a[i], a[j])) {
        a.splice(i, 1)
      }
    }
  }
  return a
}

export const calculatorPeriod = (number: number) => {
  if (number % 365 == 0) {
    return { period: 365, repeat: number / 365 }
  }

  if (number % 30 == 0) {
    return { period: 30, repeat: number / 30 }
  }
  if (number % 7 == 0) {
    return { period: 7, repeat: number / 7 }
  }
  return { period: 1, repeat: number }
}

export const compareTwoArray = (arr1, arr2, variable1, variable2) => {
  let newArr = []
  for (let i = 0; i < arr2.length; i++) {
    for (let j = 0; j < arr1.length; j++) {
      if (arr1[j][variable1] == arr2[i][variable2]) {
        newArr = [...newArr, ...[arr1[j]]]
      }
    }
  }
  return newArr
}
