import { Box, Paper, SxProps, Typography } from '@mui/material'
import { CurrentTheme } from '../../../styles/themes'
import __ from 'languages/index'

const TableHeadCart = () => {
  return (
    <Paper sx={headStyled}>
      <Box className="header_wrapper">
        <Box>
          <Typography variant={'subtitle2'} fontWeight={600}>
            {__('txt_products')}
          </Typography>
        </Box>
        <Box textAlign={'center'}>
          <Typography variant={'subtitle2'} fontWeight={600}>
            {__('label_price')}
          </Typography>
        </Box>
        <Box textAlign={'center'}>
          <Typography variant={'subtitle2'} fontWeight={600}>
            {__('label_quantity')}
          </Typography>
        </Box>
        <Box textAlign={'center'}>
          <Typography variant={'subtitle2'} fontWeight={600}>
            {__('label_total')}
          </Typography>
        </Box>
        <Box textAlign={'center'}>
          <Typography variant={'subtitle2'} fontWeight={600}>
            {__('label_action')}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default TableHeadCart

const headStyled: SxProps<CurrentTheme> = {
  display: 'grid',
  alignItems: 'center',
  height: theme => theme.spacing(12.5),
  padding: theme => theme.spacing(3),
  width: '100%',

  '& .header_wrapper': {
    display: 'grid',
    gridTemplateColumns: `repeat(8,minmax(0,1fr))`,
    alignItems: 'center',
    '& div:first-child': {
      gridColumn: 'span 4'
    }
  }
}
