import __ from "languages/index";
import React, {KeyboardEvent, Ref, RefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import { Form} from "react-bootstrap";
import {AxiosError, AxiosResponse} from "axios";
import {useAppDispatch, useAppSelector} from "config/store";
import {useLocation, useNavigate} from "react-router-dom";
import {EnumTypeToast, useToast} from "../../hooks/useToast";
import helpers from "helpers";
import "./media/css/index.scss";
import {updatePassword, verifyPassword} from "entities/forgotPassword/forgot.password.store.reducer";
import _Helmet from "components/helmet";
import { Box, Button, IconButton, keyframes, Paper, styled, Typography } from "@mui/material";
import  backgroundLogin  from 'entities/auth/media/images/backgroundLogin.svg';
import useRedirectAuth from "../../hooks/useRedirectAuth";
import { ArrowLeftIcon } from "icons";
import getBackgroundLogin from "entities/auth/media/images/backgroundLogin";

const PasswordValidateCodePage =()=>{
  const inputDataRefs: RefObject<HTMLInputElement>[] = Array.from({ length: 6 }, () =>
    useRef<HTMLInputElement>(null)
  )
  const [validateCode, setValidateCode] = useState<string>('')
  const [numberOfValidations, setNumberOfValidations] = useState<number>(0)
  const [timeLeft, setTimeLeft] = useState<number>(120)
  const [isCounting, setIsCounting] = useState<boolean>(true)
  const toast = useToast();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const {goToLogin} = useRedirectAuth()
  const checkMobile = helpers.isMobile()
  const channel_data = useAppSelector(state => state.user.channel_data)
  useEffect(() => {
    const {sessionId} = location?.state || {}
    if(!sessionId){
      goToLogin()
    }
  }, []);

  const handleChangeVerifyCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const regex = new RegExp(/\D/)
      if (regex.test(value)) return
      setValidateCode(validateCode => [validateCode, value].join(''))
    },
    [validateCode]
  )

  const handleInputChange = useCallback(
    (index: number, value: string) => {
      if (value && index < inputDataRefs.length - 1 && helpers.parseNumeric(value) >= 0) {
        inputDataRefs[index + 1]?.current?.focus()
      }
    },
    [inputDataRefs]
  )

  const handleKeyDown = useCallback(
    (index: number, event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace') {
        if (index > 0 && validateCode?.length < 6  && !validateCode.split('')[index + 1]) {
          inputDataRefs[index - 1]?.current?.focus()
          return  setValidateCode(validateCode =>
                    validateCode
                      .split('')
                      .filter((_, idx: number) => idx !== (index - 1))
                      .join('')
                  )
        }
        setValidateCode(validateCode =>
          validateCode
            .split('')
            .filter((_, idx: number) => idx !== (index))
            .join('')
        )
      }
    },
    [inputDataRefs, validateCode]
  )

  const handleSubmitVerifyCode = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {sessionId} = location?.state || {}
    const formData = {
      verified_code: validateCode,
      session_id: sessionId
    }
    dispatch(verifyPassword(formData))
      .unwrap()
      .then((res: AxiosResponse) => {
        setNumberOfValidations(0)
        toast.show({
          content: `${__("validate_code_page_verify_success")}`,
          type: EnumTypeToast.Success
        })
        navigate(`/forgot-password/change-password`, { state: {sessionId: res?.data?.session_id || ''}})
      })
      .catch((error: AxiosError) => {
        console.log(`sendOTP_${error}`)
        setNumberOfValidations(numberOfValidations => numberOfValidations + 1)
        toast.show({
          content: `${__("validate_code_page_confirm_failed")}`,
          type: EnumTypeToast.Error
        })
      })
  }, [validateCode, numberOfValidations, location])

  const countResendTime = useMemo(() => {
    const minutes = Math.floor(timeLeft / 60)
    const seconds = timeLeft % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }, [timeLeft])


  useEffect(() => {
    let timer
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)
    }
    if (timeLeft === 0) {
      setIsCounting(false)
      setTimeLeft(120)
      clearInterval(timer)
    }
    return () => {
      clearInterval(timer)
    }
  }, [isCounting, timeLeft])

  const handleResendPassword = useCallback(
    ()=>{
      const { user_input} = location?.state
      dispatch(updatePassword({
        user_input: user_input
      }))
        .unwrap()
        .then((res: AxiosResponse) => {
          setIsCounting(true)
          toast.show({
            content: `${__("forgot_password_success")}`,
            type: EnumTypeToast.Success
          })
        })
        .catch((error: AxiosError) => {
          console.log(`send_${error}`)
          toast.show({
            content: `${__(error.message || 'our_system_meet_error')}`,
            type: EnumTypeToast.Error
          })
        })
    },[location])

  return (
    <>
      <_Helmet title={'forgot_password_verify_title'} />
      <PasswordValidateCodePageContainer id="validateCodePage" >

      <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.primary_color}))}')`,
          }}
        />

        <Box
          id={'background_svg_default'}
        />

        <Box id={'box_form'} className={`wrapper ${checkMobile && 'mobile'}`}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              height: checkMobile ? '100%' : 'auto'
            }}>
            {
              checkMobile && (
                <IconButton sx={{
                  mt: '24px',
                  justifyContent: 'flex-start'
                }} onClick={() => navigate(-1)} >
                <ArrowLeftIcon />
              </IconButton>
              )
            }
            <Box padding={theme => theme.spacing(3)}>
                <h2>{__("forgot_password_verify_title")}</h2>
                <p>{__("forgot_password_verify_sub_title")}</p>
                <Form  onSubmit={handleSubmitVerifyCode} className="validateCodePage_validate_number_code">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="fw-bold mb-1">{__("forgot_password_verification_code")}</label>
                    {!isCounting ? (
                        <Button
                            variant='text'
                            onClick={handleResendPassword}
                            // disabled={!dirty || submitting}
                            className="btn btn-outline-primary btn_resend_code "
                        >
                          {__("forgot_password_resend_code")}
                        </Button>
                    ) : (
                        <Button variant='text'  disabled={isCounting} className="btn btn-outline-primary btn_resend_code">
                          {`${__("validate_code_page_resend")} ${countResendTime}`}
                        </Button>
                    )}
                  </div>
                  <div className={checkMobile ? 'validateCodePage_validate_number_code_input_mobile' : 'validateCodePage_validate_number_code_input'}>
                    {inputDataRefs?.map((inputRef: Ref<HTMLInputElement>, index: number) => (
                      <Form.Control
                        key={`input_${index}`}
                        onChange={handleChangeVerifyCode}
                        value={validateCode.split('')[index] || ''}
                        placeholder={checkMobile ? '' : '•'}
                        maxLength={1}
                        className={`text-center ${checkMobile && 'validateCodePage_validate_number_code_input_item'}`}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.target.select()
                        }}
                        ref={inputRef}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputChange(index, e.target.value)
                        }
                        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
                      />
                    ))}
                  </div>

                  <Button disabled={validateCode?.length < 6}   type="submit" sx={{ p: theme => theme.spacing(2.1875, 2), mt: theme => theme.spacing(3) }}>
                    <Typography textTransform={'none'}>
                    {__("forgot_password_btn_verify")}
                    </Typography>
                  </Button>
                  {numberOfValidations >= 3 && (
                    <span
                      role="button"
                      onClick={() => navigate('/')}
                      className="d-flex text-decoration-underline text-primary justify-content-center mt-3"
                    >
                      {__("forgot_password_verify_later")}
                    </span>
                  )}
                </Form>
              </Box>
          </Paper>
          {/*<div className="mt-4">*/}
          {/*  <span>{__("forgot_password_verify_support_text")}</span>*/}
          {/*  <a href="#" onClick={() => navigate('/') } role="button" className=" ms-1 fw-bold text-primary">*/}
          {/*    {__("forgot_password_verify_support_contact")}*/}
          {/*  </a>*/}
          {/*</div>*/}
        </Box>
      </PasswordValidateCodePageContainer>
    </>
  )
}
export default PasswordValidateCodePage

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`;

const PasswordValidateCodePageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',

  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },

  '& #box_form': {
    position: 'absolute',
    top: 0
  },

  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 468px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
}))
