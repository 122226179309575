import '../media/css/order.approve.scss'
import {Order} from "entities/orders/order.type";
import {Form} from "react-bootstrap";
import {ORDER_STATUS} from "entities/orders/constant/order.constant";
import React, {useCallback, useEffect, useState} from "react";
import {updateOrderbyAdmin} from "entities/orders/order.store.reducer";
import {useAppDispatch} from "config/store";
import __helpers from "helpers/index";
import {getOrderDetail} from "entities/orders/service";
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { Button, Typography } from '@mui/material'

export interface TypeOrderApprove {
  OrderDetail: Order;
}
const OrderApprove: React.FC<TypeOrderApprove> = ({ OrderDetail }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [reloadData, setReloadData] = useState < Order > ()
  const [orderStatus, setOrderStatus] = useState('');
  const toast = useToast()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        const reloadData = async () => {
          try {
            const res = await getOrderDetail(OrderDetail?._id.toString())
            setReloadData(res.data)
          } catch (err) {
            console.log(err)
          }
        }
        reloadData().then(r => r)

      }, 5000);
    }
  }, [OrderDetail, isLoading]);


  const handleClick = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderStatus(event?.target?.value)
  }, [])

  const handleUpdateStatus = useCallback(async (status: string) => {
      if (OrderDetail?.status === "processing") {
        setIsLoading(true)
        try {
          await dispatch(updateOrderbyAdmin({
              _id: OrderDetail?._id.toString(),
              status: status,
            }))
            .unwrap()
            .then(()=>{
              toast.show({
                content: `${__('order_approve_update_success')}`,
                type: EnumTypeToast.Success
              })
            })
            .finally(()=>{
              setIsLoading(false)
            })
        } catch (err) {
          console.log(err)
        }
      }

    },
    [OrderDetail]
  )

  return (
    <div className="container_approve">
      <div className="login__right__form__username">
        <h2 className="mb-2">{__("order_approve")}
          <span className="order-code" style={{ fontSize: 24 }}>
               #{OrderDetail?.short_id}
              </span>
          </h2>
        <h3>{__("order_approve_status")} {reloadData?.status ?? OrderDetail?.status} </h3>
        <br />
        <hr className='m-0' />
        <br />
        <h3 className="channel_title_info">{__("order_approve_information_service")}</h3>

        {OrderDetail?.service_id && (
          <div className="channel_infomation mt-2 mb-4">
            <div className="channel_infomation_avatar">
              <img
                src={OrderDetail?.service_id?.avatar?.media_url}
                alt="Service Avatar" />
            </div>
            <div className="channel_infomation_text">
              <div className="channel_infomation_text_title">
                {OrderDetail?.service_id?.title}
              </div>
              <div className="channel_infomation_text_description">
                {OrderDetail?.service_id?.description}
              </div>
            </div>
          </div>
        )}

        {OrderDetail?.plan_id && OrderDetail?.plan_id?._id && (
          <div id="is_premium_pending">
            <h3 className="channel_title_info mb-2">{__("order_approve_information_plan")}</h3>
            <div className="body_channel_payment mb-4">
              <div className="is_premium_pending_name">
                <p className='text_approve'>{__("order_approve_information_plan_name")} {OrderDetail?.plan_id?.name}</p>
              </div>
              <div className="is_premium_pending_bank text_approve">
                <p className='text_approve'>{__("order_approve_description")} {OrderDetail?.plan_id?.description}</p>
              </div>
              <div className="is_premium_pending_price">
                <p>{__("order_information_money")} {__helpers.formatNumberWithCommas(OrderDetail?.plan_id?.price)} {__("order_unit")}</p>
              </div>
              {
                OrderDetail?.plan_type === "recurring" &&
                <>
                  <div className="is_premium_pending_price">
                    <p>{__("order_approve_trial_time")} {OrderDetail?.trial_days} {__("order_approve_unit_time")}</p>
                  </div>
                  <div className="is_premium_pending_price">
                    <p>{__('order_approve_used_time')} {OrderDetail?.plan_id?.amount_of_day} {__("order_approve_unit_time")}</p>
                  </div>
                </>
              }

            </div>

            {
              (!['close', 'success'].includes(reloadData?.status || OrderDetail?.status)) ? (
                <>
                  <div className="form-group mb-3">
                    <label className="pt-4 pb-2" htmlFor="exampleInputEmail1"><b>{__('order_approve_order_status')}</b></label>
                    <div>
                      <Form.Select aria-label="" onChange={handleClick}>
                        {ORDER_STATUS.map((item, index: number) => (
                          <option key={index.toString()} value={item?.value}>
                            {item?.title}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    variant={'contained'}
                    sx={{
                      width: '100%'
                    }}
                    // className="btn btn-primary w-100"
                    onClick={() => handleUpdateStatus(orderStatus)}
                    disabled={isLoading || orderStatus === '' || OrderDetail?.status !== "processing"}
                  >
                    {isLoading ?
                      (<Typography textTransform={'none'}>
                        <Typography className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        {__("sending_information")}
                      </Typography>) : `${__("order_approve")}`
                    }
                  </Button>
                </>

              ) : (
                <div className='text-primary'>{['success'].includes(reloadData?.status || OrderDetail?.status) ? `${__("order_approved")}` :  `${__("order_approve_cancelled")}`}</div>
              )
            }
          </div>
        )}
      </div>
    </div>
  )
}
export default OrderApprove;
