import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { faCircleXmark, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PreviewImage from 'components/PreviewImage'
import {  ModalProps } from 'react-bootstrap'
import __ from "languages/index";
import { Box, InputBase, Modal, Tooltip, Typography, styled, Button, Stack } from '@mui/material'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import TextareaAutoSize from 'components/TextAreaAutoSize'
import { UploaderIcon } from 'icons'

type Detail = {
  title: string
  description: string
  public_album: string[]
}

type Props = Omit<ModalProps, 'onHide'> & {
  onSave: (detail: Detail) => void
  onHide: () => void
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


const CreateNewNotificationModal = ({ onSave, show, onHide }: Props) => {
  const [detail, setDetail] = useState<Detail>({
    title: '',
    description: '',
    public_album: []
  })
  const [listMedia, setListMedia] = useState<File[]>([])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const changeDetailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setDetail({
      ...detail,
      [e.target.name]: e.target.value
    })
  }

  const removePreviewImageHandler = (file: File) => {
    fileInputRef.current.value = null
    setListMedia([])
    setDetail(prev => ({
      ...prev,
      public_album: []
    }))
  }

  const selectMediaHandler = () => {
    if (!fileInputRef.current) return
    fileInputRef.current.click()
  }

  const onClose = () => {
    setDetail({
      title: '',
      description: '',
      public_album: []
    })
    onHide()
  }
  useEffect(() => {
    setListMedia([])
  }, [show])

  return (
    <CreateNewNotificationModalContainer
      open={show}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <ModalContainer className='box_container'>
        <ModalHeader>
          {__('challenge_create_new_notification')}
        </ModalHeader>
        <ModalBody className='box_body'>

          <Tooltip title={__('challenge_create_new_notification_label')} >
            <InputBase
              onChange={changeDetailHandler}
              placeholder={__('challenge_create_new_notification_label')}
              inputProps={{ 'aria-label': __('header_search'), 'maxlength': 60 }}
              className="input"
              name="title"

            />

          </Tooltip>

          <Tooltip title={__('challenge_create_new_notification_description')} sx={{
            marginTop: theme => theme.spacing(2)
          }} >
            <TextareaAutoSize
              onChange={changeDetailHandler}
              placeholder={'challenge_create_new_notification_description'}
              inputProps={{ 'aria-label': __('challenge_create_new_notification_description') }}
              name="description"
              maxLength={300}
            />
          </Tooltip>

          {listMedia?.map(media => (
            <div
              className="position-relative"
              style={{
                height: '300px'
              }}
            >
              <PreviewImage
                style={{
                  maxHeight: '100%'
                }}
                file={media}
                width="full"
                height="auto"
                className="w-100 rounded-3"
                onSuccess={media =>
                  setDetail(prev => ({
                    ...prev,
                    public_album: [...prev.public_album, media.callback._id]
                  }))
                }
              />
              <FontAwesomeIcon
                onClick={() => removePreviewImageHandler(media)}
                icon={faCircleXmark}
                size="xl"
                className="position-absolute end-0 top-0 mt-3 me-3 "
                role="button"
              />
            </div>
          ))}

          <input
            type="file"
            onChange={e => {
              setListMedia(prev => [...prev, e?.target?.files[0]])
            }}
            ref={fileInputRef}
            hidden
            accept="image/*"
          />
          {!!!listMedia.length && (
            <Stack mt={2} flexDirection={'row'} alignItems={'center'} >
              <Button
                onClick={selectMediaHandler}
                variant='outlined'
              >
                <UploaderIcon sx={{
                  marginRight: theme => theme.spacing(1)
                }} />
                {__('order_information_btn_upload')}
              </Button>

              <Typography ml={2} color={'text.secondary'}>Định dạng JPG, PNG</Typography>
            </Stack>


          )}
        </ModalBody>
        <ModalFooter
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row'
        }}
        >
          <Button sx={{
            mr: theme => theme.spacing(1)
          }} variant="text" onClick={onHide}>
            {__("btn_close")}
          </Button>
          <Button
            onClick={async () => {
              onSave(detail)
              onHide()
            }}
            disabled={!!!detail.description.trim().length || !!!detail.title.trim().length}
          >
            {__("btn_save")}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </CreateNewNotificationModalContainer>
  )
}

export default CreateNewNotificationModal

const CreateNewNotificationModalContainer = styled(Modal)(({ theme }) => ({
  '& .box_container': {

    '& .box_body': {
      '& .input': {
        background: '#F3F4F5',
        borderRadius: '12px',
        padding: theme.spacing(1, 2),
        display: 'flex',
        alignItems: 'center',
        width: '718px',
      }
    }
  }
}))

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
