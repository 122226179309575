import React from 'react'
import { useParams } from 'react-router-dom'

const DetailPodcast = () => {
  const { id: podcastId } = useParams()

  return <div>detail</div>
}

export default DetailPodcast
