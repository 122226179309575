import { z } from 'zod'
import { invalid } from 'entities/support/utils'
import __ from 'languages/index'

export const CreateChannelContactSchema = z.object({
  mentor_name: z.string().min(1, invalid('required', __('label_channel_creator'))),
  mentor_address: z.string(),
  mentor_income: z.preprocess(
    x => (x ? x : 0),
    z.coerce
      .number({
        invalid_type_error: invalid('numeric', '')
      })
      .int()
  ),
  mentor_number_member: z.preprocess(
    x => (x ? x : 0),
    z.coerce.number({ invalid_type_error: invalid('numeric', '') }).int()
  ),
  mentor_target: z.string(),
  mentor_category: z.string()
})

export type CreateChannelContactPayload = z.infer<typeof CreateChannelContactSchema>
