import __ from "languages/index";

export const dataDropdownPost = [
  {
    id: '0',
    label: `${__("data_dropdown_post_pending")}`,
    value: 'pending'
  },
  {
    id: '1',
    label: `${__("data_dropdown_post_cancel")}`,
    value: 'cancel'
  }
]
