import { TypedIcon } from "entities/calendar/calendar.interface"
import * as React from "react"
import { SVGProps, memo } from "react"
const SvgComponent = (props: TypedIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    viewBox={"0 0 24 24"}
    fill="none"
    {...props}
  >
    <g fill={props.color || "#374957"} clipPath="url(#a)">
      <path d="M10.41 19.587a4.392 4.392 0 0 1-5.945.35 4.243 4.243 0 0 1-1.44-3.672 4.511 4.511 0 0 1 1.353-2.68l2.877-2.879a1.5 1.5 0 0 0-2.122-2.12l-2.815 2.816A7.628 7.628 0 0 0 .03 16.06a7.257 7.257 0 0 0 12.356 5.794l3.008-3.008a1.5 1.5 0 0 0-2.121-2.121l-2.864 2.862ZM21.162 1.467a7.425 7.425 0 0 0-9.72.806l-2.83 2.833a1.5 1.5 0 0 0 2.12 2.121l2.877-2.873a4.384 4.384 0 0 1 5.834-.42 4.26 4.26 0 0 1 .316 6.301l-3.008 3.008a1.5 1.5 0 0 0 2.121 2.122l3.009-3.009a7.264 7.264 0 0 0-.72-10.889Z" />
      <path d="m8.611 13.246 4.662-4.662a1.5 1.5 0 1 1 2.121 2.122l-4.661 4.662a1.5 1.5 0 0 1-2.122-2.122Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
const Memo = memo(SvgComponent)
export default Memo
