import { TableCell, styled, tableCellClasses } from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontWeight: 600,
    '&:first-child': {
      borderTopLeftRadius: theme.spacing(1.5),
      borderBottomLeftRadius: theme.spacing(1.5)
    },
    '&:last-child': {
      borderTopRightRadius: theme.spacing(1.5),
      borderBottomRightRadius: theme.spacing(1.5)
    }
  }
}))
