import {
  AlignIcon,
  BarChartIcon,
  BriefCaseIcon,
  GroupIcon,
  MapIcon,
  MinimizeIcon,
  SquareTextIcon,
  UserCircleOutlinedIcon,
  WidgetIcon
} from '../../../icons'
import __ from 'languages/index'

export const basicInfoFormConfigs = [
  {
    name: 'name',
    label: __('label_channel_name'),
    type: 'text',
    placeholder: __('p_channel_name'),
    // required: true,
    defaultValue: '',
    icon: WidgetIcon
  },
  {
    name: 'slogan',
    label: __('label_channel_slogan'),
    type: 'text',
    placeholder: __('p_channel_slogan'),
    // required: true,
    defaultValue: '',
    icon: SquareTextIcon
  },
  {
    name: 'sub_domain',
    label: __('label_channel_sub_domain'),
    type: 'text',
    placeholder: __('p_channel_sub_domain'),
    defaultValue: '',
    icon: WidgetIcon
  },
  {
    name: 'description',
    label: __('label_channel_description'),
    placeholder: __('p_channel_description'),
    type: 'text',
    multiline: true,
    minRows: 3,
    // required: true,
    defaultValue: '',
    icon: AlignIcon
  }
]

export const contactFormConfigs = [
  {
    name: 'mentor_name',
    label: __('label_channel_creator'),
    type: 'text',
    placeholder: __('label_channel_creator'),
    defaultValue: '',
    icon: UserCircleOutlinedIcon
  },
  {
    name: 'mentor_address',
    label: __('label_address'),
    type: 'text',
    placeholder: __('label_address'),
    defaultValue: '',
    icon: MapIcon
  },
  {
    name: 'mentor_income',
    label: __('label_revenue_per_month'),
    type: 'text',
    placeholder: __('label_revenue_per_month'),
    required: false,
    defaultValue: '',
    icon: BarChartIcon
  },
  {
    name: 'mentor_number_member',
    label: __('label_members'),
    type: 'text',
    placeholder: __('label_members'),
    defaultValue: '',
    icon: GroupIcon
  },
  {
    name: 'mentor_category',
    label: __('label_work_field'),
    type: 'select',
    placeholder: __('label_work_field'),
    defaultValue: '',
    icon: BriefCaseIcon
  },
  {
    name: 'mentor_target',
    label: __('label_channel_target'),
    type: 'text',
    multiline: true,
    minRows: 3,
    placeholder: __('label_channel_target'),
    defaultValue: '',
    icon: MinimizeIcon
  }
]
