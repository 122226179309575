import { createRef } from 'react'

import { useParams } from 'react-router-dom'
// import Theme404 from '../../layout/404'
// import MentorList from './mentor.list'
// import MentorIncome from './mentor.income'
// import MentorPaymentManage from './mentor.payment.manage'
import ToastComponent from 'entities/courses/components/toast.component'
import SettingIcon from './media/settingIcon.svg'
import { Box, Typography } from '@mui/material'
import __ from 'languages/index'
// import DetailPayment from 'entities/infoPayment/detail.payment'
// import DetailMentor from './mentor.detail'

export default function Mentor() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'manage'
  let ActualPage: any

  const _ComponentMentorDeveloping = () => (
    <Box sx={{
      width: '100%',
      height: 'calc(100vh - 132px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }} >
      <img src={SettingIcon} width={200} height={200} alt='icon'  />
      <Typography sx={{
        fontSize: 20,
        fontWeight: 600,
        color: '#000000',
      }}>{__('mentor_feature_is_growing')}</Typography>
    </Box>
  )

  switch (Param) {
    // case 'manage':
    //   ActualPage = MentorList
    //   break
    // case 'payment':
    //   ActualPage = DetailPayment
    //   break
    // case 'detail':
    //   ActualPage = DetailMentor
    //   break
    // case 'income':
    //   ActualPage = MentorIncome
    //   break
    // case 'payment-management':
    //   ActualPage = MentorPaymentManage
    //   break
    default:
      // ActualPage = Theme404
      ActualPage = _ComponentMentorDeveloping
      break
  }
  return (
    <div className="mainFrame">
        <ActualPage />
      {/* <div className="mainFrame">
        <div className="container">
          <Breadcrumb className="d-none d-md-block">
            <Breadcrumb.Item href={__helpers.getRootDomain("/v")}>Trang chủ</Breadcrumb.Item>
            <Breadcrumb.Item active>Quản lý mentor</Breadcrumb.Item>
          </Breadcrumb>
          {<ActualPage />}
        </div>
      </div> */}
    </div>
  )
}
