import './media/courses.list.scss'

import React, { useCallback, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'config/store'
import CoursesListItem from 'entities/courses/components/courses.list.item'
import { TypedCourse } from 'entities/courses/courses.interface'
import { clearError } from 'entities/courses/courses.store.reducer'
import CoursesListSkeleton from 'entities/courses/skeletons/courses.list.skeleton'
import helpers from 'helpers/index'
import Form from 'react-bootstrap/Form'
import { Link } from 'react-router-dom'

import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckChannelPayment from 'components/CheckChannelPayment'
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll'
import { getListCourses } from 'entities/courses/services/courses.service'
import Title from 'components/Title'
import TypedChannel from 'interfaces/channel.interface'
import _Helmet from 'components/helmet'
import __ from 'languages/index'
import BigLayout from "../../layouts/bigLayout";
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";
import { formatString } from 'entities/support/utils'

export default function CoursesList() {
  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)

  const user_data = useAppSelector(state => state.user.user_data)
  const permission_data = useAppSelector(state => state.user.permission_data)

  const dispatch = useAppDispatch()

  const rootSearchPath = window.location.search
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || {}
  const {
    data,
    hasMore,
    totalItems,
    paramRequest,
    refresh,
    loadMore,
    isRefreshing,
    updateParamRequestDebounce
  } = useInfiniteScroll<TypedCourse>(
    {
      search: '',
      limit: 12,
      auth_id: user_data?._id,
      order_by: 'DESC',
      ...StringQuery
    },
    getListCourses
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(clearError())
  }, [])

  const onChangeSearch = useCallback((event: any) => {
    updateParamRequestDebounce({ search: event?.target?.value })
  }, [])

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }, [])

  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(paramRequest)
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Courses', '/courses' + buildURLSearch)
    }
  }, [paramRequest])


  return (
    <BigLayout>
      <div id={'course-list'}>
        <_Helmet title={`${__('course_list')} ${`${totalItems}`} ${__('course_list_lesson')}`} />
        {channel_data?.official_status !== 0 ? (
          <div className="mainFrame">
            <div className="container">
              <div className="course_main_wrap">
                {helpers.current_user_can('boss', permission_data) ? (
                  <div className="course_header_list">
                    <div className="g-3 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                      <Title text={__('courses')} />
                      <div className="d-flex align-items-center course_container_search">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                        <Form>
                          <Form.Control
                            type="text"
                            placeholder={__('course_upload_search_placeholder')}
                            className="border-0"
                            onChange={onChangeSearch}
                            onKeyDown={onKeyDown}
                          />
                        </Form>
                      </div>
                    </div>

                    {helpers.current_user_can('boss', permission_data) ? (
                      <>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <div className="course_txtnumofcourse mb-1">
                          <span className="course_numofcourse">
                            {' '}
                            {`${__('course_list_total_lesson')} ${totalItems}`}
                          </span>
                          </div>

                          {helpers.current_user_can('boss', permission_data) ? (
                            <Link
                              to={'/courses/update'}
                              className="btn course_btn_add d-flex align-items-center"
                            >
                              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                              {__('course_add_title')}
                            </Link>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <h2 className="mb-4 pb-3 course_txtcourse">{__('course_title')}</h2>
                )}
                {/*{!isShow && (*/}
                {/*  <div*/}
                {/*    className="mb-4"*/}
                {/*    style={{ paddingRight: 12, paddingLeft: 12, maxWidth: 600, marginLeft: 'auto' }}*/}
                {/*  >*/}
                {/*    <PopupUpgrade*/}
                {/*      text={`còn 30 thành viên`}*/}
                {/*      url="/upgrade"*/}
                {/*      handleClickBtnClose={handleClickBtnClose}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}

                <InfiniteScrollContainer
                  isRefreshing={isRefreshing}
                  dataLength={data.length}
                  next={loadMore}
                  hasMore={hasMore}
                  refreshFunction={refresh}
                  skeleton={<CoursesListSkeleton />}
                  showEndMessage={Boolean(!data.length)}
                  textEmptyMessage= {formatString(__('data_empty'), `${__('courses').toLowerCase()}`)}
                >
                  <div className="row" id="course_wrap">
                    {data.map((courseData: TypedCourse, index) => {
                      return <CoursesListItem key={`course_loop_${index}`} course={courseData} />
                    })}
                  </div>
                </InfiniteScrollContainer>

                {/*{isRefreshing ? (*/}
                {/*  <CoursesListSkeleton />*/}
                {/*) : totalItems > 0 ? (*/}
                {/*  <InfiniteScroll*/}
                {/*    load={loadMore}*/}
                {/*    hasMore={hasMore}*/}
                {/*    loader={*/}
                {/*      <div className="px-2 py-2 text-center small text-muted">*/}
                {/*        {__('loading_more')}*/}
                {/*      </div>*/}
                {/*    }*/}
                {/*    endMessage={<div className="px-2 py-2 text-center small text-muted"></div>}*/}
                {/*  >*/}
                {/*    <div className="row" id="course_wrap">*/}
                {/*      {data.map((courseData: TypedCourse, index) => {*/}
                {/*        return <CoursesListItem key={`course_loop_${index}`} course={courseData} />*/}
                {/*      })}*/}
                {/*    </div>*/}
                {/*  </InfiniteScroll>*/}
                {/*) : (*/}
                {/*  <CoursesEmpty />*/}
                {/*)}*/}
                <br />
                <br />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <CheckChannelPayment />
          </div>
        )}
      </div>
    </BigLayout>
  )
}
