import React, {useMemo, useState} from 'react'
import {useListQuery} from './store/podcastApi'
import RenderView from 'components/RenderView'
import PodcastItem from './components/PodcastItem'
import InfiniteList from 'components/InfiniteList'
import {Link, useNavigate} from 'react-router-dom'
import {Podcast} from './podcast.types'
import {useDispatch, useSelector} from 'react-redux'
import {getPlayingPodcast, setPlayingPodcast, updatePlayingPodcastInfo} from './store/podcastSlice'
import {Container} from 'react-bootstrap'
import {faAdd} from '@fortawesome/free-solid-svg-icons'
import IconButton from 'components/IconButton'
import __ from "languages/index";
import BigLayout from "../layouts/bigLayout";

const PodCast = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const playingPodcast = useSelector(getPlayingPodcast)

  const [page, setPage] = useState<number>(1)

  const fetchParams = useMemo(() => {
    return {
      page: page
    }
  }, [page])

  const { data: podcastListData, isLoading: gettingList } = useListQuery(fetchParams)

  const podcastList = podcastListData?.data
  const totalPodcast = podcastListData?.totalCount

  const linkToDetailPodcast = (id: string) => {
    navigate(`/podcast/${id}`)
  }

  const handlePlayPodcast = (podcast: Podcast) => (isPlaying: boolean) => {
    if (podcast?._id !== playingPodcast?._id) {
      dispatch(setPlayingPodcast(podcast))
    } else {
      dispatch(
        updatePlayingPodcastInfo({
          isPlaying
        })
      )
    }
  }

  const handleGotoPodcast = (id: string) => {}

  return (
    <BigLayout>
      <Container className="py-4">
        <div className="flex">
          <h1 className="mb-3">{__("podcast_list")}</h1>
          <IconButton icon={faAdd}>
            <Link to="/podcast/create" className="text-white text-decoration-none">
              {__("podcast_create")}
            </Link>
          </IconButton>
        </div>
        <RenderView
          view={
            <InfiniteList
              list={podcastList}
              renderItem={(podcast, ref) => {
                return (
                  <div ref={ref} key={podcast._id} className="mt-2">
                    <PodcastItem {...podcast} onPlayPause={handlePlayPodcast(podcast)} />
                  </div>
                )
              }}
              hasNextPage={podcastList?.length < totalPodcast}
              onLoadMore={() => setPage(prev => prev + 1)}
            />
          }
          isEmpty={podcastList?.length === 0}
          isLoading={gettingList}
        />
      </Container>
    </BigLayout>

  )
}

export default PodCast
