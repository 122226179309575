import {useAppDispatch, useAppSelector} from 'config/store'
import helpers from 'helpers/index'
import {useEffect, useState} from 'react'
import {clearError} from './calendar.store.reducer'
import CalendarComponent from './components/calendar.component'
import _Helmet from 'components/helmet'
import TypedChannel from 'interfaces/channel.interface'
import __ from "languages/index";
import BigLayout from "../../layouts/bigLayout";

export default function CalendarList() {
  const updateSuccess = useAppSelector(state => state.calendar.updateSuccess)
    const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)


  const dispatch = useAppDispatch()

  const rootSearchPath = window.location.search
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50
    // sort: "createdAt:desc",
  }
  const [mainQuery, setMainQuery] = useState < any > ({
    ...initialQuery,
    ...StringQuery
  })

  useEffect(() => {
    dispatch(clearError())
  }, [])

  return (
    <BigLayout>
      <_Helmet title='calendar_list_title' />
      <CalendarComponent />
    </BigLayout>
  )
}
