import {
  BlockIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  CopyIcon,
  FlagIcon,
  PenIcon,
  PinOutlinedIcon,
  TrashIcon
} from 'icons'

export const POST_OPTIONS_CONFIGS = [
  {
    key: 'report',
    icon: FlagIcon,
    label: 'home_page_report_post',
    hide: true
  },
  {
    key: 'block',
    icon: BlockIcon,
    label: 'Chặn',
    hide: true
  },
  {
    key: 'copy',
    icon: CopyIcon,
    label: "home_page_post_copy_link"
  },
  {
    key: 'pin',
    icon: PinOutlinedIcon,
    label: 'home_page_pin_post'
  },
  {
    key: 'unpin',
    icon: PinOutlinedIcon,
    label: 'home_page_unpin_post'
  },
  {
    key: 'edit',
    icon: PenIcon,
    label: 'home_page_edit_post'
  },
  {
    key: 'delete',
    icon: TrashIcon,
    label: 'home_page_delete_post'
  },
  {
    key: 'hide-comment',
    icon: CloseCircleIcon,
    label: 'home_page_hidden_comment'
  },
  {
    key: 'show-comment',
    icon: CheckCircleIcon,
    label: 'home_page_turn_on_comment'
  }
]

export const POST_CATEGORIES = [
  {
    name: 'notice',
    label: 'Thông báo từ admin'
  },
  {
    name: 'discussion',
    label: 'Thảo luận'
  },
  {
    name: 'question',
    label: 'Hỏi đáp'
  },
  {
    name: 'job',
    label: 'Việc làm'
  },
  {
    name: 'event',
    label: 'Sự kiện'
  }
]
