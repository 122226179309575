import { PaletteMode, ThemeOptions } from '@mui/material'
import { lightPalette } from 'styles/themes/palettes/defaultPalete'
import { baseThemeOptions } from 'styles/themes/defaultTheme'
import { getDesignTokens } from './getDesignTokens'

export const lightThemeOptions: ThemeOptions = {
  palette: lightPalette,
  ...baseThemeOptions
}

export const darkThemeOptions: ThemeOptions = {
  palette: {
    ...lightPalette,
    mode: 'dark'
  },
  ...baseThemeOptions
}

export const getThemeOptionsByMode = (
  mode: PaletteMode,
  palettes: {
    lightPalette?: typeof lightThemeOptions.palette
    darkPalette?: typeof darkThemeOptions.palette
  } = { lightPalette: lightThemeOptions.palette, darkPalette: darkThemeOptions.palette }
) => ({
  ...getDesignTokens(mode, palettes),
  ...baseThemeOptions
})
