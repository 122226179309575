//Library
import { Box, Button, IconButton, Typography, styled } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'

//Components
import CardItem from 'entities/shop/components/shop.cardItem'

//Data
import __, { ___ } from 'languages/index'
import { ArrowRightIcon } from 'icons'
import { useNavigate } from 'react-router-dom'

const ProductCollection = ({ productCollectionData }: { productCollectionData: any }) => {
  const [isShowButtonNext, setIsShowButtonNext] = useState<boolean>(true)
  const [isShowButtonPrev, setIsShowButtonPrev] = useState<boolean>(false)
  const  navigate = useNavigate()

  const productCollection = useMemo(() => {
    return {
      ...productCollectionData,
      product_to_collection: [
        ...productCollectionData?.product_to_collection?.map(data => data?.product)
      ]
    }
  }, [productCollectionData])
  const handleClickCollection = (collection_id) =>{
    navigate(`/shop/search?product_to_collection.collection_id=${collection_id}&&sort=product_meta.product_review_count:desc`)
  }

  const ButtonNext = () => {
    const swiper = useSwiper()
    useEffect(() => {
      setIsShowButtonNext(!swiper?.isEnd || false)
    }, [swiper?.isEnd])
    return (
      <IconButton
        onClick={() => {
          setIsShowButtonPrev(true)
          typeof swiper.slideNext() === 'function' && swiper.slideNext()
        }}
        sx={{ color: theme => theme.palette.primary.dark }}
        className="button button_next"
      >
        <ArrowRightIcon color="inherit" />
      </IconButton>
    )
  }
  const ButtonPrev = () => {
    const swiper = useSwiper()
    return (
      <IconButton
        onClick={() => {
          setIsShowButtonNext(true)
          typeof swiper.slidePrev() === 'function' && swiper.slidePrev()
        }}
        sx={{ color: theme => theme.palette.primary.dark }}
        className="button button_prev"
      >
        <ArrowRightIcon sx={{ transform: 'rotate(180deg)' }} color="inherit" />
      </IconButton>
    )
  }

  return (
    <ProductCollectionContainer>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography textTransform={'none'} variant="h6">
          {productCollection?.collection_name || ''}
        </Typography>
        <Button
          onClick={()=>handleClickCollection(productCollection?.collection_id)}

          variant="text"
        >
          <Typography textTransform={'none'}>{__('header_see_all')}</Typography>
        </Button>
      </Box>

      <Swiper
        onReachEnd={() => setIsShowButtonNext(false)}
        onReachBeginning={() => setIsShowButtonPrev(false)}
        onSlideChange={e => {
          setIsShowButtonNext(!e?.isEnd)
          setIsShowButtonPrev(!e?.isBeginning)
        }}
        spaceBetween={24}
        slidesPerView={4}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation]}
      >
        {productCollection?.product_to_collection?.map((item: any, idx: number) => (
          <SwiperSlide key={`item_${idx}`}>
            <CardItem productData={item} />
          </SwiperSlide>
        ))}
        {isShowButtonNext && <ButtonNext />}
        {isShowButtonPrev && <ButtonPrev />}
      </Swiper>
    </ProductCollectionContainer>
  )
}

export default ProductCollection

const ProductCollectionContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  '& .list_card': {
    display: 'flex',
    columnGap: theme.spacing(3)
  },
  '& .button': {
    height: 'auto',
    position: 'absolute',
    top: '50%',
    transform: 'translate(50%, -50%)',
    zIndex: 1,
    borderRadius: theme.spacing(1.5),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    ':hover': {
      background: theme.palette.primary.backgroundHover
    }
  },
  '& .button_next': {
    right: theme.spacing(2.875)
  },
  '& .button_prev': {
    left: theme.spacing(-2.5)
  }
}))
