import __ from 'languages/index'

export const dataCategory = [
  { key: '1', value: `${__('channel_category_medical')}`, id: 'yte-duoc-pham' },
  { key: '2', value: `${__('channel_category_industry')}`, id: 'cong-nghiep-san-xuat' },
  { key: '3', value: `${__('channel_category_technology')}`, id: 'cong-nghe-thong-tin' },
  { key: '4', value: `${__('channel_category_bank')}`, id: 'tai-chinh-ngan-hang' },
  { key: '5', value: `${__('channel_category_education')}`, id: 'giao-duc-dao-tao' },
  { key: '6', value: `${__('channel_category_architecture')}`, id: 'xay-dung-kien-truc' },
  { key: '7', value: `${__('channel_category_forestry')}`, id: 'nong-nghiep-lam-nghiep' },
  { key: '8', value: `${__('channel_category_travel')}`, id: 'du-lich-dich-vu-luu-tru' },
  { key: '9', value: `${__('channel_category_commerce')}`, id: 'thuong-mai-ban-le' },
  { key: '10', value: `${__('channel_category_communications')}`, id: 'truyen-thong-quang-cao' },
  { key: '11', value: `${__('channel_category_environment')}`, id: 'nang-luong-moi-truong' },
  { key: '12', value: `${__('channel_category_logistics')}`, id: 'van-tai-logistics' },
  { key: '13', value: `${__('channel_category_health_care')}`, id: 'cham-soc-suc-khoe-lam-dep' },
  { key: '14', value: `${__('channel_category_food_beverage')}`, id: 'thuc-pham-do-uong' },
  {
    key: '15',
    value: `${__('channel_category_research_development')}`,
    id: 'nghien-cuu-phat-trien'
  },
  { key: '16', value: `${__('channel_category_law')}`, id: 'luat-luat-su' },
  { key: '17', value: `${__('channel_category_arts_entertainment')}`, id: 'nghe-thuat-giai-tri' },
  {
    key: '18',
    value: `${__('channel_category_asset_management')}`,
    id: 'bat-dong-san-quan-ly-tai-san'
  },
  { key: '19', value: `${__('channel_category_mechanical')}`, id: 'co-khi-dien-tu' },
  {
    key: '20',
    value: `${__('channel_category_social_services_non-Profits')}`,
    id: 'dich-vu-xa-hoi-phi-loi-nhuan'
  }
]
