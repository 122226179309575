import { TypedEvent } from "entities/calendar/calendar.interface";
import helpers from 'helpers'

export function extractGoogleMapInfo(googleMapLink: string): { placeName: string; lat: number; lng: number } | null {
    if (googleMapLink && googleMapLink.includes("https://www.google.com/maps")) {
        const latLngPattern = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
        const placePattern = /\/place\/([^\/?]+)/;

        const latLngMatches = googleMapLink.match(latLngPattern);
        const placeMatches = googleMapLink.match(placePattern);

        if (latLngMatches && latLngMatches.length >= 3 && placeMatches && placeMatches.length >= 2) {
            const lat = parseFloat(latLngMatches[1]);
            const lng = parseFloat(latLngMatches[2]);
            const placeName = decodeURIComponent(placeMatches[1]?.replace(/\+/g, ' '));

            return { placeName, lat, lng };
        } else {
            return {placeName: "", lat: 0, lng: 0};
        }
    }
    return {placeName: "", lat: 0, lng: 0};
}

export const checkDurationEvent = (time => {
    const currentTime = new Date().getTime()
    const eventTime = new Date(time)
    if (currentTime > eventTime.getTime()) {
      return true
    }
    return false
  })

 export const checkIsShowEndDate = ((item: TypedEvent) => {
    const currentTime = new Date().getTime()
    if (
      item?.end_date &&
      currentTime >= new Date(item?.end_date).getTime()
    ) {
      return true
    }
    return false
  })

export  const checkIsNotShowButtonJoin = ((item: TypedEvent, user_in_this_channel) => {
    const currentTime = new Date().getTime()
    const openDate = new Date(item?.open_date).getTime()
    if(helpers.isEmpty(user_in_this_channel)) return true;
    // if (item?.event_course?._id && !item?.event_course?.is_join) {
    //   return true
    // }
    // if (Number(user_in_this_channel?.level_number) < Number(item?.event_level)) {
    //   return true
    // }
    if (item?.livestream_id?.livestream_status === 'end') {
      // check livestream kết thúc
      return true
    }
    if (
      item?.end_date &&
      currentTime >= new Date(item?.end_date).getTime() &&
      currentTime > openDate
    ) {
      // check khi có ngày kết thúc và thời gian hiện tại lớn hơn ngày bắt đầu
      return true
    }

    if (!item?.end_date) {
      if (item?.is_recurring === 0) {
        return checkDurationEvent(item?.open_date)
      }
      if (item?.is_recurring === 1) {
        if (item?.repeat_every > 0 && item?.end_occurrences > 0) {
          let repeatEveryTime = new Date(item?.open_date)
          repeatEveryTime.setDate(
            repeatEveryTime.getDate() + item?.end_occurrences * item?.repeat_every
          )
          return checkDurationEvent(repeatEveryTime)
        }
      }
    }
    return false
  })
