import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'
import { createAsyncThunk } from '@reduxjs/toolkit'
import helpers from 'helpers/index'
import axios from 'axios'
import {ListPlan} from "entities/payment/payment.interface";
import { IRootState } from 'config/store'

export const initialState = {
  entity: {},
  entities: [],
  plans: [] as ListPlan[],
  plan: {} as ListPlan,
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false
}
const apiUrl = 'plan'
export const getListPlan = createAsyncThunk(
  'plan/list_plan',
  async (object: any) => {
    const EndURL = helpers.buildEndUrl(object)

    const requestUrl = `${apiUrl}/list-plan${EndURL}`
    return axios.get<any>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const getPlan = createAsyncThunk(
    'plan/detail_plan',
    async ({_id}:{_id: string}) => {
      const requestUrl = `${apiUrl}/detail-plan/${_id}`
      return axios.get<any>(requestUrl)
    },
    { serializeError: serializeAxiosError }
)

const PAYMENT_REDUCER = createEntitySlice({
  name: 'payment',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => ({ ...state, ...initialState }),
    clearEntity: state => {
      state.entity = null
    }
  },
  extraReducers(builder) {
    builder.addCase(getListPlan.fulfilled, (state, action) => {
      state.plans = action.payload.data
    })
    builder.addCase(getPlan.fulfilled, (state, action) => {
      state.plan = action.payload.data
    })
  }
})

export const { clearError, reset, clearEntity } = PAYMENT_REDUCER.actions

export const getPaymentPlans = (state: IRootState) => state.payment?.plans
export default PAYMENT_REDUCER.reducer
