import { Box, Button, FormControl, Grid, MenuItem, OutlinedInput, Paper, Select, Stack, styled, Typography } from "@mui/material";
import { Field, FieldBag, notEmpty, useField, useForm } from "@shopify/react-form";
import { AxiosError, AxiosResponse } from "axios";
import { useAppDispatch } from "config/store";
import { IsDefaultStock } from "entities/shop/data/shop.logictic.enum";
import { TypedProduct, TypedStock } from "entities/shop/interface";
import { getSTockes } from "entities/shop/store/shop.store.reducer";
import { validateLabel } from "entities/support/utils";
import __helpers from "helpers/index";
import { EnumTypeToast, useToast } from "hooks/useToast";
import { CheckIcon } from "icons";
import DownloadIcon from "icons/DownloadIcon";
import __, { ___ } from "languages/index";
import BigLayout from "layouts/bigLayout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import OrderDetailMethodPayment from "./components/Order.detail.methodPatment";
import OrderDetailTable from "./components/Order.detail.table";
import OrderInfomationForm from "./components/Order.infomation.form";
import { tabChildrenManager } from "./data";
import { OrderStatus, PaymentStatus, TypeActionOrderList } from "./data/order.enum";
import { assignOrderFullfillment, assignOrderUserAddress, assignProduct, assignProductChangeQuantity, assignProductDelete, getOrderdetailOrder, orderEdit, updateStatusOrder } from "./order.store.reducer";
import { Order, OrderProduct } from "./order.type";
import BoxSuggestCreateProduct from "components/BoxSuggestCreateProduct";
import ConfirmModal from "components/ConfirmModal";
import ShopBreadcrumb from "entities/shop/components/shop.breadcrumb";

export interface TypedDetailForm extends FieldBag {
    order_customer_name: Field<string>;
    order_customer_phonenumber: Field<string>;
    order_customer_address_ward: Field<string>;
    order_customer_address_district: Field<string>;
    order_customer_address_city: Field<string>;
    order_customer_address: Field<string>;
    customer_note: Field<string>;
    order_status: Field<OrderStatus>;
    stock_id: Field<string>;
    logistic_id: Field<string>;
    order_delivery_type: Field<string>;
    payment_status: Field<PaymentStatus>;
    order_voucher: Field<string>;
}

const OrderDetailManager = () => {
    const { productId } = useParams();
    const [detailOrder, setDetailOrder] = useState<Order>(null);
    const [stockes, setStockes] = useState<TypedStock[]>([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalOriginPrice, setOriginTotalPrice] = useState(0)
    const [showModalWarningChangeStock, setShowModalWarningChangeStock] = useState(false);
    const [productIsNotEnough, setProductIsNotEnough] = useState<OrderProduct[]>([]);
    const [stockIdSelected, setStockIdSelected] = useState('')
    const [adressDetail, setAdresDetail] = useState('')


    const dispatch = useAppDispatch();
    const toast = useToast();
    const navigate = useNavigate()
    const useFields: TypedDetailForm = {
        order_customer_name: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("name_partner")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('name_partner'))?.required

                    }
                }
            ]
        }),
        order_customer_phonenumber: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("phone_number")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('phone_number'))?.required

                    }

                    if (!__helpers.isPhoneNumber(inputVal)) {
                        return `${__('validate_phone')}`
                    }
                },
            ]
        }),
        order_customer_address_ward: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_ward")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_ward'))?.required

                    }
                }
            ]
        }),
        order_customer_address_district: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_district")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_district'))?.required

                    }
                }
            ]
        }),
        order_customer_address_city: useField<string>({
            value: '',
            validates: [
                notEmpty(validateLabel(`${__("select_city")}`)?.required),
                inputVal => {
                    if (!inputVal) {
                        return validateLabel(__('select_city'))?.required

                    }
                }
            ]
        }),
        order_customer_address: useField<string>({
            value: '',
            validates: []
        }),
        customer_note: useField<string>({
            value: '',
            validates: []
        }),
        order_status: useField<OrderStatus>({
            value: OrderStatus.Pending,
            validates: []
        }),
        // kho
        stock_id: useField<string>({
            value: '',
            validates: []
        }),

        logistic_id: useField<string>({
            value: '',
            validates: []
        }),

        order_delivery_type: useField<string>({
            value: '',
            validates: []
        }),
        payment_status: useField<PaymentStatus>({
            value: PaymentStatus.UNPAID,
            validates: []
        }),
        order_voucher: useField<string>({
            value: '',
            validates: []
        })
    }

    const { fields, submit, submitting } = useForm<TypedDetailForm>({
        fields: useFields,
        async onSubmit(values) {
            try {
                const actionListOrder = [dispatch(assignOrderUserAddress({
                    order_id: productId,
                    order_customer_address_city: values.order_customer_address_city,
                    order_customer_address_district: values.order_customer_address_district,
                    order_customer_address_ward: values.order_customer_address_ward,
                    order_customer_name: values.order_customer_name,
                    order_customer_phonenumber: values.order_customer_phonenumber,
                    order_customer_address: values.order_customer_address,
                    customer_note: values.customer_note
                })).unwrap()
                    .catch((err) => {
                        toast.show({
                            type: EnumTypeToast.Error, title: 'Error', content: __('edit_information_error')
                        })
                    }),
                dispatch(assignOrderFullfillment({
                    order_id: productId,
                    logistic_id: +values.logistic_id
                })).unwrap().catch((err) => {
                    toast.show({
                        type: EnumTypeToast.Error, title: 'Error', content: __('edit_fullfillment_error')
                    })
                }),
                dispatch(orderEdit({
                    order_id: productId,
                    order_delivery_type: values.order_delivery_type
                }))

                ];




                detailOrder.orders_to_product.forEach((res) => {
                    if (res.type === TypeActionOrderList.ADD) {
                        actionListOrder.push(
                            dispatch(assignProduct({
                                item_quantity: res.item_quantity,
                                order_id: productId,
                                product_id: res.product.product_id
                            })).unwrap().catch((err) => {
                                toast.show({
                                    type: EnumTypeToast.Error, title: 'Error', content: __('add_order_product_error')
                                })
                            })
                        )
                    }

                    if (res.type === TypeActionOrderList.UPDATE) {
                        actionListOrder.push(
                            dispatch(assignProductChangeQuantity({
                                item_quantity: res.item_quantity,
                                order_product_id: res.order_product_id
                            })).unwrap().catch((err) => {
                                toast.show({
                                    type: EnumTypeToast.Error, title: 'Error', content: __('edit_quantity_error')
                                })
                            })
                        )
                    }

                    if (res.type === TypeActionOrderList.DELETE) {
                        actionListOrder.push(
                            dispatch(assignProductDelete({
                                order_product_id: res.order_product_id,
                                order_id: res.order_id,
                            })).unwrap().catch((err) => {
                                toast.show({
                                    type: EnumTypeToast.Error, title: 'Error', content: __('delete_product')
                                })
                            })
                        )
                    }
                })

                const promiseAll = await Promise.all(actionListOrder);
                if (!promiseAll.includes(undefined)) {
                    if (detailOrder.order_status !== OrderStatus.Delivered && detailOrder.order_status !== OrderStatus.ReturnInitated && detailOrder.order_status !== values.order_status) {
                        dispatch(updateStatusOrder({
                            idOrder: productId,
                            body: {
                                order_status: values.order_status
                            }
                        })).unwrap()
                            .catch((error: AxiosError) => {
                                return toast.show({
                                    content: `${__('about_page_update_info_failed')}`,
                                    type: EnumTypeToast.Error
                                })
                            })
                    }

                    toast.show({
                        content: `${__("edit_order_success")}`,
                        type: EnumTypeToast.Success
                    })
                    navigate('/shop/manage-order')
                }

                return { status: 'success' }

            } catch (e: any) {
                console.error(`Submit error`, e)
                const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
                const field = e?.response?.data?.errorKey ?? 'base'
                return { status: 'fail', errors: [{ field, message }] }
            }
        }
    })

    useEffect(() => {
        dispatch(getOrderdetailOrder({
            _id: productId
        })).unwrap()
            .then((res) => {
                fields.stock_id.onChange(res.data?.stock?.stock_id)
                fields.order_delivery_type.onChange(res.data.order_delivery_type)
                fields.payment_status.onChange(res.data.payment_status)
                fields.order_status.onChange(`${res.data.order_status}` as OrderStatus)

                setDetailOrder({
                    ...res.data,
                    orders_to_product: res.data.orders_to_product.map((res) => ({ ...res, type: TypeActionOrderList.UPDATE }))
                })

                setTotalPrice(res.data.order_total_price)
                setOriginTotalPrice(res.data.order_total_original_price)

            })
            .catch((err: AxiosError) => {
                console.log('error', err)
            })

        dispatch(getSTockes({
            limit: 100
        }))
            .unwrap()
            .then((res) => {
                setStockes(res.data)
                const defaultStock = res.data.find((item) => item.is_default_stock === IsDefaultStock.DEFAULT);

            })
            .catch((err: AxiosError) => {
                console.log('get stockes', err)
            })
    }, [productId])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            const confirmationMessage = 'Thông tin chưa được lưu,bạn có muốn tải lại trang không?';
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };


        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);


    useEffect(() => {
        if (productIsNotEnough.length) {
            setShowModalWarningChangeStock(true)
        }
    }, [productIsNotEnough])

    const handleRemoveProductFromOrderList = (product_id) => {

        const findItem = detailOrder.orders_to_product.find((item) => item.product.product_id === product_id);
        let dataNew: OrderProduct[] = detailOrder.orders_to_product
        if (findItem.type === TypeActionOrderList.ADD) {
            dataNew = dataNew.filter((res) => res.product.product_id !== product_id)
        } else {
            dataNew = detailOrder.orders_to_product.map((item) => {
                if (item.product.product_id === product_id) {
                    return {
                        ...item,
                        type: TypeActionOrderList.DELETE
                    }
                }

                return item
            });
        }

        setDetailOrder({
            ...detailOrder,
            orders_to_product: dataNew
        })

        updatePriceTotal(dataNew)
    }

    const handleChangeQuantityProduct = (product_id, value) => {

        const findProduct = detailOrder.orders_to_product.find((res) => res.product.product_id === product_id);
        const findStock = findProduct.product.product_to_stock.find((res) => res.stock_id === fields.stock_id.value);

        if (!findStock && value > findProduct.item_quantity || value > findStock?.product_quantity) {
            toast.show({
                type: EnumTypeToast.Error, title: 'Error', content: __('product_in_stock_not_enough')
            })
        } else {
            const dataNew = detailOrder.orders_to_product.map((item) => {
                if (item.product.product_id === product_id) {
                    return {
                        ...item,
                        item_quantity: value
                    }
                }
                return item;
            });



            setDetailOrder({
                ...detailOrder,
                orders_to_product: dataNew
            })

            updatePriceTotal(dataNew)
        }
    }

    const updatePriceTotal = (dataNew: OrderProduct[]) => {
        let totalPrice = 0;
        let totalOriginPrice = 0;

        dataNew.forEach((res) => {
            if (res.type !== TypeActionOrderList.DELETE) {
                totalOriginPrice += res.product_original_price * res.item_quantity;
                totalPrice += res.product_price * res.item_quantity
            }
        })

        setTotalPrice(totalPrice)
        setOriginTotalPrice(totalOriginPrice)
    }


    const handleSelectItemProduct = (product: TypedProduct) => {
        let dataNew = detailOrder.orders_to_product
        const productAlreadyExist = detailOrder.orders_to_product.find((item) => item.product.product_id === product.product_id);
        if (productAlreadyExist) {

            if (productAlreadyExist.type !== TypeActionOrderList.DELETE) {
                dataNew = detailOrder.orders_to_product.map((res) => {
                    if (res.product.product_id === product.product_id) {
                        return {
                            ...res,
                            item_quantity: res.item_quantity + 1
                        }
                    }

                    return res;
                })
            } else {

                if (productAlreadyExist.order_product_id) {
                    dataNew = [
                        {
                            ...productAlreadyExist,
                            item_quantity: 1,
                            type: TypeActionOrderList.UPDATE
                        },
                        ...dataNew.filter((res) => res.order_product_id !== productAlreadyExist.order_product_id)
                    ]
                } else {
                    dataNew = [
                        {
                            ...productAlreadyExist,
                            item_quantity: 1,
                            type: TypeActionOrderList.ADD
                        },
                        ...dataNew.filter((res) => res.product_id !== productAlreadyExist.product_id)
                    ]
                }
            }


        } else {
            const data: OrderProduct = {
                item_quantity: 1,
                product_original_price: product.product_original_price,
                product_price: product.product_price,
                product: product,
                type: TypeActionOrderList.ADD
            }

            dataNew.unshift(data)
        }

        setDetailOrder({
            ...detailOrder,
            orders_to_product: dataNew
        })

        updatePriceTotal(dataNew)
    }

    const handleChangeStock = (stock_id) => {
        const productInOrder = detailOrder.orders_to_product;
        const productInStockIsNotEnough: OrderProduct[] = [];
        productInOrder.forEach((item) => {
            const findStock = item.product.product_to_stock.find((stock) => stock.stock_id === stock_id);
            if (!findStock || findStock?.product_quantity < item.item_quantity) {
              productInStockIsNotEnough.push(item)
            } else {
                fields.stock_id.onChange(stock_id)
            }
        });

        if (productInStockIsNotEnough.length) {
            setStockIdSelected(stock_id)
            setProductIsNotEnough(productInStockIsNotEnough)
        } else {
            fields.stock_id.onChange(stock_id)
        }

    }

    const handlePrintBill = () => {
        window.open('/print/order/' + productId)
    }

    return (
        <BigLayout>
            <ShopBreadcrumb
                breadcrumbName={{
                    [`/shop/order-detail-manager/${productId}`]: __('manager_detail_order_admin')
                }}
            />
            <DetailContainer>
                {
                    detailOrder && (
                        <>
                            <Stack className="box_header">
                                <Typography variant="h5">{___('detail_order {code}', {
                                    code: <>{detailOrder.order_pnr}</>
                                })}</Typography>

                                <Stack className="btn">
                                    <Button onClick={handlePrintBill} variant="outlined" >
                                        <DownloadIcon fill="#0E1426" />
                                        {__('print')}
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        if (detailOrder.order_status !== OrderStatus.Delivered && detailOrder.order_status !== OrderStatus.ReturnInitated && detailOrder.order_status !== OrderStatus.Cancelled) {
                                            submit();
                                        } else {
                                            toast.show({
                                                type: EnumTypeToast.Warning, title: 'Warning', content: __('warning_order_is_success')
                                            })
                                        }
                                      }}
                                      disabled={
                                              submitting ||
                                              !detailOrder.orders_to_product.length ||
                                              detailOrder.order_status === OrderStatus.Delivered ||
                                              detailOrder.order_status === OrderStatus.ReturnInitated ||
                                              detailOrder.order_status === OrderStatus.Cancelled}>
                                        <CheckIcon />
                                        {__('save_order')}
                                    </Button>
                                </Stack>
                            </Stack>

                            <Grid className="info_order" container  >
                                <Grid xs={7} >
                                    <OrderInfomationForm fields={fields} orderToAddress={detailOrder.orders_to_address} />
                                </Grid>
                                <Grid sx={{
                                    marginLeft: theme => theme.spacing(2)
                                }} xs={4.8} >
                                    <OrderDetailMethodPayment
                                        fields={fields}
                                        stockes={stockes}
                                        logisticCode ={detailOrder?.order_pnr}
                                        orderTotalPrice={totalPrice}
                                        orderFee={detailOrder?.order_total_fee}
                                        orderTotalOriginPrice={totalOriginPrice}
                                    />
                                </Grid>
                            </Grid>

                            <Grid className="control_list_item_order" container  >
                                {/* stock */}
                                <Grid xs={7} >
                                    <Stack flexDirection={"row"} alignItems="center">
                                        <FormControl sx={{
                                            width: '30%'
                                        }} >
                                            {
                                                stockes?.length ? (
                                                    <Select

                                                        MenuProps={{
                                                            elevation: 1,
                                                            sx: {
                                                                maxHeight: theme => theme.spacing(37.5),
                                                                width: '10%'
                                                            }

                                                        }}
                                                        displayEmpty

                                                        required
                                                        defaultValue={fields.stock_id?.value}
                                                        value={fields.stock_id?.value}
                                                        onChange={(e) => handleChangeStock(e.target.value)}
                                                        input={<OutlinedInput />}
                                                        className='payment-modal-select'
                                                        sx={{
                                                            padding: theme => theme.spacing(1, 2),
                                                            backgroundColor: theme => theme.palette.background.default,
                                                            borderRadius: theme => theme.spacing(1.5),
                                                        }}
                                                        inputProps={{ 'aria-label': 'Without label' }}

                                                    >
                                                        {stockes.map((stock) => (
                                                            <MenuItem
                                                                key={stock?.stock_id}
                                                                value={stock?.stock_id}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        whiteSpace: 'nowrap',
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden'
                                                                    }}
                                                                    variant="body1"
                                                                >
                                                                    {stock?.stock_name}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                ) : null
                                            }

                                        </FormControl>

                                        <BoxSuggestCreateProduct stock_id={fields.stock_id?.value} handleSelectItemProduct={handleSelectItemProduct} />
                                    </Stack>
                                </Grid>
                                <Grid sx={{
                                    marginLeft: theme => theme.spacing(2)
                                }} xs={4.8} >
                                    <FormControl sx={{
                                        width: '100%'
                                    }} >
                                        <Select

                                            MenuProps={{
                                                elevation: 1,
                                                sx: {
                                                    maxHeight: theme => theme.spacing(37.5)
                                                }

                                            }}
                                            displayEmpty
                                            required
                                            disabled={detailOrder.order_status === OrderStatus.Delivered || detailOrder.order_status === OrderStatus.ReturnInitated || detailOrder.order_status === OrderStatus.Cancelled}
                                            defaultValue={fields.order_status.value}
                                            value={fields.order_status.value}
                                            onChange={(e) => fields.order_status.onChange(e.target.value as OrderStatus)}
                                            input={<OutlinedInput />}
                                            className='payment-modal-select'
                                            sx={{
                                                padding: theme => theme.spacing(1, 2),
                                                backgroundColor: theme => theme.palette.background.default,
                                                borderRadius: theme => theme.spacing(1.5),
                                                width: '100%'
                                            }}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                {__("status_order")}
                                            </MenuItem>
                                            {tabChildrenManager.slice(1, tabChildrenManager.length).map((statusOrder) => (
                                                <MenuItem
                                                    key={statusOrder?.value}
                                                    value={statusOrder?.value}
                                                >
                                                    {statusOrder?.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {fields.order_status?.error && (
                                            <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.order_status?.error}</Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Box className="table_item_product">
                                <OrderDetailTable
                                    orderProducts={detailOrder?.orders_to_product}
                                    handleRemoveProductFromOrderList={handleRemoveProductFromOrderList}
                                    handleChangeQuantityProduct={handleChangeQuantityProduct}
                                />
                            </Box>
                            <ConfirmModal

                                onClose={() => {
                                    setProductIsNotEnough([])
                                    setStockIdSelected('')
                                    setShowModalWarningChangeStock(false)
                                }}
                                open={showModalWarningChangeStock}
                                onConfirm={() => {
                                    let dataNew = detailOrder.orders_to_product;
                                    productIsNotEnough.forEach((res) => {
                                        dataNew = dataNew.filter((item) => item.product.product_id !== res.product.product_id)
                                    })

                                    setDetailOrder({
                                        ...detailOrder,
                                        orders_to_product: dataNew
                                    })

                                    updatePriceTotal(dataNew)
                                    setProductIsNotEnough([])
                                    fields.stock_id.onChange(stockIdSelected)
                                    setShowModalWarningChangeStock(false)
                                }}
                                title={__('btn_confirm')}
                                cancelTextBtn={__('home_btn_cancel')}
                            >
                                <Typography>
                                    {___('notice warning {name} remove', {
                                        name: <>{productIsNotEnough.map((item) => item.product.product_name).join(', ')}</>
                                    })}
                                </Typography>

                            </ConfirmModal>
                        </>
                    )
                }
            </DetailContainer>
        </BigLayout>
    )
}

export default OrderDetailManager


const DetailContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),

    '& .box_header': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& .btn': {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',

            '& button': {
                fontSize: '1rem',

                '& svg': {
                    marginRight: theme.spacing(0.5)
                }
            },

            '& button:first-child': {
                margin: theme.spacing(0, 1),
                borderColor: theme.palette.divider
            }
        }
    },
    '& .info_order': {
        margin: theme.spacing(2, 0),
    },
    '& .control_list_item_order': {
        '& fieldset': {
            border: 'none'
        },
        '& .box_search': {
            background: '#F3F4F5',
            padding: theme.spacing(1.5, 2),
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: theme.spacing(1.5),
            marginLeft: theme.spacing(2.5),
            width: '50%',
            position: 'relative',

            '& .search_input': {
                paddingLeft: theme.spacing(2)
            },

            '& .list_item': {
                position: 'absolute',
                width: '100%',
                top: theme.spacing(5.8),
                left: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                padding: '16px',
                minHeight: theme.spacing(3),
                zIndex: 1,
                maxHeight: '300px',
                overflowY: 'scroll',

                '& .item_product': {
                    padding: theme.spacing(1, 2),

                    '&:hover': {
                        backgroundColor: "rgba(220, 53, 69, 0.08)",
                        cursor: 'pointer'
                    }
                }
            }
        }
    },

}))
