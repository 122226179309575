import React, { useEffect, useState } from "react";
import { MediaResponse, uploadMedia } from "entities/calendar/service";

const useUploadMedia = (file: File) => {
  const [media, setMedia] = useState<MediaResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const uploadFile = async () => {
      try {
        setLoading(true);
        const res = await uploadMedia(file);
        setMedia(res[0]);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    uploadFile();
  }, [file]);

  return { media, loading };
};

export default useUploadMedia;

