import React, {useCallback, useImperativeHandle, useRef, useState} from "react"
import SearchSelect from "react-select"
import __ from "languages/index";


interface TypedList {
  value: string,
  label: string
}

interface Props {
  required: boolean,
  onChange: (data) => void
}

const SearchSelectComponent = (props: Props, ref) => {
  const {required, onChange} = props

  useImperativeHandle(ref, () => ({
    show: ({ list }) => updateData(list),
    setDefaultValue: ({_id, label}) => setDefault(_id, label)
  }))

  // Refs
  const searchSelectRef = useRef(null)
  // ========================================

  // State
  const [list, setList] = useState<TypedList[]>([])
  const [defaultValue, setDefaultValue] = useState<TypedList>({value: "", label: `${__("calendar_chose_course")}`})

  // ========================================

  // Function

  const _onDataChange = useCallback((data) => {
    setDefaultValue(data)
    onChange(data)
  }, [])

  const setDefault = useCallback((_id: string, label: string) => {
    console.log("_id", _id);
    setTimeout(() => {
      setDefaultValue({
        value: _id + "",
        label
      })
    }, 200)
  }, [list])

  const updateData = useCallback((data) => {

    const tempArr: TypedList[] = []
    if (Array.isArray(data) && data.length > 0) {
      data.map(item => {
        const temp: TypedList = {
          value: item?._id,
          label: item?.title
        }
        tempArr.push(temp)
      })
    }
    setList(tempArr)
  }, [])
  // ========================================

  return (
    <SearchSelect options={list} value={defaultValue} required={required} ref={searchSelectRef} onChange={_onDataChange} />
  )
}


export default React.forwardRef(SearchSelectComponent)
