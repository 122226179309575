import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AxiosResponse } from 'axios'
import debounce from 'lodash.debounce'
import { uniqBy } from 'lodash'
import { useAppDispatch } from 'config/store'
interface TypedUseInfiniteScroll<T> {
  data: T[]
  nextPage: number
  hasMore: boolean
  isFirstLoading: boolean
  isLoading: boolean
  isRefreshing: boolean
  totalItems: number
  loadMore: () => void
  loadMoreFromLastItem: () => void
  updateParamRequest: (newParam: object) => void
  updateParamRequestDebounce: (newParam: object) => void
  setParamRequest: (newParam: object) => void
  setParamRequestDebounce: (newParam: object) => void
  refresh: (isBackground?: boolean) => void
  paramRequest: any
  addTail: (newItem: T) => void
  addHead: (newItem: T) => void
  updateItem: (item: T) => void
  updateItemAttribute: (item: T) => void
  deleteItemById: (itemId: string) => void
  updateData: (newData: T[]) => void
  headers: object
}

interface TypedStateData<T> {
  data: T[]
  nextPage: number
  isFirstLoading: boolean
  isLoading: boolean
  hasMore: boolean
  isRefreshing: boolean
  totalItems: number
  hasError: boolean
  headers: object
}

const DEFAULT_NO_PROPS = 'eivbib84iekstv4vb'

export function useInfiniteScroll<T>(
  { keyAttribute, ...rest }: any,
  requestData: (param: object) => Promise<AxiosResponse<T[], any>>,
  autoFirstLoad: boolean = true,
  dataFromStore: any = DEFAULT_NO_PROPS,
  fullFillFn?: Function,
  pendingFn?: Function,
  rejectFn?: Function
): TypedUseInfiniteScroll<T> {
  const dispatch = useAppDispatch()
  const [paramRequest, setParamRequest] = useState<any>({ limit: 10, ...rest })
  const refKeyAttribute = useRef(keyAttribute || '_id')
  const refIgnoreFirstTime = useRef(autoFirstLoad)
  const isUseStore = useMemo(() => dataFromStore !== DEFAULT_NO_PROPS, [])
  const isHasFullFill = useMemo(() => typeof fullFillFn === 'function', [])
  const isHasPending = useMemo(() => typeof pendingFn === 'function', [])
  const isHasReject = useMemo(() => typeof rejectFn === 'function', [])
  const [stateData, setStateData] = useState<TypedStateData<T>>({
    data: [],
    nextPage: 1,
    isFirstLoading: true,
    isLoading: true,
    hasMore: false,
    isRefreshing: true,
    totalItems: 0,
    hasError: false,
    headers: {}
  })

  const refParamRequest = useRef(paramRequest)

  useEffect(() => {
    refParamRequest.current = paramRequest
    if (refIgnoreFirstTime.current) {
      refresh()
    } else {
      refIgnoreFirstTime.current = true
    }
  }, [paramRequest])

  const refresh = async (isBackground?: boolean) => {
    if (!isBackground) {
      setStateData(oldState => ({ ...oldState, isRefreshing: true, isLoading: true }))
    }

    try {
      if (isHasPending) {
        dispatch(pendingFn())
      }
      let responseData = await requestData({ ...refParamRequest.current, page: 1 })
      if (Array.isArray(responseData.data)) {
        let hasMore = true
        let nextPage = 1

        if (responseData.data.length < refParamRequest.current?.limit) {
          hasMore = false
        } else {
          nextPage = 2
        }

        setStateData(oldState => ({
          ...oldState,
          hasMore,
          nextPage,
          data: isUseStore ? [] : uniqBy(responseData.data, refKeyAttribute?.current),
          isFirstLoading: false,
          isLoading: false,
          isRefreshing: false,
          totalItems: Number(responseData?.headers?.['x-total-count'] || 0),
          hasError: false,
          headers: responseData.headers
        }))

        if (isUseStore && isHasFullFill) {
          dispatch(fullFillFn(uniqBy(responseData.data, refKeyAttribute?.current)))
        }
      } else {
        throw 'error load list'
      }
    } catch (error) {
      console.log(error, 'sdkhbfkhsdbf')
      setStateData(oldState => ({
        ...oldState,
        hasMore: false,
        nextPage: 1,
        data: [],
        isFirstLoading: false,
        isLoading: false,
        isRefreshing: false,
        totalItems: 0,
        hasError: true,
        headers: {}
      }))

      if (isUseStore && isHasReject) {
        dispatch(rejectFn([]))
      }
    }
  }

  async function loadMore() {
    if (stateData.hasMore) {
      try {
        setStateData(oldState => ({ ...oldState, isLoading: true }))

        if (isHasPending) {
          dispatch(pendingFn())
        }

        let responseData = await requestData({
          ...refParamRequest.current,
          page: stateData.nextPage
        })
        if (Array.isArray(responseData.data)) {
          let hasMore = true
          let nextPage = stateData.nextPage
          if (responseData.data.length < refParamRequest.current?.limit) {
            hasMore = false
          } else {
            nextPage = stateData.nextPage + 1
          }

          setStateData(oldState => ({
            ...oldState,
            hasMore,
            nextPage,
            data: isUseStore
              ? []
              : uniqBy([...stateData.data, ...responseData.data], refKeyAttribute?.current),
            isRefreshing: false,
            isLoading: false,
            totalItems: Number(responseData?.headers?.['x-total-count'] || 0),
            hasError: false,
            headers: responseData.headers
          }))

          if (isUseStore && isHasFullFill) {
            dispatch(
              fullFillFn(uniqBy([...dataFromStore, ...responseData.data], refKeyAttribute?.current))
            )
          }
        } else {
          throw 'loi load more list'
        }
      } catch (error) {
        console.log(error, 'sljfjsdbnf')
        setStateData(oldState => ({
          ...oldState,
          hasMore: false,
          isRefreshing: false,
          isLoading: false,
          hasError: true,
          headers: {}
        }))
      }
    }
  }

  async function loadMoreFromLastItem() {
    if (stateData.hasMore) {
      try {
        setStateData(oldState => ({ ...oldState, isLoading: true }))
        if (isHasPending) {
          dispatch(pendingFn())
        }

        let responseData = await requestData({
          ...refParamRequest.current,
          ...(stateData.data.length > 0
            ? { to_id: stateData.data[stateData.data.length - 1]?.[refKeyAttribute?.current] }
            : { page: stateData.nextPage })
        })
        if (Array.isArray(responseData.data)) {
          let hasMore = true
          let nextPage = stateData.nextPage

          if (responseData.data.length < refParamRequest.current?.limit) {
            hasMore = false
          } else {
            nextPage = stateData.nextPage + 1
          }

          setStateData(oldState => ({
            ...oldState,
            hasMore,
            nextPage,
            data: isUseStore
              ? []
              : uniqBy([...stateData.data, ...responseData.data], refKeyAttribute?.current),
            isRefreshing: false,
            isLoading: false,
            totalItems: Number(responseData?.headers?.['x-total-count'] || 0),
            hasError: false,
            headers: responseData.headers
          }))

          if (isUseStore && isHasFullFill) {
            dispatch(
              fullFillFn(uniqBy([...dataFromStore, ...responseData.data], refKeyAttribute?.current))
            )
          }
        } else {
          throw 'loi load more list'
        }
      } catch (error) {
        console.log(error, 'sljfjsdbnf')
        setStateData(oldState => ({
          ...oldState,
          hasMore: false,
          isRefreshing: false,
          isLoading: false,
          hasError: true,
          headers: {}
        }))
      }
    }
  }

  const updateParamRequest = useCallback((newParam: object) => {
    setParamRequest(oldParam => ({ ...oldParam, ...newParam }))
  }, [])

  const updateParamRequestDebounce = useMemo(() => {
    return debounce((newParam: object) => {
      setParamRequest((oldParam: object) => ({ ...oldParam, ...newParam }))
    }, 500)
  }, [])

  const setParamRequestDebounce = useMemo(() => {
    return debounce((newParam: object) => {
      setParamRequest(newParam)
    }, 500)
  }, [])

  const addHead = (newItem: T) => {
    if (isUseStore) {
      if (isHasFullFill) {
        dispatch(fullFillFn(uniqBy([newItem, ...dataFromStore], refKeyAttribute?.current)))
      }
    } else {
      setStateData(oldState => ({
        ...oldState,
        data: uniqBy([newItem, ...oldState.data], refKeyAttribute?.current)
      }))
    }
  }

  const updateData = (newData: T[]) => {
    if (isUseStore) {
      if (isHasFullFill) {
        dispatch(fullFillFn(uniqBy([...newData], refKeyAttribute?.current)))
      }
    } else {
      setStateData(prevState => ({
        ...prevState,
        data: uniqBy([...newData], refKeyAttribute?.current)
      }))
    }
  }

  const addTail = (newItem: T) => {
    if (isUseStore) {
      if (isHasFullFill) {
        dispatch(fullFillFn(uniqBy([...dataFromStore, newItem], refKeyAttribute?.current)))
      }
    } else {
      setStateData(oldState => ({
        ...oldState,
        data: uniqBy([...oldState.data, newItem], refKeyAttribute?.current)
      }))
    }
  }

  const updateItem = (item: T) => {
    if (isUseStore) {
      if (isHasFullFill) {
        dispatch(
          fullFillFn(
            dataFromStore.map(oldItem => {
              if (oldItem?.[refKeyAttribute?.current] === item?.[refKeyAttribute?.current]) {
                return item
              }
              return oldItem
            })
          )
        )
      }
    } else {
      setStateData(oldData => ({
        ...oldData,
        data: oldData.data.map(oldItem => {
          if (oldItem?.[refKeyAttribute?.current] === item?.[refKeyAttribute?.current]) {
            return item
          }
          return oldItem
        })
      }))
    }
  }

  const updateItemAttribute = (item: T) => {
    if (isUseStore) {
      if (isHasFullFill) {
        dispatch(
          fullFillFn(
            dataFromStore.map(oldItem => {
              if (oldItem?.[refKeyAttribute?.current] === item?.[refKeyAttribute?.current]) {
                return { ...oldItem, ...item }
              }
              return oldItem
            })
          )
        )
      }
    } else {
      setStateData(oldData => ({
        ...oldData,
        data: oldData.data.map(oldItem => {
          if (oldItem?.[refKeyAttribute?.current] === item?.[refKeyAttribute?.current]) {
            return { ...oldItem, ...item }
          }
          return oldItem
        })
      }))
    }
  }

  const deleteItemById = (itemId: string) => {
    if (isUseStore) {
      if (isHasFullFill) {
        dispatch(
          fullFillFn(
            dataFromStore.filter(oldItem => oldItem?.[refKeyAttribute?.current] !== itemId)
          )
        )
      }
    } else {
      setStateData(oldData => ({
        ...oldData,
        data: oldData.data.filter(oldItem => oldItem?.[refKeyAttribute?.current] !== itemId)
      }))
    }
  }

  return {
    paramRequest,
    data: stateData.data,
    nextPage: stateData.nextPage,
    hasMore: stateData.hasMore,
    isRefreshing: stateData.isRefreshing,
    loadMore,
    loadMoreFromLastItem,
    refresh,
    isFirstLoading: stateData.isFirstLoading,
    isLoading: stateData.isLoading,
    totalItems: stateData.totalItems,
    headers: stateData.headers,
    updateParamRequest,
    setParamRequest,
    updateParamRequestDebounce,
    setParamRequestDebounce,
    addTail,
    addHead,
    updateItemAttribute,
    updateItem,
    updateData,
    deleteItemById
  }
}
