import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import ImgBlur from "entities/courses/media/img_blur.png";
import { uploadMedia } from "entities/calendar/service";
import { TypedPublicAlbum } from "entities/calendar/calendar.interface";
import { useAppDispatch } from "config/store";
import { createGiftChallenge, getListGiftChallenge } from "entities/challenge/challenge.store.reducer";
import __ from "languages/index";
import { EnumTypeToast, useToast } from "../../../../hooks/useToast";
import { Box, Modal, Stack, Typography, styled, Button, Tooltip } from "@mui/material";
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from "components/Modal";
import { UploaderIcon } from "icons";
import imageDefaultGift from '../../media/img/img_default_gift.png'
interface Props {
  refresh?: (isBackground?: boolean) => void
}

const GiftModalComponent = (props: Props, ref) => {
  const { refresh } = props
  const dispatch = useAppDispatch()

  // Refs Input
  const titleGiftInputRef = useRef(null);
  const moneyGiftInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  const toast = useToast()
  // ==========================================

  // Refs
  const avatarRef = useRef<TypedPublicAlbum>(null);
  const titleGiftRef = useRef<string>("");
  const moneyGiftRef = useRef<string>("");
  const descriptionRef = useRef<string>("");
  // ==========================================

  // useEffect
  useImperativeHandle(ref, () => ({
    show: handleShow,
  }));
  // ========================================

  // State
  const [show, setShow] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [validate, setValidate] = useState<boolean>(false);
  const [loadingAvatar, setLoadingAvatar] = useState<boolean>(false);
  // ==========================================

  // Function
  const handleShow = useCallback(() => {
    setShow(true);
    setValidate(false);
    setAvatarUrl("");
    if (titleGiftInputRef.current) {
      titleGiftInputRef.current.value = "";
    }
    if (moneyGiftInputRef.current) {
      moneyGiftInputRef.current.value = "";
    }
    if (descriptionInputRef.current) {
      descriptionInputRef.current.value = "";
    }
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const _onChooseImageChange = useCallback(async (event) => {
    if (event.target.files[0]) {
      setLoadingAvatar(true);
      setAvatarUrl(ImgBlur);
      const link = URL.createObjectURL(event.target.files[0]);
      try {
        const res: any = await uploadMedia(event.target.files[0])

        if (Array.isArray(res) && res.length > 0) {
          avatarRef.current = res[0]?.callback
        }
      } catch (error) {
        console.log("error_234234", error);
      }
      setAvatarUrl(link);
      setLoadingAvatar(false);
    }
  }, []);

  const _onTitleGiftChange = useCallback((event) => {
    titleGiftRef.current = event.target.value;
  }, []);

  const _onMoneyGiftChange = useCallback((event) => {
    const value = event.target.value || ''
    if (value.match(/\D+/g)) {
      moneyGiftInputRef.current.value = moneyGiftRef.current
    } else {
      moneyGiftRef.current = value;
      moneyGiftInputRef.current.value = value;
    }
  }, []);

  const _onDescriptionChange = useCallback((event) => {
    descriptionRef.current = event.target.value;
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
      handleClose();
      const res: any = await dispatch(createGiftChallenge({ name: titleGiftRef.current, price: moneyGiftRef.current, media_id: avatarRef.current?._id, description: descriptionRef.current }))
      if (res?.payload?.data) {
        toast.show({
          title: `${__("notification")}`,
          content: `${__("challenge_add_gift_success")}`,
          type: EnumTypeToast.Success
        })
        if (res?.payload?.data) {
          refresh()
        }
      }
    }
    setValidate(true);
  };
  // ==========================================

  return (
    <>
      <GiftModalContainer open={show} onClose={handleClose}>
        <ModalContainer>
          <Form className="needs-validation" noValidate validated={validate} onSubmit={handleSubmit}>
            <ModalHeader>
              <Typography variant="h6" >{__("challenge_add_gift")}</Typography>
            </ModalHeader>
            <ModalBody>
              <Form.Group className="mb-3">
                <Form.Group className="mb-3 form-group">
                  <Stack flexDirection={'row'} alignItems={'center'} >

                    <Box className="file-drop-area ">
                      <Image
                        id="target"
                        className={`choose_file_image choose-file-button ${!loadingAvatar ? "fit-image-gift" : ""}`}
                        src={avatarUrl || imageDefaultGift}
                        alt="placeholder"
                      />
                    </Box>

                    <Box ml={2}>
                      <Button onClick={() => {
                        const input = document.getElementById('image-gift');
                        input.click()
                      }} variant="outlined">
                        <UploaderIcon sx={{
                          width: '20px',
                          marginRight: theme => theme.spacing(1)
                        }} />
                        Tải lên
                      </Button>
                      <Typography mt={1} color={'text.secondary'}>{__('txt_logo_format')}</Typography>

                    </Box>
                  </Stack>

                  <Form.Control
                    className="file-input"
                    type="file"
                    id="image-gift"
                    accept=".png,.jpg,.jpeg,.gif"
                    required={true}
                    onChange={_onChooseImageChange}
                  />
                  <div className="invalid-feedback">{__("challenge_information_gift_text")}</div>

                </Form.Group>
                <Form.Group className="mb-3 info">
                  <Tooltip title={__('mission_reward_name')}>

                    <Form.Control
                      maxLength={100}
                      onChange={_onTitleGiftChange}
                      type="search"
                      ref={titleGiftInputRef}
                      id="title"
                      required
                      className="input"
                      placeholder={__('mission_reward_name')}
                    />
                  </Tooltip>

                  <div className="invalid-feedback">{__("challenge_gift_name_sub_text")}</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Tooltip title={__('mission_price')}>

                    <InputGroup className="input_group">
                      <Form.Control
                        maxLength={12}
                        min={0}
                        max={999999999999}
                        onChange={_onMoneyGiftChange}
                        type="text"
                        ref={moneyGiftInputRef}
                        id="money"
                        required
                        placeholder={__('mission_price')}

                      />
                      <InputGroup.Text>{__("currency_unit")}</InputGroup.Text>
                    </InputGroup>
                  </Tooltip>
                  <div className="invalid-feedback">{__("currency_unit")}</div>
                </Form.Group>
              </Form.Group>

              {/* design mới không có phần này nên comment */}

              {/* <Form.Group className="mb-3">
                <Form.Label>{__("challenge_gift_detail")}</Form.Label>
                <Form.Control
                  as="textarea"
                  maxLength={600}
                  rows={7}
                  ref={descriptionInputRef}
                  onChange={_onDescriptionChange}
                  required
                  id="description"
                />
                <div className="invalid-feedback">{__("challenge_gift_detail_sub_text")}</div>
              </Form.Group> */}
            </ModalBody>
            <ModalFooter flexDirection={'row'}>
              <Button sx={{
                marginRight: theme => theme.spacing(1)
              }} variant="text" onClick={handleClose}>
                {__(("btn_close"))}
              </Button>
              <Button type="submit" >
                {__("btn_save")}
              </Button>
            </ModalFooter>
          </Form>
        </ModalContainer>
      </GiftModalContainer>
    </>
  );
};

export default forwardRef(GiftModalComponent)
const GiftModalContainer = styled(Modal)(({ theme }) => ({
  '& .file-drop-area': {
    width: '80px',
    height: '80px',
    border: 'none',

    '& .choose-file-button': {
      padding: 0
    },

    '& .fit-image-gift': {
      objectFit: 'contain',
      borderRadius: theme.spacing(1.5)
    }
  },
  '& .input, .input_group': {
    height: '52px',
    width: '100%',
    padding: theme.spacing(1, 2),
    background: '#F3F4F5',
    borderRadius: `${theme.spacing(1.5)} !important`,
    minWidth: '718px',

    '& input, #dropdown-basic': {
      border: 'none',
      background: 'none',
      color: '#000',
      padding: 0,


    },

    '& input': {
      paddingRight: theme.spacing(1),
    },
  },
  '& .form-control': {

    '&:focus': {
      boxShadow: 'none !important',
      border: 'none',
      '&-visible': {
        outline: 0
      }
    },
  },

  '& .input_group': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),

    '& .input-group-text': {
      border: 'none',
      background: 'none',
      padding: 0,

    }
  },


}))

