import { memo, useEffect } from 'react'

//**
//  * Zoho Sales Iq Script
//  * @param url
//  * @param widgetCode
//  */
declare var $zoho: any
const SalesIQ = ({ isShowFrame }: { isShowFrame: boolean }) => {
  const visible = isShowFrame ? 'show' : 'hide'

  useEffect(() => {
    const script = document.createElement('script')
    try {
      script.setAttribute('type', 'text/javascript')
      let code = `var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || { 
              widgetcode: "${process.env.REACT_APP_ZOHO_WIDGETCODE}", 
              values:{},
              ready:function(){}
          };
          var d=document;
          s=d.createElement("script");
          s.type="text/javascript";
          s.id = "zsiqscript";
          s.defer = true;
          s.src = "${process.env.REACT_APP_ZOHO_URL}";
          t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
          if (!window.zohoReadyEvent){
              window.zohoReadyEvent = new Event('zohoReady');
          }
          $zoho.salesiq.ready = function(){
            // $zoho.salesiq.floatbutton.coin.hidetooltip();
            $zoho.salesiq.floatbutton.position('bottomright');
            if (!!'${visible}'){
              $zoho.salesiq.floatbutton.visible('${visible}');   
              $zoho?.salesiq?.reset?.()
            }
            window.dispatchEvent(window.zohoReadyEvent);
          }
      `
      script.appendChild(document.createTextNode(code))
      document.body.appendChild(script)
    } catch (error) {
      console.log(`Zoho_${error}`);
    }
    return () => {
      document.body.removeChild(script)
    }
  }, [visible])

  return null
}

export default memo(SalesIQ)
