import { SvgIcon, SvgIconProps } from '@mui/material'

const LockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C10.3431 4 9 5.34315 9 7V8H15V7C15 5.34315 13.6569 4 12 4ZM17 8.01762V7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7V8.01762C6.93277 8.02117 6.86774 8.02544 6.80497 8.03057C6.40963 8.06287 6.01641 8.13419 5.63803 8.32698C5.07354 8.6146 4.6146 9.07354 4.32698 9.63803C4.13419 10.0164 4.06287 10.4096 4.03057 10.805C3.99997 11.1795 3.99998 11.6343 4 12.1615V17.8385C3.99998 18.3657 3.99997 18.8205 4.03057 19.195C4.06287 19.5904 4.13419 19.9836 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.01641 21.8658 6.40963 21.9371 6.80497 21.9694C7.17954 22 7.6343 22 8.16144 22H15.8386C16.3657 22 16.8205 22 17.195 21.9694C17.5904 21.9371 17.9836 21.8658 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C19.8658 19.9836 19.9371 19.5904 19.9694 19.195C20 18.8205 20 18.3657 20 17.8385V12.1615C20 11.6343 20 11.1795 19.9694 10.805C19.9371 10.4096 19.8658 10.0164 19.673 9.63803C19.3854 9.07354 18.9265 8.6146 18.362 8.32698C17.9836 8.13419 17.5904 8.06287 17.195 8.03057C17.1323 8.02544 17.0672 8.02117 17 8.01762ZM6.96784 10.0239C6.69617 10.0461 6.59546 10.0838 6.54601 10.109C6.35785 10.2049 6.20487 10.3578 6.109 10.546C6.0838 10.5955 6.04612 10.6962 6.02393 10.9678C6.00078 11.2512 6 11.6234 6 12.2V17.8C6 18.3766 6.00078 18.7488 6.02393 19.0322C6.04612 19.3038 6.0838 19.4045 6.109 19.454C6.20487 19.6422 6.35785 19.7951 6.54601 19.891C6.59546 19.9162 6.69617 19.9539 6.96784 19.9761C7.25117 19.9992 7.62345 20 8.2 20H15.8C16.3766 20 16.7488 19.9992 17.0322 19.9761C17.3038 19.9539 17.4045 19.9162 17.454 19.891C17.6422 19.7951 17.7951 19.6422 17.891 19.454C17.9162 19.4045 17.9539 19.3038 17.9761 19.0322C17.9992 18.7488 18 18.3766 18 17.8V12.2C18 11.6234 17.9992 11.2512 17.9761 10.9678C17.9539 10.6962 17.9162 10.5955 17.891 10.546C17.7951 10.3578 17.6422 10.2049 17.454 10.109C17.4045 10.0838 17.3038 10.0461 17.0322 10.0239C16.7488 10.0008 16.3766 10 15.8 10H8.2C7.62345 10 7.25118 10.0008 6.96784 10.0239Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default LockIcon
