import React, {useEffect, useMemo, useState} from 'react'
import {faVolumeHigh, faVolumeLow, faVolumeMute, faX} from '@fortawesome/free-solid-svg-icons'
import {getStatePlayingPodcast, setPlayingPodcast, updatePlayingPodcastInfo} from '../podcast/store/podcastSlice'

import {useDispatch, useSelector} from 'react-redux'
import {deleteItemFromLocalStorage, getItemFromLocalStorage, setItemToLocalStorage} from '../podcast/utils'
import {Key_Of_Playing_Podcast_In_LocalStorage} from '../podcast/utils/constant'
import {PlayingPodcast, PlayingPodcastState} from '../podcast/podcast.types'
import {Image, Stack} from 'react-bootstrap'
import IconButton from 'components/IconButton'
import AudioProgress from '../podcast/components/AudioProgress'
import useAudio from '../hooks/useAudio'
import './media/PodcastPlayer.scss'
import PodcastController from 'podcast/components/PodcastController'
import PodcastCountdown from 'podcast/components/PodcastCountdown'
import {countdownTime} from 'podcast/utils/countdownTime'
import __ from "languages/index";

type Props = {
  playingPodcast: PlayingPodcast
}

const PodcastPlayer = ({ playingPodcast }: Props) => {
  const dispatch = useDispatch()
  const playingPodcastState = useSelector(getStatePlayingPodcast)
  const audioFile = playingPodcast?.attach_files[0]
  const { ref, play, pause } = useAudio(audioFile?.media_url, {
    fastForwardTime: 10,
    rewindTime: 10,
    currentTime: playingPodcast?.currentTime
  })

  const [currentTime, setCurrentTime] = useState<number>(0)
  const duration = useMemo(() => ref.current?.duration, [ref.current?.duration])

  useEffect(() => {
    const saveData = () => {
      const playingPodcast = getItemFromLocalStorage(Key_Of_Playing_Podcast_In_LocalStorage)
      if (!playingPodcast) {
        deleteItemFromLocalStorage(Key_Of_Playing_Podcast_In_LocalStorage)
      } else {
        setItemToLocalStorage(Key_Of_Playing_Podcast_In_LocalStorage, {
          ...playingPodcast,
          currentTime: ref.current?.currentTime
        })
      }
    }
    window.addEventListener('unload', saveData)
    return () => {
      saveData()
      window.removeEventListener('unload', saveData)
    }
  }, [])

  useEffect(() => {
    if (!playingPodcast.currentTime) return
    ref.current.currentTime = playingPodcast?.currentTime
  }, [])

  useEffect(() => {
    if (playingPodcastState?.isPlaying) play()
    else pause()
  }, [playingPodcast, playingPodcastState?.isPlaying])

  useEffect(() => {
    ref.current.volume = playingPodcastState?.volume
  }, [playingPodcastState?.volume])

  useEffect(() => {
    if (!ref.current) return
    ref.current?.addEventListener('timeupdate', () => {
      setCurrentTime(ref.current?.currentTime)
    })
    return () => {
      ref.current?.removeEventListener('timeupdate', () => {
        setCurrentTime(ref.current?.currentTime)
      })
    }
  }, [])

  const handleChangeVolume = (volume: number) => {
    const newVolume = Number((volume / 100).toFixed(2))
    ref.current.volume = newVolume
    updateStateCurrentPodcast({
      prevVolume: playingPodcastState?.volume,
      volume: newVolume
    })
  }

  const mute = () => {
    if (!(playingPodcastState?.volume === 0)) handleChangeVolume(0)
    else handleChangeVolume(playingPodcastState?.prevVolume)
  }

  const getCountdownTime = useMemo(() => {
    return countdownTime(currentTime, duration)
  }, [currentTime])

  const updateStateCurrentPodcast = (newState: Partial<PlayingPodcastState>) => {
    dispatch(updatePlayingPodcastInfo(newState))
  }

  const closePodcastPlayer = () => {
    dispatch(setPlayingPodcast(null))
    updateStateCurrentPodcast({
      isPlaying: false
    })
    deleteItemFromLocalStorage(Key_Of_Playing_Podcast_In_LocalStorage)
  }

  return (
    playingPodcast && (
      <div className="podcast-player">
        <Stack
          direction="horizontal"
          className="w-100 align-items-center d-flex  justify-content-between"
        >
          <Stack direction={'horizontal'} gap={2}>
            <Image
              src={
                playingPodcast?.post_avatar?.media_url ||
                playingPodcast?.post_avatar?.media_thumbnail
              }
              width={45}
              height={45}
              className="rounded-3"
            />
            <div className="podcast-player__name-container">
              <div className="podcast-player__name">
                {Array.from({ length: 5 }).map((item, index) => (
                  <span key={index}>{playingPodcast?.title}</span>
                ))}
              </div>
              <span className="podcast-player__countdown-time m-0">
                {getCountdownTime === 0 ? `${__("podcast_player_finished")}` : `${__("podcast_player_text")} ${getCountdownTime} ${__("podcast_player_time")}`}{' '}
              </span>
            </div>
          </Stack>
          {
            <audio
              hidden
              ref={ref}
              controls
              src={audioFile?.media_url}
              autoPlay={false}
              onLoadedMetadata={() => {
                const currentTime = ref.current.currentTime
                const duration = ref.current.duration
                updateStateCurrentPodcast({
                  currentTime,
                  duration
                })
              }}
              onEnded={() => {
                updateStateCurrentPodcast({
                  isPlaying: false
                })
              }}
            />
          }
          <AudioProgress
            audioRef={ref}
            onChange={value => updateStateCurrentPodcast({ currentTime: value })}
            className="position-absolute top-0 start-0"
          />
          <PodcastController audioRef={ref} />
          <Stack direction={'horizontal'} gap={2}>
            <Stack direction="horizontal" className="podcast-player__volume">
              <IconButton
                onClick={mute}
                variant="none"
                icon={
                  playingPodcastState?.volume === 0
                    ? faVolumeMute
                    : playingPodcastState?.volume < 0.5 && playingPodcastState?.volume > 0
                    ? faVolumeLow
                    : faVolumeHigh
                }
              />
              <input
                type="range"
                value={playingPodcastState?.volume * 100}
                onChange={e => {
                  handleChangeVolume(+e.target.value)
                }}
              />
            </Stack>
            <PodcastCountdown currentTime={currentTime} duration={ref.current.duration} />
          </Stack>
          <IconButton variant="none" icon={faX} onClick={closePodcastPlayer} />
        </Stack>
      </div>
    )
  )
}

export default PodcastPlayer
