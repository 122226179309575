import React from "react";
import {Table} from "react-bootstrap";
import CoursesEmpty from "entities/courses/components/courses.empty"

type Props<T> = {
  className?: string;
  listItem: T[];
  renderHeader: () => React.ReactNode;
  renderBodyItem: (item: T, index: number) => React.ReactNode;
};

const OrderTable = <T,>(props: Props<T>) => {
  const { listItem, renderHeader, renderBodyItem, className } = props;

  const renderListitem = listItem?.map((item, index) => renderBodyItem(item, index));
  if (listItem?.length <= 0){
    return (
      <CoursesEmpty />
    )
  }
  return (
    <Table hover className={className} responsive="lg" style={{textAlign: "start", tableLayout: "fixed"}}>
      <thead>{renderHeader()}</thead>
      <tbody>{renderListitem}</tbody>
    </Table>
  );
};

export default OrderTable;

