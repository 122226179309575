import React from 'react'
import {ListPlan} from 'entities/payment/payment.interface'
import VNpayIcon from 'media/images/VNpayIcon.png'
import zaloPay from 'media/images/ZaloPayIcon.png'
import googleImage from 'media/images/google.png'
import applePay from 'media/images/apple-pay.png'
import stripe from 'media/images/stripe.png'
import paypal from 'media/images/paypal.png'
import __ from "languages/index";
import helpers from "helpers/index";

interface TypePaymentMethodProps {
  listPaymentMethod: string[]
  selectPayment?: number
  checked?: string
  listPlan: ListPlan[]
  isShowModal?:boolean
  onHandelSelectPayment: (param: number) => void
}

const PaymentMethod: React.FC<TypePaymentMethodProps> = ({
                                                           listPaymentMethod,
                                                           selectPayment,
                                                           onHandelSelectPayment,
                                                           listPlan,
                                                           checked,
                                                           isShowModal
                                                         }) => {
  const plan = listPlan?.find(plan => checked ? checked === plan?._id : listPlan[0]?._id)

  return (
    <div key={plan?._id}>
      {plan?.price > 0 && (
        <div  className="bg-white rounded-2 p-3">
          {!isShowModal&&<h6 className=''>{__("payment_method_title")}</h6>}
          {listPaymentMethod?.filter(item=> helpers.isGamifa()? true : item!=="vn_pay").map((method, index: number) => (
            <div key={`paymentMethod${index}`} >
              {method === 'google_payment' && (
                <button
                  onClick={() => onHandelSelectPayment(0)}
                  className="form-check ps-5 py-3 w-100 rounded border-secondary
                         mt-3  mb-0 border d-flex align-items-center"
                  style={{
                    backgroundColor: selectPayment === 0 ? 'rgba(0,0,0,0.05)' : 'white'
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    checked={selectPayment === 0}
                    defaultValue={selectPayment || 0}
                    onChange={() => onHandelSelectPayment(0)}
                  />
                  <div className="d-block ms-3 ms-3 d-flex mt-1">
                    <img style={{ width: 20, objectFit: 'contain' }} src={googleImage} />

                    <p className="m-0 text-start ms-2">Google payment</p>
                  </div>
                </button>
              )}
              {method === 'vn_pay' && (
                <button
                  onClick={() => onHandelSelectPayment(1)}
                  className="form-check ps-5 py-3 w-100 rounded border-secondary
                        mt-3  mb-0 border d-flex align-items-center"
                  style={{
                    backgroundColor: selectPayment === 1 ? 'rgba(0,0,0,0.05)' : 'white'
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    checked={selectPayment === 1}
                    defaultValue={selectPayment || 1}
                    onChange={() => onHandelSelectPayment(1)}
                  />
                  <div className="d-block ms-3 ms-3 d-flex mt-1">
                    <img style={{ width: 20, objectFit: 'contain' }} src={VNpayIcon} />
                    <p className="m-0 text-start ms-2">{__("payment_method_VNPay")}</p>
                  </div>
                </button>
              )}
              {method === 'transfer' && (
                <button
                  onClick={() => onHandelSelectPayment(2)}
                  className="form-check ps-5 py-3 w-100 rounded border-secondary
                        mt-3  mb-0 border d-flex align-items-center"
                  style={{
                    backgroundColor: selectPayment === 2 ? 'rgba(0,0,0,0.05)' : 'white'
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    checked={selectPayment === 2}
                    defaultValue={selectPayment || 2}
                    onChange={() => onHandelSelectPayment(2)}
                  />
                  <div className="d-block ms-3 d-flex mt-1">
                    <i className="bi bi-credit-card"></i>
                    <p className="m-0 text-start ms-2">{__("payment_method_transfer")}</p>
                  </div>
                </button>
              )}
              {method === 'apple_payment' && (
                <button
                  onClick={() => onHandelSelectPayment(3)}
                  className="form-check ps-5 py-3 w-100 rounded border-secondary
                         mt-3  mb-0 border d-flex align-items-center"
                  style={{
                    backgroundColor: selectPayment === 3 ? 'rgba(0,0,0,0.05)' : 'white'
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    checked={selectPayment === 3}
                    defaultValue={selectPayment || 3}
                    onChange={() => onHandelSelectPayment(3)}
                  />
                  <div className="d-block ms-3 ms-3 d-flex mt-1">
                    <img style={{ width: 20, objectFit: 'contain' }} src={applePay} />
                    <p className="m-0 text-start ms-2">{__("payment_method_apple_payment")}</p>
                  </div>
                </button>
              )}
              {method === 'stripe' && (
                <button
                  onClick={() => onHandelSelectPayment(4)}
                  className="form-check ps-5 py-3 w-100 rounded border-secondary
                        mt-3  mb-0 border d-flex align-items-center"
                  style={{
                    backgroundColor: selectPayment === 4 ? 'rgba(0,0,0,0.05)' : 'white'
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    checked={selectPayment === 4}
                    defaultValue={selectPayment || 4}
                    onChange={() => onHandelSelectPayment(4)}
                  />
                  <div className="d-block ms-3 ms-3 d-flex mt-1">
                    <img style={{ width: 20, objectFit: 'contain' }} src={stripe} />
                    <p className="m-0 text-start ms-2">{__("payment_method_stripe")}</p>
                  </div>
                </button>
              )}
              {method === 'zalo_pay' && (
                <button
                  onClick={() => onHandelSelectPayment(5)}
                  className="form-check ps-5 py-3 w-100 rounded border-secondary
                        mt-3  mb-0 border d-flex align-items-center"
                  style={{
                    backgroundColor: selectPayment === 5 ? 'rgba(0,0,0,0.05)' : 'white'
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    checked={selectPayment === 5}
                    defaultValue={selectPayment || 5}
                    onChange={() => onHandelSelectPayment(5)}
                  />
                  <div className="d-block ms-3 ms-3 d-flex mt-1">
                    <img style={{ width: 20, objectFit: 'contain' }} src={zaloPay} />
                    <p className="m-0 text-start ms-2">{__("payment_method_zalo_pay")}</p>
                  </div>
                </button>
              )}
              {method === 'paypal' && (
                <button
                  onClick={() => onHandelSelectPayment(6)}
                  className="form-check ps-5 py-3 w-100 rounded border-secondary
                        mt-3  mb-0 border d-flex align-items-center"
                  style={{
                    backgroundColor: selectPayment === 6 ? 'rgba(0,0,0,0.05)' : 'white'
                  }}
                >
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radio2"
                    checked={selectPayment === 6}
                    defaultValue={selectPayment || 6}
                    onChange={() => onHandelSelectPayment(6)}
                  />
                  <div className="d-block ms-3 ms-3 d-flex mt-1">
                    <img style={{ width: 20, objectFit: 'contain' }} src={paypal} />
                    <p className="m-0 text-start ms-2">{__("payment_method_paypal")}</p>
                  </div>
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default PaymentMethod
