import { Typography, styled } from '@mui/material'
import React from 'react'

const ChannelSettingsTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600
}))

export default ChannelSettingsTitle

ChannelSettingsTitle.defaultProps = {
  variant: 'h5'
}
