import {
  Box,
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  Typography
} from '@mui/material'
import {  LogisticDefaultService } from "entities/shop/data/shop.logictic.enum"
import {
  TypeAddess,
  TypeDistrict,
  TypedLogisticService,
  TypedStock,
  TypeWard
} from 'entities/shop/interface'
import __helpers from "helpers/index"
import __ from "languages/index"
import {  useCallback, useEffect, useState } from 'react'
import { paymentStatus } from "../data"
import { PaymentStatus } from "../data/order.enum"
import LogisticInformationModal from 'entities/orders/components/LogisticInformationModal'
import { TypedDetailForm } from 'entities/orders/order.manager.detail'
import addressData from '../../challenge/media/diachinh.json';
import { convertData } from 'entities/shop/helper'


interface TypedProps {
    fields: TypedDetailForm
    stockes: TypedStock[];
    orderTotalPrice: number;
    orderTotalOriginPrice: number;
    orderFee: number;
    logisticCode?: string;
}


const dataPaymentMethod = [
    {
        value: "cash_on_delivery",
        label: __('payment_upon_delivery')
    }
]

const OrderDetailMethodPayment = ({
        fields,
        stockes,
        orderTotalPrice,
        orderTotalOriginPrice,
        orderFee,
        logisticCode
    }: TypedProps) => {
    const [logistices, setLogistices] = useState<TypedLogisticService[]>([])
    const [logisticAddresses, setLogisticAddresses] = useState<TypeAddess[]>([])
    const [cityName, setCityName] = useState<string>('')
    const [districtName, setDistrictName] = useState<string>('')
    const [districtByCity, setDistrictByCity] = useState<TypeDistrict[]>([]);
    const [wardByDistrict, setWardByDistrict] = useState<TypeWard[]>([]);
    const [logisticName, setLogisticName] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() =>{
    setLogisticAddresses(convertData(addressData))

  }, [])

  useEffect(() => {
    const districtData = logisticAddresses.filter((item)=> item.code === fields?.order_customer_address_city.value).map(item => {
      setCityName(item.name)
      return item['quan-huyen']
    })
    if (!!districtData) {
      setDistrictByCity(convertData(districtData[0]))
    }

  }, [fields.order_customer_address_city.value])

  useEffect(() => {
    const wardData = districtByCity?.filter(item => item?.code === fields.order_customer_address_district.value).map(item => {
      setDistrictName(item.name)
      return item['xa-phuong']
    })
    if (!!wardData) {
      setWardByDistrict(convertData(wardData[0]))
    }
  }, [fields.order_customer_address_district.value, districtByCity])

    useEffect(() => {
        if (stockes.length) {
            const findStock = stockes.find((item) => item.stock_id === fields.stock_id.value)
            setLogistices(findStock.logistic_service_to_stock)
            const findLogisticDefault = findStock.logistic_service_to_stock.find((item) => item.is_default_service === LogisticDefaultService.DEFAULT)
          fields.logistic_id.onChange(findLogisticDefault?.logistic_id || findStock.logistic_service_to_stock[0]?.logistic_id)
        }
    }, [fields.stock_id.value, stockes])

  useEffect(() => {
    const logistic = logistices.find((logistic)=> logistic?.logistic_id === fields?.logistic_id.value)
    setLogisticName(logistic?.logistic_service.logistic_name)
  }, [fields?.logistic_id, logistices])


    const handleSelectLogistic = (e) => {
      fields.logistic_id.onChange(e.target.value)
    }
    const handleCloseModal = useCallback(()=>{
      setShowModal(false)
    },[])


  const customerAddress = useCallback(() => {
    const wardName = wardByDistrict?.find(item => item.code === fields?.order_customer_address_ward.value)?.name;
    const logisticAddress = [fields?.order_customer_address.value, wardName, districtName, cityName].filter(Boolean);
    return `${logisticAddress.join(', ')} `
  }, [fields, wardByDistrict]);


    return (
        <OrderDetailMethodPaymentContainer>

            {/* logistic */}
            <Box className="logistic">
                <Box className="logistic_header">
                  <Typography variant="subtitle2" >
                    {__('logistic_service')}
                  </Typography>
                  <Button variant="text" onClick={()=>setShowModal(true)} size={'small'}>
                    <Typography textTransform='none' sx={{borderBottom: theme => theme.spacing(0.2)}}>
                      {__('logistic_see_detail')}
                    </Typography>
                  </Button>
                </Box>
                <FormControl sx={{
                    width: '100%',
                    margin: theme => theme.spacing(3, 0)
                }} >
                    <Select
                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }
                        }}

                        defaultValue={fields.logistic_id.value || ""}
                        value={fields.logistic_id.value || ""}
                        onChange={handleSelectLogistic}
                        input={<OutlinedInput />}
                        className='payment-modal-select'
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5),
                            width: '100%'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >

                        {logistices?.length && logistices.map((logistic) => (
                            <MenuItem
                                key={logistic?.logistic_id}
                                value={logistic?.logistic_id}
                            >
                                {logistic?.logistic_service.logistic_name}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* validate lại */}
                    {/* {fields.stock_address_city?.error && (
            <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.stock_address_city?.error}</Typography>
        )} */}
                </FormControl>
            </Box>

            {/* payment */}
            <Box className="payment">
                <Typography variant="subtitle2" >
                    {__('payment')}
                </Typography>

                <FormControl sx={{
                    width: '100%',
                    marginTop: theme => theme.spacing(2)
                }} >
                    <Select

                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }
                        }}
                        displayEmpty
                        required
                        defaultValue={fields.order_delivery_type.value}
                        value={fields.order_delivery_type.value}
                        onChange={e => fields.order_delivery_type.onChange(e.target.value)}
                        input={<OutlinedInput />}
                        className='payment-modal-select'
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5),
                            width: '100%'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="">
                            {__("select_payment_method")}
                        </MenuItem>
                        {dataPaymentMethod?.map((method, index) => (
                            <MenuItem
                                key={index}
                                value={method?.value}
                            >
                                {method?.label}
                            </MenuItem>
                        ))}
                    </Select>

                </FormControl>


                {/* voucher */}
                <FormControl sx={{
                    width: '100%',
                    margin: theme => theme.spacing(2, 0)
                }} >
                    <Select
                        // onBlur={() => {
                        //     fields.stock_address_city?.runValidation(fields.stock_address_city.value)
                        // }}
                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }
                        }}
                        disabled={true}
                        displayEmpty
                        required
                        defaultValue={fields.order_voucher.value}
                        value={fields.order_voucher.value}
                        onChange={e => fields.order_voucher.onChange(e.target.value)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                            if (selected?.length === 0) {
                                return <>{__("voucher")}</>;
                            }
                            return selected
                        }}
                        className='payment-modal-select'
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5),
                            width: '100%'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="">
                            {__("voucher")}
                        </MenuItem>
                        {/* {addresses?.map((city) => (
                            <MenuItem
                                key={city?.code}
                                value={city?.name}
                                sx={theme => getStyles(city?.name, stockCity, theme)}
                                onClick={() => setCodeCity(city?.code)}
                            >
                                {city?.name}
                            </MenuItem>
                        ))} */}
                    </Select>
                    {/* {fields.stock_address_city?.error && (
            <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.stock_address_city?.error}</Typography>
        )} */}
                </FormControl>

                <FormControl sx={{
                    width: '100%',
                    marginBottom: theme => theme.spacing(2)
                }} >
                    <Select
                        MenuProps={{
                            elevation: 1,
                            sx: {
                                maxHeight: theme => theme.spacing(37.5)
                            }
                        }}
                        displayEmpty
                        required
                        defaultValue={fields.payment_status.value}
                        value={fields.payment_status.value}
                        onChange={(e) => fields.payment_status.onChange(e.target.value as PaymentStatus)}
                        input={<OutlinedInput />}
                        className='payment-modal-select'
                        sx={{
                            padding: theme => theme.spacing(1, 2),
                            backgroundColor: theme => theme.palette.background.default,
                            borderRadius: theme => theme.spacing(1.5),
                            width: '100%'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="">
                            {__("status_payment")}
                        </MenuItem>
                        {paymentStatus?.map((status) => (
                            <MenuItem
                                key={status.value}
                                value={status.value}
                            >
                                {status?.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* {fields.stock_address_city?.error && (
            <Typography sx={{ paddingTop: theme => theme.spacing(0.25) }} variant="body2" color="error.main">{fields.stock_address_city?.error}</Typography>
        )} */}
                </FormControl>
            </Box>
            <Box className="total">
                <Stack mb={2} className="item_total" >
                    <Typography>{__('cost')}</Typography>
                    <Typography>{__helpers.formatNumberWithCommas(orderTotalPrice)} {__("order_unit")}</Typography>
                </Stack>
                <Stack mb={2} className="item_total">
                    <Typography>{__('fee')}</Typography>
                    <Typography>{__helpers.formatNumberWithCommas(orderFee)} {__("order_unit") || __('not_yet_counter')}</Typography>
                </Stack>
                <Stack mb={2} className="item_total">
                    <Typography>{__('voucher')}</Typography>
                    <Typography color={'primary'}>{__helpers.formatNumberWithCommas(0)} {__("order_unit")}</Typography>
                </Stack>
                <Stack className="item_total">
                    <Typography>{__('total_order_price')}</Typography>
                    <Typography>{__helpers.formatNumberWithCommas(orderTotalOriginPrice)} {__("order_unit")}</Typography>
                </Stack>
            </Box>
          {showModal &&
            <LogisticInformationModal
              showModal={showModal}
              handleClose={handleCloseModal}
              customerName = {fields?.order_customer_name}
              customerPhone = {fields?.order_customer_phonenumber}
              logistic = {logisticName}
              logisticCode = {logisticCode}
              customerAddress = {customerAddress}
            />
          }
        </OrderDetailMethodPaymentContainer>
    )
}

export default OrderDetailMethodPayment

const OrderDetailMethodPaymentContainer = styled(Box)(({ theme }) => ({


    '& fieldset': {
        border: 'none'
    },
    '& .logistic': {
        borderBottom: '1px solid #E0E2E6',
      "& .logistic_header":{
        display: "flex",
        alignItems: 'baseline',
        justifyContent: "space-between"
      },
    },

    '& .payment': {
        marginTop: theme.spacing(3)
    },

    '& .total': {
        background: '#FDEFF1',
        padding: theme.spacing(1.5),
        borderRadius: theme.spacing(1.5),

        '& .item_total': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

        },
    }
}))
