import {TypedLeg} from 'entities/challenge/challenge.interface'
import React from 'react'
import ChallengeTimeline, {TypedTimeline} from '../ChallengeTimeline'

interface Props {
  listLeg: TypedLeg[]
  onDelete: (_id: string) => void
}

const ListLegComponent = (props: Props) => {
  const { listLeg, onDelete } = props

  if (listLeg.length == 0) {
    return null
  }

  const data: TypedTimeline[] = listLeg.map(item => {
    let newItem: TypedTimeline = {
      _id: item?._id,
      startDate: item?.start_time,
      endDate: item?.end_time,
      title: item?.name,
      detail: [{ description: item?.description, gifts: item?.gift_data, id: item._id }]
    }
    return newItem
  })

  return <ChallengeTimeline timeline={data} isOwner={true} onDelete={onDelete} />
}

export default ListLegComponent
