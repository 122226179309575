import {getRequestHome, isFulfilledListRequest} from 'entities/homepage/home.store.reducer'
import {useInfiniteScroll} from 'hooks/useInfiniteScroll'
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useAppSelector} from 'config/store'
import __ from 'languages/index'
import helpers from 'helpers/index'
import {Stack} from '@mui/material'
import SmallLayout from "../../layouts/smallLayout";
import {TypedRequest} from "../../interfaces/request.interface";
import ItemHomePost from "entities/homeNew/components/ItemHomePost";
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";

export default function SearchScreen() {
  const user_data = useAppSelector(state => state.user.user_data)
  const channel_data = useAppSelector(state => state.user.channel_data)
  const location = useLocation();
  let StringQuery: any = helpers.ExtractUrl(location?.search) || {}
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in);
  const dataRequest = useAppSelector(state => state.home.dataRequest, (next, prev) => helpers.isEqual(next, prev))


  const {
    hasMore,
    isRefreshing,
    loadMore,
    updateParamRequest,
    refresh
  } = useInfiniteScroll<TypedRequest>({
    auth_id: user_data?._id,
    channel_id: channel_data?._id,
    search: StringQuery?.search || '',
    limit: 12,
    order_type: 'time'
  }, getRequestHome, false, dataRequest, isFulfilledListRequest)


  useEffect(() => {
    updateParamRequest({
      search: StringQuery?.search || ''
    })
  }, [StringQuery?.search]);

  return (
    <SmallLayout>
      <div className="d-flex align-items-center">
        <p className="my-0 fs-6">{`${__('search_keyword')}`}</p>
        <p className="my-0 fs-5 ms-2">{`${StringQuery?.search || ''}`}</p>
      </div>
      <InfiniteScrollContainer
        isRefreshing={isRefreshing}
        dataLength={dataRequest.length}
        next={loadMore}
        hasMore={hasMore}
        refreshFunction={refresh}
        textEmptyMessage={__("search_empty_value")}
      >
        <Stack gap={2.5}>
          {dataRequest?.map((post) => {
            return (
              <ItemHomePost
                key={post._id}
                post={post}
                is_user_logged_in={is_user_logged_in}
              />
            )
          })}
        </Stack>
      </InfiniteScrollContainer>
    </SmallLayout>
  )
}
