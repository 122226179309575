import React, { useState } from 'react'
import imageDefault from 'media/images/image_default.png'
import { Box, Button, Checkbox, Stack, SxProps, Typography } from '@mui/material'
import { CurrentTheme } from '../../../styles/themes'
import { ItemInCart } from 'entities/shop/interface'
import QuantityAdjustment from 'entities/shop/components/QuantityAdjustment'
import helpers from 'helpers/index'
import __ from 'languages/index'
import ConfirmModal from 'components/ConfirmModal'
import { productSoldQuantity } from 'entities/shop/helper'
import { EnumTypeToast, useToast } from 'hooks/useToast'

type Props = {
  label: string
  isSelected?: boolean
  cartItem: ItemInCart
  onSelectProduct?: (product: ItemInCart) => void
  onChangeQuantity?: (quantity: number) => void
  onRemoveProduct?: (id: string) => void
  onUpdateQuantity?: (quantity: number) => void
}

const CartProductItem = ({
  onSelectProduct,
  onRemoveProduct,
  onUpdateQuantity,
  isSelected,
  label,
  cartItem
}: Props) => {
  const { product } = cartItem

  const toast = useToast()

  const [productQuantity, setProductQuantity] = useState<number>(
    Number(cartItem?.item_quantity || '0')
  )
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)

  return (
    <>
      <Box sx={rowStyled}>
        <Box display={'flex'} columnGap={theme => theme.spacing(3)} alignItems={'center'}>
          <Box>
            <Checkbox
              onChange={() => onSelectProduct(cartItem)}
              color="primary"
              checked={isSelected}
              inputProps={{
                'aria-labelledby': label
              }}
            />
          </Box>
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <Box
              component={'img'}
              src={product?.product_avatar ? product?.product_avatar[0] : imageDefault}
              sx={{
                width: theme => theme.spacing(13.125),
                height: theme => theme.spacing(13.125),
                borderRadius: theme => theme.spacing(1.5),
                objectFit: 'cover'
              }}
            ></Box>
            <Typography>{product?.product_name || ''}</Typography>
          </Stack>
        </Box>
        <Box textAlign="center">
          {`${helpers.formatNumberWithCommas(product?.product_price || '0')} VND`}
        </Box>
        <Box textAlign="center" sx={quantityStyled}>
          <QuantityAdjustment
            onChangeQuantity={value => {
              if (Number(value) < 1) return setIsShowConfirmDelete(true)
              if (Number(value) > productSoldQuantity(cartItem?.product)?.totalProduct) {
                return toast.show({
                  type: EnumTypeToast.Warning,
                  content: `${__('warningAddQuantityProduct')}`
                })
              }
              setProductQuantity(value)
              onUpdateQuantity(value)
            }}
            quantity={productQuantity}
          />
        </Box>
        <Box textAlign="center">
          {`${helpers.formatNumberWithCommas(productQuantity * product?.product_price)} VND`}
        </Box>
        <Box textAlign="center" className={'btn-delete'}>
          <Button variant={'text'} onClick={() => setIsShowConfirmDelete(true)}>
            <Typography variant={'body2'} color={'inherit'} textTransform={'capitalize'}>
              {__('btn_delete')}
            </Typography>
          </Button>
        </Box>
      </Box>
      {isShowConfirmDelete && (
        <ConfirmModal
          onClose={() => setIsShowConfirmDelete(false)}
          open={isShowConfirmDelete}
          onConfirm={() => {
            onRemoveProduct(cartItem?.item_id)
            setIsShowConfirmDelete(false)
          }}
          title={__('btn_confirm')}
          cancelTextBtn={__('home_btn_cancel')}
          confirmText={__('delete_product_title')}
        />
      )}
    </>
  )
}

export default CartProductItem

const rowStyled: SxProps<CurrentTheme> = {
  display: 'grid',
  gridTemplateColumns: `repeat(8,minmax(0,1fr))`,
  alignItems: 'center',
  backgroundColor: theme => theme.palette.background.paper,
  marginBottom: theme => theme.spacing(3),
  '& div:first-child': {
    gridColumn: 'span 4'
  },
  '& div:last-child': {
    marginBottom: '0'
  },
  '& .btn-delete': {
    color: theme => theme.palette.error.main,
    cursor: 'pointer'
  }
}

const quantityStyled: SxProps<CurrentTheme> = {
  '& .input': {
    width: 'auto',
    maxWidth: theme => theme.spacing(5),
    padding: theme => theme.spacing(0.5)
  },

  '& .icon-button': {
    color: theme => theme.palette.text.primary,
    fontSize: 12
  }
}
