//Library
import React, {memo, useCallback, useRef, useState} from 'react'
import {faEye} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { Form} from 'react-bootstrap'
import {notEmpty, useField, useForm} from '@shopify/react-form'
import axios, {AxiosError} from 'axios'
import {Link} from 'react-router-dom'

//Components
import UserModalConfirm from './user.modal.confirm'
import ProgressBarPassword from 'components/ProgressBarPassword'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import __ from 'languages/index'

//Helpers
import helpers from 'helpers'

import {EnumTypeToast, useToast} from '../../../hooks/useToast'

//Scss
import '../media/user.setting.account.scss'
import {logout} from "../../../store/user.store.reducer";
import { Box, Button, Stack, styled } from '@mui/material'

const UserAccountSetting = () => {
  const newPassRef = useRef(null)
  const user_data = useAppSelector(state => state.user.user_data)

  const [isShowOldPassword, setIsShowOldPassword] = useState<boolean>(false)
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false)
  const [isShowUpdatePassword, setIsShowUpdatePassword] = useState<boolean>(false)
  const toast = useToast()
  const dispatch = useAppDispatch();

  const { fields, reset, submit, dirty, submitting } = useForm({
    fields: {
      oldPassword: useField({
        value: '',
        validates: [
          notEmpty(`${__("user_setting_password_old")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("user_setting_password_old")}`
            }
          }
        ]
      }),
      newPassword: useField({
        value: '',
        validates: [
          notEmpty(`${__("user_setting_password_new")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("user_setting_password_new")}`
            }
            if(helpers.getPasswordStrength(inputVal) < 1){
              return  `${__("user_setting_change_password_sub_text")}`
            }
          }
        ]
      }),
      confirmPassword: useField({
        value: '',
        validates: [
          notEmpty(`${__("user_setting_confirm_password_new")}`),
          inputVal => {
            if (!inputVal) {
              return `${__("user_setting_confirm_password_new")}`
            }
            if (inputVal !== newPassRef.current) {
              return `${__("user_setting_password_error")}`
            }
          }
        ]
      })
    },
    async onSubmit() {
      try {
        setIsShowUpdatePassword(true)
        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleConfirmModalQuestion = useCallback(
    async (result: boolean) => {
      setIsShowUpdatePassword(false)
      if (result) {
        await axios
          .patch(`/user/${user_data?._id}`, {
            _id: user_data?._id,
            old_password: fields.oldPassword.value.trim(),
            user_password: fields.newPassword.value.trim()
          })
          .then(() => {
            reset()
            toast.show({
              content: `${__("user_setting_change_password_success")}`,
              type: EnumTypeToast.Success
            })
            dispatch(logout())
          })
          .catch((error: AxiosError) => {
            console.log(`updateDataUser_${error}`)
            if (`${error}` === 'E-mail or Password is not correct!') {
              toast.show({
                content: `${__("user_setting_password_old_incorrect")}`,
                type: EnumTypeToast.Error
              })
              return
            }
            toast.show({
              content: `${__("user_setting_change_password_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }
    },
    [isShowUpdatePassword, user_data, fields]
  )

  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      fields[name]?.onChange(value)
      if (name === 'newPassword') {
        newPassRef.current = value
      }
    },
    [fields]
  )

  return (
    <UserAccountSettingContainer>

      <Form onSubmit={submit} id="userAccountSetting" className="userAccountSetting_container">
        <h4 className="userAccountSetting_label">{__("user_setting_change_password")}</h4>
        <div className="userAccountSetting_body">
          <div className="userAccountSetting_body_item">
            <Form.Control
              value={fields.oldPassword.value || ''}
              name="oldPassword"
              autoFocus
              onChange={handleChangeValue}
              onBlur={() => {
                fields.oldPassword?.runValidation(fields.oldPassword.value)
              }}
              onFocus={() => fields.oldPassword?.setError('')}
              id="oldPassword"
              type={isShowOldPassword ? 'text' : 'password'}
              placeholder=" "
              className={`userAccountSetting_body_item_input ${
                fields.oldPassword?.error && 'validate_failed'
              }`}
            />
            <label
              htmlFor="oldPassword"
              className={`userAccountSetting_body_item_label ${
                fields.oldPassword?.error && 'validate_failed'
              }`}
            >
              {__("user_setting_password_old_title")}
            </label>
            {fields.oldPassword?.error && (
              <span className="form-text text-danger">{fields.oldPassword?.error}</span>
            )}
            <div
              className={`userAccountSetting_body_item_eye ${
                fields.oldPassword?.error && 'validate_failed'
              }`}
              role="button"
              onClick={() => setIsShowOldPassword(!isShowOldPassword)}
            >
              <Box component={FontAwesomeIcon}
                sx={isShowOldPassword ? { color: theme=>theme.palette.primary.main } : null}
                icon={faEye}
              />
            </div>
          </div>
          <div className="userAccountSetting_body_item">
            <Form.Control
              value={fields.newPassword.value || ''}
              name="newPassword"
              onChange={handleChangeValue}
              onBlur={() => {
                fields.newPassword?.runValidation(fields.newPassword.value)
                fields.confirmPassword.value &&
                  fields.confirmPassword?.runValidation(fields.confirmPassword.value)
              }}
              onFocus={() => fields.newPassword?.setError('')}
              id="newPassword"
              type={isShowNewPassword ? 'text' : 'password'}
              placeholder=" "
              className={`userAccountSetting_body_item_input ${
                fields.newPassword?.error && 'validate_failed'
              }`}
            />
            <label
              htmlFor="newPassword"
              className={`userAccountSetting_body_item_label ${
                fields.newPassword?.error && 'validate_failed'
              }`}
            >
              {__("user_setting_password_new_title")}
            </label>
            {fields.newPassword?.error && !fields.newPassword.value && (
              <span className="form-text text-danger">{fields.newPassword?.error}</span>
            )}
            {fields.newPassword.value && (
                <ProgressBarPassword strength_password={helpers.getPasswordStrength(fields.newPassword.value)} />
              )}
            <div
              className={`userAccountSetting_body_item_eye ${
                fields.newPassword?.error && 'validate_failed'
              }`}
              role="button"
              onClick={() => setIsShowNewPassword(!isShowNewPassword)}
            >
              <Box component={FontAwesomeIcon}
                sx={isShowNewPassword ? { color: theme=>theme.palette.primary.main } : null}
                icon={faEye}
              />
            </div>
          </div>
          <div className="userAccountSetting_body_item">
            <Form.Control
              value={fields.confirmPassword.value || ''}
              name="confirmPassword"
              onChange={handleChangeValue}
              onBlur={() => {
                fields.confirmPassword?.runValidation(fields.confirmPassword.value)
              }}
              onFocus={() => fields.confirmPassword?.setError('')}
              id="confirmPassword"
              type={isShowConfirmPassword ? 'text' : 'password'}
              placeholder=" "
              className={`userAccountSetting_body_item_input ${
                fields.confirmPassword?.error && 'validate_failed'
              }`}
            />
            <label
              htmlFor="confirmPassword"
              className={`userAccountSetting_body_item_label ${
                fields.confirmPassword?.error && 'validate_failed'
              }`}
            >
              {__("user_setting_re-enter_password_new")}
            </label>
            {fields.confirmPassword?.error && (
              <span className="form-text text-danger">{fields.confirmPassword?.error}</span>
            )}
            <div
              className={`userAccountSetting_body_item_eye ${
                fields.confirmPassword?.error && 'validate_failed'
              }`}
              onClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
            >
              <Box component={FontAwesomeIcon}
                sx={isShowConfirmPassword ? { color: theme=>theme.palette.primary.main } : null}
                icon={faEye}
              />
            </div>
          </div>
        </div>
        <Stack direction={'row'} alignItems={'center'} className="userAccountSetting_footer">
          <Link to={'/forgot-password'} className="userAccountSetting_footer_forgotPassword">
            {__("user_setting_forgot_password")}
          </Link>
          <Button sx={{textTransform: 'none'}} onClick={submit} disabled={!dirty || submitting}>
            {submitting ? `${__("btn_processing")}` : `${__("btn_update")}`}
          </Button>
        </Stack>
        {isShowUpdatePassword && (
          <UserModalConfirm
            show={isShowUpdatePassword}
            title={__("user_setting_modal_title")}
            onClose={handleConfirmModalQuestion}
          >
            <p>{__("user_setting_modal_text")}</p>
          </UserModalConfirm>
        )}
      </Form>
    </UserAccountSettingContainer>
  )
}

export default memo(UserAccountSetting)

const UserAccountSettingContainer = styled(Box)(({theme})=> ({
  '& .form-control':{
    ':focus':{
      boxShadow: `${theme.palette.primary.main} 0px 0px 6px 0.2rem !important`,
      borderColor: `${theme.palette.primary.main} !important`
    }
  }
}))
