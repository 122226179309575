import React, {forwardRef, memo, ReactNode, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import '../media/livestream.react.ani.scss';
import helpers from "helpers/index";

interface TypedLivestreamReactAniItem {
  clientX: number;
  clientY: number;
  component: ReactNode;
  key: string;
}

const LivestreamReactAni = forwardRef((_, ref) => {
  const [listReactToShow, setListReactToShow] = useState<TypedLivestreamReactAniItem[]>([])

  useImperativeHandle(ref, () => ({
    showReact: (clientX: number, clientY: number, component: ReactNode) => {
      setListReactToShow(old => [...old, {
        clientX,
        clientY,
        component,
        key: helpers.getRandomHash(5)
      }])

      setTimeout(() => {
        setListReactToShow(old => {
          if (old.length > 0) {
            return old.filter((_, i) => i > 0)
          } else {
            return old
          }
        })
      }, 2800)
    }
  }))


  const renderItemReact = useCallback((item: TypedLivestreamReactAniItem)=>{
    return(
      <div
        key={item.key}
        className="emoji_livestream"
        style={{top: item.clientY, left: item.clientX}}
      >
        {item.component}
      </div>
    )
  },[])

  return (
    <>
      {listReactToShow.map(renderItemReact)}
    </>
  );
});

export default memo(LivestreamReactAni);
