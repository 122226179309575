export const ranking = [
  {
    _id: '658c0a12214cdc430dcaf8ec',
    user_id: {
      _id: '658c09fe214cdc430dcaf7e6',
      user_login: 'test08_gmail.com',
      user_avatar: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      user_avatar_thumbnail: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      display_name: 'User 1',
      user_role: 'user',
      user_status: 1,
      last_active: '2024-01-03T08:17:57.000Z'
    },
    channel_id: '64d5decfaa2fed1758451616',
    permission: [
      'user',
      'request/create',
      'comment/create',
      'contact_form/create',
      'report/create',
      'transaction/create',
      'order/create',
      'chat_history/create',
      'ticket/create'
    ],
    point_month: 100000000023,
    point_week: 100000000023,
    point: 1000,
    level_number: 10,
    createdAt: '2023-12-27T11:27:14.989Z',
    updatedAt: '2024-01-03T04:57:07.705Z'
  },
  {
    _id: '658cecff7b42ecb50d83bf09',
    user_id: {
      _id: '658cea16151e51e9aa8fa3a4',
      user_login: 'tranbaphuc1999_gmail.com',
      user_avatar: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      user_avatar_thumbnail: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      display_name: 'User 2',
      user_role: 'user',
      user_status: 1,
      last_active: '2023-12-28T03:58:46.000Z'
    },
    channel_id: '64d5decfaa2fed1758451616',
    permission: [
      'user',
      'request/create',
      'comment/create',
      'contact_form/create',
      'report/create',
      'transaction/create',
      'order/create',
      'chat_history/create',
      'ticket/create'
    ],
    point_month: 2000,
    point_week: 2000,
    point: 500,
    level_number: 5,
    createdAt: '2023-12-28T03:35:27.032Z',
    updatedAt: '2023-12-28T11:19:31.401Z'
  },
  {
    _id: '658c0995214cdc430dcaed61',
    user_id: {
      _id: '658c0989214cdc430dcaecce',
      user_login: 'test04_gmail.com',
      user_avatar: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      user_avatar_thumbnail: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      display_name: 'User 3',
      user_role: 'user',
      user_status: 1,
      last_active: '2024-01-04T04:19:25.000Z'
    },
    channel_id: '64d5decfaa2fed1758451616',
    permission: [
      'user',
      'request/create',
      'comment/create',
      'contact_form/create',
      'report/create',
      'transaction/create',
      'order/create',
      'chat_history/create',
      'ticket/create'
    ],
    point_month: 121,
    point_week: 121,
    point: 121,
    level_number: 3,
    createdAt: '2023-12-27T11:25:09.808Z',
    updatedAt: '2024-01-04T04:19:23.090Z'
  },
  {
    _id: '658c095f214cdc430dcae73a',
    user_id: {
      _id: '658c0950214cdc430dcae6ae',
      user_login: 'test02_gmail.com',
      user_avatar: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      user_avatar_thumbnail: 'https://gravatar.com/avatar/b5f51701215057fd9ac3e60a8e918d69',
      display_name: 'User 4',
      user_role: 'user',
      user_status: 1,
      last_active: '2024-01-03T07:05:33.000Z'
    },
    channel_id: '64d5decfaa2fed1758451616',
    permission: [
      'user',
      'request/create',
      'comment/create',
      'contact_form/create',
      'report/create',
      'transaction/create',
      'order/create',
      'chat_history/create',
      'ticket/create'
    ],
    point_month: 75,
    point_week: 75,
    point: 75,
    level_number: 2,
    createdAt: '2023-12-27T11:24:15.760Z',
    updatedAt: '2024-01-03T07:00:53.280Z'
  }
]
