import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import '../media/gift.receiver.gift.add.modal.scss'
import Form from 'react-bootstrap/Form'
import { EnumGiftConditionType, GiftConditionTypeTitle } from 'entities/gift/constants/gift.constant'
import { TypedGift } from 'entities/gift/gift.interface'
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll'
import { getListGifts } from 'entities/gift/services/gift.receiver.service'
import { useAppSelector } from 'config/store'
import __ from 'languages/index'
import { EnumTypeToast, useToast } from '../../../hooks/useToast'
import { InfiniteScrollContainer } from "components/InfiniteScrollContainer";
import { Box, Button, IconButton, InputBase, MenuItem, Modal, OutlinedInput, Select, Stack, Typography, styled } from '@mui/material'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import { CloseIcon, PlusIcon, SearchIcon } from 'icons'
import __helpers from 'helpers/index'
import GiftModalComponent from 'entities/challenge/component/challenge.add.detail/gift.modal.component'

interface TypedGiftReceiverGiftAddModalProps {
  addGift: (gift: TypedGift) => void,
  listGiftIdsAdded: string[]
}

const GiftReceiverGiftAddModal = forwardRef(({ addGift, listGiftIdsAdded }: TypedGiftReceiverGiftAddModalProps, ref) => {
  const [show, setShow] = useState(false);
  const user_data = useAppSelector(state => state.user.user_data)
  const toast = useToast()
  const giftModalRef = useRef(null)

  const {
    data,
    hasMore,
    setParamRequest,
    loadMore,
    updateParamRequestDebounce,
    isRefreshing,
    refresh
  } = useInfiniteScroll<TypedGift>({
    limit: 20,
    order_by: "DESC",
    auth_id: user_data?._id,
    search: "",
    gift_type: "gift",
  }, getListGifts)

  useImperativeHandle(ref, () => ({
    show: () => {
      setShow(true)
    }
  }));

  const onAddGift = useCallback((giftAdded: TypedGift) => {
    if (giftAdded?.stock_qty <= 0) {
      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Warning,
        content: `${__("gift_over")}`,
      })
    } else {
      addGift(giftAdded);
      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Success,
        content: `${__("gift_add")}` + giftAdded?.name,
      })
    }

  }, [])

  const renderItemGift = useCallback((item: TypedGift, index) => {
    if (listGiftIdsAdded.includes(item?._id)) {
      return null;
    }

    return (
      <Box sx={{
        padding: theme => theme.spacing(1, 0),
        borderBottom: '1px solid #E0E2E6'
      }} key={index.toString()}  >
        <Stack display={'grid'} gridTemplateColumns={'1fr auto'} columnGap={theme => theme.spacing(2)} alignItems={'center'} justifyContent={'space-between'}>
          <Stack display={'grid'} gridTemplateColumns={'auto 1fr'}>
            <Box mt={0.5} className='img_gift'>
              <img src={item?.media_id?.media_url} />
            </Box>

            <Box ml={2}>
              <Typography variant='body1'>{item.name}</Typography>
              <Typography mt={1} fontWeight={600} color={'primary'} variant='body1'>{__helpers.formatNumberWithCommas(item.price)} VND</Typography>
              <Typography mt={1} display={'flex'}> {
                Object.keys(item.gift_conditions).slice(0, 4).map((key, index) => (
                  "start_time|end_time|_id".includes(key) ?
                    null :
                    <div key={key} >
                      &nbsp;
                      {index > 0 && '-  '}
                      {
                        key === EnumGiftConditionType.Birth ?
                          __(GiftConditionTypeTitle[key])
                          :
                          <span>
                            {__(GiftConditionTypeTitle[key])}: <span> {" " + key !== EnumGiftConditionType.Birth && item.gift_conditions[key]}</span>
                          </span>
                      }
                    </div>
                ))
              }</Typography>

              <Typography mt={1} display={'flex'}> {
                Object.keys(item.gift_conditions).slice(4, Object.keys(item.gift_conditions).length).map((key, index) => (
                  "start_time|end_time|_id".includes(key) ?
                    null :
                    <div>
                      &nbsp;
                      {index > 0 && '-  '}

                      {
                        key === EnumGiftConditionType.Birth ?
                          __(GiftConditionTypeTitle[key])
                          :
                          <span>
                            {__(GiftConditionTypeTitle[key])}: <span>{" " + key !== EnumGiftConditionType.Birth && item.gift_conditions[key]} </span>
                          </span>
                      }
                    </div>
                ))
              }</Typography>
            </Box>
          </Stack>


          <Button onClick={() => onAddGift(item)} variant='outlined'>
            <Typography textTransform={'none'}>
              {__("gift_btn_add")}
            </Typography>
          </Button>
        </Stack>
      </Box>
    )
  }, [data, listGiftIdsAdded])

  const onChangeSearch = useCallback((event: any) => {
    updateParamRequestDebounce({ search: event?.target?.value })
  }, [])

  const onHide = useCallback(() => {
    setShow(false)
    setParamRequest({
      limit: 20,
      order_by: "DESC",
      search: ""
    })
  }, [])

  const onKeyDown = useCallback(event => {
    if (event.key.toLowerCase() === 'enter') {
      event.preventDefault()
    }
  }, [])

  const _onShowGift = useCallback(() => {
    giftModalRef.current?.show()
  }, [])
  return (
    <>
      <GiftModalComponent refresh={refresh} ref={giftModalRef} />

      <GiftReceiverGiftAddModalContainer open={show} onClose={onHide}>
        <ModalContainer>
          <ModalHeader position={'relative'} >
            <Typography textTransform={'none'} variant='h5'>  {__('challenge_chose_gift')}</Typography>
            <IconButton sx={{
              position: 'absolute',
              right: theme => theme.spacing(2)
            }} onClick={() => setShow(false)}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <ModalBody className={"giftreceivergiftaddmodal_body"}>
            <Box className="giftreceivergiftaddmodal_container">

              <Stack flexDirection={'row'} mb={1} justifyContent={'space-between'} alignItems={'center'}>

                <Box className='box_search'>
                  <IconButton aria-label="search" sx={{ padding: 0 }}>
                    <SearchIcon color="inherit" />
                  </IconButton>
                  <InputBase
                    onChange={onChangeSearch}
                    onKeyDown={onKeyDown}
                    placeholder={__('gift_search_placeholder')}
                    inputProps={{ 'aria-label': __('gift_search_placeholder') }}
                    className="search_input"
                  />
                </Box>

                <Button sx={{
                  flexGrow: 1,
                }}
                  onClick={_onShowGift}
                >
                  <PlusIcon
                    sx={{
                      width: theme => theme.spacing(2.5),
                      height: theme => theme.spacing(2.5),
                      marginRight: theme => theme.spacing(1)
                    }}
                  />
                  <Typography textTransform={'none'} fontWeight={500} >
                    {__('challenge_add_gift')}
                  </Typography>
                </Button>
              </Stack>

              <div className="overflow-y-scroll gift_scroll_bar" id="scrollableDiv">
                <InfiniteScrollContainer
                  isRefreshing={isRefreshing}
                  dataLength={data.length}
                  next={loadMore}
                  hasMore={hasMore}
                  refreshFunction={refresh}
                  scrollableTarget={"scrollableDiv"}
                >
                  <div className="row ms-0 me-0" id="course_wrap">
                    {data?.map(renderItemGift)}
                  </div>
                </InfiniteScrollContainer>
              </div>

            </Box>
          </ModalBody>
        </ModalContainer>
      </GiftReceiverGiftAddModalContainer>
    </>

  )
})

export default GiftReceiverGiftAddModal

const GiftReceiverGiftAddModalContainer = styled(Modal)(({ theme }) => ({
  '& .box_search': {
    background: '#F3F4F5',
    borderRadius: '12px',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2.5),
    flexGrow: 10,

    '& .search_input': {
      paddingLeft: theme.spacing(2),
      width: '100%'
    }
  },

  '& .img_gift': {
    width: '52px',
    height: '52px',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: theme.spacing(1.5),

    }

  }

}))
