import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from 'config/store'
import {clearError} from 'entities/courses/courses.store.reducer'
import './media/gift.receiver.add.scss'
import {EnumGiftConditionType, GiftConditionTypeTitle} from 'entities/gift/constants/gift.constant'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUp91, faBan, faMedal, faPlus, faTrashCan} from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from 'react-router-dom'
import GiftReceiverAddModal from 'entities/gift/components/gift.receiver.add.modal'
import GiftReceiverGiftAddModal from 'entities/gift/components/gift.receivergift.add.modal'
import {TypedGift} from 'entities/gift/gift.interface'
import {TypedDataMember} from '../../interfaces/member.interface'
import {ConfirmModal} from 'components/modalConfirm'
import Button from 'react-bootstrap/Button'
import {sendGiftsToUsers} from 'entities/gift/services/gift.receiver.service'
import Spinner from 'react-bootstrap/Spinner'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import helpers from 'helpers/index'
import BigLayout from "../../layouts/bigLayout";
import {EnumTypeToast, useToast} from '../../hooks/useToast'

export default function GiftReceiverAdd() {
  const permission_data = useAppSelector(state => state.user.permission_data);
  const refConfirmModal = useRef<any>()
  const dispatch = useAppDispatch();
  // const refGiftDetailModal = useRef<any>()
  const refGiftReceiverAddModal = useRef<any>()
  const refGiftReceiverGiftAddModal = useRef<any>()
  const [loading, setLoading] = useState(false)
  const [listGiftAdded, setListGiftAdded] = useState<TypedGift[]>([])
  const refListGiftIdsAdded = useRef<string[]>([])
  const [listReceiverAdded, setListReceiverAdded] = useState<TypedDataMember[]>([])
  const refListReceiverIdsAdded = useRef<string[]>([])
  const navigate = useNavigate();
  const toast = useToast()

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if(permission_data){
      if (!helpers.current_user_can("boss", permission_data)) {
        navigate('/404_notfound')
        return
      }
    }
  }, [permission_data])

  const addGift = useCallback((gift: TypedGift) => {
    setListGiftAdded(old => [...old, gift])
    refListGiftIdsAdded.current = [...refListGiftIdsAdded.current, gift?._id]
  }, [])

  const removeGift = useCallback((gift: TypedGift) => {
    setListGiftAdded(old => old.filter(itemGift => itemGift._id !== gift._id))
    refListGiftIdsAdded.current = refListGiftIdsAdded.current.filter(itemGiftId => itemGiftId !== gift._id)
  }, [])

  const addReceiver = useCallback((receiver: TypedDataMember) => {
    setListReceiverAdded(old => [...old, receiver])
    refListReceiverIdsAdded.current = [...refListReceiverIdsAdded.current, receiver?._id]
  }, [])

  const removeReceiver = useCallback((receiver: TypedDataMember) => {
    setListReceiverAdded(old => old.filter(itemReceiver => itemReceiver._id !== receiver._id))
    refListReceiverIdsAdded.current = refListReceiverIdsAdded.current.filter(itemReceiverId => itemReceiverId !== receiver._id)
  }, [])

  const renderGift = useCallback((item: TypedGift) => {
    return (
      <div key={item?._id} className="w-100 giftreceiveradd_itemcontainer">
        <div className="giftreceiveradd_item">
          <div className="giftreceiveradd_imggift">
            <img src={item?.media_id?.media_url} className='w-100 h-100 object-fit-cover'/>
          </div>


          <div className="py-3 px-3 w-100 d-flex justify-content-evenly flex-column">
            <div className="giftreceiveradd_namegift">{item.name}</div>
            <div className="giftreceiveradd_pricegift">{item.price} đ</div>

            <div className="w-100">
              <div className="giftreceiveradd_txtcondition">{__("gift_received_condition")}</div>
              <div className="d-flex flex-wrap w-100">
                {
                  Object.keys(item.gift_conditions).map((key, index) => (
                    "start_time|end_time|_id".includes(key) ?
                      null :
                      <div key={index.toString()} className="giftreceiveradd_condition_item">
                        {
                          key === EnumGiftConditionType.Birth ?
                            __(GiftConditionTypeTitle[key])
                            :
                            <span>
                            {__(GiftConditionTypeTitle[key])}: <span
                              className="giftreceiveradd_txtcondition_item">{" " + key !== EnumGiftConditionType.Birth && item.gift_conditions[key]}</span>
                          </span>
                        }
                      </div>
                  ))
                }
              </div>
            </div>

          </div>
        </div>
        <button
          onClick={() => removeGift(item)}
          className="btn giftreceiveradd_btn_add giftreceiveradd_btn_delete">
          <FontAwesomeIcon icon={faTrashCan}/>
        </button>
      </div>
    )
  }, [])

  const renderReceiver = useCallback((item: TypedDataMember) => {
    return (
      <div key={item?._id} className="giftreceiveradd_itemcontainer">
        <div className="giftreceiveradd_item">
          <img src={item?.user_avatar_thumbnail || item?.user_avatar}
               className='giftreceiveradd_imgavatar'/>

          <div className="giftreceiveradd_info">
            <div className="giftreceiveradd_name">{item?.display_name}</div>
            <div className="d-flex align-items-center giftreceiveradd_container_user_txtlvl">
              <FontAwesomeIcon icon={faArrowUp91} style={{marginRight: 5}}/>
              <div style={{marginRight: 25}}>{item?.point} {__("gift_condition_point")}</div>
              <FontAwesomeIcon icon={faMedal} style={{marginRight: 5}}/>
              {__("gift_condition_level")} {item?.level_number}
            </div>
          </div>
        </div>


        <button
          onClick={() => removeReceiver(item)}
          className="btn giftreceiveradd_btn_add giftreceiveradd_btn_delete">
          <FontAwesomeIcon icon={faTrashCan}/>
        </button>
      </div>
    )
  }, [])

  const EmptyList = useCallback((textEmpty: string) => {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column">
        <FontAwesomeIcon icon={faBan} style={{width: 40, height: 40}}/>
        <div className="h6 text-body-secondary mt-1 mb-3">{__("list_empty")}</div>
        <div className="h5 text-body-secondary text-lg-center">{textEmpty}</div>
      </div>
    )
  }, [])

  const onPressSendGift = useCallback(() => {

    let canSendGift = true;

    for (let i = 0; i < listGiftAdded.length; i++) {
      if (listGiftAdded[i]?.stock_qty < listReceiverAdded.length) {
        toast.show({
          title: `${__("notification")}`,
          type: EnumTypeToast.Error,
          content:  `${__("gift_received_content_quantity")}` + listGiftAdded[i]?.name + `${__("gift_received_content_quantity_not_enough")}`,
        })

        canSendGift = false;
        break;
      }
    }

    if (canSendGift) {
      refConfirmModal.current?.show({
        title: `${__("gift_given")}`,
        description: `${__("gift_description_text_send")} ${listGiftAdded.length} ${__("gift_description_text_present")} ${listReceiverAdded.length} ${__("gift_description_text_question")}`,
        confirmText: `${__("gift_confirm_text")}`,
        onConfirm: sendGift
      })
    }
  }, [listGiftAdded, listReceiverAdded])

  const sendGift = useCallback(async () => {
    setLoading(true)
    let isSuccess = await sendGiftsToUsers({
      "gift_id": refListGiftIdsAdded.current?.join(","),
      "partner_id": refListReceiverIdsAdded.current?.join(","),
      "quantity": 1
    })

    if (isSuccess) {
      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Success,
        content: `${__("gift_given_success")}`,
      })

      navigate("/gift/receivers")
    } else {
      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Error,
        content: `${__("gift_given_failed")}`,
      })

      setLoading(false)
    }
  }, [])

  const onPressCancel = useCallback(() => {
    if (listReceiverAdded.length > 0 || listGiftAdded.length > 0) {
      refConfirmModal.current?.show({
        title: `${__("gift_given_cancel")}`,
        description: `${__("gift_given_cancel_description")}`,
        cancelText: `${__("gift_given_cancel_text")}`,
        confirmText: `${__("gift_given_confirm_text")}`,
        onCancel: () => navigate("/gift/receivers")
      })
    } else {
      navigate("/gift/receivers")
    }
  }, [listGiftAdded, listReceiverAdded])

  return (
    <BigLayout>
      <div id={"gift-receiver-add"}>
        <_Helmet title='gift_given' />
        <div className="w-100 d-flex justify-content-center">
          <div className="container giftreceiveradd overflow-y-auto">
            <div className="giftreceiveradd_header">
              <div className="giftreceiveradd_container_header">
                <div className={'h5'}>{__("gift_given")}</div>

                <div className="d-flex align-items-center">
                  {!loading && <Button variant="secondary" onClick={onPressCancel} className="me-2">
                    {__("gift_update_text_cancel")}
                  </Button>}
                  <Button
                    disabled={listGiftAdded.length === 0 || listReceiverAdded.length === 0 || loading}
                    onClick={onPressSendGift}>
                    {loading ? <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="visually-hidden">{__("loading")}</span>
                    </> : `${__("gift_send_text")}`}
                  </Button>
                </div>

              </div>
            </div>

            <div className="row giftreceiveradd_container_content">
              <div className="col-lg-6 col-md-12 pt-4 h-100">
                <div className="giftreceiveradd_container_gift h-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{__("gift_list")}</div>

                    <button onClick={() => refGiftReceiverGiftAddModal.current?.show()}
                            className="btn giftreceiveradd_btn_add d-flex align-items-center">
                      <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/>{__("gift_update_create")}
                    </button>
                  </div>

                  <div className="overflow-y-scroll giftreceiveradd_container_scroll">
                    {
                      (listGiftAdded || []).length > 0 ?
                        listGiftAdded.map(renderGift)
                        :
                        EmptyList(`${__("gift_list_show")}`)
                    }
                  </div>


                </div>
              </div>
              <div className="col-lg-6 col-md-12 pt-4 h-100">
                <div className="giftreceiveradd_container_gift h-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{__("gift_list_received")}</div>

                    <button onClick={() => refGiftReceiverAddModal.current?.show()}
                            className="btn giftreceiveradd_btn_add d-flex align-items-center">
                      <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/>
                      {__("gift_add_recipients")}
                    </button>
                  </div>

                  <div className="overflow-y-scroll giftreceiveradd_container_scroll">
                    {
                      (listReceiverAdded || []).length > 0 ?
                        listReceiverAdded.map(renderReceiver)
                        :
                        EmptyList(`${__("gift_list_member_show")}`)
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <GiftReceiverGiftAddModal ref={refGiftReceiverGiftAddModal} addGift={addGift}
                                  listGiftIdsAdded={refListGiftIdsAdded.current}/>
        <GiftReceiverAddModal ref={refGiftReceiverAddModal} addReceiver={addReceiver}
                              listReceiverIdsAdded={refListReceiverIdsAdded.current}/>
        {/*<GiftDetailModal ref={refGiftDetailModal}/>*/}

        <ConfirmModal ref={refConfirmModal}/>

      </div>
    </BigLayout>

  )
}
