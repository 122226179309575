import { SvgIcon, SvgIconProps } from '@mui/material'

const ClearIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
        <g clipPath="url(#clip0_6965_51571)">
          <circle cx="8" cy="8" r="8" fill="#E0E2E6" />
          <path fillRule="evenodd" clipRule="evenodd" d="M11.8047 4.19526C12.0651 4.45561 12.0651 4.87772 11.8047 5.13807L8.94281 8.00001L11.8047 10.8619C12.0651 11.1223 12.0651 11.5444 11.8047 11.8047C11.5444 12.0651 11.1223 12.0651 10.8619 11.8047L8 8.94281L5.13807 11.8047C4.87772 12.0651 4.45561 12.0651 4.19526 11.8047C3.93491 11.5444 3.93491 11.1223 4.19526 10.8619L7.05719 8.00001L4.19526 5.13808C3.93491 4.87773 3.93491 4.45562 4.19526 4.19527C4.45561 3.93492 4.87772 3.93492 5.13807 4.19527L8 7.0572L10.8619 4.19526C11.1223 3.93491 11.5444 3.93491 11.8047 4.19526Z" fill="#626F82" />
        </g>
        <defs>
          <clipPath id="clip0_6965_51571">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default ClearIcon
