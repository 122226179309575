export enum EnumRole {
  Mentor = "mentor",
  User = "user"
}

export enum EnumPublicStatus {
  public = "public",
  private = "private"
}
export enum EnumPublishStatus {
  trash = "trash",
  public = "public"
}

export enum EnumPermission {
  Channel = "channel",
  Category = "category",
  Module = "module",
  Course = "course",
  Event = "event",
  Livestream = "Livestream",
  Level = "level",
  Request = "request",
  Comment = "comment",
  User = "user",
  Gift = "gift"
}
