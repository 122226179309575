import { createRef } from 'react'

import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import ListApprovalPost from './approval.list'
import Theme404 from '../../layout/404'

import ToastComponent from 'entities/courses/components/toast.component'

export default function ApprovalPost() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'approval-list'
  let ActualPage: any

  switch (Param) {
    case 'approval-list':
      ActualPage = ListApprovalPost
      break
    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="">
        {<ActualPage />}
    </div>
  )
}
