import './media/index.scss';
import ChatWidget from './components/chat';
import _Helmet from 'components/helmet';
import __ from "languages/index";


export default function BroadCast() {

    return (
      <>
        <_Helmet title='broadCast_title' />
        <div id="broadcase" className="mainFrame">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="broadcase_video_wrap">
                  <div className="empty_video d-none">
                    <div className="fw-bold text-white">{__("broadCast_sub_title")}</div>
                  </div>

                  {/* <video controls>
                                    <source src="https://file-examples.com/wp-content/storage/2017/04/file_example_MP4_1920_18MG.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video> */}
                  <iframe
                    src="https://www.youtube.com/embed/x4D1jt0aThg?si=pRl0AoSpmQQIii9q"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className=" py-4">
                  <h4>🔴 {__("broadCast_description")} </h4>
                  <p className="text-muted">
                    {__("broadCast_description_text")}
                  </p>
                  <div className="share_wrap">
                    <a
                      className="btn btn-primary"
                      style={{ backgroundColor: '#3b5998' }}
                      href="#!"
                      role="button"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a
                      className="btn btn-primary"
                      style={{ backgroundColor: '#55acee' }}
                      href="#!"
                      role="button"
                    >
                      <i className="bi bi-twitter"></i>
                    </a>
                    <a
                      className="btn btn-primary"
                      style={{ backgroundColor: '#dd4b39' }}
                      href="#!"
                      role="button"
                    >
                      <i className="bi bi-google"> </i>
                    </a>
                    <a
                      className="btn btn-primary"
                      style={{ backgroundColor: '#ac2bac' }}
                      href="#!"
                      role="button"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <ChatWidget />
              </div>
            </div>
          </div>
        </div>
      </>
    )
}






































