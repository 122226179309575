
export enum OrderStatus {
    Pending = "draft", // Đã dặt
    AwaitPickup = "approved", // Xác nhận
    AwaitDelivery = "transporting", //Đang vậbn chuyển
    Delivered = "completed", // Thành công
    Cancelled = "cancel", // Cancel
    ReturnInitated = "refunded" // Hoàn trả
}

export enum PaymentStatus {
    UNPAID = 0, //chưa thanh toán
    PARTIAL = 1, //thanh toán 1 phần,
    PAID = 2 // đã thanh toán

}

export enum TypeActionOrderList {
    ADD = 1,
    UPDATE = 2,
    DELETE = 3
} //1: add 2 change quantity 3 delete