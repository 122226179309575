import __ from 'languages/index'

export const dataListPaymentMethod = [
  // {
  //   id: 1,
  //   name: 'vn_pay',
  //   text: `${__("payment_method_vn_pay")}`,
  // },
  {
    id: 2,
    name: 'cod',
    value: 'cash_on_delivery',
    text: `${__('payment_method_cod')}`
  }
]

export const dataListAddressCity = [
  {
    code_area: 29,
    value: 'Hà Nội'
  },
  {
    code_area: 90,
    value: 'Hà Nam'
  },
  {
    code_area: 35,
    value: 'Ninh Bình'
  },
  {
    code_area: 17,
    value: 'Thái Bình'
  },
  {
    code_area: 89,
    value: 'Hưng yên'
  },
  {
    code_area: 99,
    value: 'Bắc Ninh'
  }
]

export const dataListAddressDistrict = [
  {
    code_district: 1,
    code_area: 29,
    value: 'Hà Đông'
  },
  {
    code_district: 2,
    code_area: 29,
    value: 'Nam Từ Liêm'
  },
  {
    code_district: 3,
    code_area: 29,
    value: 'Hoàng Mai'
  },
  {
    code_district: 4,
    code_area: 29,
    value: 'Hai Bà Trưng'
  },
  {
    code_district: 5,
    code_area: 29,
    value: 'Hoàn Kiếm'
  },
  {
    code_district: 6,
    code_area: 29,
    value: 'Ba Đình'
  }
]

export const fiterByCreatedAt = [
  {
    id: 'createdAt:desc',
    value: 'Mới nhất'
  },
  {
    id: 'createdAt:asc',
    value: 'Cũ nhất'
  }
]

export const bannerTarget = [
  {
    name: 'category',
    value: 'Danh mục sản phẩm'
  },
  // {
  //   key: 'collection',
  //   value: 'Nhóm sản phẩm'
  // },
  {
    name: 'product',
    value: 'Sản phẩm'
  },
  {
    name: 'link',
    value: 'Liên kết'
  }
]

export const STEPS = [
  {
    label: 'Đơn hàng đã đặt '
  },
  {
    label: 'Chờ lấy hàng'
  },
  {
    label: 'Vận chuyển'
  },
  {
    label: 'Hoàn thành giao hàng '
  },
  {
    label: 'Đánh giá'
  }
]

export const listDataCustomer = [
  {
    customer_id: '1',
    customer_name: 'Ngọc Khánh',
    customer_phonenumber: '0348474889',
    customers_referer: 'Nguyễn Phương Hoa'
  },
  {
    customer_id: '2',
    customer_name: 'Hoang Mo',
    customer_phonenumber: '0648788989',
    customers_referer: 'Nguyễn Ngọc Nữ'
  },

]

export const sortItems = [
  {
    label: __('sort_low_high'),
    value: 'product_price:asc'
  },
  {
    label: __('sort_high_low'),
    value: 'product_price:desc'
  }
]
