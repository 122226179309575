//Library
import React, { memo, useCallback, useEffect, useState } from 'react'
import { TypedChatHistoryRoom } from '../chat.interface'
import dateandtime from 'date-and-time'
import { Typography } from '@mui/material'

//Store
import { useAppSelector } from 'config/store'

//Helpers
import helpers from 'helpers'

//Image
import imageDefault from 'media/images/image_default.png'

//Scss
import '../media/chat.message.scss'

const MessageComponent = ({
  dataChat,
  prev,
  openLightBox,
  index
}: {
  dataChat: TypedChatHistoryRoom
  prev?: TypedChatHistoryRoom
  index: number
  openLightBox?: Function
}) => {
  const user_data = useAppSelector(state => state.user.user_data)
  const [isSame, setIsSame] = useState(false)
  const [avatar, setAvatar] = useState('')

  useEffect(() => {
    setIsSame(isSamePerson(dataChat, prev))
    if (dataChat?.createBy?.user_avatar) {
      setAvatar(dataChat?.createBy?.user_avatar)
    }
  }, [dataChat, prev])

  const isSamePerson = (dataChat, prev) => {
    if (!prev) {
      return false
    } else if (prev?.createBy?.display_name === dataChat?.createBy?.display_name) {
      return true
    } else {
      return false
    }
  }

  const isToday = useCallback(date => {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const yyyy = today.getFullYear()
    return date === dd + '-' + mm + '-' + yyyy
  }, [])

  const momentNew = useCallback((dateTime, format) => {
    return dateandtime.format(new Date(dateTime), format)
  }, [])

  return (
    <div id="messageComponent">
      <div className="messageComponent_time" style={!isSame ? {} : { display: 'none' }}>
        <div className="chatList_time_text">
          {isToday(momentNew(dataChat?.createdAt, 'DD-MM-YYYY')) ? (
            <div>{momentNew(dataChat?.createdAt, 'hh:mm:ss')}</div>
          ) : (
            <div>{momentNew(dataChat?.createdAt, 'hh:mm:ss DD/MM/YYYY')}</div>
          )}
          {dataChat?.media_ids &&
            dataChat?.media_ids.map((item, index) => (
              <div key={`chat_image_l${index}`}>
                {item &&
                  item.media_type === 'system_message' &&
                  item.media_meta.map((i, e) => <div key={i.value}>{JSON.stringify(i.value)}</div>)}
              </div>
            ))}
        </div>
      </div>
      {dataChat?.createBy?._id === user_data?._id && (
        <div className={`messageComponent_chat_author`}>
          {dataChat?.chat_content && (
            <Typography component="p" sx={{background: theme=>`${theme.palette.primary.main} !important`}} className="messageComponent_chat_content">
              {dataChat?.chat_content}
            </Typography>
          )}
          {!helpers.isEmpty(dataChat?.media_ids) && (
            <>
              {dataChat?.media_ids?.map((item, idx) => (
                <div
                  role="button"
                  key={`idx_${idx}`}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault()
                    openLightBox(index)
                  }}
                >
                  {item?.media_type === 'image' && (
                    <img
                      src={item?.media_url}
                      className="messageComponent_media_author"
                      alt="image"
                    />
                  )}
                  {item?.media_type === 'video' && (
                    <video
                      role="button"
                      className="messageComponent_media_author"
                      width={'auto'}
                      height={'auto'}
                      preload={'auto'}
                      controls
                    >
                      <source src={item?.media_url} type="video/mp4" />
                    </video>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      )}
      {dataChat?.createBy?._id !== user_data?._id && (
        <div className="messageComponent_chat_client_wrapper">
          <div className="messageComponent_chat_client_avatar">
            <img
              src={dataChat?.createBy?.user_avatar || imageDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src === imageDefault
              }}
              alt="avatar_client"
            />
          </div>
          <div className={`messageComponent_chat_client`}>
            {dataChat?.chat_content && (
              <Typography component="p" className="messageComponent_chat_content_client">
                {dataChat?.chat_content}
              </Typography>
            )}
            {!helpers.isEmpty(dataChat?.media_ids) && (
              <>
                {dataChat?.media_ids?.map((item, idx) => (
                  <div
                    role="button"
                    key={`client_${idx}`}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                      e.preventDefault()
                      openLightBox(index)
                    }}
                  >
                    {item?.media_type === 'image' && (
                      <img src={item?.media_url} className="messageComponent_media" alt="image" />
                    )}
                    {item?.media_type === 'video' && (
                      <video
                        role="button"
                        className="messageComponent_media"
                        width={'auto'}
                        height={'auto'}
                        autoPlay={false}
                        preload={'auto'}
                        controls
                      >
                        <source src={item?.media_url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(MessageComponent)
