//Library
import __ from 'languages/index'
import { AxiosError, AxiosResponse } from 'axios'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faImage, faFaceGrinBeam } from '@fortawesome/free-solid-svg-icons'
import { socket } from 'config/socket.io'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import debounce from 'lodash.debounce'
import { Form } from 'react-bootstrap'
import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Counter from 'yet-another-react-lightbox/plugins/counter'

//Components
import _Helmet from 'components/helmet'
import MessageComponent from './components/chat.message'
import MessageEmpty from './components/chat.message.empty'

//Image
import imageDefault from 'media/images/image_default.png'

//Interface
import { TypedChatHistoryRoom, TypedChatRoom } from './chat.interface'

//Store
import { useAppDispatch } from 'config/store'
import {
  getDataMessageChatRoom,
  getDataUserTexting,
  uploadFileOfChat,
  sendMessageToClient
} from './store/chat.store.reducer'

//Helpers
import helpers from 'helpers'

//Scss
import './media/chat.iframe.scss'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import ArrowChevronLeftIcon from 'icons/ArrowChevronLeftIcon'
import { follow, getDataUser } from 'entities/user/store/user.store.reducer'
import { TypeDataUser } from 'entities/user/interface'
import FollowIcon from 'icons/FollowIcon'

interface TypedConvertMedia {
  type?: string
  src?: string
  autoPlay?: boolean
  sources?: unknown[]
}

const ChatIframe = ({
  idChat,
  setClientInformation
}: {
  idChat: string
  setClientInformation?: Function
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const messageRef = useRef(null)
  const messagesEndRef = useRef(null)
  const containerMessageRef = useRef(null)
  const wrapperRef = useRef(null)
  const toast = useToast()

  const [message, setMessage] = useState<string>('')
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false)
  const [messageListData, setMessageListData] = useState<TypedChatHistoryRoom[]>([])
  const [userData, setUserData] = useState<TypedChatRoom>(null)
  const [isShowIcons, setIsShowIcons] = useState<boolean>(false)
  const [isNotFetchMessage, setIsNotFetchMessage] = useState<boolean>(false)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [indexConvertMedia, setIndexConvertMedia] = useState<number[]>([])
  const [isOpenLightBox, setIsOpenLightBox] = useState<boolean>(false)
  const [currentIndexLightBox, setCurrentIndexLightBox] = useState<number>(0)
  const [numberOfGetMessageData, setNumberOfGetMessageData] = useState<number>(0)
  const [convertMediaLightBox, setConvertMediaLightBox] = useState([])
  const [params, setParams] = useState({
    page: 1,
    limit: 30,
    order_by: 'DESC'
  })

  const handleSetHeightTextArea = useCallback((ref: React.RefObject<HTMLTextAreaElement>) => {
    const textarea = ref?.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${Number(textarea.scrollHeight) - 22}px`
    }
  }, [])

  const convertDataMedia = useCallback((data: TypedChatHistoryRoom[]) => {
    if (helpers.isEmpty(data)) return []
    return data?.map((item: TypedChatHistoryRoom) => {
      const mediaType = item?.media_ids[0]?.media_type;

      if (mediaType === 'image') {
        return {
          type: 'image',
          src: item?.media_ids[0]?.media_url
        }
      } else if (mediaType === 'video') {
        return {
          type: 'video' as const,
          autoPlay: true,
          sources: [
            {
              src: item?.media_ids[0]?.media_url,
              type: 'video/mp4'
            }
          ]
        }
      } else {
        return {}
      }
    })
  }, [])

  const openLightBox = useCallback(
    (index: number) => {
      setIsOpenLightBox(true)
      indexConvertMedia.forEach((indexMedia: number, idx: number) => {
        if (Number(indexMedia) === Number(index)) {
          return setCurrentIndexLightBox(idx)
        }
      })
    },
    [convertMediaLightBox, indexConvertMedia]
  )

  const dataMedia = convertDataMedia(messageListData)

  const onEmojiSelect = useCallback(
    emoji => {
      setMessage(message => `${message}${emoji?.native}`)
    },
    [message]
  )

  const handleScrollToBottom = useCallback(() => {
    try {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    } catch (error) {
      console.log('Scroll error: ', error)
    }
  }, [])

  const handleFetchMessage = useCallback(() => {
    if (isNotFetchMessage || numberOfGetMessageData < 1) return
    if (containerMessageRef.current.scrollTop === 0) {
      setParams({
        ...params,
        page: params.page + 1
      })
    }
  }, [isNotFetchMessage, messageListData])

  useEffect(() => {
    if (helpers.isEmpty(dataMedia)) return
    setIndexConvertMedia([])
    setConvertMediaLightBox([])
    dataMedia.forEach((itemMedia: TypedConvertMedia, idx: number) => {
      if (!helpers.isEmpty(itemMedia)) {
        setIndexConvertMedia(indexConvertMedia => [...indexConvertMedia, idx])
        setConvertMediaLightBox(convertMediaLightBox => [...convertMediaLightBox, itemMedia])
        return
      }
    })
  }, [messageListData])

  useEffect(() => {
    if (!idChat) return
    dispatch(getDataUserTexting(idChat))
      .unwrap()
      .then((res: AxiosResponse) => {
        setUserData(res?.data)
        if (typeof setClientInformation === 'function') {
          setClientInformation(res?.data)
        }
      })
      .catch((error: AxiosError) => {
        console.log('Error', error)
        setUserData(null)
      })
  }, [idChat])

  useLayoutEffect(() => {
    if (!idChat) return
    setUserData(null)
    setMessage('')
    setParams(params => ({ ...params, page: 1 }))
    setMessageListData([])
  }, [idChat])

  useEffect(() => {
    if (!idChat) return
    params['_id'] = idChat
    dispatch(getDataMessageChatRoom(params))
      .unwrap()
      .then((res: AxiosResponse) => {
        setNumberOfGetMessageData(numberOfGetMessageData => numberOfGetMessageData + 1)
        if (helpers.isEmpty(res?.data)) {
          return setIsNotFetchMessage(true)
        }
        params?.page < 2 && handleScrollToBottom()
        if (helpers.isEmpty(messageListData)) {
          return setMessageListData(res?.data?.reverse())
        }
        res?.data?.forEach((item: TypedChatHistoryRoom) => {
          if (messageListData.every((data: TypedChatHistoryRoom) => data?._id !== item?._id)) {
            setMessageListData(messageListData => [item, ...messageListData])
          }
        })
      })
      .catch((error: AxiosError) => {
        console.log('Error', error)
        setMessageListData([])
      })
  }, [params])

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (wrapperRef?.current && !wrapperRef?.current.contains(event.target)) {
        setIsShowIcons(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (message) return
    const textarea = messageRef?.current
    if (textarea) {
      textarea.style.height = `50px`
    }
  }, [message, messageRef])

  const debounceTyping = useMemo(() => {
    return debounce(() => {
      socket.emit('typingToServer', `room_${idChat}`)
    }, 500)
  }, [idChat])

  const handleTyping = useCallback(() => {
    try {
      debounceTyping()
    } catch (error) {
      console.log('Error typing', error)
    }
  }, [socket])

  const handleSendMessage = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (!message.trim()) return
      const formData = {
        chat_room_id: idChat,
        chat_content: message.trim()
      }
      setSendMessageLoading(true)
      dispatch(sendMessageToClient(formData))
        .unwrap()
        .then((res: AxiosResponse) => {
          setMessage('')
          setSendMessageLoading(false)
          handleScrollToBottom()
          setIsTyping(false)
          setMessageListData(messageListData => [...messageListData, res?.data])
        })
        .catch((error: AxiosError) => {
          console.log(`SendMessage_${error}`)
          setSendMessageLoading(false)
          toast.show({
            content: `${__("chat_sending_message_failed")}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [message, idChat]
  )

  const handleSendFile = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    // let fileArr: File[] = Array.from(e.target.files)
    const fileArr = e.target.files

    if (!helpers.isEmpty(fileArr)) {
      // fileArr.forEach(async (file: File) => await handleUpload(file))
      const fileData = new FormData()
      fileData.append('file[]', fileArr[0])
      const dataMedia = await dispatch(uploadFileOfChat(fileData))
        .unwrap()
        .then((res: AxiosResponse) => {
          return res?.data
        })
        .catch((error: AxiosError) => {
          console.log(`SendMessage_${error}`)
          return null
        })
      if (helpers.isEmpty(dataMedia)) {
        toast.show({
          content: `${__("about_page_upload_failed")}`,
          type: EnumTypeToast.Error
        })
      } else {
        const formData = {
          chat_room_id: idChat,
          media_data: JSON.stringify([dataMedia[0]?.callback?._id])
        }
        await dispatch(sendMessageToClient(formData))
          .unwrap()
          .then((res: AxiosResponse) => {
            setMessage('')
            setSendMessageLoading(false)
            setIsTyping(false)
            setMessageListData(messageListData => [...messageListData, res?.data])
            handleScrollToBottom()
          })
          .catch((error: AxiosError) => {
            console.log(`SendMessage_${error}`)
            toast.show({
              content: `${__("about_page_upload_failed")}`,
              type: EnumTypeToast.Error
            })
          })
      }
    }
  }, [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e?.key === 'Enter' && !e?.shiftKey && !sendMessageLoading) {
        e.preventDefault()
        handleSendMessage(e)
      }
    },
    [message, sendMessageLoading]
  )

  const debounceTimeOut = debounce(() => {
    setIsTyping(false)
  }, 1000)

  useEffect(() => {
    try {
      socket.on('typingToClient', data => {
        if (helpers.isJson(data)) {
          if (userData?.partner_id?._id === JSON.parse(data)?.user_id) {
            setIsTyping(true)
          }
          debounceTimeOut()
        }
      })
    } catch (error) {
      console.log(`typingToClient_${error}`)
    }
  }, [socket, userData])

  useEffect(() => {
    socket.emit('joinRoom', `room_${idChat}`, response => {
      console.log('Response: ', response)
    })
    return () => {
      socket.emit('leaveRoom', `room_${idChat}`)
    }
  }, [socket, idChat])

  useEffect(() => {
    try {
      socket.on('msgToUser', data => {
        if (helpers.isJson(data)) {
          handleScrollToBottom()
          setIsTyping(false)
          const parseData = JSON.parse(data)
          if (idChat === parseData?.chat_room_id) {
            if (
              messageListData.every((data: TypedChatHistoryRoom) => data?._id !== parseData?._id)
            ) {
              setMessageListData(messageListData => [...messageListData, parseData])
            }
          }
        }
      })
    } catch (error) {
      console.log(`msgToUser_${error}`)
    }
  }, [socket])

  console.log(userData)


  const handleFollow = useCallback(
    (_id: string) => {
      dispatch(follow({ partner_id: _id }))
        .unwrap()
        .then(() => {
          setUserData(dataUser => ({ ...dataUser, is_follow: true }))
        })
        .catch((error: AxiosError) => {
          console.log(`follow_${error}`)
          toast.show({
            content: `${__("user_detail_follow_failed")}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [userData]
  )

  return (
    <>
      <_Helmet title="Nhắn tin" />
      <div id="chatIframe" className="chatIframe_container">
        <div className="chatIframe_header">
          <IconButton className='back-to-list' onClick={() => navigate(-1)}>
            <ArrowChevronLeftIcon />
          </IconButton>
          <a
            href={`${window.location.origin}/user/detail/${userData?.partner_id?._id}`}
            target="_blank"
            className="chatIframe_header_image_wrapper"
          >
            <img
              src={userData?.partner_id?.user_avatar || imageDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = imageDefault
              }}
              alt="avatar"
              className="chatIframe_header_image"
              loading="lazy"
            />
            {userData?.partner_id?.user_active > 0 && (
              <div className="chatIframe_header__image__status"></div>
            )}
          </a>
          <div className="chatIframe_header_info_wrapper">
            <a
              target="_blank"
              href={`${window.location.origin}/user/detail/${userData?.partner_id?._id}`}
              className="chatIframe_header_info_name text-decoration-none"
            >
              {userData?.partner_id?.display_name || ''}
            </a>
            <span className="chatIframe_header_info_active">
              {userData?.partner_id?.user_active > 0
                ? __('chat_online_status')
                : `${__('chat_offline_status')} - ${helpers.subtractTimeHistory(
                  new Date(userData?.partner_id?.last_active).getTime(),
                  `${new Date().getTime()}`
                )} `}
            </span>
          </div>
        </div>
        <div ref={containerMessageRef} onScroll={handleFetchMessage} className="chatIframe_body">
          {helpers.isEmpty(messageListData) ? (
            <Stack className="empty-message">
              <img
                src={userData?.partner_id?.user_avatar_thumbnail || imageDefault}
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  e.currentTarget.src = imageDefault
                }}
                className="chatDetail_avatar"
                alt="logo"
              />

              <Typography variant="h6" mt={1}>
                {userData?.partner_id?.display_name}
              </Typography>
              <Typography color={'text.secondary'} mt={1}>
                Cấp độ {userData?.channelpermisison?.level_number} - {userData?.channelpermisison?.point} điểm
              </Typography>
              <Stack className='button_info' width={'100%'} justifyContent={'space-evenly'} flexDirection={'row'} mt={2} >
                {
                  !userData?.is_follow ? (
                    <Button variant='text' className='btn_item' sx={{
                      background: theme => theme.palette.primary.background,
                      color: '#DC3545',
                      marginLeft: theme => theme.spacing(1),
                    }}  onClick={() => handleFollow(userData?.partner_id?._id)} >
                      <FollowIcon />
                      Theo dõi
                    </Button>
                  ) : null
                }
                <Button variant='text' className='btn_item' onClick={() => window.open(`/user/detail/${userData?.partner_id?._id}`)} sx={{
                  background: theme => theme.palette.background.default,
                  color: '#000'
                }} >
                  Trang cá nhân
                </Button>

              </Stack>
            </Stack>
          ) : (
            <>
              {messageListData?.map((item: TypedChatHistoryRoom, idx: number) => (
                <MessageComponent
                  key={`message_${idx}`}
                  prev={idx == 0 ? null : messageListData[idx - 1]}
                  dataChat={item}
                  openLightBox={openLightBox}
                  index={idx}
                />
              ))}
              <div ref={messagesEndRef}></div>
            </>
          )}
        </div>
        <div className="chatIframe_footer">
          <Form onSubmit={handleSendMessage} className="chatIframe_footer_wrapper">
            <textarea
              className="chatIframe_footer_input"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setMessage(e.target.value)
                handleTyping()
              }}
              placeholder="Nhập tin nhắn"
              value={message || ''}
              ref={messageRef}
              onKeyDown={handleKeyDown}
              onFocus={handleTyping}
              onInput={() => handleSetHeightTextArea(messageRef)}
            />
            <div className="chatIframe_footer_icons">
              <div ref={wrapperRef} className="position-relative">
                <div
                  className="chatIframe_footer_icon"
                  onClick={() => setIsShowIcons(!isShowIcons)}
                >
                  <FontAwesomeIcon
                    className="chatIframe_footer_icon_item"
                    icon={faFaceGrinBeam}
                    color="#999"
                  />
                </div>
                {isShowIcons && (
                  <div className="chatIframe_footer_emoji">
                    <Picker
                      locale="vi"
                      theme="light"
                      perLine="9"
                      emojiButtonSize="38"
                      data={data}
                      onSelect
                      onEmojiSelect={onEmojiSelect}
                    />
                  </div>
                )}
              </div>
              <label htmlFor="send_file" className="chatIframe_footer_icon">
                <FontAwesomeIcon
                  className="chatIframe_footer_icon_item"
                  icon={faImage}
                  color="#999"
                />
              </label>
              <input
                type="file"
                id="send_file"
                accept="image/*, video/*"
                multiple={true}
                onChange={handleSendFile}
                className="d-none"
              />
              <button className="chatIframe_footer_icon send_message" type="submit">
                <FontAwesomeIcon
                  className="chatIframe_footer_icon_item"
                  icon={faPaperPlane}
                  color="#999"
                />
              </button>
            </div>
          </Form>
        </div>
      </div>
      {isTyping && (
        <div className="chatIframe_client_typing">{`${userData?.partner_id?.display_name} đang nhập...`}</div>
      )}
      {isOpenLightBox && (
        <Lightbox
          index={currentIndexLightBox}
          slides={convertMediaLightBox}
          open={true}
          close={() => setIsOpenLightBox(false)}
          plugins={[Video, Zoom, Counter]}
          carousel={{
            finite: true
          }}
        />
      )}
    </>
  )
}

export default ChatIframe
