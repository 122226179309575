import axios from 'axios'
import { Response } from 'entities/shop/service/service.type'
import { TypedProductCollection } from 'entities/shop/interface'



export const getProductCollection = async (params: object): Response<TypedProductCollection[], void> => {
  return await axios.get<TypedProductCollection[]>('v2/product_collection', {params})
}
export const deleteCollectionById = async (collection_id: string): Response<void, string> => {
  const url = `v2/product_collection/${collection_id}`
  return axios.delete(url)
}

