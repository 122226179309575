import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import ChatIframe from './chat.iframe'
import ChatDetail from './chat.detail'
import ChatList from './chat.list'

export default function ChatPage() {
  let useParam = useParams()
  let Param = useParam.slug || 'chat'
  let ActualPage: any
  console.log('params', Param)
  switch (Param) {

    case 'chat':
      ActualPage = ChatList
      break;

    case 'detail':
      ActualPage = ChatDetail
      break

    case 'detail-iframe':
      ActualPage = ChatIframe
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <>
      <ActualPage />
    </>
  )
}
