import React, { ChangeEvent, memo, useMemo, useState } from 'react'
import InviteUserModal from '../InviteUserModal'
import useDebounce from 'hooks/useDebounce'
import { ChallengeUser, StatusMember } from 'entities/challenge/challenge.interface'
import UserCard from '../UserCard'
import { useNavigate, useParams } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import {
  useAddUsersToChallengeMutation,
  useGetChallengeDetailQuery,
  useGetJoinedChallengeUsersQuery,
  useLeaveChallengeMutation,
  useUpdatePermissionMutation
} from 'entities/challenge/store/challengeApi'
import { useAppSelector } from 'config/store'
import { EnumTypeToast, useToast } from '../../../../hooks/useToast'
import RenderView from 'components/RenderView'
import InfiniteList from 'components/InfiniteList'
import { outOfTime } from 'entities/challenge/helpers'
import __ from "languages/index"
import helpers from 'helpers/index'
import { Button, Paper, styled, Stack, IconButton, Typography, Box, OutlinedInput, InputAdornment, InputBase, Menu, MenuItem, MenuList } from '@mui/material'
import ArrowChevronLeftIcon from 'icons/ArrowChevronLeftIcon'
import { PlusIcon, SearchIcon } from 'icons'


const limit = 10

const listButton = [
  {
    title: 'challenge_detail_status_approved',
    value: StatusMember.approved
  },
  {
    title: 'challenge_detail_status_cancel',
    value: StatusMember.rejected
  },
  {
    title: 'challenge_detail_status_pending',
    value: StatusMember.pending
  }
]

const UsersJoinedComponent = ({challengeId}: {
  challengeId: string
}) => {

  const [openModalAddUser, setOpenModalAddUser] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [page, setPage] = useState(1)
  const [status, setStatus] = useState<number>(StatusMember.approved)
  const toast = useToast()
  const account = useAppSelector(state => state.user.user_data);
  const permission_data = useAppSelector(state => state.user.permission_data);

  const debounceSearchValue = useDebounce(searchValue)
  const navigate = useNavigate();
  const options = useMemo(() => {
    return {
      challenge_id: challengeId,
      search: debounceSearchValue,
      page,
      limit,
      official_status: status
    }
  }, [debounceSearchValue, page, challengeId, status])
  const { data: challengeDetail } = useGetChallengeDetailQuery({
    challengeId: challengeId,
    authId: account?._id
  })

  const [addUserToChallenge] = useAddUsersToChallengeMutation()
  const { data: joinedUsersData, isLoading, isFetching } = useGetJoinedChallengeUsersQuery(options)
  const joinedUsers = joinedUsersData?.data
  const totalCount = joinedUsersData?.totalCount

  const [removeUserFromChallenge, { }] = useLeaveChallengeMutation()
  const [updatePermission] = useUpdatePermissionMutation()

  const openAddUserModalHandler = () => {
    setOpenModalAddUser(true)
  }

  const closeAddUserModalHandler = () => {
    setOpenModalAddUser(false)
  }

  const searchChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('e', e)
    setSearchValue(e.target.value)
  }

  const deleteUserHandler = (id: string) => {
    removeUserFromChallenge({
      challengeId: challengeId,
      userId: id,
      queryParams: { ...options }
    })
      .unwrap()
      .then(() => {
        toast.show({
          type: EnumTypeToast.Success,
          content: `${__("challenge_delete_member_content_success")}`,
          title: `${__("challenge_delete_member_title")}`
        })
      })
      .catch(err => {
        toast.show({
          type: EnumTypeToast.Error,
          content: `${__("challenge_delete_member_content_failed")}`,
          title: `${__("challenge_delete_member_title")}`
        })
      })
  }

  const updatePermissionHandler = async (id: string, status: StatusMember) => {
    const officialStatus = status.toString() as '0' | '1' | '2'
    updatePermission({
      payload: {
        user_id: id,
        challenge_id: challengeId,
        official_status: officialStatus
      },
      dependency: options
    })
      .unwrap()
      .then(() => {
        toast.show({
          type: EnumTypeToast.Success,
          content:
            status === StatusMember.approved ? `${__("challenge_accept_member")}` : `${__("challenge_reject_member")}`
        })
      })
      .catch(err => {
        toast.show({
          type: EnumTypeToast.Error,
          content: `${__("challenge_list_delete_error")}`
        })
      })
  }

  const addUsersHandler = async (userIds: string[]) => {
    await addUserToChallenge({ challengeId: challengeId, userIds, queryParams: options })
      .unwrap()
      .then(() => {
        toast.show({
          title: `${__("challenge_add_member_join")}`,
          content: `${__("challenge_add_member_success")}`,
          type: EnumTypeToast.Success
        })
      })
      .catch(err => {
        toast.show({
          title: `${__("challenge_add_member_join")}`,
          content: `${__("challenge_add_member_failed")}`,
          type: EnumTypeToast.Success
        })
      })
  }

  return (
    <>
      <UserJoinedContainer
        elevation={1}
      >

        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} >
          <Stack flexDirection={'row'} alignItems={'center'} ml={1} >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowChevronLeftIcon />
            </IconButton>
            <Typography variant='h6'>
              {__('challenge_detail_list_join')}
            </Typography>
          </Stack>
          {!outOfTime(challengeDetail?.end_time) && helpers.current_user_can('boss', permission_data) && (
            <Button
              className="ms-3 w-auto align-items-center d-flex"
              onClick={openAddUserModalHandler}
            >
              <PlusIcon />
              {__("challenge_add_member")}
            </Button>
          )}
        </Stack>
        <Box mt={2} className="box_search">
          <IconButton aria-label="search" sx={{ padding: 0 }}>
            <SearchIcon color="inherit" />
          </IconButton>
          <InputBase
            onChange={searchChangeHandler}
            placeholder={__('challenge_search_member_placeholder')}
            inputProps={{ 'aria-label': __('header_search') }}
            className="search_input"
          />
        </Box>

        <Box className='users' mt={2} >
          <Stack flexDirection={'row'} alignItems={'cemter'} className='user_filter'>
            {challengeDetail?.challenge_status === 'private' && (
              listButton.map((item) => <Button key={item.value} sx={{
                marginRight: theme => theme.spacing(1),
                borderColor: status === +item.value ? '#DC3545' : '#E0E2E6',
                background: '#FDEFF1',
                color: status === +item.value ? '#DC3545' : '#000',
              }} onClick={() => setStatus(+item.value)} variant='outlined'>{__(item.title)}</Button>))}
              
          </Stack>
        </Box>
        {openModalAddUser && <InviteUserModal
          size="lg"
          show={openModalAddUser}
          onHide={closeAddUserModalHandler}
          onAddUsers={addUsersHandler}
        />}
        <Box mt={2}>
          <RenderView
            view={
              <InfiniteList
                as={Row}
                list={joinedUsers}
                isLoading={isFetching}
                renderItem={(user: ChallengeUser, ref, index) => (
                  <UserCard
                    ref={ref}
                    className="mb-2"
                    onDelete={deleteUserHandler}
                    onUpdatePermission={updatePermissionHandler}
                    status={status}
                    total_point={user?.total_point.toString()}
                    {...user?.user_id}
                    index={index}
                  />
                )}
                hasNextPage={joinedUsers?.length < totalCount}
                onLoadMore={() => setPage(prev => prev + 1)}
              />
            }
            isLoading={isLoading}
            isEmpty={joinedUsers?.length === 0}
          />
        </Box>
      </UserJoinedContainer>
    </>
  )
}

export default UsersJoinedComponent

const UserJoinedContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),

  '& .box_search': {
    background: '#F3F4F5',
    borderRadius: '12px',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .search_input': {
      paddingLeft: theme.spacing(2)
    }
  },


}))
