import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowChevronRightIcon =(props: SvgIconProps)=>{
  return(
    <SvgIcon {...props} >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289L16 10.5858C16.781 11.3668 16.781 12.6332 16 13.4142L10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929L14.5858 12L9.29289 6.70711C8.90237 6.31658 8.90237 5.68342 9.29289 5.29289Z"
              fill="#0E1426" />
      </svg>
    </SvgIcon>
  )
}
export default ArrowChevronRightIcon
