import { Box, Button, IconButton, Paper, styled, Typography } from "@mui/material"
import { Field, useForm } from "@shopify/react-form";
import TextFieldMUI from 'components/TextFieldMUI'
import { useAppDispatch } from "config/store";
import { EnumTypeToast, useToast } from "hooks/useToast";
import { EyeIcon, EyeOffIcon, SafetyClockIcon } from "icons";
import __, { ___ } from "languages/index"
import { useEffect, useState } from "react";
import { TypedLogistic, TypedLogisticAccount } from "../interface";
import { createConnectLogistic, updateConnectLogistic } from "../store/shop.store.reducer";


interface TypedProps {
    fields: any;
    payload: {
        logistic_name: string;
        logistic_delivery_method: string;
    };
    fieldNames: string[];
    placeholder: string[];
    name: string;
    data: TypedLogistic
}
const ConfigRoadItem = ({ fields, payload, fieldNames, placeholder, name, data }: TypedProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const toast = useToast();

    const { fields: field, submit } = useForm({
        fields: fields,
        async onSubmit(values: TypedLogisticAccount) {
            try {
                const value = data ? {
                    ...data,
                    logistic_service_account: {
                        ...values,
                        logistic_username: values.logistic_username?.trim(),
                        logistic_password: values.logistic_password?.trim(),
                    },

                } :  {
                    ...payload,
                    logistic_service_account: {
                        ...values,
                        logistic_username: values.logistic_username?.trim(),
                        logistic_password: values.logistic_password?.trim(),
                    }
                } 

                dispatch( data ? updateConnectLogistic({ ...value}) :   createConnectLogistic({ ...value }))
                    .unwrap()
                    .then(() => {
                        toast.show({
                            type: EnumTypeToast.Success,
                            content: `${__('gift_update_status_success')}`
                        })
                    })
                    .catch(error => {
                        console.error('Create product failed', error)
                        toast.show({
                            type: EnumTypeToast.Error,
                            content: `${__('gift_update_status_failed')}`
                        })
                    })
            } catch (e: any) {
                console.error(` createStockSubmit`, e)
                const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
                const field = e?.response?.data?.errorKey ?? 'base'
                return { status: 'fail', errors: [{ field, message }] }
            }
            return { status: 'success' }
        }
    })

    useEffect(() =>{
        if (data) {
            field[fieldNames[0]].onChange(data.logistic_service_account?.[fieldNames[0]] || '')
            field[fieldNames[1]]?.onChange(data.logistic_service_account?.[fieldNames[1]] || '')
        }   
    }, [data])
    return (
        <ConfigRoadContainer>
            <Typography>{___('config_road {brand}', {
                brand: <>{payload.logistic_name}</>
            })}</Typography>

            <Box className="box_form">
                {
                    fieldNames.length === 1 && (
                        <TextFieldMUI

                            {...field[fieldNames[0]]}
                            // value={data ? data.logistic_service_account[fieldNames[0]] : ''}
                            requiredIndicator
                            placeholder={__(placeholder[0])}
                        />
                    )
                }

                {
                    fieldNames.length > 1 && (
                        <>
                            <TextFieldMUI

                                {...field[fieldNames[0]]}
                                maxLength={150}
                                requiredIndicator
                                placeholder={__(placeholder[0])}
                            />

                            {
                                name === 'viettel' ? (
                                    <>
                                        <TextFieldMUI
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder={__(placeholder[1])}
                                            iconStart={<SafetyClockIcon sx={{ width: theme => theme.spacing(2.375) }} />}
                                            iconEnd={
                                                <IconButton
                                                    type="button"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    sx={{ p: 0 }}
                                                >
                                                    {showPassword ?
                                                        <EyeIcon sx={{ width: theme => theme.spacing(2.5) }} /> :
                                                        <EyeOffIcon sx={{ width: theme => theme.spacing(2.5) }} />}
                                                </IconButton>
                                            }
                                            {...field[fieldNames[1]]}

                                        />
                                    </>
                                ) : (
                                    <TextFieldMUI

                                        {...field[fieldNames[1]]}
                                        maxLength={ fieldNames[1] === 'logistic_phonenumber' ? 10 : 150}
                                        requiredIndicator
                                        placeholder={__(placeholder[1])}
                                    />
                                )
                            }

                        </>
                    )
                }

                <Button onClick={submit} className="btn_save">
                    {__('btn_update')}
                </Button>
            </Box>
        </ConfigRoadContainer>
    )
}

export default ConfigRoadItem

const ConfigRoadContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .box_form': {
        marginTop: theme.spacing(1.5),
        '& fieldset': {
            border: 'none',
        }
    }


}))