import {memo, useMemo} from 'react'
import {useLocation} from 'react-router-dom'

//Component
import SalesIQ from 'layout/component/iframe.zoho'

//Constant
import {GAMIFA_CHANNEL_HOST} from 'constant/index'

//Helper
import helpers from 'helpers'

const _ComponentCheckShowIframe = ({children}) => {
  const location = useLocation()

  const isShowIframeZoho = useMemo((): boolean => {
    const arrUrlNotShowZoho = [
      '/login',
      '/logout',
      '/register',
      '/forgot-password',
      '/validate-code',
      '/channel/join',
      '/study',
      '/livestream',
      '/live-replay',
      '/live-room',
      '/chat',
      '/channel/load'
    ]
    const arrUrlShowZohoGlobal = [
      '/about-us',
      '/tos',
      '/contact',
      '/channel/create',
      '/channel/order',
    ]
    if ((GAMIFA_CHANNEL_HOST.includes(window.location.host))) {
      return !arrUrlNotShowZoho.some((url: string) => location.pathname.includes(url));

      // if (arrUrlShowZohoGlobal.some((url: string) => location.pathname.includes(url))) {
      //   return true
      // }
      // return arrUrlShowZohoGlobal.some((url: string) => location.pathname.includes(url));
    }
    return false
  }, [location.pathname, window.location.host])

  return (
    <>
      <SalesIQ isShowFrame={isShowIframeZoho}/>
      {children}
    </>
  )
}

export default memo(_ComponentCheckShowIframe)
