import React, {useCallback, useMemo} from "react"
import {Col, Row} from "react-bootstrap"
import DateTime from "date-and-time"
import {TypedAddress, TypedUser} from "entities/calendar/calendar.interface"
import __ from "languages/index";

interface Props {
  open_date: string,
  user: TypedUser,
  address: TypedAddress,
  duration: number
}

const EventContent = (props: Props) => {
  const {open_date, user, address, duration} = props


  const renderDuration = useMemo(() => {
    if (duration < 1) {
      return `${__("event_detail_30minute")}`
    }
    if (duration % 1 == 0) {
      return duration + `${__("event_hour")}`
    }
    if (duration % 1 == 0.5) {
      return Math.floor(duration) + `${__("event_detail_hour_30minute")}`
    }
    return `${__("event_detail_1hour")}`
  }, [duration])

  const DATA = [
    { id: "calendar", icon: "bi bi-calendar", title: `${__("event_time")}`, description: DateTime.format(new Date(open_date), "dddd, ngày DD-MM-YYYY lúc HH:mm") },
    {id: "time", icon: "bi bi-clock", title: `${__("event_time_takes_place")}`, description: renderDuration},
    { id: "person", icon: "bi bi-person", title: `${__("event_of_speaker")}`, description: user?.display_name },
    { id: "link", icon: "bi bi-link-45deg", title: `${__("event_link_join")}`, description: address?.key != "address" ? address?.value : "" }
  ]

  const renderItem = useCallback((item, index) => {
    if (item?.id == "link" && !item?.description){
      return null
    }
    return (
      <div className="mb-2" key={index + '_event_long_lam_bi_loi'}>
        <Row>
          <Col xs="12" md={5}>
            <Row>
              <div className='d-flex flex-row align-items-center'>
                <i className={`${item?.icon} fs-5`} />
                <div className='mx-2'>{item?.title}</div>
              </div>
            </Row>
          </Col>
          <Col xs="12" md="7">
            {item?.id == "link" && item?.description ?
              <a href={item?.description} target={'_blank'}>{item?.description.slice(0, 30)}</a>
              :
              <div>{item?.description}</div>
            }
          </Col>
        </Row>
      </div>
    )
  }, [])

  return (
    <div className="gap-3 p-3">
      {DATA.map((item, index) => {
        return renderItem(item, index)
      })}
    </div>
  )
}

export default EventContent
