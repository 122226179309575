import { SvgIcon, SvgIconProps } from '@mui/material'

const AffiliateSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="31"
        viewBox="0 0 35 31"
        fill="none"
      >
        <path
          d="M17.1949 10.1339C17.1949 10.1339 17.1417 10.0807 17.1189 10.0579C15.8117 8.80391 13.9117 8.40111 12.1865 8.96351L11.2745 9.25991C8.34089 10.2175 5.66569 11.8591 3.48449 14.0403C2.68649 14.8383 2.68649 16.1303 3.48449 16.9283L9.46569 22.9095L10.2713 22.1039L12.1865 24.0191L21.6333 14.5723L17.1949 10.1339Z"
          fill="url(#paint0_linear_1881_3756)"
        />
        <path
          d="M17.1949 10.1339C17.1949 10.1339 17.1417 10.0807 17.1189 10.0579C15.8117 8.80391 13.9117 8.40111 12.1865 8.96351L11.2745 9.25991C8.34089 10.2175 5.66569 11.8591 3.48449 14.0403C2.68649 14.8383 2.68649 16.1303 3.48449 16.9283L9.46569 22.9095L10.2713 22.1039L12.1865 24.0191L21.6333 14.5723L17.1949 10.1339Z"
          fill="url(#paint1_linear_1881_3756)"
        />
        <path
          d="M33.2612 14.0404C31.08 11.8592 28.4048 10.2176 25.4712 9.25997L24.5592 8.96357C22.8416 8.40117 20.934 8.81157 19.6268 10.058C19.5964 10.0808 8.69803 20.9868 8.69803 20.9868C8.05203 21.6328 8.04443 22.674 8.67523 23.3276C8.83483 23.4948 9.05523 23.5936 9.28323 23.6468C9.87603 23.7836 10.3168 24.2852 10.37 24.8856C10.4004 25.2504 10.5524 25.6076 10.826 25.8888C11.1148 26.1928 11.4948 26.3524 11.89 26.3828C12.498 26.4284 13.03 26.8388 13.1896 27.424C13.2428 27.6216 13.334 27.8116 13.4784 27.956C13.79 28.2752 14.2004 28.4424 14.6184 28.4576C15.2188 28.4728 15.7508 28.8528 15.9484 29.4152C16.0092 29.5976 16.108 29.7724 16.2448 29.9168C16.8908 30.5932 17.9928 30.578 18.654 29.9168L26.4744 22.0964L27.28 22.902L33.2612 16.9208C34.0592 16.1228 34.0592 14.8308 33.2612 14.0328V14.0404Z"
          fill="url(#paint2_linear_1881_3756)"
        />
        <path
          d="M23.3214 13.2198L22.2726 12.627H17.0666C13.8366 15.857 8.70658 20.987 8.70658 20.987C8.06058 21.633 8.05298 22.6742 8.68378 23.3278C8.84338 23.495 9.06377 23.5938 9.29177 23.647C9.88457 23.7838 10.3254 24.2854 10.3786 24.8858C10.409 25.2506 10.561 25.6078 10.8346 25.889C11.1234 26.193 11.5034 26.3526 11.8986 26.383C12.5066 26.4286 13.0386 26.839 13.1982 27.4242C13.2514 27.6218 13.3426 27.8118 13.487 27.9562C13.7986 28.2754 14.209 28.4426 14.627 28.4578C15.2274 28.473 15.7594 28.853 15.957 29.4154C16.0178 29.5978 16.1166 29.7726 16.2534 29.917C16.8994 30.5934 18.0014 30.5782 18.6626 29.917L26.483 22.0966L27.2886 22.9022L30.1538 20.037L23.329 13.2122L23.3214 13.2198Z"
          fill="url(#paint3_linear_1881_3756)"
        />
        <path
          d="M14.5434 15.1497C11.6934 17.9997 8.70658 20.9865 8.70658 20.9865C8.06058 21.6325 8.05298 22.6737 8.68378 23.3273C8.84338 23.4945 9.06377 23.5933 9.29177 23.6465C9.88457 23.7833 10.3254 24.2849 10.3786 24.8853C10.409 25.2501 10.561 25.6073 10.8346 25.8885C11.1234 26.1925 11.5034 26.3521 11.8986 26.3825C12.5066 26.4281 13.0386 26.8385 13.1982 27.4237C13.2514 27.6213 13.3426 27.8113 13.487 27.9557C13.7986 28.2749 14.209 28.4421 14.627 28.4573C15.2274 28.4725 15.7594 28.8525 15.957 29.4149C16.0178 29.5973 16.1166 29.7721 16.2534 29.9165C16.8994 30.5929 18.0014 30.5777 18.6626 29.9165L23.9902 24.5889L14.5434 15.1421V15.1497Z"
          fill="url(#paint4_linear_1881_3756)"
        />
        <path
          d="M20.6454 30.069C21.2762 29.4154 21.2686 28.3742 20.6226 27.7282L19.6878 26.7934C19.0342 26.1398 17.9778 26.1398 17.3242 26.7934C16.6706 27.447 16.6706 28.5034 17.3242 29.157L18.2362 30.069C18.8974 30.7302 19.9918 30.7454 20.6454 30.069Z"
          fill="url(#paint5_linear_1881_3756)"
        />
        <path
          d="M20.7374 27.8498L19.4226 26.535C18.769 25.8814 18.769 24.825 19.4226 24.1714C20.0762 23.5178 21.1326 23.5178 21.7862 24.1714L23.1238 25.509C23.7698 26.155 23.7774 27.1961 23.1466 27.8498C22.5006 28.5261 21.3986 28.511 20.7374 27.8498Z"
          fill="url(#paint6_linear_1881_3756)"
        />
        <path
          d="M25.5467 23.2137L23.9431 21.6101C23.2895 20.9565 23.2895 19.9001 23.9431 19.2465C24.5967 18.5929 25.6531 18.5929 26.3067 19.2465L27.9331 20.8729C28.5791 21.5189 28.5867 22.5601 27.9559 23.2137C27.3099 23.8901 26.2079 23.8749 25.5467 23.2137Z"
          fill="url(#paint7_linear_1881_3756)"
        />
        <path
          d="M23.3892 25.7748L21.7856 24.1712C21.132 23.5176 21.132 22.4612 21.7856 21.8076C22.4392 21.154 23.4956 21.154 24.1492 21.8076L25.7756 23.434C26.4216 24.08 26.4292 25.1212 25.7984 25.7748C25.1524 26.4512 24.0504 26.436 23.3892 25.7748Z"
          fill="url(#paint8_linear_1881_3756)"
        />
        <path
          d="M23.3204 13.2196L20.2424 10.1416C17.7724 7.67156 13.7672 7.67156 11.2972 10.1416L9.91402 11.5248C9.28322 12.1556 9.28322 13.1816 9.91402 13.8124C9.92922 13.8276 9.93682 13.8352 9.95202 13.8504C12.0192 15.8492 15.3404 15.8188 17.3696 13.782C17.5976 13.554 17.7952 13.3108 17.97 13.06L20.0904 15.1804C20.9796 16.0696 22.4236 16.0696 23.3128 15.1804C23.8524 14.6408 23.8524 13.7592 23.3128 13.2196H23.3204Z"
          fill="url(#paint9_linear_1881_3756)"
        />
        <path
          d="M17.3773 13.782C15.3481 15.8188 12.0269 15.8492 9.95967 13.8504C9.94447 13.8352 9.93687 13.8276 9.92167 13.8124C9.28327 13.1816 9.28327 12.1556 9.92167 11.5248L11.3125 10.134C11.9813 9.4652 12.7641 8.9788 13.6001 8.6748L17.9777 13.0524C17.8029 13.3108 17.5977 13.5464 17.3773 13.7744V13.782Z"
          fill="url(#paint10_linear_1881_3756)"
        />
        <path
          d="M34.7196 13.2116V18.41L31.748 20.1276C30.9956 20.5608 30.0304 20.3024 29.5972 19.55L23.5704 9.10765C23.1372 8.35525 23.3956 7.39005 24.148 6.95685L28.0012 4.73005C28.7536 4.29685 29.7188 4.55525 30.152 5.30765L34.7196 13.2192V13.2116Z"
          fill="url(#paint11_linear_1881_3756)"
        />
        <path
          d="M34.7192 13.2118V18.4102L31.7476 20.1278C30.9952 20.561 30.03 20.3026 29.5968 19.5502L27.0964 15.2106L33.6856 11.4106L34.7268 13.2194L34.7192 13.2118Z"
          fill="url(#paint12_linear_1881_3756)"
        />
        <path
          d="M32.7587 0.975787L25.7363 5.02659C24.9839 5.45979 24.7331 6.41739 25.1663 7.16219L32.1659 19.2918C32.5459 19.9454 33.3819 20.1734 34.0355 19.7934C34.4611 19.5502 34.7195 19.0942 34.7195 18.6078V2.10819C34.7195 1.10499 33.6327 0.474187 32.7587 0.975787Z"
          fill="url(#paint13_linear_1881_3756)"
        />
        <path
          d="M11.6687 9.10011L5.64193 19.5425C5.20873 20.2949 4.24353 20.5533 3.49113 20.1201L0.519531 18.4025V13.2041L5.08713 5.29251C5.52033 4.54011 6.48553 4.28171 7.23793 4.71491L11.0911 6.94171C11.8435 7.37491 12.1019 8.34011 11.6687 9.09251V9.10011Z"
          fill="url(#paint14_linear_1881_3756)"
        />
        <path
          d="M8.15087 15.2031L5.65048 19.5427C5.21728 20.2951 4.25208 20.5535 3.49968 20.1203L0.528076 18.4027V13.2043L1.56928 11.3955L8.15847 15.1955L8.15087 15.2031Z"
          fill="url(#paint15_linear_1881_3756)"
        />
        <path
          d="M9.50273 5.02659L2.48033 0.975787C1.60633 0.474187 0.519531 1.10499 0.519531 2.10819V18.6154C0.519531 19.1018 0.77793 19.5578 1.20353 19.801C1.85713 20.181 2.69313 19.953 3.07313 19.2994L10.0727 7.16979C10.5059 6.41739 10.2475 5.45979 9.50273 5.03419V5.02659Z"
          fill="url(#paint16_linear_1881_3756)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1881_3756"
            x1="14.0327"
            y1="6.75639"
            x2="11.4531"
            y2="17.9988"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDFCF" />
            <stop offset="1" stopColor="#FFA78F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1881_3756"
            x1="10.4482"
            y1="11.8456"
            x2="13.6565"
            y2="13.6459"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F89580" stopOpacity="0" />
            <stop offset="0.26" stopColor="#F2917C" stopOpacity="0.26" />
            <stop offset="0.6" stopColor="#E2856F" stopOpacity="0.6" />
            <stop offset="0.97" stopColor="#C8725B" stopOpacity="0.97" />
            <stop offset="1" stopColor="#C5715A" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1881_3756"
            x1="15.614"
            y1="12.7408"
            x2="26.0488"
            y2="23.1756"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F89580" />
            <stop offset="1" stopColor="#C4573A" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1881_3756"
            x1="18.8374"
            y1="19.1478"
            x2="17.9026"
            y2="8.53055"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4573A" stopOpacity="0" />
            <stop offset="1" stopColor="#883F2E" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1881_3756"
            x1="15.4174"
            y1="22.7421"
            x2="12.6282"
            y2="27.1501"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4573A" stopOpacity="0" />
            <stop offset="0.24" stopColor="#BB5438" stopOpacity="0.24" />
            <stop offset="0.63" stopColor="#A44B33" stopOpacity="0.63" />
            <stop offset="1" stopColor="#883F2E" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1881_3756"
            x1="20.2236"
            y1="24.1685"
            x2="18.7243"
            y2="27.2478"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDFCF" />
            <stop offset="1" stopColor="#FFA78F" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1881_3756"
            x1="26.0044"
            y1="9.5633"
            x2="23.4195"
            y2="12.1482"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDFCF" />
            <stop offset="1" stopColor="#FFA78F" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1881_3756"
            x1="30.646"
            y1="4.80191"
            x2="28.0611"
            y2="7.38681"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDFCF" />
            <stop offset="1" stopColor="#FFA78F" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1881_3756"
            x1="28.4974"
            y1="7.35978"
            x2="25.9178"
            y2="9.9393"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDFCF" />
            <stop offset="1" stopColor="#FFA78F" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1881_3756"
            x1="18.133"
            y1="6.18142"
            x2="15.1396"
            y2="19.2349"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDFCF" />
            <stop offset="1" stopColor="#FFA78F" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1881_3756"
            x1="14.0434"
            y1="9.72294"
            x2="15.1451"
            y2="13.4901"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F89580" stopOpacity="0" />
            <stop offset="0.26" stopColor="#F2917C" stopOpacity="0.26" />
            <stop offset="0.6" stopColor="#E2856F" stopOpacity="0.6" />
            <stop offset="0.97" stopColor="#C8725B" stopOpacity="0.97" />
            <stop offset="1" stopColor="#C5715A" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1881_3756"
            x1="28.024"
            y1="12.1324"
            x2="26.1392"
            y2="13.0444"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1881_3756"
            x1="30.5924"
            y1="14.1238"
            x2="30.5924"
            y2="20.029"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7CA1B1" stopOpacity="0" />
            <stop offset="1" stopColor="#7CA1B1" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1881_3756"
            x1="29.8327"
            y1="-0.0806132"
            x2="29.8327"
            y2="19.6338"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#69C6FF" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1881_3756"
            x1="6.72113"
            y1="12.3833"
            x2="8.26393"
            y2="13.4169"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1881_3756"
            x1="4.12288"
            y1="14.1239"
            x2="4.12288"
            y2="20.0291"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7CA1B1" stopOpacity="0" />
            <stop offset="1" stopColor="#7CA1B1" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_1881_3756"
            x1="5.39873"
            y1="-0.194613"
            x2="5.39873"
            y2="19.5274"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default AffiliateSidebarIcon
