import { SvgIcon, SvgIconProps } from '@mui/material'

const FilterIcon = (props: SvgIconProps) =>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M2 4.41421C2 3.08088 3.08088 2 4.41421 2H19.5858C20.9191 2 22 3.08088 22 4.41421C22 5.0545 21.7456 5.66857 21.2929 6.12132L16.2929 11.1213C16.1054 11.3089 16 11.5632 16 11.8284V20.191C16 21.3061 14.8265 22.0313 13.8292 21.5326L9.65836 19.4472C8.64201 18.939 8 17.9002 8 16.7639V11.8284C8 11.5632 7.89464 11.3089 7.70711 11.1213L2.70711 6.12132C2.25435 5.66857 2 5.0545 2 4.41421ZM4.41421 4C4.18545 4 4 4.18545 4 4.41421C4 4.52407 4.04364 4.62943 4.12132 4.70711L9.12132 9.70711C9.68393 10.2697 10 11.0328 10 11.8284V16.7639C10 17.1427 10.214 17.489 10.5528 17.6584L14 19.382V11.8284C14 11.0328 14.3161 10.2697 14.8787 9.70711L19.8787 4.70711C19.9564 4.62943 20 4.52407 20 4.41421C20 4.18545 19.8146 4 19.5858 4H4.41421Z"
              fill="#0E1426" />
      </svg>
    </SvgIcon>
  )
}
export default FilterIcon
