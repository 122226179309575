import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'
import { TypedCreateLivestream } from 'entities/calendar/calendar.interface'
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { TypedGift } from 'entities/gift/gift.interface'
import axios from 'axios'
import { ErrorMediaType } from 'entities/event/event.interface'
import liveStreamApi from './services/stream.service'
import { IRootState } from 'config/store'
import { TypedLivestream } from './livestream.interface'

export const initialState = {
  entity: null as TypedLivestream,
  entities: null as TypedLivestream[],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false,
  idAudioDeviceSelectedForLivestream: null as string,
  idVideoDeviceSelectedForLivestream: null as string,
  isAlreadyPlayLiveFromFile: false as boolean,
  audioSourceList: null as any[],
  audioSourceError: null as ErrorMediaType
}

const apiUrl = 'livestream'

export const updateEntity = createAsyncThunk(
  'livestream/update_entity',
  async (data: TypedCreateLivestream) => {
    return await axios.patch<TypedGift>(`${apiUrl}/update`, data)
  },
  { serializeError: serializeAxiosError }
)

export const detailLiveStream = createAsyncThunk(
  'livestream/getById',
  async ({ id }: { id: string }) => {
    const res = await liveStreamApi.getById(id)
    return res?.data
  }
)

export const LIVESTREAM_REDUCER = createEntitySlice({
  name: 'livestream',
  initialState: initialState as any,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => {
      return { ...state, ...initialState }
    },
    clearEntity: state => {
      state.entity = null
    },
    setEntity: (state, action) => {
      state.loading = false
      state.entity = action.payload.data.entity
      state.isAlreadyPlayLiveFromFile =
        action.payload.data.isAlreadyPlayLiveFromFile || state.isAlreadyPlayLiveFromFile
      state.errorMessage = null
    },
    setIdAudioDeviceSelectedForLivestream: (state, action) => {
      state.idAudioDeviceSelectedForLivestream = action.payload
    },
    setIdVideoDeviceSelectedForLivestream: (state, action) => {
      state.idVideoDeviceSelectedForLivestream = action.payload
    },
    setIsAlreadyPlayLiveFromFile: (state, action) => {
      state.isAlreadyPlayLiveFromFile = action.payload
    },
    setAudioSourceList: (state, action) => {
      state.audioSourceList = action.payload
    },
    setAudioSourceError: (state, action) => {
      state.audioSourceError = action.payload
    }
  },

  extraReducers(builder) {
    builder
      .addCase(detailLiveStream.fulfilled, (state, action) => {
        state.entity = action.payload
        state.isAlreadyPlayLiveFromFile = false
      })
      .addMatcher(isPending(updateEntity), (state, action) => {
        state.loading = true
      })
      .addMatcher(isRejected(updateEntity), (state, action) => {
        state.loading = false
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(updateEntity), (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
  }
})
export const {
  clearError,
  setIsAlreadyPlayLiveFromFile,
  setIdAudioDeviceSelectedForLivestream,
  setIdVideoDeviceSelectedForLivestream,
  setEntity,
  clearEntity,
  reset,
  setAudioSourceList,
  setAudioSourceError
} = LIVESTREAM_REDUCER.actions

export const getDetailLiveStream = (state: IRootState): TypedLivestream => state.livestream?.entity

// Reducer
export default LIVESTREAM_REDUCER.reducer
