import { TypographyOptions } from '@mui/material/styles/createTypography'
import { fontFamily } from './font-family'

export default {
  fontSize: 16,
  fontFamily: fontFamily,
  h1: {
    fontSize: '4rem',
    lineHeight: 1.25,
    fontWeight: 500
  },
  h2: {
    fontSize: '3.5rem',
    lineHeight: 1.25,
    fontWeight: 500
  },
  h3: {
    fontSize: '3rem',
    lineHeight: 1.25,
    fontWeight: 500
  },
  h4: {
    fontSize: '2.5rem',
    lineHeight: 1.25,
    fontWeight: 500
  },
  h5: {
    fontSize: '2rem',
    lineHeight: 1.25,
    fontWeight: 500
  },
  h6: {
    fontSize: '1.5rem',
    lineHeight: 1.25,
    fontWeight: 500
  },

  subtitle1: {
    fontSize: '1.25rem',
    lineHeight: 1.25,
    fontWeight: 500
  },
  subtitle2: {
    fontSize: '1.125rem',
    lineHeight: 1.25,
    fontWeight: 500
  },
  body1: {
    fontSize: '1rem',
    lineHeight: 1.33,
    fontWeight: 400
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: 1.33,
    fontWeight: 400
  },
  caption: {
    fontSize: '0.75rem',
    lineHeight: 1.33,
    fontWeight: 400
  }
} as TypographyOptions
