import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import {
  Autocomplete,
  Box, Button,
  Divider,
  FormControl,
  IconButton,
  MenuItem, Modal,
  OutlinedInput,
  Select,
  Stack, styled, TextField,
  Typography
} from '@mui/material'
import __ from 'languages/index'
import { CloseIcon } from '../../../icons'
import UploadFileComponent from 'entities/shop/components/shop.upload'
import { bannerTarget } from 'entities/shop/data'
import TextFieldMUI from 'components/TextFieldMUI'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { TypedBanner } from 'entities/shop/interface'
import { getProductCategories } from 'entities/shop/service/product-category.service'
import { getProducts } from 'entities/shop/service/product.service'
import { getProductCollection } from 'entities/shop/service/product-collection.service'

interface  TypeBannerModal{
  createOrUpdateBanner:string,
  handleCloseShowModal: ()=>void,
  handleCreateBanner: (data: object)=>void,
  handleUpdateBanner: (data: object)=>void,
  editBannerData: TypedBanner,
  fields: any,
  onSubmit: ()=> void,
  disabled: boolean
}

const CreateEditBannerModal = ({createOrUpdateBanner, handleCloseShowModal,editBannerData, fields, onSubmit , disabled} : TypeBannerModal)=>{
  const [bannerType, setBannerType] = useState( bannerTarget[0])
  const [bannerUrl, setBannerUrl] = useState<any>(null)
  const [dataRequest, setDataRequest] = useState<any>([])
  const [inputBannerUrl, setInputBannerUrl] = useState<string>('')
  const [dataDefaul, setDataDefaul] = useState<string>('')
  const [disable, setDisable] = useState<boolean>(false)


  const handleClickMenu = useCallback((option)=>{
    setBannerType(option)
    setBannerUrl(null)
  },[])
  useEffect(() => {
    const data = bannerTarget.find((item) => item?.value === editBannerData?.banner_title )

    if(createOrUpdateBanner === 'EDIT' && !!data){
      setBannerType({ ...data })
      setBannerUrl(editBannerData?.banner_meta)
    }

  }, [editBannerData, createOrUpdateBanner])
  useEffect(() => {
    if(!createOrUpdateBanner){
      setBannerType(bannerTarget[0])
      setBannerUrl(null)
    }
    if(createOrUpdateBanner === 'EDIT'){
      const bannerMeta = editBannerData?.banner_meta


      if(!! bannerMeta?.product && dataRequest?.length){
        const  product = dataRequest?.find((item)=> item?.product_id === bannerMeta?.product)
        setDataDefaul(product?.name || '')
      }
      if(!! bannerMeta?.category && dataRequest?.length){

        const  category = dataRequest?.find((item)=> item?.category_id === bannerMeta?.category)
        setDataDefaul(category?.name || '')

      }
    }

  }, [dataRequest,createOrUpdateBanner])




  useEffect(() => {

    fields.banner_title.onChange(bannerType?.value);
    if(!!bannerUrl){
      // if(bannerUrl?.collection_id){
      //   fields.banner_meta.onChange({
      //     collection_id: bannerUrl?.collection_id
      //   })
      // }
      if(bannerUrl?.category_id){
        fields.banner_meta.onChange({
          category: bannerUrl?.category_id
        })
      }
      if(bannerUrl?.product_id){
        fields.banner_meta.onChange({
          product: bannerUrl?.product_id
        })
      }
      if(bannerUrl?.link){
        fields.banner_meta.onChange({
          link: bannerUrl?.link
        })
      }

    }

  }, [ bannerType, bannerUrl]);

  useEffect(() => {
    const isDisable =
      disabled || !fields.banner_image_url.value || !fields.banner_meta.value || !fields.banner_title.value ||
      (createOrUpdateBanner === 'EDIT' && fields.banner_image_url.value === editBannerData?.banner_image_url &&
      fields.banner_title.value === editBannerData?.banner_title &&
      fields.banner_meta.value == editBannerData.banner_meta)
    setDisable(isDisable)
  }, [editBannerData, fields, disabled])



  const getDataBanner = useCallback(async ()=>{

    if(bannerType?.name === 'category'){
      try{
        setDataRequest([])
        await getProductCategories()
          .then((res)=>{
            const categories = res?.data
            if(!!categories){
              categories?.map(category => {
                setDataRequest((prevCategory)=> [
                  ...prevCategory,
                  {
                    category_id: category?.category_id,
                    name: category?.category_name
                  }])
              })
            }
          })
          .catch((e)=>{
            console.log('getProductCategories', e)
          })

      }catch (err){
        console.log('getProductCategories', err)
      }
    }
    if(bannerType?.name === 'collection'){
      try{
        setDataRequest([])
        await getProductCollection({
          // channel_id: channelId,
          order_by: 'DESC',
          limit: 50,
        })
          .then((res)=>{
            const collections = res?.data
            if (!! collections){
              collections?.map(item => {
                setDataRequest((prevCollection)=> [
                  ...prevCollection,
                  {
                    collection_id: item?.collection_id,
                    name: item?.collection_name
                  }
                ])
              })
            }
          })
          .catch((e)=>{
            console.log('getProductCollection', e)
          })

      }catch (err){
        console.log('getProductCollection', err)
      }

    }
    if(bannerType?.name === 'product'){
      try{
        setDataRequest([])
        await getProducts({
          limit: 50,
          order_by: 'DESC',
        })
          .then((res)=>{
            const products = res?.data
            if (!! products){
              products?.map(item => {
                setDataRequest((prev)=> [
                  ...prev,
                  {
                    name: item?.product_name,
                    product_id: item?.product_id
                  }

                ])
              })
            }
          })
          .catch((err)=>{
            console.log('getProducts', err)
          })

      }catch (err){
        console.log('getProducts', err)
      }
    }
    if(bannerType?.name === 'link'){
      setDataRequest([])
    }

  },[bannerType])

  useEffect(()=>{
    getDataBanner()

  },[bannerType])
  const handleChangeSelect = (event) => {
    const {
      target: { value, name },
    } = event;

    // setBannerType({
    //   value: value,
    //   name: name
    // })
  }
  // const handleInputChange = (event) => {
  //   const inputValue = event.target.value;
  //   if(inputValue){
  //     setParam({ search: event.target.value });
  //   }
  // };

  return(
    <BannerModalContainer open={Boolean(createOrUpdateBanner)} onClose={handleCloseShowModal}>
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(115.125),
          margin: '0 auto'
        }}
      >
        <Box className="header">
          <ModalHeader direction={'row'}>
            <Typography className="title">
              {createOrUpdateBanner === 'ADD' ? __('add_banners') : __('edit_banner')}
            </Typography>
            <IconButton onClick={handleCloseShowModal}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider sx={{ opacity: 1 }} />
        </Box>
        <ModalBody className="body">
          <form onSubmit={onSubmit}>
            <Box
              sx={{
                padding: theme => theme.spacing(2, 0),
                display: 'grid',
                rowGap: theme => theme.spacing(3)
              }}
            >
              <Box
                sx={{
                  borderRadius: theme => theme.spacing(3),
                  position: 'relative',
                  paddingBottom: '44.44%',
                  border: theme =>
                    `1px solid ${
                      fields.banner_image_url.error
                        ? theme.palette.error.main
                        : theme.palette.divider
                    }`
                }}
              >
                <Box sx={{ position: 'absolute', width: '100%', height: '100%' }}>
                  <UploadFileComponent
                    mediaUrl={fields.banner_image_url.value || ''}
                    removeMediaUrl={() => {
                      fields.banner_image_url.onChange('')
                    }}
                    setMediaUrl={data => {
                      fields.banner_image_url.onChange(data)
                    }}
                    title={__('upload_banner')}
                  />
                  {fields.banner_image_url?.error && (
                    <Typography
                      component={'span'}
                      variant="body2"
                      color={'error.main'}
                      fontWeight={400}
                    >
                      {fields.banner_image_url?.error?.toString()}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="start"
                display={'grid'}
                gridTemplateColumns={'1fr 1fr'}
                sx={{ marginBottom: theme => theme.spacing(2.5) }}
                rowGap={theme => theme.spacing(1.5)}
              >
                <Box >
                  <FormControl
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Select
                      onBlur={() => {
                        fields.banner_title?.runValidation(fields.banner_title.value)
                      }}
                      MenuProps={{
                        elevation:1,
                        sx:{
                          maxHeight: theme => theme.spacing(37.5),
                        }

                      }}
                      displayEmpty
                      required
                      value={bannerType?.value}
                      defaultValue={bannerType?.value || ''}
                      onChange={handleChangeSelect}
                      input={<OutlinedInput  />}
                      sx={{
                        padding: theme => theme.spacing(0,2),
                        backgroundColor: theme => theme.palette.background.default,
                        borderRadius:  theme => theme.spacing(1.5),
                        width: '100%',
                        height: theme => theme.spacing(7)

                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {bannerTarget?.map((option) => (
                        <MenuItem
                          key={option?.name}
                          value={option?.value}
                          onClick={()=> handleClickMenu(option)}

                        >
                          {option?.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {fields.banner_title?.error && (
                      <Typography sx={{paddingTop: theme => theme.spacing(0.25)}} variant="body2"  color="error.main">{fields.banner_title?.error}</Typography>
                    )}
                  </FormControl>
                </Box>
                <Box >
                  {
                    !!dataRequest.length ? (
                      <>
                        <Autocomplete
                          id="listBanner"
                          disablePortal
                          defaultValue={bannerUrl || ''}
                          value={bannerUrl}
                          inputValue={inputBannerUrl}
                          options={dataRequest}
                          getOptionLabel={option => option.name ?? dataDefaul}
                          onChange={(event: any, newValue: any) => {
                            setBannerUrl(newValue)
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputBannerUrl(newInputValue)
                          }}
                          onBlur={() => {
                            fields.banner_meta?.runValidation(fields.banner_meta.value)
                          }}

                          renderInput={(bannerUrl) =>{
                            return(
                              <TextField
                                InputProps={{ ...bannerUrl.InputProps, type: 'search' }}
                                required
                                {...bannerUrl}
                                variant={'outlined'}
                                placeholder={__('destination_link')}
                                value={bannerUrl.inputProps.value}
                                // onChange={handleInputChange}
                              />
                            )
                          }}

                          sx={{ maxHeight: theme => theme.spacing(37.5) }}
                        />
                        {fields.banner_meta?.error && (
                          <Typography sx={{paddingTop: theme => theme.spacing(0.25)}} variant="body2" color="error.main">{fields.banner_meta?.error}</Typography>
                        )}
                      </>
                    ) :(
                      <TextFieldMUI
                        {...fields?.banner_meta}
                        // onChange={(value: string) => {
                        //   fields?.banner_meta?.value.link.onChange(value)
                        // }}
                        onChange={(value)=>(setBannerUrl({
                          link: value
                        }))}
                        value={fields.banner_meta.value.link}
                        type="url"
                        maxLength={255}
                        isShowMessage={true}
                        placeholder={__('destination_link')}
                      />
                    )
                  }
                </Box>

                {/*<Typography fontWeight={500} color={'text.secondary'}>*/}
                {/*  {__('upload_banner_des')}*/}
                {/*</Typography>*/}
              </Stack>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button type="submit" disabled={disable}>
                  <Typography textTransform={'none'}>{__('save_banners')}</Typography>
                </Button>
              </Box>
            </Box>
          </form>
        </ModalBody>
      </ModalContainer>
    </BannerModalContainer>
  )
}

export default CreateEditBannerModal
const BannerModalContainer = styled(Modal)(({ theme }) => ({
  '& .header': {
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    background: theme.palette.background.paper,
    padding: 0
  },
  '& .title': {
    textTransform: 'none',
    variant: 'h6',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center'
  },
  '& .body': {
    padding: theme.spacing(0, 3),
    maxHeight: `calc(100% - ${theme.spacing(10)})`,
    overflowY: 'auto'
  },
  '& .button_wrapper': {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 2),
    border: `1px dashed ${theme.palette.primary.main}`,
    '& .upload_wrapper': {
      display: 'grid',
      justifyItems: 'center',
      rowGap: theme.spacing(1)
    }
  },
  '& .input_search': {
    height: theme.spacing(5.75),
    padding: theme.spacing(1, 2),
    display: 'flex',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
  }
}))

