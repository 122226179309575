//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//helpers
import helpers from 'helpers'

export const initialState = {}


/**
 * @deprecated remove in a near future ...
 */
export const getListSystem = createAsyncThunk(
  'auth/get_list_system',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/config/list/system`)
      return response?.data?.config?.option_content.filter(
        (e: any) => e.key === 'is_enable_validate_phone'
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const userLoggedOut = createAsyncThunk('user/logout',async () => {
  return await axios.get<any>(`user/logout`);
})


export const loginWithPassword = createAsyncThunk('user/login_with_password',async ( entities: any ) => {
  return await axios.post<any>(`user/login/password`, entities);
})

export const loginWithFacebook = createAsyncThunk('user/login_with_facebook',async ( body: any, {rejectWithValue} ) => {
    try {
      return await axios.post<any>(`/user/login/facebook`, body);
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const loginWithGoogle = createAsyncThunk('user/login_with_google',async ( body: any, {rejectWithValue} ) => {
    try {
      return await axios.post<any>(`/user/login/google`, body);
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const registerAccount = createAsyncThunk(
  'auth/register_account',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/user/login/sign-up`, body)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const AUTH_REDUCER = createEntitySlice({
  name: 'auth',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = AUTH_REDUCER.actions

export default AUTH_REDUCER.reducer
