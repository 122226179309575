import { styled, TextareaAutosize } from "@mui/material";
import __ from "languages/index";

const TextareaAutoSize = (props) => {
    const {reset,placeholder = 'challenge_activity_note', ...rest} = props
    return (
        <Textarea
            minRows={3}
            placeholder={__(placeholder)}
            {...rest}
        />
    )
}

export default TextareaAutoSize

const Textarea = styled(TextareaAutosize)(
    ({ theme }) => ({
        boxSizing: 'border-box',
        width: '100%',
        minHeight: theme.spacing(3),
        background: theme.palette.background.default,
        border: 'none',
        padding: theme.spacing(2, 2.5),
        borderRadius: `${theme.spacing(1.5)} !important`,
        color: 'currentcolor',
        caretColor: theme.palette.primary.main,

        '&:focus-visible': {
            outline: 0
        },
    }));