import { Box, Grid, styled, Typography, Stack, Button, Select, MenuItem, useTheme, OutlinedInput, IconButton, List, Pagination, PaginationItem } from "@mui/material"
import { IQueryParams } from "config/reducer.utils"
import __helpers from "helpers/index"
import { ChevronDownIcon } from "icons"
import BestSaleIcon from "icons/BestSaleIcon"
import __, { ___ } from "languages/index"
import BigLayout from "layouts/bigLayout"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import _Helmet from '../../components/helmet'
import ShopHeader from "./components/shop.header"
import { sortItems } from "./data"
import { getStyles } from "./helper"
import { omit } from 'lodash'
import { useAppDispatch } from "config/store"
import { getProductOfShop } from "./store"
import { AxiosError, AxiosResponse } from "axios"
import { TypedProduct } from "./interface"
import CardItem from "./components/shop.cardItem"
import BoxFilterProducts from "./components/BoxFilterProducts"
import ComponentEmpty from "components/componentEmpty"
const ShopSearch = () => {
  const [open, setOpen] = useState<boolean>(false)
    const rootSearchPath = window.location.search
    let StringQuery: any = __helpers.ExtractUrl(rootSearchPath) || {}
    const initialQuery = {
        order_by: "DESC",
        page: 1,
        limit: 12,
    };
    const [queryParams, setQueryParams] = useState<IQueryParams>({
        sort: "",
        // product_status: 1,
        ...initialQuery,
        ...StringQuery
    })

    const theme = useTheme()
    const dispatch = useAppDispatch();
    const [products, setProducts] = useState<TypedProduct[]>([]);
    const [totalProduct, setTotalProduct] = useState(0);
    const [sortPrice, setSortPrice] = useState("")
    useEffect(() => {
        getProducts(queryParams);
        if (queryParams.sort) {
            let getValueSortPrice = queryParams.sort.split(',').filter(item => item !== 'product_meta.product_review_count:desc' && item.length);
            setSortPrice(getValueSortPrice[0] || "")
        }
    }, [])
    const handleFilterByField = (type, value) => {
        const newVal =
            type === 'createdAt' ? moment(value).format('YYYY-MM-DD') : value;
        const newValues = value === 0 || value
            ? {
                ...queryParams,
                [type]: newVal
            }
            : omit(queryParams, [type]);
        setQueryParams(newValues);
        getProducts(newValues)

        let buildURLSearch = __helpers.buildEndUrl(newValues);
        if (window.location.search !== buildURLSearch) {
            window.history.replaceState(null, "Shop", `/shop/search${buildURLSearch}`);
        }
    };

    const getProducts = (queryParams: IQueryParams) => {
        dispatch(getProductOfShop({ ...queryParams }))
            .unwrap()
            .then((res: AxiosResponse) => {
                setProducts([...res?.data])
                setTotalProduct(res.headers['x-total-count'])
            })
            .catch((error: AxiosError) => {
                console.log(`getProductOfShop error >>>`, error)
            })
    }

    const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        handleFilterByField("page", value)
    }, [queryParams])


    return (
        <BigLayout>
            <_Helmet title={__('shop')} />

            <ShopSearchContainer>
                <ShopHeader handleFilterByField={handleFilterByField} sort={queryParams.query} titleHeader={__('shop')} />
                <Grid container spacing={2}>
                    <Grid className="left" item xs={9}>
                        <Stack alignItems={"center"} justifyContent="space-between" flexDirection={"row"} className="header_left">

                            <Typography textTransform={'none'} color="text.secondary" variant="h6" className="search_for">
                                {queryParams?.query && ___('search_result {text}', {
                                    text: <Typography textTransform={'none'} color="primary.main" variant="h5" className="search_text">{queryParams.query}</Typography>
                                })}
                            </Typography>

                            <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems="center" className="btn_filter">
                                <Button variant="outlined" sx={{
                                    background: theme => queryParams?.sort && queryParams?.sort.includes('product_meta.product_review_count:desc') ? theme.palette.primary.main : theme.palette.primary.contrastText,
                                    margin: theme.spacing(0, 1),
                                    borderColor: 'rgba(0, 0, 0, 0.23)'
                                }}
                                    startIcon={<BestSaleIcon />}
                                    onClick={() => {
                                        let query = queryParams?.sort ? queryParams.sort.split(',') : [];
                                        if (query.includes('product_meta.product_review_count:desc')) {
                                            query = query.filter(item => item !== 'product_meta.product_review_count:desc')
                                        } else {
                                            query.push('product_meta.product_review_count:desc')
                                        }
                                        handleFilterByField('sort', query.join(','));
                                    }}
                                >
                                    <Typography textTransform={'none'} color="black" fontWeight={600}>
                                        {__('best_sale')}
                                    </Typography>
                                </Button>

                                <Select
                                    size="small"
                                    displayEmpty
                                    variant="filled"
                                    className='select_sort'
                                    value={sortPrice}
                                    MenuProps={{ elevation:1}}
                                    open={open}
                                    onMouseDownCapture={(e) => {
                                      e.preventDefault()
                                      setOpen(!open);
                                    }}
                                    onChange={(e) => {
                                        let query = queryParams?.sort ? queryParams.sort.split(',') : [];
                                        if (query.includes('product_meta.product_review_count:desc')) {
                                            query = [
                                                'product_meta.product_review_count:desc',
                                                e.target.value
                                            ]
                                        } else {
                                            query = [
                                                e.target.value
                                            ]
                                        }
                                        setSortPrice(e.target.value)
                                        handleFilterByField('sort', query.filter(item => item.length).join(','));
                                    }}
                                    IconComponent={() => (
                                        <IconButton>
                                            <ChevronDownIcon
                                              sx={{
                                                width: '100%',
                                                height: '100%' ,
                                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                                            }}
                                            />
                                          {' '}
                                        </IconButton>
                                    )}

                                    input={<OutlinedInput sx={{width: 'unset',}} />}
                                    sx={{
                                        padding: theme => theme.spacing(0, 2),
                                        borderRadius: theme => theme.spacing(1.5),
                                        width: 'unset',
                                    }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="">{__('sort_by_cost')}</MenuItem>
                                    {sortItems?.map((item, index) => (
                                        <MenuItem
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item?.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </Stack>

                        <List
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
                                gap: theme => theme.spacing(3)
                            }}
                        >
                            {
                                products.length ? products.map((data) => (
                                    <CardItem key={data.product_id} productData={data} />

                                )) : null
                            }
                        </List>
                        {!products.length ? (
                            <Stack alignItems={"center"}>
                                <ComponentEmpty />
                            </Stack>
                        ) : (
                            <Stack flexDirection={'row'} justifyContent="center" mt={2} className="pagination" >
                                <Pagination
                                    color="primary"
                                    variant="outlined"
                                    shape="rounded"
                                    page={+queryParams.page}
                                    count={Math.ceil(totalProduct / queryParams.limit) || 1}
                                    renderItem={(otherProps) => (
                                        <PaginationItem
                                            sx={{ borderRadius: theme => theme.spacing(1.5) }}
                                            {...(otherProps as any)}
                                            disableRipple
                                        />
                                    )}
                                    onChange={handleChangePage}
                                />
                            </Stack>
                        )}

                    </Grid>
                    <Grid className="right" item xs={3}>
                        <BoxFilterProducts queryParams={queryParams} handleFilterByField={handleFilterByField} />

                    </Grid>
                </Grid>
            </ShopSearchContainer>
        </BigLayout>
    )
}

export default ShopSearch


const ShopSearchContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    rowGap: theme.spacing(3),

    '& .left': {
        '& .header_left': {
            '& .search_for': {
                display: 'flex',
                alignItems: 'center',
                width: '50%',

                '& .search_text': {
                    whiteSpace: 'nowrap',
                    maxWidth: '65%',
                    textOverflow: "ellipsis",
                    overflow: 'hidden',
                    display: 'block'
                }
            }
        }
    }
}))
