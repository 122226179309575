import React, {useMemo} from 'react'
import OptionsPopover, {OptionType} from 'entities/homeNew/components/OptionsPopover'
import {EnumPostStatus, TypedRequest} from '../../../interfaces/request.interface'
import {mergeArrayObjectsByKey} from 'helpers/mergeArrayObjectsByKey'
import {POST_OPTIONS_CONFIGS} from 'constant/post'
import RenderView from 'components/RenderView'
import __ from 'languages/index'
import {useAppSelector} from "config/store";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import helpers from "helpers/index";

export type CustomPopoverOptionType = {
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  isInReview?: boolean
}

export type TypedPostOptionsProps = {
  post: TypedRequest
  options?: any
  handleOpenEditModal: ()=>void
  handleShowConfirmDeleteModal: ()=>void
  onUpdatePost: (data: any)=>Promise<void>
} & CustomPopoverOptionType
const PostOptions = ({post, anchorEl, isInReview, onClose, handleOpenEditModal, handleShowConfirmDeleteModal, onUpdatePost}: TypedPostOptionsProps) => {

  const toast = useToast()
  const userId = useAppSelector(state => state.user.user_data?._id);
  const permission_data = useAppSelector(state => state.user.permission_data)
  const need_approval = useAppSelector(state => state.user.channel_data?.need_approval)
  const isBoss = useMemo(()=>helpers.current_user_can("boss", permission_data),[permission_data])

  const postOptions = (post?: TypedRequest, actionCallback?: () => void) => {
    return [
      {
        key: 'copy',
        show: !isInReview,
        action: () => navigator.clipboard.writeText(window.location.origin+`/post/detail/${post?._id}`)
          .then(() => {
            toast.show({
              type: EnumTypeToast.Success,
              content: `${__("livestream_outside_message_copy_success")}`
            })
          })
          .catch((error) => {
            toast.show({
              type: EnumTypeToast.Error,
              content: `${__("livestream_outside_message_copy_error")}`
            })
          })
      },
      {
        key: 'pin',
        show: !isInReview && post?.is_pin != 1 && isBoss,
        action: () => onUpdatePost({is_pin: '1'})
      },
      {
        key: 'unpin',
        show: !isInReview && post?.is_pin == 1 && isBoss,
        action: () => onUpdatePost({is_pin: '0'})
      },
      {
        key: 'edit',
        show: post?.user_id?._id === userId && (!need_approval || isBoss || post?.post_status === EnumPostStatus.Pending) && !post?.data_json_type,
        action: handleOpenEditModal
      },
      {
        key: 'hide-comment',
        show: !isInReview && post?.is_comment == 1 && (post?.user_id?._id === userId || isBoss),
        action: () => onUpdatePost({is_comment: '0'})
      },
      {
        key: 'show-comment',
        show: !isInReview && post?.is_comment != 1 && (post?.user_id?._id === userId || isBoss),
        action: () => onUpdatePost({is_comment: '1'})
      },
      {
        key: 'delete',
        show: (post?.user_id?._id === userId || isBoss) && !post?.data_json_type,
        action: handleShowConfirmDeleteModal
      }
    ]
  }

  return (
    <>
      <RenderView
        view={
          <OptionsPopover
            id={'post-options' + post?._id}
            elevation={6}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            options={
              [
                ...mergeArrayObjectsByKey(POST_OPTIONS_CONFIGS.map(item => ({
                  ...item,
                  icon: <item.icon/>
                })), [...postOptions(post)], 'key')
              ] as OptionType[]
            }
          />
        }
      />
    </>
  )
}

export default PostOptions
