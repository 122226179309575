import { z } from 'zod'
import { invalid } from 'entities/support/utils'
import __ from 'languages/index'

export const AddRootDomainSchema = z.object({
  rootDomain: z
    .string()
    .min(1, invalid('required', 'Domain'))
    .refine(value => /^[a-zA-Z0-9_-]+$/.test(value), {
      message: `${__('domain_validate')}`
    })
    .transform(value => value.toLowerCase())
})

export type AddRootDomainPayload = z.infer<typeof AddRootDomainSchema>
