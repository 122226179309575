import { Theme } from '@mui/material'
import helpers from 'helpers'
import { TypedProduct, TypedProductStock } from '../interface'
import city_in_vietnam from '../data/city_in_vietnam.json'
import district_in_vietnam from '../data/district_in_vietnam.json'
import ward_in_vietnam from '../data/ward_in_vietnam.json'

export const getStyles = (name: string, selectName: string, theme: Theme) => {
  return {
    fontWeight:
      selectName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  }
}

export const convertData = (data: any) => {
  const dataArray: Array<any> = []
  if (data !== undefined && data !== null) {
    Object.keys(data).forEach(key => {
      dataArray.push(data[key])
    })
    return dataArray
  }
}

export const productSoldQuantity = (
  productData: TypedProduct
): { productSold: number; totalProduct: number } => {
  let totalProductSold = 0
  let totalProduct = 0
  if (helpers.isEmpty(productData?.product_to_stock))
    return { productSold: totalProductSold, totalProduct: totalProduct }
  productData?.product_to_stock?.forEach((data: TypedProductStock) => {
    totalProduct += data?.product_quantity
    totalProductSold += data?.product_sold_quantity
  })
  return { productSold: totalProductSold, totalProduct: totalProduct }
}

export const haversine = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Bán kính Trái Đất, km

  const dLat = toRadians(lat2 - lat1)
  const dLon = toRadians(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

function toRadians(degrees) {
  return degrees * (Math.PI / 180)
}

export const uniqueStockId = list => {
  const stockMap = new Map()

  list.forEach(item => {
    const productToStockList = item.product.product_to_stock.filter(i => i?.product_quantity > 0)

    productToStockList.forEach(productToStock => {
      const stockId = productToStock.stock.stock_id

      if (!stockMap.has(stockId)) {
        stockMap.set(stockId, {
          stock: productToStock.stock,
          products: [item]
        })
      } else {
        const existingStock = stockMap.get(stockId)
        const existingProductIds = existingStock.products.map(product => product.product.product_id)

        const isNewProduct = !existingProductIds.includes(item.product.product_id)

        if (isNewProduct) {
          existingStock.products.push(item)
        }
      }
    })
  })

  const result = Array.from(stockMap.values())

  return result
}

export const sortByDistance = (lat, long, stockList) => {
  const sortedData = [...stockList]

  sortedData.sort((a, b) => {
    if (b.products.length !== a.products.length) {
      return b.products.length - a.products.length
    } else {
      const latA = parseFloat(a?.stock.stock_address_city_latitude)
      const longA = parseFloat(a?.stock.stock_address_city_longitude)

      const latB = parseFloat(b?.stock.stock_address_city_latitude)
      const longB = parseFloat(b?.stock.stock_address_city_longitude)

      return haversine(lat, long, latA, longA) - haversine(lat, long, latB, longB)
    }
  })

  const uniqueProducts = new Set()
  sortedData.forEach(item => {
    item.products = item.products.filter(product => {
      if (!uniqueProducts.has(product.product.product_id)) {
        uniqueProducts.add(product.product.product_id)
        return true
      }
      return false
    })
  })

  const filteredData = sortedData.filter(item => item.products.length > 0)

  return filteredData
}


export function getLocationInformationViaCode (code: string | null | undefined): {
  "name": string,
  "type": string,
  "slug": string,
  "name_with_type": string,
  "path": string,
  "code": string,
  "path_with_type"?: string,
  "parent_code"?: string
  "latitude"?: string,
  "longitude"?: string
} | null {
  if (!code) return null;

  if (String(code).length === 2) {
    if (typeof city_in_vietnam[code] !== 'undefined') return city_in_vietnam[code];
    else return null;
  }

  if (String(code).length === 3) {
    if (typeof district_in_vietnam[code] !== 'undefined') return district_in_vietnam[code];
    else return null;
  }

  if (typeof ward_in_vietnam[code] !== 'undefined') return ward_in_vietnam[code];
  else return null;
}