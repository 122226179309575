//Library
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  linearProgressClasses,
  styled
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import dateandtime from 'date-and-time'
import { Link } from 'react-router-dom'

//Config
import { useAppSelector } from 'config/store'

//Icons
import { MissionIcon } from 'icons'

//Helpers
import helpers from 'helpers'
import __ from 'languages/index'
import { optionMissionData } from 'entities/mission/data'
import { socket } from 'config/socket.io'

const MissionViewComponent = () => {
  const dataJoinPermission = useAppSelector(state => state.mission.dataJoinPermission)

  const [firstMissionData, setFirstMissionData] = useState(null)

  /**
   *
   * Logic from layout/header.tsx
   */
  const getDailyMissionData = useCallback(mission => {
    const mission_action = mission?.point_data || []
    return mission_action.filter(element => {
      const currentPoint =
        mission.point_data.find(i => i.action_name === element.action_name)?.point_number || 0
      return Number(element?.action_point) > Number(currentPoint)
    })
  }, [])

  const filterMissionFormDay = useCallback(response => {
    const filteredData = response.filter((item: any) => {
      const start_day = new Date(item?.createdAt)
      const day = Number(item?.redeem_mission_id?.number_of_day || '0') - 1
      const todayDate = new Date()
      const currentDate = new Date(start_day?.setDate(start_day?.getDate() + Number(day)))
      return dateandtime.isSameDay(currentDate, todayDate)
    })
    setFirstMissionData(filteredData[0])
  }, [])

  const handleCalculator = useCallback(missionData => {
    let valueCompletedMission = 0
    let valueTotalMission = 0
    missionData?.length > 0 &&
      missionData?.forEach(item => {
        valueCompletedMission = valueCompletedMission + Number(item?.point_number)
      })
    missionData?.length > 0 &&
      missionData?.forEach(
        item => (valueTotalMission = valueTotalMission + Number(item?.action_point))
      )
    return {
      completedMission: valueCompletedMission,
      totalMission: valueTotalMission,
      percentCompletedMission: (valueCompletedMission / valueTotalMission) * 100
    }
  }, [])

  useEffect(() => {
    try {
      socket.on('redeemToClient', data => {
        if (!helpers.isJson(data)) return
        let dataParse = JSON.parse(data)
        if (firstMissionData?._id === dataParse._id) {
          setFirstMissionData(dataParse)
        }
      })
    } catch (error) {
      console.log(error, 'redeemError')
    }
  }, [socket, firstMissionData])

  useEffect(() => {
    if (helpers.isEmpty(dataJoinPermission)) return
    filterMissionFormDay(dataJoinPermission)
  }, [dataJoinPermission])

  return (
    <>
      {helpers.isEmpty(getDailyMissionData(firstMissionData)) ? (
        <></>
      ) : (
        <MissionViewContainer id="missionViewComponent">
          <Stack gap={2}>
            <Box
              display="grid"
              gridTemplateColumns={'auto 1fr'}
              columnGap={theme => theme.spacing(2)}
              alignItems={'center'}
            >
              <MissionIcon
                sx={{
                  width: theme => theme.spacing(6.5),
                  height: theme => theme.spacing(6.5)
                }}
              />
              <Box
                display="flex"
                height={'100%'}
                paddingRight={theme => theme.spacing(2)}
                flexDirection={'column'}
                alignItems={'space-between'}
              >
                <Typography
                  component={'p'}
                  variant="subtitle1"
                  color="text.primary"
                  fontSize={18}
                  lineHeight={'25px'}
                  fontWeight={600}
                >
                  {__('header_today_mission')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={handleCalculator(firstMissionData?.point_data).percentCompletedMission}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                      {`${handleCalculator(firstMissionData?.point_data).completedMission}/${
                        handleCalculator(firstMissionData?.point_data).totalMission
                      }`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="mission_wrapper">
              {helpers.isEmpty(firstMissionData?.point_data) ? (
                <></>
              ) : (
                <>
                  {getDailyMissionData(firstMissionData)?.map((value, idx: number) => {
                    const labelMission = optionMissionData.find(
                      item => item?.key === value?.action_name
                    ) || { label: '', mission: '' }
                    if (idx > 1) return

                    return (
                      <Box
                        key={`mission_${idx}`}
                        display="flex"
                        height={'100%'}
                        flexDirection={'column'}
                        alignItems={'space-between'}
                      >
                        <Typography
                          variant="body1"
                          color="text.primary"
                          fontSize={16}
                          width={'100%'}
                          lineHeight={theme => theme.spacing(2.5)}
                          fontWeight={600}
                        >
                          {__(`${labelMission.label} ${labelMission.mission}`)}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <MissionItemProgress
                              variant="determinate"
                              value={handleCalculator([value]).percentCompletedMission}
                            />
                          </Box>
                          <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">
                              {`${handleCalculator([value]).completedMission}/${
                                handleCalculator([value]).totalMission
                              }`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
                </>
              )}
              <Link to="/missions" style={{ textDecoration: 'none' }}>
                <Button variant="text" className="see_all">
                  <Typography textTransform={'none'}>{__('header_see_all')}</Typography>
                </Button>
              </Link>
            </Box>
          </Stack>
        </MissionViewContainer>
      )}
    </>
  )
}

export default MissionViewComponent

const MissionViewContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& .mission_wrapper': {
    display: 'grid',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.divider}`,
    '& .see_all': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    }
  }
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(1),
  borderRadius: theme.spacing(1.5),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary.background
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main
  }
}))

const MissionItemProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(0.625),
  borderRadius: theme.spacing(1.5),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.background.default
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.text.disabled
  }
}))
