import axios from 'axios'
import helpers from 'helpers/index'
import __ from 'languages'

/**
 * Timeout for 5 second
 * default setting ...
 * @jamviet
 */

const TIMEOUT = Number(process.env.REACT_APP_API_REQUEST_TIMEOUT) || 10000
axios.defaults.timeout = TIMEOUT
axios.defaults.baseURL = process.env.REACT_APP_AJAX_URL
axios.defaults.headers['X-Authorization'] = localStorage.getItem('session')

if (helpers.isDev()) {
  console.log('DEV mode', '<< FOR DEV ONLY >>')
  axios.defaults.baseURL = process.env.REACT_APP_AJAX_URL
}

export default function setupAxiosInterceptors(
  onUnauthenticated: any,
  onRequestErrorMessage: Function,
  onLoadingCallback: (loading: boolean) => void
) {
  const onRequestSuccess = (config: any) => {
    onLoadingCallback(true)
    config.headers['X-Authorization'] = localStorage.getItem('session')
    config.params = config.params || {}
    config.params['cacheBuster'] = helpers.getRandomNumber(10)
    return config
  }

  const onResponseSuccess = (response: any) => {
    onLoadingCallback(false)
    return response
  }
  const onResponseError = (err: any) => {
    onLoadingCallback(false)
    const status = err.status || err?.response?.status || 0
    /***************
     * 401: User not (correctly) authenticated, the resource/page require authentication
     * 403: User's role or permissions does not allow to access requested resource, for instance user is not an administrator and requested page is for administrators.
     */
    if (status === 401) {
      onUnauthenticated(status)
    }
    /**
     * translate all error message ...
     */
    let err_need_translate = err?.response?.data?.message ?? `${__("internet_connection_error")}`
    err_need_translate = __(err_need_translate)

    onRequestErrorMessage(err_need_translate)

    /**!SECTION
     * Khi mất internet, err.response undefined
     */
    return Promise.reject(err_need_translate)
  }

  const onRequestError = (err: any) => {
    onLoadingCallback(false)
    console.log('===>request Error: ', err)
    return Promise.reject(err)
  }

  axios.interceptors.request.use(onRequestSuccess, onRequestError)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}
