import { createEntitySlice } from "config/reducer.utils";  
  

const initialState = {
    loading: false,
  };
  export const globalProcessBar = createEntitySlice({
    name: 'global_process_bar',
    initialState,
    reducers: {
      stopLoading: (state) => {
        state.loading = false;
      },
      setLoading: (state) => {
        state.loading = true;
      },
    },
    extraReducers: (builder) =>  {}
  });
  
  export const { stopLoading, setLoading, reset } = globalProcessBar.actions;

// Reducer
export default globalProcessBar.reducer;

