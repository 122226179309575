import React, { ReactNode } from 'react'
import ComponentEmpty from 'components/componentEmpty'
import { Box, CircularProgress } from '@mui/material'

type Props = {
  isLoading?: boolean
  isEmpty?: boolean
  view: React.ReactElement
  loader?: React.ReactElement
  emptyElementFallback?: React.ReactElement
  textEmpty?: string
}
const RenderView = ({ isLoading, isEmpty, view, loader, emptyElementFallback, textEmpty }: Props) => {
  if (isLoading)
    return loader ? (
      loader
    ) : (
      <Box display="flex" justifyContent={'center'}>
        <CircularProgress />
      </Box>
    )
  if (isEmpty) return emptyElementFallback ?? <ComponentEmpty text={textEmpty ?? ''} />
  return view
}

export default RenderView
