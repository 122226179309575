import React, { useState } from 'react'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import { Box, Button, Radio, Stack, Switch, SxProps, TextField, Typography } from '@mui/material'
import ColorSelector from 'components/ColorSelector'
import { GlobeIcon, LockIcon } from 'icons'
import { CurrentTheme } from '../../../styles/themes'
import { useAppDispatch, useAppSelector } from 'config/store'
import { channelInfo, updateChannel, updateChannelThunk } from '../store/channel.slice'
import TypedChannel from 'interfaces/channel.interface'
import { uploadFile } from 'entities/courses/services/courses.media.service'
import FileUploader from '../components/FileUploader'

enum ChannelStatus {
  PUBLIC = 'public',
  PRIVATE = 'private'
}

const ChannelSettingsInformation = () => {
  const dispatch = useAppDispatch()
  const channel = useAppSelector(channelInfo)
  const isPublicChannel = channel?.public_status === ChannelStatus.PUBLIC
  const [logo, setLogo] = useState<FileList | File[]>(null)

  const handleUpdateChannelInfo = (updatedChannel: Partial<TypedChannel>) => {
    dispatch(updateChannel(updatedChannel))
  }

  const handleSave = async () => {
    let uploadedLogo = null
    try {
      if (logo) {
        const formData = new FormData()
        Array.from(logo).forEach(file => {
          formData.append('file[]', file)
        })
        uploadedLogo = await uploadFile(formData)
      }
      dispatch(
        updateChannelThunk({
          ...channel,
          avatar: uploadedLogo && uploadedLogo[0].callback
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const urlLogoPlaceholder = () => {
    if (logo) return URL.createObjectURL(logo[0])
    return channel?.avatar?.media_url
  }

  return (
    <Stack gap={2}>
      <ChannelSettingsTitle>{__('channel_information')}</ChannelSettingsTitle>
      <Stack direction="row" gap={2}>
        <Box>
          <FileUploader
            acceptFiles="image/*"
            onUploaded={setLogo}
            placeholder={
              (logo !== null || channel?.avatar?.media_url) && (
                <Box
                  component={'img'}
                  src={urlLogoPlaceholder()}
                  sx={{
                    borderRadius: theme => theme.spacing(1.5),
                    width: '128px',
                    height: '128px'
                  }}
                />
              )
            }
          />
        </Box>
        <Stack gap={2} width={'100%'}>
          <TextField
            value={channel?.name}
            onChange={e =>
              handleUpdateChannelInfo({
                name: e.target.value
              })
            }
            label={__('label_channel_name')}
            placeholder={__('p_channel_name')}
          />
          <TextField
            value={channel?.slogan}
            onChange={e =>
              handleUpdateChannelInfo({
                slogan: e.target.value
              })
            }
            label={__('label_channel_slogan')}
            placeholder={__('p_channel_slogan')}
          />
        </Stack>
      </Stack>
      <TextField
        value={channel?.description}
        onChange={e =>
          handleUpdateChannelInfo({
            description: e.target.value
          })
        }
        multiline
        minRows={3}
        label={__('label_channel_description')}
        placeholder={__('p_channel_description')}
      />
      <Stack></Stack>
      <ColorSelector
        color={channel?.primary_color || ''}
        onChangeColor={color => {
          handleUpdateChannelInfo({
            primary_color: color
          })
        }}
      />
      <Stack direction={'row'} alignItems={'center'}>
        <Switch
          checked={channel?.need_approval}
          onChange={e =>
            handleUpdateChannelInfo({
              need_approval: e.target.checked
            })
          }
        />
        <Typography>{__('channel_setting_overview_approval_post')}</Typography>
      </Stack>
      <Stack direction={'row'} gap={3}>
        <Box
          role="button"
          onClick={() =>
            handleUpdateChannelInfo({
              public_status: ChannelStatus.PUBLIC
            })
          }
          sx={{
            ...channelStatusStyled,
            backgroundColor: theme => isPublicChannel && theme.palette.background.default
          }}
        >
          <Stack direction={'row'} justifyContent={'flex-end'} mb={2}>
            <Stack direction={'row'} alignItems={'center'} gap={2} width={'100%'}>
              <GlobeIcon />
              <Typography variant={'subtitle2'}>Công khai</Typography>
            </Stack>
            <Radio className={'selector'} checked={isPublicChannel} />
          </Stack>
          <Typography>
            Ai cũng có thể thấy ai đang ở trong kênh và những gì họ đăng lên. Nội dung có thể được
            tìm thấy bởi các công cụ tìm kiếm.
          </Typography>
        </Box>

        <Box
          role="button"
          onClick={() =>
            handleUpdateChannelInfo({
              public_status: ChannelStatus.PRIVATE
            })
          }
          sx={{
            ...channelStatusStyled,
            backgroundColor: theme => !isPublicChannel && theme.palette.background.default
          }}
        >
          <Stack direction={'row'} justifyContent={'flex-end'} mb={2}>
            <Stack direction={'row'} alignItems={'center'} gap={2} width={'100%'}>
              <LockIcon />
              <Typography variant={'subtitle2'}>Công khai</Typography>
            </Stack>
            <Radio className={'selector'} checked={!isPublicChannel} />
          </Stack>
          <Typography>
            Ai cũng có thể thấy ai đang ở trong kênh và những gì họ đăng lên. Nội dung có thể được
            tìm thấy bởi các công cụ tìm kiếm.
          </Typography>
        </Box>
      </Stack>
      <Button
        onClick={handleSave}
        sx={{
          marginLeft: 'auto'
        }}
      >
        {__('txt_update')}
      </Button>
    </Stack>
  )
}

export default ChannelSettingsInformation

const channelStatusStyled: SxProps<CurrentTheme> = {
  padding: theme => theme.spacing(2),
  borderRadius: theme => theme.spacing(1.5),
  border: theme => `1px solid ${theme.palette.divider}`,
  '& > .selector': {
    marginRight: 'auto',
    padding: 0
  }
}
