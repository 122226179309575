import BigLayout from 'layouts/bigLayout'
import ShopBreadcrumb from '../components/shop.breadcrumb'
import __ from 'languages/index'
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Stack,
  Typography,
  styled
} from '@mui/material'
import { CalendarMinusIcon, EmptyIcon } from 'icons'
import imageDefault from 'media/images/image_default.png'
import { BarPlot, ChartsXAxis } from '@mui/x-charts'
import { BarChart } from '@mui/x-charts/BarChart'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  getAnalyticsCommercer,
  getOrderByDay,
  getRevenueByDay,
  getTopBestsellers
} from '../service/order.service'
import { AxiosResponse } from 'axios'
import helpers from 'helpers'
import { TypedProduct } from '../interface'
import { formatString } from 'entities/support/utils'
import dateandtime from 'date-and-time'
import { DateRange } from 'react-date-range'

const addDays = dateandtime.addDays
interface TypedTopBestSellers extends Partial<TypedProduct> {
  item_sold_quantity: number
}

interface TypedRevenueByDay {
  order_date: string
  order_total_value: string
  order_count: string
}

interface TypeDataTime {
  endDate: Date
  key: string
  startDate: Date
}

const ShopRevenue = () => {
  const anchorRef = useRef<HTMLDivElement>(null)

  const [showModalFilterTime, setShowModalFilterTime] = useState<boolean>(false)
  const [topBestSeller, setTopBestSeller] = useState<TypedTopBestSellers[]>([])
  const [revenueData, setRevenueData] = useState(null)
  const [revenueByDayData, setRevenueByDayData] =
    useState<Omit<TypedRevenueByDay, 'order_count'>[]>(null)
  const [orderByDayData, setOrderByDayData] =
    useState<Omit<TypedRevenueByDay, 'order_total_value'>[]>(null)

  const [dataFilterTime, setDataFilterTime] = useState<TypeDataTime>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 29)),
    endDate: new Date(),
    key: 'selection'
  })

  const [params, setParams] = useState(null)

  const cartData = useMemo(() => {
    return [
      {
        label: __('revenue_title'),
        value: `${helpers.convertToCommasFormat(revenueData?.total_order_value || 0)} VND`
      },
      {
        label: __('order'),
        value: `${revenueData?.order_count || 0} ${__('order')}`
      },
      {
        label: __('partner'),
        value: `${revenueData?.customer_count || 0} ${__('partner')}`
      }
    ]
  }, [revenueData])

  const getDaysInMonth = useCallback(
    (dataChart, date: TypeDataTime) => {
      const endDate = new Date(date?.endDate)
      const lengthDate =
        dateandtime.subtract(new Date(date?.endDate), new Date(date?.startDate)).toDays() + 1

      let labelArr = [new Date(endDate)]
      let revenueValues = [0]
      let orderValues = [0]
      for (let i = 0; i < lengthDate - 1; i++) {
        const day = new Date(endDate.setDate(endDate.getDate() - 1))
        labelArr.push(day)
        revenueValues.push(0)
        orderValues.push(0)
      }
      labelArr.reverse()
      for (let i = 0; i < labelArr?.length; i++) {
        dataChart?.forEach(value => {
          if (dateandtime.isSameDay(new Date(labelArr[i]), new Date(value?.order_date))) {
            revenueValues[i] = value?.order_total_value || 0
            orderValues[i] = value?.order_count || 0
          }
        })
      }
      return {
        label: labelArr?.map(data => dateandtime.format(new Date(data), 'DD/MM')),
        date: labelArr,
        revenueData: revenueValues,
        orderData: orderValues
      }
    },
    [dataFilterTime]
  )

  const revenueMemo = useMemo(() => {
    return getDaysInMonth(revenueByDayData, dataFilterTime)
  }, [revenueByDayData, dataFilterTime])

  const orderMemo = useMemo(() => {
    return getDaysInMonth(orderByDayData, dataFilterTime)
  }, [orderByDayData, dataFilterTime])

  const handleToggle = () => {
    setShowModalFilterTime(prevOpen => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setShowModalFilterTime(false)
  }

  const handleConvertFilterTime = useCallback((start: any, end: any) => {
    if (!start && !end) return
    const startDate = new Date(
      `${dateandtime.format(new Date(start), 'YYYY-MM-DD')} 00:00:00`
    ).getTime()
    const endDate = new Date(
      `${dateandtime.format(new Date(end), 'YYYY-MM-DD')} 23:59:59`
    ).getTime()
    return `gte:${startDate},lte:${endDate}`
  }, [])

  const handleSubmitFilterTime = useCallback(
    (value: TypeDataTime) => {
      setShowModalFilterTime(false)
      setDataFilterTime(value)
    },
    [params]
  )

  useEffect(() => {
    getAnalyticsCommercer()
      .then((res: AxiosResponse) => {
        setRevenueData(res?.data)
      })
      .catch(error => {
        console.log(`getAnalyticsCommercer Error >>>`, error)
      })
  }, [])

  useEffect(() => {
    getTopBestsellers()
      .then((res: AxiosResponse) => {
        setTopBestSeller([...res?.data])
      })
      .catch(error => {
        console.log(`getTopBestsellers Error >>>`, error)
      })
  }, [])

  useEffect(() => {
    const time = handleConvertFilterTime(dataFilterTime?.startDate, dataFilterTime?.endDate)
    setParams({ ...params, createdAt: time })
  }, [dataFilterTime])

  useEffect(() => {
    if (helpers.isEmpty(params)) return
    helpers.clearValueEmptyInObject(params)
    getRevenueByDay({ ...params })
      .then((res: AxiosResponse) => {
        setRevenueByDayData([...res?.data])
      })
      .catch(error => {
        console.log(`getRevenueByDay Error >>>`, error)
      })
  }, [params])

  useEffect(() => {
    if (helpers.isEmpty(params)) return
    helpers.clearValueEmptyInObject(params)
    getOrderByDay({ ...params })
      .then((res: AxiosResponse) => {
        setOrderByDayData([...res?.data])
      })
      .catch(error => {
        console.log(`getOrderByDay Error >>>`, error)
      })
  }, [params])

  return (
    <BigLayout>
      <ShopBreadcrumb
        breadcrumbName={{
          '/shop/revenue': __('revenue')
        }}
      />
      <Stack sx={{ marginTop: theme => theme.spacing(2) }} rowGap={theme => theme.spacing(3)}>
        <Paper>
          <Stack direction={'row'} justifyContent={'space-between'} p={theme => theme.spacing(2)}>
            <Typography variant="h5" textTransform={'none'} fontWeight={600}>
              {__('revenue')}
            </Typography>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
              <Button
                onClick={handleToggle}
                aria-controls={showModalFilterTime ? 'split-button-menu' : undefined}
                aria-expanded={showModalFilterTime ? 'true' : undefined}
                variant="outlined"
                sx={{
                  border: theme => `1px solid ${theme.palette.divider}`,
                  color: theme => theme.palette.text.primary
                }}
                startIcon={<CalendarMinusIcon />}
              >
                <Typography textTransform={'none'}>
                  {`${dateandtime.format(
                    new Date(dataFilterTime?.startDate),
                    'DD/MM/YYYY'
                  )} - ${dateandtime.format(new Date(dataFilterTime?.endDate), 'DD/MM/YYYY')}`}
                </Typography>
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
                m: theme => `${theme.spacing(2, 0, 0)} !important`
              }}
              open={showModalFilterTime}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper
                    sx={{
                      p: theme => theme.spacing(3),
                      width: theme => theme.spacing(63.75),
                      border: theme => `1px solid ${theme.palette.divider}`
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Stack>
                        <FilterTimeComponent
                          data={dataFilterTime}
                          onSubmit={handleSubmitFilterTime}
                        />
                      </Stack>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
        </Paper>
        <Stack direction={'row'} spacing={3}>
          {cartData.map((value, idx: number) => (
            <Paper key={idx} sx={{ p: theme => theme.spacing(3, 2), flex: 1 }}>
              <Typography variant="h6" textTransform={'none'} fontWeight={600}>
                {value?.label}
              </Typography>
              <Typography
                fontSize={theme => theme.spacing(4.5)}
                color={'primary.main'}
                textTransform={idx ? 'lowercase' : 'none'}
                fontWeight={700}
              >
                {value?.value}
              </Typography>
            </Paper>
          ))}
        </Stack>
        <Stack direction={'row'} spacing={3}>
          <Stack flex={0.625}>
            <Stack rowGap={theme => theme.spacing(3)}>
              <Paper sx={{ p: theme => theme.spacing(3) }}>
                <Typography fontWeight={700} fontSize={theme => theme.spacing(2.75)}>
                  {__('revenue_by_day')}
                </Typography>
                <BarChart
                  margin={{
                    right: 0
                  }}
                  bottomAxis={{}}
                  axisHighlight={{ x: 'none', y: 'line' }}
                  xAxis={[
                    {
                      scaleType: 'band',
                      data: revenueMemo?.label
                    }
                  ]}
                  series={[
                    {
                      data: revenueMemo?.revenueData,
                      type: 'bar'
                    }
                  ]}
                  height={330}
                  sx={{
                    '& rect': {
                      borderRadius: theme => theme.spacing(1.5),
                      fill: theme => theme.palette.primary.main
                    }
                  }}
                >
                  <ChartsXAxis disableTicks={true} disableLine={false} />
                </BarChart>
              </Paper>
              <Paper sx={{ p: theme => theme.spacing(3) }}>
                <Typography fontWeight={700} fontSize={theme => theme.spacing(2.75)}>
                  {__('orders_by_day')}
                </Typography>
                <BarChart
                  margin={{
                    right: 0
                  }}
                  bottomAxis={{}}
                  axisHighlight={{ x: 'none', y: 'line' }}
                  xAxis={[
                    {
                      scaleType: 'band',
                      data: orderMemo?.label
                    }
                  ]}
                  series={[
                    {
                      data: orderMemo?.orderData,
                      type: 'bar'
                    }
                  ]}
                  height={330}
                  sx={{
                    '& rect': {
                      borderRadius: theme => theme.spacing(1.5),
                      fill: theme => theme.palette.info.main
                    }
                  }}
                >
                  <ChartsXAxis disableTicks={true} disableLine={false} />
                  <BarPlot />
                </BarChart>
              </Paper>
            </Stack>
          </Stack>
          <Stack flex={0.375} overflow={'auto'}>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: theme => theme.spacing(3),
                height: '100%'
              }}
            >
              <Typography
                fontWeight={700}
                fontSize={theme => theme.spacing(2.75)}
                marginBottom={theme => theme.spacing(2)}
              >
                {__('revenue_top_product')}
              </Typography>
              <Stack rowGap={theme => theme.spacing(2)} flex={1}>
                {helpers.isEmpty(topBestSeller) ? (
                  <EmptyComponent />
                ) : (
                  <>
                    {topBestSeller.map((value: TypedTopBestSellers, idx: number) => (
                      <Paper
                        key={idx}
                        sx={{
                          p: theme => theme.spacing(1.5),
                          background: theme => theme.palette.background.default
                        }}
                      >
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'flex-end'}
                        >
                          <Stack
                            direction={'row'}
                            columnGap={theme => theme.spacing(1.5)}
                            width={'100%'}
                          >
                            <Box
                              component={'img'}
                              src={value?.product_avatar?.[0] || imageDefault}
                              sx={{
                                width: theme => theme.spacing(7.375),
                                height: theme => theme.spacing(7.375),
                                objectFit: 'cover',
                                borderRadius: theme => theme.spacing(0.75)
                              }}
                            />
                            <Stack
                              direction={'column'}
                              justifyContent={'space-around'}
                              flex={1}
                              overflow={'auto'}
                            >
                              <Typography variant="body2" fontWeight={600} noWrap>
                                {value?.product_name || ''}
                              </Typography>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                              >
                                <Typography variant="caption" color="primary.main" fontWeight={600}>
                                  {`${helpers.convertToCommasFormat(
                                    value?.product_price || '0'
                                  )} VND`}
                                </Typography>
                                <Typography variant="body2" fontWeight={700} color="success.dark">
                                  {formatString(
                                    __('number_sold'),
                                    helpers.convertToCommasFormat(value?.item_sold_quantity || '0')
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Paper>
                    ))}
                  </>
                )}
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </Stack>
    </BigLayout>
  )
}

export default ShopRevenue

const EmptyComponent = () => {
  return (
    <Box sx={{ display: 'grid', placeItems: 'center', height: '100%' }}>
      <EmptyIcon
        sx={{
          width: theme => theme.spacing(18.75),
          height: theme => theme.spacing(18.75)
        }}
      />
    </Box>
  )
}

const FilterTimeComponent = ({ data, onSubmit }: { data: any; onSubmit: Function }) => {
  const [stateTime, setStateTime] = useState<TypeDataTime[]>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ])
  const handleSubmit = useCallback(() => {
    onSubmit(stateTime.at(0))
  }, [stateTime])

  useEffect(() => {
    if (!data?.startDate) return
    setStateTime([data])
  }, [data])

  return (
    <FilterTimeContainer rowGap={theme => theme.spacing(2)}>
      <DateRange
        onChange={(item: any) => setStateTime([item.selection])}
        months={1}
        // minDate={addDays(new Date(), -300)}
        maxDate={new Date()}
        direction="vertical"
        scroll={{ enabled: true }}
        moveRangeOnFirstSelection
        retainEndDateOnFirstSelection
        ranges={stateTime}
        dateDisplayFormat="dd/MM/yyyy"
      />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button onClick={handleSubmit}>
          <Typography textTransform={'none'}>{__('course_btn_apply')}</Typography>
        </Button>
      </Stack>
    </FilterTimeContainer>
  )
}

const FilterTimeContainer = styled(Stack)(({ theme }) => ({
  '& .rdrMonth': {
    marginTop: theme.spacing(7.5),
    ':first-child': {
      marginTop: 0
    }
  },
}))
