import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography
} from '@mui/material'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import __ from 'languages/index'
import { CloseIcon } from '../../../icons'
import React, { useEffect } from 'react'
import helpers from 'helpers/index'
import { convertData, getStyles } from 'entities/shop/helper'
import addressData from '../../challenge/media/diachinh.json'
import { TypeAddess, TypeDistrict, TypeWard } from '../interface'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { formatString, validateLabel } from 'entities/support/utils'
import TextFieldMUI from 'components/TextFieldMUI'
import { AntSwitch } from './shop.admin.category'

interface TypeEditIfoModal {
  showEditModal: boolean
  handleCloseEditModal: () => void
  handleCreateAddress: Function
}

const PaymentEditInfoModal: React.FC<TypeEditIfoModal> = ({
  handleCloseEditModal,
  showEditModal,
  handleCreateAddress
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [cityName, setCityName] = React.useState<string>('')
  const [districtName, setDistrictName] = React.useState<string>('')
  const [wardName, setWardName] = React.useState<string>('')
  const [listAddress, setListAddress] = React.useState<TypeAddess[]>([])
  const [districtByCity, setDistrictByCity] = React.useState<TypeDistrict[]>([])
  const [wardByDistrict, setWardByDistrict] = React.useState<TypeWard[]>([])

  const { fields, submit, submitting, dirty, reset } = useForm({
    fields: {
      user_display_name: useField<string>({
        value: '',
        validates: [
          notEmpty(validateLabel(`${__('payment_edit_modal_input_username')}`)?.required),
          inputVal => {
            if (inputVal?.trim()?.length < 3) {
              return formatString(__('validate_than'), __('payment_edit_modal_input_username'), '2')
            }
          }
        ]
      }),
      user_phonenumber: useField<string>({
        value: '',
        validates: [
          notEmpty(validateLabel(`${__('payment_edit_modal_input_number_phone')}`)?.required),
          inputVal => {
            if (!helpers.isPhoneNumber(inputVal)) {
              return `${__('validate_phone')}`
            }
          }
        ]
      }),
      user_email: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if (inputVal && !helpers.isEmail(inputVal)) {
              return formatString(__('validate_pattern'), 'Email')
            }
          }
        ]
      }),
      user_address: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('label_address')}`)?.required)]
      }),
      user_address_city: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('select_city')}`)?.required)]
      }),
      user_address_district: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('select_district')}`)?.required)]
      }),
      user_address_ward: useField<string>({
        value: '',
        validates: [notEmpty(validateLabel(`${__('select_ward')}`)?.required)]
      }),
      user_address_note: useField<string>({
        value: '',
        validates: []
      }),
      is_default_address: useField<number>({
        value: 0,
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        handleCreateAddress({ 
          ...values, 
          user_address: values.user_address?.trim(),
          user_display_name: values.user_display_name?.trim(),
          user_address_note: values.user_address_note?.trim(),
          user_email: values.user_email?.trim()
        })
        setIsLoading(true)
        await helpers.sleep(500)
        setIsLoading(false)
        reset()
        handleCloseEditModal()
      } catch (e: any) {
        console.error(` createStockSubmit`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
      return { status: 'success' }
    }
  })

  const handleChangeCity = (event: SelectChangeEvent<typeof cityName>) => {
    const {
      target: { value }
    } = event
    setCityName(value)
    setWardName('')
    fields.user_address_district.onChange('')
    fields.user_address_ward.onChange('')
    setDistrictName('')
  }

  const handleChangeWard = (event: SelectChangeEvent<typeof wardName>) => {
    const {
      target: { value }
    } = event
    setWardName(value)
  }

  const handleChangeDistrict = (event: SelectChangeEvent<typeof districtName>) => {
    const {
      target: { value }
    } = event
    setDistrictName(value)
    setWardName('')
    fields.user_address_ward.onChange('')
  }

  const SelectComponent = ({ option, value, title, onChange, _onBlur, _onClick, error }) => {
    return (
      <FormControl
        className="select_form"
        sx={{
          m: 1,
          mt: 3,
          display: 'grid',
          rowGap: theme => theme.spacing(0.5),
          gridTemplateRows: theme => `1fr repeat(1,minmax(${theme.spacing(2.375)}, auto))`,
          '& > div': {
            height: theme => theme.spacing(7)
          }
        }}
      >
        <Select
          MenuProps={{
            elevation: 1,
            sx: {
              maxHeight: theme => theme.spacing(37.5)
            }
          }}
          displayEmpty
          value={value}
          onBlur={_onBlur}
          onChange={onChange}
          input={<OutlinedInput />}
          renderValue={selected => {
            if (selected.length === 0) {
              return <>{title}</>
            }
            return selected
          }}
          className="payment-modal-select"
          sx={{
            padding: theme => theme.spacing(0, 2),
            backgroundColor: theme => theme.palette.background.default,
            borderRadius: theme => theme.spacing(1.5)
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">{title}</MenuItem>
          {option?.map(city => (
            <MenuItem
              key={city?.code}
              value={city?.name}
              sx={theme => getStyles(city?.name, value, theme)}
              onClick={() => _onClick(city?.code)}
            >
              {city?.name}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <Typography
            sx={{ paddingTop: theme => theme.spacing(0.25) }}
            variant="body2"
            color="error.main"
          >
            {error}
          </Typography>
        )}
      </FormControl>
    )
  }

  useEffect(() => {
    setListAddress(convertData(addressData))
  }, [])

  useEffect(() => {
    const district = listAddress
      ?.filter(item => item?.code === fields.user_address_city.value)
      .map(item => item['quan-huyen'])
    if (!!district) {
      setDistrictByCity(convertData(district[0]))
    }
  }, [listAddress, fields.user_address_city.value])

  useEffect(() => {
    const ward = districtByCity
      ?.filter(item => item?.code === fields.user_address_district.value)
      .map(item => item['xa-phuong'])
    if (!!ward) {
      setWardByDistrict(convertData(ward[0]))
    }
  }, [districtByCity, fields.user_address_district.value])

  return (
    <PaymentEditInfoModalContainer>
      <Modal open={showEditModal} onClose={handleCloseEditModal}>
        <ModalContainer
          sx={{
            width: '80%',
            maxWidth: theme => theme.spacing(95.75),
            margin: '0 auto'
          }}
        >
          <ModalHeader direction={'row'}>
            <Typography textTransform={'none'} variant={'h6'} className={'title'}>
              {__('payment_edit_modal_title')}
            </Typography>
            <IconButton onClick={handleCloseEditModal}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider />
          <ModalBody
            sx={{
              maxHeight: 'calc(100% - 80px)',
              overflowY: 'auto'
            }}
          >
            <Box
              component={'form'}
              onSubmit={submit}
              sx={{
                display: 'grid',
                rowGap: theme => theme.spacing(0.5)
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-around"
                alignItems="flex-start"
              >
                <TextFieldMUI
                  {...fields.user_display_name}
                  placeholder={__('payment_edit_modal_input_username')}
                  maxLength={150}
                  minLength={2}
                />
                <TextFieldMUI
                  {...fields.user_phonenumber}
                  onChange={value => {
                    fields.user_phonenumber.onChange(`${value?.replace(/\D+/g, '')}`)
                  }}
                  maxLength={10}
                  value={fields.user_phonenumber.value || ''}
                  placeholder={__('payment_edit_modal_input_number_phone')}
                />
              </Stack>
              <TextFieldMUI
                {...fields.user_email}
                placeholder={__('payment_edit_modal_input_email')}
              />
              <TextFieldMUI
                {...fields.user_address}
                placeholder={__('payment_edit_modal_input_address')}
                maxLength={200}
              />

              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
                  columnGap: theme => theme.spacing(2)
                }}
              >
                <SelectComponent
                  option={listAddress}
                  title={__('select_city')}
                  value={cityName}
                  onChange={handleChangeCity}
                  _onClick={value => fields.user_address_city.onChange(value)}
                  _onBlur={() =>
                    fields.user_address_city.runValidation(fields.user_address_city.value)
                  }
                  error={fields.user_address_city.error}
                />
                <SelectComponent
                  option={districtByCity}
                  title={__('select_district')}
                  value={districtName}
                  onChange={handleChangeDistrict}
                  _onClick={value => fields.user_address_district.onChange(value)}
                  _onBlur={() =>
                    fields.user_address_district.runValidation(fields.user_address_district.value)
                  }
                  error={fields.user_address_district.error}
                />
                <SelectComponent
                  option={wardByDistrict}
                  title={__('select_ward')}
                  value={wardName}
                  onChange={handleChangeWard}
                  _onClick={value => fields.user_address_ward.onChange(value)}
                  _onBlur={() =>
                    fields.user_address_ward.runValidation(fields.user_address_ward.value)
                  }
                  error={fields.user_address_ward.error}
                />
              </Stack>
              <OutlinedInput
                multiline
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  fields.user_address_note.onChange(e.target.value)
                }
                value={fields.user_address_note.value || ''}
                placeholder={__('payment_edit_modal_input_note')}
                minRows={4}
                inputProps={{
                  maxLength: 250
                }}
                sx={{
                  width: '100%',
                  p: theme => theme.spacing(2),
                  borderRadius: theme => theme.spacing(1.5),
                  background: theme => theme.palette.background.default,
                  outline: 'none',
                  caretColor: theme => theme.palette.primary.main
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                marginTop={theme => theme.spacing(2.5)}
                justifyContent={'flex-end'}
                alignItems="center"
              >
                <AntSwitch
                  onChange={event => {
                    if (event.target.checked) {
                      fields.is_default_address.onChange(1)
                    } else {
                      fields.is_default_address.onChange(0)
                    }
                  }}
                  value={fields.is_default_address.value}
                  inputProps={{ 'aria-label': 'ant design' }}
                />
                <Typography fontWeight={500}>{__('set_default_address')}</Typography>
              </Stack>
              <Box
                display={'flex'}
                justifyContent={'center'}
                sx={{ marginTop: theme => theme.spacing(7.25) }}
              >
                <Button type="submit" disabled={!dirty || submitting} sx={{ minWidth: '200px' }}>
                  {isLoading ? __('btn_processing') : __('payment_btn_confirm')}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </PaymentEditInfoModalContainer>
  )
}
export default PaymentEditInfoModal
const PaymentEditInfoModalContainer = styled(Box)(({ theme }) => ({
  '& .payment-modal-select': {}
}))
