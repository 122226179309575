import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import imacImg from 'media/images/img-imac.png'
import HomeTemplate from 'entities/channel/components/template/home.template'

type Props = {
  channelName?: string
  slogan?: string
  domain?: string
}

const PreviewTemplate = ({ channelName, slogan, domain }: Props) => {
  const [layoutSize, setLayoutSize] = useState({
    height: 0,
    width: 0
  })

  const bgImacRef = React.useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (bgImacRef.current === null) return

    setLayoutSize({
      height: bgImacRef.current.getBoundingClientRect().height * 0.75,
      width: bgImacRef.current.getBoundingClientRect().width * 0.96
    })
  }, [bgImacRef.current])

  return (
    <Box
      sx={{
        padding: theme => theme.spacing(10),
        position: 'relative'
      }}
    >
      <Box>
        <Box
          ref={bgImacRef}
          component="img"
          sx={{
            width: '100%',
            height: 'auto'
          }}
          src={imacImg}
          alt="imac"
          id="imac"
        />
      </Box>

      <HomeTemplate
        slogan={slogan}
        channelName={channelName}
        domain={domain}
        sxLayout={{
          pointerEvents: 'none',
          position: 'absolute',
          height: `${layoutSize.height}px`,
          width: `${layoutSize.width}px`,
          top: theme => theme.spacing(14),
          left: theme => theme.spacing(13),
          backgroundColor: '#fff',
          overflow: 'hidden',
          borderRadius: theme => theme.spacing(1.5)
        }}
      />
    </Box>
  )
}

export default PreviewTemplate
