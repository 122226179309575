import React, {useEffect} from "react";
import MobileRegistrationForm from "./components/MobileRegistrationForm";
import {useAppDispatch, useAppSelector} from "config/store";
import TypedChannel from "../../interfaces/channel.interface";
import {getListPaymentMethod} from "entities/orders/order.store.reducer";
import BigLayout from "../../layouts/bigLayout";

const MobileAppRegisterForm = () =>{
  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)
  const listPaymentMethod = useAppSelector(state => state.order.listPaymentMethod)
  const mobile_id = localStorage.getItem('mobile_id')
  const dispatch = useAppDispatch()
  useEffect(() => {
    try{
      if(mobile_id){
        dispatch(
          getListPaymentMethod({
            service_id: String(mobile_id)
          })
        )
      }
    }
    catch (err) {
      console.log('err',err)
    }
  }, [mobile_id]);

  return(
    <BigLayout>
      <div className="mainFrame">
        <div className='container-sm container p-4' style={{maxWidth:600, margin:'auto', width: "100%"}} >
          <MobileRegistrationForm channel={channel_data} listPaymentMethod={listPaymentMethod}/>
        </div>
      </div>
    </BigLayout>
  )
}
export default MobileAppRegisterForm;
