import { Button, Table } from 'react-bootstrap'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import __ from 'languages/index'
import {TypedService} from "../../../interfaces/plan.interface";

interface TypeUpgradeCompare {
    listService: TypedService[]
    hanleClickBtnUpgrade: () =>void
}

const UpgradeCompare: React.FC<TypeUpgradeCompare> = ({listService, hanleClickBtnUpgrade}) =>{
    const navigate = useNavigate()

    const hanleClickBtnContinue = ()=>{
        navigate('/')
    }

    return(
        <div  className='upgrade-compare-container '>
            <div className = 'container container-sm'>
                <h1 className='upgrade-compare-title'>{__("upgrade_compare_title")}</h1>
                <p className='upgrade-compare-des' >
                  {__("upgrade_compare_des")}
                </p>
                <div  style={{maxWidth: 700, margin: 'auto'}}>
                    <div className="table-responsive-lg">
                        <Table striped borderless hover size="sm"  responsive="lg" className='upgrade-compare-table'>
                            <thead>
                            <tr>
                                <th style={{minWidth:200}}>{''}</th>
                                <th style={{minWidth:140}}>{__("upgrade_compare_plan_free")}</th>
                                <th style={{minWidth:140}}>{__("upgrade_compare_plan_paid")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr key={''} className=''>
                                <td>{__("upgrade_compare_number_member")}</td>
                                <td>
                                    <ul style={{padding: '0'}}>
                                        <li style={{listStyle: "none"}}>{__("upgrade_compare_number_member_default")}</li>
                                    </ul>
                                </td>
                                <td>{__("upgrade_compare_number_member_unlimited")}</td>
                            </tr>
                            <tr>
                                <td>{__("upgrade_compare_number_email")}</td>
                                <td>{__("upgrade_compare_number_email_2000")}</td>
                                <td>{__("upgrade_compare_number_email_100000")}</td>
                            </tr>
                            <tr>
                                <td>{__("upgrade_compare_capacity_course")}</td>
                                <td>{__("upgrade_compare_capacity_course_2gb")}</td>
                                <td>{__("upgrade_compare_capacity_course_10tb")}</td>
                            </tr >
                            <tr>
                                <td>{__("upgrade_compare_price")}</td>
                                <td>{__("upgrade_compare_price_free")}</td>
                                <td>{__("upgrade_compare_price_paid")}</td>
                            </tr >
                            </tbody>
                        </Table>
                    </div>
                    <div className='col-12 upgrade-card-btn'>
                        <div className= 'col-sm-4 col-0'></div>
                        <div className= 'col-sm-4 col-6 upgrade-btn-item'>
                            <Button onClick={hanleClickBtnContinue}  className= 'btn' variant="outline-primary">{__("upgrade_compare_continue")}</Button>
                        </div>
                        <div className= 'col-sm-4 col-6 upgrade-btn-item' >
                            <Button onClick={hanleClickBtnUpgrade}  className= 'btn mobile-btn' variant="primary">{__("upgrade_compare_update_now")}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UpgradeCompare
