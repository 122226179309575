import {useCallback, useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import {EnumLiveStreamSource, ErrorMediaType, TypedDeviceInfo} from "entities/event/event.interface";
import {ID_VIDEO_ADMIN_STUDIO} from "entities/livestream/livestream.admin.constant";
import {useAppDispatch, useAppSelector} from "config/store";
import {
  setIdAudioDeviceSelectedForLivestream,
  setIdVideoDeviceSelectedForLivestream
} from "entities/livestream/livestream.store.reducer";
import __ from "languages/index";

interface TypedEventSetupLivestreamCameraProps {
  setIsVideoReady: (value: boolean) => void
}

export default function LivestreamPreviewDeviceCamera({setIsVideoReady}: TypedEventSetupLivestreamCameraProps) {

  const liveDetail = useAppSelector(state => state.livestream.entity);
  const idAudioDeviceSelectedForLivestream = useAppSelector(state => state.livestream.idAudioDeviceSelectedForLivestream);
  const idVideoDeviceSelectedForLivestream = useAppSelector(state => state.livestream.idVideoDeviceSelectedForLivestream);
  const audioSourceError = useAppSelector(state => state.livestream.audioSourceError);
  const audioSourceList = useAppSelector(state => state.livestream.audioSourceList);
  const dispatch = useAppDispatch();

  const refVideoSourceSelected = useRef<any>(idVideoDeviceSelectedForLivestream);
  const [videoSourceList, setVideoSourceList] = useState<any>([]);
  const [videoSourceError, setVideoSourceError] = useState<ErrorMediaType>();

  useEffect(() => {
    if(liveDetail?.input_type === EnumLiveStreamSource.Camera){
      navigator?.mediaDevices?.addEventListener("devicechange", onDeviceChange);
    }
    return (() => {
      navigator?.mediaDevices.removeEventListener("devicechange", onDeviceChange)
    })
  }, [liveDetail?.input_type]);

  useEffect(() => {
    if (liveDetail?.input_type === EnumLiveStreamSource.Camera) {
      getDeviceMedia();
    }
  }, [liveDetail?.input_type, idAudioDeviceSelectedForLivestream, idVideoDeviceSelectedForLivestream]);

  useEffect(() => {
    refVideoSourceSelected.current = idVideoDeviceSelectedForLivestream;

    if (liveDetail?.input_type === EnumLiveStreamSource.Camera) {
      navigator?.mediaDevices?.getUserMedia({
        video: {deviceId: refVideoSourceSelected.current}
      }).then(gotVideoStream).catch(handleVideoError);
    }
  }, [liveDetail?.input_type, idVideoDeviceSelectedForLivestream]);

  const onDeviceChange = useCallback(async () => {
    await getDeviceMedia();
    setupSourceDevice()
  }, [])

  const setupSourceDevice = useCallback(() => {
    navigator?.mediaDevices?.getUserMedia({
      video: {deviceId: refVideoSourceSelected.current}
    }).then(gotVideoStream).catch(handleVideoError);
  }, []);

  const getDeviceMedia = useCallback(async () => {
    try {
      let devicesInformation = await navigator?.mediaDevices?.enumerateDevices();

      if (!devicesInformation) return;

      let videoDriver: TypedDeviceInfo[] = [];

      for (let i = 0; i !== devicesInformation.length; ++i) {
        const deviceInfo: TypedDeviceInfo = devicesInformation[i];
        if (deviceInfo.kind === 'videoinput') {
          videoDriver.push(deviceInfo);
        }
      }

      if (videoDriver.length > 0) {
        if (!refVideoSourceSelected.current || !(videoDriver.map(item => item.deviceId).includes(refVideoSourceSelected.current))) {
          refVideoSourceSelected.current = videoDriver[0]?.deviceId;
        }

        setVideoSourceList(videoDriver);
      }

    } catch (e) {
      console.log('Error in gotDevice: ' + e);
    } finally {
      console.log(liveDetail?.input_type, "sourceStream")
    }

    return;
  }, [liveDetail]);

  const gotVideoStream = (stream: MediaStream) => {
    setIsVideoReady(true)
    const videoElement = document.getElementById(ID_VIDEO_ADMIN_STUDIO) as HTMLVideoElement;
    videoElement.src = null;
    videoElement.srcObject = stream;
    videoElement.load();
  }

  const handleVideoError = (error) => {
    console.log(error, "oshdoahd")
    setIsVideoReady(false)
    setVideoSourceError(error?.toString().toLowerCase().includes("permission") ? ErrorMediaType.Permission : ErrorMediaType.NotFound);
  }

  const onChangeVideoSource = useCallback((e: any) => {
    dispatch(setIdVideoDeviceSelectedForLivestream(e.target.value))
  }, [])

  const onChangeAudioSource = useCallback((e: any) => {
    dispatch(setIdAudioDeviceSelectedForLivestream(e.target.value))
  }, [])

  return (
    <div className="livestreamSelection">
      <div className="select">
        <label htmlFor="audioSource" className="mb-2">{__("livestream_source_mic")} </label>
        {
          audioSourceError ?
            (
              audioSourceError === ErrorMediaType.Permission ?
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_permission_reject")}
                </h6>
                :
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_not_found_mic")}
                </h6>
            )
            :
            <Form.Select id="audioSource"
                         value={idAudioDeviceSelectedForLivestream || undefined}
                         onChange={onChangeAudioSource}>
              {
                audioSourceList?.map((e, index) => {
                  return <option key={index + '_dgsdg'} value={`${e.deviceId}`}>{e.label}</option>
                })
              }
            </Form.Select>
        }

      </div>

      <div className="select mt-3">
        <label htmlFor="videoSource" className="mb-2">{__("livestream_source_camera")} </label>
        {
          videoSourceError ?
            (
              videoSourceError === ErrorMediaType.Permission ?
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_allow_access_to_camera")}
                </h6>
                :
                <h6 className="mt-3 text-white mx-5 text-lg-center">
                  {__("livestream_not_found_camera")}
                </h6>
            )
            :
            <Form.Select id="videoSource"
                         value={refVideoSourceSelected.current || undefined}
                         onChange={onChangeVideoSource}>
              {
                videoSourceList?.map((e, index) => {
                  return <option key={index + '_iu48jkh'} value={`${e.deviceId}`}>{e.label}</option>
                })
              }
            </Form.Select>
        }
      </div>
    </div>
  )
}
