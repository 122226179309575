import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { Avatar, Box, Button, InputAdornment, Stack, SxProps, TextField, Typography } from '@mui/material'
import { CloseIcon, FileIcon, PhotoIcon, PollIcon } from '../icons'
import AvatarCurrentUser from 'components/AvatarCurrentUser'
import PostCategoriesSelector from 'entities/homeNew/components/PostCategoriesSelector'
import __ from 'languages/index'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import DocumentFileView from 'entities/homeNew/components/DisplayPostContent/DocumentFileView'
import { useAppSelector } from 'config/store'
import { useCreatePollMutation } from 'entities/homeNew/store/homeApi'
import { TypedRequest } from '../interfaces/request.interface'
import { EnumPostType } from "../interfaces/media.interface";
import useUpload from 'hooks/useUpload'
import { EnumTypeToast, useToast } from "../hooks/useToast";
import helpers from 'helpers'
import IconCloseImage from 'icons/IconCloseImage';
import Lightbox from 'yet-another-react-lightbox';
import { Video } from 'yet-another-react-lightbox/plugins'
import { generateGridLayoutImage, generateMediaSlides } from 'helpers/generateImages'

const docExtension = '*'

interface TypedModalCreateEditPostProps {
  actionText?: string
  postTypeInit: EnumPostType
  post?: TypedRequest | null
  onSubmit?: (post: Partial<TypedRequest>) => Promise<TypedRequest | null>
  isSubmitting?: boolean
  refresh?: (isBackground?: boolean) => void
  getListApprovalInHome?: () => void
  setIsCheckButtonPost?: (value: Partial<{}>) => void;
}

interface TypedPostRef {
  handleSubmitForm: () => Promise<void>;
  postTypeActive: any
}


const ModalCreateEditPost = forwardRef < TypedPostRef, TypedModalCreateEditPostProps> (({
  postTypeInit,
  post,
  onSubmit,
  isSubmitting,
  refresh,
  actionText,
  getListApprovalInHome,
  setIsCheckButtonPost },
  ref) => {
  const user_data = useAppSelector(state => state.user.user_data)
  const toast = useToast()
  const [selectedFiles, setSelectedFiles] = useState < { typeUpload: EnumPostType, fileList: FileList | null } > (null)
  const [postPayload, setPostPayload] = useState < Partial < TypedRequest >> ({})
  const [pollOptions, setPollOptions] = useState < string[] > ([])
  const refPostType = useRef(null);
  const [postTypeActive, setPostTypeActive] = useState(post ? postTypeInit : null);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null)
  const [createPoll, { isLoading: creatingPoll }] = useCreatePollMutation()

  const inputRef = useRef(null)

  const { srcFiles, loading, progress, deleteFileById } = useUpload(selectedFiles, {
    isMultiple: true,
    initialFiles: post?.attach_files || []
  })

  const checkMobile = helpers.isMobile()

  useEffect(() => {
    if (postTypeInit !== undefined && !post) {
      refPostType.current = postTypeInit;
      if (postTypeInit === EnumPostType.Poll) {
        setPollOptions(oldValue => {
          if (oldValue?.length < 2) {
            return ["", ""]
          } else return oldValue
        })
      }

      // if(postTypeInit === EnumPostType.Media){
      //   setTimeout(()=>{
      //     handleSelectFile('image/png, image/jpeg, image/jpg, video/*', EnumPostType.Media)
      //   },1000)
      // }
      //
      // if(postTypeInit === EnumPostType.File){
      //   handleSelectFile(docExtension, EnumPostType.File)
      // }

      setPostTypeActive(postTypeInit)
    }
  }, [postTypeInit]);

  useEffect(() => {
    if (post) {
      setPostPayload({
        post_title: post.post_title,
        post_content: post.post_content,
        post_category: post.post_category,
        post_language: post.post_language
      })
      setPollOptions(post.poll_ids.map(item => item.question))
      setIsCheckButtonPost?.({
        post_title: post.post_title,
        post_content: post.post_content,
        post_category: post.post_category,
        post_language: post.post_language
      })
    } else {
      if ([EnumPostType.Media, EnumPostType.File].includes(postTypeInit)) {
        handleSelectFile(postTypeInit === EnumPostType.Media ? 'image/png, image/jpeg, image/jpg, video/*' : docExtension, postTypeInit)
      }
    }
  }, [post])

  useEffect(() => {
    if (srcFiles?.length <= 0 && (refPostType.current === EnumPostType.File || refPostType.current === EnumPostType.Media)) {
      refPostType.current = null;
      setPostTypeActive(null)
    }
  }, [srcFiles?.length]);

  const handleSelectFile = (fileType: string, typePost: EnumPostType) => () => {
    refPostType.current = typePost;
    inputRef.current.accept = fileType

    inputRef.current.click()
  }

  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files
    if (selectedFiles?.length <= 0) return

    setPostTypeActive(refPostType.current)
    setSelectedFiles({ fileList: selectedFiles, typeUpload: refPostType.current })
  }

  const handleOpen = (index: number) => {
    setSelectedIndex(index)
  }

  const handleClose = () => {
    setSelectedIndex(null)
  }

  const handleOptionChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = [...pollOptions]
    newOptions[index] = e.target.value
    setPollOptions(newOptions)
  }
  const handleAddOption = () => {
    setPollOptions(prev => [...prev, ''])
  }

  const handleRemoveOption = (index: number) => () => {
    setPollOptions(prev => prev.filter((_, i) => i !== index))
  }
  const handleChangePost = (key: keyof TypedRequest, value: any) => {
    setPostPayload(prev => ({
      ...prev,
      [key]: value
    }))

    setIsCheckButtonPost?.(prev => ({
      ...prev,
      [key]: value
    }))
  }

  const handleSubmitForm = async () => {
    //tạo poll trước nếu là update bài đăng
    if (post && postTypeActive === EnumPostType.Poll) {
      await createPoll({
        request_id: post?._id,
        question: JSON.stringify(pollOptions)
      }).unwrap()
    }

    //chỉ có tạo bài viết mới thì mới có response post, nên ta sẽ tạo poll sau
    let newPost = await onSubmit(postPayload)
    if (!post && newPost && postTypeActive === EnumPostType.Poll) {
      await createPoll({
        request_id: newPost?._id,
        question: JSON.stringify(pollOptions)
      }).unwrap()
    }

    if (newPost) {
      getListApprovalInHome?.();
      refresh?.(true);
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__("home_post_success")}`
      })
    }
  }

  useEffect(() => {
    if (Array.isArray(srcFiles)) {
      setPostPayload(prev => ({
        ...prev,
        attach_files: srcFiles
      }))
      setIsCheckButtonPost?.(prev => ({
        ...prev,
        attach_files: srcFiles
      }))
    }
  }, [srcFiles])

  let data = generateMediaSlides(srcFiles)

  const renderAttachmentByPostType = () => {
    if (postTypeActive === EnumPostType.Media && (srcFiles?.find((file) => file?.media_type === "image") || srcFiles?.find((file) => file?.media_type === "video")))
      return (
        <>
          <Lightbox
            open={selectedIndex !== null}
            close={handleClose}
            slides={data}
            plugins={[Video]}
            carousel={{
              finite: true
            }}
            index={selectedIndex}
          />
            <ImageList
              sx={{
                width: '100%',
              }}
              variant="quilted"
              cols={6}
              rowHeight={!helpers.isMobile() ? 80 : 51}
            >
              {generateGridLayoutImage(srcFiles?.slice(0, 5))?.map((item, index) => (
                <ImageListItem
                  key={item._id}
                  sx={(index === srcFiles.slice(0, 5)?.length - 1 && srcFiles.length > 5) ? {
                    position: 'relative',
                    '&:before': {
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      content: `"+${srcFiles.length - 5}"`,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      color: theme => theme.palette.common.white,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }
                  } : {}}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpen(index);
                  }}
                >
                  <Box
                    component={'img'}
                    sx={{
                      verticalAlign: 'middle',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    key={index}
                    alt={item.media_file_name}
                    srcSet={`${item.media_thumbnail}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.media_thumbnail}?w=164&h=164&fit=crop&auto=format`}
                    loading="lazy"
                  />
                  <Avatar
                    sx={{
                      width: theme => theme.spacing(2.5),
                      height: theme => theme.spacing(2.5),
                      bgcolor: theme => theme.palette.background.paper,
                      padding: theme => theme.spacing(0.25),
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: theme => theme.palette.text.primary
                    }}
                  >
                      <IconCloseImage
                        color={'inherit'}
                        cursor='pointer'
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteFileById(item._id);
                        }}
                      />
                  </Avatar>
                </ImageListItem>
              ))}
            </ImageList>
        </>
      )
    else if (postTypeActive === EnumPostType.Poll && pollOptions?.length > 0) {
      return (
        <>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography textTransform={'none'} variant={'subtitle1'} fontWeight={600}>
              {__('home_voted')}
            </Typography>
            <Button variant={'text'} onClick={() => setPostTypeActive(null)}>
              <Typography textTransform={'none'} color={'primary.main'}>
                {__('home_remove_survey')}
              </Typography>
            </Button>
          </Box>

          <Stack gap={1.5}>
            {pollOptions.map((option, index) => {
              return (
                <TextField
                  key={index}
                  value={option}
                  onChange={handleOptionChange(index)}
                  placeholder={__("home_add_vote_placeholder")}
                  hiddenLabel
                  inputProps={{
                    maxLength: 50
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment:
                      pollOptions?.length > 2 ? (
                        <InputAdornment position={'end'} onClick={handleRemoveOption(index)}>
                          <CloseIcon />
                        </InputAdornment>
                      ) : undefined
                  }}
                />
              )
            })}
          </Stack>
          <Button  variant={'text'} onClick={handleAddOption}>
            <Typography textTransform={'none'}  color={'primary.main'}>
              {__('home_add_vote')}
            </Typography>
          </Button>
        </>
      )
    } else if (postTypeActive === EnumPostType.File || srcFiles?.find((file) => file?.media_type === "file")) {
      return (
        <Box>
          <DocumentFileView files={srcFiles} deleteFileById={deleteFileById} />
        </Box>
      )
    }
  }

  const onCreatePoll = () => {
    if (pollOptions?.length < 2) {
      setPollOptions(prev => ["", ""])
    }
    refPostType.current = EnumPostType.Poll;
    setPostTypeActive(EnumPostType.Poll)
  }

  useImperativeHandle(ref, () => ({
    handleSubmitForm,
    postTypeActive
  }))

  return (
    <Box p={!checkMobile ? 0 : '16px'}>
      <>
        <Stack direction={'row'} gap={1} paddingBottom={2} alignItems={'center'}>
          <AvatarCurrentUser
            sx={{
              width: theme => theme.spacing(7.5),
              height: theme => theme.spacing(7.5)
            }}
          />
          <Stack>
            <Typography fontWeight={600} mb={0.5}>
              {user_data?.display_name}
            </Typography>

            <PostCategoriesSelector
              value={postPayload.post_category?._id}
              onChange={category => handleChangePost('post_category', category)}
            />
          </Stack>
        </Stack>
        <TextField
          inputProps={{
            maxLength: 150
          }}
          hiddenLabel
          placeholder={__('home_post_title')}
          onChange={e => handleChangePost('post_title', e.target.value)}
          value={postPayload.post_title}
        />
        <TextField
          onChange={e => handleChangePost('post_content', e.target.value)}
          value={postPayload.post_content}
          hiddenLabel
          placeholder={__('home_post_content')}
          multiline
          rows={4}
          sx={{
            mt: theme => theme.spacing(1.5)
          }}
        />

        <Box mt={2}>{renderAttachmentByPostType()}</Box>
      </>
      <Stack
        direction={'row'}
        gap={2}
        sx={{
          width: '100%',
          bottom: 0,
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: theme => checkMobile ? 0 : theme.spacing(1.25, 3)
        }}
      >
        <input type="file" hidden ref={inputRef} onChange={handleSelectFiles} multiple />
        <Stack direction={'row'} py={checkMobile ? '10px' : 0} justifyContent={'space-between'} width={'100%'}>
          <Button variant="text"
            sx={checkMobile ? sxButtonMobile : sxButton}
            onClick={handleSelectFile('image/png, image/jpeg, image/jpg, video/*', EnumPostType.Media)}
            disabled={postTypeActive && postTypeActive !== EnumPostType.Media}
          >
            <PhotoIcon color="error" />
            <Typography ml={1} fontWeight={600} noWrap>
              {__('home_image_video')}
            </Typography>
          </Button>
          <Button
            variant="text"
            sx={checkMobile ? sxButtonMobile : sxButton}
            onClick={handleSelectFile(docExtension, EnumPostType.File)}
            disabled={postTypeActive && postTypeActive !== EnumPostType.File}
          >
            <FileIcon color="info" />
            <Typography ml={1} fontWeight={600} noWrap>
              {__('home_file')}
            </Typography>
          </Button>
          <Button variant="text" sx={checkMobile ? sxButtonMobile : sxButton}
            onClick={onCreatePoll}
            disabled={postTypeActive && postTypeActive !== EnumPostType.Poll}>
            <PollIcon color="success" />
            <Typography ml={1} fontWeight={600} noWrap>
              {__('home_voted')}
            </Typography>
          </Button>
        </Stack>

        {
          !checkMobile && (
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth
              onClick={handleSubmitForm}
              disabled={isSubmitting || !postPayload?.post_title || !postPayload?.post_content}
            >
              <Typography textTransform={'none'}>
                {actionText || __('home_btn_post')}
              </Typography>
            </Button>
          )
        }

      </Stack>
    </Box>
  )
})

export default ModalCreateEditPost
const sxButton: SxProps = {
  color: 'text.primary',
  textTransform: 'unset'
}

const sxButtonMobile: SxProps = {
  color: 'text.primary',
  textTransform: 'unset',
  padding: 0
}
