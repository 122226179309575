import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material'
import { AxiosError } from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import {ModalBody, ModalContainer, ModalFooter, ModalHeader} from 'components/Modal'
import __ from 'languages/index'
import {CloseIcon} from '../../../icons'
import TextFieldMUI from 'components/TextFieldMUI'
import {convertData, getStyles} from 'entities/shop/helper'
import {notEmpty, useField, useForm} from '@shopify/react-form'
import {useAppDispatch} from 'config/store'
import {createStock, updateStock} from 'entities/shop/store/shop.store.reducer'
import {validateLabel} from 'entities/support/utils'
import addressData from '../../challenge/media/diachinh.json';
import {TypeAddess, TypeDistrict, TypedStock, TypeWard} from 'entities/shop/interface'
import {EnumTypeToast, useToast} from '../../../hooks/useToast'
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll'
import { TypedDataMember } from '../../../interfaces/member.interface'
import { getListMembers } from 'entities/gift/services/gift.receiver.service'
import { InfiniteScrollContainer } from 'components/InfiniteScrollContainer'
import helpers from 'helpers/index'
import { AntSwitch } from './shop.admin.category'

interface TypeStockroomModal{
  handleCloseAddStockModal: ()=>void
  isShowAddStockroom: boolean
  isEdit: boolean
  channelId: string
  stockDetail: TypedStock
  setDataUpdate?: React.Dispatch<React.SetStateAction<TypedStock>>
}

const AddStockroomModal = ({handleCloseAddStockModal, isShowAddStockroom, stockDetail, isEdit, channelId, setDataUpdate}: TypeStockroomModal) =>{
  const [codeCity, setCodeCity] = React.useState<string>(stockDetail?.stock_address_city || '');
  const [codeDistrict, setCodeDistrict] = React.useState<string>(stockDetail?.stock_address_district || '');
  const [codeWard, setCodeWard] = React.useState<string>(stockDetail?.stock_address_ward || '');
  const [stockCity, setStockCity] = React.useState('');
  const [stockDistrict, setStockDistrict] = React.useState<string>('');
  const [stockWard, setStockWard] = React.useState<string>( '');
  const [inputStockOwner, setInputStockOwner] = React.useState<string>(stockDetail?.manager || '');
  const [inputSearchOwner, setInputSearchOwner] = React.useState<string>( '');
  const [stockOwner, setStockOwner] = React.useState<string | null>(stockDetail?.manager || null);
  const [isDefaultStock, setIsDefaultStock] = useState<number>(0)

  const [addresses, setAddresses] = React.useState<TypeAddess[]>([]);
  const [districtByCity, setDistrictByCity] = React.useState<TypeDistrict[]>([]);
  const [wardByDistrict, setWardByDistrict] = React.useState<TypeWard[]>([]);

  const dispatch = useAppDispatch();
  const toast = useToast()

  const stockDefaultCity = (addresses?.length>0 && isEdit) && addresses?.find((item)=>item?.code === stockDetail?.stock_address_city )
  const stockDefaultDistrict = (districtByCity?.length>0 && isEdit) && districtByCity?.find(item => item?.code === stockDetail?.stock_address_district)
  const stockDefaultWard = (wardByDistrict?.length>0 && isEdit) && wardByDistrict?.find(item =>     item?.code === stockDetail?.stock_address_ward)


  const { data, refresh, isRefreshing, hasMore, paramRequest, setParamRequest, loadMore, updateParamRequest, updateParamRequestDebounce } = useInfiniteScroll < TypedDataMember > ({
    limit: 20,
    order_by: "DESC",
    channel_id: channelId,
    search: ""
  }, getListMembers)


  useEffect(() => {
    setAddresses(convertData(addressData))
  }, [])

  useEffect(() => {
    const district = addresses?.filter(item => item?.code === codeCity).map(item => item['quan-huyen'])
    if(!!district){
      setDistrictByCity(convertData(district[0]))
    }
  }, [addresses, codeCity]);


  useEffect(()=>{
    const ward = districtByCity?.filter(item => item?.code === codeDistrict).map(item => item['xa-phuong'])
    if(!!ward){
      setWardByDistrict(convertData(ward[0]))
    }
  },[addresses,districtByCity,codeDistrict])

  useEffect(() => {
    if(isEdit){
      setStockCity(stockDefaultCity?.name || '')
      setStockWard(stockDefaultWard?.name || '')
      setStockDistrict(stockDefaultDistrict?.name || '')
    }

  }, [wardByDistrict, districtByCity, addresses])

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if(inputValue){
      updateParamRequestDebounce({ search: event.target.value });
    }
  };

  const useFields = {
    stock_name: useField<string>({
      value:  (isEdit && stockDetail?.stock_name) ? stockDetail?.stock_name : '',
      validates: [
        notEmpty(validateLabel(`${__("product_stockroom_add")}`)?.required),
        inputVal => {
          if (!inputVal.trim()) {
            return validateLabel(__('product_stockroom_add'))?.required

          }
        }
      ]
    }),
    stock_address: useField<string>({
      value: (isEdit && stockDetail?.stock_address) ? stockDetail?.stock_address : '',
      validates: [
        notEmpty(validateLabel(`${__("product_stock_address")}`)?.required),
        inputVal => {
          if (!inputVal.trim()) {
            return validateLabel(__('product_stock_address'))?.required
          }
          if (inputVal.trim().length < 3) {
            return `${__('product_stock_address_validate')}`
          }
        }
      ]
    }),

    stock_code: useField<string>({
      value: (isEdit && stockDetail?.stock_code) ? stockDetail?.stock_code : '',
      validates: [
        notEmpty(validateLabel(`${__("product_stock_code")}`)?.required),
        inputVal => {
          if (!inputVal.trim()) {
            return validateLabel(__('product_stock_code'))?.required

          }
        }
      ]
    }),

    stock_address_city: useField<string>({
      value: (isEdit && stockDetail?.stock_address_city) ? stockDetail?.stock_address_city : '',
      validates: [
        notEmpty(validateLabel(`${__("select_city")}`)?.required),
        inputVal => {
          if (!inputVal) {
            return validateLabel(__('select_city'))?.required

          }
        }
      ]
    }),
    stock_address_district: useField<string>({
      value:(isEdit && stockDetail?.stock_address_district) ? stockDetail?.stock_address_district : '',
      validates: [
        notEmpty(validateLabel(`${__("select_district")}`)?.required),
        inputVal => {
          if (!inputVal) {
            return validateLabel(__('select_district'))?.required

          }
        }
      ]
    }),
    stock_address_ward: useField<string>({
      value: (isEdit && stockDetail?.stock_address_ward) ? stockDetail?.stock_address_ward : '',
      validates: [
        notEmpty(validateLabel(`${__("select_ward")}`)?.required),
        inputVal => {
          if (!inputVal) {
            return validateLabel(__('select_ward'))?.required

          }
        }
      ]
    }),
    hotline: useField<string>({
      value: (isEdit && stockDetail?.hotline) ? stockDetail?.hotline : '',
      validates: [
        notEmpty(validateLabel(`${__("product_stock_hotline")}`)?.required),
        inputVal => {
          if (!inputVal) {
            return validateLabel(__('product_stock_hotline'))?.required
          }
          if (!helpers.isPhoneNumber(inputVal)) {
            return `${__('product_stock_format_hotline')}`
          }
        }
      ]
    }),

    manager: useField<string>({
      value: (isEdit &&stockDetail?.manager) ? stockDetail?.manager : '',
      validates: [
        notEmpty(validateLabel(`${__("product_stock_owner")}`)?.required),
        inputVal => {
          if (!inputVal) {
            return validateLabel(__('product_stock_owner'))?.required
          }
        }
      ]
    }),
    is_default_stock: useField<number>({
      value: (isEdit &&stockDetail?.is_default_stock) ? stockDetail?.is_default_stock : 0,
      validates: []
    })
  }


  const { fields, submit, submitting, dirty, reset, submitErrors,validate } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        const formData = {
          stock_name: values?.stock_name,
          stock_address: values?.stock_address,
          stock_code: values?.stock_code,
          stock_address_city: values?.stock_address_city,
          stock_address_district: values?.stock_address_district,
          stock_address_ward: values?.stock_address_ward,
          manager: values?.manager,
          hotline: values?.hotline.trim(),
          is_default_stock: values?.is_default_stock
        }

        if(isEdit){
          await  dispatch(updateStock({params : formData, _id : stockDetail.stock_id }))
            .unwrap()
            .then((res)=>{
              setDataUpdate(res?.data)
              reset()
              toast.show({
                content: `${__("edit_stockroom_success")}`,
                type: EnumTypeToast.Success
              })
            })
            .catch((e: AxiosError)=>{
              console.log('createStock', e);
              toast.show({
                content: `${__("edit_stockroom_failed")}`,
                type: EnumTypeToast.Error
              })
            })

        } else {
          await dispatch(createStock(formData))
            .unwrap()
            .then((res)=>{
              setDataUpdate(res?.data)
              reset()
              toast.show({
                content: `${__("add_stockroom_success")}`,
                type: EnumTypeToast.Success
              })
            })
            .catch((e: AxiosError)=>{
              console.log('createStock', e);
              toast.show({
                content: `${__("add_stockroom_failed")}`,
                type: EnumTypeToast.Error
              })
            })
        }

      } catch (e: any) {
        console.error(` createStockSubmit`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
      return { status: 'success' }
    }
  })


  useEffect(() => {
    useFields.stock_address_city.onChange(codeCity);
    useFields.stock_address_district.onChange(codeDistrict);
    useFields.stock_address_ward.onChange(codeWard);
    useFields.manager.onChange(stockOwner);
    useFields.is_default_stock.onChange(isDefaultStock);
  }, [ codeCity, codeDistrict, codeWard, stockOwner, isDefaultStock]);


  const handleChangeCity = (event: SelectChangeEvent<typeof stockCity>) => {
    const {
      target: { value },
    } = event;
    setStockCity(value)
    setStockWard('')
    setStockDistrict('')
  };

  const handleChangeWard = (event: SelectChangeEvent<typeof stockWard>) => {
    const {
      target: { value },
    } = event;
    setStockWard(value);
  };

  const handleChangeDistrict = (event: SelectChangeEvent<typeof stockDistrict>) => {
    const {
      target: { value },
    } = event;
    setStockDistrict(value)
    setStockWard('')
  };

  const handleSubmit = useCallback(async () => {
    await submit()
    if(!submitting && validate().length <= 0){
      handleCloseAddStockModal()
      reset()
    }
  },[])

  useEffect(() => {
    if (!isEdit) {
      reset()
    }

  }, [isEdit])

  return(
    <AddStockroomModalContainer open={isShowAddStockroom} onClose={handleCloseAddStockModal}>
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(115.125),
          margin: '0 auto'
        }}
      >
        <Box className="header">
          <ModalHeader direction={'row'}>
            <Typography variant='h6' textTransform='none' className="title">{isEdit ? `${__("product_stockroom_edit")}` : `${__('product_stockroom_add')}`}</Typography>
            <IconButton onClick={handleCloseAddStockModal}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider sx={{ opacity: 1 }} />
        </Box>
        <form onSubmit={submit}>
          <ModalBody className="body" sx={{paddingTop: theme => theme.spacing(0), paddingBottom: theme => theme.spacing(0) }}>
          <Box>
            <Stack>
              <TextFieldMUI
                {...fields.stock_name}
                maxLength={200}
                max={200}
                requiredIndicator
                placeholder={__('product_stock_name')}
              />
              <TextFieldMUI
                {...fields.stock_code}
                maxLength={50}
                max={50}
                requiredIndicator
                placeholder={__('product_stock_code')}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              display="grid"
              gridTemplateColumns="1fr 1fr"
              // justifyContent="space-between"
              // alignItems="start"
            >
              <Box >

                <Autocomplete
                  id="listStockOwner"
                  disablePortal
                  value={stockOwner}
                  inputValue={inputStockOwner}
                  defaultValue={stockDetail?.manager || ''}
                  options={data?.map((option) => option.display_name)}
                  onChange={(event: any, newValue: string | null) => {
                    setStockOwner(newValue)
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputStockOwner(newInputValue)
                  }}
                  onBlur={() => {
                    fields.manager?.runValidation(fields.manager.value)
                  }}

                  renderInput={(stockOwner) =>{
                    return(
                      <TextField
                        InputProps={{ ...stockOwner.InputProps, type: 'search' }}
                        required
                        {...stockOwner}
                        variant={'outlined'}
                        placeholder={__('product_stock_owner')}
                        value={stockOwner.inputProps.value}
                        onChange={handleInputChange}
                      />
                    )
                  }

                  }
                  // renderOption={(props, option, state)=>{
                  //   return (
                  //     <Box id="scrollableDiv" height={theme => `calc(100vh - ${theme.spacing(37.5)})`} overflow='auto'>
                  //       <InfiniteScrollContainer
                  //         isRefreshing={isRefreshing}
                  //         dataLength={data.length}
                  //         next={loadMore}
                  //         hasMore={hasMore}
                  //         refreshFunction={refresh}
                  //         scrollableTarget={"scrollableDiv"}
                  //       >
                  //         {data?.map((item, index: number)=>(
                  //           <Typography key={index} className="row ms-0 me-0" id="course_wrap">
                  //             {item?.display_name}
                  //           </Typography>
                  //         ))}
                  //
                  //       </InfiniteScrollContainer>
                  //     </Box>
                  //   )}}
                  sx={{ maxHeight: theme => theme.spacing(37.5) }}
                />
                {fields.manager?.error && (
                  <Typography sx={{paddingTop: theme => theme.spacing(0.25)}} variant="body2" color="error.main">{fields.manager?.error}</Typography>
                )}
              </Box>

              <Box>
                <TextFieldMUI
                  {...fields.hotline}
                  maxLength={11}
                  max={11}
                  requiredIndicator
                  placeholder={__('product_stock_hotline')}
                />
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              alignItems="start"
              display={'grid'}
              gridTemplateColumns={'1fr 1fr 1fr'}
              sx={{ marginBottom: theme => theme.spacing(2.5) }}
            >
                <FormControl sx={{ m: 1, mt: 3}}>
                  <Select
                    onBlur={() => {
                      fields.stock_address_city?.runValidation(fields.stock_address_city.value)
                    }}
                    MenuProps={{
                      elevation:1,
                      sx:{
                        maxHeight: theme => theme.spacing(37.5),
                      }

                    }}
                    displayEmpty
                    required
                    defaultValue={stockCity}
                    value={stockCity}
                    onChange={handleChangeCity}
                    input={<OutlinedInput  />}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <>{__("select_city")}</>;
                      }
                      return selected
                    }}
                    className='payment-modal-select'
                    sx={{
                      padding: theme => theme.spacing(0,2),
                      backgroundColor: theme => theme.palette.background.default,
                      borderRadius:  theme => theme.spacing(1.5),
                      width: '100%'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      {__("select_city")}
                    </MenuItem>
                    {addresses?.map((city) => (
                      <MenuItem
                        key={city?.code}
                        value={city?.name}
                        sx={theme => getStyles(city?.name, stockCity, theme)}
                        onClick={() => setCodeCity(city?.code)}
                      >
                        {city?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fields.stock_address_city?.error && (
                    <Typography sx={{paddingTop: theme => theme.spacing(0.25)}} variant="body2"  color="error.main">{fields.stock_address_city?.error}</Typography>
                  )}
                </FormControl>
                <FormControl sx={{ m: 1, mt: 3 }}>
                  <Select
                    MenuProps={{
                      elevation:1,
                      sx:{
                        maxHeight: theme => theme.spacing(37.5)
                      }
                    }}
                    onBlur={() => {
                      fields.stock_address_district?.runValidation(fields.stock_address_district.value)
                    }}
                    required
                    displayEmpty
                    value={stockDistrict}
                    defaultValue={stockDistrict}
                    onChange={handleChangeDistrict}
                    input={<OutlinedInput />}
                    sx={{
                      padding: theme => theme.spacing(0,2),
                      backgroundColor: theme => theme.palette.background.default,
                      borderRadius:  theme => theme.spacing(1.5)
                    }}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <>{__("select_district")}</>;
                      }
                      return selected
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <InputLabel id="select_district"></InputLabel>
                    <MenuItem value="">
                      {__("select_district")}
                    </MenuItem>
                    {districtByCity?.map((district) => (
                      <MenuItem
                        key={district?.code}
                        value={district?.name}
                        sx={theme => getStyles(district?.name, stockDistrict, theme)}
                        onClick={() => setCodeDistrict(district?.code)}
                      >
                        {district?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fields.stock_address_district?.error && (
                    <Typography sx={{paddingTop: theme => theme.spacing(0.25)}} variant="body2"  color="error.main">{fields.stock_address_district?.error}</Typography>
                  )}
                </FormControl>
                <FormControl sx={{ m: 1, mt: 3 }}>
                  <Select
                    MenuProps={{
                      elevation:1,
                      sx:{
                        maxHeight: theme => theme.spacing(37.5)
                      }
                    }}
                    onBlur={() => {
                      fields.stock_address_ward?.runValidation(fields.stock_address_ward.value)
                    }}
                    required
                    displayEmpty
                    value={stockWard}
                    defaultValue={stockWard}
                    onChange={handleChangeWard}
                    input={<OutlinedInput />}
                    sx={{
                      padding: theme => theme.spacing(0,2),
                      backgroundColor: theme => theme.palette.background.default,
                      borderRadius:  theme => theme.spacing(1.5)
                    }}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <>{__("select_ward")}</>;
                      }
                      return selected
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      {__("select_ward")}
                    </MenuItem>
                    {wardByDistrict?.map((ward) => (
                      <MenuItem
                        key={ward?.code}
                        value={ward?.name}
                        sx={theme => getStyles(ward?.name, stockWard, theme)}
                        onClick={()=>setCodeWard(ward?.code)}
                      >
                        {ward?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fields.stock_address_ward?.error && (
                    <Typography sx={{paddingTop: theme => theme.spacing(0.25)}} variant="body2"  color="error.main">{fields.stock_address_ward?.error}</Typography>
                  )}
                </FormControl>
              </Stack>
              <TextFieldMUI
                {...fields.stock_address}
                maxLength={200}
                max={200}
                requiredIndicator
                placeholder={__('product_stock_address')}
              />
          </Box>
        </ModalBody>
        </form>
        <ModalFooter
          sx={{
            flexDirection: "row",
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            paddingBottom: theme => theme.spacing(3)}}>
          <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
              {...fields.is_default_stock}
              onChange={(event)=>{
                if(event.target.checked){
                  setIsDefaultStock(1)
                }
              }}
              inputProps={{ 'aria-label': 'ant design' }}
              defaultChecked={Boolean(stockDetail?.is_default_stock ===1)}
            />
            <Typography>{__("product_stock_setting_default")}</Typography>
          </Stack>
          <Button
            type="submit"
            disabled={!dirty || submitting}
            onClick={handleSubmit}
          >
            <Typography textTransform={'none'} >
              {__("product_stock_btn_save")}
            </Typography>
          </Button>
        </ModalFooter>

      </ModalContainer>
    </AddStockroomModalContainer>
  )
}
export default AddStockroomModal
 const AddStockroomModalContainer = styled(Modal)(({theme})=>({
   '& .MuiAutocomplete-listbox':{
     maxHeight : '20vh'
   },
 }))
