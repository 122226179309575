import { z } from 'zod'

export const PaymentSchema = z.object({
  bank_account_name: z.string(),
  bank_account_number: z.string(),
  bank_brand_name: z.string(),
  bank_name: z.string()
})

export type PaymentPayload = z.infer<typeof PaymentSchema>
