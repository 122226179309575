//Library
import {memo, useEffect, useMemo, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck, faLayerGroup, faMedal} from '@fortawesome/free-solid-svg-icons'
import __ from "languages/index";

//Components
import CoursesProgress from './components/courses.progress'
import ComponentEmpty from 'components/componentEmpty'
import CoursesListSkeleton from './skeletons/courses.list.skeleton'
import _Helmet from 'components/helmet'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {getListCourseJoined} from './courses.store.reducer'

//Helper
import helpers from 'helpers'

//Hooks
import {useInfinite} from 'hooks/useInfinite'

//Constants
import {EnumPublicStatus} from 'entities/courses/media/constants'

//Interface
import {TypedCourse} from './courses.interface'

//Scss
import './media/courses.list.item.scss'
import SmallLayout from "../../layouts/smallLayout";
import BigLayout from "../../layouts/bigLayout";
import { formatString } from 'entities/support/utils'

const CourseJoined = () => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataCourse, setDataCourse] = useState<TypedCourse[]>([])
  const [totalDataCourse, setTotalDataCourse] = useState<number>(0)
  const [page, setPage] = useState<number>(1)


  const {ref} = useInfinite(dataCourse?.length < totalDataCourse, ()=>{
    setPage(page => page + 1)
  })

  const params= useMemo(()=> ({
    page: page,
    limit: 20,
    order_by: 'DESC'
  }),[page])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    params?.page < 2 && setIsLoading(true)
    dispatch(getListCourseJoined({ ...params }))
      .unwrap()
      .then(res => {
        if (res) {
          setIsLoading(false)
          setDataCourse(dataCourse => [...dataCourse, ...res?.data])
          setTotalDataCourse(parseInt(res?.headers['x-total-count'] || 20, 10))
        }
      })
      .catch(error => {
        console.log(error)
        setDataCourse([])
        setIsLoading(false)
      })
  }, [params])

  return (
    <BigLayout>
      <_Helmet title='course_joined_list' />
      <div id="courseJoined" className="mainFrame">
        <div className="container">
          <div>
            <h4>{__("course_joined_list_title")}</h4>
          </div>
          <div className="mt-4">
            {isLoading ? (
              <CoursesListSkeleton />
            ) : (
              <>
                {dataCourse?.length ? (
                  <div className="row" id="course_wrap">
                    {dataCourse?.map((data: TypedCourse, idx: number) => (
                      <div
                        key={`course_item_${idx}`}
                        className="courselistitem col-sm-6 col-lg-4 col-xl-3"
                        ref={ref}
                      >
                        <ComponentListCourseJoined course={data} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <ComponentEmpty text={formatString(__('data_empty'), `${__('course_joined').toLowerCase()}`)} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </BigLayout>
  )
}

export default CourseJoined

const ComponentListCourseJoined = memo(({ course }: { course: TypedCourse }) => {
  const navigate = useNavigate()
  const {
    _id,
    avatar,
    description,
    title,
    level_value,
    public_status,
    total_view,
    module_child_count,
    coin_value
  } = course
  const channel_data: any = useAppSelector(state => state.user.channel_data)


  const [percentComplete] = useState < number > (
    module_child_count == 0
      ? 0
      : Math.min(
        100,
        Math.max(0, Math.round((Number(total_view || 0) * 100) / Number(module_child_count || 1)))
      )
  )

  return (
    <>
      <_Helmet title='course_joined_list' />
      <div className="courselistitem_container">
        <div className="courselistitem_container_img">
          {avatar?.media_url ? (
            avatar?.media_type !== 'video' ? (
              <img
                className="courselistitem_img clickable"
                src={avatar?.media_url}
                alt=""
                onClick={() => navigate('/study/' + _id)}
              />
            ) : (
              <video
                className="courselistitem_img clickable"
                width={'auto'}
                disablePictureInPicture
                height={'auto'}
                controls
              >
                <source src={avatar?.media_url} type="video/mp4" />
              </video>
            )
          ) : (
            // <img className="courselistitem_img clickable" src={Logo} alt="" onClick={() => navigate('/study/' + _id)} />
            <img
              src={
                channel_data?.avatar?.media_thumbnail?.length > 0
                  ? channel_data?.avatar?.media_thumbnail
                  : channel_data?.avatar?.media_url
              }
              alt=""
              className="courselistitem_img clickable"
              onClick={() => navigate('/study/' + _id)}
            />
          )}
        </div>

        <div className="courselistitem_bottom d-flex flex-column justify-content-between">
          <div className="courselistitem_container_info">
            <Link to={'/study/' + _id}>
              <div className="courselistitem_title">{title}</div>
              <div className="courselistitem_description">{description}</div>
            </Link>
          </div>

          <div className="">
            {coin_value > 0 && (
              <div className="courselistitem_card_info_price">
                <span className="fs-6 fw-bold text-dark">{__("course_price")}</span>
                <span className="fs-6 fw-bold text-dark">
                  {`${helpers.convertToCommasFormat(coin_value || '0')} ${__("currency_unit")}`}
                </span>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faLayerGroup} className="courselistitem_img_layer_time" />
                <p className="courselistitem_txt_layer_time">{`${module_child_count} ${__("course_item_lesson")}`}</p>
              </div>
              {public_status === EnumPublicStatus.public ? (
                <p className="courselistitem_txt_layer_public">{__("course_public")}</p>
              ) : (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faMedal} className="courselistitem_img_layer_time" />
                  <p className="courselistitem_txt_layer_time">{`${__("course_item_LvL")} ${level_value} ${__("course_upto")}`}</p>
                </div>
              )}
            </div>

            <div className="mt-2">
              {percentComplete === 0 ? (
                //Not yet
                <>
                  <button
                    onClick={() => navigate('/study/' + _id)}
                    className="d-flex flex-column align-self-center align-items-center justify-content-center courselistitem_container_status courselistitem_btn shadow-lg btn-primary w-100 border-0 text-capitalize"
                  >
                    {__("course_btn_join")}
                  </button>
                </>
              ) : percentComplete === 100 ? (
                // Complete
                <div
                  onClick={() => navigate('/study/' + _id)}
                  className="d-flex align-items-center justify-content-center courselistitem_container_status clickable"
                >
                  <FontAwesomeIcon icon={faCircleCheck} className="courselistitem_img_status" />
                  <span className="courselistitem_txt_status done">{__("course_completed")}</span>
                </div>
              ) : (
                //In-progress
                <div
                  className="d-flex flex-column justify-content-center courselistitem_container_status clickable"
                  onClick={() => navigate('/study/' + _id)}
                >
                  <div className="d-flex justify-content-between">
                    <span className="courselistitem_txt_progress">{__("course_progress")}</span>
                    <span className="courselistitem_txt_progress percent">
                      {percentComplete.toFixed(0) + '%'}
                    </span>
                  </div>
                  <CoursesProgress
                    percentValue={percentComplete}
                    styleProgress={{ backgroundColor: '#a51c30' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
})
