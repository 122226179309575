import { TypeDataUserId } from 'entities/mentor/mentor.interface'
import { TypedProduct, TypedStock } from 'entities/shop/interface'
import { OrderStatus as StatusDelivery, PaymentStatus, TypeActionOrderList } from './data/order.enum'
export interface TypeDataMySubscription {
  cancel_at?: unknown | null
  coupon_code?: string
  createdAt?: string
  dataClient?: string
  end_at?: string
  expiryDate?: string
  is_auto_renew: true
  manual1?: string
  manual2?: string
  phone?: string
  plan_id?: {
    amount_of_coin?: number
    amount_of_day?: number
    country?: string
    createdAt?: string
    description?: string
    google_store_product_id?: string
    handle?: string
    image?: string
    name?: string
    note?: string
    options?: unknown | []
    price?: number
    service_id?: string
    status?: number
    trial_day?: number
    type?: string
    updatedAt?: string
    version?: string
    __v?: number
    _id?: string
  }
  qrCodeString?: string
  serial?: string
  service_id?: string
  service_name?: string
  start_at?: string
  status?: string
  updatedAt?: string
  user_id: TypeDataUserId
  __v?: number
  _id?: string
}
export interface TypeDataSubscription {
  listData: TypeDataMySubscription[]
  totalListData: number
}
export interface Product {
  _id: string
  image: string
  name: string
  postedBy: string
  price: number
  description: string
  amount_of_day: number
}

export interface User {
  display_name: string
  user_phone: string
  user_address: string
}
export interface Service {
  _id: string
  title: string
  avatar: Avatar
  description: string
}
export interface Avatar {
  media_url: string
}
export enum OrderStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  DONE = 'done',
  DENY = 'deny',
  CANCEL = 'cancel',
  SUCCESS = 'success',
  CLOSE = 'close'
}

export interface Order {
  _id?: string | number
  short_id?: string | number
  service_id?: Service
  plan_id?: Product
  user_id?: User
  price?: number
  coupon_price?: number
  payment_method?: string
  amount_of_package?: number
  vnpay_on?: string
  billing_on?: string
  cancelled_on?: string
  status?: OrderStatus
  createdAt?: string
  media_id?: Media
  redirect_url?: string
  product_url?: string
  trial_days?: number
  service_name?: string
  plan_type?: string
  orders_to_address?: OrderToAddress;
  order_pnr?: string;
  order_total_original_price?: number;
  orders_fullfillment?: OrderFullfillment[];
  order_status?: StatusDelivery;
  order_id?: string;
  order_delivery_status?: StatusDelivery;
  payment_status?: PaymentStatus;
  order_total_price?: number;
  order_total_fee?: number;
  orders_to_product?: OrderProduct[];
  order_delivery_type?: string;
  stock?: TypedStock,
  stock_id?: string
}

export interface OrderProduct {
  item_quantity?: number;
  order_id?: string;
  order_product_id?: string;
  product_id?: string;
  product_original_price?: number;
  product_price?: number;
  product?: TypedProduct;
  type?: TypeActionOrderList
}

export interface OrderFullfillment {

}

export interface OrderToAddress {
  ID?: string;
  order_id?: string;
  order_customer_address: string;
  order_customer_address_city: string;
  order_customer_address_district: string;
  order_customer_address_ward: string;
  order_customer_name: string;
  order_customer_phonenumber: string;
  customer_note: string;
}
export interface InfoChannel {
  _id: string | number
  bank_account_name: string
  bank_account_number: string
  bank_brand_name: string
  bank_name: string
  bank_qr_code: QrCode
  description: string
}
export interface Media {
  _id: string | number
  media_url: string
  media_thumbnail: string
  media_type: string
  media_file_name: string
}
export interface QrCode {
  media_url: string
}
