import React, { useEffect, useState } from 'react'
import '../styles/action.event.scss'
import HomeGift from '../../../media/lotties/home/homeGift.json'
import HomeLiveStream from '../../../media/lotties/home/homeLiveStream.json'
import HomeChallenger from '../../../media/lotties/home/homeChallenger.json'
import HomeCourse from '../../../media/lotties/home/homeCourse.json'
import HomeEvent from '../../../media/lotties/home/homeEvent.json'
import HomeMission from '../../../media/lotties/home/homeRedeem.json'
import Lottie from 'lottie-react'
import starImage from '../../../media/images/star_image.png'
import __ from 'languages/index'
import helpers from 'helpers'
import {useNavigate} from "react-router-dom";
import { Button, Typography } from '@mui/material'

export default function HomeActionEvent({ item, isHidePx = false }) {
  const [styleAction, setStyleAction] = useState(null)
  const [titleEvent, setTitleEvent] = useState('')
  let dataJson = helpers.isJson(item?.data_json) ? JSON.parse(item?.data_json) : {}
  const navigate = useNavigate();

  useEffect(() => {
    if (!item) return
    if (item) {
      switch (item?.data_json_type) {
        case 'gift':
          setStyleAction({
            background: 'linear-gradient(90deg, rgba(255,124,0,1) 0%, rgba(255,174,133,1) 100%)'
          })
          setTitleEvent(`${__('home_newbie_gift')}`)
          break
        case 'livestream':
          setStyleAction({
            background: 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,133,133,1) 100%)'
          })
          setTitleEvent(`${__('home_livestream')}`)
          break
        case 'challenge':
          setStyleAction({
            background: 'linear-gradient(90deg, rgba(0,99,255,1) 0%, rgba(99,149,255,1) 100%)'
          })
          setTitleEvent(`${__('home_challenge_yourself')}`)

          break
        case 'course':
          setStyleAction({
            background: 'linear-gradient(90deg, rgba(255,184,0,1) 0%, rgba(255,248,100,1) 100%)'
          })
          setTitleEvent(`${__('home_course_online')}`)

          break
        case 'event':
          setStyleAction({
            background: 'linear-gradient(90deg, rgba(23,0,138,1) 0%, rgba(100,142,255,1) 100%)'
          })
          setTitleEvent(`${__('home_upcoming_event')}`)

          break
        case 'redeem':
          setStyleAction({
            background: 'linear-gradient(90deg, rgba(21,138,0,1) 0%, rgba(105,255,100,1) 100%)'
          })
          setTitleEvent(`${__('home_mission_daily')}`)

          break
        default:
          setStyleAction({
            background: 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,133,133,1) 100%)'
          })
          setTitleEvent(`${__('home_event')}`)

          break
      }
    }
  }, [item])

  const goToScreen = (type: string) => {
    if (type == 'gift') {
      navigate('/gift');
      return;
    }
    if (type == 'livestream') {
      navigate(`/live-room/${dataJson?._id || ''}`)
      return;
    }
    if (type == 'challenge') {
      navigate('/challenge')
      return;
    }
    if (type == 'course') {
      navigate('/courses')
      return;
    }
    if (type == 'event') {
      navigate('/event')
      return;
    }
    if (type == 'redeem') {
      navigate('/missions')
      return;
    } else {
      navigate('/')
    }
  }

  return (
    <div className={isHidePx ? "" : "px-3"}>
      {item && (
        <div
          className="back_gradient d-flex justify-content-between overflow-hidden mt-3"
          style={styleAction}
        >
          <div style={{ zIndex: 9, position: 'relative' }}>
            <Typography variant='body2'>
              {titleEvent || ''}
            </Typography>
            <Typography className='title_home' color='background.paper' variant='subtitle2'>{dataJson?.title}</Typography>
            <Button onClick={() => goToScreen(item?.data_json_type)} sx={{zIndex: 9, borderRadius: theme=>theme.spacing(1.5)}}>
               <Typography textTransform={'none'} variant='body1'>
                {__("home_action_btn")}
               </Typography>
            </Button>
            <div className="position-relative">
              <div className="image_star">
                <img style={{ width: '300px', height: '300px' }} src={starImage} />
              </div>
              <div className="image_star">
                <img style={{ width: '300px', height: '300px' }} src={starImage} />
              </div>
            </div>
          </div>
          <div className="position-relative" style={{ zIndex: 5 }}>
            <div className="first_circle"></div>
            <div className="first_secondary"></div>
            <div className="animation_action_event">
              {item?.data_json_type === 'gift' && (
                <div>
                  <Lottie
                    animationData={HomeGift}
                    style={{ width: '100px', height: '100px' }}
                    autoPlay
                    loop
                  />
                </div>
              )}
              {item?.data_json_type === 'livestream' && (
                <div>
                  <Lottie
                    animationData={HomeLiveStream}
                    style={{ width: '100px', height: '100px' }}
                    autoPlay
                    loop
                  />
                </div>
              )}
              {item?.data_json_type === 'challenge' && (
                <div>
                  <Lottie
                    animationData={HomeChallenger}
                    style={{ width: '100px', height: '100px' }}
                    autoPlay
                    loop
                  />
                </div>
              )}
              {item?.data_json_type === 'course' && (
                <div>
                  <Lottie
                    animationData={HomeCourse}
                    style={{ width: '100px', height: '100px' }}
                    autoPlay
                    loop
                  />
                </div>
              )}
              {item?.data_json_type === 'event' && (
                <div>
                  <Lottie
                    animationData={HomeEvent}
                    style={{ width: '100px', height: '100px' }}
                    autoPlay
                    loop
                  />
                </div>
              )}
              {item?.data_json_type === 'redeem' && (
                <div>
                  <Lottie
                    animationData={HomeMission}
                    style={{ width: '100px', height: '100px' }}
                    autoPlay
                    loop
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
