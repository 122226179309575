import { SvgIcon, SvgIconProps } from '@mui/material'

const EssentialsIcon =(props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.660156 10C0.660156 4.93337 4.76016 0.833374 9.82682 0.833374C14.8935 0.833374 18.9935 4.93337 18.9935 10C18.9935 15.0667 14.8935 19.1667 9.82682 19.1667C4.76016 19.1667 0.660156 15.0667 0.660156 10ZM9.82682 5.83337C10.2852 5.83337 10.6602 6.20837 10.6602 6.66671V10.8334C10.6602 11.2917 10.2852 11.6667 9.82682 11.6667C9.36849 11.6667 8.99349 11.2917 8.99349 10.8334V6.66671C8.99349 6.20837 9.36849 5.83337 9.82682 5.83337ZM9.82682 12.5C10.2852 12.5 10.6602 12.875 10.6602 13.3334C10.6602 13.8 10.2852 14.175 9.82682 14.175C9.36849 14.175 8.99349 13.8 8.99349 13.3417C8.99349 12.875 9.36849 12.5 9.82682 12.5Z"
              fill="#DC3545" />
      </svg>
    </SvgIcon>
  )
}
export default EssentialsIcon
