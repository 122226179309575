import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import Table from '../components/Table'
import __ from 'languages/index'
import { StyledTableCell } from '../components/StyledTableCell'
import { TypeDataLevel } from 'entities/channelSetting/interface'
import { CloseIcon, GroupIcon, PlusIcon } from 'icons'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import EditLevelForm from './EditLevelForm'
import RowActions from '../components/RowActions'
import { getConfigsLevel } from '../service'
import { useAppSelector } from 'config/store'
import { getChannelId } from 'store/user.store.reducer'
import RenderView from 'components/RenderView'

const mock: Partial<TypeDataLevel>[] = [
  {
    level_number: 1,
    level_point: 10,
    title: 'khoi dong',
    total_member: 1000,
    _id: '1'
  }
]

const Level = () => {
  const [configsLevel, setConfigLevel] = useState<TypeDataLevel[]>([])
  const [isFetchingList, setIsFetchingList] = useState<boolean>(false)
  const [showEditForm, setShowEditForm] = useState<boolean>(false)
  const [selectedLevel, setSelectedLevel] = useState<TypeDataLevel>(null)
  const channelId = useAppSelector(getChannelId)

  const closeForm = () => {
    setShowEditForm(false)
    setSelectedLevel(null)
  }

  const openForm = () => {
    setShowEditForm(true)
  }

  const handleDeleteRow = () => {}
  useEffect(() => {
    const getListLevel = async () => {
      try {
        setIsFetchingList(true)
        const response = await getConfigsLevel({ channel_id: channelId })
        setConfigLevel(response.data)
      } catch (err) {
      } finally {
        setIsFetchingList(false)
      }
    }
    getListLevel()
  }, [])

  const header = () => {
    return (
      <TableRow>
        <StyledTableCell>Cap do</StyledTableCell>
        <StyledTableCell>Ten cap do</StyledTableCell>
        <StyledTableCell>Diem cap do</StyledTableCell>
        <StyledTableCell>Thanh vien</StyledTableCell>
        <StyledTableCell>Thao tac</StyledTableCell>
      </TableRow>
    )
  }

  const renderBodyRow = (item: TypeDataLevel & { hideDeleteBtn: boolean }) => {
    return (
      <>
        <TableCell>{item.level_number}</TableCell>
        <TableCell>{item.title}</TableCell>
        <TableCell>
          <Box
            sx={{
              padding: theme => theme.spacing(0.25, 1),
              borderRadius: theme => theme.spacing(1.5),
              bgcolor: theme => theme.palette.background.default,
              width: 'fit-content'
            }}
          >
            <Typography
              sx={{
                color: theme => theme.palette.primary.main,
                fontWeight: 600
              }}
            >
              {`${item.level_point} ${__('point')}`}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" color={'text.secondary'} gap={0.5}>
            <GroupIcon fontSize={'small'} />
            <Typography color="text.secondary">{item.total_member}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <RowActions
            onClickEditBtn={() => setSelectedLevel(item)}
            onClickDeleteBtn={!item.hideDeleteBtn && handleDeleteRow}
          />
        </TableCell>
      </>
    )
  }

  const rowsFormatter = (listLevel: TypeDataLevel[]) => {
    return listLevel.map((level, index) => ({
      ...level,
      hideDeleteBtn: index < listLevel.length - 1
    }))
  }

  return (
    <Stack gap={2}>
      <ChannelSettingsTitle>{__('channel_setting_data_gamifications')}</ChannelSettingsTitle>
      <Typography>
        Làm cho nhóm của bạn vui vẻ! Cá nhân hóa nhóm của bạn bằng cách đặt tên cho cấp độ của bạn.
        Khuyến khích sự tham gia bằng cách mở khóa các khóa học khi các thành viên đạt cấp độ cao
        hơn.
      </Typography>
      <RenderView
        view={
          <Table
            data={rowsFormatter(configsLevel)}
            renderTableRow={renderBodyRow}
            renderHeader={header}
          />
        }
        isLoading={isFetchingList}
      />

      <Button sx={{ mr: 'auto' }} startIcon={<PlusIcon />} onClick={openForm}>
        {__('channel_setting_add_level')}
      </Button>
      <Modal open={showEditForm || selectedLevel !== null} onClose={closeForm}>
        <ModalContainer
          sx={{
            width: '80%',
            maxWidth: '500px'
          }}
        >
          <ModalHeader direction="row">
            <Typography className="title">Sua</Typography>
            <IconButton onClick={closeForm}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <ModalBody>
            <EditLevelForm
              level={selectedLevel}
              onSuccess={closeForm}
              initialLevelPoint={configsLevel?.length}
            />
          </ModalBody>
        </ModalContainer>
      </Modal>
    </Stack>
  )
}

export default Level
