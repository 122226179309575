import {SvgIcon, SvgIconProps} from '@mui/material'

const CheckEssentialsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.2559 5.24408C17.5814 5.56951 17.5814 6.09715 17.2559 6.42259L8.38388 15.2946C7.89573 15.7828 7.10427 15.7828 6.61612 15.2946L2.74408 11.4226C2.41864 11.0972 2.41864 10.5695 2.74408 10.2441C3.06951 9.91864 3.59715 9.91864 3.92259 10.2441L7.5 13.8215L16.0774 5.24408C16.4028 4.91864 16.9305 4.91864 17.2559 5.24408Z" fill="white"/>
      </svg>
    </SvgIcon>
  )
}

export default CheckEssentialsIcon
