import { SvgIcon, SvgIconProps } from "@mui/material";

export default function getBackgroundLogin({ primaryColor }) {
    return (
        ` <svg viewBox="0 0 1920 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_114_3989)">
            <rect fill="white" />
            <g clip-path="url(#clip1_114_3989)">
            <mask id="mask0_114_3989" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="-107" width="1920" height="1238">
              <rect y="-107" width="1920" height="1238" fill="url(#paint0_linear_114_3989)"/>
            </mask>
                <g mask="url(#mask0_114_3989)">
                    <rect y="-107" width="1920" height="1238" fill="url(#paint1_linear_114_3989)" />
                    <path d="M1790.74 1587.32C1740.17 1424.25 1564.01 1366.08 1547.34 1360.86H1288.83C1146.56 1518.61 940.626 1617.82 711.517 1617.82C282.245 1617.82 -65.6792 1269.8 -65.6792 840.616C-65.6792 784.369 -59.5416 729.678 -48.2739 676.82C-102.505 886.695 -97.5586 1122.77 -49.9228 1313.04C122.482 2001.94 889.876 2251.66 943.558 2268.24C850.943 2283.54 675.057 2303.42 461.704 2267.88C518.592 2277.86 576.488 2284.92 635.391 2287.94C662.049 2289.31 688.615 2289.95 714.998 2289.86C724.891 2289.86 734.693 2289.31 744.587 2289.13C760.343 2288.76 776.1 2288.49 791.765 2287.66C805.506 2286.93 819.155 2285.65 832.805 2284.55C844.256 2283.63 855.798 2282.81 867.157 2281.53C882.547 2279.88 897.846 2277.77 913.144 2275.66C922.488 2274.38 931.832 2273.1 941.084 2271.63C957.574 2268.98 973.88 2266.04 990.186 2262.84C997.881 2261.37 1005.58 2259.81 1013.18 2258.26C1030.58 2254.59 1047.9 2250.56 1065.03 2246.26C1071.08 2244.79 1077.03 2243.23 1082.98 2241.58C1101.49 2236.73 1119.81 2231.6 1137.95 2226.01C1141.89 2224.82 1145.83 2223.54 1149.77 2222.26C1169.74 2215.93 1189.52 2209.34 1209.13 2202.19C1210.04 2201.83 1211.05 2201.46 1211.97 2201.09C1409.75 2128.36 1587.19 2013.75 1733.67 1868.01C1797.06 1785.01 1820.33 1682.68 1790.74 1587.32Z" fill="url(#paint2_linear_114_3989)" />
                    <path opacity="0.2" d="M605.484 70.4937C322.915 80.0183 97.5011 188.819 72.8622 201.366C-349.938 416.677 -607.868 902.158 -605.029 1444.69C-414.237 1856.18 -35.6773 2165.45 419.181 2259.32C427.516 2260.97 435.851 2262.71 444.003 2264.18C449.499 2265.19 455.086 2266.1 460.673 2267.11C673.997 2302.64 849.858 2282.77 942.46 2267.47C888.786 2250.9 121.499 2001.24 -50.882 1312.54C-98.5111 1122.41 -103.457 886.314 -49.2333 676.497C18.7298 359.529 280.141 114.453 605.484 70.4937Z" fill="url(#paint3_linear_114_3989)" />
                    <path opacity="0.4" d="M1343.86 360.999C1280.53 304.029 1276.59 217.363 1230.92 136.447C1182.74 33.9365 1135.3 -43.6565 1118.54 -69.3987C981.801 -280.008 691.381 -414.49 438.97 -452.142C230.245 -483.289 84.8982 -443.714 60.6279 -453.608C-91.405 -376.931 -228.052 -274.328 -343.633 -151.48C-371.933 -120.975 -398.951 -89.7359 -424.412 -58.0391C-427.709 -53.9167 -430.915 -49.7943 -434.12 -45.5803C-440.44 -37.5187 -446.759 -29.3655 -452.895 -21.2123C-562.341 126.462 -644.585 295.755 -691.843 479.157C-692.21 480.714 -692.576 482.272 -693.034 483.829C-698.346 504.808 -703.2 525.969 -707.688 547.314C-708.512 551.162 -709.153 555.009 -709.977 558.857C-713.824 578.095 -717.304 597.516 -720.327 617.029C-721.334 623.258 -722.067 629.488 -722.983 635.717C-725.455 653.123 -727.745 670.529 -729.668 688.118C-730.584 696.82 -731.225 705.615 -732.05 714.318C-733.423 729.617 -734.706 744.915 -735.53 760.397C-735.53 760.58 -735.53 760.855 -735.53 761.039C-735.805 765.619 -735.805 770.291 -736.079 774.872C-742.216 1000.32 -696.24 1196.09 -655.942 1322.42C-641.471 1363.46 -625.26 1403.77 -607.218 1443.07C-606.302 1445.08 -605.295 1447.01 -604.379 1449.02C-607.218 905.506 -349.22 419.061 73.4499 203.413C98.0865 190.863 323.48 81.8481 606.023 72.2291C611.152 71.4963 616.189 70.9466 621.318 70.3053C617.013 70.855 612.617 71.4962 608.13 72.1375C612.892 71.9543 617.746 71.8627 622.509 71.7711C780.678 68.6563 955.516 97.2384 1122.66 191.596C1156.91 210.925 1187.96 231.538 1216.44 252.608C1253.9 280.274 1286.32 308.581 1314.35 335.789C1314.81 336.247 1316.73 338.262 1319.48 340.919C1329.1 349.988 1338.28 356.694 1343.86 360.999Z" fill="url(#paint4_linear_114_3989)" />
                    <path d="M1861.07 -32.3337C1845.68 -57.2252 1828.38 -79.9203 1813.09 -98.4059C1794.96 -120.277 1779.67 -136.109 1773.62 -142.332C1706.42 -211.058 1648.46 -268.436 1543.71 -341.555C1307.11 -506.918 1018.13 -604.471 705.721 -604.471C588.336 -604.471 474.432 -590.378 365.105 -564.48C256.694 -538.765 153.045 -501.061 55.7129 -452.926C79.9772 -443.225 225.288 -482.118 433.961 -451.462C686.309 -414.49 976.565 -282.529 1113.36 -75.8022C1130.02 -50.5447 1177.55 25.5938 1225.71 126.166C1231.02 137.331 1235.87 147.58 1240.18 157.189C1247.78 174.027 1254.09 188.669 1259.31 201.481C1270.3 228.386 1276.89 247.512 1282.48 263.252C1287.79 278.077 1292.28 289.699 1298.78 301.687C1305.28 313.676 1311.5 321.82 1317.36 329.599C1320.75 334.083 1329.36 345.339 1342.09 358.974C1342.27 359.157 1342.45 359.34 1342.63 359.523C1401.88 414.339 1481.63 448.016 1569.44 448.016C1752.38 448.016 1900.71 302.145 1900.71 122.323C1900.71 66.317 1886.34 13.6971 1861.07 -32.3337Z" fill="url(#paint5_linear_114_3989)" />
                    <path d="M1587.58 799.8C1587.12 799.8 1579.7 800.075 1569.36 800.258C1548.94 800.532 1532.55 800.166 1522.11 799.8H1486.4H1409.3H799.115H796.642C796.551 799.8 796.551 799.8 796.459 799.891C643.088 801.265 519.109 926.085 519.109 1079.75C519.109 1233.51 642.997 1358.24 796.459 1359.61C797.375 1359.61 798.199 1359.71 799.023 1359.71H1125.18H1163.08H1185.98H1265.36H1287.52H1361.6H1522.02C1525.13 1359.71 1529.53 1359.89 1534.84 1360.25C1545 1362.27 1555.44 1364.83 1566.15 1368.13C1572.2 1370.05 1577.96 1372.07 1583.73 1374.27C1629.42 1394.5 1705.33 1437.27 1754.78 1513.01C1768.6 1535.44 1778.58 1556.41 1784.81 1572.99C1786.37 1577.29 1787.83 1581.6 1789.21 1585.99C1790.3 1589.66 1791.22 1593.32 1792.14 1596.89C1798.45 1622.26 1800.56 1646.07 1801.29 1658.71C1801.38 1661.91 1801.66 1665.12 1801.66 1668.32C1801.66 1675.56 1801.47 1682.79 1801.02 1689.93C1796.71 1752.11 1772.63 1813.29 1731.89 1866.59C1740.95 1857.52 1753.4 1844.7 1767.96 1829.04C1831.14 1760.91 1874.54 1701.38 1896.89 1669.42C1939.92 1607.7 1967.57 1557.88 1975.54 1543.23C2005.94 1487.73 2023.15 1450.37 2040.55 1398.08C2040.55 1398.08 2058.04 1332.87 2058.04 1270.51C2058.31 1010.61 1847.53 799.8 1587.58 799.8Z" fill="url(#paint6_linear_114_3989)" fill-opacity="0.8" />
                </g>
            </g>
        </g>
        <defs>
            <linearGradient id="paint0_linear_114_3989" x1="0" y1="1131" x2="1898.23" y2="1131" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A51C30" />
                <stop offset="1" stop-color="${primaryColor}" />
            </linearGradient>
            <linearGradient id="paint1_linear_114_3989" x1="0" y1="1131" x2="1898.23" y2="1131" gradientUnits="userSpaceOnUse">
                <stop stop-color="${primaryColor}" />
                <stop   stop-opacity="0.5" stop-color="${primaryColor}" />
                <stop offset="1" stop-color="${primaryColor}" />
                <stop offset="1" stop-color="${primaryColor}" />
            </linearGradient>
            <linearGradient id="paint2_linear_114_3989" x1="-87.3086" y1="2289.87" x2="1782.02" y2="2289.87" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A51C30" />
                <stop offset="1" stop-color="${primaryColor}" />
            </linearGradient>
            <linearGradient id="paint3_linear_114_3989" x1="-605.052" y1="2287.01" x2="924.911" y2="2287.01" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EF5264" />
                <stop offset="1" stop-color="${primaryColor}" />
                <stop offset="1" stop-color="${primaryColor}" />
            </linearGradient>
            <linearGradient id="paint4_linear_114_3989" x1="164" y1="1449" x2="1319.34" y2="1449.03" gradientUnits="userSpaceOnUse">
                <stop stop-color="${primaryColor}" stop-opacity="0.2" />
                <stop offset="1" stop-color="${primaryColor}" stop-opacity="0.2" />
            </linearGradient>
            <linearGradient id="paint5_linear_114_3989" x1="1216" y1="-136" x2="1539.71" y2="420.64" gradientUnits="userSpaceOnUse">
                <stop offset="0.272966" 
                stop-opacity="0.9"
                stop-color="${primaryColor}" />
                <stop offset="1" 
                stop-opacity="0.7"
                stop-color="${primaryColor}" />
            </linearGradient>
            <linearGradient id="paint6_linear_114_3989" x1="1319.41" y1="1260.79" x2="1319.41" y2="710.834" gradientUnits="userSpaceOnUse">
                <stop stop-opacity="0.2" stop-color="${primaryColor}" />
                <stop stop-opacity="0.3" offset="1" stop-color="${primaryColor}" />
            </linearGradient>
            <clipPath id="clip0_114_3989">
                <rect width="1920" height="1024" fill="white" />
            </clipPath>
            <clipPath id="clip1_114_3989">
                <rect width="1920" height="1238" fill="white" transform="translate(0 -107)" />
            </clipPath>
        </defs>
    </svg>`
    )

}