import { z } from 'zod'
import { invalid } from 'entities/support/utils'
import __ from 'languages/index'

export const BasicInfoSchema = z.object({
  name: z
    .string({
      required_error: invalid('required', __('label_channel_name'))
    })
    .min(1, invalid('required', __('label_channel_name'))),
  sub_domain: z
    .string()
    .min(1, invalid('required', __('label_channel_sub_domain')))
    .refine((value) => /^[a-z0-9.-]+$/.test(value), {
      message: invalid('pattern',  __('label_channel_sub_domain'))
    }),
  slogan: z.string().min(1, invalid('required', __('label_channel_slogan'))),
  description: z.string().min(1, invalid('required', __('label_channel_description')))
})

export type BasicInfoPayload = z.infer<typeof BasicInfoSchema>
