import {Order} from 'entities/orders/order.type'
import {createAsyncThunk, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit'
import {IQueryParams, createEntitySlice, serializeAxiosError} from 'config/reducer.utils'
import helpers from 'helpers/index'
import axios from 'axios'
import {
  TypedCategory, TypedCustomer,
  TypedLogistic,
  TypedParamProductCollection, TypedPayloadtCollection,
  TypedProduct,
  TypedProductCollection,
  TypedProductStock,
  TypedStock
} from 'entities/shop/interface'
import { useSelector } from 'react-redux'
import { IRootState } from 'config/store'
import { CreateProductCollectionPayload } from 'entities/shop/service/service.type'

export const initialState = {
  entity: {} as Order,
  entities: [] as Order[],
  errorMessage: null as unknown as string,
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false
}

const apiUrl = 'v2'

export const createStock = createAsyncThunk(
  'stock/create',
  async ( params: TypedStock, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.post<any>(`${apiUrl}/stock`, params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getSTockes = createAsyncThunk(
  'stock/get',
  async (_params: IQueryParams) => {
    const EndURL = helpers.buildEndUrl(_params)
    return axios.get<TypedStock[]>(`${apiUrl}/stock${EndURL}`)
  },
  { serializeError: serializeAxiosError }
)

export const updateStock = createAsyncThunk(
  'stock/update_stock',
  async ({params,_id} : {params: TypedStock, _id:string}, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.patch<any>(`${apiUrl}/stock/${_id}`, params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getStockById = createAsyncThunk(
  'stock/stock_by_id',
  async (stock_id:string, { rejectWithValue }) => {
    try {
      return axios.get<TypedStock>(`${apiUrl}/stock/${stock_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getProductById = createAsyncThunk(
  'stock/product_by_id',
  async (stock_id:string, { rejectWithValue }) => {
    try {
      return axios.get<TypedProduct>(`${apiUrl}/product/${stock_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const addProductToStock = createAsyncThunk(
  'stock/update_product_stock',
  async (params: TypedProductStock, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.post<TypedProduct>(`${apiUrl}/product_to_stock`, params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateProductToStock = createAsyncThunk(
  'stock/update_product_stock',
  async (params: TypedProductStock, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.patch<TypedProduct>(`${apiUrl}/product_to_stock`, params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)


export const deleteStock = createAsyncThunk(
  'stock/delete_stock',
  async (_id: string, { rejectWithValue }) => {
    try {
      return await axios.delete<any>(`${apiUrl}/stock/${_id}`);
    } catch (error) {
      return rejectWithValue(error)
    }
}, { serializeError: serializeAxiosError });

export const createConnectLogistic = createAsyncThunk(
  'shop/create_logitic',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>(`${apiUrl}/logistic_service`, body)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updateConnectLogistic = createAsyncThunk(
  'shop/update_logitic',
  async (body: any, { rejectWithValue }) => {
    try {
      const {logistic_id, logistic_count, createdAt,...rest} = body;
      const response = await axios.put<any>(`${apiUrl}/logistic_service/${logistic_id}`, rest)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getLogistices = createAsyncThunk(
  'shop/get_logitices',
  async () => {
      return axios.get<TypedLogistic[]>(`${apiUrl}/logistic_service`)
  },
  { serializeError: serializeAxiosError }
)

export const getCategoryById = createAsyncThunk(
  'category/category_by_id',
  async (category_id:string, { rejectWithValue }) => {
    try {
      return axios.get<TypedCategory>(`${apiUrl}/product_category/${category_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)


export const getCollectionById = createAsyncThunk(
  'collection/collection_by_id',
  async (collection_id:string, { rejectWithValue }) => {
    try {
      return axios.get<TypedProductCollection>(`${apiUrl}/product_collection/${collection_id}`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const createCollection = createAsyncThunk(
  'collection/create',
  async ( params: CreateProductCollectionPayload, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.post<TypedProductCollection>(`${apiUrl}/product_collection`, params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateCollection = createAsyncThunk(
  'collection/update_collection',
  async ({params,collection_id} : {params: CreateProductCollectionPayload, collection_id:string}, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.patch<TypedProductCollection>(`${apiUrl}/product_collection/${collection_id}`, params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateProductToCollection = createAsyncThunk(
  'collection/update_product_collection',
  async (params: TypedParamProductCollection, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.patch<TypedProduct>(`${apiUrl}/product/${params?.product_id}`, { collection_id :params?.collection_id }, )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const deleteProductCollection = createAsyncThunk(
  'collection/delete_product_collection',
  async (params: TypedPayloadtCollection, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.delete<TypedProduct>(`${apiUrl}/product_to_collection`, {data: params} )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const addCustomers = createAsyncThunk(
  'customer/create',
  async ( params: object, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.post<TypedCustomer[]>(`${apiUrl}/my_customer_address_book`, params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateCustomers = createAsyncThunk(
  'customer/update_customer',
  async ({params,address_id} : {params: object, address_id:string}, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return axios.patch<TypedCustomer>(`${apiUrl}/my_customer_address_book/${address_id}`,params)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const deleteCustomer = createAsyncThunk(
  'customer/delete_customer',
  async (address_id: string, { rejectWithValue }) => {
    try {
      return await axios.delete<any>(`${apiUrl}/my_customer_address_book/${address_id}`);
    } catch (error) {
      return rejectWithValue(error)
    }
  }, { serializeError: serializeAxiosError });

const SHOP_REDUCER = createEntitySlice({
  name: 'shop',
  initialState,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => ({ ...state, ...initialState }),
    clearEntity: state => {
      state.entity = null
    }
  },
  extraReducers(builder) {
    builder

      .addCase(updateStock.pending, state => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(updateStock.rejected, (state, action) => {
        state.updating = false
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addMatcher(isPending( createStock), state => {
        state.loading = true
        state.entity = null
      })
      .addMatcher(isRejected( createStock), (state, action) => {
        state.loading = false
        // @ts-ignore
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled( createStock), (state, action) => {
        state.loading = false
        // @ts-ignore
        state.entity = action.payload.data
      })
  }
})
export const { clearError, clearEntity, reset } = SHOP_REDUCER.actions
export default SHOP_REDUCER.reducer
