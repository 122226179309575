import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import './media/gift.update.scss'
import {Form, FormControl, FormLabel, Row} from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import {useAppDispatch, useAppSelector} from 'config/store'
import {useNavigate, useParams} from 'react-router-dom'
import DragDropUploadMedia from 'entities/courses/components/dragDropUploadMedia'
import {EnumGiftConditionType, GIFT_CONDITIONS} from 'entities/gift/constants/gift.constant'
import {clearEntity, createGift, getEntity, getMediaDefault, reset, updateGift} from 'entities/gift/gift.store.reducer'
import {isEqual} from 'lodash'
import {ConfirmModal} from 'components/modalConfirm'
import {uploadFile} from 'entities/courses/services/courses.media.service'
import {
  faFile,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileZipper,
  faImage
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {TypedMedia} from './gift.interface'
import LoadingButton from 'components/LoadingButton'
import helpers from 'helpers/index'
import Image from 'react-bootstrap/Image'
import Title from 'components/Title'
import TypedChannel from 'interfaces/channel.interface'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import BigLayout from "../../layouts/bigLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";
import { Box, Button, Typography } from '@mui/material'

export const getMimeFile = (mime: string) => {
  if (!mime) return
  const splitMime = mime.split('/')
  if (splitMime.includes('image')) return 'image'
  return splitMime[1]
}

const docsFiles = ['docs', 'doc']
const excelFiles = ['xls', 'xlsx']
const pdfFiles = ['pdf']
const pptFiles = ['ppt', 'pptx']
const zipFiles = ['zip']

export const getIconFile = (mimeType: string) => {
  let icon = faFile
  if (mimeType === 'image') {
    icon = faImage
  }
  if (pdfFiles.includes(mimeType)) icon = faFilePdf
  if (pptFiles.includes(mimeType)) icon = faFilePowerpoint
  if (excelFiles.includes(mimeType)) icon = faFileExcel
  if (zipFiles.includes(mimeType)) icon = faFileZipper

  return <FontAwesomeIcon icon={icon} />
}

export default function GiftUpdate() {
  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)

  let { giftId } = useParams() || {}
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const entity = useAppSelector(state => state.gift.entity)
  const mediaDefault = useAppSelector(state => state.gift.mediaDefault)
  const permission_data = useAppSelector(state => state.user.permission_data)
  const toast = useToast()
  const [urlGiftImg, setUrlGiftImg] = useState(entity?.media_id?.media_url)
  const [thumbnailDigitalGift, setThumbnailDigitalGift] = useState(entity?.gift_digital_media)

  const user_data = useAppSelector(state => state.user.user_data)
  const [errorImage, setErrorImage] = useState('')
  const [errorDigitalGift, setErrorDigitalGift] = useState<string | null>(null)
  const [isDigitalGift, setIsDigitalGift] = useState<boolean>(true)
  const [digitalGiftFiles, setDigitalGiftFiles] = useState<TypedMedia[]>([])
  const [uploadingDigitalGift, setUploadingDigitalGift] = useState(false)
  const [isCheckSwitchItem, setIsCheckSwitchItem] = useState(false)
  const [errorSwitchText, setErrorSwitchText] = useState<string | null>(null)

  const refConfirmModal = useRef<any>()
  const digitalInputRef = useRef(null)

  const refValueDefaultGift = useRef({
    name: giftId ? entity?.name || '' : '',
    description: giftId ? entity?.description || '' : '',
    media_id: giftId
      ? entity?.media_id?._id || mediaDefault?.media_id?._id
      : mediaDefault?.media_id?._id,
    gift_digital_media: giftId ? entity?.gift_digital_media?._id || '' : '',
    gift_digital_url: giftId ? entity?.gift_digital_url || '' : '',
    price: giftId ? entity?.price || '0' : '0',
    gift_conditions: giftId ? entity?.gift_conditions || {} : {},
    stock_qty: giftId ? `${entity?.stock_qty}` || '1000' : '1000'
  })

  const isUpdating = Boolean(giftId)
  const refValueToUpdateGift = useRef({
    name: giftId ? entity?.name || '' : '',
    description: giftId ? entity?.description || '' : '',
    media_id: giftId
      ? entity?.media_id?._id || mediaDefault?.media_id?._id
      : mediaDefault?.media_id?._id,
    gift_digital_media: giftId ? entity?.gift_digital_media || '' : '',
    gift_digital_url: isUpdating ? entity?.gift_digital_url || '' : '',
    price: giftId ? entity?.price || '0' : '0',
    gift_conditions: giftId ? entity?.gift_conditions || {} : {},
    stock_qty: giftId ? `${entity?.stock_qty}` || '1000' : '1000'
  })

  useEffect(() => {
    refValueDefaultGift.current.media_id = mediaDefault?.media_id?._id
    refValueToUpdateGift.current.media_id = mediaDefault?.media_id?._id
  }, [mediaDefault])

  const refConditionsEnable = useRef({
    [EnumGiftConditionType.Birth]: giftId
      ? entity?.gift_conditions[EnumGiftConditionType.Birth] !== undefined
      : false,
    [EnumGiftConditionType.Level]: giftId
      ? entity?.gift_conditions[EnumGiftConditionType.Level] !== undefined
      : false,
    [EnumGiftConditionType.Like]: giftId
      ? entity?.gift_conditions[EnumGiftConditionType.Like] !== undefined
      : false,
    [EnumGiftConditionType.Comment]: giftId
      ? entity?.gift_conditions[EnumGiftConditionType.Comment] !== undefined
      : false,
    [EnumGiftConditionType.Course]: giftId
      ? entity?.gift_conditions[EnumGiftConditionType.Course] !== undefined
      : false,
    [EnumGiftConditionType.Point]: giftId
      ? entity?.gift_conditions[EnumGiftConditionType.Point] !== undefined
      : false
  })

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [validate, setValidate] = useState(false)
  // ===============================

  useLayoutEffect(() => {
    if (giftId) {
      dispatch(
        getEntity({
          _id: giftId,
          auth_id: user_data?._id
        })
      )
    }
    return () => {
      dispatch(clearEntity())
    }
  }, [])

  useEffect(()=> {
    if(isUpdating) return
    dispatch(getMediaDefault({
      type: 'gift'
    }))

    return () => {
      dispatch(reset())
    }

  },[isUpdating])

  useEffect(() => {
    if(helpers.isEmpty(mediaDefault) || isUpdating ) return
    setUrlGiftImg(mediaDefault?.media_url)
    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      media_id: mediaDefault?._id
    }
  },[mediaDefault, isUpdating])

  useEffect(() => {
    if(permission_data){
      if (!helpers.current_user_can("boss", permission_data)) {
        navigate('/gift')
      }
    }

  }, [permission_data])

  useEffect(() => {
    if (entity) {
      refValueDefaultGift.current = {
        name: entity?.name,
        description: entity?.description,
        media_id: entity?.media_id?._id,
        gift_digital_media: entity?.media_id?._id,
        gift_digital_url: entity?.gift_digital_url,
        price: entity?.price,
        gift_conditions: entity?.gift_conditions,
        stock_qty: entity?.stock_qty
      }

      refValueToUpdateGift.current = {
        name: entity?.name,
        description: entity?.description,
        media_id: entity?.media_id?._id,
        gift_digital_media: entity?.media_id?._id,
        gift_digital_url: entity?.gift_digital_url,
        price: entity?.price,
        gift_conditions: entity?.gift_conditions,
        stock_qty: entity?.stock_qty
      }

      refConditionsEnable.current = {
        [EnumGiftConditionType.Birth]:
          entity?.gift_conditions[EnumGiftConditionType.Birth] !== undefined,
        [EnumGiftConditionType.Level]:
          entity?.gift_conditions[EnumGiftConditionType.Level] !== undefined,
        [EnumGiftConditionType.Like]:
          entity?.gift_conditions[EnumGiftConditionType.Like] !== undefined,
        [EnumGiftConditionType.Comment]:
          entity?.gift_conditions[EnumGiftConditionType.Comment] !== undefined,
        [EnumGiftConditionType.Course]:
          entity?.gift_conditions[EnumGiftConditionType.Course] !== undefined,
        [EnumGiftConditionType.Point]:
          entity?.gift_conditions[EnumGiftConditionType.Point] !== undefined
      }

      setUrlGiftImg(entity?.media_id?.media_url)
      setThumbnailDigitalGift(entity?.gift_digital_media?.media_url)
      setIsDigitalGift(Boolean(entity?.gift_digital_media))
      setDigitalGiftFiles([entity?.gift_digital_media])
    }
  }, [entity])

  const handleNameChange = (event: any) => {
    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      name: event?.target?.value.trim()
    }
  }

  const handleDesChange = (event: any) => {
    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      description: event?.target?.value.trim()
    }
  }

  const handlePriceChange = (event: any) => {
    event.target.value = event.target.value.slice(0, event.target.maxLength)

    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      price: event?.target?.value
    }
  }

  const handleQtyChange = (event: any) => {
    event.target.value = event.target.value.slice(0, event.target.maxLength)

    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      stock_qty: event.target.value
    }
  }

  const handleConditionsChange = (isInclude: boolean, conditions: EnumGiftConditionType) => {
    refConditionsEnable.current = {
      ...refConditionsEnable.current,
      [conditions]: isInclude
    }

    if (conditions === EnumGiftConditionType.Birth) {
      refValueToUpdateGift.current = {
        ...refValueToUpdateGift.current,
        gift_conditions: {
          ...refValueToUpdateGift.current.gift_conditions,
          [conditions]: 1
        }
      }
    }
  }

  const handleConditionsValueChange = (event: any, conditions: EnumGiftConditionType) => {
    event.target.value = event.target.value.slice(0, event.target.maxLength)

    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      gift_conditions: {
        ...refValueToUpdateGift.current.gift_conditions,
        [conditions]: Number(event.target.value || 0)
      }
    }
  }

  const handleImageChange = (media_id: string) => {
    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      media_id: media_id
    }
  }

  const handleDigitalGiftChange = (gift_digital_media: string) => {
    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      gift_digital_media: gift_digital_media
    }
  }
  const handleChangeGiftUrl = event => {
    refValueToUpdateGift.current = {
      ...refValueToUpdateGift.current,
      gift_digital_url: event.target.value.trim()
    }
  }

  const onPressCancel = useCallback(() => {
    if (isEqual(refValueDefaultGift.current, refValueToUpdateGift.current)) {
      navigate('/gift')
    } else {
      refConfirmModal.current?.show({
        title: `${__("gift_update_text_cancel")}`,
        description:
          (giftId ? `${__("gift_update_cancel")}` : `${__("gift_cancel_present")}`) + `${__("gift_update_confirm_text")}`,
        confirmText: giftId ? `${__("gift_update_continue_editing")}` : `${__("gift_update_keep_present")}`,
        onCancel: () => navigate('/gift')
      })
    }
  }, [entity?._id, giftId])

  const preventPasteNegative = useCallback(e => {
    const clipboardData = e.clipboardData
    const pastedData = parseFloat(clipboardData.getData('text'))

    if (isNaN(pastedData) || pastedData < 0) {
      e.preventDefault()
    }
  }, [])

  const renderConditionOption = useCallback(
    item => {
      const [isChecked, setIsChecked] = useState(false)

      useEffect(() => {
        setIsChecked(giftId ? entity?.gift_conditions?.[item.value] !== undefined : false)
      }, [entity])

      useEffect(() => {
        setIsCheckSwitchItem(!isChecked)
      }, [isChecked])
      const onChangeCondition = () => {
        handleConditionsChange(!isChecked, item.value)
        setIsChecked(old => !old)
      }


      return (
        <div key={item.value} className="giftupdate_container_condition_option">
          <Form.Check
            type="switch"
            id={'custom-switch' + item.value}
            label={item.title}
            checked={isChecked}
            onChange={onChangeCondition}
          />

          {item.value !== EnumGiftConditionType.Birth && (
            <div
              className={`giftupdate_container_condition_option_quantity ${
                isChecked ? 'show_quantity' : ''
              }`}
            >
              <Form.Label htmlFor={item.value}>
                {item.value === EnumGiftConditionType.Birth ? `${__("gift_update_birth_month")}` : `${__("gift_update_birth_text")}`}
              </Form.Label>
              <Form.Control
                maxLength={10}
                type="number"
                id={item.value}
                className="giftupdate_container_condition_input"
                min={item.value === EnumGiftConditionType.Birth ? 1 : 0}
                max={item.value === EnumGiftConditionType.Birth ? 12 : undefined}
                onPaste={preventPasteNegative}
                onKeyDown={onKeyDown}
                defaultValue={
                  giftId && entity?.gift_conditions?.[item.value] !== undefined
                    ? entity?.gift_conditions?.[item.value]
                    : undefined
                }
                onChange={event => handleConditionsValueChange(event, item.value)}
                // placeholder={GIFT_CONDITIONS_PLACEHOLDER[item.value]}
              />
            </div>
          )}
        </div>
      )
    },
    [entity]
  )

  const ViewElement = useCallback((file: any) => {
    let _Component: React.ReactNode
    const mediaType = (file?.media_mime_type ?? 'unknown')?.split('/').at(0)
    switch (mediaType) {
      case 'video':
        _Component = (
          <div className="giftUpdate_media_wrapper">
            <video
              controlsList={'nodownload'}
              controls
              key={file?.media_url}
              preload={'metadata'}
              className={`giftUpdate_media_body`}
              disablePictureInPicture
              muted
              id="video_source"
            >
              <source src={file?.media_url} type="video/mp4" />
            </video>
          </div>
        )
        break
      case 'image':
        _Component = (
          <div className="giftUpdate_media_wrapper">
            <Image
              id="target"
              className={`giftUpdate_media_body`}
              src={file?.media_url}
              alt="placeholder"
            />
          </div>
        )
        break
      default:
        _Component = (
          <div className="giftupdate_file">
            <div className="giftupdate_file_name">
              <FontAwesomeIcon icon={faFile} />
              <span className="giftupdate_media_file_name">{file?.media_file_name || ''}</span>
            </div>
          </div>
        )
        break
    }
    return (
      <div
        className=" giftupdate-media d-flex justify-content-between  mb-2 flex-column"
        style={{ width: 'max-content' }}
      >
        <div
          className={'ms-2 text-truncate d-inline-block'}
          style={{
            maxWidth: '200px'
          }}
        >
          {_Component}
        </div>
        <button
          className="giftupdate-btn-text ms-2"
          onClick={() => handleRemoveDigitalGift(file._id)}
          style={{ height: 'max-content' }}
        >
          Xoá
        </button>
      </div>
    )
  }, [])

  const submitGift = async event => {
    try {
      const form = event.currentTarget
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
        if (!refValueToUpdateGift.current?.media_id) {
          setErrorImage(`${__("gift_update_upload_image_warning")}`)
        }
        if (!refValueToUpdateGift.current?.gift_digital_media) {
          setErrorDigitalGift(`${__("gift_add_warning")}`)
        }
        if (isCheckSwitchItem) {
          setErrorSwitchText(`${__('gift_conditions_warning')}`)
        }
      } else {
        event.preventDefault()
        event.stopPropagation()
        if (!refValueToUpdateGift.current?.media_id) {
          setErrorImage(`${__("gift_update_upload_image_warning")}`)
          return
        }
        if (isDigitalGift && !refValueToUpdateGift.current?.gift_digital_media) {
          setErrorDigitalGift(`${__("gift_add_warning")}`)
          return
        }

        let isHasConditions = false
        let newConditions = { ...refValueToUpdateGift.current?.gift_conditions }

        Object.keys(refConditionsEnable.current).map(itemKey => {
          if (!refConditionsEnable.current[itemKey]) {
            if (newConditions[itemKey] !== undefined) {
              delete newConditions[itemKey]
            }
          } else {
            isHasConditions = true
          }
        })

        if (!isHasConditions) {
          toast.show({
            title: `${__("notification")}`,
            type: EnumTypeToast.Error,
            content: `${__("gift_update_condition_error_text")}`
          })
          return
        }

        setLoading(true)

        refValueToUpdateGift.current = {
          ...refValueToUpdateGift.current,
          gift_conditions: JSON.stringify(newConditions)
        }

        refValueToUpdateGift.current.gift_digital_media = isDigitalGift
          ? digitalGiftFiles[0]._id
          : undefined

        let res: any = giftId
          ? await dispatch(
              updateGift({
                ...refValueToUpdateGift.current,
                price: refValueToUpdateGift.current.price + '',
                stock_qty: refValueToUpdateGift.current.stock_qty + '',
                _id: giftId
              })
            )
          : await dispatch(
              createGift({
                ...refValueToUpdateGift.current,
                gift_type: 'gift',
                priority: '0'
              })
            )

        if (res?.type?.includes('fulfilled')) {
          toast.show({
            title: `${__("notification")}`,
            type: EnumTypeToast.Success,
            content: (giftId ? `${__("gift_update_success")}` : `${__("gift_create_success")}`)
          })

          navigate('/gift')
        } else {
          if (res?.type?.includes('rejected')) {
            toast.show({
              title: `${__("notification")}`,
              type: EnumTypeToast.Error,
              content: `${__("gift_update_error_text")}`
            })
          }

          setLoading(false)
        }
      }

      setValidate(true)
    } catch (error) {}
  }

  const onKeyDown = useCallback(event => {
    if ('e+-'.includes(event.key)) {
      event.preventDefault() // Chặn sự kiện nhập ký tự e, -, +
    }
  }, [])

  const handleUploadDigitalGift = async (files: FileList) => {
    if (!files) return
    const formData = new FormData()
    Array.from(files).forEach(file => {
      formData.append('file[]', file)
    })

    try {
      setUploadingDigitalGift(true)
      const res = await uploadFile(formData)
      const medias = res.map(media => media.callback) as TypedMedia[]
      setDigitalGiftFiles(prev => [...medias])
      refValueToUpdateGift.current.gift_digital_media = medias[0]._id
      setErrorDigitalGift(null)
    } catch (err) {
      throw err
    } finally {
      setUploadingDigitalGift(false)
    }
  }

  const handleRemoveDigitalGift = (giftId: string) => {
    setDigitalGiftFiles(digitalGiftFiles.filter(file => file._id !== giftId))
    refValueToUpdateGift.current.gift_digital_media = undefined
  }

  const handleBlurFormatCost = (e) => {
    const value = e.target.value;
    e.target.value = helpers.convertToCommasFormat(value)
  }

  const handleForcusCost = (e) =>{
    e.target.value =  e.target.value.replace(/,/g, '');
  }
  // ===============================

  return (
    <BigLayout>
      <_Helmet title={giftId ? `${__("gift_update_edit")}` : `${__("gift_update_create")}`} />
      <div id={'gift-update'}>
        <div className="bg-white container p-4 giftupdate_container">
          <Box component={Form} sx={{'& .form-check-input:checked': {
            backgroundColor: theme=>`${theme.palette.primary.main} !important`, borderColor: theme=>`${theme.palette.primary.main}!important`
          },'& .form-check-input:focus': {boxShadow: 'unset !important'}}} className="needs-validation" noValidate validated={validate} onSubmit={submitGift}>
            <Title text={`${isUpdating ? `${__("gift_update_edit")}` : `${__("gift_update_create")}`}`} />

            <Row className="h-100">
              <Col xs="12" lg={5} className="p-3 h-100 flex-column d-flex">
                <div className="giftupdate_uploadmedia_container">
                  <DragDropUploadMedia
                    callbackSetUrl={handleImageChange}
                    placeHolderBelow={__("gift_update_size_image")}
                    accept={'image/png, image/jpeg, image/jpg'}
                    errorFromParent={errorImage}
                    urlDefaultThumbnail={urlGiftImg}
                    placeHolder={__("gift_update_location_image")}
                    placeholderText={__("gift_update_location_image")}
                  />
                </div>
              </Col>

              <Col xs="12" lg={7} className="p-3 h-100 giftupdate_container_form">
                <div>
                  {!isUpdating && (
                    <Form.Group className={'mb-2'}>
                      <Form.Check
                        className="me-5"
                        name="gift-type"
                        label={__('gift_digital')}
                        inline
                        type="radio"
                        checked={isDigitalGift}
                        onChange={() => setIsDigitalGift(true)}
                      />
                      <Form.Check
                        label={__('gift_physical')}
                        name={'gift-type'}
                        inline
                        type="radio"
                        checked={!isDigitalGift}
                        onChange={() => setIsDigitalGift(false)}
                      />
                    </Form.Group>
                  )}
                  <div className={`${!isDigitalGift ? 'd-none' : ''}`}>
                    <input
                      type={'file'}
                      accept={'image/*,video/*,.doc,.docx,.pdf,.zip,.xls,.xlsx,.ppt,.pptx'}
                      hidden
                      ref={digitalInputRef}
                      onChange={e => handleUploadDigitalGift(e.target.files)}
                    />
                    {digitalGiftFiles?.map(file => ViewElement(file))}

                    {digitalGiftFiles?.length <= 0 && (
                      <LoadingButton
                        variant=""
                        className="giftupdate-btn-text"
                        onClick={e => {
                          e.preventDefault()
                          digitalInputRef.current.click()
                        }}
                        isLoading={uploadingDigitalGift}
                      >
                        <Typography color='primary.main'>
                          {__('gift_update_create')}
                        </Typography>
                      </LoadingButton>
                    )}

                    {isDigitalGift && Boolean(errorDigitalGift) && (
                      <div className={'text-danger'}>{errorDigitalGift}</div>
                    )}
                  </div>
                  <Form.Group className="mb-4 mt-4">
                    <Form.Label htmlFor="name">{__('gift_update_name')}</Form.Label>
                    <Form.Control
                      maxLength={100}
                      type="text"
                      id="name"
                      onChange={handleNameChange}
                      pattern=".*\S+.*"
                      defaultValue={giftId ? entity?.name || '' : undefined}
                      required
                    />
                    <div className="invalid-feedback">{__('gift_update_name_error_text')}</div>
                  </Form.Group>
                  {isDigitalGift && (
                    <Form.Group className="mb-4">
                      <FormLabel className={'block'}>{__('gift_update_link')}</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="https://..."
                        className="rounded-2 block"
                        name="gift_digital_url"
                        pattern="^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})(\/[^\s]*)?$"
                        defaultValue={isUpdating ? entity?.gift_digital_url : ''}
                        onChange={handleChangeGiftUrl}
                        required
                      />
                      <div className="invalid-feedback">{__('gift_update_link_error_text')}</div>
                    </Form.Group>
                  )}

                  <div className="d-flex justify-content-between gap-5 mb-4">
                    <Form.Group className="flex-grow-1">
                      <Form.Label htmlFor="price">{__('gift_update_value')}</Form.Label>
                      <Form.Control
                        maxLength={12}
                        onKeyDown={onKeyDown}
                        onPaste={preventPasteNegative}
                        defaultValue={giftId ? entity?.price || '' : 0}
                        id="price"
                        onChange={handlePriceChange}
                        onBlur={handleBlurFormatCost}

                        onFocus={handleForcusCost}
                        required
                        min={0}
                      />
                      <div className="invalid-feedback">{__('gift_update_value_error_text')}</div>
                    </Form.Group>
                    <Form.Group className="flex-grow-1">
                      <Form.Label htmlFor="qlt">{__('gift_update_quantity')}</Form.Label>
                      <Form.Control
                        maxLength={9}
                        onKeyDown={onKeyDown}
                        onPaste={preventPasteNegative}
                        defaultValue={giftId ? entity?.stock_qty || '' : 1000}
                        id="qlt"
                        onChange={e => {
                          handleQtyChange(e)
                        }}
                        required
                        min={0}
                      />
                      <div className="invalid-feedback">{__('gift_update_quantity_error_text')}</div>
                    </Form.Group>
                  </div>

                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="description">{__('gift_update_des')}</Form.Label>
                    <Form.Control
                      maxLength={1000}
                      as="textarea"
                      rows={5}
                      onChange={handleDesChange}
                      defaultValue={giftId ? entity?.description || '' : undefined}
                      required
                      id="description"
                    />
                    <div className="invalid-feedback">{__('gift_update_des__error_text')}</div>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="conditions">{__('gift_condition')}</Form.Label>
                    <div className="d-flex flex-wrap justify-content-between" id={'conditions'}>
                      {GIFT_CONDITIONS.map(renderConditionOption)}
                    </div>
                    {isCheckSwitchItem && Boolean(errorSwitchText) && (<div className="text-danger">{errorSwitchText}</div>)}
                  </Form.Group>
                  <div className="d-flex justify-content-end mt-5">
                    <Button variant="text" sx={{textTransform: 'none'}} onClick={onPressCancel}>
                      {__('gift_update_text_cancel')}
                    </Button>
                    <Button
                      sx={{textTransform: 'none'}}
                      className="mx-3"
                      type="submit"
                      disabled={loading}
                    >
                      {loading && (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="visually-hidden">{__('loading')}</span>
                        </>
                      )}
                      {__('gift_update_btn_save')}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Box>
          <br />
        </div>
        <ConfirmModal ref={refConfirmModal} />
      </div>
    </BigLayout>
  )
}
