import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import '../media/livestream.room.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";
import Button from "react-bootstrap/Button";
import {REACT_LIST_GIFT} from "entities/livestream/livestream.admin.constant";
import {sendReact} from "entities/livestream/services/livestream.service";
import {useAppSelector} from "config/store";
import ImgDefaultAvatar from "entities/courses/media/img_default_avatar.png";
import debounce from "lodash.debounce";
import Spinner from "react-bootstrap/Spinner";

interface TypedLivestreamReplayVideoProps {
}

export default function LivestreamReplayVideo({}: TypedLivestreamReplayVideoProps) {
  const liveDetail = useAppSelector(state => state.livestream.entity);
  const refVideoLivestream = useRef<HTMLVideoElement>()
  const refLivestreamReactAni = useRef<any>()
  const refBottomBanner = useRef<any>()
  const [viewCount, setViewCount] = useState(0)
  const [isVolumeOn, setIsVolumeOn] = useState(false)


  useEffect(() => {
    setViewCount(liveDetail?.view_number || 0)
  }, [liveDetail]);


  const debounceSendReact = useMemo(() => {
    return debounce((typeReact: string, idLivestream: string) => {
      sendReact(typeReact, idLivestream)
    }, 2000);
  }, []);

  const onSendEmoji = useCallback((e: any, react: any) => {
    debounceSendReact(react.name, liveDetail?._id)
    refLivestreamReactAni.current.showReact(e.clientX, e.clientY, <img src={react.icon}
                                                                       className="livestreamroomvideo_btn_react big_react"/>)
  }, [liveDetail?._id])

  const renderReactButton = useCallback((item, index: number) => {
    return (
      <Button key={index.toString()} className="livestreamroomvideo_btn_react"
              onClick={(e) => onSendEmoji(e, item)}
      >
        <img src={item.icon} className="livestreamroomvideo_img_react"/>
      </Button>
    )
  }, [liveDetail?._id])

  const onOffVolumeVideo = useCallback(() => {
    if (refVideoLivestream.current) {
      console.log(refVideoLivestream.current.muted, "refVideoLivestream.current.muted")
      refVideoLivestream.current.volume = 1;
      refVideoLivestream.current.muted = !refVideoLivestream.current.muted;
      setIsVolumeOn(old => !old)
    }
  }, [])

  const BannerStatus = useMemo(() => {
    return (
      <>
        {
          <div className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center z-index-minus">
            <img src={liveDetail?.avatar?.media_url} className="w-100 h-100 object-fit-cover"/>
            <Spinner
              as="span"
              animation="grow"
              className="position-absolute spinner-live"
              role="status"
              aria-hidden="true"
              variant="danger"
            />
          </div>
        }
      </>
    )
  }, [liveDetail])


  return (
    <div className="flex-grow-1 livestreamroomvideo">
      <video
        ref={refVideoLivestream}
        autoPlay
        playsInline
        loop
        controls={false}
        crossOrigin={"anonymous"}
        className="video-player"
        muted
      >
      </video>


      <div className="livestreamroomvideo_container">

        {BannerStatus}

        <div className="livestreamroomvideo_container_top">
          <div className="d-flex align-items-center">
            <img
              className="livestreamroomvideo_avatar_mentor"
              src={liveDetail?.user_id?.user_avatar || liveDetail?.user_id?.user_avatar_thumbnail || ImgDefaultAvatar}/>

            <div className="ms-3 d-flex flex-column align-items-start">
              <div
                className="livestreamroomvideo_txtname_mentor">{liveDetail?.user_id?.display_name || "Chủ phòng"}</div>

              <div className="d-flex align-items-center livestreamroomvideo_txtview_mentor"><FontAwesomeIcon
                icon={faEye} className="me-2"/> {" " + viewCount}</div>

            </div>
          </div>
        </div>


        <div ref={refBottomBanner} className="livestreamroomvideo_container_bottom z-3">
          {REACT_LIST_GIFT.map(renderReactButton)}
        </div>

      </div>
    </div>
  )
}
