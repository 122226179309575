import React from 'react'
import { Button, ThemeProvider } from '@mui/material'
import BaseTypography from './BaseTypography'
import BaseInput from './BaseInput'
import CustomCheckbox from './Checkbox'
import { getThemeByMode } from 'styles/themes'
import { lightPalette } from 'styles/themes/palettes/defaultPalete'
import { colorGenerator } from 'styles/utils/colorPaletteGenerator'

const Demo = () => {
  const [selectedColor, setSelectedColor] = React.useState<string>('#9254DE')

  const handleSelectColor = (value: string) => {
    setSelectedColor(value)
  }
  const tempTheme = () => ({
    ...lightPalette,
    ...colorGenerator(selectedColor, 'primary')
  })

  const theme = getThemeByMode('light', {
    lightPalette: tempTheme()
  })

  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained">Button</Button>
      <Button disabled>Button</Button>
      <Button variant="outlined">Button</Button>
      <Button disabled variant="outlined">
        Button
      </Button>
      <br />
      <BaseTypography />
      <br />
      <BaseInput />
      <CustomCheckbox />
      <Button variant="outlined">Button</Button>
      <Button
        sx={{
          background: '#9254DE',
          '&:hover': {}
        }}
        onClick={() => handleSelectColor('#9254DE')}
      >
        #9254DE
      </Button>
      <Button
        sx={{
          background: '#13C2C2',
          '&:hover': {}
        }}
        onClick={() => handleSelectColor('#13C2C2')}
      >
        #13C2C2
      </Button>
    </ThemeProvider>
  )
}

export default Demo
