import React from 'react'
import { Avatar, Box, Rating, Stack, Typography } from '@mui/material'
import { formatDate } from 'helpers/dateAndTime'
import dateandtime from 'date-and-time'
import { TypedReviewOrder } from 'entities/shop/interface'

import avatarDefault from 'media/images/avatar_default.png'
import { User } from 'entities/challenge/challenge.interface'

interface Props extends TypedReviewOrder {
  user: Omit<User, '_id'> & {
    user_id: string
  },
  clickViewImages: (index: number, images: string[]) => void

}

const ProductFeedback = (feedbackData: Props) => {
  return (
    <Stack gap={1.125}>
      <Stack direction={'row'} gap={1}>
        <Avatar src={feedbackData?.user?.user_avatar || avatarDefault} />
        <Stack>
          <Typography fontWeight={600}>{feedbackData?.user?.display_name || ''}</Typography>
          <Stack direction={'row'} gap={1}>
            <Rating readOnly={true} value={feedbackData?.review_meta?.review_point} />
            <Typography variant={'body2'} color={'text.secondary'}>
              {dateandtime.format(new Date(Number(feedbackData?.review_meta?.createdAt)), 'DD-MM-YYYY')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Typography>{feedbackData?.review_meta?.review_content || ''}</Typography>
      <Stack direction={'row'} gap={2}>
        {feedbackData?.review_meta?.review_media?.map((url, index) => (
          <Box
            key={index}
            sx={{
              width: theme => theme.spacing(10),
              height: theme => theme.spacing(10),
              borderRadius: theme => theme.spacing(1.5)
            }}
            onClick={() => feedbackData.clickViewImages(index, feedbackData?.review_meta?.review_media || [])}
          >
            <img src={url} alt="image" style={{ width: '100%', height: '100%' }} />
          </Box>
        ))}
      </Stack>
    </Stack>
  )
}

export default ProductFeedback
