//Library
import _ from "lodash";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "config/store";

//Components
import ComponentEmpty from "components/componentEmpty";
import SettingMentorSkeleton from "entities/mentor/skeleton/setting.mentor.list.skeleton";

//Store
import {TypeDataProduct} from "entities/mentor/mentor.interface";
import {getListCourse} from "../courses.store.reducer";

//Images
import imageDefault from "media/images/image_default.png";

//Icons
import iconSearch from "media/icons/search.svg";
import iconClose from "media/icons/icon_close.svg";

//Scss
import "./../media/course.filter.product.scss";
import __ from "languages/index";
import { formatString } from 'entities/support/utils'

const CourseIncomeFilterProduct = ({
  data,
  show,
  onClose,
  onSubmit,
  title,
  ...args
}: {
  data;
  show: boolean;
  onClose: () => void;
  onSubmit;
  title: string;
  args?: any;
}) => {
  const dispatch = useAppDispatch();
  const { listDataProduct, totalProduct } = useAppSelector((state) => state.courses?.dataProduct);

  const [product, setProduct] = useState<TypeDataProduct[]>([]);
  const [dataSelect, setDataSelect] = useState<TypeDataProduct[]>(data);
  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reduceRequestSearch = useMemo(() => {
    return _.debounce((_value) => {
      setProduct([]);
      setPage(1);
      setIsLoading(true);
      dispatch(getListCourse({ page: page, limit: 10, search: _value }))
        .unwrap()
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }, 500);
  }, []);

  const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    reduceRequestSearch(value);
  };

  const handleSelectProduct = useCallback(
    (_id: string) => {
      if (dataSelect.some((item) => item?._id === _id)) {
        setDataSelect(dataSelect.filter((item) => item?._id !== _id));
      } else {
        product.forEach((item) => {
          if (item?._id === _id) {
            setDataSelect((dataSelect) => [...dataSelect, item]);
          }
        });
      }
    },
    [dataSelect, product]
  );

  const handleSubmit = useCallback(() => {
    onSubmit(dataSelect);
    setPage(1);
    setProduct([]);
    dispatch(getListCourse({ page: 1, limit: 10 }))
      .unwrap()
      .then((res) => {
        setProduct(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataSelect]);

  const handleClose = useCallback(() => {
    onClose();
    setDataSelect(data);
    setPage(1);
    dispatch(getListCourse({ page: 1, limit: 10 }))
      .unwrap()
      .then((res) => {
        setProduct(res?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataSelect, data]);

  const handlePageChange = useCallback(() => {
    setPage(page + 1);
    dispatch(getListCourse({ page: page + 1, limit: 10 }));
  }, [page]);

  useEffect(() => {
    if (!product?.length) return setProduct(listDataProduct);
    listDataProduct?.length &&
      listDataProduct?.forEach((item) => {
        if (product?.every((value) => value?._id !== item?._id)) {
          setProduct((product) => [...product, item]);
        }
      });
  }, [listDataProduct]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getListCourse({ page: page, limit: 10 }))
      .unwrap()
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setDataSelect(data);
  }, [data]);

  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="courseFilterProduct__container">
            <div className="courseFilterProduct__left">
              <div className="courseFilterProduct__search">
                <img src={iconSearch} alt="icon" />
                <input
                  className="courseFilterProduct__search--input"
                  onChange={handleChangeInputSearch}
                  placeholder={__("course_search")}
                />
              </div>
              <div className="courseFilterProduct__listSelect--wrapper">
                {isLoading ? (
                  <div className="bg-white p-4 rounded mt-4">
                    <SettingMentorSkeleton />
                  </div>
                ) : (
                  <>
                    {product?.length ? (
                      <>
                        <ul className="courseFilterProduct__list">
                          {product?.map((item, idx) => (
                            <li key={idx} className="courseFilterProduct__list--item">
                              <input
                                type="checkbox"
                                id={`checkbox-${idx}`}
                                checked={dataSelect.some((data) => data?._id === item?._id)}
                                onClick={() => handleSelectProduct(item?._id)}
                                readOnly
                                className="courseFilterProduct__list--input"
                              />

                              <label htmlFor={`checkbox-${idx}`} className="courseFilterProduct__list--product">
                                <img
                                  src={item?.avatar?.media_url || imageDefault}
                                  alt="avatarCourse"
                                  onError={(e) => {
                                    e.currentTarget.src = imageDefault;
                                  }}
                                />
                                <span className="courseFilterProduct__list--title">{item?.title}</span>
                              </label>
                            </li>
                          ))}
                          {product?.length < totalProduct && (
                            <span className="courseFilterProduct__loadMore" onClick={handlePageChange}>
                              {__("course_see_product")}
                            </span>
                          )}
                        </ul>
                      </>
                    ) : (
                      <ComponentEmpty />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="courseFilterProduct__right">
              <span className="courseFilterProduct__header">{__("course_selected_product")}</span>
              <div className="courseFilterProduct__listSelect--wrapper">
                {dataSelect?.length ? (
                  <ul className="courseFilterProduct__listSelect">
                    {dataSelect?.map((item, idx) => (
                      <li key={idx} className="courseFilterProduct__list--select">
                        <div className="courseFilterProduct__list--product productSelect">
                          <img
                            src={item?.avatar?.media_url || imageDefault}
                            alt="avatarCourse"
                            onError={(e) => {
                              e.currentTarget.src = imageDefault;
                            }}
                          />
                          <span className="courseFilterProduct__list--title productSelect">{item?.title}</span>
                        </div>
                        <img src={iconClose} onClick={() => handleSelectProduct(item?._id)} alt="iconClose" />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ComponentEmpty text={formatString(__('data_empty'), `${__('course_selected_product').toLowerCase()}`)} />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            {__("course_btn_apply")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(CourseIncomeFilterProduct);
