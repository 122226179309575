/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
import Example from 'entities/example'
import Courses from 'entities/courses'
import Calendar from 'entities/calendar'
import CoursesStudy from 'entities/courses/courses.study'
import Extensions from 'entities/extentions'
import Event from 'entities/event'
import Gift from 'entities/gift'
import Challenge from 'entities/challenge'
import Broadcast from 'entities/broadcast'
import Mentor from 'entities/mentor'
import InfoPayment from 'entities/infoPayment'
import Payment from 'entities/payment'
import Order from 'entities/orders'

import aboutUS from 'layout/aboutUs'
import TOS from 'layout/tos'
import Contact from 'layout/contact'
import LiveStreamAdmin from 'entities/livestream/livestream.admin'
import LivestreamRoom from 'entities/livestream/livestream.room'
import LivestreamReplay from 'entities/livestream/livestream.replay'
import MobileApp from 'entities/mobileApp'
import Mission from 'entities/mission'
import Support from 'entities/support'
import CreateTicket from 'entities/support/page/CreateTicket'
import DetailTicket from 'entities/support/page/DetailTicket'
import AddDomain from 'entities/domain'
import IntroDomainService from 'entities/domain/page/intro.page'
import DetailMission from 'entities/mission/mission.detail'
import SuccessfulScreen from 'layout/SuccessfulScreen'
import ApprovalPost from 'entities/approvalPost'
import User from 'entities/user'
import About from 'entities/about'
import Notification from 'entities/notification'
import UpgradeAccount from 'entities/upgaradeAccount'
import Channel from 'entities/channel'
import ChannelSetting from 'entities/channelSetting'
import Member from 'entities/member'
import CreateLiveStream from 'entities/livestream/pages/CreateLiveStream.page'
import Podcast from '../podcast'
import DetailPodcast from '../podcast/pages/DetailPodcast.page'
import CreateNewPodcast from '../podcast/pages/CreateNewPodcast.page'
import LeaderBoard from 'entities/leaderBoard'
import ValidateCode from 'entities/validateCode'
import SearchScreen from 'entities/search'
import FamilyTree from '../entities/ReferrerTree'
import LoginPage from 'layout/login'
import CourseManageUser from 'entities/courses/course.manage.user'
import GiftDetail from 'entities/gift/GiftDetail.page'
import RegisterPage from 'layout/register'
import Demo from 'entities/base'
import LoginWithSocial from 'layout/component/login.sso'
import ForgotPassword from 'entities/forgotPassword'
import ChatPage from 'entities/chat'
import Auth from 'layout/component/auth'
import HomeNew from 'entities/homeNew'
import Shop from 'entities/shop'
import ViewProduct from 'entities/shop/ViewProduct'
import HomeDetailPost from 'entities/homeNew/home.detail.post'
import Print from 'entities/print'

/**
 * Any one can access this URL
 */
export const public_route = [
  {
    path: '/example',
    main: Example
  },
  {
    path: '/example/:slug',
    main: Example
  },
  {
    path: '/example/:slug/:example_id',
    main: Example
  },
  {
    path: '/courses/:slug/:courses_id/:module_id',
    main: Courses
  },
  {
    path: '/courses/:slug/:courses_id',
    main: Courses
  },
  {
    path: '/courses/:slug',
    main: Courses
  },
  {
    path: '/course/manage-user/:course_id',
    main: CourseManageUser
  },
  {
    path: '/courses',
    main: Courses
  },
  {
    path: '/user/:slug/:user_id',
    main: User
  },
  {
    path: '/user/:slug',
    main: User
  },
  {
    path: '/user',
    main: User
  },
  {
    path: '/member',
    main: Member
  },
  {
    path: '/channel-setting',
    main: ChannelSetting
  },
  {
    path: '/notifications',
    main: Notification
  },
  {
    path: '/leader-board',
    main: LeaderBoard
  },
  {
    path: '/mentor/:slug/:mentor_id',
    main: Mentor
  },
  {
    path: '/mentor',
    main: Mentor
  },
  {
    path: '/mentor/:slug',
    main: Mentor
  },
  {
    path: '/info-payment/:slug',
    main: InfoPayment
  },
  {
    path: '/info-payment',
    main: InfoPayment
  },
  {
    path: '/payment/:slug',
    main: Payment
  },
  {
    path: '/payment',
    main: Payment
  },

  {
    path: '/calendar',
    main: Calendar
  },
  {
    path: '/calendar/:slug',
    main: Calendar
  },
  {
    path: '/calendar/:slug/:calendar_id',
    main: Calendar
  },
  {
    path: '/search',
    main: SearchScreen
  },
  {
    path: '/about',
    main: About
  },
  {
    path: '/post/:slug',
    main: ApprovalPost
  },
  {
    path: '/event/:slug/:calendar_id',
    main: Event
  },
  {
    path: '/event/:slug',
    main: Event
  },
  {
    path: '/event',
    main: Event
  },
  {
    path: '/gift/:slug/:giftId',
    main: Gift
  },
  {
    path: '/gift/:slug',
    main: Gift
  },
  {
    path: '/gift',
    main: Gift
  },
  {
    path: '/broadcast',
    main: Broadcast
  },

  {
    path: '/challenge/:slug/:challenge_id',
    main: Challenge
  },
  {
    path: '/challenge/:slug',
    main: Challenge
  },
  {
    path: '/challenge',
    main: Challenge
  },

  {
    path: '/',
    main: HomeNew
  },
  {
    path: '/post/detail/:post_id',
    main: HomeDetailPost
  },
  {
    path: '/post/view/:post_id',
    main: HomeDetailPost
  },
  {
    path: '/orders',
    main: Order
  },
  {
    path: '/orders/:slug',
    main: Order
  },
  {
    path: '/orders/:slug/:order_id',
    main: Order
  },
  {
    path: '/extensions',
    main: Extensions
  },
  {
    path: '/extensions/:slug',
    main: Extensions
  },
  {
    path: '/extensions/:slug/:extension_id',
    main: Extensions
  },
  {
    path: '/mobile/:slug',
    main: MobileApp
  },
  {
    path: '/mobile',
    main: MobileApp
  },
  {
    path: '/missions',
    main: DetailMission
  },
  {
    path: '/missions/gift/:giftId',
    main: GiftDetail
  },
  {
    path: '/setting-mission',
    main: Mission
  },
  {
    path: '/setting-mission/:slug',
    main: Mission
  },
  {
    path: '/support',
    main: Support
  },
  {
    path: '/support/:id',
    main: DetailTicket
  },
  {
    path: '/support/create-ticket',
    main: CreateTicket
  },

  {
    path: '/domain/create',
    main: AddDomain
  },
  {
    path: '/domain/intro',
    main: IntroDomainService
  },
  {
    path: '/payment/success/:orderId',
    main: SuccessfulScreen
  },
  {
    path: '/livestream/create',
    main: CreateLiveStream
  },
  {
    path: '/upgrade/:slug',
    main: UpgradeAccount
  },
  {
    path: '/upgrade',
    main: UpgradeAccount
  },
  {
    path: '/podcast',
    main: Podcast
  },
  {
    path: '/podcast/:id',
    main: DetailPodcast
  },
  {
    path: '/podcast/create',
    main: CreateNewPodcast
  },
  {
    path: '/referrals',
    main: FamilyTree
  },
  {
    path: '/demo',
    main: Demo
  },
  {
    path: '/shop',
    main: Shop
  },
  {
    path: '/shop/:slug',
    main: Shop
  },
  {
    path: '/shop/manage-product/:slug',
    main: Shop
  },
  {
    path: '/shop/manage-product/:slug/:productId',
    main: Shop
  },
  {
    path: '/shop/admin/:slug/:category_id/products',
    main: Shop
  },
  {
    path: '/shop/admin/:slug/:collection_id/product-collection',
    main: Shop
  },
  {
    path: '/shop/admin/:slug/:stock_id',
    main: Shop
  },
  {
    path: '/shop/admin/:slug/:stock_id/:shipping_units',
    main: Shop
  },
  {
    path: '/shop/:slug/:productId',
    main: Shop
  }
]

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [
  {
    path: '/live-room/:event_id',
    main: LivestreamRoom
  },
  {
    path: '/live-replay/:event_id',
    main: LivestreamReplay
  },
  {
    path: '/livestream/:liveStreamId',
    main: LiveStreamAdmin
  },
  {
    path: '/study/:course_id/:module_id',
    main: CoursesStudy
  },
  {
    path: '/study/:course_id',
    main: CoursesStudy
  },

  {
    path: '/auth',
    main: Auth
  },
  {
    path: '/login',
    main: LoginPage
  },
  {
    path: '/login/facebook',
    main: LoginWithSocial
  },
  {
    path: '/login/google',
    main: LoginWithSocial
  },
  {
    path: '/register',
    main: RegisterPage
  },
  {
    path: '/about-us',
    main: aboutUS
  },
  {
    path: '/tos',
    main: TOS
  },
  {
    path: '/contact',
    main: Contact
  },
  {
    path: '/validate-code',
    main: ValidateCode
  },
  {
    path: '/forgot-password',
    main: ForgotPassword
  },
  {
    path: '/forgot-password/:slug',
    main: ForgotPassword
  },
  {
    path: '/channel',
    main: Channel
  },
  {
    path: '/channel/:slug',
    main: Channel
  },
  {
    path: '/chat',
    main: ChatPage
  },
  {
    path: '/chat/:slug/:id_chat',
    main: ChatPage
  },
  {
    path: '/channel/:slug/:channel_id',
    main: Channel
  },
  {
    path: '/print/:slug/:id',
    main: Print
  }
]
