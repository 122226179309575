//Library
import React, {useLayoutEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

//Store
import cloud_load from "../../lotties/cloud_load.json";
//Hook
import {useToast} from 'hooks/useToast'
import {styled, Typography} from "@mui/material";
import Lottie from "lottie-react";
import Container from "react-bootstrap/Container";
import __ from "languages/index";
import {checkDomainBeCreated, getOrderByID} from "./service";
import helpers from "helpers/index";

const STEP_CONTENT = [
  "channel_loading_step1",
  "channel_loading_step2",
  "channel_loading_step3",
  "channel_loading_step4",
]

const ChannelCreateLoading = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()
  const [contentStep, setContentStep] = useState(STEP_CONTENT[0])
  const [textDot, setTextDot] = useState("")


  useLayoutEffect(() => {
    let searchParam = helpers.ExtractUrl(location.search) || {}


    if (!searchParam?.order_id || !searchParam?.domain) {
      window.location.href = window.location.origin + "/orders"
    }

    let intervalCheckDomain = undefined;
    let countRequestCheckDomain = 0;

    getOrderByID(searchParam.order_id)
      .then((result) => {
        if (result?.data && result?.data?.status === "success") {
          intervalCheckDomain = setInterval(() => {
            checkDomainBeCreated(searchParam?.domain)
              .then((result) => {
                if (result?.data?.created) {
                  clearInterval(intervalCheckDomain)
                  intervalCheckDomain = undefined;
                  setTimeout(() => {
                    window.location.href = window.location.origin + "/orders/detail/" + searchParam?.order_id
                  }, 3000)
                }else {
                  countRequestCheckDomain += 1
                  if(countRequestCheckDomain > 30){
                    window.location.href = window.location.origin + "/orders/detail/" + searchParam?.order_id
                  }
                }
              })
          }, 2000)
        } else {
          window.location.href = window.location.origin + "/orders/detail/" + searchParam?.order_id
        }
      })
      .catch(() => {
        window.location.href = window.location.origin + "/orders/detail/" + searchParam?.order_id
      })


    let indexStep = 1;
    const intervalSetStep = setInterval(() => {
      if (STEP_CONTENT[indexStep]) {
        setContentStep(STEP_CONTENT[indexStep])
        setTextDot("")
        indexStep += 1
      } else {
        clearInterval(intervalSetStep)
      }
    }, 10000)

    const intervalSetDot = setInterval(() => {
      setTextDot((oldText) => {
        if (oldText.length < 3) {
          return oldText + "."
        } else {
          return ""
        }
      })
    }, 500)

    return (() => {
      clearInterval(intervalSetDot)
      if (intervalCheckDomain) {
        clearInterval(intervalCheckDomain)
      }
    })
  }, []);

  return (
    <LayoutContainer>
      <StyledLottie
        style={{width: 400, height: 400}}
        animationData={cloud_load}
        autoPlay
        loop/>
      <Typography variant="h4" textTransform={'none'} color={'primary'} height={300} fontWeight={600} noWrap>
        {__(contentStep) + textDot}
      </Typography>
    </LayoutContainer>
  )
}
const LayoutContainer = styled(Container)(({theme}) => ({
  minHeight: '100vh',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '1920px',
  margin: '0 auto'
}))

const StyledLottie = styled(Lottie)(({theme}) => ({
  'svg': {
    '& path': {
      fill: theme.palette.primary.main,
      stroke: ''
    }
  }
}))
export default ChannelCreateLoading
