import React from 'react'
import { Stack, Switch, Typography } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import { notificationConfigs } from './notificationConfigs'

const EmailNotification = () => {
  return (
    <Stack gap={2}>
      <ChannelSettingsTitle>{__('channel_setting_data_email')} </ChannelSettingsTitle>
      <>
        {notificationConfigs.map(config => (
          <Stack direction="row" justifyContent={'space-between'}>
            <Stack gap={0.5}>
              <Typography variant="subtitle2">{config.title}</Typography>
              <Typography color="text.secondary">{config.description}</Typography>
            </Stack>
            <Switch />
          </Stack>
        ))}
      </>
    </Stack>
  )
}

export default EmailNotification
