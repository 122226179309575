import React from 'react'
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  styled,
  SxProps,
  Link,
  Typography
} from '@mui/material'
import { CurrentTheme } from '../styles/themes'
import { CrownIcon } from '../icons'
import { RankingType } from '../interfaces/common.interface'
import __ from 'languages/index'
import helpers from 'helpers/index'
import { NavLink } from 'react-router-dom'
import useRedirectAuth from "../hooks/useRedirectAuth";

type Props = {
  rankingList: RankingType[]
  isAuth?: boolean
}
const QuickViewRanking = ({ rankingList, isAuth }: Props) => {
  const {getPathToLogin} = useRedirectAuth()

  const getRankingIcon = (rank: number) => {
    switch (rank) {
      case 1:
        return <CrownIcon />
      case 2:
      case 3:
        return <Box sx={numberStyled}>{rank}</Box>
      default:
        return <Typography fontWeight={600}>{rank}</Typography>
    }
  }

  return (
    <Paper elevation={0} sx={containerStyled}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontWeight={600}>{__('leader_board_title')}</Typography>
        <Typography>
          <NavLink
            to={isAuth ? '/leader-board' : getPathToLogin()}
            className={'view-all'}>
            {__('header_see_all')}
          </NavLink>
        </Typography>

      </Stack>

      <List sx={listStyled} dense={true}>
        {rankingList?.map((item, index) => (
          <RankingItem
            key={`rankingItem_${index}`}
            className={'list-item'}
            rank={index + 1}
            secondaryAction={
              <Box className={'score'}>
                <Typography>{helpers.convertToShortNumber(item?.point)}</Typography>
              </Box>
            }
          >
            <ListItemIcon sx={iconStyled}>{getRankingIcon(index + 1)}</ListItemIcon>

            <ListItemAvatar
              sx={{
                minWidth: 'auto',
                mr: theme => theme.spacing(0.5)
              }}
            >
              <Link  href={`/user/detail/${item?.user_id?._id}`} underline="none" color="inherit">
                <Avatar
                  src={item?.user_id?.user_avatar_thumbnail || item?.user_id?.user_avatar}
                  sx={{
                    height: theme => theme.spacing(5.5),
                    width: theme => theme.spacing(5.5)
                  }}
                />
              </Link>
            </ListItemAvatar>
            <ListItemText>
              <Link  href={`/user/detail/${item?.user_id?._id}`} underline="none" color="inherit">
                <Typography fontWeight={600} noWrap>
                  {item?.user_id?.display_name}
                </Typography>
              </Link>
            </ListItemText>
          </RankingItem>
        ))}
      </List>
    </Paper>
  )
}

export default QuickViewRanking

const containerStyled: SxProps<CurrentTheme> = {
  padding: theme => theme.spacing(2),
  '& .view-all': {
    color: theme => theme.palette.primary.main,
    textDecoration: 'none'
  }
}

const listStyled: SxProps<CurrentTheme> = {
  '& .list-item:not(:last-child)': {
    mb: theme => theme.spacing(1)
  }
}

const numberStyled: SxProps<CurrentTheme> = {
  backgroundColor: 'transparent',
  borderRadius: '100%',
  width: theme => theme.spacing(4.25),
  height: theme => theme.spacing(4.25),
  border: theme => `3px solid ${theme.palette.primary.backgroundHover}`,
  padding: theme => theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme => theme.palette.primary.dark
}

const iconStyled: SxProps<CurrentTheme> = {
  minWidth: theme => theme.spacing(4.25),
  mr: theme => theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const RankingItem = styled(ListItem, {
  shouldForwardProp(prop) {
    return prop !== 'rank'
  }
})<{ rank: number }>(({ theme, rank }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${
    rank === 2 || rank === 3 ? theme.palette.primary.main : theme.palette.divider
  }`,
  borderRadius: theme.spacing(1.5),
  backgroundColor:
    rank === 1
      ? theme.palette.primary.main
      : rank === 2
        ? theme.palette.primary.background
        : 'transparent',
  color: rank === 1 ? theme.palette.common.white : theme.palette.text.primary,

  '& .score': {
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
    '& > *': {
      fontWeight: 600
    }
  }
}))
