import {useAppDispatch} from 'config/store'
import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {createEntity, deleteEntity} from './calendar.store.reducer'
import CalendarComponent from './components/calendar.component'
import BigLayout from "../../layouts/bigLayout";

export default function CalendarView() {
  const dispatch = useAppDispatch()
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.example_id || false

  useEffect(() => {
    //   dispatch(getEntity(Param));
  }, [Param])

  const fetchNewSampleData = useCallback((_id: string) => {
    dispatch(deleteEntity(_id))
  }, [])

  const createNewSampleData = useCallback(() => {
    dispatch(createEntity({}))
  }, [])

  const [showModal, setShowModal] = useState(false)
  const [showToast, setShowToast] = useState(false)

  return (
    <BigLayout>
      <CalendarComponent />
    </BigLayout>
  )
}
