import React from "react";
import '../media/courses.skeletons.scss'
import ImageEmpty from "entities/courses/media/empty.png";
import __ from "languages/index";


export default function CoursesEmpty() {


    return (
        <div className="container d-flex flex-column align-items-center my-5">
            <img src={ImageEmpty} style={{width: "30%", height: 200, objectFit:'contain'}}/>
            <p id="txt_empty">{__("list_empty")}</p>
        </div>
    )
}
