import React from "react"
import {TypedListLikeEvent} from "../event.interface"
import EventDefault from "../media/event_default.jpg"
import helpers from 'helpers'
import __ from "languages/index";

interface Props {
  listUserLike: TypedListLikeEvent[]
  countUser: number
  detail?: any
}

const UserLikeEvent = (props: Props) => {

  const { listUserLike, countUser, detail } = props

  const renderAvatar = () => {
    if (helpers.isEmpty(listUserLike))  return
    return (
      <ul  className="d-flex align-items-center justify-content-center">
        {listUserLike.map(item => {
          return (
            <li key={`UserLikeEvent_${item?.event_id}`}><img src={item?.user_id?.user_avatar_thumbnail || item?.user_id?.user_avatar || EventDefault} alt="image" /></li>
          )
        })}
      </ul>
    )

  }

  return (
    <div id={`UserLikeEvent_${detail?._id}`} className="friends-list d-flex gap-3 align-items-center text-center p-3">
      {renderAvatar()}
      <span className="mdtxt d-center">{detail?.like_number > 0 ? `${detail?.like_number} ${__("event_participants")}` : `${__("event_nobody_join")}`}</span>
    </div>
  )
}

export default UserLikeEvent
