import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material'
import __ from 'languages/index'
import { dataListPaymentMethod } from 'entities/shop/data'
import VnPayIcon from '../../../icons/VnPayIcon'
import { useState } from 'react'
import { CheckSecondIcon } from 'icons'
import helpers from 'helpers'
import { useToast } from 'hooks/useToast'

const ShopPaymentMethod = ({
  handleSubmit,
  totalPayment
}: {
  totalPayment: { totalPriceProduct: number; totalFee: number }
  handleSubmit: Function
}) => {
  const [paymentMethod, setPaymentMethod] = useState<string>('cash_on_delivery')
  const [noteForShop, setNoteForShop] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleSubmitOrder = () => {
    const formData = {
      order_delivery_type: paymentMethod,
      order_note: noteForShop
    }
    setIsLoading(true)
    handleSubmit(formData, () => {
      setIsLoading(false)
    })
  }

  return (
    <ShopPaymentMethodContainer>
      <Typography variant="subtitle2" className="payment_method_title">
        {__('shop_payment_method_title')}
      </Typography>
      <Box className="payment_method_wrapper">
        <Box className="payment_method_list_wrapper">
          <List className="payment_method_list">
            {dataListPaymentMethod?.map((method, index: number) => (
              <ListItem key={`payment_method${index}`} className="payment_method_item">
                <Button
                  variant="outlined"
                  sx={{}}
                  endIcon={<>{method?.value === paymentMethod && <CheckSecondIcon />}</>}
                  className={`payment_method_btn ${method?.value === paymentMethod && 'active'}`}
                >
                  {method?.name === 'vn_pay' && (
                    <IconButton
                      sx={{ p: theme => theme.spacing(0, 1, 0, 0) }}
                      className="payment_method_btn"
                    >
                      <VnPayIcon sx={{ width: '100%', height: '100%' }} />
                    </IconButton>
                  )}
                  <Typography textTransform={'none'}>{method?.text}</Typography>
                </Button>
              </ListItem>
            ))}
          </List>
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-start"
            className="payment_method_delivery_message"
          >
            <Typography
              variant="subtitle2"
              sx={{ minWidth: 'max-content', padding: theme => theme.spacing(1) }}
            >
              {__('payment_method_message')}
            </Typography>
            <TextField
              value={noteForShop || ''}
id="outlined-message"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNoteForShop(e.target.value)
              }}
InputProps={{ hiddenLabel: true }}
              size="small"
              label={__('payment_method_input_message_placeholder')}
              variant="outlined"
              sx={{ textAlign: 'center','& .Mui-focused': {border: 'none !important'}, '& fieldset span': {width: theme=>theme.spacing(14)} }}
            />
          </Stack>
        </Box>
        <Box className="payment_method_delivery">
          <Box />
          <Stack
            direction="column"
            spacing={2}
            justifyContent="flex-end"
            className="payment_method_delivery_wrapper"
          >
            <Box className="payment_method_delivery_item">
              <Typography variant="body1">{__('payment_ship_cost')}</Typography>
              <Typography variant="subtitle2">
                {`${helpers.formatNumberWithCommas(totalPayment?.totalFee || 0)} VND`}
              </Typography>
            </Box>
            <Box className="payment_method_delivery_item">
              <Typography variant="subtitle2">{__('payment_total_money')}</Typography>
              <Typography variant="subtitle2">
                {`${helpers.formatNumberWithCommas(totalPayment?.totalPriceProduct || 0)} VND`}
              </Typography>
            </Box>
            <Button onClick={handleSubmitOrder} className="payment_method_btn_order">
              {isLoading ? __('btn_processing') : __('payment_btn_order')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </ShopPaymentMethodContainer>
  )
}
export default ShopPaymentMethod
const ShopPaymentMethodContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  '& .payment_method_list': {
    display: 'flex',
    justifyContent: 'space-around'
  },
  '& .payment_method_item': {
    width: 'auto',
    padding: 0
  },
  '& .payment_method_item:nth-child(1)': {
    paddingRight: theme.spacing(2.25)
  },
  '& .payment_method_delivery_message': {
    paddingTop: theme.spacing(1.5)
  },
  '& .payment_method_btn': {
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.75)
  },
  '& .payment_method_btn:hover,& .payment_method_btn:focus': {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  '& .payment_method_wrapper': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr'
  },
  '& .payment_method_delivery': {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    padding: theme.spacing(1, 0)
  },
  '& .payment_method_delivery_item': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: theme.spacing(0, 3)
  },
  '& .payment_method_btn_order': {
    minWidth: '150px',
    margin: 'auto',
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(8)
  },
  '& .payment_method_delivery_wrapper': {
    marginTop: theme.spacing(8)
  },
  '& .active': {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  }
}))
