import { Components } from '@mui/material'
import { CurrentTheme } from 'styles/themes'

export const defaultLabel: Components<CurrentTheme>['MuiFormLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.secondary
    }),
    filled: ({ theme }) => ({
      color: theme.palette.text.secondary
    }),
    asterisk: ({ theme }) => ({
      color: theme.palette.error.main
    })
  }
}
