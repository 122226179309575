//Library
import {memo, useCallback} from 'react'

//Interface
import {TypeTransactionUserIncome} from '../courses.interface'

//Helpers
import helpers from 'helpers'
import __ from "languages/index";

const CourseCardIncome = ({
  dataUserIncome
}: {
  dataUserIncome: Partial<TypeTransactionUserIncome>
}) => {
  const convertPercentIncome = useCallback(
    (firstData: number, secondData: number) => {
      const valueDifference = firstData - secondData
      if (firstData) {
        return parseFloat(((valueDifference / firstData) * 100)?.toFixed(2))
      }
      if ((!firstData && !secondData) || !firstData) {
        return '0'
      }
    },
    [dataUserIncome]
  )

  return (
    <>
      <div className={`coursesIncome__first--wrapper`}>
        <span className="coursesIncome__first--title">{__("course_today")}</span>
        <div className="coursesIncome__first--body">
          <span className="coursesIncome__first--value">
            {`${helpers.convertToCommasFormat(dataUserIncome?.today?.sum || '0')} ${__("currency_unit")}`}
          </span>
          <span className="coursesIncome__first--subTitle">
            {`${dataUserIncome?.today?.sum - dataUserIncome?.yesterday?.sum > 0 ? '+' : ''}${
              convertPercentIncome(dataUserIncome?.today?.sum, dataUserIncome?.yesterday?.sum) ||
              '0'
            } ${__("course_compare_by_date")}`}
          </span>
        </div>
      </div>
      <div className={`coursesIncome__first--wrapper`}>
        <span className="coursesIncome__first--title">{__("course_yesterday")}</span>
        <div className="coursesIncome__first--body">
          <span className="coursesIncome__first--value">
            {`${helpers.convertToCommasFormat(dataUserIncome?.yesterday?.sum || '0')}  ${__("currency_unit")}`}
          </span>
        </div>
      </div>
      <div className={`coursesIncome__first--wrapper statisticalMobile`}>
        <span className="coursesIncome__first--title">{__("course_week")}</span>
        <div className="coursesIncome__first--body">
          <span className="coursesIncome__first--value">
            {`${helpers.convertToCommasFormat(dataUserIncome?.current_week?.sum || '0')}  ${__("currency_unit")}`}
          </span>
        </div>
      </div>
      <div className={`coursesIncome__first--wrapper`}>
        <span className="coursesIncome__first--title">{__("course_month")}</span>
        <div className="coursesIncome__first--body">
          <span className="coursesIncome__first--value">
            {`${helpers.convertToCommasFormat(dataUserIncome?.current_month?.sum || '0')}  ${__("currency_unit")}`}
          </span>
          <span className="coursesIncome__first--subTitle">
            {`${
              dataUserIncome?.current_month?.sum - dataUserIncome?.last_month?.sum > 0 ? '+' : ''
            }${
              convertPercentIncome(
                dataUserIncome?.current_month?.sum,
                dataUserIncome?.last_month?.sum
              ) || '0'
            } ${__("course_compare_by_month")}`}
          </span>
        </div>
      </div>
      <div className={`coursesIncome__first--wrapper`}>
        <span className="coursesIncome__first--title">{__("course_last_month")}</span>
        <div className="coursesIncome__first--body">
          <span className="coursesIncome__first--value">
            {`${helpers.convertToCommasFormat(dataUserIncome?.last_month?.sum || '0')}  ${__("currency_unit")}`}
          </span>
        </div>
      </div>
    </>
  )
}

export default memo(CourseCardIncome)
