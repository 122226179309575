import '../media/css/mobile.header.scss'
import __ from "languages/index";

interface TypeMobileHeader{
  handleClickBtn: () => void
}
const MobileHeader: React.FC<TypeMobileHeader> = ({handleClickBtn})=>{
    return(
        <div className='mobile-header-container '>
            <div className='container-sm'>
                <h1 className='mobile-header-content'>
                  {__("mobile_app_easy_access")} <br/> {__("mobile_app_everywhere")} <br/> {__("mobile_app_header_sub_text")}
                </h1>
                <p style={{marginBottom: '48px'}}>{__("mobile_app_header_text")}</p>
                <button className="btn btn-primary mobile-btn" onClick={handleClickBtn}>{__("mobile_app_btn_buy")}</button>
            </div>
        </div>
    )
}
export default MobileHeader
