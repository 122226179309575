import { SvgIcon, SvgIconProps } from '@mui/material'

export default function SentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5295 5.23666C14.859 5.55795 14.8657 6.08555 14.5444 6.41508L6.64435 14.5178C6.01755 15.1607 4.99235 15.1607 4.36556 14.5178L1.07065 11.1384C0.749358 10.8089 0.75604 10.2813 1.08557 9.95998C1.4151 9.63869 1.9427 9.64537 2.26399 9.9749L5.50495 13.299L13.3511 5.25159C13.6724 4.92206 14.2 4.91537 14.5295 5.23666Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.541 7.49984C15.541 7.0396 15.9141 6.6665 16.3743 6.6665H20.1243C20.5846 6.6665 20.9577 7.0396 20.9577 7.49984C20.9577 7.96007 20.5846 8.33317 20.1243 8.33317H16.3743C15.9141 8.33317 15.541 7.96007 15.541 7.49984Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.207 10.8333C12.207 10.3731 12.5801 10 13.0404 10H16.7904C17.2506 10 17.6237 10.3731 17.6237 10.8333C17.6237 11.2936 17.2506 11.6667 16.7904 11.6667H13.0404C12.5801 11.6667 12.207 11.2936 12.207 10.8333Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.70703 14.1668C9.70703 13.7066 10.0801 13.3335 10.5404 13.3335H14.2904C14.7506 13.3335 15.1237 13.7066 15.1237 14.1668C15.1237 14.6271 14.7506 15.0002 14.2904 15.0002H10.5404C10.0801 15.0002 9.70703 14.6271 9.70703 14.1668Z"
        />
      </svg>
    </SvgIcon>
  )
}
