//Library
import {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'

//Helpers
import helpers from 'helpers/index'
import __ from "languages/index";

const ChannelModalConfirm = ({
  show,
  onClose,
  title,
  children,
  ...args
}: {
  show: boolean
  onClose: Function
  title: string
  children: JSX.Element
  args?: any
}) => {
  const [isShow, setIsShow] = useState(false)
  const [loading, setLoading] = useState(false)

  const closeOnly = useCallback(() => {
    setIsShow(false)
    onClose?.call(this, false, args)
  }, [])

  const closeAndAgree = useCallback(async () => {
    setLoading(true)
    await helpers.sleep(1000)
    setIsShow(false)
    onClose?.call(this, true, args)
    await helpers.sleep(500)
    setLoading(false)
  }, [])

  useEffect(() => {
    setIsShow(show)
  }, [show])

  return (
    <>
      <Modal show={isShow} onHide={closeOnly}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{children}</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeOnly}>
            {__("btn_close")}
          </Button>
          <Button
            variant="primary"
            className="button-event"
            disabled={loading}
            onClick={closeAndAgree}
          >
            {loading ? `${__("btn_processing")}` : `${__("btn_agree")}`}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ChannelModalConfirm

ChannelModalConfirm.defaultProps = {
  title: '',
  children: <p>{__("modal_confirm")}</p>
}
