import {useParams} from 'react-router-dom'

import Theme404 from '../../layout/404'

import DetailUser from './user.detail'
import UserSetting from './user.setting'

export default function User() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'detail'
  let ActualPage: any

  switch (Param) {
    case 'detail':
      ActualPage = DetailUser
      break
    case 'setting':
      ActualPage = UserSetting
      break
    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="mainFrame">
      <ActualPage />
    </div>
  )
}
