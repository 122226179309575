import styled from '@emotion/styled'
import { Checkbox } from '@mui/material'

const CustomCheckbox = styled(Checkbox)`
  '&:hover': {
    color: theme.palette.primary.main;
    border: 1px solid #ccc;
  }
`
export default CustomCheckbox
