//Library
import React, { useEffect, useState } from 'react'
import { Box, Chip, Divider, List, ListItem, Paper, Typography, styled } from '@mui/material'
import { ActionIcon, CheckInactiveIcon, LikeIcon, StarBadgeIcon } from 'icons'

//Store
import { useAppSelector } from 'config/store'

//Interface
import { TypedChannelLevel } from '../interface'

//Helpers
import helpers from 'helpers'
import __ from 'languages/index'
import { TypedPointData } from 'interfaces/channel.interface'

const ChannelInformation = () => {
  const level_data = useAppSelector(state => state.user.level_data)
  const channel_data = useAppSelector(state => state.user.channel_data)

  const [listLevels, setListLevels] = useState<TypedChannelLevel[]>([])
  const [pointData, setPointData] = useState<TypedPointData[]>([])
  const sortedLevels = [...listLevels].sort((a, b) => a.level_number - b.level_number)

  useEffect(() => {
    if (helpers.isEmpty(level_data)) return
    setListLevels([...level_data])
  }, [level_data])

  useEffect(() => {
    if (helpers.isEmpty(channel_data?.point_data)) return
    setPointData([...channel_data?.point_data])
  }, [channel_data?.point_data])

  return (
    <ChannelInformationContainer>
      <Box
        className="box_container"
        sx={{
          height: theme => `calc(100vh - ${theme.spacing(18.75)})`,
          overflow: 'auto',
          '::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <List className="list">
          {sortedLevels?.map((item: TypedChannelLevel, idx: number) => (
            <React.Fragment key={idx}>
              <ListItem key={`${__('leader_board_level_')}${idx + 1}`} className="item">
                <Box display={'flex'} alignItems={'center'} columnGap={theme => theme.spacing(1)}>
                  <Box className="level_number">
                    <Typography variant="body2" color="primary.dark">
                      {idx + 1}
                    </Typography>
                  </Box>
                  <Typography fontWeight={600}>{item?.title || ''}</Typography>
                </Box>

                {Number(item?.level_point) > 0 && (
                  <Chip
                    className="chip"
                    avatar={<StarBadgeIcon />}
                    label={`${helpers.convertNumber(item?.level_point || '0')}`}
                    variant="outlined"
                  />
                )}
              </ListItem>
              <Divider sx={{ borderColor: theme => theme.palette.text.disabled }} />
            </React.Fragment>
          ))}
        </List>
        <Paper className="description">
          <Box display={'grid'} rowGap={theme => theme.spacing(1)}>
            <Box display={'flex'} alignItems={'center'} columnGap={theme => theme.spacing(1.25)}>
              <LikeIcon />
              <Typography textTransform={'none'} variant="subtitle1" color="primary.main">
                {__('interact')}
              </Typography>
            </Box>
            <Typography>{__('leader_board_interact')}</Typography>
          </Box>
          <Box display={'grid'} rowGap={theme => theme.spacing(1)}>
            <Box display={'flex'} alignItems={'center'} columnGap={theme => theme.spacing(1.25)}>
              <StarBadgeIcon />
              <Typography textTransform={'none'} variant="subtitle1" color="primary.main">
                {__('accumulate')}
              </Typography>
            </Box>
            <Typography>{__('leader_board_accumulate')}</Typography>
          </Box>
        </Paper>
        <Box className="action">
          <Box display={'flex'} alignItems={'center'} columnGap={theme => theme.spacing(1.25)}>
            <ActionIcon />
            <Typography variant="subtitle1" textTransform={'none'} color="primary.main">
              {__('act')}
            </Typography>
          </Box>
          <Typography>{__('leader_board_act')}</Typography>
          <List sx={{ display: 'grid', rowGap: theme => theme.spacing(1) }}>
            {pointData?.map((item: TypedPointData, idx) => (
              <ListItem sx={{ p: 0 }} key={idx}>
                <Paper className="action_item" sx={{}}>
                  <CheckInactiveIcon
                    sx={{ width: theme => theme.spacing(2), height: theme => theme.spacing(2) }}
                    color="primary"
                  />
                  <Typography>{`${__(item?.key)} - ${item?.value} ${__('point')}`}</Typography>
                </Paper>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </ChannelInformationContainer>
  )
}

export default ChannelInformation

const ChannelInformationContainer = styled(Paper)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(2),
  padding: theme.spacing(1, 3, 3, 3),
  position: 'sticky',
  top: theme.spacing(12),
  '& .list': {
    '& hr': {
      ':last-child': {
        borderColor: 'transparent'
      }
    }
  },
  '& .item': {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    columnGap: theme.spacing(1),
    padding: theme.spacing(1.25, 0)
  },
  '& .level_number': {
    display: 'grid',
    placeItems: 'center',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    borderRadius: '50%',
    background: theme.palette.primary.backgroundHover,
    border: `2px solid ${theme.palette.primary.light}`
  },
  '& .chip': {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    justifyItems: 'center',
    background: theme.palette.background.default,
    border: 'none',
    minWidth: theme.spacing(12.5),
    color: theme.palette.primary.dark,
    fontSize: theme.spacing(0.875),
    fontWeight: 600
  },
  '& .description': {
    display: 'grid',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.background
  },
  '& .action': {
    display: 'grid',
    padding: theme.spacing(2),
    rowGap: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.text.disabled}`
  },
  '& .action_item': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    columnGap: theme.spacing(1),
    padding: theme.spacing(1.25, 2),
    backgroundColor: theme.palette.background.default
  },
  '@media(max-width: 1024px)': {
    '& .box_container': {
      height: '100%'
    }, 
    '& .description':{
      marginBottom: theme.spacing(2)
    }
  }
}))
