import {useAppSelector} from 'config/store'
import {useCallback, useEffect, useRef} from "react";
import helpers from "helpers/index";
import {useNavigate} from "react-router-dom";

interface TypedUseRedirectAuth {
  goToLogin: () => void
  getPathToLogin: () => string,
  goToRegister: () => void,
  getPathToRegister: () => string
}

const useRedirectAuth = (): TypedUseRedirectAuth => {
  const facebook_login_client_id = useAppSelector(state => state.user?.channel_data?.facebook_login_client_id)
  const google_login_client_id = useAppSelector(state => state.user?.channel_data?.google_login_client_id)
  const navigate = useNavigate();

  const refFacebookLoginClientId = useRef(facebook_login_client_id)
  const refGoogleLoginClientId = useRef(google_login_client_id)

  useEffect(() => {
    refFacebookLoginClientId.current = facebook_login_client_id;
    refGoogleLoginClientId.current = google_login_client_id;
  }, [google_login_client_id, facebook_login_client_id]);

  /**
   * Kiểm tra xem kênh có được cài đặt client ID không
   * Nếu có bất kì thì cho sang trang login riêng
   * Không có thì sang auth.appuni.io để SSO
   */

  const goToLogin = useCallback(() => {
    if (refFacebookLoginClientId.current || refGoogleLoginClientId.current) {
      navigate("/login")
    } else {
      if (helpers.isDevEnv()) {
        window.location.href = `https://dev.auth.appuni.io/login?domain=${window.location.origin}&redirect_to=${window.location.href}`
      } else {
        window.location.href = `https://auth.appuni.io/login?domain=${window.location.origin}&redirect_to=${window.location.href}`
      }
    }
  }, [])

  const getPathToLogin = useCallback(() => {
    if (refFacebookLoginClientId.current || refGoogleLoginClientId.current) {
      return "/login"
    } else {
      if (helpers.isDevEnv()) {
        return `https://dev.auth.appuni.io/login?domain=${window.location.origin}&redirect_to=${window.location.href}`
      } else {
        return `https://auth.appuni.io/login?domain=${window.location.origin}&redirect_to=${window.location.href}`

      }
    }
  }, [])

  const goToRegister = useCallback(() => {
    if (refFacebookLoginClientId.current || refGoogleLoginClientId.current) {
      navigate("/register")
    } else {
      if (helpers.isDevEnv()) {
        window.location.href = `https://dev.auth.appuni.io/register?domain=${window.location.origin}&redirect_to=${window.location.href}`
      } else {
        window.location.href = `https://auth.appuni.io/register?domain=${window.location.origin}&redirect_to=${window.location.href}`
      }
    }
  }, [])

  const getPathToRegister = useCallback(() => {
    if (refFacebookLoginClientId.current || refGoogleLoginClientId.current) {
      return "/register"
    } else {
      if (helpers.isDevEnv()) {
        return `https://adev.auth.appuni.io/register?domain=${window.location.origin}&redirect_to=${window.location.href}`
      } else {
        return `https://auth.appuni.io/register?domain=${window.location.origin}&redirect_to=${window.location.href}`
      }
    }
  }, [])

  return {
    goToLogin,
    getPathToLogin,
    goToRegister,
    getPathToRegister
  }
}

export default useRedirectAuth
