import { useAppSelector } from 'config/store'
import { TypedCustomFiled } from 'entities/challenge/challenge.interface'
import __ from 'languages/index'
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Dropdown, Form, InputGroup } from 'react-bootstrap'
import ItemCustomGame from './item.challenge.custom.game'
import { Box, Typography, styled, Button, Stack, Tooltip } from '@mui/material'
import { PlusIcon } from 'icons'

interface Props {
  listGame: TypedCustomFiled[]
  addGame: (item: TypedCustomFiled) => void
  deleteItemGame: (item: TypedCustomFiled) => void
  setName: (name: string) => void
}

const DATA_TYPE = [
  { _id: 'image', title: `${__("challenge_custom_data_type_image")}`, i: 'bi bi-image-fill' },
  { _id: 'text', title: `${__("challenge_custom_data_type_text")}`, i: 'bi bi-c-square-fill' },
  { _id: 'date', title: `${__("challenge_custom_data_type_time")}`, i: 'bi bi-calendar-event' },
  { _id: 'point', title: `${__("challenge_custom_data_type_point")}`, i: 'bi bi-9-circle' },
  { _id: 'text_link', title: `${__("challenge_custom_data_type_text_link")}`, i: 'bi bi-link-45deg fs-5' }
]

const CustomGame = (props: Props, ref) => {
  const { listGame, addGame, deleteItemGame, setName } = props
  const { cookbook } = useAppSelector(state => state.challenge)

  useImperativeHandle(ref, () => ({
    setTitleName: (name: string) => {
      setName(name)
      titleRef.current = name
      if (titleInputRef.current) {
        titleInputRef.current.value = name
      }
    },
    focusKey: () => {
      if (keyInputRef.current) {
        keyInputRef.current.focus()
      }
    },
    focusTitle: () => {
      if (titleInputRef.current) {
        titleInputRef?.current?.focus()
      }
    }
  }))

  // Refs Input
  const titleInputRef = useRef(null)
  const keyInputRef = useRef(null)
  // ==========================================================

  // Refs
  const titleRef = useRef<string>('')
  const keyRef = useRef<string>('')
  const typeRef = useRef<string>('')
  // ==========================================================

  // State
  const [validate, setValidate] = useState<boolean>(false)
  const [validateText, setValidateText] = useState<string>('')
  const [textType, setTextType] = useState<string>('')
  // ==========================================================

  // Function

  const _onTitleChange = useCallback(event => {
    titleRef.current = event.target.value
    setName(event.target.value)
  }, [])

  const _onItemTypeChoose = useCallback(item => {
    typeRef.current = item?._id
    setTextType(item?.title)
  }, [])

  const clearData = useCallback(() => {
    keyRef.current = ''
    if (keyInputRef.current) {
      keyInputRef.current.value = ''
    }
    typeRef.current = ''
    setTextType('')
    setValidate(false)
    setValidateText('')
  }, [])

  const _onDeleteGame = useCallback((item: TypedCustomFiled) => {
    deleteItemGame(item)
  }, [])

  const _onKeyChange = useCallback(event => {
    keyRef.current = event.target.value
  }, [])

  const _onAddGame = useCallback(() => {
    if (!keyRef.current) {
      setValidate(true)
      setValidateText(`${__("challenge_custom_add_game_method_scoring")}`)
      keyInputRef.current?.focus()
      return
    }
    if (!typeRef.current) {
      setValidate(true)
      setValidateText(`${__("challenge_custom_add_game_not_empty")}`)
      return
    }
    if (keyRef.current?.trim() == '') {
      setValidate(true)
      setValidateText(`${__("challenge_custom_add_game_method_scoring")}`)
      keyInputRef.current?.focus()
      return
    }
    const newItem: TypedCustomFiled = {
      _id: new Date().toISOString(),
      name: keyRef.current,
      field_type: typeRef.current
    }
    addGame(newItem)
    clearData()
  }, [])
  // ==========================================================

  // render view

  const renderFormInput = () => {
    return (
      <Form.Group style={{
        marginTop: '16px'
      }}>
        <InputGroup className='input_group'>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} className='input'>
            <Tooltip title={'Tiêu đề'}>
              <Form.Control
                ref={keyInputRef}
                placeholder={__('example_challenge_scoring_and_format')}
                onChange={_onKeyChange}
                maxLength={100}
                required={listGame.length == 0}
                id="key"
              />
            </Tooltip>
            <Dropdown>
              <Dropdown.Toggle variant={'outline-danger'} id="dropdown-basic">
                {textType || `${__("challenge_custom_form_chose_format")}`}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {DATA_TYPE.map(item => {
                  return (
                    <Dropdown.Item key={item?._id} onClick={() => _onItemTypeChoose(item)}>
                      <i className={`${item?.i} fs-5 me-2`}></i>
                      {item?.title}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Stack>

          <Button variant="outlined" className='btn_add' onClick={_onAddGame}>
            <PlusIcon
              sx={{
                width: theme => theme.spacing(2.5),
                height: theme => theme.spacing(2.5),
              }}
            />
          </Button>
          <div className="invalid-feedback">{__("challenge_custom_form_invalid")}</div>
        </InputGroup>
        {validate && <div className="text-danger mt-1">{validateText}</div>}
      </Form.Group>
    )
  }

  if (typeof cookbook !== 'string') {
    return (
      <CustomGameContainer>
        <Form.Group className="mb-3">
          <Typography textTransform={'none'} mb={2} variant='h6'>{__("challenge_custom_choose_game")}</Typography>
          <Box className="input_group" width={'100%'}>
            <Form.Control className='input' style={{
              width: '100%'
            }} disabled={true} value={cookbook?.title} />
          </Box>
        </Form.Group>
      </CustomGameContainer>
    )
  }

  return (
    <CustomGameContainer>
      <Typography variant='h6' textTransform={'none'}>{__('challenge_custom_form_title')}</Typography>
      {renderFormInput()}
      {listGame.length > 0 && (
        <div className="mt-3">
          {listGame.map(item => {
            return <ItemCustomGame key={item._id} item={item} onDelete={_onDeleteGame} />
          })}
        </div>
      )}
      <div className="mb-2 mt-2">
        <i style={{ fontSize: 12 }}>
          {__("challenge_custom_game_sub_text")}
          <br />
          <b>{__("challenge_custom_game_method_scoring")}</b> {__("challenge_custom_game_is")} <b>{__("challenge_custom_game_screenshots")}</b> {__("challenge_custom_game_and")} <b>{__("challenge_custom_game_revenue")}</b>. <br />
          {__("challenge_custom_game_with")} <b>{__("challenge_custom_game_screenshots")}</b> {__('challenge_custom_game_format_image')} <b>{__("challenge_custom_game_revenue")}</b> {__("challenge_custom_game_format_point")}
        </i>
      </div>
    </CustomGameContainer>
  )
}

export default forwardRef(CustomGame)

const CustomGameContainer = styled(Box)(({ theme }) => ({

  '& .input_group': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .input': {
      height: '52px',
      width: '80%',
      padding: theme.spacing(1, 2),
      background: '#F3F4F5',
      borderRadius: `${theme.spacing(1.5)} !important`,

      '& input, #dropdown-basic': {
        border: 'none',
        background: 'none',
        color: '#000',
        padding: 0,

        '&:focus': {
          boxShadow: 'none !important'
        }
      },

      '& input': {
        paddingRight: theme.spacing(1)
      }
    }
  },

  '& .btn_add': {
    width: '52px',
    height: '52px',
    borderRadius: `${theme.spacing(1.5)} !important`
  }
}))
