//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//Config
import { createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Helpers
import helpers from 'helpers'

export const initialState = {}

export const sendOTP = createAsyncThunk(
  'validate_code/sendOTP',
  async (params: { user_input?: string; }, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<unknown>(`/verified_code`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const checkOTP = createAsyncThunk(
  'validate_code/checkOTP',
  async (body: { verified_code?: string, session_id?: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/verified_code`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const VALIDATE_CODE_REDUCER = createEntitySlice({
  name: 'validate_code',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = VALIDATE_CODE_REDUCER.actions

export default VALIDATE_CODE_REDUCER.reducer
