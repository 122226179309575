import { Box, Button, Drawer, Icon, IconButton, InputBase, Stack, Typography, styled } from "@mui/material";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { TypedChatRoom } from "./chat.interface";
import { getListChat } from "services/chat.service";
import ArrowChevronLeftIcon from "icons/ArrowChevronLeftIcon";
import __ from "languages/index";
import { ArrowRightIcon, ChevronRightIcon, CloseIcon, EmptyIcon, ExitIcon, SearchIcon } from "icons";
import { useCallback, useEffect, useState } from "react";
import { InfiniteScrollContainer } from "components/InfiniteScrollContainer";
import ItemInbox from "./components/chat.inbox.item";
import { useAppDispatch, useAppSelector } from "config/store";
import { viewMessageOfClient } from "./store/chat.store.reducer";
import { socket } from "config/socket.io";
import __helpers from "helpers/index";
import { useNavigate } from "react-router-dom";
import ChatEmptyComponent from "./components/chat.empty";
import ClearIcon from "icons/ClearIcon";
import imageDefault from 'media/images/image_default.png'
import ComponentEmpty from "components/componentEmpty";

// only mobile
const ChatList = () => {
    const [tabIndex, setTabIndex] = useState<number>(0)
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const user_data = useAppSelector(state => state.user.user_data)
    const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number>(0)
    const [isSearchChat, setIsSearchChat] = useState(false);
    const [textSearch, setTextSearch] = useState<string>('')

    useEffect(() => {
        try {
            socket.on('msgToUser', data => {
                if (!__helpers.isJson(data)) return
                let dataParse = JSON.parse(data)

                if (user_data?._id !== dataParse?.createBy?._id) {
                    setNumberOfUnreadMessages(numberOfUnreadMessages => numberOfUnreadMessages + 1)
                }
            })
        } catch (error) {
            console.log(`msgToUser_${error}`)
        }
    }, [socket])

    const {
        hasMore,
        isRefreshing,
        loadMore,
        updateParamRequest,
        updateParamRequestDebounce,
        refresh,
        data,
        updateItem
    } = useInfiniteScroll<TypedChatRoom>({
        limit: 12,
        search: '',
        read_count: ''
    }, getListChat, true)

    const handleChangeTab = (index: number) => {
        setTabIndex(index)
        let statusMessage = ''
        switch (index) {
            case 0:
                statusMessage = ''
                break
            case 1:
                statusMessage = 'read'
                break
            case 2:
                statusMessage = 'unread'
                break

            default:
                break
        }
        updateParamRequest({
            read_count: statusMessage
        })
    }

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(e.target.value)
        updateParamRequestDebounce({
            search: e.target.value
        })
    }


    const handleOpenChatDetail = useCallback((idChatRoom: string) => {
        navigate(`/chat/detail/${idChatRoom}`)
    }, [])

    const handleCloseSearch = () =>{
        updateParamRequest({
            search: ""
        })

        setIsSearchChat(false)
    }

    return (
        <ChatListContainer>
            <Stack className="header-chat">
                <IconButton>
                    <ArrowChevronLeftIcon onClick={() => navigate('/')} width="24px" height="24px" />
                </IconButton>
                <Typography fontSize="20px" width={'100%'} noWrap textAlign={'center'} fontWeight={600} color="text.primary">
                    {__('header_chat')}
                </Typography>
                <IconButton onClick={() => setIsSearchChat(true)}>
                    <SearchIcon width="24px" height="24px" />
                </IconButton>
            </Stack>
            <Box className="chats">
                <Box className="filter chatDetail_list_message_button" >
                    <Typography
                        role="button"
                        onClick={() => handleChangeTab(0)}
                        component={'button'}
                        className={`${tabIndex === 0 && 'chatDetail_active'}`}
                    >
                        {__('chat_all')}
                    </Typography>
                    <Typography
                        role={'button'}
                        onClick={() => handleChangeTab(1)}
                        component={'button'}
                        className={`${tabIndex === 1 && 'chatDetail_active'}`}
                    >
                        {__('chat_read')}
                    </Typography>
                    <Typography
                        role={'button'}
                        onClick={() => handleChangeTab(2)}
                        component={'button'}
                        className={`${tabIndex === 2 && 'chatDetail_active'}`}
                    >
                        {__('chat_unread')}
                    </Typography>
                </Box>


                {
                    !data?.length ?

                        <ChatEmptyComponent /> :

                        <InfiniteScrollContainer
                            isRefreshing={isRefreshing}
                            dataLength={data.length}
                            next={loadMore}
                            hasMore={hasMore}
                            refreshFunction={refresh}
                        >
                            <Box className="list chatDetail_list_message_content">
                                {data.map((item: TypedChatRoom, idx: number) => (
                                    <Box onClick={() => handleOpenChatDetail(item?.chat_room_id?._id)} key={`itemInbox_${idx}`} >
                                        <ItemInbox dataChatRoom={item} />
                                    </Box>
                                ))}
                            </Box>
                        </InfiniteScrollContainer>
                }
            </Box>

            <DrawerSearchChat
                PaperProps={{
                    sx: {
                        width: '100%',
                        borderRadius: '0'
                    }
                }}
                elevation={10}
                onClose={handleCloseSearch}
                anchor="right"
                open={isSearchChat}
            >
                <Stack className="search">
                    <Box className="input">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            value={textSearch || ''}
                            onChange={handleChangeValue}
                            placeholder={__('chat_search_in_messages')}
                            className="messageContainer_modal_input_search"
                        />
                        <IconButton onClick={() => setTextSearch('')}>
                            <ClearIcon />
                        </IconButton>
                    </Box>
                    <Button onClick={handleCloseSearch} variant="text" >
                        Hủy
                    </Button>
                </Stack>

                {
                    textSearch?.length ? (
                        data?.length ? data.map((dataChatRoom) => {
                            return (
                                <Stack p={theme => theme.spacing(1, 2)} onClick={() => handleOpenChatDetail(dataChatRoom?.chat_room_id?._id)} className="item_search">
                                    <Stack flexDirection={"row"} alignItems={"center"} >
                                        <img
                                            className="itemInbox_avatar_client"
                                            src={dataChatRoom?.partner_id?.user_avatar || imageDefault}
                                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                                                (e.currentTarget.src = imageDefault)
                                            }
                                            alt="avatar_client"
                                        />
                                        <Typography sx={{ margin: theme => theme.spacing(0, 2) }} component={'p'} className="itemInbox_content_name_client fw-bold">
                                            {dataChatRoom?.partner_id?.display_name || ''}
                                        </Typography>
                                    </Stack>

                                    <ChevronRightIcon />
                                </Stack>
                            )
                        }) : (
                            <Box
                                sx={{
                                    display: 'grid',
                                    placeItems: 'center',
                                    padding: theme => theme.spacing(2)
                                }}
                            >
                                <EmptyIcon
                                    sx={{
                                        width: '150px',
                                        height: '150px'
                                    }}
                                />
                                <Typography sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        width: '100%'
                                }} color={'text.disabled'} fontWeight={400}>
                                    {__('search_chat_empty') + textSearch }
                                </Typography> 
                            </Box>
                        )
                    ) : null
                }
            </DrawerSearchChat>
        </ChatListContainer>
    )
}

export default ChatList;

const ChatListContainer = styled(Box)(({ theme }) => ({
    background: '#fff',
    minHeight: '100vh',
    '& .header-chat': {
        position: 'sticky',
        top: 0,
        flexDirection: 'row',
        background: '#fff',
        zIndex: 1,
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: theme.spacing(1)
    },
    '& .chats': {

        '& .filter': {
            padding: theme.spacing(1)
        },
    }
}))

const DrawerSearchChat = styled(Drawer)(({ theme }) => ({
    '& .search': {
        padding: theme.spacing(1, 2),
        display: 'grid',
        gridTemplateColumns: '85% 15%',

        '& .input': {
            padding: theme.spacing(1, 1.5),
            borderRadius: theme.spacing(1.5),
            background: 'var(--Other-BG, #F3F4F5)',
            display: 'flex',
            justifyContent: 'space-between'
        },

    },
    
    '& .item_search': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center"
    }
}))