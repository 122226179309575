import React, { useMemo } from 'react'
import { displayTime } from 'podcast/utils/displayTime'
import { getTimeFromSeconds } from 'podcast/utils/getTimeFromSeconds'
import { Stack } from 'react-bootstrap'

type Props = {
  currentTime: number
  duration: number
}

const PodcastCountdown = ({ currentTime, duration }: Props) => {
  const time = getTimeFromSeconds(currentTime)
  const endTime = useMemo(() => getTimeFromSeconds(duration), [duration])
  return (
    <Stack direction="horizontal" className="podcast-player__countdown">
      <div>{`${time?.hours > 0 ? time.hours + ':' : ''}${displayTime(time.minutes)}:${displayTime(
        Math.round(time.seconds)
      )}`}</div>
      <span> / </span>
      <div>
        {`${endTime?.hours > 0 ? endTime.hours + ':' : ''}${displayTime(
          endTime.minutes
        )}:${displayTime(Math.round(endTime.seconds))}`}
      </div>
    </Stack>
  )
}

export default PodcastCountdown
