import { Box, CircularProgress, IconButton, InputBase, Paper, Stack, styled } from "@mui/material"
import { AxiosError, AxiosResponse } from "axios";
import { useAppDispatch } from "config/store";
import { TypedProduct } from "entities/shop/interface";
import { getProductOfShop } from "entities/shop/store";
import { SearchIcon } from "icons"
import __ from "languages/index";
import debounce from "lodash.debounce";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"
interface TypedProps {
    handleSelectItemProduct: (product: TypedProduct) => void;
    stock_id: string
}
const BoxSuggestCreateProduct = ({ handleSelectItemProduct, stock_id }: TypedProps) => {
    const searchProductRef = useRef(null);
    const [textSearch, setTextSearch] = useState<string>('');
    const [isLoadingProduct, setIsLoadingPRoduct] = useState(false);
    const dispatch = useAppDispatch();
    const [suggestionProductData, setSuggestionProductData] = useState<TypedProduct[]>([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchProductRef.current && !searchProductRef.current.contains(event.target)) {
                setSuggestionProductData(null)
                setTextSearch('')
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);


        };
    }, []);

    const handleValueSearch = useMemo(() => {
        return debounce((e: ChangeEvent<HTMLInputElement>) => {
            getProducts({
                search: e.target.value
            })

        }, 500)
    }, [stock_id])

    const getProducts = ({ search }: { search: string }) => {
        setIsLoadingPRoduct(true)
        dispatch(getProductOfShop({ product_status: 1, limit: 12, query: search, [`product_to_stock.stock_id`]: stock_id }))
            .unwrap()
            .then((res: AxiosResponse) => {
                if (res.data?.length) {
                    const productAvailableInStock = [];
                    // check product available in stock
                    res.data.forEach((product) => {
                        const findStock = product?.product_to_stock.find((item) => item.stock_id === stock_id);
                        if (findStock && findStock.product_quantity > 0) {
                            productAvailableInStock.push(product)
                        }
                    })
                    setSuggestionProductData([...productAvailableInStock])
                }
                setIsLoadingPRoduct(false)

            })
            .catch((error: AxiosError) => {
                console.log(`getProductOfShop error >>>`, error)
                setIsLoadingPRoduct(false)

            })
    }

    const handleSelectItem = (product: TypedProduct) => {
        setSuggestionProductData(null)
        setTextSearch('')
        handleSelectItemProduct(product)
    }
    return (
        <BoxSearchContainer ref={searchProductRef} className="box_search">
            <IconButton aria-label="search" sx={{ padding: 0 }}>
                <SearchIcon color="inherit" />
            </IconButton>
            <InputBase
                value={textSearch}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setTextSearch(e.target.value)
                    handleValueSearch(e)
                }}
                sx={{
                    width: '100%'
                }}
                placeholder={__('search_add_order')}
                inputProps={{ 'aria-label': __('search_add_order') }}
                className="search_input"
            />
            {isLoadingProduct ?
                <CircularProgress color="inherit" size={20} /> : null}
            {
                suggestionProductData?.length ? (
                    <Paper className="list_item" elevation={3}>
                        {
                            suggestionProductData.map((res) => (
                                <Box key={res.product_id} onClick={() => handleSelectItem(res)} className="item_product">
                                    {res.product_name}
                                </Box>
                            ))
                        }

                    </Paper>
                ) : null
            }
        </BoxSearchContainer>
    )
}

export default BoxSuggestCreateProduct

const BoxSearchContainer = styled(Stack)(({ theme }) => ({
    background: '#F3F4F5',
    padding: theme.spacing(1.5, 2),
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.spacing(1.5),
    marginLeft: theme.spacing(2.5),
    width: '50%',
    position: 'relative',

    '& .search_input': {
        paddingLeft: theme.spacing(2)
    },

    '& .list_item': {
        position: 'absolute',
        width: '100%',
        top: theme.spacing(5.8),
        left: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: '16px',
        minHeight: theme.spacing(3),
        zIndex: 1,
        maxHeight: '300px',
        overflowY: 'scroll',

        '& .item_product': {
            padding: theme.spacing(1, 2),

            '&:hover': {
                backgroundColor: "rgba(220, 53, 69, 0.08)",
                cursor: 'pointer'
            }
        }
    }
}))