import React from 'react'
import { Avatar, AvatarProps } from '@mui/material'
import { useAppSelector } from 'config/store'

type Props = Omit<AvatarProps, 'src'>
const AvatarCurrentUser = (props: Props) => {
  const { user_data } = useAppSelector(state => state.user)

  return <Avatar src={user_data?.user_avatar || user_data?.user_avatar_thumbnail} {...props} />
}

export default AvatarCurrentUser
