import React from "react";
import {TypedLiveChat} from "entities/livestream/livestream.interface";
import '../media/livestream.livechat.item.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserGear} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import helpers from "helpers/index";


export default function LivestreamLivechatItem({item, isAdmin = false}: { item: TypedLiveChat, isAdmin?: boolean }) {

  return (
    <div className="livestream_chatitem_container">
      <a target={"_blank"} href={helpers.getRootDomain("/user/detail/") + item?.createBy?._id} className="livestream_chatitem_avatar">
        <img className="image" src={item?.createBy?.user_avatar || item?.createBy?.user_avatar_thumbnail}/>
      </a>

      <div className="livestream_chatitem_container_content w-100">
        <a target={"_blank"} href={helpers.getRootDomain("/user/detail/") + item?.createBy?._id} className={`livestream_chatitem_txtname ${isAdmin && 'is_admin'}`}>{isAdmin && <FontAwesomeIcon icon={faUserGear} className="me-2" />}{item?.createBy?.display_name}</a>
        <div className="livestream_chatitem_txtcontent text-break">{item?.chat_content}</div>
      </div>
    </div>
  )
}
