import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ActionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_2198_52034)">
          <path
            d="M16.1238 8.38977L17.3449 10.9615C17.4073 11.0977 17.5024 11.2164 17.6216 11.307C17.7408 11.3977 17.8806 11.4575 18.0285 11.4812L20.7372 11.8905C20.9098 11.9247 21.0698 12.0051 21.2003 12.1231C21.3308 12.2411 21.4268 12.3923 21.4782 12.5606C21.5296 12.7288 21.5344 12.9079 21.492 13.0787C21.4497 13.2494 21.3619 13.4055 21.2379 13.5303L19.2931 15.4996C19.1847 15.6112 19.1042 15.7469 19.0581 15.8955C19.0119 16.0441 19.0014 16.2015 19.0274 16.3549L19.4905 19.1492C19.527 19.3189 19.5148 19.4955 19.4552 19.6586C19.3956 19.8217 19.2911 19.9646 19.1537 20.0707C19.0163 20.1769 18.8517 20.242 18.6789 20.2586C18.5061 20.2751 18.3321 20.2424 18.1771 20.1643L15.7295 18.8273C15.6013 18.7569 15.4574 18.72 15.3111 18.72C15.1649 18.72 15.021 18.7569 14.8928 18.8273L12.4456 20.1647C12.2906 20.243 12.1165 20.2758 11.9435 20.2593C11.7706 20.2428 11.6058 20.1776 11.4684 20.0714C11.3309 19.9652 11.2264 19.8222 11.1668 19.659C11.1072 19.4958 11.0951 19.319 11.1318 19.1492L11.5928 16.3549C11.6188 16.2015 11.6083 16.0441 11.5621 15.8955C11.516 15.7469 11.4355 15.6112 11.3271 15.4996L9.38231 13.5303C9.25832 13.4055 9.17049 13.2494 9.12817 13.0787C9.08585 12.9079 9.09064 12.7288 9.14201 12.5606C9.19339 12.3923 9.28943 12.2411 9.41991 12.1231C9.5504 12.0051 9.71044 11.9247 9.883 11.8905L12.5917 11.4812C12.7396 11.4575 12.8794 11.3977 12.9986 11.307C13.1179 11.2164 13.2129 11.0977 13.2753 10.9615L14.4964 8.38977C14.5657 8.23164 14.6795 8.09712 14.824 8.00267C14.9686 7.90821 15.1375 7.85791 15.3101 7.85791C15.4828 7.85791 15.6517 7.90821 15.7962 8.00267C15.9407 8.09712 16.0545 8.23164 16.1238 8.38977Z"
            fill="url(#paint0_linear_2198_52034)"
          />
          <path
            d="M19.2961 15.4991C19.1875 15.611 19.1067 15.7468 19.06 15.8955C19.0133 16.0442 19.002 16.2018 19.0271 16.3557L19.4905 19.1488C19.5271 19.3188 19.5148 19.4958 19.4551 19.6591C19.3954 19.8225 19.2907 19.9657 19.1531 20.0721C19.0156 20.1785 18.8507 20.2439 18.6776 20.2606C18.5044 20.2774 18.3301 20.2448 18.1747 20.1667L15.7292 18.8277C15.4685 18.687 15.154 18.687 14.8933 18.8277L12.4478 20.1684C12.2924 20.2465 12.118 20.279 11.9449 20.2623C11.7718 20.2455 11.6069 20.1802 11.4693 20.0737C11.3317 19.9673 11.2271 19.8242 11.1674 19.6608C11.1077 19.4974 11.0954 19.3205 11.1319 19.1504L11.2354 18.5339C17.3802 14.818 16.4243 9.78216 15.8657 8.05664C15.9749 8.14768 16.0627 8.26271 16.1223 8.39181L17.3429 10.9656C17.4071 11.1009 17.5031 11.2187 17.6227 11.3088C17.7424 11.3989 17.8821 11.4586 18.0298 11.4828L20.736 11.8925C20.9085 11.9267 21.0685 12.007 21.199 12.1248C21.3294 12.2427 21.4255 12.3938 21.4768 12.562C21.5282 12.7301 21.533 12.9091 21.4907 13.0797C21.4485 13.2504 21.3607 13.4064 21.2367 13.5311L19.2961 15.4991Z"
            fill="url(#paint1_linear_2198_52034)"
          />
          <path
            d="M8.92536 0.42326L9.90233 2.48271C10.0045 2.70285 10.2094 2.85802 10.4489 2.8965L12.6156 3.22381C12.7536 3.2512 12.8816 3.31551 12.986 3.40988C13.0904 3.50425 13.1673 3.62516 13.2084 3.75974C13.2495 3.89432 13.2534 4.03753 13.2196 4.17414C13.1859 4.31075 13.1157 4.43565 13.0165 4.53554L11.4623 6.11043C11.3757 6.19982 11.3113 6.30836 11.2744 6.42727C11.2375 6.54618 11.2292 6.67211 11.2501 6.79485L11.6187 9.02933C11.6478 9.16506 11.6379 9.30626 11.5902 9.43662C11.5425 9.56698 11.4589 9.68119 11.3491 9.76607C11.2392 9.85095 11.1076 9.90304 10.9694 9.91633C10.8313 9.92963 10.6921 9.90358 10.5681 9.84119L8.61005 8.77236C8.50763 8.71578 8.39252 8.6861 8.2755 8.6861C8.15848 8.6861 8.04338 8.71578 7.94095 8.77236L5.9833 9.84202C5.85929 9.9044 5.72016 9.93045 5.58198 9.91716C5.4438 9.90387 5.3122 9.85178 5.20235 9.7669C5.09251 9.68202 5.00891 9.56781 4.96119 9.43745C4.91347 9.30709 4.90359 9.16589 4.93267 9.03016L5.30136 6.79567C5.32223 6.67294 5.31389 6.54701 5.27701 6.4281C5.24013 6.30919 5.17575 6.20064 5.08909 6.11126L3.53447 4.53554C3.43534 4.43565 3.36515 4.31075 3.33136 4.17414C3.29757 4.03753 3.30145 3.89432 3.34259 3.75974C3.38373 3.62516 3.46059 3.50425 3.56498 3.40988C3.66937 3.31551 3.7974 3.2512 3.93543 3.22381L6.10205 2.8965C6.22007 2.87753 6.33163 2.82988 6.42693 2.75773C6.52224 2.68558 6.59838 2.59115 6.64867 2.48271L7.62564 0.425329C7.68109 0.299297 7.77197 0.192094 7.88722 0.116757C8.00248 0.0414206 8.13714 0.00119665 8.27483 0.000977463C8.41252 0.00075828 8.54731 0.0405533 8.6628 0.115523C8.77829 0.190492 8.86951 0.297405 8.92536 0.42326Z"
            fill="url(#paint2_linear_2198_52034)"
          />
          <path
            d="M11.4621 6.10976C11.3753 6.1993 11.3107 6.30789 11.2733 6.42687C11.2359 6.54585 11.2269 6.67191 11.247 6.795L11.6194 9.02948C11.6486 9.16548 11.6387 9.30696 11.591 9.43761C11.5433 9.56827 11.4596 9.68276 11.3496 9.76789C11.2396 9.85302 11.1077 9.90532 10.9693 9.91876C10.8308 9.93219 10.6914 9.90622 10.5671 9.84383L8.60943 8.7721C8.50678 8.71669 8.39195 8.68768 8.2753 8.68768C8.15864 8.68768 8.04381 8.71669 7.94116 8.7721L5.98474 9.84465C5.86042 9.90705 5.721 9.93302 5.58255 9.91959C5.4441 9.90615 5.31227 9.85385 5.20226 9.76872C5.09226 9.68359 5.00856 9.56909 4.96082 9.43844C4.91309 9.30779 4.90326 9.16631 4.93247 9.03031L5.01523 8.53707C9.93109 5.56355 9.16557 1.53569 8.71867 0.155273C8.80681 0.228101 8.87716 0.319963 8.92557 0.423411L9.90047 2.48245C10.0047 2.70217 10.21 2.85693 10.45 2.89624L12.6145 3.22396C12.7525 3.25126 12.8805 3.31548 12.9849 3.40979C13.0893 3.5041 13.1661 3.62496 13.2073 3.7595C13.2484 3.89403 13.2522 4.0372 13.2184 4.17374C13.1845 4.31029 13.1143 4.43509 13.0151 4.53486L11.4621 6.10976Z"
            fill="url(#paint3_linear_2198_52034)"
          />
          <path
            d="M18.8441 18.19L21.9393 15.5996C22.1852 15.3932 22.5011 15.2893 22.8215 15.3096C23.1419 15.33 23.4422 15.4729 23.66 15.7088C23.8778 15.9447 23.9964 16.2554 23.9911 16.5764C23.9858 16.8975 23.8571 17.2041 23.6317 17.4327L18.4676 22.6921C18.1761 22.9877 17.7819 23.1596 17.3669 23.1721H9.89379C9.3651 23.1728 8.84135 23.2739 8.35035 23.47L7.03448 23.9996H0V23.1721C0 23.1721 2.06897 21.5169 2.89655 20.6893C6.2069 17.3789 8.67724 16.9652 10.3324 16.9652C11.0029 16.9628 11.6531 17.1956 12.1697 17.6231C12.3116 17.7361 12.4887 17.7961 12.6703 17.7927H16.9324C17.1813 17.828 17.4222 17.9065 17.6441 18.0245C17.7083 18.07 17.7691 18.1196 17.8262 18.1734C17.9863 18.3365 18.103 18.5372 18.1655 18.7569L18.8441 18.19Z"
            fill="url(#paint4_linear_2198_52034)"
          />
          <path
            d="M19.0306 18.9435C19.0466 19.1682 19.0159 19.3938 18.9404 19.606C18.8649 19.8182 18.7463 20.0125 18.592 20.1766C18.435 20.3396 18.2466 20.469 18.0383 20.5572C17.8299 20.6454 17.6058 20.6905 17.3796 20.6897H12.8279C12.7181 20.6897 12.6129 20.6461 12.5353 20.5685C12.4577 20.4909 12.4141 20.3857 12.4141 20.2759C12.4141 20.1662 12.4577 20.061 12.5353 19.9834C12.6129 19.9057 12.7181 19.8622 12.8279 19.8622H17.3796C17.4938 19.8634 17.607 19.8413 17.7123 19.7972C17.8176 19.7531 17.9128 19.6879 17.992 19.6056C18.1459 19.4401 18.2237 19.2183 18.2072 18.9932C18.1998 18.9136 18.1859 18.8347 18.1658 18.7573C18.1616 18.7573 18.8444 18.1904 18.8444 18.1904C18.9536 18.4275 19.017 18.6828 19.0306 18.9435Z"
            fill="#E35D6A"
          />
          <path
            d="M19 1.0759L19.5698 2.2759C19.6294 2.40459 19.749 2.49562 19.8888 2.51838L21.153 2.70955C21.2332 2.72596 21.3075 2.7637 21.3681 2.81882C21.4287 2.87393 21.4733 2.94437 21.4972 3.0227C21.5211 3.10104 21.5234 3.18437 21.5039 3.26391C21.4844 3.34346 21.4438 3.41626 21.3863 3.47466L20.4797 4.39328C20.3771 4.49879 20.3308 4.64735 20.3556 4.79259L20.5703 6.09645C20.5873 6.17563 20.5816 6.258 20.5537 6.33405C20.5259 6.41009 20.4771 6.47673 20.4131 6.52626C20.349 6.57579 20.2723 6.6062 20.1916 6.61398C20.111 6.62176 20.0299 6.6066 19.9575 6.57024L18.8159 5.94583C18.7561 5.91305 18.689 5.89587 18.6208 5.89587C18.5526 5.89587 18.4855 5.91305 18.4257 5.94583L17.2836 6.57024C17.2112 6.6066 17.1301 6.62176 17.0495 6.61398C16.9689 6.6062 16.8921 6.57579 16.828 6.52626C16.764 6.47673 16.7152 6.41009 16.6874 6.33405C16.6596 6.258 16.6538 6.17563 16.6708 6.09645L16.8859 4.79259C16.9108 4.64735 16.8644 4.49879 16.7618 4.39328L15.8552 3.47466C15.7977 3.41624 15.7572 3.3434 15.7377 3.26383C15.7182 3.18426 15.7205 3.1009 15.7445 3.02256C15.7684 2.94421 15.8131 2.87378 15.8737 2.81869C15.9343 2.7636 16.0087 2.7259 16.089 2.70955L17.3527 2.51838C17.493 2.49562 17.6126 2.405 17.6717 2.2759L18.2415 1.0759C18.2737 1.00211 18.3268 0.939318 18.3941 0.895221C18.4615 0.851125 18.5403 0.827637 18.6208 0.827637C18.7013 0.827637 18.78 0.851125 18.8474 0.895221C18.9148 0.939318 18.9678 1.00211 19 1.0759Z"
            fill="url(#paint5_linear_2198_52034)"
          />
          <path
            d="M20.4796 4.39176C20.4292 4.44401 20.3917 4.50734 20.3701 4.57668C20.3486 4.64602 20.3435 4.71944 20.3555 4.79107L20.5719 6.09452C20.589 6.17389 20.5832 6.25647 20.5554 6.33272C20.5275 6.40898 20.4787 6.47581 20.4145 6.52548C20.3503 6.57516 20.2733 6.60565 20.1925 6.61345C20.1117 6.62125 20.0303 6.60603 19.9578 6.56955L18.8149 5.9439C18.755 5.91152 18.6881 5.89457 18.62 5.89457C18.5519 5.89457 18.485 5.91152 18.4251 5.9439L17.2839 6.56955C17.2113 6.60603 17.13 6.62125 17.0492 6.61345C16.9684 6.60565 16.8914 6.57516 16.8272 6.52548C16.763 6.47581 16.7141 6.40898 16.6863 6.33272C16.6584 6.25647 16.6527 6.17389 16.6698 6.09452L16.7182 5.80693C19.5854 4.07314 19.1393 1.72321 18.8786 0.917969C18.9296 0.960714 18.9705 1.0141 18.9986 1.07438L19.568 2.27562C19.6288 2.4039 19.7488 2.49411 19.8887 2.51687L21.1512 2.70804C21.2314 2.72443 21.3057 2.76214 21.3662 2.8172C21.4268 2.87226 21.4714 2.94263 21.4953 3.02091C21.5192 3.09918 21.5216 3.18246 21.5022 3.26197C21.4828 3.34149 21.4423 3.41429 21.385 3.47273L20.4796 4.39176Z"
            fill="url(#paint6_linear_2198_52034)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2198_52034"
            x1="9.09961"
            y1="20.2634"
            x2="21.3797"
            y2="20.2634"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2198_52034"
            x1="11.1113"
            y1="20.2665"
            x2="21.4012"
            y2="20.2665"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2198_52034"
            x1="3.30859"
            y1="9.92054"
            x2="13.1298"
            y2="9.92054"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2198_52034"
            x1="4.91602"
            y1="9.92303"
            x2="13.1468"
            y2="9.92303"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2198_52034"
            x1="11.995"
            y1="23.4456"
            x2="11.995"
            y2="14.2812"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="0.11" stopColor="#FFA200" />
            <stop offset="0.37" stopColor="#FFB300" />
            <stop offset="0.65" stopColor="#FFBD00" />
            <stop offset="1" stopColor="#FFC100" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2198_52034"
            x1="15.7246"
            y1="6.61597"
            x2="21.4513"
            y2="6.61597"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2198_52034"
            x1="16.6602"
            y1="6.61544"
            x2="21.4601"
            y2="6.61544"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A51C30" />
            <stop offset="1" stopColor="#DC3545" />
          </linearGradient>
          <clipPath id="clip0_2198_52034">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
