import { TypedCustomFiled } from 'entities/challenge/challenge.interface'
import React, { useCallback } from 'react'
import __ from "languages/index";
import { Button, Stack, Typography, styled } from '@mui/material';
import { TrashIcon } from 'icons';

interface Props {
  item: TypedCustomFiled
  onDelete?: (item: TypedCustomFiled) => void
}

const ItemCustomGame = (props: Props) => {
  const { item, onDelete } = props

  // function

  const renderTextType = useCallback((type: string) => {
    if (type == 'text') {
      return `${__("challenge_custom_data_type_text")}`
    }
    if (type == 'point') {
      return `${__("challenge_custom_data_type_point")}`
    }
    if (type == 'date') {
      return `${__('challenge_custom_data_type_date')}`
    }
    if (type == 'image') {
      return `${__("challenge_custom_data_type_image")}`
    }
    if (type == 'text_link') {
      return `${__("challenge_custom_data_type_link")}`
    }
    return ''
  }, [])

  const _onDeleteGame = useCallback((item: TypedCustomFiled) => {
    onDelete(item)
  }, [])

  // =======================================

  return (
    <ItemCustomGameContainer mt={2} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} key={item?._id || item?.name}>
      <Stack className='box_info' flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography>{item?.name}</Typography>
        <Typography>{renderTextType(item?.field_type)}</Typography>
      </Stack>

      <Button variant="outlined" className="btn_remove" onClick={() => _onDeleteGame(item)}>
        <TrashIcon />
      </Button>

    </ItemCustomGameContainer>
  )
}

export default ItemCustomGame

const ItemCustomGameContainer = styled(Stack)(({ theme }) => ({
  '& .box_info': {
    height: '52px',
    width: '80%',
    padding: theme.spacing(1, 2),
    background: '#F3F4F5',
    borderRadius: `${theme.spacing(1.5)} !important`,
  },

  '& .btn_remove': {
    width: '52px',
    height: '52px'
  }
}))
