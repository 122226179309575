import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "config/store";
import {clearError} from "entities/gift/gift.store.reducer";
import {Link} from "react-router-dom";
import './media/gift.receiver.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Form, Tab, Table, Tabs} from "react-bootstrap";
import {
  EnumGiftSendStatusType,
  GIFT_RECEIVER_SORT_OPTIONS,
  GIFT_SEND_STATUS,
  GIFT_SEND_STATUS_USER_ROLE
} from "entities/gift/constants/gift.constant";
import GiftDetailModal from "entities/gift/components/gift.detail.modal";
import {TypedGift, TypedGiftTransaction} from "entities/gift/gift.interface";
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll";
import GiftReceiverSkeleton from "entities/gift/skeletons/gift.receiver.skeleton";
import {getReceiversForTab} from "entities/gift/services/gift.receiver.service";
import debounce from "lodash.debounce";
import GiftReceiverItiemTable from "./components/gift.receiver.item.table";
import Title from "components/Title";
import __ from "languages/index";
import helpers from 'helpers'
import _Helmet from "components/helmet";
import BigLayout from "../../layouts/bigLayout";
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";
import { formatString } from 'entities/support/utils'

export default function GiftReceiver() {

  const user_data = useAppSelector(state => state.user.user_data);
  const permission_data = useAppSelector(state => state.user.permission_data);
  const dispatch = useAppDispatch();
  const refGiftDetailModal = useRef<any>()
  const [keyActiveTab, setKeyActiveTab] = useState(helpers.current_user_can("boss", permission_data) ? EnumGiftSendStatusType.All : EnumGiftSendStatusType.Mine)
  const [totalGift, setTotalGift] = useState({})
  const refValueSearch = useRef("")
  const [searchParam, setSearchParam] = useState("")
  const [sortParam, setSortParam] = useState({
    order_type: "time",
    order_by: "DESC"
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(clearError());
  }, []);

  useEffect(() => {
    setKeyActiveTab(helpers.current_user_can("boss", permission_data) ? EnumGiftSendStatusType.All : EnumGiftSendStatusType.Mine)
  }, [permission_data]);


  const showDetailGift = useCallback((item: TypedGift) => {
    refGiftDetailModal.current?.show?.(item)
  }, [])

  const RenderTabReceiver = useCallback(({activeTab, sortParamGift, searchParamGift, value}:{activeTab: EnumGiftSendStatusType, sortParamGift: object, searchParamGift: string, value: EnumGiftSendStatusType}) => {

    const {
      data,
      hasMore,
      totalItems,
      setParamRequest,
      loadMore,
      isRefreshing,
      refresh
    } = useInfiniteScroll<TypedGiftTransaction>({
      limit: 20,
      gift_status: value === EnumGiftSendStatusType.All || value === EnumGiftSendStatusType.Mine ? null : value,
      user_id: value === EnumGiftSendStatusType.Mine ? user_data?._id : null
    }, getReceiversForTab, false)

    const [listIdsRemoved, setListIdsRemoved] = useState([])

    const onRemoveItem = useCallback((id: string) => {
      if (value !== EnumGiftSendStatusType.All) {
        setListIdsRemoved(old => [...old, id])
      }
    }, [])

    useEffect(() => {
      setTotalGift(old => ({
        ...old,
        [value]: totalItems
      }))
    }, [totalItems]);

    useEffect(() => {
      if (activeTab === value) {
        setParamRequest({
          limit: 20,
          gift_status: value === EnumGiftSendStatusType.All || value === EnumGiftSendStatusType.Mine ? null : value,
          user_id: value === EnumGiftSendStatusType.Mine ? user_data?._id : null,
          search: searchParamGift,
          ...sortParamGift,
        })
      }
    }, [activeTab, sortParamGift, searchParamGift]);

    return (
      <div>
        <InfiniteScrollContainer
          isRefreshing={isRefreshing}
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          refreshFunction={refresh}
          pullDownToRefresh={false}
          skeleton={<GiftReceiverSkeleton/>}
          showEndMessage={Boolean(!data.length)}
          textEmptyMessage= {formatString(__('data_empty'), `${__('gift_title').toLowerCase()}`)}

        >
          <Table hover  responsive="sm" style={{textAlign: "start"}}>
            <thead>
            <tr >
              <th className="text-center">{__("gift_table_stt")}</th>
              <th style={{paddingLeft: 40}}>{__("gift_table_receiver")}</th>
              <th  style={{minWidth:100}}>{__("gift_table_present")}</th>
              <th  style={{minWidth:100, textAlign: 'end'}}>{__("gift_table_value")}</th>
              <th  style={{minWidth:100, textAlign: 'end'}}>{__("gift_table_time")}</th>
              <th  style={{minWidth:100}}>{__("gift_table_image")}</th>
            </tr>
            </thead>
            <tbody>
            {data.map((itemGiftTransaction,index) => {
              if (listIdsRemoved.includes(itemGiftTransaction?._id)) {
                return null
              }

              return <GiftReceiverItiemTable key={`giftreceiver_loop_${itemGiftTransaction?._id}`}
                                             item={itemGiftTransaction}
                                             isMine={value === EnumGiftSendStatusType.Mine}
                                             hasPermissionBoss={helpers.current_user_can("boss",permission_data)}
                                             onRemoveItem={onRemoveItem}
                                             showDetailGift={showDetailGift}
                                             index={index}
              />
            })}
            </tbody>
          </Table>
        </InfiniteScrollContainer>
      </div>
    )
  }, [])


  const reduceRequestSearch = useMemo(() => {
    return debounce(() => {
      setSearchParam(refValueSearch.current)
    }, 500)
  }, [])

  const onChangeSearch = useCallback((event: any) => {
    refValueSearch.current = event?.target?.value.trim()
    reduceRequestSearch()
  }, [])


  return (
    <BigLayout>
      <div id={'gift-receiver'}>
        <_Helmet title='gift_title' />
        <div className="d-flex justify-content-center">
          <div className="giftreceiver_container">
            <div className={`giftreceiver_container_products`}>
              <div className="giftreceiver_container_products_header_frame">
                <div className="giftreceiver_container_products_header">
                  <Title text={__("gift_history_receiver")} />

                  <div className="giftreceiver_container_sort">
                    <Form.Select
                      aria-label=""
                      className={`giftreceiver_container_sortbox`}
                      // value={levelValue}
                      onChange={e => {
                        if(!helpers.isJson(e?.target?.value)) return
                        setSortParam(JSON.parse(e?.target?.value))
                      }}
                    >
                      {GIFT_RECEIVER_SORT_OPTIONS.map(item => (
                        <option key={item?.title} value={JSON.stringify(item?.value)}>
                          {item?.title}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <div className="giftreceiver_container_admin_header">
                  <div className="d-flex align-items-center giftreceiver_container_search">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    <Form>
                      <Form.Control
                        type="text"
                        placeholder={__("gift_receiver_name")}
                        className="border-0"
                        onChange={onChangeSearch}
                      />
                    </Form>
                  </div>

                  {helpers.current_user_can("boss",permission_data) ? (
                    <Link
                      to={'/gift/add-receiver'}
                      className="btn giftreceiver_btn_add d-flex align-items-center"
                    >
                      <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                      {__("gift_given")}
                    </Link>
                  ) : (
                    <div className="text-lg-end mt-2">{`${totalGift[keyActiveTab] || 0} ${__("gift_receiver_result")}`}</div>
                  )}
                </div>
              </div>

              {helpers.current_user_can("boss",permission_data) && (
                <div className="text-lg-end mt-2">{`${totalGift[keyActiveTab] || 0} ${__("gift_receiver_result")}`}</div>
              )}

              <div className="giftreceiver_container_tab">
                <Tabs
                  defaultActiveKey={
                    helpers.current_user_can("boss",permission_data)
                      ? EnumGiftSendStatusType.All
                      : EnumGiftSendStatusType.Mine
                  }
                  activeKey={keyActiveTab}
                  id="justify-tab-example"
                  className="mb-3"
                  fill
                  onSelect={eventKey => setKeyActiveTab(eventKey as EnumGiftSendStatusType)}
                >
                  {(helpers.current_user_can("boss",permission_data)
                      ? GIFT_SEND_STATUS
                      : GIFT_SEND_STATUS_USER_ROLE
                  ).map(itemStatus => (
                    <Tab key={itemStatus.value} eventKey={itemStatus.value} title={itemStatus.title}>
                      <RenderTabReceiver activeTab={keyActiveTab} sortParamGift={sortParam} searchParamGift={searchParam} value={itemStatus.value}/>
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <GiftDetailModal ref={refGiftDetailModal} />
      </div>
    </BigLayout>

  )
}
