import { SvgIcon, SvgIconProps } from '@mui/material'

const TimeLoadingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3375 1.57504C14.2027 1.41612 15.0936 1.33325 16.0026 1.33325C24.1028 1.33325 30.6693 7.89974 30.6693 15.9999C30.6693 24.1001 24.1028 30.6666 16.0026 30.6666C15.0936 30.6666 14.2027 30.5837 13.3375 30.4248C12.6132 30.2918 12.1339 29.5968 12.267 28.8725C12.4 28.1483 13.095 27.669 13.8192 27.802C14.5263 27.9319 15.2559 27.9999 16.0026 27.9999C22.63 27.9999 28.0026 22.6273 28.0026 15.9999C28.0026 9.3725 22.63 3.99992 16.0026 3.99992C15.2559 3.99992 14.5263 4.06796 13.8192 4.19783C13.095 4.33087 12.4 3.85158 12.267 3.12732C12.1339 2.40306 12.6132 1.70808 13.3375 1.57504ZM10.4901 3.78271C10.8588 4.42013 10.641 5.23576 10.0036 5.60449C8.36651 6.55148 6.97315 7.87463 5.94245 9.45542C5.54025 10.0723 4.71416 10.2463 4.09732 9.84409C3.48047 9.44189 3.30646 8.6158 3.70865 7.99896C4.96728 6.06859 6.6681 4.45326 8.6683 3.29621C9.30572 2.92748 10.1214 3.1453 10.4901 3.78271ZM16.0028 9.33325C16.7391 9.33325 17.3361 9.93021 17.3361 10.6666V15.2301L18.9787 16.1786C19.6164 16.5468 19.8349 17.3622 19.4667 18C19.0985 18.6377 18.283 18.8561 17.6453 18.4879L14.6694 16.7697V10.6666C14.6694 9.93021 15.2664 9.33325 16.0028 9.33325ZM3.31696 11.6709C4.03422 11.8376 4.48055 12.5542 4.31385 13.2714C4.11044 14.1467 4.0026 15.0599 4.0026 15.9999C4.0026 16.94 4.11044 17.8531 4.31385 18.7284C4.48055 19.4457 4.03422 20.1622 3.31696 20.3289C2.59969 20.4956 1.8831 20.0493 1.71641 19.332C1.46733 18.2603 1.33594 17.1446 1.33594 15.9999C1.33594 14.8552 1.46733 13.7396 1.71641 12.6678C1.8831 11.9505 2.59969 11.5042 3.31696 11.6709ZM4.09732 22.1558C4.71416 21.7536 5.54025 21.9276 5.94245 22.5444C6.97315 24.1252 8.36651 25.4484 10.0036 26.3953C10.641 26.7641 10.8588 27.5797 10.4901 28.2171C10.1214 28.8545 9.30572 29.0724 8.6683 28.7036C6.6681 27.5466 4.96728 25.9312 3.70865 24.0009C3.30646 23.384 3.48047 22.5579 4.09732 22.1558Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default TimeLoadingIcon
