import {
  Box,
  styled,
  Paper,
  Stack,
  Typography,
  Modal,
  IconButton,
  Button,
  Radio,
  Divider
} from '@mui/material'
import __ from 'languages/index'
import { ItemInCart, TypedLogistic, TypedStock } from '../interface'
import imageDefault from 'media/images/image_default.png'
import helpers from 'helpers'
import React, { useCallback, useEffect, useState } from 'react'
import { formatString } from 'entities/support/utils'
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { ChevronRightIcon, CloseIcon } from 'icons'
import dateandtime from 'date-and-time'

type TypedLogisticData = Partial<
  TypedLogistic & {
    expected_delivery_date: string
    price_for_this_transaction: number
    is_select?: boolean
  }
>
interface TypedOrderData {
  logisticData: TypedLogisticData[]
  products: ItemInCart[]
  stock: Partial<TypedStock>[]
}

const ShopPaymentProduct = ({
  orderData,
  handleUpdateOrderData
}: {
  orderData: TypedOrderData[]
  handleUpdateOrderData: Function
}) => {
  const handleSubmit = () => {
    handleUpdateOrderData(orderData => [...orderData])
  }

  return (
    <ShopPaymentProductContainer>
      <Paper>
        <Box className="list_wrapper">
          <Box>
            <Typography variant={'subtitle2'} fontWeight={600}>
              {__('payment_table_product')}
            </Typography>
          </Box>
          <Box textAlign={'center'}>
            <Typography variant={'subtitle2'} fontWeight={600}>
              {__('payment_table_price')}
            </Typography>
          </Box>
          <Box textAlign={'center'}>
            <Typography variant={'subtitle2'} fontWeight={600}>
              {__('payment_table_quantity')}
            </Typography>
          </Box>
          <Box textAlign={'center'}>
            <Typography variant={'subtitle2'} fontWeight={600}>
              {__('payment_table_total')}
            </Typography>
          </Box>
        </Box>
        <Box className="list_product">
          {orderData?.map((data: TypedOrderData, index: number) => (
            <React.Fragment key={index}>
              {data?.products?.map((valueProduct: ItemInCart, idx: number) => (
                <Box key={idx} className="list_wrapper">
                  <Box>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Box
                        component={'img'}
                        src={valueProduct?.product?.product_avatar?.[0] || imageDefault}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                          e.currentTarget.src = imageDefault
                        }}
                        alt="image"
                        sx={{
                          width: theme => theme.spacing(18.75),
                          height: theme => theme.spacing(18.75),
                          objectFit: 'cover',
                          borderRadius: theme => theme.spacing(1.5)
                        }}
                      />
                      <Typography sx={{ paddingLeft: theme => theme.spacing(2.75) }} noWrap>
                        {valueProduct?.product?.product_name || ''}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box textAlign="center">
                    {`${helpers.formatNumberWithCommas(valueProduct?.product?.product_price || '0')} VND`}
                  </Box>
                  <Box textAlign="center">{valueProduct?.item_quantity || '0'}</Box>
                  <Box textAlign="center">
                    {`${helpers.formatNumberWithCommas(
                      Number(valueProduct?.product?.product_price) *
                        Number(valueProduct?.item_quantity) || '0'
                    )} VND`}
                  </Box>
                </Box>
              ))}
              <ModalSelectLogistic
                logisticData={data?.logisticData}
                handleUpdateOrderData={handleSubmit}
              />
            </React.Fragment>
          ))}
        </Box>
      </Paper>
    </ShopPaymentProductContainer>
  )
}
export default ShopPaymentProduct

const ModalSelectLogistic = ({
  logisticData,
  handleUpdateOrderData
}: {
  logisticData: TypedLogisticData[]
  handleUpdateOrderData: Function
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [logisticSelected, setLogisticSelected] = useState<TypedLogisticData>(null)
  const [logisticDefault, setLogisticDefault] = useState<TypedLogisticData>(null)
  const [indexLogistic, setIndexLogistic] = useState<number>(0)
  const [indexLogisticDefault, setIndexLogisticDefault] = useState<number>(0)

  const onClose = useCallback(() => {
    setIsShowModal(false)
    setLogisticSelected(logisticDefault)
  }, [logisticDefault])

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    logisticData[indexLogisticDefault] = { ...logisticDefault, is_select: false }
    logisticData[indexLogistic] = { ...logisticSelected, is_select: true }
    await helpers.sleep(500)
    setIsLoading(false)
    setIsShowModal(false)
    handleUpdateOrderData()
  }, [logisticSelected, logisticDefault, indexLogistic, indexLogisticDefault, logisticData])

  useEffect(() => {
    if (helpers.isEmpty(logisticData) || isLoading) return
    const index = logisticData?.findIndex(data => data?.is_select)
    setIndexLogisticDefault(index)
    setLogisticSelected(logisticData?.[index])
    setLogisticDefault(logisticData?.[index])
  }, [logisticData, isLoading])

  return (
    <>
      {!helpers.isEmpty(logisticData) && (
        <Paper
          sx={{
            borderRadius: '0',
            padding: theme => theme.spacing(2, 4),
            backgroundColor: theme => theme.palette.primary.background
          }}
        >
          <Typography textTransform={'uppercase'} variant="subtitle2" color="primary.main">
            {__('shipping_method')}
          </Typography>

          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={theme => theme.spacing(2, 0)}
          >
            <Box>
              <Typography variant="subtitle2">{logisticDefault?.logistic_name || ''}</Typography>
              <Typography color="text.secondary">
                {formatString(
                  __('expected_to_receive'),
                  dateandtime.format(new Date(logisticDefault?.expected_delivery_date || ''), 'DD/MM/YYYY')
                )}
              </Typography>
            </Box>
            <Stack direction={'row'} alignItems={'center'} columnGap={theme => theme.spacing(2)}>
              <Typography variant="subtitle2" fontWeight={400}>
                {`${helpers.convertToCommasFormat(logisticDefault?.price_for_this_transaction || 0)} VND`}
              </Typography>
              <IconButton onClick={() => setIsShowModal(true)}>
                <ChevronRightIcon sx={{ color: theme => theme.palette.text.primary }} />
              </IconButton>
            </Stack>
          </Box>
        </Paper>
      )}
      {isShowModal && (
        <Modal open={isShowModal} sx={{ zIndex: 1 }}>
          <ModalContainer>
            <ModalHeader
              direction={'row'}
              sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
            >
              <Typography textTransform={'none'} variant={'h6'} className={'title'}>
                {__('select_shipping_unit')}
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </ModalHeader>
            <ModalBody
              sx={{
                p: theme => theme.spacing(3.75, 3),
                maxHeight: theme => `calc(100vh - ${theme.spacing(37.5)})`,
                overflow: 'auto'
              }}
            >
              <Stack pb={theme => theme.spacing(2.5)}>
                <Typography variant="subtitle2" textTransform={'none'} fontWeight={600}>
                  {__('shipping_channel_linked_to_the_store')}
                </Typography>
                <Typography color={'text.secondary'} fontWeight={400}>
                  {__('You_can_track_orders_on_the_app_when_connected_to_a_shipping_unit')}
                </Typography>
              </Stack>
              <Stack spacing={2.5}>
                {logisticData?.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <Stack direction={'row'}>
                      <Radio
                        checked={data?.logistic_id === logisticSelected?.logistic_id}
                        onClick={() => {
                          setIndexLogistic(idx)
                          setLogisticSelected(data)
                        }}
                      />
                      <Stack>
                        <Stack direction={'row'} spacing={2}>
                          <Typography variant="subtitle2">{data?.logistic_name || ''}</Typography>
                          <Typography
                            variant="subtitle2"
                            color={'primary.main'}
                            fontWeight={600}
                          >{`${helpers.convertToCommasFormat(
                            data?.price_for_this_transaction || 0
                          )}VND`}</Typography>
                        </Stack>
                        <Typography color="text.secondary">
                          {formatString(
                            __('expected_to_receive'),
                            data?.expected_delivery_date || ''
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider sx={{ opacity: 1 }} />
                  </React.Fragment>
                ))}
              </Stack>
            </ModalBody>
            <ModalFooter direction={'row'} gap={2}>
              <Button variant={'outlined'} onClick={onClose}>
                {__('btn_cancel')}
              </Button>
              <Button onClick={onSubmit}>
                {isLoading ? __('btn_processing') : __('btn_confirm')}
              </Button>
            </ModalFooter>
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}

const ShopPaymentProductContainer = styled(Box)(({ theme }) => ({
  '& .list_wrapper': {
    display: 'grid',
    gridTemplateColumns: `repeat(8,minmax(0,1fr))`,
    alignItems: 'center',
    padding: theme.spacing(4, 3.75, 5),
    '& div:first-child': {
      gridColumn: 'span 5'
    }
  }
}))
