import { SvgIcon, SvgIconProps } from '@mui/material'

const CarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Navigation &#38; Transportation/truck">
          <path id="Icon" d="M14 17.5V5.5C14 5.22386 13.7761 5 13.5 5H2.5C2.22386 5 2 5.22386 2 5.5V17.5C2 17.7761 2.22386 18 2.5 18H4M14 17.5C14 17.7761 13.7761 18 13.5 18H8M14 17.5V8.5C14 8.22386 14.2239 8 14.5 8H17.7929C17.9255 8 18.0527 8.05268 18.1464 8.14645L21.8536 11.8536C21.9473 11.9473 22 12.0745 22 12.2071V17.5C22 17.7761 21.7761 18 21.5 18H20M14 17.5C14 17.7761 14.2239 18 14.5 18H16M4 18C4 19.1046 4.89543 20 6 20C7.10457 20 8 19.1046 8 18M4 18C4 16.8954 4.89543 16 6 16C7.10457 16 8 16.8954 8 18M16 18C16 19.1046 16.8954 20 18 20C19.1046 20 20 19.1046 20 18M16 18C16 16.8954 16.8954 16 18 16C19.1046 16 20 16.8954 20 18" stroke="currentColor" strokeWidth="2" />
        </g>
      </svg>

    </SvgIcon>
  )
}

export default CarIcon
