//Library
import React, {memo, useCallback} from 'react'
import {useNavigate } from 'react-router-dom'
import { ProgressBar} from 'react-bootstrap'
import { Link } from 'react-router-dom'

//Components
//Images
import defaultImage from 'media/images/image_default.png'
import iconDiamond from 'media/icons/icon_diamond.svg'

//Data
import {optionMission} from '../data'
import {TypeSortMission} from '../data/enum'

//Interface
import {TypeListRedeemPermission, TypeMissionAction, TypePointData} from '../interface'

//Helpers
import helpers from 'helpers'
import __ from "languages/index";
import { Button, Stack } from '@mui/material'

const DetailListMission = ({
  item,
  handleCalculatorCompletedMission,
  handleShowModalOptionGift,
  type
}: {
  item?: TypeListRedeemPermission
  handleCalculatorCompletedMission?: Function
  handleShowModalOptionGift?: Function
  type?: string
}) => {
  const navigate = useNavigate()

  const handleCheckSuccess = useCallback(
    (redeem: TypeListRedeemPermission, mission_action: TypeMissionAction) => {
      let number_success =
        redeem?.point_data?.find(value => value?.action_name == mission_action?.action_name)
          ?.point_number || 0
      let checkSuccess = false
      if (number_success >= Number(mission_action?.action_point)) {
        number_success = Number(mission_action?.action_point)
        checkSuccess = true
      }
      return checkSuccess
    },
    []
  )

  const nextToAction = useCallback((nameAction?: string) => {
    if (nameAction == 'like_post') {
      navigate('/')
      let action = nameAction
      localStorage.setItem('action_mission', JSON.stringify(action))
    }
    if (nameAction == 'like_comment') {
      navigate('/')
      let action = nameAction
      localStorage.setItem('action_mission', JSON.stringify(action))
    }
    if (nameAction == 'comment') {
      navigate('/')
      let action = nameAction
      localStorage.setItem('action_mission', JSON.stringify(action))
    }
    if (nameAction == 'invite_user') {
      navigate('/member')
      let action = nameAction
      localStorage.setItem('action_mission', JSON.stringify(action))
    }
    if (nameAction == 'view_course') {
      navigate('/courses')
      let action = nameAction
      localStorage.setItem('action_mission', JSON.stringify(action))
    }
    if (nameAction == 'post_new') {
      navigate('/')
      let action = nameAction
      localStorage.setItem('action_mission', JSON.stringify(action))
    }
    if (nameAction == 'update_profile') {
      navigate('/user/setting')
      let action = nameAction
      localStorage.setItem('action_mission', JSON.stringify(action))
    }
  }, [])

  return (
    <div
      className={`missionDetail_body_container ${
        type !== TypeSortMission?.CURRENT_MISSION && 'disabled'
      }`}
    >
      <div className="missionDetail_body_container_header">
        <span className="missionDetail_header_bold">
          {`${__("mission_date")} ${item?.redeem_mission_id?.number_of_day}`}
        </span>
        <div className="missionDetail_body_header_wrapper">
          <div className="missionDetail_body_header_progress">
            <ProgressBar
              animated
              variant="primary"
              now={
                handleCalculatorCompletedMission(
                  item?.point_data,
                  item?.redeem_mission_id?.mission_action
                )?.percent || 0
              }
            />
          </div>
          <span className="missionDetail_body_header_progress_title">
            {handleCalculatorCompletedMission(
              item?.point_data,
              item?.redeem_mission_id?.mission_action
            )?.label || ''}
          </span>
          <div>
            <span className="missionDetail_body_header_progress_title">
              {handleCalculatorCompletedMission(
                item?.point_data,
                item?.redeem_mission_id?.mission_action
              )?.complete
                ? `${__("mission_rewards_received")}`
                : `${__("mission_complete_to_receive_reward")}`}
            </span>
            {Boolean(item?.redeem_mission_id?.gift_coin) && (
              <Stack direction={'row'} columnGap={1}>
                <div className="missionDetail_right_info_award_name">
                  <img src={iconDiamond} width={20} height={20} alt="icon/diamond" loading="lazy" />
                  <span>
                    {helpers.formatNumberWithCommas(item?.redeem_mission_id?.gift_coin || '0')}
                  </span>
                </div>
                <Button
                  variant='text'
                  sx={{textTransform: 'none', p: 0}}
                  onClick={() => handleShowModalOptionGift()}
                  className={`missionDetail_right_info_award_question ${
                    type === TypeSortMission?.CURRENT_MISSION && 'text-white'
                  }`}
                >
                  {__("mission_diamond")}
                </Button>
              </Stack>
            )}
            {item?.redeem_mission_id?.gift_data?.length > 0 && (
              <Link
                to={`gift/${item?.redeem_mission_id?.gift_data[0]?._id}`}
                state={{option_type: 'mission'}}
                className="missionTimeline_right_info_award_name text-decoration-none"
                >
                <img
                  src={item?.redeem_mission_id?.gift_data[0]?.media_id?.media_url || defaultImage}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = defaultImage
                  }}
                  alt="image-award"
                />
                <div className="missionTimeline_right_award_name text-white">
                  <span>{item?.redeem_mission_id?.gift_data[0]?.name || ''}</span>
                  <span>{`${helpers.formatNumberWithCommas(
                    item?.redeem_mission_id?.gift_data[0]?.price || '0'
                  )} ${__("currency_unit")}`}</span>
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="missionDetail_container_body">
        <div className="missionDetail_container_header">
          <span className="fw-medium">{__("mission_list")}</span>
        </div>
        <div className="missionDetail_container_second">
          {item?.redeem_mission_id?.mission_action?.map(
            (data: TypeMissionAction, index: number) => (
              <div key={`mission_item_${index}`} className="missionDetail_right_list_child_item">
                <div className="missionDetail_list_child_item_left">
                  {item?.redeem_mission_id?.mission_action?.length > 1 && (
                    <span className="missionDetail_list_child_item_left_title"></span>
                  )}
                </div>
                <div className="missionDetail_list_child_item_right">
                  <div className="d-flex flex-column justify-content-center">
                    <div>
                      <span className="missionDetail_right_children_title">{__("mission_detail_name")}</span>
                      <span className="ms-2 missionDetail_right_children_value">
                        {
                          optionMission?.find(
                            (e: { key?: string; label?: string }) => e?.key === data?.action_name
                          )?.label
                        }
                      </span>

                    </div>
                    {
                      data?.action_name === 'update_profile' && (
                        <span className="missionDetail_right_children_value">{__("mission_detail_update_phone_number")}</span>
                      )
                    }
                    <div>
                      <span className="missionDetail_right_children_title">{__("mission_detail_complete")}</span>
                      <span className="ms-2 missionDetail_right_children_value">
                        {`(${
                          item?.point_data?.find(
                            (value: TypePointData) => value?.action_name === data?.action_name
                          )?.point_number || '0'
                        }/${data?.action_point})`}
                      </span>
                    </div>
                  </div>

                  <div>
                    {!handleCheckSuccess(item, data) ? (
                      <Button
                        onClick={() => nextToAction(data?.action_name)}
                        disabled={type !== TypeSortMission?.CURRENT_MISSION}
                        sx={{textTransform: 'none'}}
                      >
                        {__("mission_detail_btn")}
                      </Button>
                    ) : (
                      <i
                        className="bi bi-check-circle"
                        style={{ color: 'var(--fc-bg-event-color)', fontSize: '20px' }}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(DetailListMission)
