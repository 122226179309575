//Library
import React, {useCallback, useEffect, useState} from 'react'
import dateandtime from 'date-and-time'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {Breadcrumb, OverlayTrigger, Tooltip} from 'react-bootstrap'
import Parser from 'html-react-parser'
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck, faFileLines, faMedal, faTrashCan} from '@fortawesome/free-solid-svg-icons'

//Components
import CoursesViewModulesItem from 'entities/courses/components/courses.view.modules.item'
import CoursesProgress from 'entities/courses/components/courses.progress'
import CoursesViewSkeleton from 'entities/courses/skeletons/courses.view.skeleton'
import _Helmet from 'components/helmet'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {
  clearEntity,
  deleteCourse,
  getChildrenModule,
  getEntity,
  updateStatusJoinCourse
} from 'entities/courses/courses.store.reducer'

//Constant
import {EnumPublicStatus, EnumPublishStatus} from 'entities/courses/media/constants'

//Helpers
import helpers from 'helpers/index'

//Interface
import {TypedModule} from 'entities/courses/courses.interface'

//Image
import ImgDefaultAvatar from './media/img_default_avatar.png'
import imageDefault from 'media/images/image_default.png'
import IconBan from 'entities/courses/media/ban-solid.svg'

//Scss
import './media/courses.view.scss'
import __ from "languages/index";
import BigLayout from "../../layouts/bigLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";

export default function CoursesView() {
  let useParam = useParams()
  const user_data = useAppSelector(state => state.user.user_data);
  const entity = useAppSelector(state => state.courses.entity)
  const user_in_this_channel = useAppSelector(state => state.user.user_in_this_channel);
  const permission_data = useAppSelector(state => state.user.permission_data)
  const channel_data: any = useAppSelector(state => state.user.channel_data)
  const childrenModule: TypedModule[] = useAppSelector(state => state.courses.childrenModule)
  const toast = useToast()
  const [courseId, setCourseId] = useState < string | undefined > (useParam.courses_id || '')
  const [modules, setModules] = useState < TypedModule[] > ([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()


  const sortChildrenModule = [...childrenModule]?.sort((a,b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))


  const [percentComplete, setPercentComplete] = useState < number > (
    entity?.module_child_count === undefined
      ? undefined
      : entity?.module_child_count == 0
        ? 0
        : Math.min(
          100,
          Math.max(
            0,
            Math.round(
              (Number(entity?.total_view || 0) * 100) / Number(entity?.module_child_count || 1)
            )
          )
        )
  )


  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [isCanJoinCourse, setIsCanJoinCourse] = useState(
    helpers.current_user_can("boss", permission_data)
      ? true
      : entity?.trash_status === EnumPublishStatus.public &&
      permission_data &&
      entity &&
      (entity?.public_status === EnumPublicStatus.public ||
        user_in_this_channel?.level_number >= entity?.level_value)
  )
  const [toggler, setToggler] = useState < boolean > (false)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (courseId) {
      if((!!localStorage.getItem('session') && user_data?._id) || !localStorage.getItem('session')){
        dispatch(
          getEntity({
            _id: courseId,
            auth_id: user_data?._id || ''
          })
        )
        dispatch(
          getChildrenModule({
            page: 1,
            limit: 1000,
            course_id: courseId,
            auth_id: user_data?._id || ''
          })
        )
      }
    }else {
      navigate('/courses/list')
    }

    return () => {
      dispatch(clearEntity())
    }
  }, [user_data?._id])

  useEffect(() => {
    setIsCanJoinCourse(
      helpers.current_user_can("boss", permission_data)
        ? true
        : entity?.is_join ? true : entity?.trash_status === EnumPublishStatus.public &&
          permission_data &&
          (entity?.public_status === EnumPublicStatus.public ||
            user_in_this_channel?.level_number >= entity?.level_value)
    )
    setPercentComplete(
      entity?.module_child_count === undefined
        ? undefined
        : entity?.module_child_count == 0
          ? 0
          : Math.min(
            100,
            Math.max(
              0,
              Math.round(
                (Number(entity?.total_view || 0) * 100) / Number(entity?.module_child_count || 1)
              )
            )
          )
    )
  }, [entity, permission_data, user_in_this_channel])

  useEffect(() => {
    if (sortChildrenModule && sortChildrenModule?.length) {
      setModules(
        sortChildrenModule
          .filter(item => !item.parent_id)
          .map(item => ({
            ...item,
            subModule: sortChildrenModule.filter(itemModule => itemModule.parent_id === item._id)
          }))
      )
    }
  }, [childrenModule])

  const renderItemModule = useCallback(
    (module: TypedModule, index: number) => {
      const isMentor = helpers.current_user_can('boss', permission_data)
      return (
        <CoursesViewModulesItem
          isCanJoinCourse={(isCanJoinCourse && entity?.is_join) || isMentor}
          key={module?._id}
          module={module}
          isLast={index === modules.length - 1}
          alreadyOpen
        />
      )
    },
    [modules, isCanJoinCourse]
  )

  const onConfirmDelete = useCallback(async () => {
    dispatch(deleteCourse(entity?._id))
    await helpers.sleep(1000)
    setShowModalConfirm(false)
    navigate('/courses/list')
    toast.show({
      content:  `${__("course_message_delete_success")}` + entity?.title,
      type: EnumTypeToast.Success
    })
  }, [entity])

  const handleUpdateStatusIsJoin = useCallback(() => {
    dispatch(updateStatusJoinCourse({ course_id: entity?._id }))
    navigate(`/study/${entity?._id}`)
  }, [entity])

  const handleClickTab = useCallback((link: string)=>{
    if(!link) return navigate('/404')
    navigate(link)
  },[])

  if (entity === null) {
    return <CoursesViewSkeleton />
  }

  // ======================     FOR S.E.O @jamviet.com            ======================
  function getPermalink(): string {
    return helpers.getRootDomain(`/r` + location.pathname)
  }

  const renderToolTipDelete = valueProps => {
    return (
      <Tooltip id="tooltipDelete" {...valueProps}>
        {__("course_tooltip_delete")}
      </Tooltip>
    )
  }

  return (
    <BigLayout>
      <div id={'course-view'}>
        <_Helmet title={`${__("course_title")} ${entity?.title}`} />
        <div className="ovf">
          <div className="courseview_topcontainer">
            <div className="courseview_topcontainer_infoontainer">
              <div className="mainFrame">
                <div className="container">
                  <div className="col-12 col-md-8 courseview_container">
                    <Breadcrumb className="ovf">
                      <Breadcrumb.Item onClick={()=> handleClickTab('/')}>{__("home_page")}</Breadcrumb.Item>
                      <Breadcrumb.Item onClick={()=> handleClickTab('/courses')}>
                        {__("course_library")}
                      </Breadcrumb.Item>
                      <Breadcrumb.Item active>
                        {entity?.title?.slice(0, 20) + (entity?.title?.length > 20 ? '...' : '')}
                      </Breadcrumb.Item>
                    </Breadcrumb>

                    <h1 className="courseview_topcontainer title_course">{entity?.title}</h1>
                    <div className="courseview_topcontainer des_course">{entity?.description}</div>

                    <div className="d-flex justify-content-between">
                      {new Date(entity?.updatedAt).toString() !== 'Invalid Date' ? (
                        <div className="align-items-center mt-4">
                          <div className="courseview_topcontainer createat_course">
                            {`${__("course_update_most_recent")}${' '}${dateandtime.format(new Date(entity?.updatedAt), 'DD/MM/YYYY')}`}
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}

                      <div className="d-flex align-items-center mt-4">
                        {entity?.public_status === EnumPublicStatus.public ? (
                          <div className="courseview_topcontainer lvl_public">{__("course_public")}</div>
                        ) : (
                          <>
                            {/*<img src={IconMedal} alt="" className="courseview_topcontainer iconmedal"/>*/}
                            <FontAwesomeIcon
                              icon={faMedal}
                              className="courseview_topcontainer iconmedal"
                            />
                            <div className="courseview_topcontainer lvl_course">
                              {`${__("course_level")} ${entity?.level_value} ${__("course_upto")}`}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {

                      <button onClick={() => navigate(`/course/manage-user/${entity?._id}`)} className='btn btn-primary mt-3'>
                        {helpers.current_user_can('boss', permission_data) ? `${__("course_manage_student")}` : `${__("course_list_student")}`}
                      </button>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mainFrame">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-8">
                  <div className="courseview_viewmodule">
                    {!helpers.current_user_can("boss", permission_data) &&
                    entity?.trash_status === EnumPublishStatus.public &&
                    percentComplete &&
                    sortChildrenModule &&
                    sortChildrenModule?.length > 0 &&
                    percentComplete !== 0 &&
                    percentComplete !== 100 ? (
                      <div className="d-flex flex-column justify-content-center mb-3 course_header_in_detail">
                        <div className="d-flex justify-content-between">
                          <span className="courseview_txt_progress">Tiến độ</span>
                          <span className="courseview_txt_progress percent">
                          {percentComplete.toFixed(0) + '%'}
                        </span>
                        </div>
                        <CoursesProgress
                          percentValue={percentComplete}
                          styleProgress={{ backgroundColor: '#a51c30' }}
                        />
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center course_header_in_detail">
                      <div className="courseview_txtcontent">{__("course_content")}</div>
                      {helpers.current_user_can("boss", permission_data) ? (
                        <Link
                          to={'/courses/update-module/' + entity?._id}
                          className="courseview_addbutton shadow-sm d-flex align-items-center"
                        >
                          {__("course_update_less")}
                        </Link>
                      ) : null}
                    </div>

                    {sortChildrenModule && sortChildrenModule?.length > 0 ? (
                      <>
                        <div className="courseview_txtnumofmodule">
                          {`${entity?.module_count - entity?.module_child_count || '0'} ${__("course_view_text")} ${' '} ${entity?.module_child_count || '0'} ${__("course_view_less")}`}
                        </div>

                        <div>{modules.map(renderItemModule)}</div>
                      </>
                    ) : (
                      <div className="courseview_txtempty">{__("course_no_content")}</div>
                    )}
                  </div>

                  {entity?.long_description ? (
                    <div className="courseview_viewlongdescription">
                      <div className="courseview_txtdetailcourse">{__("course_detail")}</div>
                      <div className="courseview_inputdetailcourse">
                        {Parser(entity?.long_description ? entity?.long_description : ' ')}
                      </div>
                    </div>
                  ) : null}

                  <br />
                </div>
                <div className="col-12 col-lg-4">
                  <div className="courseview_cardcontainer">
                    <div className="courseview_card shadow-lg">
                      <div className="courseview_card_media_container">
                        {entity?.avatar ? (
                          entity?.avatar?.media_type !== 'video' ? (
                            <img
                              className="courseview_card_media"
                              src={entity?.avatar?.media_url}
                              onClick={() => setToggler(true)}
                              alt="image"
                            />
                          ) : (
                            <video
                              className="courseview_card_media logo_video"
                              width={'auto'}
                              disablePictureInPicture
                              height={'auto'}
                              controls
                            >
                              <source src={entity?.avatar?.media_url} type="video/mp4" />
                            </video>
                          )
                        ) : (
                          <div className="courseview_card_media">
                            <img
                              className="courseview_card_media logo_image"
                              src={
                                channel_data?.avatar?.media_thumbnail?.length > 0
                                  ? channel_data?.avatar?.media_thumbnail
                                  : channel_data?.avatar?.media_url || imageDefault
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="courseview_card_info">
                        {Number(entity?.coin_value) > 0 && (
                          <div className="courseview_card_info_price">
                            <span className="fs-6 fw-bold text-dark">{__("course_price")}</span>
                            <span className="fs-6 fw-bold text-dark">
                            {`${helpers.convertToCommasFormat(entity?.coin_value || '0')} ${__("currency_unit")}`}
                          </span>
                          </div>
                        )}
                        {helpers.current_user_can("boss", permission_data) &&
                        entity?.trash_status === EnumPublishStatus.trash ? (
                          <div className="d-flex align-items-center justify-content-center mb-3">
                            <FontAwesomeIcon icon={faFileLines} />
                            <span className="courseview_txt_status draf ms-2">
                            {__("course_draft")}
                          </span>
                          </div>
                        ) : null}

                        {helpers.current_user_can("boss", permission_data) ? (
                          // role mentor
                          permission_data &&
                          entity &&
                          sortChildrenModule &&
                          sortChildrenModule?.length > 0 ? (
                            <button
                              className="courseview_card_button shadow-sm"
                              onClick={() => navigate(`/study/${entity?._id}`)}
                            >
                              {__("course_watch_less")}
                            </button>
                          ) : (
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              <img src={IconBan} alt="" className="courseview_img_status" />
                              <span className="courseview_txt_status ban">
                              {__("course_no_less")}
                            </span>
                            </div>
                          )
                        ) : entity?.trash_status === EnumPublishStatus.public ? (
                          permission_data &&
                          entity &&
                          sortChildrenModule &&
                          sortChildrenModule?.length > 0 ? (
                            entity?.module_child_count > 0 ? (
                              isCanJoinCourse ? (
                                percentComplete === 0 ? (
                                  <>
                                    {entity?.is_join ? (
                                      <button
                                        className="courseview_card_button shadow-sm"
                                        onClick={() => navigate(`/study/${entity?._id}`)}
                                      >
                                        {__("course_btn_join")}
                                      </button>
                                    ) : (
                                      <>
                                        {entity?.coin_value ? (
                                          <button
                                            className="courseview_card_button shadow-sm"
                                            onClick={() =>
                                              navigate(`/payment?service_id=${entity?.service_id}`)
                                            }
                                          >
                                            {__("course_btn_buy")}
                                          </button>
                                        ) : (
                                          <button
                                            className="courseview_card_button shadow-sm"
                                            onClick={handleUpdateStatusIsJoin}
                                          >
                                            {__("course_btn_join_now")}
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : percentComplete === 100 ? (
                                  <>
                                    <div className="d-flex align-items-center justify-content-center mb-3">
                                      <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        className="courseview_img_status"
                                      />
                                      <span className="courseview_txt_status done">
                                      {__("course_completed")}
                                    </span>
                                    </div>

                                    <button
                                      className="courseview_card_button shadow-sm"
                                      onClick={() => navigate(`/study/${entity?._id}`)}
                                    >
                                      {__("course_replay")}
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="courseview_card_button shadow-sm"
                                    onClick={() => navigate(`/study/${entity?._id}`)}
                                  >
                                    {__("course_btn_continue")}
                                  </button>
                                )
                              ) : (
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                  <img src={IconBan} alt="" className="courseview_img_status" />
                                  <span className="courseview_txt_status ban">
                                  {__("course_not_allow_level")}
                                </span>
                                </div>
                              )
                            ) : (
                              //Can not
                              <div className="d-flex align-items-center justify-content-center mt-3">
                                <img src={IconBan} alt="" className="courseview_img_status" />
                                <span className="courseview_txt_status ban">
                                {__("course_not_ready")}
                              </span>
                              </div>
                            )
                          ) : (
                            <div className="d-flex align-items-center justify-content-center mt-3">
                              <img src={IconBan} alt="" className="courseview_img_status" />
                              <span className="courseview_txt_status ban">
                              {__("course_not_ready")}
                            </span>
                            </div>
                          )
                        ) : (
                          <div className="d-flex align-items-center justify-content-center mt-3">
                            <img src={IconBan} alt="" className="courseview_img_status" />
                            <span className="courseview_txt_status ban">{__("course_not_ready")}</span>
                          </div>
                        )}

                        <div className="courseview_card_authcontainer">
                          <div className="courseview_card_txtauth">{__("course_author")}</div>

                          <Link
                            to={`/user/detail/${entity?.user_id?._id}`}
                            className="d-flex text-decoration-none w-100"
                          >
                            <img
                              className="courseview_card_avatar rounded-circle"
                              src={entity?.user_id?.user_avatar_thumbnail || entity?.user_id?.user_avatar || ImgDefaultAvatar}
                            />
                            <div className="ms-2 d-flex flex-column justify-content-evenly">
                              <div className="courseview_card_txtteacher">{__("course_trainer")}</div>
                              <div className="courseview_card_txtnameauth">
                                {entity?.user_id?.display_name}
                              </div>
                            </div>
                          </Link>

                          <div
                            className="courseview_card_txtdescription"
                            style={{ wordWrap: 'break-word' }}
                          >
                            {entity?.user_id?.bio}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" d-flex justify-content-between mt-4 w-100">
                      {helpers.current_user_can("boss", permission_data) ? (
                        <OverlayTrigger placement="top-end" overlay={renderToolTipDelete}>
                          <button
                            className="btn courseview_card_btndelete px-2"
                            onClick={() => setShowModalConfirm(true)}
                          >
                            <FontAwesomeIcon icon={faTrashCan} className="icon_delete" />
                          </button>
                        </OverlayTrigger>
                      ) : null}
                      {helpers.current_user_can("boss", permission_data) ? (
                        <button
                          className="btn text-lg-center courseview_card_btnupdate"
                          onClick={() => navigate('/courses/update/' + entity?._id)}
                        >
                          {__("course_update")}
                        </button>
                      ) : null}
                    </div>
                    <br />
                    <br />
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        {toggler && (
          <Lightbox
            slides={[{
              type: 'image',
              src: entity?.avatar?.media_url
            }]}
            open={toggler}
            close={() => setToggler(false)}
            index={0}
            render={{
              buttonNext: () => null,
              buttonPrev: () => null,
            }}
            carousel={{
              finite:true
            }}
            plugins={[ Fullscreen, Zoom,Counter]}
          />
        )}

        <Modal show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{__("course_modal_delete_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {__("course_modal_delete_text")}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onConfirmDelete}>
              {__("course_modal_btn_delete")}
            </Button>
            <Button variant="primary" onClick={() => setShowModalConfirm(false)}>
              {__("course_modal_btn_continue")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </BigLayout>
  )
}
