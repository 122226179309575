import React from 'react'
import { Outlet } from 'react-router-dom'
import ChannelSettingSidebar from 'entities/channel-settings/components/ChannelSettingSidebar'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Container } from '@mui/material'
import ChannelSettingsContentContainer from 'entities/channel-settings/components/ChannelSettingsContentContainer'
import { IRootState, useAppSelector } from 'config/store'
import RenderView from 'components/RenderView'

const ChannelSettingLayout = () => {
  const loading = useAppSelector((state: IRootState) => state.channelSlice.loading)

  return (
    <Container sx={{ maxWidth: '1082px', margin: '0 auto' }}>
      <Grid container gap={3}>
        <Grid xs={3}>
          <ChannelSettingSidebar />
        </Grid>
        <Grid xs={7}>
          <ChannelSettingsContentContainer>
            <RenderView view={<Outlet />} isLoading={loading} />
          </ChannelSettingsContentContainer>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ChannelSettingLayout
