import { SvgIcon, SvgIconProps } from '@mui/material'

const MissionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="53"
        viewBox="0 0 52 53"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_2425_30486)">
          <path
            d="M24.5631 8.91435V12.0816C24.5631 13.2989 23.8659 14.4098 22.7668 14.9416L18.5241 16.9743C17.0113 17.7071 15.2268 16.6907 15.0731 15.0243L14.9077 13.2516C14.8368 12.4243 15.0022 11.6325 15.3686 10.9353C15.9241 9.88344 15.8413 8.63071 15.2031 7.63798C14.2931 6.25526 13.8913 4.5298 14.2222 2.78071C14.4468 1.46889 15.5695 0.523438 16.8813 0.523438C18.4177 0.523438 19.895 1.13798 20.9822 2.21344L22.3531 3.58435C23.7595 4.99071 24.5631 6.90526 24.5631 8.90253V8.91435Z"
            fill="url(#paint0_linear_2425_30486)"
          />
          <path
            d="M24.2092 6.64522H14.6602C14.802 6.97613 14.9792 7.29522 15.1802 7.60249C15.8302 8.59522 15.9011 9.84795 15.3456 10.8998C14.9792 11.597 14.8138 12.3889 14.8847 13.2161L15.0502 15.0007C15.2038 16.6789 16.9883 17.6834 18.5011 16.9507L22.7438 14.9179C23.8429 14.3979 24.5402 13.287 24.5402 12.0579V8.89067C24.5402 8.11067 24.4102 7.35431 24.1856 6.62158L24.2092 6.64522Z"
            fill="url(#paint1_linear_2425_30486)"
          />
          <path
            d="M24.0546 10.7099C24.0546 7.89722 21.561 5.60449 18.4764 5.60449C17.0464 5.60449 15.7464 6.08904 14.7773 6.89267C14.9073 7.14086 15.0373 7.37722 15.1792 7.61358C15.8292 8.60631 15.9001 9.85904 15.3446 10.9109C14.9783 11.6081 14.8128 12.3999 14.8837 13.2272L15.0137 14.7281C15.9592 15.4136 17.1528 15.8154 18.4528 15.8154C21.5255 15.8154 24.031 13.5227 24.031 10.7099H24.0546Z"
            fill="url(#paint2_linear_2425_30486)"
          />
          <path
            d="M20.7942 13.3695C23.1815 13.3695 25.1197 12.235 25.1197 10.8522C25.1197 9.46951 23.1815 8.33496 20.7942 8.33496C18.4069 8.33496 16.4688 9.46951 16.4688 10.8522C16.4688 12.235 18.4069 13.3695 20.7942 13.3695Z"
            fill="url(#paint3_linear_2425_30486)"
          />
          <path
            d="M36.6418 10.9C37.0082 11.5973 37.1736 12.3891 37.1027 13.2164L36.9373 14.9891C36.7836 16.6673 34.9991 17.6718 33.4864 16.9391L29.2436 14.9064C28.1445 14.3864 27.4473 13.2636 27.4473 12.0464V8.87909C27.4473 6.88182 28.2391 4.97909 29.6573 3.56091L31.0282 2.19C32.1154 1.10273 33.5809 0.5 35.1291 0.5C36.4409 0.5 37.5636 1.43364 37.8 2.71C38.1309 4.45909 37.7409 6.18455 36.8191 7.56727C36.1691 8.56 36.0982 9.81273 36.6536 10.8645L36.6418 10.9Z"
            fill="url(#paint4_linear_2425_30486)"
          />
          <path
            d="M36.8074 7.6145C37.0083 7.30723 37.1855 6.98814 37.3392 6.65723H27.7901C27.5655 7.38995 27.4355 8.14632 27.4355 8.92632V12.0936C27.4355 13.3109 28.1328 14.4218 29.2319 14.9536L33.4746 16.9863C34.9874 17.719 36.7719 16.7027 36.9255 15.0363L37.091 13.2518C37.1619 12.4245 36.9965 11.6327 36.6301 10.9354C36.0746 9.88359 36.1574 8.63086 36.8074 7.63814V7.6145Z"
            fill="url(#paint5_linear_2425_30486)"
          />
          <path
            d="M27.9551 10.7099C27.9551 7.89722 30.4487 5.60449 33.5333 5.60449C34.9633 5.60449 36.2633 6.08904 37.2323 6.89267C37.1023 7.14086 36.9723 7.37722 36.8305 7.61358C36.1805 8.60631 36.1096 9.85904 36.6651 10.9109C37.0314 11.6081 37.1969 12.3999 37.126 13.2272L36.996 14.7281C36.0505 15.4136 34.8569 15.8154 33.5569 15.8154C30.4842 15.8154 27.9787 13.5227 27.9787 10.7099H27.9551Z"
            fill="url(#paint6_linear_2425_30486)"
          />
          <path
            d="M31.2161 13.3695C33.6034 13.3695 35.5415 12.235 35.5415 10.8522C35.5415 9.46951 33.6034 8.33496 31.2161 8.33496C28.8288 8.33496 26.8906 9.46951 26.8906 10.8522C26.8906 12.235 28.8288 13.3695 31.2161 13.3695Z"
            fill="url(#paint7_linear_2425_30486)"
          />
          <path
            d="M29.4672 10.7926C29.5026 12.7072 27.9544 14.2908 26.0399 14.3026C24.1253 14.3144 22.5417 12.7899 22.5299 10.8753C22.4944 8.9608 24.0426 7.37716 25.9572 7.36534C27.8717 7.32989 29.4553 8.87807 29.4672 10.7926Z"
            fill="url(#paint8_linear_2425_30486)"
          />
          <path
            d="M45.0203 46.8504H6.9894C5.76031 46.8504 4.76758 45.8577 4.76758 44.6286V21.465C4.76758 20.2359 5.76031 19.2432 6.9894 19.2432H45.0203C46.2494 19.2432 47.2421 20.2359 47.2421 21.465V44.6286C47.2421 45.8577 46.2494 46.8504 45.0203 46.8504Z"
            fill="url(#paint9_linear_2425_30486)"
          />
          <path
            d="M45.0203 19.2433H6.9894C5.76031 19.2433 4.76758 20.236 4.76758 21.4651V23.7933C4.76758 23.7933 17.2712 36.9469 27.0685 46.8387H45.0321C46.2612 46.8387 47.2539 45.846 47.2539 44.6169V21.4533C47.2539 20.2242 46.2612 19.2314 45.0321 19.2314L45.0203 19.2433Z"
            fill="url(#paint10_linear_2425_30486)"
          />
          <path
            d="M26.0059 19.2432H40.105V46.8504H26.0059V19.2432Z"
            fill="url(#paint11_linear_2425_30486)"
          />
          <path
            d="M29.314 47.3351H22.684C22.0222 47.3351 21.4785 46.7914 21.4785 46.1296V20.4369C21.4785 19.7751 22.0222 19.2314 22.684 19.2314H29.314C29.9758 19.2314 30.5194 19.7751 30.5194 20.4369V46.1296C30.5194 46.7914 29.9758 47.3351 29.314 47.3351Z"
            fill="url(#paint12_linear_2425_30486)"
          />
          <path
            d="M21.4902 20.437V41.1661C23.3693 43.1043 25.2602 45.0188 27.0684 46.8388H30.2948C30.4366 46.6379 30.5311 46.3897 30.5311 46.1179V20.4252C30.5311 19.7634 29.9875 19.2197 29.3257 19.2197H22.6957C22.0339 19.2197 21.4902 19.7634 21.4902 20.4252V20.437Z"
            fill="url(#paint13_linear_2425_30486)"
          />
          <path
            d="M46.3209 23.935H5.69001C3.9882 23.935 2.60547 22.5523 2.60547 20.8504V16.4068C2.60547 14.705 3.9882 13.3223 5.69001 13.3223H46.3091C48.0109 13.3223 49.3937 14.705 49.3937 16.4068V20.8504C49.3937 22.5523 48.0109 23.935 46.3091 23.935H46.3209Z"
            fill="url(#paint14_linear_2425_30486)"
          />
          <path
            d="M46.3209 13.3223H19.0209L15.6055 16.7377L22.8027 23.935H46.3091C48.0109 23.935 49.3937 22.5523 49.3937 20.8504V16.4068C49.3937 14.705 48.0109 13.3223 46.3091 13.3223H46.3209Z"
            fill="url(#paint15_linear_2425_30486)"
          />
          <path
            d="M26.0059 13.3223H40.105V23.935H26.0059V13.3223Z"
            fill="url(#paint16_linear_2425_30486)"
          />
          <path
            d="M44.3713 23.9343H26.0059V15.957H36.394L44.3713 23.9343Z"
            fill="url(#paint17_linear_2425_30486)"
          />
          <path
            d="M29.9185 24.4901H22.1066C21.7757 24.4901 21.5039 24.2183 21.5039 23.8874V13.381C21.5039 13.0501 21.7757 12.7783 22.1066 12.7783H29.9185C30.2494 12.7783 30.5212 13.0501 30.5212 13.381V23.8874C30.5212 24.2183 30.2494 24.4901 29.9185 24.4901Z"
            fill="url(#paint18_linear_2425_30486)"
          />
          <path
            d="M30.5193 13.381V23.8874C30.5193 24.2183 30.2475 24.4901 29.9166 24.4901H22.0693C21.7502 24.4901 21.4902 24.2301 21.4902 23.911V13.3338C21.5257 13.0265 21.7857 12.7783 22.093 12.7783H29.9048C30.2121 12.7783 30.4721 13.0147 30.5075 13.3338V13.381H30.5193Z"
            fill="url(#paint19_linear_2425_30486)"
          />
          <path
            d="M26.005 12.7781L25.9341 12.7309C23.4641 11.0881 20.1668 11.419 18.0632 13.5227L15.605 15.9809C15.2622 16.3236 15.4986 16.9263 15.995 16.9263H16.2786C17.2122 16.9263 17.9686 17.6827 17.9686 18.6163C17.9686 19.1009 18.5595 19.349 18.9141 19.0063L24.4213 13.499C24.835 13.0854 25.4022 12.8254 25.9932 12.7781H26.005Z"
            fill="url(#paint20_linear_2425_30486)"
          />
          <path
            d="M26.0059 12.7781L26.0768 12.7309C28.5468 11.0881 31.844 11.419 33.9477 13.5227L36.4059 15.9809C36.7486 16.3236 36.5122 16.9263 36.0159 16.9263H35.7322C34.7986 16.9263 34.0422 17.6827 34.0422 18.6163C34.0422 19.1009 33.4513 19.349 33.1086 19.0063L27.6013 13.499C27.1877 13.0854 26.6204 12.8254 26.0295 12.7781H26.0059Z"
            fill="url(#paint21_linear_2425_30486)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2425_30486"
            x1="15.9004"
            y1="6.89344"
            x2="23.9722"
            y2="14.9534"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2425_30486"
            x1="19.3638"
            y1="9.63522"
            x2="20.2147"
            y2="13.417"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2425_30486"
            x1="20.2728"
            y1="9.22086"
            x2="27.2455"
            y2="3.4654"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2425_30486"
            x1="20.6997"
            y1="11.325"
            x2="21.8106"
            y2="5.59314"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2425_30486"
            x1="31.9736"
            y1="7.08273"
            x2="39.23"
            y2="14.3391"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2425_30486"
            x1="32.8837"
            y1="9.24541"
            x2="31.6665"
            y2="13.1454"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2425_30486"
            x1="31.2405"
            y1="8.80722"
            x2="24.256"
            y2="3.03995"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2425_30486"
            x1="31.2752"
            y1="11.1713"
            x2="30.1643"
            y2="5.43951"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_2425_30486"
            x1="24.8226"
            y1="9.18534"
            x2="28.0608"
            y2="13.7117"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_2425_30486"
            x1="26.0049"
            y1="20.7677"
            x2="26.0049"
            y2="46.6141"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_2425_30486"
            x1="26.0048"
            y1="37.916"
            x2="26.0048"
            y2="22.0678"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_2425_30486"
            x1="36.2404"
            y1="33.0468"
            x2="29.6931"
            y2="33.0468"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_2425_30486"
            x1="21.4194"
            y1="33.2833"
            x2="30.4012"
            y2="33.2833"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_2425_30486"
            x1="26.0048"
            y1="31.8534"
            x2="26.0048"
            y2="22.7415"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_2425_30486"
            x1="26.0055"
            y1="13.8895"
            x2="26.0055"
            y2="24.7623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_2425_30486"
            x1="33.1437"
            y1="17.0686"
            x2="26.5491"
            y2="7.1059"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_2425_30486"
            x1="36.2404"
            y1="18.6286"
            x2="29.6931"
            y2="18.6286"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_2425_30486"
            x1="38.5922"
            y1="24.8916"
            x2="24.505"
            y2="15.4488"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_2425_30486"
            x1="23.7021"
            y1="16.9265"
            x2="29.1857"
            y2="20.9565"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_2425_30486"
            x1="27.7066"
            y1="22.2447"
            x2="23.8657"
            y2="14.1138"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_2425_30486"
            x1="19.4459"
            y1="13.4045"
            x2="21.7386"
            y2="15.6972"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_2425_30486"
            x1="32.5177"
            y1="13.44"
            x2="30.2368"
            y2="15.7327"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <clipPath id="clip0_2425_30486">
            <rect width="52" height="52" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default MissionIcon
