import { Components } from '@mui/material'
import { CurrentTheme } from 'styles/themes'

export const defaultButton: Components<CurrentTheme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained'
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(1.5),
      padding: theme.spacing(1, 3),
      fontSize: theme.spacing(1.75),
      fontWeight: 600
      // '&:hover': {
      //   backgroundColor: theme.palette.primary.light
      // },
      // '&:active,&:focus': {
      //   backgroundColor: theme.palette.primary.dark
      // }
    })
  },

  variants: [
    {
      props: { variant: 'contained' },
      style: ({ theme }) => {
        const palette = theme.palette
        return {
          color: palette.primary.contrastText,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: palette.primary.light
          },
          '&:active,&:focus': {
            backgroundColor: palette.primary.dark
          },
          '&:disabled': {
            backgroundColor: palette.background.secondary || palette.grey[300],
            color: palette.text.disabled
          }
        }
      }
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => {
        const palette = theme.palette
        return {
          color: palette.primary.main,
          backgroundColor: 'transparent',
          border: '1px solid',
          borderColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.background
          },
          '&:active, &:focus': {
            backgroundColor: palette.primary.backgroundHover
          }
        }
      }
    }
  ]
}
