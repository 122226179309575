import React from 'react'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import {styled} from '@mui/material'

const MaxLayout = ({children}: { children: React.ReactNode }) => {

  return (
    <MaxLayoutContainer container columns={10} spacing={3}>
      <Grid xs={11}>{children}</Grid>
    </MaxLayoutContainer>
  )
}

export default MaxLayout
const MaxLayoutContainer = styled(Grid)(({theme}) => ({
  '& .right_bar': {
    height: `calc(100vh - ${theme.spacing(12.5)})`,
    overflow: 'auto',
    transition: `padding-right 0.3s ease`,
    '::-webkit-scrollbar': {
      display: 'none'
    },
  }
}))
