import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        function handleResize() {
            debounce(() =>{
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            }, 500)
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return {
        widthCurrent: windowSize.width,
        heightCurrent: windowSize.height
    };
}

export default useWindowSize