import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import '../media/livestream.room.scss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faVolumeHigh, faVolumeXmark} from '@fortawesome/free-solid-svg-icons'
import {Socket} from 'socket.io-client'
import Button from 'react-bootstrap/Button'
import {REACT_LIST_GIFT, REACT_PNG} from 'entities/livestream/livestream.admin.constant'
import {sendReact} from 'entities/livestream/services/livestream.service'
import LivestreamReactAni from 'entities/livestream/components/livestream.react.ani'
import {useAppDispatch, useAppSelector} from 'config/store'
import {EnumReadyStatusLivestream, EnumStatusLivestream} from 'entities/livestream/livestream.interface'
import {setEntity} from 'entities/livestream/livestream.store.reducer'
import debounce from 'lodash.debounce'
import Spinner from 'react-bootstrap/Spinner'
import {useLivestreamRoom} from 'entities/livestream/hooks/livestream.room.hook'
import LivestreamRoomHeader from 'entities/livestream/components/livestream.room.header'
import {EnumLiveStreamSource} from 'entities/event/event.interface'
import VideoJS from 'entities/livestream/components/videoJS'
import Player from 'video.js/dist/types/player'
import {TypedCourse} from 'entities/courses/courses.interface'
import {OverlayTrigger, Stack, Tooltip} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import __helpers from 'helpers/index'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

interface TypedLivestreamRoomVideoProps {
  socket: Socket
}
type Product = TypedCourse | null

export default function LivestreamRoomVideo({ socket }: TypedLivestreamRoomVideoProps) {
  const liveDetail = useAppSelector(state => state.livestream.entity)
  const refVideoLivestream = useRef<HTMLVideoElement>()
  const refVideoLivestreamM3u8 = useRef<any>()
  const refLivestreamReactAni = useRef<any>()
  const refBottomBanner = useRef<any>()
  const [isVolumeOn, setIsVolumeOn] = useState(false)
  const [isReadyForM3u8, setIsReadyForM3u8] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const refSizeOfBottomBanner = useRef({
    topX: 0,
    topY: 0,
    bottomX: 0,
    bottomY: 0
  })

  const { connectionStatus, setConnectionStatus, startWatchLivestream } = useLivestreamRoom(
    liveDetail,
    refVideoLivestream,
    socket
  )

  const refVideoJsOptions = useRef<any>({
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    muted: true
  })

  useEffect(() => {
    if (
      liveDetail?.livestream_data?.m3u8_url &&
      liveDetail?.livestream_status === EnumStatusLivestream.Live &&
      liveDetail?.ready_status === EnumReadyStatusLivestream.Connected &&
      !isReadyForM3u8
    ) {
      refVideoJsOptions.current = {
        ...refVideoJsOptions.current,
        sources: [
          {
            src: liveDetail?.livestream_data?.m3u8_url,
            type: 'application/x-mpegURL'
          }
        ]
      }
      setIsReadyForM3u8(true)
    }
  }, [liveDetail, isReadyForM3u8])

  useEffect(() => {
    // socket react
    socket.on('emojiToClient', data => {
      console.log('emojiToClient')
      try {
        if(!__helpers.isJson(data)) return
        if (typeof JSON.parse(data) === 'object') {
          let reactData = JSON.parse(data)
          if (reactData?.react_type) {
            refLivestreamReactAni.current.showReact(
              Math.floor(
                Math.random() *
                  (refSizeOfBottomBanner.current.bottomX - refSizeOfBottomBanner.current.topX + 1)
              ) + refSizeOfBottomBanner.current.topX,
              Math.floor(
                Math.random() *
                  (refSizeOfBottomBanner.current.bottomY - refSizeOfBottomBanner.current.topY + 1)
              ) + refSizeOfBottomBanner.current.topY,
              <img
                src={REACT_PNG[reactData?.react_type]}
                className="livestreamroomvideo_btn_react"
              />
            )
          }
        }
      } catch (error) {
        console.log(error, 'sljnfjadasdbsdf')
      }
    })

    socket.on('livestreamEndToClient', data => {
      console.log('livestreamEndToClient')
      try {
        if(!__helpers.isJson(data)) return
        if (typeof JSON.parse(data) === 'object') {
          let dataLivestream = JSON.parse(data)
          console.log(dataLivestream, 'dataLivestream')
          dispatch(setEntity({ data: { entity: dataLivestream } }))
        }
      } catch (error) {
        console.log(error, 'sljnfjsssbsdf')
      }
    })

    socket.on('livestreamStartToClient', data => {
      console.log('livestreamStartToClient')
      try {
        if(!__helpers.isJson(data)) return
        if (typeof JSON.parse(data) === 'object') {
          let dataLivestream = JSON.parse(data)
          setTimeout(() => {
            dispatch(setEntity({ data: { entity: dataLivestream } }))
            toast.show({
              type: EnumTypeToast.Success,
              content: `${__("livestream_room_start")}`
            })
          }, 2000)
        }
      } catch (error) {
        console.log(error, 'sljnfjsssbsdf')
      }
    })

    // lấy size của bottom banner
    window.addEventListener('resize', updateBottomBannerSize)
    updateBottomBannerSize()
    return () => {
      window.removeEventListener('resize', updateBottomBannerSize)
    }
  }, [])

  const [pinningProduct, setPinningProduct] = useState<Product>(liveDetail?.product_id)
  const handleGetProductPinned = (product: Product) => {
    setPinningProduct(product)
  }

  useEffect(() => {
    setPinningProduct(liveDetail?.product_id)
  }, [liveDetail?.product_id])

  useEffect(() => {
    socket.on('productToClient', handleGetProductPinned)
  }, [])

  const updateBottomBannerSize = useCallback(() => {
    if (refBottomBanner.current) {
      const rect = refBottomBanner.current.getBoundingClientRect()
      refSizeOfBottomBanner.current = {
        topX: rect.left + 20,
        topY: rect.top,
        bottomX: rect.right - 20,
        bottomY: rect.bottom - 20
      }
    }
  }, [])

  const debounceSendReact = useMemo(() => {
    return debounce((typeReact: string, idLivestream: string) => {
      sendReact(typeReact, idLivestream)
    }, 2000)
  }, [])

  const onSendEmoji = useCallback(
    (e: any, react: any) => {
      debounceSendReact(react.name, liveDetail?._id)
      refLivestreamReactAni.current.showReact(
        e.clientX,
        e.clientY,
        <img src={REACT_PNG[react.name]} className="livestreamroomvideo_btn_react" />
      )
    },
    [liveDetail?._id]
  )

  const renderReactButton = useCallback(
    (item, index: number) => {
      return (
        <Button
          key={index.toString()}
          className="livestreamroomvideo_btn_react"
          onClick={e => onSendEmoji(e, item)}
        >
          <img src={item.icon} className="livestreamroomvideo_img_react" />
        </Button>
      )
    },
    [liveDetail?._id]
  )

  const ButtonReacts = useMemo(() => {
    return (
      <div ref={refBottomBanner} className="livestreamroomvideo_container_bottom z-3">
        {REACT_LIST_GIFT.map(renderReactButton)}
      </div>
    )
  }, [liveDetail?._id])

  const onOffVolumeVideo = useCallback(() => {
    if (refVideoLivestream.current) {
      refVideoLivestream.current.volume = 1
      refVideoLivestream.current.muted = !refVideoLivestream.current.muted
    } else {
      if (refVideoLivestreamM3u8.current) {
        refVideoLivestreamM3u8.current?.changeMuted(!isVolumeOn, 1)
      }
    }
    setIsVolumeOn(old => !old)
  }, [isVolumeOn])

  const BannerStatus = useMemo(() => {
    return (
      <>
        {liveDetail?.livestream_status === EnumStatusLivestream.Live &&
          (!connectionStatus || connectionStatus === 'connecting') && (
            <div className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center z-index-minus">
              <img
                src={liveDetail?.avatar?.media_url}
                className="w-100 h-100 object-fit-cover z-index-minus"
              />
              <Spinner
                as="span"
                animation="grow"
                className="position-absolute spinner-live"
                role="status"
                aria-hidden="true"
                variant="danger"
              />
            </div>
          )}

        {connectionStatus === 'disconnected' &&
          liveDetail?.livestream_status === EnumStatusLivestream.Live && (
            <div className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center bg-black z-index-minus">
              <h3
                className="text-white text-lg-center align-self-center"
                style={{ textAlign: 'center' }}
              >
                {__("livestream_room_disconnect")}
              </h3>
            </div>
          )}

        {connectionStatus === 'failed' &&
          liveDetail?.livestream_status === EnumStatusLivestream.Live && (
            <div className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center bg-black z-index-minus">
              <h3
                className="text-white text-lg-center align-self-center"
                style={{ textAlign: 'center' }}
              >
                {__("livestream_room_lost_connection")}
              </h3>
            </div>
          )}

        {liveDetail?.livestream_status === EnumStatusLivestream.Wait && (
          <div className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center bg-black z-index-minus">
            <h3
              className="text-white text-lg-center align-self-center "
              style={{ textAlign: 'center' }}
            >
              {__("livestream_prepare_start")}
            </h3>
          </div>
        )}

        {liveDetail?.livestream_status === EnumStatusLivestream.Ended && (
          <div className="w-100 h-100 position-absolute d-flex justify-content-center align-items-center bg-black z-index-minus">
            <h3
              className="text-white text-lg-center align-self-center"
              style={{ textAlign: 'center' }}
            >
              {__("livestream_closed")}
            </h3>
          </div>
        )}
      </>
    )
  }, [liveDetail, connectionStatus])

  const playerRef = React.useRef(null)
  const handlePlayerReady = (player: Player) => {
    setConnectionStatus('connected')
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('loadstart', () => {
      setConnectionStatus('connecting')
    })

    player.on('canplay', () => {
      setConnectionStatus('connected')
    })
  }

  return (
    <div className="flex-grow-1 livestreamroomvideo">
      {liveDetail?.input_type === EnumLiveStreamSource.OutSide && isReadyForM3u8 ? (
        <div className="video-player d-flex flex-column justify-content-center">
          <VideoJS
            ref={refVideoLivestreamM3u8}
            options={refVideoJsOptions.current}
            onReady={handlePlayerReady}
          />
        </div>
      ) : (
        <video
          ref={refVideoLivestream}
          autoPlay
          playsInline
          loop
          controls={false}
          crossOrigin={'anonymous'}
          className="video-player"
          muted
        ></video>
      )}

      <div className="livestreamroomvideo_container">
        <div className="livestreamroomvideo_container_top">
          <LivestreamRoomHeader socket={socket} />

          <Button
            className="livestreamroom_btn align-self-start align-items-center"
            onClick={onOffVolumeVideo}
          >
            {isVolumeOn ? (
              <>
                <FontAwesomeIcon icon={faVolumeHigh} className="me-2 icon_btn" />
                {__("livestream_on")}
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faVolumeXmark} className="me-2 icon_btn" />
                {__("livestream_off")}
              </>
            )}
          </Button>
        </div>

        {BannerStatus}

        {ButtonReacts}
      </div>
      <div
        className={'position-absolute'}
        style={{
          bottom: '90px',
          maxWidth: '500px',
          zIndex: 999999
        }}
      >
        {pinningProduct && (
          <Stack
            direction={'horizontal'}
            className={'d-flex align-items-start bg-white bg-opacity-50 w-100 h-100 p-2 rounded-2 '}
            gap={2}
          >
            <Image
              src={pinningProduct?.avatar?.media_url}
              width={200}
              style={{
                maxHeight: '130px'
              }}
            />
            <Stack>
              <Stack
                className={'text-black px-2'}
                style={{
                  maxWidth: '250px'
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id={'pinning-product-title'}>{pinningProduct?.title}</Tooltip>}
                >
                  <span className={'fs-6 d-block fw-semibold text-truncate'}>
                    {pinningProduct?.title}
                  </span>
                </OverlayTrigger>
                <span className={'text-primary'}>
                  {__helpers.convertToCommasFormat(pinningProduct?.coin_value)} đ
                </span>
              </Stack>
              <Button
                as={'a'}
                href={__helpers.getRootDomain(`/courses/view/${pinningProduct._id}`)}
                target={'_blank'}
              >
                {__("livestream_watch")}
              </Button>
            </Stack>
          </Stack>
        )}
      </div>

      <LivestreamReactAni ref={refLivestreamReactAni} />
    </div>
  )
}
