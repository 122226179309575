import { SvgIcon, SvgIconProps } from '@mui/material'

const RankingSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 48095867">
          <path
            id="Vector"
            d="M11.9195 34.72H2.41953C1.37073 34.72 0.519531 33.8688 0.519531 32.82V19.9532C0.519531 19.1324 1.15793 18.4712 1.94073 18.4712H11.9195V34.72Z"
            fill="url(#paint0_linear_1101_5244)"
          />
          <path
            id="Vector_2"
            d="M23.3194 34.7201H11.9194V14.0709C11.9194 13.2501 12.5578 12.5889 13.3406 12.5889H21.8982C22.681 12.5889 23.3194 13.2501 23.3194 14.0709V34.7201Z"
            fill="url(#paint1_linear_1101_5244)"
          />
          <path
            id="Vector_3"
            d="M34.7196 32.82C34.7196 33.8688 33.8684 34.72 32.8196 34.72H23.3196V22.8184H33.2984C34.0812 22.8184 34.7196 23.4796 34.7196 24.3004V32.82Z"
            fill="url(#paint2_linear_1101_5244)"
          />
          <path
            id="Vector_4"
            d="M34.7195 28.7463L30.8434 24.7031C30.4634 24.2851 29.8631 24.0723 29.0347 24.0723C28.5939 24.0723 28.2139 24.1407 27.8795 24.2699C27.5527 24.4067 27.3018 24.5815 27.127 24.7943C26.9598 25.0071 26.8307 25.2199 26.7547 25.4327C26.6787 25.6455 26.6407 25.8583 26.6407 26.0711C26.6407 26.2991 26.6787 26.4511 26.7623 26.5347C26.7775 26.5499 26.7927 26.5575 26.8155 26.5727L30.357 30.3271C30.3418 30.7907 30.2355 31.1251 30.0227 31.3455C29.8023 31.5811 29.4527 31.6951 28.9815 31.6951C28.9359 31.6951 28.8902 31.6951 28.837 31.6951C28.837 31.6951 28.8371 31.6875 28.8295 31.6799L27.507 30.3043C27.4234 30.2283 27.2562 30.1903 27.013 30.1903C26.8078 30.1903 26.6634 30.2283 26.5798 30.3043C26.4962 30.3803 26.4583 30.5171 26.4583 30.7223C26.4583 31.2619 26.671 31.7407 27.0966 32.1663C27.0966 32.1663 27.0967 32.1663 27.1043 32.1663L29.5438 34.7123H32.8042C33.853 34.7123 34.7042 33.8611 34.7042 32.8123V28.7387L34.7195 28.7463Z"
            fill="url(#paint3_linear_1101_5244)"
          />
          <path
            id="Vector_5"
            d="M23.3192 24.3689L18.5844 19.4289C18.4704 19.3529 18.3488 19.3149 18.212 19.3149C18.0904 19.3149 17.9916 19.3605 17.908 19.4441L16.73 20.6297C16.5704 20.7513 16.4944 20.9109 16.4944 21.1161C16.4944 21.2453 16.5324 21.3593 16.616 21.4657L17.5508 22.4461V27.5761C17.5508 27.6977 17.604 27.7965 17.718 27.8725L23.3268 33.7245V24.3689H23.3192Z"
            fill="url(#paint4_linear_1101_5244)"
          />
          <path
            id="Vector_6"
            d="M16.4871 21.1161C16.4871 20.9109 16.5707 20.7513 16.7227 20.6297L17.9007 19.4441C17.9843 19.3605 18.0831 19.3149 18.2047 19.3149C18.3415 19.3149 18.4631 19.3529 18.5771 19.4289C18.6835 19.5049 18.7443 19.6037 18.7443 19.7329V27.5837C18.7443 27.7129 18.6835 27.8117 18.5619 27.8877C18.4403 27.9637 18.3035 28.0017 18.1439 28.0017C17.9843 28.0017 17.8323 27.9637 17.7183 27.8877C17.6043 27.8117 17.5435 27.7129 17.5435 27.5837V20.9945L17.1483 21.5189C17.0723 21.5949 16.9887 21.6405 16.8975 21.6405C16.7835 21.6405 16.6847 21.5873 16.6087 21.4809C16.5251 21.3745 16.4871 21.2605 16.4871 21.1313V21.1161Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M11.92 26.8463L7.9224 22.6739C7.4512 22.2331 6.8736 22.0127 6.2048 22.0127C5.5664 22.0127 5.0192 22.2103 4.5632 22.5979C4.1072 22.9931 3.8792 23.4947 3.8792 24.1103C3.8792 24.4371 3.9704 24.6499 4.1528 24.7487L5.8476 26.5195C5.8476 26.5195 5.8476 26.5195 5.8476 26.5271L5.0344 27.1503C4.6696 27.4239 4.3656 27.7355 4.1224 28.0775C3.8792 28.4195 3.75 28.7615 3.75 29.1187V30.2663C3.75 30.3879 3.8108 30.4867 3.9248 30.5627L7.9072 34.7199H11.92V26.8463Z"
            fill="url(#paint5_linear_1101_5244)"
          />
          <path
            id="Vector_8"
            d="M3.75003 30.2665V29.1189C3.75003 28.7617 3.87163 28.4197 4.12243 28.0777C4.36563 27.7357 4.67723 27.4317 5.03443 27.1505L6.12123 26.3221C6.48603 26.0485 6.79003 25.7445 7.04843 25.4101C7.29923 25.0757 7.42843 24.7489 7.42843 24.4145C7.42843 24.0801 7.32203 23.7913 7.11683 23.5481C6.91163 23.2973 6.60763 23.1757 6.20483 23.1757C5.86283 23.1757 5.58923 23.2745 5.37643 23.4721C5.16363 23.6697 5.05723 23.9585 5.05723 24.3461C5.05723 24.4753 4.99643 24.5817 4.88243 24.6729C4.76843 24.7641 4.60883 24.8173 4.41883 24.8173C4.05403 24.8173 3.87163 24.5817 3.87163 24.1181C3.87163 23.5025 4.09963 23.0009 4.55563 22.6057C5.01163 22.2105 5.55883 22.0205 6.19723 22.0205C6.87363 22.0205 7.44363 22.2409 7.91483 22.6817C8.38603 23.1225 8.62163 23.6925 8.62163 24.3917C8.62163 24.8629 8.49243 25.3113 8.24163 25.7217C7.99083 26.1397 7.67923 26.4893 7.32203 26.7781C6.96483 27.0669 6.60003 27.3405 6.24283 27.5913C5.88563 27.8497 5.57403 28.1081 5.32323 28.3665C5.07243 28.6249 4.94323 28.8757 4.94323 29.1265V29.5749H8.27963C8.38603 29.5749 8.47723 29.6281 8.56083 29.7345C8.64443 29.8409 8.68243 29.9701 8.68243 30.1221C8.68243 30.2741 8.64443 30.4033 8.56083 30.5173C8.48483 30.6313 8.38603 30.6921 8.28723 30.6921H4.27443C4.15283 30.6921 4.03883 30.6541 3.91723 30.5705C3.80323 30.4945 3.74243 30.3957 3.74243 30.2741L3.75003 30.2665Z"
            fill="white"
          />
          <path
            id="Vector_9"
            d="M26.4656 30.7223C26.4656 30.5171 26.5036 30.3727 26.5872 30.3043C26.6708 30.2283 26.8152 30.1903 27.0204 30.1903C27.2636 30.1903 27.4308 30.2283 27.5144 30.3043C27.598 30.3803 27.6436 30.5019 27.6436 30.6691C27.6436 30.9503 27.7576 31.1935 27.9932 31.3987C28.2212 31.5963 28.5556 31.7027 28.9888 31.7027C29.46 31.7027 29.802 31.5887 30.03 31.3531C30.2504 31.1175 30.3644 30.7451 30.3644 30.2359V30.0079C30.3644 29.1491 29.9008 28.7159 28.9812 28.7159C28.7228 28.7159 28.5936 28.5487 28.5936 28.2219C28.5936 27.8951 28.7228 27.7203 28.9812 27.7203C29.3688 27.7203 29.6576 27.6291 29.8628 27.4467C30.0604 27.2643 30.1668 26.9223 30.1668 26.4131C30.1668 25.5999 29.7868 25.1895 29.0344 25.1895C28.6088 25.1895 28.3048 25.2807 28.1224 25.4631C27.94 25.6455 27.8488 25.8735 27.8488 26.1471C27.8488 26.4815 27.6588 26.6563 27.2788 26.6563C27.0204 26.6563 26.8532 26.6183 26.7696 26.5347C26.686 26.4587 26.648 26.2991 26.648 26.0711C26.648 25.8583 26.686 25.6455 26.762 25.4327C26.838 25.2199 26.9596 25.0071 27.1344 24.7943C27.3016 24.5815 27.5524 24.4067 27.8868 24.2699C28.2136 24.1331 28.6012 24.0723 29.042 24.0723C30.562 24.0723 31.322 24.7791 31.322 26.1927C31.322 26.6715 31.2384 27.0895 31.0788 27.4315C30.9192 27.7811 30.6912 28.0243 30.4176 28.1611C31.1928 28.4803 31.5728 29.0883 31.5728 29.9927V30.2207C31.5728 31.1099 31.3372 31.7635 30.8584 32.1815C30.3796 32.5995 29.7564 32.8047 28.9812 32.8047C28.1604 32.8047 27.5372 32.5919 27.1116 32.1663C26.686 31.7407 26.4732 31.2543 26.4732 30.7223H26.4656Z"
            fill="white"
          />
          <path
            id="Vector_10"
            d="M20.6523 7.42064C20.5003 7.42064 20.3787 7.29144 20.3787 7.13184C20.3787 6.97224 20.5003 6.84304 20.6523 6.84304C22.1723 6.84304 23.4035 5.55864 23.4035 3.97024V1.66744H20.9259C20.7739 1.66744 20.6523 1.53824 20.6523 1.37864C20.6523 1.21904 20.7739 1.08984 20.9259 1.08984H23.6771C23.8291 1.08984 23.9507 1.21904 23.9507 1.37864V3.96264C23.9507 5.87024 22.4687 7.41304 20.6447 7.41304L20.6523 7.42064Z"
            fill="url(#paint6_linear_1101_5244)"
          />
          <path
            id="Vector_11"
            d="M14.5872 7.42086C12.7632 7.42086 11.2812 5.87806 11.2812 3.97046V1.38646C11.2812 1.22686 11.4029 1.09766 11.5549 1.09766H14.3061C14.4581 1.09766 14.5797 1.22686 14.5797 1.38646C14.5797 1.54606 14.4581 1.67526 14.3061 1.67526H11.8285V3.97806C11.8285 5.56646 13.0597 6.85086 14.5797 6.85086C14.7317 6.85086 14.8532 6.98006 14.8532 7.13966C14.8532 7.29926 14.7317 7.42846 14.5797 7.42846L14.5872 7.42086Z"
            fill="url(#paint7_linear_1101_5244)"
          />
          <path
            id="Vector_12"
            d="M19.3373 10.7647L19.1929 10.6431C18.6153 10.1567 18.4557 8.8115 18.4481 8.3327C18.4481 8.2111 18.3189 8.1123 18.1745 8.1123H17.0725C16.9205 8.1123 16.7989 8.2111 16.7989 8.3327C16.7837 8.8115 16.6317 10.1643 16.0541 10.6431L15.9097 10.7647C15.1497 11.3955 14.6025 11.8515 14.6025 12.3683C14.6025 12.4899 14.7241 12.5963 14.8761 12.5963H20.3861C20.5381 12.5963 20.6597 12.4975 20.6597 12.3683C20.6597 11.8591 20.1125 11.4031 19.3525 10.7647H19.3373Z"
            fill="url(#paint8_linear_1101_5244)"
          />
          <path
            id="Vector_13"
            d="M19.3373 10.7647L19.1929 10.6431C18.6153 10.1567 18.4557 8.8115 18.4481 8.3327C18.4481 8.2111 18.3189 8.1123 18.1745 8.1123H17.0725C16.9205 8.1123 16.7989 8.2111 16.7989 8.3327C16.7837 8.8115 16.6317 10.1643 16.0541 10.6431L15.9097 10.7647C15.1497 11.3955 14.6025 11.8515 14.6025 12.3683C14.6025 12.4899 14.7241 12.5963 14.8761 12.5963H20.3861C20.5381 12.5963 20.6597 12.4975 20.6597 12.3683C20.6597 11.8591 20.1125 11.4031 19.3525 10.7647H19.3373Z"
            fill="url(#paint9_linear_1101_5244)"
          />
          <path
            id="Vector_14"
            d="M21.1995 0.80882V5.40682C21.1995 5.40682 21.1995 5.47522 21.1995 5.51322C21.1463 6.91922 20.3635 8.12002 19.2387 8.72802C18.7523 8.98642 18.2051 9.13842 17.6275 9.14602C15.6591 9.12322 14.0707 7.45882 14.0479 5.40682V0.80882C14.0479 0.64922 14.1695 0.52002 14.3215 0.52002H20.9334C21.0854 0.52002 21.207 0.64922 21.207 0.80882H21.1995Z"
            fill="url(#paint10_linear_1101_5244)"
          />
          <path
            id="Vector_15"
            opacity="0.2"
            d="M17.7104 2.42773L18.8808 3.65133L17.9004 3.76533L17.7104 2.43533V2.42773Z"
            fill="url(#paint11_linear_1101_5244)"
          />
          <path
            id="Vector_16"
            opacity="0.2"
            d="M21.1991 5.51333C21.1459 6.91933 20.3631 8.12013 19.2307 8.72813L16.5479 5.92373L17.0115 4.25933L17.7107 2.42773L18.0298 3.70453H19.4663L21.1991 5.51333Z"
            fill="url(#paint12_linear_1101_5244)"
          />
          <path
            id="Vector_17"
            d="M17.7112 2.42761L18.2432 3.41561C18.2432 3.41561 18.2888 3.46121 18.3192 3.46881L19.3832 3.68921C19.4668 3.70441 19.4972 3.81081 19.444 3.87921L18.7144 4.71521C18.7144 4.71521 18.684 4.76841 18.684 4.80641L18.8056 5.93121C18.8132 6.01481 18.7296 6.08321 18.6536 6.04521L17.6656 5.57401C17.6656 5.57401 17.6048 5.55881 17.5744 5.57401L16.5864 6.04521C16.5104 6.08321 16.4268 6.01481 16.4344 5.93121L16.556 4.80641C16.556 4.80641 16.556 4.73801 16.5256 4.71521L15.796 3.87921C15.7428 3.81841 15.7732 3.71201 15.8568 3.68921L16.9208 3.46881C16.9208 3.46881 16.9816 3.44601 16.9968 3.41561L17.5288 2.42761C17.5668 2.35161 17.6732 2.35161 17.7188 2.42761H17.7112Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1101_5244"
            x1="6.21953"
            y1="18.4712"
            x2="6.21953"
            y2="34.8416"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#69C6FF" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1101_5244"
            x1="17.6194"
            y1="11.9277"
            x2="17.6194"
            y2="34.0665"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1101_5244"
            x1="29.0196"
            y1="22.9324"
            x2="29.0196"
            y2="34.7884"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1101_5244"
            x1="33.5263"
            y1="32.8959"
            x2="22.301"
            y2="22.1419"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1101_5244"
            x1="22.3692"
            y1="27.9333"
            x2="14.9516"
            y2="20.8349"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1101_5244"
            x1="11.1372"
            y1="31.7863"
            x2="-0.931599"
            y2="20.2267"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1F88C9" stopOpacity="0" />
            <stop offset="1" stopColor="#3FA9F5" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1101_5244"
            x1="22.1647"
            y1="0.823844"
            x2="22.1647"
            y2="9.03184"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1101_5244"
            x1="13.0748"
            y1="0.824056"
            x2="13.0748"
            y2="9.03206"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1101_5244"
            x1="14.5873"
            y1="10.3467"
            x2="20.6521"
            y2="10.3467"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1101_5244"
            x1="17.6197"
            y1="12.6039"
            x2="17.6197"
            y2="8.5075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1101_5244"
            x1="17.6199"
            y1="0.82402"
            x2="17.6199"
            y2="9.03202"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1101_5244"
            x1="18.744"
            y1="4.00853"
            x2="17.452"
            y2="2.75453"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1101_5244"
            x1="21.0395"
            y1="7.86933"
            x2="16.7151"
            y2="3.65133"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default RankingSidebarIcon
