import React, {useEffect, useMemo, useState} from 'react'
import './media/livestream.room.scss'
import {Modal, Row} from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import LivestreamRoomVideo from 'entities/livestream/components/livestream.room.video'
import LivestreamRoomInfo from 'entities/livestream/components/livestream.room.info'
import LivestreamChat from 'entities/livestream/components/livestream.chat'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from 'config/store'
import {useLivestreamRoomSocket} from 'entities/livestream/hooks/livestream.room.hook'
import {EnumStatusLivestream} from 'entities/livestream/livestream.interface'
import {clearEntity, detailLiveStream, getDetailLiveStream} from 'entities/livestream/livestream.store.reducer'
import Button from 'react-bootstrap/Button'
import helpers from 'helpers/index'
import __ from "languages/index";
import _Helmet from 'components/helmet'

export default function LivestreamRoom() {
  const params = useParams()
  const event_id = params?.event_id
  const liveDetail = useAppSelector(getDetailLiveStream)
  const isLiveStreamBelongToEvent = Boolean(liveDetail?.event_data)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { socket } = useLivestreamRoomSocket(liveDetail?._id)
  const [showModalBlockMobile, setShowModalBlockMobile] = useState(false)
  const [showModalEndLivestream, setShowModalEndLivestream] = useState(false)

  const backToEvent = () => {
    navigate('/event/detail/' + liveDetail?.event_data?._id)
  }

  const backToHomepage = () => {
    navigate('/')
  }

  useEffect(() => {
    if (helpers.isMobile()) {
      setShowModalBlockMobile(true)
    } else {
      if (event_id) {
        dispatch(detailLiveStream({ id: event_id }))
      } else {
        navigate(helpers.getRootDomain('/'))
      }
    }

    return () => {
      dispatch(clearEntity())
    }
  }, [])

  useEffect(() => {
    if (liveDetail?.livestream_status === EnumStatusLivestream.Ended) {
      setShowModalEndLivestream(true)
    }

    if (liveDetail?.livestream_status === EnumStatusLivestream.Live) {
      setShowModalEndLivestream(false)
    }
  }, [liveDetail?.livestream_status])

  const ModalEndLivestream = useMemo(() => {
    if (!showModalEndLivestream) return

    return (
      <Modal show size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-lg-center" style={{ textAlign: 'center' }}>
          <h5>{__("livestream_closed")}</h5>
          {isLiveStreamBelongToEvent ? (
            <Button onClick={backToEvent}>{__("livestream_back_to_event")}</Button>
          ) : (
            <Button onClick={backToHomepage}>{__("livestream_back_to_home")} </Button>
          )}
        </Modal.Body>
      </Modal>
    )
  }, [showModalEndLivestream])

  const ModalBlockMobile = useMemo(() => {
    if (!showModalBlockMobile) return

    return (
      <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="text-lg-center" style={{ textAlign: 'center' }}>
          <h5>{__("livestream_room_use_mobile")}</h5>
          {isLiveStreamBelongToEvent ? (
            <Button onClick={backToEvent}>{__("livestream_back_to_event")}</Button>
          ) : (
            <Button onClick={backToHomepage}>{__("livestream_back_to_home")} </Button>
          )}
        </Modal.Body>
      </Modal>
    )
  }, [showModalBlockMobile])

  return (
    <div id={'containerLivestreamRoom'}>
      <_Helmet title='livestream_title' />
      <Container fluid>
        <Row>
          <Col xs={12} md={9} className="text-white d-flex flex-column">
            <LivestreamRoomVideo socket={socket} />
          </Col>
          <Col xs={12} md={3} className={' text-white'}>
            <div className="livestream_action">
              <div className={'livestream_action_row_1 mb-3'}>
                <LivestreamRoomInfo socket={socket} />
              </div>
              <div className={'livestream_action_row_2'}>
                <LivestreamChat socket={socket} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {ModalEndLivestream}
      {ModalBlockMobile}
    </div>
  )
}
