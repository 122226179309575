//Library
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'react-bootstrap'

//Icons
import imageDefault from 'media/images/image_default.png'
import iconClose from '../media/icons/iconClose.svg'
// import iconBell from '../media/icons/iconBell.svg'
import iconImage from '../media/icons/iconImage.svg'
import iconDocument from '../media/icons/iconDocument.svg'
import iconLink from '../media/icons/iconLink.svg'
// import iconDelete from '../media/icons/iconDelete.svg'
// import iconReport from '../media/icons/iconReport.svg'
// import iconBlock from '../media/icons/iconBlock.svg'

//Interface
import { TypedChatRoom } from '../chat.interface'

//Scss
import '../media/chat.information.scss'
import MediaOfChatInformation from './chat.information.media'

const ChatInformation = ({
  dataClient,
  handleShowChatInformation
}: {
  dataClient: TypedChatRoom
  handleShowChatInformation: () => void
}) => {
  const { id_chat } = useParams()
  const navigate = useNavigate()

  const [tabIndex, setTabIndex] = useState<number>(0)

  useEffect(() => {
    setTabIndex(0)
  }, [id_chat])

  return (
    <div id="chatInformation" className="chatInformation_container">
      <div className={`${tabIndex && 'd-none'}`}>
        <div className="chatInformation_header_banner">
          <img
            src={dataClient?.partner_id?.user_cover || imageDefault}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.src = imageDefault
            }}
            alt="image_banner"
          />
          <div
            role="button"
            onClick={handleShowChatInformation}
            className="chatInformation_header_banner_button_close"
          >
            <img src={iconClose} width={24} height={24} alt="image_banner" />
          </div>
        </div>
        <div className="chatInformation_header_info_client">
          <div className="chatInformation_header_info_client_avatar">
            <img
              src={dataClient?.partner_id?.user_avatar || imageDefault}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = imageDefault
              }}
              width={120}
              height={120}
              alt="image_avatar"
            />
          </div>
          <div className="chatInformation_header_info_client_body">
            <div className="chatInformation_header_info_client_name">
              <Typography component="p" className="chatInformation_header_info_client_name_title">
                {dataClient?.partner_id?.display_name || ''}
              </Typography>
              <div role="button" className="chatInformation_header_info_client_button">
                <Typography
                  component="button"
                  onClick={() => navigate(`/user/detail/${dataClient?.partner_id?._id}`)}
                  color={'primary.main'}
                >
                  Trang cá nhân
                </Typography>
              </div>
              {/* <div className="chatInformation_header_info_client_notification">
                <img src={iconBell} width={22} height={22} alt="icon_bell" />
                <Typography component={'p'}>Thông báo</Typography>
                <div className="form-switch">
                  <Form.Check name="notification" readOnly />
                </div>
              </div> */}
            </div>

            <hr className="chatInformation_header_info_client_line" />

            <div className="chatInformation_header_info_client_file">
              <div
                onClick={() => setTabIndex(1)}
                className="chatInformation_header_info_client_file_item"
              >
                <img src={iconImage} width={22} height={22} alt="icon_bell" />
                <Typography component={'p'}>Ảnh/Video</Typography>
              </div>
              <div
                onClick={() => setTabIndex(2)}
                className="chatInformation_header_info_client_file_item"
              >
                <img src={iconDocument} width={22} height={22} alt="icon_bell" />
                <Typography component={'p'} variant="body1">
                  Tài liệu
                </Typography>
              </div>
              <div
                onClick={() => setTabIndex(3)}
                className="chatInformation_header_info_client_file_item"
              >
                <img src={iconLink} width={22} height={22} alt="icon_bell" />
                <Typography component={'p'}>Liên kết</Typography>
              </div>
            </div>

            {/* <hr className="chatInformation_header_info_client_line" />

            <div className="chatInformation_header_info_client_file">
              <div className="chatInformation_header_info_client_file_item disabled">
                <img src={iconDelete} width={22} height={22} alt="icon_bell" />
                <Typography component={'p'}>Xóa tin nhắn</Typography>
              </div>
              <div className="chatInformation_header_info_client_file_item disabled">
                <img src={iconReport} width={22} height={22} alt="icon_bell" />
                <Typography component={'p'}>Báo cáo</Typography>
              </div>
              <div className="chatInformation_header_info_client_file_item disabled">
                <img src={iconBlock} width={22} height={22} alt="icon_bell" />
                <Typography component={'p'}>Chặn người dùng</Typography>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {tabIndex > 0 && <MediaOfChatInformation tabIndex={tabIndex} setTabIndex={setTabIndex} />}
    </div>
  )
}

export default ChatInformation
