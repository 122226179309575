import React from 'react'
import { Button, ButtonProps, Spinner, SpinnerProps } from 'react-bootstrap'

type Props = {
  isLoading: boolean
  loadingProps?: SpinnerProps
  loadingText?: string
} & ButtonProps
const LoadingButton = ({
  children,
  isLoading,
  loadingProps,
  loadingText = 'Loading...',
  ...rest
}: Props) => {
  return (
    <Button {...rest}>
      {isLoading && (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            {...loadingProps}
          />
          <span className="visually-hidden">{loadingText}</span>
        </>
      )}
      {children}
    </Button>
  )
}

export default LoadingButton
