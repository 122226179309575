//Library
import axios from 'axios'
import { Avatar, Box, Button, IconButton, Typography, styled } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//Component
import NotificationModal from 'layout/component/NotificationModal'

//Icons
import { EmptyNotificationIcon, NotificationIcon, SentIcon } from 'icons'

//Store
import { getNotification, updateNotification } from 'entities/homepage/home.store.reducer'
import { addTotalNotificationUnread, subTotalNotificationUnread } from 'store/user.store.reducer'

//Images
import avatarDefault from 'media/images/avatar_default.png'

//Config
import { useAppDispatch } from 'config/store'
import { socket } from 'config/socket.io'
import { EnumTypeToast, useToast } from 'hooks/useToast'

//Hooks
import { useClickOutside } from 'hooks/useClickOutside'
import __ from 'languages/index'

//Helpers
import helpers from 'helpers'
import notification_parse_action from 'entities/notification/notification.helpers'
import { TypeDataNotification } from 'entities/notification/interface'

const NotificationComponent = ({ widthCurrent }: { widthCurrent: number }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const wrapperRef = useRef(null)

  const toast = useToast()

  const [totalNotificationUnread, setTotalNotificationUnread] = useState<number>(0)
  const [notificationData, setNotificationData] = useState([])
  const [isShowModalNotification, setIsShowModalNotification] = useState<boolean>(false)
  const [notification, setNotification] = useState<TypeDataNotification>(null)
  const [showModalNotification, setShowModalNotification] = useState(false)

  useClickOutside(wrapperRef, setIsShowModalNotification)

  /**
   * Login from layout/header.tsx
   */

  const onGoToAllNotifications = useCallback(() => {
    setIsShowModalNotification(false)
    navigate('/notifications')
  }, [])

  const handleGetData = useCallback(async () => {
    try {
      const res = await getNotification({ page: 1, limit: 15, all: true })
      setNotificationData(res?.data)
      setTotalNotificationUnread(
        typeof res?.headers['x-total-unread'] === 'number' ||
          !Number.isNaN(Number(res?.headers['x-total-unread']))
          ? Number(res?.headers['x-total-unread'])
          : 0
      )
    } catch (error) {
      console.log(`getNotification_${error}`)
    }
  }, [])

  const handleShowModalNotification = useCallback(() => {
    if (!isShowModalNotification) {
      handleGetData()
    }
    setIsShowModalNotification(!isShowModalNotification)
  }, [isShowModalNotification])

  const handleSeeAll = async () => {
    try {
      let _data: any = await axios.post(`/notification/mark_as_read`)
      if (helpers.isEmpty(notificationData)) return
      const updatedData = notificationData.map(item => {
        if (item.read_status === 0) {
          return { ...item, read_status: 1 }
        }
        return item
      })
      setTotalNotificationUnread(0)
      setNotificationData(updatedData)
    } catch (error) {
      toast.show({
        content: `${__(error)}`,
        type: EnumTypeToast.Error
      })
    }
  }

  /**
   * @refactor jamviet.com note: Khi click vào thông báo, thì sẽ phải parse data từ notification_data
   */
  const handleGotoEventNotification = useCallback(async (item: TypeDataNotification) => {
    if (!item) {
      toast.show({
        content: `${__('notification_content_error')}`,
        type: EnumTypeToast.Error
      })
      return
    }

    const link_to_action = notification_parse_action(item?.notification_data)
    document.body.style.overflow = 'auto'
    updateNotification(item?._id)
      .then(res => { })
      .catch(error => { })
    dispatch(subTotalNotificationUnread(1))
    setIsShowModalNotification(false)

    if (link_to_action) {
      navigate(`${link_to_action}`)
    } else {
      toast.show({
        content: `${__('notification_content_error')}`,
        type: EnumTypeToast.Error
      })
    }
  }, [])

  useEffect(() => {
    try {
      socket.on('receiveNotification', (data: string) => {
        if (helpers.isJson(data)) {
          let dataNotification: TypeDataNotification = JSON.parse(data)
          setNotification(dataNotification)
          setShowModalNotification(true)
          dispatch(addTotalNotificationUnread(1))
          setTotalNotificationUnread(1)
          if (helpers.isJson(dataNotification.notification_data)) {
            let { screen = '' } = JSON.parse(dataNotification.notification_data)
            if (screen !== 'NAVIGATION_CHAT_ROOM') {
              setTimeout(() => {
                setShowModalNotification(false)
                setShowModalNotification(null)
              }, 10000)
            }
          }
        }
      })
    } catch (error) {
      console.log('error>>', error)
    }
  }, [socket])

  useEffect(() => {
    handleGetData()
  }, [])

  return (
    <>
      <NotificationContainer id="notificationComponent" ref={wrapperRef}>
        <IconButton
          className={"notificationComponent_icon_item"}
          onClick={() => widthCurrent > 1024 ? handleShowModalNotification() : navigate('/notifications')}
        >
          <NotificationIcon />
          {Number(totalNotificationUnread) > 0 && (
            <Box className="notificationComponent_unread"></Box>
          )}
        </IconButton>
        {isShowModalNotification && (
          <Box className="notificationComponent_modal">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography component="h6" variant="h6" textTransform='none'>
                {__('notification')}
              </Typography>
              {!helpers.isEmpty(notificationData) && (
                <Button variant="text" onClick={handleSeeAll} sx={{color: 'primary.main'}} className="see_all">
                  <SentIcon color={'inherit'} />
                  <Typography
                    sx={{
                      textTransform: 'none'
                    }}
                    variant="body1"
                    fontWeight={600}
                    color="inherit"
                  >
                    {__('click_see_all')}
                  </Typography>
                </Button>
              )}
            </Box>
            {helpers.isEmpty(notificationData) ? (
              <Box className="notificationComponent_modal_empty">
                <EmptyNotificationIcon
                  sx={{
                    width: theme => theme.spacing(18.75),
                    height: theme => theme.spacing(18.75)
                  }}
                />
                <Typography component={'span'} variant="body1" color="text.disabled">
                  {__('notification_you_do_not_have_any_notifications_yet')}
                </Typography>
              </Box>
            ) : (
              <Box className="notification_wrapper">
                {notificationData?.map((value, idx) => (
                  <Box
                    key={`notification_${idx}`}
                    className={`notification_item ${value?.read_status !== 0 && 'read'}`}
                    onClick={() => handleGotoEventNotification(value)}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={avatarDefault}
                        alt="avatar"
                        sx={{
                          width: theme => theme.spacing(5.5),
                          height: theme => theme.spacing(5.5)
                        }}
                      />
                    </Box>
                    <Typography
                      className="notification_content"
                      variant="body1"
                      fontWeight={value?.read_status === 0 ? 600 : 400}
                      color="text.primary"
                    >
                      {value?.notification_content || ''}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      color="text.secondary"
                    >
                      {helpers.subtractTimeHistory(
                        new Date(value?.createdAt).getTime(),
                        `${new Date().getTime()}`
                      )}
                    </Typography>
                  </Box>
                ))}
                <Button
                  variant="text"
                  onClick={onGoToAllNotifications}
                  className="see_all_notification"
                >
                  <Typography
                    sx={{
                      textTransform: 'none'
                    }}
                    variant="body1"
                    fontWeight={600}
                    color="primary.main"
                  >
                    {__('header_see_all')}
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        )}
      </NotificationContainer>
      {notification && showModalNotification && (
        <div className="position-fixed show_modal bg-white rounded">
          <NotificationModal
            notification={notification}
            handleOpenNotification={handleGotoEventNotification}
            setShowModalNotification={setShowModalNotification}
          />
        </div>
      )}
    </>
  )
}

export default NotificationComponent

const NotificationContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .notificationComponent_icon_item': {
    display: 'grid',
    border: `1px solid ${theme.palette.divider}`,
    placeItems: 'center',
    padding: theme.spacing(0.75),
    borderRadius: theme.spacing(1.5),
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.text.primary
  },
  '& .notificationComponent_unread': {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    background: theme.palette.primary.main,
    borderRadius: '50%',
    position: 'absolute',
    top: '-2px',
    right: '-2px'
  },
  '& .notificationComponent_modal': {
    position: 'absolute',
    width: theme.spacing(56.25),
    height: 'auto',
    maxHeight: theme.spacing(56.25),
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(5)} 0 rgba(14, 20, 38, 0.08)`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    right: 0,
    top: theme.spacing(8.75),
    zIndex: 1,
    '& .notification_wrapper': {
      display: 'grid',
      rowGap: theme.spacing(1),
      height: 'auto',
      overflow: 'auto'
    },
    '& .notificationComponent_modal_empty': {
      display: 'grid',
      rowGap: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(3)
    },
    '& .see_all': {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: theme.spacing(0.75),
      cursor: 'pointer'
    },
    '& .notification_item': {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      borderRadius: theme.spacing(1.5),
      background: theme.palette.background.default,
      padding: theme.spacing(1.5, 2),
      columnGap: theme.spacing(1),
      cursor: 'pointer',
      ' :hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    '& .read': {
      backgroundColor: theme.palette.background.paper
    },
    '& .notification_content': {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '3',
      overflow: 'hidden'
    },
    '& .see_all_notification': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    }
  },
  '@media(max-width: 1024px)': {
    '& .notificationComponent_icon_item': {
      border: 'none'
    }
  }
}))
