import './media/css/index.scss'
import UpgradeHeader from "entities/upgaradeAccount/component/UpgradeHeader";
import UpgradeCompare from "entities/upgaradeAccount/component/UpgradeCompare";
import React, {useEffect, useState} from "react";
import { getEntities } from '../extentions/extensions.store.reducer'
import helpers from "helpers/index";
import {useAppDispatch, useAppSelector} from "config/store";
import ModalUpgradeConfirm from "entities/upgaradeAccount/component/ModalUpgradeConfirm";
import MobileModalPayment from "entities/mobileApp/components/MobileModalPayment";
import BigLayout from "../../layouts/bigLayout";

const UpgradeAccountDetail = () =>{
    const [show, setShow] = useState(false)
    const [isShowModal, setIsShowModal] = useState(false)
    const entities = useAppSelector(state => state.extensions.entities)
    const dispatch = useAppDispatch()
    const rootSearchPath = window.location.search
    const StringQuery = helpers.ExtractUrl(rootSearchPath) || {}
    const initialQuery = {
        order_by:'DESC',
        service_type: 'channel',
        page: 1,
        limit: 50
    }
    const hanleClickBtnUpgrade = ()=>{
        setShow(true)
    }
    useEffect(()=>{
        dispatch(getEntities({
            ...initialQuery,
            ...StringQuery
        }))
        // dispatch(
        //     getListPlan({
        //         service_id:
        //     })
        // )
    },[])
    const handleCloseModalPayment = () =>{
        if(isShowModal){
            setIsShowModal(false)
        }
    }

    return (
        <BigLayout>
            <UpgradeHeader hanleClickBtnUpgrade={hanleClickBtnUpgrade}/>
            <UpgradeCompare listService={entities} hanleClickBtnUpgrade={hanleClickBtnUpgrade}/>
            <ModalUpgradeConfirm show={show} handleClose={()=>{setShow(false)}} onConfirm={()=>{}}/>
            {/*<MobileModalPayment*/}
            {/*    isShowModal={isShowModal}*/}
            {/*    handleCloseModalPayment={handleCloseModalPayment}*/}
            {/*    listPaymentMethod={listPaymentMethod}*/}
            {/*    plan={plan}*/}
            {/*    handleSelectPayment={handleSelectPayment}*/}
            {/*    selectPayment={selectPayment}*/}
            {/*    onClickPayment={onClickPayment}*/}
            {/*/>*/}
        </BigLayout>
    )
}
export  default  UpgradeAccountDetail
