//Library
import React, {memo, useCallback, useMemo, useState} from 'react'
import {AxiosError, AxiosResponse} from 'axios'
import Lightbox from 'yet-another-react-lightbox'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import {Link, useNavigate} from 'react-router-dom'
import {faImage} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import { createChatroomToUser } from 'entities/mentor/store/mentor.store.reducer'
// import {createChatroomToUser} from '../store/user.store.reducer'

//Image
import bannerDefault from '../media/images/banner_default.jpg'
import avatarDefault from 'media/images/avatar_default.png'

//Interface
import {TypeDataUser} from '../interface'

//Helper
import helpers from 'helpers'
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import __ from "languages/index";
import { Box, Button, Typography } from '@mui/material'

const HeaderPage = ({
  dataUser,
  handleImageUpload,
  handleUnFollow,
  handleFollow
}: {
  dataUser: TypeDataUser
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleUnFollow: Function
  handleFollow: Function
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user_data = useAppSelector(state => state.user.user_data)

  const [isOpenViewImage, setIsOpenViewImag] = useState<boolean>(false)
  const [openViewImage, setOpenViewImag] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingMessage, setIsLoadingMessage] = useState<boolean>(false)
  const toast = useToast()

  const isAuthor = useMemo(() => {
    return user_data?._id === dataUser?._id
  }, [user_data?._id, dataUser?._id])

  const isBannerEmpty = useMemo(() => {
    return !Boolean(dataUser?.user_cover)
  }, [dataUser])

  const handleUpdateFollow = useCallback(
    async (action: string) => {
      if (action === 'follow') {
        setIsLoading(true)
        handleFollow(dataUser?._id)
        await helpers.sleep(500)
        setIsLoading(false)
        return
      }
      if (action === 'unFollow') {
        setIsLoading(true)
        handleUnFollow(dataUser?._id)
        await helpers.sleep(500)
        setIsLoading(false)
      }
    },
    [dataUser]
  )

  const handleCreateRoom = useCallback(() => {
    setIsLoadingMessage(true)
    dispatch(createChatroomToUser({ partner_id: dataUser?._id, chat_type: 'personal' }))
      .unwrap()
      .then((res: AxiosResponse) => {
        setIsLoadingMessage(false)
        const { _id } = res?.data?.chat_room_id
        navigate(`/chat/detail/${_id}`)
      })
      .catch((error: AxiosError) => {
        console.log(`createChatroomToUser_${error}`)
        setIsLoadingMessage(false)
        toast.show({
          content: `${__("user_detail_message_error")}`,
          type: EnumTypeToast.Error
        })
      })
  }, [dataUser])

  const handleShowImage = useCallback((image: string) => {
    setIsOpenViewImag(true)
    setOpenViewImag(image)
  }, [])

  const handleCloseImage = useCallback(() => {
    setIsOpenViewImag(false)
    setOpenViewImag('')
  }, [])

  return (
    <div className="userDetail_header">
      <div className="userDetail_header_image_wrapper">
        <img
          src={dataUser?.user_cover || bannerDefault}
          className="userDetail_header_image"
          onClick={() => handleShowImage(dataUser?.user_cover || bannerDefault)}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = bannerDefault
          }}
          alt="image_banner"
          loading="lazy"
        />
        {isAuthor && (
          <>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            <Box 
              component={'label'}
              htmlFor="fileInput"
              sx={{backgroundColor: theme=>theme.palette.primary.main, borderColor: theme=>theme.palette.primary.main}}
              className="userDetail_header_image_add"
              role="button"
            >
              <FontAwesomeIcon size="lg" icon={faImage} />
              <span>{isBannerEmpty ? `${__("user_detail_add_cover_photo")}` : `${__("user_detail_edit_cover_photo")}`}</span>
            </Box>
          </>
        )}
      </div>
      <div className="userDetail_header_body">
        <div className="userDetail_header_user_wrapper">
          <div className="userDetail_header_image_user_wrapper">
            <img
              src={dataUser?.user_avatar_thumbnail || dataUser?.user_avatar || avatarDefault}
              className="userDetail_header_image_user"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = avatarDefault
              }}
              role="button"
              onClick={() => handleShowImage(dataUser?.user_avatar || dataUser?.user_avatar_thumbnail || avatarDefault)}
              alt="image_user"
              loading="lazy"
            />
          </div>
          <div className="userDetail_header_info_user">
            <p className="userDetail_header_info_user_name" title={dataUser?.display_name}>
              {dataUser?.display_name || ''}
            </p>
            {helpers.current_user_can("boss", dataUser?.permission||[]) ? (
              <Typography component={'span'} color={theme => `${theme.palette.primary.main} !important`} className="userDetail_header_info_user_title userDetail_header_info_user_mentor ">
                {__("user_detail_trainer")}
              </Typography>
            ) : (
              <span className="userDetail_header_info_user_title">{`${__("user_detail_Level")} ${
                dataUser?.level_number || '0'
              } - ${dataUser?.point || '0'} ${__("point_unit")}`}</span>
            )}
          </div>
        </div>
        <div className="userDetail_header_button_wrapper">
          {isAuthor ? (
            <div className="userDetail_header_button_edit_profile">
              <Link to="/user/setting">
                <Button sx={{textTransform: 'none'}}>{__("user_detail_edit_profile")}</Button>
              </Link>
            </div>
          ) : (
            <div className="userDetail_header_button">
              {!dataUser?.is_follow && (
                <Button
                  variant='outlined'
                  onClick={() => handleUpdateFollow('follow')}
                  className="userDetail_header_button_follow"
                  sx={{textTransform: 'none'}}
                >
                  {isLoading ? `${__("user_setting_processing")}` : `${__("user_detail_btn_follow")}`}
                </Button>
              )}
              {dataUser?.is_follow && (
                <Button onClick={() => handleUpdateFollow('unFollow')} sx={{backgroundColor: theme=> theme.palette.text.secondary, textTransform: 'none'}}>
                  {isLoading ? `${__("user_setting_processing")}`: `${__("user_detail_btn_unfollow")}`}
                </Button>
              )}
              <Button onClick={handleCreateRoom} sx={{textTransform: 'none'}}>
                {isLoadingMessage ? `${__("user_setting_processing")}` : `${__("user_detail_btn_chat")}`}
              </Button>
            </div>
          )}
        </div>
      </div>
      {isOpenViewImage && (
        <Lightbox
          slides={[
            {
              type: 'image',
              src: openViewImage
            }
          ]}
          carousel={{
            finite:true
          }}
          open={isOpenViewImage}
          close={handleCloseImage}
          index={0}
          render={{
            buttonNext: () => null,
            buttonPrev: () => null
          }}
          plugins={[Fullscreen, Zoom, Counter]}
        />
      )}
    </div>
  )
}

export default memo(HeaderPage)
