import React, {useCallback} from "react";
import '../media/css/index.scss';
import '../media/css/extension.skeleton.scss';
import ratingStarExtension from "media/icons/rating-star-extension.svg";

export default function ExtensionsSkeleton() {

  const renderItem = useCallback((_, index) => {
    return (

      <div key={index + '_jddjh_sk'} className="col-12 col-md-4" >
          <div className="extension_element d-flex mb-4" >
            <div className="extension_thumbnail">
              <div className="extension_thumbnail_img w-100 rounded-4 skeleton" style={{height:"100%"}}/>
            </div>
            <div className="extension_meta_in_list">
              <div className="e_title skeleton skeleton-text w-50" style={{height:22}}></div>
              <div className="e_vote mb-1 skeleton skeleton-text" style={{height:22}}>
                <img  src={ratingStarExtension} alt="" />
              </div>
              <div className="e_content skeleton skeleton-text w-50"></div>
            </div>
          </div>
      </div>
    )
  }, [])

  return (
    <div className="row ps-3 pe-3">
      {[1,2,3,4,5,6,7,8].map(renderItem)}
    </div>
  )
}
