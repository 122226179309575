import React from 'react'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Stack} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import __ from "languages/index";

const LinkToListTicket = ({ text = `${__("support_list_ticket")}` }: { text?: string }) => {
  return (
    <Stack gap={2} direction="horizontal">
      <FontAwesomeIcon icon={faChevronLeft} className="text-primary" />
      <Link to="/support" className="text-decoration-none">
        {text}
      </Link>
    </Stack>
  )
}

export default LinkToListTicket
