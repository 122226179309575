import { Checkbox, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Radio, IconButton } from "@mui/material"
import { ArrowLeftIcon, CheckInactiveIcon, CloseIcon } from "icons"
import __ from "languages/index"
import { useNavigate } from "react-router-dom";
import { TypedLogisticService } from "../interface";

interface TypedProps {
    dataTable: TypedLogisticService[];
    actionCheckTableLogistic: (dataLogistices: TypedLogisticService[]) => void
}
const ShippingUnitsTable = ({ dataTable, actionCheckTableLogistic }: TypedProps) => {

    const navigate = useNavigate();

    const handleCheckeTale = (item: TypedLogisticService, field: string, value: boolean) => {
        const newData = {
            ...item,
            [field]: Number(value)
        }

        const dataTableNew = dataTable.map((res) => {
            res.ID === item.ID ? newData : res

            if (res.ID === item.ID) {
                return newData
            }else{
                if (field === 'is_default_service') {
                    return {
                        ...res,
                        [field]: 0
                    }
                }
                return res
            }
        })

        actionCheckTableLogistic(dataTableNew)

    }

    return (
        <ShippingUnitTableContainer>
            <IconButton onClick={() => navigate(-1)} >
                <ArrowLeftIcon />
            </IconButton>
            <Typography variant="h6" >
                {__('product_shipping_units')}
            </Typography>

            <TableContainer className="table_shipping_units">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell > <Typography variant="subtitle1" >{__('transport_unit')}</Typography></TableCell>
                            <TableCell ><Typography variant="subtitle1" >{__('status')}</Typography></TableCell>
                            <TableCell ><Typography variant="subtitle1" >{__('default_apply')}</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataTable?.length ? dataTable.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell><Typography variant="subtitle2" >{item.logistic_service.logistic_name}</Typography></TableCell>
                                    <TableCell><Typography variant="body1" color={'primary.main'} >
                                        <Checkbox
                                            checked={!!item.logistic_service_status}
                                            onChange={(e) => handleCheckeTale(item, "logistic_service_status", e.target.checked)}
                                        /> {__('hide_unit')}</Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            marginLeft: '20%',
                                            display: 'flex'
                                        }}
                                    >
                                        <Radio
                                            checked={!!item.is_default_service}
                                            checkedIcon={<CheckInactiveIcon color="primary" />}
                                            onChange={(e) => handleCheckeTale(item, "is_default_service", e.target.checked)}
                                        />
                                    </TableCell>

                                </TableRow>
                            )) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </ShippingUnitTableContainer>
    )

}


export default ShippingUnitsTable

const ShippingUnitTableContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),

    '& button': {
        padding: 0,
        marginBottom: theme.spacing(2)
    },
    '& .table_shipping_units': {
        marginTop: theme.spacing(5),
    }
}))
