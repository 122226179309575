//Library
import React from 'react'
import dateandtime from 'date-and-time'
import { Box, Typography } from '@mui/material'

//Image
import imageDefault from 'media/images/image_default.png'

//Interface
import { TypedChatRoom } from '../chat.interface'

//Store
import { useAppSelector } from 'config/store'

//Helpers
import helpers from 'helpers'

//Scss
import '../media/chat.inbox.item.scss'

const ItemInbox = ({ dataChatRoom }: { dataChatRoom: TypedChatRoom }) => {
  const user_data = useAppSelector(state => state.user.user_data)

  return (
    <div
      id="itemInbox"
      className={`itemInbox_container ${
        Number(dataChatRoom?.read_count) > 0 &&
        user_data?._id !== dataChatRoom?.chat_room_id?.last_user_seen &&
        'unread_messages'
      }`}
      role="button"
    >
      {Number(dataChatRoom?.read_count) > 0 &&
        user_data?._id !== dataChatRoom?.chat_room_id?.last_user_seen && (
          <Box
            sx={{
              position: 'absolute',
              background: theme => theme.palette.primary.main,
              right: '10px',
              bottom: '16px',
              width: '10px',
              height: '10px',
              borderRadius: '50%'
            }}
          ></Box>
        )}
      <img
        className="itemInbox_avatar_client"
        src={dataChatRoom?.partner_id?.user_avatar || imageDefault}
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
          (e.currentTarget.src = imageDefault)
        }
        alt="avatar_client"
      />
      <div className="itemInbox_content">
        <div className="d-flex justify-content-between">
          <Typography component={'p'} className="itemInbox_content_name_client fw-bold">
            {dataChatRoom?.partner_id?.display_name || ''}
          </Typography>
          <Typography>
            {helpers.subtractTimeHistory(
              new Date(dataChatRoom?.last_updated).getTime(),
              `${new Date().getTime()}`
            )}
          </Typography>
        </div>
        <div className="d-flex white-space-nowrap">
          {user_data?._id === dataChatRoom?.chat_room_id?.first_history?.createBy && (
            <Typography component={'p'}>Bạn: </Typography>
          )}
          <Typography component={'p'} className="itemInbox_content_message_client">
            {dataChatRoom?.chat_room_id?.last_message || ''}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ItemInbox
