import {z} from 'zod'
import {invalid} from 'entities/support/utils'
import __ from "languages/index";

export const CreateLiveStreamSchema = z.object({
  avatar: z.string().min(1, invalid('required', `${__("validation_thumbnail")}`)),
  title: z.string().min(1, invalid('required', `${__("validation_title")}`)).max(150, invalid('maxLength', 150)),
  caption: z.string().min(1, invalid('required', `${__("validation_description")}`)).max(150, invalid('maxLength', 150))
})

export type CreateLiveStreamPayload = z.infer<typeof CreateLiveStreamSchema>
