import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginationResponse } from 'entities/challenge/challenge.interface'
import { Podcast } from '../podcast.types'
import { convertParamsToQueryString } from 'entities/challenge/store/challengeApi'
import { InjectPaginateParams } from 'entities/support/ticket.type'

type FilterPodcastType = {
  podcast_type: string
  podcast_category: string
  search: string
  post_parent: string
  podcast_status: string
  podcast_language: string
  user_id: string
}

type GetListPodcastParams = InjectPaginateParams<Partial<FilterPodcastType>>
type ListPodcastResponse = PaginationResponse<Podcast[]>

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_AJAX_URL + '/podcast',
  prepareHeaders: headers => {
    headers.set('X-Authorization', localStorage.getItem('session'))
    return headers
  }
  // credentials: 'include'
})

const podcastApi = createApi({
  baseQuery,
  reducerPath: 'podcastApi',
  tagTypes: ['ListPodcast'],
  endpoints: build => ({
    list: build.query<ListPodcastResponse, GetListPodcastParams>({
      query(params) {
        return `/list${convertParamsToQueryString({ ...params })}`
      },
      providesTags: ['ListPodcast'],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      transformResponse: (response: Podcast[], meta) => {
        return {
          data: response,
          totalCount: Number(meta.response.headers.get('X-Total-Count'))
        }
      },
      merge: (currentCacheData, newData, { arg }) => {
        if (currentCacheData.data && arg.page !== 1) {
          return {
            ...currentCacheData,
            ...newData,
            data: [...currentCacheData.data, ...newData.data]
          }
        }
        return newData
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),
    createPodcast: build.mutation<any, any>({
      query(payload) {
        return {
          url: '/create',
          method: 'POST',
          body: payload
        }
      }
    })
  })
})

export const { useListQuery, useCreatePodcastMutation } = podcastApi

export default podcastApi
