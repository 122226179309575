import { SvgIcon, SvgIconProps } from '@mui/material'

const GiftSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M16.5621 6.65343V8.96383C16.5621 9.85303 16.0529 10.6662 15.2473 11.0538L12.1465 12.5358C11.0369 13.0678 9.73729 12.3306 9.62329 11.107L9.50169 9.80743C9.44849 9.20703 9.57009 8.62183 9.83609 8.11263C10.2389 7.34503 10.1857 6.42543 9.71449 5.70343C9.04569 4.69263 8.75689 3.43103 9.00009 2.15423C9.17489 1.21183 9.99569 0.527832 10.9533 0.527832C12.0781 0.527832 13.1573 0.976232 13.9477 1.76663L14.9509 2.76983C15.9769 3.79583 16.5621 5.19423 16.5621 6.65343Z"
          fill="url(#paint0_linear_2408_2285)"
        />
        <path
          d="M16.3047 4.99658H9.32788C9.43428 5.23978 9.56348 5.47538 9.70788 5.69578C10.1867 6.41778 10.2323 7.33738 9.82948 8.10498C9.56348 8.61418 9.44188 9.19178 9.49508 9.79978L9.61668 11.107C9.73068 12.3306 11.0303 13.0678 12.1399 12.5358L15.2407 11.0538C16.0463 10.6738 16.5555 9.86058 16.5555 8.96378V6.65338C16.5555 6.08338 16.4643 5.52858 16.2971 4.99658H16.3047Z"
          fill="url(#paint1_linear_2408_2285)"
        />
        <path
          d="M16.1984 7.9606C16.1984 5.901 14.3744 4.229 12.1248 4.229C11.0836 4.229 10.1336 4.5862 9.41919 5.1714C9.51039 5.3538 9.60919 5.5286 9.71559 5.6958C10.1944 6.4178 10.24 7.3374 9.83719 8.105C9.57119 8.6142 9.44959 9.1918 9.50279 9.7998L9.60159 10.8942C10.2932 11.3958 11.1672 11.6922 12.1172 11.6922C14.3668 11.6922 16.1908 10.0202 16.1908 7.9606H16.1984Z"
          fill="url(#paint2_linear_2408_2285)"
        />
        <path
          d="M13.812 9.90594C15.56 9.90594 16.9736 9.07754 16.9736 8.06674C16.9736 7.05594 15.56 6.22754 13.812 6.22754C12.064 6.22754 10.6504 7.05594 10.6504 8.06674C10.6504 9.07754 12.064 9.90594 13.812 9.90594Z"
          fill="url(#paint3_linear_2408_2285)"
        />
        <path
          d="M25.394 8.10482C25.66 8.61402 25.7816 9.19162 25.7284 9.79962L25.6068 11.0992C25.4928 12.3228 24.1932 13.06 23.0836 12.528L19.9828 11.046C19.1772 10.666 18.668 9.84522 18.668 8.95602V6.64562C18.668 5.18642 19.2456 3.79562 20.2792 2.76202L21.2824 1.75882C22.0804 0.96082 23.152 0.52002 24.2768 0.52002C25.2344 0.52002 26.0552 1.20402 26.23 2.13882C26.4732 3.41562 26.1844 4.67722 25.5156 5.68802C25.0368 6.41002 24.9912 7.32962 25.394 8.09722V8.10482Z"
          fill="url(#paint4_linear_2408_2285)"
        />
        <path
          d="M25.5156 5.70311C25.66 5.48271 25.7892 5.24711 25.9032 5.00391H18.9264C18.7592 5.53591 18.668 6.09071 18.668 6.66071V8.97111C18.668 9.86031 19.1772 10.6735 19.9828 11.0611L23.0836 12.5431C24.1932 13.0751 25.4928 12.3379 25.6068 11.1143L25.7284 9.80711C25.7816 9.19911 25.66 8.62151 25.394 8.11231C24.9912 7.34471 25.0444 6.42511 25.5232 5.70311H25.5156Z"
          fill="url(#paint5_linear_2408_2285)"
        />
        <path
          d="M19.0405 7.9606C19.0405 5.901 20.8645 4.229 23.1141 4.229C24.1553 4.229 25.1053 4.5862 25.8197 5.1714C25.7285 5.3538 25.6297 5.5286 25.5233 5.6958C25.0445 6.4178 24.9989 7.3374 25.4017 8.105C25.6677 8.6142 25.7893 9.1918 25.7361 9.7998L25.6373 10.8942C24.9457 11.3958 24.0717 11.6922 23.1217 11.6922C20.8721 11.6922 19.0481 10.0202 19.0481 7.9606H19.0405Z"
          fill="url(#paint6_linear_2408_2285)"
        />
        <path
          d="M21.4262 9.90594C23.1742 9.90594 24.5878 9.07754 24.5878 8.06674C24.5878 7.05594 23.1742 6.22754 21.4262 6.22754C19.6782 6.22754 18.2646 7.05594 18.2646 8.06674C18.2646 9.07754 19.6782 9.90594 21.4262 9.90594Z"
          fill="url(#paint7_linear_2408_2285)"
        />
        <path
          d="M20.1494 8.02125C20.1722 9.41965 19.0474 10.5748 17.649 10.59C16.2506 10.6052 15.0954 9.48805 15.0802 8.08965C15.0574 6.69125 16.1822 5.53605 17.5806 5.52085C18.979 5.49805 20.1342 6.62285 20.1494 8.02125Z"
          fill="url(#paint8_linear_2408_2285)"
        />
        <path
          d="M31.5129 34.3704H3.71966C2.82286 34.3704 2.09326 33.6408 2.09326 32.744V15.8188C2.09326 14.922 2.82286 14.1924 3.71966 14.1924H31.5129C32.4097 14.1924 33.1393 14.922 33.1393 15.8188V32.744C33.1393 33.6408 32.4097 34.3704 31.5129 34.3704Z"
          fill="url(#paint9_linear_2408_2285)"
        />
        <path
          d="M31.5129 14.2H3.71966C2.82286 14.2 2.09326 14.9296 2.09326 15.8264V17.5288C2.09326 17.5288 11.2285 27.1428 18.3877 34.3704H31.5129C32.4097 34.3704 33.1393 33.6408 33.1393 32.744V15.8188C33.1393 14.922 32.4097 14.1924 31.5129 14.1924V14.2Z"
          fill="url(#paint10_linear_2408_2285)"
        />
        <path
          d="M17.6201 14.2002H27.9257V34.3706H17.6201V14.2002Z"
          fill="url(#paint11_linear_2408_2285)"
        />
        <path
          d="M20.037 34.7276H15.1958C14.7094 34.7276 14.3142 34.3324 14.3142 33.846V15.074C14.3142 14.5876 14.7094 14.1924 15.1958 14.1924H20.037C20.5234 14.1924 20.9186 14.5876 20.9186 15.074V33.846C20.9186 34.3324 20.5234 34.7276 20.037 34.7276Z"
          fill="url(#paint12_linear_2408_2285)"
        />
        <path
          d="M14.3208 15.074V30.2208C15.6964 31.6344 17.0796 33.0404 18.3944 34.3704H20.7504C20.8568 34.226 20.9252 34.0436 20.9252 33.846V15.074C20.9252 14.5876 20.53 14.1924 20.0436 14.1924H15.2024C14.716 14.1924 14.3208 14.5876 14.3208 15.074Z"
          fill="url(#paint13_linear_2408_2285)"
        />
        <path
          d="M32.4623 17.6278H2.77673C1.53033 17.6278 0.519531 16.617 0.519531 15.3706V12.1254C0.519531 10.879 1.53033 9.86816 2.77673 9.86816H32.4623C33.7087 9.86816 34.7195 10.879 34.7195 12.1254V15.3706C34.7195 16.617 33.7087 17.6278 32.4623 17.6278Z"
          fill="url(#paint14_linear_2408_2285)"
        />
        <path
          d="M32.4623 9.87576H12.5123L10.0195 12.3686L15.2787 17.6278H32.4547C33.7011 17.6278 34.7119 16.617 34.7119 15.3706V12.1254C34.7119 10.879 33.7011 9.86816 32.4547 9.86816L32.4623 9.87576Z"
          fill="url(#paint15_linear_2408_2285)"
        />
        <path
          d="M17.6201 9.87549H27.9257V17.6275H17.6201V9.87549Z"
          fill="url(#paint16_linear_2408_2285)"
        />
        <path
          d="M31.0417 17.6275H17.6201V11.7983H25.2125L31.0417 17.6275Z"
          fill="url(#paint17_linear_2408_2285)"
        />
        <path
          d="M20.4777 18.0303H14.7701C14.5269 18.0303 14.3293 17.8327 14.3293 17.5895V9.91346C14.3293 9.67026 14.5269 9.47266 14.7701 9.47266H20.4777C20.7209 9.47266 20.9185 9.67026 20.9185 9.91346V17.5895C20.9185 17.8327 20.7209 18.0303 20.4777 18.0303Z"
          fill="url(#paint18_linear_2408_2285)"
        />
        <path
          d="M20.9176 9.91346V17.5895C20.9176 17.8327 20.72 18.0303 20.4768 18.0303H14.7464C14.5108 18.0303 14.3208 17.8403 14.3208 17.6047V9.91346C14.3208 9.91346 14.3208 9.89066 14.3208 9.87546C14.3436 9.64746 14.5336 9.47266 14.7616 9.47266H20.4692C20.6972 9.47266 20.8872 9.64746 20.91 9.87546C20.91 9.89066 20.91 9.89826 20.91 9.91346H20.9176Z"
          fill="url(#paint19_linear_2408_2285)"
        />
        <path
          d="M17.6194 9.47303L17.5662 9.43503C15.7574 8.23423 13.3482 8.47743 11.813 10.0126L10.0194 11.8062C9.76857 12.057 9.94337 12.4978 10.3082 12.4978H10.5134C11.1974 12.4978 11.7522 13.0526 11.7522 13.7366C11.7522 14.0938 12.1854 14.2762 12.4438 14.0254L16.4642 10.005C16.7682 9.70103 17.1786 9.51103 17.6118 9.48063L17.6194 9.47303Z"
          fill="url(#paint20_linear_2408_2285)"
        />
        <path
          d="M17.6201 9.47303L17.6733 9.43503C19.4821 8.23423 21.8913 8.47743 23.4265 10.0126L25.2201 11.8062C25.4709 12.057 25.2961 12.4978 24.9313 12.4978H24.7261C24.0421 12.4978 23.4873 13.0526 23.4873 13.7366C23.4873 14.0938 23.0541 14.2762 22.8033 14.0254L18.7829 10.005C18.4789 9.70103 18.0685 9.51103 17.6353 9.48063L17.6201 9.47303Z"
          fill="url(#paint21_linear_2408_2285)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2408_2285"
            x1="10.2313"
            y1="5.17903"
            x2="16.1289"
            y2="11.069"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2408_2285"
            x1="12.7707"
            y1="7.17778"
            x2="13.3939"
            y2="9.94418"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2408_2285"
            x1="13.432"
            y1="6.8814"
            x2="18.5316"
            y2="2.671"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2408_2285"
            x1="13.7436"
            y1="8.41634"
            x2="14.5568"
            y2="4.22874"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2408_2285"
            x1="21.974"
            y1="5.31562"
            x2="27.2788"
            y2="10.6204"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2408_2285"
            x1="22.6504"
            y1="6.89631"
            x2="21.7612"
            y2="9.74631"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2408_2285"
            x1="21.4345"
            y1="6.585"
            x2="16.3349"
            y2="2.36701"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2408_2285"
            x1="21.4718"
            y1="8.30234"
            x2="20.6586"
            y2="4.11474"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_2408_2285"
            x1="16.7522"
            y1="6.85085"
            x2="19.1158"
            y2="10.1644"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_2408_2285"
            x1="17.6201"
            y1="15.3172"
            x2="17.6201"
            y2="34.2032"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_2408_2285"
            x1="17.6201"
            y1="27.8496"
            x2="17.6201"
            y2="16.2672"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_2408_2285"
            x1="25.0985"
            y1="24.2854"
            x2="20.3181"
            y2="24.2854"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_2408_2285"
            x1="14.2686"
            y1="24.4676"
            x2="20.835"
            y2="24.4676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_2408_2285"
            x1="17.6192"
            y1="23.4188"
            x2="17.6192"
            y2="16.7612"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_2408_2285"
            x1="17.6195"
            y1="10.2862"
            x2="17.6195"
            y2="18.2358"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B5CC54" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_2408_2285"
            x1="22.8331"
            y1="12.6118"
            x2="18.0223"
            y2="5.33096"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_2408_2285"
            x1="25.0985"
            y1="13.7515"
            x2="20.3181"
            y2="13.7515"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_2408_2285"
            x1="26.8161"
            y1="18.3343"
            x2="16.5181"
            y2="11.4335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#738611" stopOpacity="0" />
            <stop offset="1" stopColor="#738611" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_2408_2285"
            x1="15.9329"
            y1="12.5127"
            x2="19.9381"
            y2="15.4539"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_2408_2285"
            x1="18.8656"
            y1="16.3963"
            x2="16.0536"
            y2="10.4531"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEA613" stopOpacity="0" />
            <stop offset="1" stopColor="#E94444" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_2408_2285"
            x1="12.8314"
            y1="9.92903"
            x2="14.5034"
            y2="11.601"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_2408_2285"
            x1="22.3777"
            y1="9.95943"
            x2="20.7057"
            y2="11.6314"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default GiftSidebarIcon
