import React, { useCallback, useEffect, useState } from 'react'
import {useLocation} from 'react-router-dom'
import {useAppDispatch} from 'config/store'
import { clearError, getEntities } from 'entities/orders/order.store.reducer'
import {Form} from 'react-bootstrap'
import './media/css/order.list.scss'
import {ORDER_SORT_OPTIONS} from 'entities/orders/constant/order.constant'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {Order} from "entities/orders/order.type";
import Title from "components/Title";
import __ from "languages/index";
import _Helmet from 'components/helmet'
import BigLayout from "../../layouts/bigLayout";
import OrderListTable from './components/OrderListTable'
import { IQueryParams } from 'config/reducer.utils'
import { Box } from '@mui/material'
import ComponentEmpty from 'components/componentEmpty'
import moment from 'moment/moment'
import { omit } from 'lodash'
import __helpers from 'helpers/index'

const Orders = () => {
  const initialQuery = {
    order_by: "DESC",
    page: 1,
    limit: 10,
  }
  const [totalCount, setTotalCount] = useState(0)
  const [listOrder, setListOrder] = useState<Order[]>([])
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [paramRequest, setParamRequest] = useState<IQueryParams>({ ...initialQuery });


  useEffect(() => {
    getListOrder(paramRequest)
  }, [paramRequest])

  const dispatch = useAppDispatch()

  const handleFilterByField = (type, value) => {
    const newVal =
      type === 'createdAt' ? moment(value).format('YYYY-MM-DD') : value;
    const newValues: IQueryParams = value === 0 || value
      ? {
        ...paramRequest,
        page: type === 'query' ? 1 : paramRequest?.page,
        [type]: newVal
      }
      : omit(paramRequest, [type]);
    setParamRequest(newValues);
  };

  const location = useLocation()

  const handleClick = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setParamRequest({
      ...paramRequest,
      status: event?.target?.value
    })
  },[])


  const getListOrder = (prams: IQueryParams) => {
    setIsRefresh(true)
    dispatch(getEntities(prams))
      .unwrap()
      .then((res: AxiosResponse) => {
        setListOrder(res.data)
        setTotalCount(res.headers['x-total-count'])
        setIsRefresh(false)
      })
      .catch((error: AxiosError) => {
        console.log(`getEntities error >>>`, error)
      })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(clearError())
  }, [])

  return (
    <BigLayout>
      <_Helmet title='order_list_title' />
      <div className="order_container_select_box">
        <div className="order_select_box">
          <Title text={__('order_list_title')} />
          <div className="order_container_sort">
            <Form.Select
              style={{ cursor: 'pointer' }}
              aria-label=""
              onChange={handleClick}
              defaultValue={paramRequest?.status ? paramRequest?.status : undefined}
            >
              {ORDER_SORT_OPTIONS.map((item, index: number) => (
                <option key={index.toString()} value={item?.value}>
                  {item?.title}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="p-3"></div>
      <Box width={'100%'}>
        {Boolean((!listOrder || listOrder?.length < 1)) ?
          <ComponentEmpty /> : (
            <OrderListTable
              orders={listOrder}
              pathname={location?.pathname}
              isRefresh={isRefresh}
              paramsRequest={paramRequest}
              totalCount={totalCount}
              handleFilterByField={handleFilterByField}
            />
          )}
      </Box>
    </BigLayout>
  )
}

export default Orders
