import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const LetterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 4C7.44772 4 7 4.44772 7 5V12.6835L11.5939 14.7252C11.8524 14.8401 12.1476 14.8401 12.4061 14.7252L17 12.6835V5C17 4.44772 16.5523 4 16 4H8ZM19 11.7946V5C19 3.34315 17.6569 2 16 2H8C6.34315 2 5 3.34315 5 5V11.7946L4.81228 11.7111C3.48971 11.1233 2 12.0914 2 13.5388V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V13.5388C22 12.0914 20.5103 11.1233 19.1877 11.7111L19 11.7946ZM12 6C12.5523 6 13 6.44772 13 7V8H14C14.5523 8 15 8.44772 15 9C15 9.55228 14.5523 10 14 10H13V11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11V10H10C9.44772 10 9 9.55228 9 9C9 8.44772 9.44772 8 10 8H11V7C11 6.44772 11.4477 6 12 6ZM20 13.5388L13.2184 16.5528C12.4427 16.8976 11.5573 16.8976 10.7816 16.5528L4 13.5388L4.40614 12.625L4 13.5388V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19L20 13.5388Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default LetterIcon
