import React, {useEffect} from 'react'
import FileUploader from 'components/FileUploader'
import useUploadFile from 'hooks/useUploadFile'
import {Col, Container, Form, FormGroup, Image, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {CreateLiveStreamPayload, CreateLiveStreamSchema} from '../liveStream.validate'
import {useCreateLiveStreamMutation} from '../store/liveStreamApi'
import {zodResolver} from '@hookform/resolvers/zod'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import {useNavigate} from 'react-router-dom'
import {getDefaultImage} from 'services/media.service'
import LoadingButton from 'components/LoadingButton'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import BigLayout from "../../../layouts/bigLayout";

const CreateLiveStream = () => {

  const [defaultCoverImage, setDefaultCoverImage] = React.useState<{
    url: string
    id: string
  } | null>(null)
  const [thumbnail, setThumbnail] = React.useState<File[] | null>(null)
  const { srcFiles: uploadedThumbnail } = useUploadFile(thumbnail, {
    isMultiple: false
  })

  const [createLiveStream, { isLoading: creatingLiveStream }] = useCreateLiveStreamMutation()
  const {
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors }
  } = useForm<CreateLiveStreamPayload>({
    resolver: zodResolver(CreateLiveStreamSchema)
  })

  const toast = useToast()
  const navigate = useNavigate()

  const resetForm = () => {
    setThumbnail(null)
    reset()
  }

  const handleCreateLiveStream = async (payload: CreateLiveStreamPayload) => {
    try {
      const liveStream = await createLiveStream(payload).unwrap()
      resetForm()
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__("livestream_create_success")}`
      })
      navigate(`/livestream/${liveStream?._id}`)
    } catch (err) {
      toast.show({
        type: EnumTypeToast.Error,
        content: `${__("livestream_create_failed")}`
      })
    }
  }

  useEffect(() => {
    const getDefaultCoverImage = async () => {
      const { url, id } = await getDefaultImage('livestream')
      setDefaultCoverImage({ url, id })
      setValue('avatar', id)
    }
    getDefaultCoverImage()
  }, [])

  useEffect(() => {
    setValue('avatar', uploadedThumbnail[0]?._id)
  }, [uploadedThumbnail])

  return (
    <BigLayout>
      <_Helmet title='Livestream' />
      <Container className="py-5" style={{maxWidth: '996px'}}>
        <h1 className="mb-3">{__("livestream_create_title")}</h1>
        <Row as={Form} className="gy-2" onSubmit={handleSubmit(handleCreateLiveStream)}>
          <Col xs={12}>
            <input
              type="text"
              {...register('avatar')}
              value={uploadedThumbnail[0]?._id}
              hidden
              readOnly
            />
            {errors?.avatar?.message}
            <FileUploader
              notice={__("livestream_loader_notice")}
              onUpload={files => {
                setThumbnail(files)
                setValue('avatar', uploadedThumbnail[0]?._id)
              }}
              acceptFile={'image/jpeg, image/png, image/jpg'}
            >
              {() => {
                return (
                  <Image
                    className="w-100"
                    src={
                      uploadedThumbnail[0]?.media_url ||
                      uploadedThumbnail[0]?.media_thumbnail ||
                      defaultCoverImage?.url
                    }
                  />
                )
              }}
            </FileUploader>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Form.Label aria-required>{__("livestream_create_title_label")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={__("livestream_create_title_placeholder")}
                {...register('title')}
              />
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Form.Label>{__("livestream_create_des_label")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={__("livestream_create_des_placeholder")}
                {...register('caption')}
              />
            </FormGroup>
          </Col>
          <Col>
            <Row className="justify-content-end">
              <Col xs={12} md={3}>
                <LoadingButton
                  variant="primary"
                  type="submit"
                  className="w-100"
                  isLoading={creatingLiveStream}
                >
                  {`${!creatingLiveStream ? `${__("livestream_create")}` : `${__("livestream_creating")}`}`}
                </LoadingButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BigLayout>
  )
}

export default CreateLiveStream
