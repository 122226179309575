//Library
import _ from 'lodash'
import {useCallback, useEffect, useMemo, useState} from 'react'
import Pagination from 'react-bootstrap/Pagination'
import dateandtime from 'date-and-time'

//Components
import SettingMentorSkeleton from 'entities/mentor/skeleton/setting.mentor.list.skeleton'
import CoursesIncomeTable from './courses.income.table'
import CourseIncomeFilterProduct from './components/course.filter.product'
import CourseIncomeFilterTime from './components/course.filter.time'
import CourseCardIncome from './components/course.card.income'

//Stores
import {useAppDispatch} from 'config/store'
import {getDataUserIncome, getTransactionCourse} from './courses.store.reducer'

//Interface
import {TypeDataProduct, TypeDataTime} from 'entities/mentor/mentor.interface'
import {TypeTransactionUserIncome} from './courses.interface'

//Image
import iconSearch from './../../media/icons/search.svg'
import iconClock from './media/icon_clock.svg'
import iconProduct from './media/icon_products.svg'

//Scss
import './media/courses.income.scss'
import __ from 'languages/index'
import BigLayout from "../../layouts/bigLayout";
import { formatString } from 'entities/support/utils'
import ComponentEmpty from 'components/componentEmpty'

export default function CoursesIncome() {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [textSearch, setTextSearch] = useState<string>('')
  // const [tabIndex, setTabIndex] = useState<string>("0");
  const [showModalFilterTime, setShowModalFilterTime] = useState<boolean>(false)
  const [showModalFilterProduct, setShowModalFilterProduct] = useState<boolean>(false)
  const [dataFilterProduct, setDataFilterProduct] = useState<TypeDataProduct[]>([])
  const [dataUserIncome, setDataUserIncome] = useState<Partial<TypeTransactionUserIncome>>({})
  const [dataTransaction, setDataTransaction] = useState({
    listData: [],
    totalData: 0
  })
  const [dataFilterTime, setDataFilterTime] = useState<TypeDataTime>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection'
  })
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: '',
    type_system: 'system',
    // type_system: Number(tabIndex) ? "mentor" : "system",
    from: '',
    to: '',
    ref_type: 'course',
    ref_id: '',
    method: 'plus'
  })

  const reduceRequestSearch = useMemo(() => {
    return _.debounce(_value => {
      setParams({ ...params, page: 1, search: _value })
    }, 500)
  }, [params])

  const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setTextSearch(value)
    reduceRequestSearch(value)
  }

  const handlePageChange = useCallback(
    (type: string) => {
      const page = type === 'next' ? params?.page + 1 : params?.page - 1
      setParams({ ...params, page: page })
    },
    [params]
    // [params, tabIndex]
  )

  const handleCloseModalFilterProduct = useCallback(() => {
    setShowModalFilterProduct(false)
  }, [])

  const handleSubmitFilterProduct = useCallback(
    (value: TypeDataProduct[]) => {
      let joinId = ''
      value.forEach(item => (joinId = `${joinId} ${joinId && ','} ${item?._id}`))
      setParams({ ...params, ref_type: joinId && 'course', ref_id: joinId?.trim() })
      setShowModalFilterProduct(false)
      setDataFilterProduct(value)
    },
    [dataFilterProduct, params]
  )

  const handleCloseModalFilterTime = useCallback(() => {
    setShowModalFilterTime(false)
  }, [])

  const handleConvertFilterTime = useCallback((start: any, end: any) => {
    if (!start && !end) return
    const startDate = new Date(
      `${dateandtime.format(new Date(start), 'YYYY-MM-DD')} 00:00:00`
    ).toISOString()
    const endDate = new Date(
      `${dateandtime.format(new Date(end), 'YYYY-MM-DD')} 23:59:59`
    ).toISOString()
    return { startDate, endDate }
  }, [])

  const handleSubmitFilterTime = useCallback(
    (value: TypeDataTime) => {
      const time = handleConvertFilterTime(value?.startDate, value?.endDate)
      setShowModalFilterTime(false)
      setParams({ ...params, from: time?.startDate, to: time?.endDate })
      setDataFilterTime(value)
    },
    [params]
    // [tabIndex, params]
  )

  const handleOpenModalFilter = useCallback((type: string) => {
    if (type === 'time') {
      setShowModalFilterTime(true)
    } else if (type === 'product') {
      setShowModalFilterProduct(true)
    }
  }, [])

  // const handleChangeTabs = useCallback(
  //   (key: string) => {
  //     setTabIndex(key);
  //     setTextSearch("");
  //     setDataFilterProduct([]);
  //     setDataFilterTime({
  //       startDate: undefined,
  //       endDate: undefined,
  //       key: "selection",
  //     });
  //     setParams({
  //       ...params,
  //       page: 1,
  //       type_system: Number(key) ? "mentor" : "system",
  //       search: "",
  //       from: "",
  //       to: "",
  //       ref_id: "",
  //       ref_type: "course",
  //     });
  //   },
  //   [params]
  // );

  const handleConvertTime = useCallback((value: any) => {
    return dateandtime.format(new Date(value), 'DD/MM/YYYY')
  }, [])

  const convertTextFilterProduct = useMemo(() => {
    let text = ''
    dataFilterProduct.forEach(item => (text = `${text} ${text && ','} ${item?.title}`))
    return text
  }, [dataFilterProduct])

  useEffect(() => {
    setIsLoading(true)
    dispatch(
      getDataUserIncome({
        type_ref: 'course',
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    )
      .unwrap()
      .then(res => {
        setDataUserIncome(res?.data)
        setIsLoading(false)
      })
      .catch(error => {
        setDataUserIncome({})
        setIsLoading(false)
        console.log(error)
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getTransactionCourse(params))
      .unwrap()
      .then(res => {
        setDataTransaction({
          listData: res?.data,
          totalData: parseInt(res.headers['x-total-count'] || 1000, 10)
        })
        setIsLoading(false)
      })
      .catch(error => {
        setDataTransaction({
          listData: [],
          totalData: 0
        })
        setIsLoading(false)
        console.log(error)
      })
  }, [params])

  return (
    <BigLayout>
      <div id="coursesIncome" className="coursesIncome__container">
        <div className="coursesIncome__first">
          <span className="coursesIncome__first--revenue">{__("course_revenue")}</span>
          <div className="coursesIncome__first--statistical">
            <CourseCardIncome dataUserIncome={dataUserIncome} />
          </div>
        </div>
        <div className="coursesIncome__second">
          <span className="coursesIncome__history">{__("course_transaction_history")}</span>
          {/* <div className="courseIncome__tabs">
            {dataTabs?.map((item: any, idx: number) => (
              <div key={`tab_${idx}`}>
                <span
                  className={`courseIncome__item ${Number(tabIndex) === idx && "activeTab"}`}
                  onClick={() => handleChangeTabs(`${idx}`)}
                >
                  {item?.tabName}
                </span>
              </div>
            ))}
          </div> */}
          <div className="courseIncome__filterWrapper">
            <div className="courseIncome__filter">
              <div
                className="courseIncome__filter--time"
                onClick={() => handleOpenModalFilter('time')}
              >
                <img src={iconClock} alt="icon-clock" />
                <span className="courseIncome__filter--title">
                  {dataFilterTime?.startDate
                    ? `${handleConvertTime(dataFilterTime?.startDate)} - ${handleConvertTime(
                        dataFilterTime?.endDate
                      )}`
                    : `${__("course_filter_by_time")}`}
                </span>
              </div>
              <div
                className="courseIncome__filter--product"
                onClick={() => handleOpenModalFilter('product')}
              >
                <img src={iconProduct} alt="icon-product" />
                <span className="courseIncome__filter--title">
                  {dataFilterProduct?.length ? convertTextFilterProduct : `${__("course_filter_by_product")}`}
                </span>
              </div>
            </div>
            <div className="coursesIncome__search">
              <img src={iconSearch} alt="icon" loading="lazy" />
              <input
                className="mentorIncome__search--input"
                onChange={handleChangeInputSearch}
                value={textSearch}
                placeholder={__("course_search")}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="bg-white p-4 rounded mt-4">
            <SettingMentorSkeleton />
          </div>
        ) : (
          <>
            {dataTransaction?.listData?.length ? (
              <div className="coursesIncome__third">
                <CoursesIncomeTable
                  data={dataTransaction?.listData}
                  index={'0'}
                  // index={tabIndex}
                />

                <div
                  className="coursesIncome__third--pagination"
                  // className={
                  //   Number(tabIndex) ? "coursesIncome__third--paginationMentor" : "coursesIncome__third--pagination"
                  // }
                >
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => handlePageChange('prev')}
                      disabled={params?.page === 1}
                    />
                    <Pagination.Item active>{params?.page}</Pagination.Item>
                    <Pagination.Next
                      onClick={() => handlePageChange('next')}
                      disabled={params?.page === (Math.ceil(dataTransaction?.totalData / 10) || 1)}
                    />
                  </Pagination>
                </div>
              </div>
            ) : (
              <ComponentEmpty text={formatString(__('data_empty'), `${__('course_revenue').toLowerCase()}`)} />
            )}
          </>
        )}

        {showModalFilterProduct && (
          <CourseIncomeFilterProduct
            data={dataFilterProduct}
            show={showModalFilterProduct}
            title={__("course_select_product")}
            onClose={handleCloseModalFilterProduct}
            onSubmit={handleSubmitFilterProduct}
          />
        )}

        {showModalFilterTime && (
          <CourseIncomeFilterTime
            data={dataFilterTime}
            show={showModalFilterTime}
            title={__("course_select_time")}
            onClose={handleCloseModalFilterTime}
            onSubmit={handleSubmitFilterTime}
          />
        )}
      </div>
    </BigLayout>
  )
}
