import { createRef } from 'react'

import __helpers from 'helpers/index'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'

import ToastComponent from 'entities/courses/components/toast.component'
import AboutPage from './about.page'

export default function About() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'about'
  let ActualPage: any

  switch (Param) {
    case 'about':
      ActualPage = AboutPage
      break
    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="mainFrame">
      <div className="container container-sm">
        {<ActualPage />}
      </div>
    </div>
  )
}
