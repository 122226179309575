//Library
import {useCallback, useMemo, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell, faLanguage, faLock, faUser} from '@fortawesome/free-solid-svg-icons'

//Components
import UserNotificationSetting from './components/user.setting.notification'
import UserAccountSetting from './components/user.setting.account'
import UserProfileSetting from './components/user.setting.profile'
import UseLanguageSetting from "./components/user.setting.language";

import __ from "languages/index";

//Scss
import './media/user.setting.scss'
import BigLayout from "../../layouts/bigLayout";
import { Box } from '@mui/material'

const UserSetting = () => {
  const [tabMenu, setTabMenu] = useState<number>(0)

  const handleChangeMenu = useCallback((index: number) => {
    setTabMenu(index)
  }, [])

  const ComponentView = useMemo(() => {
    let Component: React.ComponentType
    switch (tabMenu) {
      case 0:
        Component = UserProfileSetting
        break
      case 1:
        Component = UserAccountSetting
        break
      case 2:
        Component = UserNotificationSetting
        break
      // case 3:
      //   Component = UseLanguageSetting
      //   break
      default: 
        Component = UserProfileSetting
        break
    }
    return Component
  }, [tabMenu])

  return (
    <BigLayout>
      <div id="userSetting" className="userSetting_container">
        <div className="userSetting_left">
          <Box
            role='button'
            sx={{textTransform: 'none', color: theme=> tabMenu === 0 ? theme.palette.primary.main : 'unset'}}
            className={`userSetting_left_item`}
            onClick={() => handleChangeMenu(0)}
          >
            <FontAwesomeIcon icon={faUser} />
            <span className="userSetting_left_item_title">{__("user_profile")}</span>
          </Box>
          <Box
            role='button'
            className={`userSetting_left_item`}
            sx={{textTransform: 'none', color: theme=> tabMenu === 1 ? theme.palette.primary.main : 'unset'}}
            onClick={() => handleChangeMenu(1)}
          >
            <FontAwesomeIcon icon={faLock} />
            <span className="userSetting_left_item_title">{__("user_change_password")}</span>
          </Box>
          <Box
            role='button'
            className={`userSetting_left_item`}
            sx={{textTransform: 'none', color: theme=> tabMenu === 2 ? theme.palette.primary.main : 'unset'}}
            onClick={() => handleChangeMenu(2)}
          >
            <FontAwesomeIcon icon={faBell} />
            <span className="userSetting_left_item_title">{__("user_notification")}</span>
          </Box>
          {/*<div*/}
          {/*  className={`userSetting_left_item ${tabMenu === 3 && 'active'}`}*/}
          {/*  role="button"*/}
          {/*  onClick={() => handleChangeMenu(3)}*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={faLanguage} />*/}
          {/*  <span className="userSetting_left_item_title">{__("user_language")}</span>*/}
          {/*</div>*/}
        </div>
        <div className="userSetting_right">
          <ComponentView />
        </div>
      </div>
    </BigLayout>
  )
}

export default UserSetting
