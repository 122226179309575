import __ from 'languages/index'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className="bd-footer py-4 py-md-5 mt-5 bg-body-tertiary">
      <div className="container py-4 py-md-5 px-4 px-md-3 text-body-secondary">
        <div className="row">
          <div className="col-lg-3 mb-3">
            <a
              className="d-inline-flex align-items-center mb-2 text-body-emphasis text-decoration-none"
              href="/"
              aria-label="Gamifa"
            >
              <span className="fs-5">{__('footer_title')}</span>
            </a>
            <ul className="list-unstyled small">
              <li className="mb-2">{__('footer_sub_title')}</li>
              <li className="mb-2">
                {__('footer_code_licensed')}{' '}
                <a href="#" target="_blank" rel="license noopener">
                  {__('footer_code_MIT')}
                </a>
                {__('footer_code_docs')}{' '}
                <a href="#" target="_blank" rel="license noopener">
                  {__('footer_code_CC_BY_3')}
                </a>
                .
              </li>
              <li className="mb-2">{__('footer_code_currently')}</li>
            </ul>
          </div>
          <div className="col-6 col-lg-3 offset-lg-1 mb-4">
            <h5>Links</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="https://gamifa.com/">{__('home_page')}</a>
              </li>
              <li className="mb-2">
                <a href="https://gamifa.com/event">{__('event')}</a>
              </li>
              <li className="mb-2">
                <a href="https://gamifa.com/courses">{__('courses')}</a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-3 mb-3">
            <h5>{__('footer_code_meta')}</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to={'/about-us'} rel="noopener">
                  {__('footer_code_about_us')}
                </Link>
              </li>
              <li className="mb-2">
                <Link to={'/contact'} rel="noopener">
                  {__('footer_code_contact')}
                </Link>
              </li>
              <li className="mb-2">
                <Link to={'/tos'} rel="noopener">
                  {__('footer_code_rule')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-2 mb-3">
            <h5>{__('footer_code_connect')}</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="#" target="_blank" rel="noopener">
                  {__('footer_code_connect_facebook')}
                </a>
              </li>
              <li className="mb-2">
                <a href="#" target="_blank" rel="noopener">
                  {__('footer_code_connect_twitter')}
                </a>
              </li>
              <li className="mb-2">
                <a href="#" target="_blank" rel="noopener">
                  {__('footer_code_connect_youtube')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
