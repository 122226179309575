import { TypedPublicAlbum, TypedUser } from 'entities/calendar/calendar.interface'
import TypedMedia from '../../interfaces/media.interface'

export type ChallengePrivacy = 'public' | 'private'
export interface TypedChallengeItem {
  _id: string
  title: string
  user_id: TypedUser
  game_id: TypedGame
  description: string
  long_description: string
  avatar: TypedPublicAlbum
  media_id: TypedPublicAlbum
  start_time: string
  end_time: string
  country: string
  level_value: number
  coin_value: number
  gift_data: TypedChallengeGift[]
  member_count: number
  challenge_stage: TypedLeg[]
  channel_id: string
  public_status: string
  join_number: number
  is_join: boolean
}

export interface TypedCreateChallenge {
  _id?: string
  title: string
  game_id: string
  description?: string
  long_description: string
  avatar: string
  media_id?: string
  start_time: string
  end_time: string
  level_value: string
  coin_value: string
  language?: string
  country?: string
  version?: string
  public_status: string
  trash_status?: string
  gift_data?: string
  challenge_stage?: TypedLeg[] | string
  max_user?: string
  create_next_cycle?: boolean
  add_all_user?: boolean
}

export interface TypedLeg {
  _id?: string
  name: string
  start_time: string
  end_time: string
  description: string
  gift_data?: TypedChallengeGift[]
}

export interface TypedChallengeGift {
  _id?: string
  user_id?: TypedUser
  gift_type?: 'gift'
  name?: string
  media_id: TypedPublicAlbum
  description?: string
  price: number
  createdAt?: string
  updatedAt?: string
  stock_qty?: number
}

export interface TypedCondition {
  _id?: string
  type?: string
  value?: string
}

export interface TypedChallengeRanking {
  _id: string
  user_id: TypedUser
  total_point: number
  level: number
}

export interface TypedGame {
  _id: string
  game_type: 'system' | 'custom'
  media_id?: TypedPublicAlbum
  title: string
  custom_field: TypedCustomFiled[]
  description?: string
}

export interface TypedCustomFiled {
  _id?: string
  name: string
  field_type: string
}

export interface TypedAction {
  key: 'comment' | 'like_comment' | 'like_post' | 'watch_course' | 'post_new'
  value: number
  title: string
}

export interface TypedChallengeDetail {
  avatar: TypedPublicAlbum
  challenge_stage: TypedLeg[]
  channel_id: string
  coin_value: number
  createdAt: string
  description: string
  end_time: string
  game_id: TypedGame
  gift_data: TypedChallengeGift[]
  join_number: number
  language: string
  level_value: number
  long_description: string
  media_id: TypedPublicAlbum
  member_count: number
  public_status: 'public' | 'private'
  start_time: string
  title: string
  updatedAt: string
  user_id: TypedUser
  _id: string
  version: string
  is_join: boolean
  max_user: number
  challenge_permission: TypedChallengePermission
  add_all_user: boolean
  create_next_cycle: boolean
  challenge_status: string
}

export interface TypedChallengePermission {
  _id: string
  challenge_id: string
  official_status: number
}

export interface ChallengeUserInfo {
  _id: string
  display_name: string
  last_active: string
  user_active: number
  user_avatar: string
  user_avatar_thumbnail: string
  user_role: string
  createdAt: string
  total_point: string
  official_status: number
  activities: string[]
}

export type ChallengeUser = {
  challenge_id: unknown
  createdAt: string
  user_id: ChallengeUserInfo
  total_point: number
}

export type ChallengeListUserResponse = ChallengeUser[]

export interface TypedDataAction {
  meta_key: string // id custom filed
  meta_value: string
  meta_type: string
  meta_title: string
}

export interface TypedCreateAction {
  title?: string
  media_id?: string
  channel_id?: string
  challenge_id: string
  parent_id?: string
  start_time?: string
  data_activity: string
}

export type NotificationType = {
  challenge_id: string
  channel_id: string
  createdAt: string
  description: string
  public_album: TypedMedia[]
  title: string
  user_id: Pick<TypedUser, '_id' | 'display_name' | 'user_avatar'|'user_avatar_thumbnail'>
}

export type CreateNewNotificationRequest = {
  title: string
  description: string
  channel_id: string
  challenge_id: string
  public_album: string[]
}

export type CreateNewNotificationResponse = NotificationType

export type AttackmentActivityType = {
  _id: string
  meta_key: string
  meta_value: string
  meta_type: 'text' | 'image' | 'date'
  meta_title: string
}

// export type ActivityStatus = 'approved' | 'rejected' | 'pending'
export enum OfficialStatus {
  PENDING,
  APPROVED,
  REJECTED
}

export enum StatusMember {
  approved = 1,
  rejected = 2,
  pending = 0
}

export type User = {
  _id: string
  display_name: string
  user_avatar: string
  user_avatar_thumbnail?: string
}

export type ActivityType = {
  _id: string
  title: string
  description: string
  start_time: string
  data_activity: AttackmentActivityType[]
  official_status: number
  user_id: User
  point_value: number
  admin_note: string
  createdAt: string
}

export type CreateActivityPayload = {
  _id: string
  admin_note?: string
  official_status: '1' | '2'
  point_value?: string
}

export type UpdatePermissionPayload = {
  user_id: string
  challenge_id: string
  official_status: '0' | '1' | '2'
}

export type AddUserToChallengeResponse = Array<Partial<ChallengeUser>>

export type AddUserToChallengePayload = {
  challengeId: string
  userIds: string[]
  officialStatus?: StatusMember.approved
}

export type RemoveUserFromChallengeResponse = AddUserToChallengeResponse

export type RemoveUserFromChallengePayload = {
  challengeId: string
  userId: string
}

export type PaginationQueryParamsType = {
  search?: string
  page?: number
  limit?: number
  order_by?: 'DESC' | 'ASC'
}

export type InjectPaginationQueryParams<T> = T & { queryParams?: PaginationQueryParamsType }
export type PaginationResponse<T> = {
  data: T
  totalCount: number
}

export type GetJoinedListQueryParams = InjectPaginationQueryParams<{
  challenge_id: string
  official_status: number
}>

export type JoinChallengeResponse = Pick<
  TypedChallengeDetail,
  '_id' | 'user_id' | 'updatedAt' | 'createdAt'
> & {
  official_status: 0 | 1 | 2
  total_point: number
}
