import { SvgIcon, SvgIconProps } from '@mui/material'

const ShoppingBasketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.88723 2.10359C7.38242 2.34813 7.58562 2.94781 7.34107 3.443L4.72023 8.75022H19.2798L16.6589 3.443C16.4144 2.94781 16.6176 2.34813 17.1128 2.10359C17.608 1.85905 18.2076 2.06225 18.4522 2.55744L21.5104 8.75022H22C22.5523 8.75022 23 9.19794 23 9.75022C23 10.3025 22.5523 10.7502 22 10.7502H21.7107L20.005 19.3853C19.7063 20.8972 18.3902 22.0002 16.8449 22.0002H7.15512C5.6098 22.0002 4.29365 20.8972 3.99501 19.3853L2.28932 10.7502H2C1.44772 10.7502 1 10.3025 1 9.75022C1 9.19794 1.44772 8.75022 2 8.75022H2.48965L5.54781 2.55744C5.79236 2.06225 6.39203 1.85905 6.88723 2.10359ZM4.32796 10.7502L5.9571 18.9977C6.07394 19.5892 6.58185 20.0002 7.15512 20.0002H16.8449C17.4181 20.0002 17.9261 19.5892 18.0429 18.9977L19.672 10.7502H4.32796ZM8.77778 14.2502C8.77778 13.6979 9.22549 13.2502 9.77778 13.2502H14.2222C14.7745 13.2502 15.2222 13.6979 15.2222 14.2502C15.2222 14.8025 14.7745 15.2502 14.2222 15.2502H9.77778C9.22549 15.2502 8.77778 14.8025 8.77778 14.2502Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default ShoppingBasketIcon
