import React, {useCallback, useEffect, useRef, useState} from 'react'
import './media/css/index.scss'
import {faBackward} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from 'config/store'
import {createOrder, createOrderExtension, getListPaymentMethod} from 'entities/orders/order.store.reducer'
import PaymentPackage from 'entities/payment/components/PaymentPackage'
import PaymentMethod from 'entities/payment/components/PaymentMethod'
import PaymentTime from 'entities/payment/components/PaymentTime'
import {ListPlan} from 'entities/payment/payment.interface'
import {getListPlan} from './payment.store.reducer'
import TypedChannel from "../../interfaces/channel.interface";
import Title from "components/Title";
import {dataPaymentMethod} from "../helpers/payment.helpers";
import __ from "languages/index";
import _Helmet from 'components/helmet'
import BigLayout from "../../layouts/bigLayout";
import {getEntity} from "entities/extentions/extensions.store.reducer";

export default function DetailPayment() {

  const listPlan: ListPlan[] = useAppSelector(state => state.payment.plans)
  const channel: TypedChannel = useAppSelector(state => state.user.channel_data)
  const refIsExtensionOrder = useRef<boolean>(false)
  const sortedListPlan = [...listPlan]
    .filter(plan => plan.amount_of_day)
    .sort((plan1, plan2) => plan2.amount_of_day - plan1.amount_of_day)
  const listPaymentMethod = useAppSelector(state => state.order.listPaymentMethod)
  const [checked, setChecked] = useState('')
  const [selectPayment, setSelectPayment] = useState<number>(null)
  const [show, setShow] = useState(true)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation();
  const extensionId = location.search.replace('?service_id=', '')

  const onCheckout = useCallback(async (planId: string) => {
    try {
      const res: { payload?: { data?: { redirect_url?: string } } } = await dispatch(
        !refIsExtensionOrder.current ?
          createOrder({
            plan_id: planId,
            amount_of_package: '1',
            payment_method: dataPaymentMethod(selectPayment)
          })
          :
          createOrderExtension({
            plan_id: planId,
            amount_of_package: '1',
            payment_method: dataPaymentMethod(selectPayment),
            status: 'pending'
          })
      )

      const redirectUrl = res?.payload?.data?.redirect_url
      window.location.href = redirectUrl
      // navigate(redirectUrl)
    } catch (err) {
      console.log(err, 'err')
    }
    setShow(true)
  }, [selectPayment, checked])

  useEffect(() => {
    // const myCollapse = document.getElementById('collapseTarget')
    // const bsCollapse = new Collapse(myCollapse, { toggle: false })
    // toggle ? bsCollapse.show() : bsCollapse.hide()
    if (show) {
      setTimeout(() => {
        setShow(false)
      }, 10000);
    }
  }, [extensionId, show])

  useEffect(() => {
    if(extensionId){
      dispatch(getEntity({_id: extensionId.toString()}))
        .unwrap()
        .then((response) => {
          refIsExtensionOrder.current = response?.data?.service_type === "extension"
          setShow(false)
        })

      dispatch(getListPlan({service_id: extensionId.toString()}))
      dispatch(getListPaymentMethod({service_id: extensionId.toString()}))
    }else {
      navigate("/")
    }
  }, []);

  const handleSelectPayment = (param: number) => {
    setSelectPayment(param)
  }
  const handleCheckPackage = (planId: string) => {
    if (checked && (checked !== planId)) {
      setSelectPayment(null)
    } else {
      if (sortedListPlan[0]?._id !== planId) {
        setSelectPayment(null)
      }
    }
    if (planId) {
      setChecked(planId)
    }
  }
  return (
    <BigLayout>
      <_Helmet title='payment_title'/>
      <div className="d-flex  align-items-center justify-content-between">
        <Title text={__("payment_title")} className="mb-3"/>

        <button
          onClick={() => navigate(-1)}
          className="btn btn-outline-secondary d-flex align-items-center my-2"
        >
          <FontAwesomeIcon icon={faBackward} className="me-2"/>
          {__("payment_back")}
        </button>
      </div>

      <div className="d-flex flex-sm-wrap row mb-5">
        {/* ======================= Left screen payment ======================= */}
        <div className="col-12 col-xl-8">
          {/* ======================= Payment ======================= */}
          <PaymentPackage
            checked={checked}
            onHandle={handleCheckPackage}
            listPlan={sortedListPlan || []}
          />
          {/* ======================= Add Card ======================= */}
          <PaymentMethod
            listPaymentMethod={listPaymentMethod}
            selectPayment={selectPayment}
            listPlan={sortedListPlan}
            onHandelSelectPayment={handleSelectPayment}
            checked={checked}
          />
        </div>
        {/* ======================= Right screen payment ======================= */}
        <PaymentTime
          onCheckout={onCheckout}
          checked={checked}
          selectPayment={selectPayment}
          listPlan={sortedListPlan}
          show={show}
          channel={channel}
        />

        {/*<Modal show={show} onHide={handleClose}>*/}
        {/*  <Modal.Header closeButton>*/}
        {/*    <Modal.Title>Thanh toán thành công</Modal.Title>*/}
        {/*  </Modal.Header>*/}
        {/*  <Modal.Body>Bạn đã thanh toán thành công hãy tiếp tục sử dụng trang web!</Modal.Body>*/}
        {/*  <Modal.Footer>*/}
        {/*    <Button variant="primary" onClick={handleClose}>*/}
        {/*      Đóng*/}
        {/*    </Button>*/}
        {/*  </Modal.Footer>*/}
        {/*</Modal>*/}
      </div>
    </BigLayout>
  )
}
