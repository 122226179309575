import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { Badge, Card, Stack } from 'react-bootstrap'
import { useGetDomainPlanQuery, useGetSubscriptionsByDomainQuery } from '../store/domainApi'
import __helpers from 'helpers/index'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserId } from 'store/user.store.reducer'
import { Plan } from '../add-domain.type'
import { useAppSelector } from 'config/store'
import TypedChannel from 'interfaces/channel.interface'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import BigLayout from "../../../layouts/bigLayout";
import { Box, Button } from '@mui/material'

const IntroDomainService = () => {
  const userId = useSelector(getUserId)

  const { data: paymentPlans } = useGetDomainPlanQuery({ service_name: 'domain' })
  const { data: subscription } = useGetSubscriptionsByDomainQuery({ id: userId })
  const [isMonthlyPlan, setIsMonthlyPlan] = useState<boolean>(true)
  const [currentPlan, setCurrentPlan] = useState<Plan | null>(null)
  const serviceId = currentPlan?.service_id
  const channel_data: TypedChannel = useAppSelector(state => state.user.channel_data)

  const isPaid = Boolean(subscription)

  useEffect(() => {
    // if (!paymentPlans) return
    const selectPlan = () => {
      const monthlyPlan = paymentPlans?.find(plan => plan?.amount_of_day <= 30)
      const yearlyPlan = paymentPlans?.find(plan => plan?.amount_of_day > 30)

      setCurrentPlan(() => {
        return isMonthlyPlan ? monthlyPlan : yearlyPlan
      })
    }
    selectPlan()
  }, [isMonthlyPlan, paymentPlans])

  return (
    <BigLayout>
      <_Helmet title="domain_title" />
      <Container className={'pt-5'}>
        <Stack direction={'vertical'} gap={2} className={'align-items-center mb-3'}>
          <h5 className={'mb-2 text-center'}>{__('domain_sub_title')}</h5>
          <p className={'m-0 text-center'}>{__('domain_text')}</p>
        </Stack>

        <Box
          className="rounded-5 d-flex align-items-center justify-content-center p-1 mx-auto mb-2"
          sx={{
            border: theme=> `1px solid ${theme.palette.primary.main}`,
            maxWidth: '500px'
          }}
        >
          <Box
            role="button"
            className={`w-100 text-center rounded-5`}
            sx={isMonthlyPlan
              ? {
                  backgroundColor: theme=>theme.palette.primary.main,
                  color: '#fff'
                }
              : {}}
            onClick={() => setIsMonthlyPlan(true)}
          >
            {__('domain_plan_month')}
          </Box>
          <Box
            role="button"
            className={`w-100 text-center rounded-5`}
            onClick={() => setIsMonthlyPlan(false)}
            sx={!isMonthlyPlan
              ? {
                  backgroundColor: theme=>theme.palette.primary.main,
                  color: '#fff'
                }
              : {}}
          >
            {__('domain_plan_year')}
          </Box>
        </Box>

        <div className={'d-flex flex-column flex-lg-row gap-2'}>
          <Card className={'px-0 position-relative'}>
            <Card.Header>
              <Card.Title>{__('domain_name')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>{__('domain_introduction')}</Card.Text>
              <Card.Text className={'fs-4 fw-semibold mb-0'}>{__('domain_free')}</Card.Text>
              {/* <div>
              <p>
                <strong>Goi basic bao gom</strong>
              </p>
              <Stack as={'div'} direction={'horizontal'} gap={2}>
                <FontAwesomeIcon icon={faCheck} className={'text-success'} />
                <span>Bao cao co ban</span>
              </Stack>
            </div> */}
              <Button className={'w-100 mt-3'}>
                <Link to="/domain/create" className="text-white text-decoration-none">
                  {__('domain_start_trial')}
                </Link>
              </Button>
            </Card.Body>
          </Card>
          <Card className={'px-0 position-relative'}>
            <Box component={Badge} sx={{backgroundColor: theme=>`${theme.palette.primary.main} !important`}} className={'position-absolute end-0'}>{__('domain_popular')}</Box>
            <Card.Header>
              <Card.Title>{__('domain_name_private')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>{__('domain_name_private_sub_text')}</Card.Text>
              <Card.Text className={'fs-4 fw-semibold mb-0'}>
                {__helpers.formatNumberWithCommas(currentPlan?.price)} {__('currency_unit')} /{' '}
                {isMonthlyPlan ? `${__('domain_month')}` : `${__('domain_year')}`}
              </Card.Text>
              {/* <div>
              <p>
                <strong>Goi basic bao gom</strong>
              </p>
              <div className={'d-flex align-items-center justify-content-start'}>
                <FontAwesomeIcon icon={faCheck} className={'text-success'} />
                <span>Bao cao co ban</span>
              </div>
            </div> */}
              <Button className={'w-100 mt-3'}>
                {/* Todo: redirect to payment */}
                <Link
                  to={isPaid ? '/domain/create' : `/payment?service_id=${serviceId}`}
                  className="text-white text-decoration-none"
                >
                  {__('domain_btn_buy')}
                </Link>
              </Button>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </BigLayout>
  )
}

export default IntroDomainService
