import React, {memo, useEffect, useRef, useState} from 'react'
import '../media/livestream.admin.scss'
import Button from 'react-bootstrap/Button'
import {EnumLiveStreamSource} from 'entities/event/event.interface'
import helpers from 'helpers/index'
import {ConfirmModal} from 'components/modalConfirm'
import {EnumStatusLivestream} from 'entities/livestream/livestream.interface'
import {useAppSelector} from 'config/store'
import Spinner from 'react-bootstrap/Spinner'
import __ from 'languages/index'

interface TypedLivestreamAdminPreviewProps {
  startLivestream: () => void
  endLiveStream: () => void
  connectionStatus: RTCPeerConnectionState
  isVideoReady: boolean
  showModalAlreadyLive: boolean
  setIsPressEnd: (value: boolean) => void
  liveStreamId: string
}

const LivestreamPreviewButtonControl = ({
  startLivestream,
  endLiveStream,
  connectionStatus,
  isVideoReady,
  showModalAlreadyLive,
  setIsPressEnd,
  liveStreamId
}: TypedLivestreamAdminPreviewProps) => {
  const liveDetail = useAppSelector(state => state.livestream.entity)
  const [secondCount, setSecondCount] = useState(0)
  const refIntervalCount = useRef<NodeJS.Timeout>()
  const refConfirmModal = useRef<any>()

  useEffect(() => {
    if (
      ((connectionStatus === 'connected' &&
        liveDetail?.livestream_status === EnumStatusLivestream.Live) ||
        (liveDetail?.input_type === EnumLiveStreamSource.OutSide &&
          liveDetail?.livestream_status === EnumStatusLivestream.Live)) &&
      !refIntervalCount.current
    ) {
      refIntervalCount.current = setInterval(() => {
        setSecondCount(old => old + 1)
      }, 1000)
    }

    return () => {
      if (refIntervalCount.current) {
        clearInterval(refIntervalCount.current)
      }

      refIntervalCount.current = null
    }
  }, [liveDetail?.livestream_status, connectionStatus])

  const onPressStartLivestream = () => {
    refConfirmModal.current?.show({
      title: `${__("livestream_title")}`,
      description: `${__("livestream_btn_control_start_description")}`,
      confirmText: `${__("livestream_btn_control_start_confirmText")}`,
      onConfirm: startLivestream
    })
  }

  const onPressEndLivestream = () => {
    refConfirmModal.current?.show({
      title: `${__("livestream_title")}`,
      description: `${__("livestream_btn_control_press_end_description")}`,
      confirmText: `${__("livestream_btn_control_press_end_confirmText")}`,
      cancelText: `${__("livestream_btn_control_press_end_cancelText")}`,
      onCancel: () => {
        setIsPressEnd(true)
        endLiveStream()
        clearLiveStreamProduct()
      }
    })
  }

  const clearLiveStreamProduct = () => {
    localStorage.removeItem(liveStreamId)
  }

  return (
    <>
      {liveDetail?.input_type === EnumLiveStreamSource.OutSide ? (
        liveDetail?.livestream_status === EnumStatusLivestream.Live ? (
          <Button onClick={onPressEndLivestream} className="py-2 px-3">
            {`${__("livestream_btn_control_close")}` + helpers.secondsToHMS(secondCount)}
          </Button>
        ) : (
          <div className="livestream_status_box py-2 px-3 rounded-2">
            Trạng thái:{' '}
            {liveDetail?.livestream_status === EnumStatusLivestream.Wait
              ? `${__("livestream_btn_control_prepare_start")}`
              : `${__("livestream_btn_control_closed")}`}
          </div>
        )
      ) : (liveDetail?.input_type === EnumLiveStreamSource.Camera && !isVideoReady) ||
        showModalAlreadyLive ? (
        <div className="livestream_status_box py-2 px-3 rounded-2">{__("livestream_btn_control_cannot_play")}</div>
      ) : (
        <>
          {connectionStatus === 'connecting' && (
            <Button disabled className="py-2 px-3">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-3"
              />
              {__("livestream_btn_control_connect")}
            </Button>
          )}

          {connectionStatus === 'connected' &&
            liveDetail?.livestream_status === EnumStatusLivestream.Live && (
              <Button onClick={onPressEndLivestream} className="py-2 px-3">
                {`${__("livestream_btn_control_close")}` + helpers.secondsToHMS(secondCount)}
              </Button>
            )}

          {(liveDetail?.livestream_status === EnumStatusLivestream.Wait ||
            liveDetail?.livestream_status === EnumStatusLivestream.Live) &&
            connectionStatus !== 'connected' &&
            connectionStatus !== 'connecting' && (
              <Button onClick={onPressStartLivestream} className="py-2">
                {__("livestream_btn_control_start_confirmText")}
              </Button>
            )}
        </>
      )}
      <ConfirmModal ref={refConfirmModal} />
    </>
  )
}

export default memo(LivestreamPreviewButtonControl)
