import React from "react";
import {useParams} from "react-router-dom";
import Theme404 from "../../layout/404";
import UpgradeAccountDetail from "./upgrade.account.detail";

const UpgradeAccount = () =>{
    let useParam = {} as any;
    useParam = useParams();
    let Param = useParam.slug || "upgrade";
    let ActualPage: any;

    switch (Param) {
        case 'upgrade':
            ActualPage = UpgradeAccountDetail
            break

        default:
            ActualPage = Theme404
            break
    }
    return(
        <>
            {<ActualPage />}
        </>
    )

}
export  default UpgradeAccount;
