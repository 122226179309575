import React, {ChangeEvent, forwardRef, KeyboardEventHandler, useEffect, useState} from 'react'
import { Avatar, IconButton, InputAdornment, Stack, styled, TextField } from '@mui/material'
import { SendIcon } from 'icons'
import { useAddCommentMutation, useGetPostDetailQuery } from '../store/homeApi'
import { getFetchingParams } from 'entities/homeNew/store/homeSlice'
import { useSelector } from 'react-redux'
import {useAppDispatch, useAppSelector} from "config/store";

type Props = {
  className?: string
  postId?: string
  commentId?: string
  disabled?: boolean
}

const AddCommentForm = forwardRef(({ className, postId, commentId, disabled }: Props, ref) => {
  const fetchingParams = useSelector(getFetchingParams)

  const [commentContent, setCommentContent] = useState('')
  const user_data = useAppSelector(state => state.user.user_data)
  const [comment] = useAddCommentMutation()
  const dispatch = useAppDispatch()

  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setCommentContent(event.target.value)
  }

  const handleSendComment = () => {
    if (!commentContent) return
    try {
      comment({
        content: commentContent,
        postId,
        commentId,
        currentFetchParams: fetchingParams
      }).unwrap()
      setCommentContent('')
    } catch (e) {
      console.log(e)
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key.toLowerCase() === 'enter' && !event.shiftKey) {
      handleSendComment()
      event.preventDefault()
    }
  };

  return (
      <FormContainer className={className} direction="row" gap={1}>
        <Avatar sizes={"small"} src={user_data?.user_avatar || user_data?.user_avatar_thumbnail} />
        <TextField
          disabled={disabled}
          value={commentContent}
          onChange={handleChangeComment}
          inputRef={ref}
          size="small"
          hiddenLabel
          multiline
          minRows={1}
          maxRows={8}
          onKeyDown={handleKeyDown}
          inputProps={{
            maxLength: 3000
          }}
          InputProps={{
            autoFocus: true,
            disableUnderline: true,
            endAdornment: (
                <IconButton style={{alignSelf:"end"}} size="small" onClick={handleSendComment} disabled={!commentContent}>
                  <SendIcon color="disabled" />
                </IconButton>
            )
          }}
          sx={{
            root: {
              padding: theme => `${theme.spacing(1)} ${theme.spacing(2)}`
            }
          }}
        />
      </FormContainer>
  )
})

export default AddCommentForm

const FormContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .menu-icon': {
    transform: 'rotate(90deg)',
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.text.secondary
  },
}))
