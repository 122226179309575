import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from "react"
import {Button, Modal} from "react-bootstrap"
import helpers from "helpers/index"
import __ from "languages/index";


const DeleteConfirmModal = (props, ref) => {


  useImperativeHandle(ref, () => ({
    show: (title: string, cb) => showModal(title, cb)
  }))

  const callBack = useRef(null)

  // state
  const [show, setShow] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  // ==========================================

  // function
  const showModal = (title: string, cb) => {
    setShow(true)
    setTitle(title)
    callBack.current = cb
  }

  const closeOnly = useCallback(() => {
    setShow(false)
  }, [])

  const closeAndAgree = useCallback(async () => {
    setLoading(true);
    await helpers.sleep(1000);
    setShow(false)
    callBack.current?.()
    await helpers.sleep(500);
    setLoading(false);
  }, [])
  // ==========================================

  return (
    <Modal show={show} onHide={closeOnly}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{__("delete_confirm_text")}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeOnly}>{__("btn_close")}</Button>
        <Button variant="danger" disabled={loading} onClick={closeAndAgree}>{loading ? `${__("delete_confirm_btn_deleting")}` : `${__("delete_confirm_btn_delete")}`}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default forwardRef(DeleteConfirmModal)
