//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

//Config
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Interface
import { TypeCategory, TypeDataLevel } from '../interface'

//Helpers
import helpers from 'helpers'
import TypedChannel from "../../../interfaces/channel.interface";

interface TypeUpdateChannel {
  _id?: string
  avatar?: string
  name?: string
  description?: string
  public_status?: string
  need_approval?: string
  point_data?: string
  bank_account_name?: string
  bank_account_number?: string
  bank_brand_name?: string
  bank_name?: string
  bank_qr_code?: unknown | null
}

export const initialState = {}

export const invite = createAsyncThunk(
  'channel_setting/invite',
  async (body: { channel_id: string; email: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(`/channel/invite-email`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const uploadImage = createAsyncThunk(
  'channel_setting/upload_image',
  async (body: unknown, { rejectWithValue }) => {
    try {
      const response = await axios.post<unknown>(
        `${process.env.REACT_APP_MEDIA_UPLOAD_URL}?callback=${process.env.REACT_APP_AJAX_URL}/chat-media/create`,
        body,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updateChannel = createAsyncThunk(
  'channel_setting/update_data_channel',
  async (body: TypeUpdateChannel, { rejectWithValue }) => {
    try {
      const response = await axios.patch<TypedChannel>(
        `/channel/update`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getDataChannel = createAsyncThunk(
  'channel_setting/get_data_channel',
  async (_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get<TypedChannel>(`/channel/detail/${_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getDataChannelSetting = createAsyncThunk(
  'channel_setting/get_data_channel_setting',
  async (_params, { rejectWithValue }) => {
    try {
      const response = await axios.get<TypedChannel>(`/channel/channel-settings`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getDataCategory = createAsyncThunk(
  'channel_setting/get_data_category',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<TypeCategory[]>(`/request/list-category`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const getListLevel = createAsyncThunk(
  'channel_setting/get_list_level',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<TypeDataLevel[]>(`/channel/list-level`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createLevel = createAsyncThunk(
  'channel_setting/create_level',
  async (
    body: { channel_id: string; title: string; level_point: string; level_number: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<TypeDataLevel>(
        `/channel/create-level`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updateLevel = createAsyncThunk(
  'channel_setting/update_level',
  async (
    body: { _id: string; channel_id: string; title: string; level_point: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.patch<TypeDataLevel>(
        `/channel/update-level`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const deleteLevel = createAsyncThunk(
  'channel_setting/delete_level',
  async (_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete<unknown>(`/channel/delete-level/${_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updateCategory = createAsyncThunk(
  'channel_setting/update_data_category',
  async (
    body: {
      _id?: string
      public_status?: string
      category_title?: string
      channel_id?: string
      category_content?: string
      category_language?: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.patch<TypeCategory[]>(
        `/request/update-category`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const createCategory = createAsyncThunk(
  'channel_setting/create_data_category',
  async (
    body: {
      category_title?: string
      channel_id?: string
      category_content?: string
      category_language?: string
      public_status?: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post<TypeCategory[]>(
        `/request/create-category`,
        helpers.cleanEntity(body)
      )
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const CHANNEL_SETTING_REDUCER = createEntitySlice({
  name: 'channel_setting',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = CHANNEL_SETTING_REDUCER.actions

export default CHANNEL_SETTING_REDUCER.reducer
