import { TypedLeg } from 'entities/challenge/challenge.interface'
import React, { useCallback, useRef, useState } from 'react'
import LegComponent from './leg.component'
import __ from "languages/index";
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { PlusIcon } from 'icons';

interface Props {
  startDate: string
  finishedDate: string
  listLeg: TypedLeg[]
  addItemLeg: (item: TypedLeg) => void
}
const ChallengeLegComponent = (props: Props) => {
  const { startDate, finishedDate, listLeg, addItemLeg } = props

  // Refs
  const legRef = useRef(null)
  // ================================================

  // State
  const [validate, setValidate] = useState<boolean>(false)
  // ================================================

  // Function
  const _addLeg = useCallback(() => {
    if (!startDate && !finishedDate) {
      setValidate(true)
      return
    }
    setValidate(false)
    let openDate = ''
    let disableOpenDate = true
    let disableEndDate = false
    if (listLeg.length == 0) {
      openDate = startDate
      disableOpenDate = true
    } else {
      openDate = listLeg[listLeg.length - 1]?.end_time
    }
    legRef.current.show(openDate, finishedDate, disableOpenDate, disableEndDate)
  }, [listLeg, startDate, finishedDate])

  const _onAddItemLeg = useCallback((item: TypedLeg) => {
    addItemLeg(item)
  }, [])
  // ================================================

  return (
    <ChallengeLegContainer>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} >
        <Typography textTransform={'none'} variant='h6'>{__("challenge_add_detail_race_setting")}</Typography>

        <Button
          disabled={listLeg[listLeg.length - 1]?.end_time >= finishedDate}
          variant="outlined"
          onClick={_addLeg}
          className='btn_add_race'
        >
          <PlusIcon />
          <div>{__("challenge_leg_add_race")}</div>
        </Button>
      </Stack>
      {validate && (
        <div className="text-danger mt-1">
          {__("challenge_leg_text")}
        </div>
      )}
      <LegComponent ref={legRef} onAddLeg={_onAddItemLeg} />
    </ChallengeLegContainer>
  )
}

export default ChallengeLegComponent
const ChallengeLegContainer = styled(Box)(({ theme }) => ({
    '& .btn_add_race':{
      padding: theme.spacing(1,2),
      textTransform: 'none'
    }
}))
