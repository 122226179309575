import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useState} from 'react'
import '../media/gift.detail.modal.scss'
import Modal from 'react-bootstrap/Modal'
import helpers from 'helpers/index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  EnumGiftConditionType,
  GiftConditionTypeIcon,
  GiftConditionTypeTitle
} from 'entities/gift/constants/gift.constant'
import Parser from 'html-react-parser'
import {faArrowUp91, faMedal, faXmark} from '@fortawesome/free-solid-svg-icons'
import {TypedGift, TypedGiftTransaction} from 'entities/gift/gift.interface'
import {getReceiversForTab} from 'entities/gift/services/gift.receiver.service'
import {useInfiniteScroll} from '../../../hooks/useInfiniteScroll'
import GiftReceiverSkeleton from 'entities/gift/skeletons/gift.receiver.gift.modal.skeleton'
import {getIconFile, getMimeFile} from '../gift.update'
import {useAppSelector} from 'config/store'
import __ from "languages/index";
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";

const GiftDetailModal = forwardRef((_, ref) => {
  const [show, setShow] = useState(false)
  const [dataGift, setDataGift] = useState<TypedGift>()
  const permission_data = useAppSelector( state => state.user.permission_data );

  const { data, hasMore, totalItems, refresh, setParamRequest, loadMore, isRefreshing } =
    useInfiniteScroll<TypedGiftTransaction>(
      {
        limit: 20,
        order_type: 'time',
        order_by: 'DESC'
      },
      getReceiversForTab,
      false
    )

  const isDigitalGift = Boolean(dataGift?.gift_digital_media)

  useImperativeHandle(ref, () => ({
    show: (gift: TypedGift) => {
      setDataGift(gift)
      setShow(true)
      setParamRequest({
        limit: 20,
        gift_id: gift?._id,
        order_type: 'time',
        order_by: 'DESC'
      })
    }
  }))
  const renderUser = useCallback(({ user_id }, index) => {
    return (
      <div key={index.toString()} className="d-flex w-100 giftdetailmodal_container_user">
        <div className="giftdetailmodal_avatar">
          <img
            src={user_id?.user_avatar_thumbnail || user_id?.user_avatar}
            className="giftdetailmodal_avatar rounded-circle"
          />
        </div>

        <div className="ms-2 d-flex flex-column justify-content-evenly">
          <div className="giftdetailmodal_txtnameuser">{user_id?.display_name}</div>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faArrowUp91} className="giftdetailmodal_iconuser" />
            <div className="giftdetailmodal_txtinfouser" style={{ marginRight: 20 }}>
              {user_id?.point} {__("gift_condition_point")}
            </div>
            <FontAwesomeIcon icon={faMedal} className="giftdetailmodal_iconuser" />
            <div className="giftdetailmodal_txtinfouser">{__("gift_condition_level")} {user_id?.level_number}</div>
          </div>
        </div>
      </div>
    )
  }, [])


  if (!dataGift) {
    return null
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      className="giftmodal_detail"
      centered
    >
      <Modal.Body className="giftdetailmodal_body">
        <div className="w-100 h-100 d-flex justify-content-between flex-wrap position-relative giftdetailmodal_container">
          <button onClick={() => setShow(false)} className="giftdetailmodal_closebtn">
            <FontAwesomeIcon icon={faXmark} />
          </button>

          <div className="giftdetailmodal_30">
            <img src={dataGift?.media_id?.media_url} className="giftdetailmodal_image" />
          </div>

          <div className="giftdetailmodal_40 p-5 h-100">
            <div className="giftdetailmodal_title">{dataGift?.name}</div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="giftdetailmodal_price">
                {`${helpers.numberWithCommas(dataGift?.price)} ${__("currency_unit")}`}
              </div>
              <div className="giftdetailmodal_received">{`${__("gift_quantity_text")} ${dataGift?.stock_qty} ${__("gift_quantity")}`}</div>
            </div>

            <div className="w-100 giftdetailmodal_condition_content">
              <div className="giftdetailmodal_condition">{__("gift_condition")}</div>

              <div className="w-100 d-flex justify-content-between flex-wrap">
                {Object.keys(dataGift?.gift_conditions).map((key, index) =>
                  'start_time|end_time|_id'.includes(key) ? null : (
                    <div
                      key={index.toString()}
                      className="d-flex align-items-center mb-1 mx-1"
                      style={{ width: '45%' }}
                    >
                      <FontAwesomeIcon
                        icon={GiftConditionTypeIcon[key]}
                        className={`${
                          key !== EnumGiftConditionType.Birth ? 'me-2' : 'me-2'
                        } giftlistitem_icon`}
                      />
                      <div className="giftdetailmodal_condition_title">
                        {__(GiftConditionTypeTitle[key]) +
                          (key !== EnumGiftConditionType.Birth ? ': ' : '')}
                      </div>
                      <div className="giftdetailmodal_condition_value">
                        {key !== EnumGiftConditionType.Birth && dataGift?.gift_conditions[key]}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="giftdetailmodal_txtdes">{Parser(dataGift?.description)}</div>
            {helpers.current_user_can('boss', permission_data) && isDigitalGift && (
              <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <a
                  href={dataGift?.gift_digital_media?.media_url}
                  className="text-decoration-none d-flex align-items-center"
                  download
                >
                  {getIconFile(getMimeFile(dataGift?.gift_digital_media?.media_mime_type))}
                  <span
                    className={'ms-2 text-truncate d-inline-block'}
                    style={{
                      maxWidth: '200px'
                    }}
                  >
                    {dataGift?.gift_digital_media?.media_file_name}
                  </span>
                </a>
              </div>
            )}

            <br />
            <br />
          </div>

          <div className="giftdetailmodal_30r">
            <div className="giftdetailmodal_listuser_header">
              <div className="giftdetailmodal_title_received">{totalItems} {__("gift_member_received")}</div>
            </div>

            <div className="giftdetailmodal_listuser_container" id={"scrollableDiv"}>
              <InfiniteScrollContainer
                isRefreshing={isRefreshing}
                dataLength={data.length}
                next={loadMore}
                hasMore={hasMore}
                refreshFunction={refresh}
                scrollableTarget={"scrollableDiv"}
                skeleton={<GiftReceiverSkeleton />}
              >
                <div className="gap-3" id="course_wrap">
                  {data.map(renderUser)}
                </div>
              </InfiniteScrollContainer>


            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})

export default GiftDetailModal
