//Library
import { useParams } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import Lightbox, { Slide } from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

//Hooks
import { useInfinite } from 'hooks/useInfinite'

//helpers
import helpers from 'helpers'

//Icons
import iconBack from '../media/icons/iconBack.svg'
import imageDefault from 'media/images/image_default.png'
import iconDocument from '../media/icons/iconDocument.svg'
import iconLink from '../media/icons/iconLink.svg'

//Store
import { useAppDispatch } from 'config/store'
import { getListMediaChatRoom } from '../store/chat.store.reducer'
import { TypedMediaData } from '../chat.interface'

interface TypedParams {
  page: number
  limit: number
  media_type: string | 'image,video' | 'file' | 'link'
}

const MediaOfChatInformation = ({
  tabIndex,
  setTabIndex
}: {
  tabIndex: number
  setTabIndex: Function
}) => {

  console.log(tabIndex)
  const dispatch = useAppDispatch()
  const { id_chat } = useParams()

  const [isOpenLightBox, setIsOpenLightBox] = useState<boolean>(false)
  const [currentIndexLightBox, setCurrentIndexLightBox] = useState<number>(0)
  const [mediaLists, setMediaLists] = useState<TypedMediaData[]>([])
  const [totalMediaList, setTotalMediaList] = useState<number>(0)
  const [params, setParams] = useState<TypedParams>({
    page: 1,
    limit: 20,
    media_type: ''
  })

  const { ref } = useInfinite(mediaLists?.length < totalMediaList, () => {
    setParams({
      ...params,
      page: params.page + 1
    })
  })

  const convertMediaData = useMemo((): any => {
    if (helpers.isEmpty(mediaLists)) return
    return mediaLists?.map((item: TypedMediaData) => {
      if (item?.media_type === 'image') {
        return {
          type: 'image',
          src: item?.media_url
        }
      } else if (item?.media_type === 'video') {
        return {
          type: 'video' as const,
          autoPlay: true,
          sources: [
            {
              src: item?.media_url,
              type: 'video/mp4'
            }
          ]
        }
      } else {
        return {}
      }
    })
  }, [mediaLists])

  const openLightBox = useCallback((index: number) => {
    setIsOpenLightBox(true)
    setCurrentIndexLightBox(index)
  }, [])

  const _ComponentViewMedia = useCallback(
    (mediaData, index: number) => {
      switch (tabIndex) {
        case 1:
          return (
            <div
              ref={ref}
              role="button"
              className="chatInformation_media_image_item"
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault()
                openLightBox(index)
              }}
            >
              {mediaData?.type === 'image' && (
                <img
                  src={mediaData?.src || imageDefault}
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = imageDefault
                  }}
                  alt="image"
                />
              )}
              {mediaData?.type === 'video' && (
                <video role="button" width={'auto'} height={'auto'} preload={'auto'}>
                  <source src={mediaData?.sources[0]?.src} type="video/mp4" />
                </video>
              )}
            </div>
          )
        case 2:
          return (
            <Box
              ref={ref}
              sx={{
                display: 'grid',
                gridTemplateColumns: '52px 1fr',
                columnGap: '8px',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  background: theme => theme.palette.background.default,
                  borderRadius: '12px',
                  height: '100%',
                  display: 'grid',
                  placeItems: 'center'
                }}
              >
                <img src={iconDocument} alt="image" />
              </Box>
              <div>
                <Typography component="p" variant="body1" color={'text.primary'}>
                  File đính kèm.pdf
                </Typography>
                <Typography component="p" variant="body1" color={'text.secondary'}>
                  12MB
                </Typography>
              </div>
            </Box>
          )
        case 3:
          return (
            <Box
              ref={ref}
              sx={{
                display: 'grid',
                gridTemplateColumns: '52px 1fr',
                columnGap: '8px',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  background: theme => theme.palette.background.default,
                  borderRadius: '12px',
                  height: '100%',
                  display: 'grid',
                  placeItems: 'center'
                }}
              >
                <img src={iconLink} alt="image" />
              </Box>
              <div>
                <Typography component="p" variant="body1" color={'text.primary'}>
                  Liên kết
                </Typography>
                <Typography component="p" variant="body1" color={'text.secondary'}>
                  {mediaData?.chat_content || ''}
                </Typography>
              </div>
            </Box>
          )
        default:
          return null
      }
    },
    [tabIndex]
  )

  useEffect(() => {
    setMediaLists([])
    setTotalMediaList(0)
    switch (tabIndex) {
      case 1:
        setParams(params => ({ ...params, page: 1, media_type: 'image,video' }))
        break
      case 2:
        setParams(params => ({ ...params, page: 1, media_type: 'file' }))
        break
      case 3:
        setParams(params => ({ ...params, page: 1, media_type: 'link' }))
        break
      default:
        setParams(params => ({ ...params, page: 1, media_type: 'image,video' }))
        break
    }
  }, [tabIndex])

  useEffect(() => {
    if (!id_chat || !params?.media_type) return
    params['_id'] = id_chat
    dispatch(getListMediaChatRoom(params))
      .unwrap()
      .then((res: AxiosResponse) => {
        res?.data?.forEach((item: any) => {
          if (mediaLists.every((data: any) => data?._id !== item?._id)) {
            setMediaLists(mediaLists => [...mediaLists, item])
          }
        })
        setTotalMediaList(parseInt(res?.headers['x-total-count'] || 0, 10))
      })
      .catch((error: AxiosError) => {
        console.log(`getListMediaChatRoom_${error}`)
        setMediaLists([])
        setTotalMediaList(0)
      })
  }, [id_chat, params])

  return (
    <div className={`chatInformation_container_media ${tabIndex && 'show_media'}`}>
      <div className="chatInformation_header_media">
        <img
          role="button"
          onClick={() => setTabIndex(0)}
          src={iconBack}
          width={22}
          height={22}
          alt="icon_bell"
        />
        <Typography component={'h6'} variant="h6">
          File đa phương tiện
        </Typography>
      </div>
      <Box className="chatInformation_body_media">
        <Box className="chatInformation_tab_media">
          <Typography
            component={'p'}
            onClick={() => setTabIndex(1)}
            className={`chatInformation_tab_media_item ${tabIndex === 1 && 'chat_active_tab'}`}
          >
            Ảnh/Video
          </Typography>
          <Typography
            component={'p'}
            onClick={() => setTabIndex(2)}
            className={`chatInformation_tab_media_item ${tabIndex === 2 && 'chat_active_tab'}`}
          >
            Tài liệu
          </Typography>
          <Typography
            component={'p'}
            onClick={() => setTabIndex(3)}
            className={`chatInformation_tab_media_item ${tabIndex === 3 && 'chat_active_tab'}`}
          >
            Liên kết
          </Typography>
        </Box>
        <div
          className={`chatInformation_media_image ${tabIndex !== 1 && 'media_document_and_link'}`}
        >
          {tabIndex === 1 ? (
            <>
              {!helpers.isEmpty(convertMediaData) && (
                <>
                  {convertMediaData?.map((item: Slide, idx: number) => (
                    <React.Fragment key={`media_${idx}`}>
                      {_ComponentViewMedia(item, idx)}
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              {!helpers.isEmpty(mediaLists) && (
                <>
                  {mediaLists?.map((item, idx: number) => (
                    <React.Fragment key={`media_${idx}`}>
                      {_ComponentViewMedia(item, idx)}
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </Box>
      {isOpenLightBox && (
        <Lightbox
          index={currentIndexLightBox}
          slides={convertMediaData}
          open={true}
          close={() => setIsOpenLightBox(false)}
          plugins={[Video, Zoom, Counter]}
          carousel={{
            finite:true
          }}
        />
      )}
    </div>
  )
}

export default MediaOfChatInformation
