import { TypedChallengeRanking, TypedLeg } from 'entities/challenge/challenge.interface'
import React, { useCallback, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import OrderTable from 'entities/orders/components/OrderTable'
import { useInfinite } from 'hooks/useInfinite'
import { getListPermission } from 'entities/challenge/services'
import SettingMentorSkeleton from 'entities/mentor/skeleton/setting.mentor.list.skeleton'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import Dropdown from 'components/Dropdown'
import IconButton from 'components/IconButton'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import NavigateUser from '../NavigateUser'
import __ from "languages/index";
import { Box, Button, Stack, Typography, styled } from '@mui/material'
import CoursesEmpty from 'entities/courses/components/courses.empty'
import RankTopIcon from 'icons/RankTopIcon'
import RankTopIcon2 from 'icons/RankTopIcon2'
import { useNavigate } from 'react-router-dom'

const limit = 10
interface Props {
  challenge_id: string
  challenge_stage: TypedLeg[]
}

const ChallengeRanking = (props: Props) => {
  const { challenge_id, challenge_stage } = props

  // State
  const [data, setData] = useState<TypedChallengeRanking[]>([])
  const [page, setPage] = useState<number>(1)
  const [chooseStage, setChooseStage] = useState<TypedLeg>(null)
  const [textStage, setTextStage] = useState<string>('Xếp hạng của Challenge')
  const [loading, setLoading] = useState<boolean>(true)
  
  // =================================================
  const navigate = useNavigate();
  // useEffect
  useEffect(() => {
    getData()
  }, [page, chooseStage])
  // =================================================

  // Function

  const getData = async () => {
    const res: any = await getListPermission({
      params: {
        challenge_id,
        challenge_stage_id: (chooseStage?._id !== '001ABC' && chooseStage?._id) || undefined,
        page,
        limit
      },
      headers: {
        ['X-Authorization']: localStorage.getItem('session')
      }
    })
    if (res?.data) {
      setData(prev => [...prev, ...res?.data])
      setLoading(false)
    }
  }

  const { ref } = useInfinite(data.length / limit <= page, () => {
    setPage(prev => prev + 1)
  })

  const _onChooseStage = useCallback((item: TypedLeg) => {
    setChooseStage(item)
    setTextStage(item?._id !== '001ABC' ? `${__("challenge_detail_race")} ${item?.name}` : item?.name)
  }, [])

  // =================================================

  // render view

  const renderHeaderRankTable = () => {
    return (
      <tr>
        <th>{__("challenge_rank")}</th>
        <th>{__("challenge_rank_name")}</th>
        <th>{__("challenge_rank_level")}</th>
        <th>{__("challenge_rank_point")}</th>
      </tr>
    )
  }


  const renderBodyRankTable = (item: TypedChallengeRanking, index: number) => {
    return (
      <Box

        sx={{
          background: theme => !index ? theme.palette.primary.main : index === 1 ? theme.palette.primary.backgroundHover : '#fff',
          border: theme=> index > 0 && index < 3 ? `1px solid ${theme.palette.primary.main}` : 'none',
          color: index === 0 ? '#fff' : '#000'
        }}

        className="item_rank" key={item?._id} ref={ref}>
        {
          index === 0 && (
            <Box className="rank_number" >
              <RankTopIcon />
              <Typography color={'black'}>{index + 1}</Typography>
            </Box>
          )
        }
        {
          index > 0 && index < 3 && (
            <Box className="rank_number">
              <RankTopIcon2 />
              <Typography>{index + 1}</Typography>
            </Box>
          )
        }
        {
          index > 2 && (
            <Box sx={{
              textAlign: 'center'
            }}>
              <Typography>{index + 1}</Typography>
            </Box>
          )
        }
        <td>
          <NavigateUser _id={item?.user_id?._id}>
            <div className="d-flex align-items-center gap-2">
              <Image
                className="image-avatar-challenge-rank"
                src={item?.user_id?.user_avatar_thumbnail || item?.user_id?.user_avatar}
              />
            </div>
          </NavigateUser>
        </td>
        <div className='info'>{item?.user_id?.display_name}
          <Typography>
            Cấp độ: {item?.user_id?.level_number}
          </Typography>
        </div>
        <Box>

          <Typography sx={{
            padding: 'var(--spacing-1, 2px) 8px',
            color: 'primary.main',
            background: index < 3 ? '#fff' : '#F3F4F5',
            borderRadius: '12px',
            textAlign: 'center'
          }} >{item?.total_point}</Typography>
        </Box>

      </Box>
    )
  }

  // =================================================
  if (loading) {
    return <SettingMentorSkeleton />
  }

  return (
    <ChallengeRankingContainer className="w-100">
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='subtitle2'>
          {__('challenge_detail_rating')}
        </Typography>
        {
          data?.length ? (
            <Button sx={{
              padding: 0,
              textTransform: 'none'
            }} variant='text'
            onClick={() => navigate(`/challenge/ranking/${challenge_id}`)}
            >
              {__('home_page_btn_seen_all')}
            </Button>
          ) : null
        }
      </Stack>
      <Box mt={2}>
        {
          data?.length <= 0 ? (
            <CoursesEmpty />

          ) : (
            data.slice(0, 5).map((item, index) => renderBodyRankTable(item, index))
          )
        }
      </Box>
    </ChallengeRankingContainer>
  )
}

export default ChallengeRanking

const ChallengeRankingContainer = styled(Box)(({ theme }) => ({
  '& .item_rank': {
    display: 'grid',
    gridTemplateColumns: '30px 40px 1fr 50px',
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    '& div, td': {
      margin: 'auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '100%',
    },



    '& .rank_number': {
      position: 'relative',

      'p': {
        position: 'absolute',
        top: '3px',
        left: '9px',

        '&:fisrt-of-type': {
          top: '6px'
        }
      }
    }
  }
}))
