import { Box, SxProps } from '@mui/material'
import TypedMedia from '../../../../interfaces/media.interface'
import DocumentFileView from 'entities/homeNew/components/DisplayPostContent/DocumentFileView'
import OverviewPostImages from 'entities/homeNew/components/DisplayPostContent/OverviewPostImages'
import { CurrentTheme } from '../../../../styles/themes'

type Props = {
  attachFiles: TypedMedia[]
  listImageStyle?: any
}

const DisplayAttachFiles = ({ attachFiles, listImageStyle }: Props) => {
  const isFile = attachFiles[0]?.media_type === 'file'

  const renderAttachFiles = () => {
    if (isFile) {
      return <DocumentFileView files={attachFiles} />
    }
    return <OverviewPostImages medias={attachFiles} sxImageList={listImageStyle} />
  }

  return <Box sx={displayAttachFilesStyled}>{renderAttachFiles()}</Box>
}

export default DisplayAttachFiles

const displayAttachFilesStyled: SxProps<CurrentTheme> = {
  px: theme => theme.spacing(3),
  marginTop: theme => theme.spacing(2)
}
