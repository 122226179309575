import React from "react";
import {useParams} from "react-router-dom";
import Theme404 from "../../layout/404";
import MobileAppDetail from "./mobile.app.detail";
import MobileAppRegisterForm from "./mobile.app.register";

const MobileApp = () =>{
    let useParam = {} as any;
    useParam = useParams();
    let Param = useParam.slug || "mobile";
    let ActualPage: any;

    switch (Param) {
        case 'mobile':
            ActualPage = MobileAppDetail
            break
        case 'register-form':
            ActualPage = MobileAppRegisterForm
            break
        default:
            ActualPage = Theme404
            break
    }
    return(
        <>
            {<ActualPage />}
        </>
    )

}
export  default MobileApp;
