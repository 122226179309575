import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {EnumLiveStreamSource, ErrorMediaType, TypedDeviceInfo} from "entities/event/event.interface";
import Modal from "react-bootstrap/Modal";
import {Tab, Tabs} from "react-bootstrap";
import LivestreamPreviewDeviceCamera from "entities/livestream/components/livestream.preview.device.camera";
import LivestreamPreviewDeviceFile from "entities/livestream/components/livestream.preview.device.file";
import LivestreamPreviewDeviceScreen from "entities/livestream/components/livestream.preview.device.screen";
import {useAppDispatch, useAppSelector} from "config/store";
import {
    setAudioSourceError,
    setAudioSourceList,
    setIdAudioDeviceSelectedForLivestream,
    setIsAlreadyPlayLiveFromFile,
    updateEntity
} from "entities/livestream/livestream.store.reducer";
import {EnumTypeInputSound} from "entities/livestream/hooks/livestream.admin.hook";
import LivestreamPreviewDeviceOutside from "entities/livestream/components/livestream.preview.device.outside";
import {EnumStatusLivestream} from "entities/livestream/livestream.interface";
import Button from "react-bootstrap/Button";
import {ID_VIDEO_ADMIN_STUDIO} from "entities/livestream/livestream.admin.constant";
import __ from "languages/index";

interface TypedLivestreamAdminDeviceModalProps {
  setAudioTrack?: (audioTrack: MediaStreamTrack, type: EnumTypeInputSound) => void
  setIsVideoReady: (value: boolean) => void
  setIsMicroReady: (value: boolean) => void
  isAudioReady: boolean
}

const LivestreamAdminDeviceModal = forwardRef(({
                                                 setAudioTrack,
                                                 setIsVideoReady,
                                                 setIsMicroReady,
                                                 isAudioReady
                                               }: TypedLivestreamAdminDeviceModalProps, ref) => {
    const liveDetail = useAppSelector(state => state.livestream.entity);
    const [show, setShow] = useState(false);
    const dispatch = useAppDispatch();
    const [currentTab, setCurrentTab] = useState(liveDetail?.input_type || EnumLiveStreamSource.Camera)
    const idAudioDeviceSelectedForLivestream = useAppSelector(state => state.livestream.idAudioDeviceSelectedForLivestream);
    const refAudioSourceSelected = useRef<any>(idAudioDeviceSelectedForLivestream);

    useImperativeHandle(ref, () => ({
      show: () => {
        setShow(true)
      }
    }));

    useEffect(() => {
      navigator?.mediaDevices?.addEventListener("devicechange", onDeviceChange);
      return (() => {
        navigator?.mediaDevices.removeEventListener("devicechange", onDeviceChange)
      })
    }, [liveDetail?.input_type]);

    useEffect(() => {
      if (liveDetail?.input_type) {
        setCurrentTab(liveDetail?.input_type || EnumLiveStreamSource.Camera)
      }
    }, [liveDetail?.input_type]);

    useEffect(() => {
      getDeviceMedia();
    }, []);

    useEffect(() => {
      refAudioSourceSelected.current = idAudioDeviceSelectedForLivestream;
      setupSourceDevice()
    }, [idAudioDeviceSelectedForLivestream]);

    const setupSourceDevice = useCallback(() => {
      navigator?.mediaDevices?.getUserMedia({
        audio: {deviceId: refAudioSourceSelected.current}
      }).then(gotAudioStream).catch(handleAudioError);
    }, []);

    const handleAudioError = (error) => {
      setIsMicroReady(false)
      dispatch(setAudioSourceError(error?.toString().toLowerCase().includes("permission") ? ErrorMediaType.Permission : ErrorMediaType.NotFound))
    }

    const gotAudioStream = (stream: MediaStream) => {
      if (stream.getAudioTracks().length > 0) {
        setIsMicroReady(true)
        setAudioTrack(stream.getAudioTracks()?.[0], EnumTypeInputSound.Mic);
      }
    }

    const onDeviceChange = useCallback(async () => {
      await getDeviceMedia();
      setupSourceDevice()
    }, [])

    const getDeviceMedia = useCallback(async () => {
      try {
        let devicesInformation = await navigator?.mediaDevices?.enumerateDevices();

        if (!devicesInformation) return;

        let audioDriver: TypedDeviceInfo[] = [];

        for (let i = 0; i !== devicesInformation.length; ++i) {
          const deviceInfo: TypedDeviceInfo = devicesInformation[i];
          if (deviceInfo.kind === 'audioinput') {
            audioDriver.push(deviceInfo);
          }
        }

        if (audioDriver.length > 0) {
          if (!refAudioSourceSelected.current || !(audioDriver.map(item => item.deviceId).includes(refAudioSourceSelected.current))) {
            refAudioSourceSelected.current = audioDriver[0]?.deviceId
            dispatch(setIdAudioDeviceSelectedForLivestream(audioDriver[0]?.deviceId))
          }

          dispatch(setAudioSourceList(audioDriver))
        }

      } catch (e) {
        console.log('Error in gotDevice: ' + e);
      } finally {
        console.log(liveDetail?.input_type, "sourceStream")
      }

      return;
    }, [liveDetail]);

    const onSelectTab = useCallback((event: EnumLiveStreamSource) => {
      if ((event !== EnumLiveStreamSource.File && event !== EnumLiveStreamSource.OutSide) || (liveDetail?.media_id && event === EnumLiveStreamSource.File)) {
        dispatch(updateEntity({_id: liveDetail?._id, input_type: event}))
      }

      if (event === EnumLiveStreamSource.Camera) {
        dispatch(setIsAlreadyPlayLiveFromFile(false))
      }

      setCurrentTab(event)
    }, [liveDetail])

    const onHide = useCallback(() => {
      const videoElement = document.getElementById(ID_VIDEO_ADMIN_STUDIO) as any;
      if (videoElement) {
        videoElement.muted = false;
      }

      if(!isAudioReady){
        let audioElement: HTMLAudioElement = document.getElementById("audio_for_create_empty_track") as HTMLAudioElement;
        audioElement.play()
      }

      setShow(false)
    }, [isAudioReady])

    return (
      <Modal show={show} onHide={onHide}
             size={"lg"}
             aria-labelledby="contained-modal-title-vcenter"
             className={"livestreamAdminDeviceModal"}
             centered>
        <Modal.Body className={"livepreview_device_modal_body"}>
          <div className="livepreview_device_modal">
            {
              liveDetail?.input_type === EnumLiveStreamSource.OutSide ?
                <Tabs
                  onSelect={onSelectTab}
                  defaultActiveKey={EnumLiveStreamSource.OutSide}
                  id="justify-tab-example"
                  justify
                >
                  <Tab eventKey={EnumLiveStreamSource.OutSide} title={__("livestream_admin_play_from_software")}>
                    <LivestreamPreviewDeviceOutside/>
                  </Tab>
                </Tabs>
                :
                <Tabs
                  onSelect={onSelectTab}
                  defaultActiveKey={liveDetail?.input_type || EnumLiveStreamSource.Camera}
                  id="justify-tab-example"
                  justify
                >
                  <Tab eventKey={EnumLiveStreamSource.Camera} title={__("livestream_admin_play_from_camera")}>
                    <LivestreamPreviewDeviceCamera
                      setIsVideoReady={setIsVideoReady}/>
                  </Tab>
                  <Tab eventKey={EnumLiveStreamSource.File} title={__("livestream_admin_play_from_file")}>
                    <LivestreamPreviewDeviceFile setAudioTrack={setAudioTrack}/>
                  </Tab>
                  <Tab eventKey={EnumLiveStreamSource.Screen} title={__("livestream_admin_play_from_screen")}>
                    <LivestreamPreviewDeviceScreen currentSourceStream={currentTab} setAudioTrack={setAudioTrack}/>
                  </Tab>
                  {
                    liveDetail?.livestream_status !== EnumStatusLivestream.Live &&
                    <Tab eventKey={EnumLiveStreamSource.OutSide} title={__("livestream_admin_play_from_software")}>
                      <LivestreamPreviewDeviceOutside/>
                    </Tab>
                  }

                </Tabs>
            }

          </div>
        </Modal.Body>
        <Modal.Footer className="livepreview_modal_body_bg">
          <Button onClick={onHide}>
            {__("btn_close")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
)

export default LivestreamAdminDeviceModal
