import '../media/courses.list.item.scss'
import {CSSProperties, useState} from "react";

interface TypedCourseProgressProps {
  maxValue?: number
  currentValue?: number
  percentValue?: number
  styleBackground?: CSSProperties
  styleProgress?: CSSProperties
  isRealtime?: boolean
}

export default function CoursesProgress({
                                         maxValue = 100,
                                         currentValue = 50,
                                         styleBackground,
                                         percentValue,
                                         styleProgress,
                                         isRealtime
                                       }: TypedCourseProgressProps) {
  const [progressWidth] = useState(percentValue ? percentValue : (currentValue / maxValue) * 100);

  return (
    <div className="progress_bar_container" style={styleBackground}>
      <div className="progress_bar" style={{width: `${isRealtime ? percentValue : progressWidth}%`, ...styleProgress}}/>
    </div>

  )
}
