import { SvgIcon, SvgIconProps } from '@mui/material'

const UnionIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} >
      <svg width="21" height="22" viewBox="0 0 21 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.1616 22C13.9308 22 12.1223 20.1915 12.1223 17.9606C12.1223 17.3894 12.2408 16.8458 12.4547 16.3532L8.90881 14.1564C8.05549 15.0858 6.83035 15.6684 5.46914 15.6684C2.89086 15.6684 0.800781 13.5783 0.800781 11C0.800781 8.42172 2.89086 6.33164 5.46914 6.33164C6.83035 6.33164 8.05547 6.91421 8.9088 7.84363L12.4547 5.64678C12.2408 5.15419 12.1223 4.61064 12.1223 4.03939C12.1223 1.80851 13.9308 0 16.1616 0C18.3925 0 20.201 1.80851 20.201 4.03939C20.201 6.27027 18.3925 8.07878 16.1616 8.07878C15.1504 8.07878 14.2259 7.70717 13.5173 7.09305L9.8445 9.36852C10.0339 9.87638 10.1375 10.4261 10.1375 11C10.1375 11.5739 10.0339 12.1236 9.84451 12.6315L13.5174 14.9069C14.2259 14.2928 15.1504 13.9212 16.1616 13.9212C18.3925 13.9212 20.201 15.7297 20.201 17.9606C20.201 20.1915 18.3925 22 16.1616 22ZM13.9113 17.9606C13.9113 19.2034 14.9188 20.2109 16.1616 20.2109C17.4044 20.2109 18.412 19.2034 18.412 17.9606C18.412 16.7178 17.4044 15.7103 16.1616 15.7103C14.9188 15.7103 13.9113 16.7178 13.9113 17.9606ZM5.46914 13.8793C3.87894 13.8793 2.58985 12.5902 2.58985 11C2.58985 9.4098 3.87894 8.12071 5.46914 8.12071C7.05934 8.12071 8.34843 9.4098 8.34843 11C8.34843 12.5902 7.05934 13.8793 5.46914 13.8793ZM16.1616 6.28971C14.9188 6.28971 13.9113 5.28219 13.9113 4.03939C13.9113 2.79659 14.9188 1.78907 16.1616 1.78907C17.4044 1.78907 18.412 2.79659 18.412 4.03939C18.412 5.28219 17.4044 6.28971 16.1616 6.28971Z" fill="currentColor" />
      </svg>

    </SvgIcon>
  )
}
export default UnionIcon
