//Library
import {memo, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";

//Components
import DeleteConfirm from "components/deleteConfirm";

//Stores
import {deleteAccountBank, MENTOR_REDUCER} from "entities/mentor/store/mentor.store.reducer";
import {useAppDispatch} from "config/store";

//Interface
import {TypeAccountBank} from "../payment.interface";
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

const DetailPaymentAccountBank = ({
  dataBank,
  setIsTriggerFetch,
}: {
  dataBank: TypeAccountBank;
  setIsTriggerFetch: Function;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast()

  const [isShowModalConfirm, setIsShowModalConfirm] = useState<boolean>(false);

  const handleSubmitDeleteBank = useCallback(
    (result: boolean) => {
      setIsShowModalConfirm(false);
      if (result) {
        dispatch(deleteAccountBank(dataBank?._id))
          .unwrap()
          .then((res) => {
            setIsTriggerFetch(true);
            dispatch(
              MENTOR_REDUCER.actions.setActionsCheck({
                type: `${__("payment_remove_payment_method")}`,
                isSuccess: true,
                statusCode: res?.status,
              })
            );
          })
          .catch((error) => {
            console.log(error);
            toast.show({
              title: `${__("notification")}`,
              content: `${__("payment_remove_payment_method_failed")}`,
              type: EnumTypeToast.Error,
            });
          });
      }
    },
    [dataBank]
  );

  const handleNavigatePage = useCallback(() => {
    navigate(`/info-payment/card`, { replace: true, state: dataBank });
  }, [dataBank]);

  const handleShowModalDelete = useCallback(() => {
    setIsShowModalConfirm(!isShowModalConfirm);
  }, []);

  return (
    <>
      <div className="p-4 bg-white ">
        <div className="p-4 d-flex flex-row">
          <div
            className="bg-secondary d-flex justify-content-center rounded"
            style={{ width: "120px", height: "80px" }}
          >
            <i className="bi bi-bank" style={{ fontSize: "40px", color: "white" }}></i>
          </div>
          <div className="ms-4">
            <p className="my-0 fs-5">{`${__('payment_transfer_to_account')} ${dataBank?.bank_number || ""}`}</p>
            <p className="mt-0" style={{ fontSize: "14px" }}>
              {dataBank?.bank_account_name || `${__("anonymous_user")}`}
            </p>
          </div>
        </div>
        <button className="btn btn-primary px-4" onClick={handleNavigatePage}>
          {__("payment_btn_edit")}
        </button>
        <button className="btn btn-danger ms-3 px-4" onClick={handleShowModalDelete}>
          {__("payment_btn_delete")}
        </button>
      </div>

      {isShowModalConfirm && (
        <DeleteConfirm
          show={isShowModalConfirm}
          onClose={handleSubmitDeleteBank}
          title={__("payment_confirm_method")}
        />
      )}
    </>
  );
};

export default memo(DetailPaymentAccountBank);
