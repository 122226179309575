//Library
import React, {memo, useMemo, useState} from 'react'
import {faCalendar, faClock} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

//Image
import iconFacebook from '../media/icons/icon_facebook.svg'
import iconInstagram from '../media/icons/icon_instagram.svg'
import iconLinkedin from '../media/icons/icon_linkedin.svg'
import iconTwitter from '../media/icons/icon_twitter.svg'
import iconYoutube from '../media/icons/icon_youtube.svg'
import iconLink from '../media/icons/icon_link.svg'

//Interface
import {TypeDataFollowCount, TypeDataUser, TypeSocialLink} from '../interface'

//Helpers
import helpers from 'helpers'
import __ from "languages/index";
import {useAppSelector} from "config/store";

const CardUserInfo = ({
  dataUser,
}: {
  dataUser?: TypeDataUser
}) => {
  const [isTruncatedBio, setIsTruncatedBio] = useState<boolean>(true)
  const [isTruncatedDesc, setIsTruncatedDesc] = useState<boolean>(true)
  const permission_data = useAppSelector(state => state.user?.permission_data);
  const userLoginId = useAppSelector(state => state.user?.user_data?._id)

  const truncatedTextBio = useMemo(() => {
    return isTruncatedBio && dataUser?.bio?.length > 100
      ? helpers.truncatedContentString(dataUser?.bio, 20)
      : dataUser?.bio
  }, [isTruncatedBio, dataUser])

  const truncatedText = useMemo(() => {
    return isTruncatedDesc && dataUser?.description?.length > 100
      ? helpers.truncatedContentString(dataUser?.description, 20)
      : dataUser?.description
  }, [isTruncatedDesc, dataUser])

  return (
    <div className="userDetail_body_left_wrapper">
      <div className="userDetail_body_left_first">
        <div className="userDetail_body_left_item">
          <span className="userDetail_body_left_item_title">{__("user_setting_biography")}</span>
          <span className="userDetail_body_left_item_content">
            {dataUser?.bio ? truncatedTextBio : `${__("user_setting_biography_sub_text")}`}
          </span>
          {dataUser?.bio?.length > 100 && (
            <span
              role="button"
              className="userDetail_body_left_item_content_truncated"
              onClick={() => setIsTruncatedBio(!isTruncatedBio)}
            >
              {isTruncatedBio ? `${__("user_setting_info_see_more")}` : `${__("user_setting_info_collapse")}`}
            </span>
          )}
        </div>
        <div className="userDetail_body_left_item">
          <span className="userDetail_body_left_item_title">{__("user_setting_introduce")}</span>
          <span className="userDetail_body_left_item_content">
            {dataUser?.description ? truncatedText : `${__("user_setting_biography_sub_text")}`}
          </span>
          {dataUser?.description?.length > 100 && (
            <span
              role="button"
              className="userDetail_body_left_item_content_truncated"
              onClick={() => setIsTruncatedDesc(!isTruncatedDesc)}
            >
              {isTruncatedDesc ? `${__("user_setting_info_see_more")}` : `${__("user_setting_info_collapse")}`}
            </span>
          )}
        </div>
        {
          (helpers.current_user_can("boss", permission_data) || userLoginId === dataUser?._id) &&
          <>
            <div className="userDetail_body_left_item">
              <span className="userDetail_body_left_item_title">{__("user_setting_telephone_number")}</span>
              <span className="userDetail_body_left_item_content">
            {helpers.validPhoneNumber(dataUser?.user_phone) || `${__("user_setting_no_phone_number")}`}
          </span>
            </div>
            <div className="userDetail_body_left_item">
              <span className="userDetail_body_left_item_title">{__("user_setting_address")}</span>
              <span className="userDetail_body_left_item_content">
            {dataUser?.user_address || `${__("user_setting_no_address")}`}
          </span>
            </div>
          </>
        }
      </div>
      <div className="userDetail_body_left_second">
        <div className="userDetail_body_left_second_item">
          <span className="userDetail_body_left_second_item_value">
            {dataUser?.contribute || '0'}
          </span>
          <span className="userDetail_body_left_second_item_title">{__("user_setting_contribute")}</span>
        </div>
        <div className="userDetail_body_left_second_item">
          <span>{dataUser?.followers || '0'}</span>
          <span className="userDetail_body_left_second_item_title">{__("user_setting_follower")}</span>
        </div>
        <div className="userDetail_body_left_second_item">
          <span>{dataUser?.following || '0'}</span>
          <span className="userDetail_body_left_second_item_title">{__("user_setting_following")}</span>
        </div>
      </div>
      <div className="userDetail_body_left_third">
        <div className="userDetail_body_left_third_item">
          <FontAwesomeIcon icon={faClock} />
          <span>{`${__("user_setting_activity")} ${helpers.subtractTimeHistory(
            new Date(dataUser?.last_active).getTime()
          )}`}</span>
        </div>
        <div className="userDetail_body_left_third_item">
          <FontAwesomeIcon icon={faCalendar} />
          <span>{`${__("user_setting_join")} ${helpers.convertDateTime(dataUser?.createdAt)}`}</span>
        </div>
      </div>
      {(!helpers.isEmpty(dataUser?.social_link) && (helpers.current_user_can("boss", permission_data) || userLoginId === dataUser?._id)) && (
        <div className="userDetail_body_left_four">
          {dataUser?.social_link.map((item: TypeSocialLink, idx: number) => {
            let iconSocialLink = iconLink
            switch (item?.key) {
              case 'website':
                iconSocialLink = iconLink
                break
              case 'instagram':
                iconSocialLink = iconInstagram
                break
              case 'twitter':
                iconSocialLink = iconTwitter
                break
              case 'youTube':
                iconSocialLink = iconYoutube
                break
              case 'linkedln':
                iconSocialLink = iconLinkedin
                break
              case 'facebook':
                iconSocialLink = iconFacebook
                break
              default:
                iconSocialLink = null
                break
            }
            if(!item?.value) return null;
            return (
              <div key={`social_${idx}`} className="userDetail_body_left_four_item">
                <img
                  src={iconSocialLink}
                  width={24}
                  height={24}
                  alt="icon"
                  className="userDetail_body_left_four_item_image"
                />
                <a
                  href={item?.value}
                  target="_blank"
                  className="userDetail_body_left_four_item_value"
                >
                  {item?.value}
                </a>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default memo(CardUserInfo)
