import React from 'react'
import helpers from "helpers/index";
import {TypeDataMySubscription} from "entities/orders/order.type";
import __ from "languages/index";
import { Box, Button, Divider, Stack, styled, Typography } from '@mui/material'

interface TypeOrderPlan{
  dataSubscription: TypeDataMySubscription
  idx: number
  refItem: (item: HTMLElement) => void
  handleClickCancel:(item: TypeDataMySubscription) => void
}
const OrderPlan: React.FC<TypeOrderPlan> = ({dataSubscription, idx,refItem, handleClickCancel}) =>{

  return(
    <OrderPlanContainer>
      <Box ref={refItem} key={`card_${idx}`}>
        <Box className="orderMySubscription_card">
          <Box className="orderMySubscription_left">
            <Typography variant={'h6'} className="orderMySubscription_left_title">{dataSubscription?.plan_id?.name}</Typography>
            <Typography
              variant={'subtitle2'}
              color={'primary.main'}
              fontWeight={400}
              className="orderMySubscription_left_extend_title">{`${__("order_plan_text")} ${helpers.convertToCommasFormat(
              dataSubscription?.plan_id?.amount_of_day || '0'
            )} ${__("order_plan_unit")}`}</Typography>
          </Box>
          <Divider color={'text.secondary'} />
          <Box color={'text.primary'}>
            <Stack direction={'row'} display={'grid'} gridTemplateColumns={'auto auto'}>
              <Box>
                <Typography padding={theme => theme.spacing(1,2)}>{__('order_plan_status')}</Typography>
                <Typography padding={theme => theme.spacing(1,2)}>{__('order_plan_register_date')}</Typography>
                <Typography padding={theme => theme.spacing(1,2)}>{__('order_plan_renewal_date')}</Typography>
                <Typography padding={theme => theme.spacing(1,2)}>{__('order_plan_total')}</Typography>

              </Box>
              <Box>
                <Typography padding={theme => theme.spacing(1,2)}>{__(dataSubscription?.status)}</Typography>
                <Typography padding={theme => theme.spacing(1,2)}>{helpers.convertDateTime(dataSubscription?.start_at)}</Typography>
                <Typography padding={theme => theme.spacing(1,2)}>{helpers.convertDateTime(dataSubscription?.end_at)}</Typography>
                <Typography padding={theme => theme.spacing(1,2)}>{`${helpers.convertToCommasFormat(
                  dataSubscription?.plan_id?.price || '0'
                )} ${__('order_unit')}`}</Typography>

              </Box>
            </Stack>
            <Divider color={'text.secondary'} />
            <Box sx={{ textAlign: 'end' }}>
              {dataSubscription?.is_auto_renew && (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'none'
                  }}
                  onClick={() => handleClickCancel(dataSubscription)}
                >
                  {__("order_plan_cancel_renewal")}
                </Button>
              )}
            </Box>

          </Box>
        </Box>
      </Box>

    </OrderPlanContainer>
  )
}
export default OrderPlan

const OrderPlanContainer = styled(Box)(({theme})=>({
  '& .orderMySubscription_card': {
    display: 'grid',
    marginRight: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: theme.spacing(125),
    gridTemplateColumns: `repeat(1, minmax(0, 1fr))`
  },
  '& .orderMySubscription_left':{
    display: 'flex',
    flexDirection: 'column'
  },
  '& .orderMySubscription_left_title':{
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    wordBreak: 'normal'
  },
  '& .orderMySubscription_left_extend_title':{
    width: 'max-content',
    padding: theme.spacing(0,1.25),
    margin: theme.spacing(1,0),
    borderRadius: theme.spacing(2.25),
    backgroundColor: theme.palette.primary.background
  },
  '& .orderMySubscription_right_item':{

  }

}))
