import { SvgIcon, SvgIconProps } from '@mui/material'

const MissionSidebarICon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 48095865">
          <path
            id="Vector"
            d="M17.3383 34.4723C26.6255 34.4723 34.1571 26.9407 34.1571 17.6535C34.1571 8.36629 26.6179 0.842285 17.3383 0.842285C8.05873 0.842285 0.519531 8.37389 0.519531 17.6535C0.519531 26.9331 8.05113 34.4723 17.3383 34.4723Z"
            fill="url(#paint0_linear_1101_5288)"
          />
          <path
            id="Vector_2"
            d="M29.0422 29.7223C32.1886 26.6671 34.1494 22.3883 34.1494 17.6535C34.1494 12.9187 32.2646 8.80709 29.2246 5.76709L17.1558 17.8359L29.0422 29.7223Z"
            fill="url(#paint1_linear_1101_5288)"
          />
          <path
            id="Vector_3"
            d="M17.3303 30.6268C24.4895 30.6268 30.2959 24.8204 30.2959 17.6612C30.2959 10.502 24.4971 4.68799 17.3303 4.68799C10.1635 4.68799 4.36475 10.4944 4.36475 17.6536C4.36475 24.8128 10.1711 30.6192 17.3303 30.6192V30.6268Z"
            fill="url(#paint2_linear_1101_5288)"
          />
          <path
            id="Vector_4"
            d="M26.3214 27.0014C28.7762 24.6454 30.2962 21.3242 30.2962 17.6534C30.2962 13.9826 28.8446 10.8286 26.4962 8.48779L17.1482 17.8358L26.3138 27.0014H26.3214Z"
            fill="url(#paint3_linear_1101_5288)"
          />
          <path
            id="Vector_5"
            d="M17.3383 26.91C22.4455 26.91 26.5875 22.768 26.5875 17.6608C26.5875 12.5536 22.4455 8.41162 17.3383 8.41162C12.2311 8.41162 8.08911 12.5536 8.08911 17.6608C8.08911 22.768 12.2311 26.91 17.3383 26.91Z"
            fill="url(#paint4_linear_1101_5288)"
          />
          <path
            id="Vector_6"
            d="M23.6918 24.3719C25.4702 22.6847 26.5798 20.2983 26.5798 17.6535C26.5798 15.0087 25.5462 12.7895 23.8742 11.1099L17.1558 17.8283L23.6918 24.3643V24.3719Z"
            fill="url(#paint5_linear_1101_5288)"
          />
          <path
            id="Vector_7"
            d="M17.3388 22.8592C20.2116 22.8592 22.5372 20.5336 22.5372 17.6608C22.5372 14.788 20.2116 12.4624 17.3388 12.4624C14.466 12.4624 12.1404 14.788 12.1404 17.6608C12.1404 20.5336 14.466 22.8592 17.3388 22.8592Z"
            fill="url(#paint6_linear_1101_5288)"
          />
          <path
            id="Vector_8"
            d="M20.8266 21.5067C21.8754 20.5567 22.529 19.1811 22.529 17.6535C22.529 16.2171 21.9438 14.9175 21.009 13.9751L17.1558 17.8283L20.8266 21.4991V21.5067Z"
            fill="url(#paint7_linear_1101_5288)"
          />
          <path
            id="Vector_9"
            d="M17.3385 20.0399C18.6533 20.0399 19.7249 18.9683 19.7249 17.6535C19.7249 16.3387 18.6533 15.2671 17.3385 15.2671C16.0237 15.2671 14.9521 16.3387 14.9521 17.6535C14.9521 18.9683 16.0237 20.0399 17.3385 20.0399Z"
            fill="url(#paint8_linear_1101_5288)"
          />
          <path
            id="Vector_10"
            d="M18.8358 19.5152C19.3754 19.0744 19.725 18.4132 19.725 17.6608C19.725 16.9996 19.459 16.4068 19.0258 15.9736L17.1638 17.8356L18.8434 19.5152H18.8358Z"
            fill="url(#paint9_linear_1101_5288)"
          />
          <path
            id="Vector_11"
            d="M17.1256 17.8662C16.9432 17.6838 16.9432 17.395 17.1256 17.2126L22.3088 12.0294C22.4912 11.847 22.78 11.847 22.9624 12.0294C23.1448 12.2118 23.1448 12.5006 22.9624 12.683L17.7792 17.8662C17.5968 18.0486 17.308 18.0486 17.1256 17.8662Z"
            fill="#414042"
          />
          <path
            id="Vector_12"
            d="M34.719 4.53579C34.719 3.35779 33.7614 2.40779 32.591 2.40779C32.591 1.22979 31.6334 0.279785 30.463 0.279785C29.2926 0.279785 28.335 1.23739 28.335 2.40779V4.45979C28.335 5.04499 28.5706 5.60739 28.981 6.02539C29.399 6.44339 29.9614 6.67139 30.5466 6.67139H32.5986C33.7766 6.67139 34.7266 5.72139 34.7266 4.54339L34.719 4.53579Z"
            fill="url(#paint10_linear_1101_5288)"
          />
          <path
            id="Vector_13"
            d="M28.9744 6.01771C29.3924 6.43572 29.9548 6.66371 30.54 6.66371H32.592C33.77 6.66371 34.72 5.70612 34.72 4.53572C34.72 3.36532 33.7624 2.40771 32.592 2.40771L28.982 6.01771H28.9744Z"
            fill="url(#paint11_linear_1101_5288)"
          />
          <path
            id="Vector_14"
            d="M27.1876 7.80368C27.0052 7.62128 27.0052 7.33248 27.1876 7.15008L32.3708 1.96688C32.5532 1.78448 32.842 1.78448 33.0244 1.96688C33.2068 2.14928 33.2068 2.43808 33.0244 2.62048L27.8412 7.80368C27.6588 7.98608 27.37 7.98608 27.1876 7.80368Z"
            fill="url(#paint12_linear_1101_5288)"
          />
          <path
            id="Vector_15"
            d="M24.5512 13.9295C22.0584 16.4223 20.3256 16.5895 19.368 15.6319C18.4028 14.6667 18.5776 12.9339 21.0704 10.4487C23.5632 7.9559 28.2144 4.8627 29.1796 5.8279C30.1448 6.7931 27.044 11.4443 24.5588 13.9371L24.5512 13.9295Z"
            fill="url(#paint13_linear_1101_5288)"
          />
          <path
            id="Vector_16"
            d="M34.719 4.53579C34.719 3.35779 33.7614 2.40779 32.591 2.40779C32.591 1.22979 31.6334 0.279785 30.463 0.279785C29.2926 0.279785 28.335 1.23739 28.335 2.40779V4.45979C28.335 5.04499 28.5706 5.60739 28.981 6.02539C29.399 6.44339 29.9614 6.67139 30.5466 6.67139H32.5986C33.7766 6.67139 34.7266 5.72139 34.7266 4.54339L34.719 4.53579Z"
            fill="url(#paint14_linear_1101_5288)"
          />
          <path
            id="Vector_17"
            d="M24.5512 13.9295C22.0584 16.4223 20.3256 16.5895 19.368 15.6319C18.4028 14.6667 18.5776 12.9339 21.0704 10.4487C23.5632 7.9559 28.2144 4.8627 29.1796 5.8279C30.1448 6.7931 27.044 11.4443 24.5588 13.9371L24.5512 13.9295Z"
            fill="url(#paint15_linear_1101_5288)"
          />
          <path
            id="Vector_18"
            d="M24.5512 13.9295C22.0584 16.4223 20.3256 16.5895 19.368 15.6319C18.4028 14.6667 18.5776 12.9339 21.0704 10.4487C21.1464 10.3727 21.2224 10.2967 21.2984 10.2207C21.7924 10.7831 22.3624 11.3911 22.9856 12.0143C23.6088 12.6375 24.2168 13.2075 24.7792 13.7015C24.7032 13.7775 24.6272 13.8535 24.5512 13.9295Z"
            fill="url(#paint16_linear_1101_5288)"
          />
          <path
            id="Vector_19"
            d="M18.8511 13.9143L21.0703 16.1335C21.5795 16.0499 22.1647 15.8143 22.8183 15.3735L19.6035 12.1587C19.1703 12.8123 18.9271 13.3975 18.8435 13.9067L18.8511 13.9143Z"
            fill="url(#paint17_linear_1101_5288)"
          />
          <path
            id="Vector_20"
            d="M19.6104 12.1736L22.8252 15.3884C23.1976 15.1452 23.5852 14.8336 24.0032 14.4536L20.5452 10.9956C20.1652 11.4136 19.8612 11.8088 19.6104 12.1736Z"
            fill="url(#paint18_linear_1101_5288)"
          />
          <path
            id="Vector_21"
            d="M20.3935 10.7828C20.2415 10.9576 20.2415 11.216 20.3935 11.3908C21.3967 12.5232 22.4607 13.5872 23.5931 14.5904C23.7679 14.7424 24.0263 14.7424 24.2011 14.5904C24.4063 14.408 24.4063 14.0888 24.2011 13.9064C23.0915 12.926 22.0579 11.8848 21.0775 10.7828C20.8951 10.5776 20.5759 10.5776 20.3935 10.7828Z"
            fill="url(#paint19_linear_1101_5288)"
          />
          <path
            id="Vector_22"
            d="M19.383 11.8014C19.231 11.9762 19.231 12.2346 19.383 12.4094C20.3862 13.5418 21.4502 14.6058 22.5826 15.609C22.7574 15.761 23.0158 15.761 23.1906 15.609C23.3958 15.4266 23.3958 15.1074 23.1906 14.925C22.081 13.9446 21.0474 12.9034 20.067 11.8014C19.8846 11.5962 19.5654 11.5962 19.383 11.8014Z"
            fill="url(#paint20_linear_1101_5288)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1101_5288"
            x1="17.3383"
            y1="0.697885"
            x2="17.3383"
            y2="33.8719"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1101_5288"
            x1="31.9074"
            y1="20.5111"
            x2="23.7374"
            y2="12.3487"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1101_5288"
            x1="17.3303"
            y1="4.74879"
            x2="17.3303"
            y2="29.5932"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1101_5288"
            x1="32.3102"
            y1="23.6422"
            x2="23.0914"
            y2="14.4234"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7BACDF" stopOpacity="0" />
            <stop offset="1" stopColor="#7BACDF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1101_5288"
            x1="17.3383"
            y1="0.697621"
            x2="17.3383"
            y2="33.8716"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1101_5288"
            x1="27.849"
            y1="21.8031"
            x2="21.2294"
            y2="15.1911"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1101_5288"
            x1="17.3388"
            y1="0.272003"
            x2="17.3388"
            y2="0.272003"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1101_5288"
            x1="25.1586"
            y1="21.9855"
            x2="18.8126"
            y2="15.6395"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7BACDF" stopOpacity="0" />
            <stop offset="1" stopColor="#7BACDF" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1101_5288"
            x1="15.4613"
            y1="15.7839"
            x2="18.7977"
            y2="19.1127"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1101_5288"
            x1="22.7042"
            y1="21.5216"
            x2="17.6502"
            y2="16.4676"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1101_5288"
            x1="31.5194"
            y1="0.211385"
            x2="31.5194"
            y2="6.58019"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1101_5288"
            x1="32.3032"
            y1="5.28811"
            x2="30.5856"
            y2="3.57051"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#017BAE" stopOpacity="0" />
            <stop offset="0.63" stopColor="#02678E" stopOpacity="0.63" />
            <stop offset="1" stopColor="#025F80" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1101_5288"
            x1="29.574"
            y1="4.34568"
            x2="30.2276"
            y2="4.99928"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1101_5288"
            x1="23.1452"
            y1="9.6051"
            x2="25.106"
            y2="11.5659"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF4FF" />
            <stop offset="1" stopColor="#CFE7FD" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1101_5288"
            x1="31.5194"
            y1="0.211385"
            x2="31.5194"
            y2="6.58019"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AAADC" stopOpacity="0" />
            <stop offset="1" stopColor="#8AAADC" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1101_5288"
            x1="23.1452"
            y1="9.6051"
            x2="25.106"
            y2="11.5659"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8AAADC" stopOpacity="0" />
            <stop offset="1" stopColor="#8AAADC" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_1101_5288"
            x1="20.4244"
            y1="13.0175"
            x2="21.7088"
            y2="14.3019"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_1101_5288"
            x1="20.3939"
            y1="14.5907"
            x2="21.4655"
            y2="13.5267"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="0.25" stopColor="#F02818" stopOpacity="0.25" />
            <stop offset="0.64" stopColor="#DA2721" stopOpacity="0.64" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_1101_5288"
            x1="21.6168"
            y1="13.3744"
            x2="22.5212"
            y2="12.47"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F82814" stopOpacity="0" />
            <stop offset="0.25" stopColor="#F02818" stopOpacity="0.25" />
            <stop offset="0.64" stopColor="#DA2721" stopOpacity="0.64" />
            <stop offset="1" stopColor="#C0272D" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_1101_5288"
            x1="22.8027"
            y1="12.1888"
            x2="22.2631"
            y2="12.7284"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_1101_5288"
            x1="21.7846"
            y1="13.1998"
            x2="21.2526"
            y2="13.7394"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default MissionSidebarICon
