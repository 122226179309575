import React, { useState } from 'react'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import { Button, IconButton, Modal, Stack, TableCell, TableRow, Typography } from '@mui/material'
import __ from 'languages/index'
import Table from '../components/Table'
import { TypedCategory } from 'interfaces/request.interface'
import _ from 'lodash'
import { CloseIcon, PlusIcon } from 'icons'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import AddCategoryForm from './AddCategoryForm'
import { StyledTableCell } from '../components/StyledTableCell'
import RowActions from 'entities/channel-settings/components/RowActions'
import {
  useGetPostCategoriesQuery,
  useUpdateCategoryMutation
} from 'entities/homeNew/store/homeApi'
import RenderView from 'components/RenderView'
import __helpers from 'helpers/index'
import ConfirmModal from 'components/ConfirmModal'
import { useAppSelector } from 'config/store'
import { getChannelId } from 'store/user.store.reducer'
import { EnumTypeToast, useToast } from 'hooks/useToast'

const getKeys = (obj: Record<string, any>) => Object.keys(obj)

const PostCategory = () => {
  const channelId = useAppSelector(getChannelId)
  const [selectedCategory, setSelectedCategory] = useState<{
    action: 'Delete' | 'Edit'
    payload: TypedCategory
  }>(null)

  const { data: categories, isLoading: fetchingCategories } = useGetPostCategoriesQuery()
  const [updateCategory] = useUpdateCategoryMutation()
  const toast = useToast()

  const closeAddOrEditForm = () => {
    setSelectedCategory(null)
  }

  const openAddForm = () => {
    setSelectedCategory({
      action: 'Edit',
      payload: null
    })
  }
  const openEditForm = (category: TypedCategory) => {
    setSelectedCategory({
      action: 'Edit',
      payload: category
    })
  }

  const openDeleteForm = (category: TypedCategory) => {
    setSelectedCategory({
      action: 'Delete',
      payload: category
    })
  }

  const closeDeleteForm = () => {
    setSelectedCategory({
      action: 'Delete',
      payload: null
    })
  }

  const handleDeleteCategory = async () => {
    try {
      await updateCategory({
        _id: selectedCategory.payload._id,
        public_status: '0',
        channel_id: channelId
      }).unwrap()
      toast.show({
        type: EnumTypeToast.Success,
        content: __('channel_setting_delete_category_success')
      })
      closeDeleteForm()
    } catch (err) {
      console.log(err)
    }
  }

  const cellFormatter = (item: TypedCategory) => {
    const rowData = _.pick(item, ['category_title', 'category_content'])

    return [...getKeys(rowData), 'action'].map(key => ({
      key,
      render:
        key !== 'action' ? (
          <Typography>{item[key]}</Typography>
        ) : (
          <RowActions
            onClickDeleteBtn={() => openDeleteForm(item)}
            onClickEditBtn={() => openEditForm(item)}
          />
        )
    }))
  }

  const header = () => {
    return (
      <TableRow>
        <StyledTableCell>{__('channel_setting_label_category_name')}</StyledTableCell>
        <StyledTableCell>{__('channel_setting_level_name')}</StyledTableCell>
        <StyledTableCell>{__('label_action')}</StyledTableCell>
      </TableRow>
    )
  }

  return (
    <Stack gap={2}>
      <ChannelSettingsTitle>{__('channel_setting_title_post_categories')}</ChannelSettingsTitle>
      <RenderView
        view={
          <Table
            data={categories}
            renderHeader={header}
            renderTableRow={item =>
              cellFormatter(item as TypedCategory).map(cell => <TableCell>{cell.render}</TableCell>)
            }
          />
        }
        isLoading={fetchingCategories}
        isEmpty={!categories || __helpers.isEmpty(categories)}
      />
      <Button startIcon={<PlusIcon />} sx={{ mr: 'auto' }} onClick={openAddForm}>
        {__('channel_setting_add_category')}
      </Button>
      <Modal
        open={selectedCategory?.action === 'Edit' && selectedCategory !== null}
        onClose={closeAddOrEditForm}
      >
        <ModalContainer
          sx={{
            width: '80%',
            maxWidth: '600px'
          }}
        >
          <ModalHeader direction={'row'}>
            <Typography className="title">{__('channel_setting_add_category')}</Typography>
            <IconButton onClick={closeAddOrEditForm}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <ModalBody>
            <AddCategoryForm category={selectedCategory?.payload} onSuccess={closeAddOrEditForm} />
          </ModalBody>
        </ModalContainer>
      </Modal>
      <ConfirmModal
        open={selectedCategory?.action === 'Delete' && selectedCategory?.payload !== null}
        onClose={closeDeleteForm}
        onConfirm={handleDeleteCategory}
        title={__('channel_setting_modal_confirm_title')}
      >
        <Typography>{__('channel_setting_modal_confirm_text')}</Typography>
      </ConfirmModal>
    </Stack>
  )
}

export default PostCategory
