export enum LogicticModule {
    GHTK = 'GHTK',
    GHN = 'GHN',
    VP = 'VIETTELPOST',
    AHA = 'AHA',
    VNP = 'VNPOST'
}

export enum LogisticDefaultService {
    DEFAULT = 1,
    NORMAL = 0
}

export enum IsDefaultStock {
    DEFAULT = 1,
    NORMAL = 0
}

export enum ProductStatus {
    SELLING = 1,
    NOSALE = 0
}