import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  styled,
  Typography
} from '@mui/material'
import {useNavigate, useParams} from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import __ from 'languages/index'
import {useAppDispatch} from 'config/store'
import { TypedProduct, TypedProductStock } from 'entities/shop/interface'
import {AxiosError} from 'axios'
import ArrowChevronLeftIcon from '../../../icons/ArrowChevronLeftIcon'
import { EditIcon, SearchIcon } from '../../../icons'
import PlusIcon from '../../../icons/PlusIcon'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {Image} from 'react-bootstrap'
import EssentialsIcon from '../../../icons/EssentialsIcon'
import AddProductModal from 'entities/shop/components/AddProductModal'
import {InfiniteScrollContainer} from 'components/InfiniteScrollContainer'
import {useInfiniteScroll} from '../../../hooks/useInfiniteScroll'
import imageDefault from 'media/images/image_default.png'
import {  getStockById, updateProductToStock } from 'entities/shop/store/shop.store.reducer'
import __helpers from "helpers/index";
import {formatString} from "entities/support/utils";
import CheckEssentialsIcon from "../../../icons/CheckEssentialsIcon";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import { getProducts } from 'entities/shop/service/product.service'
import TextFieldMUI from 'components/TextFieldMUI'

const StockDetail = ()=>{
  const [stockName,setStockName] = useState<string>('')
  const [productQuantity,setProductQuantity] = useState<number>(0)
  const [inputValueQuantity,setInputValueQuantity] = useState<number>(0)
  const [addProduct,setAddProduct] = useState<TypedProduct>()

  const [isShowAddProductModal,setIsShowAddProductModal] = useState<boolean>(false)
  const [idEditCell,setIdEditCell] = useState<string>('')
  const { stock_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const toast = useToast()


  useEffect(() => {
    if(!stock_id){
      navigate("/shop/admin")
    }
    dispatch(getStockById(stock_id))
      .unwrap()
      .then(res=>{
        setStockName(res?.data?.stock_name)
      })
      .catch((e: AxiosError)=>{
        console.log('getStockById', e)
      })

  }, [stock_id])

  const {
    hasMore,
    isRefreshing,
    loadMore,
    data,
    updateParamRequest,
    updateParamRequestDebounce,
    updateItem,
    updateData,
    isLoading,
    refresh
  } = useInfiniteScroll<TypedProduct>({
    stock_id: stock_id,
    limit: 12,
    order_by: 'DESC',
    keyAttribute:"product_id"
  }, getProducts)


  useEffect(()=>{
    if(!!addProduct){
      updateData([...data,addProduct])
      refresh(true)
    }
  },[addProduct])

  const handleEditProductToStock = useCallback((rowId: string)=>{
    setIdEditCell(rowId)
  },[])

  const handleUpdateProductToStock = useCallback(async (param: TypedProduct)=>{

    await dispatch(updateProductToStock({
      stock_id: stock_id,
      product_id: param?.product_id,
      product_sku: param?.product_sku,
      product_quantity: productQuantity
    }))
      .unwrap()
      .then((res)=>{
        updateItem(res?.data)
        refresh(true)
        if (!isRefreshing){
          setProductQuantity(0)

        }
        toast.show({
          content: `${__('update_product_success')}`,
          type: EnumTypeToast.Success
        })
      })
      .catch((e: AxiosError)=>{
        toast.show({
          content: `${__('update_product_failed')}`,
          type: EnumTypeToast.Error
        })
        console.log('updateProduct',e)
      })
  },[productQuantity])

  const handleResetQuantityProduct = useCallback(async (param)=>{
    const filterProductToStockById = Array.isArray(param?.product_to_stock) && param?.product_to_stock?.find((item: TypedProductStock)=> item?.stock_id === stock_id)
    if(filterProductToStockById?.product_quantity > 0){
      await dispatch(updateProductToStock({
        stock_id: stock_id,
        product_id: param?.product_id,
        product_sku: param?.product_sku,
        product_quantity: 0
      }))
        .unwrap()
        .then((res)=>{
          refresh(true)
          if (!isRefreshing){
            setProductQuantity(0)

          }

          toast.show({
            content: `${__('update_product_success')}`,
            type: EnumTypeToast.Success
          })
        })
        .catch((e: AxiosError)=>{
          toast.show({
            content: `${__('update_product_failed')}`,
            type: EnumTypeToast.Error
          })
          console.log('resetQuantity',e)
        })
    }
  },[])

  const handleCloseAddProductModal = useCallback(()=>{
    setIsShowAddProductModal(false)
  },[])

  const handleAddQuantity = useCallback((value: string)=>{
    let inputValue = Number(value?.replace(/\D+/g, ''))
    setProductQuantity(inputValue)
    setInputValueQuantity(inputValue)
  },[])


  const handleSearchProduct = (event) => {
    const inputValue = event.target.value;
    updateParamRequestDebounce({ query: inputValue })};

  const columns :GridColDef[] = [
    {
      field: "product",
      headerName: `${__("stock_product")}`,
      flex: 1,
      sortable: false,
      renderCell: (params) =>{
        return(
          <Stack direction={'row'} alignItems='center' >
            <Box sx={{paddingRight: theme => theme.spacing(2)}}>
              <Image style={{ height: '50px', width: '50px', objectFit: 'cover' }}
                     src={ params?.row?.product_avatar?.[0] ?? imageDefault}/>
            </Box>
            <Stack direction={'column'}>
              <Typography color={'text.primary'} variant={'subtitle2'}>
                {__helpers.getTrimContent(`${params?.row.product_name}`, 30)}
              </Typography>
              <Typography color={'primary.main'}  variant={'body1'} >
                {formatString(__('stock_product_price'), `${__helpers.formatNumberWithCommas(params?.row.product_price) || 0} `)}
              </Typography>
              <Typography color={'text.secondary'}  variant={'body1'}>
                {formatString(__('stock_product_sku'), `${params?.row.product_sku || 0} `)}
              </Typography>
            </Stack>
          </Stack>
        )
      }

    },
    {
      field: "product_to_stock",
      headerName: `${__("stock_quantity")}`,
      flex: 1,
      editable:true,
      headerAlign: 'center',
      sortingOrder: ['asc', 'desc'],
      align:'center',
      renderCell: (params)=>{
        const filterQuantityOfProduct = Array.isArray(params?.row.product_to_stock) && params?.row.product_to_stock.find((item)=> item.stock_id === stock_id)
        const showQuantity = (isRefreshing && idEditCell === params?.rowNode.id)
          ?  `${productQuantity}` : !filterQuantityOfProduct
            ? `${inputValueQuantity}` : `${filterQuantityOfProduct?.product_quantity}`;

        return(
          <Box alignItems={'center'}>
            {idEditCell !== params.rowNode.id ?
              (
                <Typography
                  variant={'body1'}
                  color={'primary.main'}
                  sx={{
                    backgroundColor: theme => theme.palette.background.default,
                    borderRadius: theme => theme.spacing(1.5),
                    padding: theme => theme.spacing(0.5)
                  }}
                >
                  {formatString(__('stock_product_quantity'), showQuantity)}
                </Typography>
              ):(
                <TextFieldMUI
                  autoFocus
                  isShowMessage={true}
                  value={ inputValueQuantity > 0 ?`${inputValueQuantity}` : ''}
                  placeholder={__("stock_detail_quantity")}
                  className= 'stock_product_quantity'
                  onChange={value => handleAddQuantity(value)}
                  min={0}
                  onBlur={()=>{
                    setInputValueQuantity(0)
                    setIdEditCell('')
                  }}
                />
              )
            }
          </Box>

        )
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const quantity1 = Array.isArray(cellParams1.value) && cellParams1.value.find((item) => item.stock_id === stock_id)?.product_quantity || 0;
        const quantity2 = Array.isArray(cellParams2.value) && cellParams2.value.find((item) => item.stock_id === stock_id)?.product_quantity || 0;
        return quantity1 - quantity2;
      },
      renderEditCell: (params) =>
        <TextFieldMUI
           autoFocus
           isShowMessage={true}
           value={ inputValueQuantity > 0 ?`${inputValueQuantity}` : ''}
           placeholder={__("stock_detail_quantity")}
           className= 'stock_product_quantity'
           onChange={value => handleAddQuantity(value)}
           min={0}
           onBlur={()=>setInputValueQuantity(0)}
        />
    },

    {
      field: 'action',
      headerName: `${__("stock_action")}`,
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align:'center',
      renderCell: (params) =>{
        return(
          <Box>
              <IconButton
                onClick={(event)=>handleResetQuantityProduct(params?.row)}
                className='icon_button_action'
              >
                <EssentialsIcon
                  sx={{
                    width: theme => theme.spacing(2.5),
                    height: theme => theme.spacing(2.5)
                  }}
                />

              </IconButton>
            {idEditCell === params.rowNode.id ? (
              <IconButton
                sx={{marginLeft: theme => theme.spacing(0.5)}}
                onClick={(event)=>handleUpdateProductToStock(params?.row)}
                className='icon_button_action icon_button_check'
              >
                <CheckEssentialsIcon
                  sx={{
                    width: theme => theme.spacing(2.375),
                    height: theme => theme.spacing(2.5)
                  }}
                />

              </IconButton>
            ):(
              <IconButton
                sx={{marginLeft: theme => theme.spacing(0.5)}}
                onClick={()=>handleEditProductToStock(`${params.rowNode.id}`)}
                className='icon_button_action icon_button_check'
              >
                <EditIcon
                  sx={{
                    width: theme => theme.spacing(2.375),
                    height: theme => theme.spacing(2.5)
                  }}
                  color="inherit"

                />

              </IconButton>
            )}
          </Box>

        )
      }

    },
  ]



  return(
    < StockDetailContainer id={'StockDetailContainer'}>
      <Paper className="stock_detail_wrapper">
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <IconButton
            onClick={() => navigate(`/shop/admin#stockroom`)}
            sx={{color: theme => theme.palette.text.primary, fontSize: theme => theme.spacing(2.5) }}
          >
            <ArrowChevronLeftIcon
              sx={{
                width: theme => theme.spacing(1.5),
                height: theme => theme.spacing(1.5),
              }}/>
          </IconButton>
          <Typography
            variant='subtitle1'
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            {stockName}
          </Typography>
        </Box>
        <Box>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent='space-between'
          >
            <Box sx={{maxWidth: theme => theme.spacing(60)}}>
              <OutlinedInput
                size="small"
                type="search"
                id="stock_detail_search"
                className="stock_detail_search"
                placeholder={__('manage_product')}
                autoFocus
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="search" sx={{ padding: 0, width: theme => theme.spacing(2.375)}} >
                      <SearchIcon sx={{ width: '100%', height: '100%', marginRight: theme => theme.spacing(1)}} color="inherit" />
                    </IconButton>
                  </InputAdornment>}
                aria-describedby="outlined-helper-text"
                sx={{borderRadius: theme => theme.spacing(1.5)}}
                onChange={handleSearchProduct}
              />
            </Box>
            <Button
              onClick={()=> setIsShowAddProductModal(true)}
              size={'small'}
              sx={{padding: theme => theme.spacing(1,1)}}
            >
                <PlusIcon
                  sx={{
                  width: theme => theme.spacing(2.25),
                  height: theme => theme.spacing(2.25) ,
                  margin: theme => theme.spacing(0, 1, 0, 0),
                  color: 'inherit',
                }}
                />
              <Typography variant="body1" textTransform= 'none'>
                {__("stock_detail_btn_add")}
              </Typography>
            </Button>
          </Stack>
        </Box>
        <Box>

          <InfiniteScrollContainer
            isRefreshing={isRefreshing}
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            refreshFunction={refresh}
            showEndMessage={false}
            scrollableTarget={"scrollableDiv"}
          >
            <DataGrid
              rowHeight={120}
              hideFooterPagination
              hideFooter
              disableColumnMenu
              disableRowSelectionOnClick
              showCellVerticalBorder={false}
              initialState={{
                // sorting: {
                //   sortModel: [
                //     {
                //       field: 'product_to_stock',
                //       sort: 'asc',
                //     },
                //   ],
                // },
              }}
              // sortingMode="server"
              columns={columns}
              rows={data}
              rowSpacingType={'margin'}
              sx={{
                backgroundColor: theme => theme.palette.background.paper,
                borderRadius: theme => theme.spacing(1.5),
                boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.08)`,
                '& .MuiDataGrid-cell': {
                  height: '100px',
                },
              }}
              onCellClick={(params)=>{
                if (!params.colDef.editable) {
                  // params.event.stopPropagation();
                }
              }}
              getRowId={(row) => row.product_id}
              onCellEditStart={(params, event)=> setIdEditCell(`${params?.rowNode.id}`)}
              onCellEditStop={(params, event)=>setIdEditCell('')}
            />
          </InfiniteScrollContainer>

        </Box>
      </Paper>

      {isShowAddProductModal &&
        <AddProductModal
          setAddProduct={setAddProduct}
          stock_id={stock_id}
          isShowAddProductModal={isShowAddProductModal}
          handleCloseAddProductModal={handleCloseAddProductModal}
        />}

    </StockDetailContainer>

  )
}

export default StockDetail

const StockDetailContainer = styled(Box)(({theme})=>({
  '& .stock_detail_wrapper':{
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`
  },
  '& .MuiDataGrid-root':{
    boxShadow: 'none',
    borderRadius: 0,
    borderColor: 'transparent',
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within,& .MuiDataGrid-cell:focus-visible, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-columnHeader:focus-visible, & .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within':{
      outline: 'none',
      outlineWidth: 0,
      outlineOffset: 0
    }
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.background.secondary,
    borderRadius:  theme.spacing(1.5),

  },
  '& .super-app-theme--header:hover': {
    '& .MuiDataGrid-menuIcon': {
      visibility: 'hidden',
    },
  },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
    display: 'flex',
    visibility: 'visible',
    width: 'auto'

  },
  '& .MuiDataGrid-iconSeparator':{
    visibility: 'hidden',
  },
  '& .MuiDataGrid-root .MuiDataGrid-withBorderColor':{
    borderColor: theme.palette.divider,
  } ,
  '& .MuiDataGrid-footerContainer':{
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .stock_product_quantity':{
    width: '90%',
    margin: theme.spacing(2, 1)

  },

  '& .icon_button_action': {
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    alignItems: 'center'
  },
  '& .icon_button_check': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '& .stock_detail_search':{
    borderColor: theme.palette.text.secondary,
    display: "flex"
  },

}))
