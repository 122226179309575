import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from 'components/Modal'
import { Box, Button, Divider, IconButton, Link, Modal, styled, Typography } from '@mui/material'
import __ from 'languages/index'
import { CloseIcon } from '../icons'
import React, { useEffect, useState } from 'react'
import TypedChannel from '../interfaces/channel.interface'

interface TypeBlockModal{
  showModal: boolean
  handleCloseModal?: ()=>void
  channelData: TypedChannel
}

const BlockWebOnMobileModal = ({showModal,handleCloseModal, channelData}: TypeBlockModal) =>{
  const [appDownloadLink, setAppDownloadLink] = useState('')
  useEffect(() => {
    if (!channelData) return
    const userAgent = window.navigator.userAgent.toLowerCase()

    const linkAppIos = channelData?.ios_link
      ? process.env.REACT_APP_DOWNLOAD_IOS
      : process.env.REACT_APP_DOWNLOAD_IOS
    const linkAppAndroid = channelData?.android_link
      ? process.env.REACT_APP_DOWNLOAD_ANDROID
      : process.env.REACT_APP_DOWNLOAD_ANDROID

    if (userAgent.includes('iphone')) {
      setAppDownloadLink(linkAppIos)
    } else if (userAgent.includes('android')) {
      setAppDownloadLink(linkAppAndroid)
    } else {
      setAppDownloadLink(linkAppAndroid)
    }
  }, [channelData])

  return(
      <Modal
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        open={showModal}
        // onClose={handleCloseModal}
        sx={{
          textAlign: "center"
        }}
        slotProps={{
          backdrop: {
            sx:{backgroundColor: theme => theme.palette.text.primary}
          },
        }}
      >
        <ModalContainer
          sx={{
            width: '60%',
            maxWidth: theme => theme.spacing(95.75),
            margin: '0 auto'
          }}
        >
          <ModalHeader direction={'row'}>
            <Typography variant={'h6'} className={'title'}>
              {__('block_modal_title')}
            </Typography>
            {/*<IconButton onClick={handleCloseModal}>*/}
            {/*  <CloseIcon />*/}
            {/*</IconButton>*/}
          </ModalHeader>
          <Divider />
          <ModalBody
            sx={{
              maxHeight: 'calc(100% - 80px)',
              overflowY: 'auto'
            }}
          >
            {__("block_modal_content")}

          </ModalBody>
          <ModalFooter>
            <Button>
              <Link href={appDownloadLink} underline="none" color={'primary.contrastText'}>
                {__("block_modal_btn_down")}
              </Link>
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
  )
}
export default BlockWebOnMobileModal

const BlockWebOnMobileModalContainer = styled(Box)(({})=>({}))
