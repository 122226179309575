//Library
import {useCallback, useEffect, useState} from 'react';
import __ from "languages/index";
import Lottie from 'lottie-react';

//Socket
import {socket} from 'config/socket.io';

//Json
import levelUp1 from 'media/json/level_up1.json'
import pointPlus1 from 'media/json/pointPlus1.json'
import pointPlus2 from 'media/json/pointPlus2.json'
import pointPlus3 from 'media/json/pointPlus3.json'
import pointPlus4 from 'media/json/pointPlus4.json'
import pointPlus5 from 'media/json/pointPlus5.json'
import pointPlus6 from 'media/json/pointPlus6.json'
import pointPlus7 from 'media/json/pointPlus7.json'
import pointPlus8 from 'media/json/pointPlus8.json'
import pointPlus9 from 'media/json/pointPlus9.json'
import pointPlus10 from 'media/json/pointPlus10.json'
import pointPlus11 from 'media/json/pointPlus11.json'
import pointPlus12 from 'media/json/pointPlus12.json'
import pointPlus13 from 'media/json/pointPlus13.json'
import pointPlus14 from 'media/json/pointPlus14.json'
import pointPlus15 from 'media/json/pointPlus15.json'
import pointPlus16 from 'media/json/pointPlus16.json'
import pointPlus17 from 'media/json/pointPlus17.json'
import pointPlus18 from 'media/json/pointPlus18.json'
import pointPlus19 from 'media/json/pointPlus19.json'
import pointPlus20 from 'media/json/pointPlus20.json'
import pointPlus21 from 'media/json/pointPlus21.json'
import pointPlus22 from 'media/json/pointPlus22.json'
import pointPlus23 from 'media/json/pointPlus23.json'
import pointPlus24 from 'media/json/pointPlus24.json'
import pointPlus25 from 'media/json/pointPlus25.json'
import pointPlus26 from 'media/json/pointPlus26.json'
import pointPlus27 from 'media/json/pointPlus27.json'
import pointPlus28 from 'media/json/pointPlus28.json'
import pointPlus29 from 'media/json/pointPlus29.json'
import pointPlus30 from 'media/json/pointPlus30.json'

//Helpers
import helpers from 'helpers'
import { getCurrentUserData } from '../store/user.store.reducer'
import { useAppDispatch } from 'config/store'
import { Typography, styled } from '@mui/material';

export default function NotificationLevel() {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [dataReturn, setDataReturn] = useState(null);
    const dispatch = useAppDispatch()

    const imageMappings = {
      1: pointPlus1,
      2: pointPlus2,
      3: pointPlus3,
      4: pointPlus4,
      5: pointPlus5,
      6: pointPlus6,
      7: pointPlus7,
      8: pointPlus8,
      9: pointPlus9,
      10: pointPlus10,
      11: pointPlus11,
      12: pointPlus12,
      13: pointPlus13,
      14: pointPlus14,
      15: pointPlus15,
      16: pointPlus16,
      17: pointPlus17,
      18: pointPlus18,
      19: pointPlus19,
      20: pointPlus20,
      21: pointPlus21,
      22: pointPlus22,
      23: pointPlus23,
      24: pointPlus24,
      25: pointPlus25,
      26: pointPlus26,
      27: pointPlus27,
      28: pointPlus28,
      29: pointPlus29,
      30: pointPlus30
    }

  const handleViewLottie = useCallback((value: number) => {
    return imageMappings[value] || pointPlus1
  }, [])


    useEffect(() => {
      function onConnect() {
        setIsConnected(true);
        console.log('Socket connect successfully ....',"1");
      }

      function onDisconnect() {
        setIsConnected(false);
        console.log('Socket lost connection ....',"2");
      }

      async function pointToClient(data: any) {
        let _lottieSetting: any = {};
        try {
          if (!helpers.isJson(data)) throw new Error('data_invalid');
          const dataNotification = JSON.parse(data);
          _lottieSetting.lottieAnimation = dataNotification?.is_up_level.toString() === 'false' ? handleViewLottie(Number(dataNotification?.point) - Number(dataNotification?.old_point)) : levelUp1;

          _lottieSetting.is_level_up = dataNotification?.is_up_level;
          _lottieSetting.levelNumber = dataNotification?.level_number;
          _lottieSetting.pointNumber = Number(dataNotification?.point) - Number(dataNotification?.old_point);

          setDataReturn(_lottieSetting);

          setTimeout(() => {
            setDataReturn(null);
          }, 4000);
          dispatch(getCurrentUserData())
        } catch (e) {
          console.log(e, '<<<< E_I987');
        }
      }

      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('pointToClient', pointToClient);

      // setInterval( () => {
      //   let stringData = '{"_id":"6554333d836522a2f0ad5006","user_id":"64bf9677472d41114059c608","channel_id":"65095635ba7583ad822712ce","channel_level":"65139daf7b7543d9836d08b7","permission":"channel/create,channel/delete,channel/update,request/create,request/delete,request/update,category/create,category/update,category/delete,module/create,module/update,module/delete,course/create,course/update,course/delete,comment/craete,comment/update,comment/delete,event/create,event/update,event/delete,user,mentor","old_point":"76","point_month":"93","point_week":"43","point":"78","level_number":"5","channel_role":"mentor","coin_number":"9","number_of_user":"0","official_status":"1","total_like":"0","total_comment":"0","total_view_course":"0","createdAt":"Wed Nov 15 2023 09:55:57 GMT+0700 (Indochina Time)","updatedAt":"Sun Dec 17 2023 19:54:19 GMT+0700 (Indochina Time)","__v":"0","approvedAt":"Wed Nov 15 2023 09:55:57 GMT+0700 (Indochina Time)","is_up_level":"false","image_url":"https://media.whiteg.app/animations/animations+2.json"}'
      //   pointToClient(stringData);
      //   console.log("call nè")
      // }, 6000);

      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('pointToClient', pointToClient);
      };
    }, [socket]);


    return (
      <div className="d-flex">
        {/*{*/}
        {/*    (dataReturn && dataReturn?.lottieAnimation ) && (*/}
        {/*      dataReturn?.is_level_up?.toString() === 'false' ? (*/}
        {/*        <div className="show_animation">*/}
        {/*          <Lottie*/}
        {/*            animationData={dataReturn?.lottieAnimation || ''}*/}
        {/*            height={60}*/}
        {/*            width={60}*/}
        {/*            className="view_animation_level_1"*/}
        {/*            autoPlay*/}
        {/*            loop />*/}
        {/*          <div className="show_animation_text text-success">{__("notification_level_title")} <b>{dataReturn?.pointNumber ||'0'}</b> {__("notification_level_point")}</div>*/}
        {/*        </div>*/}
        {/*      ) : (*/}
        {/*        <div className="show_animation_level_up">*/}
        {/*          <Lottie*/}
        {/*            animationData={dataReturn?.lottieAnimation || ''}*/}
        {/*            className="view_animation_level_1"*/}
        {/*            style={{width: 160,height: 160}}*/}
        {/*            autoPlay*/}
        {/*            loop={false}*/}
        {/*            />*/}
        {/*          <div className="show_animation_text text-success">{__("notification_level_text")} <b>{dataReturn?.levelNumber || '0'}</b>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      )*/}
        {/*    )*/}
        {/*}*/}

        <div className={`show_animation pe-none position-absolute z-3 ${dataReturn && dataReturn?.lottieAnimation && dataReturn?.is_level_up?.toString() == 'false'?"visible":"invisible"}`}>
          <StyledLottie
            animationData={dataReturn?.lottieAnimation || ''}
            height={60}
            width={60}
            className="view_animation_level_1"
            autoPlay
            loop />
          <Typography color='primary.main' className="show_animation_text">{__("notification_level_title")} <b>{dataReturn?.pointNumber ||'0'}</b> {__("notification_level_point")}</Typography>
        </div>

        <div className={`show_animation pe-none show_animation_level_up flex-column position-absolute z-3 ${dataReturn && dataReturn?.lottieAnimation && dataReturn?.is_level_up?.toString() != 'false'?"visible":"invisible"}`}>

          <Lottie
            animationData={dataReturn?.lottieAnimation || ''}
            className="view_animation_level_1"
            style={{width: 160,height: 160}}
            autoPlay
            loop
          />
          <Typography color={'primary.main'} className="show_animation_text">{__("notification_level_text")} <b>{dataReturn?.levelNumber || '0'}</b>
          </Typography>
        </div>
      </div>

    )
}

const StyledLottie = styled(Lottie)(({ theme }) => ({
  'svg':{
    '& path' :{
      fill: theme.palette.primary.main,
      stroke: ''
    }
  }
}))
