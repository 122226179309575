import Footer from './footer'
import contactSVG from 'media/images/contact.svg'
import __ from 'languages/index'
import _Helmet from 'components/helmet'
import HeaderComponent from "../layouts/Header";
import React from "react";

export default function Contact() {

  return (
    <div id="single_page">
      <_Helmet title='contact_title' />
      <HeaderComponent />

      <header className="py-5 border-bottom" id="page_hero">
        <div className="container bd-gutter pt-md-1 pb-md-4 d-flex align-items-center">
          <div className="row my-5">
            <div className="col-xl-10">
              <h1>{__('contact_sub_title')}</h1>
              <p className="lead mb-0"></p>
            </div>
          </div>
        </div>
      </header>

      <main className="py-5">
        <div className="container bd-gutter pt-md-1 pb-md-4">
          <div className="row g-4">
            <div className="col-md-6 col-xl-4">
              <div className="card card-body shadow text-center align-items-center h-100">
                <div className="icon-lg bg-info bg-opacity-10 text-info rounded-circle mb-2">
                  <i className="bi bi-headset fs-5"></i>
                </div>
                <h5>{__('contact_call_us')}</h5>
                <p>{__('contact_call_us_text')}</p>
                <div className="d-grid gap-3 d-sm-block">
                  <button className="btn btn-sm btn-primary-soft">
                    <i className="bi bi-phone me-2"></i>
                    {__('contact_phone_number1')}
                  </button>
                  <button className="btn btn-sm btn-light">
                    <i className="bi bi-telephone me-2"></i>
                    {__('contact_phone_number2')}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-4">
              <div className="card card-body shadow text-center align-items-center h-100">
                <div className="icon-lg bg-danger bg-opacity-10 text-danger rounded-circle mb-2">
                  <i className="bi bi-inboxes-fill fs-5"></i>
                </div>
                <h5>{__('contact_email_us')}</h5>
                <p>{__('contact_email_us_text')}</p>
                <a
                  href="mailto:support@appuni.io"
                  className="btn btn-link text-decoration-underline p-0 mb-0"
                >
                  <i className="bi bi-envelope me-1"></i> support@appuni.io{' '}
                </a>
              </div>
            </div>

            <div className="col-xl-4 position-relative">
              <div className="card card-body shadow text-center align-items-center h-100">
                <div className="icon-lg bg-orange bg-opacity-10 text-orange rounded-circle mb-2">
                  <i className="bi bi-globe2 fs-5"></i>
                </div>
                <h5>{__('contact_sosial')}</h5>
                <p>{__('contact_sosial_text')}</p>
              </div>
            </div>
          </div>

          <section className="pt-5">
            <div className="container">
              <div className="row g-4 g-lg-5 align-items-center">
                <div className="col-lg-6 text-center d-none d-md-block">
                  <img src={contactSVG} alt="" />
                </div>

                <div className="col-lg-6">
                  <div className="card bg-light p-4">
                    <div className="card-header bg-light p-0 pb-3">
                      <h3 className="mb-0">{__('contact_send_message')}</h3>
                    </div>

                    <div className="card-body p-0">
                      <form className="row g-4">
                        <div className="col-md-6">
                          <label className="form-label">{__('contact_name')}</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">{__('contact_email')}</label>
                          <input type="email" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label className="form-label">{__('contact_phone_number')}</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="col-12">
                          <label className="form-label">{__('contact_information')}</label>
                          <textarea className="form-control" rows={3}></textarea>
                        </div>
                        <div className="col-12">
                          <button className="btn btn-dark mb-0" type="button">
                            Gửi
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <iframe
                    className="w-100 h-300px grayscale rounded"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.2240060637!2d105.79204201319764!3d20.98365592001832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ad316d6240b9%3A0x83edbb09d1e8fa0f!2zQ8O0bmcgdHkgQ-G7lSBwaOG6p24gSOG7jWMgdmnhu4duIMSQw6BvIHThuqFvIFF14buRYyB04bq_IFRBS0k!5e0!3m2!1svi!2s!4v1692954418879!5m2!1svi!2s"
                    height="500"
                    style={{ border: '0' }}
                    aria-hidden="false"
                    tabIndex={0}
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  )
}
