//Library
import { Box, IconButton, InputBase, styled, Typography } from '@mui/material'
import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react'
import __ from 'languages/index'
import { useNavigate } from 'react-router-dom'

//Components
import ItemInbox from 'entities/chat/components/chat.inbox.item'

//Icons

//Hooks
import { useToast } from 'hooks/useToast'
import { useClickOutside } from 'hooks/useClickOutside'

//Stores
import { viewMessageOfClient } from 'entities/chat/store/chat.store.reducer'
import { clearDaTaChatRoom } from 'entities/mentor/store/mentor.store.reducer'

//Interfaces
import { TypedChatRoom } from 'entities/chat/chat.interface'
import { MessageIcon, SearchIcon } from 'icons'

//Helpers
import helpers from 'helpers'

//Config
import { socket } from 'config/socket.io'
import { useAppDispatch, useAppSelector } from 'config/store'
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { InfiniteScrollContainer } from "components/InfiniteScrollContainer";
import { getListChat } from 'services/chat.service'

const IframeChat = lazy(() => import('layout/iframeChat'))


const MessageComponent = ({ widthCurrent }: { widthCurrent: number }) => {
  const wrapperRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const toast = useToast()
  const user_data = useAppSelector(state => state.user.user_data)
  const dataChatroomCreateByMentor = useAppSelector(state => state?.mentor?.dataChatroom)

  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number>(0)
  const [textSearch, setTextSearch] = useState<string>('')
  const [listDataMessage, setListDataMessage] = useState<TypedChatRoom[]>([])
  const [totalListDataMessage, setTotalListDataMessage] = useState<number>(0)
  const [isShowModalMessage, setIsShowModalMessage] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [showModal, setShowModal] = useState(true)
  const [itemChat, setItemChat] = useState({})
  const [idChat, setIdChat] = useState({ user_avatar: '', user_avatar_thumbnail: '' })


  const {
    hasMore,
    isRefreshing,
    loadMore,
    updateParamRequest,
    updateParamRequestDebounce,
    refresh,
    data,
    updateItem
  } = useInfiniteScroll<TypedChatRoom>({
    limit: 12,
    search: '',
    read_count: ''
  }, getListChat, true)

  useEffect(() => {
    setNumberOfUnreadMessages(data.filter((item) => item.read_count == 1).length)
  }, [data]);

  useClickOutside(wrapperRef, setIsShowModalMessage)

  const handleChangeTab = (index: number) => {
    setTabIndex(index)
    let statusMessage = ''
    switch (index) {
      case 0:
        statusMessage = ''
        break
      case 1:
        statusMessage = 'read'
        break
      case 2:
        statusMessage = 'unread'
        break

      default:
        break
    }
    updateParamRequest({
      read_count: statusMessage
    })
    setListDataMessage([])
    setTotalListDataMessage(0)
  }

  const handleClickMessage = useCallback(() => {
    if (widthCurrent > 1024) {
      setIsShowModalMessage(!isShowModalMessage)

    } else {
      navigate('/chat')
    }
  }, [isShowModalMessage])


  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value)
    updateParamRequestDebounce({
      search: e.target.value
    })
  }


  const handleOpenChatDetail = useCallback((idChatRoom: string) => {
    navigate(`/chat/detail/${idChatRoom}`)
  }, [])

  const showChatIframe = (itemChat: any) => {
    if (itemChat?.read_count == 1) {
      updateItem({ ...itemChat, read_count: 0 })
      dispatch(viewMessageOfClient(itemChat?.chat_room_id?._id))
    }

    if (helpers.isMobile()) {
      handleOpenChatDetail(itemChat?.chat_room_id?._id)
    } else {
      setShowModal(true)
      const formData = {
        _id: itemChat?.chat_room_id?._id,
        room_title: itemChat?.room_title,
        room_image: itemChat?.room_image,
        last_message: itemChat?.chat_room_id?.last_message,
        updatedAt: itemChat?.updatedAt,
        user_avatar: itemChat?.partner_id?.user_avatar,
        user_avatar_thumbnail: itemChat?.partner_id?.user_avatar_thumbnail
      }
      setItemChat({...formData})
      let newData = { ...formData, showModal: showModal }
      localStorage.setItem('data_chat', JSON.stringify(newData))
      setIdChat(itemChat?.chat_room_id?._id)
    }
    setIsShowModalMessage(false)
  }

  /**
   * Logic from layout/header.tsx
   */
  useEffect(() => {
    let dataChatNew = { _id: null, user_avatar: '', user_avatar_thumbnail: '', showModal: true }
    try {
      const localChat = localStorage.getItem('data_chat') || ''
      if (!helpers.isJson(localChat)) return
      dataChatNew = JSON.parse(localChat)
    } catch (e) {
      return undefined
    }
    if (dataChatNew && dataChatNew?._id) {
      let data = dataChatNew?._id || ''
      setIdChat(data)
      setItemChat(dataChatNew)
      setShowModal(dataChatNew?.showModal)
    }
  }, [])

  useEffect(() => {
    if (helpers.isEmpty(dataChatroomCreateByMentor)) return
    showChatIframe({
      _id: dataChatroomCreateByMentor?.chat_room_id?._id,
      room_title: dataChatroomCreateByMentor?.room_title,
      room_image: dataChatroomCreateByMentor?.room_image,
      last_message: dataChatroomCreateByMentor?.chat_room_id?.last_message,
      updatedAt: dataChatroomCreateByMentor?.updatedAt,
      user_avatar: dataChatroomCreateByMentor?.partner_id?.user_avatar,
      user_avatar_thumbnail: dataChatroomCreateByMentor?.partner_id?.user_avatar_thumbnail
    })

    dispatch(clearDaTaChatRoom())
  }, [dataChatroomCreateByMentor])

  useEffect(() => {
    if (!isShowModalMessage) return
    refresh(true)
  }, [isShowModalMessage])

  useEffect(() => {
    try {
      socket.on('msgToUser', data => {
        if (!helpers.isJson(data)) return
        let dataParse = JSON.parse(data)
        if (user_data?._id !== dataParse?.createBy?._id) {
          setNumberOfUnreadMessages(numberOfUnreadMessages => numberOfUnreadMessages + 1)
        }
      })
    } catch (error) {
      console.log(`msgToUser_${error}`)
    }
  }, [socket])

  return (
    <MessageContainer id="messageContainer" ref={wrapperRef}>
      <IconButton className={"messageContainer_icon_item"} onClick={handleClickMessage}>
        <MessageIcon color="inherit" />
        {numberOfUnreadMessages > 0 && <Box className="messageContainer_unread"></Box>}
      </IconButton>
      {isShowModalMessage && (
        <Box className="messageContainer_modal">
          <Typography
            textTransform='none'
            component="h6"
            variant="h6"
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {__('header_chat')}
          </Typography>
          <Box className="messageContainer_modal_input">
            <IconButton>
              <SearchIcon />
            </IconButton>
            <InputBase
              value={textSearch || ''}
              onChange={handleChangeValue}
              placeholder={__('chat_search_in_messages')}
              className="messageContainer_modal_input_search"
            />
          </Box>
          <Box className="chatDetail_list_message_button">
            <Typography
              role="button"
              onClick={() => handleChangeTab(0)}
              component={'button'}
              className={`${tabIndex === 0 && 'chatDetail_active'}`}
            >
              {__('chat_all')}
            </Typography>
            <Typography
              role={'button'}
              onClick={() => handleChangeTab(1)}
              component={'button'}
              className={`${tabIndex === 1 && 'chatDetail_active'}`}
            >
              {__('chat_read')}
            </Typography>
            <Typography
              role={'button'}
              onClick={() => handleChangeTab(2)}
              component={'button'}
              className={`${tabIndex === 2 && 'chatDetail_active'}`}
            >
              {__('chat_unread')}
            </Typography>
          </Box>


          <InfiniteScrollContainer
            isRefreshing={isRefreshing}
            dataLength={data.length}
            next={loadMore}
            hasMore={hasMore}
            refreshFunction={refresh}
          >
            <Box className="chatDetail_list_message_content">
              {data.map((item: TypedChatRoom, idx: number) => (
                <Box key={`itemInbox_${idx}`} onClick={() => showChatIframe(item)}>
                  <ItemInbox dataChatRoom={item} />
                </Box>
              ))}
            </Box>
          </InfiniteScrollContainer>
        </Box>
      )}
      <div className="position-fixed" style={{ bottom: 0, right: 80, zIndex: 999 }}>
        <Suspense fallback={<></>}>
          <IframeChat
            idChat={idChat}
            itemChat={itemChat}
            setIdChat={setIdChat}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </Suspense>
      </div>
    </MessageContainer>
  )
}

export default MessageComponent

const MessageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .messageContainer_icon_item': {
    display: 'grid',
    border: `1px solid ${theme.palette.divider}`,
    placeItems: 'center',
    padding: theme.spacing(0.75),
    borderRadius: theme.spacing(1.5),
    position: 'relative',
    cursor: 'pointer',
    color: theme.palette.text.primary
  },
  '& .messageContainer_unread': {
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    background: theme.palette.primary.main,
    borderRadius: '50%',
    position: 'absolute',
    top: '-2px',
    right: '-2px'
  },
  '& .chatDetail_active': {
    background: `${theme.palette.primary.backgroundHover} !important`, 
    color: `${theme.palette.primary.main} !important`
  },
  '& .messageContainer_modal': {
    position: 'absolute',
    width: theme.spacing(56.25),
    height: 'auto',
    maxHeight: theme.spacing(56.25),
    overflow: 'auto',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1.5),
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(5)} 0 rgba(14, 20, 38, 0.08)`,
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
    right: 0,
    top: theme.spacing(8.75),
    zIndex: 1
  },
  '& .messageContainer_modal_input': {
    display: 'flex',
    background: theme.palette.background.default,
    borderRadius: theme.spacing(1.5),
    '& .messageContainer_modal_input_search': {
      width: '100%',
      '& ::placeholder': {
        color: theme.palette.text.secondary,
        fontSize: theme.spacing(2),
        fontWeight: 400,
        lineHeight: '133%'
      }
    }
  },
  '& .messageContainer_modal_empty': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: theme.spacing(18.75),
      height: theme.spacing(18.75)
    }
  },
  '@media(max-width: 1024px)': {
    '& .messageContainer_icon_item': {
      border: 'none'
    }
  }
}))
