import { SvgIcon, SvgIconProps } from '@mui/material'

const FollowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9.57996 0.833008C6.68829 0.833008 4.31329 3.20801 4.31329 6.09967C4.31329 7.87467 5.20496 9.44134 6.55496 10.3913C3.41329 11.658 1.17996 14.733 1.17996 18.3163C1.15496 19.4497 2.86329 19.4497 2.83829 18.3163C2.83829 14.508 5.90496 11.4413 9.71329 11.4413C11.0466 11.4413 12.28 11.8163 13.33 12.4663C14.2716 13.0413 15.1466 11.633 14.1966 11.0497C13.7216 10.758 13.2133 10.533 12.68 10.333C13.98 9.37467 14.8383 7.83301 14.8383 6.09967C14.8383 3.19967 12.4716 0.833008 9.57996 0.833008ZM9.57996 2.49134C11.58 2.49134 13.18 4.09967 13.18 6.09967C13.18 8.09967 11.58 9.69967 9.57996 9.69967C7.57996 9.69967 5.97163 8.09967 5.97163 6.09967C5.97163 4.09967 7.57996 2.49134 9.57996 2.49134ZM14.6716 16.608V18.2663C14.6966 19.3497 16.305 19.3497 16.33 18.2663V16.608H17.9883C19.0966 16.608 19.0966 14.9497 17.9883 14.9497H16.33V13.2913C16.33 12.8247 15.955 12.4497 15.4883 12.4497C15.03 12.4497 14.6633 12.833 14.6633 13.2913V14.9497H12.9633C11.8133 14.9997 11.8966 16.6663 13.005 16.608H14.6633H14.6716Z" fill="#DC3545" />
      </svg>
    </SvgIcon>
  )
}

export default FollowIcon
