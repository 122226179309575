//Library
import {memo, useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { Modal} from 'react-bootstrap'

//Component
import ComponentEmpty from 'components/componentEmpty'

//Image
import defaultImage from 'media/images/image_default.png'
import iconDiamond from 'media/icons/icon_diamond.svg'

//Store
import {useAppDispatch} from 'config/store'
import {getListGift} from '../store/mission.store.reduce'

//Interface
import {TypeGiftData} from '../interface'

//Helper
import helpers from 'helpers'

//Scss
import '../media/mission.view.gift.scss'
import __ from "languages/index";
import { Button, Typography } from '@mui/material'

const ViewGiftMission = ({ show, onClose }: { show?: boolean; onClose?: () => void }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listGift, setListGift] = useState<TypeGiftData[]>([])

  const handleNavigate = useCallback(() => {
    navigate('/gift', { state: { option_type: 'coin' } })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getListGift({ gift_type: 'gift', coin: 1 }))
      .unwrap()
      .then(res => {
        setIsLoading(false)
        res?.data?.map((item: TypeGiftData) => {
          if (listGift?.every((data: TypeGiftData) => data?._id !== item?._id)) {
            setListGift(listGift => [...listGift, item])
          }
        })
      })
      .catch(error => {
        console.log(`getListGift_${error}`)
        setIsLoading(false)
        setListGift([])
      })
  }, [])

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{__("mission_list_gift")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="viewGiftMission_wrapper">
            {isLoading ? (
              <div className="bg-white p-4 rounded mt-4">
                <p
                  className="card-text placeholder-glow mb-4 p-4 rounded"
                  style={{ backgroundColor: '#f8f9fa' }}
                >
                  <span className="placeholder col-7"></span>
                  <span className="placeholder col-4"></span>
                  <span className="placeholder col-4"></span>
                  <span className="placeholder col-6"></span>
                  <span className="placeholder col-8"></span>
                </p>
              </div>
            ) : (
              <>
                {helpers.isEmpty(listGift) ? (
                    <ComponentEmpty />
                  ) : (
                    <>
                      {listGift?.map((item?: TypeGiftData, idx?: number) => (
                          <div key={`viewGift_${idx}`} className="viewGiftMission_item">
                            <img
                              src={item?.media_id?.media_url || defaultImage}
                              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                e.currentTarget.src = defaultImage
                              }}
                              className="viewGiftMission_item_img"
                              width={80}
                              height={80}
                              alt="image/gift"
                              loading="lazy"
                            />
                            <div className="viewGiftMission_item_right">
                              <span className="viewGiftMission_item_right_name" title={item?.name}>
                                {item?.name || ''}
                              </span>
                              <div>
                                <img
                                  src={iconDiamond}
                                  width={20}
                                  height={20}
                                  alt="icon/diamond"
                                  loading="lazy"
                                />
                                <span>
                                  {helpers.formatNumberWithCommas(item?.gift_conditions?.coin || '')}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )
                }
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='text' onClick={onClose}>
            <Typography textTransform={'none'}>
              {__("btn_close")}
            </Typography>
          </Button>
          <Button type="submit" onClick={handleNavigate}>
            <Typography textTransform={'none'}>
              {__("mission_list_gift_btn")}
            </Typography>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default memo(ViewGiftMission)
