import { SvgIcon, SvgIconProps } from '@mui/material'

const SaleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="40" viewBox="0 0 56 40" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M47.8313 39.9296H31.5595C31.297 39.9296 31.1002 39.8311 30.9361 39.6671L28.6069 37.3379L26.3105 39.6671C26.1465 39.8311 25.9168 39.9296 25.6872 39.9296H11.5149C11.3181 39.9296 11.1541 39.864 11.0228 39.7655L8.56239 38.1908L6.10193 39.7655C5.97071 39.864 5.80668 39.9296 5.64265 39.9296H2.42765C1.0826 39.9296 0 38.847 0 37.5019V31.5968C0 31.1047 0.393673 30.7111 0.885764 30.7111C3.83831 30.7111 6.23316 28.3162 6.23316 25.3637C6.23316 22.4111 3.83831 20.0163 0.885764 20.0163C0.393673 20.0163 0 19.6226 0 19.1633V13.2254C0 11.9132 1.0826 10.8306 2.42765 10.8306H9.80902C10.0059 10.8306 10.1699 10.8634 10.3011 10.9618L12.9912 12.7661L15.6813 10.9618C15.8453 10.8634 16.0094 10.8306 16.1734 10.8306H36.1851C36.4148 10.8306 36.6444 10.929 36.8084 11.093L39.3673 13.6519L41.9262 11.093C42.0902 10.929 42.3199 10.8306 42.5495 10.8306H47.8313C49.1763 10.8306 50.2589 11.9132 50.2589 13.2254V37.5019C50.2589 38.847 49.1763 39.9296 47.8313 39.9296Z" fill="#EF5350" />
        <path fillRule="evenodd" clipRule="evenodd" d="M42.5459 10.8307H47.8277C49.1727 10.8307 50.2553 11.9133 50.2553 13.2255V26.2167L55.4715 24.9373C55.6355 24.9045 55.7996 24.8061 55.898 24.642C55.9964 24.5108 55.9964 24.314 55.9636 24.1499L50.2225 0.562351C50.1241 0.23429 49.7632 0.00464736 49.4024 0.103066L44.6127 1.25128L42.4475 4.79434C42.2178 5.22082 41.6601 5.35204 41.2665 5.08959L37.7234 2.9572L18.9255 7.55005L16.4979 10.1745C16.2682 10.437 15.9073 10.5354 15.5465 10.4042L12.2003 9.19035L5.50781 10.8307H9.80541C10.0022 10.8307 10.1663 10.8635 10.2975 10.9619L12.9876 12.7662L15.6777 10.9619C15.8417 10.8635 16.0058 10.8307 16.1698 10.8307H36.1815C36.4112 10.8307 36.6408 10.9291 36.8048 11.0931L39.3637 13.652L41.9226 11.0931C42.0866 10.9291 42.3162 10.8307 42.5459 10.8307Z" fill="#AF3D3B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.092 39.9296V10.8306H16.1734C16.0094 10.8306 15.8453 10.8634 15.6813 10.9618L12.9912 12.7661L10.3011 10.9618C10.1699 10.8634 10.0059 10.8306 9.80902 10.8306H5.51142H2.42765C1.0826 10.8306 0 11.9132 0 13.2254V19.1633C0 19.6226 0.393673 20.0163 0.885764 20.0163C3.83831 20.0163 6.23316 22.4111 6.23316 25.3637C6.23316 28.3162 3.83831 30.7111 0.885764 30.7111C0.393673 30.7111 0 31.1047 0 31.5968V37.5019C0 38.847 1.0826 39.9296 2.42765 39.9296H5.64265C5.80668 39.9296 5.97071 39.864 6.10193 39.7655L8.56239 38.1908L11.0228 39.7655C11.1541 39.864 11.3181 39.9296 11.5149 39.9296H17.092Z" fill="#C24441" />
      </svg>
    </SvgIcon>
  )
}

export default SaleIcon
