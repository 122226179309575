import Capacity from "components/Capacity"
import React, { useCallback } from "react"
import Cookbook from "./component/Cookbook"
import {useNavigate} from "react-router-dom"
import _Helmet from "components/helmet"
import BigLayout from "../../layouts/bigLayout";

const ChallengeCookbook = (props) => {

  return (
    <>
      <_Helmet title='Cookbook' />
      <div className="mainFrame">
        <div className="container">
          <Cookbook />
        </div>
      </div>
    </>
  )
}

export default ChallengeCookbook
