import React from 'react'
import { Paper, Typography } from '@mui/material'

type Props = {
  children: React.ReactNode
  label: string
}
const ProductDetailContainer = ({ children, label }: Props) => {
  return (
    <Paper
      sx={{
        padding: 4
      }}
    >
      <Typography variant="h5" mb={3}>
        {label}
      </Typography>
      {children}
    </Paper>
  )
}

export default ProductDetailContainer
