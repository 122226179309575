import { SvgIcon, SvgIconProps } from '@mui/material'

const EmptyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="151"
        viewBox="0 0 150 151"
        fill="currentColor"
      >
        <g clipPath="url(#clip0_2189_51395)">
          <path
            d="M83.3598 138.483C116.561 138.483 143.478 111.566 143.478 78.2454C143.478 44.9253 116.443 18.0083 83.3598 18.0083C50.1582 18.0083 23.2412 44.9253 23.2412 78.2454C23.2412 111.566 50.1582 138.483 83.3598 138.483Z"
            fill="#EAEEF9"
          />
          <path
            d="M139.565 38.7595C142.25 38.7595 144.427 36.5828 144.427 33.8978C144.427 31.2128 142.25 29.0361 139.565 29.0361C136.88 29.0361 134.704 31.2128 134.704 33.8978C134.704 36.5828 136.88 38.7595 139.565 38.7595Z"
            fill="#EAEEF9"
          />
          <path
            d="M146.68 19.7868C148.513 19.7868 150 18.3003 150 16.4666C150 14.633 148.513 13.1465 146.68 13.1465C144.846 13.1465 143.359 14.633 143.359 16.4666C143.359 18.3003 144.846 19.7868 146.68 19.7868Z"
            fill="#EAEEF9"
          />
          <path
            d="M26.4427 38.6408C28.2764 38.6408 29.7629 37.1543 29.7629 35.3206C29.7629 33.487 28.2764 32.0005 26.4427 32.0005C24.609 32.0005 23.1226 33.487 23.1226 35.3206C23.1226 37.1543 24.609 38.6408 26.4427 38.6408Z"
            fill="#EAEEF9"
          />
          <path
            d="M6.16601 102.672C9.5714 102.672 12.332 99.9117 12.332 96.5063C12.332 93.101 9.5714 90.3403 6.16601 90.3403C2.76061 90.3403 0 93.101 0 96.5063C0 99.9117 2.76061 102.672 6.16601 102.672Z"
            fill="#EAEEF9"
          />
          <g filter="url(#filter0_d_2189_51395)">
            <path
              d="M124.269 48.1268L125.217 124.49C125.217 127.099 123.083 129.115 120.474 129.115H46.0077C43.399 129.115 41.2646 126.981 41.2646 124.49V26.3086C41.2646 23.6999 43.399 21.6841 46.0077 21.6841H99.9603L124.269 48.1268Z"
              fill="url(#paint0_linear_2189_51395)"
            />
          </g>
          <path
            d="M77.5494 113.819H74.8221C74.2292 113.819 73.7549 113.226 73.7549 112.514C73.7549 111.803 74.2292 111.21 74.8221 111.21H77.5494C78.1422 111.21 78.6165 111.803 78.6165 112.514C78.6165 113.344 78.1422 113.819 77.5494 113.819Z"
            fill="#CED7E2"
          />
          <path
            d="M69.249 113.819H53.3597C52.7668 113.819 52.2925 113.226 52.2925 112.514C52.2925 111.803 52.7668 111.21 53.3597 111.21H69.249C69.8419 111.21 70.3162 111.803 70.3162 112.514C70.3162 113.344 69.8419 113.819 69.249 113.819Z"
            fill="#D5DDEA"
          />
          <path
            d="M89.5257 37.6919H53.834C53.0039 37.6919 52.2925 36.9804 52.2925 36.1504C52.2925 35.3203 53.0039 34.6089 53.834 34.6089H89.5257C90.3557 34.6089 91.0672 35.3203 91.0672 36.1504C91.0672 36.9804 90.3557 37.6919 89.5257 37.6919Z"
            fill="#D5DDEA"
          />
          <path
            d="M67.5889 46.2295H53.834C53.0039 46.2295 52.2925 45.518 52.2925 44.688C52.2925 43.8579 53.0039 43.1465 53.834 43.1465H67.4703C68.3004 43.1465 69.0118 43.8579 69.0118 44.688C69.0118 45.518 68.3004 46.2295 67.5889 46.2295Z"
            fill="#D5DDEA"
          />
          <path
            d="M99.9604 21.6841V42.7908C99.9604 45.7552 102.569 48.1268 105.534 48.1268H124.269"
            fill="#D5DDEA"
          />
          <path
            d="M129.961 130.893C128.775 130.893 127.589 130.419 126.64 129.352L106.838 109.55L106.127 110.024C99.7234 114.767 92.2531 117.257 84.6641 117.257C75.5337 117.257 66.4033 113.581 59.6444 107.178C52.5297 100.419 48.6167 91.2887 48.6167 81.3283C48.6167 61.5259 64.7432 45.3994 84.5456 45.3994C98.0633 45.3994 109.921 52.514 116.324 64.6089C122.609 76.5852 121.779 90.5773 114.071 101.842L113.597 102.554L133.518 122.474C135.534 124.49 135.059 126.506 134.704 127.573C133.755 129.471 131.858 130.893 129.961 130.893ZM84.5456 55.2413C70.0792 55.2413 58.4586 66.9804 58.4586 81.3283C58.4586 97.6919 71.8578 107.534 84.9013 107.534C92.8459 107.534 100.079 103.976 105.178 97.5733C111.463 89.7472 112.53 79.1939 108.142 70.0634C103.636 60.933 94.6246 55.2413 84.5456 55.2413Z"
            fill="#989FB0"
          />
          <path
            d="M73.2804 85.597C74.8219 85.597 76.1263 84.2926 76.1263 82.7511C76.1263 81.2096 74.8219 79.9053 73.2804 79.9053C71.7389 79.9053 70.4346 81.2096 70.4346 82.7511C70.4346 84.2926 71.7389 85.597 73.2804 85.597Z"
            fill="#989FB0"
          />
          <path
            d="M95.0988 85.597C96.6403 85.597 97.9446 84.2926 97.9446 82.7511C97.9446 81.2096 96.6403 79.9053 95.0988 79.9053C93.5573 79.9053 92.2529 81.2096 92.2529 82.7511C92.2529 84.4112 93.5573 85.597 95.0988 85.597Z"
            fill="#989FB0"
          />
          <path
            d="M73.1693 73.3362L66.9092 76.7163L67.7542 78.2813L74.0144 74.9012L73.1693 73.3362Z"
            fill="#989FB0"
          />
          <path
            d="M94.7116 73.2907L93.8667 74.856L100.128 78.2354L100.972 76.6702L94.7116 73.2907Z"
            fill="#989FB0"
          />
          <path
            d="M84.1898 92.2376C86.0235 92.2376 87.51 91.1227 87.51 89.7474C87.51 88.3722 86.0235 87.2573 84.1898 87.2573C82.3561 87.2573 80.8696 88.3722 80.8696 89.7474C80.8696 91.1227 82.3561 92.2376 84.1898 92.2376Z"
            fill="#989FB0"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2189_51395"
            x="19.2646"
            y="10.6841"
            width="127.953"
            height="151.431"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="11" />
            <feGaussianBlur stdDeviation="11" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2189_51395"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2189_51395"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2189_51395"
            x1="83.2136"
            y1="19.1991"
            x2="83.2136"
            y2="130.273"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDFEFF" />
            <stop offset="0.9964" stopColor="#ECF0F5" />
          </linearGradient>
          <clipPath id="clip0_2189_51395">
            <rect width="150" height="150" fill="white" transform="translate(0 0.814453)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default EmptyIcon
