import {useParams} from "react-router-dom";
import Theme404 from "../../layout/404";
import ForgotPasswordPage from "entities/forgotPassword/forgot.password.page";
import ToastComponent from "entities/courses/components/toast.component";
import {createRef} from "react";
import PasswordValidateCodePage from "entities/forgotPassword/password.validateCode.page";
import ChangePassword from "entities/forgotPassword/forgot.password.changePassword";


export default function ForgotPassword() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'forgot-password';

  let ActualPage: any;
  switch (Param) {
    case 'forgot-password':
      ActualPage = ForgotPasswordPage;
      break;
    case 'verify':
      ActualPage = PasswordValidateCodePage;
      break;
    case 'change-password':
      ActualPage = ChangePassword;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return (
      <div>
        {<ActualPage />}
      </div>
  );
}
