export function getTimeFromSeconds(seconds: number) {
  if (seconds <= 60) {
    return { hours: 0, minutes: 0, seconds }
  } else {
    const remainingSeconds = seconds % 60
    const minutesInHours = Math.floor(seconds / 60)
    if (minutesInHours > 60) {
      const result = getTimeFromSeconds(minutesInHours)
      return {
        hours: result.hours + 1,
        minutes: result.minutes,
        seconds: remainingSeconds
      }
    } else {
      return {
        hours: 0,
        minutes: minutesInHours,
        seconds: remainingSeconds
      }
    }
  }
}
