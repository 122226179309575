import React from 'react'
import { useParams } from 'react-router-dom'

import Theme404 from '../../layout/404'
import DetailPayment from './payment.detail'

export default function Payment() {
  const useParam = useParams()
  const Param = useParam.slug || 'payment'
  let ActualPage: ({ includeHeader }: { includeHeader?: never }) => React.JSX.Element

  switch (Param) {
    case 'payment':
      ActualPage = DetailPayment
      break
    default:
      ActualPage = Theme404
      break
  }
  return (
    <div className="">
      <ActualPage />
    </div>
  )
}
