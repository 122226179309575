import { SvgIcon, SvgIconProps } from '@mui/material'

const EventIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M18.3606 21.633C17.7906 21.177 16.9622 21.177 16.3998 21.633L0.759033 34.325C1.03263 34.5682 1.38983 34.7202 1.79263 34.7202H32.9602C33.363 34.7202 33.7202 34.5682 33.9938 34.325L18.3606 21.633Z"
            fill="url(#paint0_linear_1101_5177)"
          />
          <path
            id="Vector_2"
            d="M20.0631 23.0162L33.9939 34.3326C34.2903 34.0666 34.4727 33.6942 34.4727 33.2762V13.4554L20.0555 23.0238L20.0631 23.0162ZM0.759073 34.325L14.6899 23.0086L0.280273 13.4478V33.2686C0.280273 33.6866 0.462673 34.059 0.759073 34.325Z"
            fill="url(#paint1_linear_1101_5177)"
          />
          <path
            id="Vector_3"
            d="M4.58947 9.95166V16.3053L0.280273 13.4477L4.58947 9.95166Z"
            fill="url(#paint2_linear_1101_5177)"
          />
          <path
            id="Vector_4"
            d="M30.1711 9.95166V16.3053L34.4803 13.4477L30.1711 9.95166Z"
            fill="url(#paint3_linear_1101_5177)"
          />
          <path
            id="Vector_5"
            d="M14.6979 23.016L16.4003 21.6328C16.9703 21.1692 17.7987 21.1692 18.3611 21.6328L20.0635 23.016L30.1715 16.3052V1.85002C30.1715 1.11282 29.5483 0.52002 28.7807 0.52002H5.97308C5.20548 0.52002 4.58228 1.11282 4.58228 1.85002V16.3052L14.6903 23.016H14.6979Z"
            fill="url(#paint4_linear_1101_5177)"
          />
          <path
            id="Vector_6"
            d="M20.063 21.7088L18.3606 20.3256C17.7906 19.862 16.9622 19.862 16.3998 20.3256L14.6974 21.7088L4.58936 14.998V16.3052L14.6974 23.016L16.3998 21.6328C16.9698 21.1768 17.7982 21.1768 18.3606 21.6328L20.063 23.016L30.171 16.3052V14.998L20.063 21.7088Z"
            fill="url(#paint5_linear_1101_5177)"
          />
          <path
            id="Vector_7"
            d="M18.0793 5.88582L19.4397 8.22662C19.5537 8.42422 19.7437 8.56102 19.9717 8.60662L22.6317 9.16902C23.2397 9.29822 23.4753 10.0278 23.0649 10.4838L21.2409 12.4902C21.0889 12.6574 21.0129 12.8854 21.0357 13.1058L21.3245 15.7886C21.3929 16.3966 20.7621 16.8526 20.1997 16.6018L17.7145 15.4998C17.5093 15.4086 17.2661 15.4086 17.0609 15.4998L14.5757 16.6018C14.0133 16.8526 13.3825 16.4042 13.4509 15.7886L13.7397 13.1058C13.7625 12.8778 13.6865 12.6574 13.5345 12.4902L11.7105 10.4838C11.3001 10.0278 11.5357 9.29822 12.1437 9.16902L14.8037 8.60662C15.0241 8.56102 15.2217 8.42422 15.3357 8.22662L16.6961 5.88582C17.0077 5.35382 17.7753 5.35382 18.0869 5.88582H18.0793Z"
            fill="url(#paint6_linear_1101_5177)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1101_5177"
            x1="17.3802"
            y1="34.8038"
            x2="17.3802"
            y2="13.4478"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9900" />
            <stop offset="1" stopColor="#FFDC70" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1101_5177"
            x1="17.3803"
            y1="13.2274"
            x2="17.3803"
            y2="34.3934"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1101_5177"
            x1="2.43107"
            y1="0.770858"
            x2="2.43107"
            y2="14.9753"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1101_5177"
            x1="32.3295"
            y1="0.770858"
            x2="32.3295"
            y2="14.9753"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FEE45A" />
            <stop offset="1" stopColor="#FEA613" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1101_5177"
            x1="17.3807"
            y1="0.37562"
            x2="17.3807"
            y2="19.8696"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFF0C0" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1101_5177"
            x1="17.3802"
            y1="23.1452"
            x2="17.3802"
            y2="10.5672"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFF0C0" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1101_5177"
            x1="17.3801"
            y1="6.00742"
            x2="17.3801"
            y2="16.6854"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default EventIcon
