//Library
import {Avatar, Box, Button, styled, Typography} from '@mui/material'
import axios from 'axios'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import __ from 'languages/index'

//Hooks
import {useClickOutside} from 'hooks/useClickOutside'

//Image
import imageDefault from 'media/images/image_default.png'

//Icons
import {ChevronIcon} from 'icons'

//helpers
import helpers from 'helpers'
import ComponentEmpty from 'components/componentEmpty'
import {useAppSelector} from "config/store";

const ChannelListComponent = () => {
  const wrapperRef = useRef(null)
  const channelTotalRawData = useRef(null)
  const currentChannelId = useAppSelector(state => state.user.channel_data?._id)
  const [isShowModalChangeChannel, setIsShowModalChangeChannel] = useState<boolean>(false)
  const [isShowButtonChangeChannel, setIsShowButtonChangeChannel] = useState<boolean>(false)
  const [channelListData, setChannelListData] = useState<any>([])

  useClickOutside(wrapperRef, setIsShowModalChangeChannel)

  const handleShowModalChangeChannel = useCallback(() => {
    setIsShowModalChangeChannel(!isShowModalChangeChannel)
  }, [isShowModalChangeChannel])

  const loadChannelData = useCallback(async () => {
    try {
      let internalData = []
      let _data: any = await axios.get(`/channel`)
      channelTotalRawData.current = _data.data
      for (let channelData of _data.data) {
        if ((channelData?.domain || channelData?.sub_domain) && channelData?._id !== currentChannelId) {
          internalData.push({
            _id: channelData?._id,
            media_thumbnail: channelData?.avatar?.media_thumbnail,
            name: channelData?.name,
            member_number: channelData?.member_number,
            domain: channelData?.domain || channelData?.sub_domain
          })
        }
      }
      if (internalData?.length < 1) {
        setIsShowButtonChangeChannel(true)
      }
      setChannelListData(internalData)
    } catch (e) {
      console.log(e, 'ERR_87687')
    }
  }, [currentChannelId])

  const handleChangeChannel = useCallback((domain: string) => {
    let mainDomain = domain || ' ';
    if (String(mainDomain).substring(0, 4) !== 'http') {
      mainDomain = 'https://' + mainDomain;
    }

    try {
      let {host} = new URL(mainDomain);
      window.location.href = 'https://' + host;
    } catch (error) {
      // no need to log ...
    }
  }, [])

  useEffect(() => {
    if(currentChannelId){
      loadChannelData()
    }
  }, [currentChannelId])

  return (
    <ChannelListContainer id="channelListComponent" className="change_channel_wrapper">
      {!isShowButtonChangeChannel && (
        <>
          <Box className="border_left"></Box>
          <Button
            onClick={handleShowModalChangeChannel}
            className="change_channel"
            ref={wrapperRef}
            variant="text"
          >
            <Typography textTransform={'none'} noWrap fontWeight={600}>
              {__('header_change_the_channel')}
            </Typography>
            <ChevronIcon color="inherit"/>
            {isShowModalChangeChannel && (
              <>
                {helpers.isEmpty(channelListData) ? (
                  <ComponentEmpty/>
                ) : (
                  <Box className="channel_modal">
                    {channelListData?.map((value, idx) => (
                      <Button
                        key={`channel_${idx}`}
                        onClick={() => handleChangeChannel(value?.domain || value?.sub_domain)}
                        variant="text"
                        className="channel_button"
                      >
                        <Avatar src={value?.media_thumbnail || imageDefault} alt="avatar"/>
                        <Box>
                          <Typography
                            sx={{
                              textTransform: 'none',
                              textAlign: 'start'
                            }}
                            variant="body1"
                            color={'text.primary'}
                            fontWeight={600}
                          >
                            {value?.name || ''}
                          </Typography>
                          <Typography
                            sx={{
                              textTransform: 'none',
                              textAlign: 'start'
                            }}
                            variant="body1"
                            color={'text.secondary'}
                            fontWeight={400}
                          >
                            {`${value?.member_number || '0'} ${__('member')}`}
                          </Typography>
                        </Box>
                      </Button>
                    ))}
                  </Box>
                )}
              </>
            )}
          </Button>
        </>
      )}
    </ChannelListContainer>
  )
}

export default ChannelListComponent

const ChannelListContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  columnGap: theme.spacing(2),
  alignItems: 'center',
  '& .border_left': {
    borderLeft: `1px solid ${theme.palette.divider}`,
    width: '1px',
    height: theme.spacing(3)
  },
  '& .change_channel': {
    position: 'relative',
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 1),
    '& .channel_modal': {
      position: 'absolute',
      width: theme.spacing(50),
      height: 'auto',
      maxHeight: theme.spacing(56.25),
      overflow: 'auto',
      padding: theme.spacing(2),
      background: theme.palette.background.paper,
      borderRadius: theme.spacing(1.5),
      boxShadow: `0px 8px 40px 0px rgba(14, 20, 38, 0.08)`,
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(2),
      left: 0,
      top: theme.spacing(8.75),
      zIndex: 1,
      '& .channel_button': {
        display: 'flex',
        columnGap: theme.spacing(1),
        cursor: 'pointer',
        justifyContent: 'flex-start',
        padding: '0'
      }
    }
  }
}))
