import __helpers from 'helpers/index'
import { Link } from 'react-router-dom'

const NavigateUser = ({ children, _id }) => {
  return (
    <Link to={`/user/detail/${_id}`} className="text-decoration-none">
      {children}
    </Link>
  )
}

export default NavigateUser
