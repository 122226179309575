import React, {useCallback, useEffect, useRef, useState} from 'react'
import '../media/livestream.admin.scss'
import Button from 'react-bootstrap/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCalendarDay,
  faCircleInfo,
  faGear,
  faList,
  faMicrophone,
  faMicrophoneSlash,
  faVolumeHigh,
  faVolumeXmark
} from '@fortawesome/free-solid-svg-icons'
import LivestreamAdminSlider from 'entities/livestream/components/livestream.admin.slider'
import {EnumLiveStreamSource} from 'entities/event/event.interface'
import LivestreamAdminDeviceModal from 'entities/livestream/components/livestream.admin.device.modal'
import LivestreamReactAni from 'entities/livestream/components/livestream.react.ani'
import {DEFAULT_VOLUME, EnumReactLivestream, REACT_GIFT} from 'entities/livestream/livestream.admin.constant'
import {Socket} from 'socket.io-client'
import {Link} from 'react-router-dom'
import {EnumReadyStatusLivestream, EnumStatusLivestream} from 'entities/livestream/livestream.interface'
import {EnumTypeInputSound} from 'entities/livestream/hooks/livestream.admin.hook'
import LivestreamAdminInfoModal from 'entities/livestream/components/livestream.admin.info.modal'
import VideoJS from 'entities/livestream/components/videoJS'
import LivestreamPreviewButtonControl from 'entities/livestream/components/livestream.preview.buttonControl'
import {Modal, OverlayTrigger, Stack, Tooltip} from 'react-bootstrap'
import AddListProduct from './AddListProduct'
import {TypedCourse} from 'entities/courses/courses.interface'
import {updateLiveStream} from '../services/livestream.service'
import Image from 'react-bootstrap/Image'
import __helpers from 'helpers/index'
import {getDetailLiveStream} from '../livestream.store.reducer'
import {useSelector} from 'react-redux'
import __ from "languages/index";

interface TypedLivestreamAdminPreviewProps {
  socket: Socket
  onPlusCountReact: (typeReact: EnumReactLivestream) => void
  setStream: (stream: MediaStream) => void
  startLivestream: () => void
  replaceAudioTrackInStream: (
    audioTrack: MediaStreamTrack,
    type: EnumTypeInputSound,
    updateChangeStream?: boolean
  ) => void
  isReadyForSetup: boolean
  showModalAlreadyLive: boolean
  onChangeVolume: (value: number, type: EnumTypeInputSound) => void
  updateMaxResolution: (value: { width: number; height: number }) => void
  endLiveStream: () => void
  connectionStatus: RTCPeerConnectionState
  setIsPressEnd: (value: boolean) => void
}

export default function LivestreamAdminPreview({
  socket,
  onPlusCountReact,
  setStream,
  startLivestream,
  replaceAudioTrackInStream,
  isReadyForSetup,
  onChangeVolume,
  endLiveStream,
  updateMaxResolution,
  connectionStatus,
  showModalAlreadyLive,
  setIsPressEnd
}: TypedLivestreamAdminPreviewProps) {
  const liveDetail = useSelector(getDetailLiveStream)
  const isLiveBelongToEvent = Boolean(liveDetail?.event_data)
  const eventDetail = isLiveBelongToEvent ? liveDetail?.event_data : null

  // const event_id = params?.event_id
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false)
  const refLivestreamAdminDeviceModal = useRef<any>()
  const refCanvas = useRef<HTMLCanvasElement | null>(null)
  const refCanvasContext = useRef<CanvasRenderingContext2D | null>(null)
  const refVideo = useRef<HTMLVideoElement | null>(null)
  const [isMicroReady, setIsMicroReady] = useState<boolean>(false)
  const [isSoundReady, setIsSoundReady] = useState<boolean>(false)
  const [isEmptySoundReady, setIsEmptySoundReady] = useState<boolean>(false)
  const [showModalAddProduce, setShowModalAddProduce] = useState<boolean>(false)

  const refLivestreamReactAni = useRef<any>()
  const refBottomBanner = useRef<any>()
  const refRequestIdAnimationFrame = useRef<any>()
  const refSetupOnceTime = useRef(false)
  const [isReadyForM3u8, setIsReadyForM3u8] = useState(false)
  const refVideoJsOptions = useRef<any>({
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    muted: true
  })
  const refSizeOfBottomBanner = useRef({
    topX: 0,
    topY: 0,
    bottomX: 0,
    bottomY: 0
  })
  const refLivestreamAdminInfoModal = useRef<any>()

  // kích thước tính toán để vẽ canvas
  const refDw = useRef(0)
  const refDh = useRef(0)
  const refDx = useRef(0)
  const refDy = useRef(0)

  useEffect(() => {
  }, [liveDetail, eventDetail?._id])

  useEffect(() => {
    if (liveDetail?.input_type === EnumLiveStreamSource.OutSide) {
      window.removeEventListener('resize', responsiveSizeCanvas)

      refVideoJsOptions.current = {
        ...refVideoJsOptions.current,
        sources: [
          {
            src: liveDetail?.livestream_data?.m3u8_url,
            type: 'application/x-mpegURL'
          }
        ]
      }
      setIsReadyForM3u8(true)

      if (refRequestIdAnimationFrame.current) {
        cancelAnimationFrame(refRequestIdAnimationFrame.current)
      }
    }
  }, [liveDetail?.input_type, liveDetail?.livestream_data?.m3u8_url])

  useEffect(() => {
    if (
      isReadyForSetup &&
      liveDetail?.livestream_status !== EnumStatusLivestream.Ended &&
      !refSetupOnceTime.current
    ) {
      refSetupOnceTime.current = true
      refLivestreamAdminDeviceModal.current?.show()
    }
  }, [isReadyForSetup, liveDetail])

  useEffect(() => {
    // socket react
    socket.on('emojiToClient', data => {
      try {
        if(!__helpers.isJson(data)) return
        if (typeof JSON.parse(data) === 'object') {
          let reactData = JSON.parse(data)
          if (reactData?.react_type) {
            onPlusCountReact?.(reactData?.react_type)
            refLivestreamReactAni.current.showReact(
              Math.floor(
                Math.random() *
                  (refSizeOfBottomBanner.current.bottomX - refSizeOfBottomBanner.current.topX + 1)
              ) + refSizeOfBottomBanner.current.topX,
              Math.floor(
                Math.random() *
                  (refSizeOfBottomBanner.current.bottomY - refSizeOfBottomBanner.current.topY + 1)
              ) + refSizeOfBottomBanner.current.topY,
              <img src={REACT_GIFT[reactData?.react_type]} className="livestreamvideo_btn_react" />
            )
          }
        }
      } catch (error) {
        console.log(error, 'sljnfjbsdf')
      }
    })

    if (!refCanvas.current || !refVideo.current) return

    refCanvasContext.current = refCanvas.current?.getContext('2d')
    refVideo.current.volume = DEFAULT_VOLUME

    refVideo.current.onloadedmetadata = function () {
      refCanvas.current.width = refVideo.current?.offsetWidth
      refCanvas.current.height = refVideo.current?.offsetHeight
      calculateSizeVideoToDrawInCanvas()
    }

    document.addEventListener('visibilitychange', function () {
      refVideo.current?.play()
    })

    setStream(refCanvas.current?.captureStream(24))

    window.addEventListener('resize', responsiveSizeCanvas)
    responsiveSizeCanvas()

    let audioElement: HTMLAudioElement = document.getElementById(
      'audio_for_create_empty_track'
    ) as HTMLAudioElement
    audioElement.volume = 0
    audioElement.addEventListener('play', function () {
      let audioContext = new AudioContext()
      let source = audioContext.createMediaElementSource(audioElement)
      let destination = audioContext.createMediaStreamDestination()
      source.connect(destination)
      if (destination?.stream?.getAudioTracks().length > 0) {
        setIsEmptySoundReady(true)
        replaceAudioTrackInStream(
          destination?.stream?.getAudioTracks()?.[0],
          EnumTypeInputSound.Mic,
          true
        )
      }
      audioContext.close()
    })

    return () => {
      // stopStream();
      window.removeEventListener('resize', responsiveSizeCanvas)

      if (refRequestIdAnimationFrame.current) {
        cancelAnimationFrame(refRequestIdAnimationFrame.current)
      }
    }
  }, [])

  /**
   * Các hàm phục vụ vẽ canvas
   */
  const calculateSizeVideoToDrawInCanvas = useCallback(() => {
    let ratioWidth = refVideo.current.videoWidth / refCanvas.current.width
    let ratioHeight = refVideo.current.videoHeight / refCanvas.current.height
    let widthSet = 0,
      heightSet = 0

    if (ratioWidth > ratioHeight) {
      widthSet = refCanvas.current.width
      heightSet =
        (refCanvas.current.width / refVideo.current.videoWidth) * refVideo.current.videoHeight
    } else {
      widthSet =
        (refCanvas.current.height / refVideo.current.videoHeight) * refVideo.current.videoWidth
      heightSet = refCanvas.current.height
    }
    let xOffset = widthSet < refCanvas.current.width ? (refCanvas.current.width - widthSet) / 2 : 0
    let yOffset =
      heightSet < refCanvas.current.height ? (refCanvas.current.height - heightSet) / 2 : 0

    refDw.current = widthSet
    refDh.current = heightSet
    refDx.current = xOffset
    refDy.current = yOffset
  }, [])

  const responsiveSizeCanvas = useCallback(() => {
    refCanvas.current.width = refVideo.current?.offsetWidth
    refCanvas.current.height = refVideo.current?.offsetHeight
    calculateSizeVideoToDrawInCanvas()

    if (refBottomBanner.current) {
      const rect = refBottomBanner.current?.getBoundingClientRect()
      refSizeOfBottomBanner.current = {
        topX: rect.left + 20,
        topY: rect.top - 80,
        bottomX: rect.right - 20,
        bottomY: rect.bottom - 80
      }
    }
  }, [])

  const drawVideoToCanvas = useCallback(() => {
    if (
      (!refCanvasContext.current && !refVideo.current) ||
      refVideo.current?.paused ||
      refVideo.current?.ended
    ) {
      return
    }

    try {
      refCanvasContext.current?.drawImage(
        refVideo.current,
        refDx.current,
        refDy.current,
        refDw.current,
        refDh.current
      )
    } catch (error) {
      console.log(error)
    }

    refRequestIdAnimationFrame.current = requestAnimationFrame(drawVideoToCanvas) // loop anim. using rAF
  }, [])

  const onChangeVolumeVideo = useCallback((value: number, type: EnumTypeInputSound) => {
    if (refVideo.current) {
      refVideo.current.volume = value / 100
    }

    onChangeVolume(value, type)
  }, [])

  const [pinningProduct, setPinningProduct] = useState<TypedCourse | null>(liveDetail?.product_id)

  const handlePinProduct = product => {
    setPinningProduct(product)
  }

  const handleUnpinProduct = async () => {
    try {
      socket.emit(
        'emitProduct',
        JSON.stringify({ payload: null, room_id: `livestream_${liveDetail?._id}` })
      )
      await updateLiveStream({
        _id: liveDetail?._id,
        product_id: null
      })
      setPinningProduct(null)
    } catch (err) {
      socket.emit(
        'emitProduct',
        JSON.stringify({ payload: pinningProduct, room_id: `livestream_${liveDetail?._id}` })
      )
      setPinningProduct(pinningProduct)
    }
  }

  useEffect(() => {
    setPinningProduct(liveDetail?.product_id)
  }, [liveDetail])

  useEffect(() => {
    socket.on('productToClient', handlePinProduct)
  }, [])

  return (
    <div className="livepreview-container">
      <div className="livestream_container_preview">
        <div className="livestream_header px-3">
          <b>Live Preview</b>

          <div>
            <Link
              to={isLiveBelongToEvent ? '/event/detail/' + eventDetail?._id : '/'}
              target="_blank"
              className="livestreamadmin_btn text-decoration-none"
            >
              <FontAwesomeIcon icon={faCalendarDay} className="me-2 icon_btn" />
              {isLiveBelongToEvent ? `${__("livestream_event")}` : `${__("livestream_home")}`}
            </Link>
            <Button
              className="livestreamadmin_btn"
              onClick={() => refLivestreamAdminInfoModal.current.show()}
            >
              <FontAwesomeIcon icon={faCircleInfo} className="me-2 icon_btn" />
              {__("livestream_information")}
            </Button>
          </div>
        </div>
        <div className="w-100 h-100 p-3 position-relative">
          <div className="livestream_video_content">
            {
              !liveDetail?.input_type && (
                <div
                  onClick={() => refLivestreamAdminDeviceModal.current?.show()}
                  className="position-absolute pointer_custom z-0"
                >
                  <h3 className="text-white">{__("livestream_prepare_ready")}</h3>
                  <h3 className="text-white">{__("livestream_chose_source")}</h3>
                </div>
              )
            }
            {liveDetail?.input_type === EnumLiveStreamSource.OutSide ? (
              liveDetail?.livestream_status === EnumStatusLivestream.Live &&
              liveDetail?.ready_status === EnumReadyStatusLivestream.Connected &&
              isReadyForM3u8 ? (
                <div className="video-player d-flex flex-column justify-content-center z-1">
                  <VideoJS options={refVideoJsOptions.current} />
                </div>
              ) : null
            ) : (
              <>
                <video
                  ref={refVideo}
                  id={'live_video_preview'}
                  onPlay={drawVideoToCanvas}
                  autoPlay
                  muted
                  playsInline
                  loop
                  controls={false}
                  crossOrigin={'anonymous'}
                  className="video-player opacity-0 pe-none"
                />
                <div
                  className={'position-absolute'}
                  style={{
                    bottom: '20px',
                    left: 0,
                    maxWidth: '500px',
                    zIndex: 10
                  }}
                >
                  {pinningProduct && (
                    <Stack
                      direction={'horizontal'}
                      className={
                        'd-flex align-items-start bg-white bg-opacity-50 w-100 h-100 p-2 rounded-2 '
                      }
                      gap={2}
                    >
                      <Image
                        src={pinningProduct?.avatar?.media_thumbnail || pinningProduct?.avatar?.media_url}
                        width={200}
                        height={'auto'}
                        style={{
                          maxHeight: '130px'
                        }}
                      />
                      <Stack>
                        <Stack
                          className="d-flex align-items-start text-black px-2"
                          style={{
                            maxWidth: '250px'
                          }}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={'pinning-product-title'}>
                                {pinningProduct?.title}
                              </Tooltip>
                            }
                          >
                            <span
                              className={'fs-6 d-block fw-semibold text-truncate'}
                              style={{
                                maxWidth: '200px'
                              }}
                            >
                              {pinningProduct?.title}
                            </span>
                          </OverlayTrigger>
                          <span className={'text-primary'}>
                            {__helpers.convertToCommasFormat(pinningProduct?.coin_value)} đ
                          </span>
                        </Stack>
                        <Button
                          className={'position-relative '}
                          style={{
                            zIndex: '100'
                          }}
                          onClick={handleUnpinProduct}
                        >
                          {__("livestream_unpin")}
                        </Button>
                      </Stack>
                    </Stack>
                  )}
                </div>
                <canvas ref={refCanvas} id="canvas" className="z-2 pe-none"></canvas>
              </>
            )}

            <audio id="audio_for_create_empty_track" src={require('../media/voice.mp3')} />
            <div id="overlayPause" className="position-absolute z-2 w-100 h-100 d-none">
              <div
                className="text-lg-center w-100 h-100 d-flex flex-column justify-content-center"
                style={{ backgroundColor: '#000' }}
              >
                <h3 className="text-white">{__("livestream_pending")}</h3>
              </div>
            </div>
          </div>

          <div ref={refBottomBanner} className="livestreamvideo_container_bottom"></div>

          <LivestreamReactAni ref={refLivestreamReactAni} />
        </div>
      </div>
      <div className="livestream_footer">
        <div className="livestream_setting_group flex-grow-1 me-4">
          <Stack className={'d-flex align-items-start'}>
            <Button
              onClick={() => refLivestreamAdminDeviceModal.current?.show()}
              className="livestreamadmin_btn_setting_source"
            >
              <FontAwesomeIcon icon={faGear} className="me-2 icon_btn" />
              {__("livestream_source_video")}
            </Button>
            <div onClick={() => setShowModalAddProduce(true)} role={'button'}>
              <FontAwesomeIcon icon={faList} className={'me-2'} />
              <span
                style={{
                  fontSize: '14px'
                }}
              >
                {__("livestream_list_product")}
              </span>
            </div>
          </Stack>
          <Modal
            show={showModalAddProduce}
            onHide={() => setShowModalAddProduce(false)}
            style={{
              zIndex: 99999
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>{__("livestream_list_product")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddListProduct
                socket={socket}
                liveStreamId={liveDetail?._id}
                pinningProduct={pinningProduct}
                onPinProductFailed={product => console.log(product)}
                onPinProductSuccess={setPinningProduct}
              />
            </Modal.Body>
          </Modal>

          <div className="livestream_setting_volumn align-items-center gap-5">
            {liveDetail?.input_type !== EnumLiveStreamSource.OutSide && (
              <>
                <LivestreamAdminSlider
                  onChangeVolume={onChangeVolumeVideo}
                  iconOn={faVolumeHigh}
                  type={EnumTypeInputSound.Sound}
                  iconOff={faVolumeXmark}
                  display={liveDetail?.input_type !== EnumLiveStreamSource.Camera}
                />
                <LivestreamAdminSlider
                  onChangeVolume={onChangeVolume}
                  iconOn={faMicrophone}
                  type={EnumTypeInputSound.Mic}
                  display={isMicroReady}
                  iconOff={faMicrophoneSlash}
                />
              </>
            )}
          </div>
        </div>

        {/*Nút bắt đầu phát*/}
        <LivestreamPreviewButtonControl
          startLivestream={startLivestream}
          endLiveStream={endLiveStream}
          connectionStatus={connectionStatus}
          isVideoReady={isVideoReady}
          setIsPressEnd={setIsPressEnd}
          showModalAlreadyLive={showModalAlreadyLive}
          liveStreamId={liveDetail?._id}
        />
      </div>

      <LivestreamAdminDeviceModal
        setAudioTrack={replaceAudioTrackInStream}
        ref={refLivestreamAdminDeviceModal}
        setIsVideoReady={setIsVideoReady}
        setIsMicroReady={setIsMicroReady}
        isAudioReady={isMicroReady || isSoundReady || isEmptySoundReady}
      />

      <LivestreamAdminInfoModal ref={refLivestreamAdminInfoModal} socket={socket} />
    </div>
  )
}
