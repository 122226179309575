//Library
import React, {memo, useCallback, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {AxiosError, AxiosResponse} from 'axios'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'

//Scss
import '../media/user.setting.notification.scss'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import {getCurrentUserData, updateDataUser} from "../../../store/user.store.reducer";
import { Box, styled } from '@mui/material'

interface TypeFormData {
  notification_chat?: boolean
  notification_community?: boolean
  notification_course?: boolean
}

const UserNotificationSetting = () => {
  const dispatch = useAppDispatch()

  const user_data = useAppSelector(state => state.user.user_data)
  const toast = useToast()

  const [formData, setFormData] = useState<TypeFormData>({
    notification_chat: false,
    notification_community: false,
    notification_course: false
  })

  const handleChangeCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = e.target
      setFormData({ ...formData, [name]: checked })
      dispatch(updateDataUser({ _id: user_data?._id, [name]: checked ? '1' : '0' }))
        .unwrap()
        .then(() => {
          toast.show({
            content: `${__("user_setting_notification_success")}`,
            type: EnumTypeToast.Success
          })
        })
        .catch((error: AxiosError) => {
          console.log(`updateDataUser_${error}`)
          setFormData({ ...formData, [name]: !checked })
          toast.show({
            content: `${__("user_setting_notification_failed")}`,
            type: EnumTypeToast.Error
          })
        })
    },
    [formData, user_data]
  )

  useEffect(() => {
    dispatch(getCurrentUserData())
      .unwrap()
      .then((res: AxiosResponse) => {
        setFormData({
          notification_chat: Boolean(Number(res?.data?.notification_chat)),
          notification_community: Boolean(Number(res?.data?.notification_community)),
          notification_course: Boolean(Number(res?.data?.notification_course))
        })
      })
      .catch((error: AxiosError) => {
        console.log(`getDataUser_${error}`)
        setFormData({
          notification_chat: false,
          notification_community: false,
          notification_course: false
        })
      })
  }, [])

  return (
    <UserNotificationSettingContainer id="userNotificationSetting" className="userNotificationSetting_container">
      <h4 className="userNotificationSetting_label">{__("user_setting_notification")}</h4>
      <div className="userNotificationSetting_body">
        <div className="userNotificationSetting_body_item">
          <div className="userNotificationSetting_body_item_left">
            <span className="userNotificationSetting_body_item_title">{__("user_setting_notification_chat")}</span>
            <span className="userNotificationSetting_body_item_left_content">
              {__("user_setting_notification_chat_on")}
            </span>
          </div>
          <div className="form-switch">
            <Form.Check
              name="notification_chat"
              onChange={handleChangeCheckbox}
              checked={formData?.notification_chat || false}
              readOnly
              className="userNotificationSetting_body_item_right"
            />
          </div>
        </div>
        <div className="userNotificationSetting_body_item">
          <div className="userNotificationSetting_body_item_left">
            <span className="userNotificationSetting_body_item_title">{__("user_setting_notification_post")}</span>
            <span className="userNotificationSetting_body_item_left_content">
              {__("user_setting_notification_post_on")}
            </span>
          </div>
          <div className="form-switch">
            <Form.Check
              name="notification_community"
              onChange={handleChangeCheckbox}
              checked={formData?.notification_community || false}
              readOnly
              className="userNotificationSetting_body_item_right"
            />
          </div>
        </div>
        <div className="userNotificationSetting_body_item">
          <div className="userNotificationSetting_body_item_left">
            <span className="userNotificationSetting_body_item_title">{__("user_setting_notification_course")}</span>
            <span className="userNotificationSetting_body_item_left_content">
              {__("user_setting_notification_course_on")}
            </span>
          </div>
          <div className="form-switch">
            <Form.Check
              name="notification_course"
              onChange={handleChangeCheckbox}
              checked={formData?.notification_course || false}
              readOnly
              className="userNotificationSetting_body_item_right"
            />
          </div>
        </div>
      </div>
    </UserNotificationSettingContainer>
  )
}

export default memo(UserNotificationSetting)

const UserNotificationSettingContainer = styled(Box)(({theme})=> ({
  '& .form-check-input':{
    ':checked':{
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`
    },
    ':focus':{
      boxShadow: 'unset !important',
      borderColor: 'unset !important'
    }
  }
}))
