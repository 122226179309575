import { SvgIcon, SvgIconProps } from '@mui/material'

const OrderVoteIcon =(props: SvgIconProps)=>{
  return(
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="currentColor">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.147 1.15185C13.905 -0.383951 16.095 -0.383951 16.853 1.15185L20.3682 8.27451L28.2285 9.41668C29.9234 9.66296 30.6001 11.7458 29.3737 12.9412L23.6859 18.4855L25.0287 26.314C25.3182 28.002 23.5464 29.2893 22.0305 28.4923L15 24.7962L7.96951 28.4923C6.45359 29.2893 4.68183 28.002 4.97135 26.314L6.31405 18.4855L0.626271 12.9412C-0.600141 11.7458 0.0766076 9.66296 1.77147 9.41668L9.63179 8.27451L13.147 1.15185ZM15 3.62269L11.9422 9.81856C11.6412 10.4284 11.0594 10.8511 10.3863 10.9489L3.54877 11.9425L8.49647 16.7653C8.98348 17.24 9.20571 17.924 9.09074 18.5943L7.92275 25.4042L14.0384 22.189C14.6404 21.8725 15.3596 21.8725 15.9616 22.189L22.0772 25.4042L20.9093 18.5943C20.7943 17.924 21.0165 17.24 21.5035 16.7653L26.4512 11.9425L19.6137 10.9489C18.9406 10.8511 18.3588 10.4284 18.0578 9.81856L15 3.62269Z"
              fill="currentColor" />
      </svg>
    </SvgIcon>
  )
}
export default OrderVoteIcon
