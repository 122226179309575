import { breakpoints } from '../constant';
import TypedMedia, { ImageSlide, VideoSlide } from '../interfaces/media.interface'

export const generateGridLayoutImage = (images: TypedMedia[]) => {
  const setImageSize = (item, { cols, rows }: { cols: number; rows: number }) => {
    item.cols = cols
    item.rows = rows
  }

  let tempItemData = images.map(item => ({
    ...item,
    cols: 1,
    rows: 1
  }))
  switch (images.length) {
    case 1: {
      setImageSize(tempItemData[0], { cols: 6, rows: 8 })
      return tempItemData
    }

    case 2: {
      setImageSize(tempItemData[0], { cols: 3, rows: 8 })
      setImageSize(tempItemData[1], { cols: 3, rows: 8 })
      return tempItemData
    }
    case 3: {
      setImageSize(tempItemData[0], { cols: 6, rows: 4 })
      setImageSize(tempItemData[1], { cols: 3, rows: 4 })
      setImageSize(tempItemData[2], { cols: 3, rows: 4 })
      return tempItemData
    }
    case 4: {
      setImageSize(tempItemData[0], { cols: 3, rows: 4 })
      setImageSize(tempItemData[1], { cols: 3, rows: 4 })
      setImageSize(tempItemData[2], { cols: 3, rows: 4 })
      setImageSize(tempItemData[3], { cols: 3, rows: 4 })
      return tempItemData
    }
    case 5:
    default: {
      setImageSize(tempItemData[0], { cols: 3, rows: 5 })
      setImageSize(tempItemData[1], { cols: 3, rows: 5 })
      setImageSize(tempItemData[2], { cols: 2, rows: 3 })
      setImageSize(tempItemData[3], { cols: 2, rows: 3 })
      setImageSize(tempItemData[4], { cols: 2, rows: 3 })
      return tempItemData
    }
  }
}

export const generateMediaSlides = (media: TypedMedia[]) => {
  if (!media) return [];

  const slides: (ImageSlide | VideoSlide)[] = [];

  media.forEach(item => {
    if (item.media_type === 'image') {
      const meta = item.media_meta;
      const width = +meta.find(metaItem => metaItem.key === 'width') * 4;
      const height = +meta.find(metaItem => metaItem.key === 'height') * 4;

      const srcSet = breakpoints.map(breakpoint => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: `${item.media_url}?w=${breakpoint}&h=${breakpointHeight}&fit=crop&auto=format`,
          width: breakpoint,
          height: breakpointHeight
        };
      });

      const imageSlide: ImageSlide = {
        type: 'image',
        src: item.media_url,
        width,
        height,
        srcSet
      };

      slides.push(imageSlide);
    } else if (item.media_type === 'video') {
      const videoSlide: VideoSlide = {
        type: 'video',
        src: item.media_url,
        poster: item.media_thumbnail,
        width: 1280,
        height: 720,
        sources: [
          {
            src: item.media_url,
            type: 'video/mp4'
          }
        ]
      };

      slides.push(videoSlide);
    }
  });

  return slides;
};