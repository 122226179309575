//Library
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IQueryParams, createEntitySlice, serializeAxiosError } from 'config/reducer.utils'

//Interface
import { TypeListApprovalPost, TypeUpdateRequest } from '../interface'

//helpers
import helpers from 'helpers'
import {EnumPostStatus} from "../../../interfaces/request.interface";

export const initialState = {}

export const getListApprovalPost = createAsyncThunk(
  'post/get_list_approval',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<TypeListApprovalPost[]>(`/request/list`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const deletePost = createAsyncThunk(
  'post/delete_post',
  async (_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/request/delete/${_id}`)
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const updatePost = createAsyncThunk(
  'post/update',
  async (body: TypeUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`/request/update`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },

  { serializeError: serializeAxiosError }
)

export const APPROVAL_REDUCER = createEntitySlice({
  name: 'approval_post',
  initialState: initialState as any,
  reducers: {},
  extraReducers(builder) {}
})

export const {} = APPROVAL_REDUCER.actions

export default APPROVAL_REDUCER.reducer
