import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import '../media/livestream.admin.scss';
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons";
import {EnumReactLivestream, REACT_LIST} from "entities/livestream/livestream.admin.constant";
import {useAppSelector} from "config/store";
import __ from "languages/index";


const LivestreamAdminReact = forwardRef((_, ref) => {

  const liveDetail = useAppSelector(state => state.livestream.entity);

  const [countReact, setCountReact] = useState({
    [EnumReactLivestream.Like]:0,
    [EnumReactLivestream.Haha]:0,
    [EnumReactLivestream.Love]:0,
    [EnumReactLivestream.Wow]:0,
    [EnumReactLivestream.Care]:0,
    [EnumReactLivestream.Sad]:0,
  })

  useImperativeHandle(ref, () => ({
    plusCount: (type: EnumReactLivestream)=>{
      setCountReact(old=>({...old, [type]:old[type]+1}))
    }
  }),[])

  useEffect(() => {
    if(liveDetail?.react_value){
      setCountReact((oldValue)=>{
        let newValue = {...oldValue};
        Object.keys(oldValue).forEach((key)=>{
          newValue[key] = liveDetail?.react_value?.[key+"_value"] || newValue[key]
        })

        return newValue
      })
    }
  }, [liveDetail]);

    const renderReactComponent = useCallback((item) => {
      return (
        <div key={item.name} className="d-flex align-items-center" style={{width: "32%"}}>
          <img src={item.icon} className="react_img_board"/>
          <div>{countReact[item.name]}</div>
        </div>
      )
    }, [countReact])

    return (
      <div className="livestreamadminreact">
        <div className="livestream_header px-3">
          <b>
            {__("livestream_interact")}
          </b>

          <Button disabled className="livestreamadmin_btn invisible pe-none">
            <FontAwesomeIcon icon={faCalendarDay} className="me-2 icon_btn"/>
            {__("livestream_interact")}
          </Button>
        </div>

        <div className="d-flex flex-wrap justify-content-between py-3 px-4 flex-grow-1">
          <div className="d-flex flex-column justify-content-around h-100">
            {REACT_LIST.slice(0, 2).map(renderReactComponent)}
          </div>
          <div className="d-flex flex-column justify-content-around h-100">
            {REACT_LIST.slice(2, 4).map(renderReactComponent)}
          </div>
          <div className="d-flex flex-column justify-content-around h-100">
            {REACT_LIST.slice(4, 6).map(renderReactComponent)}
          </div>
        </div>

      </div>
    )
  }
)
export default LivestreamAdminReact
