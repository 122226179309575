import { Shadows, ThemeOptions } from '@mui/material'
import { defaultButton } from 'styles/components/button'
import { defaultInput } from 'styles/components/input'
import { defaultLabel } from 'styles/components/label'
import { defaultSelect } from 'styles/components/select'
import { defaultCheckbox } from 'styles/components/checkbox'
import { shadows } from './shadows'
import { defaultPaper } from 'styles/components/paper'
import typography from './typography'

export const baseThemeOptions: ThemeOptions = {
  typography: typography,

  components: {
    MuiButton: defaultButton,
    MuiInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(1.5),
          backgroundColor: theme.palette.background.secondary
        })
      }
    },
    MuiTextField: defaultInput,
    MuiFormLabel: defaultLabel,
    MuiSelect: defaultSelect,
    MuiCheckbox: defaultCheckbox,
    MuiPaper: defaultPaper
  },
  shadows: shadows as Shadows
}
