import { SvgIcon, SvgIconProps } from '@mui/material'

const HeartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9928 3.73069C9.65295 1.66959 6.19028 1.24637 3.50171 3.5649C0.597862 6.06908 0.178405 10.2806 2.47194 13.2594C3.34409 14.3921 5.04675 16.0942 6.68346 17.6535C8.33919 19.2308 9.99112 20.7213 10.8058 21.4495C10.8108 21.454 10.816 21.4586 10.8213 21.4633C10.8979 21.5319 10.9932 21.6171 11.0845 21.6866C11.1938 21.7696 11.3512 21.8732 11.562 21.9367C11.8424 22.0211 12.1441 22.0211 12.4245 21.9367C12.6353 21.8732 12.7927 21.7696 12.902 21.6866C12.9933 21.6171 13.0886 21.5319 13.1652 21.4633C13.1705 21.4586 13.1756 21.454 13.1807 21.4495C13.9954 20.7213 15.6473 19.2308 17.303 17.6535C18.9397 16.0942 20.6424 14.3921 21.5145 13.2594C23.7994 10.2919 23.4449 6.04794 20.4744 3.55607C17.7558 1.27555 14.3301 1.6688 11.9928 3.73069ZM11.2304 5.80221C9.52421 3.78904 6.8187 3.34541 4.80785 5.07949C2.7016 6.89585 2.41571 9.90805 4.05663 12.0392C4.82011 13.0308 6.41023 14.6309 8.06298 16.2054C9.60398 17.6734 11.1454 19.0687 11.9932 19.8283C12.8411 19.0687 14.3825 17.6734 15.9235 16.2054C17.5762 14.6309 19.1664 13.0308 19.9298 12.0392C21.5795 9.89672 21.3147 6.87154 19.189 5.08832C17.1249 3.35676 14.4547 3.79799 12.7561 5.80221C12.5661 6.0264 12.2871 6.15567 11.9932 6.15567C11.6994 6.15567 11.4204 6.0264 11.2304 5.80221Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default HeartIcon
