import { SvgIcon, SvgIconProps } from '@mui/material'

export default function RankTopIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
        <path d="M22.6228 12.4908C22.5339 12.4909 22.4463 12.4687 22.3679 12.4263C22.2896 12.3839 22.2229 12.3225 22.1738 12.2477L19.6441 8.40176C19.5786 8.30202 19.5477 8.18325 19.556 8.06393C19.5644 7.94461 19.6116 7.83142 19.6903 7.74196L22.8659 4.13043C22.9931 3.98309 23.1501 3.865 23.3263 3.78413C23.5026 3.70325 23.694 3.66148 23.8877 3.66162C23.984 3.66185 24.08 3.67185 24.1743 3.69146C24.4117 3.73916 24.6323 3.84974 24.8133 4.01184C24.9944 4.17394 25.1295 4.38173 25.2047 4.61388L26.8928 9.74795C26.9331 9.86962 26.9295 10.0018 26.8824 10.121C26.8353 10.2402 26.7479 10.3387 26.6357 10.3991L22.8745 12.4278C22.7971 12.4696 22.7106 12.4912 22.6228 12.4908Z" fill="#FAAD14" />
        <path d="M7.37643 12.4904C7.2882 12.4907 7.20131 12.4687 7.12367 12.4264L3.363 10.3981C3.25077 10.3378 3.16336 10.2393 3.1163 10.1201C3.06924 10.0009 3.06557 9.8687 3.10595 9.74703L4.79349 4.61296C4.86873 4.38073 5.00388 4.1729 5.18506 4.01079C5.36623 3.84869 5.5869 3.73816 5.8244 3.69054C5.91937 3.67042 6.01615 3.66024 6.11319 3.66016C6.30695 3.6599 6.49849 3.70173 6.6748 3.78281C6.85111 3.86389 7.00806 3.98232 7.13497 4.13005L10.3105 7.74158C10.3889 7.83129 10.4357 7.9446 10.4437 8.06392C10.4517 8.18324 10.4204 8.30187 10.3546 8.40138L7.82708 12.2484C7.77772 12.3233 7.71069 12.3846 7.63203 12.4268C7.55337 12.469 7.46554 12.4909 7.37643 12.4904Z" fill="#FAAD14" />
        <path d="M5.58478 25.5C5.28731 25.4986 4.99815 25.4008 4.76003 25.2209C4.5219 25.041 4.34743 24.7886 4.26239 24.501L0.0564415 10.0679C-0.0205743 9.80485 -0.0187349 9.52467 0.0617281 9.26266C0.142191 9.00065 0.297681 8.76851 0.508623 8.59547C0.719565 8.42243 0.97653 8.31623 1.24717 8.29022C1.51781 8.26422 1.79002 8.31958 2.02954 8.44934L7.19972 11.2394L13.852 1.12209C13.9777 0.930809 14.1484 0.773898 14.3489 0.665352C14.5493 0.556806 14.7732 0.5 15.0007 0.5C15.2282 0.5 15.4521 0.556806 15.6525 0.665352C15.853 0.773898 16.0237 0.930809 16.1494 1.12209L22.8011 11.2378L27.9713 8.44771C28.2108 8.31858 28.4828 8.26369 28.7531 8.28992C29.0234 8.31615 29.2801 8.42233 29.4908 8.59514C29.7015 8.76796 29.857 8.99971 29.9377 9.26133C30.0184 9.52295 30.0207 9.80279 29.9444 10.0657L25.7385 24.4989C25.6535 24.7866 25.479 25.0391 25.2407 25.219C25.0024 25.3989 24.7131 25.4967 24.4155 25.4978L5.58478 25.5Z" fill="url(#paint0_linear_7194_48635)" />
        <path d="M25.7374 24.501L26.1078 23.2755L3.89844 23.2754L4.26131 24.501C4.34635 24.7886 4.52082 25.041 4.75894 25.2209C4.99707 25.4008 5.28623 25.4986 5.5837 25.4999H24.4145C24.7121 25.4988 25.0014 25.4011 25.2396 25.2212C25.4779 25.0413 25.6524 24.7887 25.7374 24.501Z" fill="#FAAD14" />
        <defs>
          <linearGradient id="paint0_linear_7194_48635" x1="15" y1="0.5" x2="15" y2="25.5" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFD87D" />
            <stop offset="1" stop-color="#FFC53D" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}
