import { SvgIcon, SvgIconProps } from '@mui/material'

const CloseCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4.5C7.58172 4.5 4 8.08172 4 12.5C4 13.8188 4.31832 15.0603 4.88144 16.1546C5.27781 16.9249 5.43555 17.8942 5.08534 18.8186L4.44826 20.5H12C16.4183 20.5 20 16.9183 20 12.5C20 8.08172 16.4183 4.5 12 4.5ZM2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5C22 18.0228 17.5228 22.5 12 22.5H3.72413C2.67465 22.5 1.9496 21.4499 2.32144 20.4685L3.25656 20.8228L2.32144 20.4685L3.21508 18.1099C3.32331 17.8243 3.29872 17.45 3.10306 17.0697C2.39771 15.6989 2 14.1445 2 12.5ZM16.7071 9.79289C17.0976 10.1834 17.0976 10.8166 16.7071 11.2071L12.0607 15.8536C11.4749 16.4393 10.5251 16.4393 9.93934 15.8536L7.29289 13.2071C6.90237 12.8166 6.90237 12.1834 7.29289 11.7929C7.68342 11.4024 8.31658 11.4024 8.70711 11.7929L11 14.0858L15.2929 9.79289C15.6834 9.40237 16.3166 9.40237 16.7071 9.79289Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default CloseCircleIcon
