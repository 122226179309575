import React from 'react'
import { MinusIcon, PlusIcon } from 'icons'
import { Divider, IconButton, Stack } from '@mui/material'

type Props = {
  onChangeQuantity: (quantity: number) => void
  quantity: number
}

const QuantityAdjustment = ({ onChangeQuantity, quantity }: Props) => {
  const handleAdjustment = (value: number) => {
    onChangeQuantity(value)
  }

  return (
    <Stack sx={containerStyled} gap={1} direction="row">
      <IconButton className={'icon-button'} onClick={() => handleAdjustment(quantity - 1)}>
        <MinusIcon fontSize={'inherit'} color={'inherit'} />
      </IconButton>
      <Divider orientation="vertical" variant="fullWidth" flexItem />

      <input
        className="quantity-input"
        type="text"
        value={quantity}
        onChange={e => onChangeQuantity(Number(e.target.value?.replace(/\D+/g, '')))}
      />

      <Divider orientation="vertical" variant="fullWidth" flexItem />
      <IconButton className={'icon-button'} onClick={() => handleAdjustment(quantity + 1)}>
        <PlusIcon fontSize={'inherit'} color={'inherit'} />
      </IconButton>
    </Stack>
  )
}

export default QuantityAdjustment

export const containerStyled = {
  width: 'fit-content',
  border: theme => `1px solid ${theme.palette.divider}`,
  '& .icon-button': {
    fontSize: '0.875rem'
  },
  '& .quantity-input': {
    border: 'none',
    outline: 'none',
    textAlign: 'center',
    maxWidth: theme => theme.spacing(3)
  }
}
