import React from 'react'
import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalProps,
  Stack,
  styled,
  Typography
} from '@mui/material'
import { CloseIcon } from '../icons'
import __ from 'languages/index'

type FunctionType = () => void

type Props = Omit<ModalProps, 'children'> & {
  title?: string
  confirmTextBtn?: string
  cancelTextBtn?: string
  confirmText?: string
  children?: React.ReactNode
  onClose: FunctionType
  onConfirm: FunctionType
  onCancel?: FunctionType
}
const ConfirmModal = ({
  title = 'Confirm',
  cancelTextBtn = __('btn_cancel'),
  confirmTextBtn = __('btn_confirm'),
  confirmText,
  children,
  onClose,
  onConfirm,
  onCancel,
  ...rest
}: Props) => {
  const renderChildren = () => {
    return children ? (
      children
    ) : (
      <Typography variant={'subtitle1'} textAlign={'center'} fontWeight={600}>
        {confirmText}
      </Typography>
    )
  }

  const handleClose = () => onClose()

  return (
    <Modal {...rest} onClose={handleClose}>
      <ModalContainer>
        <ModalHeader direction={'row'} alignItems={'center'}>
          <Typography variant={'h6'} className={'title'}>
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <ModalBody>{renderChildren()}</ModalBody>

        <ModalFooter direction={'row'}>
          <Button
            className={'button_action'}
            variant={'text'}
            onClick={onCancel ? onCancel : handleClose}
          >
            {cancelTextBtn}
          </Button>
          <Button className={'button_action'} onClick={onConfirm}>
            {confirmTextBtn}
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  )
}

export default ConfirmModal

const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1.5),
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  overflowY: 'auto',
  width: 'auto',
  maxWidth: theme.spacing(95.75),
  minWidth: theme.spacing(50),
  margin: '0 auto'
}))

const ModalHeader = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 3),
  borderBottom: `1px solid ${ theme.palette.divider}`,
  '& .title': {
    fontWeight: 700,
    width: '100%',
    marginRight: 'auto',
    textAlign: 'center'
  }
}))

const ModalBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3)
}))

const ModalFooter = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1.25, 3),
  '& .button_action': {
    textTransform: 'none'
  }
}))
