import React, { ElementType, HTMLAttributes } from 'react'
import { useInfinite } from 'hooks/useInfinite'
import { BsPrefixProps, BsPrefixRefForwardingComponent } from 'react-bootstrap/helpers'
import Spinner from 'react-bootstrap/Spinner'

type Props<T, P extends BsPrefixProps & React.HTMLAttributes<HTMLElement>> = {
  list: T[]
  renderItem: (item: T, ref?: any, index?: number) => React.ReactNode
  hasNextPage: boolean
  onLoadMore: () => void
  isLoading?: boolean
  as?: BsPrefixRefForwardingComponent<
    'div',
    BsPrefixProps<ElementType<P>> & HTMLAttributes<HTMLElement>
  > &
    'symbol'
} & P

const InfiniteList = <
  ListType,
  ContainerComponentProps extends BsPrefixProps & React.HTMLAttributes<HTMLElement>
>({
  list,
  renderItem,
  hasNextPage,
  onLoadMore,
  isLoading,
  as,
  ...rest
}: Props<ListType, ContainerComponentProps>) => {
  const { ref } = useInfinite(hasNextPage, onLoadMore)

  const Container = as || 'div'

  return (
    <Container {...rest}>
      { Array.isArray(list) &&  list.map((item, index) => {
        if (list.length === index + 1) {
          return isLoading ? (
            <div className="text-center" key={index}>
              <Spinner animation="border" role="status" className="text-center">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            renderItem(item, ref, index)
          )
        }
        return renderItem(item)
      })}
    </Container>
  )
}

export default InfiniteList
