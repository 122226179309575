import { TypedChallengeGift } from 'entities/challenge/challenge.interface'
import React, { useCallback } from 'react'
import { Image } from 'react-bootstrap'
import __ from "languages/index";
import { Box, Stack, Button, styled, Typography } from '@mui/material';
import { TrashIcon } from 'icons';

interface Props {
  listGift: TypedChallengeGift[]
  onDeleteGift: (item: TypedChallengeGift) => void
}

const ListGiftComponent = (props: Props) => {
  const { listGift, onDeleteGift } = props


  // Function
  const _onDeleteGift = (item: TypedChallengeGift) => {
    onDeleteGift(item)
  }
  // ==================================

  if (listGift.length == 0) {
    return null
  }

  const renderItem = useCallback((item: TypedChallengeGift) => {
    return (
      <Stack
        key={item?._id}
        flexDirection={'row'}
        className='gift_item'
        justifyContent={'space-between'}
        alignItems={'center'}
        mt={1.5}
      >
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Image
            src={item?.media_id?.media_thumbnail || item?.media_id?.media_url}
            className="image-challenge-gift"
          />

          <Box ml={2} className="d-block">
            <Typography >{item?.name}</Typography>
            <Typography color={'primary'}>{item?.price.toLocaleString('en-US')} {__("currency_unit")}</Typography>
          </Box>
        </Stack>

        <Button variant="outlined" onClick={() => _onDeleteGift(item)}>
          <TrashIcon />
        </Button>
      </Stack>
    )
  }, [])

  return (
    <ListGiftContainer className="mb-3">
      {listGift.map(item => {
        return renderItem(item)
      })}
    </ListGiftContainer>
  )
}

export default ListGiftComponent

const ListGiftContainer = styled(Box)(({ theme }) => ({

  '& .gift_item': {
    '& .image-challenge-gift': {
      width: '52px',
      height: '52px',
      borderRadius: '10px',
      objectFit: 'cover',
    }
  }
}))
