import '../media/css/index.scss'
import step1 from '../media/image/step1.png'
import step2 from '../media/image/step2.png'
import step3 from '../media/image/step3.png'
import step4 from '../media/image/step4.png'
import step5 from '../media/image/step5.png'
import longArrow from '../media/image/arrow_long.png'
import __ from "languages/index";

const MobileProcess = () =>{
    return(
        <div className='mobile-process-container'>
            <div  className='container-sm p-0'>
                <h1 className='mobile-title' >{__("mobile_app_process_title")}</h1>
              <div className='mobile-reponsive'>
                <div className='d-lg-flex justify-content-between mt-4 '>
                  <div className='mobile-process'>
                    <div className='mobile-process-content'>
                      <img src={step1} alt='step1' className= 'mobile-img mobile-mr' />
                      <img src={longArrow} alt='longArrow' className= 'mobile-img'/>
                    </div>
                    <div>
                      <p className='mobile-process-text'>
                        {__("mobile_app_process_receive_information")}
                        <br className='mobile-br'/>
                        {__("mobile_app_process_receive_information_time")}
                      </p>
                    </div>
                  </div>
                  <div className='mobile-process'>
                    <div className='mobile-process-content'>
                      <img src={step2} alt='step1' className= 'mobile-img mobile-mr'/>
                      <img src={longArrow} alt='longArrow' className= 'mobile-img'/>
                    </div>
                    <div>
                      <p className='mobile-process-text'>
                        {__("mobile_app_process_exchange")}
                        <br className='mobile-br'/>
                        {__("mobile_app_process_exchange_time")}
                      </p>
                    </div>
                  </div>
                  <div className='mobile-process'>
                    <div className='mobile-process-content'>
                      <img src={step3} alt='step1' className= 'mobile-img mobile-mr'/>
                      <img src={longArrow} alt='longArrow' className= 'mobile-img'/>
                    </div>
                    <div>
                      <p className='mobile-process-text'> {__("mobile_app_process_create_app")} </p>
                    </div>
                  </div>
                  <div className='mobile-process'>
                    <div className='mobile-process-content'>
                      <img src={step4} alt='step1' className= 'mobile-img mobile-mr'/>
                      <img src={longArrow} alt='longArrow' className= 'mobile-img'/>
                    </div>
                    <div>
                      <p className='mobile-process-text'>{__("mobile_app_process_demo")}</p>
                    </div>
                  </div>
                  <div className='mobile-process-last'>
                    <div className='mobile-process-content'>
                      <img src={step5} alt='step1' className= 'mobile-img'/>
                      <p className='mobile-process-text'>{__("mobile_app_process_push_store")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mobile-reponsive-small'>
                <div className=' d-lg-flex justify-content-between mt-4 row col-12'>
                  <div className='col-6 mobile-process'>
                    <p className='mobile-process-lable' >{__("mobile_app_process_step1")}</p>
                    <div className='mobile-process-content'>
                      <img src={step1} alt='step1' className= 'mobile-img mobile-mr' />
                      <p className='mobile-process-text'>
                        {__("mobile_app_process_step1_receive_information")}
                      </p>
                    </div>
                  </div>
                  <div className='col-6 mobile-process'>
                    <p className='mobile-process-lable' >{__("mobile_app_process_step2")}</p>
                    <div className='mobile-process-content'>
                      <img src={step2} alt='step1' className= 'mobile-img mobile-mr'/>
                      <p className='mobile-process-text'>
                        {__("mobile_app_process_step2_exchange")}
                      </p>
                    </div>
                  </div>
                  <div className='col-6 mobile-process'>
                    <p className='mobile-process-lable' >{__("mobile_app_process_step3")}</p>
                    <div className='mobile-process-content'>
                      <img src={step3} alt='step1' className= 'mobile-img mobile-mr'/>
                      <p className='mobile-process-text'> {__("mobile_app_process_step3_create_app")} </p>
                    </div>
                  </div>
                  <div className='col-6 mobile-process'>
                    <p className='mobile-process-lable'>{__("mobile_app_process_step4")}</p>
                    <div className='mobile-process-content'>
                      <img src={step4} alt='step1' className= 'mobile-img mobile-mr'/>
                      <p className='mobile-process-text'>{__("mobile_app_process_step4_demo")}</p>
                    </div>
                  </div>
                  <div className='col-6 mobile-process'>
                    <p className='mobile-process-lable'>{__("mobile_app_process_step5")}</p>
                    <div className='mobile-process-content'>
                      <img src={step5} alt='step1' className= 'mobile-img'/>
                      <p className='mobile-process-text'>{__("mobile_app_process_step5_push_store")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
}
export default MobileProcess;
