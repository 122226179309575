import { SvgIcon, SvgIconProps } from '@mui/material'

const ArrowLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.707 5.29289C12.0975 5.68342 12.0975 6.31658 11.707 6.70711L7.41406 11H18.9998C19.5521 11 19.9998 11.4477 19.9998 12C19.9998 12.5523 19.5521 13 18.9998 13H7.41406L11.707 17.2929C12.0975 17.6834 12.0975 18.3166 11.707 18.7071C11.3164 19.0976 10.6833 19.0976 10.2927 18.7071L4.99985 13.4142C4.2188 12.6332 4.2188 11.3668 4.99985 10.5858L10.2927 5.29289C10.6833 4.90237 11.3164 4.90237 11.707 5.29289Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default ArrowLeftIcon
