import { StepIconProps, styled } from '@mui/material'
import React from 'react'
import OrderIcon from '../../../icons/OrderIcon'
import AwaitingPickupIcon from '../../../icons/ShoppingBagIcon'
import ShippingIcon from '../../../icons/ShippingIcon'
import WaitingDeliveryIcon from '../../../icons/WaitingDeliveryIcon'
import OrderVoteIcon from '../../../icons/OrderVoteIcon'

export  default  function OrderStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const colorIconStep = completed ?  theme =>theme.palette.primary.main : active ? theme => theme.palette.primary.contrastText: theme => theme.palette.text.disabled

  const icons: { [index: string]: React.ReactElement } = {
    1: <OrderIcon sx={{height: 25, width: 25, color: colorIconStep }} />,
    2: <AwaitingPickupIcon sx={{height:"100%", width: 25,  color: colorIconStep }} />,
    3: <ShippingIcon sx={{height:"100%", width: 25, color: completed || active ? theme=>theme.palette.primary.main : theme => theme.palette.text.disabled}}/>,
    4: <WaitingDeliveryIcon sx={{height:"100%", width: 25, color: colorIconStep}} />,
    5: <OrderVoteIcon sx={{height:"100%", width: 25, color: colorIconStep}} />
  };

  return (
    <ColorStepIconContainer ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorStepIconContainer>
  );
}
const ColorStepIconContainer = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: theme.palette.primary.main,
  padding: theme.spacing(1),
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  border:`solid 3px ${theme.palette.primary.main}`,
  justifyContent: 'center',
  alignItems: 'center',
  ...(!ownerState.active && {
     color: theme.palette.text.secondary,
    // backgroundColor: theme.palette.text.disabled
    '& .MuiStepper-root .MuiStep:not(.MuiStep-active) .MuiStepLabel-icon':{
      color: theme.palette.text.secondary
    },
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,

  }),
  ...(ownerState.completed && {
    color: theme.palette.primary.contrastText,

  }),
 '& .MuiStepper-root .MuiStep-active .MuiStepLabel-icon':{

  },


}));
