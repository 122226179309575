//Library
import {memo} from 'react'
import date from 'date-and-time'
import __ from 'languages/index'

//Helpers
import helpers from 'helpers'

//Interface
import {TypeDataPayment} from 'entities/mentor/mentor.interface'

interface TypeProps {
  paymentHistory: {
    listPaymentHistory: TypeDataPayment[]
    totalData: number
  }
  _onLoadMore: any
}

const DetailPaymentHistory = ({ paymentHistory, _onLoadMore }: TypeProps) => {
  const style = {
    display: 'grid',
    maxHeight: '500px',
    overflow: 'auto'
  }

  return (
    <div className="w-100">
      <div className="p-4 bg-white mt-4 rounded">
        <h4>{__('payment_history')}</h4>
        <div style={style}>
          {paymentHistory?.listPaymentHistory?.length ? (
            <>
              {paymentHistory?.listPaymentHistory?.map((item: TypeDataPayment, idx: number) => (
                <div
                  key={`paymentHistory_${idx}`}
                  className="d-flex flex-row justify-content-between text-capitalize"
                >
                  <p>{date.format(new Date(item?.createdAt), 'DD/MM/YYYY')}</p>
                  <p>{`${helpers.convertToCommasFormat(item?.transaction_value || '0')} ${__("currency_unit")} (${__(
                    item?.status
                  )})`}</p>
                </div>
              ))}
              {paymentHistory?.totalData > paymentHistory?.listPaymentHistory?.length && (
                <span className="mentorFilterProduct__loadMore" onClick={_onLoadMore}>
                  {__('payment_see_more_history')}
                </span>
              )}
            </>
          ) : (
            <span className="d-flex py-4">{__("payment_no_transactions")}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(DetailPaymentHistory)
