import {useCallback} from "react";
import {EnumLiveStreamSource} from "entities/event/event.interface";
import Button from "react-bootstrap/Button";
import {updateEntity} from "entities/livestream/livestream.store.reducer";
import {useAppDispatch, useAppSelector} from "config/store";
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";


interface TypedLivestreamPreviewDeviceScreenProps {
  // currentSourceStream: EnumLiveStreamSource,
}

export default function LivestreamPreviewDeviceOutside({}: TypedLivestreamPreviewDeviceScreenProps) {
  const liveDetail = useAppSelector(state => state.livestream.entity);
  const dispatch = useAppDispatch();
  const toast = useToast()

  const onConfirm = useCallback(async () => {
    dispatch(updateEntity({_id: liveDetail?._id, input_type: EnumLiveStreamSource.OutSide}))
  }, [])

  const onCopyLink = useCallback(() => {
    navigator.clipboard.writeText(liveDetail?.livestream_data?.rtmp_url || "")
      .then(() => {
        toast.show({
          type: EnumTypeToast.Success,
          content: `${__("livestream_outside_message_copy_success")}`
        })
      })
      .catch((error) => {
        toast.show({
          type: EnumTypeToast.Error,
          content: `${__("livestream_outside_message_copy_error")}`
        })
      });
  }, [liveDetail?.livestream_data?.rtmp_url])


  const onCopyKey = useCallback(() => {
    navigator.clipboard.writeText(liveDetail?.livestream_data?.stream_key || "")
      .then(() => {
        toast.show({
          type: EnumTypeToast.Success,
          content: `${__("livestream_outside_message_copy_key_success")}`
        })
      })
      .catch((error) => {
        toast.show({
          type: EnumTypeToast.Error,
          content: `${__("livestream_outside_message_copy_key_error")}`
        })
      });
  }, [liveDetail?.livestream_data?.stream_key])

  return (
    <div className="livestreamSelection">

      <div className="d-flex w-100 justify-content-around my-3">
        <Button className="py-3" onClick={onCopyKey}>
          {__("livestream_outside_copy_key")}
        </Button>
        <Button className="py-3" onClick={onCopyLink}>
          {__("livestream_outside_copy_link")}
        </Button>
      </div>

      {
        liveDetail?.input_type !== EnumLiveStreamSource.OutSide ?
          <div className="livestreamSelection_note mb-4">
            {__("livestream_outside_text_note1")}

          </div>
          :
          <div className="livestreamSelection_note mb-1">
            {__("livestream_outside_text_note2")}
          </div>
      }
      <div className="livestreamSelection_note mb-4">
        <b>{__("livestream_outside_text_note3")}</b>
      </div>

      {liveDetail?.input_type !== EnumLiveStreamSource.OutSide &&
        <Button className="livestreamSelection_obs_btn" onClick={onConfirm}>
          {__("livestream_outside_btn_confirm")}
        </Button>}
    </div>
  )
}
