import { SvgIcon, SvgIconProps } from '@mui/material'

const ChallengeSidebarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="32"
        viewBox="0 0 35 32"
        fill="none"
      >
        <path
          d="M28.5539 5.01051C26.3347 4.12131 24.2523 5.46651 22.0483 4.40251C21.9191 4.34171 21.8507 4.21251 21.8735 4.07571C22.0635 2.92051 21.8279 1.74251 22.0179 0.587305C22.0483 0.397305 22.2535 0.275705 22.4511 0.328905C24.4651 0.860905 26.4487 -0.248695 28.4703 0.252905C28.7135 0.313705 28.8883 0.518905 28.8959 0.754505C28.9339 2.20611 28.9719 3.28531 29.0099 4.73691C29.0099 4.94971 28.7819 5.09411 28.5691 5.01051H28.5539Z"
          fill="url(#paint0_linear_2408_2315)"
        />
        <path
          d="M21.9423 6.98644C21.6991 6.98644 21.5015 6.80404 21.5015 6.57604V0.450439C21.5015 0.222439 21.6991 0.0400391 21.9423 0.0400391C22.1855 0.0400391 22.3831 0.222439 22.3831 0.450439V6.57604C22.3831 6.80404 22.1855 6.98644 21.9423 6.98644Z"
          fill="#414042"
        />
        <path
          d="M33.5931 31.6863H8.52832L13.2403 22.1559L13.5291 21.5859L13.7875 21.0615L17.5191 13.5299L21.4787 11.1283L22.0259 10.7939L26.5251 13.8491L34.6343 30.2271C34.9763 30.9111 34.4291 31.6939 33.6083 31.6939L33.5931 31.6863Z"
          fill="url(#paint1_linear_2408_2315)"
        />
        <path
          opacity="0.5"
          d="M31.6475 31.6862H8.52832L13.2403 22.1482L13.5291 21.5782L13.7875 21.0538L17.5115 13.5222L21.4711 11.1206L22.1627 12.4962L31.6475 31.6862Z"
          fill="url(#paint2_linear_2408_2315)"
        />
        <path
          d="M21.2498 31.6861H1.64937C0.752568 31.6861 0.220568 30.7741 0.699368 30.0825L6.26257 22.1633L10.5718 19.7617L13.5282 21.5857L14.6454 22.2697L21.2498 31.6861Z"
          fill="url(#paint3_linear_2408_2315)"
        />
        <path
          d="M18.1256 13.4615C18.422 13.4615 18.7032 13.4995 18.9768 13.5679C19.296 13.6515 19.6228 13.5603 19.8584 13.3247C20.3828 12.8003 21.1048 12.4811 21.9028 12.4811C22.7008 12.4811 23.3544 12.7775 23.8712 13.2563C24.0536 13.4311 24.312 13.5147 24.5552 13.4843C24.692 13.4691 24.8288 13.4615 24.9656 13.4615C25.5204 13.4615 26.0524 13.5983 26.516 13.8415L22.4652 5.65628C22.2448 5.21548 21.6292 5.21548 21.4088 5.65628L17.5176 13.5223C17.7152 13.4843 17.9204 13.4691 18.1256 13.4691V13.4615Z"
          fill="white"
        />
        <path
          d="M13.7862 21.0537L11.1034 17.2385C10.769 16.7597 10.0622 16.7597 9.72778 17.2385L6.26978 22.1557C6.39898 22.1481 6.52058 22.1405 6.64978 22.1405C6.94618 22.1405 7.23498 22.1709 7.50858 22.2165C7.82778 22.2773 8.16218 22.2165 8.39778 22.0493C8.92978 21.6845 9.65177 21.4565 10.4574 21.4565C11.263 21.4565 11.9166 21.6617 12.441 22.0037C12.631 22.1253 12.8818 22.1861 13.1326 22.1633C13.2694 22.1481 13.4062 22.1481 13.5506 22.1481C13.9306 22.1481 14.2954 22.1937 14.6374 22.2773L13.7862 21.0613V21.0537Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2408_2315"
            x1="24.6606"
            y1="0.130858"
            x2="26.3564"
            y2="5.34985"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F95F53" />
            <stop offset="1" stopColor="#EF332E" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2408_2315"
            x1="15.7331"
            y1="21.2363"
            x2="28.5923"
            y2="21.2363"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#69C6FF" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2408_2315"
            x1="24.0703"
            y1="22.9614"
            x2="18.0435"
            y2="27.2174"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.53" stopColor="#0E91E2" stopOpacity="0" />
            <stop offset="0.58" stopColor="#0E91E2" stopOpacity="0.03" />
            <stop offset="0.64" stopColor="#0E91E2" stopOpacity="0.12" />
            <stop offset="0.72" stopColor="#0E91E2" stopOpacity="0.26" />
            <stop offset="0.8" stopColor="#0E91E2" stopOpacity="0.47" />
            <stop offset="0.9" stopColor="#0E91E2" stopOpacity="0.72" />
            <stop offset="0.99" stopColor="#0E91E2" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2408_2315"
            x1="4.65137"
            y1="25.7201"
            x2="16.0134"
            y2="25.7201"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#69C6FF" />
            <stop offset="1" stopColor="#0E91E2" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default ChallengeSidebarIcon
