import __ from "languages/index";

export const ORDER_SORT_OPTIONS = [
  {
    title: __('order_status_total'),
    value: ""
  },
  {
    title: __("order_status_pending"),
    value: "pending"
  },
  {
    title: __('order_status_processing'),
    value: "processing"
  },
  {
    title: __('order_status_deny'),
    value: "deny"
  },
  {
    title: __('order_status_cancel'),
    value: "cancel"
  },
  {
    title: __('order_status_success'),
    value: "success"
  },
  {
    title: __('order_status_close'),
    value: "close"
  },
];
export const ORDER_STATUS = [
  {
    title: __('order_status_option'),
    value: ""
  },
  {
    title: __('order_status_success'),
    value: "success"
  },
  {
    title: __('order_status_cancel'),
    value: "close"
  },
];
