import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  styled
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import Grid from '@mui/material/Unstable_Grid2'
import BigLayout from 'layouts/bigLayout'
import ShopBreadcrumb from './components/shop.breadcrumb'
import __ from 'languages/index'
import { BoxHalvedIcon, Menu1Icon, MenuBoxIcon, UserAddIcon } from 'icons'
import { useEffect, useState } from 'react'
import ProductCategory from './components/shop.admin.category'
import ProductGroups from './components/shop.admin.product.groups'
import BannerComponent from './components/shop.admin.banner'
import SkyscraperIcon from '../../icons/SkyscraperIcon'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import StockDetail from 'entities/shop/components/StockDetail'
import { getCategoryById, getCollectionById, getStockById } from 'entities/shop/store/shop.store.reducer'
import { AxiosError } from 'axios'
import { useAppDispatch } from 'config/store'
import ShippingUnit from './components/ShippingUnits.stock'
import CarIcon from 'icons/CarIcon'
import ConfigRoad from './components/shop.admin.configRoad'
import ExternalCustomers from './components/shop.admin.externalCustomers'
import ListProductCategory from 'entities/shop/components/ListProductCategory'
import ListProductCollection from './components/ListProductCollection'
import  ProductStockRoom from './components/shop.admin.product.stock'

const ShopAdministration = () => {
  const { hash, pathname } = useLocation()
  const navigate = useNavigate()

  const [menuIndex, setMenuIndex] = useState<number>(0)
  const [stockName, setStockName] = useState<string>('')
  const [categoryName, setCategoryName] = useState<string>('')
  const [collectionName, setCollectionName] = useState<string>('')
  const { stock_id, shipping_units, category_id, collection_id } = useParams()

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!!stock_id) {
      dispatch(getStockById(stock_id))
        .unwrap()
        .then(res => {
          setStockName(res?.data?.stock_name)
        })
        .catch((e: AxiosError) => {
          console.log('getStockById', e)
        })
    }
    if(!!category_id){
      dispatch(getCategoryById(category_id))
        .unwrap()
        .then(res => {
          setCategoryName(res?.data?.category_name)
        })
        .catch((e:AxiosError)=>{
          console.log('getCategoryById', e)
        })
    }
    if(!!collection_id){
      dispatch(getCollectionById(collection_id))
        .unwrap()
        .then(res => {
          setCollectionName(res?.data?.collection_name)
        })
        .catch((e:AxiosError)=>{
          console.log('getCollectionById', e)
        })
    }
  }, [stock_id, category_id, collection_id])

  const menuData = [
    {
      label: 'Banner',
      hash: '#banner',
      icon: <MenuBoxIcon color="inherit" className="menu_icon" />
    },
    {
      label: __('product_portfolio'),
      hash: '#portfolio',
      icon: <Menu1Icon color="inherit" className="menu_icon" />
    },
    {
      label: __('product_groups'),
      hash: '#groups',
      icon: <BoxHalvedIcon color="inherit" className="menu_icon" />
    },
    {
      label: __('product_stockroom'),
      hash: '#stockroom',
      icon: <SkyscraperIcon color="inherit" className="menu_icon" />
    },
    {
      label: __('config_shipping_units'),
      hash: '#configRoad',
      icon: <CarIcon color="inherit" className="menu_icon" />
    },
    {
      label: __('external_customers'),
      hash: '#external_customers',
      icon: <UserAddIcon color="inherit" className="menu_icon" />
    }
  ]

  const _ComponentViewRightBar = {
    0: <BannerComponent />,
    1: category_id ? <ListProductCategory/> : <ProductCategory />,
    2:  collection_id ? <ListProductCollection/> :<ProductGroups />,
    3:
      shipping_units === 'shippingunits' ? (
        <ShippingUnit />
      ) : stock_id ? (
        <StockDetail />
      ) : (
        <ProductStockRoom/>
      ),
    4: <ConfigRoad />,
    5: <ExternalCustomers />
  }

  useEffect(() => {
    switch (hash) {
      case '#banner':
        setMenuIndex(0)
        break
      case '#portfolio':
        setMenuIndex(1)
        break
      case '#groups':
        setMenuIndex(2)
        break
      case '#stockroom':
        setMenuIndex(3)
        break
      case '#configRoad':
        setMenuIndex(4)
        break
      case '#external_customers':
        setMenuIndex(5)
        break
      default:
        setMenuIndex(0)
    }
  }, [hash])

  return (
    <BigLayout>
      <ShopAdministrationContainer>
        <ShopBreadcrumb
          breadcrumbName={{
            '/shop/admin': __('shop_administration'),
            [`/shop/admin/stock/${stock_id}`]: `${stockName}`,
            [`/shop/admin/category/${category_id}`]: `${categoryName}`,
            [`/shop/admin/collection/${collection_id}`]: `${collectionName}`

          }}
        />
        <Grid2 container spacing={3} columns={7} sx={{ margin: 0 }}>
          <Grid xs={2} p={theme => theme.spacing(0, 1.5, 0, 0)}>
            <Paper
              elevation={1}
              sx={{
                p: theme => theme.spacing(1),
                border: theme => `1px solid ${theme.palette.divider}`
              }}
            >
              <MenuList className="menu_list">
                {menuData?.map((item, idx) => (
                  <MenuItem
                    onClick={() => navigate(`/shop/admin${item?.hash}`)}
                    key={idx}
                    selected={menuIndex === idx}
                  >
                    <ListItemIcon
                      sx={{ color: menuIndex === idx ? 'primary.main' : 'text.primary' }}
                    >
                      {item?.icon}
                    </ListItemIcon>
                    <Typography
                      fontWeight={500}
                      color={menuIndex === idx ? 'primary.main' : 'text.primary'}
                      variant="inherit"
                      noWrap
                    >
                      {item?.label || ''}
                    </Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grid>
          <Grid xs={5} p={theme => theme.spacing(0, 0, 0, 1.5)}>
            {_ComponentViewRightBar[menuIndex]}
          </Grid>
        </Grid2>
      </ShopAdministrationContainer>
    </BigLayout>
  )
}

export default ShopAdministration

const ShopAdministrationContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(2),
  '& .menu_list': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(0.5),
    '& li': {
      padding: theme.spacing(1.25, 2),
      borderRadius: theme.spacing(1.5)
    }
  },
  '& .menu_icon': {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))
