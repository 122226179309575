import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Modal,
  OutlinedInput,
  Stack,
  styled,
  Typography
} from '@mui/material'
import {ModalBody, ModalContainer, ModalHeader} from 'components/Modal'
import __ from 'languages/index'
import {CloseIcon, SearchIcon} from '../../../icons'
import {Image} from 'react-bootstrap'
import PlusIcon from '../../../icons/PlusIcon'
import imageDefault from 'media/images/image_default.png'
import {useAppDispatch} from 'config/store'
import {TypedProduct} from 'entities/shop/interface'
import {InfiniteScrollContainer} from 'components/InfiniteScrollContainer'
import {useInfiniteScroll} from '../../../hooks/useInfiniteScroll'
import {
  addProductToStock,
  updateProductToCollection
} from 'entities/shop/store/shop.store.reducer'
import {EnumTypeToast, useToast} from '../../../hooks/useToast'
import {AxiosError} from 'axios'
import __helpers from 'helpers/index'
import {formatString} from 'entities/support/utils'
import {  getProducts, updateCategoryProducts } from 'entities/shop/service/product.service'

interface TypeAddProductToStockModal{
  isShowAddProductModal: boolean
  handleCloseAddProductModal:()=>void
  setAddProduct:(item:TypedProduct) => void
  stock_id?: string
  category_id?: string
  collection_id?: string
}

const AddProductModal = ({handleCloseAddProductModal, isShowAddProductModal, stock_id, setAddProduct, category_id, collection_id} : TypeAddProductToStockModal)=>{
  const dispatch = useAppDispatch()
  const toast = useToast()
  const isStockId= Boolean(!!stock_id && !category_id && !collection_id)
  const isCategoryId= Boolean(!!category_id && !collection_id)

  const {
    hasMore,
    isRefreshing,
    loadMore,
    data,
    updateParamRequestDebounce,
    deleteItemById,
    refresh
  } = useInfiniteScroll<TypedProduct>({
    [isStockId ? `stock_id` : isCategoryId ? `category_id` : `collection_id`]: isStockId ? `!${stock_id}`  : isCategoryId ? `!${category_id}` : `!${collection_id}`,
    limit: 20,
    order_by: 'DESC',
    keyAttribute:"product_id"
  }, getProducts)


  const handleAddProduct = useCallback(async (product: TypedProduct) => {
      if (isStockId) {
        dispatch(addProductToStock({
          stock_id: stock_id,
          product_id: product?.product_id,
          product_sku: product?.product_sku,
          product_quantity: 0
        }))
          .unwrap()
          .then((res) => {
            deleteItemById(res?.data?.product_id)
            refresh(true)
            setAddProduct(res?.data)
            toast.show({
              content: `${__('update_product_to_stock_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((e: AxiosError) => {
            toast.show({
              content: `${__('update_product_to_stock_failed')}`,
              type: EnumTypeToast.Error
            })
            console.log('updateProductToStock', e)
          })
      }
      if(isCategoryId) {
        try {
           await updateCategoryProducts({
            category_id: category_id,
            product_id: [product?.product_id]
          })
             .then((res)=>{
               if(!!res){
                 setAddProduct(product)
               }
               refresh(true)
                toast.show({
                  content: `${__('update_product_to_category_success')}`,
                  type: EnumTypeToast.Success
                })
            })

        } catch (err) {
          toast.show({
            content: `${__('update_product_to_category_failed')}`,
            type: EnumTypeToast.Error
          })
          setAddProduct(null)
          console.log('err', err)
        }
      }

      if(!isStockId && !isCategoryId && !!collection_id){
        dispatch(updateProductToCollection({
          collection_id: [collection_id],
          product_id: product?.product_id,
        }))
          .unwrap()
          .then((res) => {
            deleteItemById(res?.data?.product_id)
            refresh(true)
            setAddProduct(res?.data)
            toast.show({
              content: `${__('update_product_to_stock_success')}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((e: AxiosError) => {
            toast.show({
              content: `${__('update_product_to_stock_failed')}`,
              type: EnumTypeToast.Error
            })
            console.log('updateProductToStock', e)
          })

      }

    },[])
  const handleSearchProduct = (event) => {
    updateParamRequestDebounce({query: event.target.value});
  };



  return(
    <AddProductModalContainer
      disableScrollLock
      open={isShowAddProductModal}
      onClose={handleCloseAddProductModal}
    >
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(115.125),
          margin: '0 auto'
        }}
      >
          <Box className="header">
            <ModalHeader direction={'row'}>
              <Typography variant='h6' textTransform='none' className="title">
                { isStockId ? `${__('product_to_stock_add')}` :
                  isCategoryId ? `${__('product_to_category_add')}` : `${__('product_to_collection_add')}`}
              </Typography>
              <IconButton onClick={handleCloseAddProductModal}>
                <CloseIcon />
              </IconButton>
            </ModalHeader>
            <Divider sx={{ opacity: 1 }} />
          </Box>
          <ModalBody
            className="body"
            sx={{ paddingTop: theme => theme.spacing(0), }}
          >
            <Box>
              <OutlinedInput
                type="search"
                id="product_search"
                className="stock_product_search"
                placeholder={__('manage_product')}
                autoFocus
                startAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      sx={{ padding: 0, width: '100%'}} >
                      <SearchIcon sx={{ width: theme => theme.spacing(3), height: theme => theme.spacing(3), marginRight: theme => theme.spacing(1)}} color="inherit" />
                    </IconButton>
                  </InputAdornment>}
                aria-describedby="outlined-helper-text"
                sx={{borderRadius: theme => theme.spacing(1.5)}}
                onChange={handleSearchProduct}
              />
            </Box>
            <Typography variant='subtitle2' sx={{paddingBottom: theme => theme.spacing(1)}}>{__("stock_product_list")}</Typography>
            <Box id={"scrollableDiv"} height={theme => `calc(100vh - ${theme.spacing(37.5)})`} overflow='auto'>
                <List
                  id={"scrollableDiv"}
                  className="stock_product_list"
                  dense
                  component="div"
                  role="list"
                >
                  <InfiniteScrollContainer
                    isRefreshing={isRefreshing}
                    dataLength={data.length}
                    next={loadMore}
                    hasMore={hasMore}
                    refreshFunction={refresh}
                    scrollableTarget={"scrollableDiv"}
                  pullDownToRefresh={false}>
                  {data?.map((product, idx) => (
                    <ListItem key={idx} role="listitem" className='stock_product_listItem'>
                      <Stack direction={'row'} alignItems='center' >
                        <Box sx={{paddingRight: theme => theme.spacing(2)}}>
                          <Image style={{ height: '50px', width: '50px', objectFit: 'cover' }} src={product?.product_avatar ? product?.product_avatar[0]: imageDefault}/>
                        </Box>
                        <Stack direction={'column'}>
                          <Typography color={'text.primary'} variant={'subtitle2'}>
                            {product?.product_name}
                          </Typography>
                          <Typography color={'text.secondary'} variant={'body1'} >
                            {formatString(__('stock_product_price'), __helpers.formatNumberWithCommas(product?.product_price))}
                          </Typography>
                          <Typography color={'primary.main'} variant={'body1'}>
                            {product?.product_code}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Button
                        onClick={() => handleAddProduct(product)}
                        size={'small'}
                        sx={{padding: theme => theme.spacing(1,1)}}
                      >
                        <IconButton sx={{ p: theme => theme.spacing(0, 1, 0, 0) }}>
                          <PlusIcon sx={{ width: '100%', height: '100%' , color: 'primary.contrastText' }}/>
                        </IconButton>
                        <Typography variant="body1" textTransform= 'none'>
                          {__("product_to_stock_btn_add")}
                        </Typography>
                      </Button>
                    </ListItem>
                  ))}
                  </InfiniteScrollContainer>
                </List>
            </Box>
          </ModalBody>
      </ModalContainer>
    </AddProductModalContainer>
  )
}
export default AddProductModal
const AddProductModalContainer = styled(Modal)(({theme})=>({
  zIndex: 2,
  '& .stock_product_list':{
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1.5),
    // maxHeight: `calc(100% - ${theme.spacing(20)})`
  },
  '& .stock_product_listItem':{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(1.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  '& .stock_product_search':{
    borderColor: theme.palette.text.secondary,
    display: "flex",
    marginBottom: theme.spacing(3)
  },
}))
