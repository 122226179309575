import Footer from "./footer";
import __ from "languages/index";
import _Helmet from "components/helmet";
import HeaderComponent from "../layouts/Header";
import React from "react";


export default function TOS() {

    return (
      <div id="single_page">
        <_Helmet title='tos_policy' />
        <HeaderComponent />

        <header className="py-5 border-bottom" id="page_hero">
          <div className="container bd-gutter pt-md-1 pb-md-4 d-flex align-items-center">
            <div className="ovf" style={{ maxWidth: '600px' }}>
              <h1 className="bd-title fs-1" style={{marginTop: 50, letterSpacing: 0}}>{__("tos_policy")}</h1>
              <p className="small text-white">{__("tos_policy_text")}</p>
              <p className="text-white">
                {__("tos_policy_sub_text")}
              </p>
            </div>
          </div>
        </header>

        <main className="py-5">
          <div className="container bd-gutter pt-md-1 pb-md-4">
            <h5>{__("tos_respect_cooperation")}</h5>
            <p>
              {__("tos_respect_cooperation_text")}
            </p>

            <h5>{__("tos_content")}</h5>
            <p>
              {__("tos_content_text")}
            </p>

            <h5>{__("tos_security")}</h5>
            <p>
              {__("tos_security_text")}
            </p>

            <h5>{__("tos_manage_account")}</h5>
            <p>
              {__("tos_manage_account_text")}
            </p>

            <h5>{__("tos_copyright")}</h5>
            <p>
              {__("tos_copyright_text")}
            </p>

            <h5>{__("tos_report")}</h5>
            <p>
              {__("tos_report_text")}
            </p>
            <p>
              {__("tos_report_sub_text")}
            </p>
            <p>{__("tos_regards")}</p>
            <p>{__("channel_gamifa")}</p>
          </div>
        </main>

        <Footer />
      </div>
    )
}
