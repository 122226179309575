import { memo } from 'react'

const SkeletonMember = () => {
  return (
    <div>
      <p className="card-text placeholder-glow mt-4 mb-4">
        <span className="placeholder col-7"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-6"></span>
        <span className="placeholder col-8"></span>
      </p>
      <p className="card-text placeholder-glow mt-4 mb-4">
        <span className="placeholder col-7"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-6"></span>
        <span className="placeholder col-8"></span>
      </p>

      <p className="card-text placeholder-glow mt-4 mb-4">
        <span className="placeholder col-7"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-6"></span>
        <span className="placeholder col-8"></span>
      </p>
    </div>
  )
}

export default memo(SkeletonMember)
