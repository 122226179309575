import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckboxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
      >
        <path
          d="M0.850586 4C0.850586 2.067 2.41759 0.5 4.35059 0.5H16.3506C18.2836 0.5 19.8506 2.067 19.8506 4V16C19.8506 17.933 18.2836 19.5 16.3506 19.5H4.35059C2.41759 19.5 0.850586 17.933 0.850586 16V4Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}

export default CheckboxIcon
