import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointer, faImage} from "@fortawesome/free-solid-svg-icons";
import Image from "react-bootstrap/Image";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ColorResult, SketchPicker} from 'react-color'
import TypedChannel from "../../../interfaces/channel.interface";
import helpers from "helpers/index";
import TextField from "components/TextField";
import {notEmpty, useField, useForm} from "@shopify/react-form";
import ImgBlur from "entities/courses/media/img_blur.png";
import {uploadMedia} from "entities/courses/services/courses.media.service";
import {createContact} from "entities/mobileApp/mobile.app.store.reducer";
import {useAppDispatch, useAppSelector} from "config/store";
import MobileModalPayment from "entities/mobileApp/components/MobileModalPayment";
import {ListPlan} from 'entities/payment/payment.interface'
import {getPlan} from "entities/payment/payment.store.reducer";
import {createOrder} from "entities/orders/order.store.reducer";
import {dataPaymentMethod} from "../../helpers/payment.helpers";
import __ from "languages/index";
import {useNavigate} from "react-router-dom";

interface TypeMobileAppRegistration{
  channel: TypedChannel;
  listPaymentMethod: string[];
}
const MobileRegistrationForm: React.FC<TypeMobileAppRegistration> = ({channel,listPaymentMethod}) =>{
  const [color, setColor] = useState<ColorResult>({hex:"#ffffff"});
  const [imageLogoUrl, setImageLogoUrl] = useState<string>('')
  const [mobileId, setMobileId] = useState<string>('')
  const [loadingImageLogo, setLoadingImageLogo] = useState<boolean>(false)
  const imageLogoRef = useRef(null)
  const [imageHighlightUrl, setImageHighlightUrl] = useState<string>('')
  const [mediaLogoUrl, setMediaLogoUrl] = useState<string>('')
  const [mediaHigtlightUrl, setMediaHightUrl] = useState<string>('')
  const [loadingImageHighlight, setLoadingImageHighlight] = useState<boolean>(false)
  const imageHighlightRef = useRef(null)
  const [isShowColorBoard,setIsShowColorBoard] = useState(false)
  const [isShowModal,setIsShowModal] = useState(false)
  const [selectPayment, setSelectPayment] = useState<number>(null)
  const plan_id = localStorage.getItem('plan_id')
  const plan: ListPlan = useAppSelector(state => state.payment.plan)
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    try{
      if(plan_id){
        dispatch(
            getPlan({ _id: plan_id})
        )
      }
    }
    catch (err) {
      console.log('err',err)
    }
  }, [plan_id]);

  function handleColorPickerChange(currentColor: ColorResult){
    setColor(currentColor);
  };

  const onChooseImageLogoChange = useCallback(async (event: Event | any) => {
    // if(shouldDisplayData) return;
    const maxSize = 512 * 512
    if (event.target.files[0] && event.target.files[0].size < maxSize) {
      setLoadingImageLogo(true)
      setImageLogoUrl(ImgBlur)
      const link = URL.createObjectURL(event.target.files[0])
      console.log('ImgBlur',ImgBlur)

      try {
        const res: any = await uploadMedia(event.target.files)

        if (Array.isArray(res) && res.length > 0) {
          imageLogoRef.current = res[0]?.callback
          setMediaLogoUrl(res[0]?.callback.media_url)
        }
      } catch (error) {
        console.log('errorLogo', error)
      }
      setImageLogoUrl(link)
      setLoadingImageLogo(false)
    }
  }, [])

  const onChooseImageHighlightChange = useCallback(async (event: Event | any) => {
    const maxSizeUpload = 1024 * 512
    if (event.target.files[0] && event.target.files[0].size < maxSizeUpload ) {
      setLoadingImageHighlight(true)
      setImageHighlightUrl(ImgBlur)
      const link = URL.createObjectURL(event.target.files[0])

      try {
        const res: any = await uploadMedia(event.target.files)

        if (Array.isArray(res) && res.length > 0) {
          imageHighlightRef.current = res[0]?.callback
          setMediaHightUrl(res[0]?.callback.media_url)
        }
      } catch (error) {
        console.log('error', error)
      }
      setImageHighlightUrl(link)
      setLoadingImageHighlight(false)
    }
  }, [])

  const handleSelectPayment = (param: number)=>  {
    setSelectPayment(param)
  }

  const onClickPayment = useCallback(async (planId: string) => {
    try {
      if(mobileId){
        const res: { payload?: { data?: { redirect_url?: string } } } = await dispatch(
          createOrder({
            plan_id: planId,
            amount_of_package: '1',
            payment_method: dataPaymentMethod(selectPayment),
            trans_id: mobileId,
          })
        )
        navigate(res?.payload?.data?.redirect_url)
      }

    } catch (err) {
      console.log(err,'err')
    }
  }, [selectPayment])

  /**
   *
   * @param e
   * @returns
   */

  const useFields = {
    nameApp: useField<string>({
      value: channel?.name ? channel?.name : '',
      validates: [
        notEmpty(`${__("mobile_app_registration_form_no_empty")}`),
      ]
    }),

    policy: useField<string>({
      value: '',
      validates: []
    }),

    term: useField<string>({
      value: '',
      validates: []
    }),

    home: useField<string>({
      value: '',
      validates: []
    }),

    channel_avatar: useField<string>({
      value: mediaLogoUrl ? mediaLogoUrl : channel?.avatar?.media_url,
      validates: [notEmpty(`${__("mobile_app_registration_form_upload_logo")}`)]
    }),

    color: useField<string>({
      value:'',
      validates: [
        notEmpty(`${__("mobile_app_registration_form_chose_color")}`),
      ]
    }),

    image: useField<string>({
      value: mediaHigtlightUrl ? mediaHigtlightUrl : '',
      validates: []
    }),

  }

  const { fields, submit, submitting, dirty, reset } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        const res :{ payload?: { data?:{_id: string}} } = await dispatch(createContact(
          {
            form_type: "mobile",
            full_name: values.nameApp,
            color: values.color,
            content: JSON.stringify({
              image_logo: values.channel_avatar,
              image_upload: values.image,
              url_home: values.home,
              url_policy: values.policy,
              url_term: values?.term,
            })
          }
        ));
        const mobileId= res?.payload?.data?._id
        setMobileId(mobileId)

        await helpers.sleep(2000)
        if(plan?.trial_day <=0){
          setIsShowModal(true)
        } else {
          try {
            const res: { payload?: { data?: { redirect_url?: string } } } = await dispatch(
                createOrder({
                  plan_id: plan?._id,
                  amount_of_package: '1',
                  payment_method: dataPaymentMethod(selectPayment),
                  trans_id: mobileId
                })
            )

            const redirectUrl = res?.payload?.data?.redirect_url

            navigate(redirectUrl)
          } catch (err) {
            console.log(err,'err')
          }
        }
      } catch (e: any) {
        console.log('err', e);
      }
      return { status: 'success' }
    }
  })

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    useFields.channel_avatar.onChange(mediaLogoUrl ? mediaLogoUrl : channel?.avatar?.media_url );
    useFields.color.onChange(color);
    useFields.image.onChange(mediaHigtlightUrl);
  }, [mediaLogoUrl, channel?.avatar?.media_url ,color, mediaHigtlightUrl]);
  const hanleShowCorlorPicker = () =>{
    setIsShowColorBoard(true)
  }
  function getComplementaryColor(hexColor: string) {
    hexColor = hexColor?.replace('#', '');

    const r = parseInt(hexColor?.slice(0, 2), 16);
    const g = parseInt(hexColor?.slice(2, 4), 16);
    const b = parseInt(hexColor?.slice(4, 6), 16);

    const complementaryR = 255 - r;
    const complementaryG = 255 - g;
    const complementaryB = 255 - b;

    const complementaryHex = `#${complementaryR.toString(16).padStart(2, '0')}${complementaryG.toString(16).padStart(2, '0')}${complementaryB.toString(16).padStart(2, '0')}`;

    return complementaryHex;
  }
  const handleCloseModalPayment = () =>{
    if(isShowModal){
      setIsShowModal(false)
    }
  }

  return(
    <div className='mobile-register-form ' >
      <h2 className='mobile-register-title'>{__("mobile_app_registration_form_title")}</h2>
      <p className='mobile-btn-change-color' style={{fontSize:10,marginBottom:30}}>{__("mobile_app_registration_form_sub_title")}</p>
      <div className='d-flex '>
        <div className='col-4 mobile-register-content h-100' style={{marginBottom:20}}>
          <div>{__("mobile_app_registration_form_logo")}</div>
          <div className="mobile-register-wrapper position-relative d-flex" >
            <Form.Group className="my-3" >
              <div className="file-drop-area form-group form-upload-image-event" style={{height: "auto"}} >
                <div className="text-lg-center w-100 d-flex flex-column text-center align-items-center " >
                  <div >
                    <FontAwesomeIcon icon={faImage}  style={{color: "#b9bcc0", fontSize: '20px'}} />
                  </div>
                  <button  className="btn btn-primary upload-button" >{__("mobile_app_registration_form_upload_photo")}</button>
                </div>
                { <Form.Control className="file-input" type="file" id="image" accept=".png,.jpg,.jpeg,.gif" onChange={onChooseImageLogoChange} />}
                <div className="error-image invalid-feedback">
                  {__("media_form_text")}
                </div>
                {
                  imageLogoUrl || channel?.avatar?.media_url ?
                    <Image id="target" className={`choose_file_image choose-file-button fit-image`} src={imageLogoUrl || channel?.avatar?.media_url} alt="placeholder" />
                    :
                    null
                }
                {
                  imageLogoUrl ? !loadingImageLogo ?
                      <div className="view-bottom-image" style={{fontSize: 8}}>{__("media_form_sub_text")}</div>
                      :
                      null
                    :
                    null
                }
              </div>
            </Form.Group>
          </div>
          <div className='mobile-error' style={{color:"#dc3545", fontSize: 12}}>
            {useFields.channel_avatar.error}
          </div>
        </div>
        <div className='col-8 mobile-register-content pt-1' style={{marginBottom:20}}>
          <TextField
            className='mb-3'
            label={__("mobile_app_registration_form_name_app")}
            maxLength={150}
            requiredIndicator
            {...fields.nameApp}
          />
        </div>
      </div>
      <div className='mobile-register-content' style={{marginBottom:20}}>
        <TextField
          className='mb-2'
          label={__("mobile_app_registration_form_policy")}
          maxLength={150}
          requiredIndicator
          {...fields.policy}
        />
        <TextField
          className='mb-2'
          label={__("mobile_app_registration_form_term")}
          maxLength={150}
          requiredIndicator
          {...fields.term}
        />
        <TextField
          className='mb-2'
          label={__("mobile_app_registration_form_url_home")}
          maxLength={150}
          requiredIndicator
          {...fields.home}
        />

      </div>
      <div className='mobile-register-content d-flex'>
        <div className='col-12 col-md-6 mobile-register-color' style={{marginBottom:20 }}>
          <label style={{marginBottom:12}}>{__("mobile_app_registration_form_color_code")}</label>
          {
            isShowColorBoard ? (
             <div className='mobile-register-color-picker'>
               <SketchPicker
                 color={color}
                 onChangeComplete={(currentColor:ColorResult)=>handleColorPickerChange(currentColor)}
                 // onChangeComplete={onChangeComplete}
                 disableAlpha={true}
                 style={{cursor: 'pointer'}}

               />
               <button className='btn mobile-btn-change-color mt-1' onClick={()=> setIsShowColorBoard(false)}>
                 <FontAwesomeIcon icon={faHandPointer} rotation={90} size="lg" style={{marginRight: 8}}/>
                 {__("mobile_app_registration_form_confirm_color_code")}
               </button>
             </div>
            ):(
              <div className='mobile-register-color-picker' >
                <div>{`${__("mobile_app_registration_form_color")} ${color?.hex}`}</div>
                <button
                  className="btn w-100 m-3"
                  type="button"
                  onClick={hanleShowCorlorPicker}
                  style={{border:"none",borderRadius:"50%", backgroundColor: `${color?.hex ?? "#fff"}`, height:200}}
                >
                  <div style={{color: getComplementaryColor(color?.hex)}}>{__("mobile_app_registration_form_change_color")}</div>
                </button>
              </div>
            )
          }
          {useFields.color.error}
        </div>
        <div className='col-12 col-md-6 mobile-register-content h-100' style={{marginBottom:20}}>
          <label>{__('mobile_app_registration_form_media')}</label>
          <div className="mobile-register-wrapper position-relative d-flex" >
            <Form.Group className="my-3" >
              <div className="file-drop-area form-group form-upload-image-event" style={{height: "310px"}} >
                <div className="text-lg-center w-100 d-flex flex-column text-center align-items-center " >
                  <div >
                    <FontAwesomeIcon icon={faImage}  style={{color: "#b9bcc0", fontSize: '50px'}} />
                    <p style={{marginTop: '14px'}}>{__("mobile_app_registration_form_size_media")}</p>
                  </div>
                  <button  className="btn btn-primary upload-button" >{__("mobile_app_registration_form_upload_photo")}</button>
                </div>
                { <Form.Control className="file-input" type="file" id="image" accept=".png,.jpg,.jpeg,.gif" onChange={onChooseImageHighlightChange} />}
                <div className="error-image invalid-feedback">
                  {__("media_form_text")}
                </div>
                {
                  imageHighlightUrl ?
                    <Image id="target" className={`choose_file_image choose-file-button fit-image`} src={imageHighlightUrl} alt="placeholder" />
                    :
                    null
                }
                {
                  imageHighlightUrl ? !loadingImageHighlight ?
                      <div className="view-bottom-image">{__("media_form_sub_text")}</div>
                      :
                      null
                    :
                    null
                }
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
      <button
        className="btn btn-primary w-100"
        type="button"
        disabled={!dirty}
        onClick={submit}
      >
        { submitting && (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>)}
        {__("mobile_app_registration_form_btn_continue")}
      </button>
      <MobileModalPayment
          isShowModal={isShowModal}
          handleCloseModalPayment={handleCloseModalPayment}
          listPaymentMethod={listPaymentMethod}
          plan={plan}
          handleSelectPayment={handleSelectPayment}
          selectPayment={selectPayment}
          onClickPayment={onClickPayment}
      />
    </div>
  )
}
export default MobileRegistrationForm
