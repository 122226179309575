import React from 'react'
import { Box, Button, Divider, Rating, Stack, styled, Typography } from '@mui/material'
import QuantityAdjustment from './QuantityAdjustment'
import { ShoppingBasketIcon } from 'icons'
import { IRootState, useAppDispatch } from 'config/store'
import { addItemToCart } from 'entities/shop/store/cart/cart.slice'
import { TypedProduct, TypedProductStock } from 'entities/shop/interface'
import helpers from 'helpers/index'
import __ from 'languages/index'
import { useSelector } from 'react-redux'
import SaleIcon from 'icons/SaleIcon'
import { formatString } from 'entities/support/utils'
import { productSoldQuantity } from '../helper'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

type PaymentMethod = 'COD' | 'VN_PAY'
type Props = {
  product: TypedProduct,
  isSoldOut: boolean,
  productSold: number
}
const ProductProperties = ({ product, isSoldOut,productSold }: Props) => {
  const { product_name } = product

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [currentQuantity, setCurrentQuantity] = React.useState(1)
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethod>('COD')
  const productReview = useSelector((state: IRootState) => state.shop.summarize);

  const toast = useToast()

  const getPromotion = (): boolean | number => {
    const originalPrice = product.product_original_price
    const discountPrice = product.product_price
    if (discountPrice === originalPrice) return false
    if (discountPrice < originalPrice) {
      return discountPrice / originalPrice
    }
  }

  const handleAddToCart = () => {
    dispatch(addItemToCart({ product, item_quantity: currentQuantity }))
    .unwrap()
    .then(()=>{
      toast.show({
        type: EnumTypeToast.Success,
        content: `${__('add_to_cart_success')}`
      })
    })
    .catch((error: AxiosError)=>{
      console.log('addItemToCart Error',error);
      toast.show({
        type: EnumTypeToast.Error,
        content: `${error}`
      })
      
    })
  }

  const handleChangeQuantity = (quantity: number) => {
    if (quantity < 1) return 
    if (quantity > productSoldQuantity(product)?.totalProduct) {
      return toast.show({
        type: EnumTypeToast.Warning,
        content: `${__('warningAddQuantityProduct')}`
      })
    }
    setCurrentQuantity(quantity)
  }

  const handleBuyItem = () => {
    //TODO: link to checkout page
    localStorage.setItem('product_cart', JSON.stringify([{product: {...product},item_quantity: 1, product_id: product?.product_id}]))
    navigate('/shop/payment')
  }

  const handleChoosePaymentMethod = (method: PaymentMethod) => {
    setPaymentMethod(method)
  }

  const discount = () => {
    return (100 - (product.product_price / product.product_original_price * 100)).toFixed(0)
  }

  return (
    <Stack gap={3} sx={containerStyled}>
      <Typography variant="h5" fontWeight={700}>
        {product_name}
      </Typography>
      <Stack direction="row" className="rating-info" alignItems="center" gap={2}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography
            variant={'subtitle1'}
            color={'primary'}
            fontWeight={600}
            sx={{ textDecoration: 'underline' }}
          >
            {productReview?.product_review_point ? productReview?.product_review_point.toFixed(1) : 0}
          </Typography>
          <Rating name="read-only" value={Number((productReview?.product_review_point || 0).toFixed(1))} precision={0.1} readOnly />
        </Stack>
        <Divider orientation="vertical" variant="fullWidth" flexItem sx={{opacity: 1}} />
        <Typography color="text.secondary" fontWeight={700}>
          Đã đánh giá {productReview?.product_review_count}
        </Typography>
        <Divider orientation="vertical" variant="fullWidth" flexItem sx={{opacity: 1}} />
        <Typography color="text.secondary" fontWeight={700}>
          {formatString(
              __('number_sold'),
              helpers.convertNumber(productSold || '0')
            )}
        </Typography>
      </Stack>

      <Box
        sx={{
          backgroundColor: theme => theme.palette.primary.background || theme.palette.grey[300],
          padding: theme => theme.spacing(2),
          position: 'relative'
        }}
        className="price"
      >
        {getPromotion() && (
          <Typography
            sx={{
              color: theme => theme.palette.text.secondary,
              textDecoration: getPromotion() ? 'line-through' : 'none'
            }}
            variant='h6'
          >
            {`${helpers.formatNumberWithCommas(product.product_original_price)} VND`}
          </Typography>
        )}
        <Typography color="primary" variant='h5'>
          {`${helpers.formatNumberWithCommas(product.product_price)} VND`}
        </Typography>
        {
          +discount() > 0 ? (
            <Box className="sale_icon" >
              <SaleIcon sx={{
                width: theme => theme.spacing(6.9),
                height: theme => theme.spacing(4.98)
              }} />

              <Typography className='discount' variant='caption' >
                {discount()}%
              </Typography>
            </Box>
          ) : null
        }

      </Box>

      <PropertyItem productToStock={product.product_to_stock || []} label={__('label_quantity')}>
        <QuantityAdjustment quantity={currentQuantity} onChangeQuantity={handleChangeQuantity} />
      </PropertyItem>
      {/*<PropertyItem label="Các hình thức thanh toán">*/}
      {/*  <PaymentMethodSelector*/}
      {/*    checked={paymentMethod === 'COD'}*/}
      {/*    onClick={() => handleChoosePaymentMethod('COD')}*/}
      {/*  >*/}
      {/*    COD*/}
      {/*  </PaymentMethodSelector>*/}
      {/*  <PaymentMethodSelector*/}
      {/*    checked={paymentMethod === 'VN_PAY'}*/}
      {/*    onClick={() => handleChoosePaymentMethod('VN_PAY')}*/}
      {/*  >*/}
      {/*    <img src={VNpayIcon} alt="VNpay" width={20} height={20} />*/}
      {/*  </PaymentMethodSelector>*/}
      {/*</PropertyItem>*/}

      <Stack direction="row" gap={2}>
        <Button disabled={isSoldOut} onClick={handleAddToCart} startIcon={<ShoppingBasketIcon />} variant="outlined">
          <Typography textTransform={'capitalize'} >
            {__('add_to_cart')}
          </Typography>
        </Button>
        <Button disabled={isSoldOut} onClick={handleBuyItem}>
          <Typography textTransform={'capitalize'}>{__('mobile_app_btn_buy')}</Typography>
        </Button>
      </Stack>
    </Stack>
  )
}

export default ProductProperties

const PropertyItem = ({ label, children, productToStock }: { label: string; children: React.ReactNode, productToStock: TypedProductStock[] }) => {
  const count = productToStock.reduce((res, item) =>{ 
      res += item.product_quantity

      return res
     }, 0)
  return (
    <Stack direction="row" alignItems={'center'} gap={1}>
      <Typography>{label}</Typography>
      {children}
      <Typography variant='body1' color={'#626F82'}>{count} sản phẩm có sẵn</Typography>
    </Stack>
  )
}

const containerStyled = {
  '& .rating-info': {
    '& .MuiRating-root': {
      fontSize: '1.2rem',
      color: '#fdd835'
    },
    '& .MuiRating-iconEmpty': {
      color: '#fdd835'
    },
    '& .MuiRating-iconFilled': {
      color: '#fdd835'
    }
  },
  '& .price': {
    '& .sale_icon': {
      position: 'absolute',
      right: '25px',
      top: '31px',

      '& .discount': {
        position: 'absolute',
        right: '24%',
        top: '45%',
        color: '#fff',
        fontWeight: 700
      }
    }
  }
}

const PaymentMethodSelector = styled(Box, {
  shouldForwardProp: propName => propName !== 'checked'
})<{ checked: boolean }>(({ theme, checked }) => ({
  borderRadius: theme.spacing(1.5),
  border: `1px solid  ${!checked ? theme.palette.divider : theme.palette.primary.main}`,
  padding: theme.spacing(0.5),
  backgroundColor: checked ? theme.palette.primary.background : 'transparent',
  cursor: 'pointer'
}))
