//Library
import { Box, Button, List, ListItem, Typography, styled } from '@mui/material'

//Components
import CardItem from 'entities/shop/components/shop.cardItem'

//Interface
import __, { ___ } from 'languages/index'
import { useNavigate } from 'react-router-dom'
import { TypedProduct } from '../interface'

const SuggestionToday = ({ suggestionProductData }: { suggestionProductData: TypedProduct[] }) => {
  const navigate = useNavigate();
  return (
    <SuggestionTodayContainer>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography textTransform={'none'} variant="h6">
          {__('suggestion_today')}
        </Typography>
        <Button  variant="text" onClick={() => navigate('/shop/search?sort=product_meta.product_review_count:desc')}>
          <Typography textTransform={'none'}>{__('header_see_all')}</Typography>
        </Button>
      </Box>
      <List
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
          gap: theme => theme.spacing(3)
        }}
      >
        {suggestionProductData?.map((data: TypedProduct, idx: number) => (
          <ListItem key={`item_${idx}`} sx={{ p: 0, width: '100%' }}>
            <CardItem productData={data} />
          </ListItem>
        ))}
      </List>
    </SuggestionTodayContainer>
  )
}

export default SuggestionToday

const SuggestionTodayContainer = styled(Box)(({ theme }) => ({}))
