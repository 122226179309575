import '../media/css/modal.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { Box, Divider, IconButton, styled, Typography, Modal, Stack, Theme } from '@mui/material'
import { ModalBody, ModalContainer, ModalHeader } from 'components/Modal'
import __ from 'languages/index'
import { CloseIcon } from '../../../icons'
import helpers from 'helpers/index'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import dateandtime from 'date-and-time'
import { TypedLogisticLog } from 'entities/shop/interface'
import { Field } from "@shopify/react-form"
import { getLogisticLog } from 'entities/shop/service/order.service'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'



export interface  TypeLogisticInformationModal{
  showModal:boolean;
  handleClose: ()=> void;
  customerName: Field<string>;
  customerPhone: Field<string>;
  logistic: string;
  customerAddress: ()=>string
  logisticCode: string;
}

const LogisticInformationModal: React.FC<TypeLogisticInformationModal> =({
      showModal,
      handleClose,
      customerName,
      customerPhone,
      customerAddress,
      logistic,
      logisticCode
  }) =>{
  const {  productId : orderId } = useParams();
  const [logisticLog, setLogisticLog] = useState<TypedLogisticLog[]>(null)


  useEffect(()=>{
    if(!orderId) return
    getLogisticLog({
      order_id: orderId,
      sort: "createdAt:desc",
    })
      .then((res: AxiosResponse)=>{
        setLogisticLog(res?.data)
      })
      .catch((error)=>{
        setLogisticLog(null)
        console.log(' getLogisticLog1error >>>',error);
      })
  },[orderId])

  const handleSetColorText = useCallback((data, theme: Theme)=>{
    switch (data?.log_type) {
      case 'success':
        return `${theme.palette.text.primary}`
      case 'warning':
        return `${theme.palette.warning.main}`
      case 'error':
        return `${theme.palette.error.main}`
      default:
        return `${theme.palette.text.primary}`
    }
  },[logisticLog])

  return(
    <LogisticInformationModalContainer
      open={showModal}
      onClose={handleClose}
    >
      <ModalContainer
        sx={{
          width: '100%',
          maxWidth: theme => theme.spacing(115.125),
          margin: '0 auto'
        }}
      >
        <Box className="header">
          <ModalHeader
            direction={'row'}
          >
            <Typography
              variant='h6'
              textTransform='none'
              className="title"
            >
              {__("logistic_information_modal")}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          <Divider sx={{ opacity: 1 }} />
        </Box>
        <ModalBody
          className="body"
          sx={{ paddingTop: theme => theme.spacing(0), }}
        >

          <Stack
            display={'grid'}
            direction={'row'}
            gridAutoFlow={'column'}
            columnGap={theme => theme.spacing(3)}
            gridTemplateColumns={'0.7fr auto 1fr'}
          >
            <Stack
              direction={'column'}
            >
              <Box>
                <Typography
                  variant={'subtitle2'}
                  color={'primary.main'}
                  textTransform='none'
                >
                  {__('shop_order_info')}
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  sx={{
                    padding: theme => theme.spacing(1, 0)
                  }}
                >
                  {customerName.value}
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  textTransform='none'
                >
                  {customerPhone.value}
                </Typography>

                <Typography
                  variant='body1'
                  color={'text.primary'}
                  textTransform='none'
                  sx={{
                    padding: theme => theme.spacing(1, 0)
                  }}
                >
                  {customerAddress()}
                </Typography>

              </Box>
              <Box sx={{paddingTop: theme=>theme.spacing(1.25)}}>
                <Typography
                  variant={'subtitle2'}
                  color={'primary.main'}
                  textTransform='none'
                >
                  {__('logistic_unit')}
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  sx={{
                    padding: theme => theme.spacing(1, 0)
                  }}
                >
                  {logistic}
                </Typography>
                <Typography
                  variant='body1'
                  color={'text.primary'}
                  textTransform='none'
                >
                  {logisticCode}
                </Typography>

              </Box>
            </Stack>
            <Divider orientation= 'vertical'
                     sx={{ opacity: 1 }} flexItem />

            <Stack direction={'column'}>
              <Typography
                variant={'subtitle2'}
                textTransform='none'
                paddingTop={theme => theme.spacing(1)}
              >
                {__('logistic_detail')}
              </Typography>
              <Box sx={{ maxWidth: '60%', minWidth: '60%', }} className='shop_order_info_step'>
                {helpers.isEmpty(logisticLog) ? <Typography>{__('no_information_order')}</Typography> : (
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}`]: {
                        height:'100%',
                      },
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}>
                    {logisticLog?.map((step, index) => (
                      <Stack key={index} sx={{display:'grid', alignItems: 'flex-start', gridTemplateColumns: '1fr 2fr'}}>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot />
                            {index + 1 < logisticLog?.length && <TimelineConnector />}
                          </TimelineSeparator>
                          <TimelineContent fontWeight={!index ? 700 : 400}>{dateandtime.format(new Date(Number(step?.createdAt)), 'HH:mm DD/MM/YYYY')}</TimelineContent>
                        </TimelineItem>
                        <Box sx={{p: theme => theme.spacing(0.75, 0, 0.75, 1.25, )}}>
                          <Typography fontWeight={!index ? 700 : 600} variant='subtitle2' color={theme => handleSetColorText(step, theme)} textTransform='none'>{step?.log_title || ''}</Typography>
                          <Typography fontWeight={!index ? 600 : 400} color={theme => handleSetColorText(step, theme)}>{step?.log_content || ''}</Typography>
                        </Box>
                      </Stack>
                    ))}
                  </Timeline>
                )}
              </Box>
            </Stack>
          </Stack>

        </ModalBody>

      </ModalContainer>
    </LogisticInformationModalContainer>

  )
}
export  default LogisticInformationModal;
const LogisticInformationModalContainer = styled(Modal)(({theme}) =>({

}))
