import __ from "languages/index";
import {EmptyIcon} from "../icons";
import {Box, Typography} from "@mui/material";
import React from "react";

interface TypeComponentEmpty{
  text?: string
}

export default function ComponentEmpty({text= `${__(`not_found`)}`} : TypeComponentEmpty) {
  return (
    <Box
      sx={{
        display: 'grid',
        placeItems: 'center'
      }}
    >
      <EmptyIcon
        sx={{
          width: '150px',
          height: '150px'
        }}
      />
      <Typography textTransform={'none'} color={'text.disabled'} fontWeight={400}>
        {text}
      </Typography>
    </Box>
  );
}
