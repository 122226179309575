import axios from 'axios'
import { IQueryParams } from 'config/reducer.utils'
import { TypedReviewOrder } from '../interface'

export const getListOrders = (params: IQueryParams) => {
  const url = `v2/orders`
  return axios.get(url, { params: params })
}
export const getOrderDetail = (order_id: string) => {
  const url = `v2/orders/${order_id}`
  return axios.get(url)
}
export const getMyUnReview = (params?: IQueryParams) => {
  const url = `v2/review/my_unreview`
  return axios.get<TypedReviewOrder[]>(url, { params: params })
}
export const createReviewOfOrder = (review_id: string, body: any) => {
  const url = `v2/review/${review_id}`
  return axios.post(url, body)
}
export const caculatorFeeOfOrder = (body: any) => {
  const url = `v2/logistic_service/fee_caculator`
  return axios.post(url, body)
}
export const cancelMyOrder = (orderId: string) => {
  const url = `v2/orders/cancel_my_order/${orderId}`
  return axios.post(url)
}

//Revenue API
export const getAnalyticsCommercer = () => {
  const url = `v2/analytics/commercer`
  return axios.get(url)
}

export const getTopBestsellers = () => {
  const url = `v2/analytics/commercer/top10`
  return axios.get(url)
}

export const getRevenueByDay = (params: IQueryParams) => {
  const url = `v2/analytics/commercer/order_value`
  return axios.get(url, { params: params })
}

export const getOrderByDay = (params: IQueryParams) => {
  const url = `v2/analytics/commercer/order_count`
  return axios.get(url, { params: params })
}

export const getLogisticLog = (params: IQueryParams) => {
  const url = `v2/orders_log/${params.order_id}`
  return axios.get(url, { params: params })
}

export default {}
