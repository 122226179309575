import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  Radio,
  Stack,
  Typography,
  styled
} from '@mui/material'
import __ from 'languages/index'
import { TypedAddressBook } from '../interface'
import ConfirmModal from 'components/ConfirmModal'

type Props = {
  defaultAddress: string
  setDefaultAddress: Function
  list: TypedAddressBook[]
  handleDeleteAddress: Function
}
const ListAddress = ({ list, defaultAddress, setDefaultAddress, handleDeleteAddress }: Props) => {
  const [selectedAddress, setSelectedAddress] = useState<string>('')

  const handleAcceptDeleteAddress = useCallback(() => {
    handleDeleteAddress(selectedAddress)
    setSelectedAddress('')
  }, [selectedAddress, list])

  return (
    <ListAddressContainer>
      {list?.map((address: TypedAddressBook, idx: number) => (
        <ListItem
          key={`address_${idx}`}
          sx={{
            p: theme => theme.spacing(0, 0, 2.5, 0),
            borderBottom: theme => `1px solid ${theme.palette.divider}`
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} gap={2}>
            <Box>
              <Radio
                checked={address?.address_id === defaultAddress}
                onClick={() => setDefaultAddress(address?.address_id)}
              />
            </Box>
            <Stack direction={'column'} rowGap={theme => theme.spacing(0.5)} width={'100%'}>
              <Stack>
                <Stack direction={'row'} gap={0.5} alignItems={'center'}>
                  <Typography variant={'subtitle2'} fontWeight={600}>
                    {address?.user_display_name || ''}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                    color={'text.secondary'}
                  />
                  <Typography color={'text.secondary'} variant={'subtitle2'}>
                    {address?.user_phonenumber || ''}
                  </Typography>
                </Stack>
                <Typography color={'text.secondary'}>{`${address?.user_address || ''}, ${
                  address?.user_address_ward_name || ''
                }, ${address?.user_address_district_name || ''}, ${
                  address?.user_address_city_name || ''
                }`}</Typography>
              </Stack>
              {address?.is_default_address === 1 && (
                <Box>
                  <Chip label={__('address_default')} className="chip" />
                </Box>
              )}
            </Stack>
            {address?.is_default_address !== 1 && (
              <Button
                onClick={() => setSelectedAddress(address?.address_id)}
                variant={'text'}
                sx={{ color: theme => theme.palette.error.main }}
              >
                {__('btn_delete')}
              </Button>
            )}
          </Stack>
          {Boolean(selectedAddress) && (
            <ConfirmModal
              onClose={() => setSelectedAddress('')}
              open={Boolean(selectedAddress)}
              onConfirm={handleAcceptDeleteAddress}
              title={__('btn_confirm')}
              cancelTextBtn={__('home_btn_cancel')}
              confirmText={__('address_delete_title')}
            />
          )}
        </ListItem>
      ))}
    </ListAddressContainer>
  )
}

export default ListAddress

const ListAddressContainer = styled(List)(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(2.5),
  '& .chip': {
    background: theme.palette.primary.background,
    height: theme.spacing(3),
    '& span': {
      fontSize: theme.spacing(1.75),
      padding: theme.spacing(0, 3),
      color: theme.palette.primary.main
    }
  }
}))
