import React, {useCallback, useEffect, useRef, useState} from 'react'
import HomeLayout from 'layouts/HomeLayout'
import {Box, Button, Paper, Typography} from '@mui/material'
import PostForm from 'components/PostForm'
import RenderView from 'components/RenderView'
import InfiniteList from 'components/InfinityList'
import {useAppDispatch, useAppSelector} from 'config/store'
import Dropdown from 'components/Dropdown/index'
import __ from 'languages/index'
import {dataTabs} from 'entities/homepage/data'
import {
  getListApproval,
  getListRequestCategory,
  getRequestHome,
  isFulfilledListRequest
} from 'entities/homepage/home.store.reducer'
import {useToast} from 'hooks/useToast'
import helpers from 'helpers'
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll";
import ItemHomePost from "entities/homeNew/components/ItemHomePost";
import {Link} from 'react-router-dom'
import {EnumPostStatus, TypedRequest} from 'interfaces/request.interface'
import {AxiosError} from 'axios'
import _Helmet from 'components/helmet'
import ShieldCheck from "../../icons/ShieldCheck";
import Filter3LineIcon from 'icons/Filter3LineIcon'

const HomeNew = () => {
  const toast = useToast()
  const checkMobile = helpers.isMobile();

  const channel_data = useAppSelector(state => state.user.channel_data)
  const permission_data = useAppSelector(state => state.user.permission_data)

  const user_data = useAppSelector(state => state.user.user_data)
  const dataRequest = useAppSelector(state => state.home.dataRequest, (next, prev) => helpers.isEqual(next, prev))
  const categories = useAppSelector(state => state.home.categories)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in);

  const [totalDataApproval, setTotalDataApproval] = useState<number>(0)
  const [selectArrange, setSelectArrange] = useState(dataTabs[0])
  const [category, setCategory] = useState(categories)
  const [selectCategory, setSelectCategory] = useState({
    value: '',
    label: 'home_page_select_category',
  })
  const [categoryData, setCategoryData] = useState([
    {value: '', label: 'home_page_select_category', createdAt: ''}
  ])

  const ref = useRef(null)


  const dispatch = useAppDispatch()

  const {
    hasMore,
    isRefreshing,
    loadMore,
    updateParamRequest,
    isLoading,
    refresh
  } = useInfiniteScroll<TypedRequest>({
    auth_id: user_data?._id,
    channel_id: channel_data?._id,
    limit: 12,
    post_category: '',
    order_type: 'time'
  }, getRequestHome, true, dataRequest, isFulfilledListRequest)


  useEffect(() => {
    // @ts-ignore
    dispatch(getListRequestCategory())
      .unwrap()
      .then((res)=>{
        setCategory(res?.data)
      })
      .catch((e:AxiosError)=>{
        console.log('getListRequestCategory', e)
      })
  }, []);

  useEffect(() => {

    const convertData =[...category].map(item => {
      return {value: item?._id, label: item?.category_content, createdAt: item?.createdAt}
    })

    const dataFilterById = [...categoryData, ...convertData]?.filter((category, index, data) => {
      return index === data?.findIndex((item) => item?.value === category?.value);
    })

    const sortData = [...dataFilterById]?.sort((a,b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    setCategoryData(sortData)
  }, [category]);


  const handleSelectArrange = (selectedOption) => {
    setSelectArrange(selectedOption)
    updateParamRequest({order_type: selectedOption.value})
  }

  const handleSelectCategory = (selectedOption) => {
    setSelectCategory(selectedOption)
    updateParamRequest({post_category: selectedOption.value})
  }

  const getListApprovalInHome = useCallback(()=>{
    if (permission_data) {
      if (user_data?._id && channel_data?._id) {
        const params = {
          post_status: EnumPostStatus.Pending,
          user_id: helpers.current_user_can('boss', permission_data) ? '' : user_data?._id,
          channel_id: channel_data?._id
        }
        dispatch(getListApproval(params))
          .unwrap()
          .then((res) => {
            setTotalDataApproval(parseInt(res.headers["x-total-count"] || 0, 10))
          })
          .catch((error: AxiosError) => {
            console.log(`getListApproval_${error}`);
            setTotalDataApproval(0)
          })
      }
    }
  },[channel_data?._id, user_data?._id, permission_data])

  useEffect(() => {
    getListApprovalInHome()
  }, [channel_data?._id, user_data?._id, permission_data])

  return (
    <>
      <_Helmet title='home_page'/>
      <HomeLayout>
        <Box
          sx={{
            maxWidth: theme => theme.spacing(96),
            margin: '0 auto'
          }}
        >
          <Box mb={2.5}>
            <PostForm getListApprovalInHome={getListApprovalInHome} refresh={refresh}/>
          </Box>
          <Button
            hidden={true}
            ref={ref}
            onClick={loadMore}
          />

          {
            checkMobile ? (
              <Box
                bgcolor='#fff'
                p="19px 9px 19px 23px"
                display='flex'
                justifyContent={'space-between'}
                alignItems={'center'}

              >
                <Dropdown
                  options={categoryData}
                  variant="text"
                  onSelect={handleSelectCategory}
                  selectData={selectCategory}
                />

               <Dropdown
                  options={dataTabs}
                  variant="text"
                  position="right"
                  onSelect={handleSelectArrange}
                  selectData={selectArrange}
                  icon={<Filter3LineIcon />}

                />

              </Box>
            ) : (
              <Box
              display={'flex'}
              justifyContent={'space-between'}
              marginTop={theme => theme.spacing(2.5)}
              marginBottom={theme => theme.spacing(2)}
              height={theme => theme.spacing(5.5)}
              alignItems={'center'}
            >
              <Dropdown
                options={categoryData}
                variant="text"
                onSelect={handleSelectCategory}
                selectData={selectCategory}
              />
              <Box
                display={'flex'}
                columnGap={theme => theme.spacing(0.5)}
                alignItems={'center'}
                justifyContent={'flex-end'}
              >
                <Typography variant='body2' color="text.secondary">{`${__('arrange')}:`}</Typography>
                <Dropdown
                  options={dataTabs}
                  variant="text"
                  position="right"
                  onSelect={handleSelectArrange}
                  selectData={selectArrange}
                />
              </Box>
            </Box>
            )
          }

          {/*Start: Number of articles awaiting approval */}
          {is_user_logged_in
            && channel_data?.need_approval
            && totalDataApproval > 0 && (
              <Paper
                elevation={3}
                sx={{
                  margin:theme =>  `${theme.spacing(2)} 0`,
                  background: theme => theme.palette.background.paper
                }}>
                <Link to="/post/approval-list">
                  <Box
                    sx={{
                      p: theme => theme.spacing(1.75),
                      background: theme => theme.palette.primary.dark,
                      borderRadius: theme => theme.spacing(1),
                      display: 'flex',
                      columnGap: theme => theme.spacing(1),
                      alignItems: 'center'
                    }}>
                    <ShieldCheck sx={{
                      color: theme => theme.palette.background.paper,
                      width: theme => theme.spacing(3),
                      height: theme => theme.spacing(3),
                    }}/>
                    <Typography textTransform={'none'} fontWeight={600}
                                color={'background.paper'}>{`${__("home_page_approval_text")} ${totalDataApproval || 0} ${__("home_page_approval_awaiting")}`}</Typography>
                  </Box>
                </Link>
              </Paper>


            )}
          {/*End: Number of articles awaiting approval */}
          <RenderView
            isLoading={isRefreshing}
            isEmpty={dataRequest.length === 0}
            view={
              <InfiniteList
                isLoading={isLoading}
                list={dataRequest}
                renderItem={post => (
                  <ItemHomePost
                    key={post._id}
                    post={post}
                    is_user_logged_in={is_user_logged_in}
                  />
                )}
                hasNextPage={hasMore}
                onLoadMore={() => ref.current?.click()}
              />
            }
          />
        </Box>
      </HomeLayout>
    </>
  )
}

export default HomeNew
