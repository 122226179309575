import React, {useCallback, useEffect} from "react";
import '../media/livestream.room.scss';
import '../media/livestream.chat.scss';
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay} from "@fortawesome/free-solid-svg-icons";
import {TypedLiveChat} from "entities/livestream/livestream.interface";
import LivestreamLivechatItem from "entities/livestream/components/livestream.livechat.item";
import {useAppSelector} from "config/store";
import {useInfiniteScroll} from "../../../hooks/useInfiniteScroll";
import {TypedGiftTransaction} from "entities/gift/gift.interface";
import {getListChatLivestream} from "entities/livestream/services/livestream.service";
import __ from "languages/index";
import {InfiniteScrollContainer} from "components/InfiniteScrollContainer";

interface TypedLivestreamReplayChatProps {
}

const LivestreamReplayChat = ({}: TypedLivestreamReplayChatProps) => {
  const liveDetail = useAppSelector(state => state.livestream.entity);

  const getDataChat = useCallback((param: object)=>{
    return getListChatLivestream(param, liveDetail?._id)
  },[liveDetail?._id])

  const {data, hasMore, refresh, totalItems, setParamRequest, loadMore, isRefreshing} = useInfiniteScroll<TypedGiftTransaction>({
    limit: 20,
    order_by: 'DESC',
  }, getDataChat, false)

  useEffect(() => {
    if(liveDetail?._id){
      refresh()
    }
  }, [liveDetail?._id]);


  const renderItemChat = useCallback((chatItem: TypedLiveChat) => {
    return <LivestreamLivechatItem isAdmin={chatItem?.createBy?._id === liveDetail?.user_id?._id} key={chatItem?._id} item={chatItem}/>
  }, [])

  return (
    <div id={"livestreamChat"}>
      <div className="livestream_header px-3">
        <b>
          {__("livestream_live_chat")}
        </b>

        <Button disabled className="livestreamroom_btn invisible pe-none">
          <FontAwesomeIcon icon={faCalendarDay} className="me-2 icon_btn"/>
          {__("livestream_interact")}
        </Button>
      </div>

      <div className="livestreamroom_chat_container">

        {/*<div className="livestreamroom_chatlist_container">*/}
        {/*  {chatList.map(renderItemChat)}*/}
        {/*</div>*/}

        <InfiniteScrollContainer
          isRefreshing={isRefreshing}
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          refreshFunction={refresh}
        >
          {data.map(renderItemChat)}
        </InfiniteScrollContainer>

      </div>

    </div>
  )
}

export default LivestreamReplayChat;
