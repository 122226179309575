import React, {forwardRef, memo, useCallback, useImperativeHandle, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useAppDispatch} from 'config/store'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import {getDetailLiveStream, updateEntity} from 'entities/livestream/livestream.store.reducer'
import {Socket} from 'socket.io-client'
import {EnumCommunicateClient} from 'entities/livestream/hooks/livestream.admin.hook'
import {useSelector} from 'react-redux'
import __ from "languages/index";
import {EnumTypeToast, useToast} from "../../../hooks/useToast";

const LivestreamAdminInfoModal = forwardRef(({ socket }: { socket: Socket }, ref) => {
  const liveDetail = useSelector(getDetailLiveStream)

  const [show, setShow] = useState(false)
  const dispatch = useAppDispatch()
  const [validate, setValidate] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const toast = useToast()
  const refValueTitle = useRef(liveDetail?.title)
  const refValueCaption = useRef(liveDetail?.caption)

  useImperativeHandle(ref, () => ({
    show: () => {
      setShow(true)
    }
  }))

  const onUpdate = async event => {
    try {
      const form = event.currentTarget
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        event.preventDefault()
        event.stopPropagation()
        setLoading(true)

        let dataLivestream = {
          title: refValueTitle.current,
          caption: refValueCaption.current
        }

        let resLivestream: any
        resLivestream = await dispatch(
          updateEntity({
            ...dataLivestream,
            _id: liveDetail?._id
          })
        )

        if (!resLivestream?.payload?.data?._id) {
          toast.show({
            title: `${__("notification")}`,
            type: EnumTypeToast.Error,
            content: `${__("livestream_admin_message_error")}`
          })
          setLoading(false)
          return
        }

        if (resLivestream?.payload?.data?._id) {
          socket.emit(
            'emitOffer',
            JSON.stringify({
              payload: {
                status: EnumCommunicateClient.UpdateTitleAndCaption,
                livestreamData: resLivestream?.payload?.data
              },
              room_id: 'livestream_' + resLivestream?.payload?.data?._id
            })
          )

          toast.show({
            title: `${__("notification")}`,
            type: EnumTypeToast.Success,
            content: `${__("livestream_admin_message_success")}`
          })
          setTimeout(() => {
            setShow(false)
          }, 500)
        } else {
          toast.show({
            title: `${__("notification")}`,
            type: EnumTypeToast.Error,
            content: `${__("livestream_admin_message_error")}`
          })
        }
        setLoading(false)
      }
      setValidate(true)
    } catch (error) {
      toast.show({
        title: `${__("notification")}`,
        type: EnumTypeToast.Error,
        content: `${__("livestream_admin_message_error")}`
      })
      setLoading(false)
    }
  }

  const onTitleLivestreamChange = useCallback(event => {
    refValueTitle.current = event.target.value.trim()
  }, [])

  const onCaptionLivestreamChange = useCallback(event => {
    refValueCaption.current = event.target.value.trim()
  }, [])

  const handleClose = useCallback(() => setShow(false), [])

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={'lg'}
      aria-labelledby="contained-modal-title-vcenter"
      className={'livestreamAdminDeviceModal'}
      centered
    >
      <Modal.Header className="livepreview_modal_body_bg">
        <Modal.Title className="text-white">{__("livestream_admin_edit")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'livepreview_modal_body_bg'}>
        <Form className="needs-validation" noValidate validated={validate}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="title">{__("livestream_admin_sub_title")}</Form.Label>
            <Form.Control
              maxLength={100}
              onChange={onTitleLivestreamChange}
              type="search"
              defaultValue={liveDetail?.title}
              id="title"
              required
              placeholder={__("livestream_admin_sub_title_placeholder")}
            />
            <div className="invalid-feedback">{__("livestream_admin_text")}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="description">{__("livestream_admin_describe")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              defaultValue={liveDetail?.caption}
              maxLength={1000}
              placeholder={'Nhập Mô tả phiên livestream'}
              onChange={onCaptionLivestreamChange}
              required
              id="description"
            />
            <div className="invalid-feedback">{__("livestream_admin_describe_text")}</div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="livepreview_modal_body_bg">
        <Button variant="secondary" onClick={handleClose}>
          {__("btn_close")}
        </Button>
        <Button disabled={loading} variant="primary" onClick={onUpdate}>
          {loading ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="visually-hidden">{__("loading")}</span>
            </>
          ) : (
            `${__("livestream_admin_btn_save")}`
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export default memo(LivestreamAdminInfoModal)
