import '../media/gift.receiver.scss'
import React, {useCallback} from "react";

export default function GiftReceiverSkeleton() {

  const renderItem = useCallback((_, index) => {
    return (
      <div key={index.toString()} className="d-flex w-100 giftdetailmodal_container_user">
        <div className="giftdetailmodal_avatar">
          <div className="giftdetailmodal_avatar rounded-circle skeleton"/>
        </div>

        <div className="ms-2 flex-grow-1">
          <div className="giftdetailmodal_txtnameuser skeleton skeleton-text w-50" style={{height: 18}}/>
          <div className="giftdetailmodal_txtnameuser skeleton skeleton-text w-25 mt-1" style={{height: 14}}/>
        </div>
      </div>
    )
  }, [])

  return (
    <div className="gap-3">
      {[1, 2, 3, 4, 5, 6].map(renderItem)}
    </div>
  )
}
