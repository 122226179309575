// @ts-nocheck

/* global Map:readonly, Set:readonly, ArrayBuffer:readonly */

const hasElementType = typeof Element !== 'undefined';
const hasMap = typeof Map === 'function';
const hasSet = typeof Set === 'function';
const hasArrayBuffer = typeof ArrayBuffer === 'function' && !!ArrayBuffer.isView;

export default class helper_is {

	constructor() {}

	isGamifa(): boolean {
		switch (window.location.hostname) {
			case 'localhost':
			case 'gamifa.com':
			case 'dev.gamifa.appuni.io':
			case 'social.gamifa.com':
			case 'social.gamifa.vn':
				return true
			default:
				return false
		}
	}

	/**
	 * check if dev or production environment
	 * @returns boolean
	 */

	isDev(): boolean {
		return process.env.NODE_ENV === 'development';
	}

	/**
	 * Check if it is mobile or not ...
	 * @returns Boolean
	 */
	isMobile(): boolean {
		return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	}


	/**
	 * Check if it is email
	 * @param {*} email
	 * @returns
	 */
	isEmail (email: any) {
		if ( typeof email === 'undefined') { return false; }
		email = String(email || ' ').trim();

		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		if (filter.test(email)) {
            return true;
		}
		return false;
	}

	/**
	 * Check if String can be a boolean value, valid: true, "true"
	 * @param {*} s
	 */
	isBoolean (s: any) {
		let a = String(s || ' ');
		return a === "true" || a === "false";
	}


	/**
		 * Check if it is phone number
		 * @param {*} phonenumber
		 * @returns
		 */
	isPhoneNumber(phonenumber: any) {
		var filter = /^[0-9\-\+]{10,15}$/;
		if (filter.test(phonenumber)) {
			return true;
		}
		return false;
	}


	/**
	 * Check if it is number only
	 * @param {*} num
	 */
	isNumber(inputValue: any) {
		return Boolean(new RegExp('^[0-9]+$', 'g').test(inputValue));
	}


	/**
	 * Check if it is a valid bigint
	 * @param _big string or number
	 * @returns
	 */
	isBigInt( _big: string | number | bigint )  : boolean
	{
		try {
			if ( _big === '' ) return false;
			return BigInt( String(_big) );
		} catch(e) {
			return false;
		}
	}


	/**
	 * Check if it is JSON
	 * @param {} str
	 * @returns
	 */
	isJson (str: string) {
		if (typeof str !== 'string') return false;
		try {
			const result = JSON.parse(str);
			const type = Object.prototype.toString.call(result);
			return type === '[object Object]'
				|| type === '[object Array]';
		} catch (err) {
			return false;
		}
	}

	/*
	*	Check if it is a valid URL
	*/
	isUrl (_string: any) {
		try {
			new URL(_string);
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * Check if is string, better than isUrl
	 * @param text String
	 * @returns link or false
	 */
	isLink(text: string): string | boolean  {
		if ( String(text).match(/\s/g) ) return false;
		var $link = String(text).trim().match(/http(s)?:\/\/\S+/g);
		if ( $link ) {
			return encodeURIComponent($link);
		}
		return false;
	}

/**
 * Check if it  is number, or if this value greater than bigint, it will return false
 * @param n String
 */
	isNumeric(n: string| number | bigint ): boolean {
		n = String(n);
		let checkNum = !isNaN(parseFloat(n)) && isFinite(n);
		if ( checkNum && BigInt(`${n}`) > BigInt('999999999999999999') ) {
			return false;
		}
		return checkNum;
	}



/**
 * Check if it is array ...
 */
	isArray(_array: any) {
		return Array.isArray(_array);
	}

	/**
	 * Check if it is UTF8
	 * @param {} string
	 * @returns boolean
	 */
	isUTF8 (_string: string) {
		if ( _string === void 0 ) _string = "";
		return /[^\u0000-\u007f]/.test(_string);
	}

	/**
	 * Check length of string
	 */

	isMorethan (s: string | undefined, l: number) {
		if ( s === void 0 ) s = "";
		if ( l === void 0 ) l = 1;
		if (String(s).length > l ) return true;
		return false;
	}

	/**
	 * Check if less than X character
	 * @param {*} s
	 * @param {*} l
	 * @returns
	 */
	isLessthan (s: string | undefined, l: number) {
		if ( typeof s === 'undefined') return false;
		if ( l === void 0 ) l = 1;
		if ( String(s).length < l ) return true;
		return false;
	}

	/**
	 * Check if object/array/string is empty or not
	 * @param {*} array_or_object
	 */
	isEmpty (array_or_object: object | array | string ) {
		if ( !array_or_object ) return true;
		if ( array_or_object === void 0 ) return true;

		if ( Array.isArray(array_or_object) )
			return array_or_object.length === 0;
		if ( typeof array_or_object === 'object' )
			return Object.keys(array_or_object).length === 0;

		return ( String( array_or_object ).length === 0 );
	}

	/**
	 * Check if it is Object
	 * @param {*} objectornot string or any to check
	 */
	isObject (objectornot:  any) {
		if (
		typeof objectornot === 'object' &&
		!Array.isArray(objectornot) &&
		objectornot !== null
		) return true;

		return false;
	}

	equal(target1:any, target2:any) {
		// START: fast-deep-equal es6/index.js 3.1.3
		if (target1 === target2) return true;

		if (target1 && target2 && typeof target1 == 'object' && typeof target2 == 'object') {
			if (target1.constructor !== target2.constructor) return false;

			let length, i, keys;
			if (Array.isArray(target1)) {
				length = target1.length;
				if (length != target2.length) return false;
				for (i = length; i-- !== 0;)
					if (!this.equal(target1[i], target2[i])) return false;
				return true;
			}

			// START: Modifications:
			// 1. Extra `has<Type> &&` helpers in initial condition allow es6 code
			//    to co-exist with es5.
			// 2. Replace `for of` with es5 compliant iteration using `for`.
			//    Basically, take:
			//
			//    ```js
			//    for (i of a.entries())
			//      if (!b.has(i[0])) return false;
			//    ```
			//
			//    ... and convert to:
			//
			//    ```js
			//    it = a.entries();
			//    while (!(i = it.next()).done)
			//      if (!b.has(i.value[0])) return false;
			//    ```
			//
			//    **Note**: `i` access switches to `i.value`.
			let it;
			if (hasMap && (target1 instanceof Map) && (target2 instanceof Map)) {
				if (target1.size !== target2.size) return false;
				it = target1.entries();
				while (!(i = it.next()).done)
					if (!target2.has(i.value[0])) return false;
				it = target1.entries();
				while (!(i = it.next()).done)
					if (!this.equal(i.value[1], target2.get(i.value[0]))) return false;
				return true;
			}

			if (hasSet && (target1 instanceof Set) && (target2 instanceof Set)) {
				if (target1.size !== target2.size) return false;
				it = target1.entries();
				while (!(i = it.next()).done)
					if (!target2.has(i.value[0])) return false;
				return true;
			}
			// END: Modifications

			if (hasArrayBuffer && ArrayBuffer.isView(target1) && ArrayBuffer.isView(target2)) {
				length = target1.length;
				if (length != target2.length) return false;
				for (i = length; i-- !== 0;)
					if (target1[i] !== target2[i]) return false;
				return true;
			}

			if (target1.constructor === RegExp) return target1.source === target2.source && target1.flags === target2.flags;
			// START: Modifications:
			// Apply guards for `Object.create(null)` handling. See:
			// - https://github.com/FormidableLabs/react-fast-compare/issues/64
			// - https://github.com/epoberezkin/fast-deep-equal/issues/49
			if (target1.valueOf !== Object.prototype.valueOf && typeof target1.valueOf === 'function' && typeof target2.valueOf === 'function') return target1.valueOf() === target2.valueOf();
			if (target1.toString !== Object.prototype.toString && typeof target1.toString === 'function' && typeof target2.toString === 'function') return target1.toString() === target2.toString();
			// END: Modifications

			keys = Object.keys(target1);
			length = keys.length;
			if (length !== Object.keys(target2).length) return false;

			for (i = length; i-- !== 0;)
				if (!Object.prototype.hasOwnProperty.call(target2, keys[i])) return false;
			// END: fast-deep-equal

			// START: react-fast-compare
			// custom handling for DOM elements
			if (hasElementType && target1 instanceof Element) return false;

			// custom handling for React/Preact
			for (i = length; i-- !== 0;) {
				if ((keys[i] === '_owner' || keys[i] === '__v' || keys[i] === '__o') && target1.$$typeof) {
					// React-specific: avoid traversing React elements' _owner
					// Preact-specific: avoid traversing Preact elements' __v and __o
					//    __v = $_original / $_vnode
					//    __o = $_owner
					// These properties contain circular references and are not needed when
					// comparing the actual elements (and not their owners)
					// .$$typeof and ._store on just reasonable markers of elements

					continue;
				}

				// all other properties should be traversed as usual
				if (!this.equal(target1[keys[i]], target2[keys[i]])) return false;
			}
			// END: react-fast-compare

			// START: fast-deep-equal
			return true;
		}

		return target1 !== target1 && target2 !== target2;
	}

	isEqual(target1: any, target2: any): boolean {
		try {
			return this.equal(target1, target2);
		} catch (error) {
			if (((error.message || '').match(/stack|recursion/i))) {
				// warn on circular references, don't crash
				// browsers give this different errors name and messages:
				// chrome/safari: "RangeError", "Maximum call stack size exceeded"
				// firefox: "InternalError", too much recursion"
				// edge: "Error", "Out of stack space"
				console.warn('react-fast-compare cannot handle circular refs');
				return false;
			}
			// some other error. we should definitely know about these
			throw error;
		}
	};

}
