//Library
import {SyntheticEvent, useCallback, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {AxiosError, AxiosResponse} from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBuildingColumns, faFileLines, faMoneyBill, faUser} from '@fortawesome/free-solid-svg-icons'
import {Button} from 'react-bootstrap'

//Component
import _Helmet from 'components/helmet'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {createOrder, getDataChannel, getListPlan, getOrderList, updateOrder} from './store/channel.store.reducer'

//Image
import logoDefault from 'media/images/logo.png'

//Interface
import {TypeChannelId} from 'entities/user/interface'

//Helpers
import helpers from 'helpers'

//Scss
import './media/channel.order.scss'
import __ from "languages/index";
import { getDefaultChannel } from 'store/user.store.reducer'

const OrderChannel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { channel_id } = useParams()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [isUpdateOrder, setIsUpdateOrder] = useState<boolean>(false)
  const [dataChannel, setDataChannel] = useState<TypeChannelId>(null)
  const [dataPlan, setDataPlan] = useState(null)
  const [dataOrder, setDataOrder] = useState(null)
  const [resMessage, setResMessage] = useState<{ status: string; text: string }>({
    status: '',
    text: ''
  })

  const handleSubmit = useCallback(() => {
    if (!dataOrder?._id) {
      return setResMessage({
        status: 'true',
        text: `${__("channel_order_error")}`
      })
    }
    const formData = {
      _id: dataOrder?._id,
      status: 'processing'
    }
    setIsLoadingSubmit(true)
    dispatch(updateOrder(formData))
      .unwrap()
      .then(() => {
        setIsUpdateOrder(true)
        setIsLoadingSubmit(false)
      })
      .catch((error: AxiosError) => {
        console.log(`updateOrder_${error}`)
        setIsUpdateOrder(false)
        setIsLoadingSubmit(false)
      })
  }, [dataOrder])

  const handleCloseMessage = useCallback(() => {
    setResMessage({
      status: '',
      text: ''
    })
  }, [])

  const handleCreateOrder = useCallback((planId: string, channelId: string) => {
    const formData = {
      plan_id: planId,
      payment_method: 'transfer',
      amount_of_package: '1',
      trans_id: channelId
    }
    dispatch(createOrder(formData))
      .unwrap()
      .then((res: AxiosResponse) => {
        setDataOrder(res?.data)
      })
      .catch((error: AxiosError) => {
        setDataOrder(null)
        console.log(`createOrder_${error}`)
      })
  }, [])

  const handleGetOrderList = useCallback(
    (page: number, limit: number, planId: string, channelId: string) => {
      dispatch(getOrderList({ page: page, limit: limit, trans_id: channelId, order_by: 'DESC' }))
        .unwrap()
        .then((res: AxiosResponse) => {
          if (res?.data?.length) {
            setDataOrder(res?.data[0])
          } else {
            handleCreateOrder(planId, channelId)
          }
        })
        .catch((error: AxiosError) => {
          console.log(`getOrderList_${error}`)
          handleCreateOrder(planId, channelId)
        })
    },
    []
  )

  const handleGetPlanList = useCallback((page: number, limit: number, channelId: string) => {
    dispatch(
      getListPlan({
        page: page,
        limit: limit,
        order_by: 'DESC',
      })
    )
      .unwrap()
      .then((res: AxiosResponse) => {
        setDataPlan(res?.data[0])
        handleGetOrderList(1, 1, res?.data[0]?._id, channelId)
      })
      .catch((error: AxiosError) => {
        console.log(`getListPlan_${error}`)
        setDataPlan(null)
      })
  }, [])

  useEffect(() => {
    dispatch(getDataChannel(channel_id))
      .unwrap()
      .then((res: AxiosResponse) => {
        setDataChannel(res?.data)
        if (res?.data?.official_status) {
          navigate('/')
        } else {
          dispatch(getDefaultChannel());
          if (helpers.isEmpty(dataPlan) || !dataPlan?._id) {
            handleGetPlanList(1, 1, dataPlan?._id)
          }
        }
      })
      .catch((error: AxiosError) => {
        console.log(`getDataChannel_${error}`)
        setDataChannel(null)
      })
  }, [channel_id, dataPlan])

  return (
    <>
      <_Helmet title='channel_order_title' />
      <div id="orderChannel" className="orderChannel_container">
        <div className="orderChannel_wrapper container-small">
          <Link to="/channel" className="orderChannel_logo_wrapper text-decoration-none">
            <img
              src={channel_data?.avatar?.media_url || logoDefault}
              onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = logoDefault
              }}
              width={40}
              height={40}
              className="orderChannel_logo_image"
              alt="logo"
              loading="lazy"
            />
            <p className="orderChannel_logo_title">{channel_data?.name || `${__("channel_gamifa")}`}</p>
          </Link>
          <div className="orderChannel_body">
            <h2 className="mb-2">{__("channel_order_complete")}</h2>
            <div>
              <h3 className="orderChannel_title_info">{__("channel_order_info_product")}</h3>
              {!helpers.isEmpty(dataChannel) && dataChannel?._id && (
                <div className="orderChannel_information mt-2 mb-4">
                  <div className="orderChannel_information_image">
                    <img
                      src={dataChannel?.avatar?.media_url || logoDefault}
                      onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                        e.currentTarget.src = logoDefault
                      }}
                      width={60}
                      height={60}
                      className="orderChannel_logo_image"
                      alt="logo"
                      loading="lazy"
                    />
                  </div>
                  <div className="orderChannel_information_content">
                    <span className="fw-bold">{dataChannel?.name || ''}</span>
                    <span>{dataChannel?.description || ''}</span>
                  </div>
                </div>
              )}
            </div>
            {resMessage?.text && resMessage?.status && (
              <div
                className="d-flex justify-content-between align-items-center alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <p>{resMessage?.text}</p>
                <div>
                  <Button onClick={handleCloseMessage}>&times;</Button>
                </div>
              </div>
            )}
            <div>
              <h3 className="orderChannel_title_info">{__("channel_order_info_payment")}</h3>
              <div className="orderChannel_title_info_payment">
                <div className="orderChannel_title_info_payment_item">
                  <FontAwesomeIcon icon={faMoneyBill} />
                  <p>{`${__("channel_information_banking_number")} ${dataPlan?.google_store_product_id || ''}`}</p>
                </div>
                <div className="orderChannel_title_info_payment_item">
                  <FontAwesomeIcon icon={faUser} />
                  <p>{`${__("channel_information_banking_holders")} ${dataPlan?.name || ''}`}</p>
                </div>
                <div className="orderChannel_title_info_payment_item">
                  <FontAwesomeIcon icon={faBuildingColumns} />
                  <p>{`${__("channel_information_banking")} ${dataPlan?.description || ''}`}</p>
                </div>
                <div className="orderChannel_title_info_payment_item">
                  <FontAwesomeIcon icon={faMoneyBill} />
                  <p>{`${__("channel_information_money")} ${helpers.convertToCommasFormat(dataPlan?.price || '')}`}đ</p>
                </div>
                <div className="orderChannel_title_info_payment_item">
                  <FontAwesomeIcon icon={faFileLines} />
                  <p>{`${__("channel_information_transfer")} ${dataOrder?.short_id || ''} ${__("channel_order_ICEO")}`}</p>
                </div>
                <div className="orderChannel_title_info_payment_item">
                  <p>
                      {__("channel_order_support")}
                  </p>
                </div>
              </div>
            </div>
            {isUpdateOrder ? (
              <Button onClick={handleSubmit} disabled={isUpdateOrder} className="button-event">
                  {__("channel_order_confirm")}
              </Button>
            ) : (
              <Button onClick={handleSubmit} className="button-event">
                {isLoadingSubmit ? `${__("btn_processing")}` : `${__("channel_order_btn_confirm")}`}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderChannel
