import { FormControl, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { getStyles } from 'entities/shop/helper'
import React from 'react'

const SelectComponent = ({ options, value, title, onChange, _onBlur, _onClick, error }) => {
  return (
    <FormControl
      className="select_form"
      sx={{
        display: 'grid',
        rowGap: theme => theme.spacing(0.5),
        gridTemplateRows: theme => `1fr repeat(1,minmax(${theme.spacing(2.375)}, auto))`,
        '& > div': {
          height: theme => theme.spacing(7)
        }
      }}
    >
      <Select
        MenuProps={{
          elevation: 1,
          sx: {
            maxHeight: theme => theme.spacing(37.5)
          }
        }}
        displayEmpty
        value={value}
        onBlur={_onBlur}
        onChange={onChange}
        input={<OutlinedInput />}
        renderValue={selected => {
          if (selected.length === 0) {
            return <>{title}</>
          }
          return selected
        }}
        className="address-select"
        sx={{
          padding: theme => theme.spacing(0, 2),
          backgroundColor: theme => theme.palette.background.default,
          borderRadius: theme => theme.spacing(1.5)
        }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="">{title}</MenuItem>
        {options?.map(option => (
          <MenuItem
            key={option?.code}
            value={option?.name}
            sx={theme => getStyles(option?.name, value, theme)}
            onClick={() => _onClick(option?.code)}
          >
            {option?.name}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Typography
          sx={{ paddingTop: theme => theme.spacing(0.25) }}
          variant="body2"
          color="error.main"
        >
          {error}
        </Typography>
      )}
    </FormControl>
  )
}
export default SelectComponent
