import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected, createSlice } from '@reduxjs/toolkit';
import { TypedExample } from './example.interface';
import { createEntitySlice, serializeAxiosError } from '../../config/reducer.utils';
import { AppThunk } from '../../config/store';
import helpers from 'helpers';

export const initialState = {
    entity: {} as TypedExample,
    entities: null as TypedExample[],
    errorMessage: null as unknown as string, // Errors returned from server side
    totalItems: 0 as number,
    loading: false,
    updating: false,
    updateSuccess: false,
    creating: false,
    createdSuccess: false
};

const apiUrl = 'example';

export const getEntity = createAsyncThunk('example/fetch_entity', async (__id: string) => {
    return await axios.get<any>(`${apiUrl}/${__id}?cacheBuster=${new Date().getTime()}`);
  },
  { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk('example/fetch_entity_list', async (object: any) => {
    const EndURL = helpers.buildEndUrl(object);
    const requestUrl = `${apiUrl}${EndURL}&cacheBuster=${new Date().getTime()}`;
    return axios.get<any>(requestUrl);
});


export const updateEntity = createAsyncThunk( 'example/update_entity', async (entity: TypedExample, thunkAPI) => {
    let mainID = String(entity.example_id);
    delete entity.example_id;
      const result = await axios.put<TypedExample>(`${apiUrl}/${mainID}?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
    //   thunkAPI.dispatch(getEntities({}));
      return result;
}, { serializeError: serializeAxiosError });


export const deleteEntity = createAsyncThunk( 'example/delete_entity', async (_id: string) => {
    return await axios.delete<any>(`${apiUrl}/${_id}?cacheBuster=${new Date().getTime()}`);
}, { serializeError: serializeAxiosError });


// test checkauthentication
export const createEntity = createAsyncThunk( 'example/create_entity', async (entity: TypedExample) => {
    return await axios.post<TypedExample>(`course/create?cacheBuster=${new Date().getTime()}`, helpers.cleanEntity(entity));
}, { serializeError: serializeAxiosError });




export const EXAMPLE_REDUCER = createEntitySlice({
  name: 'example',
  initialState: initialState as any,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
      state.loading = false;
      state.updateSuccess = false;
      state.creating = false;
      state.createdSuccess = false;
    },
    reset: (state) => {
      return {...state, ...initialState};
    }
  },

  extraReducers(builder) {
    builder

        .addCase(createEntity.fulfilled, (state, action) => {
            state.creating = false;
            state.createdSuccess = true;
            state.entity = action.payload.data;
        })
        .addCase(createEntity.pending, (state, action) => {
            state.creating = true;
            state.createdSuccess = false;
        })
        .addCase(createEntity.rejected, (state, action) => {
            state.creating = false;
            state.errorMessage = action.payload;
        })

        .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false;
            state.entity = action.payload.data;
        })
        .addCase(getEntity.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getEntity.rejected, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload;
        })

        .addCase(updateEntity.fulfilled, (state, action) => {
            state.updating = false;
            state.updateSuccess = true;
            state.entity = action.payload.data;
        })
        .addCase(updateEntity.pending, (state, action) => {
            state.updating = true;
            state.updateSuccess = false;
        })
        .addCase(updateEntity.rejected, (state, action) => {
            state.updating = false;
            state.errorMessage = action.payload;
        })

        .addCase(deleteEntity.fulfilled, (state, action) => {
            state.updating = false;
            state.updateSuccess = true;
            state.entity = action.payload.data;
        })
        .addCase(deleteEntity.pending, (state, action) => {
            state.updating = true;
            state.updateSuccess = false;
        })
        .addCase(deleteEntity.rejected, (state, action) => {
            state.updating = false;
            state.errorMessage = action.payload;
        })

    .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data,
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10)
    })
    .addCase(getEntities.pending, (state, action) => {
        state.loading = true;
    })
    .addCase(getEntities.rejected, (state, action) => {
        state.loading = false;
        state.entities = null;
        state.errorMessage = action.payload;
    })
  },
});
export const {clearError, reset} = EXAMPLE_REDUCER.actions;
// Reducer
export default EXAMPLE_REDUCER.reducer;