import {
  faArrowUp91,
  faBook,
  faCakeCandles,
  faComment,
  faGem,
  faMedal,
  faThumbsUp
} from "@fortawesome/free-solid-svg-icons";
import __ from "languages/index";


export enum EnumGiftConditionType {
  Point = "point",
  Level = "level",
  Like = "like",
  Comment = "comment",
  Course = "course",
  Birth = "birth",
  Coin = "coin",
}

export enum EnumGiftSendStatusType {
  Mine = "Mine",
  All = "all",
  Prepare = "prepare",
  Transport = 'transport',
  Received = "received",
  Cancel = "cancel",
}

export const GiftConditionTypeIcon = {
  [EnumGiftConditionType.Point]: faArrowUp91,
  [EnumGiftConditionType.Level]: faMedal,
  [EnumGiftConditionType.Like]: faThumbsUp,
  [EnumGiftConditionType.Comment]: faComment,
  [EnumGiftConditionType.Course]: faBook,
  [EnumGiftConditionType.Birth]: faCakeCandles,
  [EnumGiftConditionType.Coin]: faGem
}

export const GiftConditionTypeTitle = {
  [EnumGiftConditionType.Point]: "gift_point",
  [EnumGiftConditionType.Level]: "gift_level",
  [EnumGiftConditionType.Like]: "gift_like",
  [EnumGiftConditionType.Comment]: "gift_comment",
  [EnumGiftConditionType.Course]: "gift_course",
  [EnumGiftConditionType.Birth]: "gift_birthday",
  [EnumGiftConditionType.Coin]: "gift_diamond",
}

export const GiftSendStatusTitle = {
  [EnumGiftSendStatusType.All]: "",
  [EnumGiftSendStatusType.Received]: "gift_received",
  [EnumGiftSendStatusType.Transport]: "gift_transport",
  [EnumGiftSendStatusType.Prepare]: "gift_prepare",
  [EnumGiftSendStatusType.Cancel]: "gift_cancel",
}

export const GiftSendStatusNextStep = {
  [EnumGiftSendStatusType.All]: EnumGiftSendStatusType.Prepare,
  [EnumGiftSendStatusType.Received]: EnumGiftSendStatusType.Prepare,
  [EnumGiftSendStatusType.Transport]: EnumGiftSendStatusType.Received,
  [EnumGiftSendStatusType.Prepare]: EnumGiftSendStatusType.Transport,
  [EnumGiftSendStatusType.Cancel]: EnumGiftSendStatusType.Prepare,
}

export const GIFT_CONDITIONS = [
  {
    title: `${__("gift_condition_point")}`,
    type: "checkbox",
    value: EnumGiftConditionType.Point
  },
  {
    title: `${__("gift_condition_level")}`,
    type: "checkbox",
    value: EnumGiftConditionType.Level
  },
  {
    title: `${__("gift_condition_like")}`,
    type: "checkbox",
    value: EnumGiftConditionType.Like
  },
  {
    title: `${__("gift_condition_comment")}`,
    type: "checkbox",
    value: EnumGiftConditionType.Comment
  },
  {
    title: `${__("gift_condition_post")}`,
    type: "checkbox",
    value: EnumGiftConditionType.Course
  },
  {
    title: `${__("gift_condition_diamond")}`,
    type: "checkbox",
    value: EnumGiftConditionType.Coin
  },

]

export const GIFT_SEND_STATUS = [
  {
    title: `${__("gift_status_total")}`,
    value: EnumGiftSendStatusType.All
  },
  {
    title: `${__("gift_status_prepare")}`,
    value: EnumGiftSendStatusType.Prepare
  },
  {
    title: `${__("gift_status_transport")}`,
    value: EnumGiftSendStatusType.Transport
  },
  {
    title: `${__("gift_status_received")}`,
    value: EnumGiftSendStatusType.Received
  },
  {
    title: `${__("gift_status_cancel")}`,
    value: EnumGiftSendStatusType.Cancel
  }
]


export const GIFT_SEND_STATUS_USER_ROLE = [
  {
    title: `${__("my_gift")}`,
    value: EnumGiftSendStatusType.Mine
  },
  {
    title: `${__("gift_status_total")}`,
    value: EnumGiftSendStatusType.All
  },
]


export const GIFT_SORT_OPTIONS = [
  {
    title: `${__("gift_value_desc")}`,
    value: {
      order_type: "price",
      order_by: "DESC"
    }
  },
  {
    title: `${__("gift_value_asc")}`,
    value: {
      order_type: "price",
      order_by: "ASC"
    }
  },
  {
    title: `${__("gift_time_desc")}`,
    value: {
      order_type: "time",
      order_by: "DESC"
    }
  },
  {
    title: `${__("gift_time_asc")}`,
    value: {
      order_type: "time",
      order_by: "ASC"
    }
  },
  {
    title: `${__("gift_stock_qty_desc")}`,
    value: {
      order_type: "stock_qty",
      order_by: "DESC"
    }
  },
  {
    title: `${__("gift_stock_qty_asc")}`,
    value: {
      order_type: "stock_qty",
      order_by: "ASC"
    }
  },
]


export const GIFT_RECEIVER_SORT_OPTIONS = [
  {
    title: `${__("gift_received_time_desc")}`,
    value: {
      order_type: "time",
      order_by: "DESC"
    }
  },
  {
    title: `${__("gift_received_time_asc")}`,
    value: {
      order_type: "time",
      order_by: "ASC"
    }
  }
]
