import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  Radio,
  Select,
  TextField,
  TextFieldProps,
  FormControl,
  RadioGroup
} from '@mui/material'
import { ChevronIcon, LookupIcon } from 'icons'

export const InputBase = (props: TextFieldProps) => {
  const defaultProps = {
    InputProps: {
      disableUnderline: true,
      ...props.InputProps
    },
    ...props
  }
  return <TextField {...defaultProps} />
}

const BaseInput = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        padding: 5,
        bgcolor: 'background.paper'
      }}
    >
      <Box component={'form'}>
        <InputBase
          required
          label="Required"
          placeholder="placeholder"
          size="small"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="start">
                <LookupIcon fontSize="small" cursor={'pointer'} />
              </InputAdornment>
            )
          }}
        />
        <InputBase
          disabled
          label="Disabled"
          sx={{ mt: 2 }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <LookupIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <ChevronIcon />
              </InputAdornment>
            )
          }}
        />
        {/* <Select fullWidth IconComponent={ChevronIcon}>
          <option value="1">1</option>
        </Select> */}
        <FormGroup>
          <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
          <FormControlLabel required control={<Checkbox color="info" />} label="Required" />
          <FormControlLabel disabled control={<Checkbox />} label="Disabled" />
        </FormGroup>

        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio color="info" />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>

        <Button color="primary">Submit</Button>
      </Box>
    </Box>
  )
}

export default BaseInput
