import { TypedEvent } from 'entities/calendar/calendar.interface'
import { TypedCourse, TypedUser } from 'entities/courses/courses.interface'
import { EnumLiveStreamSource } from 'entities/event/event.interface'
import TypedMedia from 'interfaces/media.interface'

export enum EnumStatusLivestream {
  Wait = 'wait',
  Live = 'live',
  Ended = 'end'
}

export enum EnumReadyStatusLivestream {
  Connected = 'connected',
  Disconnected = 'disconnected'
}

export interface TypedLiveChat {
  _id?: string
  user_type?: string
  parent_id?: any
  livestream_id?: string
  chat_content?: string
  media_ids?: any[]
  chat_type?: string
  chat_status?: string
  send_at?: string
  read_at?: string
  createBy?: TypedUser
  createdAt?: string
  updatedAt?: string
}

export interface TypedLivestreamStorage {
  uid?: string
  thumbnail?: string
  meta?: {
    name: string
  }
  created: string
  modified: string
  playback: {
    hls: string
    dash: string
  }
}

enum LiveStreamReactionValue {
  Angry = 'angry_value',
  Care = 'care_value',
  HaHa = 'haha_value',
  Like = 'like_value',
  Love = 'love_value',
  Sad = 'sad_value',
  Wow = 'wow_value'
}

export interface TypedLivestream {
  _id: string
  user_id: TypedUser
  language: string
  avatar: TypedMedia
  media_id: any
  title: string
  start_time: string
  caption: string
  cookies: string
  ref_id: any
  country: string
  like_number: number
  view_number: number
  comment_number: number
  livestream_status: EnumStatusLivestream
  ready_status?: EnumReadyStatusLivestream
  input_type: EnumLiveStreamSource
  livestream_source: string
  music_id: any[]
  hashtag_id: any[]
  react_value: Record<LiveStreamReactionValue, number>
  event_data: TypedEvent
  createdAt: string
  updatedAt: string
  whip_data?: string
  whep_data?: string
  livestream_data?: {
    rtmp_url?: string
    m3u8_url?: string
    ingest_endpoint?: string
    stream_key?: string
    _id: string
  }
  history_media?: TypedLivestreamStorage[]
  product_id: TypedCourse
}
