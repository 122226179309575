import __ from 'languages/index'
import {memo, useEffect, useState} from 'react'
import {ProgressBar} from 'react-bootstrap'

const ProgressBarPassword = ({strength_password}: {strength_password: number }) => {
  const [text, setText] = useState<string>(__('weak_password'))

  useEffect(()=> {
    switch (strength_password) {
      case 0:
        setText(__('validate_password'))
        break;
      case 1:
        setText(__('weak_password'))
        break;
      case 2:
        setText(__('average_password'))
        break;
      case 3:
        setText(__('strong_password'))
        break;
      case 4:
        setText(__('strong_password'))
        break;
      case 5:
        setText(__('very_strong_password'))
        break;
      default:
        setText(__('validate_password'))
        break;
    }
  },[strength_password])

  return (
    <div className='mt-2'>
        <ProgressBar style={{height: '10px'}} variant='primary' now={(strength_password / 5) * 100} />
        <span style={{fontSize:'14px'}}>{text || ''}</span>
    </div>
  )
}

export default memo(ProgressBarPassword)
