import React, {useEffect, useRef, useState} from 'react'
import {Col, Container, Image, Row, Stack} from 'react-bootstrap'
import {useLocation, useParams} from 'react-router-dom'
import {getDetailGift, getListUserReceivedGift} from './services/gift.receiver.service'
import {TypedGift, TypedGiftTransaction} from './gift.interface'
import RenderView from 'components/RenderView'
import __helpers from 'helpers/index'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {EnumGiftConditionType, GiftConditionTypeIcon, GiftConditionTypeTitle} from './constants/gift.constant'
import Parser from 'html-react-parser'
import {getIconFile, getMimeFile} from './gift.update'
import InfiniteList from 'components/InfiniteList'
import {faArrowUp91, faMedal} from '@fortawesome/free-solid-svg-icons'
import Avatar from 'components/Avatar'
import helpers from 'helpers'
import _Helmet from 'components/helmet'
import {useAppSelector} from 'config/store'
import TypedChannel from 'interfaces/channel.interface'
import __ from "languages/index";
import BigLayout from "../../layouts/bigLayout";
import { Typography } from '@mui/material'

const GiftDetail = () => {
  const location = useLocation()
  const { giftId } = useParams()
  const [gift, setGift] = useState<TypedGift>(null)
  const [usersReceivedGift, setUsersReceivedGift] = useState<TypedGiftTransaction[]>(
    [] as TypedGiftTransaction[]
  )
  const [fetchingListUser, setFetchingListUser] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const totalCount = useRef<{ value: number }>({ value: 0 })
  const permission_data = useAppSelector(state => state.user.permission_data)

  const giftMedia = gift?.media_id
  const isDigitalGift = Boolean(gift?.gift_digital_media)

  const getGiftDetail = async () => {
    try {
      const res = await getDetailGift(giftId)
      console.log('res', res)
      setGift(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getUsersReceivedGift = async () => {
    try {
      setFetchingListUser(true)
      const res = await getListUserReceivedGift(giftId)
      setUsersReceivedGift(res?.data)
      totalCount.current.value = res.headers['x-total-count']
    } catch (err) {
      console.log(err)
    } finally {
      setFetchingListUser(false)
    }
  }

  useEffect(() => {
    getGiftDetail()
    getUsersReceivedGift()
  }, [giftId])



  const renderListUser = () => {
    return <div>list user</div>
  }

  return (
    <BigLayout>
      <_Helmet title='gift_title' />
      <Row as={Container} className="w-100 py-4">
        <Col xs={12}>
          <Row>
            <Col as={Stack} gap={2} md={6}>
              <Image src={giftMedia?.media_url} className="w-xs-100 mb-3" />
            </Col>
            <Col md={6} className="">
              <h6 className="fs-5">{gift?.name}</h6>
              <Typography color='primary.main'>{__helpers.convertToCommasFormat(gift?.price)} {__("currency_unit")}</Typography>
              { gift?.gift_type === "gift"  && (
                <p className="text-success">{`${__("gift_quantity_text")} ${gift?.stock_qty} ${__("gift_quantity")}`}</p>
              )}
              <hr />
              { gift?.gift_type === "gift" && (
                <>
                  <p className="fw-semibold text-black">{__("gift_condition")}</p>
                  {gift?.gift_conditions && (
                    <Stack direction="horizontal" className="d-flex flex-wrap align-items-center">
                      {Object.keys(gift?.gift_conditions).map((key, index) =>
                        'start_time|end_time|_id'.includes(key) ? null : (
                          <div
                            key={index.toString()}
                            className="d-flex align-items-center justify-content-start mb-1 mx-1 me-3"
                          >
                            <FontAwesomeIcon
                              icon={GiftConditionTypeIcon[key]}
                              className={`${key !== EnumGiftConditionType.Birth ? 'me-2' : 'me-2'}`}
                            />
                            <div>
                              {__(GiftConditionTypeTitle[key]) +
                                (key !== EnumGiftConditionType.Birth ? ':' : '')}&nbsp;
                            </div>
                            <div>
                              {key !== EnumGiftConditionType.Birth &&
                                helpers.convertToCommasFormat(gift?.gift_conditions[key])}
                            </div>
                          </div>
                        )
                      )}
                    </Stack>
                  )}
                  <hr />
                </>
              )}
              {gift?.description && (
                <div style={{ whiteSpace: 'pre-line' }}>{Parser(gift?.description)}</div>
              )}
              {helpers.current_user_can('boss', permission_data) && isDigitalGift && (
                <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                  <a
                    href={gift?.gift_digital_media?.media_url}
                    className="text-decoration-none d-flex align-items-center"
                    download
                  >
                    {getIconFile(getMimeFile(gift?.gift_digital_media?.media_mime_type))}
                    <span
                      className={'ms-2 text-truncate d-inline-block'}
                      style={{
                        maxWidth: '200px'
                      }}
                    >
                      {gift?.gift_digital_media?.media_file_name}
                    </span>
                  </a>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <p className="fw-semibold">{totalCount.current.value} {__("gift_member_received")}</p>
          <RenderView
            isLoading={fetchingListUser}
            view={
              <InfiniteList
                className="d-flex flex-wrap gap-2"
                list={usersReceivedGift}
                hasNextPage={totalCount.current.value > usersReceivedGift.length}
                renderItem={(giftTransaction, ref) => {
                  const user = giftTransaction?.user_id
                  return (
                    <div
                      key={user._id}
                      className="d-flex border rounded-2 p-2"
                      ref={ref}
                      style={{
                        width: '100%',
                        minWidth: '260px',
                        maxWidth: '300px'
                      }}
                    >
                      <Avatar
                        src={user?.user_avatar_thumbnail || user?.user_avatar}
                        roundedCircle
                        width={60}
                        height={60}
                      />

                      <div className="ms-2 d-flex flex-column justify-content-evenly">
                        <div>{user?.display_name}</div>
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon icon={faArrowUp91} />
                          <div style={{ marginRight: 20 }}>{user?.point} {__("point_unit")}</div>
                          <FontAwesomeIcon icon={faMedal} />
                          <div>{__("gift_member_level")} {user?.level_number}</div>
                        </div>
                      </div>
                    </div>
                  )
                }}
                onLoadMore={() => setPage(prev => prev + 1)}
              />
            }
          />
        </Col>
      </Row>
    </BigLayout>
  )
}

export default GiftDetail
