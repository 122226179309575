import {
  AffiliateSidebarIcon,
  CalendarIcon,
  CourseSidebarIcon,
  EventIcon,
  GroupSidebarIcon,
  MissionSidebarICon,
  OrderSidebarIcon,
  RankingSidebarIcon
} from '../icons'
import PodcastSidebarIcon from '../icons/PodcastSidebarIcon'

type SidebarItemType = {
  label: string
  icon: any
  path: string
  subMenu?: Pick<SidebarItemType, 'label' | 'path'>[]
}

export const freeChannelConfigs: SidebarItemType[] = [
  {
    label: 'Event',
    icon: EventIcon,
    path: '/temp/event'
  },
  {
    label: 'Calendar',
    icon: CalendarIcon,
    path: '/temp/calendar'
  },
  {
    label: 'Courses',
    icon: CourseSidebarIcon,
    path: '/temp/courses',
    subMenu: [
      {
        label: 'All courses',
        path: '/temp/courses/all'
      },
      {
        label: 'Joined courses',
        path: '/temp/courses/joined'
      }
    ]
  },
  {
    label: 'Members',
    icon: GroupSidebarIcon,
    path: '/temp/members'
  },

  {
    label: 'Ranking',
    icon: RankingSidebarIcon,
    path: '/temp/ranking'
  },

  {
    label: 'Orders',
    icon: OrderSidebarIcon,
    path: '/temp/orders',
    subMenu: [
      {
        label: 'Registered packages',
        path: '/temp/registered-package'
      },
      {
        label: 'My Orders',
        path: '/temp/orders/my-orders'
      },
      {
        label: 'Channel Orders',
        path: '/temp/orders/channel-orders'
      }
    ]
  },

  {
    label: 'Missions',
    icon: MissionSidebarICon,
    path: '/temp/missions'
  },
  {
    label: 'Affiliate',
    icon: AffiliateSidebarIcon,
    path: '/temp/affiliate'
  },
  {
    label: 'Podcasts',
    icon: PodcastSidebarIcon,
    path: '/podcast'
  }
]
