import _Helmet from "components/helmet";
import BigLayout from "layouts/bigLayout";
import UsersJoinedComponent from "./component/challenge.detail/UsersJoined";
import ShopBreadcrumb from "entities/shop/components/shop.breadcrumb";
import { useParams } from "react-router-dom";
import __ from "languages/index";

const UserJoined = (props) => {
    const { challenge_id } = useParams()

    return (
        <BigLayout>
            <_Helmet title='challenge_detail_list_join' />
            <ShopBreadcrumb
                breadcrumbName={{
                    '/challenge': 'Challenge',
                    [`/challenge/userJoin/${challenge_id}`]: __('challenge_detail_list_join')
                }}
            />
            <div className="mainFrame">
                <UsersJoinedComponent challengeId={challenge_id} />
            </div>
        </BigLayout>
    )
}

export default UserJoined
