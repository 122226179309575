export const dataTabs = [
    {
        label: 'home_page_new',
        value: 'time'
    },
    {
        label: 'home_page_highlight',
        value: 'most_popular'
    },
    {
        label: 'home_page_like',
        value: 'most_upvote'
    },
    {
        label: 'home_page_trending',
        value: 'trending'
    },

]