//Library
import {Link, useLocation} from 'react-router-dom'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faArrowUp91,
  faBars,
  faBuildingColumns,
  faEnvelope,
  faFile,
  faGears,
  faScrewdriverWrench,
  faTimeline,
  faTrophy,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'

//Components
import ChannelSettingInvite from './components/channel.setting.invite'
import ChannelSettingLevel from './components/channel.setting.level'
import ChannelSettingOverview from './components/channel.setting.overview'
import ChannelSettingCategory from './components/channel.setting.category'
import ChannelSettingPayment from './components/channel.setting.payment'
import ChannelSettingPoint from './components/channel.setting.point'
// import ChannelSettingEmail from './components/channel.setting.email'
import _Helmet from 'components/helmet'

//Helpers
import helpers from 'helpers'
import __ from "languages/index"

//Scss
import './media/channel.setting.scss'
import ChannelSettingAdvance from "entities/channelSetting/components/channel.setting.advance";
import { Box } from '@mui/material'

interface TypeDataTab {
  id: number
  hash: string
  to: string
  label: string
  icon: IconDefinition
}

const dataTab: TypeDataTab[] = [
  {
    id: 0,
    hash: '#invite',
    to: '',
    label: `${__("channel_setting_data_invite")}`,
    icon: faEnvelope
  },
  {
    id: 1,
    hash: '#overview',
    to: '',
    label: `${__("channel_setting_data_overview")}`,
    icon: faFile
  },
  {
    id: 2,
    hash: '#category',
    to: '',
    label: `${__("channel_setting_data_category")}`,
    icon: faBars
  },
  {
    id: 3,
    hash: '#gamifications',
    to: '',
    label: `${__("channel_setting_data_gamifications")}`,
    icon: faTrophy
  },
  {
    id: 4,
    hash: '#payment',
    to: '',
    label: `${__("channel_setting_data_payment")}`,
    icon: faBuildingColumns
  },
  {
    id: 5,
    hash: '#point',
    to: '',
    label: `${__("channel_setting_data_point")}`,
    icon: faArrowUp91
  },
  // {
  //   id: 6,
  //   hash: '#email',
  //   to: '',
  //   label: `${__("channel_setting_data_email")}`,
  //   icon: faGears
  // },
  {
    id: 7,
    hash: '#domain',
    to: '/domain/intro',
    label: `${__("channel_setting_data_domain")}`,
    icon: faTimeline
  },
  {
    id: 8,
    hash: '#advance',
    to: '',
    label: `${__("channel_setting_data_advance")}`,
    icon: faScrewdriverWrench
  }
]

const ChannelSettingPage = () => {
  const { hash } = useLocation()

  const [tabMenu, setTabMenu] = useState<number>(3)

  const handleChangeMenu = useCallback((index: number) => {
    setTabMenu(index)
  }, [])

  const ComponentView = useMemo(() => {
    let _Component: React.ComponentType
    switch (tabMenu) {
      case 0:
        _Component = ChannelSettingInvite
        break
      case 1:
        _Component = ChannelSettingOverview
        break
      case 2:
        _Component = ChannelSettingCategory
        break
      case 3:
        _Component = ChannelSettingLevel
        break
      case 4:
        _Component = ChannelSettingPayment
        break
      case 5:
        _Component = ChannelSettingPoint
        break
      // case 6:
      //   _Component = ChannelSettingEmail
      //   break
      case 8:
        _Component = ChannelSettingAdvance
        break
      default:
        _Component = ChannelSettingLevel
        break
    }
    return _Component
  }, [tabMenu])

  useEffect(() => {
    if (!hash) return handleChangeMenu(3)
    switch (hash) {
      case '#invite':
        handleChangeMenu(0)
        break
      case '#overview':
        handleChangeMenu(1)
        break
      case '#category':
        handleChangeMenu(2)
        break
      case '#gamifications':
        handleChangeMenu(3)
        break
      case '#payment':
        handleChangeMenu(4)
        break
      case '#point':
        handleChangeMenu(5)
        break
      // case '#email':
      //   handleChangeMenu(6)
      //   break
      case '#advance':
        handleChangeMenu(8)
        break
      default:
        handleChangeMenu(3)
    }
  }, [hash])

  return (
    <>
      <_Helmet title='channel_setting' />
      <div id="channelSettingPage" className="channelSettingPage_container">
        <div className="channelSettingPage_left">
          {!helpers.isEmpty(dataTab) &&
            dataTab?.map((item: TypeDataTab, idx: number) => (
              <Box component={Link} 
                sx={{color: theme => tabMenu === item?.id ? theme.palette.primary.main : 'unset'}}
                key={`dataTab_${idx}`}
                to={`${item?.to}${item?.hash}`}
                className={`channelSettingPage_left_item_link`}
                role="button"
              >
                <FontAwesomeIcon width={15} icon={item?.icon} />
                <span className="channelSettingPage_left_item_title">{item?.label}</span>
              </Box>
            ))}
        </div>
        <div className="channelSettingPage_right">
          <ComponentView />
        </div>
      </div>
    </>
  )
}

export default ChannelSettingPage
