import React, {useEffect, useState} from 'react'
import AddItemToList from 'components/AddItemToList'
import {FormControl, ListGroup, Stack} from 'react-bootstrap'
import {faThumbTack} from '@fortawesome/free-solid-svg-icons'
import {getListCourses} from 'entities/courses/services/courses.service'
import {useSelector} from 'react-redux'
import {getUserId} from 'store/user.store.reducer'
import {TypedCourse} from 'entities/courses/courses.interface'
import {updateLiveStream} from '../services/livestream.service'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import {Socket} from 'socket.io-client'
import Image from 'react-bootstrap/Image'
import __helpers from 'helpers/index'
import IconButton from 'components/IconButton'
import useDebounce from 'hooks/useDebounce'
import RenderView from 'components/RenderView'
import __ from "languages/index";

type Product = TypedCourse

export const setLiveStreamProductToLocalStorage = (liveStreamId: string, products: Product[]) => {
  setLocalStorage(liveStreamId, products)
}

export const getLiveStreamProductFromLocalStorage = (liveStreamId: string) => {
  return getItemFromLocalStorage(liveStreamId) || []
}

export const setLocalStorage = (key: string, value) => {
  if (!value) return undefined
  localStorage.setItem(key, JSON.stringify(value))
}

export const getItemFromLocalStorage = (key: string) => {
  if(!__helpers.isJson(localStorage.getItem(key))) return []
  return JSON.parse(localStorage.getItem(key))
}

type Props = {
  socket: Socket
  liveStreamId: string
  pinningProduct: Product
  onPinProductSuccess: (product: Product) => void
  onPinProductFailed: (product: Product) => void
}

const AddListProduct = ({
  socket,
  pinningProduct,
  liveStreamId,
  onPinProductFailed,
  onPinProductSuccess
}: Props) => {
  const userId = useSelector(getUserId)

  const [productList, setProductList] = useState<TypedCourse[]>([])
  const [selectedResources, setSelectedResources] = useState<Product[]>([])
  const [selectedDestination, setSelectedDestination] = useState<Product[]>([])
  const [search, setSearch] = useState<string>('')
  const [fetchingProducts, setFetchingProducts] = useState<boolean>(false)

  const debounceSearchValue = useDebounce(search)

  const toast = useToast()

  const handleSelectItem =
    (item: Product) => (set: React.Dispatch<React.SetStateAction<Product[]>>) => {
      return set(prev => {
        if (!prev.includes(item)) return [...prev, item]
        return prev.filter(el => el._id !== item._id)
      })
    }

  const emitPinProduct = (product: Product | null) => {
    socket.emit(
      'emitProduct',
      JSON.stringify({ payload: product, room_id: `livestream_${liveStreamId}` })
    )
  }

  const handlePinProduct = async (product: Product) => {
    const newPinProduct = pinningProduct?._id === product._id ? null : product
    try {
      emitPinProduct(newPinProduct)
      await updateLiveStream({
        product_id: newPinProduct ? newPinProduct._id : null,
        _id: liveStreamId
      })
      onPinProductSuccess(newPinProduct)
    } catch (err) {
      emitPinProduct(pinningProduct)
      onPinProductFailed(product)
      toast.show({
        type: EnumTypeToast.Error,
        content: `${__("livestream_add_list_product_error")}`
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      // const listProduct = getLiveStreamProductFromLocalStorage(liveStreamId)
      // const listProductId = listProduct.map(item => item._id)
      try {
        setFetchingProducts(true)
        const res = await getListCourses({ auth_id: userId, search: debounceSearchValue })
        // const courses = listProduct.length
        //   ? res?.data.filter(el => !listProductId.includes(el._id))
        //   : res?.data
        const courses = res?.data
        setProductList(courses)
      } catch (err) {
        console.log(err)
      } finally {
        setFetchingProducts(false)
      }
    })()
  }, [debounceSearchValue])

  const renderListProduct = () => {
    return (
      <AddItemToList
        resource={productList}
        destination={getLiveStreamProductFromLocalStorage(liveStreamId) as TypedCourse[]}
      >
        {({ resource, destination, add, remove }) => (
          <Stack
            className="d-flex justify-content-between align-items-start"
            gap={2}
            direction="horizontal"
          >
            {/*<div className="w-100">*/}
            {/*  <p className="m-0">Danh sách sản phẩm</p>*/}
            {/*  <ListGroup className="mb-2">*/}
            {/*    {resource?.map(item => (*/}
            {/*      <ListGroup.Item*/}
            {/*        as={Stack}*/}
            {/*        gap={1}*/}
            {/*        direction="horizontal"*/}
            {/*        onClick={() => handleSelectItem(item)(setSelectedResources)}*/}
            {/*      >*/}
            {/*        <FormCheck*/}
            {/*          type="checkbox"*/}
            {/*          checked={selectedResources.includes(item)}*/}
            {/*          className="mt-1"*/}
            {/*        />*/}
            {/*        <span>{item?.title}</span>*/}
            {/*      </ListGroup.Item>*/}
            {/*    ))}*/}
            {/*  </ListGroup>*/}
            {/*  <IconButton*/}
            {/*    icon={faAdd}*/}
            {/*    onClick={() => {*/}
            {/*      add(selectedResources)*/}
            {/*      setLiveStreamProductToLocalStorage(liveStreamId, destination)*/}
            {/*      setSelectedResources([])*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Thêm*/}
            {/*  </IconButton>*/}
            {/*</div>*/}
            <div className="w-100">
              <ListGroup>
                {resource?.map(item => (
                  <ListGroup.Item
                    as={Stack}
                    gap={2}
                    direction="horizontal"
                    className="p-2 d-flex justify-content-between"
                  >
                    <Stack
                      direction="horizontal"
                      gap={2}
                      onClick={() => handleSelectItem(item)(setSelectedDestination)}
                    >
                      {/*<FormCheck*/}
                      {/*  className="mt-1"*/}
                      {/*  type="checkbox"*/}
                      {/*  checked={selectedDestination.includes(item)}*/}
                      {/*/>*/}
                      <Image
                        src={item?.avatar?.media_thumbnail || item?.avatar?.media_url}
                        width={130}
                        height={'auto'}
                        style={{
                          maxHeight: '70px'
                        }}
                      />
                      <Stack>
                        <span className="fw-semibold">{item?.title}</span>
                        <span className="text-primary">
                          {__helpers.formatNumberWithCommas(item?.coin_value)} đ
                        </span>
                      </Stack>
                    </Stack>
                    <IconButton
                      icon={faThumbTack}
                      variant="none"
                      className={pinningProduct?._id === item._id ? 'text-primary' : ''}
                      onClick={() => handlePinProduct(item)}
                    />
                  </ListGroup.Item>
                ))}
              </ListGroup>
              {/*<IconButton*/}
              {/*  icon={faTrash}*/}
              {/*  onClick={() => {*/}
              {/*    remove(selectedDestination)*/}
              {/*    if (selectedDestination.map(item => item._id).includes(pinningProduct?._id)) {*/}
              {/*      emitPinProduct(null)*/}
              {/*    }*/}
              {/*    setLiveStreamProductToLocalStorage(liveStreamId, destination)*/}
              {/*    setSelectedDestination([])*/}
              {/*  }}*/}
              {/*  className="mt-2"*/}
              {/*>*/}
              {/*  Xoá sản phẩm đã chọn*/}
              {/*</IconButton>*/}
            </div>
          </Stack>
        )}
      </AddItemToList>
    )
  }

  return (
    <>
      <FormControl
        type="text"
        onChange={e => setSearch(e.target.value)}
        className="mb-2"
        placeholder={__("livestream_add_list_product_search")}
      />
      <RenderView
        view={renderListProduct()}
        isEmpty={productList.length === 0}
        isLoading={fetchingProducts}
      />
    </>
  )
}

export default AddListProduct
