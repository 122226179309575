import React, { useCallback } from 'react';

export default function SettingMentorSkeleton() {

  const renderItem = useCallback((_, index) => {
    return (
      <p key={index} className="card-text placeholder-glow mb-4 p-4 rounded" style={{ backgroundColor: '#f8f9fa' }}>
        <span className="placeholder col-7"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-4"></span>
        <span className="placeholder col-6"></span>
        <span className="placeholder col-8"></span>
      </p>
    )
  }, [])

  return (
    <div className="row ps-3 pe-3">
      {[1, 2, 3].map(renderItem)}
    </div>
  )
}
