import helpers from "helpers/index";
import React, {useCallback, useEffect, useState} from "react";
import { Modal} from "react-bootstrap";
import __ from "languages/index";
import { Button } from "@mui/material";

export default function DeleteConfirm({ show, onClose, title, desciption = '', ...args }: { desciption?: string, show: boolean, onClose: Function, title: string, args?: any }) {

  const [showS, setShowS] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowS(show);
  }, [show]);

  const closeOnly = useCallback(() => {
    setShowS(false);
    onClose?.call(this, false, args);
  }, []);


  const closeAndAgree = useCallback(async () => {
    setLoading(true);
    await helpers.sleep(1000);
    setShowS(false);
    onClose?.call(this, true, args);
    await helpers.sleep(500);
    setLoading(false);
  }, []);

  return (
    <Modal show={showS} onHide={closeOnly}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{desciption.length > 0 ? desciption : `${__("delete_confirm_text")}`}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button sx={{textTransform: 'none'}} variant="text" onClick={closeOnly}>{__("btn_close")}</Button>
        <Button sx={{textTransform: 'none'}} disabled={loading} onClick={closeAndAgree}>{loading ? `${__("delete_confirm_btn_deleting")}` : `${__("delete_confirm_btn_delete")}`}</Button>
      </Modal.Footer>
    </Modal>
  );
}
