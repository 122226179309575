//Library
import {useCallback, useEffect, useState} from 'react'
import {ProgressBar} from 'react-bootstrap'
import dateandtime from 'date-and-time'
import { Link } from 'react-router-dom'


//Components
import SkeletonMission from '../mission/components/mission.skeleton'
import DetailListMission from '../mission/components/mission.detail.list'
import ComponentEmpty from 'components/componentEmpty'
import ViewGiftMission from './components/mission.view.gift'
import _Helmet from 'components/helmet'

//Images
import defaultImage from 'media/images/image_default.png'
import iconDiamond from 'media/icons/icon_diamond.svg'

//Data
import {TypeSortMission} from './data/enum'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {createListRedeemJoinPermission, getListRedeem} from './store/mission.store.reduce'

//Interface
import {TypeListRedeem, TypeListRedeemPermission, TypeMissionAction, TypePointData} from './interface'

//Helper
import helpers from 'helpers'

//Scss
import './media/mission.detail.scss'
import __ from "languages/index";
import SmallLayout from "../../layouts/smallLayout";
import { Button, Stack, Typography } from '@mui/material'

const DetailMission = () => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)
  const user_data = useAppSelector(state => state.user.user_data)

  const [isLoading, setIsLoading] = useState < boolean > (false)
  const [isShowGift, setIsShowGift] = useState < boolean > (false)
  const [listRedeem, setListRedeem] = useState < TypeListRedeem[] > ([])
  const [tabName, setTabName] = useState < TypeListRedeem > ()
  const [dataRedeem, setDataRedeem] = useState < TypeListRedeemPermission[] > ([])

  const handleChangeTabName = useCallback((tabName: TypeListRedeem) => {
    setTabName(tabName)
    setIsLoading(true)
    try {
      dispatch(
        createListRedeemJoinPermission({
          redeem_id: tabName?._id,
          user_id: user_data?._id,
          channel_id: channel_data?._id
        })
      )
        .unwrap()
        .then(response => {
          setIsLoading(false)
          setDataRedeem(response?.data)
        })
        .catch(error => {
          console.log(`createListRedeemJoinPermission_${error}`)
          setIsLoading(false)
        })
    } catch (error) {

    }

  }, [])

  const handleCalculator = useCallback(
    (completedMission?: TypePointData[], totalMission?: TypeMissionAction[]) => {
      let valueCompletedMission = 0
      let valueTotalMission = 0
      completedMission?.length > 0 &&
        completedMission?.forEach((item: TypePointData) => {
          valueCompletedMission = valueCompletedMission + Number(item?.point_number)
        })
      totalMission?.length > 0 &&
        totalMission?.forEach(
          (item: TypeMissionAction) =>
            (valueTotalMission = valueTotalMission + Number(item?.action_point))
        )
      return { completedMission: valueCompletedMission, totalMission: valueTotalMission }
    },
    []
  )

  const handleCalculatorCompletedMission = useCallback(
    (completedMission?: TypePointData[], totalMission?: TypeMissionAction[]) => {
      const dataCalculator = handleCalculator(completedMission, totalMission)
      return {
        label:
          dataCalculator?.totalMission - dataCalculator?.completedMission > 0
            ? `${__("mission_complete")} ${dataCalculator?.completedMission}/${dataCalculator?.totalMission} ${__("mission_remaining")} ${dataCalculator?.totalMission - dataCalculator?.completedMission
            } ${__("mission_unfinished")}`
            : `${__("mission_complete_task")}`,
        percent: parseFloat(
          ((dataCalculator?.completedMission / dataCalculator?.totalMission) * 100)?.toFixed(2)
        ),
        complete: dataCalculator?.totalMission - dataCalculator?.completedMission <= 0
      }
    },
    []
  )

  const handleSortMission = useCallback((dataMission: TypeListRedeemPermission[]) => {
    let _currentMission = [],
      _futureMission = [],
      _pastMission = []
    dataMission?.forEach((item: TypeListRedeemPermission) => {
      const start_day = new Date(item?.createdAt)
      const day = Number(item?.redeem_mission_id?.number_of_day || '0') - 1
      const todayDate = new Date()
      const currentDate = new Date(start_day?.setDate(start_day?.getDate() + Number(day)))
      if (
        dateandtime.isSameDay(currentDate, todayDate) &&
        item.redeem_mission_id?.mission_action?.length > 0
      ) {
        _currentMission.push(item)
        return
      }

      if (dateandtime.subtract(currentDate, todayDate).toMilliseconds() > 1) {
        return _futureMission.push(item)
      }
      if (dateandtime.subtract(currentDate, todayDate).toMilliseconds() < 0) {
        return _pastMission.push(item)
      }
    })
    return {
      currentMission: _currentMission,
      futureMission: _futureMission,
      pastMission: _pastMission
    }
  }, [])

  const handleCalculatorCompletedMissionAll = useCallback(() => {
    let valueCompletedMissionAll = 0
    let valueTotalMissionAll = 0
    dataRedeem?.length &&
      dataRedeem?.forEach((item: TypeListRedeemPermission) => {
        const dataCalculator = handleCalculator(
          item?.point_data,
          item?.redeem_mission_id?.mission_action
        )
        valueCompletedMissionAll = valueCompletedMissionAll + dataCalculator?.completedMission
        valueTotalMissionAll = valueTotalMissionAll + dataCalculator?.totalMission
      })
    return {
      label:
        valueTotalMissionAll - valueCompletedMissionAll > 0
          ? `${__("mission_complete")} ${valueCompletedMissionAll}/${valueTotalMissionAll} ${__("mission_remaining")} ${valueTotalMissionAll - valueCompletedMissionAll
          } ${__("mission_unfinished")}`
          : `${__("mission_complete_task")}`,
      percent: parseFloat(((valueCompletedMissionAll / valueTotalMissionAll) * 100)?.toFixed(2)),
      complete: valueTotalMissionAll - valueCompletedMissionAll <= 0
    }
  }, [dataRedeem])

  const handleShowModalOptionGift = useCallback(() => {
    setIsShowGift(true)
  }, [])

  const handleCloseModalOptionGift = useCallback(() => {
    setIsShowGift(false)
  }, [])

  useEffect(() => {
    if (!listRedeem?.length) return
    setTabName(listRedeem[0])
  }, [listRedeem])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getListRedeem())
      .unwrap()
      .then(res => {
        setListRedeem(res?.data)
        res?.data?.length &&
          dispatch(
            createListRedeemJoinPermission({
              redeem_id: res?.data[0]?._id,
              user_id: user_data?._id,
              channel_id: channel_data?._id
            })
          )
            .unwrap()
            .then(response => {
              setIsLoading(false)
              setDataRedeem(response?.data)
            })
            .catch(error => {
              console.log(`createListRedeemJoinPermission_${error}`)
              setIsLoading(false)
            })
        !res?.data?.length && setIsLoading(false)
      })
      .catch(error => {
        setListRedeem([])
        setIsLoading(false)
        console.log(`getListRedeem_${error}`)
      })
  }, [])

  return (
    <SmallLayout>
      <_Helmet title='mission_title' />
      <div className="mainFrame">
        <div className="">
          <div id="missionDetail" className="missionDetail_container">
            <h3>{__("mission_title")}</h3>
            {listRedeem?.length > 1 && (
              <div className="missionDetail_container_tabs">
                {listRedeem?.map((item: TypeListRedeem, idx: number) => (
                  <Button
                    variant='text'
                    key={`mission_detail_${idx}`}
                    onClick={() => handleChangeTabName(item)}
                    className={`missionDetail_container_tabs_item`}
                    sx={{p: theme=>theme.spacing(1.5,1.5,0)}}
                  >
                    <Typography textTransform={'none'} fontWeight={tabName?._id === item?._id ? 500 : 400} color={tabName?._id === item?._id ? 'primary.main' : 'text.primary'}>
                      {item?.redeem_name}
                    </Typography>
                  </Button>
                ))}
              </div>
            )}
            <div className="missionDetail_wrapper">
              <div className="missionDetail_body container-small">
                {isLoading ? (
                  <div className="bg-white p-4 rounded mt-4">
                    <p
                      className="card-text placeholder-glow mb-4 p-4 rounded"
                      style={{ backgroundColor: '#f8f9fa' }}
                    >
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                      <span className="placeholder col-8"></span>
                    </p>
                  </div>
                ) : (
                  <>
                    {listRedeem?.length > 0 && (
                      <div className="missionDetail_body_header">
                        <div>
                          <span className="missionDetail_body_header_first">
                            {tabName?.redeem_name || ''}
                          </span>
                        </div>
                        <div className="missionDetail_body_header_second"></div>
                        <div className="missionDetail_body_header_wrapper">
                          <div className="missionDetail_body_header_progress">
                            <ProgressBar
                              animated
                              variant="primary"
                              now={handleCalculatorCompletedMissionAll()?.percent}
                            />
                          </div>
                          <span className="missionDetail_body_header_progress_title">
                            {handleCalculatorCompletedMissionAll()?.label || ''}
                          </span>
                          <div>
                            <span className="missionDetail_body_header_progress_title">
                              {__("mission_complete_to_receive_reward")}
                            </span>

                            {Boolean(tabName?.gift_coin) && (
                              <Stack direction={'row'} columnGap={1}>
                                <div className="missionDetail_right_info_award_name">
                                  <img
                                    src={iconDiamond}
                                    width={20}
                                    height={20}
                                    alt="icon/diamond"
                                    loading="lazy"
                                  />
                                  <span>{`${helpers.formatNumberWithCommas(
                                    tabName?.gift_coin || '0'
                                  )}`}</span>
                                </div>
                                <Button
                                  variant="text"
                                  sx={{p: 0, textTransform: 'none'}}
                                  onClick={handleShowModalOptionGift}
                                  className="missionDetail_right_info_award_question"
                                >
                                  {__("mission_diamond")}
                                </Button>
                              </Stack>
                            )}
                            {tabName?.gift_data?.length > 0 && (
                              <Link
                                to={`gift/${tabName?.gift_data[0]?._id}`}
                                state={{option_type: 'mission'}}
                                className="missionTimeline_right_info_award_name text-decoration-none"
                                >
                                <img
                                  src={tabName?.gift_data[0]?.media_id?.media_url || defaultImage}
                                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                    e.currentTarget.src = defaultImage
                                  }}
                                  alt="image-award"
                                />
                                <div className="missionTimeline_right_award_name">
                                  <Typography color='primary.main'>{tabName?.gift_data[0]?.name || ''}</Typography>
                                  <Typography color='primary.main'>{`${helpers.formatNumberWithCommas(
                                    tabName?.gift_data[0]?.price || '0'
                                  )} ${__("currency_unit")}`}</Typography>
                                </div>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="missionDetail_body_content">
                  {isLoading ? (
                    <div className="bg-white p-4 rounded mt-4">
                      <SkeletonMission />
                    </div>
                  ) : (
                    <>
                      {dataRedeem?.length ? (
                        <>
                          {handleSortMission(dataRedeem)?.currentMission?.length > 0 && (
                            <>
                              <span className="missionDetail_header_title_bold">
                                {__("mission_today")}
                              </span>
                              {handleSortMission(dataRedeem)?.currentMission?.map(
                                (item: TypeListRedeemPermission, idx: number) => (
                                  <DetailListMission
                                    item={item}
                                    handleCalculatorCompletedMission={
                                      handleCalculatorCompletedMission
                                    }
                                    handleShowModalOptionGift={handleShowModalOptionGift}
                                    key={`mission_detail_${idx}`}
                                    type={TypeSortMission?.CURRENT_MISSION}
                                  />
                                )
                              )}
                            </>
                          )}
                          {handleSortMission(dataRedeem)?.futureMission?.length > 0 && (
                            <>
                              <span className="missionDetail_header_title_bold">
                                {__("mission_next_day")}
                              </span>
                              {handleSortMission(dataRedeem)?.futureMission?.map(
                                (item: TypeListRedeemPermission, idx: number) => (
                                  <DetailListMission
                                    item={item}
                                    handleCalculatorCompletedMission={
                                      handleCalculatorCompletedMission
                                    }
                                    handleShowModalOptionGift={handleShowModalOptionGift}
                                    key={`mission_detail_${idx}`}
                                    type={TypeSortMission?.FUTURE_MISSION}
                                  />
                                )
                              )}
                            </>
                          )}
                          {handleSortMission(dataRedeem)?.pastMission?.length > 0 && (
                            <>
                              <span className="missionDetail_header_title_bold">
                                {__("mission_ended")}
                              </span>
                              {handleSortMission(dataRedeem)?.pastMission?.map(
                                (item: TypeListRedeemPermission, idx: number) => (
                                  <DetailListMission
                                    item={item}
                                    handleCalculatorCompletedMission={
                                      handleCalculatorCompletedMission
                                    }
                                    handleShowModalOptionGift={handleShowModalOptionGift}
                                    key={`mission_detail_${idx}`}
                                    type={TypeSortMission?.PAST_MISSION}
                                  />
                                )
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <ComponentEmpty />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {isShowGift && (
              <ViewGiftMission show={isShowGift} onClose={handleCloseModalOptionGift} />
            )}
          </div>
        </div>
      </div>
    </SmallLayout>
  )
}

export default DetailMission
