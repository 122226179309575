import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react'
import {Form, InputGroup} from 'react-bootstrap'
import CourseTableUser from './components/course.table.user';
import ModalAddUser from './components/course.modal.add.user';
import {getEntity, getListUserJoin} from './courses.store.reducer';
import {useInfiniteScroll} from 'hooks/useInfiniteScroll';
import {useParams} from 'react-router-dom'
import ModalAccept from 'entities/homepage/components/modal.accept';
import {postDeleteUserToCourse} from 'entities/gift/services/gift.receiver.service';
import {EnumTypeToast, useToast} from 'hooks/useToast'
import {useAppDispatch, useAppSelector} from 'config/store';
import __ from "languages/index";
import helpers from 'helpers/index';
import MediumLayout from "../../layouts/mediumLayout";

export default function CourseManageUser() {
  const [modalAddUser, setModalAddUser] = useState(false);
  const user_data = useAppSelector(state => state.user.user_data)

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const permission_data = useAppSelector(state => state.user.permission_data)

  let useParam = useParams()
  const [courseId, setCourseId] = useState < string | undefined > (useParam.course_id || '')
  const toast = useToast()
  const { data, hasMore, refresh, loadMore, updateParamRequestDebounce, isRefreshing } = useInfiniteScroll < any > ({
    course_id: courseId,
    search: '',
    limit: 10
  }, getListUserJoin)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (courseId) {
      dispatch(
        getEntity({
          _id: courseId,
          auth_id: user_data?._id || ''
        })
      )
    }
  }, [])

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allItemIds = data.map(item => item?._id);
      setSelectedItems(allItemIds);
    } else {
      setSelectedItems([]);
    }
  };


  const handleCheckboxChange = (itemId) => {
    setSelectAll(false);
    setSelectedItems(prevSelected => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter(id => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  const acceptDelete = () => {
    const dataUserDelete = selectedItems.join(',')

    const params = {
      user_id: dataUserDelete,
      course_id: courseId
    }
    postDeleteUserToCourse(params).then(res => {
      setModalShow(false)
      refresh()
      toast.show({ type: EnumTypeToast.Success, content: `${__("course_manage_delete_member_success")}` })
      setSelectAll(false);
      setSelectedItems([])
    }).catch(error => {
      toast.show({ type: EnumTypeToast.Error, content: `${__("course_manage_delete_member_failed")}` })

    })
  }

  const refreshUser = () => {
    setTimeout(() => {
      refresh()
    }, 1000);
  }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    updateParamRequestDebounce({ search: inputValue });
  };


  return (
    <MediumLayout>
        <div className='row'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className={'h3'}>{helpers.current_user_can('boss', permission_data) ? `${__("course_manage_student")}` : `${__("course_list_student")}`}</div>
            {helpers.current_user_can('boss', permission_data) ?
              <button onClick={() => setModalAddUser(true)} className='btn btn-primary'>
                {__("course_manage_add_student")}
              </button> : null
            }

          </div>
          <div className='d-flex align-items-center justify-content-between mt-3'>
            <div >
              {helpers.current_user_can('boss', permission_data) ?
                <div className='d-flex align-items-center'>
                  <Form.Check
                    type={'checkbox'}
                    id={`default`}
                    label={__('check_all')}
                    onChange={handleSelectAll}
                    checked={selectAll}
                    disabled={data.length > 0 ? false : true}
                  />
                  <button disabled={data.length > 0 && selectedItems.length > 0 ? false : true}
                          onClick={() => setModalShow(true)} className='btn btn-link ms-3'>
                    {__("course_modal_btn_delete")}
                  </button>
                </div> :
                <div>

                </div>
              }
            </div>
            <div className='d-flex align-items-center'>
              <InputGroup >
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  placeholder={__("course_search")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </div>
          </div>
          <div className='mt-3'>
            <CourseTableUser
              dataUnique={data}
              selectedItems={selectedItems}
              handleCheckboxChange={handleCheckboxChange}
              loadMore={loadMore}
              hasMore={hasMore}
              isRefreshing={isRefreshing}
              refresh={refresh}
            />
          </div>
          {modalAddUser &&
            <ModalAddUser
              show={modalAddUser}
              courseId={courseId}
              onHide={() => setModalAddUser(false)}
              refresh={refreshUser}
            />
          }
          {
            modalShow &&
            <ModalAccept
              show={modalShow}
              onHide={() => setModalShow(false)}
              onAccept={acceptDelete}
              title={__("course_manage_delete_member")}
              desciption={__("course_manage_delete_member_confirm_text")}
            />
          }

        </div>
    </MediumLayout>
  )

}
