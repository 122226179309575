import React, { useCallback, useEffect, useState } from 'react'
import { TypedRequest } from '../../../interfaces/request.interface'
import NewsFeedPost from "entities/homeNew/components/NewsFeedPost";
import DetailPostModal from "entities/homeNew/components/DetailPostModal";
import helpers from 'helpers'
import { Box, Drawer, IconButton, Stack } from '@mui/material';
import { ArrowLeftIcon, MenusIcon } from 'icons';
import DetailPostMobile from './DetailPostMobile';
interface TypedItemHomePostProps {
  is_user_logged_in: boolean
  post: TypedRequest
}

const ItemHomePost = ({ is_user_logged_in, post }: TypedItemHomePostProps) => {
  const checkMobile = helpers.isMobile();

  const [modalShowPost, setModalShowPost] = useState(false);
  const [isLike, setIsLike] = useState(post?.is_like);

  useEffect(() => {
    setIsLike(post?.is_like)
  }, [post?.is_like]);

  const showModalDetail = useCallback(() => {
    setModalShowPost(true)
  }, [])

  const hideModalDetail = useCallback(() => {
    setModalShowPost(false)
  }, [])


  return (
    <>
      <NewsFeedPost
        post={post}
        isLike={isLike}
        setIsLike={setIsLike}
        onClickCommentText={showModalDetail}
        onClickCommentButton={showModalDetail}
        is_user_logged_in={is_user_logged_in}
      />

      {
        checkMobile ? (
          <DetailPostMobile
            is_user_logged_in={is_user_logged_in}
            isLike={isLike}
            setIsLike={setIsLike}
            modalProps={{open: modalShowPost, onClose: hideModalDetail}}
            post={post}
          />
        ) : (
        <DetailPostModal
          is_user_logged_in={is_user_logged_in}
          isLike={isLike}
          setIsLike={setIsLike}
          modalProps={{open: modalShowPost, onClose: hideModalDetail}}
          post={post}/>
        )
      }

    </>
  )
}
export default ItemHomePost
