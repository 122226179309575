import React, { useEffect } from "react";
import useUploadMedia from "hooks/useUploadMedia";
import { Image, ImageProps, Spinner } from "react-bootstrap";
import { MediaResponse } from "entities/calendar/service";

type Props = {
  file: File;
  onSuccess?: (media: MediaResponse) => void;
} & ImageProps;

const PreviewImage = ({ file, onSuccess, ...rest }: Props) => {
  const { media, loading } = useUploadMedia(file);

  useEffect(() => {
    if (!media || loading) return;
    onSuccess(media);
  }, [media]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  return media && <Image src={media.src} {...rest} />;
};

export default PreviewImage;

