import React, { ChangeEvent, useMemo, useState } from 'react'
import {
  Badge,
  Button,
  FloatingLabel,
  Form,
  FormControl,
  FormGroup,
  Image,
  InputGroup,
  ListGroupItem,
  Modal,
} from 'react-bootstrap'
import moment from 'moment'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import MediaView from 'components/MediaView'
import { OfficialStatus, User } from '../challenge.interface'
import Avatar from 'components/Avatar'
import challengeApi, { useUpdateStatusActivityMutation } from '../store/challengeApi'
import helps from 'helpers'
import 'moment/locale/vi'
import __ from "languages/index";
import { Box, IconButton, Stack, Typography, styled } from '@mui/material'
import { CheckIcon, CloseIcon } from 'icons'

export type MediaMetaData = {
  media_url: string
  media_type: 'video' | 'image'
  media_thumbnail: string
}
type AttachmentType = {
  _id: string
  meta_key: string
  meta_title: string
  meta_type: 'text' | 'date' | 'image' | 'point'
  meta_value: string
}

type Props = {
  id: string
  className?: string
  title?: string
  description?: string
  attachments: AttachmentType[]
  time: string
  hasPermission?: boolean
  status?: OfficialStatus
  user?: User
  point_value?: number
  onConfirmSuccess: (status: OfficialStatus) => void
}

const ActivityItem = (props: Props) => {
  const {
    id,
    point_value,
    className,
    title,
    attachments,
    time,
    hasPermission = false,
    status,
    user,
    onConfirmSuccess
  } = props

  console.log(attachments)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [validated, setValidated] = useState<boolean>(false)

  const [getActivities, { data }] = challengeApi.endpoints.getActivities.useLazyQuery()
  const [updateStatusActivity] = useUpdateStatusActivityMutation()
  const defaultPoint = useMemo(() => {
    const points = attachments.filter(at => at.meta_type === 'point')
    return points[0]?.meta_value || '0'
  }, [attachments])
  moment.locale('vi')

  const [detail, setDetail] = useState<{
    official_status: OfficialStatus.APPROVED | OfficialStatus.REJECTED
    point_value: string
    admin_note: string
  }>({
    official_status: 1,
    point_value: defaultPoint,
    admin_note: ''
  })

  const toast = useToast()

  const closeModal = () => {
    setShowConfirmModal(false)
    setValidated(false)
    setDetail({
      official_status: 1,
      point_value: defaultPoint,
      admin_note: ''
    })
  }

  const openModal = (status: OfficialStatus.REJECTED | OfficialStatus.APPROVED) => {
    setDetail({ ...detail, official_status: status })
    setShowConfirmModal(true)
  }

  const confirmActivityHandler = async event => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else {
      try {
        // await updateActivity({
        //   _id: id,
        //   ...detail,
        //   official_status: detail.official_status.toString() as '1' | '2'
        // })
        updateStatusActivity({
          _id: id,
          ...detail,
          official_status: detail.official_status.toString() as '1' | '2'
        })

        // ` getActivities({ official_status: '0' })
        setShowConfirmModal(false)
        onConfirmSuccess(detail.official_status)
      } catch (err) {
        console.log(err)
        toast.show({ type: EnumTypeToast.Error, title: 'Error', content: err.response.data.message })
      }
    }
  }

  const changeDetailHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setDetail({
      ...detail,
      [e.target.name]: e.target.value
    })
  }

  const renderStatusActivity = (status: OfficialStatus) => {
    if (status === OfficialStatus.APPROVED) return <Badge className="bg-success">{__("challenge_activity_accept")}</Badge>
    if (status === OfficialStatus.REJECTED) return <Badge className="bg-danger">{__("challenge_activity_reject")}</Badge>
  }

  const renderContent = (attachment: AttachmentType) => {
    let actElement = null
    if (attachment.meta_type !== 'image') {
      actElement = (
        <>
          <p className="fw-semibold m-0">{attachment.meta_title}</p>
          <p className="m-0">
            {attachment.meta_type === 'date'
              ? moment(attachment.meta_value).format('DD/MM/YYYY MM:HH')
              : attachment.meta_value}
          </p>
        </>
      )
    } else if (attachment.meta_type === 'image') {
      const metaDataDefault = {
        media_url: '',
        media_type: 'video',
        media_thumbnail: ''
      }
      const metaData: MediaMetaData = helps.isJson(attachment?.meta_value) ? JSON.parse(attachment?.meta_value) : metaDataDefault
      const isImage = metaData.media_type === 'image'

      actElement = (
        <>
          <p className="m-0 fw-semibold">{attachment.meta_title}</p>
          <MediaView type={metaData.media_type} mediaUrl={metaData.media_url}>
            <Image
              src={isImage ? metaData.media_url : metaData.media_thumbnail}
              className="object-fit-cover object-position-center border rounded-3"
              alt={attachment.meta_title}
              width={200}
              height={120}
            />
          </MediaView>
        </>
      )
    }
    return <div className="mb-3">{actElement}</div>
  }

  return (
    <>
      <ActivityItemContainer>

        <Box className="item_activity">
          <Avatar src={user?.user_avatar || user?.user_avatar_thumbnail} roundedCircle height={55} width={55} />
          <Box className="info_user">
            <Typography variant='subtitle2'>{title}</Typography>
            <Typography color='text.secondary'>{moment(time).fromNow()}</Typography>
          </Box>
          <Box m={'auto'}>
            {status === OfficialStatus.PENDING && hasPermission ? (
              <Stack flexDirection={'row'} alignItems={'center'} sx={{
                marginRight: theme => theme.spacing(2),
              }}>
                <IconButton
                  onClick={() => openModal(OfficialStatus.APPROVED)}
                >
                  <CheckIcon />
                </IconButton>
                <IconButton
                  onClick={() => openModal(OfficialStatus.REJECTED)}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            ) : (
              <Typography className='point_value' color={'primary.main'} sx={{
                padding: theme => theme.spacing(0.25, 1),
                borderRadius: theme => theme.spacing(1.5),
                background: '#F3F4F5',
                margin: 'auto'
              }} >+{point_value}</Typography>
            )}
          </Box>

        </Box>
        {/* <div>{renderStatusActivity(status)}</div> */}

        {attachments.map(attach => {
          return <div key={attach._id}>{renderContent(attach)}</div>
        })}

        <Modal show={showConfirmModal} onHide={closeModal}>
          <Form noValidate validated={validated} onSubmit={confirmActivityHandler}>
            <Modal.Header>
              <Modal.Title>
                {detail.official_status === OfficialStatus.APPROVED
                  ? `${__("challenge_activity_accept_title")}`
                  : `${__("challenge_activity_reject_title")}`}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {detail.official_status === OfficialStatus.APPROVED && (
                <FormGroup className="mb-2" controlId="point_value">
                  <FloatingLabel label={__("challenge_rank_point")}>
                    <FormControl
                      type="text"
                      placeholder={__("challenge_rank_point")}
                      name="point_value"
                      required
                      onChange={changeDetailHandler}
                      value={helps.parseNumeric(detail.point_value)}
                      max={999999999999}
                      maxLength={12}
                    />
                  </FloatingLabel>
                  <FormControl.Feedback type="invalid" tooltip>
                    {__("challenge_activity_not_empty_point")}
                  </FormControl.Feedback>
                </FormGroup>
              )}
              <FormGroup controlId="admin_note">
                <InputGroup hasValidation>
                  <FloatingLabel label={__("challenge_activity_note")}>
                    <FormControl
                      type="text"
                      placeholder={__("challenge_activity_note")}
                      onChange={changeDetailHandler}
                      name="admin_note"
                      value={detail.admin_note}
                    />
                  </FloatingLabel>
                </InputGroup>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                {__("btn_close")}
              </Button>
              <Button type="submit">{__("btn_save")}</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </ActivityItemContainer>
    </>
  )
}

export default ActivityItem

const ActivityItemContainer = styled(ListGroupItem)(({ theme }) => ({
  border: 'none',
  borderBottom: '1px solid #E0E2E6',

  '& .item_activity': {
    display: 'grid',
    gridTemplateColumns: '70px 1fr 62px',

    '& .info_user': {
      margin: 'auto 0'
    },

    '& .point_value': {
      textAlign: 'end'
    }
  }
}))