import { useState } from 'react'

export enum SelectFileErrorType {
  FILE_SIZE = 'FILE_SIZE',
  FILE_TYPE = 'FILE_TYPE'
}

export default function useDragAndDrop() {
  const [dragOver, setDragOver] = useState<boolean>(false)
  const [fileDropError, setFileDropError] = useState<string | null>(null)

  const onDragOver = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setDragOver(true)
  }

  const onDragLeave = () => setDragOver(false)

  return {
    dragOver,
    setDragOver,
    onDragOver,
    onDragLeave,
    fileDropError,
    setFileDropError
  }
}
