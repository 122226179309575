import '../media/gift.list.item.scss'
import React, {useCallback} from "react";

export default function GiftListSkeleton() {

  const renderItem = useCallback((_,index:number) => {
    return (
      <div key={index.toString()} className="giftlistitem my-2 col-sm-6 col-lg-4">
        <div className="giftlistitem_container">

          <div className="giftlistitem_container_img clickable">
            <div className="giftlistitem_img skeleton"/>
          </div>


          <div className="giftlistitem_bottom d-flex flex-column justify-content-between mx-4">
            <div className="d-flex flex-column align-items-center clickable">
              <div className="giftlistitem_title skeleton skeleton-text" style={{height: 22}}/>
              <div className="giftlistitem_price skeleton skeleton-text mt-1" style={{height: 22}}/>
              <div className="giftlistitem_received skeleton skeleton-text mt-2" style={{height: 16}}/>
            </div>


            <div
              className="btn d-flex align-items-center justify-content-center giftlistitem_container_status giftlistitem_btn btn-primary skeleton"/>
          </div>
        </div>
      </div>
    )
  }, [])

  return (
    <div className="row my-2">
      {[1, 2, 3, 4, 5, 6].map(renderItem)}
    </div>
  )
}
