import axios from 'axios'
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit'
import { TypedCourse, TypedModule, TypedParamsCourse } from './courses.interface'
import { createEntitySlice, IQueryParams, serializeAxiosError } from 'config/reducer.utils'
import helpers from 'helpers'
import { TypedImage } from 'entities/calendar/calendar.interface'

export const initialState = {
  entity: null as TypedCourse,
  childrenModule: [] as TypedModule[],
  entities: null as TypedCourse[],
  errorMessage: null as unknown as string, // Errors returned from server side
  totalItems: 0 as number,
  loading: false,
  updating: false,
  updateSuccess: false,
  creating: false,
  createdSuccess: false,
  dataProduct: {
    listDataProduct: [],
    totalProduct: 0
  },
  listLevel: []
}

const apiUrl = 'course'

export const getEntity = createAsyncThunk(
  'courses/fetch_entity',
  async ({ _id, auth_id }: { _id: string; auth_id: string }) => {
    return await axios.get<any>(`${apiUrl}/detail/${_id}?auth_id=${auth_id}`)
  },
  { serializeError: serializeAxiosError }
)

export const createCourse = createAsyncThunk(
  'courses/create_course',
  async (data: {
    title?: string
    description?: string
    avatar?: string
    public_status?: string
    trash_status?: string
    level_value?: string
    coin_value?: string
  }) => {
    return await axios.post<TypedCourse>(`${apiUrl}/create`, data)
  },
  { serializeError: serializeAxiosError }
)

export const createModule = createAsyncThunk(
  'courses/create_module',
  async (data: { course_id: string; title: string; parent_id?: string; media_id?: string, media_files?: string[] }) => {
    return await axios.post<TypedModule>(`${apiUrl}/create-module`, data)
  },
  { serializeError: serializeAxiosError }
)

export const updateCourse = createAsyncThunk(
  'courses/update_course',
  async (data: {
    _id?: string
    title?: string
    description?: string
    avatar?: string
    public_status?: string
    trash_status?: string
    level_value?: string
    coin_value?: string
  }, { rejectWithValue }) => {
    try {
      return await axios.patch<TypedCourse>(`${apiUrl}/update`, data)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getLevels = createAsyncThunk(
  'courses/get_levels',
  async (object: any) => {
    const EndURL = helpers.buildEndUrl(object)
    const requestUrl = `channel/list-level${EndURL}`
    return axios.get<any>(requestUrl)
  },
  { serializeError: serializeAxiosError }
)

export const deleteModule = createAsyncThunk(
  'courses/delete_module',
  async (__id: string) => {
    return await axios.delete<TypedModule>(`${apiUrl}/delete-module/${__id}`)
  },
  { serializeError: serializeAxiosError }
)

export const deleteCourse = createAsyncThunk(
  'courses/delete_course',
  async (__id: string) => {
    return await axios.delete<TypedCourse>(`${apiUrl}/delete/${__id}`)
  },
  { serializeError: serializeAxiosError }
)

export const getEntities = createAsyncThunk(
  'courses/fetch_entity_list',
  async (object: TypedParamsCourse) => {
    const EndURL = helpers.buildEndUrl(object)
    const requestUrl = `${apiUrl}/list${EndURL}`
    return axios.get<any>(requestUrl)
  }
)

export const postViewModule = createAsyncThunk('courses/post_view_module', async (object: any) => {
  return axios.post<any>(`${apiUrl}/view`, object)
})

export const getChildrenModule = createAsyncThunk(
  'courses/get_children_module',
  async (object: any) => {
    const EndURL = helpers.buildEndUrl(object)
    const requestUrl = `${apiUrl}/list-module${EndURL}`
    return axios.get<any>(requestUrl)
  }
)

export const updateEntity = createAsyncThunk(
  'courses/update_entity',
  async (entity: TypedCourse, thunkAPI) => {
    let mainID = String(entity._id)
    delete entity._id
    const result = await axios.put<TypedCourse>(`${apiUrl}/${mainID}`, helpers.cleanEntity(entity))
    //   thunkAPI.dispatch(getEntities({}));
    return result
  },
  { serializeError: serializeAxiosError }
)

export const updateModule = createAsyncThunk(
  'courses/update_module',
  async (data: { _id: string; title: string; media_id?: string; media_files?: string[] }) => {
    return await axios.patch<TypedModule>(`${apiUrl}/update-module`, data)
  },
  { serializeError: serializeAxiosError }
)

export const deleteEntity = createAsyncThunk(
  'courses/delete_entity',
  async (_id: string) => {
    return await axios.delete<any>(`${apiUrl}/${_id}`)
  },
  { serializeError: serializeAxiosError }
)

// test checkauthentication
export const createEntity = createAsyncThunk(
  'courses/create_entity',
  async (entity: TypedCourse) => {
    return await axios.post<TypedCourse>(`course/create`, helpers.cleanEntity(entity))
  },
  { serializeError: serializeAxiosError }
)

export const getTransactionCourse = createAsyncThunk(
  'course/get_data_transaction',
  async (params: any, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<any>(`transaction/admin-list`, {
        params: params
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getDataUserIncome = createAsyncThunk(
  'course/get_data_user_income',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      return await axios.get<any>(`transaction/user-income`, {
        params: params
      })
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListCourse = createAsyncThunk(
  'course/get_product',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<any>(`course/list`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const updateStatusJoinCourse = createAsyncThunk(
  'course/update_status_join_course',
  async (body: { course_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>(`course/join`, helpers.cleanEntity(body))
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListCourseJoined = createAsyncThunk(
  'course/get_list_course_joined',
  async (params: IQueryParams, { rejectWithValue }) => {
    try {
      helpers.clearValueEmptyInObject(params)
      const response = await axios.get<any>(`course/list-join`, { params: params })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const getListUserJoin = (params: IQueryParams) => {
  try {
    const courseId = params?.course_id
    delete params.course_id
    helpers.clearValueEmptyInObject(params)
    return axios.get<never>(`course/list-members/${courseId}`, {params: params})
  } catch (error) {
    return error
  }
}



export const getDefaultImage = createAsyncThunk(
  'event/get_default_image',
  async (_, { rejectWithValue }) => {
    try {
      return await axios.get<TypedImage>(`config/default-avatar/course`)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
  { serializeError: serializeAxiosError }
)

export const COURSES_REDUCER = createEntitySlice({
  name: 'courses',
  initialState: initialState as any,
  reducers: {
    clearError: state => {
      state.errorMessage = null
      state.loading = false
      state.updateSuccess = false
      state.creating = false
      state.createdSuccess = false
    },
    reset: state => {
      return { ...state, ...initialState }
    },
    clearEntity: state => {
      state.entity = null
      state.childrenModule = []
    }
  },

  extraReducers(builder) {
    builder

      .addCase(createEntity.fulfilled, (state, action) => {
        state.creating = false
        state.createdSuccess = true
        state.entity = action.payload.data
      })
      .addCase(createEntity.pending, (state, action) => {
        state.creating = true
        state.createdSuccess = false
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.creating = false
        state.errorMessage = action.payload
      })

      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
      .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false
        state.updateSuccess = true
        state.entity = action.payload.data
      })
      .addCase(updateEntity.pending, (state, action) => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false
        state.errorMessage = action.payload
      })

      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.updating = false
        state.updateSuccess = true
        state.entity = action.payload.data
      })
      .addCase(deleteEntity.pending, (state, action) => {
        state.updating = true
        state.updateSuccess = false
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.updating = false
        state.errorMessage = action.payload
      })
      .addCase(getEntities.fulfilled, (state, action) => {
        state.loading = false
          ; (state.entities = action.payload.data),
            (state.totalItems = parseInt(action.payload.headers['x-total-count'] || 1000, 10))
      })
      .addCase(getLevels.fulfilled, (state, action) => {
        state.listLevel = action.payload.data
      })
      .addCase(getEntities.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getEntities.rejected, (state, action) => {
        state.loading = false
        state.entities = null
        state.errorMessage = action.payload
      })
      .addCase(getChildrenModule.fulfilled, (state, action) => {
        state.childrenModule = action.payload.data
      })
      .addCase(getChildrenModule.rejected, (state, action) => {
        state.childrenModule = null
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.entity = null
      })
      .addCase(deleteModule.fulfilled, (state, action) => {
        state.childrenModule = state.childrenModule.filter(i => i._id !== action.payload.data?._id)
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        state.childrenModule = state.childrenModule.map(i =>
          i._id === action.payload.data?._id ? action.payload.data : i
        )
      })
      .addCase(getListCourse.fulfilled, (state, action) => {
        state.dataProduct = {
          listDataProduct: action.payload?.data,
          totalProduct: parseInt(action.payload.headers['x-total-count'] || 1000, 10)
        }
      })
      .addCase(postViewModule.fulfilled, (state, action) => {
        if (action?.payload?.data?.module_id) {
          return {
            ...state,
            childrenModule: state?.childrenModule?.length && state?.childrenModule?.map(i =>
              i?._id === action?.payload?.data?.module_id
                ? {
                  ...i,
                  is_view: true
                }
                : i
            )
          }
        }
        return state
      })
      .addMatcher(isPending(getEntity, createCourse, updateCourse), (state, action) => {
        state.loading = true
        state.entity = null
      })
      .addMatcher(isRejected(getEntity, createCourse, updateCourse), (state, action) => {
        state.loading = false
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(getEntity, createCourse, updateCourse), (state, action) => {
        state.loading = false
        state.entity = action.payload.data
      })
      .addMatcher(isFulfilled(createModule), (state, action) => {
        state.childrenModule = [...(state.childrenModule || []), action.payload.data]
      })
  }
})
export const { clearError, clearEntity, reset } = COURSES_REDUCER.actions
// Reducer
export default COURSES_REDUCER.reducer
