//Library
import {AxiosError} from 'axios'
import {memo, useCallback, useEffect, useState} from 'react'
import { Form} from 'react-bootstrap'

//Components
import TextField from 'components/TextField'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {createCategory, updateCategory} from '../store/channelSetting.store.reduce.ts'

//Helpers
import helpers from 'helpers'

//Interface
import {TypeCategory} from '../interface/index.js'
import {EnumTypeToast, useToast} from "../../../hooks/useToast";
import __ from "languages/index";
import { Button, Typography } from '@mui/material'

const AddCategory = ({
  isShowAdd,
  setIsShowAdd,
  isRefresh,
  setIsRefresh,
  dataCategorySelect
}: {
  isShowAdd: string
  setIsShowAdd: Function
  isRefresh: boolean
  setIsRefresh: Function
  dataCategorySelect: TypeCategory
}) => {
  const dispatch = useAppDispatch()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const [formData, setFormData] = useState<{ category_title: string; category_content: string }>({
    category_title: '',
    category_content: ''
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()

  const handleChangeValue = useCallback(
    (value: string, _id: string) => {
      setFormData({ ...formData, [_id]: value })
    },
    [formData]
  )

  const handleValidate = useCallback(
    (data: { category_title: string; category_content: string }) => {
      let error: boolean = false
      Object.keys(data).forEach((item: string) => {
        if (helpers.isEmpty(data[item])) {
          error = true
        }
      })
      return error
    },
    []
  )

  const handleSubmit = useCallback(() => {
    if (handleValidate(formData)) return
    setIsLoading(true)
    isShowAdd === 'ADD'
      ? dispatch(
          createCategory({
            ...formData,
            channel_id: channel_data?._id,
            category_language: 'en',
            public_status: '1'
          })
        )
          .unwrap()
          .then(() => {
            setIsLoading(false)
            setIsRefresh(!isRefresh)
            setIsShowAdd('')
            toast.show({
              content: `${__("channel_setting_add_category_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`getDataCategory_${error}`)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_create_level_failed")}`,
              type: EnumTypeToast.Error
            })
          })

      : dispatch(
          updateCategory({
            ...formData,
            _id: dataCategorySelect?._id,
            channel_id: channel_data?._id
          })
        )
          .unwrap()
          .then(() => {
            setIsLoading(false)
            setIsRefresh(!isRefresh)
            setIsShowAdd('')
            toast.show({
              content: `${__("channel_setting_update_category_success")}`,
              type: EnumTypeToast.Success
            })
          })
          .catch((error: AxiosError) => {
            console.log(`getDataCategory_${error}`)
            setIsLoading(false)
            toast.show({
              content: `${__("channel_setting_update_category_error")}`,
              type: EnumTypeToast.Error
            })
          })
  }, [formData, channel_data, isShowAdd, isRefresh, dataCategorySelect])

  useEffect(() => {
    if (isShowAdd === 'ADD') return
    setFormData({
      category_title: dataCategorySelect?.category_title,
      category_content: dataCategorySelect?.category_content
    })
  }, [isShowAdd, dataCategorySelect])

  return (
    <div id="addCategory" className="addCategory_container">
      <div className="channelSettingCategory_header">
        <h4 className="channelSettingCategory_header_title">
          {isShowAdd === 'ADD' ? `${__("channel_setting_add_category")}` : `${__("channel_setting_edit_category")}`}
        </h4>
      </div>
      <Form onSubmit={handleSubmit} className="addCategory_form_wrapper">
        <div className="addCategory_form">
          <div>
            <label className="fw-bold mb-1">{__("channel_setting_category_name")}</label>
            <TextField
              id="category_title"
              autoFocus
              value={formData?.category_title}
              onChange={handleChangeValue}
              placeholder={__("channel_setting_category_name")}
            />
          </div>
          <div>
            <label className="fw-bold mb-1">{__("channel_setting_category_description")}</label>
            <TextField
              id="category_content"
              value={formData?.category_content}
              onChange={handleChangeValue}
              placeholder={__("channel_setting_category_description")}
            />
          </div>
        </div>
        <div className="addCategory_form_button">
          <Button onClick={() => setIsShowAdd('')} variant='text'>
            <Typography textTransform={'none'} color={'error.main'}>
              {__("btn_cancel")}
            </Typography>
          </Button>
          <Button sx={{textTransform: 'none'}}
            disabled={isLoading || handleValidate(formData)}
            onClick={handleSubmit}
          >
            {isLoading ? `${__("btn_processing")}` : `${__("btn_save")}`}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default memo(AddCategory)
