
export function startWatchLivestream(url: string, videoElement: HTMLVideoElement, setStatusPeer: (status: RTCPeerConnectionState) => void){
  const stream = new MediaStream();

  /**
   * Create a new WebRTC connection, using public STUN servers with ICE,
   * allowing the client to disover its own IP address.
   * https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Protocols#ice
   */
  const peerConnection = new RTCPeerConnection({
    iceServers: [
      {
        urls: ["stun:stun.whiteg.app:3478", "turn:stun.whiteg.app:3478"],
        username: "whiteg",
        credential: "whiteg123456"
      }
    ],
    bundlePolicy: "max-bundle",
  });
  /** https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addTransceiver */
  peerConnection.addTransceiver("video", {
    direction: "recvonly",
  });
  peerConnection.addTransceiver("audio", {
    direction: "recvonly",
  });
  /**
   * When new tracks are received in the connection, store local references,
   * so that they can be added to a MediaStream, and to the <video> element.
   *
   * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/track_event
   */
  peerConnection.ontrack = (event: RTCTrackEvent) => {
    const track = event.track;
    const currentTracks = stream.getTracks();
    const streamAlreadyHasVideoTrack = currentTracks.some(
      (track) => track.kind === "video"
    );
    const streamAlreadyHasAudioTrack = currentTracks.some(
      (track) => track.kind === "audio"
    );
    switch (track.kind) {
      case "video":
        if (streamAlreadyHasVideoTrack) {
          break;
        }
        stream.addTrack(track);
        break;
      case "audio":
        if (streamAlreadyHasAudioTrack) {
          break;
        }
        stream.addTrack(track);
        break;
      default:
        console.log("got unknown track " + track);
    }
  };

  peerConnection.addEventListener("connectionstatechange", (ev) => {
    console.log(peerConnection.connectionState,"peerConnection.connectionState")
    setStatusPeer(peerConnection.connectionState)
    if (peerConnection.connectionState !== "connected") {
      return;
    }
    if (!videoElement.srcObject) {
      videoElement.srcObject = stream;
    }
  });

  peerConnection.addEventListener("negotiationneeded", (ev) => {
    console.log("negotiationneeded")
    negotiateConnectionWithClientOffer(peerConnection, url);
  });
}


/**
 * Performs the actual SDP exchange.
 *
 * 1. Constructs the client's SDP offer
 * 2. Sends the SDP offer to the server,
 * 3. Awaits the server's offer.
 *
 * SDP describes what kind of media we can send and how the server and client communicate.
 *
 * https://developer.mozilla.org/en-US/docs/Glossary/SDP
 * https://www.ietf.org/archive/id/draft-ietf-wish-whip-01.html#name-protocol-operation
 */
export async function negotiateConnectionWithClientOffer(
  peerConnection: RTCPeerConnection,
  endpoint: string
) {
  /** https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/createOffer */
  const offer = await peerConnection.createOffer();
  /** https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/setLocalDescription */
  await peerConnection.setLocalDescription(offer);
  /** Wait for ICE gathering to complete */
  let ofr = await waitToCompleteICEGathering(peerConnection);
  if (!ofr) {
    throw Error("failed to gather ICE candidates for offer");
  }
  /**
   * As long as the connection is open, attempt to...
   */
  while (peerConnection.connectionState !== "closed") {
    /**
     * This response contains the server's SDP offer.
     * This specifies how the client should communicate,
     * and what kind of media client and server have negotiated to exchange.
     */
    let response = await postSDPOffer(endpoint, ofr.sdp);
    if (response.status === 201) {
      let answerSDP = await response.text();
      await peerConnection.setRemoteDescription(
        new RTCSessionDescription({ type: "answer", sdp: answerSDP })
      );
      return response.headers.get("Location");
    } else if (response.status === 405) {
      console.error("Update the URL passed into the WHIP or WHEP client");
    } else {
      const errorMessage = await response.text();
      console.error(errorMessage);
    }
    /** Limit reconnection attempts to at-most once every 5 seconds */
    await new Promise((r) => setTimeout(r, 5000));
  }
}
export async function postSDPOffer(endpoint: string, data) {
  return await fetch(endpoint, {
    method: "POST",
    mode: "cors",
    headers: {
      "content-type": "application/sdp",
    },
    body: data,
  });
}

/**
 * Receives an RTCPeerConnection and waits until
 * the connection is initialized or a timeout passes.
 *
 * https://www.ietf.org/archive/id/draft-ietf-wish-whip-01.html#section-4.1
 * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/iceGatheringState
 * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/icegatheringstatechange_event
 */
export async function waitToCompleteICEGathering(peerConnection): Promise<any> {
  return new Promise((resolve) => {
    /** Wait at most 1 second for ICE gathering. */
    setTimeout(function () {
      resolve(peerConnection.localDescription);
    }, 1000);
    peerConnection.onicegatheringstatechange = (ev) =>
      peerConnection.iceGatheringState === "complete" &&
      resolve(peerConnection.localDescription);
  });
}
