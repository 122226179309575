import { alpha, createTheme } from '@mui/material'

const palette = createTheme().palette

export const colorGenerator = (color: string = '#fff', name: string) => {
  return {
    [name]: palette.augmentColor({
      color: {
        main: color,
        background: alpha(color, 0.05),
        backgroundHover: alpha(color, 0.15)
      },
      name
    })
  }
}
