import axios, { AxiosResponse } from 'axios'
import { TypeDataLevel } from 'entities/channelSetting/interface'
import { GetCategoriesResponse } from 'entities/homeNew/store/homeApi.type'
import TypedChannel from 'interfaces/channel.interface'
import { CreateConfigLevelPayload } from './service.type'
type Response<T, D> = Promise<AxiosResponse<T, D>>

export const getVietnamBanks = () => {
  return axios.get('https://api.vietqr.io/v2/banks')
}

export const detailChannel = (): Response<TypedChannel, void> => {
  return axios.get('/channel/me')
}

export const inviteUserByEmail = (payload: { email: string; channelId: string }) => {
  const url = '/channel/invite-email'
  return axios.post<{ email: string; channelId: string }, string>(url, {
    channel_id: payload.channelId,
    email: payload.email
  })
}

export const updateChannel = (
  payload: Partial<TypedChannel> & Pick<TypedChannel, '_id'>
): Response<TypedChannel, Partial<TypedChannel> & Pick<TypedChannel, '_id'>> => {
  const url = '/channel/update'
  const updatedPayload = {
    ...payload,
    avatar: payload.avatar._id,
    point_data: JSON.stringify(payload.point_data),
    mentor_commission: payload.mentor_commission.toString(),
    user_commission: payload.user_commission.toString(),
    bank_qr_code: payload.bank_qr_code._id
  }
  return axios.patch(url, updatedPayload)
}

export const getPostCategories = () => {
  const url = 'request/list-category'
  return axios.get<GetCategoriesResponse>(url)
}

/** Level */
export const getConfigsLevel = (params: Record<string, string>) => {
  const url = '/channel/list-level'
  return axios.get<TypeDataLevel[]>(url, {
    params: params
  })
}

export const updateConfigLevel = (payload: Partial<TypeDataLevel> & Pick<TypeDataLevel, '_id'>) => {
  const url = '/channel/update-level'
  return axios.patch<Partial<TypeDataLevel> & Pick<TypeDataLevel, '_id'>, TypeDataLevel>(
    url,
    payload
  )
}

export const createConfigLevel = (payload: CreateConfigLevelPayload) => {
  const url = '/channel/create-level'
  return axios.post<CreateConfigLevelPayload, TypeDataLevel>(url, payload)
}

// Config point
export const updatePoint = () => {
  const url = ''
  return axios.patch(url)
}

export default { detailChannel, updateChannel, inviteUserByEmail }
