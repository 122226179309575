import React from 'react'
import {
  faBackward,
  faBackwardStep,
  faForward,
  faForwardStep,
  faPause,
  faPlay
} from '@fortawesome/free-solid-svg-icons'
import IconButton from 'components/IconButton'
import { useSelector } from 'react-redux'
import { getStatePlayingPodcast, updatePlayingPodcastInfo } from 'podcast/store/podcastSlice'
import { Stack } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

type Props = {
  audioRef: React.MutableRefObject<HTMLAudioElement>
  fastForwardTime?: number
  rewindTime?: number
}

const PodcastController = ({ audioRef, fastForwardTime = 10, rewindTime = 10 }: Props) => {
  const audio = audioRef.current
  const { isPlaying } = useSelector(getStatePlayingPodcast)
  const dispatch = useDispatch()

  const handlePlayPause = () => {
    if (isPlaying) {
      audio.pause()
      dispatch(
        updatePlayingPodcastInfo({
          isPlaying: false
        })
      )
    } else {
      audio.play()
      dispatch(
        updatePlayingPodcastInfo({
          isPlaying: true
        })
      )
    }
  }

  const handleTimeControl = (time: number) => {
    audioRef.current.currentTime += time
  }

  const rewind = () => {
    handleTimeControl(rewindTime)
  }
  const fastForward = () => {
    handleTimeControl(fastForwardTime)
  }

  return (
    <Stack direction="horizontal">
      <IconButton variant="none" icon={faBackward} onClick={rewind} />
      <IconButton variant="none" icon={isPlaying ? faPause : faPlay} onClick={handlePlayPause} />
      <IconButton variant="none" icon={faForward} onClick={fastForward} />
    </Stack>
  )
}

export default PodcastController
