import React, { useCallback, useState } from 'react'
import { Box, Button, IconButton, Paper, Stack, styled, SxProps, Typography } from '@mui/material'
import { CheckSecondIcon, EventIcon, GroupIcon, SettingIcon } from 'icons'
import { CurrentTheme } from '../../../styles/themes'
import { useSelector } from 'react-redux'
import { IRootState, useAppSelector } from 'config/store'
import helpers from 'helpers/index'
import __ from 'languages/index'
import {EnumTypeToast, useToast} from 'hooks/useToast'
import Capacity from 'components/Capacity'
import { Link } from 'react-router-dom'

type Props = {
  members: number
}
const ChannelOverview = ({ members }: Props) => {
  const userName = useSelector((state: IRootState) => state.user?.user_data?.user_login)
  const is_user_logged_in = useAppSelector(state => state.user.is_user_logged_in)
  const toast = useToast()
  const channel_data = useAppSelector(state => state.user.channel_data)
  const [isCopySuccess, setIsCopySuccess] = useState<boolean>(false)
  const inviteLink = helpers.getRootDomain() + '/#user_referrer=' + userName

  const handleCopyLink = useCallback(() => {
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        setIsCopySuccess(true)
      })
      .catch(() => {
        setIsCopySuccess(false)
        toast.show({
          content: `${__('home_copy_link_failed')}`,
          type: EnumTypeToast.Error
        })
      })
  }, [inviteLink])

  return (
    <ChannelOverviewContainer>
      <Stack gap={2}>
        <Box>
          <Typography component={'h6'} fontWeight={600}>
            {__(channel_data?.name || "")}
          </Typography>
          <Typography>{__(channel_data?.description || "")}</Typography>
        </Box>
        <Stack direction={'row'} gap={1}>
          <Stack
            direction={'row'}
            minHeight={theme => theme.spacing(5.625)}
            sx={channelMemberStyled}
            gap={2}
          >
            <GroupIcon />
            <Typography>{(members||0) + __('home_member')}</Typography>
          </Stack>
          <Capacity current_user_can={'boss'}>
            <Link to="/channel-setting">
              <IconButton sx={settingButtonStyled}>
                <SettingIcon color={'inherit'} />
              </IconButton>
            </Link>
          </Capacity>
        </Stack>
        {is_user_logged_in && (
          <Capacity
            current_user_can={'boss'}
            onFail={() => (
              <Stack
                alignItems={'flex-start'}
                justifyContent={'space-between'}
                sx={inviteUserStyled}
                gap={1}
              >
                <Stack
                  direction={'row'}
                  gap={1}
                  width={'100%'}
                  overflow={'hidden'}
                  textOverflow={'ellipsis'}
                  whiteSpace={'nowrap'}
                >
                  <EventIcon />
                  <Stack overflow={'hidden'}>
                    <Typography noWrap>{__('channel_invite_others')}</Typography>
                    <Typography noWrap className={'textInviteLink'}>
                      {inviteLink}
                    </Typography>
                  </Stack>
                </Stack>
                <Button
                  size={'small'}
                  variant={'outlined'}
                  onClick={handleCopyLink}
                  sx={{
                    width: 'max-content',
                    borderColor: theme => (isCopySuccess ? theme.palette.success.main : ''),
                    color: theme => (isCopySuccess ? theme.palette.success.main : '')
                  }}
                  endIcon={isCopySuccess && <CheckSecondIcon color="success" />}
                >
                  <Typography component={'span'} textTransform={'none'}>
                    {__('channel_setting_invite_btn_copy')}
                  </Typography>
                </Button>
              </Stack>
            )}
          >
            <></>
          </Capacity>
        )}
      </Stack>
    </ChannelOverviewContainer>
  )
}

export default ChannelOverview

const ChannelOverviewContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& .channel_member': {
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.divider}`
  }
}))

const channelMemberStyled: SxProps<CurrentTheme> = {
  borderRadius: theme => theme.spacing(1.5),
  border: theme => `1px solid ${theme.palette.divider}`,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
}

const settingButtonStyled: SxProps<CurrentTheme> = {
  borderRadius: theme => theme.spacing(1.5),
  border: theme => `1px solid ${theme.palette.divider}`,
  color: theme => theme.palette.text.primary
}

const inviteUserStyled: SxProps<CurrentTheme> = {
  padding: theme => theme.spacing(2),
  backgroundColor: theme => theme.palette.primary.background,
  borderRadius: theme => theme.spacing(1.5),
  display: 'grid',
  gridTemplateColumns: '1fr auto'
}
