import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'config/store'
import DateTime from 'date-and-time'
import vi from 'date-and-time/locale/vi'
// event_default.jpg
import EventDefault from 'entities/event/media/event_default.jpg'
import helpers from 'helpers/index'
import { Col, Collapse, Container, Image, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { Link, useNavigate } from 'react-router-dom'

import { TypedEvent } from '../calendar.interface'
import { deleteEvent } from '../calendar.store.reducer'
import { IconCalendar, IconClock, IconLink, IconPerson } from '../media/svg'
import __ from 'languages/index'
import { Box, Button, Typography } from '@mui/material'

DateTime.locale(vi);
interface Props {
  detail: TypedEvent;
  deleteSuccess: (data) => void;
}

const ModalDetail = (props: Props, ref) => {
  const { detail, deleteSuccess } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);

  const [ isMentor, setIsMentor] = useState(false);
  const permission_data = useAppSelector(state => state.user.permission_data);
      useEffect( () => {
        if ( permission_data )
        setIsMentor( permission_data.indexOf('admin') > -1 );
      }, [permission_data]);


  useImperativeHandle(ref, () => ({
    show: showModal,
    hide: hideModal,
  }));

  // State

  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [openText, setOpenText] = useState(false);

  // ===========================================

  // Function

  const showModal = useCallback(() => {
    setShowDelete(false);
    setShow(true);
  }, []);

  const hideModal = useCallback(() => {
    setShow(false);
    setShowDelete(false);
  }, []);

  const _onDelete = useCallback(
    async (result: boolean) => {
      setShowDelete(false);
      if (result !== true) return;
      setDeleting(true);
      try {
        const res: any = await dispatch(deleteEvent({ _id: detail?._id }));
        if (res?.payload?.data) {
          deleteSuccess({ type: "success", _id: detail?._id });
        } else {
          deleteSuccess({ type: "dander" });
        }
        setTimeout(() => {
          hideModal();
        }, 100);
      } catch (error) {
        deleteSuccess({ type: "dander" });
      }
      setDeleting(false);
    },
    [detail]
  );

  const _onClickUpdateEvent = useCallback(() => {
    navigate(`/calendar/add/${detail?._id}`);
  }, [detail]);

  const renderDuration = useMemo(() => {
    if (detail?.duration < 1) {
      return `${__("calendar_component_30minute")}`;
    }
    if (detail?.duration % 1 == 0) {
      return detail?.duration + `${__("calendar_component_hour")}`;
    }
    if (detail?.duration % 1 == 0.5) {
      return Math.floor(detail?.duration) + `${__("calendar_component_hour_30minute")}`;
    }
    return `${__("calendar_component_1hour")}`;
  }, [detail?.duration]);

  const DATA = [
    {
      icon: <IconCalendar size={18} />,
      key: "calendar",
      title: `${__("calendar_detail_time")}`,
      description: (detail && DateTime.format(new Date(detail?.open_date), "dddd, ngày DD-MM-YYYY lúc HH:mm")) || "",
    },
    {
      icon: <IconClock size={18} />,
      key: "clock",
      title:`${__("calendar_detail_time_takes_place")}`,
      description: renderDuration,
    },
    {
      icon: <IconPerson size={18} />,
      key: "person",
      title: `${__("calendar_detail_event_of_speakers")}`,
      description: (detail && detail?.user_id?.display_name) || "",
    },
    {
      icon: <IconLink size={18} />,
      key: "link",
      title: `${__("calendar_detail_link_join")}`,
      description: (Array.isArray(detail?.address) && detail?.address[0]?.value) || "",
    },
  ];

  // ===========================================

  const renderItem = useCallback((icon, title, description, key) => {
    return (
      <div key={key} className="mb-3">
        <Row>
          <Col xs="12" md={5}>
            <Row>
              <div className="d-flex flex-row align-items-center">
                {icon}
                <div className="mx-2 fw-bold" style={{ fontWeight: "bold" }}>
                  {title}
                </div>
              </div>
            </Row>
          </Col>
          <Col xs="12" md="7">
            {key == "link" ? (
              <a href={description} target={"_blank"}>
                {description.slice(0, 30)}
              </a>
            ) : (
              <div>{description}</div>
            )}
          </Col>
        </Row>
        <hr />
      </div>
    );
  }, []);

  return (
    <Modal show={show} onHide={hideModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{showDelete ? `${__("calendar_event_delete")}` : `${__("calendar_event_detail_title")}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs="12" md="4">
              {(detail && detail?.public_album && (
                <Image
                  className="my-2 align-self-center mb-2"
                  src={(detail && detail?.public_album?.[0]?.media_url) || EventDefault}
                  style={{ height: 230, width: 230, objectFit: "cover" }}
                  thumbnail
                />
              )) || <div style={{ height: 230, width: 230, backgroundColor: "#F5F5F5" }} />}
            </Col>
            <Col xs="12" md="8">
              <p className="mb-2 fw-bold fs-4">{detail?.title}</p>
              {DATA.map((item) => {
                return renderItem(item?.icon, item?.title, item?.description, item?.key);
              })}

              <p className={"mb-2 clickable"}>
                {!openText && helpers.trimContentString(detail?.description, 100)}

                {detail?.description.length > 100 && (
                  <Collapse in={openText}>
                    <div id="collapse_text">{detail?.description}</div>
                  </Collapse>
                )}
                {detail?.description.length > 100 && (
                  <Button sx={{p: 0,margin: theme=>theme.spacing(0,0,0.5,1)}} variant='text' className="collapsed" data-toggle="collapse" onClick={() => setOpenText(!openText)}>
                    <Typography textTransform={'none'} color='primary.main'>
                      {openText ? `${__("calendar_event_detail_record")}` : `${__("calendar_event_detail_seen_more")}`}
                    </Typography>
                  </Button>
                )}
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
         {isMentor ? (
          <Button
            disabled={deleting}
            onClick={() => {
              showDelete === false ? setShowDelete(true) : _onDelete(true);
            }}
          >
            <Typography textTransform={'none'}>
              {showDelete ? `${__("calendar_event_detail_yes")}` : `${__("calendar_event_delete")}`}{" "}
            </Typography>
          </Button>
        ) : (
          <Button variant='text' onClick={hideModal}>
            <Typography textTransform={'none'} color={'error.main'}>
              {__("calendar_event_detail_btn_close")}
            </Typography>
          </Button>
        )}
        {isMentor ? (
          <Button
            onClick={() => (showDelete ? hideModal() : _onClickUpdateEvent())}
          >
            <Typography textTransform={'none'}>
              {showDelete ? `${__("calendar_event_detail_no")}` : `${__("calendar_event_detail_edit")}`}
            </Typography>
          </Button>
        ) : (
          <Box
            component={Link}
            sx={{backgroundColor: theme=>`${theme.palette.primary.main} !important`, padding: theme=>theme.spacing(1), borderRadius: theme=>theme.spacing(1.5), textDecoration: 'none'}}
            // href={(Array.isArray(detail?.address) && detail?.address[0]?.value) || ""}
            to={`/event/detail/${detail?._id}`}
          >
            <Typography textTransform={'none'} color='text.primary'>
              {__("calendar_event_detail_modal_link_join")}
            </Typography>
          </Box>
        )} 
      </Modal.Footer>
    </Modal>
  );
};

export default React.forwardRef(ModalDetail);
