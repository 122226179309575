//Library
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'

//Image
import avatarDefaults from 'media/images/avatar_default.png'
import iconShield from '../media/icons/icon_shield.svg'

//Interface
import { TypedChannelLevel } from '../interface'
import { TypedDataMember } from 'interfaces/member.interface'

//Scss
import '../media/member.avatar.scss'
import { Box } from '@mui/material'

const AvatarMember = ({
  data,
  listLevels
}: {
  data: TypedDataMember
  listLevels: TypedChannelLevel[]
}) => {
  const percentLevelUp = useMemo(() => {
    const findIndex = listLevels.findIndex(
      (item: TypedChannelLevel) => item?.level_number === data?.level_number
    )

    const dataNumberNext = listLevels[findIndex < listLevels?.length - 1 ? findIndex + 1 : findIndex];
    return (Number(data?.point) / Number(dataNumberNext?.level_point || 0)) * 100
  }, [listLevels])

  const ProcessBarLevelUp = useCallback(
    () => (
      <svg viewBox="0 0 36 36" className="circular-chart orange">
        <path
          className="circle-bg"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle bg_theme"
          strokeDasharray={`${percentLevelUp || 0}, 100`}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    ),
    [percentLevelUp]
  )

  return (
    <Box sx={{'& .bg_theme': {stroke: theme=>`${theme.palette.primary.main} !important`}}} id="avatarMember" className="avatarMember_container">
      <div className="avatarMember_wrapper">
        <img
          src={data?.user_avatar_thumbnail || data?.user_avatar || avatarDefaults}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = avatarDefaults
          }}
          className="avatarMember_image"
          alt="avatar"
          loading="lazy"
        />
        {ProcessBarLevelUp()}
        <div className="avatarMember_level_wrapper">
          <div className="avatarMember_level_body">
            <img src={iconShield} width={30} height={30} alt="icons" loading="lazy" />
            <span className="avatarMember_level_content">
              {data?.level_number || '1'}
            </span>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default memo(AvatarMember)
