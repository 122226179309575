import {uploadFile, uploadMedia} from 'entities/courses/services/courses.media.service'
import TypedMedia, {EnumPostType} from 'interfaces/media.interface'
import {useEffect, useState} from 'react'

const useUpload = (
  files: {typeUpload: EnumPostType, fileList: File[] | FileList | null},
  {
    isMultiple = false,
    initialFiles = []
  }: { isMultiple?: boolean; acceptFile?: string; initialFiles?: TypedMedia[] }
) => {
  const [srcFiles, setSrcFiles] = useState<TypedMedia[]>(initialFiles)
  const [progress, setProgress] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const handleUploadFile = async () => {
    try {
      const formData = new FormData()
      Array.from(files?.fileList).forEach(file => {
        formData.append('file[]', file)
      })
      setLoading(true)
      const res = files?.typeUpload === EnumPostType.File? await uploadFile(formData, setProgress):await uploadMedia(formData, setProgress)
      const resFiles = res.map(item => item.callback)
      setSrcFiles(prev => (isMultiple ? [...prev, ...resFiles] : resFiles))
    } catch (err) {
      console.log('upload err', err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  const deleteFileById = (id: string) => {
    setSrcFiles(prev => prev.filter(item => item._id !== id))
  }

  useEffect(() => {
    if (!files?.fileList?.length) {
      return setSrcFiles(initialFiles)
    }
    handleUploadFile()
  }, [files])

  return {
    srcFiles,
    progress,
    loading,
    deleteFileById
  }
}

export default useUpload
