import format from '@stdlib/string-format'
import { VALIDATE } from 'entities/support/constants/label.constant'

export const formatString = (str: string, ...stringParams: Array<number | string>) => {
  return format(str, ...stringParams)
}

export const validateLabel = (value: string | number) => ({
  required: formatString(VALIDATE.required, value),
  maxLength: formatString(VALIDATE.maxLength, value),
  pattern: formatString(VALIDATE.pattern, value),
  numeric: VALIDATE.numeric
})

type ValidateLabelType = ReturnType<typeof validateLabel>

export const invalid = (key: keyof ValidateLabelType, value: string | number) => {
  return validateLabel(value)[key]
}
