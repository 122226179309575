import React from 'react'
import PodcastPlayer from 'components/PodcastPlayer'
import { useSelector } from 'react-redux'
import { getPlayingPodcast } from '../podcast/store/podcastSlice'

type Props = {
  children: React.ReactNode
}

const WithPodcastLayout = ({ children }: Props) => {
  const playingPodcast = useSelector(getPlayingPodcast)

  return (
    <div className="position-relative">
      {children}
      {playingPodcast && <PodcastPlayer playingPodcast={playingPodcast} />}
    </div>
  )
}

export default WithPodcastLayout
