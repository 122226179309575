import React from 'react'
import { Button, FormGroup, FormLabel, Stack, SxProps, TextField } from '@mui/material'
import ChannelSettingsTitle from '../components/ChannelSettingsTitle'
import __ from 'languages/index'
import { CurrentTheme } from 'styles/themes'
import { useForm } from 'react-hook-form'
import { InviteUserPayload, InviteUserSchema } from '../validate/invite-user.validate'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppSelector } from 'config/store'
import { getChannelId } from 'store/user.store.reducer'
import { inviteUserByEmail } from '../service'
import { EnumTypeToast, useToast } from 'hooks/useToast'
import { copyTClipboard } from 'helpers/copyToClipboard'

const InviteUser = () => {
  const channelId = useAppSelector(getChannelId)
  const toast = useToast()

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<InviteUserPayload>({ resolver: zodResolver(InviteUserSchema) })

  const inviteUser = async (value: InviteUserPayload) => {
    try {
      await inviteUserByEmail({
        channelId: channelId,
        email: value.email
      })
      toast.show({
        type: EnumTypeToast.Success,
        content: __('channel_setting_invite_email_success')
      })
    } catch (err) {
      toast.show({
        type: EnumTypeToast.Error,
        content: __('user_already_join_channel')
      })
    }
  }

  const handleCopyInviteLink = () => {
    //TODO: add link
    copyTClipboard('text')
      .then(() => {
        toast.show({
          type: EnumTypeToast.Success,
          content: __('channel_setting_invite_copy_link_success')
        })
      })
      .catch(() => {
        toast.show({
          type: EnumTypeToast.Error,
          content: __('channel_setting_invite_copy_link_failed')
        })
      })
  }

  return (
    <Stack gap={2}>
      <ChannelSettingsTitle>{__('invite_user')}</ChannelSettingsTitle>
      <form onSubmit={handleSubmit(inviteUser)}>
        <FormGroup>
          <FormLabel sx={{ mb: 1 }}>{__('channel_setting_invite_user_by_email')}</FormLabel>
          <Stack direction="row" gap={2}>
            <TextField
              hiddenLabel
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              placeholder={__('channel_setting_invite_user_placeholder')}
              {...register('email')}
            />
            <Button type="submit" sx={buttonStyled}>
              {__('channel_setting_invite_send_invitation')}
            </Button>
          </Stack>
        </FormGroup>
      </form>
      <FormGroup>
        <FormLabel sx={{ mb: 1 }}>{__('channel_setting_invite_user_by_link')}</FormLabel>
        <Stack direction="row" gap={2}>
          <TextField
            hiddenLabel
            value="gamifa.vn"
            InputProps={{
              size: 'small',
              disableUnderline: true,
              readOnly: true
            }}
            placeholder={__('channel_setting_invite_user_placeholder')}
          />
          <Button onClick={handleCopyInviteLink} sx={buttonStyled}>
            {__('channel_setting_invite_btn_copy')}
          </Button>
        </Stack>
      </FormGroup>
    </Stack>
  )
}

export default InviteUser

const buttonStyled: SxProps<CurrentTheme> = {
  height: 'fit-content',
  minWidth: '150px',
  textTransform: 'none'
}
