import { SvgIcon, SvgIconProps } from "@mui/material";

const MessageEmptyIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                <g clip-path="url(#clip0_2198_48487)">
                    <path d="M80.1451 145.687C117.11 145.687 147.087 115.935 147.087 79.1962C147.087 42.4571 117.11 12.7051 80.1451 12.7051C43.1805 12.7051 13.2031 42.4571 13.2031 79.1962C13.2031 115.935 43.1805 145.687 80.1451 145.687Z" fill="#EAEEF9" />
                    <path d="M131.423 70.5185V111.202C131.423 119.654 124.548 126.529 115.983 126.529H44.5332C36.081 126.529 29.2064 119.767 29.0938 111.315C29.0938 111.202 29.0938 111.202 29.0938 111.089V70.5185C29.0938 70.4058 29.0938 70.4058 29.0938 70.2931C29.0938 70.0677 29.0937 69.8424 29.2064 69.617C29.3191 69.2789 29.4318 69.0535 29.5445 68.7154L48.703 32.0889C49.3792 30.6238 50.8442 29.835 52.422 29.835H107.982C109.559 29.835 110.912 30.6238 111.701 32.0889L130.859 68.7154C130.972 68.9408 131.084 69.2789 131.197 69.617C131.423 69.8424 131.423 70.1804 131.423 70.5185Z" fill="#D5DDEA" />
                    <g filter="url(#filter0_d_2198_48487)">
                        <path d="M131.423 70.5188V115.372C131.423 121.57 126.464 126.529 120.153 126.529H40.3634C34.1651 126.529 29.0938 121.57 29.0938 115.372V70.2934C29.0938 70.068 29.0937 69.8426 29.2064 69.6172H54.9013C58.733 69.6172 61.8885 72.66 61.8885 76.6044C61.8885 78.5202 62.6774 80.3234 63.9171 81.5631C65.2695 82.9154 66.8472 83.5916 68.8758 83.5916H91.7532C95.5849 83.5916 98.7404 80.5488 98.7404 76.6044C98.7404 74.6885 99.5293 72.8854 100.769 71.6457C102.121 70.2934 103.699 69.6172 105.615 69.6172H131.197C131.423 69.8426 131.423 70.1807 131.423 70.5188Z" fill="url(#paint0_linear_2198_48487)" />
                    </g>
                    <path d="M73.5766 112.069C74.017 112.069 74.4573 112.216 74.7509 112.657C75.9253 114.273 77.6867 115.154 79.7418 115.154C81.6501 115.154 83.5584 114.273 84.5859 112.657C85.0263 111.922 85.907 111.775 86.641 112.363C87.3749 112.804 87.5217 113.685 86.9346 114.42C85.3199 116.77 82.5308 118.239 79.595 118.239C76.6592 118.239 74.017 116.77 72.2555 114.42C71.8151 113.685 71.9619 112.804 72.5491 112.363C72.9894 112.069 73.283 112.069 73.5766 112.069Z" fill="#989FB0" />
                    <path d="M145.38 47.415C147.932 47.415 150.001 45.3463 150.001 42.7944C150.001 40.2425 147.932 38.1738 145.38 38.1738C142.828 38.1738 140.76 40.2425 140.76 42.7944C140.76 45.3463 142.828 47.415 145.38 47.415Z" fill="#EAEEF9" />
                    <path d="M140.757 32.4272C142.5 32.4272 143.913 31.0145 143.913 29.2717C143.913 27.529 142.5 26.1162 140.757 26.1162C139.014 26.1162 137.602 27.529 137.602 29.2717C137.602 31.0145 139.014 32.4272 140.757 32.4272Z" fill="#EAEEF9" />
                    <path d="M12.9602 42.7944C14.7029 42.7944 16.1157 41.3817 16.1157 39.6389C16.1157 37.8962 14.7029 36.4834 12.9602 36.4834C11.2175 36.4834 9.80469 37.8962 9.80469 39.6389C9.80469 41.3817 11.2175 42.7944 12.9602 42.7944Z" fill="#EAEEF9" />
                    <path d="M5.86024 112.217C9.09676 112.217 11.7205 109.593 11.7205 106.356C11.7205 103.12 9.09676 100.496 5.86024 100.496C2.62372 100.496 0 103.12 0 106.356C0 109.593 2.62372 112.217 5.86024 112.217Z" fill="#EAEEF9" />
                    <path d="M108.457 16.2764C109.928 32.3325 110.663 36.0095 107.333 50.5504C106.25 53.7832 105.167 57.3238 102.847 59.7869C99.5979 63.6354 93.7192 65.3288 88.9234 64.0972C83.9729 62.8657 79.9507 58.5554 79.0224 53.3214C78.2489 50.0886 79.3318 46.2401 82.1165 44.2389C85.0559 42.3916 89.2328 42.8534 91.7081 45.1625C94.4927 47.4716 95.5757 51.0123 95.421 54.399C95.2662 57.7857 94.0286 61.1724 92.3269 64.0972C88.48 71.2891 86.8963 71.2627 79.0225 83.0745" stroke="#ABB5CC" stroke-width="2" stroke-miterlimit="10" stroke-dasharray="4 4" />
                    <path d="M117.082 10.2962C116.494 12.4417 114.141 13.2219 111.789 11.8566C109.241 10.6863 107.477 9.71108 107.869 7.76061C108.457 5.81013 110.809 5.61509 113.553 5.42004C116.886 5.02995 117.474 8.1507 117.082 10.2962Z" fill="#DAE2EB" />
                    <path d="M98.0694 12.2465C99.0494 14.0019 101.794 15.1722 103.754 13.4167C105.91 11.4663 107.674 10.1009 106.694 8.15046C105.714 6.39503 104.146 6.98018 100.814 7.37027C98.0693 7.95541 96.8933 10.296 98.0694 12.2465Z" fill="#DAE2EB" />
                    <path d="M106.694 5.02987C108.066 4.83482 109.438 5.61501 109.83 6.7853C110.026 7.17539 110.222 7.76053 110.222 8.15063C110.614 10.8813 109.634 13.2219 108.066 13.4169C106.302 13.807 104.538 11.8565 104.342 9.32091C104.342 8.54072 104.342 8.15063 104.342 7.56549C104.538 6.20016 105.322 5.22492 106.694 5.02987C106.89 5.02987 106.694 5.02987 106.694 5.02987Z" fill="#989FB0" />
                    <path d="M67.402 99.7747C68.83 99.7747 69.9876 98.6171 69.9876 97.1891C69.9876 95.7611 68.83 94.6035 67.402 94.6035C65.974 94.6035 64.8164 95.7611 64.8164 97.1891C64.8164 98.6171 65.974 99.7747 67.402 99.7747Z" fill="#989FB0" />
                    <path d="M93.1168 99.7747C94.5448 99.7747 95.7024 98.6171 95.7024 97.1891C95.7024 95.7611 94.5448 94.6035 93.1168 94.6035C91.6889 94.6035 90.5312 95.7611 90.5312 97.1891C90.5312 98.6171 91.6889 99.7747 93.1168 99.7747Z" fill="#989FB0" />
                </g>
                <defs>
                    <filter id="filter0_d_2198_48487" x="7.09375" y="58.6172" width="146.328" height="100.912" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="11" />
                        <feGaussianBlur stdDeviation="11" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2198_48487" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2198_48487" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_2198_48487" x1="80.2248" y1="68.3008" x2="80.2248" y2="127.143" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FDFEFF" />
                        <stop offset="0.9964" stop-color="#ECF0F5" />
                    </linearGradient>
                    <clipPath id="clip0_2198_48487">
                        <rect width="150" height="150" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    )
}

export default MessageEmptyIcon