import React, { ReactNode } from 'react'
import {
  Table as MuiTable,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

type Props<T> = {
  data: T[]
  renderHeader?: () => ReactNode
  renderTableRow?: (item: T) => ReactNode
}

const Table = <T,>({ data, renderHeader, renderTableRow }: Props<T>) => {
  return (
    <Paper>
      <TableContainer>
        <MuiTable size="small">
          {renderHeader && <TableHead>{renderHeader()}</TableHead>}
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>{renderTableRow(item)}</TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Paper>
  )
}

export default Table
