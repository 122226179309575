import { useEffect } from "react";
import { useLocation } from 'react-router-dom'

/**
 * jamviet.com
 * Auto close modal after navigate ...
 * @returns
 */
export default function EventTrigger() {
  // Create the event
  const { pathname } = useLocation()

  useEffect( () => {
    var event = new CustomEvent("closeSidebar", { "detail": "Example of an event" });
    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(event);
  }, [pathname])

  return null;
}
