//Library
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import debounce from 'lodash.debounce'
import {AxiosError, AxiosResponse} from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLink, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import {Link, useLocation} from 'react-router-dom'

//Components
import TextField from 'components/TextField'
import MemberLists from './components/member.list.item'
import SkeletonMember from './components/member.skeleton'
import ComponentEmpty from 'components/componentEmpty'
import Capacity from 'components/Capacity'
import CheckChannelPayment from 'components/CheckChannelPayment'
import _Helmet from 'components/helmet'

//Hook
import {useInfinite} from 'hooks/useInfinite'

//Store
import {getListLevel, getListMembers} from './store/member.store.reducer'
import {useAppDispatch, useAppSelector} from 'config/store'

//Helpers
import helpers from 'helpers'

//Interface
import {TypedChannelLevel} from './interface'
import {TypedDataMember} from 'interfaces/member.interface'

//Scss
import './media/member.page.scss'
import __ from 'languages/index'
import {EnumTypeToast, useToast} from '../../hooks/useToast'
import BigLayout from "../../layouts/bigLayout";
import { Box, Button, Typography } from '@mui/material'

interface TypeDataTab {
  id: number
  hash: string
  label: string
}


const MemberPage = () => {
  const dispatch = useAppDispatch()
  const { hash } = useLocation()
  const channel_data = useAppSelector(state => state.user.channel_data)
  const user_data = useAppSelector(state => state.user.user_data)

  const [tabMenu, setTabMenu] = useState<number>(1)
  const [textSearch, setTextSearch] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [memberLists, setMemberLists] = useState<TypedDataMember[]>([])
  const [listLevels, setListLevels] = useState<TypedChannelLevel[]>([])
  const [totalListMembers, setTotalListMembers] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [isShowInputSearch, setIsShowInputSearch] = useState<boolean>(false)
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)
  const [isRefresh, setIsRefresh] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast();

  const params = useMemo(() => {
    let index: number
    switch (hash) {
      case '#approved':
        index = 1
        break
      case '#waiting':
        index = 0
        break
      case '#locked':
        index = 3
        break
      case '#rejected':
        index = 4
        break
      default:
        index = 1
    }
    return {
      page: page,
      limit: 10,
      search: search,
      official_status: index,
      auth_id: user_data?._id,
      channel_id: channel_data?._id,
      isRefresh: isRefresh
    }
  }, [page, search, hash, isRefresh])

  const dataTab: TypeDataTab[] = useMemo(()=>[
    {
      id: 1,
      hash: '#approved',
      label: `${__("member_approval")}`
    },
    {
      id: 0,
      hash: '#waiting',
      label: `${__("member_warning")}`
    },
    {
      id: 2,
      hash: '#locked',
      label: `${__("member_locked")}`
    },
    {
      id: 4,
      hash: '#rejected',
      label: `${__("member_reject")}`
    }
  ],[])

  const { ref } = useInfinite(memberLists?.length < totalListMembers, () => {
    setPage(page => page + 1)
  })

  const handleChangeMenu = useCallback((index: number) => {
    if(tabMenu === index) return
    setTabMenu(index)
    setMemberLists([])
    setPage(1)
  }, [tabMenu])

  const reduceRequestSearch = useMemo(() => {
    return debounce(_value => {
      setMemberLists([])
      setSearch(_value)
    }, 500)
  }, [textSearch])

  const handleChangeValue = useCallback((value: string) => {
    setTextSearch(value)
    reduceRequestSearch(value)
  }, [])

  const handleCopyLink = useCallback(() => {
    navigator.clipboard
      .writeText(
        'https://' +
          (window.location.hostname || '') +
         `/${user_data?.user_login ? '#user_referrer=' + user_data?.user_login : ''}`
      )
      .then(
        function () {
          toast.show({
            content: `${__("member_copy_link_success")}`,
            type: EnumTypeToast.Success
          })
        },
        function (err) {
          console.error('Async: Could not copy text: ', err)
          toast.show({
            content: `${__("member_copy_link_failed")}`,
            type: EnumTypeToast.Error
          })
        }
      )
      .catch(() => {})
  }, [user_data])

  const handleClickBtnClose = useCallback(() => setIsShowPopup(true), [])

  useLayoutEffect(() => {
    if (!hash) return handleChangeMenu(1)
    switch (hash) {
      case '#approved':
        handleChangeMenu(1)
        break
      case '#waiting':
        handleChangeMenu(0)
        break
      case '#locked':
        handleChangeMenu(2)
        break
      case '#rejected':
        handleChangeMenu(4)
        break
      default:
        handleChangeMenu(1)
    }
  }, [hash])

  useEffect(() => {
    delete params.isRefresh
    params.page < 2 && setIsLoading(true)
    dispatch(getListMembers(params))
      .unwrap()
      .then((res: AxiosResponse<TypedDataMember[]>) => {
        setIsLoading(false)
        setMemberLists(memberLists => [...memberLists, ...res?.data])
        setTotalListMembers(parseInt(res?.headers['x-total-count'] || 10, 10))
      })
      .catch((error: AxiosError) => {
        console.log(`getListMember_${error}`)
        setIsLoading(false)
        setMemberLists([])
        setTotalListMembers(0)
      })
  }, [params])

  useEffect(() => {
    if (helpers.isEmpty(memberLists)) return
    setMemberLists([])
    setPage(1)
  }, [isRefresh])

  useEffect(() => {
    dispatch(getListLevel({ channel_id: channel_data?._id }))
      .unwrap()
      .then((res: AxiosResponse) => {
        setListLevels(res?.data)
      })
      .catch((error: AxiosError) => {
        console.log(`getListLevel_${error}`)
        setListLevels([])
      })
  }, [channel_data])

  return (
    <BigLayout>
      <_Helmet title='member_title' />
      <div id="memberPage" className="memberPage_container">
        {channel_data?.official_status !== 0 ? (
          <div className="memberPage_wrapper">
            {/*{!isShowPopup && (*/}
            {/*  <div className="mt-4">*/}
            {/*    <PopupUpgrade*/}
            {/*      text={`còn 30 thành viên`}*/}
            {/*      url="/upgrade"*/}
            {/*      handleClickBtnClose={handleClickBtnClose}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
            <div className="memberPage_body_wrapper">
              <Capacity current_user_can={'boss'}>
                <div className="memberPage_header">
                  <div className="memberPage_header_tab_list">
                    {!helpers.isEmpty(dataTab) &&
                      dataTab?.map((item: TypeDataTab, idx: number) => (
                        <Box
                          component={Link}
                          key={`dataTab_${idx}`}
                          to={`${item?.hash}`}
                          sx={{borderBottom: theme =>  tabMenu === item?.id ? `1px solid ${theme.palette.primary.main} !important` : 'unset',':hover': {color: 'primary.main'}}}
                          className={`memberPage_header_tab_item ${
                            tabMenu === item?.id && 'active'
                          }`}
                        >
                          {item?.label}
                        </Box>
                      ))}
                  </div>
                  <div className="memberPage_header_button_wrapper">
                    <div
                      role="button"
                      onClick={() => setIsShowInputSearch(!isShowInputSearch)}
                      className="memberPage_header_button_search"
                    >
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                    <Button component={Link} to="/channel-setting/#invite">
                      <Typography textTransform={'none'} fontWeight={600}>
                        {__("member_btn_invite")}
                      </Typography>
                    </Button>
                  </div>
                </div>
              </Capacity>
              <Capacity current_user_can={'boss'} onFail={()=>(
                <div className="memberPage_header_user">
                  <div>
                    <div className="memberPage_header_user_icon">
                      <FontAwesomeIcon icon={faLink} />
                      <span className="fw-bold">{__("member_invite_others")}</span>
                    </div>
                    <span className="memberPage_header_user_desc">
                      {__("member_invite_sub_text")}
                    </span>
                  </div>
                  <div className="memberPage_footer_button">
                    <span className="memberPage_footer_button_title">
                      {'https://' +
                        (window.location.hostname || '') +
                        `/${user_data?.user_login ? '#user_referrer=' + user_data?.user_login : ''}`}
                    </span>
                    <Button onClick={handleCopyLink} sx={{textTransform: 'none'}}>
                      {__("member_btn_copy")}
                    </Button>
                  </div>
                </div>
              )}>

              </Capacity>
              <div className="memberPage_body">
                {isShowInputSearch && (
                  <TextField
                    value={textSearch || ''}
                    onChange={handleChangeValue}
                    placeholder={__("member_search")}
                  />
                )}
                {isLoading ? (
                  <SkeletonMember />
                ) : (
                  <>
                    {!helpers.isEmpty(memberLists) ? (
                      <>
                        {memberLists?.map((item: TypedDataMember, idx: number) => (
                          <div ref={ref} key={`memberLists_${idx}`}>
                            <MemberLists
                              data={item}
                              isRefresh={isRefresh}
                              listLevels={listLevels}
                              setIsRefresh={setIsRefresh}
                              memberLists={memberLists}
                              setMemberLists={setMemberLists}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <ComponentEmpty />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <CheckChannelPayment />
          </div>
        )}
      </div>
    </BigLayout>
  )
}

export default MemberPage
