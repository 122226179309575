//Library
import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from 'config/store'

//Components
import ComponentEmpty from 'components/componentEmpty'
import SkeletonMission from '../mission/components/mission.skeleton'
import SettingItemMission from '../mission/components/mission.setting.item'
import _Helmet from 'components/helmet'

//Store
import {deleteRedeem, getListRedeem, setActionsCheck} from './store/mission.store.reduce'

//Interface
import {TypeListRedeem} from './interface'

//Scss
import './media/mission.setting.scss'
import Title from 'components/Title'
import __ from 'languages/index'
import MediumLayout from "../../layouts/mediumLayout";
import {EnumTypeToast, useToast} from "../../hooks/useToast";
import { Box, Button, Typography } from '@mui/material'

const SettingMission = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { actionsCheck } = useAppSelector(state => state.mission)

  const toast = useToast()

  const [showIndexItem, setShowIndexItem] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listRedeem, setListRedeem] = useState<TypeListRedeem[]>([])

  const handleShowAddMission = useCallback(() => {
    navigate(`/setting-mission/add`)
  }, [])

  const toggleItem = useCallback(
    (index: number) => {
      if (showIndexItem === index) {
        setShowIndexItem(null)
      } else {
        setShowIndexItem(index)
      }
    },
    [showIndexItem]
  )

  const handleDeleteRedeem = useCallback((redeem_id?: string) => {
    dispatch(deleteRedeem(redeem_id))
      .unwrap()
      .then(res => {
        toast.show({
          title: `${__("notification")}`,
          content: `${__("mission_content_delete")}`,
          type: EnumTypeToast.Success
        })
        setShowIndexItem(null)
        setIsLoading(true)
        dispatch(getListRedeem())
          .unwrap()
          .then(res => {
            setListRedeem(res?.data)
            setIsLoading(false)
          })
          .catch(error => {
            setListRedeem([])
            setIsLoading(false)
            console.log(error)
          })
      })
      .catch(error => {
        console.log(`deleteRedeem_${error}`)
        toast.show({
          title: `${__("notification")}`,
          content: `${__("mission_content_delete_failed")}`,
          type: EnumTypeToast.Error
        })
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getListRedeem())
      .unwrap()
      .then(res => {
        setListRedeem(res?.data)
        setIsLoading(false)
      })
      .catch(error => {
        setListRedeem([])
        setIsLoading(false)
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (actionsCheck?.isSuccess && actionsCheck?.statusCode === 200) {
      dispatch(
        setActionsCheck({
          type: '',
          isSuccess: false,
          statusCode: 0
        })
      )
      toast.show({
        title: `${__("notification")}`,
        content: `${actionsCheck?.type} ${__("mission_success")}`,
        type: EnumTypeToast.Success
      })
    }
  }, [actionsCheck])

  return (
    <MediumLayout>
      <_Helmet title='mission_title' />
      <div id="settingMission" className="settingMission_container">
        <Title text={__("mission_setting")} />
        <div className="settingMission_wrapper">
          <div className="w-full d-flex justify-content-end">
            <Button onClick={handleShowAddMission}>
              <Typography textTransform={'none'} color={'#fff'}>
                {__("mission_btn_add")}
              </Typography>
            </Button>
          </div>
          <div className="settingMission_body">
            {isLoading ? (
              <div className="bg-white p-4 rounded mt-4">
                <SkeletonMission />
              </div>
            ) : (
              <>
                {listRedeem?.length ? (
                  <>
                    <Box sx={{backgroundColor: theme => theme.palette.primary.main}} className="settingMission_body_header_wrapper">
                      <div>
                        <span>{__("mission_name")}</span>
                      </div>
                      <div>
                        <span>{__("mission_reward")}</span>
                      </div>
                    </Box>
                    {listRedeem?.map((item: TypeListRedeem, idx: number) => (
                      <SettingItemMission
                        item={item}
                        idx={idx}
                        key={idx}
                        onToggle={toggleItem}
                        showIndexItem={showIndexItem}
                        handleDeleteRedeem={handleDeleteRedeem}
                      />
                    ))}
                  </>
                ) : (
                  <ComponentEmpty />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </MediumLayout>
  )
}

export default SettingMission
