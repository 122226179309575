import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import errorMiddleware from './error-middleware'
import reducer from '../store/'
import challengeApi from 'entities/challenge/store/challengeApi'
import supportApi from 'entities/support/store/supportApi'
import domainApi from 'entities/domain/store/domainApi'
import liveStreamApi from 'entities/livestream/store/liveStreamApi'
import podcastApi from '../podcast/store/podcastApi'
import referrerApi from 'entities/ReferrerTree/store/referrerApi'
import homeApi from 'entities/homeNew/store/homeApi'
import rankingApi from 'entities/ranking/store/rankingApi'

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([
      challengeApi.middleware,
      domainApi.middleware,
      supportApi.middleware,
      liveStreamApi.middleware,
      podcastApi.middleware,
      referrerApi.middleware,
      homeApi.middleware,
      rankingApi.middleware,
      errorMiddleware
    ])
})

export default function getStore() {
  return store
}

export type IRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>
