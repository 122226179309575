import { SvgIcon, SvgIconProps } from '@mui/material'

const WidgetIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8169 1.88116C13.7932 0.904848 15.3761 0.904848 16.3524 1.88116L18.1202 3.64893C19.0965 4.62524 19.0965 6.20815 18.1202 7.18446L16.3524 8.95223C15.3761 9.92854 13.7932 9.92854 12.8169 8.95223L11.0491 7.18446C10.0728 6.20815 10.0728 4.62524 11.0491 3.64893L12.8169 1.88116ZM15.1739 3.05967C14.8485 2.73423 14.3208 2.73423 13.9954 3.05967L12.2276 4.82744C11.9022 5.15287 11.9022 5.68051 12.2276 6.00595L13.9954 7.77372C14.3208 8.09915 14.8485 8.09915 15.1739 7.77372L16.9417 6.00595C17.2671 5.68051 17.2671 5.15287 16.9417 4.82744L15.1739 3.05967ZM1.66797 4.16669C1.66797 2.78598 2.78726 1.66669 4.16797 1.66669H6.66797C8.04868 1.66669 9.16797 2.78598 9.16797 4.16669V6.66669C9.16797 8.0474 8.04868 9.16669 6.66797 9.16669H4.16797C2.78726 9.16669 1.66797 8.0474 1.66797 6.66669V4.16669ZM4.16797 3.33336C3.70773 3.33336 3.33464 3.70646 3.33464 4.16669V6.66669C3.33464 7.12693 3.70773 7.50003 4.16797 7.50003H6.66797C7.12821 7.50003 7.5013 7.12693 7.5013 6.66669V4.16669C7.5013 3.70646 7.12821 3.33336 6.66797 3.33336H4.16797ZM1.66797 13.3334C1.66797 11.9526 2.78726 10.8334 4.16797 10.8334H6.66797C8.04868 10.8334 9.16797 11.9526 9.16797 13.3334V15.8334C9.16797 17.2141 8.04868 18.3334 6.66797 18.3334H4.16797C2.78726 18.3334 1.66797 17.2141 1.66797 15.8334V13.3334ZM4.16797 12.5C3.70773 12.5 3.33464 12.8731 3.33464 13.3334V15.8334C3.33464 16.2936 3.70773 16.6667 4.16797 16.6667H6.66797C7.12821 16.6667 7.5013 16.2936 7.5013 15.8334V13.3334C7.5013 12.8731 7.12821 12.5 6.66797 12.5H4.16797ZM10.8346 13.3334C10.8346 11.9526 11.9539 10.8334 13.3346 10.8334H15.8346C17.2153 10.8334 18.3346 11.9526 18.3346 13.3334V15.8334C18.3346 17.2141 17.2153 18.3334 15.8346 18.3334H13.3346C11.9539 18.3334 10.8346 17.2141 10.8346 15.8334V13.3334ZM13.3346 12.5C12.8744 12.5 12.5013 12.8731 12.5013 13.3334V15.8334C12.5013 16.2936 12.8744 16.6667 13.3346 16.6667H15.8346C16.2949 16.6667 16.668 16.2936 16.668 15.8334V13.3334C16.668 12.8731 16.2949 12.5 15.8346 12.5H13.3346Z"
        />
      </svg>
    </SvgIcon>
  )
}

export default WidgetIcon
