import React, { useEffect, useState } from 'react'
import { getVietnamBanks } from 'entities/channel-settings/service'

type TypedBank = {
  logo: string
  shortName: string
  name: string
  id: number
}

const useBanks = () => {
  const [banks, setBanks] = useState<TypedBank[]>([])
  useEffect(() => {
    const getBanks = async () => {
      try {
        const res = await getVietnamBanks()
        const banks = res.data.data
        setBanks(banks)
      } catch (err) {
        console.log(err)
      }
    }
    getBanks()
  }, [])
  return banks
}

export default useBanks
